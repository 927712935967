import {
    GridCellProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { KendoGridCellHelper } from './KendoGridCellHelper';

interface IProps extends GridCellProps {
    baseLink: string;
    linkField: string;
    className: string;
}

export const KendoLinkGridCell: React.FC<IProps> = (props: IProps) => {
        const value = KendoGridCellHelper.getValue(props)
        let linkValue;
        if(props.linkField != null && props.dataItem) {
            linkValue = KendoGridCellHelper.resolve(props.linkField, props.dataItem)
        }
        return <td>
            {
                <NavLink to={`${props.baseLink}/${linkValue}`} className={props.className}>
                    {value}
                </NavLink>
            }
        </td>
    }