
import { GlobalDescription } from '$src/storage/models/GlobalDescription';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * CertificateService provides all Service Methods for the Certificates 
 */
export default class GlobalDescriptionService extends ServiceClient {
    protected static _instance: GlobalDescriptionService | null = null;

    protected className = 'CertificateService';
    protected loggerLocality = 'CertificateService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): GlobalDescriptionService {
        return this._instance || (this._instance = new this());
    }
  

    public async getGlobalDescription(globalDescriptionName: string, language: string): Promise<GlobalDescription | GtError> {
        let response: GlobalDescription | GtError;
        response = await this.get<GlobalDescription>('globalDescription/GetGlobalDescription',
            GlobalDescription,
            undefined,
            [{ name: 'language', value: language }],
            globalDescriptionName);
        return response;
    }  
} 