import { DatePicker } from '$components/shared/DatePicker';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import DateHelper from '$src/util/DateHelper';
import { FieldValidators } from '$src/util/Fieldvalidators';
import React from 'react';

interface IProps {
    attributes: InputAttributes;
    onChange?: (id: string, value: string, errMsg: string) => void;
    onCrEntered?: (id: string) => void;
}

interface IState {
    value: Date | null;
    isValid: boolean;
    errorMsg: string;
}


// tslint:disable:object-literal-sort-keys
export class InputDate extends React.Component<IProps, IState> {

    public languageCode = Session.instance.getUserLanguageCodeOrFallBack;

    protected controlDate: any | null
    protected className = 'InputDate';
    protected loggerLocality = 'Components\shared.InputDate';

    constructor(props: IProps) {
        super(props);

        const sourceValue = this.props.attributes.value;
        const initialValidation: boolean = (this.props.attributes.initialValidation === undefined) ? false : this.props.attributes.initialValidation;

        let date: Date | null = null
        if (sourceValue.length !== 0) {
            date = DateHelper.toDateFromIso (sourceValue)
        }

        // Validate input value.
        const errorMessage = this.validateField(initialValidation, date);
        const controlValid = errorMessage.length === 0 ? true : false;

        // Initialize state based on validation check.        
        this.state = {
            value: date,
            isValid: controlValid,
            errorMsg: errorMessage
        }
        this.fireOnChanged = this.fireOnChanged.bind(this);

        // Prevent UTC conversion
        const stringDate = date === null ? '' : date.toISOString();

        // If the control is invalid, the event will be triggered to inform parent component.
        if (!controlValid) {
            this.fireOnChanged(stringDate, errorMessage)
        }
    }

    public componentDidMount(): void {
        if (this.props.attributes.editMode && this.props.attributes.hasFocus) {
            this.setFocus();
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {
        const lblClass: string = 'input-label';
        let txtClass: string = 'input-field';
        txtClass = txtClass + this.errorClass(this.state.errorMsg)

        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'

        // Build final component element        
        const inputElement: JSX.Element[] = [];

        inputElement.push(
            <label key={'InputDate_Label_' + this.props.attributes.id} htmlFor={this.props.attributes.id} className={lblClass}>
                <Translate>{this.props.attributes.label}</Translate>
                {(this.props.attributes.isRequired && this.props.attributes.editMode) ? ' *' : ''}
            </label>
        )

        inputElement.push(
            <DatePicker
                key={'InputDate_Input_' + this.props.attributes.id}
                id={this.props.attributes.id}
                className={txtClass}
                value={this.state.value}
                readOnly={this.props.attributes.isReadOnly}
                required={this.props.attributes.isRequired}
                isNotClearable={true}
                minDate={this.props.attributes.minDate}
                maxDate={this.props.attributes.maxDate}
                ref={(e: any) => this.controlDate = e}
                onChange={(e) => this.onChange(e)}
            />
           
        )

        inputElement.push(
            <span key={'InputDate_ErrorMsg_' + this.props.attributes.id} className={'input-message error'}>
                <Translate>{this.state.errorMsg}</Translate>
            </span>
        )

        return (
            <div key={'InputDate_Div_' + this.props.attributes.id} className={boxClass}>
                {inputElement}
            </div >
        )
    }

    /**
     * Retrieve error class.
     */
    protected errorClass(errorMsg: string) {
        return (errorMsg.length === 0 ? '' : ' has-error');
    }

    /**
     * Validate input value.
     */
    protected validateField(validateValue: boolean, date: Date | null): string {
        let message: string = '';

        if (validateValue) {
            if (date !== null) {
                if (message.length === 0) {
                    if (this.props.attributes.validationOptions != null && this.props.attributes.validationOptions.isTodayCheckEnabled) {
                        message = FieldValidators.DateValidatorToday(date);
                    }
                    else {
                        message = FieldValidators.DateValidator(date);
                    }
                }
            }

            const value = (date == null) ? '' : date.toLocaleString()

            if (message.length === 0) {
                message = FieldValidators.Required(value.toString(), this.props.attributes.isRequired);
            }
        }
        return message;
    }

    /**
     * Set input focus to input field.
     */
    protected setFocus() {
        if (this.controlDate !== null) {
            this.controlDate.input.focus();
        }
    }

    /**
     * Callback for DatePicker (text input): text has changed.
     */
    protected onChange(targetValue: Date | null) {

        // Validate input value.

        const errorMessage = this.validateField(true, targetValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        this.setState({
            value: targetValue,
            isValid: controlValid,
            errorMsg: errorMessage,
        })

        const stringDate = targetValue === null ? '' : targetValue.toISOString();
        this.fireOnChanged(stringDate, errorMessage)

    }

    protected fireOnChanged(targetValue: string, errorMessage: string) {
        // Callback for control: Text has changed.        
        if (this.props.onChange) {
            // Prevent UTC conversion
            this.props.onChange(this.props.attributes.id, targetValue, errorMessage);
        }

    }

}