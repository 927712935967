import React from 'react';

import { Assignments } from '$src/components/assignment/Assignments'
import { RegisteredItems } from '$src/components/registeredItem/RegisteredItems';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { TabsContainer } from '$src/components/shared/tab/TabsContainer';
import Logger from '$src/core/Logger';
import Session from '$src/core/Session';
import { observer } from 'mobx-react';
import { RegisteredItem } from '$src/storage/models/RegisteredItem';
import { AssignedItem } from '$storage/models/AssignedItem';

interface IProps {
    parentHeadingLevel: number;
    maxAssignments: number;
    maxRegisteredItems: number;
    maxRecommendedItems: number;
    displayCountOfRegisteredItems: boolean;
}

@observer
export class HomeTabs extends React.Component<IProps, {}> {

    protected className = 'HomeTabs';
    protected loggerLocality = 'Components.HomeTabs';

    constructor(props: IProps) {
        super(props);
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        // Only load registered items, when the count should be displayed
        const allPromises = new Array<Promise<RegisteredItem[] | AssignedItem[] | null>>();
        if (this.props.displayCountOfRegisteredItems) {
            allPromises.push(Session.instance.storage.registeredItem.getObjects());
        }
        allPromises.push(Session.instance.storage.assignment.getObjects());


        Logger.log(this.loggerLocality, `${methodName} loading required data.`);

        await Promise.all(allPromises);
    }

    public render() {
        const tr = Session.instance.storage.translation
        const methodName = `${this.className}:render()`;
        const assignmentStorage = Session.instance.storage.assignment;
        const registrationStorage = Session.instance.storage.registeredItem;

        let requiredAssignmentsCount = '0';
        let recommendedAssignmentsCount = '0';
        if (assignmentStorage.isInitialized) {
            Logger.log(this.loggerLocality, `${methodName} assignment storage is initialized, rendering count.`);
            const allAssignments = assignmentStorage.getObjectsFromCache();
            requiredAssignmentsCount = allAssignments.filter((a) => assignmentStorage.filterRequiredAssignments(a)).length.toString();
            recommendedAssignmentsCount = allAssignments.filter((a) => assignmentStorage.filterRecommendedAssignments(a)).length.toString();
        }

        let registeredItemsCount = '0';
        if (registrationStorage.isInitialized) {
            let data = registrationStorage.getObjectsFromCache();
            if (data != null) {
                Logger.log(this.loggerLocality, `${methodName} registereditem storage is initialized, rendering count.`);
                // Add potential status filter here
                data = data.filter(item =>
                    !globalConfig.registeredItemsProperties.ignoreItemsWithRegistrationStatus.includes(item.registrationStatus) &&
                    !globalConfig.registeredItemsProperties.ignoreItemsWithLessonStatus.includes(item.lessonStatus) &&
                    globalConfig.registeredItemsProperties.classStatusToShow.includes(item.classStatus)
                );
                registeredItemsCount = data.length.toString();
            }
        }

        let myTrainingsTabTitle = tr.GetString('Home:MyTrainings');
        if (this.props.displayCountOfRegisteredItems) {
            myTrainingsTabTitle += tr.GetString('Home:MyTrainingsCount').Format(registeredItemsCount);
        }

        return <TabsContainer
            parentHeadingLevel={this.props.parentHeadingLevel}
            defaultActiveTabIndex={Session.instance.activeTabHome}
            onTabIndexChanged={(tabIndex) => this.onHomeTabIndexChanged(tabIndex)}
            // tslint:disable:object-literal-sort-keys
            tabs={
                    [
                        {tabId: 'tabAssignments',
                        key: 'TabAssignments',
                        title: tr.GetString('Home:Assignments').Format(requiredAssignmentsCount),
                        content: this.getRequiredAssignmentTabContent()},
                        {tabId: 'tabMyTrainings',
                        key: 'TabMyTrainings',
                        title: myTrainingsTabTitle,
                        content: this.getMyTrainingsTabContent()},
                        {tabId: 'tabRecommendedTrainings',
                        key: 'TabRecommendedTrainings',
                        title: tr.GetString('Home:RecommendedTrainings').Format(recommendedAssignmentsCount),
                        content: this.getRecommendedAssignments()}
                    ]
            } />;
    }
    

    private getRequiredAssignmentTabContent() {

        if (!Session.instance.storage.assignment.isInitialized) {
            return <ProgressSpinner />
        }

        return <Assignments
            maxItemsToDisplay={this.props.maxAssignments}
            filterExpression={(assignment) => Session.instance.storage.assignment.filterRequiredAssignments(assignment)}
            key={'Assignments'}
            allItemsLink={'/Assignments'}
            allItemsLinkText={'Home:AllAssignments'}
            expandInline={false}
            showSortOrderButton={globalConfig.homeProperties.showSortButtonForRequiredAssignments}
            {...this.props} />
    }

    private getMyTrainingsTabContent() {
        if (!Session.instance.storage.registeredItem.isInitialized && this.props.displayCountOfRegisteredItems) {
            return <ProgressSpinner />
        }

        return <RegisteredItems
            maxItems={this.props.maxRegisteredItems}
            allItemsLink={'/mytrainings'}
            allItemsLinkText={'Home:AllMyTrainings'}
            {...this.props} />
    }

    private getRecommendedAssignments() {

        if (!Session.instance.storage.assignment.isInitialized) {
            return <ProgressSpinner />
        }
        
        return <Assignments
            maxItemsToDisplay={this.props.maxRecommendedItems}
            filterExpression={(assignment) => Session.instance.storage.assignment.filterRecommendedAssignments(assignment)}
            key={'RecommendedTrainings'}
            allItemsLink={'/recommendedTrainings'}
            allItemsLinkText={'Home:AllRecommendedTrainings'}
            expandInline={false}
            {...this.props} />

    }

    private onHomeTabIndexChanged(tabIndex: number) {
        Session.instance.setActiveTabHome(tabIndex);
    }
}
