import { JsonObject, JsonProperty } from 'json2typescript';

/** Response of a user search */
@JsonObject
export class PaymentUserSearchResponse {
    @JsonProperty('company', String, false)
    public company: string = '';

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    @JsonProperty('userId', Number, false)
    public userId: number = 0;
}