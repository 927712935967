import React from 'react';
import { RouteComponentProps } from 'react-router';

import { ItemBreadCrumb } from '$components/breadCrumb/ItemBreadCrumb';
import { ItemRegistrationActionConfirmation } from '$components/item/ItemRegistrationActionConfirmation';
import { ToolbarRegistrationStatus } from '$components/item/Toolbar/ToolbarRegistrationStatus';
import AttributeList from '$components/shared/Attributes/AttributeList';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ParticipantList } from '$components/shared/ParticipantList';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import TrainingPlanService from '$core/Services/TrainingPlanService';
import Session from '$core/Session';
import { Item } from '$src/storage/models/Item';
import { RegisteredItem } from '$src/storage/models/RegisteredItem';
import RegisteredItemStorage from '$src/storage/RegisteredItemStorage';
import { StringHelper } from '$src/util/StringHelper';
import { Attribute } from '$storage/models/Attribute';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { ECancellationPermission, ECancellationType, EItemDetailCallerContextType, EItemDetailCallerType, ERegistrationStatus } from '$storage/models/enums';
import { F2FClassDetail } from '$storage/models/F2F/F2FClassDetail';
import { Registration } from '$storage/models/Registration';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanSchedule } from '$storage/models/TrainingPlan/TrainingPlanSchedule';
import CustomErrorMessage from '$util/CustomErrorMessage';
import { isSuccess } from '$util/Result';
import { ItemLockedReasons } from '$components/item/ItemLockedReasons';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import { NumberHelper } from '$src/util/NumberHelper';
import { VoucherValidity } from '$src/storage/models/Purchase/VoucherValidity';
import PurchaseService from '$src/core/Services/PurchaseService';
import { CheckoutResponse } from '$src/storage/models/Purchase/CheckoutResponse';
import ShoppingBasket from '$src/storage/models/ShoppingBasket/ShoppingBasket';
import { CheckoutRequest } from '$src/storage/models/Purchase/CheckoutRequest';
import { CollaborationProviderClassPanel } from '$components/collaborationProvider/item/CollaborationProviderClassPanel';
import ConfigService from '$src/core/Services/ConfigService';
import ShoppingBasketPopUp from '$src/components/shoppingBasket/ShoppingBasketPopUp';

interface IMatchParams {
    tplanId: string;
    sceId: string;
}

interface IURLParamState {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    voucherCode: string | null;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
type IProps = RouteComponentProps<IMatchParams>

interface IState extends IURLParamState {
    tplanId: number;
    sceId: number;
    tplanSchedule: TrainingPlanSchedule | null | undefined;
    trainingPlan: TrainingPlan | null;
    errorMessage: string;
    f2fClassIds: number[];
    shouldShowModal: boolean;
    registeredItemArray: RegisteredItem[] | null;
    showParticipitions: boolean;
    item: Item | null;
    isScheduleInShoppingBasket: boolean;
    registerDisabledRegardingAutoF2FClassDetails: boolean;
    filteredClasses: F2FClassDetail[] | null;
    f2fClassIdsAlreadyRegistered: number[];
    voucherValidity: VoucherValidity | undefined;
    isForbiddenRedeemOnCancelledItems: boolean;
    showShoppingModal: boolean;
}

export class TrainingPlanRegistration extends React.Component<IProps, IState> {
    protected className = 'TrainingPlanRegistration';
    protected loggerLocality = 'Components.TrainingPlanRegistration';
    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessage: '',
            f2fClassIds: [],
            item: null,
            itemDetailCallerContextType: EItemDetailCallerContextType.Undefined,
            parentAssignmentId: 0,
            parentCatalogFolderId: 0,
            registeredItemArray: null,
            sceId: 0,
            shouldShowModal: false,
            showParticipitions: false,
            tplanId: 0,
            tplanSchedule: null,
            trainingPlan: null,
            isScheduleInShoppingBasket: false,
            registerDisabledRegardingAutoF2FClassDetails: false,
            filteredClasses: [],
            f2fClassIdsAlreadyRegistered: [],
            voucherCode: null,
            voucherValidity: undefined,
            isForbiddenRedeemOnCancelledItems: false,
            showShoppingModal: false
        }
    }

    public async componentDidMount() {
        const tplanId = this.props.match.params.tplanId;
        const sceId = this.props.match.params.sceId;
        const tplanChecked = tplanId != null && !isNaN(Number(tplanId)) ? Number(tplanId) : 0;
        const sceIdChecked = sceId != null && !isNaN(Number(sceId)) ? Number(sceId) : 0;

        let tplanSce: TrainingPlanSchedule | null | undefined;
        const partialStateUrlParameters = this.getURLParameters();
        const tplan = await Session.instance.storage.trainingPlans.getTrainingPlan(tplanChecked, partialStateUrlParameters.itemDetailCallerContextType, true);

        const ignoreRegisterRight = globalConfig.trainingPlanProperties.ignoreClassRegisterRight
            || globalConfig.trainingPlanProperties.ignoreClassRegistrationPhaseEnd
            || globalConfig.trainingPlanProperties.ignoreClassRegistrationPhaseStart;
        const tplanScheduleList = await Session.instance.storage.trainingPlanSchedules.getTrainingPlanSchedules(tplanChecked, partialStateUrlParameters.itemDetailCallerContextType, ignoreRegisterRight, false);
        if (tplanScheduleList !== null) {
            tplanSce = tplanScheduleList.find(item => item.sceId === sceIdChecked);
        }

        const allRegistrations = await RegisteredItemStorage.GetAllRegistrations();

        // check whether this TP schedule is already in shopping basket and set state accordingly
        const isScheduleInShoppingBasket = ShoppingBasketStorage.instance.isItemInBasket(tplanChecked, sceIdChecked);

        let filteredClasses: F2FClassDetail[] = [];
        // Array of itemid's of the course with at least one registration for the current user
        const registeredItemIds: number[] = [];
        const registeredClassIds: number[] = [];
        if (tplanSce != null && tplanSce.autoRegistrationActive && tplanSce.autoRegistrationClassDetails !== undefined && this.displayAutoRegisterClasses(tplanSce)) {

            // Array of F2FClassDetail objects, of classes, with registrations for the current user
            const registeredClasses: F2FClassDetail[] = [];

            filteredClasses = tplanSce.autoRegistrationClassDetails.filter(cls => (cls.freePlaces >= 0 || (cls.maxParticipants === 0) || (cls.hasWaitingList)));

            // if the user is already registered for one class, this class must be the only one to be displayed.
            // to check this condition, the registrations are needed
            if (allRegistrations != null) {
                filteredClasses.map(cls => {
                    if (allRegistrations != null) {
                        const isRegistered = allRegistrations.find(x => x.classId === cls.classId && cls.isRegistrationAllowed)
                        if (isRegistered !== undefined && (
                            isRegistered.registrationStatus === ERegistrationStatus.Accepted ||
                            isRegistered.registrationStatus === ERegistrationStatus.InWaitingList ||
                            isRegistered.registrationStatus === ERegistrationStatus.Requested
                        )) {
                            if (!isRegistered.isMultipleRegistrationAllowed) {
                                if (registeredItemIds.find(nr => nr === cls.itemId) === undefined) {
                                    registeredItemIds.push(cls.itemId);
                                }
                                registeredClasses.push(cls);
                            }
                            registeredClassIds.push(cls.classId);
                        }
                    }
                });
                // filter all classes of courses with registrations
                filteredClasses = filteredClasses.filter(x => !registeredItemIds.some(y => y === x.itemId));

                // add the classes with registrations for the current user
                registeredClasses.map(x => filteredClasses.push(x));
            }

            filteredClasses = filteredClasses.sort((a, b) => {
                if (a.courseId < b.courseId) { return -1 }
                if (a.courseId > b.courseId) { return 1 }
                if (a.learningPeriodStart != null && b.learningPeriodStart == null) { return -1 }
                if (a.learningPeriodStart == null && b.learningPeriodStart != null) { return 1 }
                if (a.learningPeriodStart != null && b.learningPeriodStart != null && a.learningPeriodStart < b.learningPeriodStart) { return -1 }
                if (a.learningPeriodStart != null && b.learningPeriodStart != null && a.learningPeriodStart > b.learningPeriodStart) { return -1 }
                return 0;
            });
        }

        const f2fClassIds: number[] = [];
        filteredClasses?.forEach(f => {
            //only one class per course -> set already selected (radiobutton in render)
            if (filteredClasses.filter(c => c.courseId == f.courseId && c.isPossibleToRegisterAfterTplanRegistration === false && (!registeredClassIds.includes(f.classId))).length === 1) {
                f2fClassIds.push(f.classId);
            }
        });

        let buttonDisabledAndErrMsg: [boolean, string] = [false, ''];
        if (this.allowRegistration(tplan, tplanSce)) {
            buttonDisabledAndErrMsg = this.checkRegistrationOnCourses(filteredClasses, registeredClassIds, f2fClassIds);
        }

        let voucherValidity: VoucherValidity | undefined;
        if (partialStateUrlParameters.voucherCode !== null) {
            Logger.log(this.loggerLocality, `${this.loggerLocality} loadData() -> checkVoucher`);
            const response = await PurchaseService.instance.checkVoucher(partialStateUrlParameters.voucherCode);
            if (isSuccess<VoucherValidity>(response)) {
                if (response.isValid && response.percentDiscount === 100 && response.itemIds.findIndex(i => i == tplan?.itemId) !== -1) {
                    voucherValidity = response;
                }
            } else {
                console.error(`checkVoucher failed`);
            }
        }

        let isForbiddenRedeemOnCancelledItems = false;
        const isForbiddenRedeemOnCancelledItemsReq = await ConfigService.instance.getIsForbiddenRedeemOnCancelledItems();
        if (isSuccess<BooleanResponse>(isForbiddenRedeemOnCancelledItemsReq) && isForbiddenRedeemOnCancelledItemsReq.status) {
            isForbiddenRedeemOnCancelledItems = tplan != null && (tplan.registrationStatus === ERegistrationStatus.Cancelled
                || tplan.registrationStatus === ERegistrationStatus.Rejected
                || tplan.registrationStatus === ERegistrationStatus.ScheduleCancelled);
        }

        this.setState({
            registeredItemArray: allRegistrations,
            sceId: sceIdChecked,
            tplanId: tplanChecked,
            tplanSchedule: tplanSce,
            trainingPlan: tplan,
            isScheduleInShoppingBasket,
            filteredClasses: filteredClasses,
            registerDisabledRegardingAutoF2FClassDetails: buttonDisabledAndErrMsg[0],
            f2fClassIds: f2fClassIds,
            f2fClassIdsAlreadyRegistered: registeredClassIds,
            errorMessage: buttonDisabledAndErrMsg[1],
            voucherValidity: voucherValidity,
            isForbiddenRedeemOnCancelledItems: isForbiddenRedeemOnCancelledItems,
            ...partialStateUrlParameters
        });
    }

    public render() {
        let currentRegistration: Registration[] | null = null;
        if (this.state.trainingPlan != null &&
            this.state.trainingPlan.allRegistrations != null &&
            this.state.trainingPlan.allRegistrations.length > 0 &&
            this.state.tplanSchedule != null) {
            const schedule = this.state.tplanSchedule;
            currentRegistration = this.state.trainingPlan.allRegistrations.filter(r => r.classId === schedule.sceId);
        }

        // prepare the display of the shopping button if this training plan must be purchased
        let showShoppingBtn: boolean = false;
        let shoppingBtnText = '';
        let shouldShoppingButtonBeDisabled = false;
        if (this.state.trainingPlan != null && this.state.tplanSchedule != null && this.state.trainingPlan.isPurchasableForCurrentUser) {
            showShoppingBtn = true;
            if (this.state.tplanSchedule.price === null || this.state.tplanSchedule.currency.length === 0) {
                // misconfiguration: price or currency is missing
                shoppingBtnText = Session.instance.storage.translation.GetString('Course:PurchaseImpossible');
                shouldShoppingButtonBeDisabled = true;
            } else {
                // price is known, item can be purchased
                if (this.state.isScheduleInShoppingBasket) {
                    shoppingBtnText = Session.instance.storage.translation.GetString('Course:ItemAlreadyInShoppingBasket');
                } else {
                    shoppingBtnText = Session.instance.storage.translation.GetString('Course:AddToShoppingBasket');
                }
            }
        }

        //prepare the display for 100% discount redeem voucher
        const showRedeemVoucherBtn: boolean = showShoppingBtn && this.state.voucherValidity !== undefined && this.state.voucherValidity.isValid;
        const redeemBtnText = Session.instance.storage.translation.GetString("Course:RedeemVoucher");
        const requestVoucherBtnText = Session.instance.storage.translation.GetString("Course:RequestVoucher");

        return (<React.Fragment>
            <div className="l-container">
                <ItemBreadCrumb
                    itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                    parentAssignmentId={this.state.parentAssignmentId}
                    parentCatalogFolderId={this.state.parentCatalogFolderId}
                    parentTrainingPlanId={0}
                    itemDetailType={EItemDetailCallerType.Item}
                    itemTitle={this.state.trainingPlan != null ? this.state.trainingPlan.title : ''}
                    itemId={this.state.trainingPlan != null ? this.state.trainingPlan.itemId : 0}
                    classId={this.state.sceId}
                    classTitle={this.state.tplanSchedule != null ?
                        this.state.tplanSchedule.title !== '' ?
                            this.state.tplanSchedule.title :
                            this.state.tplanSchedule.code :
                        ''}
                    isTPLevel={true}
                    trainingPlanId={this.state.tplanId}
                    voucherCode={this.state.voucherCode} />
                <div>
                    <h1 className="heading__Title">
                        <Translate>TrainingPlan:ClassDetails</Translate>
                    </h1>
                </div>
                <div>
                    {this.state.tplanSchedule != null ? this.renderCustomAttributes(this.state.tplanSchedule.attributes) : null}
                </div>
                <div>
                    <h2 className="heading__Level2">
                        <Translate>TrainingPlan:ClassDetailsOverview</Translate>
                    </h2>
                    {this.state.tplanSchedule != null && this.state.trainingPlan != null ? (this.renderDetails()) : (<ProgressSpinner />)}
                </div>
                <div>
                    {this.displayAutoRegisterClasses(this.state.tplanSchedule) ? (
                        <h2 className="heading__Level2">
                            <Translate>TrainingPlan:AutoRegisterClasses</Translate>
                        </h2>
                    ) : (<React.Fragment />)}
                </div>
                <div>
                    {this.state.tplanSchedule != null &&
                        this.state.tplanSchedule.autoRegistrationClassDetails != null ?
                        (this.renderF2FAutoRegisterClasses()) : (<ProgressSpinner />)}
                </div>

                {this.state.trainingPlan &&
                    globalConfig.trainingPlanProperties.showParticipantList &&
                    currentRegistration != null &&
                    currentRegistration.length > 0 &&
                    currentRegistration[0].registrationStatus === ERegistrationStatus.Accepted ?
                    <div className="step-content__block">
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.onParticipationListClicked()}
                            aria-label={Session.instance.storage.translation.GetString('Course:OpenParticipantList')}>
                            <Translate>Course:OpenParticipantList</Translate>
                        </button>
                    </div> : ''
                }

                {this.state.trainingPlan &&
                    currentRegistration != null &&
                    currentRegistration.length > 0 &&
                    currentRegistration[0].registrationStatus === ERegistrationStatus.Accepted &&
                    <CollaborationProviderClassPanel
                        itemId={this.state.trainingPlan.itemId}
                        classId={currentRegistration[0].classId}
                        panelTitle={'Course:CollaborationProviderLink'} />
                }

                {(this.allowRegistration(this.state.trainingPlan, this.state.tplanSchedule) || this.allowCancellation()) &&
                    <h2 className="heading__Level2">
                        <Translate>TrainingPlan:ScheduleRegistration</Translate>
                    </h2>
                }
                <div className="tp__registration-button-container">
                    {this.allowRegistration(this.state.trainingPlan, this.state.tplanSchedule) &&
                        <>
                            {
                                showRedeemVoucherBtn ? <button type="button"
                                    disabled={this.state.registerDisabledRegardingAutoF2FClassDetails}
                                    aria-label={redeemBtnText}
                                    onClick={() => this.onRedeemVoucherBtnClicked()}
                                    className="btn--md btn--primary">
                                    {redeemBtnText}
                                </button> :
                                    showShoppingBtn ?
                                        // for purchasable training plans show the request voucher (secondary) and shopping button (primary, to add the schedule to the shopping basket) 
                                        <>
                                            {globalConfig.shoppingProperties.isRequestVoucherBtnActive ? <button type="button"
                                                disabled={shouldShoppingButtonBeDisabled || this.state.registerDisabledRegardingAutoF2FClassDetails || this.state.isForbiddenRedeemOnCancelledItems}
                                                aria-label={requestVoucherBtnText}
                                                onClick={() => this.onRequestVoucherBtnClicked()}
                                                className="btn--md btn--secondary">
                                                {requestVoucherBtnText}
                                            </button> : <></>
                                            }
                                            <button type="button"
                                                disabled={shouldShoppingButtonBeDisabled || this.state.registerDisabledRegardingAutoF2FClassDetails}
                                                aria-label={shoppingBtnText}
                                                onClick={() => this.onShoppingBtnClicked()}
                                                className="btn--md btn--primary">
                                                {shoppingBtnText}
                                            </button>
                                        </>
                                        :
                                        // otherwise show the normal register button
                                        <button type="button"
                                            disabled={this.state.registerDisabledRegardingAutoF2FClassDetails}
                                            onClick={() => this.onCancellationClicked()}
                                            className="btn--md btn--primary">
                                            <Translate>TrainingPlan:Register</Translate>
                                        </button>
                            }
                        </>
                    }
                    {this.allowCancellation() &&
                        <button type="button"
                            aria-label={Session.instance.storage.translation.GetString('Course:CancelRegistration')}
                            className="btn--md btn--primary"
                            onClick={() => this.onCancellationClicked()}>
                            <Translate>Course:CancelRegistration</Translate>
                        </button>
                    }
                    <div>
                        <span className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                </div>
            </div>
            {this.state.tplanSchedule != null ?
                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={() => this.closeModal()}
                    contentCssClass={!this.state.showParticipitions ? '' : 'modal__participantList'}>
                    {!this.state.showParticipitions ?
                        <div className="modal__spread buttons">
                            <ItemRegistrationActionConfirmation
                                itemID={this.state.tplanId}
                                classTitle={this.state.tplanSchedule != null ?
                                    this.state.tplanSchedule.title : this.state.trainingPlan != null ? this.state.trainingPlan.title : ''}
                                parentHeadingLevel={2}
                                register={this.allowRegistration(this.state.trainingPlan, this.state.tplanSchedule) && !this.allowCancellation()}
                                onExitClicked={() => this.closeModal()}
                                onRegistrationConfirmedClick={(boss) => this.onRegistrationConfirmed(boss)}
                                onCancelRegistrationConfirmedClick={(reasonId, reasonText) => this.onCancelRegistrationConfirmed(reasonId, reasonText)}
                                cancellationType={this.state.tplanSchedule != null ? this.state.tplanSchedule.isCancellationAllowed : ECancellationPermission.NotAllowed}
                                cancellationReasonType={ECancellationType.Enrolment}
                                bossSelectionReadonly={globalConfig.bossSelection.bossSelectionIsReadOnly_TrainingPlan}
                                bossSelectionRequired={globalConfig.bossSelection.bossSelectionIsRequired_TrainingPlan}
                                bossSelectionVisible={globalConfig.bossSelection.selectionIsVisible_TrainingPlan && !this.state.tplanSchedule.isAutoRegister} />
                        </div> :
                        <div>
                            <ParticipantList
                                parentHeadingLevel={1}
                                scheduleId={this.state.tplanSchedule.sceId}
                                itemType={this.state.trainingPlan!.itemType}
                                itemSubType={this.state.trainingPlan!.itemSubType}
                            />
                        </div>
                    }
                </ModalPopup> : ''
            }
            <ShoppingBasketPopUp closeModal={() => this.closShoppingModal()} showModal={this.state.showShoppingModal} />
        </React.Fragment>
        );
    }

    public closShoppingModal() {
        this.setState({ showShoppingModal: false });
    }

    public componentDidUpdate() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
    }

    protected async onRegistrationConfirmed(boss: BossSearchResult | undefined) {
        const response = await TrainingPlanService.instance.registerToTrainingPlan(this.state.tplanId,
            this.state.sceId,
            this.state.f2fClassIds,
            Session.instance.getUserLanguageCodeOrFallBack,
            boss != null ? boss.id : 0,
            false);
        if (isSuccess<BooleanResponse>(response)) {
            if (response.status) {
                window.location.reload();
            }
        } else {
            if (response.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:RegistrationFailed' });
            }
            this.closeModal();
        }
    }

    protected async onRequestVoucherBtnClicked() {
        const response = await TrainingPlanService.instance.registerToTrainingPlan(this.state.tplanId,
            this.state.sceId,
            this.state.f2fClassIds,
            Session.instance.getUserLanguageCodeOrFallBack,
            0,
            true);
        if (isSuccess<BooleanResponse>(response)) {
            if (response.status) {
                window.location.reload();
            }
        } else {
            if (response.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:RegistrationFailed' });
            }
        }
    }

    protected async onCancelRegistrationConfirmed(cancellationReasonId: number | undefined, cancellationReasonText: string) {
        if (Session.instance.loginUser != null && Session.instance.loginUser.canSelfUnregisterFromTP) {
            if (this.state.trainingPlan != null) {
                await TrainingPlanService.instance.cancelRegistration(this.state.trainingPlan.itemId,
                    this.state.tplanSchedule!.sceId,
                    Session.instance.getUserLanguageCodeOrFallBack,
                    cancellationReasonId != null ? cancellationReasonId : 0,
                    cancellationReasonText,
                    Session.instance.loginUser.id);
                window.location.reload();
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:CanNotSelfUnregisterToTP' });
        }
    }

    protected closeModal(): void {
        this.setState({ shouldShowModal: false }); // Replace with actual Titel translation
    }

    protected openModal(): void {
        this.setState({ shouldShowModal: true }); // Replace with actual Titel translation
    }

    protected renderDetails(): JSX.Element {
        if (this.state.tplanSchedule != null && this.state.trainingPlan != null) {
            return (
                <React.Fragment>
                    <div role="table" className="tp__registration-details">
                        <div role="rowgroup">
                            {globalConfig.trainingPlanProperties.tplanClassDetailRows.map((r) => {
                                switch (r.label) {
                                    case "TrainingPlan:TrainingPlan":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {this.state.trainingPlan != null ? this.state.trainingPlan.title : ""}
                                            </div>
                                        </div>)
                                    case "TrainingPlan:ScheduleCode":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {this.state.tplanSchedule != null ? this.state.tplanSchedule.code : ""}
                                            </div>
                                        </div>)
                                    case "TrainingPlan:Schedule":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {this.state.tplanSchedule != null ? this.state.tplanSchedule.title : ""}
                                            </div>
                                        </div>);
                                    case "TrainingPlan:Price":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {this.state.tplanSchedule != null ? NumberHelper.getFormattedPrice(this.state.tplanSchedule.price, this.state.tplanSchedule.currency) : ""}
                                            </div>
                                        </div>);
                                    case "TrainingPlan:AvailablePlaces":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {(this.state.tplanSchedule != null && this.state.tplanSchedule.availablePlaces === undefined ?
                                                    (<Translate>TrainingPlan:UnlimitedPlaces</Translate>) :
                                                    (this.state.tplanSchedule != null && this.state.tplanSchedule.availablePlaces != null ? this.state.tplanSchedule.availablePlaces.toString() : ""))}
                                            </div>
                                        </div>);
                                    case "TrainingPlan:RegistrationPeriod":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {this.state.tplanSchedule != null && this.state.tplanSchedule.registrationBegin != null && this.state.tplanSchedule.registrationEnd != null ?
                                                    (StringHelper.dateString(this.state.tplanSchedule.registrationBegin) + ' - '
                                                        + StringHelper.dateString(this.state.tplanSchedule.registrationEnd))
                                                    : ('')}
                                            </div>
                                        </div>);
                                    case "TrainingPlan:ScheduleRegistrationStatus":
                                        return (<div role="row" className="tp__registration-details-row">
                                            <div role="columnheader">
                                                <Translate>{r.label}</Translate>
                                            </div>
                                            <div role="cell">
                                                {
                                                    this.state.trainingPlan != null && this.state.tplanSchedule != null ?
                                                        <ToolbarRegistrationStatus
                                                            item={this.state.trainingPlan}
                                                            classId={this.state.tplanSchedule.sceId}
                                                            textVisible={true}
                                                            classTitle={this.state.tplanSchedule.code}
                                                            hasRegisterRight={this.state.tplanSchedule.hasRegistrationRight}
                                                            canSelfRegister={Session.instance.loginUser != null &&
                                                                Session.instance.loginUser.canSelfRegisterToTP}
                                                            isInRegistrationPeriod={this.state.tplanSchedule.isInRegistrationPeriod}
                                                            checkingDateOfStartCondition={this.state.tplanSchedule.checkingDateOfStartCondition} />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </div>)
                                    case "TrainingPlan:CheckingStartConditionDate":
                                        return (
                                            this.state.trainingPlan != null && this.state.trainingPlan.isLocked
                                                && this.state.tplanSchedule != null && this.state.tplanSchedule.cheatStartcondition && this.state.tplanSchedule.checkingDateOfStartCondition ?
                                                <div role="row" className="tp__registration-details-row">
                                                    <div role="columnheader">
                                                        <Translate>{r.label}</Translate>
                                                    </div>
                                                    <div role="cell">
                                                        {StringHelper.dateString(this.state.tplanSchedule.checkingDateOfStartCondition)}
                                                    </div>
                                                </div> : <></>
                                        );
                                    case "TrainingPlan:ItemLockedReasons":
                                        return (<div role="row" className="tp__registration-details-row">
                                            {
                                                this.state.trainingPlan != null && this.state.tplanSchedule != null ?
                                                    <ItemLockedReasons
                                                        item={this.state.trainingPlan}
                                                        hasRegisterRight={this.state.tplanSchedule.hasRegistrationRight}
                                                        canSelfRegister={Session.instance.loginUser != null &&
                                                            Session.instance.loginUser.canSelfRegisterToTP}
                                                        isInRegistrationPeriod={this.state.tplanSchedule.isInRegistrationPeriod}
                                                    /> : <></>
                                            }
                                        </div>);
                                    default:
                                        return <></>
                                }
                            })}
                        </div>
                    </div>
                </React.Fragment>);
        } else {
            return <ProgressSpinner />;
        }
    }
    protected renderCustomAttributes(scheduleAttributes: Attribute[]): JSX.Element | null {
        const customAttributeConfig = globalConfig.trainingPlanProperties.customAttributesScheduleOnScheduleDetail;
        if (customAttributeConfig != null && customAttributeConfig.length > 0) {
            return <AttributeList
                customAttributeConfig={customAttributeConfig}
                attributes={scheduleAttributes}
                headingCssClass="heading__Level2"
                attributeCssClassName="item-detail"
                parentHeadingLevel={1}
            />
        }
        return null;
    }


    protected displayAutoRegisterClasses(tplanSchedule: TrainingPlanSchedule | null | undefined): boolean {
        const currRegistration = (tplanSchedule != null && tplanSchedule.allRegistrations != null ?
            tplanSchedule.allRegistrations.find(r => r) : null);

        if ((tplanSchedule != null &&
            tplanSchedule.autoRegistrationClassDetails != null &&
            tplanSchedule.autoRegistrationClassDetails.length === 0) ||
            (currRegistration != null
                && currRegistration.registrationStatus !== ERegistrationStatus.Undefined
                && currRegistration.registrationStatus !== ERegistrationStatus.Cancelled
                && currRegistration.registrationStatus !== ERegistrationStatus.NotRegistered) ||
            (tplanSchedule != null && tplanSchedule.autoRegistrationActive === false) ||
            (tplanSchedule != null &&
                tplanSchedule.autoRegistrationClassDetails != null &&
                tplanSchedule.autoRegistrationClassDetails.filter(cls =>
                    (cls.freePlaces >= 0 || (cls.maxParticipants === 0) || (cls.hasWaitingList))).length === 0)) {
            return false;
        }

        return true;
    }
    protected renderF2FAutoRegisterClasses() {
        return (
            this.state.tplanSchedule != null && this.state.tplanSchedule.autoRegistrationActive && this.displayAutoRegisterClasses(this.state.tplanSchedule) ? (
                <React.Fragment>
                    <hr />
                    {this.renderF2FCourses()}
                </React.Fragment>) : ('')
        );
    }

    protected renderF2FCourses(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        if (this.state.tplanSchedule != null && this.state.tplanSchedule.autoRegistrationClassDetails !== undefined && this.state.filteredClasses != null) {
            let filteredClasses = this.state.filteredClasses;

            const distinctCourses = [];
            const map = new Map();
            for (const item of filteredClasses) {
                if (!map.has(item.courseId)) {
                    map.set(item.courseId, true);    // set any value to Map
                    distinctCourses.push({
                        id: item.courseId
                    });
                }
            }

            distinctCourses.map((crs, index) => {
                const classDetail = filteredClasses.find(x => x.courseId === crs.id);
                elements.push(<React.Fragment key={index}>
                    <h3 className="heading__Level3 margin-top">{classDetail === undefined ? '' : classDetail.courseTitle}</h3>
                    <div role="table" className="tp__registration-autoRegisterClasses">
                        <div role="rowgroup">
                            <div role="row" className="tp__registration-autoRegisterClasses-row">
                                <div role="columnheader" className="notMobile">
                                    <Translate>TrainingPlan:F2FClassTitle</Translate>
                                </div>
                                <div role="columnheader" className="notMobile">
                                    <Translate>TrainingPlan:AvailablePlaces</Translate>
                                </div>
                                <div role="columnheader" className="notMobile">
                                    <Translate>TrainingPlan:CourseDuration</Translate>
                                </div>
                                <div role="columnheader" className="notMobile">
                                    <span className="screen-reader-only"><Translate>TrainingPlan:RegisterF2F</Translate></span>
                                </div>
                            </div>
                        </div>
                        <div role="rowgroup">
                            {this.renderF2FClasses(crs.id, filteredClasses)}
                        </div>
                    </div>
                    <hr />
                </React.Fragment>)
            });
        }

        return elements;
    }

    protected renderF2FClasses(courseId: number, filteredClasses: F2FClassDetail[]): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const filteredClassesByCourseId = filteredClasses.filter(cls => cls.courseId === courseId);
        filteredClassesByCourseId.map((cls, index) => {
            let statusElement =
                <input
                    type="radio"
                    key={'chb_' + cls.classId}
                    id={cls.classId.toString()}
                    name={cls.courseId.toString()}
                    defaultChecked={filteredClassesByCourseId.length === 1 && filteredClassesByCourseId[0].isPossibleToRegisterAfterTplanRegistration === false}
                    onChange={(event) => this.f2fClassSelected(event)}
                />

            if (this.state.registeredItemArray != null) {
                const result = this.state.registeredItemArray.find(x => x.classId === cls.classId);
                if (result !== undefined &&
                    (result.registrationStatus === ERegistrationStatus.Accepted ||
                        result.registrationStatus === ERegistrationStatus.InWaitingList ||
                        result.registrationStatus === ERegistrationStatus.Requested)
                ) {
                    statusElement = <Translate>{'RegistrationStatus:' + ERegistrationStatus[result.registrationStatus]}</Translate>
                }
            }

            elements.push(
                <div key={index} role="row" className="tp__registration-autoRegisterClasses-row">
                    <div role="columnheader" className="mobileOnly">
                        <Translate>TrainingPlan:F2FClassTitle</Translate>
                    </div>
                    <div role="cell">
                        {cls.classTitle !== '' ? cls.classTitle : cls.classCode}
                    </div>
                    <div role="columnheader" className="mobileOnly">
                        <Translate>TrainingPlan:AvailablePlaces</Translate>
                    </div>
                    <div role="cell">
                        {cls.maxParticipants === 0 ? (<Translate>TrainingPlan:UnlimitedPlaces</Translate>) : (cls.freePlaces)}
                    </div>
                    <div role="columnheader" className="mobileOnly">
                        <Translate>TrainingPlan:CourseDuration</Translate>
                    </div>
                    <div role="cell">
                        {cls.learningPeriodStart != null && cls.learningPeriodEnd != null ?
                            (StringHelper.dateString(cls.learningPeriodStart) + ' - '
                                + StringHelper.dateString(cls.learningPeriodEnd))
                            : ('')}
                    </div>
                    <div role="cell">
                        {statusElement}
                    </div>
                </div>
            );
        });
        return elements;
    }

    protected f2fClassSelected(event: React.FormEvent<HTMLInputElement>) {
        const courseId = Number(event.currentTarget.name);
        let classIds: number[] = [];
        this.state.filteredClasses?.forEach(f => {
            if (f.courseId == courseId) {
                if (event.currentTarget.checked && f.classId === Number(event.currentTarget.id)) {
                    classIds.push(f.classId);
                }
            }
            else {
                if (this.state.f2fClassIds.includes(f.classId)) {
                    classIds.push(f.classId)
                }
            }
        });

        const buttonDisabledAndErrMsg = this.checkRegistrationOnCourses(this.state.filteredClasses, this.state.f2fClassIdsAlreadyRegistered, classIds);

        this.setState({ f2fClassIds: classIds, registerDisabledRegardingAutoF2FClassDetails: buttonDisabledAndErrMsg[0], errorMessage: buttonDisabledAndErrMsg[1] });
    }

    private checkRegistrationOnCourses(filteredClasses: F2FClassDetail[] | null, f2fClassIdsAlreadyRegistered: number[], f2fClassIds: number[]): [boolean, string] {
        let errorMsg = '';
        let registerButtonDisabled = false;
        //flag isPossibleToRegisterAfterTplanRegistration is on course of tplan class in DB
        //and not on autoRegistrationClassDetails[] of TrainingPlanSchedule.ts
        const courseMap = new Map();
        filteredClasses?.forEach(f => {
            if (!courseMap.has(f.courseId) && f.isPossibleToRegisterAfterTplanRegistration === false) {
                courseMap.set(f.courseId, filteredClasses?.filter(c => c.courseId == f.courseId));
            }
        });
        for (let [key, value] of courseMap) {
            const f2fClassDetails: F2FClassDetail[] = value;
            const f2fClassDetailRegs = f2fClassDetails.filter(f => (f2fClassIdsAlreadyRegistered.includes(f.classId) || f2fClassIds.includes(f.classId)) && f.courseId == key);
            if (!f2fClassDetailRegs == null || f2fClassDetailRegs.length == 0) {
                if (!registerButtonDisabled) //already found one course for a selection
                {
                    registerButtonDisabled = true;
                    errorMsg = Session.instance.storage.translation.GetString("TrainingPlan:ErrorClassOfCourseNotSelected");
                }
                if (registerButtonDisabled) {
                    errorMsg = errorMsg + ' ' + f2fClassDetails.filter(f => f.courseId == key)[0].courseTitle + ', ';
                }
            }
        }
        if (errorMsg.length > 2) {
            errorMsg = errorMsg.substring(0, errorMsg.length - 2);
        }

        return [registerButtonDisabled, errorMsg];
    }

    protected allowRegistration(trainingPlan: TrainingPlan | null, tplanSchedule: TrainingPlanSchedule | null | undefined): boolean {

        if (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfRegisterToTP) {
            return false;
        }
        if (trainingPlan == null ||
            tplanSchedule == null ||
            (trainingPlan.isLocked === true && !tplanSchedule.cheatStartcondition) ||
            (tplanSchedule != null && !tplanSchedule.isRegistrationAllowed) ||
            !tplanSchedule.hasRegistrationRight) {
            return false;
        }
        else if (trainingPlan != null &&
            !trainingPlan.isRegistered) {
            return true
        }
        return false
    }

    protected allowCancellation() {
        if (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfUnregisterFromTP) {
            return false;
        }
        let currentRegistration: Registration[];
        const currentClass = this.state.tplanSchedule;
        // Check if a registration exists
        if (this.state.trainingPlan != null &&
            this.state.trainingPlan.allRegistrations != null &&
            this.state.trainingPlan.allRegistrations.length > 0 &&
            currentClass != null) {
            currentRegistration = this.state.trainingPlan.allRegistrations.filter(r => r.classId === currentClass.sceId);
            // Has registration for this class
            if (currentRegistration.length > 0 &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.Cancelled &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.Rejected &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.ScheduleCancelled &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.NotRegistered) {
                return currentClass.isCancellationAllowed !== ECancellationPermission.NotAllowed;
            }
        }
        return false
    }

    protected getURLParameters(): IURLParamState {
        const methodName = `${this.className}:readURLParameters()`;
        const parameters = new URLSearchParams(window.location.search);

        const urlParamContext = parameters.get('context');
        const urlParamAssingmentId = parameters.get('asId');
        const urlParamCatalogFolerId = parameters.get('catId');
        const urlParamVoucherCode = parameters.get('vouchercode');

        let paramContext: EItemDetailCallerContextType = EItemDetailCallerContextType.Undefined;

        paramContext = urlParamContext != null && !isNaN(Number(urlParamContext)) ?
            Number(urlParamContext) : EItemDetailCallerContextType.Undefined;
        const paramAssingmentId = urlParamAssingmentId != null && !isNaN(Number(urlParamAssingmentId)) ?
            Number(urlParamAssingmentId) : 0;
        const paramCatalogFolerId = urlParamCatalogFolerId != null && !isNaN(Number(urlParamCatalogFolerId)) ?
            Number(urlParamCatalogFolerId) : 0;

        Logger.log(this.loggerLocality, `${methodName} got context: ${paramContext}, assignmentid: ${paramAssingmentId} ` +
            `catalogFolderId: ${paramCatalogFolerId}`);

        return {
            itemDetailCallerContextType: paramContext,
            parentAssignmentId: paramAssingmentId,
            parentCatalogFolderId: paramCatalogFolerId,
            voucherCode: urlParamVoucherCode
        };
    }

    private onRedeemVoucherBtnClicked(): void {
        if (this.state.isScheduleInShoppingBasket) {
            // remove schedule from the shopping basket
            ShoppingBasketStorage.instance.removeItemFromBasket(this.state.trainingPlan!.itemId, this.state.tplanSchedule!.sceId);
            this.setState({ isScheduleInShoppingBasket: false });
        } else {
            // add schedule to the shopping basket
            // format running period
            let runningDates = '';
            if (this.state.tplanSchedule!.runningBegin != undefined && this.state.tplanSchedule!.runningEnd != undefined) {
                runningDates = StringHelper.dateString(this.state.tplanSchedule!.runningBegin) + ' - ' + StringHelper.dateString(this.state.tplanSchedule!.runningEnd);
            }

            const shoppingBasket = new ShoppingBasket();
            shoppingBasket.saveTimeStamp = new Date();
            shoppingBasket.voucherCode = this.state.voucherValidity!.voucherCode;
            shoppingBasket.shoppingBasketItems.push(new ShoppingBasketItem(
                this.state.trainingPlan!.itemId,
                this.state.tplanSchedule!.sceId,
                this.state.trainingPlan!.itemType,
                this.state.trainingPlan!.sId,
                this.state.tplanSchedule!.code,
                this.state.trainingPlan!.title,
                runningDates,
                this.state.tplanSchedule!.price,
                this.state.tplanSchedule!.currency,
                this.state.f2fClassIds, // if TP has F2F courses then the user could select the desirded class for each course (if counfigured)
                []
            ));

            PurchaseService.instance.checkout(new CheckoutRequest(shoppingBasket)).then(response => {
                if (isSuccess<CheckoutResponse>(response)) {
                    // No paylink: either because 100% paid by voucher or because an error occurred.
                    // The awaitCheckoutCompletion page handles both cases.
                    this.props.history.push('/awaitCheckoutCompletion/' + response.referenceCode);
                }
                else {
                    console.error(`PurchaseService: checkout failed with itemid ${this.state.item!.itemId}.`);
                }
            });
        }
    }

    private onShoppingBtnClicked(): void {
        if (this.state.isScheduleInShoppingBasket) {
            // remove schedule from the shopping basket
            ShoppingBasketStorage.instance.removeItemFromBasket(this.state.trainingPlan!.itemId, this.state.tplanSchedule!.sceId);
            this.setState({ isScheduleInShoppingBasket: false });
        } else {
            // add schedule to the shopping basket
            // format running period
            let runningDates = '';
            if (this.state.tplanSchedule!.runningBegin != undefined && this.state.tplanSchedule!.runningEnd != undefined) {
                runningDates = StringHelper.dateString(this.state.tplanSchedule!.runningBegin) + ' - ' + StringHelper.dateString(this.state.tplanSchedule!.runningEnd);
            }
            // add to basket
            if (ShoppingBasketStorage.instance.addItemToBasket(new ShoppingBasketItem(
                this.state.trainingPlan!.itemId,
                this.state.tplanSchedule!.sceId,
                this.state.trainingPlan!.itemType,
                this.state.trainingPlan!.sId,
                this.state.tplanSchedule!.code,
                this.state.trainingPlan!.title,
                runningDates,
                this.state.tplanSchedule!.price,
                this.state.tplanSchedule!.currency,
                this.state.f2fClassIds, // if TP has F2F courses then the user could select the desirded class for each course (if counfigured)
                []
            ))) {
                this.setState({ isScheduleInShoppingBasket: true, showShoppingModal: true });
            }
        }
    }

    private onCancellationClicked() {
        this.setState({ showParticipitions: false });
        this.openModal();
    }

    private onParticipationListClicked(): void {
        this.setState({ showParticipitions: true });
        this.openModal();
    }
}
export default TrainingPlanRegistration;