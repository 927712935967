import React, { useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import PurchaseService from '$src/core/Services/PurchaseService';
import { CheckoutStatusResponse } from '$src/storage/models/Purchase/CheckoutStatus';
import { isSuccess } from '$src/util/Result';
import { ECheckoutEventSeverity, ECheckoutStatus } from '$src/storage/models/enums';
import Logger from '$src/core/Logger';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import { CheckoutEventMessage } from '$src/storage/models/Purchase/CheckoutEventMessage';
import Session from '$core/Session';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import GtError from '$src/util/GtError';

const loggerLocality = 'Components.Purchase';

/**
 * Polls the status of a checkout and shows error messages if an error occurs.
 * If everything succeeds it redirects automatically to the PurchasedItemsList page.
 * */
export default function AwaitCheckoutCompletion() {       
    const { referenceCode } = useParams<{ referenceCode: string }>(); // identifies the checkout
    const [requiresPolling, setRequiresPolling] = useState<boolean>(true);
    const [checkoutStatus, setCheckoutStatus] = useState<ECheckoutStatus | undefined>();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [eventMessages, setEventMessages] = useState<CheckoutEventMessage[] | undefined>();
    const [buttonDestination, setButtonDestination] = useState<string | undefined>();
    const [buttonText, setButtonText] = useState<string | undefined>();
    const [exceptionCount, setExceptionCount] = useState<number>(0);
    const [pollingTimer, setPollingTimer] = useState<NodeJS.Timeout>();
    const history = useHistory();

    /** returns the error message for the given ECheckoutStatus error code. */
    function getErrorMessage(errorType: ECheckoutStatus): string {
        // To allow domain specific strings we read the string key from the configuarion.
        let stringKey: string | undefined;
        switch (errorType) {
            case ECheckoutStatus.VoucherFailed: stringKey = globalConfig.shoppingProperties.voucherFailedErrorMessage; 
                break;
            case ECheckoutStatus.PaymentFailed: stringKey = globalConfig.shoppingProperties.paymentFailedErrorMessage; 
                break;
            case ECheckoutStatus.RegistrationFailed: stringKey = globalConfig.shoppingProperties.registrationFailedErrorMessage; 
                break;
        }
        if (stringKey) {
            return Session.instance.storage.translation.GetString(stringKey).Format(referenceCode);
        } else {
            return '';
        }        
    }

    useEffect(() => {
        // reference code may be null when checkout returns bad request - just security, no usual use case!
        if (!referenceCode || referenceCode == "null" || referenceCode.length <= 0) {
            setErrorMessage(getErrorMessage(ECheckoutStatus.PaymentFailed));
            setButtonDestination('/shoppingBasketContent');
            setButtonText('Purchase:BackToShoppingBasket');
        }
        // do polling
        else if (requiresPolling) {
            setRequiresPolling(false);
            const timer = global.setTimeout(() => {
                PurchaseService.instance.getCheckoutStatus(referenceCode).then(response => {
                    if (isSuccess<CheckoutStatusResponse>(response)) {
                        setCheckoutStatus(response.checkoutStatus);
                        Logger.log(loggerLocality, `${loggerLocality} CheckoutStatus:${response.checkoutStatus}`);
                        switch (response.checkoutStatus) { 
                            case ECheckoutStatus.Pending:
                                // continue polling
                                setRequiresPolling(true);
                                break;
                            case ECheckoutStatus.Succeeded:
                                // user booked himself: continue to the page with the purchased items
                                if (!ShoppingBasketStorage.instance.shoppingBasketContent.shoppingBasketItems.some(p => p.bookUsers && p.bookUsers.length > 0)) {
                                    ShoppingBasketStorage.instance.clear();
                                    history.push('/purchasedItems/' + referenceCode); 
                                    // reload at the new location to clear storage cache (because registration status was changed in the backgound)
                                    window.location.reload();
                                } else {
                                    // admin booked for other users: redirect to admin page
                                    ShoppingBasketStorage.instance.clear();
                                    history.push('/checkoutItemsStatus/' + referenceCode); 
                                }

                                break;
                            case ECheckoutStatus.VoucherFailed:
                                setErrorMessage(getErrorMessage(response.checkoutStatus));
                                setEventMessages(response.eventMessages);
                                setButtonDestination('/shoppingBasketContent');
                                setButtonText('Purchase:BackToShoppingBasket');
                                break;
                            case ECheckoutStatus.PaymentFailed:
                                setErrorMessage(getErrorMessage(response.checkoutStatus));
                                setEventMessages(response.eventMessages);
                                setButtonDestination('/shoppingBasketContent');
                                setButtonText('Purchase:BackToShoppingBasket');
                                break;
                            case ECheckoutStatus.RegistrationFailed:
                                ShoppingBasketStorage.instance.clear(); // because voucher/payment was completed
                                setErrorMessage(getErrorMessage(response.checkoutStatus));
                                setEventMessages(response.eventMessages);
                                setButtonDestination('/purchasedItems/' + referenceCode);
                                setButtonText('Purchase:ToPurchasedItems');
                                break;
                            }
                    } else {
                        // in case of an exception (e.g. manipulated ReferenceCode in URL) we limit polling to avoid filling the servers logfile
                        if (exceptionCount < 2) {
                            setExceptionCount(exceptionCount+1);
                            setRequiresPolling(true);
                        } else {
                            if (response instanceof GtError) {
                                setErrorMessage(response.message);
                            }
                        }
                    }                
                });                
            }, 1000);
            setPollingTimer(timer);
        }
    });

    /// ensure that a pending polling timer is cleared when unloading the component
    useEffect(() => {
        return () => {
            if (pollingTimer !== undefined) {
                clearTimeout(pollingTimer);
            }
        }
    }, [pollingTimer]);


    return (
        <div className="l-container">
            <Heading headingLevel={1} cssClass="heading__Title">
                <Translate>Purchase:AwaitCheckoutCompletion</Translate>
            </Heading>            
            {   checkoutStatus == ECheckoutStatus.Pending &&
                <div>
                    <ProgressSpinner displayMessage={true} 
                        message={Session.instance.storage.translation.GetString('Purchase:PleaseWait')}/>
                </div> 
            }
            {   errorMessage && 
                <Alert alertAppereance="box" alertType="error" message={errorMessage} />
            }  
            {
                eventMessages &&
                <ul>
                    {   eventMessages.map((e, index) => <p key={index}><Alert 
                            alertType={e.severity==ECheckoutEventSeverity.Error ? "error" : 
                                       e.severity==ECheckoutEventSeverity.Warning ? "warning" : "info" }
                            alertAppereance="single-line" 
                            message={e.message} /></p>) }
                </ul>
            }   
            {   buttonDestination &&
                <div className="await-checkout-completion__button-container">
                    <button type="button"
                        className="button--md btn--primary"
                        onClick={() => {
                            history.push(buttonDestination);
                            // reload at the new location to clear storage cache (because registration status might have changed in the backgound)
                            window.location.reload(); 
                        }}> 
                        <Translate>{buttonText}</Translate>
                    </button>
                </div>
            }
        </div>
    )
}
