import { UnmountClosed } from 'react-collapse';
import React, { useEffect, useState } from 'react';

import Session from '$src/core/Session';
import SystemRoles from '$src/core/SystemRoles';

import { Translate } from '$components/shared/Translate';
import {GenericInput} from '$components/shared/GenericInput';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import GTButton from '$components/shared/Atoms/GTButton';
import { CheckBox } from '$components/shared/CheckBox';
import Logger from '$src/core/Logger';

const getTranslation = (str: string) => {
    return Session.instance.storage.translation.GetString(`PaymentBookingOverview:${str}`);
}

interface IPaymentBookingOverviewFilterProps {
    onChange?: (filterAsQuery: URLSearchParams, isAdminView: boolean) => void;
}
interface IPaymentBookingOverviewFilterInputs {
    isAdminView: boolean;
    bookingReference: string;
    paymentReference: string;
    lesson: string;
    purchaser: string;
    purchaseDate: string;
    voucherCampaignCode: string;
}

const EmptyInputs: IPaymentBookingOverviewFilterInputs = {
    bookingReference: '',
    paymentReference: '',
    lesson: '',
    purchaser: '',
    purchaseDate: '',
    isAdminView: false,
    voucherCampaignCode: ''
}

/**
 * Filter control that returns the filter as url query parameters
 * @param props
 * @returns Hook with onChange method
 */
export default function PaymentBookingOverview(props: IPaymentBookingOverviewFilterProps) {
    const hasAdminRole = Session.instance.hasCurrentUserRole(SystemRoles.instance.PaymentBookingOverviewAdmin);
    const [inputs, setInputs] = useState<IPaymentBookingOverviewFilterInputs>(EmptyInputs);
    const [isOpen, setIsOpen] = useState<boolean>(globalConfig.shoppingProperties.isBookingOverviewFilterPanelExpanded);
    const [focusId, setFocusId] = useState<string>();
    const [isAdminView, setIsAdminView] = useState<boolean>(inputs.isAdminView);

    /**
     * Generates url query parameters and calls onChange event
     */
    const setFilter = () => {
        const url: URLSearchParams = new URLSearchParams();
        isAdminView && url.append('isAdminView', isAdminView.toString());
        inputs.bookingReference && url.append('bookingReference', inputs.bookingReference);
        inputs.paymentReference && url.append('paymentReference', inputs.paymentReference);
        inputs.lesson && url.append('lesson', inputs.lesson);
        inputs.purchaser && url.append('purchaser', inputs.purchaser);
        inputs.voucherCampaignCode && url.append('voucher', inputs.voucherCampaignCode);
        if (inputs.purchaseDate) {
            try {
                url.append('purchaseDate', (new Date(inputs.purchaseDate)).toISOString());
            } catch (err) {
                Logger.log('Components.PaymentBookingOverview', err);
            }
        }
        if (props?.onChange) {
            props.onChange(url, isAdminView);
        }
    }

    /** 
     * Auto reload filer when admin checkbox changes 
     */
    useEffect(() => {
        setInputs(EmptyInputs);
        setFilter();
    }, [isAdminView]);

    /**
     * Input controls
     * @returns Hook
     */
    const Filters = () => {
        return (
            <>
                <div role="table" className="div-table__horizontal-table l-box--wide">
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="cell">
                                <GenericInput
                                    id="filterPaymentReference"
                                    type="text"
                                    fieldIndex={1}
                                    label={getTranslation("FilterPaymentReference")}
                                    value={inputs.paymentReference}
                                    editMode={true}
                                    hasFocus={focusId == 'filterPaymentReference'}
                                    onTextChange={(id: string, value: string) => {
                                        setInputs({ ...inputs, paymentReference: value });
                                        if (value != inputs.paymentReference) setFocusId(id);
                                    }}
                                    isReadOnly={false}
                                    isRequired={false}
                                    initialValidation={false}
                                />
                            </div>
                            <div role="cell">
                                <GenericInput
                                    id="filterBookingReference"
                                    type="text"
                                    fieldIndex={2}
                                    label={getTranslation("FilterBookingReference")}
                                    value={inputs.bookingReference}
                                    editMode={true}
                                    hasFocus={focusId == 'filterBookingReference'}
                                    onTextChange={(id: string, value: string) => {
                                        setInputs({ ...inputs, bookingReference: value });
                                        if (value != inputs.bookingReference) setFocusId(id);
                                    }}
                                    isReadOnly={false}
                                    isRequired={false}
                                    initialValidation={false}
                                />
                            </div>
                        </div>
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="cell">
                                <GenericInput
                                    id="filterLesson"
                                    type="text"
                                    fieldIndex={3}
                                    label={getTranslation("FilterLesson")}
                                    value={inputs.lesson}
                                    editMode={true}
                                    hasFocus={focusId == 'filterLesson'}
                                    onTextChange={(id: string, value: string) => {
                                        setInputs({ ...inputs, lesson: value });
                                        if (value != inputs.lesson) setFocusId(id);
                                    }}
                                    isReadOnly={false}
                                    isRequired={false}
                                    initialValidation={false}
                                />
                            </div>
                            <div role="cell">
                                <GenericInput
                                    id="filterPurchaser"
                                    type="text"
                                    fieldIndex={4}
                                    label={getTranslation("FilterPurchaser")}
                                    value={inputs.purchaser}
                                    editMode={true}
                                    hasFocus={focusId == 'filterPurchaser'}
                                    onTextChange={(id: string, value: string) => {
                                        setInputs({ ...inputs, purchaser: value });
                                        if (value != inputs.purchaser) setFocusId(id);
                                    }}
                                    isReadOnly={false}
                                    isRequired={false}
                                    initialValidation={false}
                                />
                            </div>
                        </div>
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="cell">
                                <GenericInput
                                    id="filterPurchaseDate"
                                    type="date"
                                    fieldIndex={5}
                                    label={getTranslation("FilterPurchaseDate")}
                                    value={inputs.purchaseDate}
                                    editMode={true}
                                    hasFocus={focusId == 'filterPurchaseDate'}
                                    onTextChange={(id: string, value: string) => {
                                        setInputs({ ...inputs, purchaseDate: value });
                                        setFocusId('');
                                    }}
                                    isReadOnly={false}
                                    isRequired={false}
                                    initialValidation={false}
                                />
                            </div>
                            <div role="cell">
                                <GenericInput
                                    id="filterVoucher"
                                    type="text"
                                    fieldIndex={6}
                                    label={getTranslation("FilterVoucher")} 
                                    value={inputs.voucherCampaignCode}
                                    editMode={true}
                                    hasFocus={focusId == 'filterVoucher'}
                                    onTextChange={(id: string, value: string) => {
                                        setInputs({ ...inputs, voucherCampaignCode: value });
                                        if (value != inputs.voucherCampaignCode) setFocusId(id);
                                    }}
                                    isReadOnly={false}
                                    isRequired={false}
                                    initialValidation={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {
                hasAdminRole &&
                <div className="l-container payment-booking-overview">
                    <div>
                        <CheckBox id="chkIsAdminView" onChange={(e) => setIsAdminView(e.currentTarget.checked)} value={isAdminView ? 'checked' : ''}>
                            <Translate>PaymentBookingOverview:EnableAdminView</Translate>
                        </CheckBox>
                    </div>
                </div>
            }


            <div className="payment-booking-overview--filter l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass="payment-booking-overview--filter__container--no-margin "
                        headingCssClass="heading__Level2"
                        isOpened={isOpen}
                        onToggleOpenedState={() => setIsOpen(!isOpen)}>
                        <Translate>PaymentBookingOverview:Filter</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={isOpen}>
                        <Filters />
                        <GTButton onClick={() => setFilter()}>
                            <Translate>PaymentBookingOverview:FilterButton</Translate>
                        </GTButton>
                    </UnmountClosed>
                </div>
            </div>
        </>
    )
}