
import { UserCertificateValidated } from '$src/storage/models/Certificates/UserCertificateValidated';
import { ECertificateType, ELessonStatus } from '$src/storage/models/enums';
import { StringHelper } from '$src/util/StringHelper';
import React from 'react';
import { Translate } from '../shared/Translate';
import IconDownload from '$resources/svgs/certificates/download.svg';
import IconVerified from '$resources/svgs/certificates/verified.svg';
import InlineSVG from 'react-inlinesvg';
import TranslationStorage from '$src/storage/TranslationStorage';

interface IValidateUserCertificateDetailProps {
    certificate: UserCertificateValidated;
    translationStorage: TranslationStorage;
}

export const ValidateUserCertificateDetail: React.FC<IValidateUserCertificateDetailProps> = (props: IValidateUserCertificateDetailProps) => {

    return (
        <table>
            <tr>
                <th scope='row'><Translate>ValidateUserCertificate:UserName</Translate></th>
                <td>{props.certificate.username}</td>
                <td className='verification-icon' rowSpan={props.certificate.certificateType !== ECertificateType.Skill ? 8 : 6}><InlineSVG src={IconVerified} /></td>
            </tr>
            <tr>
                <th scope='row'><Translate>ValidateUserCertificate:Name</Translate></th>
                <td>{`${props.certificate.firstName} ${props.certificate.lastName}`}</td>
            </tr>
            <tr>
                <th scope='row'>
                    {props.certificate.certificateType === ECertificateType.Skill ?
                        <Translate>ValidateUserCertificate:SkillID</Translate> :
                        <Translate>ValidateUserCertificate:ItemId</Translate>}
                </th>
                <td>{props.certificate.StringId}</td>
            </tr>
            <tr>
                <th scope='row'>
                    {props.certificate.certificateType === ECertificateType.Skill ?
                        <Translate>ValidateUserCertificate:SkillTitle</Translate> :
                        <Translate>ValidateUserCertificate:ItemTitle</Translate>}
                </th>
                <td>{`${props.certificate.title} ${props.certificate.subTitle}`}</td>
            </tr>
            <tr>
                <th scope='row'><Translate>ValidateUserCertificate:AcquiredDate</Translate></th>
                <td>{StringHelper.dateTimeString(props.certificate.acquiredDate)}</td>
            </tr>
            {props.certificate.certificateType !== ECertificateType.Skill &&
                <>
                    <tr>
                        <th scope='row'><Translate>ValidateUserCertificate:LessonScore</Translate></th>
                        <td>{`${props.certificate.score}%`}</td>
                    </tr>
                    <tr>
                        <th scope='row'><Translate>ValidateUserCertificate:LessonState</Translate></th>
                        <td><Translate>{`LessonStatus:${ELessonStatus[props.certificate.lessonStatus].toString()}`}</Translate></td>
                    </tr>
                </>
            }
            <tr>
                <th scope='row'><Translate>ValidateUserCertificate:CertificateUrl</Translate></th>
                <td>
                    {props.certificate.url != null &&
                        props.certificate.url.length > 0 &&
                        <a href={props.certificate.url} title={props.translationStorage.GetString("ValidateUserCertificate:OpenCertificate")} target="_blank" rel="noopener noreferrer">
                            <InlineSVG src={IconDownload} />
                        </a>
                    }
                    {(props.certificate.url == null || props.certificate.url.length === 0) && 
                    <Translate>ValidateUserCertificate:CertificateFileNotFound</Translate>
                    }
                </td>
            </tr>
        </table>
    )
}