
import { CatalogElement } from '$src/storage/models/Catalog';
import { CatalogPathElement } from '$src/storage/models/CatalogPathElement';
import GtError from '$util/GtError';
import ServiceClient from '../ServiceClient';


/**
 * CatalogService provides all Service Methods for the Catalog 
 */
export default class CatalogService extends ServiceClient {
    protected static _instance: CatalogService | null = null;

    protected className = 'CatalogService';
    protected loggerLocality = 'CatalogService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): CatalogService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get child items of a catalog folder from web API. 
     * @param parentId Id of parent folder.
     */
    public async getCatalogFolder(parentId: number): Promise<CatalogElement | GtError> {
        let response: CatalogElement | GtError;
        if (parentId === 1) {
            parentId = 9999;
        }
        response = await this.get<CatalogElement>('catalogFolder', CatalogElement, undefined, undefined, parentId.toString());
        return response;
    }

    /**
     * Get the catalog path of the given item
     * @param itemId Id of item.
     */
    public async getCatalogFolderPath(itemId: number): Promise<CatalogPathElement[] | GtError> {
        let response: CatalogPathElement[] | GtError;
        response = await this.get<CatalogPathElement[]>('catalogFolder/path', CatalogPathElement, undefined, undefined, itemId.toString());
        return response;
    }

    /**
     * Get the catalog items by SIDs
     * @param catalogFoldersIDs: itemitmid
     */
    public async getCatalogFolderBySIds(catalogFolderSIDs: string[]) {
        let response: CatalogElement | GtError;
        response = await this.post<CatalogElement>('catalogFolder/sids', catalogFolderSIDs, CatalogElement);
        return response;
    }


    /**
     * Returns all CatalogElements (Items) in a given List of catalog folders (SIDS) where the authorized user has the "Learn" ACL
     * @param catalogFoldersIDs: itemitmid
     */
    public async getCatalogFolderContentsBySIds(catalogFolderSIDs: string[]): Promise<CatalogElement | GtError> {
        let response: CatalogElement | GtError;
        response = await this.post<CatalogElement>('catalogFolder/catalogContentsBySids', catalogFolderSIDs, CatalogElement);
        return response;
    }
} 