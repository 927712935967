import PubSubHandler from '$src/util/EventListener/PubSubHandler';

export default class PubSub {

    private _listeners: PubSubHandler[] = [];

    public subscribe(listener: () => void, self: any) {
        if(!this.hasListener(listener, self)) {
            this._listeners.push(new PubSubHandler(listener, self));
        }
    }

    public subscribeOnce(listener: () => void, self: any) {
        if(!this.hasListener(listener, self)) {
            this._listeners.push(new PubSubHandler(listener, self, true));
        }
    }

    public unsubscribe(listener: () => void, self: any) {
        for(let i = 0; i < this._listeners.length; i++) {
            if(this._listeners[i].equals(listener, self)) {
                this._listeners.splice(i, 1);
                break;
            }
        }
    }

    public unsubscribeAll() {
        this._listeners.length = 0;
    }

    public dispatch() {
        this._listeners.forEach(handler => handler.apply());

        this._listeners = this._listeners.filter(
            (handler) => !handler.once() );
    }

    private hasListener(listener: () => void, self: any): boolean {
        // tslint:disable-next-line:prefer-for-of
        for(let i = 0; i < this._listeners.length; i++) {
            if(this._listeners[i].equals(listener, self)) {
                return true;
            }
        }
        return false;
    }


}