
import ServiceClient from '$core/ServiceClient';
import Session from '$core/Session';
import { TimeZoneValue } from '$src/storage/models/TimeZoneValue';
import { AttributeValue } from '$storage/models/AttributeValue';
import GtError from '$util/GtError';

/**
 * AttributeService provides all Service Methods for the Attributes 
 */
export default class AttributeService extends ServiceClient {
    protected static _instance: AttributeService | null = null;

    protected className = 'AttributeService';
    protected loggerLocality = 'AttributeService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): AttributeService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get attribute values of a attribute type from web API.
     * @param typeId Id of attribute type 
     */
    public async getAttributeValues(typeId: number): Promise<AttributeValue[] | GtError> {
        let response: AttributeValue[] | GtError;
        if(Session.instance.jwtToken === '') {
            response = await this.get<AttributeValue[]>('publicAttributeValues', AttributeValue, undefined, undefined, typeId.toString());
        } else {
            response = await this.get<AttributeValue[]>('attributeValues', AttributeValue, undefined, undefined, typeId.toString());
        }
        return response;
    }

        /**
     * Get attribute value by attribute value id.
     * @param attributeValueId Id of attribute value 
     */
         public async getAttributeValue(attributeValueId: number): Promise<AttributeValue | GtError> {
            return await this.get<AttributeValue>('attributeValue', AttributeValue, undefined, undefined, attributeValueId.toString());
        }

    public async getTimeZoneValues(): Promise<TimeZoneValue[] | GtError> {
        return await this.get<TimeZoneValue[]>('timeZoneValues', TimeZoneValue, undefined, undefined);
    }
} 