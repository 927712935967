import { Item } from '$src/storage/models/Item';
import { TrainingPlanCompletitionRequirementStatus } from '$src/storage/models/TrainingPlan/TrainingPlanCompletitionRequirementStatus';
import { JsonObject, JsonProperty } from 'json2typescript';
import { TrainingPlanInputSkill } from './TrainingPlanInputSkill';
import { TrainingPlanOutputSkill } from './TrainingPlanOutputSkill';

@JsonObject
export class TrainingPlan extends Item {

    @JsonProperty('completitionRequirementsStatus', [TrainingPlanCompletitionRequirementStatus], false)
    public completitionRequirementsStatus: TrainingPlanCompletitionRequirementStatus[] | undefined = undefined;

    @JsonProperty('minNumberOfRulesToComplete', Number, false)
    public minNumberOfRulesToComplete: number = 0;

    @JsonProperty('minSumOfScoreToComplete', Number, false)
    public minSumOfScoreToComplete: number = 0;

    @JsonProperty('equivalentDuration', Number, false)
    public equivalentDuration: number = 0;

    @JsonProperty('userInputSkills', [TrainingPlanInputSkill], false)
    public userInputSkills: TrainingPlanInputSkill[] | undefined = undefined;

    @JsonProperty('outputSkills', [TrainingPlanOutputSkill], false)
    public outputSkills: TrainingPlanOutputSkill[] | undefined = undefined;

    /** true is simple register to Tplan with only one class enabled */
    @JsonProperty('isSimpleRegisterToTplanWithOnlyOneClass', Boolean, false)
    public isSimpleRegisterToTplanWithOnlyOneClass: boolean = false;

    /** true => one skill is enough */
    @JsonProperty('isSkillRequiredOrCombination', Boolean, false)
    public isSkillRequiredOrCombination: boolean = false;
    
    public get InputSkillsFulfilled(): boolean {
        if (this.userInputSkills != null) {
            if(this.isSkillRequiredOrCombination)
            {
                if (this.userInputSkills.filter(ele => ele.fulfilled).length > 0) {
                    return true;
                }
            }
            if (this.userInputSkills.filter(ele => !ele.fulfilled).length > 0) {
                return false;
            }
        }
        return true;
    }

    public get isLocked(): boolean {
        return this.isLockedDueToRequiredSkills || this.isLockedDueToAssignments || !this.InputSkillsFulfilled;
    }

    public get isInLearningPhase(): boolean {
        return this.learningPeriodStartDate !== undefined && 
                this.learningPeriodStartDate <= new Date() &&
                this.learningPeriodEndDate !== undefined &&
                this.learningPeriodEndDate >= new Date();
    }

}
