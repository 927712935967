
import { News } from '$src/storage/models/News';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * NewsService provides all Service Methods for the News Feature 
 */
export default class NewsService extends ServiceClient {
    protected static _instance: NewsService | null = null;

    protected className = 'NewsService';
    protected loggerLocality = 'NewsService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): NewsService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Gets all News that are assigned to all groups of the authorized user. 
     * Gets only current language
     */
    public async getNewsByUser(language: string): Promise<News[] | GtError> {
        let response: News[] | GtError;
        response = await this.get<News[]>('news/byUser', News, undefined, [{ name: 'language', value: language }]);
        return response;
    }

    public async getPopupNews(language: string): Promise<News[] | GtError> {
        let response: News[] | GtError;
        response = await this.get<News[]>('news/unseenPopupNews', News, undefined, [{ name: 'language', value: language }]);
        return response;
    }

    public async setViewedPopupNews(newsIDs: number[]) {
        await this.post<void>('news/saveViewedPopups', newsIDs);
    }

} 