import { EFavoriteType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class FavoriteTypeConverter implements JsonCustomConvert<EFavoriteType> {  
    public serialize(lessonStatus: EFavoriteType): number {
        switch (lessonStatus) {         
            case EFavoriteType.Item: return 1;
            case EFavoriteType.CatalogFolder: return 2;
            case EFavoriteType.TPlanElement: return 3;            
            default: return 1;
        }
    }
    public deserialize(jsonValue: number): EFavoriteType {
        switch (jsonValue) {
         
            case 1: return EFavoriteType.Item;
            case 2: return EFavoriteType.CatalogFolder;
            case 3: return EFavoriteType.TPlanElement;         
            default: return EFavoriteType.Item;
        }
    }
}
