import GlossaryService from "$src/core/Services/GlossaryService";
import { GlossaryTermDetail } from "$src/storage/models/Glossary/GlossaryTermDetail";
import { isSuccess } from "$src/util/Result";
import React, { useEffect, useState } from "react";
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from "$components/shared/Translate";
import Carousel from "$components/shared/Carousel";
import Logger from "$src/core/Logger";
import GTButton, { EButtonSize } from "$src/components/shared/Atoms/GTButton";
import { ModalPopup } from "../shared/ModalPopup";
import Session from "$src/core/Session";
import { InputTextArea } from "../shared/InputTextArea";
import { InputAttributes } from "../shared/InputAttributes";
import { GlossaryTermErrorInsertRequest } from "$src/storage/models/RequestObjects/GlossaryTermErrorInsertRequest";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { GlossaryTermImages } from "$src/storage/models/Glossary/GlossaryTermImages";

interface IProps {
    glossarieTermId: number;
}

export default function GlossaryTerm(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [glossarieTermDetail, setGlossarieTermDeteail] = useState<GlossaryTermDetail>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [createErrorMessage, setCreateErrorMessage] = useState<string>("");
    const [createErrorMessageOk, setCreateErrorMessageOk] = useState<boolean>(false);
    const loggerLocality = 'Components.GlossaryTerm';
    const className = 'GlossaryTerm';

    useEffect(() => {
        const methodName = `${className}:fetchData()`;
        if (!isLoaded) {
            GlossaryService.instance.getGlossarieTermDetail(props.glossarieTermId).then(response => {
                if (isSuccess<GlossaryTermDetail>(response)) {
                    setGlossarieTermDeteail(response);
                } else {
                    const errorMessage = `${methodName} failed to get GlossarieTermDetail with id=${props.glossarieTermId}`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
                setIsLoaded(true);
            })
        }
    })

    function convertToJSX() {
        const elements: JSX.Element[] = [];
        glossarieTermDetail?.images.map(image => {
            elements.push(<>
                <img
                    src={image.path}
                    alt={image.caption}
                    className="description-content-image"
                />
                <div className="glossary__term--margin">
                    <Translate>Glossary:ImageCaption</Translate> {image.caption}
                </div>
            </>)
        })
        return elements;
    }

    function showSingleImage(termImage: GlossaryTermImages) {
        return (
            <>
                <img
                    src={termImage.path}
                    alt={termImage.caption}
                    className="description-content-image"
                />
                <div className="glossary__term--margin">
                    <Translate>Glossary:ImageCaption</Translate> {termImage.caption}
                </div>
            </>
        );
    }

    function renderCreateErrorMessage() {
        return (
            <div className="glossary_term--errormessage">
                <GTButton onClick={() => setShowModal(true)} buttonSize={EButtonSize.small} >
                    <Translate>Glossary:CreateErrorMessage</Translate>
                </GTButton>
            </div>
        )
    }

    const courseDescription: InputAttributes =
    {
        attributeValueType: undefined,
        class: '',
        editMode: true,
        hasFocus: false,
        id: 'createErrorMessage',
        initialValidation: false,
        isReadOnly: false,
        isRequired: true,
        label: Session.instance.storage.translation.GetString('Glossary:ModalPopUp'),
        labelClassName: 'heading__Level5',
        regExpression: undefined,
        rows: 8,
        url: undefined,
        value: "",
    };

    function sendErrorMessage() {
        setShowModal(false);
        const request: GlossaryTermErrorInsertRequest = { message: createErrorMessage };
        GlossaryService.instance.createErrorMessage(props.glossarieTermId, request).then(response => {
            if (isSuccess<BooleanResponse>(response) && response.status == true) {
                setCreateErrorMessageOk(true);
            } else {
                setCreateErrorMessageOk(false);
            }
        })
    }

    function errorMessageSet(value: string) {
        setCreateErrorMessage(value);
    }

    function isButtonDisabled() {
        if (createErrorMessage.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    function renderCreateErrorMessageModal() {
        return (
            <ModalPopup isOpen={true} onRequestClose={() => setShowModal(false)}>
                <div>
                    <InputTextArea attributes={courseDescription} onChange={(id: string, value: string) => errorMessageSet(value)} />
                    <div className="modal__spread-buttons">
                        <GTButton onClick={() => sendErrorMessage()} defaultButtonProps={{ disabled: isButtonDisabled() }}
                            ariaLabel={Session.instance.storage.translation.GetString('Glossary:ConfirmErrorMessage')}>
                            <Translate>Glossary:ConfirmErrorMessage</Translate>
                        </GTButton>
                        <GTButton onClick={() => setShowModal(false)}
                            ariaLabel={Session.instance.storage.translation.GetString('Glossary:CancelErrorMessage')}
                            isPrimary={false}>
                            <Translate>Glossary:CancelErrorMessage</Translate>
                        </GTButton>
                    </div>
                </div>
            </ModalPopup>
        )
    }

    function renderMessage() {
        return (
            <div className="glossary__term-alert">
                <Alert alertType={'success'} alertAppereance={'box'} message={Session.instance.storage.translation.GetString('Glossary:CreateErrorMessageSuccessfully')} />
            </div>
        )
    }

    return (
        <>
            {isLoaded ?
                <div className="glossary__container">
                    <div className={`glossary__term-container${(glossarieTermDetail == null || glossarieTermDetail.images.length == 0) ? " no-image" : ""}`}>
                        <div>
                            <div className="glossary__term">
                                <Heading headingLevel={3} cssClass="l-box-container heading__Title3">
                                    <Translate>Glossary:Synonym</Translate>:
                                </Heading>
                                <div className="glossary__term_text--margin">
                                    {glossarieTermDetail?.synonyms}
                                </div>
                            </div>
                            <div className="glossary__term">
                                <Heading headingLevel={3} cssClass="l-box-container heading__Title3">
                                    <Translate>Glossary:Abbreviation</Translate>:
                                </Heading>
                                <div className="glossary__term_text--margin">
                                    {glossarieTermDetail?.abbreviation}
                                </div>
                            </div>

                            <div className="glossary__term--text">
                                <span dangerouslySetInnerHTML={{ __html: glossarieTermDetail!.text }} />
                            </div>
                        </div>
                        {createErrorMessageOk ? renderMessage() : renderCreateErrorMessage()}
                        {showModal && renderCreateErrorMessageModal()}
                    </div>
                    {
                        glossarieTermDetail && glossarieTermDetail.images.length >= 2 ?
                            <div className="glossary__term--carousel">
                                <Carousel
                                    items={convertToJSX()}
                                    autoPlay={false}
                                    autoPlayInterval={0}
                                    itemsForSmallScreen={1}
                                    itemsForMediumScreen={1}
                                    itemsForLargeScreen={1}
                                />
                            </div>
                            : glossarieTermDetail && glossarieTermDetail.images.length == 1 ?
                                <div className="glossary__term--image">{showSingleImage(glossarieTermDetail.images[0])}</div>
                                : null
                    }
                </div> : <ProgressSpinner />}

        </>
    )
}