import Session from "$src/core/Session";
import { EFileType } from "$src/storage/models/enums";
import React, { useState } from "react";
import { Translate } from "$src/components/shared/Translate";
import AttachFile from '$resources/svgs/misc/attach-file.svg';
import Delete from '$resources/svgs/misc/delete.svg';
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import InlineSVG from "react-inlinesvg";
import FileHelper from "$src/util/FileHelper";
import GTButton from "$components/shared/Atoms/GTButton";
import GTIconButton from "$components/shared/Atoms/GTIconButton";
import GTHorizontalTableRow, { GTRowContent } from "$components/shared/Atoms/GTHorizontalTableRow";

/**
 * GT File Props
 */
export interface IGTFileProps {
    /**
     * Unique ID of the GT File Row
     */
    id: string;
    /**
     * The file which is displayed
     */
    file: File;
    /**
     * The default file Type {@see EFileType}
     */
    defaultFileType: EFileType;
    /**
     * The selected file Type for this file {@see EFileType}
     */
    selectedFileType: EFileType;
    /**
     * Callback if the user delete the file
     */
    onDeleteFile: () => void;
    /**
     * Callback if the user change the file type
     */
    onFileTypeChange: (fileType: EFileType) => void;
    /**
     * **Optional** Flag if the type selection should be displayed
     */
    showFileTypeSelection?: boolean;

    /**
     * **Optional** Array of supported file types, if void -> Default File Types will be displayed
     */
    supportedFiletypesWithTranslation?: UploadedFileTypes[];
}

export interface UploadedFileTypes{
    id: EFileType;
    text: string;
}

/**
 * Global Teach File which are rendered in a GTHorizontalTableRow
 */
export default function GTFile(props: IGTFileProps) {
    let uploadedFileTypes: UploadedFileTypes[];
    if (props.supportedFiletypesWithTranslation === undefined) {
        uploadedFileTypes =
            [
                { id: EFileType.Undefined, text: Session.instance.storage.translation.GetString('FileUpload:ChooseFileType') },
                { id: EFileType.Certificate, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeCertificate') },
                { id: EFileType.Confirmation, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeExpenseReport') },
            ]
    }
    else {
        uploadedFileTypes = props.supportedFiletypesWithTranslation;
    }

    const [fileType, setFileType] = useState<number>(props.selectedFileType);

    function onDeleteFile() {
        props.onDeleteFile();
    }

    function onFileTypeChange(event: DropDownListChangeEvent) {
        props.onFileTypeChange(event.target.value.id);
        setFileType(event.target.value.id);
    }

    function renderRowContent() {
        const rowContent: GTRowContent[] = [];
        const ariaLabelOpenDoc = Session.instance.storage.translation.GetString('FileUpload:OpenDocument')
            .Format(props.file.name);

        rowContent.push({
            mobileColumnHeader: Session.instance.storage.translation.GetString('FileUpload:Filename'),
            cell:
                <GTButton
                    isIconButton={true}
                    aria-label={ariaLabelOpenDoc}
                    onClick={() => FileHelper.openFile(props.file)}
                    additionalClassNames="inlineFlex">
                    <>
                        <InlineSVG src={AttachFile} />
                        {props.file.name}
                    </>
                </GTButton>,
            cellId: `file_${props.id}`,
            cellClassName: 'fullsize'
        })

        if (props.showFileTypeSelection) {
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('FileUpload:Filetype'),
                cell:
                    <>
                        <DropDownList
                            data={uploadedFileTypes}
                            textField="text"
                            dataItemKey="id"
                            defaultValue={uploadedFileTypes.filter(t => t.id === props.defaultFileType)}
                            value={uploadedFileTypes.find(type => type.id === fileType)}
                            onChange={(e) => onFileTypeChange(e)} />

                        {fileType === EFileType.Undefined &&
                            <div className="step-content__block dropdown-error">
                                <span className={'input-message error--dark'}>
                                    <Translate>ErrorMessage:MissingFileType</Translate>
                                </span>
                            </div>
                        }
                    </>,
                cellId: `fileType_${props.id}`,
            })
        }

        rowContent.push({
            mobileColumnHeader: Session.instance.storage.translation.GetString('FileUpload:DeleteFile'),
            cell:
                <GTIconButton
                    id={`delete_${props.file.name}`}
                    onClick={() => onDeleteFile()}
                    ariaLabel={Session.instance.storage.translation.GetString('FileUpload:DeleteFileName').Format(props.file.name)}>
                    <InlineSVG src={Delete} />
                </GTIconButton>,
            cellId: `fileDelete_${props.id}`,
            cellClassName: 'autoSize'
        })

        return rowContent;
    }

    return (
        <GTHorizontalTableRow
            rowContent={renderRowContent()} />
    )
}