import React, { useState } from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import InlineSVG from 'react-inlinesvg';

import { StringHelper } from '$src/util/StringHelper';
import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import Session from '$src/core/Session';
import SaveIcon from '$resources/svgs/PaymentBookingJournal/pay_save.svg';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { isSuccess } from '$src/util/Result';

import GTIconButton from '$components/shared/Atoms/GTIconButton';
import { Translate } from '$components/shared/Translate';
import PaymentCheckoutHistory from '$components/PaymentBookingJournal/CheckoutItemHistory';
import { ProgressSpinner } from '../shared/ProgressSpinner';
import { toast } from 'react-toastify';
import { PaymentMyTeamJournal } from '$src/storage/models/Payment/PaymentMyTeamJournal';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`PaymentBookingJournal:${str}`);
}
interface IPaymentJournalResultDetailsProps {
	data: PaymentBookingJournalResponse;
	onUpdated: (newData: PaymentBookingJournalResponse) => void;
	/* Holds the BossRelation Code and other config settings, only used for myTeam */
	myTeamConf?: PaymentMyTeamJournal;
}

/**
 * All data details of a journal row
 * Storing input for comments
 * @param props 
 * @returns 
 */
export default function BookingJournalResultDetails(props: IPaymentJournalResultDetailsProps) {
	const [comments, setComments] = useState<string>(props.data.comments || '');
	const [journal, setJournal] = useState<PaymentBookingJournalResponse>(props.data);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	let adminView = false;
	if (props.myTeamConf === undefined) {
		adminView = true;
	}

	function saveComment() {
		setIsSaving(true);
		PaymentAdminService.instance.saveComment(props.data.id, comments, props.myTeamConf?.bossRelationCode).then((res) => {
			if (isSuccess<PaymentBookingJournalResponse>(res)) {
				props.onUpdated(res);
				setJournal(res);
				toast.success(getTranslation('SaveCommentsOk'), { position: 'bottom-center', autoClose: 2500 });
			} else {
				toast.error(getTranslation('SaveCommentsFailed'), { position: 'bottom-center' });
			}
			setIsSaving(false);
		})
	}

	const rows = [
		{ label: 'BookingDate', text: StringHelper.dateTimeString(journal.bookingDate) },
		{ label: 'Purchaser', text: journal.purchaser },
		{ label: 'PaymentStatus', text: getTranslation(`PaymentStatus${journal.paymentStatusString}`) },
		{ label: 'BookingReference', text: journal.bookingReference },
		{ label: 'PaymentReference', text: journal.paymentReference },
		{ label: 'PaymentType', text: getTranslation(`PaymentType${journal.paymentTypeString}`) },
		{ label: 'PaymentProviderId', text: journal.paymentProviderId || '-' },
		{ label: 'Lesson', text: journal.lesson },
		{ label: 'Class', text: journal.classTitle },
		{ label: 'ClassCode', text: journal.classCode },
		{ label: 'RegistrationStatus', text: getTranslation(`RegistrationStatus${journal.registrationStatusString}`) },
		
		{ label: 'ItemSum', text: `${journal.itemSum} ${journal.currency}` },
		{ label: 'VoucherCodeUser', text: journal.voucherCodeUser || '-' },		
		{ label: 'VoucherDiscountUser', text: journal.voucherDiscountUser ? `${journal.voucherDiscountUser} ${journal.currency}` : '-' },
		{ label: 'VoucherCampaignUser', text: journal.voucherCampaignUser || '-' },
		{ label: 'GroupDiscountUser', text: journal.groupDiscountUser ? `${journal.groupDiscountUser} ${journal.currency}` : '-' },

		{ label: 'BasketPrice', text: `${journal.basketPrice} ${journal.currency}` },
		{ label: 'VoucherCodeBasket', text: journal.voucherCodeBasket || '-' },		
		{ label: 'VoucherCampaignBasket', text: journal.voucherCampaignBasket || '-' },
		{ label: 'VoucherDiscountBasket', text: journal.basketVoucherSum ? `${journal.basketVoucherSum} ${journal.currency}` : '-' },
		{ label: 'GroupDiscountBasket', text: journal.basketGroupDiscountSum ? `${journal.basketGroupDiscountSum} ${journal.currency}` : '-' },
		{ label: 'BasketSum', text: `${journal.basketSum} ${journal.currency}` }
	]
	let i = 0;

	return (
		<>
			<table>
				<tbody>
					{
						rows.map(row => {
							return <tr key={i++}>
								<td><Translate>{`PaymentBookingJournal:${row.label}`}</Translate></td>
								<td>{row.text}</td>
							</tr>
						})
					}
					<tr>
						<td><Translate>PaymentBookingJournal:History</Translate></td>
						<td>
							<PaymentCheckoutHistory bossRelationCode={props.myTeamConf?.bossRelationCode} checkoutId={journal.id} />
						</td>
					</tr>
					{(adminView || props.myTeamConf?.comment) && <tr>
						<td><Translate>PaymentBookingJournal:Comment</Translate></td>
						<td style={{ display: 'flex' }}>
							<TextArea className='input-field' style={{ flexGrow: 1 }} rows={5} value={comments} onChange={(e) => setComments(e.value.toString())} />
							{
								isSaving ? <ProgressSpinner size={18} /> :
									<GTIconButton id='save' ariaLabel='save' onClick={() => saveComment()} defaultButtonProps={{ disabled: journal.comments === comments }}>
										<InlineSVG src={SaveIcon} />
									</GTIconButton>
							}
						</td>
					</tr>}
				</tbody>
			</table>
		</>
	);
}