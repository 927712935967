import { JsonConverter, JsonCustomConvert} from 'json2typescript';

// https://www.npmjs.com/package/json2typescript

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
    public serialize(date: Date): any {
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +  date.getDate();
    }
    public deserialize(date: any): Date {
        return new Date(date);
    }
}