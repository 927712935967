import GamificationService from '$core/Services/GamificationService';
import { GamificationPointHistoryOverview as GamificationPointHistoryOverviewModel } from '$storage/models/Gamification/GamificationPointHistoryOverview';
import { isSuccess } from '$util/Result';
import React, { useEffect, useState } from 'react';
import { Translate } from '$components/shared/Translate';
import Logger from '$src/core/Logger';
import { ErrorMessage } from '../shared/WarningsAndErrors/ErrorMessage';
import { ProgressSpinner } from '../shared/ProgressSpinner';

export const GamificationPointHistoryOverview: React.FC = () => {
    const [historyOverview, setHistoryOverview] = useState<GamificationPointHistoryOverviewModel>();
    const [historyOverviewDataLoading, setHistoryOverviewDataLoading] = useState<boolean>(false);
    const [historyOverviewDataLoaded, setHistoryOverviewDataLoaded] = useState<boolean>(false);
    const [errorLoadingData, setErrorLoadingData] = useState<boolean>(false);

    const loggerLocality = 'GamificationPointHistoryDetail';

    useEffect(() => {
        async function fetchData() {
            setHistoryOverviewDataLoading(true);
            await GamificationService.instance.getGamificationPointHistoryOverview()
                .then((result) => {
                    if (isSuccess<GamificationPointHistoryOverviewModel>(result)) {
                        setHistoryOverview(result);
                        setHistoryOverviewDataLoading(false);
                        setErrorLoadingData(false);
                    }
                    else {
                        setErrorLoadingData(true);
                        Logger.log(loggerLocality, `failed to get data`);
                        console.error(`${loggerLocality} failed to get data`);
                    }
                    setHistoryOverviewDataLoaded(true);
                });
        }
        fetchData();
    }, []);


    return (<>
        <div className='gamification-history__overview'>
            {historyOverview != null && <>
                <div className='gamification-history__overview__total-points'>
                    <div className='title'><Translate>GamificationPointHistory:TotalPoints</Translate></div>
                    <div className='value'>{historyOverview.totalUserPoints}</div>
                </div>
                <div className='gamification-history__overview__level'>
                    <div className='title'><Translate>GamificationPointHistory:Level</Translate></div>
                    <div className='value'>{historyOverview.levelNumber}</div>
                </div>
                <div className={'gamificaiton-history__overview__level-image level--' + historyOverview.levelNumber.toString()}>
                    &nbsp;
                </div>
            </>
            }
        </div>
        {historyOverviewDataLoading && !historyOverviewDataLoaded && <ProgressSpinner />}
        {errorLoadingData && <ErrorMessage appearance='box' errorMessage='Common:ErrorLoadingData' />}
    </>
    )

}
