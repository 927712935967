import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Embed configuration of a Power BI report
 */
@JsonObject
export class PowerBiEmbedConfiguration {
    @JsonProperty('type', String, false)
    public type: string = '';

    @JsonProperty('id', String, false)
    public id: string = '';

    @JsonProperty('embedUrl', String, false)
    public embedUrl: string = '';

    @JsonProperty('accessToken', String, false)
    public accessToken: string = '';

    @JsonProperty('nsuiTitleStringId', String, false)
    public nsuiTitleStringId: string = '';

    @JsonProperty('nsuiDescriptionStringId', String, false)
    public nsuiDescriptionStringId: string = '';

    @JsonProperty('loadingFailed', Boolean, false)
    public loadingFailed: boolean = false;
}