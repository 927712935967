// Module Imports
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

// Icon Imports
import Iconwarning from '$resources/svgs/status/warning.svg';

// Registration Status
import Iconcancelled from '$resources/svgs/status/cancelled.svg';
import Iconaccepted from '$resources/svgs/status/confirmed.svg';
import IconnotRegistered from '$resources/svgs/status/not-registered.svg';
import Iconrejected from '$resources/svgs/status/rejected.svg';
import Iconrequested from '$resources/svgs/status/requested.svg';
import IconwaitingList from '$resources/svgs/status/waiting-list.svg';

// Lesson Status
import Iconcompleted from '$resources/svgs/status/complete.svg';
import Iconlocked from '$resources/svgs/status/locked.svg';
import Iconfailed from '$resources/svgs/status/not-passed.svg';
import Iconpassed from '$resources/svgs/status/passed.svg';
import Iconincompete from '$resources/svgs/status/started.svg';

// Self developed imports
import Session from '$src/core/Session';
import { EItemType, ELessonStatus, ERegistrationStatus } from '$src/storage/models/enums';
import { Item } from '$storage/models/Item';
import { StringHelper } from '$src/util/StringHelper';
import { ItemHelper } from '$src/util/ItemHelper';
import IconAndText from '$src/components/shared/Atoms/IconAndText';

interface IProps {
    item: Item;
    id: number;
    textVisible: boolean;
    isLocked?: boolean;
    reason?: string;
    reasonDetail?: string;
    renderAsIconAndText?: boolean; // null, undefined, false for legacy, true for icon and text
}

export class ToolbarStatus extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        const item = this.props.item;
        let icon = '';
        let text: string = '';        
        let hoverText ='';
        let hoverLockedReason = '';
        if(item.itemType===EItemType.TrainingPlan){
            hoverText = tr.GetString('ItemDetail:LessonTPlanStatus');
        } else{
            hoverText = tr.GetString('ItemDetail:LessonStatus');
        }
        if (this.props.isLocked && this.props.item.isRegistered === false && !this.props.item.isIgnoreStartCondition) {
            icon = Iconlocked;
            text = tr.GetString('LessonStatus:Locked');
            const startLockedReasonText = ' ' + tr.GetString('LessonStatus:LockedReason') + ':\r\n';
            if (item.isLockedDueToRequiredSkills) {
                hoverLockedReason += startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonRequiredSkillsMissing') + '\r\n';
            }
            if (item.isLockedDueToTrainingPlan) {
                hoverLockedReason += startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonTrainingPlanLocked') + '\r\n';
            }
            if (item.isLockedDueToAssignments) {
                hoverLockedReason += startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonAssignmentNotInLearningPeriod') + '\r\n';
            }
            if (item.lessonLockedByAttemptsUntil != null) {
                hoverLockedReason += startLockedReasonText + '- ' + ItemHelper.isLockedDueToFailedAttemptsText(item.lessonLockedByAttemptsUntil);
            }
        } else {                   
            if (((item.itemType === EItemType.F2FCourse || item.itemType === EItemType.TrainingPlan )&&
                (item.lessonStatus === ELessonStatus.Undefined || item.lessonStatus === ELessonStatus.NotAttempted))
                || (item.registrationRequiredEnabled &&
                    (item.lessonStatus === ELessonStatus.Undefined || item.lessonStatus === ELessonStatus.NotAttempted))
            ) {
                switch (item.registrationStatus) {
                    case ERegistrationStatus.Requested:
                        icon = Iconrequested;
                        text = tr.GetString('RegistrationStatus:Requested');
                        break;
                    case ERegistrationStatus.Accepted:
                        icon = Iconaccepted;
                        text = tr.GetString('RegistrationStatus:Accepted');
                        break;
                    case ERegistrationStatus.Rejected:
                        icon = Iconrejected;
                        text = tr.GetString('RegistrationStatus:Rejected');
                        break;
                    case ERegistrationStatus.Cancelled:
                        icon = Iconcancelled;
                        text = tr.GetString('RegistrationStatus:Cancelled');
                        break;
                    case ERegistrationStatus.ScheduleCancelled:
                        icon = Iconcancelled;
                        text = tr.GetString('RegistrationStatus:ScheduleCancelled');
                        break;
                    case ERegistrationStatus.InWaitingList:
                        icon = IconwaitingList;
                        text = tr.GetString('RegistrationStatus:InWaitingList');
                        break;
                    default:
                        icon = IconnotRegistered;
                        text = tr.GetString('RegistrationStatus:NotRegistered');
                        break;
                }
            }
             else {
                switch (item.lessonStatus) {

                    case ELessonStatus.Incomplete:
                        icon = Iconincompete;
                        text = tr.GetString('LessonStatus:Incomplete');
                        break;
                    case ELessonStatus.Completed:
                        icon = Iconcompleted;
                        text = tr.GetString('LessonStatus:Completed');
                        break;
                    case ELessonStatus.Failed:
                        icon = Iconfailed;
                        text = tr.GetString('LessonStatus:Failed');
                        break;
                    case ELessonStatus.Passed:
                        icon = Iconpassed;
                        text = tr.GetString('LessonStatus:Passed');
                        break;
                    default: 
                        null;
                }
            }

            // if ignore Start Condition, add hover text and set warning icon
            if(!item.isRegistered && item.isIgnoreStartCondition && item.isLockedDueToRequiredSkills) {
                icon = Iconwarning;
                hoverLockedReason += `\r\n ${tr.GetString('LessonStatus:IgnoreStartCondition')}`;
            }
        }
        if (this.props.item.itemType === EItemType.Document && (this.props.isLocked == null || this.props.isLocked === false )) {
            return <div className="l-box__toolbar__icon lesson-tools lesson-tools--status" />;
        } else {
            hoverText = hoverText + ' ' + this.props.item.title +': ' + text
            if(this.props.reason != null){
                let reason = tr.GetString(this.props.reason);
                if (this.props.reasonDetail != null) {
                    reason = reason.Format(this.props.reasonDetail).Format(tr.GetString("TrainingPlan:PrerequesiteMinSumScore"));
                } else {
                    reason = reason.Format("-");
                }
                hoverText = hoverText + '. ' + reason;
            }

            if (hoverLockedReason) {
                hoverText += hoverLockedReason;
                hoverText = StringHelper.htmlFormat(hoverText);
            }

            return <>
                    {!this.props.renderAsIconAndText && <><div data-tip={true} 
                        data-for={`${this.props.id}_toolbarStatus_hoverText`} 
                        aria-label={hoverText} 
                        className="l-box__toolbar__icon lesson-tools lesson-tools--status">
                        <InlineSVG src={icon} />
                        {this.props.textVisible ? (text) : null}
                    </div>
                    <Tooltip id={`${this.props.id}_toolbarStatus_hoverText`} html={true}>
                        {hoverText}
                    </Tooltip></> }
                    {this.props.renderAsIconAndText && 
                            <IconAndText
                            id={"toolbarstatus_" + this.props.id}
                            icon={icon}
                            ariaLabel={hoverText}
                            tooltipText={hoverText}
                            iconText={this.props.textVisible && text || undefined}
                            cssClass="status"
                        /> }
                </>
        }
    }
}
