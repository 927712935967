import InfoIcon from '$resources/svgs/misc/info.svg';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';

import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';

import { ExternalCourseRequestData } from '$components/externalCourse/ExternalCourseRequestData';
import { ExternalCourseScheduleTable } from '$components/externalCourse/ExternalCourseScheduleTable';
import { RequestActionConfirmation } from '$components/ExternalCourse/RequestActionConfirmation';
import { ItemRegistrationActionConfirmation } from '$components/item/ItemRegistrationActionConfirmation';
import { BossSelection } from '$components/shared/BossSelection';
import { Heading } from '$components/shared/Heading';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputTextArea } from '$components/shared/InputTextArea';
import { ModalPopup } from '$components/shared/ModalPopup';
import { Translate } from '$components/shared/Translate';
import ExternalCourseService from '$core/Services/ExternalCourseService';
import RegistrationService from '$core/Services/RegistrationService';
import Session from '$core/Session';
import { isSuccess } from '$src/util/Result';
import { ECancellationType, EFileType, ELessonStatus, ERegistrationStatus } from '$storage/models/enums';
import { ExternalCourse } from '$storage/models/ExternalCourse/ExternalCourse';
import { ExternalCourseProvider } from '$storage/models/ExternalCourse/ExternalCourseProvider';
import { ExternalCourseRegistration } from '$storage/models/ExternalCourse/ExternalCourseRegistration';
import { ExternalCourseSchedule } from '$storage/models/ExternalCourse/ExternalCourseSchedule';
import { NumberResponse } from '$storage/models/NumberRepsonse';
import { ExternalCourseRequest } from '$storage/models/RequestObjects/ExternalCourse/ExternalCourseRequest';
import { FileDataRequest } from '$storage/models/RequestObjects/FileDataRequest';
import CustomErrorMessage from '$util/CustomErrorMessage';
import GtError from '$util/GtError';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';
import GTFileUpload from '$src/components/shared/Molecules/GTFileUpload';


interface IProps extends RouteComponentProps<{}> {
    parentHeadingLevel: number;
    selectedCourseProvider: ExternalCourseProvider | undefined;
    selectedCourse: ExternalCourse | undefined;
    selectedSchedule: ExternalCourseSchedule | undefined;
    externalCourseRegistration: ExternalCourseRegistration | undefined;
    isNew: boolean;
    uploadedRequestFiles: File[];
    uploadedCourseFiles: FileDataRequest[];
    requestReason: string;
    showRequestedInfo: boolean;
    onStepClicked?: (stepNumber: number) => void;
    onStepRequestSend?: (requestId: number) => void;
    onStepLeave?: (requestComment: string, uploadedFiles: File[]) => void;
}

interface IState {
    applicantRequestCollapsed: boolean;
    bossCommentCollapsed: boolean;
    requestReason: string;
    uploadedFiles: File[];
    fileDataRequest: FileDataRequest[];
    errorMessage: string;
    uploadErrorMessage: string;
    shouldShowModal: boolean;
    showRequestedInfo: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class StepRequest extends React.Component<IProps, IState> {
    protected scheduleStepNumber = 3; // step number of schedule step defined in RequestWizard.tsx
    protected registrationStepNumber = 5; // step number of registration step defined in RequestWizard.tsx

    constructor(props: IProps) {
        super(props);

        this.state = {
            applicantRequestCollapsed: this.props.externalCourseRegistration ? this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted : false,
            bossCommentCollapsed: this.props.externalCourseRegistration ? this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted : false,
            errorMessage: '',
            fileDataRequest: [],
            requestReason: this.props.requestReason,
            shouldShowModal: this.props.showRequestedInfo,
            showRequestedInfo: this.props.showRequestedInfo,
            uploadedFiles: [],
            uploadErrorMessage: ''
        }
    }

    public componentWillUnmount() {
        if (this.props.onStepLeave) {
            this.props.onStepLeave(this.state.requestReason, this.state.uploadedFiles);
        }
    }

    public render() {
        if (this.props.selectedCourseProvider && this.props.selectedCourse && this.props.selectedSchedule) {
            const ariaLabelNextStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToRegistration')
            const ariaLabelPreviousStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToSchedule')
            const requestAccepted = this.props.externalCourseRegistration && this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted;
            return (
                <div className="step-content">
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:CourseProvider</Translate>
                        </Heading>
                        <span>{this.props.selectedCourseProvider.name}</span>
                    </div>
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:Course</Translate>
                        </Heading>
                        <span>{this.props.selectedCourse.title}</span>
                    </div>
                    <div className="step-content__block">
                        {this.renderScheduleDetails()}
                    </div>
                    {!this.props.externalCourseRegistration &&
                        this.renderEditForm()}

                    {(this.props.externalCourseRegistration && this.props.externalCourseRegistration.registrationStatus !== ERegistrationStatus.Undefined) &&
                        this.renderReadonly()}

                    <div className="step-navigation">
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.goToSchedule()}
                            aria-label={ariaLabelPreviousStep}>
                            <div className="inlineFlex">
                                <InlineSVG src={ArrowLeft} />
                                <span className="notMobile"><Translate>ExternalCourse:Schedule</Translate></span>
                            </div>
                        </button>
                        <span><Translate>ExternalCourse:Request</Translate></span>
                        <button className="button-link button-link--colorized-dark"
                                onClick={() => this.goToRegistration()}
                                aria-label={ariaLabelNextStep}
                                disabled={!requestAccepted}>
                                {
                                    requestAccepted ?
                                        <div className="inlineFlex">
                                            <span className="notMobile"><Translate>ExternalCourse:Registration</Translate></span>
                                            <InlineSVG src={ArrowRight} />
                                        </div>
                                    :
                                        <div className="inlineFlex">
                                            <span className="notMobile external-courses__link__disabled"><Translate>ExternalCourse:Registration</Translate></span>
                                        </div>
                                }
                        </button>
                    </div>
                </div>
            )
        } else {
            return null;
        }

    }

    private renderScheduleDetails(): JSX.Element | null {
        if (this.props.selectedSchedule) {
            return (
                <ExternalCourseScheduleTable
                    externalCourseSchedule={this.props.selectedSchedule}
                    showRequestStatus={this.props.externalCourseRegistration ? true : false}
                    externalCourseRequestStatus={this.props.externalCourseRegistration ? this.props.externalCourseRegistration.registrationStatus : ERegistrationStatus.Undefined}
                />
            )
        } else {
            return null;
        }
    }

    private renderEditForm(): JSX.Element {
        const commentAttributes: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: true,
            id: 'requestReason',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: 'ExternalCourse:RequestReasonLabel',
            labelClassName: 'heading__Level5',
            placeholder: Session.instance.storage.translation.GetString('ExternalCourse:RequestReasonPlaceholder'),
            regExpression: undefined,
            rows: 5,
            url: undefined,
            value: this.state.requestReason,
        };
        const sendRequestDisabled = this.state.errorMessage.length > 0;
        return (
            <React.Fragment>
                <div className="step-content__block">
                    <InputTextArea attributes={commentAttributes} onChange={(id, value) => this.onInputChanged(id, value)} />
                </div>
                <div className="step-content__block">
                    <GTFileUpload
                        parentHeadingLevel={this.props.parentHeadingLevel}
                        label={Session.instance.storage.translation.GetString("ExternalCourse:RequestDocuments")}
                        allowedFileExtensions={globalConfig.externalCourse.requestFileUpload.acceptFilter}
                        multipleUploadAllowed={true}
                        maxFileSizeInByte={globalConfig.externalCourse.requestFileUpload.maxFileSize}
                        onUploadFiles={(files) => this.onUploadChange(files)}
                        defaultFileType={EFileType.Undefined}
                        allowDuplicateFileNames={false}
                        duplicateErrorMessage='ExternalCourse:DocumentAlreadyUploaded'
                        initialFiles={this.props.uploadedRequestFiles}
                    />
                    <span className={'input-message error'}>
                        <Translate>{this.state.uploadErrorMessage}</Translate>
                    </span>
                </div>
                {globalConfig.bossSelection.selectionIsVisible_ExternalCourse &&
                    <div className="step-content__block">
                        <BossSelection
                            isReadOnly={true}
                            isConfigReadOnly={globalConfig.bossSelection.bossSelectionIsReadOnly_ExternalCourse}
                            parentHeadingLevel={this.props.parentHeadingLevel + 1} />
                    </div>}

                <div className="step-content__block inlineFlex">
                    <InlineSVG src={InfoIcon} />
                    <span>
                        <Translate>ExternalCourse:HintDataNotSaved</Translate>
                    </span>
                </div>
                <div className="step-content__block center">
                    <button
                        onClick={() => this.onSendRequest()}
                        className="btn--sm btn--primary"
                        disabled={sendRequestDisabled}
                        id="btnSendRequest">
                        <Translate>ExternalCourse:SendRequest</Translate>
                    </button>
                </div>

                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={() => this.closeModal()}>
                    <div className="modal__spread buttons">
                        <RequestActionConfirmation
                            courseTitle={this.props.selectedCourse ? this.props.selectedCourse.title : ''}
                            parentHeadingLevel={this.props.parentHeadingLevel + 1}
                            onExitClicked={() => this.closeModal()}
                            onRequestConfirmedClick={(selectedBoss) => this.onRequestConfirmed(selectedBoss)}
                        />
                    </div>
                </ModalPopup>

                {this.state.errorMessage.length > 0 &&
                    <div className="step-content__block center">
                        <span className={'input-message error--dark'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                }
                <div className="step-content__block center">
                    <p>
                        <Translate>ExternalCourse:RequestHintMessage</Translate>
                    </p>
                </div>
            </React.Fragment>
        )
    }

    private renderReadonly(): JSX.Element {
        if (this.props.externalCourseRegistration) {
            const completed = this.props.externalCourseRegistration.lessonStatus === ELessonStatus.Completed;
            return (
                <React.Fragment>
                    <ExternalCourseRequestData
                        externalCourseRegistration={this.props.externalCourseRegistration}
                        parentHeadingLevel={this.props.parentHeadingLevel} />
                    {this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted && !completed &&
                        <div className="step-content__block">
                            <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                <Translate>ExternalCourse:CancelRequestHint</Translate>
                            </Heading>
                        </div>
                    }
                    {!completed &&
                        <React.Fragment>
                            <div className="step-content__block center">
                                <button
                                    onClick={(event) => this.onCancelRequestClicked(event)}
                                    className="btn--sm btn--primary"
                                    disabled={this.state.errorMessage.length > 0}>
                                    <Translate>{this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Requested ?
                                        'ExternalCourse:RetractRequest' :
                                        'ExternalCourse:CancelRequest'}
                                    </Translate>
                                </button>
                            </div>

                            <ModalPopup
                                isOpen={this.state.shouldShowModal}
                                onRequestClose={() => this.closeModal()}>
                                {this.state.showRequestedInfo ?
                                    <div>
                                        <Heading cssClass="heading__Level2" headingLevel={this.props.parentHeadingLevel + 1}>
                                            <Translate>ExternalCourse:RequestedHintTitle</Translate>
                                        </Heading>
                                        <div><Translate>ExternalCourse:RequestedHint</Translate></div>
                                        <div className="modal__single-button">
                                            <button type="button"
                                                aria-label={Session.instance.storage.translation.GetString('Navigation:MyCourses')}
                                                className="btn btn--sm"
                                                onClick={() => this.onButtonClickToMyCourses()}>
                                                <Translate>Navigation:MyCourses</Translate>
                                            </button>
                                        </div>
                                    </div> :
                                    <div className="modal__spread buttons">
                                        <ItemRegistrationActionConfirmation
                                            classTitle={this.props.externalCourseRegistration.externalCourse !== undefined ?
                                                this.props.externalCourseRegistration.externalCourse.title : ''}
                                            parentHeadingLevel={this.props.parentHeadingLevel + 1}
                                            register={false}
                                            onExitClicked={() => this.closeModal()}
                                            onCancelRegistrationConfirmedClick={(cancellationReasonId, cancellationReasonText) => this.onCancelRegistrationConfirmed(cancellationReasonId, cancellationReasonText)}
                                            cancellationReasonType={ECancellationType.Enrolment}
                                            itemID={this.props.externalCourseRegistration.itemId} />
                                    </div>}
                            </ModalPopup>
                        </React.Fragment>
                    }
                </React.Fragment>
            )
        } else {
            return <div />
        }
    }

    private onInputChanged(id: string, value: string) {
        this.setState({ requestReason: value });
    }

    /**
     * Callback for InputFile component: input has changed
     * Returns a list of files
     * @param files Selected files in input file
     */
     private onUploadChange(files: FileDataRequest[]) {
        const tmpFiles: File[] = new Array<File>();
        files.map(f => {
            if (f.file) {
                tmpFiles.push(f.file)
            }
        });
        this.setState({ uploadedFiles: tmpFiles, fileDataRequest: files });
    }

    private goToRegistration() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.registrationStepNumber);
        }
    }

    private goToSchedule() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.scheduleStepNumber);
        }
    }

    private async onCancelRegistrationConfirmed(cancellationReasonId: number | undefined, cancellationReasonText: string) {
        let onRegister: BooleanResponse | GtError;
        if (this.props.externalCourseRegistration && this.props.externalCourseRegistration.registrationId > 0) {
            onRegister = await RegistrationService.instance.cancelRegistration(this.props.externalCourseRegistration.registrationId,
                cancellationReasonId != null ?
                    cancellationReasonId : 0,
                cancellationReasonText);

            if (isSuccess<BooleanResponse>(onRegister)) {
                this.props.history.push('/myCourses');
            } else {
                this.setState({ errorMessage: 'ErrorMessage:CancelRegistrationFailed' })
                this.closeModal();
            }
        }
    }


    private onCancelRequestClicked(event: React.FormEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.openModal();
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false, showRequestedInfo: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private onSendRequest() {
        this.openModal();
    }

    private onButtonClickToMyCourses() {
        this.props.history.push('/myCourses');
    }

    private async onRequestConfirmed(selectedBoss: BossSearchResult | undefined) {
        if (this.props.selectedCourseProvider && this.props.selectedCourse && this.props.selectedSchedule) {
            const request = new ExternalCourseRequest();
            request.provider = this.props.selectedCourseProvider;
            request.course = this.props.selectedCourse;
            request.schedule = this.props.selectedSchedule;
            request.fileCollection = this.state.fileDataRequest;
            request.requestReason = this.state.requestReason;
            request.createdCourseDocuments = this.props.uploadedCourseFiles;
            if (selectedBoss) {
                request.selectedBossId = selectedBoss.id;
            }
            const response = await ExternalCourseService.instance.sendExternalCourseRequest(request);
            if (isSuccess<NumberResponse>(response)) {
                if (this.props.onStepRequestSend && response.value > 0) {
                    this.props.onStepRequestSend(response.value);
                }
            } else {
                if (response.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode), shouldShowModal: false })
                } else {
                    this.setState({ errorMessage: 'ErrorMessage:SendRequestFailed', shouldShowModal: false });
                }
            }
        } else {
            this.setState({ errorMessage: 'ExternalCourse:MissingData', shouldShowModal: false });
        }
    }
}