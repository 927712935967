import { CollapsibleButton } from '$src/components/shared/CollapsibleButton';
import { TodoItemHeader } from '$src/components/TodoList/TodoItemHeader';
import { WorkflowActivityResponse } from '$src/components/TodoList/WorkflowActivityResponse';
import { WorkflowActivity } from '$src/storage/models/Workflow/WorkflowActivity';
import React from 'react';

interface IProps {
    workflowActivity: WorkflowActivity;
    onReload: () => void;
    onUpdateInProgress: () => void;

}

interface IState {
    errorMessage: string;
    selectedOption: string;
    collapseIsOpen: boolean;
}

export class TodoItem extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = { errorMessage: '', selectedOption: this.props.workflowActivity.validResponses[0].toString(), collapseIsOpen: false }

        this.onReload = this.onReload.bind(this)
        this.onUpdateInProgress = this.onUpdateInProgress.bind(this)

    }
    public render() {
        const item = this.props.workflowActivity;
        const content = <WorkflowActivityResponse onUpdateInProgress={this.onUpdateInProgress} onReload={this.onReload} workflowActivity={item} />
        const header = <TodoItemHeader collapseIsOpen={this.state.collapseIsOpen} workflowActivity={item} headingLevel={1} />
        return (
            <li className="l-box--flex todo-content-center">
                <div className="todo-box-shadow">
                    <CollapsibleButton header={header} content={content} mykey={'CollapsibleButton_' + item.id} />
                </div>
            </li>
        );
    }

    protected onReload() {
        this.props.onReload();
    }

    protected onUpdateInProgress() {
        this.props.onUpdateInProgress();
    }

}