import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ErrorLogEntry {

    @JsonProperty('concernedItem', String, false)
    public concernedItem: string = '';

    @JsonProperty('message', String, false)
    public message: string = '';

    @JsonProperty('lastname', String, false)
    public lastname: string = '';

    @JsonProperty('firstname', String, false)
    public firstname: string = '';

    @JsonProperty('lection', String, false)
    public lection: string = '';
}
