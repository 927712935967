import IconskillExpired from '$resources/svgs/skills/skill-expired.svg';
import IconskillMet from '$resources/svgs/skills/skill-met.svg';
import IconskillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import Session from '$core/Session';
import { ItemInputSkill } from '$storage/models/ItemDetails/ItemInputSkill';
import { ItemSkills } from '$storage/models/ItemDetails/ItemSkills';
import React, { useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { Translate } from '$components/shared/Translate';
import { Tooltip } from '$components/shared/Tooltip';
import IconRecommendations from '$resources/svgs/skills/recommendations.svg';
import IconRecommendationsMissing from '$resources/svgs/skills/recommendationsMissing.svg';
import { NavLink } from 'react-router-dom';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

interface IProps {
    itemSkills: ItemSkills | null;
    itemIdParam: number;
    catIdParam: number;
    tpIdParam: number;
    asIdParam: number;
    tpeIdParam: number;
}

export default function ItemInputSkills(props: IProps) {
    const [itemSkills] = useState<ItemSkills | null>(props.itemSkills);
    const [catId] = useState<number>(props.catIdParam);
    const [tpId] = useState<number>(props.tpIdParam);
    const [tpeId] = useState<number>(props.tpeIdParam);
    const [asId] = useState<number>(props.asIdParam);
    const [itemId] = useState<number>(props.itemIdParam);




    function renderRecommendedLessons(uisk: ItemInputSkill) {
        let recommendedLessonsText;
        let recommendedLessonsMissingText;
        if (uisk.inputSkill != undefined) {
            recommendedLessonsText = Session.instance.storage.translation.GetString('TrainingPlan:RecommendedLessons').Format(uisk.inputSkill.skillTitle);
            recommendedLessonsMissingText = Session.instance.storage.translation.GetString('TrainingPlan:NoRecommendedLessonsFound').Format(uisk.inputSkill.skillTitle);
        }

        return (<>
            {uisk.inputSkill != undefined ?
                uisk.inputSkill.skillNumberOfRecommendaedLessons > 0 ?
                    <div className="badge-parent">
                        <NavLink
                            to={`/trainings/${uisk.inputSkill.skillId}/${uisk.userId}/${catId}/${itemId}/${tpId}/${asId}/${tpeId}?context=${EItemDetailCallerContextType.ItemDetail}`}
                            aria-label={recommendedLessonsText}
                            data-tip={recommendedLessonsText}
                            data-for={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} >
                            <InlineSVG src={IconRecommendations} />
                            <div className="badge__filled" >{uisk.inputSkill.skillNumberOfRecommendaedLessons}</div>
                            <Tooltip id={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} />
                        </NavLink>
                    </div> :
                    <div className="badge-parent"
                        aria-label={recommendedLessonsMissingText}
                        data-tip={recommendedLessonsMissingText}
                        data-for={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} >
                        <InlineSVG src={IconRecommendationsMissing} />
                        <div className="badge__outlined" >{uisk.inputSkill.skillNumberOfRecommendaedLessons}</div>
                        <Tooltip id={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} />
                    </div> : null}

        </>
        )
    }

    function renderPrerequisiteIcons(uisk: ItemInputSkill): JSX.Element {
        let iconName = '';
        if (uisk.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            iconName = IconskillMet;
        }
        else if (uisk.isExpired) {
            iconName = IconskillExpired;
        }
        else {
            iconName = IconskillNotMet;
        }

        return <InlineSVG src={iconName} />
    }

    function renderPrerequisiteTitles(uisk: ItemInputSkill): string {
        const tr = Session.instance.storage.translation;
        let title = '';
        if (uisk.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            title = tr.GetString('TrainingPlan:FulfilledYes');
        }
        else if (uisk.isExpired) {
            title = tr.GetString('TrainingPlan:SkillExpired');
        }
        else {
            title = tr.GetString('TrainingPlan:FulfilledNo');
        }

        return title;
    }


    function renderItems(): JSX.Element[] | JSX.Element {
        if (itemSkills != null && itemSkills.userInputSkills != null && itemSkills.userInputSkills.length > 0) {
            const tr = Session.instance.storage.translation;
            const elements: JSX.Element[] = [];
            itemSkills.userInputSkills.map(
                (uisk, index) => elements.push(
                    <tr key={index}>
                        <td>{uisk.inputSkill != null ? uisk.inputSkill.skillTitle : '??'}</td>
                        <td colSpan={2} >{uisk.inputSkill != null ? uisk.inputSkill.skillLevelTitle : ''}</td>
                        <td
                            data-tip={tr.GetString('TrainingPlan:Fulfilled') + ': ' + renderPrerequisiteTitles(uisk)}
                            data-for={`${index}_uiSkillStatusTooltip`}
                            aria-label={tr.GetString('TrainingPlan:Fulfilled') + ': ' + renderPrerequisiteTitles(uisk)}>
                            <div className="inlineFlexAboveSm">{renderPrerequisiteIcons(uisk)}
                                <span className="marginLeft5AboveSm">{renderPrerequisiteTitles(uisk)}</span>
                            </div>
                            <Tooltip id={`${index}_uiSkillStatusTooltip`} />
                        </td>
                        <td>
                            {renderRecommendedLessons(uisk)}
                        </td>
                    </tr>));
            return elements;
        }
        else {
            return (
                <tr>
                    <td colSpan={3}>
                        <NoDataFound message={Session.instance.storage.translation.GetString('TrainingPlan:NoPrerequisites')} />
                    </td>
                </tr>
            );
        }
    }

    return (
        <React.Fragment>
            <table className="tp-table">
                <thead>
                    <tr>
                        <th><Translate>TrainingPlan:Skills</Translate></th>
                        <th colSpan={2} className="tp-table__column--double"><Translate>TrainingPlan:SkillLevel</Translate></th>
                        <th ><Translate>TrainingPlan:Fulfilled</Translate></th>
                        <th><Translate>TrainingPlan:Recommendations</Translate></th>
                    </tr>
                </thead>
                <tbody>
                    {renderItems()}
                </tbody>
            </table>
        </React.Fragment>
    );



}