import { ExternalPageControl } from '$src/components/externalPage/ExternalPageControl';
import Session from '$src/core/Session';
import React from 'react';



export class HelpPage extends React.Component {

    public render() {
        let targetUrl;
        if(globalConfig.navigationProperties.helpFaqUrl !== undefined) {
            const currentLanguage = Session.instance.getUserLanguageCodeOrFallBack;
            targetUrl = globalConfig.navigationProperties.helpFaqUrl.replace('@language', currentLanguage);
        }
        return (

            <React.Fragment>
                <ExternalPageControl url={targetUrl} pageTitle="Navigation:Help"  noUrl="Navigation:NoUrl"/>
            </React.Fragment> 
            )

    }

    public componentDidMount() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
    }
}
export default HelpPage;