import GlossaryService from "$src/core/Services/GlossaryService";
import { GlossaryTermDetail } from "$src/storage/models/Glossary/GlossaryTermDetail";
import { isSuccess } from "$src/util/Result";
import React, { useEffect, useState } from "react";
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import Logger from "$src/core/Logger";
import Session from "$src/core/Session";           
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import { useParams } from "react-router";
import { BreadCrumbElement } from "$src/storage/models/BreadCrumbElement";
import InlineSVG from "react-inlinesvg";
import { MenuBreadCrumb } from "../breadCrumb/MenuBreadCrumb";
import GTIconButton from "../shared/Atoms/GTIconButton";
import { NoDataFound } from "../shared/WarningsAndErrors/NoDataFound";
import GlossaryTerm from "./GlossaryTerm";
import CollapsedIcon from '$resources/svgs/navi/arrow-down.svg';
import ExpandedIcon from '$resources/svgs/navi/arrow-up.svg';
import BookmarkedIcon from '$resources/svgs/glossary/glossary-term-bookmarked.svg';
import NotBookmarkedIcon from '$resources/svgs/glossary/glossary-term-not-bookmarked.svg';



export default function GlossaryTermShow() {
    const glossarTermIdParam = useParams<{ glossarTermId: string }>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [glossarTerm, setGlossarTerm] = useState<GlossaryTermDetail>();
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);
    const loggerLocality = 'Components.GlossaryTermShow';
    const className = 'GlossaryTermShow';
    const tr = Session.instance.storage.translation;
    const glossarTermId: number = glossarTermIdParam != null && !isNaN(Number(glossarTermIdParam.glossarTermId)) ? Number(glossarTermIdParam.glossarTermId) : 0;


    useEffect(() => {
        const methodName = `${className}:fetchData()`;
        if (!isLoaded) {
            GlossaryService.instance.getGlossarieTermDetail(glossarTermId).then((result) => {
                if (isSuccess<GlossaryTermDetail>(result)) {
                    result.isViewed = true;
                    setGlossarTerm(result);
                } else {
                    const errorMessage = `${methodName} failed to get glossarTerm with id=${glossarTermId}`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            });
            GlossaryService.instance.setIsViewed(glossarTermId).then((result) => {
                if (isSuccess<BooleanResponse>(result)) {
                    if (!result.status) {
                        const errorMessage = `${methodName} failed to setIsViewd  with id=${glossarTermId}`;
                        Logger.log(loggerLocality, `${methodName} called`);
                        console.log(errorMessage);
                    }
                }
                setIsLoaded(true);
            });
        }
    })

    async function onToggleBookmarkedTerm(isBookmarked: boolean) {
        const methodName = `${className}:onToggleBookmarkedTerm()`;
        await GlossaryService.instance.setBookmarked(glossarTermId, !isBookmarked).then((result) => {
            if (isSuccess<BooleanResponse>(result) && result.status && glossarTerm != null) {
                const glossarTermCopy = { ...glossarTerm };
                if (glossarTermCopy) {
                    glossarTermCopy.isBookmarked = !isBookmarked;
                    setGlossarTerm(glossarTermCopy);
                } else {
                    const errorMessage = `${methodName} failed to setBookmarked  with id=${glossarTermId}`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            }
        });
    }


    function getBcElements() {
        const bcElements: BreadCrumbElement[] = [];
        bcElements.push(
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/GlossaryTermShow/${glossarTermId}`,
                title: glossarTerm!.title
            }
        );

        return bcElements;
    }


    return (
        <div className="l-container">

            {isLoaded ?

                glossarTerm ? <>
                    <div>
                        <MenuBreadCrumb breadCrumbElementsToAppend={getBcElements()} />
                        <Heading headingLevel={1} cssClass="l-box-container heading__Title">
                            {glossarTerm.title}
                        </Heading>
                    </div>
                    <div className={`glossar-details__item`} key={"glossaryTerm_" + glossarTerm.id}>
                        <div className={`glossar-details__item__header`}>
                            <div className="glossar-details__item-title" onClick={() => setIsPanelOpen(!isPanelOpen)}>
                                <div className="glossar-details__item-title__synonyms">
                                    {glossarTerm.synonyms}
                                </div>
                                <Heading headingLevel={2} cssClass={`heading__Level4 ${!glossarTerm.isViewed ? "glossar-details__item--not-viewed" : ""}`}>{glossarTerm.title}</Heading>
                            </div>
                            <div className="glossar-details__item-bookmarked" >

                                {glossarTerm.isBookmarked ?
                                    <GTIconButton
                                        id={`GlossaryTermBookmarked${glossarTerm.id}`}
                                        ariaLabel={Session.instance.storage.translation.GetString('Glossary:Bookmarked')}
                                        tooltipText={Session.instance.storage.translation.GetString('Glossary:Bookmarked')}
                                        onClick={() => onToggleBookmarkedTerm(glossarTerm.isBookmarked)}>
                                        <InlineSVG src={BookmarkedIcon} />
                                    </GTIconButton> : <GTIconButton
                                        id={`GlossaryTermNotBookmarked${glossarTerm.id}`}
                                        ariaLabel={Session.instance.storage.translation.GetString('Glossary:NotBookmarked')}
                                        tooltipText={Session.instance.storage.translation.GetString('Glossary:NotBookmarked')}
                                        onClick={() => onToggleBookmarkedTerm(glossarTerm.isBookmarked)}>
                                        <InlineSVG src={NotBookmarkedIcon} />
                                    </GTIconButton>}
                                {isPanelOpen ? <GTIconButton
                                    id={`GlossaryTermClose${glossarTerm.id}`}
                                    ariaLabel={Session.instance.storage.translation.GetString('Glossary:Close')}
                                    tooltipText={Session.instance.storage.translation.GetString('Glossary:Close')}
                                    onClick={() => setIsPanelOpen(!isPanelOpen)}>
                                    <InlineSVG src={ExpandedIcon} />
                                </GTIconButton> : <GTIconButton
                                    id={`GlossaryTermOpen${glossarTerm.id}`}
                                    ariaLabel={Session.instance.storage.translation.GetString('Glossary:Open')}
                                    tooltipText={Session.instance.storage.translation.GetString('Glossary:Open')}
                                    onClick={() => setIsPanelOpen(!isPanelOpen)}>
                                    <InlineSVG src={CollapsedIcon} />
                                </GTIconButton>}
                            </div>
                        </div>
                        {
                            isPanelOpen && <GlossaryTerm glossarieTermId={glossarTerm.id} />
                        }

                    </div>
                </>
                    :
                    <NoDataFound message={tr.GetString('Glossary:GlossaryTermNotFound')} />
                : <ProgressSpinner />}

        </div>

    );

}