import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';
import Logger from '$src/core/Logger';
import TeamService from '$src/core/Services/TeamService';
import TrainingPlanService from '$src/core/Services/TrainingPlanService';
import Session from '$src/core/Session';
import { EClassStatus, EItemDetailCallerContextType, EItemType } from '$src/storage/models/enums';
import { F2FClassDetail } from '$src/storage/models/F2F/F2FClassDetail';
import { Item } from '$src/storage/models/Item';
import { TplanScheduleOrF2FClassDisplay } from '$src/storage/models/myEmployees/TplanScheduleOrF2FClassDisplay';
import { RegisterUserToItemResponse } from '$src/storage/models/RegisterUserToItemResponse';
import { RegisterUserToItemRequest } from '$src/storage/models/RequestObjects/RegisterUserToItemRequest';
import { TrainingPlan } from '$src/storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanSchedule } from '$src/storage/models/TrainingPlan/TrainingPlanSchedule';
import { User } from '$src/storage/models/User';
import TranslationStorage from '$src/storage/TranslationStorage';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';
import { filterBy } from '@progress/kendo-data-query';
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import ItemsFilter from '$components/myEmployees/ActionModals/ItemsFilter';
import { ModalPopup } from '$src/components/shared/ModalPopup';

interface IProps {
    selectedEmployees: User[] | undefined;
    isOpen: boolean;
    onClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    headingLevel: number;
    itemContext: EItemDetailCallerContextType;
    headingClassName: string | undefined;
    parentTrainingPlanId?: number | undefined;
    bossRelationCode: string;
}

export interface IItemType {
    itemTypeId: number;
    displayText: string;
}

export const RegisterLessonModal: React.FC<IProps> = (props: IProps) => {
    const tr: TranslationStorage = Session.instance.storage.translation;

    const itemTypeData: IItemType[] = [];
    itemTypeData.push({ itemTypeId: EItemType.F2FCourse, displayText: tr.GetString('ItemType:F2FCourse') });
    itemTypeData.push({ itemTypeId: EItemType.TrainingPlan, displayText: tr.GetString('ItemType:TrainingPlan') });
    itemTypeData.push({ itemTypeId: EItemType.WBT, displayText: tr.GetString('ItemType:WBT') });

    const className = 'RegisterLessonModal';
    const loggerLocality = 'Components.RegisterLessonModal';
    const TRANSLATION_PREFIX = 'RegisterLessonModal'

    const [aclRight, setAclRight] = useState<number>(3);
    const [disableCmbTplanOrClassList, setDisableCmbTplanOrClassList] = useState<boolean>(true);
    const [disableRegisterButton, setDisableRegisterButton] = useState<boolean>(true);
    const [displayMessages, setDisplayMessages] = useState<string[]>([]);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isRegistrationRequiredEnabled, setIsRegistrationRequiredEnabled] = useState<boolean>(false);
    const [isVisibleAfterRegisterClick, setIsVisibleAfterRegisterClick] = useState<boolean>(true);
    const [isClassSelectionVisible, setIsClassSelectionVisible] = useState<boolean>(true);
    const [selectedLesson, setSelectedLesson] = useState<Item | undefined>(undefined);
    const [fetchedTplanOrClassList, setFetchedTplanOrClassList] = useState<TplanScheduleOrF2FClassDisplay[]>([]);
    const [sortedTplanOrClassList, setSortedTplanOrClassList] = useState<TplanScheduleOrF2FClassDisplay[] | undefined>(undefined);
    const [currentFilter, setCurrentFilter] = useState<string>('');
    const [itemTypeId, setItemTypeId] = useState<number>(EItemType.F2FCourse as number);
    const [selectedTPlanOrClass, setSelectedTPlanOrClass] = useState<TplanScheduleOrF2FClassDisplay | undefined>(undefined);
    const [isFilterTplanOrClassLoading, setIsFilterTplanOrClassLoading] = useState<boolean>(false);
    const [registerClick, setRegisterClick] = useState<boolean>(false);



    function prepareMessages(msgs: string[], alertType: string): Array<{ message: string; alertType: 'info' | 'warning' | 'error' | 'success' }> {

        return msgs.map(msg => {
            return { message: msg, alertType: alertType as ('info' | 'warning' | 'error' | 'success') };
        });
    }

    function GetReferencedIItemType(): IItemType | undefined {
        const res = itemTypeData.find(i => i.itemTypeId === itemTypeId);
        if (res != null) { return res; }
        return undefined;
    }

    function ResetDependencies(itemTypeId: EItemType): void {
        const sortedTplanOrClassList = new Array<TplanScheduleOrF2FClassDisplay>();
        const fetchedTplanOrClassList = new Array<TplanScheduleOrF2FClassDisplay>();

        setFetchedTplanOrClassList(fetchedTplanOrClassList);
        setItemTypeId(itemTypeId);
        setSelectedTPlanOrClass(undefined);
        setSortedTplanOrClassList(sortedTplanOrClassList);
        setDisableCmbTplanOrClassList(true);
        setIsFilterTplanOrClassLoading(false);
        setDisableRegisterButton(true);
        setIsVisibleAfterRegisterClick(true);
        setDisplayMessages([]);
        setErrorMessages([]);
    }

    useEffect(() => {
        async function onSelectedLessonChange() {

            const methodName = `${className}:onSelectedLessonChange()`;


            ResetDependencies(itemTypeId);

            if (selectedLesson === undefined) { return; }

            const forceLoadingFromServer = true;
            const tplanScheduleOrF2FClassDisplayList = new Array<TplanScheduleOrF2FClassDisplay>();
            switch (selectedLesson.itemType) {
                case EItemType.TrainingPlan:
                    {
                        const tplan = await Session.instance.storage.trainingPlans.getTrainingPlan(selectedLesson.itemId, props.itemContext, forceLoadingFromServer);
                        if (isSuccess<TrainingPlan | null>(tplan)) {
                            if (tplan == null) {
                                const errMsg = tr.GetString(`${TRANSLATION_PREFIX}:TplanLoadError`);
                                setErrorMessages(new Array<string>(errMsg));
                                setSortedTplanOrClassList(undefined);
                                setSelectedTPlanOrClass(undefined);
                                setIsFilterTplanOrClassLoading(false);
                                Logger.log(loggerLocality, `${methodName}: ${errMsg}`);
                                return;
                            }
                            const tplanScheduleList = await TrainingPlanService.instance
                                .getTrainingPlanSchedulesForEmployees(Session.instance.getUserLanguageCodeOrFallBack, tplan.itemId,
                                    EItemDetailCallerContextType.Undefined);
                            if (isSuccess<TrainingPlanSchedule[] | null>(tplanScheduleList)) {
                                const errMsg = tr.GetString(`${TRANSLATION_PREFIX}:TplanScheduleLoadError`);
                                if (tplanScheduleList == null) {
                                    setErrorMessages(new Array<string>(errMsg));
                                    setSortedTplanOrClassList(undefined);
                                    setSelectedTPlanOrClass(undefined);
                                    setIsFilterTplanOrClassLoading(false);
                                    Logger.log(loggerLocality, `${methodName}: ${errMsg}`);
                                    return;
                                }
                                tplanScheduleList.filter(tp => ![EClassStatus.Cancelled, EClassStatus.Closed].includes(tp.status) &&
                                    tp.isInRegistrationPeriod && tp.availablePlaces != 0 /*availablePlaces: undefinied -> infinite*/).forEach(s => {
                                        const display = new TplanScheduleOrF2FClassDisplay();
                                        display.code = s.code;
                                        display.clsOrSceId = s.sceId;
                                        display.description = s.description;
                                        display.itemId = s.itemId;
                                        display.learnEnd = s.runningEnd;
                                        display.learnStart = s.runningBegin;
                                        display.title = s.title;
                                        display.displayText = display.code + ' | ' + display.title + ' | ' +
                                            (display.learnStart === undefined ? '' : StringHelper.dateString(display.learnStart)) +
                                            ' - ' + (display.learnEnd === undefined ? '' : StringHelper.dateString(display.learnEnd)) +
                                            ' | ' + tr.GetString(`${TRANSLATION_PREFIX}:FreePlaces`) + ' : ' +
                                            (s.maxRegistrations === 0 ? tr.GetString(`${TRANSLATION_PREFIX}:FreePlacesMax`) : s.availablePlaces);
                                        tplanScheduleOrF2FClassDisplayList.push(display);
                                    });
                                setDisableCmbTplanOrClassList(false);
                                setFetchedTplanOrClassList(tplanScheduleOrF2FClassDisplayList);
                                setIsClassSelectionVisible(true);
                                setSortedTplanOrClassList(tplanScheduleOrF2FClassDisplayList);
                                setIsFilterTplanOrClassLoading(false);
                            }
                            else {
                                const errMsg = tr.GetString(`${TRANSLATION_PREFIX}:TplanScheduleLoadError`);
                                setErrorMessages(new Array<string>(errMsg));
                                setSortedTplanOrClassList(undefined);
                                setSelectedTPlanOrClass(undefined);
                                setIsFilterTplanOrClassLoading(false);
                                Logger.log(loggerLocality, `${methodName}: ${errMsg}`);
                                return;
                            }
                        } else {
                            const errMsg = tr.GetString(`${TRANSLATION_PREFIX}:TplanLoadError`);
                            setErrorMessages(new Array<string>(errMsg));
                            setSortedTplanOrClassList(undefined);
                            setSelectedTPlanOrClass(undefined);
                            setIsFilterTplanOrClassLoading(false);
                            Logger.log(loggerLocality, `${methodName}: ${errMsg}`);
                            return;
                        }
                        break;
                    }
                case EItemType.WBT:
                    setDisableRegisterButton(false);
                    setDisableCmbTplanOrClassList(true);
                    setIsClassSelectionVisible(false);
                    setIsFilterTplanOrClassLoading(false);
                    break;
                default:
                    {
                        setIsFilterTplanOrClassLoading(true);
                        const f2FClassDetail: F2FClassDetail[] | null = await Session.instance.storage.classList.getF2FClassList(selectedLesson.itemId,
                            EItemDetailCallerContextType.Undefined, undefined, props.parentTrainingPlanId);
                        if (isSuccess<F2FClassDetail[] | null>(f2FClassDetail)) {
                            if (f2FClassDetail == null) {
                                const errMsg = tr.GetString(`${TRANSLATION_PREFIX}::ClassLoadError`);
                                setErrorMessages(new Array<string>(errMsg));
                                setSortedTplanOrClassList(undefined);
                                setSelectedTPlanOrClass(undefined);
                                setIsFilterTplanOrClassLoading(false);
                                return;
                            }
                            f2FClassDetail.filter(f2fClass => ![EClassStatus.Cancelled, EClassStatus.Closed].includes(f2fClass.classStatus) &&
                                f2fClass.isInRegistrationPeriod && (f2fClass.freePlaces > 0 || f2fClass.maxParticipants == 0)).forEach(c => {
                                    if (c.hasRegistrationRight) { // in GTServices so implementiert

                                        const display = new TplanScheduleOrF2FClassDisplay();
                                        display.code = c.classCode;
                                        display.clsOrSceId = c.classId;
                                        display.title = c.classTitle;
                                        display.description = c.classDescription;
                                        display.itemId = c.itemId;
                                        display.learnEnd = c.learningPeriodEnd;
                                        display.learnStart = c.learningPeriodStart;
                                        display.displayText = display.code + ' | ' + display.title + ' | ' +
                                            (display.learnStart === undefined ? '' : StringHelper.dateString(display.learnStart)) +
                                            ' - ' + (display.learnEnd === undefined ? '' : StringHelper.dateString(display.learnEnd)) +
                                            ' | ' + tr.GetString(`${TRANSLATION_PREFIX}:FreePlaces`) + ' : ' +
                                            (c.maxParticipants === 0 ? tr.GetString(`${TRANSLATION_PREFIX}:FreePlacesMax`) : c.freePlaces);
                                        tplanScheduleOrF2FClassDisplayList.push(display);
                                    }
                                });
                            setDisableCmbTplanOrClassList(false);
                            setFetchedTplanOrClassList(tplanScheduleOrF2FClassDisplayList);
                            setIsClassSelectionVisible(true);
                            setSortedTplanOrClassList(tplanScheduleOrF2FClassDisplayList);
                            setIsFilterTplanOrClassLoading(false);
                        }
                        else {
                            const errMsg = tr.GetString(`${TRANSLATION_PREFIX}:ClassLoadError`);
                            setErrorMessages(new Array<string>(errMsg));
                            setSortedTplanOrClassList(undefined);
                            setSelectedTPlanOrClass(undefined);
                            setIsFilterTplanOrClassLoading(false);
                            Logger.log(loggerLocality, `${methodName}: ${errMsg}`);
                            return;
                        }
                    }
            }
        }
        onSelectedLessonChange();
    }, [selectedLesson]);

    useEffect(() => {
        async function onRegisterClick() {

            const methodName = `${className}:onRegisterClick()`;

            const msg = tr.GetString(`${TRANSLATION_PREFIX}:RegisterSuccessfull`);
            const errorMsg = tr.GetString(`${TRANSLATION_PREFIX}:FailedToRegister`);
            const arrMsg = new Array<string>();
            const arrErrMsg = new Array<string>();

            const requestObj: RegisterUserToItemRequest = {
                classOrTplanScheduleId: selectedTPlanOrClass == null ? 0 : selectedTPlanOrClass.clsOrSceId,
                employeeIds: props.selectedEmployees!.map((emp) => emp.id),
                itemId: selectedLesson!.itemId,
                itemType: selectedLesson!.itemType,
            }

            const response = await TeamService.instance.registerUserToItem(requestObj);
            if (isSuccess<RegisterUserToItemResponse>(response)) {
                if (props.selectedEmployees != null) {
                    props.selectedEmployees.map((emp) => {

                        if (response.employeeIdsWithSuccess.filter((rE) => rE === emp.id) != null) {
                            arrMsg.push(`${msg} ${emp.firstName} ${emp.lastName}`);
                        }
                        else {
                            arrErrMsg.push(`${errorMsg} ${emp.firstName} ${emp.lastName}`);
                            Logger.log(loggerLocality, `${methodName}: ${errorMsg}`);
                        }
                    }
                    );
                }
            }
            else if (response == null || response instanceof GtError) {
                if (props.selectedEmployees != null) {
                    props.selectedEmployees.map((emp) => {
                        arrErrMsg.push(`${errorMsg} ${emp.firstName} ${emp.lastName}`);
                    }
                    );
                }
                if (response instanceof GtError) {
                    arrErrMsg.unshift(response.message);
                    Logger.log(loggerLocality, `${methodName}: ${response.message}`);
                }
            }

            setDisplayMessages(arrMsg);
            setErrorMessages(arrErrMsg);
            setIsVisibleAfterRegisterClick(false);
        }
        if (registerClick) {
            onRegisterClick();
        }
    }, [registerClick]);

    // also used for reset if modal closed
    function onChangeItemTypeResolution(itemTypeId: number): void {
        switch (itemTypeId) {
            case EItemType.F2FCourse:
                setAclRight(3);
                setIsClassSelectionVisible(true);
                setIsRegistrationRequiredEnabled(false);
                break;
            case EItemType.TrainingPlan:
                setAclRight(3);
                setIsClassSelectionVisible(true);
                setIsRegistrationRequiredEnabled(false);
                break;
            case EItemType.WBT:
                setAclRight(2);
                setIsClassSelectionVisible(false);
                setIsRegistrationRequiredEnabled(true);
                break;
        }
        ResetDependencies(itemTypeId);
    }

    function onRequestClose(e: React.MouseEvent<Element> | React.KeyboardEvent<Element>) {
        onChangeItemTypeResolution(EItemType.F2FCourse);
        props.onClose(e);
    }

    function onChangeItemType(e: ComboBoxChangeEvent): void {
        const itemTypeId: number = parseInt(e.target.value.itemTypeId, 10);
        onChangeItemTypeResolution(itemTypeId);
    }

    function filterTplanOrClassData(val: string) {
        const filter = {
            field: 'displayText',
            ignoreCase: true,
            operator: 'contains',
            value: val
        };
        return val ? filterBy(fetchedTplanOrClassList, filter) : fetchedTplanOrClassList;
    }

    function filterTplanOrClassChange(event: ComboBoxFilterChangeEvent) {
        const sortedTplanOrClassList = filterTplanOrClassData(event.filter.value);
        const currentFilter = event.filter.value;

        setCurrentFilter(currentFilter);
        setDisableRegisterButton(event.target.value == null);
        setSortedTplanOrClassList(sortedTplanOrClassList);
    }


    function onTplanOrClassChange(event: ComboBoxChangeEvent) {

        const item = event.target.value;
        const itemList = item ? filterTplanOrClassData(item.displayText) : fetchedTplanOrClassList;
        event.target.setState({ value: item ? item : currentFilter })

        setCurrentFilter(item ? item.displayText : currentFilter);
        setDisableRegisterButton(event.target.value == null);
        setSelectedTPlanOrClass(item);
        setSortedTplanOrClassList(itemList);

        if (item instanceof TplanScheduleOrF2FClassDisplay) {
            const specItem = item;
            if (specItem.clsOrSceId > 0) {
                setDisableRegisterButton(false);
            }
        }
    }

    function renderSummary(): string {
        let summary = tr.GetString(`${TRANSLATION_PREFIX}:Summary`) + ': ';
        if (selectedLesson !== undefined) {
            summary = summary + selectedLesson.itemId;
            summary = summary + ` (${selectedLesson.sId})`;
        }
        if (selectedTPlanOrClass !== undefined) {
            summary = summary + ` | ${selectedTPlanOrClass.displayText}`;
        }

        return summary;
    }


    return (
        <ModalPopup contentCssClass="register_lessonmodal"
            isOpen={props.isOpen}
            onRequestClose={(e) => onRequestClose(e)}>
            <Heading
                headingLevel={props.headingLevel}
                cssClass={props.headingClassName != null ? props.headingClassName : 'heading__Level2'}>
                <Translate>{`${TRANSLATION_PREFIX}:RegisterLessonForSelectedEmployees`}
                </Translate>
            </Heading>
            {isVisibleAfterRegisterClick ?
                <>
                    <div>
                        <ComboBox
                            id='registerLessonModalItemType'
                            data={itemTypeData} // don't want to construct on any render
                            value={GetReferencedIItemType()}
                            filterable={true}
                            dataItemKey="itemTypeId"
                            textField="displayText"
                            onChange={(e) => onChangeItemType(e)}
                            className="my-team__item-filter"
                            label={tr.GetString(`${TRANSLATION_PREFIX}:ItemTypeData`)}
                            required={true} />
                    </div>
                    <ItemsFilter
                        id='registerLessonModalLessonFilter'
                        dataItemKey="itemId" aclRight={aclRight}
                        itemType={itemTypeId} isCheckRegisterClass={true}
                        onSelectedItemChange={(itm) => setSelectedLesson(itm)}
                        label={tr.GetString(`${TRANSLATION_PREFIX}:LessonSearch`)}
                        required={true}
                        isRegistrationRequiredEnabled={isRegistrationRequiredEnabled}
                        bossRelationCode={props.bossRelationCode} />
                    {isClassSelectionVisible ? <div>
                        <ComboBox id='registerLessonModalClass'
                            data={sortedTplanOrClassList}
                            textField="displayText"
                            dataItemKey="clsOrSceId"
                            filterable={true}
                            loading={isFilterTplanOrClassLoading}
                            value={selectedTPlanOrClass ? selectedTPlanOrClass : ''}
                            onChange={(e) => onTplanOrClassChange(e)}
                            onFilterChange={(e) => filterTplanOrClassChange(e)}
                            className="my-team__item-filter"
                            label={tr.GetString(`${TRANSLATION_PREFIX}:ClassSearch`)}
                            disabled={disableCmbTplanOrClassList}
                            required={disableCmbTplanOrClassList === false} />
                    </div>
                        : ''
                    }

                </>
                : <p>
                    {renderSummary()}
                </p>
            }
            <>
                {displayMessages.length > 0 ?
                    <Alert alertType={'success'} alertAppereance={'box'}
                        messages={prepareMessages(displayMessages, 'success')} /> : null
                }
                {errorMessages.length > 0 ?
                    <Alert alertType={'error'} alertAppereance={'box'}
                        messages={prepareMessages(errorMessages, 'error')} /> : null
                }
                <div className="register_lessonmodal__button-container modal__spread-buttons">
                    {isVisibleAfterRegisterClick ?
                        <button className="btn--sm btn--primary" disabled={disableRegisterButton} onClick={() => setRegisterClick(true)} id="btnRegisterUser">
                            <Translate>{tr.GetString(`${TRANSLATION_PREFIX}:Register`)}</Translate></button> : null}
                    <button onClick={e => onRequestClose(e)} className="btn--sm btn--default" id="btnCloseRegisterLessonModal"><Translate>{`${TRANSLATION_PREFIX}:Close`}</Translate></button>
                </div>
            </>
        </ModalPopup>
    )
}

