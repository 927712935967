import { Translate } from '$src/components/shared/Translate';
import ItemService from '$src/core/Services/ItemService';
import Session from '$src/core/Session';
import { EItemType } from '$src/storage/models/enums';
import { Item } from '$src/storage/models/Item';
import { isSuccess } from '$src/util/Result';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';

interface IItemInfo {
    fullName: string;
    itemId: number;
    sId: string;
    title: string;
}
interface IProps {
    aclRight: number;
    itemType: EItemType;
    isCheckRegisterClass: boolean;
    onSelectedItemChange: (item: Item | undefined) => void;
    dataItemKey: string;
    label?: string | undefined;
    required?: boolean;
    isRegistrationRequiredEnabled?: boolean;
    preSelectedItem?: Item;
    id?: string;
    bossRelationCode: string;
}

export const ItemsFilter: React.FC<IProps> = (props: IProps) => {
    const MININPUTLENGH = globalConfig.myTeamProperties.minInputLengthToStartLessonSearch;

    const [selectedValue, setSelectedValue] = useState<IItemInfo | undefined>(props.preSelectedItem ? {
        fullName: props.preSelectedItem.title + ' (' + props.preSelectedItem.sId + ')',
        itemId: props.preSelectedItem.itemId,
        sId: props.preSelectedItem.sId,
        title: props.preSelectedItem.title
    } : undefined);
    const [itemTitleAndSIDSortedList, setItemTitleAndSIDSortedList] = useState<IItemInfo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchedItemList, setFetchedItemList] = useState<Item[]>([]);
    const [searchText, setSearchText] = useState<string>(props.preSelectedItem ? props.preSelectedItem.title : '');

    function createItemsDataToFilterList(itemList: Item[]) {
        const list = [];
        for (const item of itemList) {
            const temp = {
                fullName: item.title + ' (' + item.sId + ')',
                itemId: item.itemId,
                sId: item.sId,
                title: item.title
            }
            list.push(temp);
        }
        return list;
    }

    useEffect(() => {
        async function getItemsFromServer() {
            setLoading(true);
            const response = await ItemService.instance.getLessonsForBossToEmployeesByRightsAndItemType(
                props.itemType,
                props.aclRight,
                props.isCheckRegisterClass,
                props.bossRelationCode,
                searchText);

            if (isSuccess<Item[]>(response)) {
                const tmpArr = props.isRegistrationRequiredEnabled === true
                    ? response.filter(r => r.registrationRequiredEnabled)
                    : response;

                const list = createItemsDataToFilterList(tmpArr);
                setFetchedItemList(tmpArr);
                setItemTitleAndSIDSortedList(list);
                setLoading(false);
            } else {
                setLoading(false);
            }
        }

        if (searchText && searchText.length >= MININPUTLENGH) {
            getItemsFromServer();
        }
        else {
            setItemTitleAndSIDSortedList([]);
        }

    }, [searchText]);

    useEffect(() => {
        if (selectedValue) {
            if (props.preSelectedItem) {
                props.onSelectedItemChange(props.preSelectedItem);
            } else {
                props.onSelectedItemChange(fetchedItemList.find(i => i.itemId === selectedValue.itemId));
            }
        }
    }, [selectedValue])

	/** No data found or input length to short */
	const searchComboBoxNoData = (element: React.ReactElement<HTMLDivElement>) => {
		const length = searchText?.length || 0;
        const minInputLengthToStartLessonSearchText = Session.instance.storage.translation.GetString('SearchSelectBox:MinimumInputLengthValidatorPlaceholder').Format(MININPUTLENGH.toString());
		const noData = (
			<span>
				{length < MININPUTLENGH && minInputLengthToStartLessonSearchText}
				{length >= MININPUTLENGH && !loading && <Translate>SearchSelectBox:NoData</Translate>}
                {length >= MININPUTLENGH && loading && <Translate>SearchSelectBox:LoadData</Translate>}
			</span>
		);

		return React.cloneElement(element, { ...element.props }, noData);
	};

    return (
        <div>
            {
                <ComboBox
                    id={props.id}
                    data={itemTitleAndSIDSortedList}
                    textField="fullName"
                    listNoDataRender={searchComboBoxNoData}
                    dataItemKey={props.dataItemKey}
                    filterable={true}
                    loading={loading}
                    value={selectedValue ? selectedValue : ''}
                    onChange={(e) => setSelectedValue(e.value)}
                    onFilterChange={(e) => setSearchText(e.filter.value)}
                    className="my-team__item-filter"
                    label={props.label}
                    required={props.required}
                    disabled={props.preSelectedItem != null}
                />
            }
        </div>
    );

}

export default ItemsFilter;