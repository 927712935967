import MediaService from '$src/core/Services/MediaService';
import { EValueType } from '$src/storage/models/enums';
import { ValueTypeConverter } from '$storage/models/converters/ValueTypeConverter'
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class Attribute {

    @JsonProperty('id', Number, false)
    public id: number = 0;
    @JsonProperty('language', String, false)
    public language: string = '';
    @JsonProperty('isDefaultLanguage', Boolean, false)
    public isDefaultLanguage: boolean = false;
    @JsonProperty('value', String, false)
    public value: string = '';
    @JsonProperty('enumCodeValue', String, false)
    public enumCodeValue: string = '';
    @JsonProperty('labelKey', String, false)
    public labelKey: string = '';
    @JsonProperty('ordinal', Number, false)
    public ordinal: number = 0;
    @JsonProperty('name', String, false)
    public name: string = '';
    @JsonProperty('valueType', ValueTypeConverter, false)
    public valueType: EValueType = EValueType.Undefined;

    public url: string;

    public getMediaStreamUrl(): string {
        return MediaService.instance.getMediaUrl(this.id);
    }

    public getPreparedDocumentUrl(): string {
        return MediaService.instance.getPreparedDocumentUrl(this.id);
    }
}