import { JsonObject, JsonProperty } from 'json2typescript';
import { ETargetSkillStatus } from '$src/storage/models/enums';
import { TargetSkillStatusConverter } from '$src/storage/models/converters/TargetSkillStatusConverter';
import { DateOrNullConverter } from '$src/storage/models/converters/DateOrNullConverter';

@JsonObject
export class UserSkillProfileSkill {

    /** The Skill Id */
    @JsonProperty('skillId', Number, false)
    public skillId: number = 0;

    /** The Id of the User */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** The used skill level set for the skill profile */
    @JsonProperty('skillProfileSkillLevelId', Number, false)
    public skillProfileSkillLevelId: number = 0;

    /**The Id from the userTargetSkill or the Id from the ProfileSkill */
    @JsonProperty('userSkillTargetOrProfileSkillId', Number, false)
    public userSkillTargetOrProfileSkillId: number = 0;

    /** The skill level value which must be achieved for this skill in the skill profile */
    @JsonProperty('skillProfileSkillLevelValue', Number, false)
    public skillProfileSkillLevelValue: number = 0;

    /** The skill level translation which must be achieved for this skill in the skill profile */
    @JsonProperty('skillProfileSkillLevelTitle', String, false)
    public skillProfileSkillLevelTitle: string = '';

    /** The Skill level set id used for this skill */
    @JsonProperty('skillLevelSetId', Number, false)
    public skillLevelSetId: number = 0;

    /** The skill title in the requested language or default language */
    @JsonProperty('skillTitle', String, false)
    public skillTitle: string = '';

    /** The skill description in the requested language or default language */
    @JsonProperty('skillDescription', String, false)
    public skillDescription: string = '';

    /** The skill Performance Review field in the selected language or in default language */
    @JsonProperty('skillPerformanceReviewField', String, false)
    public skillPerformanceReviewField: string = '';

    /** The second skill Performance Review field in the selected language or in default language */
    @JsonProperty('skillPerformanceReviewField2', String, false)
    public skillPerformanceReviewField2: string = '';

    /** Flag if the user skill profile is for performance review */
    @JsonProperty('showSkillInPerformanceReview', Boolean, false)
    public showSkillInPerformanceReview: boolean = false;

    /** Flag if the user has access right to the skill */
    @JsonProperty('hasAssignRight', Boolean, false)
    public hasAssignRight: boolean = false;

    /** Flag if the skill may be expired */
    @JsonProperty('mayBeExpired', Boolean, false)
    public mayBeExpired: boolean = false;

    /** Amount of recommended lessons for this skill */
    @JsonProperty('numberOfRecommendedLessons', Number, false)
    public numberOfRecommendedLessons: number = 0;

    /** The Status which the target skill has*/
    @JsonProperty('targetSkillStatus', TargetSkillStatusConverter, false)
    public targetSkillStatus: ETargetSkillStatus = ETargetSkillStatus.Undefined;

    /**Date & time skill is requested from the employee (PerformanceCheck)*/
    @JsonProperty('requestedDate', DateOrNullConverter, false)
    public requestDate: Date = new Date();

    /**Date & time skill is edit from the boss (PerformanceCheck)*/
    @JsonProperty('approvalDate', DateOrNullConverter, false)
    public approvalDate: Date = new Date();

    /**Comment from the employee when he request a skill (PerformanceCheck)  */
    @JsonProperty('requestReason', String, true)
    public requestReason: string | null = '';

    /**Comment from the employee when he request a skill (PerformanceCheck)  */
    @JsonProperty('responseReason', String, true)
    public responseReason: string | null = '';

    /**First name of the boss which has edit the requested skill (PerformanceCheck)  */
    @JsonProperty('bossFirstName', String, true)
    public bossFirstName: string | null = '';

    /**Last name of the boss which has edit the requested skill (PerformanceCheck)  */
    @JsonProperty('bossLastName', String, true)
    public bossLastName: string | null = '';

    /** Teh Sortorder of the skill in the profil*/
    @JsonProperty('sortOrder', Number, false)
    public sortOrder: number = 0;

    public skillProfileId?: number = undefined;
}