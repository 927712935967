
import ServiceClient from '$src/core/ServiceClient';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { RemoveRefreshTokenRequest } from '$src/storage/models/RequestObjects/RemoveRefreshTokenRequest';
import { StringResponse } from '$src/storage/models/StringResponse';
import GtError from '$src/util/GtError';
import { OldSuiUrlResponse } from '$src/storage/models/OldSuiUrlResponse';

/**
 * This is just the template class for creating a new Service Object
 * You can copy this class to create a new Service. 
 * Don't forget that you have to initialize the Service in boot.tsx
 */
export default class AuthService extends ServiceClient {
    protected static _instance: AuthService | null = null;

    protected className = 'AuthService';
    protected loggerLocality = 'AuthService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): AuthService {
        return this._instance || (this._instance = new this());
    }


    /**
     * Remove a refresh Token from the DB
     *
     * @param {string} refreshToken
     * @param {number} userID
     * @returns {(Promise<BooleanResponse | GtError>)}
     * @memberof UserService
     */
    public async removeRefreshToken(refreshToken: string, userID: number): Promise<BooleanResponse | GtError> {
        const obj = new RemoveRefreshTokenRequest();
        obj.refreshToken = refreshToken;
        obj.userID = userID;
        return await this.post<BooleanResponse>('auth/removeRefreshToken', obj, BooleanResponse, undefined);
    }

    public async GetOldSuiUrl(domain: string): Promise<OldSuiUrlResponse | GtError> {
        return await this.get<OldSuiUrlResponse>('auth/oldsuiurl', OldSuiUrlResponse, undefined, undefined, domain);
    }

    public async GetAuiUrl(): Promise<StringResponse | GtError> {
        return await this.get<StringResponse>('auth/auiurl', StringResponse, undefined, undefined);
    }

    public async GetExternalAccessToken(): Promise<StringResponse | GtError> {
        return await this.get<StringResponse>('auth/getAccessTokenForExternalUse', StringResponse, undefined, undefined);
    }

    public async GetPing(): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('ping', BooleanResponse, undefined, undefined);
    }

} 