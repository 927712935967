
import React, { useEffect, useState } from 'react';
import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { Translate } from '$components/shared/Translate';
import { UnmountClosed } from 'react-collapse';
import AddPaymentCampaignForm from '$components/voucherAdministration/AddPaymentCampaignForm';
// import PaymentCampaignArchive  from '$components/voucherAdministration/PaymentCampaignArchive ';
import PaymentCampaignArchive from '$components/voucherAdministration/PaymentCampaignArchive';
import { PaymentVoucherCampaignResponse } from '$src/storage/models/Campaign/PaymentVoucherCampaignResponse';
import PaymentCampaignService from '$src/core/Services/PaymentCampaignService';
import Logger from '$src/core/Logger';
import { isSuccess } from '$src/util/Result';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';

const loggerLocality = 'Components.PaymentCampaignOverview ';

/**
 * Page Base Element on which a new campaign can be created and the Archive of already creaded Campaigns is shown
 * @param props 
 * @returns Campaign Overview Page Element
 */
export default function PaymentCampaignOverview(props: {}) {
    const [isCreateCampaignPanelOpen, setIsCreateCampaignPanelOpen] = useState<boolean>(true);
    const [newCampaign, setNewCampaign] = useState<PaymentVoucherCampaignResponse[]>([]);

    const [isArchiveLoaded, setIsArchiveLoaded] = useState<boolean>(false);
    const [archiveMessage, setArchiveMessage] = useState<string | null>(null);
    const [archiveMessageType, setArchiveMessageType] = useState<'warning' | 'error' | 'info' | 'success' | 'mixed'>('info');
    const [campaignsArchive, setCampaignsArchive] = useState<PaymentVoucherCampaignResponse[] | null>([]);
    const [isDeletingCampaign, setIsDeletingCampaign] = useState<boolean>(false);
    const [clickedCampaignId, setClickedCampaignId] = useState<number | null>(null);

    // const TRANSLATION_PREFIX = 'PaymentCampaignOverview';

    useEffect(() => {
        if (!isArchiveLoaded) {
            PaymentCampaignService.instance.getCampaignsWithFilter().then(response => {
                if (isSuccess(response)) {
                    const cmp = response as Array<PaymentVoucherCampaignResponse>;
                    setCampaignsArchive([...cmp]);
                    setIsArchiveLoaded(true);
                    setArchiveMessage(null);
                } else {
                    Logger.log(loggerLocality, `An error ocurred while trying to get Voucher Campaigns from Server`);
                    setArchiveMessage(Session.instance.storage.translation.GetString('PaymentCampaignArchive:ErrorLoadingPaymentCampaigns'));
                    setArchiveMessageType('error');
                }
            });
        }
    }, [isArchiveLoaded]);

    const deleteCampaign = (campaignId: number) => {
        setIsDeletingCampaign(true);
        PaymentCampaignService.instance.deleteVoucherCampaign(campaignId).then(response => {
            if (isSuccess<BooleanResponse>(response) && response.status) {
                const cmp = campaignsArchive?.filter(campaign => campaign.id != campaignId) || [];
                setCampaignsArchive([...cmp]);
                setArchiveMessage(Session.instance.storage.translation.GetString('PaymentCampaignArchive:DeletedCampaignSuccessfully'));
                setArchiveMessageType('success');
                setIsDeletingCampaign(false);
                setIsArchiveLoaded(false);
            } else {
                Logger.log(loggerLocality, `An error ocurred while trying to delete Voucher Campaign with id=${campaignId}`);
                setArchiveMessage(Session.instance.storage.translation.GetString('PaymentCampaignArchive:DeleteCampaignErrorOcurred'));
                setArchiveMessageType('error');
            }
        });
    }

    useEffect(() => {
        const temp = campaignsArchive ? campaignsArchive.concat(newCampaign) : [];
        setCampaignsArchive(temp);
        setIsArchiveLoaded(false);
    }, [newCampaign]);

    const reloadData = (clickedCampaignId: number) => {
        setClickedCampaignId(clickedCampaignId);
        setIsArchiveLoaded(false);
    }

    return (
        <>
            <div className="l-container">
                <MenuBreadCrumb key="MenuBreadCrumb" {...props} />
                <>
                    <HeadingCollapsible
                        headingLevel={1}
                        headingCssClass='heading__Title'
                        containerCssClass=""
                        isOpened={isCreateCampaignPanelOpen}
                        onToggleOpenedState={() => setIsCreateCampaignPanelOpen(!isCreateCampaignPanelOpen)}>
                        <Translate>{`AddPaymentCampaignForm:Heading`}</Translate>
                    </HeadingCollapsible>
                </>
                <UnmountClosed isOpened={isCreateCampaignPanelOpen}>
                    <AddPaymentCampaignForm {...props} addNewCampaignToArchive={(val: PaymentVoucherCampaignResponse[]) => { setNewCampaign(val) }} />
                </UnmountClosed>
            </div>

            <div className="l-container">
                {!isArchiveLoaded ?
                    <ProgressSpinner /> :
                    <PaymentCampaignArchive  {...props}
                        message={archiveMessage}
                        campaignArchive={campaignsArchive}
                        archiveMessageType={archiveMessageType}
                        deleteCampaign={deleteCampaign}
                        isDeletingCampaign={isDeletingCampaign}
                        deleteVoucher={reloadData}
                        clickedCampaingId={clickedCampaignId}
                    />
                }
            </div>
        </>
    );
}