import { LazyBoolean } from '$storage/models/misc/LazyBoolean';
import { JsonObject, JsonProperty } from 'json2typescript';
import { FileSharingDocument } from './FileSharingDocument';

/**
 * User with his documents and general information regarding this user
 * @export
 * @class FileSharingUser
 */
@JsonObject
export class FileSharingUser {

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('name', String, false)
    public name: string = '';

    @JsonProperty('documents', [FileSharingDocument], false)
    public documents: FileSharingDocument[] = [];

    @JsonProperty('allowUpload', Boolean, false)
    public allowUpload: boolean = false;

    @JsonProperty('usedSpace', Number, false)
    public usedSpace: number = 0;

    @JsonProperty('isTutor', LazyBoolean, false)
    public isTutor: LazyBoolean = new LazyBoolean();
}