import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';
import InlineSVG from 'react-inlinesvg';
import Session from '$src/core/Session';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';


interface IProps {
    items: JSX.Element[];
    autoPlay: boolean;
    autoPlayInterval: number;
    itemsForSmallScreen: number;
    itemsForMediumScreen: number;
    itemsForLargeScreen: number;
    disabledArrows?: boolean;
    onRightArrowClick?: () => void;
    onLeftArrowClick?: () => void;
    startIndex?: number;
}

export default function Carousel(props: IProps) {

    function onRightArrowClick() {
        if(props.onRightArrowClick) {
            props.onRightArrowClick();
        }
    }

    function onLeftArrowClick() {
        if(props.onLeftArrowClick) {
            props.onLeftArrowClick();
        }
    }

    function renderArrowButtons() {
        return (
            <>
                <GTIconButton
                    additionalClassNames='carousel__container__slide-prev'
                    id='SlidePrev'
                    ariaLabel={Session.instance.storage.translation.GetString('Carousel:PrevSlide')}
                    tooltipText={Session.instance.storage.translation.GetString('Carousel:PrevSlide')}
                    onClick={() => onLeftArrowClick()}
                >
                    <InlineSVG src={ArrowLeft} />
                </GTIconButton>

                <GTIconButton
                    additionalClassNames='carousel__container__slide-next'
                    id='SlideNext'
                    ariaLabel={Session.instance.storage.translation.GetString('Carousel:NextSlide')}
                    tooltipText={Session.instance.storage.translation.GetString('Carousel:NextSlide')}
                    onClick={() => onRightArrowClick()}
                >
                    <InlineSVG src={ArrowRight} />
                </GTIconButton>
            </>
        );
    }
    return (
        <div className="carousel__container">
            <Swiper
                autoplay={props.autoPlay ? {delay: props.autoPlayInterval} : false}
                loop={true}
                slidesPerView={"auto"}
                navigation={{
                    nextEl: '.carousel__container__slide-next',
                    prevEl: '.carousel__container__slide-prev',
                }}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}
                slideNextClass={".carousel__container__slide-next"}
                slidePrevClass={".carousel__container__slide-prev"}
                breakpoints={{
                    0: {
                        slidesPerView: props.itemsForSmallScreen,
                        spaceBetween: 0
                    },
                    698: {
                        slidesPerView: props.itemsForMediumScreen,
                        spaceBetween: 0
                    },
                    1042: {
                        slidesPerView: props.itemsForLargeScreen,
                        spaceBetween: 0
                    }

                }}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper"

            >

                {props.items.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            {item}
                        </SwiperSlide>
                    );
                })}

            </Swiper>
            {props.disabledArrows ? null : renderArrowButtons()}
        </div>
    )
}