import { JsonObject, JsonProperty } from 'json2typescript';



@JsonObject
export class RemoveRefreshTokenRequest {

    @JsonProperty('refreshToken', String, false)
    public refreshToken: string = '';

    @JsonProperty('userID', Number, false)
    public userID: number;

}
