import React, { useEffect, useState } from "react";

import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import { Translate } from "$src/components/shared/Translate";
import { isSuccess } from "$src/util/Result";
import ItemService from '$src/core/Services/ItemService';
import { Item } from '$src/storage/models/Item';
import { EItemDetailCallerContextType, ERecommendedItemsByUserInterestsOrdering } from '$src/storage/models/enums';
import { ItemSummary } from '$components/item/itemSummaries/ItemSummary';
import GTButton from "$components/shared/Atoms/GTButton";
import MyRecommendationsByInterestsOrderBy from '$components/myRecommendations/MyRecommendationsByInterestsOrderBy';
import Carousel from "$components/shared/Carousel";
import { useHistory } from "react-router";


interface IProps {
    appereance: 'Page' | 'HomePanel';
}

/**
 * Component that renders one Power BI report
 * @param name The name of the report 
 */
export default function MyRecommendationsByInterests(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [loadMoreData, setLoadMoreData] = useState<boolean>(false);
    const [recommendedItems, setRecommendedItems] = useState<Item[]>([]);
    const [dataSettings, setDataSettings] = useState<{
        currentPage: number;
        pageSize: number;
        orderBy: ERecommendedItemsByUserInterestsOrdering;
    }>({
        currentPage: 1,
        pageSize: 5,
        orderBy: ERecommendedItemsByUserInterestsOrdering.Subject
    });
    const [hasMoreData, setHasMoreData] = useState<boolean>(true);
    const history = useHistory();


    useEffect(() => {
        if (loadMoreData) {
            setDataSettings({ ...dataSettings, currentPage: dataSettings.currentPage + 1 });
        }
    }, [loadMoreData]);

    useEffect(() => {
        ItemService.instance.getRecommendedLessonByUserInterests(dataSettings.orderBy, dataSettings.currentPage, dataSettings.pageSize).then(items => {
            if (isSuccess(items)) {
                const newItems = items as Array<Item>;
                const hasMoreData = newItems.length > dataSettings.pageSize;
                if (hasMoreData) newItems.pop();
                setHasMoreData(hasMoreData);
                setRecommendedItems([...recommendedItems, ...newItems]);
            }
            setIsLoaded(true);
            setLoadMoreData(false);
        });
    }, [dataSettings.currentPage, dataSettings.orderBy])

    const MyRecommendations = () => {
        return <div className="my-recommendations__page">
            <GTButton onClick={() => { history.push('/myrecommendations') }}>
                <Translate>MyRecommendations:AllRecommendations</Translate>
            </GTButton>
        </div>
    }

    function convertToJSXElement() {
        return recommendedItems.map(i => <ItemSummary
            item={i}
            parentHeadingLevel={1}
            itemCallerContext={EItemDetailCallerContextType.MyRecommendationsByInterests}
            parentCatalogFolderId={0}
            parentTrainingPlanId={0}
            parentAssignmentId={0}
            key={i.sId}
        />)
    }

    return (
        <>
            {isLoaded && recommendedItems.length >= 1 && <div className="my-recommendations__sorting">
                <MyRecommendationsByInterestsOrderBy {...props} value={dataSettings.orderBy} onChange={(value) => {
                    setIsLoaded(false);
                    setRecommendedItems([]);
                    setDataSettings({ ...dataSettings, currentPage: 1, orderBy: Number(value) })
                }} />
            </div>}
            <div className="my-recommendations__items">
                {isLoaded && <Carousel items={convertToJSXElement()}
                    itemsForLargeScreen={3}
                    itemsForMediumScreen={2}
                    itemsForSmallScreen={1}
                    autoPlay={globalConfig.recommendedLessonsProperties.autoPlayByInterests}
                    autoPlayInterval={globalConfig.recommendedLessonsProperties.autoPlayIntervalByInterests}
                    disabledArrows={recommendedItems.length > 1 ? false : true}
                />}
                {!isLoaded && <ProgressSpinner />}
            </div>
            {isLoaded && recommendedItems.length === 0 && <Translate>MyRecommendations:AdjustSettings</Translate>}
            {hasMoreData && props.appereance === 'Page' &&
                <div className="my-recommendations__more">
                    {loadMoreData &&
                        <ProgressSpinner />}
                    {!loadMoreData &&
                        <GTButton onClick={() => {
                            setLoadMoreData(true);
                        }}>
                            <Translate>MyRecommendations:LoadMore</Translate>
                        </GTButton>}
                </div>}
            <div className="my-recommendations__buttons">
                {props.appereance === 'HomePanel' && isLoaded && recommendedItems.length >= 1 && <MyRecommendations />}
            </div>
        </>
    )
}