import React, { useEffect } from 'react';

import Logger from '$src/core/Logger';
import SsoServiceProviderService from '$src/core/Services/SsoServiceProviderService';
import Session from '$src/core/Session';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { SsoSuiConfigurationIdentityProvider } from '$src/storage/models/SsoSuiConfigurationIdentityProvider';
import { Alert } from '../shared/WarningsAndErrors/Alert';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { SsoCreateUserResponse } from '$src/storage/models/SsoCreateUserResponse';

/**
 * @export
 * @interface ISsoUserCreationProps
 */
 export interface ISsoUserCreationProps {
    provider: SsoSuiConfigurationIdentityProvider;
    onFailed(): void;
    onLoading(isLoading: boolean): void;
}

/**
 * Will call ssosp to create a user and redirect to login after finished
 * Will also display a loading spinner with info text
 */
export const SsoUserCreation: React.FC<ISsoUserCreationProps> = (_props: ISsoUserCreationProps) => {
    const loggerLocality: string = 'SsoServiceProviderService';

    const [isLoading, setIsLoading] = React.useState(false);
    const [isFailed, setIsFailed] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const fetchFailed = (fetchedData: Response | SsoCreateUserResponse) => {
        Logger.log(loggerLocality, "Failed to crate user from sso auth", fetchedData);
        setIsLoading(false);
        setIsFailed(true);
        _props.onFailed();
        _props.onLoading(false);
    }

    useEffect(() => {
        if (SsoServiceProviderService.instance.isAnySsoEnabled() && !Session.instance.loginUser?.hasSsoId) {
            setIsLoading(true);
            _props.onLoading(true);
            Logger.log(loggerLocality, "Create gt user by sso");
            SsoServiceProviderService.instance.createUser(_props.provider.identityProvider)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        setErrorMessage('Login:SsoUserCreationFailed');
                        fetchFailed(response);
                        return Promise.reject();
                    }
                })
                .then((responseObj) => {
                    if (responseObj.isSuccess) {
                        setIsLoading(false);
                        SsoServiceProviderService.instance.startSso(_props.provider);
                    } else {
                        if (responseObj.subStatusCode > 0) {
                            setErrorMessage(CustomErrorMessage.getErrorCodeMessageString(responseObj.subStatusCode));
                        } else {
                            setErrorMessage('Login:SsoUserCreationFailed');
                        }

                        fetchFailed(responseObj);
                    }
            });
        }
    }, []);

    return (
        <>
            <div>
                { isLoading && <ProgressSpinner><Alert alertAppereance="input-message" alertType="success" message="Login:SsoUserCreating"></Alert></ProgressSpinner> }
                { isFailed && <Alert alertAppereance="box" alertType="error" message={errorMessage}></Alert> }
            </div>
        </>
    );
}