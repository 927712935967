import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import InlineSVG from 'react-inlinesvg';
import DateHelper from '$src/util/DateHelper';
import { NumberHelper } from '$src/util/NumberHelper';
import { useHistory } from "react-router";
import GTButton from '$components/shared/Atoms/GTButton';

import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';

import PurchaseService from '$src/core/Services/PurchaseService';
import { isSuccess } from '$src/util/Result';
import { CheckoutItemsStatusResponse } from '$src/storage/models/Purchase/CheckoutItemsStatusResponse';
import Session from '$src/core/Session';
import { ECheckoutRegistrationStatus } from '$src/storage/models/enums';

import IconOk from '$resources/svgs/filesharing/success.svg';
import IconFailed from '$resources/svgs/misc/cancel.svg';

const getTranslation = (str: string) => {
    return Session.instance.storage.translation.GetString(`PaymentBooking:${str}`);
}

/**
 * Shows the list of purchased items after the checkout+payment with the registration status of each user
 * This page is not for a student user, this page is for admins to review the status of their booking
 */
export default function CheckoutItemsStatus() {
    const { referenceCode } = useParams<{ referenceCode: string }>(); // identifies the checkout
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [loadPurchasedItems, setLoadPurchasedItems] = useState<boolean>(true);
    const [items, setItems] = useState<CheckoutItemsStatusResponse[]>([]);
    const history = useHistory();
    const isUserPaymentAdmin = Session.instance.loginUser?.roles.includes("PaymentBookingOverviewAdmin") ? true : false;

    useEffect(() => {
        if (loadPurchasedItems) {
            setLoadPurchasedItems(false);
            PurchaseService.instance.getPurchasedCheckoutItems(referenceCode)
                .then(response => {
                    if (isSuccess<CheckoutItemsStatusResponse>(response)) {
                        setItems(response as Array<CheckoutItemsStatusResponse>);
                        setIsLoaded(true);
                    }
                });
        }
    }, []);

    return (
        <div className="l-container payment-checkout-items">
            <Heading headingLevel={1} cssClass="heading__Title">
                <Translate>Purchase:PurchasedItems</Translate>
            </Heading>
            <div>
                <Translate>Purchase:ConfirmationPhraseAdmin</Translate>
            </div>
            <div className="payment-booking-overview--grid-container">
                {
                    isLoaded && items &&
                    <LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
                        <IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
                            <Grid data={items} >
                                <GridNoRecords>
                                    <Translate>PaymentBooking:NoData</Translate>
                                </GridNoRecords>

                                <GridColumn field="user" title={getTranslation('CheckoutStatusUser')} />

                                <GridColumn field="itemTitle" title={getTranslation('CheckoutStatusItem')}  
                                    cell={row => {
                                        if (row.dataItem != null && row.dataItem instanceof CheckoutItemsStatusResponse) {
                                            return <td>
                                                <div className='grid-inline-cell'>
                                                    { row.dataItem.itemTitle + 
                                                     (row.dataItem.classStartDate instanceof Date ? ', ' + DateHelper.getDatePeriod(row.dataItem.classStartDate, row.dataItem.classEndDate) : '') +
                                                     (row.dataItem.classCities !== "" ? ', ' + row.dataItem.classCities : '') }
                                                </div>
                                            </td>
                                        }
                                        return <td></td> //fallback
                                    }}
                                />

                                <GridColumn field="registrationStatus" title={getTranslation('CheckoutStatusStatus')}
                                    cell={row => {
                                        if (row.dataItem != null && row.dataItem instanceof CheckoutItemsStatusResponse) {
                                            return <td>
                                                <div className='grid-inline-cell'>
                                                    { row.dataItem.registrationStatus === ECheckoutRegistrationStatus.Succeeded && <InlineSVG class='icon-success' src={IconOk} style={{ outerWidth: 16 }} /> }
                                                    { row.dataItem.registrationStatus === ECheckoutRegistrationStatus.NotProcessed && <><InlineSVG class='icon-error' src={IconFailed} style={{ outerWidth: 16 }} /><Translate>PaymentBooking:StatusNotProcessed</Translate></> }
                                                    { row.dataItem.registrationStatus === ECheckoutRegistrationStatus.Failed && <><InlineSVG class='icon-error' src={IconFailed} style={{ outerWidth: 16 }} /><Translate>PaymentBooking:StatusFailed</Translate></> }
                                                    { row.dataItem.registrationStatus === ECheckoutRegistrationStatus.Canceled && <><InlineSVG class='icon-error' src={IconFailed} style={{ outerWidth: 16 }} /><Translate>PaymentBooking:StatusCancelled</Translate></> }
                                                </div>
                                            </td>
                                        }
                                        return <td></td> //fallback
                                    }}
                                />

                                <GridColumn field="cost" title={getTranslation('CheckoutStatusCost')} 
                                    cell={row => {
                                        if (row.dataItem != null && row.dataItem instanceof CheckoutItemsStatusResponse) {
                                            return <td>
                                                <div className='grid-inline-cell'>
                                                    { NumberHelper.getFormattedPrice(row.dataItem.cost, row.dataItem.currency) }
                                                </div>
                                            </td>
                                        }
                                        return <td></td> //fallback
                                    }}
                                />
                            </Grid>
                            <div className="checkout-items-status__buttons">
                                <GTButton onClick={() => history.push('/paymentBooking')} >
                                    <Translate>PaymentBooking:MakeOtherPurchase</Translate>
                                </GTButton>
                                <GTButton onClick={() => history.push('/paymentBookingJournal')} >
                                    <Translate>PaymentBooking:GoToBookingJournal</Translate>
                                </GTButton>
                                {isUserPaymentAdmin ?
                                    <GTButton onClick={() => history.push('/more')} >
                                        <Translate>PaymentBooking:GoToMore</Translate>
                                    </GTButton>
                                    :
                                    <GTButton onClick={() => history.push('/myTeam/default')} >
                                        <Translate>PaymentBooking:GoToMyTeam</Translate>
                                    </GTButton>}
                            </div>
                        </IntlProvider>
                    </LocalizationProvider>
                    
                }
                {!isLoaded && <ProgressSpinner />}
            </div>
        </div>
    )
}
