import React, { useEffect, useState } from "react";
import { MenuBreadCrumb } from "../breadCrumb/MenuBreadCrumb";
import { Heading } from "$src/components/shared/Heading";
import { Translate } from "$src/components/shared/Translate";
import { HeadingCollapsible } from "$src/components/shared/HeadingCollapsible";
import { UnmountClosed } from "react-collapse";
import PerformanceCheckTable from "$src/components/Skills/PerformanceCheckTable";
import UserService from "$src/core/Services/UserService";
import { isSuccess } from "$src/util/Result";
import { User } from "$src/storage/models/User";
import Session from "$src/core/Session";
import { PerformanceCheckTableItem } from "$src/components/Skills/PerformanceCheckItem";
import { IPanelState } from "$src/storage/models/UserPreferences/IPanelState";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import { CheckBox } from '$components/shared/CheckBox';
import { EPerformanceCheckStatus } from "$src/storage/models/enums";
import { useParams } from "react-router";
import { BreadCrumbElement } from "$src/storage/models/BreadCrumbElement";
import GtError from "$src/util/GtError";


export default function PerformanceCheckRequest() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [employees, setEmployees] = useState<EmployeePerformanceCheck[]>([]);
    const [allPanel, setAllPanel] = useState<IPanelState[]>([]);
    const [showOnlyNewRequests, setShowOnlyNewRequests] = useState<boolean>(false);
    const bossRelationCodeParam = useParams<{ bossRelationCode: string }>();
    const [bossRelationCode] = useState<string>(bossRelationCodeParam.bossRelationCode);

    useEffect(() => {
        document.title = globalConfig.appProperties.title + ' - ' + Session.instance.storage.translation.GetString("PerformanceCheckRequest:Title")
    });

    useEffect(() => {
        if (!isLoaded) {
            let response: GtError | User[];
            const fechtData = async () => {
                if (bossRelationCode.localeCompare("default") === 0) {
                    response = await UserService.instance.getEmployees();
                } else {
                    response = await UserService.instance.getEmployeesOfRelation(bossRelationCode);
                }
                if (isSuccess<User[]>(response)) {
                    const tempEmployees: EmployeePerformanceCheck[] = [];
                    const panelList: IPanelState[] = [];
                    const loadDetails = response.map((employee) => {
                        const panel = new IPanelState();
                        panel.isOpened = false;
                        panel.panelId = employee.id.toString();
                        panelList.push(panel);
                        return Session.instance.storage.skill.getSkillsForPerformanceCheck(employee.id, Session.instance.getUserLanguageCodeOrFallBack, bossRelationCode).then(response => {
                            if (isSuccess<PerformanceCheckTableItem[]>(response)) {
                                tempEmployees.push({
                                    employeeId: employee.id,
                                    employeeFullname: employee.fullName,
                                    performanceCheckItems: response
                                });
                            }
                        });
                    })
                    Promise.all(loadDetails).then(() => {
                        setEmployees([...tempEmployees.sort((a, b) => a.employeeFullname.localeCompare(b.employeeFullname))]);
                        setAllPanel([...panelList]);
                        setIsLoaded(true);
                    });
                }
            }

            fechtData()
        }
    }, [isLoaded]);

    function getPanel(emplyoeeId: number) {
        return allPanel.find((panel) => panel.panelId === emplyoeeId.toString());
    }

    function toggleOpenendState(panel: IPanelState | undefined) {
        const panels = allPanel;
        if (panel) {
            panels.map((panelList) => {
                if (panelList.panelId == panel.panelId) {
                    panelList.isOpened = !panelList.isOpened;
                }
            })
            setAllPanel([]);
            setAllPanel([...panels]);
        }

    }

    function filterEmplyoees() {
        const filteredEmplyoees: EmployeePerformanceCheck[] = [];
        if (showOnlyNewRequests) {
            employees.map((employee) => {
                const tmpListItem: PerformanceCheckTableItem[] = [];
                employee.performanceCheckItems.map((item) => {
                    if (item.status === EPerformanceCheckStatus.Requested) {
                        tmpListItem.push(item);
                    }
                })
                if (tmpListItem.length > 0) {
                    const tempEmployee: EmployeePerformanceCheck = { employeeFullname: employee.employeeFullname, employeeId: employee.employeeId, performanceCheckItems: tmpListItem };
                    filteredEmplyoees.push(tempEmployee);
                }
            })
        } else {
            employees.map((employee) => {
                filteredEmplyoees.push(employee);
            });
        }
        return filteredEmplyoees;
    }

    function filterOnlyNewRequestsClick(checked: boolean) {
        setShowOnlyNewRequests(checked);
    }

    function renderEmplyoee() {
        const elements: JSX.Element[] = [];
        filterEmplyoees().map((employee) => {
            const panel = getPanel(employee.employeeId);
            if (panel) {
                elements.push(
                    <div key={employee.employeeId}>
                        <div>
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                onToggleOpenedState={() => toggleOpenendState(panel)}
                                isOpened={panel.isOpened}>
                                {employee.employeeFullname}
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={panel.isOpened}>
                                <PerformanceCheckTable
                                    isBoss={true}
                                    skills={employee.performanceCheckItems}
                                    emplyoeeId={employee.employeeId}
                                    reloadPerformanceCheckTargetSkills={() => setIsLoaded(false)}
                                    parentHeadingLevel={1}
                                />
                            </UnmountClosed>
                        </div>
                    </div>
                )
            }

        })
        return elements;
    }

    function getBcElements() {
        const bcElements: BreadCrumbElement[] = [];
        bcElements.push(
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/myTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:MyTeam')
            },
            {
                navigationPath: `/myTeam/performanceCheckRequest/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:PerformanceCheckRequest')
            }
        );

        return bcElements;
    }

    return (
        <div>
            <div className="l-container">
                <MenuBreadCrumb breadCrumbElementsToAppend={getBcElements()} />
                <div className="l-box--wide my-performanceCheck__header">
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title"><Translate>PerformanceCheckRequest:Title</Translate></Heading>
                    <Translate>PerformanceCheckRequest:SubTitle</Translate>
                </div>
                <div className="performanceCheck--checkBox">
                    <CheckBox
                        id="cbFilterOnlyNewRequests"
                        className="input-field"
                        onClick={(e) => filterOnlyNewRequestsClick(e.currentTarget.checked)}
                        defaultChecked={showOnlyNewRequests} />
                    <div className="my-Lessons__checkbox-text performanceCheck--checkboxText"><Translate>PerformanceCheckRequest:cbOnlyNewRequests</Translate></div>
                </div>
                {isLoaded ? renderEmplyoee() : <ProgressSpinner />}
            </div>
        </div>
    )
}

export class EmployeePerformanceCheck {
    employeeId: number = 0;
    employeeFullname: string = '';
    performanceCheckItems: PerformanceCheckTableItem[] = [];
}