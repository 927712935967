import { PopupNewsSummary } from '$components/newsPanel/PopupNewsSummary';
import Carousel from '$components/shared/Carousel';
import NewsService from '$src/core/Services/NewsService';
import Session from '$src/core/Session';
import { News } from '$src/storage/models/News';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import { ModalPopup } from '$src/components/shared/ModalPopup';

export default function PopupNews() {

    const [popupNews, setPopupNews] = useState<News[]>([]);
    const [shouldShowPopupNews, setShouldShowPopupNews] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [viewedNews] = useState<number[]>([]);

    useEffect(() => {
        async function loadPopupNews() {
            await NewsService.instance.getPopupNews(Session.instance.getUserLanguageCodeOrFallBack).then((res) => {
                if (isSuccess<News[]>(res)) {
                    if (res.length > 0) {
                        setPopupNews(res);
                        setShouldShowPopupNews(true);
                    }
                }
            })
        }
        loadPopupNews();
    }, []);

    useEffect(() => {
        const news = popupNews[currentIndex];
        if (news && !viewedNews.includes(news.pkNews)) {
            viewedNews.push(news.pkNews);
        }
    }, [currentIndex, popupNews]);

    useEffect(() => {
        if (!shouldShowPopupNews && viewedNews.length > 0) {
            NewsService.instance.setViewedPopupNews(viewedNews);
        }
    }, [shouldShowPopupNews]);

    function onPreviousClick() {
        if (currentIndex === 0) {
            setCurrentIndex(popupNews.length - 1);
        } else {
            setCurrentIndex(currentIndex - 1);
        }
    }

    function closeNewsPopup() {
        // Save "Visited news here"
        setShouldShowPopupNews(false);
    }

    function onNextClick() {
        if (currentIndex + 1 < popupNews.length) {
            if (!viewedNews.includes(popupNews[currentIndex + 1].pkNews)) {
                viewedNews.push(popupNews[currentIndex + 1].pkNews);
            }
            setCurrentIndex(currentIndex + 1);
        } else {
            setCurrentIndex(0);
        }
    }
    const elements: JSX.Element[] = [];
    popupNews.map((news, index) => {
        elements.push(<PopupNewsSummary key={index} news={news} />);
    })

    if (shouldShowPopupNews && popupNews.length > 0) {
        return (
            <ModalPopup contentCssClass={'modal__content-large'} title={''}
                isOpen={true} onRequestClose={() => closeNewsPopup()} >
                <div className="popupNews__container">
                    <Carousel items={elements}
                        itemsForLargeScreen={1}
                        itemsForMediumScreen={1}
                        itemsForSmallScreen={1}
                        autoPlay={false}
                        startIndex={currentIndex}
                        autoPlayInterval={0}
                        key={'popupNews_carousel'}
                        onLeftArrowClick={() => onPreviousClick()}
                        onRightArrowClick={() => onNextClick()} />
                </div>
            </ModalPopup>
        )
    } else {
        return null;
    }

}