import SessionCache from "$storage/cache/SessionCache";
import { IPanelState } from "$storage/models/UserPreferences/IPanelState";


export default class IPanelStateStorage extends SessionCache<string, IPanelState> {


    public getPanelState(requestetId: string) {
        const panelState = this.getObjectFromCache(requestetId);
        let bool = false;
        if (panelState === null) {
            const newPanelState = new IPanelState;
            newPanelState.isOpened = false;
            newPanelState.panelId = requestetId;
            this.saveObjectToCache(requestetId, newPanelState);
            bool = false;
        } else {
            bool = panelState.isOpened;
        }
        return bool;
    }

    public isExpanded(requestetId: string) {
        const panelState = this.getObjectFromCache(requestetId);
        this.removeObjectFromCache(requestetId);
        if (panelState) {
            if (panelState.isOpened) {
                panelState.isOpened = false;
            } else {
                panelState.isOpened = true;
            }
            this.saveObjectToCache(requestetId, panelState);
            return panelState.isOpened;
        }
        return false;
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }

}