import React from "react"


/**
 * **GTHorizontalTableRow Props**
 *
 * @export
 * @interface IGTHorizontalTableRowProps
 */
export interface IGTHorizontalTableRowProps {

    /**
     * The row content
     */
    rowContent: GTRowContent[];

    /**
     * **Optional** The optional row class, default is 'div-table__horizontal-table-row zebra'
     */
    tableRowClassName?: string;
}



/**
 * Global Teach Table Horizontal Row Component
 */
export default function GTHorizontalTableRow(props: IGTHorizontalTableRowProps) {
    return (
        <div role="row" className={`${props.tableRowClassName}`} >
            {props.rowContent.map(content => {
                return (
                    <React.Fragment key={`content_${content.cellId}`}>
                        <div role="columnheader" className="mobileOnly" key={`header_${content.cellId}`}>
                            {content.mobileColumnHeader}
                        </div>
                        <div role="cell" className={content.cellClassName ? content.cellClassName : ''}>
                            {content.cell}
                        </div>
                    </React.Fragment>
                )
            })}

        </div>
    )
}

GTHorizontalTableRow.defaultProps = {
    tableRowClassName: 'div-table__horizontal-table-row zebra'
}

export class GTRowContent {
    public cellId: string;
    public cell: JSX.Element | string;
    public cellClassName?: string;
    public mobileColumnHeader: string;
}