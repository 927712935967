import { SearchResult } from '$src/storage/models/search/elk/SearchResult';
import React, { useEffect, useState } from 'react';

import { EItemDetailCallerContextType, EItemType, } from '$src/storage/models/enums';
import Session from '$src/core/Session';
import { NavLink } from 'react-router-dom';
import SearchService from '$src/core/Services/SearchService';
import { isSuccess } from '$src/util/Result';
import { LazyFetchSearchResult } from '$src/storage/models/search/elk/LazyFetchSearchResult';
import ItemSummaryHelper from '$src/util/ItemSummaryHelper';
import ItemSummaryMinimalDetail from '$src/components/item/itemSummaries/ItemSummaryMinimalDetail';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';

interface IProps {
    searchResult: SearchResult;
    onSearch: (
        searchText: string,
    ) => void;
    searchValue: string;
}
export default function SearchResultSummary(props: IProps) {
    const [isAssignment, setIsAssignment] = useState<boolean>(false);
    const [assignmentId, setAssignmentId] = useState<number>(0);
    const [lazyFetchSearchResult, setLazyFetchSearchResult] = useState<LazyFetchSearchResult>();

    useEffect(() => {
        SearchService.instance.lazyFetchSearchResultData(props.searchResult.itemId).then((res: LazyFetchSearchResult) => {
            if (isSuccess(res)) {
                setIsAssignment(res.isAssignment);
                setAssignmentId(res.assignmentId);
                setLazyFetchSearchResult(res);
            }
        });

    }, [])


    function getDetailLink(): string {
        let link = '';
        if (props.searchResult.itmTPId > 0) {
            link = '/trainingPlanDetail/' + props.searchResult.itmTPId + '?context=' + EItemDetailCallerContextType.Search;
        }
        else if (props.searchResult.itemType == EItemType.TrainingPlan) {
            link = '/trainingPlanDetail/' + props.searchResult.itemId;
        } else if (props.searchResult.itemType == EItemType.Folder) {
            link = '/catalog/' + props.searchResult.itemId;
        } else {
            link = '/itemDetail/' + props.searchResult.itemId;
        }
        if (isAssignment) {
            link += '?context=' + EItemDetailCallerContextType.Assignment + '&asId=' + assignmentId;
        }
        return link;
    }

    function getCatalogFolders(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        props.searchResult.catalogParents.map((val, index) => {
            elements.push(
                <li key={index}>
                    <NavLink
                        aria-label={Session.instance.storage.translation.GetString("ItemSummary:LinkAriaLabel").replace("{title}", val.catalogPath)}
                        to={'/catalog/' + val.parentId}
                        onClick={(e) => e.stopPropagation()}
                    >{val.catalogPath}</NavLink>
                </li>
            );
        });
        return elements;
    }

    function onSearchKeyboard(e: React.KeyboardEvent<HTMLLIElement>, val: string) {
        if (e.keyCode == 13) {
            props.onSearch(val);
        }
    }

    function getSubjectList(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        props.searchResult.subjects.map((val, index) => {
            elements.push(
                <li className={props.searchValue === val ? "active" : ""}
                    tabIndex={0}
                    key={index}
                    onClick={() => props.onSearch(val)}
                    onKeyDown={(e) => onSearchKeyboard(e, val)}
                >
                    {val}
                </li>
            );
        });
        return elements;
    }

    if (lazyFetchSearchResult) {
        const itemMinimalDetails = ItemSummaryHelper.getItemSummaryDetailsForSearchResults(props.searchResult, lazyFetchSearchResult, getDetailLink())

        return (
            <div className="search-result__summary">
                {!globalConfig.searchProperties.elkHideCatalogPath &&
                    <div className="search-result__summary-folders" >
                        <ul>
                            {getCatalogFolders()}
                        </ul>
                    </div>
                }
                <div className="search-result__summary-content">
                    <ItemSummaryMinimalDetail
                        id={`Item_${props.searchResult.itemId}`}
                        itemDetails={itemMinimalDetails}
                        parentHeadingLevel={1}
                        isAssignment={isAssignment}
                    />
                </div>
                <div className="search-result__summary-subject-container">
                    <ul>
                        {getSubjectList()}
                    </ul>
                </div>
            </div>
        );
    } else {
        return (
            <ProgressSpinner />
        )
    }

}