import React, { useEffect, useState } from 'react';
import { UnmountClosed } from 'react-collapse';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import BookingJournalFilter from '$components/PaymentBookingJournal/BookingJournalFilter';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import BookingJournalResults from '$components/PaymentBookingJournal/BookingJournalResults';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';

import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import { BookingJournalFilterRequest } from '$src/storage/models/Payment/BookingJournalFilterRequest';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { isSuccess } from '$src/util/Result';
import Session from '$src/core/Session';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import { RouteComponentProps } from 'react-router';
import { PaymentMyTeamJournal } from '$src/storage/models/Payment/PaymentMyTeamJournal';

interface IMatchParams {
    /** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
    bossRelationCode: string;
}

type IProps = RouteComponentProps<IMatchParams>
/**
 * Page to view user-lesson purchases with various filters 
 * @returns 
 */
export default function PaymentBookingJournal(props: IProps) {
    const [journalData, setJournalData] = useState<PaymentBookingJournalResponse[]>();
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bossRelationCode, setBossRelationCode] = useState<string>("");
    const [myTeamConf, setMyTeamConf] = useState<PaymentMyTeamJournal>();
    const [codeIsLoaded, setCodeIsLoaded] = useState<boolean>(false);

    const defaultTeamMatch = 'default';

    function getSectionConfigurationByBossRelationCode(bossRelationCode: string) {
        const defaultBossRelation = Session.instance.getLoggedInUserBossrelations().find(r => r.isDefault);
        if (bossRelationCode === defaultTeamMatch && defaultBossRelation != null) {
            bossRelationCode = defaultBossRelation.bossTypeCode;
        }
        let sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
            item => item.id.toLocaleLowerCase() === bossRelationCode.toLocaleLowerCase());
        if (sectionsConfig == null) {
            sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
                item => item.id.toLocaleLowerCase() === defaultTeamMatch);
        }
        sectionsConfig?.sectionsOrderAndDisplay.sort((n1, n2) => n1.order - n2.order);
        return sectionsConfig;
    }

    // This is only for the context MyTeam to give bossCode to the api, when the bossCode are not empty it checks the right for the boss otherwise its the boss view
    useEffect(() => {
        const bossRelationCodeTmp = props.match.params.bossRelationCode;
        setBossRelationCode(bossRelationCodeTmp);
        if (bossRelationCode) {
            const tmpConfig = getSectionConfigurationByBossRelationCode(bossRelationCodeTmp);
            const myTeamConfigTmp: PaymentMyTeamJournal = {
                changeClass: tmpConfig?.payChangeClass ? true : false,
                changeParticipant: tmpConfig?.payChangeParticipant ? true : false,
                cancel: tmpConfig?.payCancel ? true : false,
                comment: tmpConfig?.payComment ? true : false,
                bossRelationCode: bossRelationCode
            };
            setMyTeamConf(myTeamConfigTmp);
        }
        setCodeIsLoaded(true);
    }, [bossRelationCode])

    /** Load data from api */
    function loadData(filter: BookingJournalFilterRequest | undefined) {
        setIsLoading(true);
        PaymentAdminService.instance.getPaymentBookingJournal(filter, bossRelationCode).then(data => {
            if (isSuccess<PaymentBookingJournalResponse[]>(data)) {
                setJournalData(data);
            } else {
                setJournalData(undefined);
            }
            setIsLoading(false);
        })
    }

    function getBcElements() {

        if (props.match.params.bossRelationCode === undefined) {
            return undefined;
        }

        const bcElements: BreadCrumbElement[] = [];
        bcElements.push(
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/myTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:MyTeam')
            },
            {
                navigationPath: `/paymentJournalMyTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:PaymentBookingJournal')
            }
        );
        return bcElements;
    }

    return (
        <>
            <div className="l-container payment-booking-journal">
                <div className="">
                    <MenuBreadCrumb breadCrumbElementsToAppend={getBcElements()} />

                    <Heading headingLevel={1} cssClass="heading__Title">
                        <Translate>Payment:BookingJournal</Translate>
                    </Heading>

                    <div>
                        <Translate>PaymentBookingJournal:SubTitle</Translate>
                    </div>
                </div>
                
                <div className="l-element--striped">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass="payment-booking-overview--filter__container--no-margin "
                        headingCssClass="heading__Level2"
                        isOpened={isFilterOpen}
                        onToggleOpenedState={() => setIsFilterOpen(!isFilterOpen)}
                    >
                        <Translate>PaymentBookingOverview:Filter</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={isFilterOpen}>
                        {codeIsLoaded && <BookingJournalFilter onFilterSet={(e) => loadData(e)} bossRelationCode={bossRelationCode} />}
                    </UnmountClosed>
                </div>

                {isLoading && <ProgressSpinner />}
                {!isLoading && journalData !== undefined && <BookingJournalResults myTeamConf={myTeamConf} data={journalData} />}
            </div>
        </>
    );
}