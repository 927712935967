import { GuestUserCredentials } from '$src/storage/models/GuestUserCredentials';
import { NumberResponse } from '$src/storage/models/NumberRepsonse';
import GtError from '$util/GtError';
import ServiceClient from '../ServiceClient';
import { NsuiConfigResponse } from '$src/storage/models/NsuiConfigResponse';
import { StringResponse } from '$src/storage/models/StringResponse';
import { ServiceInfo } from '$src/storage/models/ServiceInfo';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';

/**
 * ConfigService provides all Service Methods for the User 
 */
export default class ConfigService extends ServiceClient {
    protected static _instance: ConfigService | null = null;

    protected className = 'ConfigService';
    protected loggerLocality = 'ConfigService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ConfigService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get the guest user credentials from the server config
     *
     */
    public async getGuestUserCredentials(langCode: string): Promise<GuestUserCredentials | GtError> {
        return await this.get<GuestUserCredentials>('config/GetGuestUserCredentials', GuestUserCredentials, undefined, undefined, langCode);
    }

    public async getPasswordRecoveryMode(langCode: string): Promise<NumberResponse | GtError> {
        return await this.get<NumberResponse>('config/GetPasswordRecoverMode', NumberResponse, undefined, undefined, langCode);
    }

    public async getNsuiConfig(): Promise<NsuiConfigResponse | GtError> {
        return await this.get<NsuiConfigResponse>('config/nsuiwithauth', NsuiConfigResponse, undefined, undefined);
    }

    public async getBossRelationsAllowedToRegisterNewUsers(): Promise<StringResponse | GtError> {
        return await this.get<StringResponse>('config/userRegistrationByBoss', StringResponse, undefined, undefined);
    }

    /** Should it be forbidden to redeem a voucher on a tplan with registration status cancelled, rejected or schedule cancelled,
     * ACTUALLLY only for TPLAN */
    public async getIsForbiddenRedeemOnCancelledItems(): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('config/isForbiddenRedeemOnCancelledItems', BooleanResponse, undefined, undefined);
    }

    public async getServiceInfo(): Promise<ServiceInfo | GtError> {
        return await this.get<ServiceInfo>('config/serviceInfo', ServiceInfo, undefined, undefined);
    }
} 