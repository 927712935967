import { JsonObject, JsonProperty } from 'json2typescript';
import { GlossaryTermImages } from '$src/storage/models/Glossary/GlossaryTermImages';

/** All data of a "Glossar-Karte" */
@JsonObject
export class GlossaryTermDetail {
    // Id of the GlossaryTerm 
    @JsonProperty('id', Number, false)
    public id: number = 0;

    // sId of the GlossaryTerm 
    @JsonProperty('sid', String, false)
    public sid: string = '';

    // Title of the GlossaryTerm 
    @JsonProperty('title', String, false)
    public title: string = '';

    // abbreviation of the GlossaryTerm 
    @JsonProperty('abbreviation', String, false)
    public abbreviation: string = '';

    // synonyms of the GlossaryTerm 
    @JsonProperty('synonyms', String, false)
    public synonyms: string = '';

    // text of the GlossaryTerm 
    @JsonProperty('text', String, false)
    public text: string = '';

    // List of images of the GlossaryTerm 
    @JsonProperty('images', [GlossaryTermImages], false)
    public images: GlossaryTermImages[] = [];

    // isBookmarked of the GlossaryTerm 
    @JsonProperty('isBookmarked', Boolean, false)
    public isBookmarked: boolean = false;

    // isViewed of the GlossaryTerm 
    @JsonProperty('isViewed', Boolean, false)
    public isViewed: boolean = false;

    // defaultLanguage of the GlossaryTerm 
    @JsonProperty('defaultLanguage', String, false)
    public defaultLanguage: string = '';
}