import Logger from '$src/core/Logger';
import CachedObject from '$src/storage/cache/CachedObject';
import SessionCache from '$src/storage/cache/SessionCache';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { action, observable } from 'mobx';

// Use this Cache if you want to save objects into the Session Cache, and loading can / could be called parallel but you want only to load once

export default abstract class ContainerSessionCache<TKey, TObject> extends SessionCache<TKey, TObject> {
    @observable
    public isInitialized = false;
    @observable
    public isLoading = false;

    protected className = 'ContainerSessionCache';
    protected loggerLocality = 'Storage.ContainerSessionCache';

    protected loadingPromise: Promise<TObject[] | GtError>;

    protected cachedAt: string;

    constructor(cachingDuration: number = 3600,
        autoRemoveExpiredData?: boolean,
        isLanguageDependant?: boolean) {
        super(cachingDuration, autoRemoveExpiredData, isLanguageDependant);
        this._localCache = new Map<TKey, CachedObject<TObject>>();

        Logger.log(this.loggerLocality,
            `Create ContainerSessionCache Object`);
    }

    
    @action public async getObjects(...params: string[]): Promise<TObject[] | null> {
        const methodName = `${this.className}:getObjects()`;

        Logger.log(this.loggerLocality, `${methodName} isCacheExpired: ${this.isCacheExpired()},
         autoRemoveExpiredData: ${this._autoRemoveExpiredData}, isInitialized: ${this.isInitialized}, isLoading: ${this.isLoading}`);
         
        if(this.isCacheExpired() && (this._autoRemoveExpiredData || this._autoRemoveExpiredData == null) || !this.isInitialized && !this.isLoading) {
            // Load / Reload Data
            this.setIsInitialized(false);
            this.setIsLoading(true);
            this.clear();
            Logger.log(this.loggerLocality, `${methodName} loading data from server.`);
            this.loadingPromise = this.loadData(params.join(','))
        }
        if (this.isLoading) {
            // Wait for Data Reload if is loading
            Logger.log(this.loggerLocality, `${methodName} waiting for data loading from server.`);
            const response = await this.loadingPromise;
            // Logger.log(this.loggerLocality, `${methodName} got data from server: ${JSON.stringify(response)}`);
            if (isSuccess<TObject[]>(response)) {
                Logger.log(this.loggerLocality, `${methodName} successfully got data from server.`);
                const d = new Date();
                this.cachedAt = d.toISOString();
                this.setIsLoading(false);
                this.setIsInitialized(true);
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get data from server.`);
                console.error(`${this.loggerLocality} ${methodName} failed to get data from server, error = ${ GtError.toString() }.`);
                this.setIsLoading(false);
                this.setIsInitialized(false);
            }
        }
        // Return Data from Cache
        Logger.log(this.loggerLocality, `${methodName} return data from cache.`);
        return this.getObjectsFromCache();
    }

    @action protected setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @action protected setIsInitialized(value: boolean) {
        this.isInitialized = value;
    }

    protected isCacheExpired(): boolean {
        const cachedAt = (new Date(this.cachedAt)).getTime();
        const now = (new Date()).getTime();
        const age = (now - cachedAt) / 1000;
        return age > this.cachingDuration;
    }

    protected abstract async loadData(...params: string[]): Promise<TObject[] | GtError>

}