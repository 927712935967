import { EAttendanceStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class AttendedStatusConverter implements JsonCustomConvert<EAttendanceStatus> {
    // from WebApi Models\Enums.cs: Undefined=0, NotAttended=1, Attended=2
    public serialize(attendedStatus: EAttendanceStatus): number {
        switch (attendedStatus) {
            case EAttendanceStatus.NotAttended: return 1;
            case EAttendanceStatus.Attended: return 2;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EAttendanceStatus {
        switch (jsonValue) {
            case 1: return EAttendanceStatus.NotAttended;
            case 2: return EAttendanceStatus.Attended;
            default: return EAttendanceStatus.Undefined;
        }
    }
}
