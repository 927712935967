import { JsonObject, JsonProperty } from 'json2typescript';


@JsonObject
export class SubjectsSubscribedTo{
    @JsonProperty('subjectId', Number, false)
    public subjectId: number = 0;

    @JsonProperty('subjectName', String, false)
    public subjectName: string = '';

    @JsonProperty('numberOfNewItemsInSubject', Number, false)
    public numberOfNewItemsInSubject: number = 0;
}

/**
 * Class for getting all the Subjects the current user is subscribed to. Also contains information about the number of new items under his chosen subjects.
 */
@JsonObject
export class UserSubjectSubscriptions {
 
    /** Id of the User whom the current subscriptions belong to. */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** Subjects this user is subscribed to. */
    @JsonProperty('subscribedSubjects', [SubjectsSubscribedTo], false)
    public subscribedSubjects: SubjectsSubscribedTo[] | null = null;

}



