import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * This Model is used mainly for the PW Change callback. 
 * It is used to map a guid to an url.
 */
@JsonObject
export  class CallbackUrl {
    [key: string]: number | string | boolean;

    /** GUID */
    @JsonProperty('guid', String, false)
    public guid: string = '';

    @JsonProperty('objectGuid', String, false)
    public objectGuid: string = '';

    @JsonProperty('userId', Number, false)
    public userId: string = '';

    /** URL to be called  */
    @JsonProperty('url', String, false)
    public url: string = '';

    @JsonProperty('urlLives', Number, false)
    public urlLives: string = '';

    @JsonProperty('urlValidity', String, false)
    public urlValidity: string = '';

    @JsonProperty('autoLogin', Boolean, false)
    public autoLogin: string = '';

    @JsonProperty('id', Number, false)
    public id: string = '';

    @JsonProperty('isDirty', Boolean, false)
    public isDirty: string = '';

    @JsonProperty('identity', String, false)
    public identity: string = '';

    @JsonProperty('primaryKey', Number, false)
    public primaryKey: string = '';



}
