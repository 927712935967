
import React from 'react';
import { BrowserHelper } from '$src/util/BrowserHelper';
import ReactTooltip from 'react-tooltip';

export interface ITooltipProps extends ReactTooltip.Props {
    /**
     * Displays the tooltip in the default theme, else in highlight color
     */
    defaultTheme?: boolean;
    /**
     * Important, this ID must be unique in the DOM and must match the data-for of the element for which the tooltip is
     */
    id: string;
}

/**
 * Component to render ReactTooltip, for more information see here https://www.npmjs.com/package/react-tooltip
 * Tooltip is disabled for mobile, because an element that is hovered over on the desktop browser and displays the tooltip, 
 * will show the tooltip when clicked on in mobile iOS, so a button must be clicked twice for IOS mobile
 */
export class Tooltip extends React.Component<ITooltipProps> {
    constructor(props: ITooltipProps) {
        super(props)
    }

    public render() {
        return(
            <ReactTooltip
            className={this.props.defaultTheme ? '' : 'type-highlight'} 
            disable={BrowserHelper.isMobile()}
            {...this.props}/>
        )
    }
}