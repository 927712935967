import Session from '$core/Session';
import React, { useState, useEffect } from 'react';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import { VoucherValidity } from '$src/storage/models/Purchase/VoucherValidity';
import { EItemDetailCallerContextType, EItemType, ERegistrationStatus, EVoucherInvalidityReason } from '$src/storage/models/enums';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputText } from '$components/shared/InputText';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { Translate } from '$components/shared/Translate';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Item } from '$src/storage/models/Item';
import { isSuccess } from '$src/util/Result';
import DateHelper from '$src/util/DateHelper';
import { NumberHelper } from '$src/util/NumberHelper';
import Logger from '$src/core/Logger';
import IconF2F from '$resources/svgs/lessonTypes/internal-lesson.svg';
import IconTrainingPlan from '$resources/svgs/lessonTypes/training-plan.svg';
import IconWbt from '$resources/svgs/lessonTypes/online-lesson.svg';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';
import { ItemHelper } from '$src/util/ItemHelper';
import { NavLink } from 'react-router-dom';
import PurchaseService from '$src/core/Services/PurchaseService';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import ShoppingBasket from '$src/storage/models/ShoppingBasket/ShoppingBasket';
import { CheckoutRequest } from '$src/storage/models/Purchase/CheckoutRequest';
import { CheckoutResponse } from '$src/storage/models/Purchase/CheckoutResponse';
import { F2FClassDetail } from '$src/storage/models/F2F/F2FClassDetail';
import { Registration } from '$src/storage/models/Registration';
import { TrainingPlanSchedule } from '$src/storage/models/TrainingPlan/TrainingPlanSchedule';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import { useHistory } from 'react-router';
import ConfigService from '$src/core/Services/ConfigService';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
// import ShoppingBasket from '$src/storage/models/ShoppingBasket/ShoppingBasket';
// import { CheckoutRequest } from '$src/storage/models/Purchase/CheckoutRequest';
// import { CheckoutResponse } from '$src/storage/models/Purchase/CheckoutResponse';



const loggerLocality = 'Components.VoucherValidation';


/** 
 * Check the voucher code via API
 * An API failure will be translated to invalidityReason=ValidationFailed
 * in the VoucherValidity response.
 * */
async function checkVoucher(voucherCode: string): Promise<VoucherValidity> {
    Logger.log(loggerLocality, `${loggerLocality} checkVoucher()`);
    const response = await PurchaseService.instance.checkVoucher(voucherCode);
    if (isSuccess<VoucherValidity>(response)) {
        return response;
    } else {
        console.error(`checkVoucher failed`);
        const failureResponse = new VoucherValidity();
        failureResponse.isValid = false;
        failureResponse.invalidityReason = EVoucherInvalidityReason.ValidationFailed;
        return failureResponse;
    }
}


interface IProps {
    onVoucherUpdated?: (voucherCode: string, voucherCodeIsInvalid: boolean) => void; // called when a new voucher code was entered and checked (NB: an empty voucherCode is regarded as valid)
    dontUpdateShoppingBasket?: boolean; // if true, the component doesn't write the voucher code to the ShoppingBasket (since the parent does )
    dontListVoucherItems?: boolean; // if true, the component does not list the lessons to which the voucher code is restricted
    enableAutomaticRegistrationInVoucherPanel?: boolean; // if true, Users has an tplan or f2f registration with status requested and enters voucher code -> status accepted (if there is a seat free)

}


/**
 * Component that allows to enter a voucher code and then shows the discount of the voucher
 * and the items it is restricted to (if any)
 */
export default function VoucherValidation(props: IProps) {
    const [voucherCodeInput, setVoucherCodeInput] = useState<string>(ShoppingBasketStorage.instance.shoppingBasketContent.voucherCode);
    const [voucherValidity, setVoucherValidity] = useState<VoucherValidity | undefined>(undefined);
    const [requiresVoucherCheck, setRequiresVoucherCheck] = useState<boolean>(ShoppingBasketStorage.instance.shoppingBasketContent.voucherCode != '');
    const [itemAlertCssClass, setItemAlertCssClass] = useState<string>('hidden');
    const [itemErrorMsg, setItemErrorMsg] = useState<string>('');
    const [voucherItems, setVoucherItems] = useState<Item[] | null>(null); // null : voucher not restricted to items OR props.dontListVoucherItems is active
    const tr = Session.instance.storage.translation;
    const itemContext = EItemDetailCallerContextType.VoucherValidation;
    const history = useHistory();


    /** get the lesson Items for the given itemIds */
    async function getItems(itemIds: number[]): Promise<Item[]> {
        const items: Item[] = [];
        for (let i = 0; i < itemIds.length; i++) {
            const id = itemIds[i];
            Logger.log(loggerLocality, `${loggerLocality} get item ${id}`);
            const item = await Session.instance.storage.item.getItemDetail(id, itemContext);
            if (isSuccess<Item | null>(item)) {
                if (item != null) {
                    items.push(item);
                } else {
                    // one of the items in the voucher (does no longer) exist. 
                    console.error(`VoucherValidation: Failed to get item with id ${id}.`);
                }
            }
        }
        return items;
    }


    /** to be called at the end of the voucher check */
    function onFinishedVoucherCheck(voucherValidity: VoucherValidity | undefined, voucherItems: Item[] | null) {
        setRequiresVoucherCheck(false);
        setVoucherValidity(voucherValidity);
        setVoucherItems(voucherItems);
        // notify parent about voucher code change (the code itself is in singleton ShoppingBasketStorage.instance.shoppingBasketContent.voucherCode)
        if (props.onVoucherUpdated) {
            props.onVoucherUpdated(voucherCodeInput,
                voucherValidity != undefined && voucherValidity.voucherCode.length > 0 && !voucherValidity.isValid);
        }
    }


    useEffect(() => {
        if (requiresVoucherCheck) {
            if (voucherCodeInput.length > 0) {
                checkVoucher(voucherCodeInput).then((voucherValidity) => {
                    // if the voucher is restricted to certain items, then fetch their details if needed
                    if (voucherValidity.isValid && voucherValidity.itemIds.length > 0 && !props.dontListVoucherItems) {
                        getItems(voucherValidity.itemIds).then(items => {
                            onFinishedVoucherCheck(voucherValidity, items);
                        });
                    } else {
                        onFinishedVoucherCheck(voucherValidity, null);
                    }
                });
            } else {
                onFinishedVoucherCheck(undefined, null); // no voucher          
            }
        }
    });


    /** input handler called while typing the voucher code */
    const onVoucherCodeChanged = (voucherCode: string) => {
        setVoucherCodeInput(voucherCode);
    }


    /** handler called when clicking the ShowVoucherInfo button or when hitting CR after entering the voucher code */
    const onShowVoucherInfo = () => {
        setRequiresVoucherCheck(true);
        if (!props.dontUpdateShoppingBasket) {
            ShoppingBasketStorage.instance.shoppingBasketContent.voucherCode = voucherCodeInput;
        }
    }

    async function handleOnItemLinkClickedF2F(item: Item, requestedRegistrations: Registration[] | null): Promise<ShoppingBasketItem | null> {

        // The checkout with voucher can be completed automatically if a registration with status "requested" is already pending
        // or exactly one class allows the registration
        const f2fClassListResponse = await Session.instance.storage.classList.getF2FClassList(item.itemId, itemContext, undefined, 0);
        if (isSuccess<F2FClassDetail[] | null>(f2fClassListResponse)) {
            if (requestedRegistrations !== null && requestedRegistrations.length > 0) {
                //exactly one F2F Class and one exactly registration
                const requestedItemToCheck = f2fClassListResponse?.filter(f => f.classId === requestedRegistrations[0].classId);
                if (requestedItemToCheck !== undefined) {
                    if (requestedItemToCheck[0].isRegistrationAllowed) {
                        return new ShoppingBasketItem(
                            item.itemId,
                            requestedItemToCheck[0].classId,
                            item.itemType,
                            item.sId,
                            requestedItemToCheck[0].classCode,
                            item.title,
                            item.learningDuration,
                            item.price!,
                            item.currency,
                            [],
                            []);
                    }
                    else {
                        setItemErrorMsg('PaymentCampaignVouchers:RedeemItemError');
                        setItemAlertCssClass('');
                        return null;
                    }
                }
                else {
                    setItemErrorMsg('PaymentCampaignVouchers:RedeemItemError');
                    setItemAlertCssClass('');
                    return null;
                }
            }
            else {
                const alreadyRegisteredClassIds = item.allRegistrations?.filter(f => f.registrationStatus == ERegistrationStatus.Accepted).map(c => c.classId);
                const hasAlreadyRegisteredClassIds = alreadyRegisteredClassIds === undefined || alreadyRegisteredClassIds.length === 0;
                
                const registrationAllowedItems = f2fClassListResponse?.filter(f => f.isRegistrationAllowed && !alreadyRegisteredClassIds?.includes(f.classId)).sort(s => s.learningPeriodStart!.valueOf());
                
                if ((hasAlreadyRegisteredClassIds || item.isMultipleRegistrationAllowed)
                    //exactly one F2F Class, where registration is allowed
                    && registrationAllowedItems !== undefined && registrationAllowedItems.length === 1) {
                    return new ShoppingBasketItem(
                        item.itemId,
                        registrationAllowedItems[0].classId,
                        item.itemType,
                        item.sId,
                        registrationAllowedItems[0].classCode,
                        item.title,
                        item.learningDuration,
                        item.price!,
                        item.currency,
                        [],
                        []);
                }//more than one F2F Class (registration is allowed)
                else if (registrationAllowedItems !== undefined && registrationAllowedItems.length > 1) {
                    return new ShoppingBasketItem(0, 0, EItemType.Undefined, '', '', '', '', 0, '', [], []);
                }
                else {
                    setItemErrorMsg('PaymentCampaignVouchers:NoMoreClassesSchedulesAvailable');
                    setItemAlertCssClass('');
                    return null;
                }
            }
        }
        else {
            console.error(`VoucherValidation: getF2FClassList failed with itemid ${item.itemId}.`);
            return null;
        }
    }

    async function handleOnItemLinkClickedTPlan(item: Item, requestedRegistrations: Registration[] | null): Promise<ShoppingBasketItem | null> {

        const tplanSchedules = await Session.instance.storage.trainingPlanSchedules.getTrainingPlanSchedules(item.itemId, itemContext);
        const tplanElements = await Session.instance.storage.trainingPlanElements.getTrainingPlanElements(item.itemId, itemContext);

        if (isSuccess<TrainingPlanSchedule[] | null>(tplanSchedules) && isSuccess<TrainingPlanElement[] | null>(tplanElements)) {
            
            /* Set helper variables
            // f2fCourseClassIdsLaterRegister -> User can register after tplan registration on this f2f class(es)
            // and f2fCourseClassIdsNotLaterRegister -> User must register f2f class(es) with tplan registration
            // with scheduleId
            // and classId
            */
            const f2fCourseClassIdsLaterRegister: { sceId: number; classId: number }[] = [];
            const f2fCourseClassIdsNotLaterRegister: { sceId: number; classId: number }[] = [];
            const tpeAutoRegistrationSchedules = tplanSchedules?.filter(a => a.autoRegistrationClassDetails !== undefined && a.autoRegistrationClassDetails.length > 0 && a.autoRegistrationActive);
            const autoRegisterClasses = tpeAutoRegistrationSchedules?.map(s => { return {sceId: s.sceId, autoRegistrationClassDetails: s.autoRegistrationClassDetails} });
            if (autoRegisterClasses !== undefined) {
                autoRegisterClasses.forEach(a => {
                    if (a !== undefined && a.autoRegistrationClassDetails !== undefined) {
                        a.autoRegistrationClassDetails.forEach(c => {
                            if (c.isPossibleToRegisterAfterTplanRegistration) {
                                f2fCourseClassIdsLaterRegister.push({ sceId: a.sceId, classId: c.classId });
                            }
                            else {
                                f2fCourseClassIdsNotLaterRegister.push({ sceId: a.sceId, classId: c.classId });
                            }
                        })
                    }
                })
            }

            if (requestedRegistrations !== null && requestedRegistrations.length > 0) {
                const requestedItemToCheck = tplanSchedules?.filter(f => f.sceId === requestedRegistrations[0].classId);
                if (requestedItemToCheck !== undefined) {
                    //there is one possible requested registration on tplan
                    if (requestedItemToCheck[0].isRegistrationAllowedAndStatusIsNotRequested) {
                        
                        const f2fItemsStatusRequested = tplanElements?.filter(t=> t.registrationStatus === ERegistrationStatus.Requested && t.type == EItemType.F2FCourse);

                        let f2fClassIdsLaterRegister = f2fCourseClassIdsLaterRegister.filter(f => f.sceId === requestedItemToCheck[0].sceId);
                        let f2fClassIdsNotLaterRegister = f2fCourseClassIdsNotLaterRegister.filter(f => f.sceId === requestedItemToCheck[0].sceId);

                        //there are f2f classes with status requested in tplan
                        if(f2fItemsStatusRequested !== undefined && f2fItemsStatusRequested?.length > 0)
                        {
                            const f2fClassIds: number[] = [];
                            const f2fCalls: Promise<F2FClassDetail[] | null>[] = [];
                            f2fItemsStatusRequested.forEach(f => {
                                f2fCalls.push(Session.instance.storage.classList.getF2FClassList(f.itemId, EItemDetailCallerContextType.VoucherValidation, undefined, item.itemId));
                            });
                            //get f2f classes ids by itemId of tplan element(s)
                            await Promise.all(f2fCalls).then(results => {
                                results.forEach(r => {
                                    if (r !== null) {
                                        f2fClassIds.push(...r.map(r => r.classId));
                                    }
                                });
                            });
                            
                            //remaining f2f class ids to register later as tplan registration
                            f2fClassIdsLaterRegister = f2fClassIdsLaterRegister.filter(f => !f2fClassIds.includes(f.classId));
                            //remaining f2f class ids to register with tplan registration
                            f2fClassIdsNotLaterRegister = f2fClassIdsNotLaterRegister.filter(f => !f2fClassIds.includes(f.classId));

                            if (f2fClassIdsLaterRegister.length == 0 && f2fClassIdsNotLaterRegister.length <= 1) {
                                return new ShoppingBasketItem(
                                    item.itemId,
                                    requestedItemToCheck[0].sceId,
                                    item.itemType,
                                    item.sId,
                                    requestedItemToCheck[0].code,
                                    item.title,
                                    item.learningDuration,
                                    item.price!,
                                    item.currency,
                                    f2fClassIds,
                                    []);
                            }
                            else {
                                return new ShoppingBasketItem(0, 0, EItemType.Undefined, '', '', '', '', 0, '', [], []);
                            }
                        }
                        //there is no f2f class to register later and only one f2f class to register with tplan
                        else if (f2fClassIdsLaterRegister.length == 0 && f2fClassIdsNotLaterRegister.length <= 1) {
                            return new ShoppingBasketItem(
                                item.itemId,
                                requestedItemToCheck[0].sceId,
                                item.itemType,
                                item.sId,
                                requestedItemToCheck[0].code,
                                item.title,
                                item.learningDuration,
                                item.price!,
                                item.currency,
                                f2fClassIdsNotLaterRegister.map(f => f.classId),
                                []);
                        }
                        else {
                            return new ShoppingBasketItem(0, 0, EItemType.Undefined, '', '', '', '', 0, '', [], []);
                        }
                    }
                    else {
                        setItemErrorMsg('PaymentCampaignVouchers:RedeemItemError');
                        setItemAlertCssClass('');
                        return null;
                    }
                }
                else {
                    setItemErrorMsg('PaymentCampaignVouchers:RedeemItemError');
                    setItemAlertCssClass('');
                    return null;
                }
            }
            else {
                const registrationAllowedItems = tplanSchedules?.filter(f => f.isRegistrationAllowed).sort(s => s.runningBegin!.valueOf());
                //there is one tplan schedule
                if (registrationAllowedItems !== undefined && registrationAllowedItems.length === 1) {
                    const f2fClassIdsLaterRegister = f2fCourseClassIdsLaterRegister.filter(f => f.sceId === registrationAllowedItems[0].sceId);
                    const f2fClassIdsNotLaterRegister = f2fCourseClassIdsNotLaterRegister.filter(f => f.sceId === registrationAllowedItems[0].sceId);
                    //there is <=1 f2f class to register with tplan
                    if (f2fClassIdsLaterRegister.length == 0 && f2fClassIdsNotLaterRegister.length <= 1) {
                        return new ShoppingBasketItem(
                            item.itemId,
                            registrationAllowedItems[0].sceId,
                            item.itemType,
                            item.sId,
                            registrationAllowedItems[0].code,
                            item.title,
                            item.learningDuration,
                            item.price!,
                            item.currency,
                            f2fClassIdsNotLaterRegister.map(f => f.classId),
                            []);
                    }
                    // there is > 1 f2f classes to register with tplan schedule
                    else {
                        return new ShoppingBasketItem(0, 0, EItemType.Undefined, '', '', '', '', 0, '', [], []);
                    }
                }
                // there is more than one tplan schedule
                else if (registrationAllowedItems !== undefined && registrationAllowedItems.length > 1) {
                    return new ShoppingBasketItem(0, 0, EItemType.Undefined, '', '', '', '', 0, '', [], []);
                }
                else {

                    setItemErrorMsg('PaymentCampaignVouchers:NoMoreClassesSchedulesAvailable');
                    setItemAlertCssClass('');
                    return null;
                }
            }
        }
        else {
            console.error(`VoucherValidation: getTrainingPlanSchedules failed with itemid ${item.itemId}.`);
            return null;
        }
    }

    const onItemLinkClicked = async (item: Item, link: string) => {

        /*
        / Actually only for TPLAN
        */
        if (item.itemType == EItemType.TrainingPlan) {
            const res = await ConfigService.instance.getIsForbiddenRedeemOnCancelledItems();
            if (isSuccess<BooleanResponse>(res) && res.status) {
                if (item.registrationStatus == ERegistrationStatus.Cancelled ||
                    item.registrationStatus == ERegistrationStatus.Rejected ||
                    item.registrationStatus == ERegistrationStatus.ScheduleCancelled) {
                    setItemErrorMsg('PaymentCampaignVouchers:RegistrationStatusIsCancelled');
                    setItemAlertCssClass('');

                    return;
                }
            }
        }

        if (!props.enableAutomaticRegistrationInVoucherPanel || voucherValidity?.percentDiscount !== 100) {
            history.push(link);
            return;
        }

        let shoppingBasketItem: ShoppingBasketItem | null = null;

        let requestedRegistrations: Registration[] | null = null;
        requestedRegistrations = item.allRegistrations !== null ? item.allRegistrations.filter(r => r.registrationStatus === ERegistrationStatus.Requested) : null;

        switch (item.itemType) {
            case EItemType.F2FCourse:
                shoppingBasketItem = await handleOnItemLinkClickedF2F(item, requestedRegistrations);
                break;
            case EItemType.TrainingPlan:
                shoppingBasketItem = await handleOnItemLinkClickedTPlan(item, requestedRegistrations);
                break;
        }

        if (shoppingBasketItem !== null && shoppingBasketItem.itemId !== 0) {
            const shoppingBasket = new ShoppingBasket();
            shoppingBasket.saveTimeStamp = new Date();
            shoppingBasket.voucherCode = voucherValidity.voucherCode;
            shoppingBasket.shoppingBasketItems.push(shoppingBasketItem);

            PurchaseService.instance.checkout(new CheckoutRequest(shoppingBasket)).then(response => {
                if (isSuccess<CheckoutResponse>(response)) {
                    if(item.itemType == EItemType.TrainingPlan)
                    {
                        /*
                        // remove item from cache, because if user has clicked on tplan, the elements and schedules are loaded from cache in TrainingPlanRegistration component (next page)
                        // if they are loaded from cache, they will be shown as locked
                        */
                        Session.instance.storage.trainingPlanElements.removeItemFromCache(item.itemId);
                        Session.instance.storage.trainingPlanSchedules.removeItemFromCache(item.itemId);
                    }
                    history.push(link);
                }
                else {
                    console.error(`VoucherValidation: checkout failed with itemid ${item.itemId}.`);
                }
            });
        }
        else if (shoppingBasketItem?.itemId === 0) {
            history.push(link);
        }
    }

    function renderVoucherInput(): JSX.Element {
        const tr = Session.instance.storage.translation;
        const voucherInputAttributes: InputAttributes =
        {
            id: 'inputVoucherCode',
            label: '',
            value: voucherCodeInput,
            class: 'l-box--short',
            isReadOnly: false,
            isRequired: false,
            regExpression: undefined,
            hasFocus: false,
            editMode: true,
            placeholder: tr.GetString('ShoppingBasketContent:EnterVoucherCode'),
        };
        return (
            <div>
                <div>
                    <Translate>ShoppingBasketContent:VoucherInfo</Translate>
                </div>
                <div className="voucher-panel__redeem-voucher">
                    <InputText
                        attributes={voucherInputAttributes}
                        onChange={(id, text) => onVoucherCodeChanged(text)}
                        onCrEntered={() => onShowVoucherInfo()}
                    />
                    <button type="button"
                        className="btn--sm btn--primary"
                        onClick={() => onShowVoucherInfo()}>
                        <Translate>ShoppingBasketContent:ShowVoucherInfo</Translate>
                    </button>
                </div>
                <div>{ }</div>
            </div>
        );
    }

    /** Renders one lesson to which the voucher is restricted  */
    function renderVoucherItem(item: Item): JSX.Element {
        // get lesson icon and type translation
        const tr = Session.instance.storage.translation;
        let itemTypeIcon = '';
        let itemTypeName = '';
        let link = '';
        const itemTypePrefix = tr.GetString('ItemDetail:ItemType');
        switch (item.itemType) {
            case EItemType.WBT:
                itemTypeIcon = IconWbt;
                itemTypeName = itemTypePrefix + ": " + tr.GetString('ItemType:WBT');
                link = ItemHelper.getItemDetailLink(item.itemId, itemContext, 0, 0, 0, 0);
                break;
            case EItemType.F2FCourse:
                itemTypeIcon = IconF2F;
                itemTypeName = itemTypePrefix + ": " + tr.GetString('ItemType:F2FCourse');
                link = ItemHelper.getItemDetailLink(item.itemId, itemContext, 0, 0, 0, 0);
                break;
            case EItemType.TrainingPlan:
                itemTypeIcon = IconTrainingPlan;
                itemTypeName = itemTypePrefix + ": " + tr.GetString('ItemType:TrainingPlan');
                link = ItemHelper.getTrainingPlanDetailLink(item.itemId, itemContext, 0, 0);
                break;
        }
        link = `${link}&vouchercode=${voucherCodeInput}`
        const itemKey = "voucherItem_" + item.itemId.toString();
        const itemTypeTooltipId = itemKey + '_ItemType';

        return (
            <div role="row" className="voucher-panel__item-container" key={itemKey}>
                <div role="cell" className="voucher-panel__item-icon"
                    aria-label={itemTypeName}
                    data-tip={itemTypeName}
                    data-for={itemTypeTooltipId} >
                    <InlineSVG src={itemTypeIcon} />
                    <Tooltip id={itemTypeTooltipId} place="bottom" delayShow={500} />
                </div>
                <div role="cell" className="voucher-panel__item-text">
                    <p>
                        <NavLink to={link} onClick={(e) => {e.preventDefault(); onItemLinkClicked(item, link);}}>
                            {item.title}
                        </NavLink>
                    </p>
                </div>
            </div>

        );
    }

    /** Render the voucher's restriction to certain lessons if applicable */
    function renderVoucherItems(): JSX.Element {
        if (voucherValidity && voucherValidity.itemIds.length > 0) {
            // The voucher is restricted to certain items
            if (props.dontListVoucherItems) {
                // only show a hint, if the items must not be listed
                return (<Translate>ShoppingBasketContent:VoucherRestrictedToCertainLessons</Translate>);
            } else {
                // list the items
                const elements: JSX.Element[] = [];
                voucherItems?.map((item) => {
                    elements.push(renderVoucherItem(item));
                });
                return (
                    <>
                        <Translate>ShoppingBasketContent:VoucherRestrictedToLessons</Translate>
                        <div role="table">
                            {elements}
                        </div>
                        <Alert message={itemErrorMsg}
                            alertAppereance="single-line" alertType="error" className={itemAlertCssClass} />
                    </>
                );
            }
        } else {
            return <></>
        }
    }


    function getInvalidityReason(): string {
        if (voucherValidity?.isValid) {
            return "";
        } else {
            switch (voucherValidity?.invalidityReason) {
                case EVoucherInvalidityReason.ValidityPeriodExpired:
                    return tr.GetString("ShoppingBasketContent:VoucherExpired") + ` (${DateHelper.toDateString(voucherValidity.expirationDate)})`;
                case EVoucherInvalidityReason.AlreadyRedeemed:
                    return tr.GetString("ShoppingBasketContent:VoucherAlreadyRedeemed");
                case EVoucherInvalidityReason.Revoked:
                    return tr.GetString("ShoppingBasketContent:VoucherRevoked");
                case EVoucherInvalidityReason.InvalidCode:
                    return tr.GetString("ShoppingBasketContent:voucherInvalid");
                case EVoucherInvalidityReason.ValidationFailed:
                default:
                    return tr.GetString("ShoppingBasketContent:voucherValidationFailed");
            }
        }
    }


    /** Renders the voucher's validity and conditions */
    function renderVoucherValidity(): JSX.Element {
        if (voucherValidity != undefined) {
            if (voucherValidity.isValid) {
                return (
                    <div>
                        {voucherValidity.absDiscount !== 0 &&
                            <p><Translate args={[NumberHelper.getFormattedPrice(voucherValidity.absDiscount, voucherValidity.absDiscountCurrency)]}>
                                ShoppingBasketContent:VoucherAbsDiscount</Translate></p>}
                        {voucherValidity.percentDiscount !== 0 &&
                            <p><Translate args={[voucherValidity.percentDiscount.toString()]}>
                                ShoppingBasketContent:VoucherPercentDiscount</Translate></p>}
                        {voucherValidity.itemIds.length > 0 &&
                            renderVoucherItems()}
                    </div>
                );
            } else {
                return (
                    <Alert message={getInvalidityReason()} alertAppereance="single-line" alertType="error" />
                );
            }
        } else {
            // voucherValidity not yet defined
            return <></>
        }
    }

    return (
        <>
            {renderVoucherInput()}
            {requiresVoucherCheck ?
                <ProgressSpinner />
                :
                renderVoucherValidity()
            }
        </>
    )
}