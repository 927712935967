import Logger from '$core/Logger';
import Session from '$core/Session';
import SkillProfileService from '$src/core/Services/SkillProfileService';
import { UserSkillProfile } from '$src/storage/models/SkillProfile/UserSkillProfile';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import SessionCache from '$storage/cache/SessionCache';
import { isSuccess } from '$util/Result';

const CACHING_DURATION = 300;

export default class UserSkillProfileStorage extends SessionCache<number, UserSkillProfile[]> {
    protected className = 'UserSkillProfileStorage';
    protected loggerLocality = 'Storage.UserSkillProfileStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getUserSkillProfiles(requestedUserId?: number, forceReload = false, bossRelationCode?: string): Promise<UserSkillProfile[] | null> {
        const methodName = `${this.className}:getUserSkillProfiles()`;
        const userId = requestedUserId ? requestedUserId : Session.instance.loginUser!.id;
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        if (this.isObjectInCache(userId) && !forceReload) {
            Logger.log(this.loggerLocality, `${methodName} getting user skill profiles for userId=${userId}.`);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} getting user skill profiles from server, userId=${userId}.`);
            const response = await SkillProfileService.instance.getUserSkillProfiles(language, userId, bossRelationCode);

            if (isSuccess<UserSkillProfile[]>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality, `${methodName} got user skill profiles from server, userid=${userId}, number of profiles=${elementCount}.`);
                this.saveObjectToCache(userId, response);
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get user skill profiles from server, userId=${userId}.`);
                this.saveObjectToCache(userId, new Array<UserSkillProfile>());
            }
        }
        return this.getObjectFromCache(userId);
    }

    public async getPerformanceReviewProfiles(requestedUserId?: number, bossRelationCode?: string): Promise<UserSkillProfile[] | null> {
        const response = await this.getUserSkillProfiles(requestedUserId, false, bossRelationCode);
        if (response != null) {
            return response.filter(uskp => uskp.showSkillProfileInPerformanceReview);    
        } else {
            return null;
        }
    }

    public async getProfileSkill(skillId: number, profileId: number, requestedUserId?: number, bossRelationCode?: string): Promise<UserSkillProfileSkill | undefined> {
        const skillProfiles = await this.getUserSkillProfiles(requestedUserId, false, bossRelationCode);
        let response: UserSkillProfileSkill | undefined ;
        if (skillProfiles != null) {
            const profile = skillProfiles.find(uskp => uskp.skillProfileId === profileId);
            if(profile != null) {
                response = profile.skillContainer?.userSkillProfileSkills.find(sk => sk.skillId === skillId);
            }    
        } 
        return response;
    }

    public clear(): void {
        super.clear();
    } 

    public isCacheExpired(): boolean {
        return false;
    }
}