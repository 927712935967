
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { TranslationList } from '$src/storage/models/TranslationList';
import TranslationObject from '$src/storage/models/TranslationObject';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * TranslationService provides all Service Methods to get the translations
 */
export default class TranslationService extends ServiceClient {
    protected static _instance: TranslationService | null = null;

    protected className = 'TranslationService';
    protected loggerLocality = 'TranslationService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): TranslationService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get all translations for this language
     * @param language 
     */
    public async getTranslationList(language: string): Promise<TranslationList | GtError> {
        const response = await this.get<TranslationList>('translation', TranslationList, undefined, [{ name: 'language', value: language }], this._applicationName);
        return response;
    }

    /**
     * Get one single translation
     * @param languageCode 
     * @param stringID 
     */
    public async getTranslationSingle(language: string, stringID: string): Promise<TranslationObject | GtError> {
        const splittedStrings: string[] = stringID.split(':', 2)
        const response = await this.get<TranslationObject>('translation/singlestring', TranslationObject, undefined, [{ name: 'language', value: language }],
            splittedStrings[0], splittedStrings[1], this._applicationName);
        return response;
    }


    /**
     * Trigger String Cache update
     */
    public async cleanTranslationCache(): Promise<BooleanResponse | GtError> {
        const response = await this.post<BooleanResponse>('translation/cleancache', {}, BooleanResponse);
        return response;
    }
} 