
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { StringHelper } from '$src/util/StringHelper';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseSchedule {
    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('courseId', Number, false)
    public courseId: number = 0;

    @JsonProperty('location', String, false)
    public location: string = '';

    @JsonProperty('code', String, false)
    public code: string = '';

    @JsonProperty('price', Number, false)
    public price: number = -1;
    
    @JsonProperty('currency', String, false)
    public currency: string = '';

    @JsonProperty('firstAppointment', DateTimeConverter, false)
    public firstAppointment: Date | undefined = undefined;

    @JsonProperty('lastAppointment', DateTimeConverter, false)
    public lastAppointment: Date | undefined = undefined;

    @JsonProperty('duration', String, false)
    public duration: string = '';

    public get formatedFirstAppointment(): string | undefined {
        if(this.firstAppointment) {
            return StringHelper.dateString(this.firstAppointment);
        } else {
            return '';
        }
    }

    public get formatedLastAppointment(): string | undefined {
        if(this.lastAppointment) {
            return StringHelper.dateString(this.lastAppointment);
        } else {
            return '';
        }
    }

    public get priceAsDecimal(): string {
        return this.price.toFixed(2);
    }
}