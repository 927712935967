import Session from '$src/core/Session'
import * as React from 'react'
import { Redirect, useParams } from 'react-router'
import { Alert } from '$components/shared/WarningsAndErrors/Alert'
import { SelfRegistration } from '$components/selfRegistration/SelfRegistration'



export default function UserRegistration() {
    const bossRelationCodeParam = useParams<{ bossRelationCode: string }>();
    const [bossRelationCode] = React.useState<string>(bossRelationCodeParam.bossRelationCode);

    if (Session.instance.loginUser?.id === 0) {
        return <Redirect push={true} to="/" />;
    } else if (!Session.instance.isBossAllowedToRegisterNewUsers()) {
        return <div className="l-container">
            <Alert alertAppereance="box" alertType="error" message="MyTeam:NoAccess" />
        </div>
    } else {
        return (
            <SelfRegistration
                parentHeadingLevel={2}
                bossRelationCode={bossRelationCode}
                boss={Session.instance.loginUser}
            />
        );
    }
}