import React from 'react';

import Iconcompleted from '$resources/svgs/status/complete.svg';
import Iconlocked from '$resources/svgs/status/locked.svg';
import Iconfailed from '$resources/svgs/status/not-passed.svg';
import IconnotAttempted from '$resources/svgs/status/not-started.svg';
import Iconpassed from '$resources/svgs/status/passed.svg';
import Iconincompete from '$resources/svgs/status/started.svg';

import Iconcancelled from '$resources/svgs/status/cancelled.svg';
import Iconaccepted from '$resources/svgs/status/confirmed.svg';
import IconnotRegistered from '$resources/svgs/status/not-registered.svg';
import Iconrejected from '$resources/svgs/status/rejected.svg';
import Iconrequested from '$resources/svgs/status/requested.svg';
import IconwaitingList from '$resources/svgs/status/waiting-list.svg';


import Session from '$src/core/Session';
import { EItemType, ELessonStatus, ERegistrationStatus } from '$src/storage/models/enums';
import { Item } from '$src/storage/models/Item';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    item?: Item;
    lessonStatus?: ELessonStatus;
    includeText: boolean;
    context: 'navigation' | 'completitionRequirements' | 'targetCompletitionRequirements';
    itemTitle?: string;
}

/**
 * @deprecated The method should not be used
 */
export class TrainingPlanLessonStatusIcon extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        let icon = IconnotAttempted;
        let text: string = '';
        let hoverText = '';
        let tooltipId = 'TooltipFor_';

        if (this.props.item == null && this.props.lessonStatus != null) {
            switch (this.props.lessonStatus) {
                case ELessonStatus.NotAttempted:
                    icon = IconnotAttempted;
                    text = tr.GetString('LessonStatus:NotAttempted');
                    break;
                case ELessonStatus.Incomplete:
                    icon = Iconincompete;
                    text = tr.GetString('LessonStatus:Incomplete');
                    break;
                case ELessonStatus.Completed:
                    icon = Iconcompleted;
                    text = tr.GetString('LessonStatus:Completed');
                    break;
                case ELessonStatus.Failed:
                    icon = Iconfailed;
                    text = tr.GetString('LessonStatus:Failed');
                    break;
                case ELessonStatus.Passed:
                    icon = Iconpassed;
                    text = tr.GetString('LessonStatus:Passed');
                    break;
                default:
                    icon = IconnotAttempted;
                    text = tr.GetString('LessonStatus:NotAttempted');
                    break;
            }
        } else if (this.props.item != null) {
            if (((this.props.item.itemType === EItemType.F2FCourse || this.props.item.itemType === EItemType.TrainingPlan) &&
                (this.props.item.lessonStatus === ELessonStatus.Undefined ||
                    this.props.item.lessonStatus === ELessonStatus.NotAttempted))
                || (this.props.item.registrationRequiredEnabled &&
                    (this.props.item.lessonStatus === ELessonStatus.Undefined || this.props.item.lessonStatus === ELessonStatus.NotAttempted))
            ) {
                switch (this.props.item.registrationStatus) {
                    case ERegistrationStatus.Requested:
                        icon = Iconrequested;
                        text = tr.GetString('RegistrationStatus:Requested');
                        break;
                    case ERegistrationStatus.Accepted:
                        icon = Iconaccepted;
                        text = tr.GetString('RegistrationStatus:Accepted');
                        break;
                    case ERegistrationStatus.Rejected:
                        icon = Iconrejected;
                        text = tr.GetString('RegistrationStatus:Rejected');
                        break;
                    case ERegistrationStatus.Cancelled:
                    case ERegistrationStatus.ScheduleCancelled:
                        icon = Iconcancelled;
                        text = tr.GetString('RegistrationStatus:Cancelled');
                        break;
                    case ERegistrationStatus.InWaitingList:
                        icon = IconwaitingList;
                        text = tr.GetString('RegistrationStatus:InWaitingList');
                        break;
                    default:
                        icon = IconnotRegistered;
                        text = tr.GetString('LessonStatus:NotRegistered');
                        break;
                }
            }
            else if (this.props.item.isLocked) {
                icon = Iconlocked;
                text = tr.GetString('LessonStatus:Locked');
            } else {
                switch (this.props.item.lessonStatus) {
                    case ELessonStatus.NotAttempted:
                        icon = IconnotAttempted;
                        text = tr.GetString('LessonStatus:NotAttempted');
                        break;
                    case ELessonStatus.Incomplete:
                        icon = Iconincompete;
                        text = tr.GetString('LessonStatus:Incomplete');
                        break;
                    case ELessonStatus.Completed:
                        icon = Iconcompleted;
                        text = tr.GetString('LessonStatus:Completed');
                        break;
                    case ELessonStatus.Failed:
                        icon = Iconfailed;
                        text = tr.GetString('LessonStatus:Failed');
                        break;
                    case ELessonStatus.Passed:
                        icon = Iconpassed;
                        text = tr.GetString('LessonStatus:Passed');
                        break;
                    default:
                        icon = IconnotAttempted;
                        text = tr.GetString('LessonStatus:NotAttempted');
                        break;
                }
            }
        }
        if (this.props.item) {
            if (this.props.context === 'navigation') {
                if (this.props.item.itemType === EItemType.Block) {
                    hoverText = tr.GetString('ItemDetail:ModuleStatus');
                } else {
                    hoverText = tr.GetString('ItemDetail:LessonTPlanStatus');
                }
            } 
            else if (this.props.context === 'completitionRequirements') {
                hoverText = tr.GetString('ItemDetail:LessonStatus');
            } 
            else if (this.props.context === 'targetCompletitionRequirements') {
                hoverText = tr.GetString('ItemDetail:targetLessonStatus');
            }
            if (this.props.itemTitle != null) {
                hoverText = hoverText + ' ' + this.props.itemTitle + ': ' + text;
            } else {
                hoverText = hoverText + ' ' + this.props.item.title + ': ' + text;
            }
            tooltipId += this.props.item.itemId;
        }
        else {
            if (this.props.context === 'navigation') {
                hoverText = tr.GetString('ItemDetail:LessonTPlanStatus');
            } 
            else if (this.props.context === 'completitionRequirements') {
                hoverText = tr.GetString('ItemDetail:LessonStatus');
            } 
            else if (this.props.context === 'targetCompletitionRequirements') {
                hoverText = tr.GetString('ItemDetail:targetLessonStatus');
            }
            tooltipId += this.props.context;
            if (this.props.itemTitle != null) {
                hoverText = hoverText + ' ' + this.props.itemTitle + ': ' + text;
                tooltipId += `_${this.props.itemTitle}`;
            } else {
                hoverText = hoverText + ': ' + text;
            }

        }
        return (
            <div>
                <span aria-label={hoverText}
                    data-tip={hoverText}
                    data-for={tooltipId}>
                    <InlineSVG className="tp-cr__icon" src={icon} />&nbsp;{this.props.includeText ? (text) : ('')}
                </span>
                <Tooltip id={tooltipId} />
            </div>
        );
    }
}
