import { JsonObject, JsonProperty } from 'json2typescript';
@JsonObject
export class GamificationPointHistoryOverview {

    @JsonProperty('totalUserPoints', Number, false)
    public totalUserPoints: number = 0;

    @JsonProperty('reachedLevelName', String, false)
    public reachedLevelName: string = '';

    @JsonProperty('reachedLevelMinPoints', Number, false)
    public reachedLevelMinPoints: number = 0;

    @JsonProperty('levelNumber', Number, false)
    public levelNumber: number = 0;

}