import { JsonObject, JsonProperty } from 'json2typescript';

/** This class holds information needed for starting a CBT lesson. */
@JsonObject
export class CbtCommandButton {

    /** 
     * Title of start button
     */
    @JsonProperty('startButton', String, false)
    public startButton: string = '';

    /** 
     * the command line
     */
    @JsonProperty('commandLine', String, false)
    public commandLine: string = '';

    /** 
     * the cd or dvd label
     */
    @JsonProperty('cdDvdLabel', String, false)
    public cdDvdLabel: string = '';

    /** 
     * the working directory
     */
    @JsonProperty('workingDirectory', String, false)
    public workingDirectory: string = '';

     /** 
      * Indicates if the ContentHost asks if user have completed when finishing a lesson. Valid only for non-compatible lessons.
      * Property just for information. 
      */
    @JsonProperty('askForCompletion', Boolean, false)
    public askForCompletion: boolean = false;

}