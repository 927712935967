import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class TutoringFilter {

    @JsonProperty('filterText', String, false)
    public filterText: string = '';

    @JsonProperty('filterItemType', Number, false)
    public filterItemType: number = 0;

    @JsonProperty('filterSubjectId', Number, false)
    public filterSubjectId: number = 0;

    constructor(filterText: string,  filterItemType: number, filterSubjectId: number){
        this.filterText = filterText;
        this.filterItemType = filterItemType;
        this.filterSubjectId = filterSubjectId;
    }

}