import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class RemoveSkillsRequest {
    @JsonProperty('userIds', [Number], false)
    public userIds: number[] = [];

    @JsonProperty('skillIds', [Number], false)
    public skillIds: number[] = [];

    @JsonProperty('isTargetSkill', Boolean, false)
    public isTargetSkill: boolean = false;
}