import React from 'react';

import { ToolbarExternalClassDuration } from '$components/item/Toolbar/ToolbarExternalClassDuration';
import ToolbarItemType from '$components/item/Toolbar/ToolbarItemType';
import { ToolbarLearningDuration } from '$components/item/Toolbar/ToolbarLearningDuration';
import { ToolbarScheduleDates } from '$components/item/Toolbar/ToolbarScheduleDates';
import { ToolbarScore } from '$components/item/Toolbar/ToolbarScore';
import { ToolbarStatus } from '$components/item/Toolbar/ToolbarStatus';
import { EItemSubType, EItemType, ELessonStatus } from '$storage/models/enums';
import { Item } from '$storage/models/Item';
import { ToolbarItemRating } from '$src/components/item/Toolbar/ToolbarItemRating';

interface IProps {
    item: Item;
    showTPlanScore?: boolean;
    hideRatingToolbar?: boolean;
    relatedClassId?: number;
}

export class Toolbar extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const item = this.props.item;
        let elements: JSX.Element[] = [];
        let key = 1;
        elements.push(<ToolbarItemType item={item} key={key++} textVisible={true}/>);
        if (item.itemSubType !== EItemSubType.External && 
            !this.props.hideRatingToolbar && 
            (globalConfig.lessonToolbarProperties.displayRating &&
            globalConfig.lessonToolbarProperties.displayRating && item.isRatingEnabled)) {
            elements.push(<ToolbarItemRating starsCount={6} item={item} key={key++} />); 
        }
        if (item.itemSubType === EItemSubType.External) {
            elements.push(<ToolbarExternalClassDuration item={item} key={key++} textVisible={true}/>);
        }
        if (item.itemType === EItemType.F2FCourse) {
            elements.push(<ToolbarScheduleDates item={item} key={key++} textVisible={true} relatedClassId={this.props.relatedClassId}/>);
        }
        if (item.itemType !== EItemType.Document) {
            elements.push(<ToolbarStatus item={item} id={item.itemId} key={key++} textVisible={true} isLocked={item.isLocked} />);
        }
        // Score when TP and showTPScore or not document / tp and lesson status = completed / passed / failed and displayScore is true
        if (item.itemType === EItemType.TrainingPlan && this.props.showTPlanScore === true || 
            (item.itemType !== EItemType.Document && item.itemType !== EItemType.TrainingPlan
            && 
            (
                (item.lessonStatus === ELessonStatus.Completed ||
                item.lessonStatus === ELessonStatus.Passed ||
                item.lessonStatus === ELessonStatus.Failed)
                &&
                globalConfig.lessonToolbarProperties.displayScore === true
            )
        )) {
            if (item.score > 0) {
                elements.push(<ToolbarScore item={item} key={key++} />);
            }
        }
        // Duration when duration is not empty and not F2F and display score is true and status != completed / passed / failed 
        // or display score is false or display rating is false
        if (item.learningDuration && item.learningDuration !== ''
            &&
            item.itemType !== EItemType.F2FCourse
            && 
            (
                (
                    (item.lessonStatus !== ELessonStatus.Completed &&
                    item.lessonStatus !== ELessonStatus.Passed &&
                    item.lessonStatus !== ELessonStatus.Failed)
                    &&
                    globalConfig.lessonToolbarProperties.displayScore === true
                )
                ||
                (
                    globalConfig.lessonToolbarProperties.displayScore === false
                    ||
                    globalConfig.lessonToolbarProperties.displayRating === false
                )
            )) {
            elements.push(<ToolbarLearningDuration item={item} key={key++} />);
        }
        if(elements.length > 4) {
            elements = elements.slice(0, 4);
            console.warn('Lessontoolbar has too many items.');
        }
        return (
            <div className="l-box__toolbar lesson__toolbar">
                {elements}
            </div>
        )
    }
}