import { NsuiConfigResponse } from "./storage/models/NsuiConfigResponse";
import ConfigService from "./core/Services/ConfigService";
import { isSuccess } from "./util/Result";
import { MergeRecursive } from "./configBoot";

export const InjectAfterLoginConfiguration = async () => {
    console.log(`CONFIG: Loading and injecting domain specific configurations from configuration database`);
    const dbConfig = await ConfigService.instance.getNsuiConfig();
    if (isSuccess<NsuiConfigResponse>(dbConfig)) {
        const globalConfigDomainSpecificDB = eval('(' + dbConfig.globalConfig + ')');
        console.debug(`CONFIG: Domain specific GlobalConfig from DB: ${JSON.stringify(globalConfigDomainSpecificDB)}`);
        const customizationConfigDomainSpecificDB = eval('(' + dbConfig.customizationConfig + ')');
        console.debug(`CONFIG: Domain specific CustomizationConfig from DB: ${JSON.stringify(customizationConfigDomainSpecificDB)}`);
        MergeRecursive(globalConfig, globalConfigDomainSpecificDB);
        MergeRecursive(globalConfig, customizationConfigDomainSpecificDB);
        console.debug(`CONFIG: Merge Result: ${JSON.stringify(globalConfig)}`);
    }
    else {
        console.error('CONFIG: Could not get domain specific configuration');
    }
}