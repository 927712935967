import React, { useEffect, useState } from 'react';

import ArrowRight from '$resources/svgs/navi/arrow-right.svg';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';

import InlineSVG from 'react-inlinesvg';

interface IProps {
    resultsPerPage: number;
    totalResults: number;
    onPageSelected?: (
        selectedPage: number
    ) => void;
}

export default function SearchPager(props: IProps) {
    const [selectedPage, setSelectedPage] = useState(1);

    useEffect(() => {
        setSelectedPage(1);
    }, [props.totalResults]);
    
    function pageSelected(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        if (props.onPageSelected != null) {
            setSelectedPage(Number.parseInt(e.currentTarget.id))
            props.onPageSelected(Number.parseInt(e.currentTarget.id));
        }
    }

    function previousePage(): void {
        if (selectedPage > 1) {
            setSelectedPage(selectedPage - 1);
            if (props.onPageSelected != null) {
                props.onPageSelected(selectedPage - 1);
            }
        }
    }

    function nextPage(): void {
        if (selectedPage < Math.ceil(props.totalResults / props.resultsPerPage)) {
            setSelectedPage(selectedPage + 1);
            if (props.onPageSelected != null) {
                props.onPageSelected(selectedPage + 1);
            }
        }
    }

    function renderNumbers(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        for (let _i = 1; _i <= Math.ceil(props.totalResults / props.resultsPerPage); _i++) {
            elements.push(
                < li
                    className={_i == selectedPage ? 'page--selected' : 'page'}
                    key={_i}
                >
                    <a id={_i.toString()} onClick={(e) => pageSelected(e)} > {_i}</ a>
                </li >
            );
        }
        return elements;
    }

    if (props.totalResults > 0) {
        return (
            <div className="search-result__pager">
                <div>
                    <ul>
                        <li onClick={() => previousePage()}><InlineSVG src={ArrowLeft} /></li>
                        {renderNumbers()}
                        <li onClick={() => nextPage()}><InlineSVG src={ArrowRight} /></li>
                    </ul>
                </div>
            </div>
        );
    } else {
        return null;
    }
}