import React from 'react';

import Session from '$src/core/Session';
import Iconduration from '$src/resources/svgs/toolbar/duration.svg';
import { Item } from '$src/storage/models/Item';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    item: Item;
}

export class ToolbarLearningDuration extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        let hoverText = tr.GetString('ItemDetail:LearningDuration');
        const durationText = this.props.item.learningDuration ? this.props.item.learningDuration : '';
        let duration: JSX.Element = <span />;
        if (durationText !== '') {
            duration = <React.Fragment><InlineSVG src={Iconduration} />{durationText} </React.Fragment>
        }
        hoverText= hoverText + ': ' + durationText;
        return (
            <div className="l-box__toolbar__icon lesson-tools lesson-tools--duration" 
                aria-label={hoverText} 
                data-tip={hoverText}
                data-for={`${this.props.item.itemId}_durationTooltip`}>
                {duration}
                <Tooltip id={`${this.props.item.itemId}_durationTooltip`} />
            </div>
        )
    }
}
