import React, { useEffect, useState } from "react";
import { Translate } from "$src/components/shared/Translate";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import { Heading } from "$src/components/shared/Heading";
import Session from "$src/core/Session";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { ModalPopup } from "$src/components/shared/ModalPopup";
import { GroupMailLink } from "$src/storage/models/GroupMailLink";
import UserService from "$src/core/Services/UserService";
import { isSuccess } from "$src/util/Result";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { UserMailLink } from "$src/storage/models/UserMailLink";
import { CheckBox } from "$src/components/shared/CheckBox";
import Logger from "$src/core/Logger";
import GTInfoMessage from "$src/components/shared/Atoms/GTInfoMessage";
import { ErrorMessage } from "$src/components/shared/WarningsAndErrors/ErrorMessage";
import { ClassMailLink } from "$src/storage/models/ClassMailLink";
import { StringHelper } from "$src/util/StringHelper";
import { GenericFilter, IGenericFilterItem } from "$src/components/shared/GenericFilter";
import { ItemMailLink } from "$src/storage/models/ItemMailLink";
import { MailToRequest } from "$src/storage/models/RequestObjects/MailToRequest";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import GTHorizontalTableHeaderRow, { GTHeaderContent } from "$components/shared/Atoms/GTHorizontalTableHeaderRow";
import GTHorizontalTableRow, { GTRowContent } from "$components/shared/Atoms/GTHorizontalTableRow";
import GTHorizontalTable from "$components/shared/Molecules/GTHorizontalTable";

export default function MailTo() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [filterFirstName, setFilterFirstName] = useState<string>("");
    const [filterLastName, setFilterLastName] = useState<string>("");
    const [filterMail, setFilterMail] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [groups, setGroups] = useState<GroupMailLink[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<GroupMailLink[] | undefined>([]);
    const [showGroups, setShowGroups] = useState<GroupMailLink[]>([]); // this is used for showing the groups in the dropdown without the Employee Groups
    const [filteredGroups, setFilteredGroups] = useState<GroupMailLink[]>([]);
    const [classes, setClasses] = useState<ClassMailLink[]>([]);
    const [selectedClasses, setSelectedClasses] = useState<ClassMailLink[] | undefined>([]);
    const [filteredClasses, setFilteredClasses] = useState<ClassMailLink[]>([]);
    const [users, setUsers] = useState<UserMailLink[]>([]);
    const [showError, setShowError] = useState<boolean>(false);
    const [items, setItems] = useState<IGenericFilterItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<IGenericFilterItem | undefined>(undefined);
    const [disableGroupFilter, setDisableGroupFilter] = useState<boolean>(false);
    const [disableItemFilter, setDisableItemFilter] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const tr = Session.instance.storage.translation;
    const className = 'MailTo';
    const loggerLocality = 'Components.MailTo';

    useEffect(() => {
        const methodName = `${className}:fetchData()`;
        if (!isLoaded) {
            UserService.instance.getGroups().then((result) => {
                if (isSuccess<GroupMailLink[]>(result)) {
                    setGroups(result);
                    const tmpList: GroupMailLink[] = [];
                    result.map(res => {
                        if (!res.groupName.includes("sEmployees")) {
                            tmpList.push(res);
                        }
                    })
                    setShowGroups(tmpList);
                    setFilteredGroups(tmpList);
                } else {
                    const errorMessage = `${methodName} failed to get groups`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            })

            UserService.instance.getItems(Session.instance.getUserLanguageCodeOrFallBack).then((result) => {
                if (isSuccess<ItemMailLink[]>(result)) {
                    const tmp: IGenericFilterItem[] = [];
                    result.map((item) => {
                        tmp.push({ id: item.itemId, text: item.itemTitle + " ( " + item.itemName + " )", helperItem: item.itemType } as IGenericFilterItem);
                    })
                    setItems(tmp);
                } else {
                    const errorMessage = `${methodName} failed to get items`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            })
            setIsLoaded(true);
        }
    });

    useEffect(() => {
        const methodName = `${className}:onLoadUser()`;
        const groupIds: number[] = [];
        const classIds: number[] = [];
        if (selectedGroups && selectedGroups.length > 0) {
            groupIds.push(...selectedGroups.map(g => g.groupId));
        } else {
            groupIds.push(...groups.map(g => g.groupId));
        }

        if (selectedClasses && selectedClasses.length > 0) {
            classIds.push(...selectedClasses.map(c => c.classId));
        } else {
            classIds.push(...classes.map(c => c.classId));
        }

        const request: MailToRequest = {
            classIds: classIds, groupIds: groupIds, itemType: selectedItem?.helperItem.toString(),
            filterFirstName: filterFirstName, filterLastName: filterLastName, filterMail: filterMail
        };
        UserService.instance.getUserForMailLink(request).then((result) => {
            if (isSuccess<UserMailLink[]>(result)) {
                setUsers(result);
            } else {
                const errorMessage = `${methodName} failed to get users`;
                Logger.log(loggerLocality, `${methodName} called`);
                console.log(errorMessage);
            }
            setShowSpinner(false);
        })
    }, [showSpinner]);

    function onShowUser() {
        setShowModal(true);
    }

    function handleGroupSelectionChanged(e: MultiSelectChangeEvent) {

        if (e.target.value.length == 0) {
            setSelectedGroups([]);
            setDisableItemFilter(false);
        }
        else {
            setSelectedGroups([...e.target.value]);
            setDisableItemFilter(true);
            setSelectedItem(undefined);
        }
    }

    function filterGroupes(filterGroups: string): void {

        let tmp: GroupMailLink[] = [];
        if (filterGroups != null && filterGroups !== '') {
            tmp = showGroups.filter(group => group.groupName.toLowerCase().includes(filterGroups.toLowerCase()));
            setDisableItemFilter(true);
            setSelectedItem(undefined);
        } else {
            tmp = showGroups;
            setDisableItemFilter(false);
        }
        setFilteredGroups(tmp);
    }

    function groupFilterChanged(event: MultiSelectFilterChangeEvent) {

        const groupFilter = event.filter.value;
        if (groupFilter !== '') {
            if (groupFilter !== (selectedGroups != null)) {
                setSelectedGroups(undefined);
            }
        } else {
            setSelectedGroups(undefined);
        }
        filterGroupes(groupFilter);
    }

    function getCountOfUsers() {
        let count = 0;
        users.map(user => {
            if (user.forMailLink) {
                count++;
            }
        })
        return count.toString();
    }

    function filterClass(filterClass: string): void {

        let tmp: ClassMailLink[] = [];
        if (filterClass != null && filterClass !== '') {
            tmp = classes.filter(classTmp => classTmp.classString.toLowerCase().includes(filterClass.toLowerCase()));
        } else {
            tmp = classes;
        }

        setFilteredClasses(tmp);
    }

    function classFilterChanged(event: MultiSelectFilterChangeEvent) {

        const classFilter = event.filter.value;
        if (classFilter !== '') {
            if (classFilter !== (selectedClasses != null)) {
                setSelectedClasses(undefined);
            }
        } else {
            setSelectedClasses(undefined);
        }
        filterClass(classFilter);
    }

    function handleClassSelectionChanged(e: MultiSelectChangeEvent) {

        if (e.target.value.length == 0) {
            setSelectedClasses([]);
        }
        else {
            setSelectedClasses([...e.target.value]);
        }
    }

    function onChangeItemType(itm: IGenericFilterItem | undefined) {
        const methodName = "onChangeItemtype";
        setSelectedItem(itm);
        if (itm) {
            setDisableGroupFilter(true);
            setFilterFirstName("");
            setFilterLastName("");
            setFilterMail("");
            UserService.instance.getClasses(itm.id, itm.helperItem).then((result) => {
                if (isSuccess<ClassMailLink[]>(result)) {
                    result.map(classTmp => {
                        let dateStart: string = "";
                        let dateEnd: string = "";
                        if (classTmp.classStarteDate) {
                            dateStart = StringHelper.dateString(classTmp.classStarteDate);
                        }
                        if (classTmp.classEndDate) {
                            dateEnd = StringHelper.dateString(classTmp.classEndDate);
                        }
                        classTmp.classString = classTmp.className + " " + dateStart + " - " + dateEnd;
                    })
                    setClasses(result);
                    setFilteredClasses(result);
                } else {
                    const errorMessage = `${methodName} failed to get class`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            })
        } else {
            setClasses([]);
            setFilteredClasses([]);
            setDisableGroupFilter(false);
            setSelectedClasses(undefined);
        }
    }

    function disableItemFilterCheck(actualFilter: string, id: string) {
        switch (id) {
            case "firstName":
                if (actualFilter.length == 0 && filterLastName.length == 0 && filterMail.length == 0) {
                    setDisableItemFilter(false);
                } else {
                    setDisableItemFilter(true);
                    setSelectedItem(undefined);
                }
                setFilterFirstName(actualFilter);
                break;
            case "lastName":
                if (filterFirstName.length == 0 && actualFilter.length == 0 && filterMail.length == 0) {
                    setDisableItemFilter(false);
                } else {
                    setDisableItemFilter(true);
                    setSelectedItem(undefined);
                }
                setFilterLastName(actualFilter);
                break;
            case "mail":
                if (filterFirstName.length == 0 && filterLastName.length == 0 && actualFilter.length == 0) {
                    setDisableItemFilter(false);
                } else {
                    setDisableItemFilter(true);
                    setSelectedItem(undefined);
                }
                setFilterMail(actualFilter);
                break;
        }
    }

    function renderFilter() {
        return (
            <>
                <div className="searchByUser__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>MailTo:SearchUser</Translate></Heading>
                    <div className="search__spacer">
                        <p dangerouslySetInnerHTML={{ __html: Session.instance.storage.translation.GetString('MailTo:InfoText') }} />
                    </div>
                </div>

                <div className="mailTo__container">
                    <div role="table" className="mailTo__filter-table">
                        <div role="rowgroup">

                            {/* Filter Firstname */}
                            <div role="row" className="mailTo__filter-table-row filter__margin">
                                <div role="columnheader">
                                    <Translate>MailTo:SearchByFirstName</Translate>
                                </div>
                                <div role="cell">
                                    <input
                                        type="text"
                                        aria-label={tr.GetString('MailTo:SearchByFirstName')}
                                        placeholder={tr.GetString('MailTo:SearchByFirstName')}
                                        value={filterFirstName}
                                        className="input-field filter__padding"
                                        onChange={(e) => { disableItemFilterCheck(e.target.value, "firstName") }}
                                        disabled={disableGroupFilter}
                                    />
                                </div>
                            </div>

                            {/* Filter Lastname */}
                            <div role="row" className="mailTo__filter-table-row filter__margin">
                                <div role="columnheader">
                                    <Translate>MailTo:SearchByLastName</Translate>
                                </div>
                                <div role="cell">
                                    <input
                                        type="text"
                                        aria-label={tr.GetString('MailTo:SearchByLastName')}
                                        placeholder={tr.GetString('MailTo:SearchByLastName')}
                                        value={filterLastName}
                                        className="input-field filter__padding"
                                        onChange={(e) => { disableItemFilterCheck(e.target.value, "lastName") }}
                                        disabled={disableGroupFilter}
                                    />
                                </div>
                            </div>

                            {/* Filter Mail */}
                            <div role="row" className="mailTo__filter-table-row filter__margin">
                                <div role="columnheader">
                                    <Translate>MailTo:SearchByMail</Translate>
                                </div>
                                <div role="cell">
                                    <input
                                        type="text"
                                        aria-label={tr.GetString('MailTo:SearchByMail')}
                                        placeholder={tr.GetString('MailTo:SearchByMail')}
                                        value={filterMail}
                                        className="input-field filter__padding"
                                        onChange={(e) => { disableItemFilterCheck(e.target.value, "mail") }}
                                        disabled={disableGroupFilter}
                                    />
                                </div>
                            </div>

                            {/* Filter Title */}
                            <div role="row" className="mailTo__filter-table-row">
                                <div role="columnheader">
                                    <Translate>MailTo:SearchByGroup</Translate>
                                </div>
                                <div role="cell">

                                    <MultiSelect
                                        data={filteredGroups}
                                        label={tr.GetString('MailTo:SearchGroup')}
                                        dataItemKey="groupId"
                                        textField="groupName"
                                        filterable={true}
                                        onChange={(e) => handleGroupSelectionChanged(e)}
                                        onFilterChange={(e) => groupFilterChanged(e)}
                                        value={selectedGroups}
                                        allowCustom={false}
                                        disabled={disableGroupFilter}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="searchByUser__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>MailTo:SearchForItem</Translate></Heading>
                    <div className="search__spacer">
                        <p dangerouslySetInnerHTML={{ __html: Session.instance.storage.translation.GetString('MailTo:SearchByClass') }} />
                    </div>
                </div>

                <div className="mailTo__container">
                    <div role="table" className="mailTo__filter-table">
                        <div role="rowgroup">

                            {/* Filter Class */}
                            <div role="row" className="mailTo__filter-table-row">
                                <div role="columnheader">
                                    <Translate>MailTo:SearchByItem</Translate>
                                </div>
                                <div role="cell" className="mailTo_filter-table-row__space">

                                    <GenericFilter
                                        id='MailToItemFilter'
                                        fetchedItemList={items}
                                        label={tr.GetString('MailTo:Item')}
                                        filterable={true}
                                        suggest={false}
                                        onSelectedItemChange={(itm) => { onChangeItemType(itm) }}
                                        className="my-team__item-filter"
                                        required={true}
                                        disabled={disableItemFilter}
                                    />
                                </div>
                            </div>


                            <div role="row" className="mailTo__filter-table-row">
                                <div role="columnheader">
                                    <Translate>MailTo:SearchClass</Translate>
                                </div>
                                <div role="cell">

                                    <MultiSelect
                                        data={filteredClasses}
                                        label={tr.GetString('MailTo:SearchClassText')}
                                        dataItemKey="classId"
                                        textField="classString"
                                        filterable={true}
                                        onChange={(e) => handleClassSelectionChanged(e)}
                                        onFilterChange={(e) => classFilterChanged(e)}
                                        value={selectedClasses}
                                        allowCustom={false}
                                        disabled={disableItemFilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function renderMailOption() {
        return (
            <>
                <div className="button-position">
                    <GTButton
                        onClick={() => setShowSpinner(true)}
                        additionalClassNames="button-position"
                        aria-label={tr.GetString("MailTo:LoadUser")}>
                        <Translate>MailTo:LoadUser</Translate>
                    </GTButton>

                </div>

                <div>
                    <GTInfoMessage
                        id={"CountOfFoundUser"}
                        message={Session.instance.storage.translation.GetString('MailTo:CountUserFound')
                            .Format(getCountOfUsers())}
                        showInfoIcon={true} />
                </div>

                <div className="button-position">
                    <GTButton
                        onClick={() => onShowUser()}
                        aria-label={tr.GetString("MailTo:ShowUser")}
                        defaultButtonProps={{ disabled: users.length == 0 }}>
                        <Translate>MailTo:ShowUser</Translate>
                    </GTButton>
                </div>
                <div>
                    <Translate>MailTo:SubjectText</Translate>
                </div>

                <div className="mailTo__container">

                    <div role="table" className="mailTo__filter-table">
                        <div role="rowgroup">

                            {/* Filter Title */}
                            <div role="row" className="mailTo__filter-table-row">
                                <div role="columnheader">
                                    <Translate>MailTo:SubjectHeader</Translate>
                                </div>
                                <div role="cell">
                                    <input
                                        type="text"
                                        aria-label={tr.GetString('MailTo:Subject')}
                                        placeholder={tr.GetString('MailTo:Subject')}
                                        value={subject}
                                        className="input-field"
                                        onChange={(e) => { setSubject(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        )
    }

    function onCreateLinkClick() {
        let tmpMails = "";
        users.map(user => {
            if (user.forMailLink) {
                if (tmpMails.length > 0) {
                    tmpMails = tmpMails + ";" + user.userMail;
                } else {
                    tmpMails = user.userMail;
                }
            }
        })
        const link = `mailto:?bcc=${tmpMails}&subject=${subject}`;
        if (link.length > 2040) {
            setShowError(true);
        } else {
            window.open(link);
            setShowError(false);
        }
    }

    function onToggleUser(userId: number) {
        users.map(user => {
            if (user.userId == userId) {
                user.forMailLink = !user.forMailLink;
            }
        })
    }

    function onCancelModal() {
        users.map(user => {
            user.forMailLink = true;
        })
        setShowModal(false);
    }

    function showUser() {

        const headerElements: GTHeaderContent[] = [];

        headerElements.push({
            cellId: 'User',
            cellElement: Session.instance.storage.translation.GetString('MailTo:User')
        });

        headerElements.push({
            cellId: 'Mail',
            cellElement: Session.instance.storage.translation.GetString('MailTo:Mail')
        });

        headerElements.push({
            cellId: 'Username',
            cellElement: Session.instance.storage.translation.GetString('MailTo:Username')
        });

        const headerRow = <GTHorizontalTableHeaderRow
            headerElements={headerElements} />


        const rows = () => {
            const tableRows: JSX.Element[] = [];
            users.map((user, index) => {
                const rowContent: GTRowContent[] = [];
                rowContent.push({
                    mobileColumnHeader: Session.instance.storage.translation.GetString('MailTo:User'),
                    cell: <CheckBox id={`cbForMailLink${user.userId}`} onClick={() => onToggleUser(user.userId)} defaultChecked={user.forMailLink}> {user.userFirstName + " " + user.userLastName}</CheckBox>,
                    cellId: `user_${index}`
                })
                rowContent.push({
                    mobileColumnHeader: Session.instance.storage.translation.GetString('MailTo:Mail'),
                    cell: <>{user.userMail}</>,
                    cellId: `mail_${index}`,
                })
                rowContent.push({
                    mobileColumnHeader: Session.instance.storage.translation.GetString('MailTo:Username'),
                    cell: <>{user.username}</>,
                    cellId: `username_${index}`,
                })


                tableRows.push(<GTHorizontalTableRow
                    key={`user_${index}`}
                    rowContent={rowContent} />);
            });
            return tableRows;
        }
        return (
            <ModalPopup isOpen={true} onRequestClose={() => setShowModal(false)} contentCssClass="modal__mailTo" >
                <div>
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>MailTo:FoundUsers</Translate></Heading>
                    <Translate>MailTo:PopUpSubTitle</Translate>

                    <GTHorizontalTable
                        headerElement={headerRow}
                        rows={rows()} />

                    <div className="modal__spread-buttons">
                        <GTButton onClick={() => setShowModal(false)}
                            ariaLabel={Session.instance.storage.translation.GetString('MailTo:OK')}>
                            <Translate>MailTo:OK</Translate>
                        </GTButton>
                        <GTButton onClick={() => onCancelModal()}
                            ariaLabel={Session.instance.storage.translation.GetString('MailTo:Cancel')}
                            isPrimary={false}>
                            <Translate>MailTo:Cancel</Translate>
                        </GTButton>
                    </div>
                </div>
            </ModalPopup>
        )
    }

    return (
        <>
            <div className="l-container title">
                <MenuBreadCrumb />
                <Heading headingLevel={1} cssClass="heading__Title"><Translate>MailTo:Title</Translate></Heading>
                <div>
                    <Translate>MailTo:SubTitle</Translate>
                </div>
            </div>

            <div className="l-container">

                {renderFilter()}
                {showSpinner ? <ProgressSpinner /> :
                    <>
                        {renderMailOption()}

                        < div className="button-position">
                            <GTButton
                                onClick={() => onCreateLinkClick()}
                                aria-label={tr.GetString("MailTo:CreateLink")}
                                defaultButtonProps={{ disabled: users.length == 0 }}>
                                <Translate>MailTo:CreateLink</Translate>
                            </GTButton>
                        </div>
                        {showError && <ErrorMessage errorMessage={tr.GetString("MailTo:TooLongLink")} />}
                    </>
                }
            </div>
            {showModal && showUser()}
        </>
    )
}