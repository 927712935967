import * as React from 'react';

import Session from '$core/Session';
import { Item } from '$storage/models/Item';
import { Tooltip } from '../../shared/Tooltip';
import { Rating } from '@progress/kendo-react-inputs';

interface IProps {
    item: Item;
    starsCount: number;
}

interface IState {
    hoverValue: number;
    shouldShowRatingModal: boolean;
    dataLoaded: boolean;
    errorMessage: string;
}

export class ToolbarItemRating extends React.Component<IProps, IState> {

    protected className = 'ToolbarItemRating';
    protected loggerLocality = 'Components.ItemRatingDetails';

    constructor(props: IProps) {
        super(props)
        this.state = {
            dataLoaded: false,
            errorMessage: '',
            hoverValue: 0,
            shouldShowRatingModal: false
        };
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        const item = this.props.item;
        const isRatingEnabled = item.isRatingEnabled;
        const averageRating = item.ratingAverage / 2;
        const myRating = item.ratingMine / 2;
        const count = item.ratingCount;

        let hoverText = '';
        if (isRatingEnabled) {
            if (myRating === 0 ) {
                hoverText = tr.GetString('Rating:NotRated')
                    .replace('{0}', count.toString())
                    .replace('{1}', averageRating.toString())
            } else {
                hoverText = tr.GetString('Rating:AlreadySelected')
                    .replace('{0}', count.toString())
                    .replace('{1}', averageRating.toString())
                    .replace('{2}', myRating.toString());
            }
            return (
                <>
                    <label key={`star-rating_${this.props.item.itemId}`}
                        className={"l-box__toolbar__icon lesson-tools lesson-tools--rating" }
                        aria-label={tr.GetString('Rating:ShowItemRating')}
                        data-tip={hoverText}
                        data-for={`${this.props.item.itemId}_myRatingTooltip`}
                        data-event={'focus mouseover mouseenter'}
                        data-event-off={'blur mouseleave mouseout'}>
                         <Rating
                            value={averageRating}
                            disabled={true} />
                        <Tooltip id={`${this.props.item.itemId}_myRatingTooltip`} /> 
                        <div className="lesson-tools--rating__details">
                            {this.props.item.ratingCount}
                        </div>
                    </label>
                </>
            )
        } else {
            hoverText = tr.GetString('Rating:Disabled');
            return (
                <div className="l-box__toolbar__icon lesson-tools lesson-tools--rating star-select:focus" title={hoverText} />
            )
        }
    }
}
