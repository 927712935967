import React from "react";
import GTButton from "./GTButton";
import { Translate } from "../Translate";
/**
 * The GT Confirm Props
 */

export interface IGtConfirmTextProps {
    confirmText?: string;
    cancelText?: string;
}
export interface IGTConfirmProps extends IGtConfirmTextProps {
    onConfirm: (event: React.FormEvent<HTMLButtonElement>) => void;
    onCancel: () => void;
}

/**
 * Global Teach Confirm Component
 * @param props 
 */
export default function GTConfirm(props: IGTConfirmProps) {
    return (
        <div className="gt-confirm_alginment">
            <GTButton id="GtConfirmTrue"
                onClick={(event) => props.onConfirm(event)}>
                {props.confirmText ? props.confirmText : <Translate>Common:ConfirmAction</Translate>}
            </GTButton>
            <GTButton id="GtConfirmFalse"
                onClick={() => props.onCancel()}
                isPrimary={false}>
                {props.cancelText ? props.cancelText : <Translate>Common:CancelAction</Translate> }
            </GTButton>
        </div>
    )
}