import { Item } from '$src/storage/models/Item';
import { JsonObject, JsonProperty } from 'json2typescript';


@JsonObject
export class ReportUrlObject {

    @JsonProperty('reportName', String, false)
    public reportName: string = '';

    @JsonProperty('reportUrl', String, false)
    public reportUrl: string = '';

}

// tslint:disable-next-line: max-classes-per-file
@JsonObject
export class ItemsReportResponse extends Item {

    @JsonProperty('subjects', String, false)
    public subjects: string = '';

    @JsonProperty('completeTitle', String, false)
    public completeTitle: string = '';

    @JsonProperty('reportUrl', [ReportUrlObject], false)
    public reportUrl: ReportUrlObject[] =[];
}

