import { Attribute } from '$src/storage/models/Attribute';
import { ClassStatusConverter } from '$src/storage/models/converters/ClassStatusConverter';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { UtcDateTimeConverter } from '$src/storage/models/converters/UtcDateTimeConverter';
import { ECancellationPermission, EClassStatus } from '$src/storage/models/enums';
import { DateTimeSpan } from '$src/storage/models/F2F/DateTimeSpan';
import { Room } from '$src/storage/models/F2F/Room';
import { RegisteredItem } from '$src/storage/models/RegisteredItem';
import { JsonObject, JsonProperty } from 'json2typescript'
import { Trainer } from '$src/storage/models/F2F/Trainer';

@JsonObject
export class F2FClassDetail {

    /** Class Id of the corresponding class */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** Class Id of the corresponding class */
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    @JsonProperty('guid', String, false)
    public guid: string = '';

    /** Course Id of the corresponding class */
    @JsonProperty('courseId', Number, false)
    public courseId: number = 0;

    /** Class Code of the corresponding class */
    @JsonProperty('classCode', String, false)
    public classCode: string = '';

    /** Class Title of the corresponding class */
    @JsonProperty('classTitle', String, false)
    public classTitle: string = '';

     /** Class Description of the corresponding class */
     @JsonProperty('classDescription', String, false)
     public classDescription: string = '';

    /** Venue City of the corresponding class  (Top 1 of all rooms) */
    @JsonProperty('venueCity', String, false)
    public venueCity: string = '';

    /** Max participants of the corresponding class. If is 0, freePlaces can be ignored */
    @JsonProperty('maxParticipants', Number, false)
    public maxParticipants: number = 0;

    /** Number of free places of the corresponding class */
    @JsonProperty('freePlaces', Number, false)
    public freePlaces: number = 0;

    /** Learning period start of the corresponding class */
    @JsonProperty('learningPeriodStart', UtcDateTimeConverter, false)
    public learningPeriodStart: Date | undefined = undefined;

    /** Learning period end of the corresponding class */
    @JsonProperty('learningPeriodEnd', UtcDateTimeConverter, false)
    public learningPeriodEnd: Date | undefined = undefined;

    /** Registration start of the corresponding class */
    @JsonProperty('registrationStart', DateTimeConverter, false)
    public registrationStart: Date | undefined = undefined;

    /** Registration end of the corresponding class */
    @JsonProperty('registrationEnd', DateTimeConverter, false)
    public registrationEnd: Date | undefined = undefined;

    /** cancellationDate1 of the corresponding class */
    @JsonProperty('cancellationDate1', DateTimeConverter, false)
    public cancellationDate1: Date | undefined = undefined;

    /** cancellationDate2 of the corresponding class */
    @JsonProperty('cancellationDate2', DateTimeConverter, false)
    public cancellationDate2: Date | undefined = undefined;

    /** checkingDateOfStartCondition of the corresponding class */
    @JsonProperty('checkingDateOfStartCondition', DateTimeConverter, false)
    public checkingDateOfStartCondition: Date | undefined = undefined;

    /** Price of the corresponding class */
    @JsonProperty('price', Number, false)
    public price: number = 0;

    /** Price info of the corresponding class */
    @JsonProperty('priceInfo', String, false)
    public priceInfo: string = '';

    /** Price currency of the corresponding class */
    @JsonProperty('currency', String, false)
    public currency: string = '';

    /** Schedules list of the corresponding class */
    @JsonProperty('schedulesList', [DateTimeSpan], false)
    public schedulesList: DateTimeSpan[] | null = null;

    /** Room list of the corresponding class */
    @JsonProperty('rooms', [Room], false)
    public rooms: Room[] | null = null;

    /** Trainer list of the corresponding class */
    @JsonProperty('trainers', [Trainer], false)
    public trainers: Trainer[] | null = null;

    @JsonProperty('hasRegistrationRight', Boolean, false)
    public hasRegistrationRight: boolean = false;

    /** User is tutor of this class */
    @JsonProperty('isTutor', Boolean, false)
    public isTutor: boolean = false;

    @JsonProperty('hasWaitingList', Boolean, false)
    public hasWaitingList: boolean = false;

    /** Class status (F2F class or training plan class). */
    @JsonProperty('classStatus', ClassStatusConverter, true)
    public classStatus: EClassStatus = EClassStatus.Undefined;

    /** Class duration in days (schedule lower than 4 hours = 0.5, schedule greater than 4 hours = 1) */
    @JsonProperty('classDurationInDays', Number, true)
    public classDurationInDays: number = 0;

    @JsonProperty('isAutoregister', Boolean, false)
    public isAutoregister: boolean = false;

    @JsonProperty('courseTitle', String, false)
    public courseTitle: string = '';

    /** List of all (custom) attributes in selected language or in default language. */
    @JsonProperty('attributes', [Attribute], false)
    public attributes: Attribute[] = [];

    /** List of overlapping registrations (only accepted registrations) */
    @JsonProperty('overlappingRegistrations', [RegisteredItem], false)
    public overlappingRegistrations: RegisteredItem[] = [];

    /** The f2F class Language (this is a text field in the AUI) */
    @JsonProperty('language', String, false)
    public language: string = '';

    @JsonProperty('domainId', Number, false)
    public domainId: number = 0;

    /** user can register later to an f2f/vc item of the tplan **/
    @JsonProperty('isPossibleToRegisterAfterTplanRegistration', Boolean, false)
    public isPossibleToRegisterAfterTplanRegistration: boolean = false;

    /** User Participation Self Confirmation Start (Date from when a user can self confirm his participation */
    @JsonProperty('userParticipationSelfConfirmationStart', DateTimeConverter, false)
    public UserParticipationSelfConfirmationStart: Date | undefined = undefined;

    /** User Participation Self Confirmation End (Date until when a user can self confirm his participation */
    @JsonProperty('userParticipationSelfConfirmationEnd', DateTimeConverter, false)
    public UserParticipationSelfConfirmationEnd: Date | undefined = undefined;

    /** Is Auto Confirmation when opening join link enabled */
    @JsonProperty('participationAutoConfirmationForVCG', Boolean, false)
    public participationAutoConfirmationForVCG: boolean = false;

    public get isCompleted(): boolean {
        return this.maxParticipants !== 0 && this.freePlaces <= 0;
    }

    /**
     * Returns true if the item has no cancellation period specified
     */
    public get hasNoCancellationPeriod(): boolean {
        return (this.cancellationDate1 == null && this.cancellationDate2 == null) 
    }

    public get isInRegistrationPeriod(): boolean {
        return this.registrationStart !== undefined && this.registrationStart <= new Date()
            && this.registrationEnd !== undefined && this.registrationEnd >= new Date();
    }

    public get isRegistrationAllowed(): boolean {
        if ((this.freePlaces > 0 || (this.freePlaces > 0 || this.hasWaitingList || this.maxParticipants === 0))
                && (this.registrationStart != null && new Date() >= this.registrationStart)
                && (this.registrationEnd != null && new Date() <= this.registrationEnd)
                && (this.classStatus !== EClassStatus.Cancelled && this.classStatus !== EClassStatus.Closed)) {
            return true;
        }
        return false;
    }

    /**
     * Returns true if today is before the checking date of start conditions
     */
    public get cheatStartcondition(): boolean {
        return this.checkingDateOfStartCondition != undefined && this.checkingDateOfStartCondition >= new Date();
    }

    /**
     * Returns true if today is before cancellationPeriod1 Date
     */
    public get isInCancellationPeriod1(): boolean {
        return this.cancellationDate1 !== undefined && this.cancellationDate1 > new Date();
    }

    /**
     * Returns true if today is between user self confirmation of approval dates
     */
         public get isInUserSelfConfirmationOfApprovalDates(): boolean {
            return this.UserParticipationSelfConfirmationStart != null && 
                   this.UserParticipationSelfConfirmationEnd != null &&
                   this.UserParticipationSelfConfirmationStart <= new Date() &&
                   this.UserParticipationSelfConfirmationEnd >= new Date();
        }

    /**
     * Returns true if today is before the cancellation Period 2 Date
     */
    public get isInCancellationPeriod2(): boolean {
        if (this.cancellationDate2 === undefined) {
            return false;
        } else if (new Date() > this.cancellationDate2) {
            return false;
        } else {
            return true;
        }
    }

    public get isCancellationAllowed(): ECancellationPermission{
        if(this.isInCancellationPeriod1){
            return ECancellationPermission.Allowed;
        }else if(this.isInCancellationPeriod2){
            return ECancellationPermission.WithWarning;
        }else if(this.hasNoCancellationPeriod){
            return ECancellationPermission.Allowed;
        }
        return ECancellationPermission.NotAllowed;
    }

    // Keys to can call property with key class['classCode']
    [key: string]: string | number | boolean | Date | DateTimeConverter | string[] | RegisteredItem[] | Attribute[] | Room[] | Trainer[] | DateTimeSpan[] | null | undefined
}