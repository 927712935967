import React from 'react';

import SearchPanel from '$src/components/search/elk/SearchPanel';
import { useHistory } from 'react-router';

interface IQuickSearchProps {
    onSearch?: () => void;
    onClose?: (e: React.FormEvent<HTMLButtonElement>) => void;
    isMenuQuickSearch? :  boolean;
}


export default function QuickSearch(props: IQuickSearchProps) {
    const history = useHistory();
    function onSearch(searchValue: string) {
        history.push({
            pathname: '/search',
            search: '?value=' + encodeURIComponent(searchValue)
        });
        props.onSearch && props.onSearch();
    }

    return (
        <SearchPanel
            classPrefix={"quicksearch"} 
            onSearch={(value) => onSearch(value)} 
            onClose={props.onClose} 
            className={props.isMenuQuickSearch ? 'quicksearch__menu' : ''}
            searchValue=''/>
    );
}

