import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TplanScheduleOrF2FClassDisplay')
export class TplanScheduleOrF2FClassDisplay {

    /** Id of the corresponding item. */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('clsOrSceId', Number, false)
    public clsOrSceId: number = 0;

    @JsonProperty('code', String, false)
    public code: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('description', String, false)
    public description: string = '';

    @JsonProperty('learnStart', DateTimeConverter, false)
    public learnStart: Date | undefined;

    @JsonProperty('learnEnd', DateTimeConverter, false)
    public learnEnd: Date | undefined;

    public displayText: string = '';
}