import { JsonObject, JsonProperty } from 'json2typescript';
/**
 * Item ID / SID
 */
@JsonObject('ItemId')
export class ItemId {

    /** Id of the corresponding item. */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** String Id of the corresponding item. */
    @JsonProperty('sId', String, false)
    public sId: string = '';

}

