import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';

/**
 *This Model is for showing the Service info which can be edit or create in the aui
 */
@JsonObject
export class ServiceInfo {

    /** Date  for string */
    @JsonProperty('systemOffFrom', DateTimeConverter, false)
    public systemOffFrom: Date | null = new Date();

    /**Date for string*/
    @JsonProperty('systemOffTo', DateTimeConverter, false)
    public systemOffTo: Date | null = new Date();

    /**Bool if the Service info shoulbe display*/
    @JsonProperty('shouldBeDisplay', Boolean, false)
    public shouldBeDisplay: boolean = false;
}
