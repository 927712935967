import Session from '$src/core/Session';
import React from 'react';

export interface IStepperDefinition {
    stepId: string;
    stepNumber: number;
    stepTitle: string;
    stepState: EStepState;
}

export enum EStepState { Undefined, IsActive, IsDone, IsLocked }

interface IProps {
    parentHeadingLevel: number;
    stepperDefinitions: IStepperDefinition[];
    onStepClick?: (stepNumber: number) => void;
}


export class Stepper extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);

        this.onStepClicked = this.onStepClicked.bind(this);
    }

    public render() {
        return (
            <div className="stepper notMobile">
                <ol className="steps">
                    {this.renderSteps()}
                </ol>
            </div>
        )
    }

    private renderSteps(): JSX.Element[] {
        const steps: JSX.Element[] = [];
        this.props.stepperDefinitions.map(step => {
            const ariaLabel = Session.instance.storage.translation.GetString('Stepper:NavigateTo').Format(step.stepTitle);
            steps.push(
                <li key={`step_${step.stepId}`}
                    className={step.stepState === EStepState.IsActive ? 'active' :
                        step.stepState === EStepState.IsDone ? 'done' :
                            step.stepState === EStepState.IsLocked ? 'locked' : ''}>
                    <span />
                    <button type="button"
                        aria-label={ariaLabel}
                        className="button-link"
                        onClick={() => this.onStepClicked(step.stepNumber)}
                        disabled={step.stepState === EStepState.IsLocked}>
                        {step.stepTitle}
                    </button>
                </li>
            )
        });

        return steps;
    }

    private onStepClicked(stepNumber: number) {
        if (this.props.onStepClick) {
            this.props.onStepClick(stepNumber);
        }
    }
}