import { F2FDocument } from '$src/storage/models/F2F/F2FDocument';
import { JsonObject, JsonProperty} from 'json2typescript'

@JsonObject
export class F2FDocumentList {
   /** Documents of the corresponding class */
    @JsonProperty('classDocuments', [F2FDocument], false)
    public classDocuments: F2FDocument[] | null = null;

    /** Documents of the corresponding course */
    @JsonProperty('courseDocuments', [F2FDocument], false)
    public courseDocuments: F2FDocument[] | null = null;

    /** Documents of the corresponding class and course */
    @JsonProperty('allDocuments', [F2FDocument], false)
    public allDocuments: F2FDocument[] | null = null;

    /** Documents of the corresponding class and course where the user is allowed to see */
    @JsonProperty('allowedDocuments', [F2FDocument], false)
    public allowedDocuments: F2FDocument[] | null = null;
}