import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { AssignSkillProfileModal } from '$components/myEmployees/actionModals/AssignSkillProfileModal';
import MyTeamActionCell from '$components/myEmployees/myTeamList/MyTeamActionCell';
import ReportList from '$components/myEmployees/reporting/ReportList';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import UserService from '$core/Services/UserService';
import Session from '$core/Session';
import { Group } from '$storage/models/Group';
import { User } from '$storage/models/User';
import { UserBossRelation } from '$storage/models/UserBossRelation';
import CustomErrorMessage from '$util/CustomErrorMessage';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';

import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import {
    Grid,
    GridCell,
    GridColumn,
    GridHeaderSelectionChangeEvent,
    GridNoRecords,
    GridPageChangeEvent,
    GridRowClickEvent,
    GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import { IntlProvider, load, LocalizationProvider } from '@progress/kendo-react-intl';

import currencyData from 'cldr-core/supplemental/currencyData.json';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from 'cldr-dates-full/main/de/dateFields.json';
import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
import deNumbers from 'cldr-numbers-full/main/de/numbers.json';

import enCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import enDateFields from 'cldr-dates-full/main/en/dateFields.json';
import enLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import enNumbers from 'cldr-numbers-full/main/en/numbers.json';

import { KendoArrayGridCell } from '$components/shared/Kendo/KendoArrayGridCell';
import { KendoBoolGridCell } from '$components/shared/Kendo/KendoBoolGridCell';
import { KendoDateGridCell } from '$components/shared/Kendo/KendoDateGridCell';
import { KendoLinkGridCell } from '$components/shared/Kendo/KendoLinkGridCell';
import { KendoAttributeGridCell } from '$components/shared/Kendo/KendoAttributeGridCell';

import { Alert } from '$components/shared/WarningsAndErrors/Alert';

import AssignLessonsModal from '$components/myEmployees/ActionModals/AssignLessonsModal';
import { AssignSkillsModal } from '$components/myEmployees/ActionModals/AssignSkillsModal';
import { RegisterLessonModal } from '$components/myEmployees/ActionModals/RegisterLessonModal';
import { DeputyManagement } from '$src/components/myEmployees/deputyManagement/DeputyManagement';
import TeamService from '$src/core/Services/TeamService';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { HeadingCollapsible } from '$src/components/shared/HeadingCollapsible';
import { UnmountClosed } from 'react-collapse';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import FunctionsList from '$src/components/myEmployees/FunctionsList';


interface IMatchParams {
    bossRelationCode: string;
}

type IProps = RouteComponentProps<IMatchParams>


interface IPagingState {
    skip: number;
    take: number;
}

interface ISortingState {
    sort: SortDescriptor[];
}
interface IFilteringState {
    filter: CompositeFilterDescriptor | undefined;
}
interface IDataState {
    employees: Array<{ employee: User; selected: boolean }> | null;
    isDataLoaded: boolean;
}
interface IState extends IPagingState, ISortingState, IFilteringState, IDataState {
    errorMessage: string;
    userId: number;
    showAssignLessonsModal: boolean;
    showAssignSkillsModal: boolean;
    showRegisterModal: boolean;
    disableButtons: boolean;
    showSkillProfileModal: boolean;
    shouldShowDeputyManagement: boolean;
    isReportsPanelCollapsed: boolean;
    isDeputyManagementPanelCollapsed: boolean;
    isFunctionPanelCollapsed: boolean;
    pageSize: number;
}

load(
    likelySubtags,
    currencyData,
    weekData,
    deNumbers,
    deLocalCurrency,
    deCaGregorian,
    deDateFields,
    enNumbers,
    enLocalCurrency,
    enCaGregorian,
    enDateFields,
);

/**
 * Displays a list of all the employees of this user
 */
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class MyTeam extends React.Component<IProps, IState> {

    private defaultTeamMatch = 'default';

    constructor(props: IProps) {
        super(props);
        // Use Session UserId if not empty
        let tempUsrId: number = 0;
        if (Session.instance.loginUser !== null) {
            tempUsrId = Session.instance.loginUser.id;
        }
        this.state = {
            disableButtons: true,
            employees: null,
            errorMessage: '',
            filter: undefined,
            isDataLoaded: false,
            shouldShowDeputyManagement: false,
            showAssignLessonsModal: false,
            showAssignSkillsModal: false,
            showRegisterModal: false,
            showSkillProfileModal: false,
            skip: 0,
            sort: [],
            take: 10,
            userId: tempUsrId,
            isReportsPanelCollapsed: true,
            isDeputyManagementPanelCollapsed: true,
            pageSize: 10,
            isFunctionPanelCollapsed: true
        }

    }

    public componentDidMount() {
        this.loadMyEmployees();
        this.loadShouldShowDeputyManagement();
        document.title = globalConfig.appProperties.title;
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.match.params.bossRelationCode !== prevProps.match.params.bossRelationCode) {
            this.loadMyEmployees();
            this.loadShouldShowDeputyManagement();
        }
    }

    public render() {
        if (this.state.userId === 0) {
            return <Redirect push={true} to="/" />;
        } else if (!this.state.isDataLoaded) {
            return <ProgressSpinner />
        } else if (Session.instance.getLoggedInUserBossrelations().find(
            r => r.bossTypeCode.toLocaleLowerCase() === this.props.match.params.bossRelationCode.toLocaleLowerCase() ||
                (this.props.match.params.bossRelationCode.toLocaleLowerCase() === this.defaultTeamMatch.toLocaleLowerCase() && r.isDefault)
        ) === undefined) {
            return <div className="l-container"><Alert alertAppereance="box" alertType="error" message="MyTeam:NoAccess" /></div>
        } else {
            const sectionsConfig = this.getSectionConfigurationByBossRelationCode(this.props.match.params.bossRelationCode);
            return (
                <div className="l-container">
                    <MenuBreadCrumb {...this.props} />
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title">
                        <Translate>MyTeam:Title</Translate>
                        {
                            ` - ${this.getRelationName()}`
                        }
                    </Heading>
                    <div>
                        <span className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                    {this.renderEmployees()}

                    {sectionsConfig?.sectionsOrderAndDisplay.map(section => {
                        if (section.show) {
                            return this.renderSection(section.id);
                        }
                        return null;
                    })}
                </div >
            );
        }
    }

    private renderSection(sectionId: string) {
        switch (sectionId.toLocaleLowerCase()) {
            case 'reports':
                return (
                    (Session.instance.loginUser?.roles.includes("Tutor") && <div key={sectionId}>
                        <HeadingCollapsible
                            headingLevel={2}
                            headingCssClass='heading__Title'
                            containerCssClass=""
                            isOpened={this.state.isReportsPanelCollapsed}
                            onToggleOpenedState={() => this.setState({ isReportsPanelCollapsed: this.state.isReportsPanelCollapsed ? false : true })}>
                            <Translate>MyTeam:Reports</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={this.state.isReportsPanelCollapsed ? false : true}>
                            <ReportList reports={globalConfig.myTeamProperties.reporting.myTeamReports}
                                additionalReports={globalConfig.myTeamProperties.reporting.additionalReports} bossRelationCode={this.props.match.params.bossRelationCode} />
                        </UnmountClosed>
                    </div>)

                );
            case 'deputymanagement':
                if (this.state.shouldShowDeputyManagement) {
                    return (
                        <div key={sectionId}>
                            <HeadingCollapsible
                                key={sectionId}
                                headingLevel={2}
                                headingCssClass='heading__Title'
                                containerCssClass=""
                                isOpened={this.state.isDeputyManagementPanelCollapsed}
                                onToggleOpenedState={() => this.setState({ isDeputyManagementPanelCollapsed: this.state.isDeputyManagementPanelCollapsed ? false : true })}>
                                <Translate>MyTeam:DeputyManagement</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={this.state.isDeputyManagementPanelCollapsed ? false : true}>
                                <DeputyManagement bossRelationCode={this.props.match.params.bossRelationCode} parentHeadingLevel={2} />
                            </UnmountClosed>
                        </div>
                    );
                }
                return null;
            case 'functions':
                return (
                    (Session.instance.loginUser?.roles.includes("Tutor") && <div key={sectionId}>
                        <HeadingCollapsible
                            key={sectionId}
                            headingLevel={2}
                            headingCssClass='heading__Title'
                            containerCssClass=""
                            isOpened={this.state.isFunctionPanelCollapsed}
                            onToggleOpenedState={() => this.setState({ isFunctionPanelCollapsed: this.state.isFunctionPanelCollapsed ? false : true })}>
                            <Translate>MyTeam:Functions</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={this.state.isFunctionPanelCollapsed ? false : true}>
                            <FunctionsList bossRelation={this.props.match.params.bossRelationCode} />
                        </UnmountClosed>
                    </div>)
                );
            default:
                return null;
        }

    }

    private getSectionConfigurationByBossRelationCode(bossRelationCode: string) {
        const defaultBossRelation = Session.instance.getLoggedInUserBossrelations().find(r => r.isDefault);
        if (bossRelationCode === this.defaultTeamMatch && defaultBossRelation != null) {
            bossRelationCode = defaultBossRelation.bossTypeCode;
        }
        let sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
            item => item.id.toLocaleLowerCase() === bossRelationCode.toLocaleLowerCase());
        if (sectionsConfig == null) {
            sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
                item => item.id.toLocaleLowerCase() === this.defaultTeamMatch);
        }
        sectionsConfig?.sectionsOrderAndDisplay.sort((n1, n2) => n1.order - n2.order);
        return sectionsConfig;
    }

    // checks if there is anything selected in the list in order to enable the buttons.
    private doesSelectionExist() {
        const modEmployees = [...(this.state.employees || [])];
        this.setState({ disableButtons: modEmployees.filter(e => e.selected === true).length === 0 });
    }

    private loadShouldShowDeputyManagement(): void {
        TeamService.instance.isDeputyOfRelation(this.props.match.params.bossRelationCode).then(res => {
            if (isSuccess<BooleanResponse>(res)) {
                this.setState({ shouldShowDeputyManagement: !res.status });
            }
        });
    }

    private selectionChange = (event: GridSelectionChangeEvent) => {
        // Copy employees to not modify state directly
        const modEmployees = [...(this.state.employees || [])];
        modEmployees.forEach(me => { me.selected = me === event.dataItem ? !me.selected : me.selected })
        this.setState({
            employees: modEmployees
        })
        this.doesSelectionExist();
    }

    private rowClick = (event: GridRowClickEvent) => {
        if (this.state.employees != null) {
            // Copy employees to not modify state directly
            const modEmployees = [...this.state.employees];

            // Selection of current = !currentSelection
            // Unselect others or keep selection if ctrl is pressed
            modEmployees.forEach(me => {
                me.selected = me === event.dataItem ? !me.selected :
                    event.nativeEvent.ctrlKey ? me.selected : false
            })
            this.setState({
                employees: modEmployees
            })
            this.doesSelectionExist();
        }
        this.doesSelectionExist();
    }

    private headerSelectionChange = (event: GridHeaderSelectionChangeEvent) => {
        if (this.state.employees != null) {
            // Copy employees to not modify state directly
            const modEmployees = [...this.state.employees];
            const filteredEmployees = this.state.filter && filterBy(
                modEmployees,
                this.state.filter);
            const hasFilter = this.state.filter != null;
            modEmployees.forEach(me => {
                if (hasFilter && filteredEmployees != null) {
                    me.selected = event.nativeEvent.target.checked && filteredEmployees.findIndex(fe => fe.employee.id === me.employee.id) >= 0;
                }
                else {
                    me.selected = event.nativeEvent.target.checked;
                }
            })
            this.setState({
                employees: modEmployees
            })
        }
        this.doesSelectionExist();
    }

    private allFilteredSelected = () => {
        if (this.state.employees != null) {
            const modEmployees = this.state.employees;
            const filteredEmployees = this.state.filter && filterBy(
                modEmployees,
                this.state.filter);
            const hasFilter = this.state.filter != null;
            if (hasFilter && filteredEmployees != null) {
                return filteredEmployees.filter(fe => fe.selected === true).length === filteredEmployees.length;
            }
            else {
                return modEmployees.filter(me => me.selected === true).length === modEmployees.length;
            }
        }
        else {
            return false;
        }
    }

    private pageChange = (event: GridPageChangeEvent) => {
        this.setState({
            skip: event.page.skip,
            take: event.page.take,
            pageSize: event.page.take
        });
    }

    private renderEmployees(): JSX.Element {
        if (this.state.employees === null) {
            if (this.state.errorMessage === '') {
                return (<ProgressSpinner />);
            }
        } else {
            const classNameHighlight = 'my-team__table__cell--highlight';
            const stringPrefixHeaderColumn = 'MyTeamTable';
            const objectHierarchieEmployee = 'employee';
            const gridColumnKeyPrefix = 'myTeamColumns';
            const selectedFieldName = 'selected';

            return (<React.Fragment>
                <LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
                    <IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
                        <Grid
                            data={this.state.filter != null ?
                                filterBy(
                                    orderBy(this.state.employees, this.state.sort),
                                    this.state.filter).slice(this.state.skip, this.state.take + this.state.skip) :
                                orderBy(this.state.employees, this.state.sort).slice(this.state.skip, this.state.take + this.state.skip)}

                            selectedField={selectedFieldName}
                            onSelectionChange={this.selectionChange}
                            onHeaderSelectionChange={this.headerSelectionChange}
                            onRowClick={this.rowClick}

                            sortable={true}
                            sort={this.state.sort}
                            onSortChange={(e) => {
                                this.setState({
                                    sort: e.sort
                                });
                            }}

                            filterable={true}
                            filter={this.state.filter}
                            onFilterChange={(e) => {
                                this.setState({
                                    filter: e.filter,
                                    skip: 0,
                                    take: 10,
                                    pageSize: 10
                                });
                            }}

                            onPageChange={this.pageChange}
                            total={this.state.filter != null ?
                                filterBy(this.state.employees, this.state.filter).length :
                                this.state.employees.length}
                            skip={this.state.skip}
                            pageSize={this.state.pageSize}
                            pageable={{ pageSizes: [10, 50, 100] }}

                            className="my-team__table"

                        >

                            <GridNoRecords>
                                <Translate>MyTeam:NoData</Translate>
                            </GridNoRecords>

                            <GridColumn
                                field={selectedFieldName}
                                filterable={false}
                                width={75}
                                headerSelectionValue={this.allFilteredSelected()}
                                sortable={false} />

                            {globalConfig.myTeamProperties.employeeList.employeeColumns.map((c, index) => {
                                if (c.id.toLocaleLowerCase() === 'action') {
                                    return <GridColumn
                                        key={`${gridColumnKeyPrefix}_${index}`}
                                        field={`${objectHierarchieEmployee}.id`}
                                        title={Session.instance.storage.translation.GetString(`${stringPrefixHeaderColumn}:${c.id}`)}
                                        width={c.width === 0 ? undefined : c.width}
                                        resizable={true}
                                        filterable={false}
                                        sortable={false}
                                        cell={(props) => <MyTeamActionCell
                                            {...props}
                                            location={this.props.location}
                                            history={this.props.history}
                                            match={this.props.match} />}
                                    />
                                }

                                return <GridColumn key={`${gridColumnKeyPrefix}_${index}`}
                                    field={`${objectHierarchieEmployee}.${c.id}`}
                                    title={Session.instance.storage.translation.GetString(`${stringPrefixHeaderColumn}:${c.id}`)}
                                    width={c.width === 0 ? undefined : c.width}
                                    resizable={true}
                                    className={c.highlight ? classNameHighlight : ''}
                                    sortable={c.allowSorting}
                                    filter={c.id.toLocaleLowerCase() === 'birthdate' ? 'date' : c.id.toLocaleLowerCase() === 'isActive' ? 'boolean' : 'text'}
                                    filterable={c.allowFiltering && (c.id.toLocaleLowerCase() !== 'groups' || c.id.toLocaleLowerCase() !== 'roles')}
                                    cell={(props) => {
                                        if (props.field != null) {
                                            const fieldNames = props.field.split('.');
                                            const fieldName = fieldNames[fieldNames.length - 1].toLocaleLowerCase();
                                            switch (fieldName) {
                                                case 'groups':
                                                    return <KendoArrayGridCell
                                                        {...props}
                                                        arrayDataField="group"
                                                        filterExpression={(d) => this.filterListData(d)} />
                                                case 'roles':
                                                    return <KendoArrayGridCell
                                                        {...props}
                                                        filterExpression={() => true} />
                                                case 'isactive':
                                                    return <KendoBoolGridCell
                                                        {...props}
                                                        trueText="user:isActive"
                                                        falseText="user:isInactive" />
                                                case 'birthdate':
                                                    return <KendoDateGridCell
                                                        {...props} />
                                                case 'attribute1':
                                                case 'attribute2':
                                                case 'attribute3':
                                                case 'attribute4':
                                                case 'attribute5':
                                                    return <KendoAttributeGridCell
                                                        {...props} />
                                                default:
                                                    if (c.linkToEmployeeDetails) {
                                                        return <KendoLinkGridCell
                                                            {...props}
                                                            className={c.highlight ? classNameHighlight : ''}
                                                            baseLink={`/employee/${this.props.match.params.bossRelationCode}`}
                                                            linkField={`${objectHierarchieEmployee}.id`} />
                                                    }
                                                    return <GridCell {...props} />
                                            }
                                        }
                                        return <GridCell {...props} />;
                                    }
                                    } />;
                            })}
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
                <div className="my-team__actions">
                    {
                        this.renderFunctionButtons()
                    }
                </div>
                {
                    this.state.showRegisterModal ?
                        <RegisterLessonModal
                            headingLevel={2}
                            headingClassName="heading__Level2"
                            isOpen={this.state.showRegisterModal}
                            onClose={() => this.setState({ showRegisterModal: false })}
                            itemContext={EItemDetailCallerContextType.Undefined}
                            selectedEmployees={this.state.showRegisterModal
                                ? this.state.employees.filter(e => e.selected).map(e => e.employee)
                                : undefined}
                            bossRelationCode={this.props.match.params.bossRelationCode} /> : ''
                }
                {
                    this.state.showAssignLessonsModal ?
                        <AssignLessonsModal isOpen={this.state.showAssignLessonsModal}
                            onClose={() => this.setState({ showAssignLessonsModal: false })}
                            employees={this.state.employees.filter(e => e.selected).map(e => e.employee)}
                            bossRelationCode={this.props.match.params.bossRelationCode} />
                        : ''

                }
                {
                    this.state.showAssignSkillsModal ?
                        <AssignSkillsModal
                            headingLevel={2}
                            headingClassName="heading__Level2" isOpen={this.state.showAssignSkillsModal}
                            onClose={() => this.setState({ showAssignSkillsModal: false })}
                            selectedEmployees={this.state.showAssignSkillsModal
                                ? this.state.employees.filter(e => e.selected).map(e => e.employee)
                                : undefined}
                            bossRelationCode={this.props.match.params.bossRelationCode} /> : ''
                }
                {
                    this.state.showSkillProfileModal ?
                        <AssignSkillProfileModal
                            isOpen={this.state.showSkillProfileModal}
                            onClose={() => this.setState({ showSkillProfileModal: false })}
                            employees={this.state.employees.filter(e => e.selected).map(e => e.employee)}
                            headingLevel={2}
                            headingClassName="heading__Level2"
                            bossRelationCode={this.props.match.params.bossRelationCode} /> : ''
                }

            </React.Fragment>);
        }
        return <React.Fragment />
    }

    private renderFunctionButtons(): JSX.Element[] {
        const sectionsConfig = this.getSectionConfigurationByBossRelationCode(this.props.match.params.bossRelationCode);
        const elements: JSX.Element[] = [];
        sectionsConfig?.buttonDisplay.map(fButton => {
            if (fButton.show) {
                switch (fButton.id) {
                    case 'enrol':
                        elements.push(
                            <button className="btn--md btn--primary my-team__action"
                                key={fButton.id}
                                onClick={() => this.setState({ showRegisterModal: true })}
                                disabled={this.state.disableButtons}
                                id={`btnMyTeam_${fButton.id}`}>
                                {Session.instance.storage.translation.GetString('MyTeam:EnrolUser')}
                            </button>
                        )
                        break;
                    case 'AssignRequirements':
                        elements.push(
                            <button className="btn--md btn--primary my-team__action"
                                disabled={this.state.disableButtons}
                                key={fButton.id}
                                onClick={() => this.setState({ showAssignLessonsModal: true })}
                                id={`btnMyTeam_${fButton.id}`}>
                                {Session.instance.storage.translation.GetString('MyTeam:AssignRequirements')}
                            </button>
                        )
                        break;
                    case 'AssignSkill':
                        elements.push(
                            <button className="btn--md btn--primary my-team__action"
                                disabled={this.state.disableButtons}
                                key={fButton.id}
                                onClick={() => this.setState({ showAssignSkillsModal: true })}
                                id={`btnMyTeam_${fButton.id}`}>
                                {Session.instance.storage.translation.GetString('MyTeam:AssignSkills')}
                            </button>
                        )
                        break;
                    case 'AssignProfile':
                        elements.push(
                            <button className="btn--md btn--primary my-team__action"
                                disabled={this.state.disableButtons}
                                key={fButton.id}
                                onClick={() => this.setState({ showSkillProfileModal: true })}
                                id={`btnMyTeam_${fButton.id}`}>
                                {Session.instance.storage.translation.GetString('MyTeam:AssignProfile')}
                            </button>
                        )
                        break;
                    case 'AddUser':
                        elements.push(
                            Session.instance.isBossAllowedToRegisterNewUsers() ?
                                <button className="btn--md btn--primary my-team__action"
                                    disabled={false}
                                    key={fButton.id}
                                    onClick={() => { this.props.history.push(`/userRegistration/${this.props.match.params.bossRelationCode}`) }}
                                    id={`btnMyTeam_${fButton.id}`}>
                                    {Session.instance.storage.translation.GetString('MyTeam:AddUser')}
                                </button> : <></>
                        )
                        break;
                }
            }
        })
        return elements;
    }

    private filterListData<T>(d: T) {
        if (d instanceof Group) {
            if (d.isBossEmployeeGroup) { return false }
            if (globalConfig.myTeamProperties.employeeList.employeeGroupColumnTypeFilter.length === 0) {
                return true
            }
            return globalConfig.myTeamProperties.employeeList.employeeGroupColumnTypeFilter.find(v =>
                v.toLocaleUpperCase() === d.groupTypeCode.toLocaleUpperCase()) != null;
        }
        return true;
    }

    private getRelationName(): string {
        let relation: UserBossRelation[] | null = null;
        if (this.props.match.params.bossRelationCode.toLocaleLowerCase() === this.defaultTeamMatch.toLocaleLowerCase()) {
            relation = Session.instance.getLoggedInUserBossrelations().filter(r => r.isDefault);
        }
        else {
            relation = Session.instance.getLoggedInUserBossrelations().filter(r =>
                r.bossTypeCode.toLocaleUpperCase() === this.props.match.params.bossRelationCode.toLocaleUpperCase());
        }
        return relation != null && relation.length === 1 ? relation[0].bossTypeTitle : '';
    }

    private async loadMyEmployees() {

        const params = this.props.match.params;
        const bossRelationCode: string | null = params.bossRelationCode != null ? params.bossRelationCode : null;
        let data: User[] | GtError;

        if (bossRelationCode != null && bossRelationCode.toLocaleLowerCase() !== this.defaultTeamMatch.toLocaleLowerCase()) {
            data = await UserService.instance.getEmployeesOfRelation(bossRelationCode);
        }
        else {
            data = await UserService.instance.getEmployees();
        }

        if (isSuccess<User[]>(data)) {
            this.setState({
                employees: data.sort((a, b) => this.defaultSortEmployees(a, b)).map(employee => Object.assign({ selected: false, employee })),
                errorMessage: data.length > 0 ? '' : 'MyTeam:NoEmployeesFound',
                isDataLoaded: true
            });
        } else {
            if (data.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(data.detailedObject.subStatusCode), isDataLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:MyTeamFailed', isDataLoaded: true });
            }
        }
    }

    private defaultSortEmployees(a: User, b: User): number {
        if (a.lastName > b.lastName) { return 1 }
        if (a.lastName < b.lastName) { return -1 }
        if (a.lastName === b.lastName && a.firstName > b.firstName) { return 1 }
        if (a.lastName === b.lastName && a.firstName < b.firstName) { return -1 }
        return 0;
    }
}
export default MyTeam;