import { GenericFilter, IGenericFilterItem } from "$src/components/shared/GenericFilter";
import { Heading } from "$src/components/shared/Heading";
import { ModalPopup } from "$src/components/shared/ModalPopup";
import { Translate } from "$src/components/shared/Translate";
import { Alert } from "$src/components/shared/WarningsAndErrors/Alert";
import Logger from "$src/core/Logger";
import AttributeService from "$src/core/Services/AttributeService";
import SkillService from "$src/core/Services/SkillService";
import TeamService from "$src/core/Services/TeamService";
import Session from "$src/core/Session";
import { AttributeValue } from "$src/storage/models/AttributeValue";
import { EAttributeType } from "$src/storage/models/enums";
import { RemoveSkillsRequest } from "$src/storage/models/RequestObjects/RemoveSkillsRequest";
import { ItemSkillDisplay } from "$src/storage/models/Skill/ItemSkillDisplay";
import { RemoveSkillResponse, RemoveSkillResponseContainer } from "$src/storage/models/Skill/RemoveSkillResponse";
import { UserSkill } from "$src/storage/models/Skill/UserSkill";
import { UserSkillProfileSkill } from "$src/storage/models/SkillProfile/UserSkillProfileSkill";
import { User } from "$src/storage/models/User";
import GtError from "$src/util/GtError";
import { isSuccess } from "$src/util/Result";
import { filterBy } from "@progress/kendo-data-query";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import React from "react";

interface IProps {
    isOpen: boolean;
    onClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    onTargetSkillRemovalSuccess: (removals: RemoveSkillResponse[]) => void;
    headingClassName?: string;
    headingLevel: number;

    userTargetSkills?: UserSkillProfileSkill[];
    achievedSkills?: UserSkill[];

    isForTargetSkills: boolean;
    translationPrefix: string;

    employees: User[];
    bossRelationCode: string;
}

interface IStateData {
    targetSkillTypes: IGenericFilterItem[];
    selectedTargetSkillType: IGenericFilterItem;

    storedServerResponse: undefined | RemoveSkillResponse[];

    allSkills: ItemSkillDisplay[];
    allRemovableSkills: ItemSkillDisplay[] | undefined;
    filteredSkills: ItemSkillDisplay[];
    selectedSkills: ItemSkillDisplay[] | undefined;
    skillFilter: string;
}

interface IState extends IStateData {
    errorMessage: string;
}

export class RemoveSkillsModal extends React.Component<IProps, IState>{
    protected loggerLocality = 'Components.RemoveSkillsModal';
    protected TRANSLATION_PREFIX = this.props.translationPrefix; //'RemoveTargetSkills' or 'EmployeeDetail'
    protected className = 'RemoveSkillsModal';

    private allTargetSkillType =
        {
            id: 0,
            text: Session.instance.storage.translation.GetString(`AssignSkills:AllSkillTypes`) // does not work with this.getTranslation(), because of init.
        } as IGenericFilterItem;

    constructor(props: IProps) {
        super(props)

        this.state = {
            targetSkillTypes: [],
            selectedTargetSkillType: this.allTargetSkillType,

            errorMessage: '',
            storedServerResponse: undefined,

            allSkills: [],
            allRemovableSkills: undefined,
            filteredSkills: [],
            selectedSkills: undefined,
            skillFilter: ''
        }
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const allPromises = new Array<Promise<unknown>>();
        allPromises.push(this.loadTargetSkillTypes());
        allPromises.push(this.loadSkillsWithAccessRight());
        await Promise.all(allPromises);

        const assignedTargetSkills: (ItemSkillDisplay[] | undefined) = [];
        // This modal is used for target skills and skills
        if (this.props.isForTargetSkills) {
            // This block selects all TargetSkills from the list of assignableTargetSkills (API) that are already assigned to the user (UI)
            this.props.userTargetSkills?.map((userSkill) => {
                const skill = this.state.allSkills.find(skill => skill.id === userSkill.skillId)
                if (skill) {
                    assignedTargetSkills.push(skill);
                }
            })
        } else {
            // This block selects all skills from the list of assignableSkills (API) that are already assigned to the user (UI)
            this.props.achievedSkills?.map((userSkill) => {
                const skill = this.state.allSkills.find(skill => skill.id === userSkill.skillId)
                if (skill) {
                    assignedTargetSkills.push(skill);
                }
            })
        }


        // Filter out types that dont have a removable target skill
        const filterableProfilesTypes: IGenericFilterItem[] = [];
        this.state.targetSkillTypes.map((skillType) => {
            if (assignedTargetSkills?.some(targetSkill => targetSkill.skillTypeId == skillType.id)) {
                filterableProfilesTypes.push(skillType);
            }
        })

        this.setState({ allRemovableSkills: assignedTargetSkills, filteredSkills: assignedTargetSkills, targetSkillTypes: filterableProfilesTypes })
    }

    public render() {
        return (
            <ModalPopup
                isOpen={this.props.isOpen}
                onRequestClose={(e) => this.closeModal(e)}
                contentCssClass="assign-skill-profile">
                <form className="assign-skill-profile__form">
                    <Heading
                        headingLevel={this.props.headingLevel}
                        cssClass={this.props.headingClassName != null ? this.props.headingClassName : 'heading__Level2'}>
                        <Translate>{`${this.TRANSLATION_PREFIX}:Title`}</Translate>
                    </Heading>
                    {this.renderContent()}
                    {this.renderButtons()}
                </form>
            </ModalPopup>
        );
    }

    private renderButtons(): JSX.Element {
        const methodName = `${this.className}:renderButtons()`;
        Logger.log(this.loggerLocality, `${methodName} rendering buttons`);

        return <div
            key={`assign-skill-profile__button__container`}
            className={'assign-skill-profile__button-container modal__spread-buttons'}>
            {!this.state.storedServerResponse &&
                <button
                    type="button"
                    className="btn btn--sm"
                    disabled={!this.state.selectedSkills || this.state.selectedSkills.length === 0}
                    onClick={() => this.removeSkills()}>
                    <Translate>{`${this.TRANSLATION_PREFIX}:Save`}</Translate>
                </button>
            }
            <button
                type="button"
                className="btn btn--sm"
                onClick={(e) => this.closeModal(e)}>
                {this.state.storedServerResponse != null ?
                    <Translate>{`${this.TRANSLATION_PREFIX}:Close`}</Translate> :
                    <Translate>{`${this.TRANSLATION_PREFIX}:Cancel`}</Translate>
                }
            </button>
        </div>
    }

    private async removeSkills() {
        const methodName = `${this.className}:removeTargetSkills()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const removeRequest = new RemoveSkillsRequest();

        if (this.state.selectedSkills != null) {
            this.state.selectedSkills.forEach(targetSkill => {
                Logger.log(this.loggerLocality, `${methodName} removing skill ${targetSkill.id}`);
                removeRequest.skillIds.push(targetSkill.id)
            });
            removeRequest.userIds = this.props.employees.map(e => e.id);
            removeRequest.isTargetSkill = this.props.isForTargetSkills;

            const response = await TeamService.instance.RemoveSkillsFromEmployees(removeRequest);
            if (isSuccess<RemoveSkillResponseContainer>(response)) {
                if (this.props.onTargetSkillRemovalSuccess) {
                    this.props.onTargetSkillRemovalSuccess(response.removeSkillResponses);
                }
                this.setState({ storedServerResponse: response.removeSkillResponses });
            } else {
                const errorMessage = `${methodName} failed to remove skill from users. ${response.message}`;
                console.error(errorMessage);

                this.setState({ errorMessage: this.TRANSLATION_PREFIX + ':Error' });
            }
        }
    }

    private renderContent(): JSX.Element {
        const methodName = `${this.className}:renderContent()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        // User tried to remove target skill(s) => render answer instead of selection
        if (this.state.storedServerResponse != null) {
            return this.renderServerResults();
        }

        // Add option to select all skills to list
        const allTargetSkills = Object.assign(new ItemSkillDisplay(),
            {
                id: 0,
                title: this.getTranslation(`AllSkills`)
            });
        const filteredTargetSkills = [allTargetSkills, ...this.state.filteredSkills]

        return (
            <React.Fragment>
                {/* This is the target skill type filter */}
                <GenericFilter
                    fetchedItemList={this.state.targetSkillTypes}
                    initValue={this.allTargetSkillType}
                    label={Session.instance.storage.translation.GetString(`AssignSkills:lblCompetenceType`)}
                    filterable={true}
                    suggest={false}
                    onSelectedItemChange={(itm) => { this.onChangeTargetSkillType(itm) }}
                    className="my-team__item-filter"
                    required={true}
                />
                {/* This is the target skill filter */}
                <MultiSelect
                    data={filteredTargetSkills}
                    label={Session.instance.storage.translation.GetString(`AssignSkills:lblCompetenceSearch`)}
                    dataItemKey="id"
                    textField="title"
                    filterable={true}
                    onChange={(e) => this.handleTargetSkillSelectionChanged(e)}
                    onFilterChange={(e) => this.handleTargetSkillFilterChanged(e)}
                    value={this.state.selectedSkills}
                    allowCustom={false}
                />
            </React.Fragment>
        )
    }

    private renderServerResults(): JSX.Element {
        if (this.state.storedServerResponse != null) {
            return <Alert
                alertType="mixed"
                alertAppereance="box"
                className="no-background no-padding no-margin"
                messages={this.getResultMessages()}
            />
        }
        return <React.Fragment />;
    }

    private getResultMessages(): Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> {
        const methodName = `${this.className}:getResultMessage()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const messages: Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> = [];
        const serverResponses = this.state.storedServerResponse;

        this.props.employees.map(employee => {
            if (serverResponses == null) {
                const errorMessage = `${methodName} got no results`;
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);
                // erver did not respond
                messages.push({
                    alertType: 'error',
                    message: `${employee.fullName}: ${this.getTranslation(`ResultNoAnswer`)}`
                })
            } else {
                if (serverResponses.length === 0) {
                    const errorMessageInvalidResult = `${methodName} invalid result for user ${employee.fullName}`;
                    Logger.log(this.loggerLocality, errorMessageInvalidResult);
                    console.error(errorMessageInvalidResult);
                    // Got an empty Result
                    messages.push(
                        {
                            alertType: 'error',
                            message: `${employee.fullName}: ${this.getTranslation(`ResultNoResult`)}`
                        }
                    );
                } else {
                    serverResponses.map(response => {
                        if (this.state.selectedSkills) {
                            // Skill not found, perfect
                            const skill = this.state.selectedSkills.find(sp => sp.id === response.skillId);
                            if (skill) {
                                messages.push({
                                    alertType: response.removed ? 'success' : 'error',
                                    message: `${employee.fullName}: ${response.removed ?
                                        this.getTranslation(`ResultSuccessfull`).Format(skill?.title) :
                                        this.getTranslation(`ResultError`)}`
                                });
                            }
                        }
                    })
                }
            }
        });
        return messages;
    }

    private async loadTargetSkillTypes() {
        const methodName = `${this.className}:loadSkillProfileTypes()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const data: AttributeValue[] | GtError = await AttributeService.instance.getAttributeValues(EAttributeType.SkillType);

        if (isSuccess<AttributeValue[]>(data)) {
            const competenceTypeData = [];
            competenceTypeData.push(this.allTargetSkillType);
            data.map((item) => {
                competenceTypeData.push({ id: item.id, text: item.text, helperItem: undefined } as IGenericFilterItem);
            });
            this.setState({ targetSkillTypes: competenceTypeData })
        } else {
            const errorMessage = `${methodName} failed to load skill profile types from AttributeService. ${data.message}`;
            console.error(errorMessage);
        }
    }

    private async loadSkillsWithAccessRight() {
        const methodName = `${this.className}:loadSkillsWithAccessRight()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const allSkills: ItemSkillDisplay[] | GtError = await SkillService.instance.getSkillsWithAssignRight(Session.instance.getUserLanguageCodeOrFallBack, this.props.bossRelationCode)

        if (isSuccess<ItemSkillDisplay[]>(allSkills)) {
            this.setState({ allSkills: allSkills });
        } else {
            const errorMessage = `loadSkillsWithAccessRight: failed to load skills for removing from SkillService. ${allSkills.message}`;
            console.error(`${methodName}`, errorMessage);
            this.setState({ errorMessage: errorMessage });
        }
    }

    private handleTargetSkillFilterChanged(e: MultiSelectFilterChangeEvent): void {
        const methodName = `${this.className}:handleTargetSkillFilterChanged()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const targetSkillFilter = e.filter.value;
        if (targetSkillFilter !== '') {
            Logger.log(this.loggerLocality, `${methodName} filter is ${e.filter.value}`);
            this.setState({
                skillFilter: targetSkillFilter
            });
            if (targetSkillFilter !== (this.state.selectedSkills != null)) {
                this.setState({
                    selectedSkills: undefined
                });
            }
        } else {
            Logger.log(this.loggerLocality, `${methodName} filter is empty`);
            this.setState({
                selectedSkills: undefined,
                skillFilter: ''
            });
        }
        this.filterTargetSkills(this.state.selectedTargetSkillType != null ? this.state.selectedTargetSkillType.id : 0, targetSkillFilter)
    }

    private filterTargetSkills(selectedTargetSkillTypeID: number | null, skillTargetSkillFilter: string): void {
        const methodName = `${this.className}:filterSkillProfiles()`;
        Logger.log(this.loggerLocality, `${methodName} called with ${selectedTargetSkillTypeID} ${skillTargetSkillFilter}`);

        let targetSkillsFiltered = this.state.allRemovableSkills;
        if (targetSkillsFiltered === undefined) {
            return
        }

        // Filter out by selected profile type
        if (selectedTargetSkillTypeID != null && selectedTargetSkillTypeID !== 0) {
            Logger.log(this.loggerLocality, `${methodName} filter by id ${selectedTargetSkillTypeID}`);
            const targetSkillTypeFilter = {
                field: 'skillTypeId',
                ignoreCase: true,
                operator: 'eq',
                value: selectedTargetSkillTypeID,
            };
            targetSkillsFiltered = filterBy(targetSkillsFiltered, targetSkillTypeFilter);
        }

        // Filter out by entered text
        if (skillTargetSkillFilter != null && skillTargetSkillFilter !== '') {
            Logger.log(this.loggerLocality, `${methodName} filter by string ${skillTargetSkillFilter}`);
            const skillProfileInputTextFilter = {
                field: 'skillTitle',
                ignoreCase: true,
                operator: 'contains',
                value: skillTargetSkillFilter,
            };
            targetSkillsFiltered = filterBy(targetSkillsFiltered, skillProfileInputTextFilter);
        }

        this.setState({ filteredSkills: targetSkillsFiltered })
    }

    private handleTargetSkillSelectionChanged(e: MultiSelectChangeEvent): void {
        const methodName = `${this.className}:handleTargetSkillSelectionChanged()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const values = e.target.value
        const lastItem = values[values.length - 1]
        if (lastItem != null && lastItem.id == 0) {
            Logger.log(this.loggerLocality, `Got id == 0, add all`)
            this.setState({ selectedSkills: this.state.filteredSkills })
        }
        else if (e.target.value.length == 0) {
            Logger.log(this.loggerLocality, `No elements, empty the list`)
            this.setState({ selectedSkills: [] })
        }
        else {
            Logger.log(this.loggerLocality, `Add selected values to list ${e.target.value}`)
            this.setState({ selectedSkills: [...e.target.value] })
        }
    }

    private onChangeTargetSkillType(itm: IGenericFilterItem | undefined) {
        if (itm) {
            this.setState({ selectedTargetSkillType: itm, selectedSkills: undefined, skillFilter: '' });
            this.filterTargetSkills(itm.id, this.state.skillFilter);
        }
    }

    private closeModal(e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>): void {
        const methodName = `${this.className}:closeModal()`;
        Logger.log(this.loggerLocality, `${methodName} closing modal and resetting state`);
        this.setState({
            errorMessage: '',
        });
        if (this.props.onClose != null) {
            this.props.onClose(e);
        }
    }

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }
}