import React from 'react';
import { MenuBreadCrumb } from '../breadCrumb/MenuBreadCrumb';
import { PerformanceReview } from '../performanceReview/PerformanceReview';
import { Heading } from '../shared/Heading';
import { Translate } from '../shared/Translate';

export class MyPerformanceReview extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
    }

    public render() {
        return (
            <div className="">
                <div className="l-container">
                    <MenuBreadCrumb {...this.props} />
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title"><Translate>PerformanceReview:Title</Translate></Heading>
                    <div>
                        <Translate>PerformanceReview:SubTitle</Translate>
                    </div>
                </div>

                <PerformanceReview parentHeadingLevel={1} {...this.props} />
            </div>
        )
    }
}