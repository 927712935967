import IconPrint from '$resources/svgs/misc/print.svg'
import { GenericInput } from '$components/shared/GenericInput';
import { Translate } from '$src/components/shared/Translate';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import SkillService from '$src/core/Services/SkillService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { EUserSkillCommentType } from '$src/storage/models/enums';
import { UserSkillCommentUpdateRequest } from '$src/storage/models/RequestObjects/UserSkillCommentUpdateRequest';
import { UserSkillComment } from '$src/storage/models/Skill/UserSkillComment';
import DateHelper from '$src/util/DateHelper';
import { isSuccess } from '$src/util/Result';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import React, { RefObject } from 'react';
import InlineSVG from 'react-inlinesvg';
import { UserSkillCommentCreateRequest } from '$src/storage/models/RequestObjects/UserSkillCommentCreateRequest';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { EvaluationsToPrint } from '$src/components/performanceReview/EvaluationsToPrint';
import ReactToPrint from 'react-to-print';
import { PerformanceReviewCommentResponse } from '$src/storage/models/PerformanceReviewCommentResponse';
import { CommentUpdateRequest } from '$src/storage/models/Skill/CommentUpdateRequest';
import GTButton from '$src/components/shared/Atoms/GTButton';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';
interface IProps {
    evaluations: UserSkillComment[];
    previousEvaluations: UserSkillComment[];
    createEvaluationAllowed: boolean;
    headingLevel: number;
    userId: number;
    userSkillId: number;
}
interface IVersion {
    date: Date;
    dateAsString: string;
}
interface IEvealuationDateFiler {
    versions: IVersion[];
    selectedDateFilter: IVersion | null;
}
interface IState extends IEvealuationDateFiler {
    showSpinner: boolean;
    selectedEvaluation: UserSkillComment[];
    errorMessage: string;
    showSuccessMessage: boolean;
    shouldShowModalFinalizeConfirmation: boolean;
    shouldShowModalOverwriteComments: boolean;
    finalize: boolean;
}

/**
 * This component render the user skill comments (evaluation) for the related user skill 
 * in a table of 5 textarea fields for each type of EUserSkillCommentType.
 * One evaluation is a set of 5 user skill comments grouped by the version date.
 * It is used for the employee as well as for the boss, he can edit all fields as long as they are not finalized
 * the employee can only edit his fields (EUserSkillCommentType.MySkill_User, EUserSkillCommentType.MyPotential_User and EUserSkillCommentType.MyTargets)
 */
export class Evaluations extends React.Component<IProps, IState> {
    private userIsBoss = false;
    private evaluationToPrintRef: RefObject<EvaluationsToPrint>;
    constructor(props: IProps) {
        super(props);
        this.evaluationToPrintRef = React.createRef();
        this.state = {
            errorMessage: '',
            selectedDateFilter: null,
            selectedEvaluation: [],
            shouldShowModalFinalizeConfirmation: false,
            showSuccessMessage: false,
            showSpinner: false,
            versions: [],
            shouldShowModalOverwriteComments: false,
            finalize: false
        }
        this.userIsBoss = Session.instance.loginUser != null
            && Session.instance.loginUser.id !== this.props.userId;
    }

    public componentDidMount() {
        if (this.props.evaluations.length > 0) {
            const versions: IVersion[] = [];
            this.props.evaluations.map(ev => {
                if (versions.length === 0 || !versions.find(v => DateHelper.CompareDate(v.date, ev.versionDate) === 0)) {
                    versions.push({
                        date: ev.versionDate,
                        dateAsString: DateHelper.toDateString(ev.versionDate)
                    });
                }
            });
            const selectedEvaluation = this.props.evaluations.filter(ev => DateHelper.CompareDate(versions[0].date, ev.versionDate) === 0);
            this.setState({ versions, selectedDateFilter: versions[0], selectedEvaluation });
        }
    }

    public render() {
        if (this.state.showSpinner) {
            return <ProgressSpinner />
        }
        if (this.props.evaluations.length === 0) {
            return (
                <React.Fragment>
                    {this.renderCreateButton()}
                    <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceReview:NoCommentsFound')} />
                </React.Fragment>
            )
        } else {
            const isAllFinalized = this.props.evaluations.find(usk => !usk.isFinalized) ? false : true;
            const isCurrentFinalized = this.state.selectedEvaluation.find(usk => usk.isFinalized) ? true : false;
            return (
                <div>
                    <div className="l-box-container">
                        <div className="l-box--short">
                            <DropDownList
                                data={this.state.versions}
                                textField="dateAsString"
                                value={this.state.selectedDateFilter}
                                label={Session.instance.storage.translation.GetString('PerformanceReview:EvaluationOf')}
                                onChange={(e) => this.onChangeDateFilter(e)} />
                        </div>
                        <ReactToPrint
                            trigger={() => this.printButton()}
                            content={() => this.evaluationToPrintRef.current}
                            bodyClass="performanceReview-evaluation__printBody" />

                    </div>
                    <form className="l-box-container">
                        <div className="spread-buttons">
                            {!isCurrentFinalized && this.renderSaveButton()}
                            {isAllFinalized && this.renderCreateButton()}
                            {!isCurrentFinalized && this.renderFinalizeButton()}
                        </div>
                        {this.state.errorMessage !== '' &&
                            <ErrorMessage errorMessage={this.state.errorMessage} />
                        }
                        {this.state.showSuccessMessage &&
                            <span className={'input-message success'} >
                                <Translate>PerformanceReview:CommentSaveSuccessMessage</Translate>
                            </span>
                        }
                        {this.renderEvaluationTable()}
                        <div className="spread-buttons">
                            {!isCurrentFinalized && this.renderSaveButton()}
                            {isAllFinalized && this.renderCreateButton()}
                            {!isCurrentFinalized && this.renderFinalizeButton()}
                        </div>
                        {this.state.errorMessage !== '' &&
                            <ErrorMessage errorMessage={this.state.errorMessage} />
                        }
                        {this.state.showSuccessMessage &&
                            <span className={'input-message success'} >
                                <Translate>PerformanceReview:CommentSaveSuccessMessage</Translate>
                            </span>
                        }
                        {this.renderFinalizeConfirmation()}
                        {this.renderOverwriteComments()}
                    </form>
                    <div className="onlyForPrint">
                        <EvaluationsToPrint
                            selectedEvaluation={this.state.selectedEvaluation}
                            selectedVersion={this.state.selectedDateFilter != null ? this.state.selectedDateFilter.dateAsString : ''}
                            ref={this.evaluationToPrintRef} />
                    </div>
                </div>
            )
        }
    }

    private printButton(): JSX.Element {
        return (
            <GTIconButton
                tooltipText={Session.instance.storage.translation.GetString('PerformanceReview:EvaluationPrint')}
                ariaLabel={Session.instance.storage.translation.GetString('PerformanceReview:EvaluationPrint')}
                id="btnPrint"
                onClick={() => this.setState({ showSpinner: true })}>
                <InlineSVG src={IconPrint} />
            </GTIconButton>
        )
    }

    private renderEvaluationTable(): JSX.Element {
        return (
            <div role="table" className="div-table__horizontal-table">
                <div role="rowgroup" className="notMobile">
                    <div role="row" className="div-table__horizontal-table-row">
                        <div role="columnheader" >
                            <Translate>PerformanceReview:MySkill</Translate>
                        </div>
                        <div role="columnheader" className="screen-reader-only">
                            <Translate>PerformanceReview:MySkill</Translate>
                        </div>
                    </div>
                </div>
                <div role="rowgroup">
                    <div role="row" className="div-table__horizontal-table-row">
                        <div role="columnheader" className="heading__Level5 mobileOnly">
                            <Translate>PerformanceReview:MySkill</Translate>
                        </div>
                        <div role="cell">
                            {this.renderEvaluationText(EUserSkillCommentType.MySkill_User)}
                        </div>
                        <div role="columnheader" className="heading__Level5 mobileOnly">
                            <Translate>PerformanceReview:MySkill</Translate>
                        </div>
                        <div role="cell">
                            {this.renderEvaluationText(EUserSkillCommentType.MySkill_Boss)}
                        </div>
                    </div>
                </div>
                <div role="rowgroup" className="notMobile">
                    <div role="row" className="div-table__horizontal-table-row">
                        <div role="columnheader" >
                            <Translate>PerformanceReview:MyPotential</Translate>
                        </div>
                        <div role="columnheader" className="screen-reader-only">
                            <Translate>PerformanceReview:MyPotential</Translate>
                        </div>
                    </div>
                </div>
                <div role="rowgroup">
                    <div role="row" className="div-table__horizontal-table-row">
                        <div role="columnheader" className="heading__Level5 mobileOnly">
                            <Translate>PerformanceReview:MyPotential</Translate>
                        </div>
                        <div role="cell">
                            {this.renderEvaluationText(EUserSkillCommentType.MyPotential_User)}
                        </div>
                        <div role="columnheader" className="heading__Level5 mobileOnly">
                            <Translate>PerformanceReview:MyPotential</Translate>
                        </div>
                        <div role="cell">
                            {this.renderEvaluationText(EUserSkillCommentType.MyPotential_Boss)}
                        </div>
                    </div>
                </div>
                <div role="rowgroup" className="notMobile">
                    <div role="row" className="div-table__horizontal-table-row">
                        <div role="columnheader" >
                            <Translate>PerformanceReview:MyTargets</Translate>
                        </div>
                    </div>
                </div>
                <div role="rowgroup">
                    <div role="row" className="div-table__horizontal-table-row">
                        <div role="columnheader" className="heading__Level5 mobileOnly">
                            <Translate>PerformanceReview:MyTargets</Translate>
                        </div>
                        <div role="cell">
                            {this.renderEvaluationText(EUserSkillCommentType.MyTargets)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private renderEvaluationText(userSkillCommentType: EUserSkillCommentType): JSX.Element | undefined {
        let result: JSX.Element | undefined;
        const userSkillComment = this.state.selectedEvaluation.find(usk => usk.commentType === userSkillCommentType);
        if (userSkillComment) {
            const inputFieldState = this.getInputFieldState(userSkillComment)
            result = (
                <div className="l-box--shadow performanceReview-evaluation__box">
                    <GenericInput
                        type={'textarea'}
                        id={userSkillComment.userSkillCommentId.toString()}
                        label={userSkillComment.commentedUserFirstName + ' ' + userSkillComment.commentedUserLastName}
                        value={userSkillComment.comment != null ? userSkillComment.comment : ''}
                        isReadOnly={inputFieldState.isReadOnly}
                        isRequired={false}
                        onTextChange={(id, value, errorMsg) => this.onTextChange(id, value, errorMsg)}
                        hasFocus={inputFieldState.hasFocus}
                        editMode={true}
                        initialValidation={false}
                        class="performanceReview-evaluation__textarea"
                        fieldIndex={0}
                        rows={10} />
                </div>
            )
        }
        return result;
    }

    private getInputFieldState(userSkillComment: UserSkillComment): { hasFocus: boolean; isReadOnly: boolean } {
        let isReadOnly = true;
        let hasFocus = false;
        // if the user is the boss and the comment isn't finalized, he can edit all fields
        if (this.userIsBoss) {
            isReadOnly = userSkillComment.isFinalized;
            hasFocus = !userSkillComment.isFinalized && userSkillComment.commentType === EUserSkillCommentType.MySkill_User;
        }
        // if the user is the employee and the comment isn't finalized, he can edit his fields
        else if (userSkillComment.commentType === EUserSkillCommentType.MySkill_User
            || userSkillComment.commentTypeId === EUserSkillCommentType.MyPotential_User
            || userSkillComment.commentTypeId === EUserSkillCommentType.MyTargets) {
            isReadOnly = userSkillComment.isFinalized;
            hasFocus = !userSkillComment.isFinalized && userSkillComment.commentType === EUserSkillCommentType.MySkill_User;
        }
        return {
            hasFocus,
            isReadOnly
        }
    }

    private renderSaveButton(): JSX.Element {
        return (
            <GTButton
                onClick={() => this.onSave(false)}>
                <Translate>Button:Save</Translate>
            </GTButton>
        )
    }

    private renderFinalizeButton(): JSX.Element | null {
        if (this.userIsBoss) {
            return (
                <div className="performanceReview-evaluation__buttons--margin">
                    <GTButton
                        onClick={() => this.setState({ shouldShowModalFinalizeConfirmation: true, finalize: true })}>
                        <Translate>Button:SaveAndFinalize</Translate>
                    </GTButton>
                </div>

            )
        } else {
            return null;
        }
    }

    private renderCreateButton(): JSX.Element | null {
        if (this.props.createEvaluationAllowed && this.userIsBoss) {
            return (
                <GTButton
                    onClick={() => this.onCreate()}>
                    <Translate>Button:CreateNewEvaluation</Translate>
                </GTButton>
            )
        } else {
            return null;
        }
    }

    private renderFinalizeConfirmation(): JSX.Element {
        return (
            <ModalPopup
                isOpen={this.state.shouldShowModalFinalizeConfirmation}
                onRequestClose={() => this.setState({ shouldShowModalFinalizeConfirmation: false })}>
                <div>
                    <Translate>PerformanceReview:FinalizeHint</Translate>
                </div>
                <div className="modal__spread-buttons">
                    <GTButton id="btnSave" onClick={() => this.onSave(false)}><Translate>Button:Confirm</Translate></GTButton>
                    <GTButton id="btnCancel" onClick={() => this.setState({ shouldShowModalFinalizeConfirmation: false, finalize: false })}><Translate>Button:Cancel</Translate></GTButton>
                </div>
            </ModalPopup>
        )
    }

    private renderOverwriteComments(): JSX.Element {
        return (
            <ModalPopup
                isOpen={this.state.shouldShowModalOverwriteComments}
                onRequestClose={() => this.setState({ shouldShowModalOverwriteComments: false })}>
                <div>
                    <Translate>PerformanceReview:CommentSaveWarningMessage</Translate>
                </div>
                <div className="modal__spread-buttons">
                    <GTButton id="btnSave" onClick={() => this.onSave(true)}><Translate>Button:Confirm</Translate></GTButton>
                    <GTButton id="btnCancel" onClick={() => this.setState({ shouldShowModalOverwriteComments: false, finalize: false })}><Translate>Button:Cancel</Translate></GTButton>
                </div>
            </ModalPopup >
        )
    }

    private onTextChange(id: string, value: string, errorMsg: string) {
        const userSkillComment = this.state.selectedEvaluation.find(usk => usk.userSkillCommentId.toString() === id);
        if (userSkillComment) {
            userSkillComment.comment = value;
        }
        this.setState({ selectedEvaluation: this.state.selectedEvaluation, showSuccessMessage: false, errorMessage: errorMsg });
    }
    /**
     * Save the selected evaluation
     * @param finalize set the finalized flag
     */
    private async onSave(overwriteComments: boolean) {
        this.setState({ showSpinner: true });

        const tmpComments: CommentUpdateRequest[] = [];

        this.state.selectedEvaluation.map(uskC => {
            const uskCCopy = { ...uskC };
            if (this.state.finalize) {
                uskCCopy.isFinalized = true;
            }
            const tmpPreviousComment = this.props.previousEvaluations.find(nuskc => nuskc.userSkillCommentId == uskCCopy.userSkillCommentId);
            const tempComment: CommentUpdateRequest = {
                comment: uskCCopy.comment,
                isFinalized: uskCCopy.isFinalized,
                commentType: uskCCopy.commentType,
                userSkillCommentId: uskCCopy.userSkillCommentId,
                previousComment: tmpPreviousComment?.comment ? tmpPreviousComment.comment : "",
            }
            tmpComments.push(tempComment);
        })
        const userSkillCommentUpdateRequest: UserSkillCommentUpdateRequest = {
            userSkillId: this.props.userSkillId,
            overWriteComments: overwriteComments,
            userSkillComments: tmpComments
        }
        const response = await SkillService.instance.updateUserSkillComments(userSkillCommentUpdateRequest);
        if (isSuccess<PerformanceReviewCommentResponse>(response) && response.status) {
            if (!response.editWarning && !overwriteComments) {
                this.setState({ showSuccessMessage: true, showSpinner: false, shouldShowModalOverwriteComments: false });
                window.location.reload();
            } else if (!response.editWarning && overwriteComments) {
                this.setState({ showSuccessMessage: true, showSpinner: false, shouldShowModalOverwriteComments: false, shouldShowModalFinalizeConfirmation: false });
                window.location.reload();
            } else if (response.editWarning) {
                this.setState({ showSuccessMessage: false, showSpinner: false, shouldShowModalOverwriteComments: true });
            } else if (this.state.finalize) {
                window.location.reload();
            }
        } else {
            this.setState({ errorMessage: 'PerformanceReview:FailedToSaveEvaluation', showSpinner: false });
        }
    }

    private async onCreate() {
        this.setState({ showSpinner: true });
        const request: UserSkillCommentCreateRequest = {
            userSkillId: this.props.userSkillId
        }
        const response = await SkillService.instance.createUserSkillComments(request);
        if (isSuccess<BooleanResponse>(response) && response.status) {
            window.location.reload();
        } else {
            this.setState({ errorMessage: 'PerformanceReview:FailedToCreateEvaluation', showSpinner: false });
        }
    }

    private onChangeDateFilter(event: DropDownListChangeEvent) {
        const selectedDateFilter: IVersion = event.target.value;
        const selectedEvaluation = this.props.evaluations.filter(ev => DateHelper.CompareDate(ev.versionDate, selectedDateFilter.date) === 0);
        this.setState({
            errorMessage: '',
            selectedDateFilter,
            selectedEvaluation,
            showSuccessMessage: false
        });
    }
}