
import React, { useEffect, useState } from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { PaymentVoucherCampaignResponse, Voucher } from '$src/storage/models/Campaign/PaymentVoucherCampaignResponse';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import Session from '$src/core/Session';
import GTButton from '$src/components/shared/Atoms/GTButton';
import { PaymentVoucherCode } from '$src/storage/models/Payment/PaymentVoucherCode';
import InlineSVG from 'react-inlinesvg';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';
import Icondelete from '$resources/svgs/misc/delete.svg';
import PaymentCampaignService from '$src/core/Services/PaymentCampaignService';
import { isSuccess } from '$src/util/Result';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import Logger from '$src/core/Logger';
import IconVoucherUsed from '$src/resources/svgs/voucherCampaign/VoucherUsed.svg';
import IconVoucherDeleted from '$src/resources/svgs/voucherCampaign/VoucherDeleted.svg';
import { Tooltip } from '../shared/Tooltip';

const loggerLocality = 'Components.PaymentCampaignVoucherCodesPopup ';
interface IProps {
    campaign: PaymentVoucherCampaignResponse | null;
    exportHandler: () => void; // export handler from the PaymentCampaignArchive
    deletedVoucher: () => void; // for reload the data
}

/**
 * Modal showing a List/Table/Grid of all Vouchers and giving the User the option to export them to an Excel
 * @param props 
 * @returns 
 */
export default function PaymentCampaignVoucherCodesPopup(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [vouchers, setVouchers] = useState<Voucher[] | null>([]);

    const TRANSLATION_PREFIX = 'PaymentCampaignVouchers';
    const getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${TRANSLATION_PREFIX}:${str}`);
    }

    function deleteVoucher(voucherCode: number) {
        setIsLoaded(false);
        PaymentCampaignService.instance.deleteVoucherCode(voucherCode).then(response => {
            if (isSuccess<BooleanResponse>(response) && response.status) {
                props.deletedVoucher();
            } else {
                Logger.log(loggerLocality, `An error ocurred while trying to delete Voucher Code with id=${voucherCode}`);
            }
        })
        return true;
    }

    function getElementForVoucher(voucher: PaymentVoucherCode) {
        if (voucher.isRevoked) {
            return <div className='payment-campaign-archive__voucher-modal--icon'
                data-tip={getTranslation('RevokedVoucherCode')}
                data-for={`revokedVoucherCode${voucher.voucherCode}`}
            >
                <InlineSVG aria-label={getTranslation('RevokedVoucherCode')} src={IconVoucherDeleted} />
                <Tooltip id={`revokedVoucherCode${voucher.voucherCode}`} />
            </div>
        } else if (voucher.usedAt.length > 0 && voucher.usedBy.length > 0) {
            return <div className='payment-campaign-archive__voucher-modal--icon'
                data-tip={getTranslation('UsedVoucherCode')}
                data-for={`usedVoucherCode_${voucher.voucherCode}`}
            >
                <InlineSVG aria-label={getTranslation('UsedVoucherCode')} src={IconVoucherUsed} />
                <Tooltip id={`usedVoucherCode_${voucher.voucherCode}`} />
            </div>


        } else {
            return <div className='payment-campaign-archive__voucher-modal--icon'>
                <GTIconButton
                    id={`delete_${voucher.voucherCode}`}
                    ariaLabel={getTranslation('DeleteVoucherCode')}
                    tooltipText={getTranslation('DeleteVoucherCode')}
                    onClick={() => deleteVoucher(voucher.voucherCodeId)}>
                    <InlineSVG src={Icondelete} />
                </GTIconButton>
            </div>

        }
    }

    useEffect(() => {
        if (!isLoaded && props.campaign) {
            const list = Array<Voucher>();
            props.campaign.vouchers.map((value) => {
                const temp = {
                    pvcrId: props.campaign?.id,
                    voucherCode: value.voucherCode,
                    campaignName: props.campaign?.title,
                    expireTime: props.campaign?.expireTime,
                    discount: props.campaign?.discount,
                    usedAt: value.usedAt,
                    usedBy: value.usedBy,
                    voucherCodeId: value.voucherCodeId,
                    isRevoked: value.isRevoked
                } as Voucher;
                list.push(temp);
            });
            setVouchers(list);
            setIsLoaded(true);
        }
    }, []);


    return (
        isLoaded ?
            <>
                <Heading headingLevel={1} cssClass={'heading__Title '}>
                    <Translate>{`${TRANSLATION_PREFIX}:Heading`}</Translate>
                </Heading>
                <Grid
                    style={{ height: '400px'}}
                    data={vouchers}
                >
                    <GridToolbar>
                        <GTButton additionalClassNames="btn btn--sm" onClick={() => props.exportHandler()} >
                            {getTranslation('ExportToExcel')}
                        </GTButton>
                    </GridToolbar>
                    <Column field="voucherCode" title={getTranslation('VoucherCode')} />
                    <Column field="campaignName" title={getTranslation('CampaignName')} />
                    <Column field="expireTime" title={getTranslation('ExpiryTime')} />
                    <Column field="discount" title={getTranslation('Discount')} />
                    <Column field="actionVoucher" title={" "}  headerClassName='payment-campaign-archive__voucher-modal__action-column'
                        cell={(props: GridCellProps) => (
                            <>
                                <td className='payment-campaign-archive__voucher-modal__action-column'>
                                    {getElementForVoucher((props.dataItem as PaymentVoucherCode))}
                                </td>

                            </>
                        )}
                    />
                </Grid>
            </>
            : <ProgressSpinner />

    );
}