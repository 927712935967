import Logger from '$src/core/Logger';
import GamificationService from '$src/core/Services/GamificationService';
import { GamificationUserHighscore } from '$src/storage/models/Gamification/GamificationUserHighscore';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import GTHorizontalTableHeaderRow, { GTHeaderContent } from '$components/shared/Atoms/GTHorizontalTableHeaderRow';
import Session from '$src/core/Session';
import GTHorizontalTableRow, { GTRowContent } from '$components/shared/Atoms/GTHorizontalTableRow';
import GTHorizontalTable from '$components/shared/Molecules/GTHorizontalTable';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';


export const GamificationUserHighscoreRankingList: React.FC = () => {
    const [userHighscoreRankingList, setUserHighscoreRankingList] = useState<GamificationUserHighscore[]>();
    const [userHighscoreDataLoading, setUserHighscoreDataLoading] = useState<boolean>(false);
    const [userHighscoreDataLoaded, setUserHighscoreDataLoaded] = useState<boolean>(false);
    const [errorLoadingData, setErrorLoadingData] = useState<boolean>(false);

    const loggerLocality = 'GamificationUserHighscoreRankingList';

    useEffect(() => {
        async function fetchData() {
            setUserHighscoreDataLoading(true);
            await GamificationService.instance.getGamificationUserHighscore()
                .then((result) => {
                    if (isSuccess<GamificationUserHighscore[]>(result)) {
                        setUserHighscoreRankingList(result);
                        setUserHighscoreDataLoading(false);
                        setErrorLoadingData(false);
                    } else {
                        setErrorLoadingData(true);
                        Logger.log(loggerLocality, 'failed to get data');
                        console.error(`${loggerLocality} failed to get data`);
                    }
                    setUserHighscoreDataLoaded(true);
                })
        }
        fetchData();
    }, []);

    const headerElements: GTHeaderContent[] = [];
    headerElements.push({
        cellId: 'rank',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Rank')
    });

    headerElements.push({
        cellId: 'username',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Name')
    });

    headerElements.push({
        cellId: 'reachedLevel',
        cellElement: Session.instance.storage.translation.GetString('Gamification:ReachedLevel')
    });

    headerElements.push({
        cellId: 'points',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Points')
    });

    const headerRow = <GTHorizontalTableHeaderRow
        headerElements={headerElements}
        tableRowClassName="screen-reader-only" />

    const rows = () => {
        const tableRows: JSX.Element[] = [];
        userHighscoreRankingList!.map((usRank, index) => {
            const rowContent: GTRowContent[] = [];
            const isMyRank = usRank.userId == Session.instance.loginUser?.id;
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Rank'),
                cell: <>{usRank.rank}</>,
                cellId: `rank_${index}`,
                cellClassName: `gamification-highscore__rank-cell`
            })
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Name'),
                cell: <>{usRank.userFirstAndLastname}</>,
                cellId: `name_${index}`,
                cellClassName: `gamification-highscore__name-cell`
            })
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:ReachedLevel'),
                cell: <>{usRank.reachedLevelName}</>,
                cellId: `reachedLevel_${index}`,
                cellClassName: `gamification-highscore__reachedLevel-cell`
            })
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Points'),
                cell: <>{usRank.points.toLocaleString(Session.instance.getUserLanguageCodeOrFallBack)}</>,
                cellId: `points_${index}`,
                cellClassName: `gamification-highscore__points-cell`
            })
    
    
            tableRows.push(<GTHorizontalTableRow
                key={`userRank_${index}`}
                rowContent={rowContent}
                tableRowClassName={`div-table__horizontal-table-row zebra ${isMyRank ? `gamification-highscore__my-rank` : ''}` } />);
        });
        return tableRows;
    }


    return (
        <div className='gamification-highscore__ranking-list'>
            {userHighscoreDataLoaded && <GTHorizontalTable
                headerElement={headerRow}
                rows={rows()} />}
            {userHighscoreDataLoading && !userHighscoreDataLoaded && <ProgressSpinner />}
            {errorLoadingData && <ErrorMessage appearance='box' errorMessage='Common:ErrorLoadingData' />}
        </div>
    )
}