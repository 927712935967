import React from 'react';

import { CatalogBreadCrumb } from '$components/breadCrumb//CatalogBreadCrumb';
import { MenuBreadCrumb } from '$components/breadCrumb//MenuBreadCrumb';
import { BreadCrumb } from '$components/breadCrumb/BreadCrumb';
import Session from '$core/Session';
import UserService from '$src/core/Services/UserService';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import { User } from '$src/storage/models/User';
import { ItemHelper } from '$src/util/ItemHelper';
import { BreadCrumbElement } from '$storage/models/BreadCrumbElement';
import { EItemDetailCallerContextType, EItemDetailCallerType, EItemSubType, EItemType } from '$storage/models/enums';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { isSuccess } from '$util/Result';
import { AssignedItem } from '$src/storage/models/AssignedItem';
import { Skill } from '$src/storage/models/Skill/Skill';

interface IProps {
    rootElement?: BreadCrumbElement;
    splitterElement?: JSX.Element;
    itemDetailCallerContextType: EItemDetailCallerContextType; // The type of the caller
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number | null;
    itemDetailType: EItemDetailCallerType;
    itemSubType?: EItemSubType;
    itemTitle: string;
    itemId?: number;
    registraionId?: number;
    classId?: number;
    classTitle?: string;
    isTPLevel?: boolean; // If it is at the same level as the tp (tp registration)
    trainingPlanId?: number; // If it is at the same level as the tp (tp registration)
    skillId?: number; // Used for PerformanceReview
    profileId?: number; // Used for PerformanceReview
    userId?: number; // Used for PerformanceReview
    bossRelationCode?: string; // Used for PerformanceReview
    voucherCode?: string | null | undefined;
}

interface IState {
    trainingPlan: TrainingPlan | null;
    targetSkill?: UserSkillProfileSkill; // Used for PerformanceReview
    skill?: Skill; // Used for PerfomanceSupport
    username: string; // Used for PerformanceReview
    isItemRequired?: boolean;
}

export class ItemBreadCrumb extends React.Component<IProps, IState> {
    // Id for the "not associated" fake skill profile used for target skills
    protected notAssociatedSkillProfile: number = 9999;
    protected className = 'ItemBreadCrumb';
    protected loggerLocality = 'Components.ItemBreadCrumb';

    constructor(props: IProps) {
        super(props);

        this.state = {
            trainingPlan: null,
            username: ''
        }
    }

    public async componentDidMount() {
        // Load Training Plan if the sub context has an id (tp id)
        if (this.props.parentTrainingPlanId != null && this.props.parentTrainingPlanId > 0 && this.state.trainingPlan == null) {
            // Called from Training Plan
            await this.getTPlanDetails(this.props.parentTrainingPlanId);
        }
        if (this.props.trainingPlanId != null && this.props.trainingPlanId > 0 && this.state.trainingPlan == null) {
            // Called from Training Plan (same level)
            await this.getTPlanDetails(this.props.trainingPlanId);
        }
        if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.PerformanceSupport) {
            await this.getPerformanceSupportDetails()
        }
        // Load skillId and username for performance review
        if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.PerformanceReview) {
            await this.getPerformanceReviewDetails()
        }
        if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.MySkills) {
            await this.getMySkillsDetails()
        }
        if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.MyPerformanceSupport) {
            await this.getMyPerformanceSupportDetails()
        }
        if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.Assignment) {
            await this.GetAssignments()
        }
    }

    public async componentDidUpdate() {
        // If Training Plan IDs changed after the componend did mount

        // Load Training Plan if the sub context has an id (tp id)
        if (this.props.parentTrainingPlanId != null && this.props.parentTrainingPlanId > 0 && this.state.trainingPlan == null) {
            // Called from Training Plan
            await this.getTPlanDetails(this.props.parentTrainingPlanId);
        }
        if (this.props.trainingPlanId != null && this.props.trainingPlanId > 0 && this.state.trainingPlan == null) {
            // Called from Training Plan (same level)
            await this.getTPlanDetails(this.props.trainingPlanId);
        }
    }

    public render() {
        const itemStyle = globalConfig.breadCrumbProperties.itemStyle;
        let breadCrumb: JSX.Element | null = null;

        // Create Navigation for Item with TP as Parent
        const parentTpNavigationPath = ItemHelper.getTrainingPlanDetailLink(this.props.parentTrainingPlanId,
            this.props.itemDetailCallerContextType,
            this.props.parentCatalogFolderId,
            this.props.parentAssignmentId,
            this.props.itemId,
            this.props.voucherCode);

        // Create Item Navigation
        let itemNavigationPath: string = '';

        // Item type to know if item is a training plan or not
        const itemType = this.props.trainingPlanId != null && this.props.trainingPlanId > 0 ? EItemType.TrainingPlan : EItemType.Undefined;

        // External Course
        if (this.props.itemDetailType === EItemDetailCallerType.ExternalCourse && this.props.registraionId) {
            if (this.props.bossRelationCode) {
                itemNavigationPath = `${this.props.registraionId}?context=${this.props.itemDetailCallerContextType}`
                    + `&itemSubType=${this.props.itemSubType}&bossRelationCode=${this.props.bossRelationCode}`
            }
            else {
                itemNavigationPath = ItemHelper.getRequestWizardDetailLink(this.props.registraionId,
                    this.props.itemDetailCallerContextType)
            }

        }
        // Training Plan
        else if (this.props.itemDetailType === EItemDetailCallerType.TPlan && this.props.itemId) {
            itemNavigationPath = ItemHelper.getTrainingPlanDetailLink(this.props.itemId,
                this.props.itemDetailCallerContextType,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId,
                undefined,
                this.props.voucherCode)
        }
        else if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.PerformanceReview) {
            itemNavigationPath = ItemHelper.getPerformanceSupportItemDetailLink(this.props.itemId,
                this.props.itemDetailCallerContextType,
                itemType,
                this.props.skillId,
                this.props.userId,
                this.props.profileId,
                this.props.bossRelationCode)
        }
        else if (this.props.itemDetailCallerContextType === EItemDetailCallerContextType.PerformanceSupport) {
            itemNavigationPath = ItemHelper.getPerformanceReviewItemDetailLink(this.props.itemId,
                this.props.itemDetailCallerContextType,
                itemType,
                this.props.skillId,
                this.props.userId,
                this.props.profileId,
                this.props.bossRelationCode)
        }
        else if (this.props.itemDetailCallerContextType == EItemDetailCallerContextType.MySkills) {
            itemNavigationPath = ItemHelper.getMySkillsDetailLink(this.props.itemId,
                this.props.itemDetailCallerContextType,
                itemType,
                this.props.skillId,
                this.props.userId)
        }
        else if (this.props.itemDetailCallerContextType == EItemDetailCallerContextType.MyPerformanceSupport) {
            itemNavigationPath = ItemHelper.getMyPerformanceSupportLink(this.props.itemId,
                this.props.itemDetailCallerContextType,
                itemType,
                this.props.skillId,
                this.props.userId)
        }
        // All other Items
        else if (this.props.itemId) {
            itemNavigationPath = ItemHelper.getItemDetailLink(this.props.itemId,
                this.props.itemDetailCallerContextType,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId,
                this.props.parentTrainingPlanId,
                0,
                this.props.voucherCode)
        }

        let appendBcElements: [{ navigationPath: string; style: React.CSSProperties | undefined; title: string }];

        // Add Item Navigation Path
        if (this.props.isTPLevel && this.props.trainingPlanId != null && this.props.trainingPlanId > 0) {
            // Add TP as Item Navigation Path if isTPLevel (e.g. TP Registration)
            const tpNavigationPath = ItemHelper.getTrainingPlanDetailLink(this.props.trainingPlanId,
                this.props.itemDetailCallerContextType,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId,
                undefined,
                this.props.voucherCode);
            appendBcElements = [{
                navigationPath: tpNavigationPath,
                style: itemStyle,
                title: this.state.trainingPlan != null ? this.state.trainingPlan.title : '...'
            }];
        }
        else {
            // Add ItemDetail Item Navigation Path if not is TP Level
            appendBcElements = [{
                navigationPath: itemNavigationPath,
                style: itemStyle,
                title: this.props.itemTitle
            }];

            if (this.props.parentTrainingPlanId != null && this.props.parentTrainingPlanId > 0) {
                // Add TP Bread Crumb Element if sub context has an id (tp id)
                appendBcElements.unshift({
                    navigationPath: parentTpNavigationPath,
                    style: itemStyle,
                    title: this.state.trainingPlan != null ? this.state.trainingPlan.title : '...'
                })
            }
        }

        // Get Item Registration Path if needed
        if (this.props.classId != null && this.props.classId > 0) {
            let itemRegistrationNavigationPath: string = '';
            if (this.props.parentTrainingPlanId != null && this.props.parentTrainingPlanId > 0) {
                // Get TrainingPlanRegistration link
                itemRegistrationNavigationPath = ItemHelper.getTrainingPlanScheduleRegistrationLink(this.props.parentTrainingPlanId,
                    this.props.classId,
                    this.props.itemDetailCallerContextType,
                    this.props.parentCatalogFolderId,
                    this.props.parentAssignmentId,
                    this.props.voucherCode);
            }
            else {
                // Get F2FRegistration Link
                itemRegistrationNavigationPath = ItemHelper.getF2fRegistrationLink(this.props.itemId || 0,
                    this.props.classId,
                    this.props.itemDetailCallerContextType,
                    this.props.parentCatalogFolderId,
                    this.props.parentAssignmentId,
                    this.props.parentTrainingPlanId,
                    this.props.voucherCode);
            }
            appendBcElements.push({
                navigationPath: itemRegistrationNavigationPath,
                style: itemStyle,
                title: this.props.classTitle || '',
            })
        }

        const customBreadCrumbElements: BreadCrumbElement[] = [];

        switch (this.props.itemDetailCallerContextType) {
            case EItemDetailCallerContextType.Catalog:
                breadCrumb = <CatalogBreadCrumb
                    breadCrumbElementsToAppend={appendBcElements}
                    folderId={this.props.parentCatalogFolderId}
                    itemStyle={itemStyle} />
                break;
            case EItemDetailCallerContextType.Assignment:
                // eslint-disable-next-line no-case-declarations
                const navigationPath = this.state.isItemRequired !== null && this.state.isItemRequired !== undefined ?
                    (this.state.isItemRequired ? '/assignments' : '/recommendedTrainings') : '/assignments';

                customBreadCrumbElements.push({
                    navigationPath: navigationPath,
                    style: itemStyle,
                    title: navigationPath === '/assignments' ? 'Assignments:Title' : 'RecommendedTrainings:Title',
                    translateTitle: true
                });
                appendBcElements.map(bc => customBreadCrumbElements.push(bc));
                breadCrumb = <BreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    breadCrumbElements={customBreadCrumbElements} />
                break;
            case EItemDetailCallerContextType.MyFavorites:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/favorites"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.MyLesson:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myLessons"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.MyRecommendationsByInterests:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myrecommendations"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.MyWatchlist:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/watcheditems"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.RecommendedItems:
                customBreadCrumbElements.push({
                    navigationPath: '/recommendedTrainings',
                    style: itemStyle,
                    title: 'RecommendedTrainings:Title',
                    translateTitle: true,
                });
                appendBcElements.map(bc => customBreadCrumbElements.push(bc));
                breadCrumb = <BreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    breadCrumbElements={customBreadCrumbElements} />
                break;
            case EItemDetailCallerContextType.RegisteredItem:
                customBreadCrumbElements.push({
                    navigationPath: '/mytrainings',
                    style: itemStyle,
                    title: 'MyTrainings:Title',
                    translateTitle: true
                });
                appendBcElements.map(bc => customBreadCrumbElements.push(bc));
                breadCrumb = <BreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    breadCrumbElements={customBreadCrumbElements} />
                break;
            case EItemDetailCallerContextType.Search:
                customBreadCrumbElements.push({
                    navigationPath: `/search`,
                    style: itemStyle,
                    title: 'Search:Title',
                    translateTitle: true
                });
                appendBcElements.map(bc => customBreadCrumbElements.push(bc));
                breadCrumb = <BreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    breadCrumbElements={customBreadCrumbElements} />
                break;
            case EItemDetailCallerContextType.MyCourses:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myCourses"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.CourseRequests:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/CourseRequests"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.ExternalCourseCostReport:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/reporting/reportExternalCourseRegistration"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.MyFileSharing:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myFileSharing"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.Recommendations:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/catalog/1"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.PerformanceSupport:
                breadCrumb = this.renderPerformanceSupportBreadCrumb(customBreadCrumbElements, appendBcElements, itemStyle);
                break;
            case EItemDetailCallerContextType.PerformanceReview:
                breadCrumb = this.renderPerformanceReviewBreadCrumb(customBreadCrumbElements, appendBcElements, itemStyle);
                break;
            case EItemDetailCallerContextType.MySkills:
                breadCrumb = this.renderMySkillsBreadCrumb(customBreadCrumbElements, appendBcElements, itemStyle);
                break;
            case EItemDetailCallerContextType.MyPerformanceSupport:
                breadCrumb = this.renderMyPerformanceSupportBreadCrumb(customBreadCrumbElements, appendBcElements, itemStyle);
                break;
            case EItemDetailCallerContextType.TutoringReport:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/reporting/reportTutoring"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.TrainerBookingView:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/Trainer/trainerBookingView"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.MySubjectSubscriptionsEditView:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/EditMySubjectSubscriptions"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.PowerBiReports:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/PowerBiReports"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.Privacy:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myPrivacy"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.BookingOverview:
                customBreadCrumbElements.push({
                    navigationPath: `/bookingOverview/${this.props.bossRelationCode}`,
                    style: itemStyle, title: 'Navigation:BookingOverview',
                    translateTitle: true
                });
                appendBcElements.map(bc => customBreadCrumbElements.push(bc));
                breadCrumb = <MenuBreadCrumb
                    key="MenuBreadCrumb"
                    routePathName={`/myTeam/${this.props.bossRelationCode}`}
                    splitterElement={this.props.splitterElement}
                    breadCrumbElementsToAppend={customBreadCrumbElements}
                    {...this.props} />
                break;
            case EItemDetailCallerContextType.RequirementsOverview:
                customBreadCrumbElements.push({
                    navigationPath: `/requirementsOverview/${this.props.bossRelationCode}`,
                    style: itemStyle, title: 'Navigation:RequirementsOverview',
                    translateTitle: true
                });
                appendBcElements.map(bc => customBreadCrumbElements.push(bc));
                breadCrumb = <MenuBreadCrumb
                    key="MenuBreadCrumb"
                    routePathName={`/myTeam/${this.props.bossRelationCode}`}
                    splitterElement={this.props.splitterElement}
                    breadCrumbElementsToAppend={customBreadCrumbElements}
                    {...this.props} />
                break;
            case EItemDetailCallerContextType.VoucherValidation:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/voucherValidation"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
            case EItemDetailCallerContextType.PaymentCampaignOverview:
                breadCrumb = <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/paymentCampaignOverview"
                    breadCrumbElementsToAppend={appendBcElements} {...this.props} />
                break;
        }
        return breadCrumb;
    }


    private async GetAssignments() {
        await Session.instance.storage.assignment.getObjects();
        const response: AssignedItem[] | null = Session.instance.storage.assignment.getObjectsDistinct();
        if (isSuccess<AssignedItem[] | null>(response)) {
            if (response !== null && response !== undefined) {
                this.setState({ isItemRequired: response.filter((assignment) => assignment.itemId === this.props.itemId)[0].isRequired });
            }
        }
    }

    protected renderPerformanceReviewBreadCrumb(customBreadCrumbElements: BreadCrumbElement[],
        appendBcElements: BreadCrumbElement[], itemStyle?: React.CSSProperties): JSX.Element {
        if (this.state.targetSkill) {
            let navigationPath = `/performanceReviewDetail/${this.props.skillId}?profileId=${this.props.profileId}`;
            if (this.props.userId && this.props.bossRelationCode) {
                navigationPath += `&userId=${this.props.userId}&bossRelationCode=${this.props.bossRelationCode}`;
            }
            customBreadCrumbElements.push({
                navigationPath,
                style: itemStyle,
                title: this.state.targetSkill.skillTitle
            });
        }
        if (this.props.bossRelationCode) {
            customBreadCrumbElements.unshift({
                navigationPath: `/employee/${this.props.bossRelationCode}/${this.props.userId}?focus=PerformanceReview`,
                style: itemStyle,
                title: this.state.username
            });
            appendBcElements.map(bc => customBreadCrumbElements.push(bc));
            return (
                <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName={`/myTeam/${this.props.bossRelationCode}`}
                    breadCrumbElementsToAppend={customBreadCrumbElements}
                    {...this.props} />
            )
        } else {
            appendBcElements.map(bc => customBreadCrumbElements.push(bc));
            return (
                <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myPerformanceReview"
                    breadCrumbElementsToAppend={customBreadCrumbElements}
                    {...this.props} />
            )
        }
    }

    protected renderPerformanceSupportBreadCrumb(customBreadCrumbElements: BreadCrumbElement[],
        appendBcElements: BreadCrumbElement[], itemStyle?: React.CSSProperties): JSX.Element {

        if (this.state.skill) {
            let navigationPath = `/trainings/${this.props.skillId}`;
            if (this.props.userId) {
                navigationPath += `/${this.props.userId}`;
            } else {
                navigationPath += `/${Session.instance.loginUser ? Session.instance.loginUser.id : 0}`
            }
            navigationPath += `/0/0/0/0/0?context=${EItemDetailCallerContextType.PerformanceSupport}${this.props.bossRelationCode != null ? '&bossRelationCode=' + this.props.bossRelationCode : ''}`;

            if (this.props.profileId) {
                navigationPath += `&skillProfileId${this.props.profileId}`;
            }

            customBreadCrumbElements.push({
                navigationPath,
                style: itemStyle,
                title: this.state.skill.skillTitle
            });
        }
        if (this.props.bossRelationCode) {
            customBreadCrumbElements.unshift({
                navigationPath: `/employee/${this.props.bossRelationCode}/${this.props.userId}`,
                style: itemStyle,
                title: this.state.username
            });
            appendBcElements.map(bc => customBreadCrumbElements.push(bc));
            return (
                <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName={`/myTeam/${this.props.bossRelationCode}`}
                    breadCrumbElementsToAppend={customBreadCrumbElements}
                    {...this.props} />
            )
        } else {
            appendBcElements.map(bc => customBreadCrumbElements.push(bc));
            return (
                <MenuBreadCrumb
                    splitterElement={this.props.splitterElement}
                    rootElement={this.props.rootElement}
                    routePathName="/myPerformanceReview"
                    breadCrumbElementsToAppend={customBreadCrumbElements}
                    {...this.props} />
            )
        }
    }

    protected renderMySkillsBreadCrumb(customBreadCrumbElements: BreadCrumbElement[],
        appendBcElements: BreadCrumbElement[], itemStyle?: React.CSSProperties): JSX.Element {

        if (this.state.skill) {
            let navigationPath = `/trainings/${this.props.skillId}`;
            if (this.props.userId) {
                navigationPath += `/${this.props.userId}`;
            } else {
                navigationPath += `/${Session.instance.loginUser ? Session.instance.loginUser.id : 0}`
            }
            navigationPath +=  "/0/0/0/0/0/";
            navigationPath += `?context=${EItemDetailCallerContextType.MySkills}`;

            customBreadCrumbElements.push({
                navigationPath,
                style: itemStyle,
                title: this.state.skill.skillTitle
            });
        }

        appendBcElements.map(bc => customBreadCrumbElements.push(bc));
        return (
            <MenuBreadCrumb
                splitterElement={this.props.splitterElement}
                rootElement={this.props.rootElement}
                routePathName="/mySkills"
                breadCrumbElementsToAppend={customBreadCrumbElements}
                {...this.props} />
        )
    }

    protected renderMyPerformanceSupportBreadCrumb(customBreadCrumbElements: BreadCrumbElement[],
        appendBcElements: BreadCrumbElement[], itemStyle?: React.CSSProperties): JSX.Element {

        if (this.state.skill) {
            let navigationPath = `/trainings/${this.props.skillId}`;
            if (this.props.userId) {
                navigationPath += `/${this.props.userId}`;
            } else {
                navigationPath += `/${Session.instance.loginUser ? Session.instance.loginUser.id : 0}`
            }
            navigationPath +=  "/0/0/0/0/0/";
            navigationPath += `?context=${EItemDetailCallerContextType.MyPerformanceSupport}`;

            if (this.props.profileId) {
                navigationPath += `&skillProfileId${this.props.profileId}`;
            }

            customBreadCrumbElements.push({
                navigationPath,
                style: itemStyle,
                title: this.state.skill.skillTitle
            });
        }

        appendBcElements.map(bc => customBreadCrumbElements.push(bc));
        return (
            <MenuBreadCrumb
                splitterElement={this.props.splitterElement}
                rootElement={this.props.rootElement}
                routePathName="/myPerformanceSupport"
                breadCrumbElementsToAppend={customBreadCrumbElements}
                {...this.props} />
        )
    }

    protected async getTPlanDetails(itemId: number): Promise<void> {
        const response: TrainingPlan | null = await
            Session.instance.storage.trainingPlans.getTrainingPlan(itemId, this.props.itemDetailCallerContextType, false);
        if (isSuccess<TrainingPlan | null>(response)) {
            this.setState({ trainingPlan: response });
        }
    }

    private async getPerformanceReviewDetails(): Promise<void> {
        let targetSkill: UserSkillProfileSkill | undefined;
        if (this.props.skillId && this.props.profileId) {
            if (this.props.profileId === this.notAssociatedSkillProfile) {
                targetSkill = await Session.instance.storage.userTargetSkill.getTargetSkill(this.props.skillId, this.props.userId, this.props.bossRelationCode);
            } else {
                targetSkill = await Session.instance.storage.userSkillProfile.getProfileSkill(this.props.skillId,
                    this.props.profileId,
                    this.props.userId,
                    this.props.bossRelationCode);
            }
        }

        let username: string = '';
        if (this.props.bossRelationCode && this.props.userId) {
            const user = await UserService.instance.getUser(this.props.userId);

            if (isSuccess<User>(user)) {
                username = user.fullName;
            }
        }
        this.setState({ targetSkill, username });
    }

    private async getPerformanceSupportDetails(): Promise<void> {
        let skill: Skill | undefined;
        if (this.props.skillId) {
            const response = await Session.instance.storage.skill.getSkill(this.props.skillId);
            skill = response != null ? response : undefined;
        }

        let username: string = '';
        if (this.props.bossRelationCode && this.props.userId) {
            const user = await UserService.instance.getUser(this.props.userId);

            if (isSuccess<User>(user)) {
                username = user.fullName;
            }
        }
        this.setState({ skill, username });
    }

    private async getMySkillsDetails(): Promise<void> {
        let skill: Skill | undefined;
        if (this.props.skillId) {
            const response = await Session.instance.storage.skill.getSkill(this.props.skillId);
            skill = response != null ? response : undefined;
        }

        this.setState({ skill });
    }

    private async getMyPerformanceSupportDetails(): Promise<void> {
        let skill: Skill | undefined;
        if (this.props.skillId) {
            const response = await Session.instance.storage.skill.getSkill(this.props.skillId);
            skill = response != null ? response : undefined;
        }

        this.setState({ skill });
    }
}