import { PasswordReset } from '$components/login/PasswordReset';
import { Heading } from '$components/shared/Heading';
import { InputAttributes, ISetFocus } from '$components/shared/InputAttributes';
import { InputPassword } from '$components/shared/InputPassword';
import { InputText } from '$components/shared/InputText';
import { ModalPopup } from '$components/shared/ModalPopup';
import { OpenDialogLink } from '$components/shared/OpenDialogLink';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Logger from '$src/core/Logger';
import LicenseRegistrationService from '$src/core/Services/LicenseRegistrationService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';


interface IURLParamState {
    contractNumber: string;
}

interface IState extends IURLParamState {
    username: string;
    password: string;
    focusTo: '' | 'contractNumber' | 'username' | 'password';  // Id of the TextInput getting the input focus on next render.
    errorMessage: string;
    isLoggingIn: boolean;
    isLoginOk: boolean;
    isPasswordResetPopupVisible: boolean;
    passwordResetPopupTitle: string;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class LicenseRegLogin extends React.Component<RouteComponentProps, IState> {
    protected ref: ISetFocus;
    protected className = 'LicenseRegLogin';
    protected loggerLocality = 'Components.LicenseRegLogin';

    private readonly localStorageKeyForSavedUsername = 'savedUsername';

    constructor(props: RouteComponentProps) {
        super(props);

        const savedUsername = localStorage.getItem(this.localStorageKeyForSavedUsername);
        // Get URL Parameters
        const partialStateUrlParamState = this.getURLParameters();

        this.state = {
            ...partialStateUrlParamState,
            errorMessage: '',
            focusTo: partialStateUrlParamState.contractNumber !== '' ? 'username' : 'contractNumber',
            isLoggingIn: false,
            isLoginOk: false,
            isPasswordResetPopupVisible: false,
            password: '',
            passwordResetPopupTitle: 'Login:PWResetTitle',
            username: savedUsername ? savedUsername : '',
        }

        this.closePasswordResetPopup = this.closePasswordResetPopup.bind(this);
        this.openPasswordResetPopup = this.openPasswordResetPopup.bind(this);
        this.onSelfRegisterClick = this.onSelfRegisterClick.bind(this);
        this.onPasswordReset = this.onPasswordReset.bind(this);
        this.areInputsFieldsNotEmpty = this.areInputsFieldsNotEmpty.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('LicenseReg:ActivateLicense');
    }

    public render() {
        if (this.state.isLoginOk) {
            return <Redirect push={true} to={globalConfig.licenseReg.returnUrl} />
        } else {
            return (
                <div className="login__background">
                    <div className="l-container">
                        {this.renderLicenseRegLoginForm()}
                    </div>
                </div>
            )
        }

    }

    private getURLParameters(): IURLParamState {
        const parameters = new URLSearchParams(window.location.search);
        const urlContractNumber = parameters.get('contractNumber');

        return {
            contractNumber: urlContractNumber != null ? urlContractNumber : ''
        }
    }

    private renderLicenseRegLoginForm(): JSX.Element {
        const contractNumberAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'contractNumber',
            label: 'LicenseReg:ContractNumber',
            value: this.state.contractNumber,
            class: 'box__login',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'contractNumber',
            placeholder: '',
            editMode: true,
            autoComplete: 'contractNumber'
        };

        const userAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'username',
            label: 'LicenseReg:Username',
            value: this.state.username,
            class: 'box__login',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'username',
            placeholder: '',
            editMode: true,
            autoComplete: 'username'
        };

        const pwdAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'password',
            label: 'LicenseReg:Password',
            value: this.state.password,
            class: 'box__login',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'password',
            placeholder: '',
            editMode: true,
            setFocus: this.ref,
            autoComplete: 'current-password'
        };
        if (this.state.isLoggingIn) {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">
                        <div className="login__spinner">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">
                        <div className="box__login login__AcitvateLicense">
                            <Heading headingLevel={1} cssClass="heading__Level4">
                                <Translate>LicenseReg:LicenseRegTitle</Translate>
                            </Heading>
                            <div className="text">
                                <Translate>LicenseReg:LicenseRegHint</Translate>
                            </div>
                        </div>
                        <br />
                        <form className="box__login">
                            <InputText
                                attributes={contractNumberAttributes}
                                onChange={(id, text) => this.onTextChange(id, text)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                            <InputText
                                attributes={userAttributes}
                                onChange={(id, text) => this.onTextChange(id, text)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                            <InputPassword
                                attributes={pwdAttributes}
                                onChange={(id, text) => this.onTextChange(id, text)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                            <span className={'input-message error'}>
                                <Translate>{this.state.errorMessage}</Translate>
                            </span>
                        </form>
                        <div className="box__login login__NotRegisteredUsers">
                            {this.renderOptionsForNotRegisteredUsers()}
                        </div>
                        <div className="box__login login__StaySignedIn">
                            {this.renderOptionResetPassword()}
                        </div>
                        <div className="box__login login__LoginPanel">
                            <button
                                id="butLogin"
                                type="button"
                                className="btn--md"
                                onClick={() => this.login()}
                                disabled={!this.areInputsFieldsNotEmpty()} >
                                <Translate>Login:Login</Translate>
                            </button>
                        </div>
                    </div>
                </div>
                <ModalPopup isOpen={this.state.isPasswordResetPopupVisible}
                    onRequestClose={this.closePasswordResetPopup}>
                    <PasswordReset onResetClick={this.onPasswordReset} />
                </ModalPopup>
            </React.Fragment>
        }
    }

    private renderOptionResetPassword(): JSX.Element | null {
        if (globalConfig.licenseReg.isResetPasswordEnabled) {
            return (
                <React.Fragment>
                    <OpenDialogLink ariaPopupvalue={"dialog"} id="lblPasswordForgotten" title="Login:PWResetTitle" openDialog={this.openPasswordResetPopup} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    private renderOptionsForNotRegisteredUsers(): JSX.Element | null {
        const selfReg = globalConfig.licenseReg.isSelfRegistrationEnabled;
        if (selfReg) {
            return (
                <React.Fragment>
                    <Translate>Login:NotRegistered</Translate>
                    <br />
                    <button
                        className="button-link button-link--colorized"
                        onClick={this.onSelfRegisterClick}>
                        <Translate>LicenseReg:SelfRegister</Translate>
                    </button>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    /**
     * Callback for TextInput component: text has changed.
     * @param id Id of the TextInput triggering this callback.
     * @param text Current text from TextInput.
     */
    private onTextChange(id: string, text: string) {
        switch (id) {
            case 'contractNumber':
                this.setState({ contractNumber: text });
                break;
            case 'username':
                this.setState({ username: text });
                break;
            case 'password':
                this.setState({ password: text });
                break;
        }
        this.setState({ focusTo: '' });
    }

    private handleClick = () => {
        if (this.ref !== undefined) {
            if (this.ref.innerRef !== undefined && this.ref.innerRef.current !== null) {
                this.ref.innerRef.current.focus()
            }
        }
    };

    /**
     * Callback for TextInput component: user has entered a Carriage Return ('Enter' key).
     * @param id Id of the TextInput triggering this callback.
     */
    private onCrEntered(id: string) {
        if (id === 'username') {
            this.setState({ focusTo: 'password' })
        } else if (id === 'password') {
            this.login();
        }
    }

    /**
     * Callback for Login button.
     */
    private async login() {
        this.setState({ isLoggingIn: true });
        const methodName = 'login()';
        let domain = '';
        let username = this.state.username;
        const posSlash = username.indexOf('\\');
        if (posSlash >= 0) {
            domain = username.substr(0, posSlash);
            username = username.substr(posSlash + 1);
        }
        if (posSlash === -1 && globalConfig.loginProperties && globalConfig.loginProperties.defaultDomain && domain === '') {
            domain = globalConfig.loginProperties.defaultDomain;
        }
        Logger.log(this.loggerLocality, `${methodName} : login in user ${domain}\\${username}`);
        const loginOk = await Session.instance.login(domain, username, this.state.password, false);
        if (isSuccess<boolean>(loginOk)) {
            if (loginOk) {
                const activateLicenseOk = await LicenseRegistrationService.instance.activateLicense(Session.instance.getUserLanguageCodeOrFallBack, this.state.contractNumber);
                if (isSuccess<BooleanResponse>(activateLicenseOk)) {
                    if (activateLicenseOk.status) {
                        this.setState({ isLoginOk: loginOk, focusTo: '' });
                    } else {
                        this.setState({ errorMessage: 'ErrorMessage:ActivateLicenseFailed' });
                        this.handleClick();
                        localStorage.removeItem(this.localStorageKeyForSavedUsername);
                    }
                } else {
                    if (activateLicenseOk != null && activateLicenseOk.detailedObject != null) {
                        this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(activateLicenseOk.detailedObject.subStatusCode) });
                    } else {
                        this.setState({ errorMessage: 'ErrorMessage:ActivateLicenseFailed' });
                    }
                    this.handleClick();
                    localStorage.removeItem(this.localStorageKeyForSavedUsername);
                }
            } else {
                this.setState({ errorMessage: 'ErrorMessage:LoginFailed' });

                this.handleClick();
                localStorage.removeItem(this.localStorageKeyForSavedUsername);
            }
        } else {
            if (loginOk != null && loginOk.detailedObject != null) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(loginOk.detailedObject.subStatusCode) });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:LoginFailed' });
            }
            this.handleClick();
            localStorage.removeItem(this.localStorageKeyForSavedUsername);
        }
        this.setState({ isLoggingIn: false });
    }

    private closePasswordResetPopup(): void {
        this.setState({ isPasswordResetPopupVisible: false, passwordResetPopupTitle: 'Login:PWResetTitle' }); // Replace with actual Titel translation
    }
    private openPasswordResetPopup(): void {
        this.setState({ isPasswordResetPopupVisible: true, passwordResetPopupTitle: 'Login:PWResetTitle' }); // Replace with actual Titel translation
    }

    private onPasswordReset() {
        this.closePasswordResetPopup();
    }
    /**
     * Check if fields (contractNumber, username and password) are not empty
     */
    private areInputsFieldsNotEmpty(): boolean {
        let isValid: boolean = true;
        if (this.state.contractNumber === '' || this.state.username === '' || this.state.password === '') {
            isValid = false;
        }
        return isValid;
    }

    /**
     * Pass event to parent and redirect to SelfRegistration
     */
    private onSelfRegisterClick(): void {
        this.props.history.push(`/licenseSelfRegCheck?contractNumber=${this.state.contractNumber}`);
    }
}