import Logger from '$core/Logger';
import AssignmentService from '$core/Services/AssignmentService';
import Session from '$core/Session';
import ContainerSessionCache from '$storage/cache/ContainerSessionCache';
import { AssignedItem } from '$storage/models/AssignedItem';
import { EItemType, ELessonStatus, ERegistrationStatus, ESortDirection } from '$storage/models/enums';
import { FakeAssignedItem } from '$storage/models/FakeAssignedItem';
import { RegisteredItem } from '$storage/models/RegisteredItem';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import { action } from 'mobx';

const CACHING_DURATION = 300;

export default class AssignmentStorage extends ContainerSessionCache<number, AssignedItem> {
    protected className = 'AssignmentStorage';
    protected loggerLocality = 'Storage.AssignmentStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    /**
     * Rmove all assignments from cache.
     */
    @action public clear(): void {
        super.clear();
        this.isInitialized = false;
    }

    public sort(items: AssignedItem[]): void {
        items.sort((a1: AssignedItem, a2: AssignedItem): number => {
            let rh1: number | undefined;
            let rh2: number | undefined;

            const emptyRemainingHours = globalConfig.assignmentProperties.orderByDateASC ? Number.MAX_VALUE : Number.MIN_VALUE;

            if(a1.lessonStatus === ELessonStatus.Completed || a1.lessonStatus === ELessonStatus.Failed || a1.lessonStatus === ELessonStatus.Passed) {
                // Handle finished lessons like assignments without date (date is now shown in summaries)
                rh1 = emptyRemainingHours;
            }
            else {
                rh1 = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ? 
                    (a1.remainingHoursToEnd !== undefined ? a1.remainingHoursToEnd : emptyRemainingHours) :
                    (a1.remainingHoursToStart !== undefined ? a1.remainingHoursToStart : emptyRemainingHours);
            }

            if(a2.lessonStatus === ELessonStatus.Completed || a2.lessonStatus === ELessonStatus.Failed || a2.lessonStatus === ELessonStatus.Passed) {
                // Handle finished lessons like assignments without date (date is now shown in summaries)
                rh2 = emptyRemainingHours;
            }
            else {
                rh2 = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ? 
                    (a2.remainingHoursToEnd !== undefined ? a2.remainingHoursToEnd : emptyRemainingHours) :
                    (a2.remainingHoursToStart !== undefined ? a2.remainingHoursToStart : emptyRemainingHours);
            }

            if (rh1 > rh2) { return globalConfig.assignmentProperties.orderByDateASC ? 1 : -1; }
            if (rh1 < rh2) { return globalConfig.assignmentProperties.orderByDateASC ? -1 : 1 }
            // Sort by Title if Date is same (ignore case)
            if(a1.title.toLowerCase() > a2.title.toLowerCase()) { return 1}
            if(a1.title.toLowerCase() < a2.title.toLowerCase()) { return -1}
            return 0;
        })
    }

    public sortByRemainingHours(items: AssignedItem[], direction: ESortDirection): void {
        items.sort((a1: AssignedItem, a2: AssignedItem): number => {
            let rh1: number | undefined;
            let rh2: number | undefined;

            const emptyRemainingHours = direction == ESortDirection.Up ? Number.MAX_VALUE : Number.MIN_VALUE;

            if(a1.lessonStatus === ELessonStatus.Completed || a1.lessonStatus === ELessonStatus.Failed || a1.lessonStatus === ELessonStatus.Passed) {
                // Handle finished lessons like assignments without date (date is now shown in summaries)
                rh1 = emptyRemainingHours;
            }
            else {
                rh1 = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ? 
                    (a1.remainingHoursToEnd !== undefined ? a1.remainingHoursToEnd : emptyRemainingHours) :
                    (a1.remainingHoursToStart !== undefined ? a1.remainingHoursToStart : emptyRemainingHours);
            }

            if(a2.lessonStatus === ELessonStatus.Completed || a2.lessonStatus === ELessonStatus.Failed || a2.lessonStatus === ELessonStatus.Passed) {
                // Handle finished lessons like assignments without date (date is now shown in summaries)
                rh2 = emptyRemainingHours;
            }
            else {
                rh2 = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ? 
                    (a2.remainingHoursToEnd !== undefined ? a2.remainingHoursToEnd : emptyRemainingHours) :
                    (a2.remainingHoursToStart !== undefined ? a2.remainingHoursToStart : emptyRemainingHours);
            }

            if (rh1 > rh2) { return direction == ESortDirection.Up ? 1 : -1; }
            if (rh1 < rh2) { return direction == ESortDirection.Up ? -1 : 1 }
            // Sort by Title if Date is same (ignore case)
            if(a1.title.toLowerCase() > a2.title.toLowerCase()) { return 1}
            if(a1.title.toLowerCase() < a2.title.toLowerCase()) { return -1}
            return 0;
        })
    }

    public filterRequiredAssignments(a: AssignedItem): boolean {
        return a.isRequired &&
            !globalConfig.assignmentProperties.ignoreItemsWithLessonStatus.includes(a.lessonStatus) &&
            (
                (!globalConfig.assignmentProperties.showFutureAssignedItems &&
                    (
                        (!(a instanceof FakeAssignedItem) &&
                            (a.assignmentsEarliestLearningPeriodBegin == null ||
                                a.assignmentsEarliestLearningPeriodBegin <= new Date()))
                        ||
                        (a instanceof FakeAssignedItem &&
                            (a.learningPeriodStartDate == null ||
                                a.learningPeriodStartDate <= new Date()))
                    )
                )
                ||
                globalConfig.assignmentProperties.showFutureAssignedItems
            )
            &&
            (
                (!globalConfig.assignmentProperties.showPassedAssignedItems &&
                    (
                        (!(a instanceof FakeAssignedItem) &&
                            (a.assignmentsLatestLearningPeriodEnd == null ||
                                a.assignmentsLatestLearningPeriodEnd >= new Date()))
                        ||
                        (a instanceof FakeAssignedItem &&
                            (a.learningPeriodEndDate == null ||
                                a.learningPeriodEndDate >= new Date()))

                    )
                )
                ||
                globalConfig.assignmentProperties.showPassedAssignedItems
            )
    }

    public filterRecommendedAssignments(a: AssignedItem): boolean {
        // FakeAssigned Items can be ignored since isRequired is set to true
        return !a.isRequired &&
               !globalConfig.assignmentProperties.ignoreItemsWithLessonStatus.includes(a.lessonStatus) &&
            (
                (!globalConfig.assignmentProperties.showFutureRecommendedItems &&
                    (a.assignmentsEarliestLearningPeriodBegin == null ||
                      a.assignmentsEarliestLearningPeriodBegin <= new Date()
                    )
                )
                ||
                globalConfig.assignmentProperties.showFutureRecommendedItems
            )
            &&
            (
                (!globalConfig.assignmentProperties.showPassedRecommendedItems &&
                    (a.assignmentsLatestLearningPeriodEnd == null ||
                        a.assignmentsLatestLearningPeriodEnd >= new Date())
                )
                ||
                globalConfig.assignmentProperties.showPassedRecommendedItems
            )
    }

    public getObjectsDistinct(): AssignedItem[] | null {
        const distinctMap = new Map<number, AssignedItem>();
        const items = this.getObjectsFromCache();
        if (items !== null) {
            for (const item of items) {
                if (!distinctMap.has(item.itemId)) {
                    distinctMap.set(item.itemId, item);
                }
            }
        }
        const distinctArray: AssignedItem[] = Array.from(distinctMap.values());
        return distinctArray;
    }

    @action
    protected async loadData(): Promise<AssignedItem[] | GtError> {
        const assignments = await this.loadDataInternal(Session.instance.getUserLanguageCodeOrFallBack);
        if (isSuccess<AssignedItem[]>(assignments)) {
            const registeredItems = await this.loadRegisterdItems()
            registeredItems.map((item) => {if (assignments != null) {                 
                 assignments.push(item)
                 this.saveObjectToCache(item.itemId, item);
                }
              })
        }
        return assignments
    }

    
    /**
     * Get Assignments of current user from server.
     * @param language Language
     */
    @action
    private async loadDataInternal(language?: string): Promise<AssignedItem[] | GtError> {
        const methodName = `${this.className}:loadDataInternal()`;
        if (language === null || language === undefined) {
            language = Session.instance.getUserLanguageCodeOrFallBack;
        }
        this.clear();
        Logger.log(this.loggerLocality, `${methodName} getting assignments from server, language=${language}.`);
        const response = await AssignmentService.instance.getAssignments();
        if (isSuccess<AssignedItem[]>(response)) {
            const elementCount = (Array.isArray(response)) ? response.length : 0;
            Logger.log(this.loggerLocality, `${methodName} got assignments from server, language=${language}, number of elements=${elementCount}.`);
            response.map(a => {
                this.saveObjectToCache(a.itemId, a);
            });
            return this.getObjectsFromCache();
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} failed to get assignments from server, language=${language}.`);
            return response;
        }
    }

    @action
    private async loadRegisterdItems(): Promise<AssignedItem[]>
    {
        // TODO this should be down serverside
        const assignments: AssignedItem[] =[]
        if(!globalConfig.assignmentProperties.showRegisteredTpInLearningPeriod) {
            return assignments
        }


        const registeredItems = await Session.instance.storage.registeredItem.getObjects()
        if( registeredItems != null ) {
            const filteredItems = registeredItems.filter((registeredItem) => this.filterTplans(registeredItem))
            filteredItems.map((item) => {

                    const assignment = new FakeAssignedItem()
                    assignment.allAssignments  = item.allAssignments
                    assignment.allRegistrations = item.allRegistrations
                    assignment.assignmentId = 0 // We need to set 0, otherwise the TP will start in the wrong context
                    assignment.assignmentsEarliestLearningPeriodBegin = item.assignmentsEarliestLearningPeriodBegin
                    assignment.assignmentsLatestLearningPeriodEnd = item.assignmentsLatestLearningPeriodEnd
                    assignment.assignmentsLatestLearningPeriodTarget = item.assignmentsLatestLearningPeriodTarget
                    assignment.attributes = item.attributes
                    assignment.cancellationDate1 = item.cancellationDate1
                    assignment.cancellationDate2 = item.cancellationDate2
                    assignment.currentRegistration = item.currentRegistration
                    assignment.globalTemplate = item.globalTemplate
                    assignment.icon = item.icon
                    assignment.inputSkillCount = item.inputSkillCount
                    assignment.isAutoRegister = item.isAutoRegister
                    assignment.isMultipleRegistrationAllowed = item.isMultipleRegistrationAllowed
                    assignment.isRatingEnabled = item.isRatingEnabled
                    assignment.itemSubType = item.itemSubType
                    assignment.isRequired = true
                    assignment.itemId = item.itemId
                    assignment.itemType = item.itemType
                    assignment.learningPeriodBegin = item.learningPeriodStartDate
                    assignment.learningPeriodEnd = item.learningPeriodEndDate
                    assignment.learningPeriodTarget = item.learningPeriodEndDate
                    assignment.lastUsed = item.lastUsed
                    assignment.learningDuration = item.learningDuration
                    assignment.learningDurationId = item.learningDurationId
                    assignment.learningPeriodBegin = item.learningPeriodStartDate
                    assignment.learningPeriodEnd = item.learningPeriodEndDate
                    assignment.lessonStatus= item.lessonStatus
                    assignment.mentors = item.mentors
                    assignment.nextRegistration = item.nextRegistration
                    assignment.orderByTitle = item.orderByTitle
                    assignment.ratingAverage = item.ratingAverage
                    assignment.ratingCount = item.ratingCount
                    assignment.ratingMine = item.ratingMine
                    assignment.registeredClassId = item.registeredClassId
                    assignment.registrationId = item.registrationId
                    assignment.registrationPeriodEnd = item.registrationPeriodEnd
                    assignment.registrationPeriodStart = item.registrationPeriodStart
                    assignment.registrationRequiredEnabled = item.registrationRequiredEnabled
                    assignment.registrationStatus = item.registrationStatus
                    assignment.sId = item.sId
                    assignment.score = item.score
                    assignment.summary = item.summary
                    assignment.title = item.title

                    assignments.push(assignment)
                    }
            )

        }
        return assignments
    }

    @action
    private filterTplans(item: RegisteredItem ) {
        // Only take TP with Registration Status Accepted
        // and filter then like an assignment
        return  item.itemType === EItemType.TrainingPlan ?
                                  item.registrationStatus === ERegistrationStatus.Accepted
                                  :
                                  false
    }

}
