import Logger from '$src/core/Logger';
import TranslationService from '$src/core/Services/TranslationService';
import Session from '$src/core/Session';
import LocalCache from '$src/storage/cache/LocalCache';
import { TranslationList } from '$storage/models/TranslationList';
import TranslationObject from '$storage/models/TranslationObject';
import { isSuccess } from '$util/Result';

const CACHING_DURATION = 43200;
const FALLBACK_LANGUAGE = 'de-DE';
const STORAGE_PREFIX = 'TRA-';

export default class TranslationStorage extends LocalCache<string, TranslationList> {
    protected className = 'TranslationStorage';
    protected loggerLocality = 'Storage.Storage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION,
            localStorage,
            STORAGE_PREFIX,
            false,
            false); // Reloading is made differently on Language Switch
    }

    public clear(): void {
        super.clear();
    }

    /**
     * Get Translation String from cache.
     * @param langCode 
     * @param key 
     */
    public GetString(key: string, langCode?: string): string {
        if (langCode === null || langCode === undefined) {
            langCode = Session.instance.languageCode || FALLBACK_LANGUAGE;
        }
        return this.GetStringFromCache(langCode, key);
    }

    /**
     * Check if the sessionStore for current Language is empty or not
     * @param langCode 
     */
    public isObjectInCacheAndValid(langCode: string): boolean {
        let retVal = false;
        if (this.isObjectInCache(langCode)) {
            const cobj = this.getCachedObjectFromCache(langCode);
            if (cobj !== null && cobj !== undefined) {
                retVal = cobj.isValid(this.cachingDuration)
            }
        }
        return retVal;
    }

    /**
     * Read the translations in given language from the Web API
     * @param langCode 
     */
    public async InitializeTranslationStore(langCode: string): Promise<void> {
        const methodName = `${this.className}:InitializeTranslationStore()`;
        try {
            const tranPromise = await TranslationService.instance.getTranslationList(langCode)
            if (isSuccess<TranslationList>(tranPromise) && tranPromise.translationObjects !== undefined) {
                this.saveObjectToCache(langCode, tranPromise);
                Logger.log(this.loggerLocality, 
                           `${methodName} got translationlist from server, 
                            langCode=${langCode} 
                            ArrayCount= ${tranPromise.translationObjects.length.toString()}`);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get translationlist from server, langCode=${langCode}`);
            }
        } catch (ex) {
            Logger.log(this.loggerLocality, `${methodName} failed to get translationlist from server, langCode=${langCode}, ex=${ex.toString}`);
        }
    }

    public isCacheExpired(): boolean {
        return false;
    }


    /**
     * Get translation string from already initialized store
     * @param langCode 
     * @param key 
     */
    private GetStringFromCache(langCode: string, key: string): string {
        let retVal: string = key;

        const langPack = this.getObjectFromCache(langCode);
        if (langPack !== null && langPack !== undefined) {
            if (langPack.translationObjects !== null && langPack.translationObjects !== undefined) {
                const langString: TranslationObject | undefined = langPack.translationObjects.find(f => f.key === key);
                retVal = langString ? langString.text : key;
            }
        }

        return retVal;
    }
}
