import Session from '$core/Session';

export class ReportHelper {

    public static getReportLink(reportName: string,
                                reportMode: string,
                                userid? : number,
                                bossRelationCode? : string): string {

            return `/lmssui?forwardurl=Reports/DefaultForReport.aspx` +
            `&p1=reportUserId=${Session.instance.loginUser != null ? Session.instance.loginUser.id : ''}` +
            `&p2=reportName=${reportName}` +
            `&p3=mode=${reportMode}` +
            (userid != null ? `&p4=userid=${userid}` : '') +
            `&p5=nsui2=clear` + // Clear Schedule Filter
            `${bossRelationCode ? `&p6=bossRelationCode=${bossRelationCode}` : ''}`;
    }
    public static getEmbeddedReportLink(reportName: string,
                                reportMode: string,
                                userid? : number,
                                bossRelationCode? : string): string {

            return `/lmssuiembedded?forwardurl=Reports/DefaultForReport.aspx` +
            `${bossRelationCode ? `?bossRelationCode=${bossRelationCode}` : ''}` +
            `&p1=reportUserId=${Session.instance.loginUser != null ? Session.instance.loginUser.id : ''}` +
            `&p2=reportName=${reportName}` +
            `&p3=mode=${reportMode}` +
            (userid != null ? `&p4=userid=${userid}` : '') +
            `&p5=nsui2=clear` + // Clear Schedule Filter
            `${bossRelationCode ? `&p6=${bossRelationCode}` : ''}`;
    }
}

