export default class CachedObject<TObject> {
    private _cachedAt: string;
    private _obj: TObject;

    constructor(object: TObject) {
        this._obj = object;
        const d = new Date();
        this._cachedAt = d.toISOString();
    }

    get object(): TObject { return this._obj; }

    public isValid(cachingDuration: number): boolean {
        const cachedAt = (new Date(this._cachedAt)).getTime();
        const now = (new Date()).getTime();
        const age = (now - cachedAt) / 1000;
        return age <= cachingDuration;
    }
}