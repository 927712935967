import React from 'react';

import Iconhelp from '$resources/svgs/navi/help.svg';
import Iconhome from '$resources/svgs/navi/home.svg';

import { Footer } from '$components/layout/Footer';
import { Header } from '$components/layout/Header';
import { Menu } from '$components/layout/menu/Menu';
import { FullScreenLogin } from '$components/login/FullScreenLogin';
import { PasswordChange } from '$components/login/PasswordChange';
import { Cookiebar } from '$components/shared/Cookiebar';
import Session from '$core/Session';
import { LanguageSwitcher } from '$components/layout/LanguageSwitcher';
import { Scroller } from '$components/shared/Scroller';
import Logger from '$core/Logger';
import ResizeEventListener from '$util/EventListener/ResizeEventListener';
import { observer } from 'mobx-react';
import InlineSVG from 'react-inlinesvg';
import { NavLink, Redirect } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Sticky, StickyContainer } from 'react-sticky';
import { Tooltip } from '$components/shared/Tooltip';
import SystemRoles from '$src/core/SystemRoles';
import ReactGA from 'react-ga';
import { BackgroundSsoIdMigration } from '$components/userProfile/BackgroundSsoIdMigration';
import ToastMessages from '$src/components/shared/WarningsAndErrors/ToastErrorMessage';
import ServiceInfoBar from '$src/components/shared/ServiceInfoBar';
import UserProfileMissingDataWarning from '$src/components/userProfile/UserProfileMissingDataWarning';
import PrivacyPopUp from '$src/components/privacy/PrivacyPopUp';
import PopupNews from '$src/components/newsPanel/PopupNews';
export interface IRouteProperties {
    exact?: boolean;
    path: string;
    IsPublic: boolean;
    IsExternal?: boolean; // true, if the link points to an external source
    renderComponentOnly?: boolean; // true, if only the component should be rendered instead of the full app
}

export interface IProps extends RouteComponentProps {
    children?: React.ReactNode;
}

@observer
export class App extends React.Component<IProps> {

    protected className = 'App';
    protected loggerLocality = 'Components.App';

    constructor(props: IProps) {
        super(props);
        Session.instance.app = this;
    }

    public componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        Logger.log(this.loggerLocality, `${methodName} adding Event Listener "resize".`);
        window.addEventListener('resize', () => this.onResize());

        if (globalConfig.trackingProperties.googleAnalyticsTrackingId != null && globalConfig.trackingProperties.googleAnalyticsTrackingId != '') {
            ReactGA.initialize(globalConfig.trackingProperties.googleAnalyticsTrackingId);
            ReactGA.pageview(window.location.pathname + window.location.search);

            // Initialize google analytics page view tracking
            this.props.history.listen(loc => {
                ReactGA.set({ page: loc.pathname }); // Update the user's current page
                ReactGA.pageview(loc.pathname); // Record a pageview for the given page
            });
        }
    }

    public componentDidUpdate(prevProps: RouteComponentProps<{}>) {
        const methodName = `${this.className}:componentDidUpdate()`;
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0) // Scroll to Top on Navigation
            Logger.log(this.loggerLocality, `${methodName} scrolling to 0`);
        }
    }

    public componentWillUnmount() {
        const methodName = `${this.className}:componentWillUnmount()`;
        Logger.log(this.loggerLocality, `${methodName} removing Event Listener "resize".`);
        window.removeEventListener('resize', () => this.onResize());
    }

    public render() {
        if (this.renderComponentOnly(this.props) === true) {
            return (<div className="app">{this.props.children}</div>);
        }
        else {
            return (
                <div className="app">
                    <Header
                        onLanguageSwitched={() => this.onLanguageSwitched()}
                        {...this.props}
                    />
                    <main>
                        {this.renderCore()}
                    </main>
                    <Scroller />
                    <Footer />
                    <Cookiebar />
                    <ServiceInfoBar />
                </div>
            );
        }
    }

    private renderCore() {
        let retVal;
        // Default Pages with menu (User logged in)
        const isUserLoggedIn = Session.instance.isUserLoggedIn;
        let faqURL = globalConfig.navigationProperties.helpFaqUrl || '';
        faqURL = faqURL.replace('@language', Session.instance.getUserLanguageCodeOrFallBack);

        if (isUserLoggedIn) {
            // clear return url when user is logged in
            Session.instance.setReturnUrlAfterLogin('');

            // If the logged in User has a password that is expired or is still the initial pw
            if (Session.instance.isPasswordExpired && !Session.instance.getIsExternalLoginSession) {
                retVal = (
                    <PasswordChange {...this.props}
                        hint={'PasswordChange:' + (Session.instance.jwtTokenDecoded != null ? Session.instance.jwtTokenDecoded['gt-user-passwordexpired-code'] : '')} />

                );
                // Else show the default view with the menu on top
            } else {
                retVal = (
                    <StickyContainer className="app__sticky-container">
                        <Sticky>
                            {({ style,
                                isSticky,
                                wasSticky,
                                distanceFromTop,
                                distanceFromBottom,
                                calculatedHeight }) => (
                                <Menu
                                    style={style}
                                    isSticky={isSticky}
                                    wasSticky={wasSticky}
                                    distanceFromTop={distanceFromTop}
                                    distanceFromBottom={distanceFromBottom}
                                    calculatedHeight={calculatedHeight}
                                    smallMenuMaxWidthFallBack={globalConfig.layoutProperties.menu_SmallMenuBreakPoint}
                                    onLanguageSwitched={() => this.onLanguageSwitched()}
                                    {...this.props}
                                />
                            )}
                        </Sticky>
                        {this.pageAllowedForUser(this.props) ? this.props.children : <Redirect to="/forbidden" />}
                        {(Session.instance.loginUser && Session.instance.loginUser.roles.some(p => p.toLowerCase() === 'guest') === false) && <BackgroundSsoIdMigration />}
                        <ToastMessages />
                        <UserProfileMissingDataWarning />
                        {(Session.instance.getIsGdprEnabled && Session.instance.getHasOutdatedTermsAndConditions) && <PrivacyPopUp />}
                        <PopupNews />
                    </StickyContainer>
                );
            }
            // Else show the requested public page or the login page
        } else {
            const page = this.checkForPublicPage(this.props);
            if (page != null && page !== undefined) {
                if (this.isPageExternal(this.props) === true) {
                    retVal = (
                        <StickyContainer className="app__sticky-container">
                            <Sticky>
                                {({ style }) => (
                                    <div className={'menu menu--login'} style={style}>
                                        <div className={'l-container menu__container'}>

                                            <NavLink
                                                aria-label={Session.instance.storage.translation.GetString('Navigation:Home')}
                                                data-tip={Session.instance.storage.translation.GetString('Navigation:Home')}
                                                data-for="app_menuIconHomeTooltip"
                                                to={'/'}
                                                className="menu__icon" >
                                                <InlineSVG src={Iconhome} />
                                                <Tooltip id="app_menuIconHomeTooltip" place="bottom" />
                                            </NavLink>
                                            {globalConfig.navigationProperties.helpIconButton_Visible && globalConfig.navigationProperties.helpFaqInIframe ?
                                                (

                                                    <NavLink
                                                        to={'/help'}
                                                        className="menu__icon"
                                                        aria-label={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                        data-tip={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                        data-for="app_menuIconHelpTooltip">
                                                        <InlineSVG src={Iconhelp} />
                                                        <Tooltip id="app_menuIconHelpTooltip" place="bottom" />
                                                    </NavLink>

                                                ) : ''}

                                            {globalConfig.navigationProperties.helpIconButton_Visible && !globalConfig.navigationProperties.helpFaqInIframe ?
                                                (

                                                    <a
                                                        href={faqURL}
                                                        target={'_blank'}
                                                        rel="noopener noreferrer"
                                                        className="menu__icon"
                                                        aria-label={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                        data-tip={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                        data-for="app_menuIconHelpTooltip">
                                                        <InlineSVG src={Iconhelp} />
                                                        <Tooltip id="app_menuIconHelpTooltip" place="bottom" />
                                                    </a>

                                                ) : ''}
                                            {globalConfig.layoutProperties.displayLanguageSwitcherOnLogin ?
                                                <div className="menu__language-container">
                                                    <LanguageSwitcher
                                                        onLanguageSwitched={() => this.onLanguageSwitched()} />
                                                </div>
                                                : null}
                                        </div>
                                    </div>)}
                            </Sticky>
                            {page}
                        </StickyContainer>);
                }
                else {
                    retVal = page;
                }

            } else {
                retVal = (
                    <StickyContainer className="app__sticky-container">
                        <Sticky>
                            {({ style }) => (
                                <div className={'menu menu--login'} style={style}>
                                    <div className={'l-container menu__container'}>
                                        {globalConfig.navigationProperties.helpIconButton_Visible && globalConfig.navigationProperties.helpFaqInIframe ?
                                            (
                                                <NavLink
                                                    to={'/help'}
                                                    className="menu__icon"
                                                    aria-label={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                    data-tip={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                    data-for="app_menuIconHelpTooltip"
                                                >
                                                    <InlineSVG src={Iconhelp} />
                                                    <Tooltip id="app_menuIconHelpTooltip" place="bottom" />
                                                </NavLink>

                                            ) : ''}

                                        {globalConfig.navigationProperties.helpIconButton_Visible && !globalConfig.navigationProperties.helpFaqInIframe ?
                                            (

                                                <a
                                                    href={faqURL}
                                                    target={'_blank'}
                                                    rel="noopener noreferrer"
                                                    className="menu__icon"
                                                    aria-label={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                    data-tip={Session.instance.storage.translation.GetString('Navigation:Help')}
                                                    data-for="app_menuIconHelpTooltip">
                                                    <InlineSVG src={Iconhelp} />
                                                    <Tooltip id="app_menuIconHelpTooltip" place="bottom" />
                                                </a>

                                            ) : ''}
                                        {globalConfig.layoutProperties.displayLanguageSwitcherOnLogin ?
                                            <div className="menu__language-container">
                                                <LanguageSwitcher onLanguageSwitched={() => this.onLanguageSwitched()} />
                                            </div> : null}

                                    </div>
                                </div>)}
                        </Sticky>
                        <FullScreenLogin
                            username=""
                            password=""
                            redirectPage={window.location.pathname + window.location.search}
                            {...this.props}
                        />
                        <ToastMessages />
                    </StickyContainer>);
            }
        }
        return retVal;
    }

    /**
     * Check if the current page (url) is marked as public in routes.tsx
     * If it is, it is accessible even if you are not logged in.
     */
    private checkForPublicPage(props: IProps) {
        let retVal;
        let curPage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        curPage = '/' + curPage;
        if (props.children != null && props.children !== undefined) {
            const routes: React.ReactNodeArray = props.children as React.ReactNodeArray;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < routes.length; i++) {
                const route = routes[i];
                if (React.isValidElement(route)) {
                    const routeProperties = route.props as IRouteProperties;
                    if (routeProperties.path === curPage && routeProperties.IsPublic === true) {
                        retVal = route;
                    }
                }
            }
        }
        return retVal;
    }

    /**
     * Check if the current loggedin user is allowed for the current page
     * @param props 
     */
    private pageAllowedForUser(props: IProps): boolean {
        let retVal: boolean = true;
        const isUserGuest = Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest);
        const allowedGuestNavigation = globalConfig.navigationProperties.allowedRoutesByGuest;

        const baseUrl = globalConfig.appProperties.appPathOverride ? globalConfig.appProperties.appPathOverride : '';
        const currentPage = window.location.pathname.replace(baseUrl, '/');

        // if the user is guest, check if the currentPage (only the first path part) is configured in allowedRoutesByGuest
        if (isUserGuest &&
            (allowedGuestNavigation.filter(nav => nav.link.split('/')[1].toLocaleLowerCase() === currentPage.split('/')[1].toLocaleLowerCase()).length === 0)) {
            retVal = currentPage === '/forbidden';
        }

        // if retVal is false, check if the currentPage correspond to a public route
        if (!retVal) {
            if (props.children != null && props.children !== undefined) {
                const routes: React.ReactNodeArray = props.children as React.ReactNodeArray;
                // tslint:disable-next-line:prefer-for-of
                for (let i = 0; i < routes.length; i++) {
                    const route = routes[i];
                    if (React.isValidElement(route)) {
                        const routeProperties = route.props as IRouteProperties;
                        if (routeProperties.path.toLocaleLowerCase().includes(currentPage.split('/')[1].toLocaleLowerCase()) && routeProperties.IsPublic === true) {
                            retVal = true
                        }
                    }
                }
            }
        }

        return retVal;
    }

    /**
     * Check if the current page (url) is marked as external in routes.tsx
     * If it is, it is displayed.
     */
    private isPageExternal(props: IProps) {
        let retVal = false;
        let curPage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        curPage = '/' + curPage;
        if (props.children != null && props.children !== undefined) {
            const routes: React.ReactNodeArray = props.children as React.ReactNodeArray;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < routes.length; i++) {
                const route = routes[i];
                if (React.isValidElement(route)) {
                    const routeProperties = route.props as IRouteProperties;
                    if (routeProperties.path === curPage && routeProperties.IsExternal != null && routeProperties.IsExternal === true) {
                        retVal = true;
                    }
                }
            }
        }
        return retVal;
    }

    /**
     * Check if the current page (url) is marked as component only
     */
    private renderComponentOnly(props: IProps) {
        const methodName = `${this.className}:isComponentOnly()`;
        let retVal = false;
        let curPage = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        curPage = '/' + curPage;
        if (props.children != null && props.children !== undefined) {
            const routes: React.ReactNodeArray = props.children as React.ReactNodeArray;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < routes.length; i++) {
                const route = routes[i];
                if (React.isValidElement(route)) {
                    const routeProperties = route.props as IRouteProperties;
                    if (routeProperties.path === curPage && routeProperties.renderComponentOnly != null && routeProperties.renderComponentOnly === true) {
                        retVal = true;
                    }
                }
            }
        }
        Logger.log(this.loggerLocality, `${methodName} render component only is set to ${retVal}`);
        return retVal;
    }

    private onLanguageSwitched() {
        const methodName = `${this.className}:onLanguageSwitched()`;
        Logger.log(this.loggerLocality, `${methodName} event executed on component App.tsx`);
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    }

    private onResize() {
        const methodName = `${this.className}:onResize()`;
        Logger.log(this.loggerLocality, `${methodName} - dispatch subscriber`);
        ResizeEventListener.instance.subscriberHandler.dispatch();
    }
}

export default withRouter(App);