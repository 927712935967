
import { GlossaryAssociationTypeConverter } from '$src/storage/models/converters/GlossaryAssociationTypeConverter';
import { EGlossaryAssociationType } from '$src/storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

/** This is an Element used in the Catalog. */
@JsonObject
export class Glossary {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('sid', String, false)
    public sid: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';
 
    @JsonProperty('numberOfTerms', Number, false)
    public numberOfTerms: number = 0;

    @JsonProperty('numberOfViewedTerms', Number, false)
    public numberOfViewedTerms: number = 0;

    @JsonProperty('associationType', GlossaryAssociationTypeConverter, false)
    public associationType: EGlossaryAssociationType = EGlossaryAssociationType.UserPinned;

}
