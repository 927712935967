import { JsonObject, JsonProperty } from 'json2typescript';

/**
 *This Model is for showing the item for MailTo
 */
@JsonObject
export class ItemMailLink {

    /** ItemId  */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** ItemName*/
    @JsonProperty('itemName', String, false)
    public itemName: string = "";

    /** ItemTitle*/
    @JsonProperty('itemTitle', String, false)
    public itemTitle: string = "";

    /** ItemType*/
    @JsonProperty('itemType', Number, false)
    public itemType: number = 0;
}
