import React, { useEffect, useState } from 'react';

import Carousel from '$components/shared/Carousel';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import LessonService from '$core/Services/ItemService';
import Session from '$core/Session';
import { EItemDetailCallerContextType } from '$storage/models/enums';
import { Item } from '$storage/models/Item';
import { isSuccess } from '$util/Result';
import { ItemSummary } from '$components/item/itemSummaries/ItemSummary';
import { useHistory } from 'react-router';
import { useInView } from 'react-intersection-observer';


interface IProps {
    parentHeadingLevel: number;
    parentItemId: number;
}

export default function RecommendationContainer(props: IProps) {
    const [itemIdLoaded, setItemIdLoaded] = useState<number>(0);
    const [lessons, setLessons] = useState<Item[] | undefined>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const history = useHistory();
    const className = 'RecommendationContainer';
    const loggerLocality = 'Components.RecommendationContainer';
    const lessonElements: JSX.Element[] = [];
    const { ref: refViewPortElement, inView: isInViewPort } = useInView({
        /* Optional options */
        skip: !globalConfig.recommendedLessonsProperties.isLoadOnVisibleViewPort,
      });
    
    useEffect(() => {
        if (!dataLoaded && (isInViewPort || !globalConfig.recommendedLessonsProperties.isLoadOnVisibleViewPort)) {
            const methodName = `${className}:getRecommendedLessons()`;
            LessonService.instance.getRecommendedLessonByItemID(Session.instance.getUserLanguageCodeOrFallBack,
                props.parentItemId,
                globalConfig.recommendedLessonsProperties.recommendedLessonsMaxCount).then((response) => {
                    if (isSuccess<Item[]>(response)) {
                        Logger.log(loggerLocality, `${methodName} got recommended lessons`);
                        setDataLoaded(true);
                        setItemIdLoaded(props.parentItemId);
                        setLessons(response);
                    }
                    else {
                        const empty: Item[] = [];
                        Logger.log(loggerLocality, `${methodName} failed to get data, ${response.message}`);
                        console.error(`${loggerLocality} ${methodName} failed to get data, ${response.message}`);
                        setLessons(empty);
                        setDataLoaded(true)
                    }
                });
        }
    })

    function onGoToCatalogClicked() {
        history.push('/Catalog/1');
    }

    return (
        <div ref={refViewPortElement}>
            {dataLoaded && props.parentItemId > 0 && itemIdLoaded === props.parentItemId && (isInViewPort || !globalConfig.recommendedLessonsProperties.isLoadOnVisibleViewPort)  ?
                lessons != null &&
                lessons.map((lesson) => {
                    lessonElements.push(
                        <ItemSummary
                            item={lesson}
                            parentHeadingLevel={props.parentHeadingLevel + 1}
                            parentCatalogFolderId={0}
                            parentTrainingPlanId={0}
                            parentAssignmentId={0}
                            itemCallerContext={EItemDetailCallerContextType.Recommendations}
                        />
                    )
                })
                :
                lessonElements.push(
                    <ProgressSpinner key={'progSpinner'} />
                )
            }

            {lessons === undefined || lessons.length === 0 ? <React.Fragment /> : <div className="recommendation__container-background">
                <div className="recommendation__title">
                    <div className="l-container recommendation__container-header">
                        <div>
                            <Heading cssClass={'heading__Title heading--secondary'} headingLevel={props.parentHeadingLevel + 1}>
                                <Translate>RecommendationContainer:Title</Translate>
                            </Heading>
                        </div>
                    </div>
                </div>

                <div className="l-container recommendation__container-main">
                    <Carousel
                        items={lessonElements}
                        autoPlay={globalConfig.recommendedLessonsProperties.autoPlay}
                        autoPlayInterval={globalConfig.recommendedLessonsProperties.autoPlayInterval}
                        itemsForSmallScreen={0}
                        itemsForMediumScreen={2}
                        itemsForLargeScreen={3} />

                    {globalConfig.recommendedLessonsProperties.showGoToCatalogButton ? (
                        <div className="recommendation__container-catalogButton">
                            <button className="btn--md btn--primary" onClick={() => onGoToCatalogClicked()}>
                                <Translate>RecommendationContainer:GoToCatalog</Translate>
                            </button>
                        </div>) : (<React.Fragment />)}

                </div>

            </div>}
        </div>
    )
}