import { JsonObject, JsonProperty } from 'json2typescript';
import { Item } from '$src/storage/models/Item'

/** Model Class for the search function result list.
 * It extends the default Item Model
 */
@JsonObject('SearchedItem')
export class LegacySearchedItem extends Item{

    /** ID of the Parent Catalog Folder */
    @JsonProperty('parentItemId', Number, false)
    public parentItemId: number = 0;

    @JsonProperty('usageCount', Number, false)
    public usageCount: number = 0;
    /** Path to the current Catalog Folder */
    @JsonProperty('catalogPath', String, false)
    public catalogPath: string = '';


 }