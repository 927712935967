import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import React from 'react';
import { Redirect } from 'react-router';

interface IProps {
    attributes: InputAttributes;
}

interface IState {
    redirect: boolean;
}

export class RedirectLink extends React.Component<IProps, IState> {
    protected className = 'RedirectLink';
    protected loggerLocality = 'Components\\shared.RedirectLink';

    constructor(props: IProps) {
        super(props);
        this.state = {
            redirect: false
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {
        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'

        // Build final component element        
        const inputElement: JSX.Element[] = [];

        if (!this.props.attributes.editMode) {
            inputElement.push(
                <div key={'RedirectLink_Link_' + this.props.attributes.id}>
                    <button
                        className="button-link button-link--colorized"
                        onClick={(event) => this.setRedirect(event)}>
                        <Translate>{this.props.attributes.label}</Translate>
                    </button>
                </div>
            )
        }

        return (
            <div key={'RedirectLink_Div_' + this.props.attributes.id} className={boxClass}>
                {this.renderRedirect()}
                {inputElement}
            </div >
        )
    }

    protected setRedirect(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        this.setState(() => {
            return {
                redirect: true
            }
        });
    }

    protected renderRedirect() {
        const link: string = (this.props.attributes.url === undefined || this.props.attributes.url === null)
            ? '/' : this.props.attributes.url
        if (this.state.redirect) {
            return <Redirect push={true} to={link} />
        }
        return null;
    }

}