
import { CallbackUrl } from '$src/storage/models/CallbackUrl';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * SharedService provides all Service Methods who don't have an own Service. 
 * If you think it is not neccessary to create a new service, just add your SINGLE Method inside here.
 * If you have more than one method. Please create a new Service Class.
 */
export default class SharedService extends ServiceClient {
    protected static _instance: SharedService | null = null;

    protected className = 'SharedService';
    protected loggerLocality = 'SharedService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): SharedService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get the URL that should be called, mapped to the guid.
     * @param guid guid to map the url in the database
     */
    public async getCallbackURL(guid: string): Promise<CallbackUrl | GtError> {
        let response: CallbackUrl | GtError;
        response = await this.get<CallbackUrl>('callbackurl', CallbackUrl, undefined, undefined, guid);
        return response;
    }    
   
} 