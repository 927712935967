import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import { isSuccess } from "$src/util/Result";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { Translate } from "$src/components/shared/Translate";
import { ItemSummary } from "$src/components/item/itemSummaries/ItemSummary";
import { EItemDetailCallerContextType } from "$src/storage/models/enums";
import UserSubjectSubscriptionService from '$src/core/Services/UserSubjectSubscriptionService';
import { Heading } from "$src/components/shared/Heading";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import ContainerButton from "$src/components/shared/ContainerButton";
import Session from "$src/core/Session";
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { ItemWithSubscription } from '$storage/models/ItemWithSubscription';

export default function UserSubjectSubscriptionItems() {
    const { subjectId, subjName } = useParams<{ subjectId: string; subjName: string }>();

    const [currentSubjectName, setCurrentSubjectName] = useState<string>('');

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [items, setItems] = useState<ItemWithSubscription[]>([]);
    const [filterTitle, setFilterTitle] = useState<string>('');
    
    // Filter Data
    const [lessonTitles, setLessonTitles] = useState<string[]>([]);
    const [lessonTiltesFiltered, setLessonTitlesFiltered] = useState<string[]>([]);


    const [dataSettings, setDataSettings] = useState<{
        currentPage: number;
        pageSize: number;
    }>({
        currentPage: 1,
        pageSize: 5,
    });
    const [hasMoreData, setHasMoreData] = useState<boolean>(false);

    // Load values for filter once
    useEffect(() => {
        const subjId: number = subjectId != null && !isNaN(Number(subjectId)) ? Number(subjectId) : 0;
        UserSubjectSubscriptionService.instance.getLessonsTitleStrings(subjId).then(titles => {
            if (isSuccess<string[]>(titles)) {
                setLessonTitles(titles);
                setLessonTitlesFiltered(titles);
            }
        });
    }, []);

    /**
     * Load data from server
     */
    useEffect(() => {
        const subjId: number = subjectId != null && !isNaN(Number(subjectId)) ? Number(subjectId) : 0;
        setIsDataLoading(true);
        UserSubjectSubscriptionService.instance.getItemsOfSubscribedSubjectFromUser(subjId, dataSettings.currentPage, dataSettings.pageSize, filterTitle).then(sItems => {
            if (isSuccess(sItems)) {
                const response = sItems as Array<ItemWithSubscription>;
                const hasMoreData = response.length > dataSettings.pageSize;
                if (response != null && sItems != null) {
                    if (hasMoreData) response.pop();
                    setHasMoreData(hasMoreData);
                    if(dataSettings.currentPage === 1) {
                        setItems([...response])
                    }
                    else {
                        setItems([...items, ...response])
                    }
                    setCurrentSubjectName(subjName)
                }
            }
            setIsLoaded(true);
            setIsDataLoading(false);
        });
    }, [dataSettings]);

    const onChanged = (event: ComboBoxChangeEvent) => {
        const val = event.value;
        setFilterTitle(val || "");

    }

    const onFilterChanged = (event: ComboBoxFilterChangeEvent) => {
        const val = event.filter.value;
        setFilterTitle(val || "");
        setLessonTitlesFiltered(lessonTitles.filter(f => f.toLocaleLowerCase().includes(val.toLocaleLowerCase())));
    }

    return (
        <>
            <div className='l-container'>
                <MenuBreadCrumb />
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>UserSubjectSubscriptions:AboutSubject</Translate> {currentSubjectName}
                </Heading>
                <Heading headingLevel={2} cssClass="heading__Level4" >
                    <Translate>UserSubjectSubscriptions:Description</Translate>
                </Heading>
                <div className='my-lessons__container l-box--wide l-element--striped-reverse my-lessons__container--padding'>
                    <div role="table" className="my-Lessons__filter-table">
                        <div role="row" className="my-Lessons__filter-table-row">
                            <div role="columnheader" className="my-Lessons__columnheader-autocomplete"><Translate>UserSubjectSubscriptions:SearchByTitle</Translate>
                            </div>
                            <div role="cell">
                                <ComboBox
                                    aria-label={Session.instance.storage.translation.GetString('UserSubjectSubscriptions:SearchByTitle')}
                                    data={lessonTiltesFiltered}
                                    placeholder={Session.instance.storage.translation.GetString('UserSubjectSubscriptions:SearchByTitle')}
                                    value={filterTitle}
                                    className="input-field"
                                    filterable={true}
                                    allowCustom={true}
                                    suggest={false}
                                    onFilterChange={(value: ComboBoxFilterChangeEvent) => onFilterChanged(value)}
                                    onChange={(value: ComboBoxChangeEvent) => onChanged(value)}
                                />
                            </div>
                        </div>
                    </div>
                    <ContainerButton
                        ariaLabel={Session.instance.storage.translation.GetString('UserSubjectSubscriptions:ApplyFilter')}
                        buttonText={Session.instance.storage.translation.GetString('UserSubjectSubscriptions:ApplyFilter')}
                        id="idLoadSearch"
                        onButtonClicked={() => { setDataSettings({ ...dataSettings, currentPage: dataSettings.currentPage = 1 });  }}
                    />
                </div>
                <div className="l-box-container l-box-container--column-flex my-subscriptions__items">
                    {isLoaded && <ul className="item-box-tile__parent">
                        {
                            items.map(i => <ItemSummary
                                key={i.itemId}
                                item={i}
                                parentHeadingLevel={1}
                                itemCallerContext={EItemDetailCallerContextType.MySubjectSubscriptionsEditView}
                                parentCatalogFolderId={0}
                                parentTrainingPlanId={0}
                                parentAssignmentId={0}
                                highlight={i.isNew} />)
                        }
                    </ul>
                    }
                    {(!isLoaded || isDataLoading) && <ProgressSpinner />}
                </div>
                {
                    hasMoreData &&
                    <div className="my-recommendations__more">
                        {isDataLoading &&
                            <ProgressSpinner />}
                        {!isDataLoading &&
                            <GTButton onClick={() => {
                                setDataSettings({ ...dataSettings, currentPage: dataSettings.currentPage += 1 });
                            }}>
                                <Translate>UserSubjectSubscriptions:LoadMore</Translate>
                            </GTButton>}
                    </div>
                }
            </div>
        </>
    );
}

