import { SelfRegistration } from '$components/selfRegistration/SelfRegistration';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputText } from '$components/shared/InputText';
import { Translate } from '$components/shared/Translate';
import Session from '$src/core/Session';
import React from 'react';

interface IURLParamState {
    contractNumber: string;
    username: string;
    email: string;
    bossRelationCode: string;
}

interface IState extends IURLParamState {
    errorMessage: string;
}

export class LicenseSelfReg extends React.Component<{}, IState> {
    protected className = 'LicenseSelfReg';
    protected loggerLocality = 'Components.LicenseSelfReg';

    constructor(props: {}) {
        super(props);

        // Get URL Parameters
        const partialStateUrlParamState = this.getURLParameters();

        this.state = {
            ...partialStateUrlParamState,
            errorMessage: '',
        }
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('LicenseReg:ActivateLicense');
    }

    public render() {
        return (
            <div className="l-form-container">
                <div className="l-box--wide">
                    <h1 className="l-box--wide heading__Title">
                        <Translate>LicenseReg:SelfRegTitle</Translate>
                    </h1>
                    <div className="text">
                        <Translate>LicenseReg:SelfRegStep2Hint</Translate>
                    </div>
                </div>
                {this.renderLicenseSelfRegForm()}
            </div>
        )
    }

    private getURLParameters(): IURLParamState {
        const parameters = new URLSearchParams(window.location.search);
        const urlContractNumber = parameters.get('contractNumber');
        const urlUsername = parameters.get('username');
        const urlEmail = parameters.get('email');
        const urlBossRelationCode = parameters.get('bossRelationCode');
        return {
            contractNumber: urlContractNumber != null ? urlContractNumber : '',
            email: urlEmail != null ? urlEmail : '',
            username: urlUsername != null ? urlUsername : '',
            bossRelationCode: urlBossRelationCode != null ? urlBossRelationCode : ''
        }
    }

    private renderLicenseSelfRegForm(): JSX.Element {
        const contractNumberAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'contractNumber',
            label: 'LicenseReg:ContractNumber',
            value: this.state.contractNumber,
            class: 'l-box--short',
            isReadOnly: true,
            isRequired: true,
            regExpression: undefined,
            hasFocus: false,
            placeholder: '',
            editMode: true,
            autoComplete: 'contractNumber'
        };

        const userAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'username',
            label: 'LicenseReg:Username',
            value: this.state.username,
            class: 'l-box--short',
            isReadOnly: true,
            isRequired: true,
            regExpression: undefined,
            hasFocus: false,
            placeholder: '',
            editMode: true,
            autoComplete: 'username'
        };

        const emailAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'email',
            label: 'LicenseReg:Email',
            value: this.state.email,
            class: 'l-box--short',
            isReadOnly: true,
            isRequired: true,
            // tslint:disable-next-line:max-line-length
            regExpression: /^(([^<>()[\]\\.,;:\s@\""]+(\.[^<>()[\]\\.,;:\s@\""]+)*)|(\"".+\""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            hasFocus: false,
            placeholder: '',
            editMode: true,
            autoComplete: 'current-email'
        };
        return (
            <React.Fragment>
                <InputText
                    attributes={contractNumberAttributes}
                />
                <InputText
                    attributes={userAttributes}
                />
                <InputText
                    attributes={emailAttributes}
                />
                <SelfRegistration
                    parentHeadingLevel={2}
                    licenseKey={this.state.contractNumber}
                />
                <span className={'input-message error'}>
                    <Translate>{this.state.errorMessage}</Translate>
                </span>
            </React.Fragment>
        )

    }
}
