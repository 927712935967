import { JsonObject, JsonProperty } from "json2typescript";
import { ECheckoutEventCategory, ECheckoutEventSeverity } from "$src/storage/models/enums";

/** An event message that occured during the checkout process */
@JsonObject
export class CheckoutEventMessage {
    @JsonProperty('category', Number, false)
    public category: ECheckoutEventCategory = ECheckoutEventCategory.Registration; 
    
    @JsonProperty('severity', Number, false)
    public severity: ECheckoutEventSeverity = ECheckoutEventSeverity.Information;

    @JsonProperty('message', String, false)
    public message: string = '';
}