import { EGamificationPointOrigin } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class GamificationPointOriginConverter implements JsonCustomConvert<EGamificationPointOrigin> {  
    public serialize(lessonStatus: EGamificationPointOrigin): number {
        switch (lessonStatus) {         
            case EGamificationPointOrigin.SkillAquired: return 1;
            case EGamificationPointOrigin.RatingSubmitted: return 2;
            case EGamificationPointOrigin.LessonStatusChanged: return 3;            
            default: return 1;
        }
    }
    public deserialize(jsonValue: number): EGamificationPointOrigin {
        switch (jsonValue) {
         
            case 1: return EGamificationPointOrigin.SkillAquired;
            case 2: return EGamificationPointOrigin.RatingSubmitted;
            case 3: return EGamificationPointOrigin.LessonStatusChanged;         
            default: return EGamificationPointOrigin.Unknown;
        }
    }
}
