import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import UserPreferencesService from '$core/Services/UserPreferencesService';
import Session from '$core/Session';
import { ColorScheme } from '$storage/models/UserPreferences/ColorScheme';

interface IProps {
    colorSchemes: ColorScheme[];
    currentScheme: ColorScheme | undefined;
    onColorSchemeSelected?: (colorScheme: ColorScheme) => void;
    userPreferencesService: UserPreferencesService;
    parentHeadingLevel: number;
    isPopup: boolean;
    headingCssClassName: string;
    schemeHeadingCssName: string;
}

export class ColorSchemeSelection extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return <div>
            <div className={(!this.props.isPopup ? 'l-container ' : '') + 'color-scheme color-scheme__container'}>
                {!this.props.isPopup ? <MenuBreadCrumb {...this.props} /> : null}
                <div>
                    <Heading headingLevel={this.props.parentHeadingLevel + 1} cssClass={this.props.headingCssClassName}>
                        <Translate>ColorSchemeSelection:Title</Translate>
                    </Heading>
                </div>
                <p>
                    <Translate>ColorSchemeSelection:Description</Translate>
                </p>
                <div className={'l-box-container color-scheme__container__schemes'}>
                    {this.props.colorSchemes.map((scheme: ColorScheme, index) => {
                        return <button key={index}
                            type="button"
                            className="button-link l-box--short color-scheme__container__scheme"
                            style={scheme.previewImage !== '' ?
                                { backgroundColor: `${scheme.previewColor}`, backgroundImage: `url(${scheme.previewImage})` } :
                                { backgroundColor: `${scheme.previewColor}` }
                            }
                            onClick={() => this.onColorSchemeSelectedInternal(scheme)}
                            id={`btnColorScheme_${index}`}>
                            <div>
                                <Heading
                                    headingLevel={this.props.parentHeadingLevel + 2}
                                    cssClass={this.props.schemeHeadingCssName}>
                                    <Translate>{scheme.title}</Translate>
                                </Heading>
                                <Translate>{scheme.description}</Translate>
                            </div>
                        </button>
                    })}
                </div>
                {this.props.isPopup ?
                    <p>
                        <Translate>ColorSchemeSelection:Hint</Translate>
                    </p> : null}
            </div>
        </div>
    }
    
    public componentDidMount() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
    }

    protected onColorSchemeSelectedInternal(colorScheme: ColorScheme) {
        // Apply Color Scheme
        Session.instance.applyColorScheme(colorScheme);

        // Save choosen Scheme
        const up = Session.instance.loginUserPreferences;
        if (up != null) {
            up.preferences.scheme = colorScheme.name;
            Session.instance.setLoginUserPreferences(up);
            this.props.userPreferencesService.saveUserPreferences(up);
        }

        if (this.props.onColorSchemeSelected != null) {
            this.props.onColorSchemeSelected(colorScheme);
        }
    }
}
export default ColorSchemeSelection;