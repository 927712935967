import React, { useEffect, useState } from 'react';
import CollaborationProviderService from '$core/Services/CollaborationProviderService';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { isSuccess } from '$util/Result';
import { CollaborationProviderLinkResponse } from '$storage/models/CollaborationProvider/CollaborationProviderLinkResponse';
import { CollaborationProviderLink } from '$components/collaborationProvider/CollaborationProviderLink';

interface ICollaborationProviderClassLinkProps {
    itemId: number;
    classId: number;
    hasLink?: boolean;
}

export const CollaborationProviderClassLink: React.FC<ICollaborationProviderClassLinkProps> = (props: ICollaborationProviderClassLinkProps) => {

    const [hasLink, setHasLink] = useState<boolean>(false);
    const [link, setLink] = useState<CollaborationProviderLinkResponse>();

    useEffect(() => {
        const getLink = async (itemId: number, classId: number) => {
            const result = await CollaborationProviderService.instance.getClassLink(itemId, classId);
            console.log(result);
            if (isSuccess<CollaborationProviderLinkResponse>(result)) {
                setLink(result);
            }
        }
        const linkExists = async (itemId: number, classId: number) => {
            const result = await CollaborationProviderService.instance.hasClassLink(itemId, classId)
            if (isSuccess<BooleanResponse>(result)) {
                setHasLink(result.status);
                if(result.status) {
                    await getLink(itemId, classId);
                }
            }
        }
        if(props.hasLink) {
            setHasLink(props.hasLink);
            if(props.hasLink) {
                getLink(props.itemId, props.classId);
            }
        }
        else {
            linkExists(props.itemId, props.classId);
            getLink(props.itemId, props.classId);
        }
    }, []);

    return <>
        {hasLink &&
            link != null &&
            <CollaborationProviderLink
                link={link.channelLink}
                title="CollaborationProvider:OpenClassTeam" />
        }
    </>
}