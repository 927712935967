import Session from '$src/core/Session';
import { FileSharingLesson } from '$src/storage/models/FileSharing/FileSharingLesson';
import React, { useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import GTIconButton from '$components/shared/Atoms/GTIconButton';
import CancelIcon from '$resources/svgs/misc/cancel.svg';
import { Translate } from '$components/shared/Translate';
import FileSharingService from '$src/core/Services/FileSharingService';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import { isSuccess } from '$src/util/Result';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import GTAlertDialog from '$components/shared/Atoms/GTAlertDialog';

interface IProps {
    fileSharingLesson: FileSharingLesson;
    itemId: number;
    assignmentId: number;
    tPlanId: number;
    scheduleId: number;
    onCreateTag?: () => void;
}

export default function FileSharingTagManagement(props: IProps) {
    const [errorMessageTag, setErrorMessageTag] = useState<string>();
    const [createdTagName, setCreatedTagName] = useState<string>();
    const [tagToDelete, setTagToDelete] = useState<number>();



    async function onDeleteTag(tagId: number) {
        await FileSharingService.instance.deleteTag(props.itemId, { id: tagId }, props.assignmentId, props.tPlanId, props.scheduleId)
            .then(resp => {
                if (isSuccess<BooleanResponse>(resp)) {
                    setErrorMessageTag('');
                    if (!resp.status) {
                        setErrorMessageTag('FileSharing:FailedToDeleteTag');
                    }
                    if (props.onCreateTag && resp.status) {
                        props.onCreateTag();
                    }
                } else {
                    setErrorMessageTag('FileSharing:FailedToDeleteTag');
                }
                setTagToDelete(undefined);
            });
    }

    async function onKeyUp(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            const tagName = createdTagName ?? '';
            if(props.fileSharingLesson.availableFileSharingTags.find(tag => tag.tagName.toLocaleLowerCase() === tagName.toLocaleLowerCase())) {
                setErrorMessageTag('FileSharing:TagAlreadyExists');
            } else {
                await FileSharingService.instance.createTag(props.itemId, { tagName: tagName }, props.assignmentId, props.tPlanId, props.scheduleId)
                .then(resp => {
                    if (isSuccess<BooleanResponse>(resp)) {
                        setErrorMessageTag('');
                        if (!resp.status) {
                            setErrorMessageTag('FileSharing:FailedToCreateTag');
                        }
                        if (props.onCreateTag && resp.status) {
                            props.onCreateTag();
                        }
                    } else {
                        setErrorMessageTag('FileSharing:FailedToCreateTag');
                    }
                });
                setCreatedTagName('');
            }
        }
    }

    function renderTags(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        props.fileSharingLesson.availableFileSharingTags.map(tag => {
            elements.push(
                <li key={tag.id}>
                    {tag.tagName}
                    <GTIconButton
                        id={`DeleteTag_${tag.id}`}
                        ariaLabel={Session.instance.storage.translation.GetString('FileSharing:DeleteTag').Format(tag.tagName)}
                        tooltipText={Session.instance.storage.translation.GetString('FileSharing:DeleteTag').Format(tag.tagName)}
                        onClick={() => setTagToDelete(tag.id)}>
                        <InlineSVG src={CancelIcon} className="item-detail__file-sharing__tag-list__delete-icon" />
                    </GTIconButton>
                </li>
            )
        })

        return elements;
    }

    return (
        <div className="item-detail__file-sharing__tag-handling">
            <p><Translate>FileSharing:FileSharingHandlingInfo</Translate></p>
            <input type="text"
                placeholder={Session.instance.storage.translation.GetString('FileSharing:CreateTagPlaceholder')}
                onKeyUp={(e) => onKeyUp(e)}
                onChange={(e) => setCreatedTagName(e.target.value)}
                maxLength={globalConfig.fileSharingProperties.maxLengthForTag}
                className="input-field"
            />
            {errorMessageTag && <ErrorMessage errorMessage={errorMessageTag} />}
            <ul className="item-detail__file-sharing__tag-list">
                {renderTags()}
            </ul>
            {tagToDelete &&
                <GTAlertDialog
                    onResponse={(confirm) => confirm ? onDeleteTag(tagToDelete) : setTagToDelete(undefined)}
                    alertText={Session.instance.storage.translation.GetString('FileSharing:ConfirmDeleteText')
                        .Format(props.fileSharingLesson.availableFileSharingTags.filter(t => t.id === tagToDelete)[0]!.tagName)}
                />}
        </div>
    )
}