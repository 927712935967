import { JsonObject, JsonProperty } from 'json2typescript';

/** Payment Voucher */
@JsonObject
export class PaymentVoucherCode {

    /**Voucher Code Id */
    @JsonProperty('voucherCodeId', Number, false)
    public voucherCodeId: number = 0;

    /**Voucher Code */
    @JsonProperty('voucherCode', String, false)
    public voucherCode: string = "";

    /** usedBy if it is null the voucher was not used yet */
    @JsonProperty('usedBy', String, false)
    public usedBy: string = '';

    /** usedAt when was the voucher used */
    @JsonProperty('usedAt', String, false)
    public usedAt: string = '';

    /** if the Voucher is Revoked*/
    @JsonProperty('isRevoked', Boolean, false)
    public isRevoked: boolean = false;
}