import React from "react";
import { EItemType, ELessonStatus } from "$src/storage/models/enums";
import Passed from '$resources/svgs/status/lesson/passed.svg'
import Failed from '$resources/svgs/status/lesson/failed.svg'
import Completed from '$resources/svgs/status/lesson/completed.svg'
import Incomplete from '$resources/svgs/status/lesson/incomplete.svg'
import NotAttempted from '$resources/svgs/status/lesson/not-attempted.svg'
import Session from "$src/core/Session";
import IconAndText from "$src/components/shared/Atoms/IconAndText";

/**
 * The GT Item Status Icon Props
 */
export interface IItemStatusIconProps {

    /**
     * Component unique id
     */
    id: string;

    /**
     * Item status of the user
     */
    itemStatus: ELessonStatus;

    /**
     * Item type
     */
    itemType: EItemType;

    /**
     * Item title
     */
    itemTitle: string;

    /**
     * **Optional** If the tool tip should be displayed. 
     */
    showToolTipText?: boolean;

}


/**
 * Global Teach Item Status Icon Component
 */
export default function ItemStatusIcon(props: IItemStatusIconProps) {
    const tr = Session.instance.storage.translation;
    let statusIcon = null;
    let tooltipText = '';
    let ariaLabel = '';
    if (props.itemType === EItemType.TrainingPlan) {
        ariaLabel = tr.GetString('ItemDetail:LessonTPlanStatus') + ' ' + props.itemTitle + ': ';
    } else {
        ariaLabel = tr.GetString('ItemDetail:LessonStatus') + ' ' + props.itemTitle + ': ';
    }

    switch (props.itemStatus) {
        case ELessonStatus.Undefined:
            return null;
        case ELessonStatus.Passed:
            statusIcon = Passed;
            tooltipText = tr.GetString('LessonStatus:Passed');
            break;
        case ELessonStatus.Failed:
            statusIcon = Failed;
            tooltipText = tr.GetString('LessonStatus:Failed');
            break;
        case ELessonStatus.Completed:
            statusIcon = Completed;
            tooltipText = tr.GetString('LessonStatus:Completed');
            break;
        case ELessonStatus.Incomplete:
            statusIcon = Incomplete;
            tooltipText = tr.GetString('LessonStatus:Incomplete');
            break;
        case ELessonStatus.NotAttempted:
            statusIcon = NotAttempted;
            tooltipText = tr.GetString('LessonStatus:NotAttempted');
            break;
        default:
            return null;
    }

    ariaLabel += tooltipText;

    return (
        <IconAndText
            id={`ItemStatus_${props.id}`}
            ariaLabel={ariaLabel}
            tooltipText={props.showToolTipText ? tooltipText : undefined}
            icon={statusIcon}
            iconHeight={24}
            iconWidth={24} />
    );
}