import React from 'react';

import Session from '$core/Session';
import { Assignments } from '$src/components/assignment/Assignments'
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import Logger from '$src/core/Logger';
import { AssignedItem } from '$src/storage/models/AssignedItem';
import { isSuccess } from '$src/util/Result';

export interface IAllAssignmentsProps {
    title: string;
    maxItemsToDisplay: number;
    filterExpression: (a: AssignedItem) => boolean;
    showSortOrderButton?: boolean;
}

interface IState {
    dataLoaded: boolean;
    assignments: AssignedItem[] | null;
}

export class AllAssignments extends React.Component<IAllAssignmentsProps, IState> {

    protected className = 'AllAssignments';
    protected loggerLocality = 'Components.AllAssignments';

    constructor(props: IAllAssignmentsProps) {
        super(props);
        this.state = {
            assignments: null,
            dataLoaded: false
        };
    }

    public render() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString(this.props.title);

        if (this.state.dataLoaded === false) {
            this.getAssignments();
            return <ProgressSpinner />;
        }
        return <div className="all-assignments">
            <div className="l-container">
                <h1 className="heading__Title">
                    <Translate>{this.props.title}</Translate>
                </h1>
                <Assignments expandInline={false}
                    {...this.props}
                    parentHeadingLevel={1} />
            </div>
        </div>;

    }

    protected async getAssignments(): Promise<void> {
        let response: AssignedItem[] | null;
        await Session.instance.storage.assignment.getObjects();
        response = Session.instance.storage.assignment.getObjectsDistinct();
        if (isSuccess<AssignedItem[] | null>(response)) {
            if (response != null) {
                response = response.filter(a => {
                    if (this.props.filterExpression != null) {
                        return this.props.filterExpression(a);
                    }
                    else {
                        return true;
                    }
                });
                Session.instance.storage.assignment.sort(response);
                Logger.log(this.loggerLocality, this.className + ' Added assignments to state. Length: ' + response.length.toString());
                this.setState({
                    assignments: response,
                    dataLoaded: true
                });
            }
        }
    }
}
export default AllAssignments;