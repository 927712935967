import { JsonObject, JsonProperty } from 'json2typescript';
import { FavoriteTypeConverter } from './converters/FavoriteTypeConverter';
import { EFavoriteType } from './enums';

@JsonObject
export class Favorite {
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('objectId', Number, false)
    public objectId: number = 0;

    @JsonProperty('type', FavoriteTypeConverter, false)
    public type: EFavoriteType = EFavoriteType.Item;

    @JsonProperty('title', String, false)
    public title: string = '';
    
    @JsonProperty('parentId', Number, true)
    public parentId: number = 0;
}