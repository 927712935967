import { InputAttributes } from '$src/components/shared/InputAttributes';
import { InputTextArea } from '$src/components/shared/InputTextArea';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import React from 'react';

interface IState {
    comment: string;
}

interface IProps {
    comment: string;
    onCommentChanged: (comment: string) => void;
    onClose: () => void;
}

export class CommentDialog extends React.Component<IProps, IState> {

    private commentAttributes: InputAttributes ;            


    constructor(props: IProps) {
        super(props);
        this.state ={comment: props.comment};    
        this.Save = this.Save.bind(this);
        this.Cancel = this.Cancel.bind(this);
        this.onInputChanged = this.onInputChanged.bind(this);

        this.commentAttributes =
            {
                attributeValueType: undefined,
                class: 'todo-Input80',
                editMode: false,
                hasFocus: true,
                id:'searchText',
                initialValidation: false,    
                isReadOnly: false,
                isRequired: false,  
                label:'',
                placeholder: Session.instance.storage.translation.GetString('Common:CommentLabel'),
                regExpression: undefined,
                rows: 5,    
                url: undefined,
                value: this.state.comment,
            };            

    }
    

    public render() {
        return (
            <div className="todo-container-column--window">
                <div className="todo-container-row">
                <div className="todo-label"><Translate>Common:CommentLabel</Translate>: </div>
                    <InputTextArea  attributes={this.commentAttributes}   onChange={this.onInputChanged}/>
                </div>

                <div className="todo-container-row popup__buttons">
                <button  type="button" id="btnSave" className="btn btn--md btn--primary" onClick={this.Save}><Translate>Button:Save</Translate></button>
                <button  type="button" id="btnCancel" className="btn btn--md" onClick={this.Cancel}><Translate>Button:Cancel</Translate></button>
                </div>
            </div>
        );
        
    } 

    private onInputChanged(id: string, value: string) {
        this.setState({ comment: value });
    }
    private Save()
    {
        this.props.onCommentChanged(this.state.comment);
        this.props.onClose();
    }

    private Cancel()
    {
        this.props.onClose();
    }

}   
