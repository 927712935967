import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class RemoveSkillProfileRequest {

    @JsonProperty('userIds', [Number], false)
    public userIds: number[] = [];

    @JsonProperty('skillProfileIds', [Number], false)
    public skillProfileIds: number[] = [];

}