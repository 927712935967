
import ServiceClient from '$src/core/ServiceClient';
import GtError from '$util/GtError';

import { UserSubjectSubscriptions } from '$src/storage/models/UserSubjectSubscriptions/UserSubjectSubscriptions';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ItemWithSubscription } from '$src/storage/models/ItemWithSubscription';

/**
 * UserSubjectSubscriptionService provides all Service Methods for the Subscriptions on subject of the user 
 */
export default class UserSubjectSubscriptionService extends ServiceClient {

    protected static _instance: UserSubjectSubscriptionService | null = null;

    protected className = 'UserSubjectSubscriptionService';
    protected loggerLocality = 'UserSubjectSubscriptionService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): UserSubjectSubscriptionService {
        return this._instance || (this._instance = new this());
    }


    /**
     * Gets all the subjects the current user is subscribed to
     */
    public async getSubjectSubscriptionsFromUser(): Promise<UserSubjectSubscriptions | GtError> {
        const response: UserSubjectSubscriptions | GtError = await this.get<UserSubjectSubscriptions>('userSubjectSubscription', UserSubjectSubscriptions, undefined, undefined);
        return response;
    }

    /**
    * Gets all lesson titles
    */
    public async getLessonsTitleStrings(subjectId: number): Promise<string[] | GtError> {
        const response: string[] | GtError = await this.get<string[]>(
            'userSubjectSubscription/getLessonsTitleStrings',
            String,
            undefined,
            [],
            subjectId.toString()
        );
        return response;
    }

    /**
     * Gets all the Lessons of a certain Subject the User is subscribed to
     * @param subjectId : ID of the selected Subject from which the lessons should be loaded
     * @param pageNumber : Paging Number
     * @param pageSize : Amount of Lessons to be loaded for Paging
     */
    public async getItemsOfSubscribedSubjectFromUser(subjectId: number, pageNumber: number, pageSize: number, titleToFilter: string): Promise<ItemWithSubscription[] | GtError> {
        return await this.get<ItemWithSubscription[]>('userSubjectSubscription/getLessonsFromSubscribedSubject',
        ItemWithSubscription,
            undefined,
            [{ name: 'language', value: Session.instance.getUserLanguageCodeOrFallBack },
            {
                name: 'titleToFilter', value: titleToFilter
            }],
            subjectId.toString(),
            pageNumber.toString(),
            pageSize.toString()
        );
    }

    /**
     * Adds new subject to the Subscriptions of the User
     * @param subjectIdToBeAdded : Id of the subject to be added to the users Subscriptions List
     */
    public async addSubjectSubscriptionsFromUser(subjectIdToBeAdded: number): Promise<BooleanResponse | GtError> {
        const response = await this.post<BooleanResponse>(`userSubjectSubscription/insert/${subjectIdToBeAdded}`, {}, BooleanResponse);
        return response;
    }

    /**
     * Deletes given Subjects from the Users Subscriptions List
     * @param subscriptionsToDelete : Subscribtions to be deleted
     */
    public async deleteSubjectSubscriptionsFromUser(subjectIdToBeDeleted: number): Promise<BooleanResponse | GtError> {
        const response = await this.post<BooleanResponse>(`userSubjectSubscription/delete/${subjectIdToBeDeleted}`, {}, BooleanResponse);
        return response;
    }
}