import { CommentDialog } from '$src/components/shared/CommentDialog/CommentDialog';
import { OpenDialogLink } from '$src/components/shared/OpenDialogLink';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import Session from '$src/core/Session';
import React from 'react';

interface IProps {
    comment: string;
    onCommentChanged: (comment: string) => void;
}

interface IState {
    shouldShowModal: boolean;
    comment: string;
}


export class CommentDialogModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state ={comment: props.comment, shouldShowModal: false};    
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.commentChangedHandler = this.commentChangedHandler.bind(this);
    }
    

    public render() {
            const commentTitle =this.state.comment === '' ?
                Session.instance.storage.translation.GetString('Common:CommentLink') :
                Session.instance.storage.translation.GetString('Common:CommentEditLabel')
                return (
                    <div>
                        <OpenDialogLink ariaPopupvalue={"dialog"}  id="commentDialog" title={commentTitle} openDialog={this.openModal} />
                        <ModalPopup key={'Modal_'} 
                                isOpen={this.state.shouldShowModal}
                                onRequestClose={this.closeModal}
                                >
                            <CommentDialog comment={this.props.comment} onClose={this.closeModal} onCommentChanged={this.commentChangedHandler}/>
                        </ModalPopup>
                    </div>
                );                
    } 

    private closeModal(): void {
        this.setState({ shouldShowModal: false }); 
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true }); 
    }

    private commentChangedHandler(changedComment: string)
    {
        this.setState({ comment: changedComment});
        this.props.onCommentChanged(changedComment);
    }
}
