import { EBreakPoint } from "$src/storage/models/enums";
import { BrowserHelper } from "./BrowserHelper";

const loadCliplister = (callback: any) => {
    const existingScript = document.getElementById('cliplister');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://mycliplister.com/static/clplayer/cllib.inc.min.js';
        script.id = 'cliplister';
        document.head.appendChild(script);
        script.onload = () => {
            if (callback) callback();
        };
    }
    if (existingScript && callback) callback();
};
export const playVideo = (elementId: string, callback: any, requestkey: string) => {
    const vvideoStartupScriptElementIdPrefix: string = 'cliplister'
    const videoStartupScriptElementId = vvideoStartupScriptElementIdPrefix + elementId;
    const existingScripts = document.querySelectorAll('[id^="' + videoStartupScriptElementId + '"]');
    if (existingScripts != null) { existingScripts.forEach(s => {s.remove();}) }
    const existingVideos = document.getElementById(elementId);
    if(existingVideos != null) { existingVideos.innerHTML = ""; } // Clean existing videos from container
    const videoStartupScript = document.createElement('script');
    videoStartupScript.id = videoStartupScriptElementId;
    const currentBrowserBreakPointSize = BrowserHelper.getCurrentBreakPoint();
    let videoSize = '';
    switch (currentBrowserBreakPointSize) {
        case EBreakPoint.Tiny:
            videoSize = "450x300";
            break;
        case EBreakPoint.Small:
            videoSize = "450x300";
            break;
        case EBreakPoint.Medium:
            videoSize = "656x400";
            break;
        case EBreakPoint.Large:
            videoSize = "928x500";
            break;
        case EBreakPoint.ExtraLarge:
            videoSize = "1056x636";
            break;
    }
    videoStartupScript.innerHTML = "window.Cliplister.clips.length = 0; window.Cliplister.player({elementID: '" + elementId + "',requestkey:'" + requestkey + "',keytype:10000,size:'" + videoSize + "',fsk:16,lang:'de'},157893); window.Cliplister.reloadClips()";

    document.body.appendChild(videoStartupScript);
    videoStartupScript.onload = () => {
        if (callback) callback();
    };
}
export default loadCliplister;