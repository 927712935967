import * as React from 'react';

import { Translate } from '$src/components/shared/Translate';
import ItemRatingService from '$src/core/Services/ItemRatingService';
import { Item } from '$src/storage/models/Item';
import { Input, Rating, TextArea } from '@progress/kendo-react-inputs';
import Session from '$src/core/Session';
import { FieldWrapper } from '@progress/kendo-react-form/dist/npm/FieldWrapper';
import { Label } from '@progress/kendo-react-labels';
import DateHelper from '$src/util/DateHelper';


interface IProps {
    item: Item;
    onCancle?: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    onSave?: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    allowCancel?: boolean;
}

interface IState {
    errorMessage: string;
    ratingTitle: string;
    ratingComment: string;
    ratingStars: number;
    ratingSaved: boolean;
    disableSaveButton: boolean;
    ratingCommentLength: number;
    ratingTitleLength: number;
}

export class ApplyRating extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            disableSaveButton: false,
            errorMessage: '',
            ratingTitle: '',
            ratingComment: '',
            ratingStars: 0,
            ratingSaved: false,
            ratingCommentLength: 0,
            ratingTitleLength: 0,
        }
    }

    public render() {
        return (this.renderApplyRating());
    }

    protected ratingComment(readonly: boolean): JSX.Element {
        const maxTitleLength = 50;
        const maxCommentLength = 500;
        return <>
            <Input
                onChange={(e) => { this.onTitleChange(e.value) }}
                maxLength={maxTitleLength}
                readOnly={readonly}
                className="input-field"
                label={Session.instance.storage.translation.GetString('Rating:Title')}
            />{this.state.ratingTitleLength.toString() + '/' + maxTitleLength}
            <FieldWrapper>
                <Label editorId={"RatingComment"} editorValid={true} editorDisabled={false}>
                    {Session.instance.storage.translation.GetString('Rating:Comment')}
                </Label>
                <TextArea
                    id="RatingComment"
                    onChange={(e) => { this.onCommentChange(e.value.toString(), maxCommentLength) }}
                    className="input-field"
                    autoSize={true}
                    {...{ maxlength: maxCommentLength }}
                />
                {this.state.ratingCommentLength.toString() + '/' + maxCommentLength}
            </FieldWrapper>
        </>
    }

    protected renderApplyRating = (): JSX.Element | null => {
        return (<>
            <div className="apply-rating__container">
                {
                    globalConfig.ratingProperties.commentsEnabled && <div className="apply-rating__container__title-and-text">
                        <Translate>{'Rating:RateNow'}</Translate>
                        {this.ratingComment(this.props.item.ratingMine > 0 || !globalConfig.ratingProperties.commentsEnabled)}
                    </div>
                }
                <div className="apply-rating__container__autor-and-stars">
                    <div className="autor-and-stars__stars">
                        <Rating
                            value={this.props.item.ratingMine}
                            disabled={this.props.item.ratingMine > 0}
                            onChange={(e) => this.setState({ ratingStars: e.value })} />
                    </div>
                    <div
                        className="autor-and-stars__autor">
                        {Session.instance.loginUser?.fullName}
                    </div>
                </div>

                <div className="single-rating__container__date">
                    <div className="apply-rating__container__date">
                        {DateHelper.toDateString(new Date())}
                    </div>
                </div>
            </div>
            <div className="apply-rating__container__actions">
                <div className="modal__spread-buttons">
                    <button type="button"
                        className="btn--sm btn--primary"
                        disabled={this.state.disableSaveButton || (this.state.ratingStars === 0)}
                        onClick={(e) => this.saveMyRating(e)}>
                        <Translate>{'Button:Save'}</Translate>
                    </button>
                    {(this.props.allowCancel == null || this.props.allowCancel) && <button type="button" className="btn--sm btn--secondary" onClick={(event) => this.cancleMyRating(event)}>
                        <Translate>{'Button:Cancel'}</Translate>
                    </button>
                    }
                </div>
            </div>

        </>)
    }


    private cancleMyRating(event: React.MouseEvent<Element>) {
        this.setState({ ratingComment: '', ratingStars: 0 });
        if (this.props.onCancle) {
            this.props.onCancle(event);
        }
    }

    private onCommentChange(value: string, maxLength: number) {
        this.setState({ ratingComment: value.substring(0, maxLength), ratingCommentLength: value.substring(0, maxLength).length });
    }

    private onTitleChange(value: string) {
        this.setState({ ratingTitle: value, ratingTitleLength: value.length })
    }

    private async saveMyRating(e: React.MouseEvent<Element>) {
        if (this.props.onSave) {
            this.setState({ disableSaveButton: true })
            this.props.onSave(e);
        }
        await ItemRatingService.instance.saveItemRating({
            title: this.state.ratingTitle,
            comment: this.state.ratingComment,
            itemId: this.props.item.itemId,
            rating: this.state.ratingStars * 2,
        });
        window.location.reload();
    }
}