import React from 'react';
import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { GamificationPointHistoryDetail } from '$components/gamification/GamificationPointHistoryDetail';
import { GamificationPointHistoryOverview } from '$components/gamification/GamificationPointHistoryOverview';
import Session from '$src/core/Session';

export const GamificationPointHistoryPage: React.FC = () => {

    const tr = Session.instance.storage.translation;
    
    return (
        <div className="gamification-history">
            <div className="l-container title">
                <MenuBreadCrumb />
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>GamificationPointHistory:Title</Translate>
                </Heading>
                <div>
                    <Translate>GamificationPointHistory:SubTitle</Translate>
                </div>
            </div>

            <div className="l-container">
                <GamificationPointHistoryOverview />
            </div>

            <div className="l-container title">
                <Heading headingLevel={2} cssClass="heading__Title">
                    <Translate>GamificationPointHistory:TitleDetails</Translate>
                </Heading>
                <div>
                    <Translate>GamificationPointHistory:SubTitleDetails</Translate>
                </div>
            </div>

            <div className="l-container">
                <GamificationPointHistoryDetail translationStorage={tr} />
            </div>
        </div>
    )

}
