/**
 * Simple Logger for diagnostics output during development.
 * The logging output is controlled with locality strings and is disabled by default.
 * To enable output, insert the concerning locality string in globalConfig.loggerProperties.localities.
 * Localities are hierarchical with '.' as separator, i.e. 'parent.child' is enabled if 'parent' is configured in globalConfig.
 */
export default class Logger {
    /**
     * Check whether given locality is enabled in globalConfig.
     * @param locality 
     */
    protected static isLocalityEnabled(locality: string): boolean {
            // build array with all localities to check, i.e. from 'parent.child' build ['parent', 'parent.child']
            const localityParts = locality.split('.');
            const localitiesToCheck: string[] = [];
            for (const part of localityParts) {
                if (localitiesToCheck.length === 0) {
                    localitiesToCheck.push(part);
                } else {
                    localitiesToCheck.push(localitiesToCheck[localitiesToCheck.length - 1] + '.' + part);
                }
            }

            // check whether one of the above localitiesToCheck is configured in globalConfig.loggerProperties.localities
            for (const enabledLocality of globalConfig.loggerProperties.localities) {
                for (const localityToCheck of localitiesToCheck) {
                    if (localityToCheck.toUpperCase() === enabledLocality.toUpperCase()) {
                        return true;
                    }
                }
            }
            return false;
    }

    /**
     * Write the remainingParams to the console if the locality is enabled.
     * @param locality 
     * @param remainingParams 
     */
    public static log(locality: string, ...remainingParams: unknown[]) {
        try {
             if (this.isLocalityEnabled(locality)) {
                console.log(`[${locality}]`, ...remainingParams);
             }
        }
        catch {
            console.debug(`Out put disabled for locality: ${locality}`);
        }
    }
}