import { JsonObject, JsonProperty } from 'json2typescript';
import { Attribute } from '../Attribute';
import { AttendedStatusConverter } from '../converters/AttendedStatusConverter';
import { DateTimeConverter } from '../converters/DateTimeConverter';
import { LessonStatusConverter } from '../converters/LessonStatusConverter';
import { RegistrationStatusConverter } from '../converters/RegistrationStatusConverter';
import { EAttendanceStatus, ELessonStatus, ERegistrationStatus } from '../enums';
import { UserCertificateViewModel } from '../Certificates/UserCertificateViewModel';
import { ExternalCourse } from './ExternalCourse';
import { ExternalCourseProvider } from './ExternalCourseProvider';
import { ExternalCourseSchedule } from './ExternalCourseSchedule';

@JsonObject
export class ExternalCourseRegistration {

    @JsonProperty('registrationId', Number, false)
    public registrationId: number = 0;

    @JsonProperty('registeredUserId', Number, false)
    public registeredUserId: number = 0;

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('guid', String, false)
    public guid: string = '';

    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;

    @JsonProperty('attendedStatus', AttendedStatusConverter, false)
    public attendedStatus: EAttendanceStatus = EAttendanceStatus.Undefined;

    @JsonProperty('externalRegistrationDone', Boolean, false)
    public externalRegistrationDone: boolean = false;

    @JsonProperty('cancellationReason', String, false)
    public cancellationReason: string = '';

    @JsonProperty('cancellationReasonId', Number, false)
    public cancellationReasonId: number = 0;

    @JsonProperty('cancellationPeriod', Number, false)
    public cancellationPeriod: number = 0;

    @JsonProperty('lessonStatus', LessonStatusConverter, false)
    public lessonStatus: ELessonStatus = ELessonStatus.NotAttempted;

    @JsonProperty('lessonScore', Number, false)
    public lessonScore: number = 0;

    @JsonProperty('lessonStatusTimeEnd', DateTimeConverter, false)
    public lessonStatusTimeEnd: Date | undefined = undefined;

    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('externalCourseClass', ExternalCourseSchedule, false)
    public externalCourseClass: ExternalCourseSchedule | undefined = undefined;

    @JsonProperty('externalCourseId', Number, false)
    public externalCourseId: number = 0;

    @JsonProperty('externalCourse', ExternalCourse, false)
    public externalCourse: ExternalCourse | undefined = undefined;
    
    @JsonProperty('externalCourseProviderId', Number, false)
    public externalCourseProviderId: number = 0;

    @JsonProperty('externalCourseProvider', ExternalCourseProvider, false)
    public externalCourseProvider: ExternalCourseProvider | undefined = undefined;

    @JsonProperty('attributes', [Attribute], false)
    public attributes: Attribute[] = [];

    @JsonProperty('certificates', [UserCertificateViewModel], false)
    public certificates: UserCertificateViewModel[] = [];

    @JsonProperty('registeredUserFullName', String, false)
    public registeredUserFullName: string = '';
}
