import Logger from '$src/core/Logger';
import TrainingPlanService from '$src/core/Services/TrainingPlanService';
import Session from '$src/core/Session';
import SessionCache from '$src/storage/cache/SessionCache';
import { isSuccess } from '$src/util/Result';
import { EItemDetailCallerContextType } from './models/enums';
import { TrainingPlanSchedule } from './models/TrainingPlan/TrainingPlanSchedule';

const CACHING_DURATION = 300;

export default class TrainingPlanScheduleStorage extends SessionCache<string, TrainingPlanSchedule[]> {
    protected className = 'TrainingPlanElementStorage';
    protected loggerLocality = 'Storage.TrainingPlanElementStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getTrainingPlanSchedules(tplanId: number, itemContext: EItemDetailCallerContextType, ignoreRegisterRight = false, forceLoadingFromServer = true): Promise<TrainingPlanSchedule[] | null> {
        const methodName = `${this.className}:TrainingPlanSchedules()`;

        if (this.isObjectInCache(tplanId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack) && !forceLoadingFromServer) {
            Logger.log(this.loggerLocality, `${methodName} getting TrainingPlanSchedules from cache, tplanId=${tplanId}_${Session.instance.getUserLanguageCodeOrFallBack}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting TrainingPlanSchedules from server, tplanId=${tplanId}_${Session.instance.getUserLanguageCodeOrFallBack}`);
            const response = await TrainingPlanService.instance.getTrainingPlanSchedules(Session.instance.getUserLanguageCodeOrFallBack, tplanId, itemContext, ignoreRegisterRight);
            if (isSuccess<TrainingPlanSchedule[]>(response)) {
                this.saveObjectToCache(tplanId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack, response);
            } else {
                // tslint:disable-next-line:max-line-length
                Logger.log(this.loggerLocality, `${methodName} failed to get TrainingPlanSchedules from server, tplanId=${tplanId}_${Session.instance.getUserLanguageCodeOrFallBack}.`);
            }
        }
        return this.getObjectFromCache(tplanId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack);
    }

    public removeItemFromCache(itemId: number): void {
        super.removeObjectFromCache(itemId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack);
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}