// import React from "react";
import Logger from '$core/Logger';
import LessonService from '$core/Services/ItemService';
import { __gt_startCbt, __gt_startWbt } from '$src/boot';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { WbtStartParameters } from '$src/storage/models/WbtStartParameters';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';

/**
 * Start WBT
 * @param methodName Name for the logger
 * @param isItemLocked Flag if item is locked
 * @param itemId Id of item
 * @param itemTitle Title of item
 * @param parentAssignmentId Assignment Id if item is an assignment
 * @param parentTrainingPlanId Id of training plan if the wbt is part of a trainig plan
 * @param trainingPlanScheduleId Schedule id of the training plan
 * @param loggerLocality Locality of the logger
 */
export async function startWBT(
    methodName: string,
    isItemLocked: boolean,
    itemId: number,
    itemTitle: string,
    itemContext: EItemDetailCallerContextType,
    parentAssignmentId: number,
    parentTrainingPlanId: number,
    trainingPlanScheduleId: number,
    loggerLocality: string) {

    if (!isItemLocked) {
        const wbtWindow = window.open('', 'nsui_wbt');

        // WINDOW.OPEN MUST BE CALLED DIRECTLY IN THIS FUNCTION OTHERWISE POPUPBLOCKER WILL BLOCK
        // WE need to get the wbt start Paraemters to open the window correctly
        const response: WbtStartParameters | GtError = await LessonService.instance.getWbtStartParameters(itemId,
            itemContext,
            parentAssignmentId,
            parentTrainingPlanId,
            trainingPlanScheduleId);
        if (isSuccess<WbtStartParameters>(response)) {
            const wbtStartParameters = response;
            const startUrl = wbtStartParameters.startUrl;
            // const windowParams = wbtStartParameters.windowParameters;
            // TODO load before to use windowsParams
            Logger.log(loggerLocality, `${methodName} starting wbt ${itemId} 
                - ${itemTitle}
                assignmentId: ${parentAssignmentId}, tpid: ${parentTrainingPlanId}, 
                tpScheduleId: ${trainingPlanScheduleId}`);
            Logger.log(loggerLocality, `opening WBT lesson ${wbtStartParameters.lessonSId} using URL ${startUrl}`);
            if (wbtWindow != null) {
                wbtWindow.location.href = startUrl;
                __gt_startWbt(wbtWindow);
            }
            else {
                const errorMessage = `${methodName} could not open window for wbt ${itemId} 
                - ${itemTitle}
                assignmentId: ${parentAssignmentId}, tpid: ${parentTrainingPlanId}, 
                tpScheduleId: ${trainingPlanScheduleId}`;

                Logger.log(loggerLocality, errorMessage);
                console.error(errorMessage)
                // TODO - We should show an error
            }
        }
    }
}
/**
 * Start CBT
 * @param methodName Name for the logger
 * @param isItemLocked Flag if item is locked
 * @param itemId Id of item
 * @param itemTitle Title of item
 * @param parentAssignmentId Assignment Id if item is an assignment
 * @param parentTrainingPlanId Id of training plan if the cbt is part of a trainig plan
 * @param trainingPlanScheduleId Schedule id of the training plan
 * @param loggerLocality Locality of the logger
 */

export function startCbt(
    methodName: string,
    isItemLocked: boolean,
    itemId: number,
    itemTitle: string,
    itemContext: EItemDetailCallerContextType,
    parentAssignmentId: number,
    parentTrainingPlanId: number,
    trainingPlanScheduleId: number,
    loggerLocality: string) {
    if (!isItemLocked) {
        Logger.log(loggerLocality, `${methodName} starting cbt ${itemId} - ${itemTitle}
        assignmentId: ${parentAssignmentId}, tpid: ${parentTrainingPlanId}, tpScheduleId: ${trainingPlanScheduleId}`);
        __gt_startCbt(itemId, 1, itemContext, parentAssignmentId, parentTrainingPlanId, trainingPlanScheduleId);
    }
}