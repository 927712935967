import * as React from 'react';
import Survey001 from '$resources/svgs/reports/Survey001.svg';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$src/components/shared/Tooltip';
import { QuestionnaireUrls } from '$src/storage/models/Trainer/TrainerBookingsResponse';
import { NavigationUtil } from '$src/util/NavigationUtil';
import Session from '$src/core/Session';


interface IProps{
    id: number;
    title: string;
    open: boolean;
    questionnaires: QuestionnaireUrls[];
} 

function FeedbackRow(props: IProps){   
    function onNavigate(event: React.MouseEvent<Element>, url: string,doAUILogin: boolean, doLMSSUILogin: boolean) {
        if (doLMSSUILogin) {
            const win = window.open('about:blank', '_blank');
            event.preventDefault();
            if (win != null) {
                NavigationUtil.loginSui(url, win);
            }
        }
        else if (doAUILogin) {
            const win = window.open('about:blank', '_blank');
            event.preventDefault();
            if (win != null) {
                NavigationUtil.loginAui(win);
            }
        }
    }

    const questionnaires = props.questionnaires.map((questionnaire, i) => {
        const txt = Session.instance.storage.translation.GetString('TrainerBookingView:results') + ` "${questionnaire.title}"`;
        return ( <li key={`Feedback_${i}_On_${props.id}`}>
                    <button
                        onClick={(event) => onNavigate(event, questionnaire.url, false, true)}
                        className="icon__button"
                        aria-label={props.title}
                        data-tip={props.title}
                        data-for={`Survey_${props.id}`}> 
                        <InlineSVG src={Survey001} />
                        <Tooltip id={`Survey_${props.id}`} />
                        <span>{txt}</span>
                    </button>
                </li> );
    });

    const open = props.open;

    return(
        (open && props.questionnaires.length > 0) ? 
            <React.Fragment>
                <ul className="feedbackrow_list--style">
                    {questionnaires}
                </ul>
            </React.Fragment>
        :
            null
    );

}



export default FeedbackRow;