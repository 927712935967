import React from 'react';
import { ItemRating } from '$src/storage/models/ItemDetails/ItemRating';
import { Rating } from '@progress/kendo-react-inputs';
import { StringHelper } from '$src/util/StringHelper';
import GtConfirmButton from '$components/shared/Atoms/GtConfirmButton';
import { Translate } from '$components/shared/Translate';
import ItemRatingService from '$src/core/Services/ItemRatingService';
interface IProps {
    itemRating: ItemRating;
    onDeleted?: (itemRatingId: number) => void;
}

/**
 * Component that renders one rating
 */
export default function SingleRating(props: IProps) {

    const onDeletedInternal = async (itemRatingId: number, onDeleted?: (itemRatingId: number) => void) => {
        if (onDeleted) {
            onDeleted(itemRatingId);
            await ItemRatingService.instance.deleteItemRating(itemRatingId);
            window.location.reload()
        }
    }

    return (
        <div className={`single-rating__container ${props.itemRating.isOwnRating ? 'single-rating__container--own' : ''}`}>

            {
                globalConfig.ratingProperties.commentsEnabled && <div className="single-rating__container__title-and-text">
                    <div
                        className="title-and-text__title">
                        {props.itemRating.title}
                    </div>
                    <div
                        className="title-and-text__comment">
                        {props.itemRating.comment}
                    </div>
                </div>
            }
            <div className="single-rating__container__autor-and-stars">
                <div className="autor-and-stars__stars">
                    <Rating
                        value={props.itemRating.rating / 2}
                        disabled={true} />
                </div>
                <div
                    className="autor-and-stars__autor">
                    {props.itemRating.userFirstName} {props.itemRating.userLastName}
                </div>
            </div>
            <div className="single-rating__container__date-and-actions">
                <div
                    className="date-and-actions__date">
                    {StringHelper.dateString(props.itemRating.ratingDate)}
                </div>
                <div
                    className="date-and-actions__actions">
                    <div
                        className="date-and-actions__actions__delete">
                        {props.itemRating.allowDeletion &&
                            <GtConfirmButton
                                onClick={() => onDeletedInternal(props.itemRating.id, props.onDeleted)}
                                additionalClassNames="button-link"
                                isPrimary={false}>
                                <Translate>{'Rating:Delete'}</Translate>
                            </GtConfirmButton>}
                    </div>
                </div>
            </div>
        </div>
    )
}