import React from 'react';

import ToolbarItemType from '$src/components/item/Toolbar/ToolbarItemType';
import { ToolbarStatus } from '$src/components/item/Toolbar/ToolbarStatus';
import { EItemType } from '$src/storage/models/enums';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import { ToolbarTrainingPlanElementDuration } from './ToolbarTrainingPlanElementDuration';
import ToolbarTrainingPlanModul from './ToolbarTrainingPlanModul';
import IconModule from '$resources/svgs/lessonTypes/module.svg';

interface IProps {
    trainingPlanElement: TrainingPlanElement;
    showTPlanScore?: boolean;
    allowSelection: boolean;
}

/**
 * @deprecated The method should not be used
 */
export default function ToolbarTrainingPlanElement(props: IProps) {

    const item = props.trainingPlanElement;

    return (
        <div className="l-box__toolbar lesson__toolbar">
            {item.type === EItemType.Block &&
                <ToolbarTrainingPlanModul 
                trainingPlanElement={item} 
                icon={IconModule}
                className="l-box__toolbar__icon lesson-tools lesson-tools--duration" />}
            {item.type !== EItemType.Block &&
                <ToolbarItemType item={item} textVisible={false} />}
            <ToolbarStatus
                reason={(item.lockedReason != null && item.lockedReason !== '' ? 'TrainingPlan:' + item.lockedReason : '')}
                reasonDetail={(item.lockedReasonDetail != null && item.lockedReasonDetail !== '' ? item.lockedReasonDetail : '')}
                isLocked={item.isLocked || !props.allowSelection}
                item={item} textVisible={false}
                id={item.tpeId} />
            <ToolbarTrainingPlanElementDuration
                trainingPlanElement={props.trainingPlanElement}
                className="l-box__toolbar__icon lesson-tools lesson-tools--duration" />
        </div>
    )
}
