import { JsonObject, JsonProperty } from 'json2typescript';
/*import { observable } from 'mobx';*/

/**
 * This Model is used for pure Boolean responses from the WebAPI. 
 * If the value is not packed inside this model, the Jscon2TypescriptConverter has problems
 * reading the value.
 */
@JsonObject
export class BooleanResponse {

    /** Boolean value  */
    @JsonProperty('status', Boolean, false)
    public status: boolean = false;

    [key: string]:  boolean 
   
}
