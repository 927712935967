import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class CollaborationProviderLinkResponse {

    @JsonProperty('title', String, false)
    public title: string = '';
    @JsonProperty('description', String, false)
    public description: string = '';
    @JsonProperty('channelLink', String, false)
    public channelLink: string = '';
}
