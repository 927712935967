import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DocumentTypeConverter } from '$src/storage/models/converters/DocumentTypeConverter';
import { EDocumentType } from '$src/storage/models/enums';

@JsonObject
export class ClassFileDataRequest extends FileDataRequest {

    @JsonProperty('name', String, true)
    public name: string = '';

    @JsonProperty('documentType', DocumentTypeConverter, true)
    public documentType: EDocumentType = EDocumentType.WithoutRestriction;

    @JsonProperty('linkedDocument', String, true)
    public linkedDocument: string = '';
}