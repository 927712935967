import Session from '$core/Session';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import DateHelper from '$src/util/DateHelper';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';
import Moment from 'moment';
import React from 'react';

interface IProps {
    attributes: InputAttributes;
    type: string;
}

export class InputReadOnly extends React.Component<IProps, {}> {
    public languageCode = Session.instance.getUserLanguageCodeOrFallBack;

    protected controlText: HTMLInputElement | null;
    protected className = 'InputReadOnly';
    protected loggerLocality = 'Components\shared.InputReadOnly';

    constructor(props: IProps) {
        super(props);
    }

    public UNSAFE_componentWillMount() {
        if (this.props.type.toLowerCase() === 'select'
            && this.props.attributes.attributeValueType !== undefined) {
            this.getAttributeValues(this.props.attributes.attributeValueType);
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {

        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'

        let returnValue: string = '';
        switch (this.props.type.toLowerCase()) {
            case 'checkbox':
                {
                    const valueConverted: boolean = this.props.attributes.value.toLocaleUpperCase() === 'TRUE' ? true : false;
                    returnValue = valueConverted === true ? Session.instance.storage.translation.GetString('Control:Checked')
                        : Session.instance.storage.translation.GetString('Control:UnChecked')
                    break;
                }
            case 'date':
                {
                    const value: Date | null = DateHelper.toDateFromIso(this.props.attributes.value);
                    if (value !== null && Moment(value).isValid()) {
                        returnValue = StringHelper.dateString(value)
                    }
                    break;
                }

            case 'password':
                returnValue = '*****'
                break;

            case 'select': {
                let attributeValue: AttributeValue | null = null;
                if (this.props.attributes.attributeValueType !== undefined) {
                    attributeValue = Session.instance.storage.attributeValue.getAttributeValue(this.props.attributes.attributeValueType, parseInt(this.props.attributes.value, 10))
                }
                if (attributeValue !== null) {
                    returnValue = attributeValue.text
                }
                break;
            }
            default:
                returnValue = this.props.attributes.value
        }

        return (
            <div key={'InputReadOnly_Div_' + this.props.attributes.id} className={boxClass}>

                <label
                    key={'InputReadOnly_Label_' + this.props.attributes.id}
                    id={'InputReadOnly_Label_' + this.props.attributes.id}
                    htmlFor={this.props.attributes.id}
                    className={'input-label'}>
                    <Translate>{this.props.attributes.label}</Translate>
                    {(this.props.attributes.isRequired && this.props.attributes.editMode) ? ' *' : ''}
                </label>

                <input
                    type={'text'}
                    key={'InputReadOnly_Span_' + this.props.attributes.id}
                    id={this.props.attributes.id}
                    className="input-text-readonly"
                    value={returnValue}
                    readOnly={true}
                    aria-labelledby={'InputReadOnly_Label_' + this.props.attributes.id} />

                <span key={'InputText_ErrorMsg_' + this.props.attributes.id} className={'input-message error'}></span>

            </div >
        )
    }

    /**
     * Get attribute values for select control.
     */
    protected async getAttributeValues(typeId: number): Promise<void> {
        const attributeValues: AttributeValue[] | null = await Session.instance.storage.attributeValue.getAttributeValuesAsync(typeId);

        if (isSuccess<AttributeValue[] | null>(attributeValues)) {
            this.setState({ attributeValue: attributeValues });
        }
    }
}