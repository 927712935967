import React from 'react';

import Icondate from '$resources/svgs/toolbar/date.svg';
import Session from '$src/core/Session';
import { Item } from '$src/storage/models/Item';
import { StringHelper } from '$src/util/StringHelper';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    item: Item;
    textVisible: boolean;
    relatedClassId?: number;
}

export class ToolbarScheduleDates extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        const icon = Icondate;
        let text = '';
        let hoverText = tr.GetString('ItemDetail:CourseDates');
        let startDate: Date | undefined;
        let endDate: Date | undefined;
        if (this.props.relatedClassId != null) {
            startDate = this.props.item.learningPeriodStartDateByClassID(this.props.relatedClassId);
            endDate = this.props.item.learningPeriodEndDateByClassID(this.props.relatedClassId);
        } else {
            startDate = this.props.item.learningPeriodStartDate;
            endDate = this.props.item.learningPeriodEndDate;
        }
        if (!startDate || !endDate) {
            text = tr.GetString('NoDataFound:NoDatesFound');
        } else if (startDate.toDateString() === endDate.toDateString()) {
            text = StringHelper.dateString(startDate);
        } else {
            text = `${StringHelper.dateString(startDate)} - 
                    ${StringHelper.dateString(endDate)}`;
        }
        hoverText = hoverText + ': ' + text;
        return (
            <div className="l-box__toolbar__icon lesson-tools lesson-tools--type"
                aria-label={hoverText}
                data-tip={hoverText}
                data-for={`${this.props.item.itemId}_scheduleDatesTooltip`}>
                <InlineSVG src={icon} />
                {this.props.textVisible ? (text) : ''}
                <Tooltip id={`${this.props.item.itemId}_scheduleDatesTooltip`} />
            </div>
        )
    }
}
