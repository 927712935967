import { LegacySearch } from '$src/components/search/legacy/LegacySearch';
import { Translate } from '$components/shared/Translate';
import { TodoList } from '$components/TodoList/TodoList';
import { MyCertificates } from '$components/userProfile/MyCertificates';
import { MyLessons } from '$components/userProfile/MyLessons';
import Logger from '$src/core/Logger';
import AuthService from '$src/core/Services/AuthService';
import Session from '$src/core/Session';
import { StringResponse } from '$src/storage/models/StringResponse';
import { isSuccess } from '$src/util/Result';
import { RefObject } from 'react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';

interface IMatchParams {
    pageId?: string;
}

interface IPageConfig {
    pageId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
    pageConfiguration?: IPageConfig;
}

interface IState {
    lastRefresh?: number;
    index: number;
    accessToken?: string;
    currentPageId?: string;
    lastPageId?: string;
    currentUrlSearch?: string;
    lastUrlSearch?: string;
    shouldRelaodAccessToken: boolean;
}

const iframeStyle = {
    background: 'transparent',
    boder: 'none',
    marginTop: '20px',
    outline: 'none',
}

/** 
 * This class is used to integrate external applications or pages in the SUI.
 * Externale pages must be configured using globalConfig.externalPages and added to the menu 
 * using globalConfig.navigationProperties.navigationItems.
 * See also Confluenze article 'SWTGTDTCR-7 SUI - Meine Mitarbeiter (Manager Board)' 
 * https://confluence.swissteach.ch/pages/viewpage.action?pageId=68846009
 */
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class ExternalNavigationPage extends React.Component<IProps, IState> {


    protected className = 'ExternalPage';
    protected loggerLocality = 'Components.ExternalPage';
    protected iframe: RefObject<HTMLIFrameElement>;
    constructor(props: IProps) {
        super(props);
        this.iframe = React.createRef();
        this.state = {
            accessToken: '',
            index: 1,
            lastRefresh: Date.now(),
            shouldRelaodAccessToken: false
        };
    }

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        return { lastRefresh: Date.now(),
                 index: prevState.index + 1,
                 currentPageId: nextProps.match.params,
                 lastPageId: prevState.currentPageId,
                 currentUrlSearch: nextProps.location.search,
                 lastUrlSearch: prevState.currentUrlSearch,
                 shouldRelaodAccessToken:  prevState.currentPageId != null && (nextProps.location.search != prevState.currentUrlSearch) };
    }

    public async componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));

        await AuthService.instance.GetExternalAccessToken().then(resp => {
            if (isSuccess<StringResponse>(resp)) {
                this.setState({ accessToken: resp.value });
            }
        });
    }
    
    /**
     * If the users jumps from one externalPage to another externalPage, we have to update the AT because it is only 1 time usable.
     * @param prevProps Previous props before the switch
     * @param prevState Previous state before the switch
     */
    public async componentDidUpdate() {
        if (this.state.currentPageId !== this.state.lastPageId || this.state.lastUrlSearch !== this.state.currentUrlSearch) {
            await AuthService.instance.GetExternalAccessToken().then(resp => {
                if (isSuccess<StringResponse>(resp)) {
                    this.setState({ accessToken: resp.value });
                }
            });
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.resize.bind(this));
    }

    public render() {
        const pageId = this.props.match.params.pageId || this.props.pageConfiguration?.pageId;
        const iframeId = 'frameContent';
        const language = Session.instance.languageCode;
        const authToken = this.state.accessToken;
        const pageConfig = globalConfig.externalPages.find(c => c.pageId === pageId);
        if (pageConfig !== undefined && authToken != null && authToken !== '' && this.state.shouldRelaodAccessToken != true) {
            const opt = pageConfig.url.indexOf('?') > 0 ? '&' : '?'
            const url = `${pageConfig.url}${opt}language=${language}&at=${authToken}`;
            const height = this.calculateIframeHeight();
            const cssMainDiv = pageConfig.isOnHomeComponent ? '' : 'home__background-image';
            const cssSubDiv = pageConfig.isOnHomeComponent ? '' : 'l-container';
            return (
                <div className={cssMainDiv} style={pageConfig.pageStyle}>
                    {this.renderPageTitle(pageConfig.pageTitle)}
                    {this.renderDefinedComponents(pageConfig.componentsBefore)}
                    <div className={cssSubDiv}>
                        <iframe
                            id={iframeId}
                            key={this.state.index}
                            style={iframeStyle}
                            src={url}
                            width="100%"
                            height={`${height}px`}
                            frameBorder="0"
                            allowFullScreen={true}
                            allowTransparency={true}
                            ref={this.iframe}
                        />
                    </div>
                </div>
            )
        } 
        else if (this.state.shouldRelaodAccessToken || authToken == null || authToken === '') {
            return <ProgressSpinner />
        }
        else {
            return <p>Configuration for page &apos;{pageId}&apos; not found.</p>
        }
    }

    public resize() {
        const height = this.calculateIframeHeight();
        if (this.iframe != null && this.iframe.current != null) {
            this.iframe.current.height = `${height}px`;
        }
    }

    protected renderPageTitle(pageTitle: string | undefined): JSX.Element | null {
        return pageTitle != null ? <div className="l-box-container"><h1 className="heading__Title"><Translate>{pageTitle}</Translate></h1></div> : null;
    }

    protected renderDefinedComponents(components: string[] | undefined): JSX.Element | null {
        if (components != null) {
            return <React.Fragment>
                {components.findIndex(item => item.toLowerCase() === 'todolist') >= 0 ? <TodoList {...this.props} userId={undefined} /> : null}
                {components.findIndex(item => item.toLowerCase() === 'search') >= 0 ? <LegacySearch {...this.props} /> : null}
                {components.findIndex(item => item.toLowerCase() === 'certificates') >= 0 ? <MyCertificates {...this.props} userId={undefined} /> : null}
                {components.findIndex(item => item.toLowerCase() === 'mylessons') >= 0 ? <MyLessons componentId="myLessons" userId={undefined} {...this.props} /> : null}
            </React.Fragment>
        }
        else {
            return null;
        }
    }

    private calculateIframeHeight(): number {
        const methodName = `${this.className}:calculateIframeHeight()`;
        if (document == null || document.documentElement == null) {
            return 0;
        }
        let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        height -= 220; // space for header & footer
        Logger.log(this.loggerLocality, `${methodName} changing heigth of iframe to ${height}px.`);
        return height;
    }

}
export default ExternalNavigationPage;