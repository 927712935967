export const userName = 'userName';
export const userDisplayName = 'userDisplayName';
export const firstName = 'firstName';
export const lastName = 'lastName';
export const email = 'email';
export const eTPBlockLessonStatus = 'eTPBlockLessonStatus';
export const lessonStatus = 'lessonStatus';


export class EvaluationOfTplansItemModel {
    public columnDictionary: { [key: number]: string } = {};

    constructor() {
        this.columnDictionary[0] = userName;
        this.columnDictionary[1] = userDisplayName;
        this.columnDictionary[2] = firstName;
        this.columnDictionary[3] = lastName;
        this.columnDictionary[4] = email;
        this.columnDictionary[5] = eTPBlockLessonStatus;
        this.columnDictionary[6] = lessonStatus;
    }
}