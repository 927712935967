import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserResponse {

    @JsonProperty('isFirstLogin', Boolean, false)
    public isFirstLogin: boolean = false;

    @JsonProperty('username', String, false)
    public username: string = '';

    @JsonProperty('domainName', String, false)
    public domainName: string = '';

    @JsonProperty('email', String, false)
    public email: string = '';

    @JsonProperty('mobileNumber', String, false)
    public mobileNumber: string = '';
}