import { Preferences } from '$src/storage/models/UserPreferences/Preferences';
import { PreferencesConverter } from '$src/storage/models/UserPreferences/PreferencesConverter';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserPreferences {

    @JsonProperty('userId', Number, false)
    public id: number = 0;

    @JsonProperty('preferences', PreferencesConverter, true)
    public preferences: Preferences = new Preferences();
}