import { JsonConverter, JsonCustomConvert} from 'json2typescript';

@JsonConverter
export class DateTimeConverter implements JsonCustomConvert<Date | undefined> {
    public serialize(date: Date | undefined): string | undefined {
        return date !== undefined ? date.toISOString(): undefined;
    }
    public deserialize(iso8601DateTimeString: string | null): Date | undefined {
        return iso8601DateTimeString != null ? new Date(iso8601DateTimeString) : undefined;
    }
}