import { DatePicker } from '$components/shared/DatePicker';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { InputNumber } from '$src/components/shared/InputNumber';
import { InputText } from '$src/components/shared/InputText';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import { ExternalCourseSchedule } from '$src/storage/models/ExternalCourse/ExternalCourseSchedule';
import React from 'react';
import { NumberHelper } from '$src/util/NumberHelper';
import { StringHelper } from '$src/util/StringHelper';

interface IProps {
    onSaveExternalCourseSchedule?: (confirmed: boolean, externalCourseSchedule: ExternalCourseSchedule | undefined) => void;
    parentHeadingLevel: number;
    scheduleToCopy?: ExternalCourseSchedule;
}

interface IState {
    externalCourseSchedule: ExternalCourseSchedule;
    firstDateErrorMessage: string;
    lastDateErrorMessage: string;
}

export class CreateExternalCourseSchedule extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const externalCourseSchedule = this.props.scheduleToCopy ? this.props.scheduleToCopy : new ExternalCourseSchedule();
        externalCourseSchedule.scheduleId = 0;

        this.state = {
            externalCourseSchedule,
            firstDateErrorMessage: '',
            lastDateErrorMessage: ''
        }
    }

    public render() {
        const ariaLabelCancel = Session.instance.storage.translation.GetString('Button:Cancel');
        const ariaLabelSave = Session.instance.storage.translation.GetString('Button:Save');
        let saveDisabled = true;
        // enable save only when required fields are filled
        if (this.state.externalCourseSchedule.firstAppointment
            && this.state.externalCourseSchedule.lastAppointment
            && this.state.firstDateErrorMessage === ''
            && this.state.lastDateErrorMessage === ''
            && this.state.externalCourseSchedule.duration.length > 0
            && this.state.externalCourseSchedule.location.length > 0
            && this.state.externalCourseSchedule.price >= 0) {
            saveDisabled = false;
        }
        return (
            <div>
                {this.renderTable()}
                <div className="modal__spread-buttons">
                    <button type="button"
                        aria-label={ariaLabelSave}
                        className="btn btn--sm marginRight-5"
                        onClick={() => this.onButtonClickSave()}
                        disabled={saveDisabled}
                        id="btnSave">
                        <Translate>Button:Save</Translate>
                    </button>
                    <button type="button"
                        aria-label={ariaLabelCancel}
                        className="btn btn--sm"
                        onClick={() => this.onButtonClickClose()}
                        id="btnCancel">
                        <Translate>Button:Cancel</Translate>
                    </button>
                </div>
            </div>
        )
    }

    private renderTable(): JSX.Element {
        const durationText: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'durationText',
            initialValidation: false,
            isReadOnly: false,
            isRequired: true,
            label: Session.instance.storage.translation.GetString('ExternalCourse:ScheduleDuration'),
            labelClassName: 'screen-reader-only',
            maxLength: 100,
            regExpression: undefined,
            rows: 5,
            url: undefined,
            value: this.state.externalCourseSchedule.duration,
        };

        const schedulePrice: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'schedulePrice',
            initialValidation: false,
            isReadOnly: false,
            isRequired: true,
            label: Session.instance.storage.translation.GetString('ExternalCourse:SchedulePrice'),
            labelClassName: 'screen-reader-only',
            regExpression: /[0-9]*/,
            rows: 5,
            url: undefined,
            value: this.state.externalCourseSchedule.price >= 0 && this.props.scheduleToCopy ? StringHelper.numberString(this.state.externalCourseSchedule.price) : ''
        };

        const scheduleLocation: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'scheduleLocation',
            initialValidation: false,
            isReadOnly: false,
            isRequired: true,
            label: Session.instance.storage.translation.GetString('ExternalCourse:Location'),
            labelClassName: 'screen-reader-only',
            maxLength: 100,
            regExpression: undefined,
            url: undefined,
            value: this.state.externalCourseSchedule.location
        };

        const scheduleCode: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'scheduleCode',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: Session.instance.storage.translation.GetString('ExternalCourse:Code'),
            labelClassName: 'screen-reader-only',
            maxLength: 100,
            regExpression: undefined,
            url: undefined,
            value: this.state.externalCourseSchedule.code
        };
        const classNameError = this.state.firstDateErrorMessage !== ''
            || this.state.lastDateErrorMessage !== '' ?
            'input-message--md error' :
            'input-message error'

        return (
            <div className="step-content__block">
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:FirstSchedule</Translate> *
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:LastSchedule</Translate> *
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:FirstSchedule</Translate> *
                            </div>
                            <div role="cell" className="l-box__input l-box__input--edit">
                                <DatePicker
                                    id='CreateExternalCourseScheduleFirstDate'
                                    onChange={(e) => this.firstSchedule_dateFormChanged(e)}
                                    value={this.state.externalCourseSchedule.firstAppointment ? this.state.externalCourseSchedule.firstAppointment : null}
                                    maxDate={this.state.externalCourseSchedule.lastAppointment}
                                    hasFocus={!this.props.scheduleToCopy ? true : false}
                                />
                                <span className={classNameError}>
                                    <Translate>{this.state.firstDateErrorMessage}</Translate>
                                </span>
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:LastSchedule</Translate> *
                            </div>
                            <div role="cell" className="l-box__input l-box__input--edit">
                                <DatePicker
                                    id='CreateExternalCourseScheduleLastDate'
                                    onChange={(e) => this.lastSchedule_dateFormChanged(e)}
                                    value={this.state.externalCourseSchedule.lastAppointment ? this.state.externalCourseSchedule.lastAppointment : null}
                                    minDate={this.state.externalCourseSchedule.firstAppointment}
                                />
                                <span className={classNameError} >
                                    <Translate>{this.state.lastDateErrorMessage}</Translate>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Duration</Translate> *
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Price</Translate> *
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Duration</Translate> *
                            </div>
                            <div role="cell" className="inlineFlex">
                                <InputText attributes={durationText} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Price</Translate> *
                            </div>
                            <div role="cell" className="inlineFlex">
                                <InputNumber attributes={schedulePrice} onChange={(id: string, value: string) => this.onInputNumberChange(id, value)} />
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Location</Translate> *
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Code</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Location</Translate> *
                            </div>
                            <div role="cell" className="inlineFlex">
                                <InputText attributes={scheduleLocation} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                            </div>

                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Code</Translate>
                            </div>
                            <div role="cell" className="inlineFlex" >
                                <InputText attributes={scheduleCode} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private onInputChange(id: string, value: string) {
        const externalCourseSchedule = this.state.externalCourseSchedule;
        switch (id) {
            case 'durationText':
                externalCourseSchedule.duration = value;
                break;
            case 'scheduleLocation':
                externalCourseSchedule.location = value;
                break;
            case 'scheduleCode':
                externalCourseSchedule.code = value;
                break;

        }
        this.setState({ externalCourseSchedule });
    }

    private onInputNumberChange(id: string, value: string) {
        const externalCourseSchedule = this.state.externalCourseSchedule;
        switch (id) {
            case 'schedulePrice':
                externalCourseSchedule.price = NumberHelper.priceAsDecimal(value);
                break;
        }
        this.setState({ externalCourseSchedule });
    }

    private firstSchedule_dateFormChanged(targetValue: Date | null) {
        const externalCourseSchedule = this.state.externalCourseSchedule;
        externalCourseSchedule.firstAppointment = targetValue != null ? targetValue : undefined;
        let errorMessage = '';
        if (targetValue == null) {
            errorMessage = 'ErrorMessage:Required';
        }
        else {
            if (this.state.externalCourseSchedule.lastAppointment != null) {
                if (targetValue > this.state.externalCourseSchedule.lastAppointment) {
                    errorMessage = 'ErrorMessage:StartLtEnd';
                }
            }
        }
        this.setState({ externalCourseSchedule, firstDateErrorMessage: errorMessage, lastDateErrorMessage: '' });
    }

    private lastSchedule_dateFormChanged(targetValue: Date | null) {
        const externalCourseSchedule = this.state.externalCourseSchedule;
        externalCourseSchedule.lastAppointment = targetValue != null ? targetValue : undefined;
        let errorMessage = '';

        if (targetValue == null) {
            errorMessage = 'ErrorMessage:Required';
        }
        else {
            if (this.state.externalCourseSchedule.firstAppointment != null) {
                if (targetValue < this.state.externalCourseSchedule.firstAppointment) {
                    errorMessage = 'ErrorMessage:EndGtStart';
                }
            }
        }
        this.setState({ externalCourseSchedule, firstDateErrorMessage: '', lastDateErrorMessage: errorMessage });
    }

    private onButtonClickClose() {
        if (this.props.onSaveExternalCourseSchedule) {
            this.props.onSaveExternalCourseSchedule(false, undefined);
        }
    }

    private onButtonClickSave() {
        if (this.props.onSaveExternalCourseSchedule) {
            const externalCourseSchedule = this.state.externalCourseSchedule;
            this.props.onSaveExternalCourseSchedule(true, externalCourseSchedule);
        }
    }
}