import { EItemSubType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class ItemSubTypeConverter implements JsonCustomConvert<EItemSubType> {
    // Undefined = 0, Traditional = 1, FaceToFace = 2, Virtual = 3, External = 4, Standard = 5, Random = 6, Survey = 8
    public serialize(itemSubType: EItemSubType): any {
        switch (itemSubType) {
            case EItemSubType.Traditional: return 1;
            case EItemSubType.FaceToFace: return 2;
            case EItemSubType.Virtual: return 3;
            case EItemSubType.External: return 4;
            case EItemSubType.Standard: return 5;
            case EItemSubType.Random: return 6;
            case EItemSubType.Survey: return 8;
            default: return 0;
        }
    }
    public stringToInt(itemSubType: string): number {
        switch (itemSubType) {
            case 'Traditional': return 1;
            case 'FaceToFace': return 2;
            case 'Virtual': return 3;
            case 'External': return 4;
            case 'Standard': return 5;
            case 'Random': return 6;
            case 'Survey': return 8;
            case 'Undefined': return 0;
            default: return -1;
        }
    }
    public deserialize(jsonValue: number): EItemSubType {
        switch (jsonValue) {
            case 1: return EItemSubType.Traditional;
            case 2: return EItemSubType.FaceToFace;
            case 3: return EItemSubType.Virtual;
            case 4: return EItemSubType.External;
            case 5: return EItemSubType.Standard;
            case 6: return EItemSubType.Random;
            case 8: return EItemSubType.Survey;
            default: return EItemSubType.Undefined;
        }
    }
}
