import InfoIcon from '$resources/svgs/misc/info.svg';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCloseEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

import IconattachFile from '$resources/svgs/misc/attach-file.svg';
import IconOpenInNewTab from '$resources/svgs/misc/open-in-new-tab.svg';
import IconarrowLeft from '$resources/svgs/navi/arrow-left.svg';
import IconarrowRight from '$resources/svgs/navi/arrow-right.svg';

import { CreateExternalCourse } from '$components/ExternalCourse/CreateDialogs/CreateExternalCourse';
import { Heading } from '$components/shared/Heading';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import ExternalCourseService from '$core/Services/ExternalCourseService';
import F2FService from '$core/Services/F2FService';
import Session from '$core/Session';
import { UrlHelper } from '$src/util/UrlHelper';
import { ExternalCourse } from '$storage/models/ExternalCourse/ExternalCourse';
import { ExternalCourseProvider } from '$storage/models/ExternalCourse/ExternalCourseProvider';
import { F2FDocumentList } from '$storage/models/F2F/F2FDocumentList';
import { FileDataRequest } from '$storage/models/RequestObjects/FileDataRequest';
import FileHelper from '$util/FileHelper';
import { isSuccess } from '$util/Result';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import GTButton from '$src/components/shared/Atoms/GTButton';

interface IProps {
    parentHeadingLevel: number;
    selectedCourseProvider: ExternalCourseProvider | undefined;
    selectedCourse: ExternalCourse | undefined;
    isNew: boolean;
    uploadedCourseFiles: FileDataRequest[];
    onStepClicked?: (stepNumber: number) => void;
    onCourseChanged?: (selectedCourse: ExternalCourse, uploadedCourseFiles: FileDataRequest[]) => void;
}

interface IState {
    courseList: ExternalCourse[];
    dataLoaded: boolean;
    errorMessage: string;
    filteredCourses: ExternalCourse[];
    selectedCourse: ExternalCourse | undefined;
    courseFiles: F2FDocumentList | null;
    courseFilesLoaded: boolean;
    shouldShowModal: boolean;
    uploadedCourseFiles: FileDataRequest[];
}

export class StepCourse extends React.Component<IProps, IState> {
    protected courseProviderStepNumber = 1; // step number of course provider step defined in RequestWizard.tsx
    protected scheduleStepNumber = 3; // step number of schedule step defined in RequestWizard.tsx

    constructor(props: IProps) {
        super(props);

        this.state = {
            courseFiles: null,
            courseFilesLoaded: false,
            courseList: [],
            dataLoaded: false,
            errorMessage: '',
            filteredCourses: [],
            selectedCourse: this.props.selectedCourse,
            shouldShowModal: false,
            uploadedCourseFiles: this.props.uploadedCourseFiles,
        }
    }

    public async componentDidMount() {
        if (this.props.isNew) {
            await this.getCourses();
        } else {
            this.setState({ dataLoaded: true });
        }
    }

    public render() {
        if (!this.state.dataLoaded) {
            return <ProgressSpinner />
        } else if (this.props.selectedCourseProvider) {
            const ariaLabelNextStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToSchedule')
            const ariaLabelPreviousStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToCourseProvider')
            return (
                <div className="step-content">
                    <div className="step-content__block">
                        <div className="step-content__block">
                            <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                <Translate>ExternalCourse:CourseProvider</Translate>
                            </Heading>
                            <span>{this.props.selectedCourseProvider.name}</span>
                        </div>
                        <div className="step-content__block">
                            {(this.state.courseList.length === 0 && this.props.isNew && !this.state.selectedCourse) &&
                                <Translate>ExternalCourse:NoCourseForThisProvider</Translate>
                            }
                            {(this.state.courseList.length > 0 || !this.props.isNew || this.state.selectedCourse) &&
                                <AutoComplete
                                    data={this.state.filteredCourses}
                                    onChange={(e: AutoCompleteChangeEvent) => this.onChange(e)}
                                    label={Session.instance.storage.translation.GetString('ExternalCourse:SelectCourse')}
                                    suggest={false}
                                    textField="title"
                                    className="flexForIcon"
                                    value={this.state.selectedCourse ? `${this.state.selectedCourse.title} (${this.state.selectedCourse.sid})` : undefined}
                                    onClose={(e) => this.onClose(e)}
                                    itemRender={(li, p) => this.itemRender(li, p)}
                                    disabled={!this.props.isNew}
                                />
                            }
                        </div>
                        {this.state.errorMessage.length > 0 &&
                            <div className="step-content__block">
                                <span className={'input-message error--dark'}>
                                    <Translate>{this.state.errorMessage}</Translate>
                                </span>
                            </div>
                        }
                        {this.renderCourseDetails()}

                        {this.props.isNew &&
                            <React.Fragment>
                                {globalConfig.externalCourse.showCreateCourseLink &&
                                    <React.Fragment>
                                        <div className="step-content__block">
                                            <button className="button-link button-link--colorized-dark create-link"
                                                onClick={() => this.createNewCourse()}
                                                id="btnCreateNewCourse">
                                                <Translate>ExternalCourse:CreateNewCourse</Translate>
                                            </button>
                                        </div>
                                        <ModalPopup
                                            isOpen={this.state.shouldShowModal}
                                            onRequestClose={() => this.closeModal()}
                                            title={Session.instance.storage.translation.GetString('ExternalCourse:CreateNewCourseTitle')}>
                                            <div className="modal__spread buttons">
                                                <CreateExternalCourse
                                                    parentHeadingLevel={this.props.parentHeadingLevel}
                                                    onSaveExternalCourse={(confirmed, createdCourse, courseFiles) => this.onSaveExternalCourse(confirmed, createdCourse, courseFiles)}
                                                />
                                            </div>
                                        </ModalPopup>
                                    </React.Fragment>
                                }
                                <div className="step-info__block">
                                    <div className="inlineFlex">
                                        <InlineSVG src={InfoIcon} />
                                        <span>
                                            <Translate>ExternalCourse:HintDataSavedOnStepRequest</Translate>
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="step-navigation">
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.goToCourseProvider()}
                            aria-label={ariaLabelPreviousStep}
                            id="btnGoToCourseProvider">
                            <div className="inlineFlex">
                                <InlineSVG src={IconarrowLeft} />
                                <span className="notMobile"><Translate>ExternalCourse:CourseProvider</Translate></span>
                            </div>
                        </button>
                        <Translate>ExternalCourse:Course</Translate>
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.goToSchedule()}
                            aria-label={ariaLabelNextStep}
                            disabled={!this.state.selectedCourse}
                            id="btnGoToSchedule">
                            {
                                this.state.selectedCourse ?
                                    <div className="inlineFlex">
                                        <span className="notMobile"><Translate>ExternalCourse:Schedule</Translate></span>
                                        <InlineSVG src={IconarrowRight} />
                                    </div>
                                    :
                                    <div className="inlineFlex external-courses__link__disabled">
                                        <span className="notMobile"><Translate>ExternalCourse:Schedule</Translate></span>
                                    </div>
                            }
                        </button>
                    </div>

                </div>
            )
        } else {
            return null;
        }
    }


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private itemRender(li: any, itemProps: ListItemProps) {
        const course = itemProps.dataItem as ExternalCourse;
        const itemChildren = <span>{li.props.children} ({course.sid})</span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    private async getCourses() {
        if (this.props.selectedCourseProvider) {
            const courses = await ExternalCourseService.instance.getExternalCourseByProvider(this.props.selectedCourseProvider.providerId);
            if (isSuccess<ExternalCourse[]>(courses)) {
                this.setState({ courseList: courses, filteredCourses: courses, dataLoaded: true, errorMessage: '' })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesFailed', dataLoaded: true });
            }
        } else {
            this.setState({ dataLoaded: true });
        }
    }

    private async getCourseFiles() {
        if (this.state.selectedCourse) {
            const response = await F2FService.instance.getF2FDocuments(this.state.selectedCourse.courseId, 0, 0, EItemDetailCallerContextType.ExternalCourse, 0);
            if (isSuccess<F2FDocumentList | null>(response)) {
                this.setState({ courseFiles: response, courseFilesLoaded: true });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCourseFileLoadFailed', courseFilesLoaded: true });
            }
        }
    }

    private renderCourseDetails(): JSX.Element | null {
        if (!this.state.courseFilesLoaded && this.state.selectedCourse) {
            this.getCourseFiles();
            return <ProgressSpinner />;
        }
        else if (this.state.selectedCourse) {
            let courseUrl: string = '';
            if (this.state.selectedCourse.courseUrl != null && this.state.selectedCourse.courseUrl.length > 0) {
                courseUrl = UrlHelper.getHttpsUrl(this.state.selectedCourse.courseUrl);
            }
            return (
                <React.Fragment>
                    <div className="step-content__block ">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:CourseDescription</Translate>
                        </Heading>
                        {(this.state.selectedCourse.description != null && this.state.selectedCourse.description.length === 0) &&
                            <Translate>ExternalCourse:NoCourseDescription</Translate>
                        }
                        <p>{this.state.selectedCourse.description}</p>
                    </div>
                    {(courseUrl.length > 0) &&
                        <div className="step-content__block inlineFlex">
                            <InlineSVG src={IconOpenInNewTab} />
                            <a href={courseUrl} target="_blank" rel="noopener noreferrer" className="button-link button-link--colorized-dark">
                                <Translate>ExternalCourse:CourseURL</Translate>
                            </a>
                        </div>
                    }
                    {this.renderDocumentTable()}
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    private renderDocumentTable(): JSX.Element | null {
        if (this.state.courseFiles != null && this.state.courseFiles.courseDocuments != null && this.state.courseFiles.courseDocuments.length > 0) {
            return (
                <div className="step-content__block">
                    <Translate>ItemDetail:Files</Translate>
                    <table>
                        <thead>
                            <tr className="screen-reader-only">
                                <th>
                                    <Translate>ExternalCourse:Filename</Translate>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.courseFiles.courseDocuments.map(doc =>
                                <tr key={doc.filename}>
                                    <td className="inlineFlex">
                                        <InlineSVG src={IconattachFile} />
                                        <a href={doc.url} target="_blank" rel="noopener noreferrer" className="button-link button-link--colorized-dark">{doc.filename}</a>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            );
        } else if (this.state.uploadedCourseFiles.length > 0) {
            return (
                <div className="step-content__block">
                    <Translate>ItemDetail:Files</Translate>
                    <table>
                        <thead>
                            <tr className="screen-reader-only">
                                <th>
                                    <Translate>ExternalCourse:Filename</Translate>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.uploadedCourseFiles.map((doc, index) => {
                                const currentFileName = doc.fileName
                                const arriaLabel = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                    .Format(currentFileName);
                                if (doc.file) {
                                    const file = doc.file;
                                    return (
                                        <tr key={index}>
                                            <td className="inlineFlex">
                                                <GTButton
                                                    isIconButton={true}
                                                    aria-label={arriaLabel}
                                                    onClick={() => FileHelper.openFile(file)}
                                                    additionalClassNames="inlineFlex">
                                                    <>
                                                        <InlineSVG src={IconattachFile} />
                                                        {currentFileName}
                                                    </>
                                                </GTButton>
                                            </td>
                                        </tr>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        else {
            return null;
        }
    }

    private onChange(event: AutoCompleteChangeEvent) {
        const value = event.target.value;
        const filteredCourses = this.filterData(value);
        let errorMessage = this.state.errorMessage
        if (value === '') {
            errorMessage = '';
        }
        this.setState({
            errorMessage,
            filteredCourses,
            selectedCourse: undefined
        })
    }

    private filterData(value: string): ExternalCourse[] {
        const data = this.state.courseList
        const filter: CompositeFilterDescriptor = {
            filters: [
                { field: 'title', ignoreCase: true, operator: 'contains', value },
                { field: 'sid', ignoreCase: true, operator: 'contains', value },
            ],
            logic: 'or'
        };
        return value && value !== '*' ? filterBy(data, filter) : data;
    }

    private onClose(event: AutoCompleteCloseEvent) {
        const value = event.target.value;
        const selectedCourse = this.state.courseList.filter(c => c.title === value)[0];
        const errorMessage = selectedCourse || value === '' ? '' : 'ErrorMessage:SelectAValidCourse';
        this.setState({
            courseFilesLoaded: false,
            errorMessage,
            selectedCourse
        })
        if (this.props.onCourseChanged && selectedCourse) {
            this.props.onCourseChanged(selectedCourse, this.state.uploadedCourseFiles)
        }
    }

    private goToCourseProvider() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.courseProviderStepNumber);
        }
    }

    private goToSchedule() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.scheduleStepNumber);
        }
    }

    private createNewCourse() {
        this.openModal();
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private onSaveExternalCourse(confirmed: boolean, createdCourse: ExternalCourse | undefined, courseFiles: FileDataRequest[]) {
        if (confirmed && createdCourse) {
            const courseList = this.state.courseList;
            courseList.push(createdCourse);
            this.setState({ selectedCourse: createdCourse, courseList, uploadedCourseFiles: courseFiles, errorMessage: '' });
            if (this.props.onCourseChanged && createdCourse) {
                this.props.onCourseChanged(createdCourse, courseFiles)
            }
        }
        this.closeModal();
    }
}