import React from 'react';
import InlineSVG from 'react-inlinesvg';

import arrowRightIcon from '$resources/svgs/navi/arrow-right.svg';
import backIconGray from '$resources/svgs/navi/back--gray.svg';
import backIcon from '$resources/svgs/navi/back.svg';

import { TrainingPlanLessonStatusIcon } from '$components/trainingPlan/TrainingPlanLessonStatusIcon';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import TrainingPlanBlockCompletitionRequirementsIcon from '$components/trainingPlan/TrainingPlanBlockCompletitionRequirementsIcon';

interface IProps {
    activeElement: TrainingPlanElement | null;
    trainingPlan: TrainingPlan | null;
    trainingPlanElements: TrainingPlanElement[] | null;
    onNavigation?: (trainingPlanElement: TrainingPlanElement | undefined) => void;
}
/**
 * @deprecated The method should not be used
 */
export class TrainingPlanElementsNavigation extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
        this.onBackButtonClicked = this.onBackButtonClicked.bind(this);
        this.onModulClicked = this.onModulClicked.bind(this);
    }

    public render() {
        let parent: string | null = null;
        const listElements: JSX.Element[] = [];
        let key = 0;
        if (this.props.activeElement != null && this.props.trainingPlan != null && this.props.trainingPlanElements != null) {
            parent = this.props.activeElement.parentItemId;
            // Add active element
            listElements.unshift(<li key={key++} className="tp__elements__navigation-item tp__elements__navigation-item--active">
                <InlineSVG src={arrowRightIcon} />
                {this.props.activeElement.title}
            </li>);
            // Add parents
            do {
                const parentElement = this.props.trainingPlanElements.find(element => element.sId === parent);
                if (parentElement != null) {
                    listElements.unshift(<li key={key++} className="tp__elements__navigation-item "><InlineSVG src={arrowRightIcon} />
                        <button className="button-link" onClick={() => this.onModulClicked(parentElement)}>{parentElement.title}</button>
                    </li>)
                    parent = parentElement.parentItemId;
                }
                else {
                    parent = this.props.trainingPlan.sId;
                }
            } while (parent !== null && parent !== this.props.trainingPlan.sId)
        }
        // Add root
        listElements.unshift(<li key={key++} className="tp__elements__navigation-item">
            <button className="button-link" onClick={() => this.onModulClicked(undefined)}>
                {this.props.trainingPlan != null ? this.props.trainingPlan.title : ''}
            </button>
        </li>)
        if (this.props.activeElement != null) {
            listElements.unshift(<li key={key++}><button className="button-image tp__elements__navigation__back-button"
                onClick={() => this.onBackButtonClicked()}><InlineSVG src={backIcon} /></button></li>)
        }
        else {
            listElements.unshift(<li key={key++}><InlineSVG className="tp__elements__navigation__back-button" src={backIconGray} /></li>)
        }
        return <nav className="tp__elements__navigation">
            <div>
                <ol>{listElements}</ol>
            </div>  
            <div className="statusicon-container">
                <TrainingPlanBlockCompletitionRequirementsIcon activeTPElement={this.props.activeElement} alwaysVisible={false} /> 
                <TrainingPlanLessonStatusIcon includeText={true} context={'navigation'}
                    item={this.props.activeElement != null ? (this.props.activeElement) : (this.props.trainingPlan != null ? this.props.trainingPlan : undefined)} />
            </div>
        </nav>;
    }

    private onBackButtonClicked() {
        if (this.props.onNavigation != null) {
            this.props.onNavigation(this.props.trainingPlanElements != null ?
                this.props.trainingPlanElements.find(element => this.props.activeElement != null &&
                    element.sId === this.props.activeElement.parentItemId) :
                undefined);
        }
    }

    private onModulClicked(trainingPlanModul: TrainingPlanElement | undefined) {
        if (this.props.onNavigation != null) {
            this.props.onNavigation(trainingPlanModul);
        }
    }
}