import { BossSelection } from '$components/shared/BossSelection';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Session from '$src/core/Session';
import React from 'react';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';

interface IProps {
    courseTitle: string;
    parentHeadingLevel: number;
    onRequestConfirmedClick?: (boss: BossSearchResult | undefined) => void;
    onExitClicked?: () => void;
}

interface IState {
    responseWait: boolean;
    selectedBoss?: BossSearchResult;
}

export class RequestActionConfirmation extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            responseWait: false,
            selectedBoss: undefined,
        }
    }

    public render() {
        if (this.state.responseWait) {
            return <ProgressSpinner />;
        } else {
            const confirmationMessage = Session.instance.storage.translation.GetString('ExternalCourse:ConfirmRequest').Format(this.props.courseTitle);
            const ariaLabelCancel = Session.instance.storage.translation.GetString('ExternalCourse:CancelRequestConfirmation').Format(this.props.courseTitle);
            const ariaLabelConfirm = Session.instance.storage.translation.GetString('ExternalCourse:ConfirmRequest').Format(this.props.courseTitle);

            return (
                <div>
                    <Heading cssClass="heading__Level2" headingLevel={this.props.parentHeadingLevel + 1}>
                        <Translate>{confirmationMessage}</Translate>
                    </Heading>
                    {globalConfig.bossSelection.selectionIsVisible_ExternalCourse &&
                        <div>
                            <BossSelection
                                isReadOnly={false}
                                isConfigReadOnly={globalConfig.bossSelection.bossSelectionIsReadOnly_ExternalCourse}
                                parentHeadingLevel={this.props.parentHeadingLevel + 1}
                                onBossSelectionChanged={(selectedBoss) => this.onBossSelectionChanged(selectedBoss)} />
                        </div>
                    }
                    <div className="modal__spread-buttons">
                        <button type="button"
                            aria-label={ariaLabelConfirm}
                            className="btn btn--sm marginRight-5"
                            onClick={() => this.onButtonClickConfirm()}
                            disabled={!this.allowConfirmation()}
                            id="btnConfirm">
                            <Translate>Class:Confirm</Translate>
                        </button>
                        <button type="button"
                            aria-label={ariaLabelCancel}
                            className="btn btn--sm"
                            onClick={() => this.onButtonExitClicked()}
                            disabled={false}
                            id="btnCancel">
                            <Translate>Class:CancelConfirmation</Translate>
                        </button>
                    </div>
                </div>
            )
        }
    }


    private allowConfirmation(): boolean {
        return !globalConfig.bossSelection.selectionIsVisible_ExternalCourse ||
            (globalConfig.bossSelection.selectionIsVisible_ExternalCourse && this.state.selectedBoss != null);
    }

    private onButtonExitClicked() {
        if (this.props.onExitClicked != null) {
            this.props.onExitClicked();
        }
    }

    private onButtonClickConfirm() {
        this.setState({ responseWait: true });
        if (this.props.onRequestConfirmedClick != null) {
            this.props.onRequestConfirmedClick(this.state.selectedBoss)
        }
    }

    private onBossSelectionChanged(selectedBoss: BossSearchResult | undefined) {
        this.setState({ selectedBoss });
    }
}