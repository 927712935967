import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export default class PriceInfo {

	constructor (_price: number) {
		this.price = _price;
		this.originalPrice = _price;
	}

    /** Price of the item after applying the discounts */
    @JsonProperty('price', Number, false)
    public price: number = 0;

	/** Original price without any discounts */
	@JsonProperty('originalPrice', Number, true)
    public originalPrice: number = 0;

	/** Sum of group discount */
    @JsonProperty('groupDiscountSum', Number, true)
    public groupDiscountSum: number = 0;
    
	/** Voucher code only for this price */
	@JsonProperty('voucherCode', String, true)
	public voucherCode?: string = undefined;

	/** Sum of voucher discount */
    @JsonProperty('voucherDiscountSum', Number, true)
    public voucherDiscountSum: number = 0;
}
