import React, { useEffect, useState } from 'react';

import { ParticipantListForTutor } from '$src/storage/models/ParticipantListForTutor';
import { User } from '$src/storage/models/User';
import Session from '$src/core/Session';
import { Translate } from '$src/components/shared/Translate';
import { EAttributeType } from '$src/storage/models/enums';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { StringHelper } from '$src/util/StringHelper';

interface IProps {
    participantList?: ParticipantListForTutor;
    showSignatureColumnLocal: boolean;
}

/**
 * Renders the content of the participant list for tutors and admin for printing
 * @param {IProps} props All information about the participant list (see ParticipantListForTutor.cs) and showSignatureColumn that can be localy changed
 */
export function F2FParticipantListTutorToPrint(props: IProps) {
    const showSignatureColumn = globalConfig.participantListTutor.showSignatureColumn && props.showSignatureColumnLocal;
    const tr = Session.instance.storage.translation;
    const columnConfig: string[] = [];

    const [isLoading, setIsLoading] = useState(true);

    // Checks the allowed user field if valid and pushes them to the local variable columnConfig
    if (props.participantList && props.participantList.allowedUserFields.length > 0 && props.participantList.users.length > 0) {
        props.participantList.allowedUserFields.forEach(allowedField => {
            if (allowedField in props.participantList!.users[0]) {
                columnConfig.push(allowedField);
            }
        });
    }

    // Converts the string columnConfig attribute* into enum EAttributeType
    function userAttributeToEnum(userAttributeString: string) {
        switch (Number.parseInt(userAttributeString.slice(-1)) || 0) {
            case 1:
                return EAttributeType.UserAttribute1;
            case 2:
                return EAttributeType.UserAttribute2;
            case 3:
                return EAttributeType.UserAttribute3;
            case 4:
                return EAttributeType.UserAttribute4;
            case 5:
                return EAttributeType.UserAttribute5;
            default:
                return 0;

        }
    }

    useEffect(() => {
        async function fetchData() {
            if (props.participantList) {
                const userAttributes = props.participantList.allowedUserFields.filter(field => field.toLocaleLowerCase().startsWith("attribute"));
                if (userAttributes.length > 0) {
                    const calls: Promise<AttributeValue[] | null>[] = [];
                    userAttributes.forEach(userAttribute => {
                        const userAttributeEnum = userAttributeToEnum(userAttribute);
                        if (userAttributeEnum != 0) {
                            calls.push(Session.instance.storage.attributeValue.getAttributeValuesAsync(userAttributeEnum));
                        }
                    });
                    await Promise.all(calls);
                }
            }
            setIsLoading(false);
        }
        fetchData();
    }, [props.participantList])

    function renderUserTableHeader(): JSX.Element {
        return (
            <tr>
                {columnConfig && columnConfig.map(column => {
                    return (<th key={'column_' + column}>{tr.GetString('ParticipantListTutor:' + column).toLocaleUpperCase()}</th>);
                })}
                {showSignatureColumn &&
                    <th className="participant-list-tutor__user-table--signature">
                        {tr.GetString('ParticipantListTutor:Signature').toLocaleUpperCase()}
                    </th>}
            </tr>
        );
    }

    function renderUserTableItem(user: User): JSX.Element {
        return (
            <tr key={'user_' + user.id}>
                {columnConfig && columnConfig.map(column => {
                    // Get translation for the user attribute values, the user contains the attribute value id 
                    if (column.toLocaleLowerCase().startsWith('attribute')) {
                        const userColumnValue = user[column];
                        const userColumnValueNumber = typeof userColumnValue === 'number' ? userColumnValue : 0
                        const userAttributeId = userAttributeToEnum(column);
                        const attributeValue = Session.instance.storage.attributeValue.getAttributeValue(userAttributeId, userColumnValueNumber);
                        return (<td key={'user_' + column}>{attributeValue?.text}</td>);
                    } else if (column.toLocaleLowerCase() === 'groups') {
                        return (<td key={'user_' + column}>{user.groups.map(group => group.groupName).join(', ')}</td>);
                    }
                    return (<td key={'user_' + column}>{user[column]}</td>);
                })}
                {showSignatureColumn && <td className="participant-list-tutor__user-table--signature"></td>}
            </tr>
        );
    }

    if (props.participantList) {

        const trainers = props.participantList.trainers.map(trainer => {
            if (props.participantList!.trainers.indexOf(trainer) === props.participantList!.trainers.length - 1) {
                return trainer.trainerFirstName + ' ' + trainer.trainerLastName;
            } else {
                return trainer.trainerFirstName + ' ' + trainer.trainerLastName + ', ';
            }
        });
        return (
            <div className="participant-list-tutor__container">
                <div className="participant-list-tutor__header">
                    <div className="participant-list-tutor__logo" />
                    <div className="participant-list-tutor__information">
                        <div className="heading__Level2">
                            {props.participantList.courseTitle}
                        </div>
                        <table className="participant-list-tutor_information-table">
                            <tbody>
                                <tr>
                                    <th><Translate>ParticipantListTutor:Dates</Translate></th>
                                    <td>{StringHelper.dateTimeString(props.participantList.startDate)} - {StringHelper.dateTimeString(props.participantList.endDate)}</td>
                                </tr>
                                {props.participantList.venues && props.participantList.venues.length > 0 &&
                                    <tr>
                                        <th><Translate>ParticipantListTutor:Venue</Translate></th>
                                        <td>{props.participantList.venues.join("\n")}</td>
                                    </tr>
                                }
                                {props.participantList.trainers && props.participantList.trainers.length > 0 &&
                                    <tr>
                                        <th><Translate>ParticipantListTutor:Trainers</Translate></th>
                                        <td>{trainers}</td>
                                    </tr>
                                }
                                {props.participantList.attributes && props.participantList.attributes.length > 0 &&
                                    <tr>
                                        <th><Translate>ParticipantListTutor:Information</Translate></th>
                                        <td>
                                            {props.participantList.attributes.map(attribute =>
                                                <div key={'attribute_' + attribute.id}>{attribute.value}</div>)}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {!isLoading &&
                    <>
                        <table className="participant-list-tutor__user-table">
                            <thead>
                                {renderUserTableHeader()}
                            </thead>
                            <tbody>
                                {props.participantList.users.map(user => renderUserTableItem(user))}
                            </tbody>
                        </table>
                        <div className="participant-list-tutor__footer">{showSignatureColumn && tr.GetString('ParticipantListTutor:SignatureHint')}</div>
                    </>
                }
            </div>
        );
    }
    else {
        return null;
    }

}
