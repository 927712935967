import { JsonObject, JsonProperty } from 'json2typescript';

import { LessonStatusConverter } from '$src/storage/models/converters/LessonStatusConverter';
import { ELessonStatus } from '$storage/models/enums';

/** 
 * This class holds information about restrictions applied to an item (lesson or training plan) 
 * due to its assignment to the current user or one of his groups (without information about the item).
 */
@JsonObject
export class Assessment {
    
    /** true user can set lesson status is enabled. */
    @JsonProperty('userCanSetStatusEnabled', Boolean, false)
    public userCanSetStatusEnabled: boolean = false;

    /** Lesson status that will be set by the user. */
    @JsonProperty('setToLessonStatus', LessonStatusConverter, false)
    public setToLessonStatus: ELessonStatus = 0;
}
