import React, { useState, useEffect } from 'react';

import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import PrivacyService from '$src/core/Services/PrivacyService';
import { isSuccess } from '$src/util/Result';
import { TCDocument } from '$src/storage/models/Privacy/TCDocument';
import GtError from '$src/util/GtError';
import GtConfirmButtonWithModal from '$src/components/shared/Atoms/GtConfirmButtonWithModal';
import GTButton, { EButtonSize } from '$src/components/shared/Atoms/GTButton';


/**
 * Mandatory popup for pricacy statement
 */
export default function PrivacyPopUp() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [hasDownloaded, setHasDownloaded] = useState<boolean>(false);
    const [tcDocument, setTCDocument] = useState<TCDocument>();

    useEffect(() => {
        setErrorMessage(null);
        if (Session.instance.getHasOutdatedTermsAndConditions) {
            setOpenModal(true);
        }
    }, []);

    useEffect(() => {
        (async function loadTcDocument() {
            const tc: TCDocument | GtError = await PrivacyService.instance.getCurrentTermsAndConditionsDocument();
        if (isSuccess(tc) && tc) {
            setTCDocument(tc as TCDocument);     
        } else {
            console.error('No TC Document found.');
            setErrorMessage('MyPrivacy:Error_PopUp')
        }
        })();
    }, []);

    /**
     * Decline terms and conditions and logout
     */
    const decline = async (tcDocumentId: number) => {
        setModalIsLoading(true);
        const res = await PrivacyService.instance.declineTermsAndConditions(tcDocumentId);

        if (isSuccess(res)) {
            Session.instance.logout();
        } else {
            setModalIsLoading(false);
            setErrorMessage('MyPrivacy:Error_PopUp');
        }
    }

    /**
     * Approve terms and conditions and close modal
     */
    const approve = async (tcDocumentId: number) => {
        setModalIsLoading(true);
        const res = await PrivacyService.instance.approveTermsAndConditions(tcDocumentId);

        if (isSuccess(res)) {
            Session.instance.setHasOutdatedTermsAndConditions(false);
            setOpenModal(false);
        } else {
            setModalIsLoading(false);
            setErrorMessage('MyPrivacy:Error_PopUp');
        }
    }

    /**
     * Downloads the current terms and conditions document
     */
    const download = () => {
        setModalIsLoading(true);
        if (tcDocument) {
            PrivacyService.instance.downloadTermsAndConditions(tcDocument);
            setHasDownloaded(true);          
        } else {
            setErrorMessage('MyPrivacy:Error_PopUp')
        }
        setModalIsLoading(false);
    }

    /**
     * Renders approve and decline button
     */
    const UseModalButtons = () => {
        return <>
            {tcDocument && <div className="my-privacy__privacy-modal-buttonContainer">
                <GtConfirmButtonWithModal
                    isPrimary={false} 
                    buttonSize={EButtonSize.small} 
                    modalTitle="" 
                    modalText={"MyPrivacy:ConfirmDecline"} 
                    onClick={() => decline(tcDocument.id)}>
                <Translate>MyPrivacy:PrivacyDoDecline</Translate>
                </GtConfirmButtonWithModal>
                <GTButton isPrimary={true} buttonSize={EButtonSize.small} onClick={() => approve(tcDocument.id)}
                    id="btnPrivacyAccept">
                    <Translate>MyPrivacy:PrivacyDoApprove</Translate>
                </GTButton>           
            </div>}
        </>;
    }
    
    return (
        <ModalPopup
            isOpen={openModal} 
            disableClosing={true} 
            onRequestClose={() => { console.log('closing disabled'); }} 
            contentCssClass="my-privacy__privacy-modal-content" >
            <p>
                <Translate>MyPrivacy:PrivacyPopUpText</Translate>
            </p>
            <button className='link-button' onClick={() => download()}>
                <Translate>MyPrivacy:PrivacyPopUpDocumentButton</Translate>
            </button>
            <p>
                {hasDownloaded && <Alert alertAppereance="single-line" alertType="success" message="MyPrivacy:DocumentDownloaded" />}
            </p>

            {modalIsLoading ? <ProgressSpinner /> : <UseModalButtons />}
            {errorMessage && <Alert alertAppereance="single-line" alertType="error" message={errorMessage} />}
        </ModalPopup>
    )
}