import ServiceClient from "../ServiceClient";
import GtError from "$src/util/GtError";
import { PowerBiEmbedConfiguration } from "$src/storage/models/PowerBiEmbedConfiguration";

/**
 * Service for Power BI reports
 */
export default class PowerBiService extends ServiceClient {
    protected static _instance: PowerBiService | null = null;

    protected className = 'PowerBiService';
    protected loggerLocality = 'PowerBiService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PowerBiService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Returns the available Power BI reports for the user
     * In preperation for further reports - for now it only returns the default report
     */
    public async getAvailableReports(): Promise<string[] | GtError> {
        const response: string[] | GtError = await this.get<string[]>('powerBiReports/availableReportNames', String, undefined, undefined);
        return response;
    }

    /**
     * Gets the Power BI report configuration with the given name
     */
    public async getReportConfig(name: string): Promise<PowerBiEmbedConfiguration | GtError> {
        const response: PowerBiEmbedConfiguration | GtError = await this.get<PowerBiEmbedConfiguration>('powerBiReports/reportConfig', PowerBiEmbedConfiguration, undefined, [{ name: 'name', value: name }]);
        return response;
    }

} 