import React from 'react';

import Session from '$core/Session';
import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    trainingPlanElement: TrainingPlanElement;
    className: string;
    icon: string;
}

/**
 * @deprecated The method should not be used
 */
export default function ToolbarTrainingPlanModul(props: IProps) {
    const tr = Session.instance.storage.translation;
    let hoverText = tr.GetString('TrainingPlan:ModuleContent');
    hoverText = hoverText + ': ' + props.trainingPlanElement.childElements;
    return (
        <div className={props.className}
            aria-label={hoverText}
            data-tip={hoverText}
            data-for={`${props.trainingPlanElement.tpeId}_tpModulContentTooltip`}>
            <InlineSVG src={props.icon} />
            <span>{props.trainingPlanElement.childElements}</span>
            <Tooltip id={`${props.trainingPlanElement.tpeId}_tpModulContentTooltip`} />
        </div>
    )
}
