import { Heading } from '$components/shared/Heading';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import SkillService from '$src/core/Services/SkillService';
import Session from '$src/core/Session';
import SkillLow from '$resources/svgs/skills/skill-low.svg';
import SkillMet from '$resources/svgs/skills/skill-met.svg';
import SkillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import skillExpired from '$resources/svgs/skills/skill-expired.svg';
import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import { UserSkillProfile } from '$src/storage/models/SkillProfile/UserSkillProfile';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import { KeyPressHelper } from '$src/util/KeyPressHelper';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { UnmountClosed } from 'react-collapse';
import { ESkillStatus } from '$src/storage/models/enums';
import { Tooltip } from '$components/shared/Tooltip';
import { UserSkillProfileSkillContainer } from '$src/storage/models/SkillProfile/UserSkillProfileSkillContainer';
import { useHistory } from 'react-router';

interface IProps {
    userId?: number;
    isBossView?: boolean;
    userSkillProfiles?: UserSkillProfile[];
    achievedUserSkills?: UserSkill[];
    parentHeadingLevel: number;
    bossRelationCode?: string;
}

interface IPanelState {
    panelId: number;
    isOpened: boolean;
}

interface IState {
    isDataLoaded: boolean;
    userSkillProfiles: UserSkillProfile[];
    achievedUserSkills: UserSkill[];
    errorMessage: string;
    profilePanel: IPanelState[];
}

export class PerformanceReview extends React.Component<IProps, IState> {
    // Id for the "not associated" fake skill profile used for target skills
    protected notAssociatedSkillProfile: number = 9999;

    constructor(props: IProps) {
        super(props);

        this.state = {
            achievedUserSkills: this.props.achievedUserSkills ? this.props.achievedUserSkills : [],
            errorMessage: '',
            isDataLoaded: false,
            profilePanel: [],
            userSkillProfiles: this.props.userSkillProfiles ? this.props.userSkillProfiles : []
        }
    }

    public async componentDidMount() {
        if (!this.props.isBossView) {
            document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('PerformanceReview:Title');
        }
        const allPromises = new Array<Promise<unknown>>();
        if (!this.props.achievedUserSkills) {
            allPromises.push(this.getUserSkills());
        }
        if (!this.props.userSkillProfiles) {
            allPromises.push(this.getUserSkillProfile());
        }

        allPromises.push(this.getUserTargetSkills());

        await Promise.all(allPromises);
        this.setProfilePanel();
        this.setState({ isDataLoaded: true });
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return (
                <ProgressSpinner />
            )
        }
        else if (this.state.userSkillProfiles.length === 0) {
            if (this.props.isBossView) {
                return (
                    <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceReview:NoSkillsFound')} />
                )
            } else {
                return (
                    <div className="l-container">
                        <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceReview:NoSkillsFound')} />
                    </div>
                )
            }
        } else {
            return this.renderStripedProfiles(this.state.userSkillProfiles)
        }
    }

    private renderStripedProfiles(profiles: UserSkillProfile[]): JSX.Element[] {
        const elements: JSX.Element[] = [];
        profiles.map(profile => {
            const panelState = this.state.profilePanel.find(pnl => pnl.panelId === profile.userSkillProfileId);
            const isOpened = panelState ? panelState.isOpened : true;
            if (this.props.isBossView) {
                elements.push(
                    <div className="performanceReview__skillProfile-box" key={profile.userSkillProfileId}>
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level3">
                            {profile.skillProfileTitle}
                        </Heading>

                        {this.renderSkills(profile.skillProfileId, profile.skillContainer?.userSkillProfileSkills)}
                    </div>
                )
            } else {
                elements.push(
                    <div className="l-element--striped" key={profile.userSkillProfileId}>
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                onToggleOpenedState={() => this.toggleProfile(profile.userSkillProfileId)}
                                isOpened={isOpened}>
                                {profile.skillProfileTitle}
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={isOpened}>
                                {this.renderSkills(profile.skillProfileId, profile.skillContainer?.userSkillProfileSkills)}
                            </UnmountClosed>
                        </div>
                    </div>
                )
            }
        });

        return elements;
    }

    private renderSkills(profileId: number, skills?: UserSkillProfileSkill[]): JSX.Element {
        if(!globalConfig.targetProfiles.sortAlphabetical) {
            skills?.sort((skill1, skill2) => skill1.sortOrder - skill2.sortOrder);
        }
        return (
            <ul className="performanceReview__skill-list">
                {skills?.map(sk =>
                    this.renderSkill(sk, profileId)
                )}
            </ul>
        )
    }

    private renderSkill(skill: UserSkillProfileSkill, profileId: number): JSX.Element {
        // The status stlye not received is set as standard
        let skillCssStatus = 'status__not-received';
        let iconName = SkillNotMet;
        let text = Session.instance.storage.translation.GetString('PerformanceReview:SkillNotReceived');
        const userSkill = this.state.achievedUserSkills.find(usk => usk.skillId === skill.skillId);
        if (userSkill != null) {
            // If the user has the skill, the status style in work is set
            if (userSkill.skillStatus == ESkillStatus.Expired) {
                iconName = skillExpired;
                skillCssStatus = 'status__in-work--expired';
                text = Session.instance.storage.translation.GetString('PerformanceReview:SkillExpired').Format(userSkill.skillLevelTitle);
            } else {
                iconName = SkillLow;
                skillCssStatus = 'status__in-work';
                text = Session.instance.storage.translation.GetString('PerformanceReview:SkillLevel').Format(userSkill.skillLevelTitle);
            }

            // If the user has the skill with the required or higher skill level value, the status style achieved is set
            if (userSkill.skillLevelValue >= skill.skillProfileSkillLevelValue) {
                if (userSkill.skillStatus == ESkillStatus.Expired) {
                    iconName = skillExpired;
                    skillCssStatus = 'status__achieved--expired';
                    text = Session.instance.storage.translation.GetString('PerformanceReview:SkillExpired').Format(userSkill.skillLevelTitle);
                } else {
                    iconName = SkillMet;
                    skillCssStatus = 'status__achieved';
                    text = Session.instance.storage.translation.GetString('PerformanceReview:SkillLevel').Format(userSkill.skillLevelTitle);
                }
            }
        }
        return (<PerformanceReviewItem 
                    skill={skill} 
                    skillCssStatus={skillCssStatus} 
                    profileId={profileId} 
                    text={text} 
                    iconName={iconName}
                    userId={this.props.userId}
                    bossRelationCode={this.props.bossRelationCode} />)
    }

    private async getUserSkillProfile(): Promise<void> {
        const response = await Session.instance.storage.userSkillProfile.getPerformanceReviewProfiles(this.props.userId, this.props.bossRelationCode);
        const errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadProfiles');
        if (isSuccess<UserSkillProfile[] | null>(response)) {
            if (response != null) {
                this.setState(prevState => ({ userSkillProfiles: response.concat(prevState.userSkillProfiles) }));
            } else {
                Session.instance.setLastErrorMessage(errorMessage);
                this.setState({ errorMessage })
            }

        } else {
            Session.instance.setLastErrorMessage(errorMessage);
            this.setState({ errorMessage })
        }
    }

    private async getUserTargetSkills(): Promise<void> {
        const response = await Session.instance.storage.userTargetSkill.getPerformanceReviewTargetSkills(this.props.userId, this.props.bossRelationCode);
        const errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadTargetSkills');
        if (isSuccess<UserSkillProfileSkillContainer | null>(response)) {
            if (response != null && response.userSkillProfileSkills.length > 0) {
                // Add Target Skills as a not associated profile in user skill profiles
                const userSkillProfile = new UserSkillProfile();
                userSkillProfile.skillProfileId = this.notAssociatedSkillProfile;
                userSkillProfile.skillProfileTitle = Session.instance.storage.translation.GetString('PerformanceReview:NotAssociatedSkills');
                userSkillProfile.skillContainer = response;
                this.setState(prevState => ({ userSkillProfiles: prevState.userSkillProfiles.concat(userSkillProfile) }));
            } else {
                Session.instance.setLastErrorMessage(errorMessage);
                this.setState({ errorMessage })
            }

        } else {
            Session.instance.setLastErrorMessage(errorMessage);
            this.setState({ errorMessage })
        }
    }

    private setProfilePanel(): void {
        const profilePanel: IPanelState[] = this.state.profilePanel;
        this.state.userSkillProfiles.map(profile => {
            profilePanel.push({ panelId: profile.userSkillProfileId, isOpened: true });
        });
    }

    private async getUserSkills(): Promise<void> {
        const lang = Session.instance.getUserLanguageCodeOrFallBack;
        const userId = this.props.userId ? this.props.userId : Session.instance.loginUser!.id;
        const response = await SkillService.instance.getUserSkills(lang, userId);
        if (isSuccess<UserSkill[]>(response)) {
            this.setState({
                achievedUserSkills: response
            })
        }
        else {
            Session.instance.setLastErrorMessage(response.message);
            this.setState({ errorMessage: response.message });
        }
    }

    private toggleProfile(profileId: number) {
        const profilePanel: IPanelState[] = this.state.profilePanel;
        const panel = profilePanel.find(pnl => pnl.panelId === profileId);
        panel!.isOpened = !panel!.isOpened;
        this.setState({ profilePanel });
    }
}

const PerformanceReviewItem: React.FC<{
    skill: UserSkillProfileSkill;
    skillCssStatus: string;
    profileId: number;
    text: string;
    iconName: string;
    userId?: number;
    bossRelationCode?: string;
}> = (props: { skill: UserSkillProfileSkill; skillCssStatus: string; profileId: number; text: string; iconName: string; userId: number; bossRelationCode: string }) => {
    const history = useHistory();

    const onClick = (skillId: number, profileId: number, userId?: number, bossRelationCode?: string) => {
        let detailUrl = `/performanceReviewDetail/${skillId}?profileId=${profileId}`;
        if (userId) {
            detailUrl += `&userId=${userId}&bossRelationCode=${bossRelationCode}`;
        }
        history.push(detailUrl);
    }

    return <li key={props.skill.skillId} className={`performanceReview__skill-box ${props.skillCssStatus}`}
        onClick={() => onClick(props.skill.skillId, props.profileId, props.userId, props.bossRelationCode)}
        onKeyUp={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => onClick(props.skill.skillId, props.profileId, props.userId, props.bossRelationCode)) }}>
        <div className="performanceReview-title__icon__small">
            <div className="performanceReview-title__icon__small__tooltip"
                aria-label={props.text}
                data-tip={props.text}
                data-for={`skillStatusIconTooltip_${props.profileId}${props.skill.skillId}`}
                role="img">
                <InlineSVG aria-label={props.text} src={props.iconName} className={props.skillCssStatus} />
                <Tooltip id={`skillStatusIconTooltip_${props.profileId}${props.skill.skillId}`} />
            </div>
        </div>
        <div className="performanceReview__skill-box__text">{props.skill.skillTitle.substring(0,85)}{props.skill.skillTitle.length >= 85 && "..."}</div>
    </li>
}