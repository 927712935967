import React from 'react';

import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import IconAndText from '$src/components/shared/Atoms/IconAndText';
import IconChildCount from '$resources/svgs/toolbar/lessons-included.svg';
import TranslationStorage from '$src/storage/TranslationStorage';

interface IProps {
    trainingPlanElement: TrainingPlanElement;
    translationStorage: TranslationStorage;
}

export default function ToolbarTrainingPlanModulChildCount(props: IProps) {
    let hoverText = props.translationStorage.GetString('TrainingPlan:ModuleContent');
    hoverText = hoverText + ': ' + props.trainingPlanElement.childElements;
    return <IconAndText
    id={'ttpm_childcount_' + props.trainingPlanElement.sId}
    icon={IconChildCount}
    ariaLabel={hoverText}
    tooltipText={hoverText}
    iconText={props.trainingPlanElement.childElements.toString()}
    cssClass="child-count"
/>
}
