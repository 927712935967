import { ItemPurchaseValidationRequest } from '$src/storage/models/Purchase/ItemPurchaseValidationRequest';
import { ItemPurchaseValidationResponse } from '$src/storage/models/Purchase/ItemPurchaseValidationResponse';
import GtError from '$src/util/GtError';
import ServiceClient from '$core/ServiceClient';
import { PaymentBookingOverviewResponse } from '$src/storage/models/Purchase/PaymentBookingOverviewResponse';
import { VoucherValidity } from '$src/storage/models/Purchase/VoucherValidity';
import ShoppingBasket from '$src/storage/models/ShoppingBasket/ShoppingBasket';
import { CheckoutRequest } from '$src/storage/models/Purchase/CheckoutRequest';
import { CheckoutResponse } from '$src/storage/models/Purchase/CheckoutResponse';
import { CheckoutItemsStatusResponse } from '$src/storage/models/Purchase/CheckoutItemsStatusResponse';
import { CheckoutStatusResponse } from '$src/storage/models/Purchase/CheckoutStatus';
import { PurchasedItem } from '$src/storage/models/Purchase/PurchasedItem';

/**
 * PurchaseService provides helper services that are 
 * used in the context of the purchase of learning contents.
 */
export default class PurchaseService extends ServiceClient {
    protected static _instance: PurchaseService | null = null;

    protected className = 'PurchaseService';
    protected loggerLocality = 'PurchaseService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PurchaseService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Validate that the given items (from the shopping basket) are still purchasable
     * @param ItemPurchaseValidationRequest 
     */
    public async validateBeforePurchase(itemPurchaseValidationRequests: ItemPurchaseValidationRequest[]): Promise<ItemPurchaseValidationResponse[] | GtError> {
        const resp = await this.post<ItemPurchaseValidationResponse[]>('purchase/validateBeforePurchase', itemPurchaseValidationRequests, ItemPurchaseValidationResponse, undefined);
        return resp;
    }

    /** 
     * Check whether the given voucher is valid and what its conditions are.
     * @param voucherCode 
     */
    public async checkVoucher(voucherCode: string): Promise<VoucherValidity | GtError> {
        const resp = await this.get<VoucherValidity>('purchase/checkVoucher', VoucherValidity, undefined, undefined, voucherCode);
        return resp;
    }

    /**
     * Update the prices of the Items in the ShoppingBasket from the Database and 
     * calculate the total and discount based on the optional voucher code.
     * The purpose of this API is to make the price calculation tamper-proof and undo any
     * manipulation that might have been done on the persistent shopping basket in local storage.
     * @param ItemPurchaseValidationRequest 
     */
    public async getVerifiedPrices(shoppingBasket: ShoppingBasket): Promise<ShoppingBasket | GtError> {
        const resp = await this.post<ShoppingBasket>('purchase/getVerifiedPrices', shoppingBasket, ShoppingBasket, undefined);
        return resp;
    }

    /**
     * Start a purchase 
     * Requests with a 100% voucher can be completed during the request.
     * If a payment is needed, a payment provider url is returned and the payment must be done for the purchase to complete.
     * @param checkoutRequest
     */
    public async checkout(checkoutRequest: CheckoutRequest): Promise<CheckoutResponse | GtError> {
        const resp = await this.post<CheckoutResponse>('purchase/checkout', checkoutRequest, CheckoutResponse, undefined);
        return resp;
    }

    /**
     * Get the Items purchased with given referenceCode
     * @param referenceCode
     */
    public async getPurchasedItems(referenceCode: string): Promise<PurchasedItem[] | GtError> {
        const resp = await this.get<PurchasedItem[]>('purchase/getPurchasedItems', PurchasedItem, undefined, undefined, referenceCode);
        return resp;
    }

    /**
     * Get the registration status of purchased items with given referenceCode
     * @param referenceCode
     */
     public async getPurchasedCheckoutItems(referenceCode: string): Promise<CheckoutItemsStatusResponse[] | GtError> {
        const resp = await this.get<CheckoutItemsStatusResponse[]>('purchase/getPurchasedCheckoutItems', CheckoutItemsStatusResponse, undefined, undefined, referenceCode);
        return resp;
    }

    /**
     * Get the checkout status for a given referenceCode
     * @param referenceCode
     */
    public async getCheckoutStatus(referenceCode: string): Promise<CheckoutStatusResponse | GtError> {
        const resp = await this.get<CheckoutStatusResponse>('purchase/getCheckoutStatus', CheckoutStatusResponse, undefined, undefined, referenceCode);
        return resp;
    }

    /**
     * Returns the PaymentCheckouts as report for the booking overview page
     * @returns {(Promise<PaymentBookingOverviewResponse[] | GtError>)} List of prepared booking overview data
     * @memberof PurchaseService
     */
    public async getBookingOverview(skip: number, take: number, filterParams?: URLSearchParams): Promise<PaymentBookingOverviewResponse | GtError> {
        const filterParamsArray: {name: string; value: string}[] = [];
        if (filterParams) {
            filterParams.forEach((v, k) => filterParamsArray.push({name: k, value: v}));
        }

        const resp = await this.get<PaymentBookingOverviewResponse>("purchase/bookingOverview", PaymentBookingOverviewResponse, undefined, [
            { name: 'skip', value: skip.toString() },
            { name: 'take', value: take.toString() },
            ...filterParamsArray
        ],);
        return resp;
    }

    /**
     * Returns the PaymentCheckouts as export for the booking overview
     * @returns {(Promise<string | GtError>)} Export file of prepared booking overview data
     * @memberof PurchaseService
     */
     public async getBookingOverviewExport(filterParams?: URLSearchParams): Promise<string | GtError> {
        const filterParamsArray: {name: string; value: string}[] = [];
        if (filterParams) {
            filterParams.forEach((v, k) => filterParamsArray.push({name: k, value: v}));
        }

        const resp = await this.get<string>("purchase/bookingOverviewExport", String, undefined, [
            ...filterParamsArray
        ],);

        return resp;
    }
}