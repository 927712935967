
import { JsonObject, JsonProperty } from 'json2typescript';
import { ClassFileDataRequest } from './ClassFileDataRequest';

@JsonObject
export class ClassDocumentUploadRequest {

    @JsonProperty('classId', Number, true)
    public classId: number = 0;

    @JsonProperty('documents', [ClassFileDataRequest], false)
    public documents: ClassFileDataRequest[] = [];
}