import React from 'react';

import { ReportNavigationPoint } from '$components/layout/menu/ReportNavigationPoint';
import { NavigationPointContainer } from '$src/components/layout/menu/NavigationPointContainer';
import { NavigationPoint } from '$src/components/layout/menu/NavigationPoint';

interface IProps {
    bossRelationCode?: string;
    reports: Array<{
        id: string;
        text: string;
        reportName: string;
        reportMode?: string;
        backgroundColor?: string;
        iconColor?: string;
        userId?: number;
    }>;
    additionalReports?: Array<{
        id: string;
        text: string;
        link: string;
        backgroundColor?: string;
        iconColor?: string;
    }>;
}
/**
 * Displays a list of LMS SUI Reports
 */
export class ReportList extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        if (this.props.reports != null && this.props.reports.length > 0 || this.props.additionalReports != null && this.props.additionalReports.length > 0) {
            return <NavigationPointContainer>
               {this.renderReports()}
               {this.renderAdditionalReports()}
            </NavigationPointContainer>
        }
        return null;
    }

    private renderReports(): JSX.Element[] | null{
        const elements: JSX.Element[] = [];

        this.props.reports.map(r => {
            elements.push(<ReportNavigationPoint
                key={r.id}
                text={r.text}
                reportName={r.reportName}
                reportMode={r.reportMode || ''}
                id={r.id}
                backgroundColor={r.backgroundColor}
                iconColor={r.iconColor}
                userId={r.userId}
                bossRelationCode={this.props.bossRelationCode} />
            )
        })

        return elements;
    }

    private renderAdditionalReports(): JSX.Element[] | null {
        const elements: JSX.Element[] = [];

        if (this.props.additionalReports !== undefined) {
            this.props.additionalReports.map(report => {
                elements.push(<NavigationPoint
                    link={`${report.link}/${this.props.bossRelationCode}`} 
                    text={report.text}
                    key={report.id}
                    id={`Reporting_${report.id}`}
                    iconColor={report.iconColor}
                    backgroundColor={report.backgroundColor}
                    openNewWindow={false} />)
            })
        }
        return elements;
    }

}
export default ReportList;