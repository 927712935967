import React from 'react';

import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import PowerBiReportList from '$src/components/PowerBiReports/PowerBiReportList';
import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';

/**
 * Main container component for Power BI reports
 * @param props 
 */
export default function PowerBiReports() {
    return (
        <div>
            <div className="l-container">
                <MenuBreadCrumb />
                
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>{"Navigation:PowerBiReports"}</Translate>
                </Heading>               
            </div>
            
            <PowerBiReportList parentHeadingLevel={1} />
        </div>
    )
}