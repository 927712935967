import { UtcDateTimeConverter } from '$src/storage/models/converters/UtcDateTimeConverter';
import { Room } from '$src/storage/models/F2F/Room';
import { Trainer } from '$src/storage/models/F2F/Trainer';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class DateTimeSpan {
    
    /** Id of the corresponding date time span */
    @JsonProperty('dateTimeSpanId', Number, false)
    public dateTimeSpanId: number = 0;

    /** Class Id of the corresponding date time span */
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    /** Start date time of the corresponding date time span */
    @JsonProperty('startDateTimeUTC', UtcDateTimeConverter, false)
    public startDateTimeUTC: Date | undefined = undefined;

    /** End date time of the corresponding date time span */
    @JsonProperty('endDateTimeUTC', UtcDateTimeConverter, false)
    public endDateTimeUTC: Date | undefined = undefined;

    /** The Session Description (aka Class title in the AUI session table) */
    @JsonProperty('sessionDescription', String, false)
    public sessionDescription: string = '';

    /** Trainers list of the corresponding date time span */
    @JsonProperty('trainers', [Trainer], false)
    public trainers: Trainer[] = [];

    /** Rooms list of the corresponding date time span */
    @JsonProperty('rooms', [Room], false)
    public rooms: Room[] = [];

}