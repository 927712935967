import { JsonObject, JsonProperty } from 'json2typescript';
import { LessonStatusConverter } from '../converters/LessonStatusConverter';
import { ELessonStatus } from '../enums';
import { TrainingPlanCompletitionRequirement } from './TrainingPlanCompletitionRequirement';


@JsonObject
export class TrainingPlanCompletitionRequirementStatus {

    @JsonProperty('completitionRequirement', TrainingPlanCompletitionRequirement, false)
    public completitionRequirement: TrainingPlanCompletitionRequirement | undefined = undefined;

    @JsonProperty('requiredItemTitle', String, false)
    public requiredItemTitle: string = '';

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('score', Number, false)
    public score: number = 0;

    @JsonProperty('status', LessonStatusConverter, false)
    public status: ELessonStatus = ELessonStatus.Undefined;

    @JsonProperty('fulfilled', Boolean, false)
    public fulfilled: boolean = false;
}

