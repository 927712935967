import ServiceClient from '$core/ServiceClient';
import Logger from '$src/core/Logger';
import { FileSharingLesson } from '$src/storage/models/FileSharing/FileSharingLesson';
import { MyFileSharing } from '$src/storage/models/FileSharing/MyFileSharing';
import { ScheduleCode } from '$src/storage/models/FileSharing/ScheduleCode';
import GtError from '$util/GtError';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { StringResponse } from '$src/storage/models/StringResponse';
import { FileSharingDocument } from '$src/storage/models/FileSharing/FileSharingDocument';
import { CreateFileSharingTagRequest } from '$src/storage/models/RequestObjects/FileSharing/CreateFileSharingTagRequest';
import { AddOrRemoveFileSharingTagRequest } from '$src/storage/models/RequestObjects/FileSharing/AddOrRemoveFileSharingTagRequest';
import { DeleteFileSharingTagRequest } from '$src/storage/models/RequestObjects/FileSharing/DeleteFileSharingTagRequest';
import { StoreFileSharingDocumentOrderRequest } from '$src/storage/models/RequestObjects/FileSharing/StoreFileSharingDocumentOrderRequest';

/**
 * FileSharingService provides all Service Methods for the FileSharing 
 */
export default class FileSharingService extends ServiceClient {
    protected static _instance: FileSharingService | null = null;

    protected className = 'FileSharingService';
    protected loggerLocality = 'FileSharingService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): FileSharingService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get FileSharing lesson including documents grouped by user and their document infos from web API.
     * @param itemId lesson id of FileSharing lesson
     * @returns {(Promise<FileSharingUser[] | GtError>)}
     * @memberof FileSharingService
     */
    public async getFileSharingLesson(itemId: number, assignmentId: number, tPlanId: number, scheduleId?: number): Promise<FileSharingLesson | GtError> {
        let response: FileSharingLesson | GtError;
        const queryParams = [
            { name: 'assignmentId', value: assignmentId > 0 ? assignmentId.toString() : '' },
            { name: 'tPlanId', value: tPlanId > 0 ? tPlanId.toString() : '' },
            { name: 'scheduleId', value: scheduleId ? scheduleId.toString() : '' }
        ];
        Logger.log(this.loggerLocality, 'getFileSharingLesson()', 'itemId=', itemId, 'queryParams=', queryParams);
        response = await this.get<FileSharingLesson>('filesharing', FileSharingLesson, undefined, queryParams, itemId.toString());
        return response;
    }


    /**
     * Get a download token for downloading a specific file
     * @param fileSharingId id of FileSharingDocument record
     * @param itemId lesson id of FileSharing lesson
     * @returns {(Promise<String | GtError>)}
     * @memberof FileSharingService
     */
    public async getDownloadToken(documentIds: number[], itemId: number, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<StringResponse | GtError> {
        const data: any = {
            documentIds,
            itemId
        };
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        Logger.log(this.loggerLocality, 'getDownloadToken()', 'documentIds=', documentIds, 'itemId=', itemId, 'queryParams=', queryParams);
        return await this.post<StringResponse>('filesharing/getDownloadToken', data, StringResponse, undefined, queryParams); 
    }

    /**
     * Uploads a FileSharingDocument with data as Base64 string
     * @param {FormData} request Including the ids and data
     * @returns {(Promise<Number | GtError>)} Id of the database FileSharingDocument entry
     * @memberof FileSharingService
     */
    public async postFile(request: FormData): Promise<FileSharingDocument | GtError> {
        Logger.log(this.loggerLocality, 'postFile()', 'file=', request);
        return await this.postFormData<FileSharingDocument>('filesharing/upload', request, FileSharingDocument);
    }

    /**
     * Deletes a FileSharingDocument entry in the database
     * @param fileSharingId id of FileSharingDocument record
     * @param itemId lesson id of FileSharing lesson
     * @returns {(Promise<Boolean | GtError>)}
     * @memberof FileSharingService
     */
    public async deleteDocument(fileSharingId: number, itemId: number, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const data: any = {
            documentIds: [ fileSharingId ],
            itemId
        };
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        Logger.log(this.loggerLocality, 'deleteDocument()', 'data=', data, 'queryParams=', queryParams);
        return await this.post<BooleanResponse>('filesharing/delete', data, BooleanResponse, undefined, queryParams);
    }

     /**
     * Checks if a file already exists
     * @returns {(Promise<Boolean | GtError>)}
     * @memberof FileSharingService
     */
    public async existsDocument(itemId: number, userId: number, fileName: string, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const queryParams = [
            { name: 'fileName', value: (fileName).toString() },
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        Logger.log(this.loggerLocality, 'existsDocument()', 'queryParams=', queryParams);
        return await this.get<BooleanResponse>('filesharing/fileexists', BooleanResponse, undefined, queryParams, itemId.toString(), userId.toString());
    }

    /**
     * Returns a list of file sharing lessons the user has access to
     * @returns {(Promise<MyFileSharing[] | GtError>)}
     * @memberof FileSharingService
     */
    public async getMyFileSharings(): Promise<MyFileSharing[] | GtError> {
        Logger.log(this.loggerLocality, 'getMyFileSharings()');
        return await this.get<MyFileSharing[]>('filesharing/myfilesharings', MyFileSharing, undefined, undefined);
    }

    /**
     * Returns a list of schedule ids the user has tutorize rights on.
     * Does the user has tutorize rights on training plan level, all schedules will be returned
     * @param {number} tPlanId
     * @returns {(Promise<Array<[boolean, number]> | GtError>)}
     * @memberof FileSharingService
     */
    public async getMyTutorizeSchedules(tPlanId: number): Promise<ScheduleCode[] | GtError> {
        const queryParams = [
            { name: 'tPlanId', value: tPlanId.toString() },
        ];
        Logger.log(this.loggerLocality, 'getMyTutorizeSchedules()', 'tPlanId=', tPlanId);
        return await this.get<ScheduleCode[]>('filesharing/myTutorizeSchedules', ScheduleCode, undefined, queryParams);
    }

    /**
     * Create a new tag for a file sharing lesson which is then available for selection
     * This function is only available for tutors
     *
     * @param {number} itemId
     * @param {CreateFileSharingTagRequest} request
     * @param {(number | null)} assignmentId
     * @param {(number | null)} tPlanId
     * @param {(number | null)} scheduleId
     * @return {*}  {(Promise<BooleanResponse | GtError>)}
     * @memberof FileSharingService
     */
    public async createTag(itemId: number, request: CreateFileSharingTagRequest, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        return await this.post<BooleanResponse>(`filesharing/createTag/${itemId}`, request, BooleanResponse, undefined ,queryParams);
    }

    /**
     * Delete a file sharing tag (also the relation to document if it was already in use)
     * This function is only available for tutors
     *
     * @param {number} itemId
     * @param {DeleteFileSharingTagRequest} request
     * @param {(number | null)} assignmentId
     * @param {(number | null)} tPlanId
     * @param {(number | null)} scheduleId
     * @return {*}  {(Promise<BooleanResponse | GtError>)}
     * @memberof FileSharingService
     */
    public async deleteTag(itemId: number, request: DeleteFileSharingTagRequest, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        return await this.post<BooleanResponse>(`filesharing/deleteTag/${itemId}`, request, BooleanResponse, undefined ,queryParams);
    }

    /**
     * Associate a file sharing tag to a file sharing document
     *
     * @param {number} itemId
     * @param {number} fsDocumentId
     * @param {AddOrRemoveFileSharingTagRequest} request
     * @param {(number | null)} assignmentId
     * @param {(number | null)} tPlanId
     * @param {(number | null)} scheduleId
     * @return {*}  {(Promise<BooleanResponse | GtError>)}
     * @memberof FileSharingService
     */
    public async addTagToDocument(itemId: number, fsDocumentId: number, request: AddOrRemoveFileSharingTagRequest, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        return await this.post<BooleanResponse>(`filesharing/addTagToDocument/${itemId}/${fsDocumentId}`, request, BooleanResponse, undefined ,queryParams);
    }

    /**
     * Remove a file sharing tag from a file sharing document
     *
     * @param {number} itemId
     * @param {number} fsDocumentId
     * @param {AddOrRemoveFileSharingTagRequest} request
     * @param {(number | null)} assignmentId
     * @param {(number | null)} tPlanId
     * @param {(number | null)} scheduleId
     * @return {*}  {(Promise<BooleanResponse | GtError>)}
     * @memberof FileSharingService
     */
    public async removeTagFromDocument(itemId: number, fsDocumentId: number, request: AddOrRemoveFileSharingTagRequest, assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        return await this.post<BooleanResponse>(`filesharing/removeTagFromDocument/${itemId}/${fsDocumentId}`, request, BooleanResponse, undefined ,queryParams);
    }

    /**
     * Store the file sharing order
     *
     * @param {number} itemId
     * @param {StoreFileSharingDocumentOrderRequest} request
     * @param {(number | null)} assignmentId
     * @param {(number | null)} tPlanId
     * @param {(number | null)} scheduleId
     * @return {*}  {(Promise<BooleanResponse | GtError>)}
     * @memberof FileSharingService
     */
    public async storeFileSharingDocumentOrder(itemId: number, request: StoreFileSharingDocumentOrderRequest[], assignmentId: number | null, tPlanId: number | null, scheduleId: number | null): Promise<BooleanResponse | GtError> {
        const queryParams = [
            { name: 'assignmentId', value: (assignmentId || '').toString() },
            { name: 'tPlanId', value: (tPlanId || '').toString() },
            { name: 'scheduleId', value: (scheduleId || '').toString() }
        ];
        return await this.post<BooleanResponse>(`filesharing/storeFileSharingDocumentOrder/${itemId}`, request, BooleanResponse, undefined ,queryParams);
    }
}