import { EFileType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class FileTypeConverter implements JsonCustomConvert<EFileType> {
    // from WebApi Models\Enums.cs: Undefined=0, Certificate=1, Confirmation=2
    public serialize(fileType: EFileType): number {
        switch (fileType) {
            case EFileType.Certificate: return 1;
            case EFileType.Confirmation: return 2;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EFileType {
        switch (jsonValue) {
            case 1: return EFileType.Certificate;
            case 2: return EFileType.Confirmation;
            default: return EFileType.Undefined;
        }
    }
}
