import { GlossaryTable } from '$src/components/glossary/GlossaryTable';
import { Heading } from '$src/components/shared/Heading';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import GlossaryService from '$src/core/Services/GlossaryService';
import Session from '$src/core/Session';
import { EGlossaryAssociationType, EItemDetailCallerContextType } from '$src/storage/models/enums';
import { Glossary } from '$src/storage/models/Glossary/Glossary';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { HeadingCollapsible } from '$src/components/shared/HeadingCollapsible';
import { UnmountClosed } from 'react-collapse';
import { GlossarIdForBredCrumb } from '$src/storage/models/Glossary/GlossarIdForBreadCrumb';
import GTSearch from '$components/shared/Atoms/GTSearch';

interface IProps {
    itemId: number;
    callerContext: EItemDetailCallerContextType;
    idsForGlossary: GlossarIdForBredCrumb;
}

interface IState {
    itmId: number;
    glossaries: Glossary[] | undefined;
    dataLoaded: boolean;
    glossaryCollapsed: boolean;
    callerContext: EItemDetailCallerContextType;
    idsForGlossary: GlossarIdForBredCrumb;
}

export class GlossaryList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            glossaries: undefined,
            itmId: 0,
            glossaryCollapsed: false,
            callerContext: EItemDetailCallerContextType.Undefined,
            idsForGlossary: new GlossarIdForBredCrumb()
        }
    }

    public async componentDidMount() {
        const itemId = this.props.itemId;

        if (itemId != null && itemId >= 1) {
            const result = await GlossaryService.instance.getGlossariesForItem(itemId, Session.instance.getUserLanguageCodeOrFallBack);
            if (isSuccess<Glossary[]>(result)) {
                this.setState({ itmId: itemId, glossaries: result, dataLoaded: true });
            } else {
                this.setState({ dataLoaded: true });
            }
        } else {
            const result = await GlossaryService.instance.getMyGlossaries(Session.instance.getUserLanguageCodeOrFallBack,"");
            if (isSuccess<Glossary[]>(result)) {
                this.setState({ glossaries: result, dataLoaded: true });
            } else {
                this.setState({ dataLoaded: true });
            }
        }

        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
        if (this.props.callerContext == undefined) {
            this.setState({ callerContext: EItemDetailCallerContextType.MyGlossary });
        } else {
            this.setState({ callerContext: this.props.callerContext });
        }

        if (this.props.idsForGlossary == undefined) {
            this.setState({
                idsForGlossary: {
                    itemId: 0,
                    asId: 0,
                    catId: 0,
                    tpId: 0,
                    tpeId: 0
                }
            })
        } else {
            this.setState({ idsForGlossary: this.props.idsForGlossary })
        }
    }

    public render() {
        if (this.props.itemId == null) {
            return (
                <div className="l-container" >
                    <MenuBreadCrumb {...this.props} />
                    <Heading headingLevel={1} cssClass="heading__Title">
                        <Translate>Glossary:Title</Translate>
                    </Heading>
                    <GTSearch
                        showSearchButton={true}
                        onSearchButtonClick={(searchText) => this.onSearch(searchText)}
                        defaultInputProps={{ "aria-label": Session.instance.storage.translation.GetString('Glossary:AriaSearch') }}
                    />
                    {this.renderGlossaries()}
                </div>
            );
        } else {
            if (this.state.glossaries != null && this.state.glossaries.length > 0) {
                return (
                    <div className="l-element--striped">
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.glossaryCollapsed}
                                onToggleOpenedState={() => this.setState({ glossaryCollapsed: !this.state.glossaryCollapsed })}>
                                <Translate>Glossary:Title</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed
                                isOpened={!this.state.glossaryCollapsed}>
                                {
                                    <div className="item-detail" >
                                        {this.renderGlossaries()}
                                    </div>
                                }
                            </UnmountClosed>
                        </div>
                    </div>

                );
            }
            return null;
        }
    }

    private renderGlossaries(): JSX.Element | JSX.Element[] {
        if (this.state.dataLoaded && this.state.glossaries !== undefined && this.state.glossaries.length > 0) {
            if (this.state.itmId !== 0 && this.state.glossaries !== undefined) {
                return this.renderItemGlossaries();
            } else {
                return (
                    <React.Fragment>
                        {this.renderConfigGlossaries()}
                        {this.renderGroupGlossaries()}
                        {this.renderMyGlossaries()}
                    </React.Fragment>
                );
            }
        } else if (this.state.dataLoaded === false) {
            return (<ProgressSpinner />);
        } else {
            return <NoDataFound message={Session.instance.storage.translation.GetString('Glossary:NoGlossariesFound')} />
        }
    }

    private renderItemGlossaries(): JSX.Element {
        if (this.state.glossaries !== undefined) {
            return (
                <React.Fragment>
                    <GlossaryTable glossaries={this.state.glossaries} callerContext={this.state.callerContext} idsForGlossary={this.state.idsForGlossary} />
                </React.Fragment>);

        } else {
            return <React.Fragment />;
        }
    }

    private renderConfigGlossaries(): JSX.Element | JSX.Element[] {
        if (this.state.glossaries !== undefined && this.state.glossaries.filter(g => g.associationType === EGlossaryAssociationType.Config).length > 0) {
            return (
                <React.Fragment>
                    <Heading headingLevel={2} cssClass="heading__Level2">
                        <Translate>Glossary:ConfigGlossaryTitle</Translate>
                    </Heading>
                    <GlossaryTable glossaries={this.state.glossaries.filter(g => g.associationType === EGlossaryAssociationType.Config)} callerContext={this.state.callerContext} idsForGlossary={this.state.idsForGlossary} />
                </React.Fragment>);

        } else {
            return <React.Fragment />;
        }
    }


    private renderGroupGlossaries(): JSX.Element | JSX.Element[] {
        if (this.state.glossaries !== undefined && this.state.glossaries.filter(g => g.associationType === EGlossaryAssociationType.Groups).length > 0) {
            return (
                <React.Fragment>
                    <Heading headingLevel={2} cssClass="heading__Level2">
                        <Translate>Glossary:GroupGlossaryTitle</Translate>
                    </Heading>
                    <GlossaryTable glossaries={this.state.glossaries.filter(g => g.associationType === EGlossaryAssociationType.Groups)} callerContext={this.state.callerContext} idsForGlossary={this.state.idsForGlossary} />
                </React.Fragment>);
        } else {
            return <React.Fragment />;
        }
    }

    private renderMyGlossaries(): JSX.Element | JSX.Element[] {
        if (this.state.glossaries !== undefined && this.state.glossaries.filter(g => g.associationType === EGlossaryAssociationType.UserPinned).length > 0) {
            return (
                <React.Fragment>
                    <Heading headingLevel={2} cssClass="heading__Level2">
                        <Translate>Glossary:MyGlossariesTitle</Translate>
                    </Heading>
                    <GlossaryTable glossaries={this.state.glossaries.filter(g => g.associationType === EGlossaryAssociationType.UserPinned)} callerContext={this.state.callerContext} idsForGlossary={this.state.idsForGlossary} />
                </React.Fragment>
            );
        } else {
            return <React.Fragment />;
        }
    }

    private async onSearch(searchText: string) {
        const result = await GlossaryService.instance.getMyGlossaries(Session.instance.getUserLanguageCodeOrFallBack, searchText);
        this.setState({glossaries: undefined});
        if (isSuccess<Glossary[]>(result)) {
            this.setState({ glossaries: result, dataLoaded: true });
        } else {
            this.setState({ dataLoaded: true });
        }
    }
}
export default GlossaryList;
