
import * as React from 'react';
import { UnmountClosed } from 'react-collapse';
import { Redirect, RouteComponentProps } from 'react-router';

import Mail from '$resources/svgs/myTeam/mail.svg';
import MobilePhone from '$resources/svgs/myTeam/mobilePhone.svg';
import Phone from '$resources/svgs/myTeam/phone.svg';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import ReportList from '$components/myEmployees/reporting/ReportList';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import UserDetails from '$components/userProfile/UserDetails';
import UserService from '$core/Services/UserService';
import Session from '$core/Session';
import SystemRoles from '$core/SystemRoles';
import { PerformanceReview } from '$src/components/performanceReview/PerformanceReview';
import { User } from '$storage/models/User';
import CustomErrorMessage from '$util/CustomErrorMessage';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import InlineSVG from 'react-inlinesvg';
import { PerformanceSupport } from '$src/components/performanceSupport/PerformanceSupport';
import { UserSkillProfile } from '$src/storage/models/SkillProfile/UserSkillProfile';
import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import { UserSkillProfileSkillContainer } from '$src/storage/models/SkillProfile/UserSkillProfileSkillContainer';
import { UserSkills } from '$src/components/Skills/UserSkills';
import { AssignSkillsModal } from '$src/components/myEmployees/ActionModals/AssignSkillsModal';
import { ESkillType, EItemDetailCallerContextType } from '$src/storage/models/enums';
import { AssignUserSkillResponse } from '$src/storage/models/AssignUserSkillResponse';
import { IPanelState } from "$storage/models/UserPreferences/IPanelState";
import { EmployeePasswordResetRequest } from '$src/storage/models/RequestObjects/EmployeePasswordResetRequest';
import TeamService from '$src/core/Services/TeamService';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { IAlertProps, Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import GTButton from '$src/components/shared/Atoms/GTButton';
import { RemoveSkillsModal } from '../ActionModals/RemoveSkillsModal';
import Logger from '$src/core/Logger';
import PerformanceCheckTable from '$src/components/Skills/PerformanceCheckTable';
import { PerformanceCheckTableItem } from '$src/components/Skills/PerformanceCheckItem';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';

interface IMatchParams {
    bossRelationCode: string;
    employeeId: string;
}

type IProps = RouteComponentProps<IMatchParams>

interface IState {
    isDataLoaded: boolean;
    userSkillProfiles: UserSkillProfile[];
    achievedUserSkills: UserSkill[];
    user?: User;
    errorMessage?: string;
    masterDataPanel: IPanelState;
    curriculumPanel: IPanelState;
    performanceSupportPanel: IPanelState;
    performanceReviewPanel: IPanelState;
    performanceCheckPanel: IPanelState;
    skillPanel: IPanelState;
    userTargetSkills: UserSkillProfileSkill[];
    skillsForPerformanceCheck: PerformanceCheckTableItem[];
    userTargetSkillContainer: UserSkillProfileSkillContainer;
    showAssignSkillsModal: boolean;
    showDeleteSkillsModal: boolean;
    passwordResetAlertMessage?: IAlertProps;
    showPasswordResetConfirmation: boolean;
    isDataLoadingForPerformanceCheck: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class EmployeeDetail extends React.Component<IProps, IState> {

    // Id for the "not associated" fake skill profile used for target skills
    protected notAssociatedSkillProfile: number = 9999;
    protected className = 'EmployeeDetail';
    protected loggerLocality = 'Components.UserProfile';
    protected employeeId = 0;

    protected curriculumIFrame: HTMLIFrameElement | null

    constructor(props: IProps) {
        super(props);

        const parameters = new URLSearchParams(window.location.search);

        const urlParamFocus = parameters.get('focus');

        this.state = {
            achievedUserSkills: [],
            curriculumPanel: { panelId: 'curriculumPanel', isOpened: urlParamFocus?.toLowerCase() === 'curriculumpanel' },
            errorMessage: undefined,
            isDataLoaded: false,
            masterDataPanel: { panelId: 'masterData', isOpened: urlParamFocus?.toLowerCase() === 'masterdata' },
            performanceSupportPanel: { panelId: 'performanceSupport', isOpened: urlParamFocus?.toLowerCase() === 'performancesupport' },
            performanceReviewPanel: { panelId: 'performanceReview', isOpened: urlParamFocus?.toLowerCase() === 'performancereview' },
            performanceCheckPanel: { panelId: 'performanceCheckPanel', isOpened: urlParamFocus?.toLowerCase() === 'performanceCheckPanel' },
            showAssignSkillsModal: false,
            skillPanel: { panelId: 'skills', isOpened: urlParamFocus?.toLowerCase() === 'skills' },
            user: undefined,
            userSkillProfiles: [],
            userTargetSkills: [],
            skillsForPerformanceCheck: [],
            userTargetSkillContainer: new UserSkillProfileSkillContainer(),
            showDeleteSkillsModal: false,
            showPasswordResetConfirmation: false,
            isDataLoadingForPerformanceCheck: false,
        };
        this.employeeId = Number.parseInt(this.props.match.params.employeeId);


    }

    public async componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('EmployeeDetail:PageTitle');
        const allPromises = new Array<Promise<unknown>>();
        allPromises.push(this.getUserSkillProfile());
        allPromises.push(this.getUserTargetSkills());
        allPromises.push(this.getSkillsForPerformanceCheck());
        allPromises.push(this.getUserSkills());
        allPromises.push(this.loadEmployee());

        await Promise.all(allPromises);
        this.setState({ isDataLoaded: true });
    }

    public render() {
        const user = this.state.user;
        if (Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)) {
            return <Redirect push={true} to="/" />;
        }
        if (!this.state.isDataLoaded) {
            return <ProgressSpinner />
        }
        else if (user != null) {
            return (
                <div className="employee-detail__container">
                    <div className="l-container">
                        <MenuBreadCrumb
                            key="MenuBreadCrumb"
                            routePathName={`/myteam/${this.props.match.params.bossRelationCode}`}
                            breadCrumbElementsToAppend={[{
                                navigationPath: this.props.location.pathname,
                                title: user.fullName
                            }]}
                            {...this.props} />
                        <div className="employee-detail__title" key="Title">
                            <h1 className="l-box--wide heading__Title" key={'EmployeeDetail_MainTitle'}>
                                {user.fullName}
                            </h1>
                            <div>
                                {user.email === '' ? '' :
                                    <div className="employee-detail__title__attribute">
                                        <InlineSVG src={Mail} />&nbsp;
                                        <a href={`mailto:${user.email}`}>{user.email}</a>&nbsp;
                                    </div>}
                                {user.phoneNumber === '' ? '' :
                                    (<div className="employee-detail__title__attribute">
                                        <InlineSVG src={Phone} />&nbsp;
                                        {user.phoneNumber}&nbsp;
                                    </div>)}
                                {user.mobileNumber === '' ? '' :
                                    <div className="employee-detail__title__attribute">
                                        <InlineSVG src={MobilePhone} />&nbsp;
                                        {user.mobileNumber}&nbsp;
                                    </div>}
                            </div>
                            {this.renderPasswordReset(user)}
                        </div>
                    </div>
                    {this.isPanelVisible('masterdata') && this.renderMasterData(user)}
                    {this.isPanelVisible('curriculum') && this.renderCurriculumReport(user)}
                    {this.isPanelVisible('performancereview') && this.renderPerformanceReview(user)}
                    {this.isPanelVisible('performancesupport') && this.renderPerformanceSupport(user)}
                    {this.isPanelVisible('performancecheck') && this.renderPerformanceCheck()}
                    {this.isPanelVisible('skills') && this.renderSkills(user)}
                </div>
            );
        }
        if (this.state.errorMessage != null) {
            return <div className="l-container">
                <ErrorMessage appearance="box" errorMessage={this.state.errorMessage} />
            </div>
        }
        return null;
    }

    private renderPasswordReset(user: User): JSX.Element | null {
        if (this.isPasswordResetEnabled()) {
            return (
                <>
                    <GTButton
                        onClick={() => this.setState({ showPasswordResetConfirmation: true })}>
                        <Translate>EmployeeDetail:PasswordReset</Translate>
                    </GTButton>
                    <ModalPopup
                        isOpen={this.state.showPasswordResetConfirmation}
                        onRequestClose={() => this.setState({ showPasswordResetConfirmation: false, passwordResetAlertMessage: undefined })}>
                        {!this.state.passwordResetAlertMessage ?
                            <>
                                <Translate>{Session.instance.storage.translation.GetString('EmployeeDetail:PasswordResetConfirmationMessage').Format(user.fullName)}</Translate>
                                <div className="modal__spread-buttons">
                                    <button type="button"
                                        aria-label={Session.instance.storage.translation.GetString('EmployeeDetail:ConfirmPasswordReset')}
                                        className="btn btn--sm marginRight-5"
                                        onClick={() => this.resetPassword()}>
                                        <Translate>EmployeeDetail:ConfirmPasswordReset</Translate>
                                    </button>
                                    <button type="button"
                                        aria-label={Session.instance.storage.translation.GetString('EmployeeDetail:CancelPasswordReset')}
                                        className="btn btn--sm button--secondary"
                                        onClick={() => this.setState({ showPasswordResetConfirmation: false })}
                                        disabled={false}>
                                        <Translate>EmployeeDetail:CancelPasswordReset</Translate>
                                    </button>
                                </div>
                            </> :
                            <>
                                <Alert
                                    message={this.state.passwordResetAlertMessage.message}
                                    alertType={this.state.passwordResetAlertMessage.alertType}
                                    alertAppereance={this.state.passwordResetAlertMessage.alertAppereance} />
                                <div className="modal__spread-buttons">
                                    <button type="button"
                                        aria-label={Session.instance.storage.translation.GetString('EmployeeDetail:ClosePasswordReset')}
                                        className="btn btn--sm"
                                        onClick={() => this.setState({ showPasswordResetConfirmation: false, passwordResetAlertMessage: undefined })}
                                        disabled={false}>
                                        <Translate>EmployeeDetail:ClosePasswordReset</Translate>
                                    </button>
                                </div>
                            </>
                        }
                    </ModalPopup>
                </>
            )
        } else {
            return null;
        }
    }

    private renderCurriculumReport(user: User): JSX.Element {
        return (
            <div className="l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        onToggleOpenedState={() => this.setState(
                            {
                                curriculumPanel: {
                                    ...this.state.curriculumPanel,
                                    isOpened: !this.state.curriculumPanel.isOpened
                                }
                            })}
                        isOpened={this.state.curriculumPanel.isOpened}>
                        <Translate>{'EmployeeDetail:CurriculumReport'}</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={this.state.curriculumPanel.isOpened}>
                        <ReportList reports={[{
                            id: 'Report_Curriculum',
                            reportName: 'UserCurriculum001',
                            text: 'Report:UserCurriculum001',
                            userId: user.id
                        }]} />
                    </UnmountClosed>
                </div>
            </div>);
    }

    private renderMasterData(user: User): JSX.Element {
        return (
            <div className="l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        onToggleOpenedState={() => this.setState(
                            {
                                masterDataPanel: {
                                    ...this.state.masterDataPanel,
                                    isOpened: !this.state.masterDataPanel.isOpened,
                                }
                            })}
                        isOpened={this.state.masterDataPanel.isOpened}>
                        <Translate>{'EmployeeDetail:MasterData'}</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={this.state.masterDataPanel.isOpened}>
                        <UserDetails
                            user={user}
                            extendedConfigurations={globalConfig.employeeDetailExtendedProperties}
                            defaultConfiguration={globalConfig.employeeDetailProperties.employeeMasterData.fieldGroups}
                            formClassName="employee-detail"
                            headingClassName="heading__Level3"
                            headingLevel={3} />
                    </UnmountClosed>
                </div>
            </div>);
    }

    private renderPerformanceCheck(): JSX.Element {
        return (
            <div className="l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        onToggleOpenedState={() => this.setState(
                            {
                                performanceCheckPanel: {
                                    ...this.state.performanceCheckPanel,
                                    isOpened: !this.state.performanceCheckPanel.isOpened,
                                }
                            })}
                        isOpened={this.state.performanceCheckPanel.isOpened}>
                        <Translate>{'EmployeeDetail:PerformanceCheck'}</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={this.state.performanceCheckPanel.isOpened}>
                        {this.state.isDataLoadingForPerformanceCheck ? <ProgressSpinner /> :
                            <PerformanceCheckTable
                                isBoss={true}
                                skills={this.state.skillsForPerformanceCheck}
                                emplyoeeId={this.employeeId}
                                reloadPerformanceCheckTargetSkills={() => this.getSkillsForPerformanceCheck()}
                                parentHeadingLevel={1}
                                {...this.props}
                            />
                        }
                    </UnmountClosed>
                </div>
            </div>);
    }

    private renderPerformanceReview(user: User): JSX.Element {
        return (
            <div className="l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        onToggleOpenedState={() => this.setState(
                            {
                                performanceReviewPanel: {
                                    ...this.state.performanceReviewPanel,
                                    isOpened: !this.state.performanceReviewPanel.isOpened,
                                }
                            })}
                        isOpened={this.state.performanceReviewPanel.isOpened}>
                        <Translate>{'EmployeeDetail:PerformanceReview'}</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={this.state.performanceReviewPanel.isOpened}>
                        <PerformanceReview
                            userId={user.id}
                            isBossView={true}
                            parentHeadingLevel={2}
                            bossRelationCode={this.props.match.params.bossRelationCode}
                            userSkillProfiles={this.state.userSkillProfiles.filter(usp => usp.showSkillProfileInPerformanceReview)}
                            achievedUserSkills={this.state.achievedUserSkills}
                            {...this.props} />
                    </UnmountClosed>
                </div>
            </div>);
    }


    private renderPerformanceSupport(user: User): JSX.Element {
        return (
            <div className="l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        onToggleOpenedState={() => this.setState(
                            {
                                performanceSupportPanel: {
                                    ...this.state.performanceSupportPanel,
                                    isOpened: Session.instance.storage.ipanelState.isExpanded("performanceSupportPanel_" + user.id),
                                }
                            })}
                        isOpened={Session.instance.storage.ipanelState.getPanelState("performanceSupportPanel_" + user.id)}>
                        <Translate>{'EmployeeDetail:PerformanceSupport'}</Translate>
                    </HeadingCollapsible>

                    <UnmountClosed isOpened={Session.instance.storage.ipanelState.getPanelState("performanceSupportPanel_" + user.id)}>
                        <PerformanceSupport
                            context={EItemDetailCallerContextType.PerformanceSupport}
                            user={user}
                            isBossView={true}
                            parentHeadingLevel={2}
                            bossRelationCode={this.props.match.params.bossRelationCode}
                            userSkillProfiles={this.state.userSkillProfiles}
                            userTargetSkillContainer={this.state.userTargetSkillContainer}
                            achievedUserSkills={this.state.achievedUserSkills}
                            {...this.props} />
                    </UnmountClosed>
                </div>
            </div>);
    }

    private renderSkills(user: User): JSX.Element {
        return (
            <div className="l-element--striped-reverse">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        onToggleOpenedState={() => this.setState(
                            {
                                skillPanel: {
                                    ...this.state.skillPanel,
                                    isOpened: Session.instance.storage.ipanelState.isExpanded("Skills_" + user.id),
                                }
                            })}
                        isOpened={Session.instance.storage.ipanelState.getPanelState("Skills_" + user.id)}>
                        <Translate>{'EmployeeDetail:Skills'}</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={Session.instance.storage.ipanelState.getPanelState("Skills_" + user.id)}>
                        <UserSkills
                            bossRelationCode={this.props.match.params.bossRelationCode}
                            context={EItemDetailCallerContextType.PerformanceSupport}
                            userSkills={this.state.achievedUserSkills}
                            userId={this.state.user ? this.state.user.id : 0} />
                        <div className="employee-detail__actions">
                            <div className="employee-detail__action">
                                <button
                                    className="btn--md btn--primary"
                                    onClick={() => this.setState({ showAssignSkillsModal: true })}>
                                    {Session.instance.storage.translation.GetString('EmployeeDetail:AssignSkillButton')}
                                </button>
                            </div>
                            {this.isSkillRemovalOptionEnabled() &&
                                <div className="employee-detail__action">
                                    <button
                                        className="btn--md btn--primary"
                                        onClick={() => this.setState({ showDeleteSkillsModal: true })}>
                                        {Session.instance.storage.translation.GetString('EmployeeDetail:DeleteSkillButton')}
                                    </button>
                                </div>
                            }
                        </div>
                    </UnmountClosed>
                    {this.state.showDeleteSkillsModal &&
                        <RemoveSkillsModal
                            isOpen={this.state.showDeleteSkillsModal}
                            onClose={() => this.setState({ showDeleteSkillsModal: false })}
                            headingLevel={2}
                            translationPrefix='EmployeeDetail'
                            isForTargetSkills={false}
                            headingClassName="heading__Level2"
                            achievedSkills={this.state.achievedUserSkills}
                            employees={[user]}
                            onTargetSkillRemovalSuccess={() => this.onSkillRemovalSuccess()}
                            bossRelationCode={this.props.match.params.bossRelationCode}  />
                    }
                    {this.state.showAssignSkillsModal ?
                        <AssignSkillsModal
                            headingLevel={2}
                            headingClassName="heading__Level2"
                            skillTypeValue={ESkillType.ActualSkill}
                            isOpen={this.state.showAssignSkillsModal}
                            onAssignSuccess={(assignments) => this.onTargetSkillAssignmentSuccess(assignments)}
                            onClose={() => this.setState({ showAssignSkillsModal: false })}
                            selectedEmployees={[user]}
                            bossRelationCode={this.props.match.params.bossRelationCode} /> : ''
                    }
                </div>
            </div>);
    }

    private loadEmployee = async (): Promise<void> => {
        const params = this.props.match.params;
        const bossRelationCode: string | null = params.bossRelationCode != null ? params.bossRelationCode : null;
        let data: User | GtError;
        if (bossRelationCode != null && this.employeeId != null && !isNaN(Number(this.employeeId))) {
            data = await UserService.instance.getUser(this.employeeId);

            if (isSuccess<User>(data)) {
                this.setState({
                    user: data,
                });
            } else {
                if (data.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(data.detailedObject.subStatusCode), isDataLoaded: true })
                }
                else if (data.statusCode && data.statusCode === 405) {
                    this.setState({ errorMessage: 'ErrorMessage:NoAccessForUser' });
                }
                else {
                    this.setState({ errorMessage: 'ErrorMessage:LoadEmployeeFailed' });
                }
            }
        }
    }

    private async getUserSkillProfile(): Promise<void> {
        const response = await Session.instance.storage.userSkillProfile.getUserSkillProfiles(this.employeeId, true, this.props.match.params.bossRelationCode);
        const errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadProfiles');
        if (isSuccess<UserSkillProfile[] | null>(response)) {
            if (response != null) {
                this.setState(prevState => ({ userSkillProfiles: response.concat(prevState.userSkillProfiles) }));
            } else {
                Session.instance.setLastErrorMessage(errorMessage);
                this.setState({ errorMessage })
            }

        } else {
            Session.instance.setLastErrorMessage(errorMessage);
            this.setState({ errorMessage })
        }
    }

    private async getUserTargetSkills(): Promise<void> {
        const response = await Session.instance.storage.userTargetSkill.getUserTargetSkills(this.employeeId, true, this.props.match.params.bossRelationCode);
        const errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadTargetSkills');
        if (isSuccess<UserSkillProfileSkillContainer | null>(response)) {
            if (response != null && response.userSkillProfileSkills.length > 0) {
                this.setState({ userTargetSkillContainer: response });
            } else {
                Session.instance.setLastErrorMessage(errorMessage);
                this.setState({ errorMessage })
            }

        } else {
            Session.instance.setLastErrorMessage(errorMessage);
            this.setState({ errorMessage })
        }
    }

    private async getUserSkills(): Promise<void> {
        const response = await Session.instance.storage.userSkill.getUserSkills(this.employeeId, true, this.props.match.params.bossRelationCode);
        if (response != null) {
            this.setState({
                achievedUserSkills: response
            })
        }
        else {
            this.setState({ errorMessage: Session.instance.lastErrorMessage });
        }
    }

    private async getSkillsForPerformanceCheck(): Promise<void> {
        this.setState({ isDataLoadingForPerformanceCheck: true });
        const response = await Session.instance.storage.skill.getSkillsForPerformanceCheck(this.employeeId, Session.instance.getUserLanguageCodeOrFallBack, this.props.match.params.bossRelationCode);
        if (isSuccess<PerformanceCheckTableItem[]>(response)) {
            this.setState({
                skillsForPerformanceCheck: response, isDataLoadingForPerformanceCheck: false
            })
        }
        else {
            this.setState({ errorMessage: Session.instance.lastErrorMessage, isDataLoadingForPerformanceCheck: false });
        }
    }

    private onSkillRemovalSuccess() {
        const methodName = `${this.className}:onSkillRemovalSuccess()`;
        Logger.log(this.loggerLocality, `${methodName} called`);
        this.getUserSkills();
        return;
    }

    private onTargetSkillAssignmentSuccess(assignments: AssignUserSkillResponse) {
        assignments.employeeIdsWithSuccess.map(async (employee) => {
            if (employee === this.employeeId) {
                await this.getUserSkills();
                return;
            }
        });
    }

    private async resetPassword(): Promise<void> {
        const request = new EmployeePasswordResetRequest();
        request.employeeId = this.employeeId;
        await TeamService.instance.employeePasswordReset(request).then(response => {
            if (isSuccess<BooleanResponse>(response)) {
                if (response.status) {
                    const passwordResetAlertMessage: IAlertProps = {
                        message: Session.instance.storage.translation.GetString('EmployeeDetail:PasswordResetSuccessfull'),
                        alertType: 'success',
                        alertAppereance: 'single-line'
                    };
                    this.setState({ passwordResetAlertMessage });
                } else {
                    const passwordResetAlertMessage: IAlertProps = {
                        message: Session.instance.storage.translation.GetString('EmployeeDetail:PasswordResetFailed'),
                        alertType: 'error',
                        alertAppereance: 'single-line'
                    };
                    this.setState({ passwordResetAlertMessage });
                }
            } else {
                const passwordResetAlertMessage: IAlertProps = {
                    message: Session.instance.storage.translation.GetString('EmployeeDetail:PasswordResetFailed'),
                    alertType: 'error',
                    alertAppereance: 'single-line'
                };
                this.setState({ passwordResetAlertMessage });
            }
        })
    }

    private isPasswordResetEnabled(): boolean {
        const relationConfiguration = globalConfig.myTeamProperties.relationConfiguration.find(relation => relation.id.toLowerCase() === this.props.match.params.bossRelationCode.toLowerCase());
        return relationConfiguration ? relationConfiguration.passwordResetEnabled : false;
    }

    private isSkillRemovalOptionEnabled(): boolean {
        const relationConfiguration = globalConfig.myTeamProperties.relationConfiguration.find(relation => relation.id.toLowerCase() === this.props.match.params.bossRelationCode.toLowerCase());
        return relationConfiguration ? relationConfiguration.showSkillRemovalOption : false;
    }

    private employeeDetailPanelsConfiguration(): string[] | null {
        const relationConfiguration = globalConfig.myTeamProperties.relationConfiguration.find(relation => relation.id.toLowerCase() === this.props.match.params.bossRelationCode.toLowerCase());
        return relationConfiguration ? relationConfiguration.employeeDetailPanels : null;
    }

    private isPanelVisible = (section: string) => {
        const panelConfiguration = this.employeeDetailPanelsConfiguration();
        if (panelConfiguration != null) {
            return panelConfiguration.filter(p => p.toLowerCase() === section.toLowerCase()).length > 0;
        }
        return true;
    }
}

export default EmployeeDetail;