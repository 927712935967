import ServiceClient from '$core/ServiceClient';
import Session from '$core/Session';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { ItemRequest } from '$storage/models/RequestObjects/ItemRequest';
import { WatchedItemModel } from '$storage/models/WatchedItemModel';
import GtError from '$util/GtError';

export default class WatchlistService extends ServiceClient {
    protected static _instance: WatchlistService | null = null;

    protected className = 'Watchlist';
    protected loggerLocality = 'WatchlistService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): WatchlistService {
        return this._instance || (this._instance = new this());
    }

     /**
      * Add item to watchlist of current user
      * This method is allowed to be used in WatchlistStorage.ts only
      * @param itemId Id 
      */
    public async AddSubscriber(itemId: number): Promise<WatchedItemModel | GtError> {
        let response: WatchedItemModel | GtError;
        const request = new ItemRequest();
        request.itemId = itemId
        request.uiCulture = Session.instance.getUserLanguageCodeOrFallBack
        response = await this.post<WatchedItemModel >('watchlist/AddSubscriber', request ,WatchedItemModel);        
        return response;
    }

     /**
      * Removes item from watchlist of current user
      * This method is allowed to be used in WatchlistStorage.ts only
      * @param itemId Id 
      */
     public async RemoveSubscriber(itemId: number): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        const request = new ItemRequest();
        request.itemId = itemId
        response = await this.post<BooleanResponse >('watchlist/RemoveSubscriber', request ,BooleanResponse);        
        return response;
    }

     /**
      * Get subscription status of a selected item
      * This method is allowed to be used in WatchlistStorage.ts only
      * @param itemId Id 
      */
     public async GetSubscriptionStatus(itemId: number): Promise<WatchedItemModel | GtError> {
        let response: WatchedItemModel | GtError;
        response = await this.get<WatchedItemModel>('watchlist', WatchedItemModel, undefined, undefined, itemId.toString());
        return response;
    }

     /**
      * Get watched items of the current user
      * This method is allowed to be used in WatchlistStorage.ts only
      * @param uiculture 
      */
     public async GetWatchedItems(uiCulture: string): Promise<WatchedItemModel[] | GtError> {
        let response: WatchedItemModel[] | GtError;
        response = await this.get<WatchedItemModel[]>('watcheditems', WatchedItemModel, undefined, undefined, uiCulture);
        return response;
    }

}