import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '../converters/DateTimeConverter';

@JsonObject
export class ItemRating {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('userFirstName', String, false)
    public userFirstName: string = '';

    @JsonProperty('userLastName', String, false)
    public userLastName: string = '';

    @JsonProperty('rating', Number, false)
    public rating: number = 0;

    @JsonProperty('ratingDate', DateTimeConverter, false)
    public ratingDate: Date = new Date();

    @JsonProperty('comment', String, false)
    public comment: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('isOwnRating', Boolean, false)
    public isOwnRating: boolean = false;

    @JsonProperty('allowDeletion', Boolean, false)
    public allowDeletion: boolean = false;

}