
import React, { useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { HeadingCollapsible } from '$components//shared/HeadingCollapsible';
import { Translate } from '$components/shared/Translate';
import { CollaborationProviderClassLink } from '$components/collaborationProvider/CollaborationProviderClassLink';
import { CollaborationProviderItemLink } from '$components/collaborationProvider/CollaborationProviderItemLink';
import { useHasCollaborationLinks } from '$components/collaborationProvider/Hooks/UseHasCollaborationLinks';


interface ICollaborationProviderItemPanelProps {
    itemId: number;
    classId: number;
    panelTitle: string;
}

export const CollaborationProviderItemPanel: React.FC<ICollaborationProviderItemPanelProps> = (props: ICollaborationProviderItemPanelProps) => {

    const [panelOpen, setPanelOpen] = useState<boolean>(true);
    const [hasItemLink, hasClassLink] = useHasCollaborationLinks({itemId: props.itemId, classId: props.classId});

    return <>
        {hasItemLink && <div className="l-element--striped">
            <div className="l-container">
                <HeadingCollapsible headingLevel={2}
                    containerCssClass=""
                    headingCssClass="heading__Level2"
                    isOpened={panelOpen}
                    onToggleOpenedState={() => setPanelOpen(!panelOpen)}>
                    <Translate>{props.panelTitle}</Translate>
                </HeadingCollapsible>
                <UnmountClosed
                    isOpened={panelOpen}>
                    <div className="collaboration-provider">
                        <CollaborationProviderItemLink itemId={props.itemId} hasLink={hasItemLink} />
                        {hasClassLink &&
                            <CollaborationProviderClassLink itemId={props.itemId} classId={props.classId} hasLink={hasClassLink} />
                        }
                    </div>
                </UnmountClosed>
            </div>
        </div>}
    </>
}