import { observer } from 'mobx-react';
import React from 'react';
import { UnmountClosed } from 'react-collapse';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import FileSharingService from '$src/core/Services/FileSharingService';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { MyFileSharing } from '$src/storage/models/FileSharing/MyFileSharing';
import { ItemHelper } from '$src/util/ItemHelper';
import { isSuccess } from '$src/util/Result';

import Iconfilesharing from '$resources/svgs/lessonTypes/share.svg';

interface IProps extends RouteComponentProps {
    headingLevel: number;
    title: string;
}
interface IState {
    myFileSharings: MyFileSharing[] | undefined;
    errorMessage: string | null;
    isTPlanCollapsed: boolean;
    isCatalogCollapsed: boolean;
    isAssignmentCollapsed: boolean;
}

@observer // observing Session.instance.storage.registeredItem.isInitialized
export class MyFileSharings extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { 
            errorMessage: null, 
            isAssignmentCollapsed: false,
            isCatalogCollapsed: false,
            isTPlanCollapsed: false,
            myFileSharings: undefined 
        };
    }

    public componentDidMount() {
        // Set document.title, as long as App.tsx componentDidUpdate doesn't work
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;

        // load
        FileSharingService.instance.getMyFileSharings().then((res: MyFileSharing[]) => {
            if (isSuccess(res)) {
                this.setState({ myFileSharings: res, errorMessage: null });
            } else {
                this.setState({ errorMessage: 'FileSharing:Error_LoadingMyFileSharings' });
            }
        });        
    }

    public render() {
        return (
            <div>
                <div className="l-container" >
                    <div>
                        <MenuBreadCrumb {...this.props} />
                        <div className="l-box--wide">
                            <h1 className="heading__Title"><Translate>FileSharing:MyFileSharing</Translate></h1>
                            <ErrorMessage errorMessage={this.state.errorMessage} />
                            <div>
                                <Translate>FileSharing:SubTitle</Translate>
                            </div>
                        </div>
                        {this.renderPanels()}
                    </div>
                </div>
            </div>
        );
    }

    protected renderPanels() {
        if (!this.state.myFileSharings && !this.state.errorMessage) {
            return <ProgressSpinner />;
        }

        return (
            <React.Fragment>
                {this.renderPanel('MyFileSharingsTrainingPlan', this.state.isTPlanCollapsed, this.filterTPlanEntries())}
                {this.renderPanel('MyFileSharingsCatalog', this.state.isCatalogCollapsed, this.filterCatalogEntries())}
                {this.renderPanel('MyFileSharingsAssignment', this.state.isAssignmentCollapsed, this.filterAssignmentEntries())}
            </React.Fragment>
        );
    }

    protected renderPanel(titleStringId: string, isCollapsed: boolean, entries: MyFileSharing[]) {
        return (
            <React.Fragment>
                <HeadingCollapsible
                    headingLevel={2}
                    containerCssClass=""
                    headingCssClass="heading__Level2"
                    isOpened={!isCollapsed}
                    onToggleOpenedState={() => this.setCollapsed(titleStringId.substr(14), !isCollapsed)}>
                    <Translate>
                        {'FileSharing:' + titleStringId}
                    </Translate>
                </HeadingCollapsible>
                <UnmountClosed isOpened={!isCollapsed}>
                    <table className="my-filesharings__filesharings-table">
                        <tbody>
                        {
                            entries.map(entry => this.renderEntry(entry))
                        }
                        </tbody>
                    </table>
                    {entries.length <= 0 ? <i><Translate>FileSharing:NoEntries</Translate></i> : null}
                </UnmountClosed>
            </React.Fragment>
        );
    }

    protected renderEntry(myFileSharing: MyFileSharing) {
        return (
            <tr key={myFileSharing.itemId.toString() + myFileSharing.assignmentId + myFileSharing.tPlanId + myFileSharing.scheduleId}>
                <td className="filesharings-table__col--icon">
                    <InlineSVG src={Iconfilesharing} />
                </td>
                <td className="filesharings-table__col--title">
                    <NavLink to={this.getUrl(myFileSharing)} onClick={(e: any) => e.stopPropagation()}>
                        {this.getTitle(myFileSharing)}
                    </NavLink>
                </td>
            </tr>
        );
    }

    /* FUNCTIONS */

    protected setCollapsed = (panel: string, state: boolean) => {
        switch (panel) {
            case 'Catalog':
                this.setState({ isCatalogCollapsed: state });
                break;
            case 'TrainingPlan':
                this.setState({ isTPlanCollapsed: state });
                break;
            case 'Assignment':
                this.setState({ isAssignmentCollapsed: state });
                break;            
        }
    }

    protected getTitle = (myFileSharing: MyFileSharing): string => {
        let title: string = myFileSharing.title.valueOf();
        if (myFileSharing.courseTitle && myFileSharing.courseTitle.length > 0) {
            title = myFileSharing.courseTitle + ' - ' + title;
        }
        return title;
    }

    protected getUrl = (myFileSharing: MyFileSharing): string => {
        let context: EItemDetailCallerContextType = EItemDetailCallerContextType.MyFileSharing;
        if (myFileSharing.assignmentId) {
            context = EItemDetailCallerContextType.Assignment;
        } else if (myFileSharing.tPlanId) {
            context = EItemDetailCallerContextType.RegisteredItem;
        }

        const url = ItemHelper.getItemDetailLink(
            myFileSharing.itemId.valueOf(), 
            context, 
            0, 
            myFileSharing.assignmentId ? myFileSharing.assignmentId.valueOf() : 0, 
            myFileSharing.tPlanId ? myFileSharing.tPlanId.valueOf() : 0, 
            myFileSharing.scheduleId ? myFileSharing.scheduleId.valueOf() : 0);
        return url;
    }

    /* EVENTS */

    protected filterTPlanEntries = (): MyFileSharing[] => {
        if (!this.state.myFileSharings) { 
            return [];
        }
        return this.state.myFileSharings.filter(p => p.tPlanId && !p.assignmentId);
    }

    protected filterCatalogEntries = (): MyFileSharing[] => {
        if (!this.state.myFileSharings) { 
            return [];
        }
        return this.state.myFileSharings.filter(p => !p.tPlanId && !p.assignmentId);
    }

    protected filterAssignmentEntries = (): MyFileSharing[] => {
        if (!this.state.myFileSharings) { 
            return [];
        }
        return this.state.myFileSharings.filter(p => p.assignmentId && !p.tPlanId);
    }
}