import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import ExpirationIcon from '$resources/svgs/skills/expiration.svg';
import SkillLow from '$resources/svgs/skills/skill-low.svg';
import SkillMet from '$resources/svgs/skills/skill-met.svg';
import SkillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import skillExpired from '$resources/svgs/skills/skill-expired.svg';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { ChangeSkillLevelConfirmation } from '$src/components/performanceReview/ChangeSkillLevelConfirmation';
import { Evaluations } from '$src/components/performanceReview/Evaluations';
import { Heading } from '$src/components/shared/Heading';
import { HeadingCollapsible } from '$src/components/shared/HeadingCollapsible';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import Logger from '$src/core/Logger';
import ItemService from '$src/core/Services/ItemService';
import SkillService from '$src/core/Services/SkillService';
import TeamService from '$src/core/Services/TeamService';
import UserService from '$src/core/Services/UserService';
import Session from '$src/core/Session';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import { EItemDetailCallerContextType, ESkillType, ESkillStatus } from '$src/storage/models/enums';
import { Item } from '$src/storage/models/Item';
import { AssignUserSkillRequest } from '$src/storage/models/RequestObjects/AssignUserSkillRequest';
import { UserSkillCommentUpdateRequest } from '$src/storage/models/RequestObjects/UserSkillCommentUpdateRequest';
import { ItemSkillLevelTranslatedDisplay } from '$src/storage/models/Skill/ItemSkillLevelTranslatedDisplay';
import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import { User } from '$src/storage/models/User';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';
import React from 'react';
import { UnmountClosed } from 'react-collapse';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';
import { Tooltip } from '$components/shared/Tooltip';
import DateHelper from '$src/util/DateHelper';
import { RecommendedTrainingsForSkill } from '$components/shared/Trainings';
import { PerformanceReviewCommentResponse } from '$src/storage/models/PerformanceReviewCommentResponse';
import { CommentUpdateRequest } from '$src/storage/models/Skill/CommentUpdateRequest';

interface IMatchParams {
    skillId: string;
}

type IProps = RouteComponentProps<IMatchParams>

interface ICollapseState {
    descriptionCollapsed: boolean;
    trainingsCollapsed: boolean;
    evaluationsCollapsed: boolean;
}

interface IURLParamState {
    profileId: number;
    userId?: number;
    bossRelationCode?: string;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IState extends ICollapseState, IURLParamState {
    isDataLoaded: boolean;
    trainings: Item[];
    skill?: UserSkillProfileSkill;
    errorMessage?: string;
    userSkill?: UserSkill;
    previousData?: UserSkill;
    isUserBoss: boolean;
    skillLevelSet: ItemSkillLevelTranslatedDisplay[];
    shouldShowModalChangeSkillLevel: boolean;
    user?: User;
    overWriteComments: boolean;
    shouldShowModalOverWriteComments: boolean;
    callerContextChangeSkillLevel: boolean;
    expirationDate: Date | null;
    targetSkillLevel?: ItemSkillLevelTranslatedDisplay;
}

export class PerformanceReviewDetail extends React.Component<IProps, IState> {
    protected className = 'PerformanceReviewDetail';
    protected loggerLocality = 'Components.PerformanceReviewDetail';
    // Id for the "not associated" fake skill profile used for target skills
    protected notAssociatedSkillProfile: number = 9999;
    protected userId: number = 0;

    constructor(props: IProps) {
        super(props);

        this.state = {
            descriptionCollapsed: false,
            errorMessage: undefined,
            evaluationsCollapsed: false,
            isDataLoaded: false,
            isUserBoss: false,
            profileId: 0,
            shouldShowModalChangeSkillLevel: false,
            skill: undefined,
            skillLevelSet: [],
            trainings: [],
            trainingsCollapsed: false,
            userId: undefined,
            userSkill: undefined,
            previousData: undefined,
            user: undefined,
            overWriteComments: false,
            shouldShowModalOverWriteComments: false,
            callerContextChangeSkillLevel: false,
            expirationDate: null,
            targetSkillLevel: undefined
        }
    }

    public async componentDidMount() {
        // Load data
        await this.loadData();
        document.title = globalConfig.appProperties.title + ': ' + this.state.skill!.skillTitle;
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return (
                <ProgressSpinner />
            )
        }
        else if (this.state.errorMessage) {
            return (
                <div className="l-container">
                    <ErrorMessage appearance="box" errorMessage={this.state.errorMessage} />
                </div>
            )
        }
        else if (!this.state.skill) {
            return (
                <div className="l-container">
                    <ErrorMessage appearance="box" errorMessage={Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadSkill')} />
                </div>
            )
        } else {
            const bcElements: BreadCrumbElement[] = [];
            if (this.state.isUserBoss) {
                bcElements.push(
                    {
                        navigationPath: `/employee/${this.state.bossRelationCode}/${this.state.userId}?focus=PerformanceReview`,
                        title: this.state.user ? this.state.user.fullName : ''
                    }
                )
            }
            bcElements.push({
                navigationPath: this.props.location.pathname,
                title: this.state.skill.skillTitle
            });

            return (
                <div>
                    <div className="l-container">
                        <MenuBreadCrumb
                            routePathName={this.state.isUserBoss ? `/myTeam/${this.state.bossRelationCode}` : `/myPerformanceReview`}
                            breadCrumbElementsToAppend={bcElements}
                            {...this.props} />
                        <div className="performanceReviewDetail-title__container">
                            <Heading headingLevel={1} cssClass="l-box-container heading__Title">
                                {this.state.skill.skillTitle}
                            </Heading>
                            {this.renderSkillStatus(this.state.skill)}
                        </div>
                        <div className="inlineFlex">
                            {this.renderChangeSkillLevelButton(this.state.skill)}
                            {this.renderExpiration()}
                        </div>
                        {this.renderChangeSkillLevelPopup(this.state.skill)}
                        {this.renderOverWriteComments()}
                    </div>
                    <div className="l-element--striped">
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.descriptionCollapsed}
                                onToggleOpenedState={() => this.setState({ descriptionCollapsed: !this.state.descriptionCollapsed })}>
                                <Translate>PerformanceReview:Descriptions</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={!this.state.descriptionCollapsed}>
                                {this.renderDescription()}
                            </UnmountClosed>
                        </div>
                    </div>

                    <div className="l-element--striped" >
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.trainingsCollapsed}
                                onToggleOpenedState={() => this.setState({ trainingsCollapsed: !this.state.trainingsCollapsed })}>
                                <Translate>PerformanceReview:Trainings</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={!this.state.trainingsCollapsed}>
                                <div className="l-box-container--column-flex">
                                    {this.state.trainings.length === 0 ?
                                        <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceReview:NoLessonsFound')} /> :
                                        this.renderTrainings()
                                    }
                                </div>
                            </UnmountClosed>
                        </div>
                    </div>

                    <div className="l-element--striped" >
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.evaluationsCollapsed}
                                onToggleOpenedState={() => this.setState({ evaluationsCollapsed: !this.state.evaluationsCollapsed })}>
                                <Translate>PerformanceReview:Evaluations</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={!this.state.evaluationsCollapsed}>
                                <div className="l-box-container">
                                    {this.renderEvaluations(this.state.userSkill, this.state.previousData)}
                                </div>
                            </UnmountClosed>
                        </div>
                    </div>
                </div>
            )
        }

    }

    private renderDescription(): JSX.Element {
        const performanceReviewField = this.state.skill != null ? this.state.skill.skillPerformanceReviewField : null;
        const performanceReviewField2 = this.state.skill != null ? this.state.skill.skillPerformanceReviewField2 : null;
        return (
            <React.Fragment>
                {performanceReviewField &&
                    <React.Fragment>
                        <Heading headingLevel={3} cssClass="heading__Level3"><Translate>PerformanceReview:PerformanceReviewFieldTitle</Translate></Heading>
                        <div className="item-detail">
                            {globalConfig.performanceReviewProperties.enableHTMLFormatterForDescriptionFields ?
                                (<span dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(performanceReviewField) }} />) :
                                (<span dangerouslySetInnerHTML={{ __html: performanceReviewField }} />)
                            }
                        </div>
                    </React.Fragment>
                }
                {performanceReviewField2 &&
                    <React.Fragment>
                        <Heading headingLevel={3} cssClass="heading__Level3"><Translate>PerformanceReview:PerformanceReviewField2Title</Translate></Heading>
                        <div className="item-detail">
                            {globalConfig.performanceReviewProperties.enableHTMLFormatterForDescriptionFields ?
                                (<span dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(performanceReviewField2) }} />) :
                                (<span dangerouslySetInnerHTML={{ __html: performanceReviewField2 }} />)
                            }
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }

    private renderTrainings(): JSX.Element {
        const params = this.props.match.params;
        const skillId: number = params.skillId != null && !isNaN(Number(params.skillId)) ? Number(params.skillId) : 0;
        return (<RecommendedTrainingsForSkill
            trainings={this.state.trainings}
            itemDetailCallerContextType={EItemDetailCallerContextType.PerformanceReview}
            skillId={skillId}
            user={this.state.user ? this.state.user : new User()}
            profileId={this.state.profileId}
            bossRelationCode={this.state.bossRelationCode}
            parentHeadingLevel={1}
            {...this.props} />);
    }

    private renderEvaluations(userSkill: UserSkill | undefined, previousUserSkill: UserSkill | undefined): JSX.Element {
        if (userSkill && previousUserSkill) {
            return (
                <Evaluations
                    evaluations={userSkill.userSkillComments}
                    previousEvaluations={previousUserSkill.userSkillComments}
                    createEvaluationAllowed={this.state.skill ? this.state.skill.skillProfileSkillLevelValue > userSkill.skillLevelValue : false}
                    headingLevel={1}
                    userId={this.userId}
                    userSkillId={userSkill.userSkillId} />
            )
        } else {
            return (
                <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceReview:NoCommentsFound')} />
            )
        }
    }

    private renderSkillStatus(requiredSkill: UserSkillProfileSkill): JSX.Element {
        let iconName = '';
        let className = '';
        let text = '';
        const userSkill = this.state.userSkill;
        if (!userSkill) {
            iconName = SkillNotMet;
            className = 'status__not-received';
            text = Session.instance.storage.translation.GetString('PerformanceReview:SkillNotReceived');
        } else if (userSkill.skillLevelValue < requiredSkill.skillProfileSkillLevelValue) {
            if (userSkill.skillStatus == ESkillStatus.Expired) {
                iconName = skillExpired;
                className = 'status__in-work--expired';
                text = Session.instance.storage.translation.GetString('PerformanceReview:SkillExpired').Format(userSkill.skillLevelTitle);
            } else {
                iconName = SkillLow;
                className = 'status__in-work';
                text = Session.instance.storage.translation.GetString('PerformanceReview:SkillLevel').Format(userSkill.skillLevelTitle);
            }
        } else {
            if (userSkill.skillStatus == ESkillStatus.Expired) {
                iconName = skillExpired;
                className = 'status__achieved--expired';
                text = Session.instance.storage.translation.GetString('PerformanceReview:SkillExpired').Format(userSkill.skillLevelTitle);
            } else {
                iconName = SkillMet;
                className = 'status__achieved';
                text = Session.instance.storage.translation.GetString('PerformanceReview:SkillLevel').Format(userSkill.skillLevelTitle);
            }

        }
        return (
            <div className="performanceReview-title__icon"
                aria-label={text}
                data-tip={text}
                data-for="skillStatusIconTooltip">
                <InlineSVG aria-label={text} src={iconName} className={className} />
                <Tooltip id="skillStatusIconTooltip" />
            </div>
        )
    }

    private renderExpiration(): JSX.Element | null {
        const userSkill = this.state.userSkill;
        const expirationDate = userSkill && userSkill.expirationDate != null ? StringHelper.dateString(userSkill.expirationDate) : '';
        if (expirationDate.length > 0) {

            const text = Session.instance.storage.translation.GetString('PerformanceReview:SkillExpiredAt').Format(expirationDate);
            return (
                <div
                    className="inlineFlex"
                    aria-label={text}
                    data-tip={text}
                    data-for="skillExpirationTooltip">
                    <InlineSVG aria-label={text} src={ExpirationIcon} />
                    {expirationDate}
                    <Tooltip id="skillExpirationTooltip" />
                </div>
            )
        } else {
            return null;
        }
    }

    /**
     * Render skill level change button only if
     * - the current user is boss
     * - the current user skill level is bellow the required skill level
     * - the required skill level is acquired and the expiration date are equal or less then today
     */
    private renderChangeSkillLevelButton(requiredSkill: UserSkillProfileSkill): JSX.Element | null {
        const userSkill = this.state.userSkill;
        if (this.state.isUserBoss
            && (!userSkill || userSkill.skillLevelValue < requiredSkill.skillProfileSkillLevelValue
                || (userSkill.skillLevelValue === requiredSkill.skillProfileSkillLevelValue
                    && DateHelper.CompareDate(new Date(), userSkill.expirationDate) >= 0))
            && requiredSkill.hasAssignRight) {
            return (
                <button type="button"
                    className="btn btn--sm"
                    onClick={() => this.setState({ shouldShowModalChangeSkillLevel: true })}>
                    <Translate>Button:ChangeSkillLevel</Translate>
                </button>
            )
        } else {
            return null;
        }
    }

    private renderChangeSkillLevelPopup(requiredSkill: UserSkillProfileSkill): JSX.Element {
        const userSkill = this.state.userSkill
        const actualSkillLevel = userSkill ? userSkill.skillLevelTitle : Session.instance.storage.translation.GetString('PerformanceReview:SkillNotReceived');
        const targetSkillLevel = this.getNextSkillLevel();
        const expirationDateRequired = targetSkillLevel.skillLevelValue === requiredSkill.skillProfileSkillLevelValue;
        return (
            <ModalPopup
                isOpen={this.state.shouldShowModalChangeSkillLevel}
                onRequestClose={() => this.setState({ shouldShowModalChangeSkillLevel: false })}>
                <div className="modal__spread buttons">
                    <ChangeSkillLevelConfirmation
                        skillTitle={requiredSkill.skillTitle}
                        parentHeadingLevel={1}
                        actualSkillLevel={actualSkillLevel}
                        targetSkillLevel={targetSkillLevel.translatedText}
                        isExpirationDateRequired={expirationDateRequired}
                        isSkillLevelExpired={userSkill?.skillStatus === ESkillStatus.Expired}
                        onConfirmedClick={(expirationDate: Date | null) => this.onChangeSkillLevel(expirationDate)}
                        onExitClicked={() => this.setState({ shouldShowModalChangeSkillLevel: false })}
                    />
                </div>
            </ModalPopup>
        )
    }

    private renderOverWriteComments(): JSX.Element {
        return (
            <ModalPopup
                isOpen={this.state.shouldShowModalOverWriteComments}
                onRequestClose={() => this.setState({ shouldShowModalOverWriteComments: false })}>
                <div>
                    <Translate>PerformanceReview:CommentSaveWarningMessage</Translate>
                </div>
                <div className="modal__spread-buttons">
                    <button type="button" className="btn btn--sm marginRight-5" id="btnSave" onClick={() => this.finalizeAllEvaluations()}><Translate>Button:Confirm</Translate></button>
                    <button type="button" className="button button-small" id="btnCancel" onClick={() => this.setState({ shouldShowModalOverWriteComments: false })}><Translate>Button:Cancel</Translate></button>
                </div>
            </ModalPopup>
        )
    }

    /**
     * Get the next skill level for the used skill level set
     */
    private getNextSkillLevel(): ItemSkillLevelTranslatedDisplay {
        const userSkill = this.state.userSkill
        // Per default the first level (levels are sorted by level value)
        let response = this.state.skillLevelSet[0];
        // Take the next level only if the user has the skill or it is not expired, in this case the first (default) level is set again
        if (userSkill && userSkill.skillStatus !== ESkillStatus.Expired) {
            // Get the next Skill Level
            const nextSkillLevel = this.state.skillLevelSet.find(sls => sls.skillLevelValue === userSkill.skillLevelValue + 1);
            // It is not necessary to check if the existing level is already the highest as this function is not available in this case
            if (nextSkillLevel) {
                response = nextSkillLevel;
            }
        }
        return response;
    }

    /**
     * The confirmation dialog was confirmed
     * @param expirationDate if it is required, else it can be null
     */
    private async onChangeSkillLevel(expirationDate: Date | null) {
        if (this.state.skill && this.state.userId) {
            const targetSkillLevel = this.getNextSkillLevel();
            const employeeIds: number[] = [];
            this.setState({ callerContextChangeSkillLevel: true, expirationDate: expirationDate, targetSkillLevel: targetSkillLevel });
            employeeIds.push(this.state.userId);
            let finalizedAllEvaluations = true;
            // Finalize all evaluations when the target skill level corresponds to the required target skill level
            if (targetSkillLevel.skillLevelValue === this.state.skill.skillProfileSkillLevelValue) {
                finalizedAllEvaluations = await this.finalizeAllEvaluations();
            }

            if (finalizedAllEvaluations) {
                await this.assignSkillToEmployee(expirationDate, targetSkillLevel);
                window.location.reload();
            }
        }
    }

    private async assignSkillToEmployee(expirationDate: Date | null, targetSkillLevel: ItemSkillLevelTranslatedDisplay) {
        if (this.state.skill && this.state.userId) {
            const employeeIds: number[] = [];
            employeeIds.push(this.state.userId);
            const requestObj: AssignUserSkillRequest = {
                assignDateTicks: new Date().valueOf(),
                employeeIds,
                expDateTicks: expirationDate == null ? undefined : expirationDate.valueOf(),
                reason: '',
                skillId: this.state.skill.skillId,
                skillLevelId: targetSkillLevel.id,
                skillTypeId: ESkillType.ActualSkill,
                fileCollection: []
            }

            const response = await TeamService.instance.assingSkillToEmployees(requestObj);
            if (response == null || response instanceof GtError) {
                const errorMsg = response == null ? Session.instance.storage.translation.GetString('PerformanceReview:FailedToUpdateSkill') : response.message;
                this.setState({ errorMessage: errorMsg });
            }
        }
    }

    /**
     * Set all not finalized user skill comments (evaluations) as finalized 
     */
    private async finalizeAllEvaluations(): Promise<boolean> {
        const tmpComments: CommentUpdateRequest[] = [];
        let finalized = true;
        if (this.state.userSkill && this.state.previousData) {
            const notFinalizedComments = this.state.userSkill.userSkillComments.filter(uskC => !uskC.isFinalized);
            const notPreviousFinalizedComments = this.state.previousData.userSkillComments.filter(uskC => !uskC.isFinalized);
            if (notFinalizedComments && notPreviousFinalizedComments) {
                notFinalizedComments.map(uskC => {

                    const tmpPreviousComment = notPreviousFinalizedComments.find(nuskc => nuskc.userSkillCommentId == uskC.userSkillCommentId);
                    const tempComment: CommentUpdateRequest = {
                        comment: uskC.comment,
                        isFinalized: true,
                        commentType: uskC.commentType,
                        userSkillCommentId: uskC.userSkillCommentId,
                        previousComment: tmpPreviousComment?.comment ? tmpPreviousComment.comment : "",
                    }
                    tmpComments.push(tempComment);
                });
            }
            const updateCommentRequest: UserSkillCommentUpdateRequest = {
                userSkillComments: tmpComments,
                userSkillId: this.state.userSkill.id,
                overWriteComments: this.state.overWriteComments
            }
            const response = await SkillService.instance.updateUserSkillComments(updateCommentRequest);
            if (!isSuccess<PerformanceReviewCommentResponse>(response) || !response.status) {
                this.setState({ errorMessage: 'PerformanceReview:FailedToFinalizeEvaluations' });
                finalized = false;
            } else if (response.editWarning) {
                this.setState({ overWriteComments: true, shouldShowModalOverWriteComments: true, shouldShowModalChangeSkillLevel: false });
                finalized = false;
            } else if (this.state.callerContextChangeSkillLevel) {
                await this.assignSkillToEmployee(this.state.expirationDate, this.state.targetSkillLevel!);
                window.location.reload();
            }
        }
        return finalized
    }

    private async loadData() {
        const methodName = `${this.className}:loadData()`;
        Logger.log(this.loggerLocality, `${methodName}`);
        // Get URL Parameters
        const partialStateUrlParamState = this.getURLParameters();
        const params = this.props.match.params;
        const skillId: number = params.skillId != null && !isNaN(Number(params.skillId)) ? Number(params.skillId) : 0;
        const userId = partialStateUrlParamState.userId ? partialStateUrlParamState.userId : Session.instance.loginUser!.id;

        let errorMessage: string | undefined;
        let user: User | undefined = undefined;
        let isUserBoss: boolean = false;
        Logger.log(this.loggerLocality, `${partialStateUrlParamState.bossRelationCode} boss relation check`)
        if (partialStateUrlParamState.bossRelationCode) {
            Logger.log(this.loggerLocality, `${methodName} boss relation check`)
            const userResponse = await UserService.instance.getUser(userId);

            if (isSuccess<User>(userResponse)) {
                user = userResponse;
                isUserBoss = true;
                Logger.log(this.loggerLocality, `${methodName} user is boss`)
            } else {
                if (userResponse.detailedObject !== undefined) {
                    errorMessage = CustomErrorMessage.getErrorCodeMessageString(userResponse.detailedObject.subStatusCode);
                }
                else if (userResponse.statusCode && userResponse.statusCode === 405) {
                    errorMessage = 'ErrorMessage:NoAccessForUser';
                }
                else {
                    errorMessage = 'ErrorMessage:LoadEmployeeFailed';
                }
                Logger.log(this.loggerLocality, `${methodName} boss relation failed check, error=${errorMessage}`);
            }
        }

        if (!user && Session.instance.loginUser != null) {
            user = Session.instance.loginUser;
        }

        let skill: UserSkillProfileSkill | undefined;
        let skillLevelSet: ItemSkillLevelTranslatedDisplay[] = [];
        let trainings: Item[] = [];
        let userSkill: UserSkill | undefined;
        let previousData: UserSkill | undefined;

        if (!errorMessage) {

            if (partialStateUrlParamState.profileId >= 0 && skillId > 0) {
                if (partialStateUrlParamState.profileId === this.notAssociatedSkillProfile) {
                    skill = await Session.instance.storage.userTargetSkill.getTargetSkill(skillId, partialStateUrlParamState.userId, partialStateUrlParamState.bossRelationCode);
                } else {
                    skill = await Session.instance.storage.userSkillProfile.getProfileSkill(skillId,
                        partialStateUrlParamState.profileId,
                        partialStateUrlParamState.userId,
                        partialStateUrlParamState.bossRelationCode)
                }
            }
            if (skill) {
                const levels = await SkillService.instance.getSkillLevelsTranslated(Session.instance.getUserLanguageCodeOrFallBack,
                    skill.skillLevelSetId);
                if (isSuccess<ItemSkillLevelTranslatedDisplay[]>(levels)) {
                    skillLevelSet = levels;
                } else {
                    Session.instance.setLastErrorMessage(levels.message);
                    errorMessage = levels.message;
                    Logger.log(this.loggerLocality, `${methodName} failed to load skill levels, error=${errorMessage}`);
                }
            }
            const trainingsResponse = await ItemService.instance.getOutputLessonsForSkill(skillId, userId,
                partialStateUrlParamState.bossRelationCode);
            if (isSuccess<Item[]>(trainingsResponse)) {
                trainings = trainingsResponse;
            } else {
                Session.instance.setLastErrorMessage(trainingsResponse.message);
                errorMessage = trainingsResponse.message;
                Logger.log(this.loggerLocality, `${methodName} failed to load trainings, error=${errorMessage}`);
            }

            const userSkillData = await this.getUserSkill(userId, skillId, partialStateUrlParamState.bossRelationCode);
            let previousSkillData = new UserSkill();
            if (userSkillData && userSkillData.userSkill) {
                previousSkillData = JSON.parse(JSON.stringify(userSkillData.userSkill));
            }
            userSkill = userSkillData.userSkill;
            previousData = previousSkillData;
            if (!errorMessage && userSkillData.errorMessage.length > 0) {
                errorMessage = userSkillData.errorMessage;
                Logger.log(this.loggerLocality, `${methodName} failed to load user skill, error=${errorMessage}`);
            }
        }

        this.setState({
            ...partialStateUrlParamState,
            errorMessage,
            isDataLoaded: true,
            isUserBoss,
            skill,
            skillLevelSet,
            trainings,
            userSkill,
            previousData,
            user
        });
    }

    private async getUserSkill(userId: number, skillId: number, bossRelationCode?: string): Promise<{ userSkill: UserSkill | undefined; errorMessage: string }> {
        let userSkill: UserSkill | undefined;
        const userSkillResponse = await Session.instance.storage.userSkill.getUserSkill(userId, skillId, bossRelationCode);
        let errorMessage = '';
        if (isSuccess<UserSkill | undefined>(userSkillResponse)) {
            userSkill = userSkillResponse;
        }
        else {
            Session.instance.setLastErrorMessage(Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadUserSkills'));
            errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadUserSkills');
        }
        return {
            errorMessage,
            userSkill
        };

    }


    private getURLParameters(): IURLParamState {
        const parameters = new URLSearchParams(window.location.search);

        const urlParamProfileId = parameters.get('profileId');
        const urlParamUserId = parameters.get('userId');
        const urlParamBossRelationCode = parameters.get('bossRelationCode');

        const paramProfileId = urlParamProfileId != null && !isNaN(Number(urlParamProfileId)) ?
            Number(urlParamProfileId) : 0;
        const paramUserId = urlParamUserId != null && !isNaN(Number(urlParamUserId)) ?
            Number(urlParamUserId) : undefined;
        const paramBossRelationCode = urlParamBossRelationCode != null ?
            urlParamBossRelationCode : undefined;


        this.userId = paramUserId ? paramUserId : Session.instance.loginUser!.id;

        return {
            bossRelationCode: paramBossRelationCode,
            profileId: paramProfileId,
            userId: paramUserId,
        };
    }
}