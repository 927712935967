import Session from '$core/Session';
import React from 'react';
import { Tooltip } from '$components/shared/Tooltip';
import { SkillTableItem } from '$components/Skills/SkillItem';

interface IProps {
    skill: SkillTableItem;
    onCollapseStateChanged: (item: SkillTitle) => void;
}


/**
 * SkillTitle renders the title and forwards the onClick event to the parent (MySkillItem.tsx)
 */
export class SkillTitle extends React.Component<IProps> {


    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <div className="pointer"
                aria-label={Session.instance.storage.translation.GetString('Skill:TitleHint')}
                data-tip={Session.instance.storage.translation.GetString('Skill:TitleHint')}
                data-for={`${this.props.skill.skillId}_SkillTitleHintTooltip`}
                onClick={(e) => this.onClick(e)}>
                {this.props.skill.skillTitle}
                <Tooltip id={`${this.props.skill.skillId}_SkillTitleHintTooltip`} />
            </div>
        )

    }

    /**
     * The event must not be propagated, because the parent (MySkill.tsx) will handle it too and immediately remove the selection
     * Forward the clicked item to the parent
     * @param event 
     */
    protected onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.stopPropagation();
        this.props.onCollapseStateChanged(this)
    }
}
