import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';

import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';

import IconskillExpired from '$resources/svgs/skills/skill-expired.svg';
import IconskillMet from '$resources/svgs/skills/skill-met.svg';
import IconskillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import { TrainingPlanInputSkill } from '$src/storage/models/TrainingPlan/TrainingPlanInputSkill';
import { Tooltip } from '$components/shared/Tooltip';
import { NavLink } from 'react-router-dom';
import IconRecommendations from '$resources/svgs/skills/recommendations.svg';
import IconRecommendationsMissing from '$resources/svgs/skills/recommendationsMissing.svg';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

interface IProps {
    trainingPlan: TrainingPlan | null;
    catalogId: number;
}

export default function TrainingPlanPrerequisites(props: IProps) {
    const [trainingPlan, setTrainingPlan] = useState<TrainingPlan>();
    const [catId, setCatId] = useState<number>(0);

    useEffect(() => {
        if (props.trainingPlan) {
            setTrainingPlan(props.trainingPlan);
        }
        if (props.catalogId) {
            setCatId(props.catalogId);
        }
    });

    function renderPrerequisiteIcons(uisk: TrainingPlanInputSkill): JSX.Element {
        let iconName = '';
        if (uisk.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            iconName = IconskillMet;
        }
        else if (uisk.isExpired) {
            iconName = IconskillExpired;
        }
        else {
            iconName = IconskillNotMet;
        }

        return <InlineSVG src={iconName} />
    }

    function renderPrerequisiteTitles(uisk: TrainingPlanInputSkill): string {
        const tr = Session.instance.storage.translation;
        let title = '';
        if (uisk.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            title = tr.GetString('TrainingPlan:FulfilledYes');
        }
        else if (uisk.isExpired) {
            title = tr.GetString('TrainingPlan:SkillExpired');
        }
        else {
            title = tr.GetString('TrainingPlan:FulfilledNo');
        }

        return title;
    }

    function renderRecommendedLessons(uisk: TrainingPlanInputSkill) {
        let recommendedLessonsText;
        let recommendedLessonsMissingText;
        if (uisk.inputSkill != undefined) {
            recommendedLessonsText = Session.instance.storage.translation.GetString('TrainingPlan:RecommendedLessons').Format(uisk.inputSkill.skillTitle);
            recommendedLessonsMissingText = Session.instance.storage.translation.GetString('TrainingPlan:NoRecommendedLessonsFound').Format(uisk.inputSkill.skillTitle);
        }

        return (<>
            {uisk.inputSkill != undefined ?
                uisk.inputSkill.skillNumberOfRecommendaedLessons > 0 ?
                    <div className="badge-parent">
                        <NavLink
                            to={`/trainings/${uisk.inputSkill.skillId}/${uisk.userId}/${catId}/${trainingPlan?.itemId}/${trainingPlan?.itemId}/0/0?context=${EItemDetailCallerContextType.TrainingPlan}`}
                            aria-label={recommendedLessonsText}
                            data-tip={recommendedLessonsText}
                            data-for={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} >
                            <InlineSVG src={IconRecommendations} />
                            <div className="badge__filled" >{uisk.inputSkill.skillNumberOfRecommendaedLessons}</div>
                            <Tooltip id={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} />
                        </NavLink>
                    </div> :
                    <div className="badge-parent"
                        aria-label={recommendedLessonsMissingText}
                        data-tip={recommendedLessonsMissingText}
                        data-for={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} >
                        <InlineSVG src={IconRecommendationsMissing} />
                        <div className="badge__outlined" >{uisk.inputSkill.skillNumberOfRecommendaedLessons}</div>
                        <Tooltip id={`${uisk.inputSkill.skillId}_skillReccomandationTooltip`} />
                    </div> : null}

        </>
        )
    }

    function renderPrerequisites(): JSX.Element[] | JSX.Element {
        const tr = Session.instance.storage.translation;
        if (trainingPlan && trainingPlan.userInputSkills && trainingPlan.userInputSkills.length > 0) {

            const elements: JSX.Element[] = [];
            trainingPlan.userInputSkills.map(
                (uisk, index) =>
                    elements.push(
                        <tr key={index}>
                            <td>{uisk.inputSkill != null ? uisk.inputSkill.skillTitle : '??'}</td>
                            <td colSpan={2}>{uisk.inputSkill != null ? uisk.inputSkill.skillLevelTitle : ''}</td>
                            <td
                                aria-label={tr.GetString('TrainingPlan:Fulfilled') + ': ' + renderPrerequisiteTitles(uisk)}
                                data-tip={tr.GetString('TrainingPlan:Fulfilled') + ': ' + renderPrerequisiteTitles(uisk)}
                                data-for={`${index}_uiSkillTooltip`}>
                                <div className="inlineFlexAboveSm">{renderPrerequisiteIcons(uisk)}
                                    <span className="marginLeft5AboveSm">{renderPrerequisiteTitles(uisk)}</span>
                                </div>
                                <Tooltip id={`${index}_uiSkillTooltip`} />
                            </td>
                            <td>
                                {renderRecommendedLessons(uisk)}
                            </td>
                        </tr>));
            return elements;
        }
        else {
            return (
                <tr>
                    <td colSpan={3}>
                        <NoDataFound message={tr.GetString('TrainingPlan:NoPrerequisites')} />
                    </td>
                </tr>
            );
        }
    }

    return (
        <React.Fragment>
            <table className="tp-table">
                <thead>
                    <tr>
                        <th><Translate>TrainingPlan:Skills</Translate></th>
                        <th colSpan={2} className="tp-table__column--double"><Translate>TrainingPlan:SkillLevel</Translate></th>
                        <th><Translate>TrainingPlan:Fulfilled</Translate></th>
                        <th><Translate>TrainingPlan:Recommendations</Translate></th>
                    </tr>
                </thead>
                <tbody>
                    {renderPrerequisites()}
                </tbody>
            </table>
        </React.Fragment>
    );





}