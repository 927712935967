import React from 'react';

import Session from '$src/core/Session';

interface IProps {
    titleString: string;
}
export class HighlightTitle extends React.Component<IProps, {}> {

    public render() {
        const title = Session.instance.storage.translation.GetString(this.props.titleString);
        return <div className="highlight-title__title">
                        <div>
                            <div>
                                <div>
                                    <span className="heading--secondary" dangerouslySetInnerHTML={{ __html: title }} />
                                </div>
                            </div>
                        </div>
                    </div>;
    }
}