import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { MyCertificatesGroup } from '$components/userProfile/MyCertificatesGroup';
import CertificateService from '$core/Services/CertificateService';
import Session from '$core/Session';
import { ECertificateType } from '$storage/models/enums';
import { UserCertificateViewModel } from '$storage/models/Certificates/UserCertificateViewModel';
import CustomErrorMessage from '$util/CustomErrorMessage';
import { isSuccess } from '$util/Result';
import { Redirect } from 'react-router';

interface IProps {
    userId: number | undefined;
}
interface IState {
    userCertificates: UserCertificateViewModel[] | null;
    errorMessage: string;
    userId: number;
    currentFilter: string;
    selectedYearFilter: string;

}

// this class renders all certificates of the provided user, 
// if the list of certificates for a type is empty, nothing is rendered for this type.
export class MyCertificates extends React.Component<IProps, IState> {
    protected inputControl: HTMLInputElement | null;

    constructor(props: IProps) {
        super(props);

        // Use Session UserId if not empty
        let tempUsrId: number = 0;
        if (Session.instance.loginUser !== null) {
            tempUsrId = Session.instance.loginUser.id;
        } else if (this.props.userId !== undefined) {
            tempUsrId = this.props.userId;
        }


        this.state = {
            currentFilter: '',
            errorMessage: '',
            selectedYearFilter: 'all',
            userCertificates: null,
            userId: tempUsrId
        }
    }

    public async UNSAFE_componentWillMount() {
        const tmp = await CertificateService.instance.getCertificatesForUser(this.state.userId, Session.instance.getUserLanguageCodeOrFallBack);
        // For further developement, uncomment the functions GetFake, CreateFakes at the bottom of the file,  the following line and use fakes instead of tmp in "SetState"
        // const fakes= this.CreateFakes()
        // this.setState({userCertificates: fakes})

        if (isSuccess<UserCertificateViewModel[]>(tmp)) {
            this.setState({ errorMessage: tmp.length > 0 ? '' : 'MyCertificates:NoData', userCertificates: tmp });
        } else {
            if (tmp.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(tmp.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:MyCertificatesFailed' });
            }
        }
        this.forceUpdate();
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('MyCertificates:Title');
    }

    public render() {
        if (this.state.userId === 0) {
            return <Redirect push={true} to="/" />;
        } else {

            const certificatesReachedSkills: UserCertificateViewModel[] = [];
            const certificatesTrainingPlans: UserCertificateViewModel[] = [];
            const certificatesTPlanModules: UserCertificateViewModel[] = [];
            const certificatesLessons: UserCertificateViewModel[] = [];

            if (this.state.userCertificates != null) {
                for (const item of this.state.userCertificates) {
                    if (item.certificate !== undefined) {

                        // Determine which type the item is
                        switch (item.certificate.certificateType) {
                            case ECertificateType.Competence:
                                certificatesReachedSkills.push(item);
                                break;
                            case ECertificateType.Lesson:
                                certificatesLessons.push(item);

                                break;
                            case ECertificateType.Skill:
                                certificatesReachedSkills.push(item);
                                break;
                            case ECertificateType.TPlan:
                                certificatesTrainingPlans.push(item);

                                break;
                            case ECertificateType.TplanBlock:
                                certificatesTPlanModules.push(item);
                                break;
                        }
                    }
                }

            }
            return (
                <div>
                    <div className="l-container" >
                        <div>
                            <MenuBreadCrumb {...this.props} />
                            <div className="l-box--wide">
                                <h1 className="heading__Title"><Translate>MyCertificates:Title</Translate></h1>
                                <div>
                                    <Translate>MyCertificates:SubTitle</Translate>
                                </div>
                            </div>
                            <div className="l-box__input--auto my-certificates__certificate-filter">
                                <input
                                    id="txtFilter"
                                    className="certificate__filter--name"
                                    name="txtFilter"
                                    type="text"
                                    autoComplete={'on'}
                                    aria-label={Session.instance.storage.translation.GetString('Filter:FilterFieldLabel')}
                                    ref={(e) => this.inputControl = e}
                                    placeholder={Session.instance.storage.translation.GetString('Filter:FilterPlaceholder')}
                                    onChange={(e) => { this.setState({ currentFilter: e.target.value })} }
                                    value={this.state.currentFilter}
                                />
                                <select className="certificate__filter--date"
                                    onChange={(e) => { 
                                        this.setState({selectedYearFilter: e.target.value})
                                        }}> 
                                    <option value="all">{Session.instance.storage.translation.GetString('MyCertificates:DropdownFilterAllYears')}</option>
                                    {this.CreateDropDownFilterOptions()}
                                </select>
                            </div>

                            {this.state.userCertificates == null && this.state.errorMessage === '' ? (<ProgressSpinner />) : ''}
                            {this.RenderItems(certificatesLessons, 'MyCertificates:CertificateForLesson', 'LessonTable')}
                            {this.RenderItems(certificatesReachedSkills, 'MyCertificates:CertificateForSkill', 'SkilLTable')}
                            {this.RenderItems(certificatesTPlanModules, 'MyCertificates:CertificateForTPlanBlock', 'TPlanBlockTable')}
                            {this.RenderItems(certificatesTrainingPlans, 'MyCertificates:CertificateForTPlan', 'TPlanTable')}
                            {this.state.errorMessage !== '' ? (
                                <NoDataFound message={Session.instance.storage.translation.GetString(this.state.errorMessage)} />) : ('')
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    private CreateDropDownFilterOptions(){
        const options: string[] = [];
        const elements: Array<JSX.Element> = [];
        if(this.state.userCertificates !== null){
            const userCert = [...this.state.userCertificates];
            userCert.sort((a, b) => a.certificate!= null && 
                                    b.certificate != null && 
                                    a.certificate.acquiredDate.getFullYear() >  
                                    b.certificate.acquiredDate.getFullYear() ? -1 : 1 );
            this.state.userCertificates.forEach(item => {
                if(item.certificate !== undefined && !options.includes(item.certificate.acquiredDate.getFullYear().toString())){
                    options.push(item.certificate.acquiredDate.getFullYear().toString()); 
                }
            });
            options.forEach(option => {
                elements.push(
                    <option value={option}>{option}</option>
                );
            });
        }
        return elements;
    }

    private RenderItems(items: UserCertificateViewModel[], title: string, type: string) {
        if (items.length > 0) {
            return <MyCertificatesGroup headingLevel={2} userCertificates={items} title={title} type={type} 
                    filter={this.state.currentFilter} yearFilter={this.state.selectedYearFilter} />
        }
        return undefined;
    }
}
export default MyCertificates;