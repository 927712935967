import { JsonObject, JsonProperty } from 'json2typescript';

// tslint:disable-next-line:max-classes-per-file

/** Model to get the Credentials of the server side configured guest user */
@JsonObject
export class HomePanel {

    @JsonProperty('id', String, false)
    public id: string = '';
    @JsonProperty('order', Number, false)
    public order: number = 0;
    @JsonProperty('visible', Boolean, false)
    public visible: boolean = true;
    @JsonProperty('expanded', Boolean, false)
    public expanded: boolean = true;


    constructor(id: string, order: number, visible: boolean, expanded: boolean){
        this.id = id;
        this.order = order;
        this.visible = visible;
        this.expanded = expanded;
    }
}
