import { File } from '$src/storage/models/File';
import { JsonObject, JsonProperty } from 'json2typescript';

// tslint:disable-next-line:max-classes-per-file
@JsonObject
export class FileWithContent extends File {

    @JsonProperty('fileContent', String, false)
    public fileContent: string = '';
}
