
import { StepCourse } from '$src/components/ExternalCourse/Steps/StepCourse';
import { StepCourseProvider } from '$src/components/ExternalCourse/Steps/StepCourseProvider';
import { StepRegistration } from '$src/components/externalCourse/Steps/StepRegistration';
import { StepRequest } from '$src/components/ExternalCourse/Steps/StepRequest';
import { StepSchedule } from '$src/components/ExternalCourse/Steps/StepSchedule';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { EStepState, IStepperDefinition, Stepper } from '$src/components/shared/Stepper/Stepper';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { EAttendanceStatus, EItemDetailCallerContextType, EItemDetailCallerType, EItemSubType, ELessonStatus, ERegistrationStatus } from '$src/storage/models/enums';
import { ExternalCourse } from '$src/storage/models/ExternalCourse/ExternalCourse';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { ExternalCourseSchedule } from '$src/storage/models/ExternalCourse/ExternalCourseSchedule';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ItemBreadCrumb } from '../breadCrumb/ItemBreadCrumb';
import { MenuBreadCrumb } from '../breadCrumb/MenuBreadCrumb';
import { StepAttendance } from './Steps/StepAttendance';
import { StepConclusion } from './Steps/StepConclusion';


interface IMatchParams {
    itemRegistrationId: string;
}


type IProps = RouteComponentProps<IMatchParams>

interface IURLParamState {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    showRequestedInfo: boolean;
}

interface IState extends IURLParamState {
    activeStep: number;
    errorMessage: string;
    lastDoneStep: number;
    isDataLoaded: boolean;
    selectedCourseProvider: ExternalCourseProvider | undefined;
    selectedCourse: ExternalCourse | undefined;
    selectedSchedule: ExternalCourseSchedule | undefined;
    externalCourseRegistration: ExternalCourseRegistration | undefined;
    uploadedCourseFiles: FileDataRequest[];
    uploadedRequestFiles: File[];
    requestReason: string;
    showRequestedInfo: boolean;
}


export class RequestWizard extends React.Component<IProps, IState> {
    private itemRegistrationId: number;
    constructor(props: IProps) {
        super(props);


        this.state = {
            activeStep: 0,
            errorMessage: '',
            externalCourseRegistration: undefined,
            isDataLoaded: false,
            itemDetailCallerContextType: EItemDetailCallerContextType.Undefined,
            lastDoneStep: 0,
            requestReason: '',
            selectedCourse: undefined,
            selectedCourseProvider: undefined,
            selectedSchedule: undefined,
            showRequestedInfo: false,
            uploadedCourseFiles: [],
            uploadedRequestFiles: [],
        }
    }

    public componentWillUnmount() {
        document.title = globalConfig.appProperties.title;
    }

    public componentDidMount() {
        // Get URL Parameters
        const partialStateUrlParamState = this.getURLParameters();

        // Set State
        this.setState({ ...partialStateUrlParamState });
    }

    public async componentDidUpdate() {
        // Set document.title, as long as App.tsx componentDidUpdate doesn't work
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;

        const { itemRegistrationId } = this.props.match.params;
        this.itemRegistrationId = itemRegistrationId != null && !isNaN(Number(itemRegistrationId)) ? Number(itemRegistrationId) : 0;

        // if itemRegistrationId is bigger than 0, it's an existant request
        if (!this.state.isDataLoaded) {
            await this.getExternalCourseRegistration();
        }
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return <ProgressSpinner />;
        } else {
            let itemTitle = Session.instance.storage.translation.GetString('Navigation:NewExternalCourseRequest');
            if (this.state.externalCourseRegistration && this.state.externalCourseRegistration.externalCourse) {
                itemTitle = this.state.externalCourseRegistration.externalCourse.title;
            }
            return (
                <div>
                    <div className="l-container">
                        {this.state.itemDetailCallerContextType !== EItemDetailCallerContextType.Undefined ?
                            (<ItemBreadCrumb
                                itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                                parentAssignmentId={0}
                                parentCatalogFolderId={0}
                                parentTrainingPlanId={0}
                                itemDetailType={EItemDetailCallerType.ExternalCourse}
                                itemTitle={itemTitle}
                                registraionId={this.itemRegistrationId}
                                {...this.props} />) :
                            (
                                <MenuBreadCrumb {...this.props} />
                            )
                        }
                        <h1 className="heading__Title">
                            <span dangerouslySetInnerHTML={{ __html: Session.instance.storage.translation.GetString('ExternalCourse:RequestProcedure') }} />
                        </h1>
                        {this.state.errorMessage.length > 0 ?
                            (<span className={'input-message error'}>
                                <Translate>{this.state.errorMessage}</Translate>
                            </span>)
                            :
                            (<div className="request-wizard__main">
                                <Stepper parentHeadingLevel={1} stepperDefinitions={this.getStepperDefinition()} onStepClick={(stepNumber) => this.onStepClicked(stepNumber)} />
                                {this.renderActivatedStepContent()}
                            </div>)}
                    </div>
                </div>
            );
        }

    }

    protected getURLParameters(): IURLParamState {
        const parameters = new URLSearchParams(window.location.search);

        const urlParamContext = parameters.get('context');
        const urlParamShowRequestedInfo = parameters.get('showRequestedInfo') === 'true'; 

        let paramContext: EItemDetailCallerContextType = EItemDetailCallerContextType.Undefined;

        paramContext = urlParamContext != null && !isNaN(Number(urlParamContext)) ?
            Number(urlParamContext) : EItemDetailCallerContextType.Undefined;


        return { itemDetailCallerContextType: paramContext, showRequestedInfo: urlParamShowRequestedInfo }
    }

    private getStepperDefinition(): IStepperDefinition[] {
        const stepperDef: IStepperDefinition[] = [];
        stepperDef.push({
            stepId: 'CourseProvider',
            stepNumber: 1,
            stepState: this.state.activeStep === 1 ? EStepState.IsActive :
                this.state.lastDoneStep >= 1 ? EStepState.IsDone : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:CourseProvider'),
        });
        stepperDef.push({
            stepId: 'Course',
            stepNumber: 2,
            stepState: this.state.activeStep === 2 ? EStepState.IsActive :
                this.state.lastDoneStep >= 2 ? EStepState.IsDone : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:Course'),
        });
        stepperDef.push({
            stepId: 'Schedule',
            stepNumber: 3,
            stepState: this.state.activeStep === 3 ? EStepState.IsActive :
                this.state.lastDoneStep >= 3 ? EStepState.IsDone :
                    this.state.lastDoneStep + 1 === 3 ? EStepState.Undefined : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:Schedule'),
        });
        stepperDef.push({
            stepId: 'Request',
            stepNumber: 4,
            stepState: this.state.activeStep === 4 ? EStepState.IsActive :
                this.state.lastDoneStep >= 4 ? EStepState.IsDone :
                    this.state.lastDoneStep + 1 === 4 ? EStepState.Undefined : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:Request'),
        });
        stepperDef.push({
            stepId: 'Registration',
            stepNumber: 5,
            stepState: this.state.activeStep === 5 ? EStepState.IsActive :
                this.state.lastDoneStep >= 5 ? EStepState.IsDone :
                    this.state.lastDoneStep + 1 === 5 ? EStepState.Undefined : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:Registration'),
        });
        stepperDef.push({
            stepId: 'Attendance',
            stepNumber: 6,
            stepState: this.state.activeStep === 6 ? EStepState.IsActive :
                this.state.lastDoneStep >= 6 ? EStepState.IsDone :
                    this.state.lastDoneStep + 1 === 6 ? EStepState.Undefined : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:Attendance'),
        });
        stepperDef.push({
            stepId: 'Conclusion',
            stepNumber: 7,
            stepState: this.state.activeStep === 7 ? EStepState.IsActive :
                this.state.lastDoneStep >= 7 ? EStepState.IsDone :
                    this.state.lastDoneStep + 1 === 7 ? EStepState.Undefined : EStepState.IsLocked,
            stepTitle: Session.instance.storage.translation.GetString('ExternalCourse:Conclusion'),
        });
        return stepperDef;
    }

    private renderActivatedStepContent(): JSX.Element | null {
        switch (this.state.activeStep) {
            case 1:
                return (
                    <StepCourseProvider
                        parentHeadingLevel={2}
                        selectedCourseProvider={this.state.selectedCourseProvider}
                        onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                        onCourseProviderChanged={(selectedCourseProvider) => this.onCourseProviderChanged(selectedCourseProvider)}
                        isNew={this.itemRegistrationId === 0} />
                )
            case 2:
                return (
                    <StepCourse
                        parentHeadingLevel={2}
                        selectedCourseProvider={this.state.selectedCourseProvider}
                        selectedCourse={this.state.selectedCourse}
                        onCourseChanged={(selectedCourse, uploadedCourseFiles) => this.onCourseChanged(selectedCourse, uploadedCourseFiles)}
                        onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                        isNew={this.itemRegistrationId === 0}
                        uploadedCourseFiles={this.state.uploadedCourseFiles}
                    />
                )
            case 3:
                return (
                    <StepSchedule
                        parentHeadingLevel={2}
                        selectedCourseProvider={this.state.selectedCourseProvider}
                        selectedCourse={this.state.selectedCourse}
                        selectedSchedule={this.state.selectedSchedule}
                        onScheduleChanged={(selectedSchedule) => this.onScheduleChanged(selectedSchedule)}
                        onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                        isNew={this.itemRegistrationId === 0}
                    />
                )
            case 4:
                return (
                    <StepRequest
                        parentHeadingLevel={2}
                        selectedCourseProvider={this.state.selectedCourseProvider}
                        selectedCourse={this.state.selectedCourse}
                        selectedSchedule={this.state.selectedSchedule}
                        onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                        onStepRequestSend={(registrationId) => this.onStepRequestSend(registrationId)}
                        isNew={this.itemRegistrationId === 0}
                        externalCourseRegistration={this.state.externalCourseRegistration}
                        uploadedRequestFiles={this.state.uploadedRequestFiles}
                        uploadedCourseFiles={this.state.uploadedCourseFiles}
                        requestReason={this.state.requestReason}
                        onStepLeave={(requestReason, uploadedRequestFiles) => this.onRequestStepLeave(requestReason, uploadedRequestFiles)}
                        showRequestedInfo={this.state.showRequestedInfo}
                        {...this.props}
                    />
                )
            case 5:
                if (this.state.selectedCourseProvider && this.state.selectedCourse && this.state.selectedSchedule && this.state.externalCourseRegistration) {
                    return (
                        <StepRegistration
                            parentHeadingLevel={2}
                            onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                            externalCourseRegistration={this.state.externalCourseRegistration}
                            onSaveSuccess={() => this.onSaveSuccess()}
                        />
                    )
                }
                return null;
            case 6:
                if (this.state.selectedCourseProvider && this.state.selectedCourse && this.state.selectedSchedule && this.state.externalCourseRegistration) {
                    return (
                        <StepAttendance
                            parentHeadingLevel={2}
                            onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                            externalCourseRegistration={this.state.externalCourseRegistration}
                            onSaveSuccess={() => this.onSaveSuccess()}
                            {...this.props}
                        />
                    )
                }
                return null;
            case 7:
                if (this.state.selectedCourseProvider && this.state.selectedCourse && this.state.selectedSchedule && this.state.externalCourseRegistration) {
                    return (
                        <StepConclusion
                            parentHeadingLevel={2}
                            onStepClicked={(stepNumber) => this.onStepClicked(stepNumber)}
                            externalCourseRegistration={this.state.externalCourseRegistration}
                            onSaveSuccess={() => this.onSaveSuccess()}
                        />
                    )
                }
                return null;
            default:
                return (
                    <div>
                        TODO
                    </div>
                )
        }

    }

    private activateStep() {
        let registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;
        let lessonStatus: ELessonStatus = ELessonStatus.Undefined;
        let hasAttended: boolean = false;
        let externalRegistrationDone: boolean = false;

        if (this.state.externalCourseRegistration) {
            registrationStatus = this.state.externalCourseRegistration.registrationStatus;
            lessonStatus = this.state.externalCourseRegistration.lessonStatus;
            hasAttended = this.state.externalCourseRegistration.attendedStatus === EAttendanceStatus.Attended;
            externalRegistrationDone = this.state.externalCourseRegistration.externalRegistrationDone;
        }

        switch (registrationStatus) {
            case ERegistrationStatus.Undefined:
                this.setState({ activeStep: 1, lastDoneStep: 1 });
                break;
            case ERegistrationStatus.Requested:
                this.setState({ activeStep: 4, lastDoneStep: 3 });
                break;
            case ERegistrationStatus.Accepted:
                this.setState({ activeStep: 5, lastDoneStep: 4 });
                break;
        }

        if (registrationStatus === ERegistrationStatus.Accepted && externalRegistrationDone) {
            this.setState({ activeStep: 6, lastDoneStep: 5 });
        }

        if (registrationStatus === ERegistrationStatus.Accepted && hasAttended) {
            this.setState({ activeStep: 7, lastDoneStep: 6 });
        }

        if (registrationStatus === ERegistrationStatus.Accepted && lessonStatus >= ELessonStatus.Completed && externalRegistrationDone && hasAttended) {
            this.setState({ activeStep: 7, lastDoneStep: 7 })
        }
    }

    private onStepClicked(stepNumber: number) {
        this.setState({ activeStep: stepNumber });
    }

    private onCourseProviderChanged(selectedCourseProvider: ExternalCourseProvider) {
        this.setState({ selectedCourseProvider, selectedCourse: undefined, selectedSchedule: undefined, lastDoneStep: 1 });
    }

    private onCourseChanged(selectedCourse: ExternalCourse, uploadedCourseFiles: FileDataRequest[]) {
        this.setState({ selectedCourse, selectedSchedule: undefined, lastDoneStep: 2, uploadedCourseFiles });
    }

    private onScheduleChanged(selectedSchedule: ExternalCourseSchedule) {
        const lastDoneStep = this.state.lastDoneStep < 3 ? 3 : this.state.lastDoneStep;
        this.setState({ selectedSchedule, lastDoneStep });
    }

    private onStepRequestSend(registrationId: number) {
        window.location.href = 
            // tslint:disable-next-line: max-line-length
            `${window.location.href.substring(0, window.location.href.lastIndexOf('/'))}/${registrationId}?context=${EItemDetailCallerContextType.MyCourses}&showRequestedInfo=true`;
    }

    private onRequestStepLeave(requestReason: string, uploadedRequestFiles: File[]) {
        this.setState({ requestReason, uploadedRequestFiles, showRequestedInfo: false });
    }

    private async getExternalCourseRegistration() {
        if (this.itemRegistrationId > 0) {
            const registration = await ExternalCourseService.instance.getExternalCourseRegistration(this.itemRegistrationId, EItemSubType.External);
            if (isSuccess<ExternalCourseRegistration>(registration)) {
                this.setState({ externalCourseRegistration: registration, isDataLoaded: true })
                this.setExternalCourseDetails();
            } else {
                if (registration.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(registration.detailedObject.subStatusCode), isDataLoaded: true })
                } else {
                    this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesRegistrationFailed', isDataLoaded: true });
                }
            }
        } else {
            this.setState({ isDataLoaded: true });
        }
        this.activateStep();
    }

    private setExternalCourseDetails() {
        if (this.state.externalCourseRegistration) {
            this.setState({
                selectedCourse: this.state.externalCourseRegistration.externalCourse,
                selectedCourseProvider: this.state.externalCourseRegistration.externalCourseProvider,
                selectedSchedule: this.state.externalCourseRegistration.externalCourseClass,
            });
        }
    }

    private onSaveSuccess() {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    }
}
export default RequestWizard;