import { CatalogElement } from "$src/storage/models/Catalog";

export class UserConfigPanel {
    public id: string;
    public component: string;
    public defaultOrder: number;
    public isFixed: boolean;
    public translationString: string;
    public headingStyle: number;
    public isVisible: boolean;
    public isTitleVisible: boolean;
    public catalogFolderContentListConfiguration?: {
        catalogFolderSIds: string[];
        itemStyle?: React.CSSProperties;
        itemLinkStyle?: React.CSSProperties;
        showItemBackground: boolean;
        showNavigationButton: boolean;
        navigationButtonText?: string;
        navigationButtonStyle?: React.CSSProperties;
        maxItems?: number;
    };
    public catalogElement: CatalogElement | null;
    public catalogFolderListConfigurationWithAllowedGroups?: Array<{
        catalogFolderSId: string;
        allowedGroups?: string[];
    }>;
    public nextTrainingConfiguration?: {
        maxAssignments: number;
        maxRegisteredItems: number;
        maxRecommendedItems: number;
        displayCountOfRegisteredItems: boolean;
    };
    public assignmentConfiguration?: { maxAssignments: number };
    public welcomePanelConfiguration?: { title: string };
    public performanceSupportPanelConfiguration?: { maxItems: number; expiringInDaysFromNow: number };
    public externalPageConfiguration?: {
        pageId: string;
    };
    public expanded: boolean;
    public catalogFolderSIDs: string[];
    public allowedGroups?: string[];

    public constructor() {
        this.isVisible = true;
        this.expanded = false;
    }

}