import React from "react";

export default function Firework() {

    return (
        <div className="pyro">
            <div className="before"></div>
            <div className="after"></div>
        </div>
    )
}