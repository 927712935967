import ServiceClient from '$src/core/ServiceClient';
import { UserPreferencesRequest } from '$src/storage/models/RequestObjects/UserPreferencesRequest';
import { UserPreferences } from '$src/storage/models/UserPreferences/UserPreferences';
import GtError from '$src/util/GtError';
import { UrlHelper } from '$src/util/UrlHelper';


/**
 * UserPreferencesService provides all Service Methods for the User Preferences Functionallity
 */
export default class UserPreferencesService extends ServiceClient {
    protected static _instance: UserPreferencesService | null = null;

    protected className = 'UserPreferencesService';
    protected loggerLocality = 'UserPreferencesService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): UserPreferencesService {
        return this._instance || (this._instance = new this());
    }

    public async getUserPreferences(): Promise<UserPreferences| GtError> {
        let response: UserPreferences | GtError;
        response = await this.get<UserPreferences>('userpreferences', UserPreferences);
        return response;
    }

    public async saveUserPreferences(up: UserPreferences): Promise<UserPreferences | GtError> {
        let response: UserPreferences | GtError;
        const obj = new UserPreferencesRequest();
        obj.userId = up.id;
        obj.currentSuiUrl = UrlHelper.getCurrentSuiUrl();
        obj.preferences = JSON.stringify(up.preferences);
        response = await this.post<UserPreferences>('userpreferences', obj, UserPreferences);
        return response;
    }
} 