import React, { useState } from "react";
import Session from '$src/core/Session';
import IconInfo from '$resources/svgs/misc/info.svg';
import IconInfoDisabled from '$resources/svgs/misc/info--disabled.svg';
import InlineSVG from 'react-inlinesvg';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Tooltip } from '$components/shared/Tooltip';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import TrainingPlanCompletitionRequirements, { ICompletitionRequirements } from '$src/components/trainingPlan/TrainingPlanCompletitionRequirements';

interface IProps {
    /** the currently selected training plan element */
    activeTPElement: TrainingPlanElement | null;
    alwaysVisible: boolean;
}

/**
 * Shows an information icon if the active training plan element is a block (aka module).
 * A click on the icon opens a modal popup with the completion requiremts of the block.
 * @param props 
 */
export default function TrainingPlanBlockCompletitionRequirementsIcon(props: IProps) {
    const [infoVisible, setInfoVisible] = useState<boolean>(false);

    if (props.activeTPElement != null && ((
        props.activeTPElement.completitionRequirementsStatus != null &&
        props.activeTPElement.completitionRequirementsStatus.length > 0) || props.alwaysVisible)) {

        const hoverText = Session.instance.storage.translation.GetString('TrainingPlan:CompletionRequirements');
        const tooltipId = `BlockCompletitionRequirementsIconToolTip_${props.activeTPElement.tPElementSid}`;

        let cr: ICompletitionRequirements[] = [];
        if (props.activeTPElement.completitionRequirementsStatus != null && props.activeTPElement.completitionRequirementsStatus.length > 0) {
            cr = props.activeTPElement.completitionRequirementsStatus.map(compReq => {
                return {
                    requiredLessonStatus: compReq.completitionRequirement!.requiredLessonStatus,
                    requiredScore: compReq.completitionRequirement!.requiredScore,
                    requiredItemSID: compReq.completitionRequirement!.requiredItemSID,
                    requiredItemTitle: compReq.requiredItemTitle,
                    currentStatus: compReq.status,
                    currentScore: compReq.score,
                    fulfilled: compReq.fulfilled
                }
            });
        }

        return <>
            {cr.length > 0 &&
                <>
                    <button className="button-image" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setInfoVisible(true);
                    }}>
                        <span aria-label={hoverText}
                            role="img"
                            data-tip={hoverText}
                            data-for={tooltipId}>
                            <InlineSVG src={IconInfo} />
                        </span>
                    </button>
                    <Tooltip id={tooltipId} />
                    <ModalPopup
                        isOpen={infoVisible}
                        onRequestClose={() => setInfoVisible(false)}
                        contentCssClass="modal__tpBlockCompletitionRequirements"
                        title={`${Session.instance.storage.translation.GetString('TrainingPlan:CompletionRequirements')} ${props.activeTPElement.title}`}>
                        <TrainingPlanCompletitionRequirements completitionRequirementsStatus={cr} />
                    </ModalPopup>
                </>
            }
            {cr.length === 0 &&
                <>
                    <span
                        aria-label={hoverText}
                        role="img"
                        data-tip={hoverText}
                        data-for={tooltipId}>
                        <InlineSVG src={IconInfoDisabled} />
                    </span>
                </>}
        </>
    } else {
        return null;
    }
}
