import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class SearchRequest {

    @JsonProperty('searchValue', String, true)
    public searchValue: string = '';

    @JsonProperty('language', String, true)
    public language: string = '';

    @JsonProperty('from', String, true)
    public from: string = '';

    @JsonProperty('to', String, true)
    public to: string = '';

    @JsonProperty('durationMin', Number, true)
    public durationMin: number = 0;

    @JsonProperty('durationMax', Number, true)
    public durationMax: number = 0;

    @JsonProperty('itemTypeArrayAsString', String, true)
    public itemTypeArray: string = '';

    @JsonProperty('subjects', [String], true)
    public subjects: string[] = []

    @JsonProperty('sortOrder', String, true)
    public sortOrder: string = 'Relevance';
}