/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable no-case-declarations */

import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { EItemSubType } from '$src/storage/models/enums';
import { ExternalCourse } from '$src/storage/models/ExternalCourse/ExternalCourse';
import { ExternalCourseEmployeeRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseEmployeeRegistration';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { ExternalCourseSchedule } from '$src/storage/models/ExternalCourse/ExternalCourseSchedule';
import { NumberResponse } from '$src/storage/models/NumberRepsonse';
import { ExternalCourseAttendanceRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseAttendanceRequest';
import { ExternalCourseConclusionRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseConclusionRequest';
import { ExternalCourseRegistrationDoneRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseRegistrationDoneRequest';
import { ExternalCourseRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseRequest';
import { ExternalCourseRevertStatusRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseRevertStatusRequest';
import { ExternalCourseUpdateDocumentsRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseUpdateDocumentsRequest';
import { ExternalCourseUpdateRegistrationStatus } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseUpdateRegistrationStatus';
import { UpdateDocumentRequest } from '$src/storage/models/RequestObjects/ExternalCourse/UpdateDocumentRequest';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import { StringRequest } from '$src/storage/models/RequestObjects/StringRequest';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * ExternalCourseService provides all Service Methods for the External Course Feature 
 */
export default class ExternalCourseService extends ServiceClient {
    protected static _instance: ExternalCourseService | null = null;

    protected className = 'ExternalCourseService';
    protected loggerLocality = 'ExternalCourseService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ExternalCourseService {
        return this._instance || (this._instance = new this());
    }

    //#region GET

    /**
     * Get a list of active external course providers in current ui language and for specified domain
     *
     * @returns {(Promise<ExternalCourseProvider[] | GtError>)}
     * @memberof ExternalCourseService
     */
    public async getExternalCourseProviders(): Promise<ExternalCourseProvider[] | GtError> {
        let response: ExternalCourseProvider[] | GtError;
        response = await this.get<ExternalCourseProvider[]>('externalCourse/providerList', ExternalCourseProvider, undefined, undefined);
        return response;
    }

    /**
     * Get a list of external courses of a provider which has at least one schedule in the future to which the user may register.
     * @param providerId 
     */
    public async getExternalCourseByProvider(providerId: number): Promise<ExternalCourse[] | GtError> {
        let response: ExternalCourse[] | GtError;
        response = await this.get<ExternalCourse[]>('externalCourse/courseList', ExternalCourse, undefined, undefined, providerId.toString());
        return response;
    }

    /**
     * Get a list of schedules of an external course which are in the future and the user may register
     * @param courseId 
     */
    public async getExternalCourseClassListByCourse(courseId: number): Promise<ExternalCourseSchedule[] | GtError> {
        let response: ExternalCourseSchedule[] | GtError;
        response = await this.get<ExternalCourseSchedule[]>('externalCourse/classList', ExternalCourseSchedule, undefined, undefined, courseId.toString());
        return response;
    }

    /**
     * Get external course registration data
     * @param registrationId 
     */
    public async getExternalCourseRegistration(registrationId: number, courseSubType: EItemSubType): Promise<ExternalCourseRegistration | GtError> {
        let response: ExternalCourseRegistration | GtError;
        response = await this.get<ExternalCourseRegistration>('externalCourse/registration',
            ExternalCourseRegistration,
            undefined,
            undefined,
            registrationId.toString(),
            courseSubType.valueOf().toString());
        return response;
    }

    /**
     * Get a list of external course registration for users employees
     */
    public async getExternalCourseEmployeeRegistrations(): Promise<ExternalCourseEmployeeRegistration[] | GtError> {
        let response: ExternalCourseEmployeeRegistration[] | GtError;
        response = await this.get<ExternalCourseEmployeeRegistration[]>('externalCourse/employeeRegistrations',ExternalCourseEmployeeRegistration, undefined, undefined);
        return response;
    }

    //#endregion

    //#region POST

    /**
     * Send a request for a schedule of an external course
     * @param externalCourseRequest 
     */
    public async sendExternalCourseRequest(externalCourseRequest: ExternalCourseRequest): Promise<NumberResponse | GtError> {
        const formData = new FormData();
        let key: keyof ExternalCourseRequest;
        for (key in externalCourseRequest) {
            switch (key) {
                case "provider":
                    const provider = externalCourseRequest[key] as ExternalCourseProvider;
                    formData.append(key, JSON.stringify(provider));
                    break;
                case "course":
                    const course = externalCourseRequest[key] as ExternalCourse;
                    formData.append(key, JSON.stringify(course));
                    break;
                case "schedule":
                    const schedule = externalCourseRequest[key] as ExternalCourseSchedule;
                    formData.append(key, JSON.stringify(schedule));
                    break;
                case "fileCollection":
                    const fC = externalCourseRequest[key] as FileDataRequest[];
                    fC.forEach(f => {
                        formData.append("[fileCollection]:[fileName]:" + f.fileName, f.file as File);
                        formData.append("[fileCollection]:[fileType]:" + f.fileName, f.fileType.toString());
                    })
                    break;
                case "createdCourseDocuments":
                    const eC = externalCourseRequest[key] as FileDataRequest[];
                    eC.forEach(f => {
                        formData.append("[createdCourseDocuments]:[fileName]:" + f.fileName, f.file as File);
                        formData.append("[createdCourseDocuments]:[fileType]:" + f.fileName, f.fileType.toString());
                    })
                    break;
                default:
                    formData.append(key, externalCourseRequest[key].toString());
                    break;
            }
        }
        return await this.postFormData<NumberResponse>('externalCourse/sendRequest', formData, NumberResponse);
    }

    /**
     * Check if sid for new external course provider already exists
     * @param externalCourseProviderName 
     */
    public async externalCourseProviderExists(externalCourseProviderName: string): Promise<BooleanResponse | GtError> {
        const stringRequest = new StringRequest();
        stringRequest.stringValue = externalCourseProviderName;
        return await this.post<BooleanResponse>('externalCourse/externalCourseProviderSidCheck', stringRequest, BooleanResponse, undefined);
    }

    /**
     * Check if sid for new external course already exists
     * @param externalCourseTitle 
     */
    public async externalCourseExists(externalCourseTitle: string): Promise<BooleanResponse | GtError> {
        const stringRequest = new StringRequest();
        stringRequest.stringValue = externalCourseTitle;
        return await this.post<BooleanResponse>('externalCourse/externalCourseSidCheck', stringRequest, BooleanResponse, undefined);
    }

    /**
     * Set registration to external course as dane
     * @param registrationId 
     * @param done 
     */
    public async updateExternalCourseRegistrationDone(registrationId: number, done: boolean): Promise<BooleanResponse | GtError> {
        const externalCourseRegistrationDoneRequest = new ExternalCourseRegistrationDoneRequest();
        externalCourseRegistrationDoneRequest.registrationId = registrationId;
        externalCourseRegistrationDoneRequest.externalCourseRegistrationDone = done;
        return await this.post<BooleanResponse>('externalCourse/updateExternalCourseRegistrationDone', externalCourseRegistrationDoneRequest, BooleanResponse, undefined);
    }

    /**
     * Save attendance for an external course
     * @param registrationId 
     * @param hasAttended 
     * @param cancellationId 
     * @param cancellationReason 
     */
    public async saveExternalCourseAttendance(registrationId: number, 
                                                hasAttended: boolean, 
                                                cancellationId: number, 
                                                cancellationReason: string): Promise<BooleanResponse | GtError> {
        const externalCourseAttendanceRequest = new ExternalCourseAttendanceRequest();
        externalCourseAttendanceRequest.registrationId = registrationId;
        externalCourseAttendanceRequest.hasAttended = hasAttended;
        externalCourseAttendanceRequest.cancellationId = cancellationId;
        externalCourseAttendanceRequest.cancellationReason = cancellationReason;
        return await this.post<BooleanResponse>('externalCourse/saveExternalCourseAttendance', externalCourseAttendanceRequest, BooleanResponse, undefined);
    }
    
    /**
     * Save conclusion for an external course
     * @param externalCourseConclusionRequest 
     */
    public async saveExternalCourseConclusion(request: ExternalCourseConclusionRequest): Promise<BooleanResponse | GtError> {

        const formData = new FormData();
        let key: keyof ExternalCourseConclusionRequest;
        for (key in request) {
            switch (key) {
                case "updateDocumentList":
                    let updateDocumentList = request[key] as UpdateDocumentRequest[];
                    formData.append(key, JSON.stringify(updateDocumentList));
                    break;
                case "fileCollection":
                    let fC = request[key] as FileDataRequest[];
                    fC.forEach(f => {
                        formData.append("[fileCollection]:[fileName]:" + f.fileName, f.file as File);
                        formData.append("[fileCollection]:[fileType]:" + f.fileName, f.fileType.toString());
                    })
                    break;
                default:
                    formData.append(key, request[key].toString());
                    break;
            }
        }

        return await this.postFormData<BooleanResponse>('externalCourse/saveExternalCourseConclusion', formData, BooleanResponse);
    }

    //#region Boss functions

    /**
     * Update registration to accepted or rejected
     * @param externalCourseUpdateRegistrationStatus 
     */
    public async updateRegistrationStatus(externalCourseUpdateRegistrationStatus: ExternalCourseUpdateRegistrationStatus): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('externalCourse/updateRegistrationStatus', externalCourseUpdateRegistrationStatus, BooleanResponse, undefined);
    }

    /**
     * Revert lesson status to allow requested user to upload documents
     * @param externalCourseRevertStatusRequest 
     */
    public async revertLessonStatus(externalCourseRevertStatusRequest: ExternalCourseRevertStatusRequest): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('externalCourse/revertLessonStatus', externalCourseRevertStatusRequest, BooleanResponse, undefined);
    }
        
    /**
     * Update documents
     * @param externalCourseUpdateDocumentsRequest 
     */
    public async updateDocuments(request: ExternalCourseUpdateDocumentsRequest): Promise<BooleanResponse | GtError> {
        const formData = new FormData();
        let key: keyof ExternalCourseUpdateDocumentsRequest;
        for (key in request) {
            switch (key) {
                case "updateDocumentList":
                    let updateDocumentList = request[key] as UpdateDocumentRequest[];
                    formData.append(key, JSON.stringify(updateDocumentList));
                    break;
                case "fileCollection":
                    let fC = request[key] as FileDataRequest[];
                    fC.forEach(f => {
                        formData.append("[fileCollection]:[fileName]:" + f.fileName, f.file as File);
                        formData.append("[fileCollection]:[fileType]:" + f.fileName, f.fileType.toString());
                    })
                    break;
                default:
                    formData.append(key, request[key].toString());
                    break;
            }
        }

        return await this.postFormData<BooleanResponse>('externalCourse/updateDocuments', formData, BooleanResponse);
    }

    //#endregion

    //#endregion
}