import Logger from '$src/core/Logger';
import LocalCache from '$src/storage/cache/LocalCache';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import ShoppingBasket, { VALID_BASKET_VERSION } from '$src/storage/models/ShoppingBasket/ShoppingBasket';
import Session from '$src/core/Session';
import { observable, action } from 'mobx';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';


/**
 * Implements a shopping basket stored in the browsers local storage (per user)
 * The itemCount in the basket is @observable
 */
export default class ShoppingBasketStorage extends LocalCache<string, ShoppingBasket> {
    protected className = 'ShoppingBasketStorage';
    protected loggerLocality = 'Storage.ShoppingBasket';

    /** Number of items in the shopping basket.
     *  Observable to allow updates of dependent components like the Shopping Basket Icon
    */
    @observable
    private itemCount: number = 0;
    public getItemCount(): number {
        return this.itemCount;
    }

    private basket: ShoppingBasket;

    constructor() {
        super(
            undefined, 
            localStorage, 
            'ShoppingBasket-', 
            false, 
            false);             
        this.loadBasket();
    }

    /**
     * Get the shopping basket's content
     */
    public get shoppingBasketContent(): ShoppingBasket {
        return this.basket;
    }

    /**
     * Set the shopping basket's content
     */
    public set shoppingBasketContent(shoppingBasket: ShoppingBasket) {
        this.basket = shoppingBasket;
        this.saveBasket();
    }


    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ShoppingBasketStorage {
        return this._instance || (this._instance = new this());
    }
    protected static _instance: ShoppingBasketStorage | null = null;

    /**
     * Returns the key name used to store the basket
     */
    protected getBasketKeyName(): string {
        // the username and domain is used as key in the local storage, since several users might use the same browser
        return (Session.instance.loginUser?.domainName || '') + '\\' + (Session.instance.loginUser?.username || 'unknown')
    }

    public onUserChanged(): void {
        // since the basket is stored per user, we have to load the basket of the new user
        this.loadBasket();
    }

    /**
     * Clear the shopping basket
     */
    @action
    public clear(): void {
        super.clear();
        this.loadBasket();
    }

    private removeBasketFromStorage(): void {
        this.removeObjectFromCache(this.getBasketKeyName());
        Logger.log(this.loggerLocality, `Removed stored basket for ${this.getBasketKeyName()}`);
    }

    /** 
     * Save the shopping basket to the browsers local storage 
     * */
    @action
    private saveBasket(): void {
        const methodName = `${this.className}:saveBasket()`;
        this.basket.saveTimeStamp = new Date();
        this.itemCount = this.basket.shoppingBasketItems.length; 
        Logger.log(this.loggerLocality, `${methodName} saving basket for ${this.getBasketKeyName()} with ${this.itemCount} items`);
        this.saveObjectToCache(this.getBasketKeyName(), this.basket);
    }

    /** 
     * Load the basket from the browsers local storage if present or initialize an empty one
     * */
    private loadBasket(): void {
        const methodName = `${this.className}:loadBasket()`;
        try {
            const objectFromStorage = this.getObjectFromCache(this.getBasketKeyName());
            
            // Make a real ShoppingBasket class instance from the object retrieved from Cache.
            // Note: The LocalCache class used as base for the ShoppingBasketStorage does not deliver an
            // original ShoppingBasket class instance, only a JS object that "looks alike".
            // To get the "real thing" we need to use the JsonConvert from json2typescript.
            let basketFromStorage: ShoppingBasket | null = null;
            if (objectFromStorage != null) {
                // code fragment taken from ServiceClient.ts
                const jsonConvert: JsonConvert = new JsonConvert();
                jsonConvert.operationMode = OperationMode.ENABLE; 
                jsonConvert.ignorePrimitiveChecks = false; 
                jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; 
                basketFromStorage = jsonConvert.deserialize(objectFromStorage, ShoppingBasket);
            }
            
            // if a basket was loaded then check whether it's valid and discard it otherwise
            if (basketFromStorage != null) {
                // Check whether the stored basket has the current valid version number
                if (basketFromStorage.version !== VALID_BASKET_VERSION) {
                    Logger.log(this.loggerLocality, `${methodName} Discarding ShoppingBasket with version ${basketFromStorage.version}`)
                    this.removeBasketFromStorage();
                    basketFromStorage = null;
                } else {
                    // check whether the stored basket is too old
                    const configuredPersistencyTimeHours = globalConfig.shoppingProperties?.shoppingBasketPersistencyTime;      
                    if (configuredPersistencyTimeHours && basketFromStorage.saveTimeStamp) {
                        const basketAgeMillisec = Date.now() - basketFromStorage.saveTimeStamp.getTime();
                        if (basketAgeMillisec > (configuredPersistencyTimeHours * 1000 * 3600)) { 
                            Logger.log(this.loggerLocality, `${methodName} Discarding ShoppingBasket because of age.`)
                            this.removeBasketFromStorage();
                            basketFromStorage = null;
                        }
                    } else {
                        Logger.log(this.loggerLocality, `${methodName} Discarding ShoppingBasket because of missing saveTimeStamp or missing shoppingBasketPersistencyTime`)                            
                        this.removeBasketFromStorage();
                        basketFromStorage = null;
                    }
                }
            }

            // if we could load a valid basket then we use it, otherwise create a new one
            if (basketFromStorage != null) {
                this.basket = basketFromStorage;
                this.itemCount = this.basket.shoppingBasketItems.length;
                Logger.log(this.loggerLocality, `${methodName} loaded basket for ${this.getBasketKeyName()} with ${this.itemCount} items`);
            } else {
                this.basket = new ShoppingBasket(); 
                this.itemCount = 0;
                Logger.log(this.loggerLocality, `${methodName} no basket for ${this.getBasketKeyName()} to load, created a new one`);
            }    
        }
        catch (ex) {
            this.removeBasketFromStorage();
            this.basket = new ShoppingBasket(); 
            this.itemCount = 0;
            console.error(`${methodName} Discarded basket for ${this.getBasketKeyName()} because incompatible or corrupt, created a new one (ex=${ex.toString()})`);
        }
    }

    /**
     * Check whether the item identified by itemId and classId is already in the shopping basket
     * @param itemId    item id of the WBT or LP/F2F course
     * @param classId   TP/F2F class id (0 for WBT)
     */
    public isItemInBasket(itemId: number, classId: number): boolean {
        const item = this.basket.shoppingBasketItems.find(i => i.itemId === itemId && i.classId === classId);
        return (item !== undefined);        
    }

    /**
     * Add an item to the shopping basket
     * @param {ShoppingBasketItem} shoppingBasketItem
     * @returns {bool} true if item was added
     */
    public addItemToBasket(shoppingBasketItem: ShoppingBasketItem): boolean {
        const methodName = `${this.className}:addItemToBasket()`;
        Logger.log(this.loggerLocality, `${methodName} adding itemId:${shoppingBasketItem.itemId} (${shoppingBasketItem.sId}), classId:${shoppingBasketItem.classId} (${shoppingBasketItem.classCode})`);
        this.basket.shoppingBasketItems.push(shoppingBasketItem);
        this.saveBasket();
        return true; // currently always true, but in the future we might add some validation that causes other return values
    }

    /**
     * Remove the item identified by itemId and classId from the shopping basket
     * @param itemId 
     * @param classId 
     */
    public removeItemFromBasket(itemId: number, classId: number): void {
        const methodName = `${this.className}:removeItemFromBasket()`;
        Logger.log(this.loggerLocality, `${methodName} removing itemId:${itemId}, classId:${classId}`);
        this.basket.shoppingBasketItems = this.basket.shoppingBasketItems.filter(i => !(i.itemId === itemId && i.classId === classId));
        this.saveBasket();
    }

    /**
     * Validates voucher on client side before server side validation 
     * Will check, if voucher was already added to basket
     * @param voucherCode Voucher code to check
     */
    public prevalidateVoucher(voucherCode: string): {isValid: boolean; error?: string} {
        if (this.basket.shoppingBasketItems.some(p => p.bookUsers?.some(u => u.price.voucherCode === voucherCode)) || this.basket.voucherCode === voucherCode) {
            return { isValid: false, error: 'ShoppingBasketContent:VoucherAlreadyUsedInBasket' };
        }

        return { isValid: true };
    }
}