import React from 'react';

import { CatalogFolderListItem } from '$src/components/catalogFolderList/CatalogFolderListItem';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import Session from '$src/core/Session';
import { CatalogElement } from '$src/storage/models/Catalog';
import { isSuccess } from '$src/util/Result';

interface IProps {
    catelogFoldersIdsWithGroupNames?: Array<{
        catalogFolderSId: string;
        allowedGroups?: string[];
    }>;
    parentHeadingLevel: number;

    catalogElement?: CatalogElement | null;
}
interface IState {
    catalogFolders: CatalogElement[] | null;
    dataLoaded: boolean;
}

export class CatalogFolderList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            catalogFolders: null,
            dataLoaded: false
        };
    }

    public async componentDidMount() {
        if(this.state.dataLoaded === false && this.props.catelogFoldersIdsWithGroupNames != null && this.props.catelogFoldersIdsWithGroupNames.length > 0){
            let catalogFolderSIDs = new Array<string>();
            this.props.catelogFoldersIdsWithGroupNames.forEach(element => {
                // allowedGroups kann null sein -> alle dürfen das CatalogFolderListItem sehen
                if(element.allowedGroups == null || Session.instance.isCurrentUserMemberOfAtLeastOneOfGroups(element.allowedGroups)){
                    catalogFolderSIDs.push(element.catalogFolderSId);
                }
            });
            catalogFolderSIDs = catalogFolderSIDs.filter((x, i, a) => a.indexOf(x) === i);
            await this.getCatalogFolders(catalogFolderSIDs);
        }
    }

    public render() {
        if (this.state.dataLoaded === false && this.props.catelogFoldersIdsWithGroupNames != null && this.props.catelogFoldersIdsWithGroupNames.length > 0) {
            return <ProgressSpinner />
        }
        else if (this.state.dataLoaded === true && this.state.catalogFolders !== null && this.state.catalogFolders.length > 0) {
            return <ol className="l-box-container catalog-folder-list">
                {this.state.catalogFolders.map(catElement =>
                    <li key={`li_${catElement.sId}`} className="l-box--short l-box--shadow item-summary">
                        <CatalogFolderListItem key={catElement.sId} catalogFolder={catElement} {...this.props} />
                    </li>
                )
                }
                </ol>
        }
        else {
            return <div/>
        }
    }

    protected async getCatalogFolders(catalogFolderSIDs: string[]): Promise<void> {
        const response: CatalogElement | null = this.props.catalogElement != null 
            ? this.props.catalogElement : await Session.instance.storage.catalog.getCatalogFolderBySIds(catalogFolderSIDs);
        if (isSuccess<CatalogElement | null>(response)) {
            if (response !== null && response.children !== null) {
                const folders = response.children;
                this.setState({ catalogFolders: folders, dataLoaded: true });
                }
            }
            else {
                this.setState({ catalogFolders: [], dataLoaded: true });
            }
        }
    }