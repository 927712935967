import { JsonObject, JsonProperty} from 'json2typescript';
import { VirtualMeetingLink } from '$storage/models/F2F/VirtualMeetingLink';

@JsonObject

export class Room {
    /** Id of the corresponding room */
    @JsonProperty('roomId', Number, false)
    public roomId: number = 0;

    /** String Id of the corresponding room */
    @JsonProperty('roomSid', String, false)
    public roomSid: string = '';

    /** Title  of the corresponding room in the selected language */
    @JsonProperty('roomTitle', String, false)
    public roomTitle: string = '';

    /** Venue Building of the corresponding room */
    @JsonProperty('venueId', Number, false)
    public venueId: number = 0;
    
    /** Venue Building of the corresponding room */
    @JsonProperty('venueBuilding', String, false)
    public venueBuilding: string = '';

    /** Venue Address of the corresponding room */
    @JsonProperty('venueAddress', String, false)
    public venueAddress: string = '';

    /** Venue Zip Code of the corresponding room */
    @JsonProperty('venueZipCode', String, false)
    public venueZipCode: string = '';

    /** Venue City of the corresponding room */
    @JsonProperty('venueCity', String, false)
    public venueCity: string = '';

    /** Venue Country of the corresponding room */
    @JsonProperty('venueCountry', String, false)
    public venueCountry: string = '';

    /** Venue Contact of the corresponding room */
    @JsonProperty('venueContact', String, false)
    public venueContact: string = '';

    /** Venue Email from the Contact Person of the corresponding room */
    @JsonProperty('venueEmail', String, false)
    public venueEmail: string = '';

    /** Venue Phonenumber from the Contact Person */
    @JsonProperty('venuePhone', String, false)
    public venuePhone: string = '';

    /** Venue Link from the corresponding room */
    @JsonProperty('venueLink', String, false)
    public venueLink: string = '';

    /** Room Number of the corresponding room */
    @JsonProperty('roomNr', String, false)
    public roomNr: number = 0;
    
    /** Venue Title of the corresponding room */
    @JsonProperty('venueTitle', String, false)
    public venueTitle: string = '';

    @JsonProperty('roomTypeCode', String, false)
    public roomTypeCode: string = '';

    @JsonProperty('externalMeetingId', String, false)
    public externalMeetingId: string = '';

    @JsonProperty('virtualMeetingLink', VirtualMeetingLink, false)
    public  virtualMeetingLink: VirtualMeetingLink | null = null;
    
}