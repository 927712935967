import Iconclear from '$resources/svgs/misc/clear.svg';
import Session from '$src/core/Session';
import { DatePicker as KendoDatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

interface IProps {
    onChange?: (date: Date | null) => void;
    value: Date | null;
    className?: string;
    id?: string;
    readOnly?: boolean;
    required?: boolean;
    isNotClearable?: boolean;
    minDate?: Date;
    maxDate?: Date;
    hasFocus?: boolean;
    name?: string;
    width?: string | number | undefined;
}

interface IState {
    selectedDate: Date | null;
}

export class DatePicker extends React.Component<IProps, IState> {
    protected datepicker: KendoDatePicker | null

    constructor(props: IProps) {
        super(props);

        this.state = {
            selectedDate: this.props.value
        }
    }
    public componentDidMount(): void {
        if ( this.props.hasFocus) {
            this.setFocus();
        }
    }
    public render() {
        return (
            <div className={this.props.className == null ? 'inlineFlex' : this.props.className}>
                <KendoDatePicker
                    onChange={(e) => this.datePickerChanged(e)}
                    format={Session.instance.storage.translation.GetString('DatePicker:Format')}
                    formatPlaceholder={{
                        day: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderDay'),
                        month: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderMonth'),
                        year: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderYear'),
                    }}
                    value={this.state.selectedDate}
                    id={this.props.id}
                    name={this.props.name}
                    disabled={this.props.readOnly}
                    required={this.props.required}
                    min={this.props.minDate}
                    max={this.props.maxDate}
                    ref={(e) => this.datepicker = e}
                    width={this.props.width}
                />
                {!this.props.isNotClearable &&
                    <button type={"button"} className="button-link" onClick={() => this.datePickerCleared()}>
                        <InlineSVG src={Iconclear} />
                    </button>
                }
            </div>
        )
    }

    private datePickerCleared() {
        if (this.props.onChange) {
            this.props.onChange(null);
        }
        this.setState({
            selectedDate: null
        })
    }

    private datePickerChanged(event: DatePickerChangeEvent) {
        if (this.props.onChange) {
            this.props.onChange(event.value);
        }
        this.setState({
            selectedDate: event.value
        })
    }

    /**
     * Set input focus to input field.
     */
    private setFocus() {
        if (this.datepicker !== null && this.datepicker.dateInput !== null && this.datepicker.dateInput.element !== null) {
            this.datepicker.dateInput.element.focus();
        }
    }
}