import Logger from '$src/core/Logger';
import TrainingPlanService from '$src/core/Services/TrainingPlanService';
import SessionCache from '$src/storage/cache/SessionCache';
import { isSuccess } from '$src/util/Result';
import { EItemDetailCallerContextType } from './models/enums';
import { TrainingPlan } from './models/TrainingPlan/TrainingPlan';

const CACHING_DURATION = 300;

export default class TrainingPlanStorage extends SessionCache<number, TrainingPlan> {
    protected className = 'TrainingPlanStorage';
    protected loggerLocality = 'Storage.TrainingPlanStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getTrainingPlan(itemId: number, itemContext: EItemDetailCallerContextType, forceLoadingFromServer = true): Promise<TrainingPlan | null> {
        const methodName = `${this.className}:getTrainingPlan()`;

        if (this.isObjectInCache(itemId) && !forceLoadingFromServer) {
            Logger.log(this.loggerLocality, `${methodName} getting TrainingPlan from cache, itemId=${itemId}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting TrainingPlan from server, itemId=${itemId}`);
            const response = await TrainingPlanService.instance.getTrainingPlan(itemId, itemContext);
            if (isSuccess<TrainingPlan>(response)) {
                this.saveObjectToCache(itemId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get TrainingPlan from server, itemId=${itemId}.`);
            }
        }
        return this.getObjectFromCache(itemId);
    }
   
    public clear(): void {
        super.clear();
    }

    public isTPCached(itemId: number){
        return this.isObjectInCache(itemId);
    }

    public removeTPFromCache(itemId: number) {
        this.removeObjectFromCache(itemId);
    }

    public isCacheExpired(): boolean {
        return false;
    }
}