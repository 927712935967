import {
    GridCellProps,
} from '@progress/kendo-react-grid';

export class KendoGridCellHelper {
    public static resolve(path: string, obj: any): any {
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, '');           // strip a leading dot
        const pathArray = path.split('.');
        for (let i = 0, n = pathArray.length; i < n; ++i) {
            const pathOfPart = pathArray[i];
            if (pathOfPart in obj) {
                obj = obj[pathOfPart];
            } else {
                return;
            }
        }
        return obj;
    }

    public static getValue(cellProps: GridCellProps): any {
        let value = undefined;
        if (cellProps.field != null && cellProps.dataItem) {
            value = KendoGridCellHelper.resolve(cellProps.field, cellProps.dataItem)
        }
        return value;
    }
}
