import requestPromise from 'request-promise';

import Logger from '$src/core/Logger';
import { SsoAuthenticationStatus } from '$src/storage/models/SsoAuthenticationStatus';
import Session from '$core/Session';
import { SsoSuiConfiguration } from '$src/storage/models/SsoSuiConfiguration';
import { SsoSuiConfigurationIdentityProvider } from '$src/storage/models/SsoSuiConfigurationIdentityProvider';
import { action, computed } from 'mobx';

export default class SsoServiceProviderService {
    protected static _instance: SsoServiceProviderService | null = null;

    protected _serviceUrl: string;

    protected className = 'SsoServiceProvider';
    protected loggerLocality = 'SsoServiceProviderService';

    protected ssoTrigger: number;

    protected _stopAutoSso: boolean = false;

    /**
     * stop auto sso
     */
    @action
    public setStopAutoSso(value: boolean) {
        this._stopAutoSso = value;
    }

    /**
     * is auto sso stopped
     */
    @computed
    public get getStopAutoSso() {
        return this._stopAutoSso;
    }

    /**
     * Redirection to sso service provider started     *
     * @type {Function}
     * @memberof SsoServiceProviderService
     */
    public onSsoRedirectionStarted: Function;

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): SsoServiceProviderService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Initialize service client.
     * @param protocol http or https
     * @param hostname of the web API server
     * @param port PortSuffix to use (e.g. ':2020' or '' if none)
     * @param baseUrl Base URL to use, i.e. the part of the URL between hostname and API name
     */
    public init(protocol: string, hostname: string, portSuffix: string, baseUrl: string): void {
        const methodName = `${this.className}:init()`;
        this._serviceUrl = `${protocol}://${hostname}${portSuffix}/${baseUrl}`;
        Logger.log(this.loggerLocality, `${methodName} service url is ${this._serviceUrl}`);
    }

    /**
     * Returns the base url of the service application
     * @memberof SsoServiceProviderService
     */
    public getUrl(): string {
        const url: string = `${this._serviceUrl}`;
        return url;
    }

    /**
     * Redirects to the GT sso service provider to initiate the sso process
     *
     * @param {SsoSuiConfigurationIdentityProvider} identityProviderConfig configuration fetched from SSOSP
     * @memberof SsoServiceProviderService
     */
    public startSso(identityProviderConfig: SsoSuiConfigurationIdentityProvider): void {
        Session.instance.storeCurrentSsoIdP(identityProviderConfig.identityProvider);
        const url = this.getSsoLoginUrl(identityProviderConfig);
        if (this.onSsoRedirectionStarted) {
            this.onSsoRedirectionStarted();
        }
        window.location.href = url;
    }

    /**
     * Just reset session current idp
     * 
     * @memberof SsoServiceProviderService
     */
    public sessionSignOut(): void {
        if (!this.isAnySsoEnabled()) {
            return;
        }
        Session.instance.storeCurrentSsoIdP('');
    }

    /**
     * Returns the url the browser has to open to initiate sso
     * 
     * @param {string} identityProvider Valid strings are GoogleOpenIdConnect, Saml2, OpenIdConnect, OAuth2 or globalConfig.loginProperties.clientSSOProviderId for domain specific provider ids
     * @param {string} returnUrl The url to return after the authentication process. The return url will contain a token to fetch the sso status
     * @returns {string} Complete url to GTSsoServiceProvider login action
     * @memberof SsoServiceProviderService
     */
    private getSsoLoginUrl(identityProviderConfig: SsoSuiConfigurationIdentityProvider): string {
        return `${SsoServiceProviderService.instance.getUrl()}${identityProviderConfig.authEndpoint}&returnUrl=${encodeURIComponent(window.location.href)}&domainName=${globalConfig.loginProperties.defaultDomain}`;
    }

    /**
     * Returns the url to sign out from the GT sso service provider
     *
     * @param {string} identityProvider Valid strings are Google, Saml2, OpenIdConnect, OAuth2 or globalConfig.loginProperties.clientSSOProviderId for domain specific provider ids
     * @returns {string} Complete url to GTSsoServiceProvider sign out action
     * @memberof SsoServiceProviderService
     */
    public getSsoBackgroundSignOutUrl(): string {
        return `${SsoServiceProviderService.instance.getUrl()}BackgroundSignOut`;
    }

    /**
     * Returns the url of the merge user api call
     *
     * @param {string} accessToken access token for external usage to retrieve jwt token
     * @returns {string} Complete url
     * @memberof SsoServiceProviderService
     */
    public getTryMergeUserUrl(accessToken: string): string {
        return `${SsoServiceProviderService.instance.getUrl()}ssoapi/TryBackgroundMergeUser/${accessToken}`;
    }

    /**
     * Calls ssosp CreateUser to create a new user from sso auth
     *
     * @returns {string} Complete url
     * @memberof SsoServiceProviderService
     */
    public async createUser(providerId: string): Promise<Response> {
        const url = `${SsoServiceProviderService.instance.getUrl()}ssoapi/CreateUser?providerId=${providerId}`;
        return fetch(url, { credentials: 'include' });
    }

    /**
     * Returns the url of the create sso user api call
     *
     * @returns {string} Complete url
     * @memberof SsoServiceProviderService
     */
    public getCreateUserUrl(providerId: string): string {
        const url = `${SsoServiceProviderService.instance.getUrl()}ssoapi/TryBackgroundCreateUser?providerId=${providerId}`;
        return url;
    }

    /**
     * Is any of the new GT sso service provider options enabled
     *
     * @returns {boolean} true or false
     * @memberof SsoServiceProviderService
     */
    public isAnySsoEnabled(): boolean {
        return this.getEnabledIdentityProviders().length > 0;
    }

    /**
     * Fetches configured identity providers and the corresponding sui configuration from SSOSP
     */
    public async getConfiguration(): Promise<SsoSuiConfiguration> {
        let response: SsoSuiConfiguration;

        try {
            const res = await requestPromise.get(`${this._serviceUrl}ssoapi/SuiConfiguration`);
            response = JSON.parse(res);
        } catch (error) {
            Logger.log(this.loggerLocality, error);
            response = new SsoSuiConfiguration();
        }

        return response;
    }

    /**
     * Returns a list of enabled identity providers
     * Strings matches the identity providers from SsoSuiConfiguration
     */
    public getEnabledIdentityProviders(): string[] {
        const res: string[] = [];

        if (globalConfig.loginProperties.enableGoogleSSO) {
            res.push('GoogleOpenIdConnect');
        }
        if (globalConfig.loginProperties.enableSamlSSO) {
            res.push('Saml2');
        }
        if (globalConfig.loginProperties.enableOpenIdConnectSSO) {
            res.push('OpenIdConnect');
        }
        if (globalConfig.loginProperties.enableOAuthSSO) {
            res.push('OAuth2');
        }
        if (globalConfig.loginProperties.enableWindowsSSO) {
            res.push('Windows');
        }
        if (globalConfig.loginProperties.clientSSOProviderId) {
            res.push(globalConfig.loginProperties.clientSSOProviderId);
        }

        return res;
    }

    /**
     * Fetches the authentication status for the given sso token
     * @param {string} ssoToken
     * @returns {Promise<SsoAuthenticationStatus>}
     * @memberof SsoServiceProvider
     */
    public async getSsoStatus(ssoToken: string): Promise<SsoAuthenticationStatus> {
        let response: SsoAuthenticationStatus;

        try {
            const res = await requestPromise.get(`${this._serviceUrl}ssoapi/AuthenticationStatus?ssoToken=${ssoToken}`);
            response = JSON.parse(res);
        } catch (error) {
            Logger.log(this.loggerLocality, error);
            response = new SsoAuthenticationStatus();
            response.statusString = 'ApiStatus500';
        }

        return response;
    }
}