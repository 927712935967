import React from 'react';

import { Contact } from '$components/contact/Contact';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import { KeyPressHelper } from '$src/util/KeyPressHelper';

import footerSvg from '$src/resources/defaultImages/footer_header_space.custom.svg'
import InlineSVG from 'react-inlinesvg';

interface IAppState {
    shouldShowModal: boolean;
    modalTitle: string;
}

export class Footer extends React.Component<{}, IAppState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            modalTitle: '',
            shouldShowModal: false,
        }
    }

    public render() {
        return (
            <>
                <footer className="footer">
                    <div className="l-container l-container--flex">
                        {this.sortFooterOrder()}
                    </div>
                </footer >
                {this.renderFooterImage()}
            </>

        );
    }

    private renderFooterImage() {
        if (!globalConfig.footerConfiguration.imageEnabled) {
            return null;
        }

        return (
            <div className="footer__background-image">
                <InlineSVG src={footerSvg} />
            </div>
        )
    }

    private sortFooterOrder() {
        const footerElementsConfig = globalConfig.footerProperties;
        footerElementsConfig.sort((a, b) => a.position - b.position);
        return footerElementsConfig.map((element, index) => {
            if (element.footerType === 'logo') {
                return <div key={`footer_${index}`} className="footer__logo" />
            }
            else if (element.footerType === 'contact') {
                return <div key={`footer_${index}`} className="footer__element">
                    <button
                        className="button-link"
                        aria-haspopup={"dialog"}
                        onClick={() => this.openModal()}
                        // changed from onKeyUp to down, had an issue with opening the Modal window again after closing with enter.
                        onKeyDown={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => this.openModal()) }}>
                        <Translate>{element.text}</Translate>
                    </button>
                    <ModalPopup
                        isOpen={this.state.shouldShowModal}
                        onRequestClose={() => this.closeModal()}>
                        <Contact />
                    </ModalPopup>
                </div>
            }
            else if (element.footerType === 'link') {
                return <div key={`footer_${index}`} className="footer__element">
                    <a href={Session.instance.storage.translation.GetString(`${element.link}`)} target="_blank">
                        <Translate>{element.text}</Translate>
                    </a>
                </div>
            } else {
                return <div key={`footer_${index}`} className="footer__element">
                    <span><Translate>{element.text}</Translate></span>
                </div>
            }
        })
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false, modalTitle: Session.instance.storage.translation.GetString('Contact:PopUpTitle') }); // Replace with actual Titel translation
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true, modalTitle: Session.instance.storage.translation.GetString('Contact:PopUpTitle') }); // Replace with actual Titel translation
    }
}