import ServiceClient from '$src/core/ServiceClient';
import { MediaTokenResponse } from '$src/storage/models/MediaTokenResponse';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import Logger from '../Logger';
// import Session from '../Session';

/**
 * This is just the template class for creating a new Service Object
 * You can copy this class to create a new Service. 
 * Don't forget that you have to initialize the Service in boot.tsx
 */
export default class MediaService extends ServiceClient {

    protected static _instance: MediaService | null = null;

    protected className = 'MediaService';
    protected loggerLocality = 'MediaService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): MediaService {
        return this._instance || (this._instance = new this());
    }

    public getMediaUrl(id: number) {
        const mediaUrl = this.getUrl() + 'media/' + id;
        return mediaUrl;
    }

    public getPreparedDocumentUrl(id: number): string {
        return this.getUrl() + 'media/document/{token}/' + id;
    }

    public async getDocumentUrl(preparedDocumentUrl: string) {
        let mediaTokenResponse: MediaTokenResponse | GtError;   
        mediaTokenResponse = await this.getSetExternalAccessToken('GTService:Document');
        if (isSuccess<MediaTokenResponse>(mediaTokenResponse)) {
            return preparedDocumentUrl.replace('{token}', mediaTokenResponse.token);
        }
        return '';
    }

    private async getSetExternalAccessToken(tokenName: string): Promise<MediaTokenResponse | GtError> {
        let response: MediaTokenResponse | GtError;
        response = await this.get<MediaTokenResponse>('media/getAccessTokenForMedia/', MediaTokenResponse, undefined, undefined, tokenName.replace(':', '_'));
        if (isSuccess<MediaTokenResponse>(response)) {
            const jsonMediaTokenReponse = JSON.stringify(response);
            sessionStorage.setItem('GTService:Media', jsonMediaTokenReponse);
        }
        else {
            Logger.log(this.loggerLocality, `${this.className} failed to get data, ${response.message}`);
            console.error(`${this.loggerLocality} ${this.className} failed to get data, ${response.message}`);
            return response;
        }
        return response;
    }

} 