import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useState, useEffect } from 'react';
import { Grid, GridNoRecords, GridColumn, GridExpandChangeEvent, GridPageChangeEvent } from '@progress/kendo-react-grid';

import PurchaseService from '$src/core/Services/PurchaseService';
import { PaymentBookingOverviewEntry, PaymentBookingOverviewResponse } from '$src/storage/models/Purchase/PaymentBookingOverviewResponse';
import Session from '$src/core/Session';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import PaymentBookingOverviewFilter from '$components/PaymentBookingOverview/PaymentBookingOverviewFilter';
import PaymentBookingOverviewDetailsRow from '$components/PaymentBookingOverview/PaymentBookingOverviewDetailsRow';
import PaymentBookingOverviewExport from '$components/PaymentBookingOverview/PaymentBookingOverviewExport';

const getTranslation = (str: string) => {
    return Session.instance.storage.translation.GetString(`PaymentBookingOverview:${str}`);
}

/**
 * Page for users and admins that displays any booking made via payment feature, regardless the status of the payment (waiting,cancelled,completed,failed...)
 * @param props 
 * @returns Hook - complete page
 */
// eslint-disable-next-line no-unused-vars
export default function PaymentBookingOverview(props: {}) {

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [data, setData] = useState<PaymentBookingOverviewResponse>();
    const [page, setPage] = React.useState({ skip: 0, take: 10 });
    const [filterParams, setFilterParams] = useState<URLSearchParams>();
    const [isAdminView, setIsAdminView] = useState<boolean>(false);

    useEffect(() => {
        setData(undefined);
    }, [isAdminView]);

    useEffect(() => {
        setIsLoaded(false);
        PurchaseService.instance.getBookingOverview(page.skip, page.take, filterParams).then(res => {
            if (isSuccess(res)) {
                setData(res as PaymentBookingOverviewResponse);
            } else {
                setErrorMessage((res as GtError).detailedObject?.message);
            }
            setIsLoaded(true);
        })
    }, [page, filterParams]);

    const expandDetailRow = (event: GridExpandChangeEvent) => {
        const newData = data!.items.map((item) => {
            if (item.bookingReference === event.dataItem.bookingReference) {
                item.isGridDetailExpanded = !event.dataItem.isGridDetailExpanded;
            }

            return item;
        });
        setData({ items: newData, totalCount: data!.totalCount });
    };

    const pageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    return (
        <>
            <div className="l-container payment-booking-overview">
                <div className="l-box--wide">
                    <MenuBreadCrumb />

                    <Heading headingLevel={1} cssClass="heading__Title">
                        <Translate>Payment:BookingOverview</Translate>
                    </Heading>

                    <div>
                        <Translate>PaymentBookingOverview:SubTitle</Translate>
                    </div>
                </div>
            </div>

            <PaymentBookingOverviewFilter onChange={(filter, filterAdminView) => {
                page.skip = 0; // reset without calling useEffect to prevent double loading
                setIsAdminView(filterAdminView);
                setFilterParams(filter);
            }} />

            <div className="l-container payment-booking-overview">
                {!isLoaded && !data && <ProgressSpinner />}

                <div className="payment-booking-overview--grid-container">
                    {data && data.items && <LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
                        <IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
                            <Grid
                                data={data.items}

                                pageable={{
                                    type: 'numeric',
                                    info: false
                                }}
                                total={data.totalCount}
                                skip={page.skip}
                                take={page.take}
                                onPageChange={(e) => pageChange(e)}

                                detail={PaymentBookingOverviewDetailsRow}
                                expandField="isGridDetailExpanded"
                                onExpandChange={expandDetailRow}

                                className={!isLoaded ? 'is-loading' : ''}
                            >
                                <GridNoRecords>
                                    <Translate>PaymentBookingOverview:NoData</Translate>
                                </GridNoRecords>

                                {
                                    isAdminView && <GridColumn field="bookingReference" sortable={true} title={getTranslation('BookingReference')} />
                                }
                                {
                                    isAdminView && <GridColumn field="purchaser.name" sortable={true} title={getTranslation('Purchaser')} />
                                }
                                <GridColumn field="bookedLessons" title={getTranslation('BookedLessons')}
                                    cell={props => {
                                        if (props.dataItem != null && props.dataItem instanceof PaymentBookingOverviewEntry) {
                                            return <td>
                                                {props.dataItem.bookedLessons.map(lesson => {
                                                    return <div key={lesson.id}>{lesson.name} ({lesson.id})</div>
                                                })}
                                            </td>
                                        }
                                        return <td></td> //fallback
                                    }}
                                />
                                <GridColumn field="totalPrice" title={getTranslation('TotalPriceAndCurrency')}
                                    cell={props => {
                                        return <td>
                                            {props.dataItem.totalPrice}&nbsp;{props.dataItem.currency}
                                        </td>
                                    }} />
                                <GridColumn field="bookingDateDate" sortable={true} title={getTranslation('BookingDate')}
                                    cell={props => {
                                        if (props.dataItem != null && props.dataItem instanceof PaymentBookingOverviewEntry && props.dataItem.bookingDate && props.dataItem.bookingDate.getTime() > 0) {
                                            return (
                                                <td>
                                                    {StringHelper.dateTimeString(props.dataItem.bookingDate)}
                                                </td>
                                            )
                                        }
                                        return <td></td> //fallback
                                    }}
                                />
                            </Grid>
                        </IntlProvider>
                    </LocalizationProvider>}

                    <div className={!isLoaded && data ? 'page-loading' : 'page-loading hidden'}>
                        <Translate>PaymentBookingOverview:PageLoading</Translate>&nbsp;<ProgressSpinner size={16} />
                    </div>
                </div>

                {errorMessage && <Alert alertAppereance="box" alertType="error" message={errorMessage} />}

                {data && <PaymentBookingOverviewExport filterParams={filterParams} />}
            </div>
        </>
    );
}