export const userFirstAndLastName = 'userFirstAndLastName';
export const lessonTitle = 'lessonTitle';
export const date = 'date';
export const lessonStatus = 'lessonStatus';
export const score = 'score';
export const icons = 'icons'


export class RequirementsOverviewModel {
    public columnDictionary: { [key: number]: string } = {};

    constructor() {
        this.columnDictionary[0] = userFirstAndLastName;
        this.columnDictionary[1] = lessonTitle;
        this.columnDictionary[2] = date;
        this.columnDictionary[3] = lessonStatus;
        this.columnDictionary[4] = score;
        this.columnDictionary[5] = icons;
    }
}