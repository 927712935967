import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserPreferencesRequest {

    @JsonProperty('userId', Number, false)
    public userId: number;
    @JsonProperty('currentSuiUrl', String, false)
    public currentSuiUrl: string = '';
    @JsonProperty('preferences', String, false)
    public preferences: string = '';
}