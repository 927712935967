import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { CertificateTypeConverter } from '$storage/models/converters/CertificateTypeConverter';
import { ECertificateType, ELessonStatus } from '$storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';
import { LessonStatusConverter } from '../converters/LessonStatusConverter';

@JsonObject
export class UserCertificateValidated {


    @JsonProperty('userCertificateId', Number, false)
    public userCertificateId: number = 0;

    @JsonProperty('acquiredDate', DateTimeConverter, false)
    public acquiredDate: Date = new Date();

    @JsonProperty('certificateType', CertificateTypeConverter, false)
    public certificateType: ECertificateType = ECertificateType.Undefined;

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('userName', String, false)
    public username: string = '';

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    @JsonProperty('stringId', String, false)
    public StringId: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('subTitle', String, false)
    public subTitle: string = '';

    @JsonProperty('score', Number, false)
    public score: number = 0;

    @JsonProperty('lessonStatus', LessonStatusConverter, false)
    public lessonStatus: ELessonStatus = ELessonStatus.NotAttempted;
        
    @JsonProperty('url', String, false)
    public url: string = '';
    
}



