import React, { useState, useEffect } from 'react';
import Session from '$src/core/Session';
import { Item } from '$src/storage/models/Item';
import { ItemSummary } from '$components/item/itemSummaries/ItemSummary';
import { EItemDetailCallerContextType, ESkillStatus } from '$src/storage/models/enums';
import { useLocation, useParams } from 'react-router';
import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Heading } from '$components/shared/Heading';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import { Skill } from '$src/storage/models/Skill/Skill';
import { User } from '$src/storage/models/User';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import { Tooltip } from '$components/shared/Tooltip';
import AssignLessonsModal from '$src/components/myEmployees/ActionModals/AssignLessonsModal';
import { TrainingPlan } from '$src/storage/models/TrainingPlan/TrainingPlan';
import { CatalogElement } from '$src/storage/models/Catalog';

interface IDisplayProps {
    trainings: Item[];
    itemDetailCallerContextType: EItemDetailCallerContextType;
    skillId: number;
    skillTargetLevelValue?: number;
    user: User;
    profileId?: number;
    bossRelationCode?: string;
    parentHeadingLevel: number;
}

/**
 * Loads all data asynchronous for the overview of the recommended trainings for a skill
 * 
 * @param {number} skillId Id of the skill to load the recommended trainings
 * @param {number} userId Id of the user to load the recommendations for
 * @param {number} profileId Id of the user profile the target skill is in
 * @param {number} context caller context
 * @param {number} itemId Id of the item
 * @param {number} catId Id of the catalog folder
 * @param {number} tpId Id of th trainingplan
 * @return {*}  {([Item[] | null | undefined, UserSkill | undefined, Skill | null, User | null, UserSkillProfileSkill | null, boolean, TrainingPlan | null, CatalogElement | null])}
 */
function useAllData(skillId: number, userId: number, skillProfileId: number, context: number, itemId: number, catId: number, tpId: number, bossRelationCode: string): [Item[] | null | undefined, UserSkill | undefined, Skill | null, User | null, UserSkillProfileSkill | null, boolean, TrainingPlan | null, CatalogElement | null, Item | null] {
    const [trainings, setTrainings] = useState<Item[] | null | undefined>(undefined);
    const [userSkill, setUserSkill] = useState<UserSkill | undefined>(undefined);
    const [skill, setSkill] = useState<Skill | null>(null);
    const [user, setUser] = useState<User | null>(null);
    const [userTargetSkill, setUserTargetSkill] = useState<UserSkillProfileSkill | null>(null);
    const [trainingPlan, setTrainingPlan] = useState<TrainingPlan | null>(null);
    const [folder, setFolder] = useState<CatalogElement | null>(null);
    const [item, setItem] = useState<Item | null>(null);


    useEffect(() => {
        let ignore = false;

        async function fetchData() {
            if (skillId != 0 && userId != 0 && !ignore) {
                if (skillProfileId !== 0) {
                    await Promise.all([
                        Session.instance.storage.item.getOutputLessonsForSkill(skillId, userId, bossRelationCode),
                        Session.instance.storage.userSkill.getUserSkill(userId, skillId),
                        Session.instance.storage.skill.getSkill(skillId),
                        Session.instance.storage.user.getUser(userId),
                        Session.instance.storage.userSkillProfile.getProfileSkill(skillId, skillProfileId, userId, bossRelationCode),
                    ]).then((results) => {
                        setTrainings(results[0] ? results[0] : undefined);
                        setUserSkill(results[1] ? results[1] : undefined);
                        setSkill(results[2] ? results[2] : null);
                        setUser(results[3] ? results[3] : null);
                        setUserTargetSkill(results[4] ? results[4] : null);
                    });
                } else {
                    await Promise.all([
                        Session.instance.storage.item.getOutputLessonsForSkill(skillId, userId, bossRelationCode),
                        Session.instance.storage.userSkill.getUserSkill(userId, skillId),
                        Session.instance.storage.skill.getSkill(skillId),
                        Session.instance.storage.user.getUser(userId),
                        Session.instance.storage.userTargetSkill.getTargetSkill(skillId, userId, bossRelationCode),
                    ]).then((results) => {
                        setTrainings(results[0] ? results[0] : undefined);
                        setUserSkill(results[1] ? results[1] : undefined);
                        setSkill(results[2] ? results[2] : null);
                        setUser(results[3] ? results[3] : null);
                        setUserTargetSkill(results[4] ? results[4] : null);
                    });
                }

                if (context == EItemDetailCallerContextType.TrainingPlan || context == EItemDetailCallerContextType.ItemDetail) {
                    await Promise.all([
                        Session.instance.storage.catalog.getCatalogFolder(catId)
                    ]).then((results) => {
                        setFolder(results[0] ? results[0] : null);
                    });
                }
                if (context == EItemDetailCallerContextType.TrainingPlan) {
                    await Promise.all([
                        Session.instance.storage.trainingPlans.getTrainingPlan(itemId, EItemDetailCallerContextType.Catalog),
                    ]).then((results) => {
                        setTrainingPlan(results[0] ? results[0] : null);
                    });
                } else if (context == EItemDetailCallerContextType.ItemDetail) {
                    await Promise.all([
                        Session.instance.storage.item.getItemDetail(itemId, EItemDetailCallerContextType.Catalog, tpId),
                    ]).then((results) => {
                        setItem(results[0] ? results[0] : null);
                    });
                }
            }
        }
        fetchData();
        return () => { ignore = true; }
    }, [skillId, userId, skillProfileId]);

    return [trainings, userSkill, skill, user, userTargetSkill, trainings === null, trainingPlan, folder, item];
}

/**
 * Formats a substring of a string with css code from a provided css class. The tooltip is option but recommended.
 *
 * @param {string} mainString Main string that contains the sub string to format
 * @param {string} substring Sub string to format. Must match exactly
 * @param {string} cssClass Css class to format the substring with
 * @param {string} [tooltipId] Optional id to reference the tooltip. Must be provided to display the tooltip
 * @param {string} [tootltipText] Optional text for the tooltip. Must be provided to display the tooltip
 * @return {*}  {JSX.Element}
 */
function formatSubString(mainString: string, substring: string, cssClass: string, tooltipId?: string, tootltipText?: string): JSX.Element {
    if (mainString.includes(substring)) {
        const splitedString: string[] = mainString.split(substring);
        if (tooltipId && tootltipText) {
            return (
                <>
                    {splitedString[0]}
                    <span
                        className={cssClass}
                        data-tip={tootltipText}
                        data-for={`TT_formattedSubString__${tooltipId}`}
                        aria-label={tootltipText}>
                        {substring}
                    </span>
                    {splitedString.length > 1 && splitedString[1]}
                    <Tooltip id={`TT_formattedSubString__${tooltipId}`} delayShow={500} />
                </>
            );
        } else {
            return (<>{splitedString[0]}<span className={cssClass} >{substring}</span>{splitedString.length > 1 && splitedString[1]}</>);
        }
    }
    return (<>{mainString}</>);

}

/**
 * Component for the overview of trainings for a skill
 * The tranings are listed per skill level. The current user skill level and the skill target level of the user are displayed
 *
 * @export
 * @param {IProps} props
 * @return {*} 
 */
export default function Trainings() {
    const location = useLocation();
    const parameters = new URLSearchParams(window.location.search);
    const { skillIdParam, userIdParam, catIdParam, itemIdParam, tpIdParam, asIdParam, tpeIdParam } =
        useParams<{ skillIdParam: string; userIdParam: string; catIdParam: string; itemIdParam: string; tpIdParam: string; asIdParam: string; tpeIdParam: string }>();
    const skillId: number = skillIdParam != null && !isNaN(Number(skillIdParam)) ? Number(skillIdParam) : 0;
    const userId: number = userIdParam != null && !isNaN(Number(userIdParam)) ? Number(userIdParam) : 0;
    const catId: number = catIdParam != null && !isNaN(Number(catIdParam)) ? Number(catIdParam) : 0;
    const tpId: number = tpIdParam != null && !isNaN(Number(tpIdParam)) ? Number(tpIdParam) : 0;
    const tpeId: number = tpeIdParam != null && !isNaN(Number(tpeIdParam)) ? Number(tpeIdParam) : 0;
    const asId: number = asIdParam != null && !isNaN(Number(asIdParam)) ? Number(asIdParam) : 0;
    const itemId: number = itemIdParam != null && !isNaN(Number(itemIdParam)) ? Number(itemIdParam) : 0;
    const contextParam = parameters.get('context');
    const skillProfileIdParam = parameters.get('skillProfileId');
    const context: number = contextParam != null && !isNaN(Number(contextParam)) ? Number(contextParam) : 0;
    const skillProfileId: number = contextParam != null && !isNaN(Number(skillProfileIdParam)) ? Number(skillProfileIdParam) : 0;
    const bossRelationCodeParam = parameters.get('bossRelationCode');
    const bossRelationCode: string = bossRelationCodeParam != null ? bossRelationCodeParam : '';
    const tr = Session.instance.storage.translation;

    useEffect(() => {
        document.title = globalConfig.appProperties.title + ': ' + tr.GetString('Skill:Recommendations');
    });
    const [trainings, userSkill, skill, user, userTargetSkill, errorOccured, trainingPlan, folder, item] = useAllData(skillId, userId, skillProfileId, context, itemId, catId, tpId, bossRelationCode);

    if (trainings == null) {
        if (errorOccured) {
            return (
                <div className="l-container">
                    <ErrorMessage appearance="box" errorMessage={tr.GetString('ErrorMessage:General')} />
                </div>);
        } else {
            return (
                <ProgressSpinner />
            );
        }
    }
    else {
        const bcElements: BreadCrumbElement[] = [];
        const skillTitle = skill ? skill.skillTitle : '';
        let routePathName = '';
        let showBreadCrumb = false;
        if ((folder != null && folder.title != "" && (trainingPlan != null || item != null)) || item != null || skill != null) {
            showBreadCrumb = true;
        }

        switch (context) {
            case EItemDetailCallerContextType.PerformanceSupport:
                routePathName = '/myTeam/' + bossRelationCode;
                bcElements.push({
                    navigationPath: `/employee/${bossRelationCode}/${userId}`,
                    title: user ? user.fullName : ''
                })
                break;
            case EItemDetailCallerContextType.MySkills:
                bcElements.push({
                    navigationPath: `/more`,
                    title: tr.GetString('Navigation:More')
                });
                bcElements.push({
                    navigationPath: `/mySkills`,
                    title: tr.GetString('Navigation:MySkills')
                });
                break;
            case EItemDetailCallerContextType.TrainingPlan:
                bcElements.push({
                    navigationPath: `/catalog/1`,
                    title: tr.GetString('Catalog:Title')
                });

                bcElements.push({
                    navigationPath: `/catalog/${folder?.itemId}`,
                    title: folder?.title ? folder.title : ""
                });
                bcElements.push({
                    navigationPath: `/trainingPlanDetail/${trainingPlan?.itemId}?context=1&catId=${catId}&asId=${asId}`,
                    title: trainingPlan?.title ? trainingPlan.title : ""
                });
                break;
            case EItemDetailCallerContextType.ItemDetail:
                bcElements.push({
                    navigationPath: `/catalog/1`,
                    title: tr.GetString('Catalog:Title')
                });

                bcElements.push({
                    navigationPath: `/catalog/${folder?.itemId}`,
                    title: folder?.title ? folder.title : ""
                });
                bcElements.push({
                    navigationPath: `/itemDetail/${item?.itemId}?context=1&catId=${catId}&asId=${asId}tpId=${tpId}&tpeId=${tpeId}`,
                    title: item?.title ? item?.title : ""
                });
                break;
            case EItemDetailCallerContextType.MyPerformanceSupport:
            default:
                routePathName = '/myPerformanceSupport';

        }
        bcElements.push({
            navigationPath: location.pathname + `?context=${context}`,
            title: skillTitle
        });

        // Loading texts for user infomation about user skill and user target skill
        const currentUserLevelText = tr.GetString("Trainings:CurrentUserLevel").Format(userSkill?.skillLevelTitle || '-')
        const usertargetLevelText = tr.GetString("Trainings:UserTargetLevel").Format(userTargetSkill?.skillProfileSkillLevelTitle || '-');
        let currentUserLevelTooltip: string | undefined;
        let levelHighlightColor: string | undefined;
        // Coloring and adding tooltip for user skill level and user target skill level
        if (userSkill && userTargetSkill) {
            levelHighlightColor = userSkill.skillLevelValue >= userTargetSkill.skillProfileSkillLevelValue &&
                (userSkill.skillStatus === ESkillStatus.Valid || userTargetSkill.mayBeExpired) ?
                "trainings-title__level-highlight--requested-level" : "trainings-title__level-highlight--lower-level";
            currentUserLevelTooltip = userSkill.skillLevelValue < userTargetSkill.skillProfileSkillLevelValue ?
                tr.GetString("Trainings:MissingLevel") :
                ((userSkill.skillStatus === ESkillStatus.Valid || userTargetSkill.mayBeExpired) ?
                    tr.GetString("Trainings:AchievedTargetLevel") : tr.GetString("Trainings:AchievedTargetLevelExpired"));
        }


        return (
            <div>
                <div className="l-container">
                    {showBreadCrumb && <MenuBreadCrumb
                        routePathName={routePathName}
                        breadCrumbElementsToAppend={bcElements}/>}
                   
                    <div className="trainigs-title__container">
                        <Heading headingLevel={1} cssClass="l-box-container heading__Title">
                            {tr.GetString("Skill:RecommendedLessons").Format(skillTitle)}
                        </Heading>
                    </div>
                    <div className="trainings__information">
                        {userSkill ?
                            formatSubString(currentUserLevelText, userSkill.skillLevelTitle, levelHighlightColor || "trainings-title__level-highlight", userSkill.id.toString(), currentUserLevelTooltip)
                            : currentUserLevelText}<br />
                        {context != EItemDetailCallerContextType.ItemDetail && context != EItemDetailCallerContextType.TrainingPlan ? userTargetSkill ? formatSubString(usertargetLevelText, userTargetSkill.skillProfileSkillLevelTitle, "trainings-title__level-highlight") : usertargetLevelText : null}
                    </div>
                    {user && <div className="l-box-container--column-flex">
                        <RecommendedTrainingsForSkill
                            trainings={trainings}
                            itemDetailCallerContextType={context}
                            skillId={skillId}
                            skillTargetLevelValue={userTargetSkill?.skillProfileSkillLevelValue}
                            user={user}
                            bossRelationCode={bossRelationCode}
                            parentHeadingLevel={1}
                            profileId={skillProfileId} />
                    </div>}
                </div>
            </div>
        );
    }
}

/**
 * Displays the recommended trainigs for a skill grouped by the level the trainings provide
 * Used for the performance review
 *
 * @export
 * @param {IDisplayProps} props
 * @return {*}  {JSX.Element}
 */
export function RecommendedTrainingsForSkill(props: IDisplayProps): JSX.Element {

    const availableLevels: { levelValue: number; levelTitle: string; items: Item[] }[] = [];
    const lessons: JSX.Element[] = [];
    const tr = Session.instance.storage.translation;
    const [showAssignLessonsModal, setShowAssignLessonsModal] = useState(false);
    const [selecetedTrainingToAssign, setSelecetedTrainingToAssign] = useState<Item | undefined>(undefined);

    function showAssignLessonModal(itemId: number) {
        setSelecetedTrainingToAssign(props.trainings.find(tr => tr.itemId = itemId));
        setShowAssignLessonsModal(true);
    }

    props.trainings.forEach(training => {
        const outputSkill = training.itemOutputSkills.find(tr => tr.skillId === props.skillId);
        if (outputSkill != null && !availableLevels.some(al => al.levelValue === outputSkill.skillLevelValue)) {
            availableLevels.push({ levelValue: outputSkill.skillLevelValue, levelTitle: outputSkill.skillLevelTitle, items: [training] });
        }
        else if (outputSkill != null && availableLevels.some(al => al.levelValue === outputSkill.skillLevelValue)) {
            availableLevels.map(al => {
                if (al.levelValue === outputSkill.skillLevelValue) {
                    al.items.push(training);
                }
            });
        }
    });

    availableLevels.sort((a, b) => b.levelValue - a.levelValue);

    availableLevels.forEach(al => {
        // Loading texts for available skill level the leesons will provide
        const alertMessage = tr.GetString('Trainings:TrainingsForLevel').Format(al.levelTitle)
        let levelHighlightCssClass: string = "trainings-title__level-highlight";
        let levelTooltipText: string | undefined;
        // Coloring and adding tooltip for avasilable skill level
        if (props.skillTargetLevelValue) {
            levelHighlightCssClass = al.levelValue >= props.skillTargetLevelValue ? "trainings-title__level-highlight--requested-level" : "trainings-title__level-highlight--lower-level";
            levelTooltipText = al.levelValue >= props.skillTargetLevelValue ? tr.GetString("Trainings:LevelMatchesTarget").Format(al.levelTitle)
                : tr.GetString("Trainings:LevelMissesTarget").Format(al.levelTitle);
        }
        lessons.push(
            <React.Fragment key={`trainings__${al.levelValue}`}>
                <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level4 trainings__group-title">
                    {formatSubString(alertMessage, al.levelTitle, levelHighlightCssClass, props.skillTargetLevelValue?.toString(), levelTooltipText)}
                </Heading>
                <ul className="item-box-tile__parent">
                    {al.items.map(item =>
                        <ItemSummary
                            key={item.sId}
                            item={item}
                            itemCallerContext={props.itemDetailCallerContextType}
                            parentCatalogFolderId={0}
                            parentTrainingPlanId={0}
                            parentAssignmentId={0}
                            profileId={props.profileId}
                            isBoss={props.bossRelationCode != null}
                            onButtonClicked={() => showAssignLessonModal(item.itemId)}
                            userId={props.user.id}
                            {...props} />)}
                </ul>
            </React.Fragment>
        );
    });

    return (
        <>
            {lessons}
            {
                showAssignLessonsModal &&
                <AssignLessonsModal isOpen={showAssignLessonsModal}
                    onClose={() => setShowAssignLessonsModal(false)}
                    employees={[props.user]}
                    preSelectedItem={selecetedTrainingToAssign}
                    bossRelationCode={props.bossRelationCode ?? ''} />
            }
        </>
    );
}