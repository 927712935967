import { Item } from '$src/storage/models/Item';
import { JsonObject, JsonProperty } from 'json2typescript';
/*import { observable } from 'mobx';*/

/** This is an Element used in the Catalog. */
@JsonObject
export class CatalogElement extends Item {

    @JsonProperty('rank', Number, false)
    public rank: number = 0;

    @JsonProperty('children', [CatalogElement], false)
    public children: CatalogElement[] | null = null;

    @JsonProperty('childCount', Number, false)
    public childCount: number = 0;
}
