import CollaborationProviderService from '$core/Services/CollaborationProviderService';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { isSuccess } from '$util/Result';
import { useState, useEffect } from 'react';

type IUseHasCollaborationLinksProps = {
  itemId: number;
  classId: number;
}

export const useHasCollaborationLinks = (props: IUseHasCollaborationLinksProps) => {
  const [hasItemLink, setHasItemLink] = useState<boolean>(false);
  const [hasClassLink, setHasClassLink] = useState<boolean>(false);
  useEffect(() => {
    const linkExists = async (itemId: number, classId: number) => {
      const result = await CollaborationProviderService.instance.hasItemLink(itemId)
      if (isSuccess<BooleanResponse>(result)) {
        setHasItemLink(result.status);
        if (result.status) {
          const result2 = await CollaborationProviderService.instance.hasClassLink(itemId, classId);
          if (isSuccess<BooleanResponse>(result2)) {
            setHasClassLink(result2.status);
          }
        }
      }
    }
    linkExists(props.itemId, props.classId);
  }, []);

  return [hasItemLink, hasClassLink];
}