import React from "react";
import { ESortDirection } from "$src/storage/models/enums";
import { SortableHeaderElement } from "../shared/SortableHeaderElement";
import Session from "$src/core/Session";
import { EvaluationOfTplanData } from "$src/storage/models/EvaluationOfTplanData";

interface IProps {
    data: EvaluationOfTplanData;
    isActiveHeader: boolean;
    onActiveHeaderChanged: (activeHeaderId: string, sortDirection: ESortDirection) => void;
    key: string;
    columnSettings: { id: string }[];
}

export default function EvaluationOfTplanItem(props: IProps) {
    const tr = Session.instance.storage.translation;
    return (
        <div role="row">
            <div className="evaluationOfReport__table__row row__content" >
                {
                    props.columnSettings.map((field, idx) => {
                        return <>
                            <div role="columnheader" className="mobileOnly small-cell" key={idx}>
                                <SortableHeaderElement
                                    key={idx}
                                    id={field.id}
                                    isActive={props.isActiveHeader}
                                    header={tr.GetString(`EvaluationOfTplans:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                                    initialSortDirection={ESortDirection.Down}
                                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                                />
                            </div>
                            <div role="cell">{eval(`props.data.${field.id}`)}</div>
                        </>
                    })
                }
            </div>
        </div>
    )
}