import { JsonObject, JsonProperty } from 'json2typescript';

/**
 *This Model is holding the data for the groups
 */
@JsonObject
export class EvaluationGroup {

    /** groupId  */
    @JsonProperty('groupId', Number, false)
    public groupId: number = 0;

    /** groupName*/
    @JsonProperty('groupName', String, false)
    public groupName: string = "";
}
