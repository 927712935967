import React, { useState } from 'react';

import PurchaseService from '$src/core/Services/PurchaseService';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';

import { Translate } from '$components/shared/Translate';
import GTButton from '$components/shared/Atoms/GTButton';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';

interface IPaymentBookingOverviewExportProps {
    filterParams?: URLSearchParams;
}

/**
 * Export button to download data as csv
 * @param props Filter params
 * @returns Hook
 */
export default function PaymentBookingOverviewExport(props: IPaymentBookingOverviewExportProps) {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [istLoading, setIsLoading] = useState<boolean>(false);

    const download = (csv: string) => {
        const link = document.createElement("a");
        link.href = "data:text/csv;charset=utf-8," + escape(csv);
        link.style.visibility = "hidden";
        link.download = "export.csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const requestData = () => {
        setIsLoading(true);
        PurchaseService.instance.getBookingOverviewExport(props.filterParams).then(res => {
            if (isSuccess(res)) {
                download(res as string);
            } else {
                setErrorMessage((res as GtError).detailedObject?.message);
            }
            setIsLoading(false);
        })
    }

    return (
        <>
            <GTButton defaultButtonProps={{ disabled: istLoading }} onClick={() => requestData()}>
                {istLoading ? <ProgressSpinner size={16} /> : <Translate>PaymentBookingOverview:ExportButton</Translate>}
            </GTButton>

            {errorMessage && <Alert alertAppereance="box" alertType="error" message={errorMessage} />}
        </>
    )
}