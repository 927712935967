import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';
import RegistrationService from '$src/core/Services/RegistrationService';
import Session from '$src/core/Session';
import { User } from '$src/storage/models/User';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { EItemType, EItemSubType } from '$src/storage/models/enums';

interface IProps {
    scheduleId: number;
    parentHeadingLevel: number;
    itemType: EItemType;
    itemSubType: EItemSubType;
}

interface IState {
    participants: User[];
    errorMessage: string;
}

export class ParticipantList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            participants: [],
        }
    }

    public async componentDidMount() {
        const participants = await RegistrationService.instance.getRegisteredUsers(this.props.scheduleId, this.props.itemType, this.props.itemSubType);

        if (isSuccess<User[]>(participants)) {
            this.setState({ participants });
        } else {
            this.setState({ errorMessage: 'ErrorMessage:getParticipantsFailed' });
        }
    }

    public render() {
        return (
            <div>
                <span className={'input-message error'}>
                    <Translate>{this.state.errorMessage}</Translate>
                </span>
                <Heading cssClass="heading__Level2" headingLevel={this.props.parentHeadingLevel + 1}>
                    <Translate>ParticipantList:Title</Translate>
                </Heading>
                {this.state.participants.length <= 0 &&
                    <Translate>ParticipantList:NoParticipants</Translate>
                }
                {this.state.participants.length > 0 &&
                    <div role="table" className="div-table__horizontal-table">
                        {this.renderTable()}
                    </div>
                }
            </div>
        )
    }

    private renderTable(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const properties = globalConfig.participantList.properties;

        // add notMobile columnheader
        elements.push(
            <div role="rowgroup" key="header_row">
                <div role="row" className="div-table__horizontal-table-row zebra">
                    {properties.map(p => {
                        return (
                            <div role="columnheader" className="notMobile" key={`header_${p.id}`}>
                                {Session.instance.storage.translation.GetString(`ParticipantList:${p.id}`)}
                            </div>
                        )
                    })}
                </div>
            </div>
        )

        // add cells and mobile headers
        elements.push(
            <div role="rowgroup" key="content_row">
                {this.state.participants.map((participant, index) => {
                    {
                        return (
                            <div role="row" key={`row_${index}`} className="div-table__horizontal-table-row zebra">
                                {
                                    properties.map(p => {
                                        return (
                                            <React.Fragment key={`row_${index}_${p.id}`}>
                                                <div role="columnheader" className="mobileOnly">
                                                    {Session.instance.storage.translation.GetString(`ParticipantList:${p.id}`)}
                                                </div>
                                                <div role="cell">
                                                    {participant[p.id]}
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                })}
            </div>
        )

        return elements;
    }
}