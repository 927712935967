import React, { useEffect, useState } from "react";
import InputFile from "$src/components/shared/InputFile";
import { EFileType } from "$src/storage/models/enums";
import { FileDataRequest } from "$src/storage/models/RequestObjects/FileDataRequest";
import { Heading } from "$src/components/shared/Heading";
import GTFileList from "$components/shared/Molecules/GTFileList";
import { Translate } from "$components/shared/Translate";
import { UploadedFileTypes } from "$components/shared/Atoms/GTFile";

/**
 * GT FileUpload Props
 */
export interface IGTFileUploadProps {
    /**
     * The parent heading level
     */
    parentHeadingLevel: number;
    /**
     * The label for the upload
     */
    label: string;
    /**
     * The allowed file extensions as comma separated string
     */
    allowedFileExtensions: string;
    /**
     * Flag if multiple file upload is allowed
     */
    multipleUploadAllowed: boolean;
    /**
     * The maximum file size of a file
     */
    maxFileSizeInByte: number;
    /**
     * The Callback of uploaded files, returns a list of {@see FileDataRequest} which contains the file type {@see EFileType}
     */
    onUploadFiles: (files: FileDataRequest[]) => void;
    /**
     * The default file type {@see EFileType}
     */
    defaultFileType: EFileType;
    /**
     * Flag if the type selection should be displayed 
     */
    showFileTypeSelection?: boolean;

    /**
     * Flag if duplicate file names are allowed
     */
    allowDuplicateFileNames?: boolean;

    /**
     * Error Msg if duplicate has been tried to add
     */
    duplicateErrorMessage?: string;

    /**
     * Files at start
     */
    initialFiles?: File[];
    /**
     * **Optional** Array of supported file types, if void -> Default File Types will be displayed
     */
    supportedFiletypesWithTranslation?: UploadedFileTypes[];
    /**
     *  **Optional** Multiple Upload of File of type certificate
     */
    multipleCertificateUploadAllowed?: boolean;
    /**
     * **Optional** Multiple Upload of File of type misc.
     */
    multipleMiscFileUploadAllowed?: boolean;
    /**
     * **Optional** MultipleCertificateNotAllowed Error Message
     */
    multipleCertificateNotAllowedErrorMessage?: string;

    /**
     * **Optional** MultipleMiscFileUploadAllowed ErrorMessage
     */
    multipleMiscFileUploadAllowedErrorMessage?: string;

    /**
    *  **Optional** Disable p.ex. an Action Button of parent component
    */
    onDisableFurtherAction?: (disabled: boolean) => void;

    /**
     * **Optional** If file type required onDisableFurtherAction will be executed with true, if a file exists w/o type
     */
    filetypeRequired?: boolean;
}

/**
 * Global Teach File Upload
 * Renders a file upload and shows a file list as table
 */
export default function GTFileUpload(props: IGTFileUploadProps) {
    // contains a list of the selected files which are shown in the browser
    const [fileList, setFileList] = useState<File[]>([]);
    // contains a list of fileDataRequest which are used to be uploaded in gt with api requests
    const [fileDataRequest, setFileDataRequest] = useState<FileDataRequest[]>([]);
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
    const [duplicateFound, setDuplicateFound] = useState<boolean>(false);
    const [isMultipleCertificateNotAllowed, setIsMultipleCertificate] = useState<boolean>(false);
    const [isMultipleMiscFilesNotAllowed, setIsMultipleMiscFiles] = useState<boolean>(false);

    useEffect(() => {
        if (props.initialFiles && props.initialFiles.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onUploadChange(props.initialFiles);
        }
    }, [props.initialFiles]);

    /**
     * Callback for InputFile component: input has changed
     * Returns a list of files
     * @param files Selected files in input file
     */
    const onUploadChange = (files: File[]) => {
        let duplicateFound = false;
        files.map(f => {
            if (props.allowDuplicateFileNames !== true 
                && fileDataRequest.find(uploadedFile => uploadedFile.fileName.toLowerCase() == f.name.toLowerCase())) {
                duplicateFound = true;
            } else {
                fileDataRequest.push({
                    base64String: '',
                    file: f,
                    contentType: f.type,
                    fileName: f.name,
                    fileType: props.defaultFileType,
                    url: ''
                });
                fileList.push(f)

                setFileDataRequest(Array.from(new Set(fileDataRequest)));
                setFileList(Array.from(new Set(fileList)));
                props.onUploadFiles(fileDataRequest);
                // Disable file upload if multiple upload isn't allowed and a file has already been uploaded
                setUploadDisabled(!props.multipleUploadAllowed && fileDataRequest.length === 1);
            }
        });
        setDuplicateFound(duplicateFound);

        if(props.onDisableFurtherAction){
            const missingFileTypes = props.filetypeRequired === true && fileDataRequest.filter(f=> f.fileType === EFileType.Undefined).length > 0;
            props.onDisableFurtherAction(missingFileTypes);
        }
    }

    function onFileDataRequestChange(fileDataRequest: FileDataRequest[]) {
        setFileDataRequest(fileDataRequest);
        // Disable file upload if multiple upload isn't allowed and a file has already been uploaded
        setUploadDisabled(!props.multipleUploadAllowed && fileDataRequest.length === 1);
        
        const multipleCerts = props.multipleCertificateUploadAllowed === false && fileDataRequest.filter(f => f.fileType === EFileType.Certificate).length > 1;
        setIsMultipleCertificate(multipleCerts);
        const multipleMiscFiles = props.multipleMiscFileUploadAllowed === false && fileDataRequest.filter(f => f.fileType === EFileType.Miscellaneous).length > 1;
        setIsMultipleMiscFiles(multipleMiscFiles);
        if(props.onDisableFurtherAction){
            const missingFileTypes = props.filetypeRequired === true && fileDataRequest.filter(f=> f.fileType === EFileType.Undefined).length > 0;
            props.onDisableFurtherAction(multipleCerts || multipleMiscFiles || missingFileTypes);
        }

        props.onUploadFiles(fileDataRequest);
    }

    return (
        <>
            <Heading headingLevel={props.parentHeadingLevel} cssClass="heading__Level5">
                <label id="lblDocuments">{props.label}</label>
            </Heading>
            <InputFile
                acceptFilter={props.allowedFileExtensions}
                isMultiple={props.multipleUploadAllowed}
                labeledBy="lblDocuments"
                onUploadChange={(files) => onUploadChange(files)}
                maxFileSizeInByte={props.maxFileSizeInByte}
                linkCssClass="button-link button-link--colorized-dark"
                isDisabled={uploadDisabled}
            />
            <GTFileList
                files={fileList}
                fileDataRequests={fileDataRequest}
                showFileTypeSelection={props.showFileTypeSelection}
                supportedFiletypesWithTranslation={props.supportedFiletypesWithTranslation}
                defaultFileType={props.defaultFileType}
                onFileChange={(files) => setFileList(files)}
                onFileDataRequestChange={(fileDataRequest) => onFileDataRequestChange(fileDataRequest)}
            />
            {duplicateFound && props.duplicateErrorMessage ?
                <span className={'input-message error'}>
                    <Translate>{props.duplicateErrorMessage}</Translate>
                </span> : ''
            }
            {isMultipleCertificateNotAllowed && props.multipleCertificateNotAllowedErrorMessage?
                <span className={'input-message error'}>
                    <Translate>{props.multipleCertificateNotAllowedErrorMessage}</Translate>
                </span> : ''
            }
            {isMultipleMiscFilesNotAllowed && props.multipleMiscFileUploadAllowedErrorMessage ?
                <span className={'input-message error'}>
                    <Translate>{props.multipleMiscFileUploadAllowedErrorMessage}</Translate>
                </span> : ''
            }
        </>
    )
}
