import { useEffect, useState } from "react";

import { EItemButtonType } from "$src/components/shared/itemBox/ItemFooterButton";
import Session from "$src/core/Session";
import { Attribute } from "$src/storage/models/Attribute";
import { CatalogElement } from "$src/storage/models/Catalog";
import { EColor, EItemDetailCallerContextType, EItemSubType, EItemType, ELessonStatus, ERegistrationStatus } from "$src/storage/models/enums";
import { Item } from "$src/storage/models/Item";
import { ItemHelper } from "$src/util/ItemHelper";
import IconF2FRequested from '$resources/svgs/toolbar/lesson_f2f_requested.svg';
import IconF2FAccepted from '$resources/svgs/toolbar/lesson_f2f_accepted.svg';
import IconF2FCancelled from '$resources/svgs/toolbar/lesson_f2f_cancelled.svg';
import IconLocked from '$resources/svgs/status/locked.svg';
import IconScore from '$resources/svgs/toolbar/score.svg';
import IconDuration from '$resources/svgs/toolbar/duration.svg';
import IconInfoRed from '$resources/svgs/toolbar/due-at.svg';
import IconWarning from '$resources/svgs/status/warning.svg';
import { StringHelper } from "$src/util/StringHelper";
import { Registration } from "$src/storage/models/Registration";
import { ItemDetails } from "$src/components/item/itemSummaries/ItemSummaryDetail";
import { FolderDetails } from "$src/components/item/itemSummaries/ItemSummaryCatalogFolder";
import { EmployeeRegisteredItem } from "$src/storage/models/EmployeeRegisteredItem";
import { SearchResult } from "$src/storage/models/search/elk/SearchResult";
import { LazyFetchSearchResult } from "$src/storage/models/search/elk/LazyFetchSearchResult";
import { ItemMinimalDetails } from "$src/components/item/itemSummaries/ItemSummaryMinimalDetail";

export default class ItemSummaryHelper {

    /**
     * Calculates the due date of an item based on the config (caclulateDueDateBasedOnLastDate) and the item type
     * @param itemType type of the item EItemType
     * @param currentRegistration current registration of the user
     * @param nextRegistration next registration of the user
     * @param assignmentStartDate start date of the assignment
     * @param assignmentTargetDate target date of the assignment
     * @param assignmentEndDate end date of the assignment
     */
    public static calculateDueDate(itemType: EItemType, currentRegistration: Registration | null, nextRegistration: Registration | null,
        assignmentStartDate?: Date, assignmentTargetDate?: Date, assignmentEndDate?: Date): Date | undefined {
        const useLastDueDate = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate

        let dueDate = useLastDueDate ?
            (assignmentTargetDate == null || assignmentEndDate != null && (assignmentTargetDate > assignmentEndDate) ? assignmentEndDate : assignmentTargetDate) :
            assignmentStartDate;

        // the training plan and f2f courses only diplays the learning period end date as due date if the assignment date is in the past
        if ((itemType === EItemType.F2FCourse || itemType === EItemType.TrainingPlan) && !(dueDate && dueDate > new Date())) {
            if (useLastDueDate) {
                if (currentRegistration != null && currentRegistration.learningPeriodEnd != null && currentRegistration.registrationStatus <= ERegistrationStatus.InWaitingList)
                    dueDate = currentRegistration.learningPeriodEnd;
                else if (nextRegistration != null && nextRegistration.learningPeriodEnd != null && nextRegistration.registrationStatus <= ERegistrationStatus.InWaitingList)
                    dueDate = nextRegistration.learningPeriodEnd;
            }
            else {
                if (currentRegistration != null && currentRegistration.learningPeriodBegin != null && currentRegistration.registrationStatus <= ERegistrationStatus.InWaitingList)
                    dueDate = currentRegistration.learningPeriodBegin;
                else if (nextRegistration != null && nextRegistration.learningPeriodBegin != null && nextRegistration.registrationStatus <= ERegistrationStatus.InWaitingList)
                    dueDate = nextRegistration.learningPeriodBegin;
            }
        }

        return dueDate;
    }

    /**
     * Calculates the remaining time text based on the config (showDueDateAsCountDown, caclulateDueDateBasedOnLastDate)
     * @param dueDate calculated due date of the assignment
     * @param unlimitedTimeLimit time limit to decide if the duration to the due date is unlimited (eg 100 years) 
     * @param remainingHoursToStart time until start in hours (see Item.remainingHoursToStart)
     * @param remainingHoursToEnd time until end in hours (see Item.remainingHoursToEnd)
     */
    public static calculateRemainingTimeText(dueDate: Date, unlimitedTimeLimit: Date, remainingHoursToStart?: number, remainingHoursToEnd?: number): string {
        const tr = Session.instance.storage.translation;
        let remainingTimeText = tr.GetString('RemainingTime:Date') + StringHelper.dateString(dueDate);

        if (globalConfig.lessonToolbarProperties.showDueDateAsCountDown) {
            const remainingHours = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ? remainingHoursToEnd : remainingHoursToStart;
            if (remainingHours !== undefined) {
                if (remainingHours > 36) {
                    remainingTimeText = tr.GetString('RemainingTime:RemainingDays').replace('{0}', Math.round(remainingHours / 24).toString());
                } else if (remainingHours >= 0) {
                    remainingTimeText = tr.GetString('RemainingTime:RemainingHours').replace('{0}', remainingHours.toString());
                } else {
                    remainingTimeText = tr.GetString('RemainingTime:Overdue');
                }
            }
        }
        else {
            if (dueDate < unlimitedTimeLimit) {
                remainingTimeText = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ?
                    tr.GetString('RemainingTime:DateToEnd') :
                    tr.GetString('RemainingTime:DateToStart');
                remainingTimeText += ' ' + StringHelper.dateString(dueDate);
            }
            else {
                remainingTimeText = tr.GetString('RemainingTime:Endless');
            }
        }

        return remainingTimeText;
    }

    /**
     * Creates and return the link to the item detail based on the item type and the caller context
     * @param item item object
     * @param itemDetailCallerContextType caller context type (see EItemDetailCallerContextType)
     * @param parentCatalogFolderId id of the parant catalog folder
     * @param parentAssignmentId 
     * @param registrationId id of the registration
     * @param parentTrainingPlanId id of the training plan if the item was opend in a training plan
     * @param skillId id of skill if the item was opened from as a recommended lesson of a skill
     * @param userId id of the user
     * @param profileId id of target profil if the item is opened from the performance review
     * @param bossRelationCode boss relation code if the usr opens the item in a boss function
     */
    public static getLinkForItemDetail(
        item: Item,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        parentCatalogFolderId: number,
        parentAssignmentId: number,
        registrationId: number,
        parentTrainingPlanId: number,
        skillId?: number,
        userId?: number,
        profileId?: number,
        bossRelationCode?: string): string {

        if (itemDetailCallerContextType === EItemDetailCallerContextType.CourseRequests) {
            return `/requestDetail/${item.registrationId}?context=${itemDetailCallerContextType}&itemSubType=${item.itemSubType}`;
        }
        // First check if the itemDetailCallerContextType is set
        if (itemDetailCallerContextType === EItemDetailCallerContextType.PerformanceReview
            && skillId && userId && profileId) {
            return ItemHelper.getPerformanceReviewItemDetailLink(item.itemId,
                itemDetailCallerContextType,
                item.itemType,
                skillId,
                userId,
                profileId,
                bossRelationCode)
        } else if (itemDetailCallerContextType === EItemDetailCallerContextType.MySkills) {
            return ItemHelper.getMySkillsDetailLink(item.itemId,
                itemDetailCallerContextType,
                item.itemType,
                skillId,
                userId);
        }
        else if (itemDetailCallerContextType === EItemDetailCallerContextType.MyPerformanceSupport) {
            return ItemHelper.getMyPerformanceSupportLink(item.itemId,
                itemDetailCallerContextType,
                item.itemType,
                skillId,
                userId);
        } else if (itemDetailCallerContextType === EItemDetailCallerContextType.PerformanceSupport
            && skillId && userId) {
            return ItemHelper.getPerformanceSupportItemDetailLink(item.itemId,
                itemDetailCallerContextType,
                item.itemType,
                skillId,
                userId,
                profileId,
                bossRelationCode)
            // If none of the itemDetailCallerContextType is set then check the ItemType
        } else if (item.itemType === EItemType.TrainingPlan) {
            return ItemHelper.getTrainingPlanDetailLink(item.itemId,
                itemDetailCallerContextType,
                parentCatalogFolderId,
                parentAssignmentId)
        } else if (item.itemType === EItemType.F2FCourse
            && item.itemSubType === EItemSubType.External
            && itemDetailCallerContextType !== EItemDetailCallerContextType.Catalog) {
            return ItemHelper.getRequestWizardDetailLink(registrationId, itemDetailCallerContextType)
        } else {
            return ItemHelper.getItemDetailLink(item.itemId,
                itemDetailCallerContextType,
                parentCatalogFolderId,
                parentAssignmentId,
                parentTrainingPlanId,
                0)
        }
    }

    /**
     * Assembles the details fo the detailed item summary display (see ItemSummaryDetail.tsx)
     * @param item item object (see Item.ts)
     * @param parentAssignmentId 
     * @param detailLink link to the details of the item
     */
    public static getItemSummaryDetails(item: Item | EmployeeRegisteredItem, parentAssignmentId: number, detailLink: string): ItemDetails {

        const itemDetails: ItemDetails = {
            itemId: item.itemId,
            itemTitle: item.title != null ? item.title.Ellipsis(globalConfig.itemProperties.maxTitleLengthInItemList) : '',
            itemDescription: item.summary != null ? item.summary.Ellipsis(globalConfig.itemProperties.maxSummaryLengthInItemList) : '',
            itemType: item.itemType,
            itemSubType: item.itemSubType,
            itemStatus: item.lessonStatus,
            navUrl: detailLink,
            showFavoriteRibbon: globalConfig.favoritesProperties.enableFavorites && globalConfig.favoritesProperties.enableFavoritesOnCatalogItems,
            isRatingEnabled: item.isRatingEnabled,
            ratingCount: item.ratingCount,
            myRating: item.ratingMine / 2,
            averageRating: item.ratingAverage / 2,
            registrationRequiredEnabled: item.registrationRequiredEnabled,
            registrationStatus: item.registrationStatus,
            isLocked: item.isLocked,
            isLockedDueToAssignments: item.isLockedDueToAssignments,
            isLockedDueToRequiredSkills: item.isLockedDueToRequiredSkills,
            isIgnoreStartCondition: item.isIgnoreStartCondition,
            isRegistered: item.isRegistered,
            score: item.score,
            learningPeriodEnd: item instanceof EmployeeRegisteredItem ? item.learningPeriodEnd : item.learningPeriodEndDate,
            learningPeriodStart: item instanceof EmployeeRegisteredItem ? item.learningPeriodBegin : item.learningPeriodStartDate,
            duration: item.learningDuration,
            dueDate: ItemSummaryHelper.calculateDueDate(item.itemType, item.currentRegistration, item.nextRegistration, item.assignmentsEarliestLearningPeriodBegin, item.assignmentsLatestLearningPeriodTarget, item.assignmentsLatestLearningPeriodEnd),
            parentAssignmentId: parentAssignmentId,
            remainingHoursToStart: item.remainingHoursToStart,
            remainingHoursToEnd: item.remainingHoursToEnd,
            assignmentEndDate: item.assignmentsLatestLearningPeriodEnd,
            classCosts: item instanceof EmployeeRegisteredItem ? item.classCosts : undefined,
            classCurrency: item instanceof EmployeeRegisteredItem ? item.classCurrency : undefined,
            userNameAndMail: item instanceof EmployeeRegisteredItem ? item.userNameAndEmail : undefined,
            itemAttributes: item.attributes,
            itemIcon: item.icon,
            lessonLockedByAttemptsUntil: item.lessonLockedByAttemptsUntil,
            hasPublishRight: item.hasPublishRight,
        }

        return itemDetails;
    }

    /**
     * Assembles the details fo the catalog folder item summary display (see ItemSummaryDetail.tsx)
     * @param item item objcet (see Item.ts)
     */
    public static getItemSummaryCatalogFolderDetails(item: CatalogElement): FolderDetails {
        const [folderImage, setFolderImage] = useState('');
        const tr = Session.instance.storage.translation;
        const footer = tr.GetString('folderSummary:childCount').replace('{0}', item.childCount.toString());

        useEffect(() => {
            if (folderImage === '') {
                const itemImageAttr: Attribute | undefined = item.attributes.find(f => f.name === 'ItemImage');

                if (itemImageAttr !== undefined) {
                    setFolderImage('url(' + itemImageAttr.getMediaStreamUrl() + ')');
                }
            }
        });

        const folderDetails = {
            folderId: item.itemId,
            folderTitle: item.title != null ? item.title.Ellipsis(globalConfig.itemProperties.maxTitleLengthInItemList) : '',
            folderInfo: footer,
            navUrl: `/catalog/${item.itemId}`,
            imageUrl: folderImage,
            showFavoriteRibbon: globalConfig.favoritesProperties.enableFavorites && globalConfig.favoritesProperties.enableFavoritesOnCatalogFolders,
        }
        return folderDetails;
    }

    public static getItemSummaryDetailsForSearchResults(item: SearchResult, lazyFetchResult: LazyFetchSearchResult, detailLink: string): ItemMinimalDetails {
        const itemTitle = item.itmTPId > 0 ? item.itmTPTitle + ': ' + item.title : item.title;
        const itemMinimalDetails: ItemMinimalDetails = {
            itemId: item.itemId,
            itemTitle: itemTitle != null ? itemTitle.Ellipsis(globalConfig.itemProperties.maxTitleLengthInItemList) : '',
            itemDescription: item.summary != null ? item.summary.Ellipsis(globalConfig.itemProperties.maxSummaryLengthInItemList) : '',
            itemType: item.itemType,
            itemSubType: item.itemSubType,
            itemStatus: lazyFetchResult.lessonStatus,
            navUrl: detailLink,
            isRatingEnabled: lazyFetchResult.isRatingEnabled && globalConfig.lessonToolbarProperties.displayRating,
            ratingCount: lazyFetchResult.ratingCount,
            myRating: lazyFetchResult.myRating / 2,
            averageRating: lazyFetchResult.ratingAverage / 2,
            isLocked: (!lazyFetchResult.areInputSkillsFulfilled
                || lazyFetchResult.isLockedDueToAssignment
                || lazyFetchResult.lessonLockedByAttemptsUntil != null) && item.itemType != EItemType.Folder,
            isLockedDueToAssignments: lazyFetchResult.isLockedDueToAssignment,
            isLockedDueToRequiredSkills: !lazyFetchResult.areInputSkillsFulfilled,
            itemIcon: lazyFetchResult.itemIcon,
            lessonLockedByAttemptsUntil: lazyFetchResult.lessonLockedByAttemptsUntil
        }
        return itemMinimalDetails;
    }

    /**
     * 
     * @param itemDetail 
     * @param ariaLabelItemTitle 
     */
    public static calculateDatesAndRegStatusFooterInformation(itemDetail: ItemDetails, ariaLabelItemTitle: string, inADecade: Date): { icon: string; ariaLabel: string; text?: string; textColor?: EColor; tooltip?: string } | undefined {
        const tr = Session.instance.storage.translation;
        let itemFooterIconAndText = undefined;
        const datesAriaLabel = (itemDetail.itemType === EItemType.TrainingPlan ? tr.GetString('ItemDetail:TrainingPlanDates') : tr.GetString('ItemDetail:CourseDates')) + ' ' + ariaLabelItemTitle;
        const registrationAriaLabel = tr.GetString('ItemDetail:RegistrationStatus') + ' ' + ariaLabelItemTitle;

        let textDates = '';
        if (itemDetail.learningPeriodStart && itemDetail.learningPeriodEnd) {
            textDates = StringHelper.dateString(itemDetail.learningPeriodStart) + ' - ' + StringHelper.dateString(itemDetail.learningPeriodEnd);
            if (itemDetail.learningPeriodStart.getDate() === itemDetail.learningPeriodEnd.getDate()) {
                textDates = StringHelper.dateString(itemDetail.learningPeriodStart);
            }
        }
        if (itemDetail.itemType === EItemType.TrainingPlan && (itemDetail.learningPeriodEnd == null || itemDetail.learningPeriodEnd >= inADecade)) {
            textDates = tr.GetString('TrainingPlan:UnlimitedDuration');
        }

        if (itemDetail.registrationStatus === ERegistrationStatus.Accepted) {
            const regStatusAccepted = tr.GetString('RegistrationStatus:Accepted');
            itemFooterIconAndText = { icon: IconF2FAccepted, ariaLabel: registrationAriaLabel + regStatusAccepted + ', ' + datesAriaLabel + textDates, text: textDates, tooltip: registrationAriaLabel + regStatusAccepted };
        }
        else if (itemDetail.registrationStatus === ERegistrationStatus.Requested || itemDetail.registrationStatus === ERegistrationStatus.InWaitingList) {
            const regStatus = itemDetail.registrationStatus === ERegistrationStatus.Requested ? tr.GetString('RegistrationStatus:Requested') : tr.GetString('RegistrationStatus:InWaitingList');
            itemFooterIconAndText = { icon: IconF2FRequested, ariaLabel: registrationAriaLabel + regStatus + ', ' + datesAriaLabel + textDates, text: textDates, tooltip: registrationAriaLabel + regStatus };
        }
        else if (itemDetail.registrationStatus === ERegistrationStatus.Cancelled) {
            const regStatusCancelled = tr.GetString('RegistrationStatus:Cancelled');
            itemFooterIconAndText = { icon: IconF2FCancelled, ariaLabel: registrationAriaLabel + regStatusCancelled, text: regStatusCancelled };
        }
        else if (itemDetail.registrationStatus === ERegistrationStatus.ScheduleCancelled) {
            const regStatusScheduleCancelled = tr.GetString('RegistrationStatus:ScheduleCancelled');
            itemFooterIconAndText = { icon: IconF2FCancelled, ariaLabel: registrationAriaLabel + regStatusScheduleCancelled, text: regStatusScheduleCancelled };
        }
        else if (itemDetail.registrationStatus === ERegistrationStatus.Rejected) {
            const regStatusRejected = tr.GetString('RegistrationStatus:Rejected');
            itemFooterIconAndText = { icon: IconF2FCancelled, ariaLabel: registrationAriaLabel + regStatusRejected, text: regStatusRejected };
        }

        return itemFooterIconAndText;
    }



    /**
     * Calculates the correct information for the footer based on the item type, item context, user status and available informations
     * @param itemDetail all necessary information for the itemSummaryDetail
     * @param itemCallerContext call context type of the item
     */
    public static calculateItemDetailFooterInformation(itemDetail: ItemDetails, itemCallerContext: EItemDetailCallerContextType): { icon: string; ariaLabel: string; text?: string; textColor?: EColor; tooltip?: string } | undefined {
        let itemFooterIconAndText = undefined;
        const inADecade = new Date();
        inADecade.setFullYear(inADecade.getFullYear() + 100);

        const ariaLabelItemTitle = itemDetail.itemTitle + ': ';
        let ariaLabelItemStatus;
        const tr = Session.instance.storage.translation;

        if (itemDetail.itemType === EItemType.TrainingPlan) {
            ariaLabelItemStatus = tr.GetString('ItemDetail:LessonTPlanStatus') + ' ' + itemDetail.itemTitle + ': ';
        } else {
            ariaLabelItemStatus = tr.GetString('ItemDetail:LessonStatus') + ' ' + itemDetail.itemTitle + ': ';
        }

        // Same footer for every item type if item is locked
        if (itemDetail.isLocked) {
            let lockedReasonText = tr.GetString('LessonStatus:Locked') + ' ' + tr.GetString('LessonStatus:LockedReason') + ':\r\n';
            let icon = IconLocked;
            if (itemDetail.isLockedDueToAssignments) {
                lockedReasonText += '- ' + tr.GetString('LessonStatus:LockedReasonAssignmentNotInLearningPeriod') + '\r\n';
            }
            if (itemDetail.isLockedDueToRequiredSkills) {
                lockedReasonText += '- ' + tr.GetString('LessonStatus:LockedReasonRequiredSkillsMissing') + '\r\n';

                if (!itemDetail.isRegistered && itemDetail.isIgnoreStartCondition) {
                    icon = IconWarning;
                    lockedReasonText += `\r\n ${tr.GetString('LessonStatus:IgnoreStartCondition')}`;
                }
            }
            if (itemDetail.lessonLockedByAttemptsUntil != null) {
                lockedReasonText += '- ' + ItemHelper.isLockedDueToFailedAttemptsText(itemDetail.lessonLockedByAttemptsUntil);
            }

            return { icon: icon, ariaLabel: ariaLabelItemStatus + lockedReasonText, text: tr.GetString('LessonStatus:Locked'), tooltip: StringHelper.htmlFormat(lockedReasonText) };
        }

        // If the item summary is shown in a list of assignments the due date must be displayed if available
        if (itemCallerContext === EItemDetailCallerContextType.Assignment && itemDetail.dueDate != null) {
            const dueDateText = ItemSummaryHelper.calculateRemainingTimeText(itemDetail.dueDate, inADecade, itemDetail.remainingHoursToStart, itemDetail.remainingHoursToEnd);
            const textColor = itemDetail.dueDate.getTime() < Date.now() ? EColor.Error : EColor.Undefined;
            return { icon: IconInfoRed, ariaLabel: ariaLabelItemTitle + dueDateText, text: dueDateText, textColor: textColor };
        }

        // Fix footer for course request summary
        if (itemCallerContext === EItemDetailCallerContextType.CourseRequests && itemDetail.registrationStatus === ERegistrationStatus.Requested) {
            const coureRequestText = tr.GetString('CourseRequest:EditCourseRequest');
            return { icon: IconInfoRed, ariaLabel: ariaLabelItemTitle + coureRequestText, text: coureRequestText, textColor: EColor.Error };
        }

        switch (itemDetail.itemType) {

            case EItemType.F2FCourse: {
                itemFooterIconAndText = ItemSummaryHelper.calculateDatesAndRegStatusFooterInformation(itemDetail, ariaLabelItemTitle, inADecade);
                break;
            }
            case EItemType.TrainingPlan: {
                if (itemDetail.score > 0 && itemDetail.itemStatus >= ELessonStatus.Completed) {
                    const scoreAriaLabel = tr.GetString('ItemDetail:Score') + ' ' + ariaLabelItemTitle + itemDetail.score + '%';
                    itemFooterIconAndText = { icon: IconScore, ariaLabel: scoreAriaLabel, text: itemDetail.score + '%', tooltip: tr.GetString('ItemDetail:Score') };
                }
                else {
                    itemFooterIconAndText = ItemSummaryHelper.calculateDatesAndRegStatusFooterInformation(itemDetail, ariaLabelItemTitle, inADecade);
                }
                break;
            }
            default:
                if (itemDetail.score > 0) {
                    const scoreAriaLabel = tr.GetString('ItemDetail:Score') + ' ' + ariaLabelItemTitle + itemDetail.score + '%';
                    itemFooterIconAndText = { icon: IconScore, ariaLabel: scoreAriaLabel, text: itemDetail.score + '%', tooltip: tr.GetString('ItemDetail:Score') };
                }
                else if (itemDetail.duration) {
                    const durationAriaLabel = tr.GetString('ItemDetail:LearningDuration') + ' ' + ariaLabelItemTitle + itemDetail.duration;
                    itemFooterIconAndText = { icon: IconDuration, ariaLabel: durationAriaLabel, text: itemDetail.duration, tooltip: tr.GetString('ItemDetail:LearningDuration') };
                }
        }

        if (!itemFooterIconAndText && itemDetail.dueDate != null && itemDetail.itemStatus <= ELessonStatus.Incomplete) {
            const dueDateText = ItemSummaryHelper.calculateRemainingTimeText(itemDetail.dueDate, inADecade, itemDetail.remainingHoursToStart, itemDetail.remainingHoursToEnd);
            const textColor = itemDetail.dueDate.getTime() < Date.now() ? EColor.Error : EColor.Undefined;
            return { icon: IconInfoRed, ariaLabel: ariaLabelItemTitle + dueDateText, text: dueDateText, textColor: textColor };
        }

        return itemFooterIconAndText;
    }

    /**
     * Calculates the details for the item button
     * @param itemIsLocked flag if the istem is locked
     * @param itemType item type (see EItemType)
     * @param itemTitle title of the item
     * @param itemCallerContext caller item type (see EItemDetailCallerContextType)
     * @param registrationRequiredEnabled flag if a registration is required to start the lesson
     * @param registrationStatus users registration status
     * @param itemStatus users lesson status
     * @param isBoss flag if user is boss and the item is from his employee
     * @param itemAssingmentEndDate end date of the assignment
     */
    public static calculateItemDetailFooterButtonType(
        itemIsLocked: boolean,
        itemType: EItemType,
        itemTitle: string,
        itemCallerContext: EItemDetailCallerContextType,
        registrationRequiredEnabled: boolean,
        registrationStatus: ERegistrationStatus,
        itemStatus: ELessonStatus,
        itemAssingmentEndDate?: Date,
        isBoss?: boolean,
        hasPublishRight?: boolean): { buttonType: EItemButtonType; ariaLabel: string; tooltipText?: string; disabled?: boolean } {
        const tr = Session.instance.storage.translation;

        if (itemCallerContext === EItemDetailCallerContextType.CourseRequests && registrationStatus === ERegistrationStatus.Requested) {
            return { buttonType: EItemButtonType.Edit, ariaLabel: itemTitle + ': ' + tr.GetString('CourseRequest:EditCourseRequest') };
        }

        if ((itemCallerContext === EItemDetailCallerContextType.PerformanceSupport || itemCallerContext === EItemDetailCallerContextType.PerformanceReview)
            && isBoss) {
            const tooltipText = hasPublishRight ? tr.GetString('Trainings:AssignLesson') : tr.GetString('Trainings:NoAssignRight');
            return { buttonType: EItemButtonType.AddAssingment, ariaLabel: itemTitle + ': ' + tooltipText, tooltipText, disabled: !hasPublishRight };
        }

        if (!itemIsLocked && globalConfig.itemProperties.allowStartingFromSummary
            && (globalConfig.assignmentProperties.allowToStartAssignmentAfterRunningPeriod || (itemAssingmentEndDate != null && itemAssingmentEndDate.getTime() < Date.now()))
            && (itemType === EItemType.WBT || itemType === EItemType.CBT || itemType === EItemType.Questionnaire)
            && (!registrationRequiredEnabled || registrationStatus === ERegistrationStatus.Accepted)
            && (itemStatus !== ELessonStatus.Undefined && itemStatus !== ELessonStatus.NotAttempted)) {
            return { buttonType: EItemButtonType.Play, ariaLabel: tr.GetString('Wbt:Continue') + ' ' + itemTitle };
        }

        return { buttonType: EItemButtonType.Undefined, ariaLabel: '' };
    }
}