import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter'
import { EItemType } from '$storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class WatchedItemModel {
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** Type of the item. */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType = EItemType.Undefined;
    

}