/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import ShoppingBasket from '$src/storage/models/ShoppingBasket/ShoppingBasket';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import { EPayLinkUsage } from '$src/storage/models/enums';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { NumberHelper } from '$src/util/NumberHelper';
import PurchaseService from '$src/core/Services/PurchaseService';
import { ItemPurchaseValidationRequest } from '$src/storage/models/Purchase/ItemPurchaseValidationRequest';
import { ItemPurchaseValidationResponse } from '$src/storage/models/Purchase/ItemPurchaseValidationResponse';
import { isSuccess } from '$util/Result';
import GtError from '$src/util/GtError';
import Logger from '$core/Logger';
import VoucherValidation from '$src/components/shoppingBasket/VoucherValidation';
import { CheckoutRequest } from '$src/storage/models/Purchase/CheckoutRequest';
import { CheckoutResponse } from '$src/storage/models/Purchase/CheckoutResponse';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import PaymentProviderService from '$src/core/Services/PaymentProviderService';
import useSelectPaymentProvider from '$components/shoppingBasket/SelectPaymentProvider';
import { useHistory } from 'react-router';
import ShoppingBasketItemComponent from '$components/shoppingBasket/ShoppingBasketItemComponent';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import Session from '$src/core/Session';
import { CheckBox } from '$src/components/shared/CheckBox';

const loggerLocality = 'Components.ShoppingBasketContent';

/**
 * Shows the content of the shopping basket, allows to delete items, redeem vouchers and checkout to payment
 */
export default function ShoppingBasketContent() {
    const [shoppingBasket, setShoppingBasket] = useState<ShoppingBasket>(ShoppingBasketStorage.instance.shoppingBasketContent);
    const [requiresPriceValidation, setRequiresPriceValidation] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [requiresItemsValidation, setRequiresItemsValidation] = useState<boolean>(false);
    const [itemValidationResponses, setItemValidationResponses] = useState<ItemPurchaseValidationResponse[]>([]);
    const [showCheckoutSpinner, setShowCheckoutSpinner] = useState<boolean>(false);
    const [acceptedTermsCondition, setAcceptedTermsCondition] = useState<boolean>(false);
    const { SelectPaymentProvider, selectedPaymentProvider } = useSelectPaymentProvider();
    const history = useHistory();

    function handlePayLink(payLink: string, payLinkUsage: EPayLinkUsage, referenceCode: string): void {
        Logger.log(loggerLocality, `${loggerLocality} handlePayLink()`);
        switch (payLinkUsage) {
            case EPayLinkUsage.SameTab:
                // Navigate to the payment provider in the same tab. The payment provider will then navigate to the awaitCheckoutCompletion page.
                window.location.href = payLink;
                break;
            case EPayLinkUsage.NewTab:
                // Navigate to the payment provider in a new tab and navigate to awaitCheckoutCompletion in the original tab.
                window.open(payLink, '_blank');
                history.push('/awaitCheckoutCompletion/' + referenceCode);
                break;
            case EPayLinkUsage.Webhook:
                payWithWebhook(payLink, referenceCode);
                break;
            default:
                console.error("unsupported PayLinkUsage:", payLinkUsage);
                break;
        }
    }

    useEffect(() => {
        if (requiresPriceValidation) {
            Logger.log(loggerLocality, `${loggerLocality} getVerifiedPrices()`);
            PurchaseService.instance.getVerifiedPrices(shoppingBasket).then(res => {
                setRequiresPriceValidation(false);
                if (isSuccess<ShoppingBasket>(res)) {
                    updateShoppingBasket(res, false);
                } else {
                    setErrorMessage('Price validation failed'); // no translation since it should never happen
                }
            });
        }
        if (requiresItemsValidation) {

            const itemPurchaseValidationRequests = shoppingBasket.shoppingBasketItems.map(
                item => {
                    const isPurchaseForOthers = item.bookUsers != undefined && item.bookUsers.length > 0;
                    const voucherCodeForCurrentUserHimSelf = shoppingBasket.voucherCode;
                    return new ItemPurchaseValidationRequest(item.itemType, item.title, item.itemId, item.classId, item.f2fClassIdsForTP, isPurchaseForOthers, voucherCodeForCurrentUserHimSelf)
                });

            shoppingBasket.selectedPaymentProvider = selectedPaymentProvider;
            PurchaseService.instance.validateBeforePurchase(itemPurchaseValidationRequests).then((responses) => {
                setRequiresItemsValidation(false);
                if (isSuccess<ItemPurchaseValidationResponse[]>(responses)) {
                    setItemValidationResponses(responses);
                    // if the validation did not return any problems then we can start the checkout
                    if (!anyProblemsIn(responses)) {
                        setShowCheckoutSpinner(true);
                        PurchaseService.instance.checkout(new CheckoutRequest(shoppingBasket)).then(response => {
                            if (isSuccess<CheckoutResponse>(response)) {
                                if (response.paymentProviderUrl.length > 0) {
                                    handlePayLink(response.paymentProviderUrl, response.payLinkUsage, response.referenceCode);
                                    setShowCheckoutSpinner(false);
                                } else {
                                    // No paylink: either because 100% paid by voucher or because an error occurred.
                                    // The awaitCheckoutCompletion page handles both cases.
                                    history.push('/awaitCheckoutCompletion/' + response.referenceCode);
                                }
                            } else {
                                if (responses instanceof GtError) {
                                    setErrorMessage(responses.message);
                                    setShowCheckoutSpinner(false);
                                }
                            }
                        });
                    }
                } else {
                    if (responses instanceof GtError) {
                        setErrorMessage(responses.message);
                    }
                }
            })
        }
    });

    /**
     * set the shoppingBasket state, save the ShoppingBasketStorage and start a price validation if required
     * @param modifiedShoppingBasket new shoppingBasket state (must not be the same object instance as the current state)
     * @param requirePriceValidation true if price validation must be initiated
     */
    function updateShoppingBasket(modifiedShoppingBasket: ShoppingBasket, requirePriceValidation: boolean) {
        setShoppingBasket(modifiedShoppingBasket);
        // stores the content and updates the counter in the shopping basket icon
        ShoppingBasketStorage.instance.shoppingBasketContent = modifiedShoppingBasket;
        if (requirePriceValidation) {
            setRequiresPriceValidation(true);
        }
    }

    /**
     * Call the paylink which should be our webhook link and then return to the completion page
     * @param payLink Our PaymentProvider webhook - but will be given by checkout, so this is only a hint
     */
    function payWithWebhook(payLink: string, referenceCode: string) {
        PaymentProviderService.instance.callWebhook(payLink, referenceCode).then(() => {
            history.push('/awaitCheckoutCompletion/' + referenceCode);
        });
    }

    /** click handler called when clicking on the delete icon of an item */
    const deleteItemHandler = (delItm: ShoppingBasketItem) => {
        // create a new modified ShoppingBasket, since react forbids direct modification of a state object
        const modifiedShoppingBasket = {
            ...shoppingBasket,
            shoppingBasketItems: shoppingBasket.shoppingBasketItems =
                shoppingBasket.shoppingBasketItems.filter((item) => !(item.itemId === delItm.itemId && item.classId === delItm.classId))
        }
        updateShoppingBasket(modifiedShoppingBasket, true);
        setItemValidationResponses([]);
    }

    const deleteUserHandler = (item: ShoppingBasketItem, userId: number) => {
        if (item.bookUsers && item.bookUsers.length == 1) {
            return deleteItemHandler(item);
        }

        const modifiedItems = [
            ...shoppingBasket.shoppingBasketItems
        ];
        modifiedItems[modifiedItems.findIndex(p => p.classId === item.classId)].bookUsers = item.bookUsers?.filter(p => p.userId !== userId);

        const modifiedShoppingBasket = {
            ...shoppingBasket,
            shoppingBasketItems: modifiedItems
        }

        updateShoppingBasket(modifiedShoppingBasket, true);
        setItemValidationResponses([]);
    }

    const onVoucherUpdated = (voucherCode: string) => {
        updateShoppingBasket({ ...shoppingBasket, voucherCode: voucherCode }, true);
    }

    /**
     * Renders the UI to redeem a voucher
     */
    function renderVoucherSection(): JSX.Element {
        return globalConfig.shoppingProperties.disableRedeemVoucherInShoppingBasket || shoppingBasket.shoppingBasketItems.filter(element => element.bookUsers == undefined || element.bookUsers.length == 0).length == 0 ?
            <div className='shopping-basket-content__voucher_section'>
                <p></p>
            </div>
            :
            <div className='shopping-basket-content__voucher_section'>
                <Heading headingLevel={2} cssClass="heading__Level2">
                    <Translate>ShoppingBasketContent:VoucherSubTitle</Translate>
                </Heading>
                <VoucherValidation
                    dontUpdateShoppingBasket={true}
                    dontListVoucherItems={true}
                    onVoucherUpdated={(voucherCode) => onVoucherUpdated(voucherCode)} />
            </div>
    }

    const onStartCheckout = () => {
        setRequiresItemsValidation(true);
    }

    function getBcElements() {
        const bcElements: BreadCrumbElement[] = [];
        bcElements.push(
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/shoppingBasketContent`,
                title: Session.instance.storage.translation.GetString('Navigation:ShoppingBasket')
            }
        );

        return bcElements;
    }

    function onClickTermsAndConditions() {
        setAcceptedTermsCondition(!acceptedTermsCondition);
    }

    function termsAndConditionsForAdmin() {
        if (!acceptedTermsCondition) {
            setAcceptedTermsCondition(true)
        }
    }

    function termsAndConditions() {
        return <>
            {globalConfig.shoppingProperties.consentToAGBNecessaryForPaymentAdmin && Session.instance.loginUser?.roles.includes("PaymentBookingOverviewAdmin") ?
                termsAndConditionsForAdmin() :

                <div className='shopping-basket-content__termsAndCondition'>
                    <CheckBox id="cbAcceptAGB" onClick={() => onClickTermsAndConditions()} />
                    <label id="lblAcceptAGB" htmlFor="cbAcceptAGB">
                        <div className="shopping-basket-content__termsAndCondition--margin" >
                            <Translate>ShoppingBasketContent:TermsAndConditions</Translate>
                        </div>
                        <a href={Session.instance.storage.translation.GetString(`${globalConfig.shoppingProperties.linkToAGB.link}`)} target="_blank">
                            {Session.instance.storage.translation.GetString(`${globalConfig.shoppingProperties.linkToAGB.text}`)}
                        </a>

                    </label>

                </div>}
        </>
    }

    return (
        <div className="l-container">
            <MenuBreadCrumb routePathName=' ' breadCrumbElementsToAppend={getBcElements()} />

            <Heading headingLevel={1} cssClass="heading__Title shopping-basket-content__title-with-subtitle">
                <Translate>ShoppingBasketContent:Title</Translate>
            </Heading>
            <div>
                <Translate>ShoppingBasketContent:SubTitle</Translate>
            </div>

            {renderShoppingBasketContent(shoppingBasket, requiresPriceValidation, deleteItemHandler, deleteUserHandler, () => setRequiresPriceValidation(true))}
            {renderVoucherSection()}
            {renderPriceCalculation(shoppingBasket, requiresPriceValidation)}
            {errorMessage &&
                <Alert alertAppereance="box" alertType="error" message={errorMessage} />}
            {anyProblemsIn(itemValidationResponses) &&
                <Alert alertAppereance="box" alertType="error" message={getProblemMessages(itemValidationResponses)} />}
            {hasMixedCurrencies(shoppingBasket.shoppingBasketItems) &&
                <Alert message="ShoppingBasketContent:CurrencyMismatchNotAllowed" alertAppereance="box" alertType="error" />}
            {shoppingBasket.finalPrice > 0 && !showCheckoutSpinner && <SelectPaymentProvider />}
            {showCheckoutSpinner && <ProgressSpinner />}
            {termsAndConditions()}
            <div className="shopping-basket-content__start-payment">
                <button type="button"
                    className="btn--md btn--primary"
                    disabled={shoppingBasket.shoppingBasketItems.length === 0
                        || showCheckoutSpinner
                        || anyProblemsIn(itemValidationResponses)
                        || hasMixedCurrencies(shoppingBasket.shoppingBasketItems)
                        || (!!errorMessage)
                        || requiresPriceValidation
                        || !acceptedTermsCondition
                    }
                    onClick={() => onStartCheckout()}>
                    {shoppingBasket.finalPrice === 0 ?
                        <Translate>ShoppingBasketContent:UnlockContents</Translate> :
                        <span><Translate>ShoppingBasketContent:StartPayment</Translate></span>}
                </button>
            </div>
        </div>
    );
}


/**
 * Returns the concatenated messages for all items that cannot be purchased
 */
function getProblemMessages(responses: ItemPurchaseValidationResponse[]): string {
    return responses.filter(itemResp => !itemResp.canBePurchased).map(itemResp => itemResp.reason).join('<br>');
}


/**
 * Returns true if the responses show that any of the items cannot be purchased
 */
function anyProblemsIn(responses: ItemPurchaseValidationResponse[]): boolean {
    return responses.filter(itemResp => !itemResp.canBePurchased).length > 0;
}

function hasMixedCurrencies(shoppingBasketItems: ShoppingBasketItem[]): boolean {
    // verify that all shopping basket items have the same currency
    return shoppingBasketItems.find((e, index, arr) => e.currency !== arr[0].currency) !== undefined;
}


/** 
 * Renders the content of the shopping basket
 * */
function renderShoppingBasketContent(shoppingBasket: ShoppingBasket, isRecalculating: boolean, deleteItemHandler: (sbItem: ShoppingBasketItem) => void, deleteUserHandler: (item: ShoppingBasketItem, userId: number) => void, itemUpdatedHandler: (item: ShoppingBasketItem) => void): JSX.Element {
    return (
        <>
            <Heading headingLevel={2} cssClass="heading__Level2 shopping-basket-content__title-with-subtitle">
                <Translate>ShoppingBasketContent:ItemsSubTitle</Translate>
            </Heading>
            <div className='shopping-basket-content__subtitle'>
                <Translate>ShoppingBasketContent:ItemsSubSubTitle</Translate>
            </div>

            {
                shoppingBasket.shoppingBasketItems && shoppingBasket.shoppingBasketItems.map((item, index) => {
                    return <ShoppingBasketItemComponent key={index} item={item} isRecalculating={isRecalculating} onDeleteClicked={() => deleteItemHandler(item)} onUserDeleteClicked={(userId) => deleteUserHandler(item, userId)} onItemUpdated={(e) => itemUpdatedHandler(e)} />
                })
            }
        </>
    );
}

/**
 * Renders the price summary
 */
function renderPriceCalculation(shoppingBasket: ShoppingBasket, isRecalculating: boolean): JSX.Element | null {
    if (shoppingBasket.shoppingBasketItems.length == 0) {
        return null;
    } else {
        const currency = shoppingBasket.shoppingBasketItems[0].currency;
        return (
            <div role="table">
                {shoppingBasket.discount !== 0 &&
                    <>
                        <div role="cell" className="shopping-basket-content__price-calculation">
                            <div><Translate>ShoppingBasketContent:Subtotal</Translate></div>
                            <div>{isRecalculating ? <ProgressSpinner size={16} /> : NumberHelper.getFormattedPrice(shoppingBasket.totalPriceBeforeDiscount, currency)}</div>
                        </div>
                        <div role="cell" className="shopping-basket-content__price-calculation shopping-basket-content__price-calculation-discount">
                            <div><Translate>ShoppingBasketContent:Discount</Translate></div>
                            <div>{isRecalculating ? '...' : NumberHelper.getFormattedPrice(-shoppingBasket.discount, currency)}</div>
                        </div>
                    </>}
                <div role="cell" className="shopping-basket-content__price-calculation">
                    <div><Translate>ShoppingBasketContent:TotalPrice</Translate></div>
                    <div>
                        {isRecalculating ? '...' : NumberHelper.getFormattedPrice(shoppingBasket.finalPrice, currency)}
                    </div>
                </div>
            </div>
        );
    }
}

