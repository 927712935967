import { EGamificationPointOrigin } from "$storage/models/enums"
import React from "react"
import { Translate } from "$components/shared/Translate"
import { GamificationPointHistoryDetail as GamificationPointHistoryDetailModel } from '$src/storage/models/Gamification/GamificationPointHistoryDetail';
import TranslationStorage from "$storage/TranslationStorage";

interface IGamificationPointHistoryTitleProps {
    detail: GamificationPointHistoryDetailModel;
    translationStorage: TranslationStorage;
}

export const GamificationPointHistoryTitle: React.FC<IGamificationPointHistoryTitleProps> = (props: IGamificationPointHistoryTitleProps) => {
    return <>
        {props.detail.triggerItem != null && props.detail.PointOrigin === EGamificationPointOrigin.LessonStatusChanged && props.detail.triggerItem.title}
        {props.detail.triggerItem == null && props.detail.PointOrigin === EGamificationPointOrigin.LessonStatusChanged && props.detail.triggerObjectTitle}
        {props.detail.PointOrigin == EGamificationPointOrigin.RatingSubmitted && <Translate>GamificationPointHistory:RatingSubmitted</Translate>}
        {props.detail.PointOrigin == EGamificationPointOrigin.SkillAquired && props.detail.triggerObjectTitle}
    </>
}