import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject
export class RemoveSkillResponse {
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('skillId', Number, false)
    public skillId: number = 0;

    @JsonProperty('removed', Boolean, false)
    public removed: boolean = false;
}

// This container is needed to be able to json convert the array
// tslint:disable-next-line: max-classes-per-file
@JsonObject
export class RemoveSkillResponseContainer {
    @JsonProperty('removeSkillResponses', [RemoveSkillResponse], false)
    public removeSkillResponses: RemoveSkillResponse[] = [];
}