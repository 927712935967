
import { GamificationPointHistoryDetail } from '$src/storage/models/Gamification/GamificationPointHistoryDetail';
import { GamificationPointHistoryOverview } from '$storage/models/Gamification/GamificationPointHistoryOverview';
import GtError from '$util/GtError';
import ServiceClient from '$core/ServiceClient';
import Session from '$core/Session';
import { GamificationUserHighscore } from '$src/storage/models/Gamification/GamificationUserHighscore';
import { GamificationGroupHighscore } from '$src/storage/models/Gamification/GamificationGroupHighscore';


/**
 * GamificationService provides all Service Methods for the Gamification Functionality
 */
export default class GamificationService extends ServiceClient {
    protected static _instance: GamificationService | null = null;

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): GamificationService {
        return this._instance || (this._instance = new this());
    }

    public async getGamificationPointHistoryOverview(): Promise<GamificationPointHistoryOverview | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        return await this.get<GamificationPointHistoryOverview>('gamification/pointhistoryoverview', 
        GamificationPointHistoryOverview, 
        undefined, 
        [{ name: 'language', value: language }]);
    }

    public async getGamificationPointHistoryDetail(pageSize: number, pageNumber: number): Promise<GamificationPointHistoryDetail[] | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        return await this.get<GamificationPointHistoryDetail[]>('gamification/pointhistorydetail', 
        GamificationPointHistoryDetail, 
        undefined, 
        [{ name: 'language', value: language }],
        pageSize.toString(),
        pageNumber.toString());
    }

    public async getGamificationUserHighscore(): Promise<GamificationUserHighscore[] | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        return await this.get<GamificationUserHighscore[]>('gamification/userHighscore', 
        GamificationUserHighscore, 
        undefined, 
        [{ name: 'language', value: language }]);
    }

    public async getGamificationGroupHighscore(): Promise<GamificationGroupHighscore[] | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        return await this.get<GamificationGroupHighscore[]>('gamification/groupHighscore', 
        GamificationGroupHighscore, 
        undefined, 
        [{ name: 'language', value: language }]);
    }

} 