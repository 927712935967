import React from 'react';

interface IProps {
    tabId: string;
    title: string;
    content: JSX.Element;
    isActive: boolean;
}

export class Tab extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <div id={this.props.tabId + '_panel'} role="tabpanel" aria-labelledby={ this.props.tabId }>
                {this.props.isActive ? this.props.content : null}
            </div>
        );
    }
}