import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import Session from '$src/core/Session';
import { Heading } from '$src/components/shared/Heading';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { TrainerBookingsResponse, TrainerBookings } from '$src/storage/models/Trainer/TrainerBookingsResponse';
import TrainerService from '$src/core/Services/TrainerService';
import { isSuccess } from '$src/util/Result';
import Logger from '$src/core/Logger';
import GtError from '$src/util/GtError';
import { SortableHeaderElement } from '$src/components/shared/SortableHeaderElement';
import { ESortDirection, EItemDetailCallerContextType } from '$src/storage/models/enums';
import DateHelper from '$src/util/DateHelper';
import { NavigationUtil } from '$src/util/NavigationUtil';
import InlineSVG from 'react-inlinesvg';
import InfoIcon from '$resources/svgs/misc/info.svg';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { ItemHelper } from '$src/util/ItemHelper';
import CourseAttendance001 from '$resources/svgs/reports/CourseAttendance001.svg';
import ParticipantList from '$resources/svgs/reports/ParticipantList.svg';
import Survey001 from '$resources/svgs/reports/Survey001.svg';
import FeedbackRow from '$src/components/trainer/FeedbackRow';
import { Translate } from '$src/components/shared/Translate';
import { BookingsFilter } from '$src/storage/models/Trainer/BookingsFilter';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';
import { StringHelper } from '$src/util/StringHelper';
import SystemRoles from '$src/core/SystemRoles';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import GTInfoMessage from '$src/components/shared/Atoms/GTInfoMessage';
import MailIcon from '$resources/svgs/objects/mailTo.svg';

interface IStateTable {
    activeHeaderId: string;
    courseHeader: string;
    classHeader: string;
    dateHeader: string;
}
interface IStateItemData {
    dataIsLoaded: boolean;
    dataIsLoading: boolean;
    data: TrainerBookings[] | undefined;
    sortedBookings: TrainerBookings[] | undefined;
    dataPageSize: number;
    dataCurrentPage: number;
    dataHasMore: boolean;
}
interface IStateFeedbackRow {
    openRowId: number;
}
interface IStateFilterData {
    filterYearSelected: number;
    filterMonthSelected: number;
    filterCourseTitle: string;
    filterClassTitle: string;
    filterMonths: number[];
    filterYears: number[];
}
interface IStateError {
    errorMessage: string;
    showErrorMessage: boolean;
    noParticipantsFound: boolean;
}
interface IState extends IStateItemData, IStateError, IStateFilterData, IStateTable, IStateFeedbackRow {
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class TrainerBookingView extends React.Component<RouteComponentProps, IState> {

    protected inputControl: HTMLInputElement | null;
    protected className = 'TrainerBookingView';
    protected loggerLocality = 'Components.TrainerBookingView';
    private TRANSLATION_PREFIX = 'TrainerBookingView';
    private lgCode = Session.instance.getUserLanguageCodeOrFallBack;

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            data: undefined,
            sortedBookings: undefined,
            dataIsLoaded: false,
            dataIsLoading: true,
            dataPageSize: 10,
            dataHasMore: false,
            dataCurrentPage: 0,

            errorMessage: '',

            filterYearSelected: 0,
            filterMonthSelected: 0,
            filterCourseTitle: '',
            filterClassTitle: '',
            filterMonths: [],
            filterYears: [],
            showErrorMessage: false,
            noParticipantsFound: false,

            activeHeaderId: this.getTranslation('BookingDates'),
            courseHeader: this.getTranslation('CourseTitle'),
            classHeader: this.getTranslation('ClassTitle'),
            dateHeader: this.getTranslation('BookingDates'),

            openRowId: -1,
        }
    }

    public async componentDidMount() {
        let data = {};
        data = this.loadData(this.state.dataCurrentPage, true);
        await Promise.all([data]);
    }

    public render() {
        return (
            <div className="l-container trainer-booking-view__container" >
                <div>
                    <MenuBreadCrumb
                        breadCrumbElementsToAppend={[{
                            navigationPath: this.props.location.pathname,
                            title: Session.instance.storage.translation.GetString('Navigation:TrainerBookingView'),
                            translateTitle: true
                        }]}
                        routePathName="/trainer"
                        {...this.props} />
                    <div className="l-box--wide">
                        <Heading headingLevel={1} cssClass="heading__Title">
                            {this.getTranslation('Title')}
                        </Heading>
                        {this.renderSubTitle()}
                    </div>
                    <div className="booking-view__filter-wrapper">
                        {this.renderTextFilter()}
                        {this.renderDropdownFilters()}
                        {this.renderApplyFilterbutton()}
                    </div>
                    <div>
                        {this.renderBookings()}
                        {this.renderLoadMoreButton()}
                    </div>
                    {this.state.dataIsLoading ? <ProgressSpinner /> :
                        this.state.dataIsLoaded && this.state.data === undefined ?
                            <Alert
                                alertType={'warning'}
                                alertAppereance={'box'}
                                message={this.state.errorMessage} />
                            : null
                    }
                </div>
            </div>
        )
    }

    private renderSubTitle(): JSX.Element {
        return (
            <div>
                <Translate>TrainerBookingView:SubTitle</Translate>
            </div>
        )
    }

    private applyFilter = async () => {
        const filter = new BookingsFilter(this.state.filterCourseTitle, this.state.filterClassTitle, this.state.filterYearSelected, this.state.filterMonthSelected);
        if (filter !== null) {
            Session.instance.setBookingFilters(filter);
        }
        this.setState({ dataIsLoading: true, data: undefined, dataIsLoaded: false });
        await this.loadData(0, true);
    }

    private loadData = async (dataCurrentPage: number, clearBookings: boolean) => {
        const bookings: TrainerBookingsResponse | GtError =
            await TrainerService.instance.getBookings(
                this.lgCode,
                this.state.dataPageSize,
                dataCurrentPage + 1,
                this.state.filterYearSelected,
                this.state.filterMonthSelected,
                this.state.filterCourseTitle,
                this.state.filterClassTitle);

        if (isSuccess<TrainerBookingsResponse>(bookings)) {
            if (bookings) {
                const moreDataToLoad = this.state.dataPageSize < bookings.trainerBookings.length;
                Logger.log(this.loggerLocality, `Successfully loaded bookings from server.`);
                if (moreDataToLoad) bookings.trainerBookings.pop(); // Remove additonal loaded Item if needed
                const data = !clearBookings ? [...(this.state.data || []), ...bookings.trainerBookings] : bookings.trainerBookings;
                this.setState({
                    dataIsLoaded: true,
                    dataIsLoading: false,
                    data,
                    sortedBookings: data,
                    errorMessage: '',
                    dataCurrentPage: dataCurrentPage + 1,
                    dataHasMore: moreDataToLoad,
                    filterMonths: bookings.filterMonths,
                    filterYears: bookings.filterYears,
                    filterMonthSelected: this.state.filterMonthSelected,
                    filterYearSelected: this.state.filterYearSelected
                });
            }
            else {
                Logger.log(this.loggerLocality, `Successfully executed request. No Bookings were found.`);
                this.setState({
                    dataIsLoaded: true,
                    dataIsLoading: false,
                    data: undefined,
                    sortedBookings: undefined,
                    errorMessage: this.getTranslation('NoBookingsFound')
                });
            }
        }
        else {
            const msg = 'Failed to load bookings from server.';
            Logger.log(this.loggerLocality, msg);
            this.setState({ errorMessage: this.getTranslation('ErrorOcurred'), dataIsLoading: false });
        }
    }



    private renderDropdownFilters = (): JSX.Element => {
        return (
            <React.Fragment>
                <div className="booking-view__container">
                    <div className="input-title__container">
                        <label id="ReportingItemTypeFilter">{this.getTranslation('FilterByYear')}</label>
                    </div>
                    {/* Filter by Year */}
                    <div className="input-field__container">
                        <select
                            className="booking-view__filter-action"
                            onChange={e =>
                                this.setState({ filterYearSelected: +e.target.value })}
                            aria-labelledby="BookingsFilterByYear">
                            <option value="0" selected={0 === this.state.filterYearSelected}>{this.getTranslation('All')}</option>
                            {this.createYearDropdownOptions()}
                        </select>
                    </div>
                </div>
                <div className="booking-view__container">
                    <div className="input-title__container">
                        <label id="ReportingItemTypeFilter">{this.getTranslation('FilterByMonth')}</label>
                    </div>
                    {/* Filter by Month */}
                    <div className="input-field__container">
                        <select
                            className="booking-view__filter-action"
                            onChange={e => this.setState({ filterMonthSelected: +e.target.value })}
                            aria-labelledby="BookingsFilterByMonth">
                            <option value="0" selected={0 === this.state.filterMonthSelected}>{this.getTranslation('All')}</option>
                            {this.createMonthDropdownOptions()}
                        </select>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderTextFilter = (): JSX.Element => {
        const placeholder = Session.instance.storage.translation.GetString('Filter:FilterPlaceholder');
        return (
            <React.Fragment>
                <div className="booking-view__container">
                    <div className="input-title__container">
                        <label id="ReportingTextFilter" >{this.getTranslation('SearchFilterCourseTitle')}</label>
                    </div>
                    <div className="input-field__container">
                        <input
                            id="CourseFilter"
                            className="booking-view__filter-action"
                            name="CourseFilter"
                            type="text"
                            autoComplete={'on'}
                            // aria-label={Session.instance.storage.translation.GetString('Filter:FilterFieldLabel')}
                            aria-labelledby="ReportingTextFilter"
                            ref={(e) => this.inputControl = e}
                            placeholder={placeholder}
                            onChange={(e) => { this.setState({ filterCourseTitle: e.target.value }) }}
                            value={this.state.filterCourseTitle}
                        />
                    </div>
                </div>
                <div className="booking-view__container">
                    <div className="input-title__container">
                        <label id="ReportingTextFilter">{this.getTranslation('SearchFilterClassTitle')}</label>
                    </div>
                    <div className="input-field__container">
                        <input
                            id="ClassFilter"
                            className="booking-view__filter-action"
                            name="ClassFilter"
                            type="text"
                            autoComplete={'on'}
                            // aria-label={Session.instance.storage.translation.GetString('Filter:FilterFieldLabel')}
                            aria-labelledby="ReportingTextFilter"
                            ref={(e) => this.inputControl = e}
                            placeholder={placeholder}
                            onChange={(e) => { this.setState({ filterClassTitle: e.target.value }) }}
                            value={this.state.filterClassTitle}
                        />
                    </div>
                </div>
            </React.Fragment>

        );
    }

    private renderMessage() {
        return (
            <>
                {this.state.showErrorMessage && <ErrorMessage errorMessage={this.getTranslation("TooLongLink")} />}
                {this.state.noParticipantsFound && <GTInfoMessage
                    id={"NoParticipantsFound"}
                    message={this.getTranslation("NoParticipantsFound")}
                    showInfoIcon={true} />
                }
            </>
        )
    }

    private renderBookings = (): JSX.Element | null => {
        if (this.state.sortedBookings !== undefined) {
            return (
                <>
                    <div className="booking-view__container">
                        <table className="trainer-bookings__table">
                            <thead>
                                {this.renderTableHeader()}
                            </thead>
                            <tbody>
                                {this.createBookingRows(this.state.sortedBookings)}
                            </tbody>
                        </table>

                    </div>
                    {this.renderMessage()}
                </>
            )
        } else {
            return null;
        }
    }

    private renderTableHeader() {
        const tableHeader: JSX.Element = (
            <tr>
                <th className="trainerBookingView__th--course">
                    <SortableHeaderElement
                        id={this.state.courseHeader}
                        isActive={this.IsActiveHeader(this.state.courseHeader)}
                        header={this.state.courseHeader}
                        className="heading__Level4"
                        initialSortDirection={ESortDirection.Down}
                        OnSortDirectionChanged={this.OnSort}
                    />
                </th>
                <th className="trainerBookingView__th--class">
                    <SortableHeaderElement
                        id={this.state.classHeader}
                        isActive={this.IsActiveHeader(this.state.classHeader)}
                        header={this.state.classHeader}
                        className="heading__Level4"
                        initialSortDirection={ESortDirection.None}
                        OnSortDirectionChanged={this.OnSort}
                    />
                </th>
                <th className="trainerBookingView__th--dates">
                    <SortableHeaderElement
                        id={this.state.dateHeader}
                        isActive={this.IsActiveHeader(this.state.dateHeader)}
                        header={this.state.dateHeader}
                        className="heading__Level4"
                        initialSortDirection={ESortDirection.None}
                        OnSortDirectionChanged={this.OnSort}
                    />
                </th>
                <th className="trainerBookingView__th--info">
                </th>
            </tr>
        );
        return tableHeader
    }

    private createBookingRows = (bookings: TrainerBookings[]): JSX.Element[] | null => {
        if (bookings !== undefined) {
            const elements: JSX.Element[] = [];
            for (let index = 0; index < bookings.length; index++) {
                const item = bookings[index];
                if (item !== undefined) {
                    const bookingDates = `${StringHelper.dateString(item.sessionStartDate)} - ${StringHelper.dateString(item.sessionEndDate)}`
                    const reportNameCourseAttendance = Session.instance.storage.translation.GetString('Report:CourseAttendance001');
                    const reportNameQuestionnaire = Session.instance.storage.translation.GetString('Report:Survey001');
                    const QuestionnaireButtonId = `GTButtonQuestionnaireButton_${index}`
                    const ReportUrlButtonId = `GTButtonReportUrlButton_${index}`
                    const hasTutorRole = Session.instance.loginUser!.roles.find(role => role.includes(SystemRoles.instance.Tutor)) != undefined;

                    const element = (
                        <React.Fragment key={`Booking_${item.classId}`}>
                            <tr>
                                <td className="trainerBookingView__col--course">
                                    {item.courseTitle}
                                </td>
                                <td className="trainerBookingView__col--class">
                                    {item.classTitle}
                                </td>
                                <td className="trainerBookingView__col--dates">
                                    {bookingDates}
                                </td>
                                <td className="trainerBookingView__col--info">
                                    <GTIconButton
                                        id={`Course_Info_${item.classId}`}
                                        ariaLabel={this.getTranslation("Info").replace("{title}", item.courseTitle)}
                                        tooltipText={this.getTranslation("Info").replace("{title}", item.courseTitle)}
                                        onClick={() => this.props.history.push(this.getLink(item.courseItemId))}>
                                        <InlineSVG src={InfoIcon} />
                                    </GTIconButton>
                                    {globalConfig.f2fProperties.showParticipantListBookingOverview && hasTutorRole && item.reportUrl != '' && <GTIconButton
                                        id={`Participant_List_${item.classId}`}
                                        ariaLabel={this.getTranslation("ParticipantList")}
                                        tooltipText={this.getTranslation("ParticipantList")}
                                        onClick={() => this.props.history.push(this.getParticipantListLink(item.classId))}>
                                        <InlineSVG src={ParticipantList} />
                                    </GTIconButton>}

                                    {globalConfig.f2fProperties.showMailLink && <GTIconButton
                                        id={`CreateMailLink_${item.classId}`}
                                        ariaLabel={this.getTranslation("MailLink")}
                                        tooltipText={this.getTranslation("MailLink")}
                                        onClick={() => this.getMailToLink(item.classId)}>
                                        <InlineSVG src={MailIcon} />
                                    </GTIconButton>}

                                    {// If Trainer has no Tutoring rights, then the report URL is empty and no icon is shown
                                        (item.reportUrl != '' && hasTutorRole) ? <GTIconButton
                                            id={ReportUrlButtonId}
                                            onClick={() => this.onNavigate(item.reportUrl, false, true)}
                                            ariaLabel={reportNameCourseAttendance}
                                            tooltipText={reportNameCourseAttendance}>
                                            <InlineSVG src={CourseAttendance001} />
                                        </GTIconButton> : null

                                    }
                                    {(item.questionnaireUrls != null && hasTutorRole) ? <GTIconButton
                                        id={QuestionnaireButtonId}
                                        onClick={() => (this.state.openRowId === item.classId) ?
                                            this.setState({ openRowId: 0 }) :
                                            this.setState({ openRowId: item.classId })}
                                        ariaLabel={reportNameQuestionnaire}
                                        tooltipText={reportNameQuestionnaire}>
                                        <InlineSVG src={Survey001} />
                                    </GTIconButton> : null
                                    }
                                </td>
                            </tr>
                            {
                                (this.state.openRowId === item.classId ?
                                    <tr>
                                        <td colSpan={4}>
                                            <FeedbackRow
                                                id={item.classId}
                                                open={(this.state.openRowId === item.classId) ? true : false}
                                                questionnaires={item.questionnaireUrls}
                                                title={reportNameQuestionnaire} />
                                        </td>
                                    </tr>
                                    : null)
                            }
                        </React.Fragment>
                    );
                    elements.push(element);
                }
            }
            return elements;
        } else {
            return null;
        }
    }

    private createYearDropdownOptions() {
        const options: number[] = [];
        const elements: Array<JSX.Element> = [];
        if (this.state.data !== undefined) {
            this.state.filterYears.forEach(year => {
                options.push(year);
            });
            options.forEach(option => {
                elements.push(
                    <option key={`YearDropDownOption_${option}`}
                        value={option}
                        selected={this.state.filterYearSelected === option}>
                        {option}
                    </option>
                );
            });
        }
        return elements;
    }

    private createMonthDropdownOptions() {
        const options: string[] = [];
        const monthlyNumber: number[] = [];
        const elements: Array<JSX.Element> = [];
        if (this.state.data !== undefined) {
            this.state.filterMonths.forEach(elm => {
                const date = new Date(0, elm, 0);
                const month = date.toLocaleString(Session.instance.getUserLanguageCodeOrFallBack, { month: 'long' });
                if (!options.includes(month)) {
                    options.push(month);
                    monthlyNumber.push(date.getMonth() + 1);
                }
            });
            for (let index = 0; index < options.length; index++) {
                elements.push(
                    <option key={`MonthDropDownOption_${options[index]}`}
                        value={monthlyNumber[index]}
                        selected={this.state.filterMonthSelected === monthlyNumber[index]} >
                        {options[index]}
                    </option>
                );
            }
        }
        return elements;
    }

    private OnSort = (id: string, direction: ESortDirection) => {
        if (this.state.data != null && this.state.sortedBookings !== undefined) {
            if (id === this.state.courseHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.courseHeader,
                        sortedBookings: this.state.sortedBookings.sort(this.sortBookingsByCoursetitle)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.courseHeader,
                        sortedBookings: this.state.sortedBookings.sort(this.sortBookingsByCoursetitleDown)
                    });
                }
            }
            if (id === this.state.classHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.classHeader,
                        sortedBookings: this.state.sortedBookings.sort(this.sortBookingsByClasstitle)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.classHeader,
                        sortedBookings: this.state.sortedBookings.sort(this.sortBookingsByClasstitleDown)
                    });
                }
            }
            if (id === this.state.dateHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.dateHeader,
                        sortedBookings: this.state.sortedBookings.sort(this.sortBookingsByDates)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.dateHeader,
                        sortedBookings: this.state.sortedBookings.sort(this.sortBookingsByDatesDown)
                    });
                }
            }
        }
    }

    /**
 * Sort the Trainer Bookings alphabeticly
 * @param a      
 * @param b 
 */
    public sortBookingsByCoursetitle = (a: TrainerBookings, b: TrainerBookings) => {
        return b.courseTitle.toLowerCase().localeCompare(a.courseTitle.toLowerCase());
    }
    public sortBookingsByCoursetitleDown = (a: TrainerBookings, b: TrainerBookings) => {
        return a.courseTitle.toLowerCase().localeCompare(b.courseTitle.toLowerCase());
    }

    public sortBookingsByClasstitle = (a: TrainerBookings, b: TrainerBookings) => {
        return b.classTitle.toLowerCase().localeCompare(a.classTitle.toLowerCase());
    }
    public sortBookingsByClasstitleDown = (a: TrainerBookings, b: TrainerBookings) => {
        return a.classTitle.toLowerCase().localeCompare(b.classTitle.toLowerCase());
    }

    public sortBookingsByDates = (a: TrainerBookings, b: TrainerBookings) => {
        return DateHelper.CompareDate(a.sessionStartDate, b.sessionStartDate);
    }
    public sortBookingsByDatesDown = (a: TrainerBookings, b: TrainerBookings) => {
        return DateHelper.CompareDate(b.sessionStartDate, a.sessionStartDate);
    }

    private renderApplyFilterbutton = (): JSX.Element => {
        return (
            <div className="reporting__filter-container">
                <div className="input-title__container">
                </div>
                <div className="input-field__container">
                    <button type="button" className="btn--md btn--primary booking-view__filter-btn" onClick={() => this.applyFilter()}>
                        <Translate>ReportTutoring:ApplyFilter</Translate>
                    </button>
                </div>
            </div>
        )
    }

    private renderLoadMoreButton = (): JSX.Element | null => {
        if (this.state.errorMessage.length > 0) return null;

        if (this.state.dataHasMore && !this.state.dataIsLoading) {
            return <div className="booking-view__load-more-btn">
                <button
                    className="btn--md btn--primary"
                    onClick={() => this.loadNextPage()}>
                    {this.getTranslation('loadMore')}
                </button>
            </div>
        }

        return null;
    }

    private loadNextPage = async () => {
        this.setState({ dataIsLoading: true });
        await this.loadData(this.state.dataCurrentPage, false);
    }

    protected getLink(itemId: number): string {
        return ItemHelper.getItemDetailLink(itemId,
            EItemDetailCallerContextType.TrainerBookingView,
            0,
            0,
            0,
            0);
    }

    protected getParticipantListLink(itemId: number): string {
        return `/participantlisttutor/${itemId}?callercontext=nsui`;
    }

    protected getMailToLink(classId: number) {
        const methodName = `${this.className}:getMailToLink()`;
        TrainerService.instance.getMailLink(Session.instance.getUserLanguageCodeOrFallBack, classId).then((result) => {
            if (isSuccess<string>(result) && result.length > 0) {
                if (result.length > 2040) {
                    this.setState({ showErrorMessage: true, noParticipantsFound: false });
                } else if (result == "mailto:?bcc=") {
                    this.setState({ noParticipantsFound: true, showErrorMessage: false });
                } else {
                    window.open(result);
                    this.setState({ showErrorMessage: false, noParticipantsFound: false });
                }
            } else {
                const errorMessage = `${methodName} failed to get users`;
                Logger.log(this.loggerLocality, `${methodName} called`);
                console.log(errorMessage);
            }
        })
    }

    private onNavigate(url: string, doAUILogin: boolean, doLMSSUILogin: boolean) {
        if (doLMSSUILogin) {
            const win = window.open('about:blank', '_blank');
            // event.preventDefault();
            if (win != null) {
                NavigationUtil.loginSui(url, win);
            }
        }
        else if (doAUILogin) {
            const win = window.open('about:blank', '_blank');
            // event.preventDefault();
            if (win != null) {
                NavigationUtil.loginAui(win);
            }
        }
    }

    private IsActiveHeader(id: string) {
        return id === this.state.activeHeaderId;
    }

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }
}

export default TrainerBookingView;