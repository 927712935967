import { JsonObject, JsonProperty } from 'json2typescript'
import { LocationDocument } from './LocationDocument';

@JsonObject
export class LocationDocumentList {

    /** Documents of the corresponding Room */
    @JsonProperty('roomDocuments', [LocationDocument], false)
    public roomDocuments: LocationDocument[] = [];
    
    /** Documents of the corresponding Venue */
    @JsonProperty('venueDocuments', [LocationDocument], false)
    public venueDocuments: LocationDocument[] = [];
}