export class UrlHelper {
    public static getHttpsUrl(url: string) {
        const pattern = new RegExp('^(https?|ftp)://');
        if (!pattern.test(url)) {
            url = `https://${url}`;
        }
        return url;
    }

    public static getCurrentSuiUrl() {
        // eslint-disable-next-line @typescript-eslint/camelcase
        let suiName = __webpack_public_path__;
        if(suiName.startsWith('/')) {
            suiName = suiName.slice(1);
        }
        return location.protocol + '//' + location.host + '/' + suiName;
    }

    /**
     * Returns the search params as string 
     * Automatically including the leading ?
     * @param searchParams Url query parameter
     */
    public static searchToString(searchParams: URLSearchParams) {
        const search = searchParams.toString();
        return search.length > 0 ? '?' + search : '';
    }
}