import Logger from '$src/core/Logger';
import F2FService from '$src/core/Services/F2FService';
import SessionCache from '$src/storage/cache/SessionCache';
import { F2FDocumentList } from '$src/storage/models/F2F/F2FDocumentList';
import { isSuccess } from '$src/util/Result';
import GtError from '$util/GtError';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

const CACHING_DURATION = 300;

export default class F2FDocumentListStorage extends SessionCache<string, F2FDocumentList> {
    protected className = 'F2FDoucmentListStorage';
    protected loggerLocality = 'Storage.F2FDoucmentListStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getF2FDocuments(courseId: number, courseItemId: number, classId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0, forceReload?: boolean): Promise<F2FDocumentList | null | GtError> {
        const methodName = `${this.className}:getF2FDocuments`;
        if(courseId < 0) { courseId = 0}
        if(courseItemId < 0) { courseItemId = 0}
        if(classId < 0) { classId = 0}
        const key = `${courseId}-${courseItemId}-${classId}`;
        if(this.isObjectInCache(key) && !forceReload) {
            Logger.log(this.loggerLocality, `${methodName} getting f2fDocuments from cache, key=${key}`);
            return this.getObjectFromCache(key);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting f2fDocuments from server, key=${key}`);
            const response = await F2FService.instance.getF2FDocuments(courseId, courseItemId, classId, itemContext, parentTrainingPlanId);
            if(isSuccess<F2FDocumentList>(response))
            {
                this.saveObjectToCache(key, response);
                return this.getObjectFromCache(key);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get F2FDocument from server, key=${key}. Exception: ${response.message}`);
                return response;
            }
        }
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}