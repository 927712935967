import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Status information after sso authentication process over GT SSO ServiceProvider
 *
 * @export
 * @class SsoAuthenticationStatus
 */
@JsonObject
export class SsoAuthenticationStatus {

    @JsonProperty('jwtSecurityToken', String, false)
    public jwtSecurityToken: string = '';

    @JsonProperty('refreshToken', String, false)
    public refreshToken: string = '';

    @JsonProperty('providerId', String, false)
    public providerId: string = '';

    @JsonProperty('isIdPAuthenticated', Boolean, false)
    public isIdPAuthenticated: boolean = false;

    @JsonProperty('statusString', String, false)
    public statusString: string = '';

    @JsonProperty('lastErrorMessage', String, false)
    public lastErrorMessage: string = '';

    @JsonProperty('srvTime', String, false)
    public srvTime: string = '';
}