import React, { useMemo } from "react";
import { Heading } from "$components/shared/Heading";
import Session from "$src/core/Session";
import ItemIconAndStatus from "$src/components/shared/itemBox/ItemIconAndStatus";
import { NavLink } from "react-router-dom";
import { TrainingPlanElement } from "$src/storage/models/TrainingPlan/TrainingPlanElement";
import { TrainingPlan } from "$src/storage/models/TrainingPlan/TrainingPlan";
import { ToolbarStatus } from "$components/item/Toolbar/ToolbarStatus";
import ToolbarTrainingPlanElementDurationV2 from "$components/item/Toolbar/ToolbarTrainingPlanElementDurationV2";
import TranslationStorage from "$src/storage/TranslationStorage";

/**
 * The GT Item Summary for Detail Props
 */
export interface IItemSummaryTrainingPlanItemV2Props {

    /**
     * Component unique id for the key of the dom element
     */
    id: string;

    /**
     * Heading Level of parent component
     */
    parentHeadingLevel: number;

    /**
     *  Details of the item like title, description
     */
    trainingPlanElement: TrainingPlanElement;

    trainingPlan: TrainingPlan;

    navLink: string;

    translationStorage: TranslationStorage;
}

interface ItemHeadingProps { parentHeadingLevel: number; title: string }



export default function ItemSummaryTrainingPlanItemV2(props: IItemSummaryTrainingPlanItemV2Props) {

    const allowSelection = useMemo(() => props.trainingPlanElement.allowSelection(props.trainingPlan), [props.trainingPlan, props.trainingPlanElement]);
    const summary = props.trainingPlanElement.summary != null ? props.trainingPlanElement.summary.Ellipsis(globalConfig.itemProperties.maxSummaryLengthInItemList) : '';
    const ItemHeading = ({ parentHeadingLevel, title }: ItemHeadingProps) => {
        return <><Heading cssClass="heading__Level4" headingLevel={parentHeadingLevel + 1} aria-label="">
            <p>{title}</p>
        </Heading>
        </>
    }

    return (<>
        <div className="item-summary__tp-detail__summary-icon">
            {allowSelection ? <NavLink to={props.navLink}
                aria-label={Session.instance.storage.translation.GetString("ItemSummary:LinkAriaLabel").replace("{title}", props.trainingPlanElement.title)}>
                <ItemIconAndStatus
                    id={props.id}
                    itemType={props.trainingPlanElement.itemType}
                    itemSubType={props.trainingPlanElement.itemSubType}
                    itemStatus={props.trainingPlanElement.lessonStatus}
                    itemTitle={props.trainingPlanElement.title}
                    itemAttributes={[]}
                    itemIcon={props.trainingPlanElement.icon}
                    hideItemTypeText={true} />
            </NavLink> : <ItemIconAndStatus
                id={props.id}
                itemType={props.trainingPlanElement.itemType}
                itemSubType={props.trainingPlanElement.itemSubType}
                itemStatus={props.trainingPlanElement.lessonStatus}
                itemTitle={props.trainingPlanElement.title}
                itemAttributes={[]}
                itemIcon={props.trainingPlanElement.icon}
                hideItemTypeText={true} />}

        </div>
        <div className="item-summary__tp-detail__detail-container">
            <div className="item-summary__tp-detail__title-container">
                {allowSelection && <NavLink to={props.navLink}
                    aria-label={Session.instance.storage.translation.GetString("ItemSummary:LinkAriaLabel").replace("{title}", props.trainingPlanElement.title)}>
                    <ItemHeading title={props.trainingPlanElement.title} parentHeadingLevel={props.parentHeadingLevel} />
                </NavLink>}
                {!allowSelection && <ItemHeading title={props.trainingPlanElement.title} parentHeadingLevel={props.parentHeadingLevel} />}
                {props.trainingPlanElement.isLocked &&
                    <ToolbarStatus
                        reason={(props.trainingPlanElement.lockedReason != null && props.trainingPlanElement.lockedReason !== '' ? 'TrainingPlan:' + props.trainingPlanElement.lockedReason : '')}
                        reasonDetail={(props.trainingPlanElement.lockedReasonDetail != null && props.trainingPlanElement.lockedReasonDetail !== '' ? props.trainingPlanElement.lockedReasonDetail : '')}
                        isLocked={props.trainingPlanElement.isLocked || !props.trainingPlanElement.allowSelection(props.trainingPlan)}
                        item={props.trainingPlanElement} textVisible={false}
                        id={props.trainingPlanElement.tpeId}
                        renderAsIconAndText={true} />
                }
                <ToolbarTrainingPlanElementDurationV2
                    trainingPlanElement={props.trainingPlanElement}
                    translationStorage={props.translationStorage} />
                <div className="spacer">&nbsp;</div>
            </div>
            <div className="item-description">
                <span dangerouslySetInnerHTML={{ __html: summary }} />
            </div>
        </div>
    </>
    );
}