import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class TrainingPlanCancelRequest {

    @JsonProperty('langCode', String, false)
    public langCode: string = '';

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('cancellationReasonId', Number, false)
    public cancellationReasonId: number = 0;

    @JsonProperty('cancellationReasonText', String, false)
    public cancellationReasonText: string = '';

    @JsonProperty('userIdToCancel', Number, false)
    public userIdToCancel: number = 0;
}