import Session from "$src/core/Session";
import { ESortDirection } from "$src/storage/models/enums";
import SortingAlgorithm from "$src/util/SortingAlgorithm";
import React, { useState } from "react";
import GTHorizontalTableHeaderRow, { GTHeaderContent } from "$src/components/shared/Atoms/GTHorizontalTableHeaderRow";
import GTHorizontalTable from "$src/components/shared/Molecules/GTHorizontalTable";
import { SortableHeaderElement } from "$src/components/shared/SortableHeaderElement";
import { Translate } from "$src/components/shared/Translate";
import { NoDataFound } from "$src/components/shared/WarningsAndErrors/NoDataFound";
import PerformanceCheckItem, { PerformanceCheckTableItem } from "$src/components/Skills/PerformanceCheckItem";
import { PerformanceCheckModel } from "$src/components/Skills/PerformanceCheckModel";

interface IProps {
    emplyoeeId?: number;
    isBoss: boolean;
    skills: PerformanceCheckTableItem[];
    reloadPerformanceCheckTargetSkills?: () => void;
    parentHeadingLevel: number;
}

interface ISort {
    id: string;
    direction: ESortDirection;
}

/*
Author Bryan Gerber
Render the Table for PerformanceCheck
*/
export default function PerformanceCheckTable(props: IProps) {
    const [editedSkillKey, setEditedSkillKey] = useState<string>('');
    const [activeHeader, setActiveHeader] = useState<string>('skillTitle');
    const [sort, setSort] = useState<ISort>({ id: 'skillTitle', direction: ESortDirection.Up });
    const targetSkillModel = new PerformanceCheckModel;
    const [isBoss] = useState<boolean>(props.isBoss);


    function isActiveHeader(id: string) {
        return id === activeHeader;
    }

    function onSortDirectionChanged(id: string, direction: ESortDirection) {
        setActiveHeader(id);
        setSort({
            id, direction
        });
    }

    function sortSkills(id: string, direction: ESortDirection) {
        props.skills.sort((a: PerformanceCheckTableItem, b: PerformanceCheckTableItem): number => {
            return SortingAlgorithm.sortByColumnAndDirection(a, b, id, direction);
        })
    }

    function RenderTableHeader() {
        const headerElements: GTHeaderContent[] = [];
        headerElements.push({

            cellId: 'skillType',
            cellElement: <SortableHeaderElement
                id={'skillType'}
                isActive={isActiveHeader('skillType')}
                header='PerformanceCheck:SkillType'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />,
            cellClassName: 'small-cell'
        });

        headerElements.push({

            cellId: 'skillTitle',
            cellElement: <SortableHeaderElement
                id={'skillTitle'}
                isActive={isActiveHeader('skillTitle')}
                header='Skill:SkillName'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'skillRequiredLevel',
            cellElement: <SortableHeaderElement
                id={'skillRequiredLevel'}
                isActive={isActiveHeader('skillRequiredLevel')}
                header='Skill:SkillRequiredLevel'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'requestDate',
            cellElement: <SortableHeaderElement
                id={'requestDate'}
                isActive={isActiveHeader('requestDate')}
                header='PerformanceCheck:RequestDate'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'approvalDate',
            cellElement: <SortableHeaderElement
                id={'approvalDate'}
                isActive={isActiveHeader('approvalDate')}
                header='PerformanceCheck:ApprovalDate'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'UserTargetSkillStatus',
            cellElement: <Translate>Skill:SkillValid</Translate>,
            cellClassName: 'not-sortable-header small-cell',
        });

        if (isBoss) {
            headerElements.push({
                cellId: 'ActionButton',
                cellElement: <Translate>PerformanceCheck:ActionButton</Translate>,
                cellClassName: 'not-sortable-header small-cell'
            });
        }


        return <GTHorizontalTableHeaderRow
            headerElements={headerElements}
            tableRowClassName="skill__table__row not mobile row__header" />
    }


    function RenderRows(skills: PerformanceCheckTableItem[]) {
        const rowElements: JSX.Element[] = [];
        skills.map((skill, index) => {
            rowElements.push(
                <PerformanceCheckItem
                    emplyoeeId={props.emplyoeeId}
                    isBoss={props.isBoss}
                    skill={skill}
                    key={skill.skillUniqueKey}
                    isActiveHeader={isActiveHeader(targetSkillModel.columnDictionary[index])}
                    onActiveHeaderChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                    reloadPerformanceCheckTargetSkills={() => {props.reloadPerformanceCheckTargetSkills && props.reloadPerformanceCheckTargetSkills()}}
                    parentHeadingLevel={props.parentHeadingLevel}
                    inEditMode={skill.skillUniqueKey=== editedSkillKey}
                    onEditModeChanged={(inEditMode) => inEditMode ? setEditedSkillKey(skill.skillUniqueKey) : setEditedSkillKey('')}
                />
            )
        })
        return rowElements;
    }

    return (
        <>
            {sortSkills(sort.id, sort.direction)}
            {props.skills.length !== 0 ? <GTHorizontalTable tableClassName="skill__table"
                headerElement={RenderTableHeader()}
                rows={RenderRows(props.skills)}
            /> : <NoDataFound message={Session.instance.storage.translation.GetString("PerformanceCheck:NoDataFound")} />}

        </>
    )
}