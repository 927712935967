import React from 'react';

import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router';

import Logger from '$src/core/Logger';

import { PasswordReset } from '$components/login/PasswordReset';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { ColorSchemeSelection } from '$src/components/colorSchemeSelection/ColorSchemeSelection';
import { SsoButtonContainer } from '$src/components/login/SsoButtonContainer';
import { CheckBox } from '$src/components/shared/CheckBox';
import { InputAttributes, ISetFocus } from '$src/components/shared/InputAttributes';
import { InputPassword } from '$src/components/shared/InputPassword';
import { InputText } from '$src/components/shared/InputText';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { OpenDialogLink } from '$src/components/shared/OpenDialogLink';
import { Translate } from '$src/components/shared/Translate';
import ConfigService from '$src/core/Services/ConfigService';
import ItemService from '$src/core/Services/ItemService';
import UserPreferencesService from '$src/core/Services/UserPreferencesService';
import Session from '$src/core/Session';
import { GuestUserCredentials } from '$src/storage/models/GuestUserCredentials';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { File } from '$storage/models/File';
import SsoServiceProviderService from '$src/core/Services/SsoServiceProviderService';
import GTButton from '$components/shared/Atoms/GTButton';
import LicenseRegPanel from '$components/Login/LicenseRegPanel';
import { Group } from '$src/storage/models/Group';

interface IProps extends RouteComponentProps {
    username: string;
    password: string;
    redirectPage: string;
}

interface IState {
    username: string;
    password: string;
    focusTo: '' | 'username' | 'password' | 'cbStySignedIn';  // Id of the TextInput getting the input focus on next render.
    showErrorDialog: boolean;
    isLoginOk: boolean;
    isColorSchemeDefined: boolean;
    isPasswordResetPopupVisible: boolean;
    passwordResetPopupTitle: string;
    errorMessage: string;
    staySignedIn: boolean;
    loginInfoDocument?: File;
    isLoggingIn: boolean;
    isDisableInput: boolean;
    isAutoSsoStopped: boolean;
    showLoginForSso: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
@observer
export class FullScreenLogin extends React.Component<IProps, IState> {

    protected ref: ISetFocus;
    protected className = 'FullScreenLogin';
    protected loggerLocality = 'Components.FullScreenLogin';
    protected loginInfoRef: HTMLIFrameElement | null
    private readonly localStorageKeyForSavedUsername = 'savedUsername';
    private _isMounted = false;

    constructor(props: IProps) {
        super(props);

        this.ref = { idx: 1, innerRef: React.createRef() }
        const savedUsername = localStorage.getItem(this.localStorageKeyForSavedUsername);

        this.state = {
            errorMessage: '',
            focusTo: 'username',
            isAutoSsoStopped: false,
            isColorSchemeDefined: false,
            isDisableInput: false,
            isLoggingIn: false,
            isLoginOk: false,
            isPasswordResetPopupVisible: false,
            loginInfoDocument: undefined,
            password: '',
            passwordResetPopupTitle: 'Login:PWResetTitle',
            showErrorDialog: false,
            staySignedIn: false,
            username: savedUsername ? savedUsername : '',
            showLoginForSso: false,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleLoginInfoDocHeightMessage = this.handleLoginInfoDocHeightMessage.bind(this);

        SsoServiceProviderService.instance.onSsoRedirectionStarted = () => {
            this.setState({ isDisableInput: true });
        }
    }

    public async UNSAFE_componentWillMount() {
        // Load login info document
        const tmp = await ItemService.instance.getLoginInfoDocument();
        if (this._isMounted) {
            if (isSuccess<File>(tmp)) {
                this.setState({ loginInfoDocument: tmp });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:FilesForDocumentFailed' });
            }
        }
    }

    public render() {
        // IF the users has successfully completed the login action and the Color Scheme is known
        if (this.state.isLoginOk && (this.state.isColorSchemeDefined || globalConfig.colorSchemes.colorSchemeDefinitions.length === 0)) {
            let redirectLink = '/';
            if (this.props.redirectPage === '' || this.props.redirectPage.includes('logout')) {
                redirectLink = '/';
            } else if (globalConfig.appProperties.appPathOverride != null && this.props.redirectPage != null) {
                redirectLink = this.props.redirectPage.toLocaleLowerCase().replace(globalConfig.appProperties.appPathOverride.toLocaleLowerCase(), '');
            }
            if (!redirectLink.startsWith('/')) {
                redirectLink = `/${redirectLink}`;
            }

            if (Session.instance.loginUser && globalConfig.loginProperties.alternativeLoginPage?.pageId.length > 0 && globalConfig.loginProperties.alternativeLoginPage.groups.length > 0) {
                let forAll = false;
                let result: Group | undefined = undefined;
                if (globalConfig.loginProperties.alternativeLoginPage.groups.includes('*')) {
                    forAll = true;
                } else {
                    result = Session.instance.loginUser.groups.find(group =>
                        globalConfig.loginProperties.alternativeLoginPage.groups.includes(group.groupName) == true
                    );
                }

                if (result || forAll) {
                    const pageId = globalConfig.loginProperties.alternativeLoginPage.pageId;
                    if (redirectLink.endsWith('/')) {
                        if (pageId.startsWith('/')) {
                            redirectLink = `${redirectLink}${pageId.substring(1)}`;
                        } else {
                            redirectLink = `${redirectLink}${pageId}`;
                        }
                    } else {
                        if (pageId.startsWith('/')) {
                            redirectLink = `${redirectLink}${pageId}`;
                        } else {
                            redirectLink = `${redirectLink}/${pageId}`;
                        }
                    }
                }
            }

            return <Redirect push={true} to={redirectLink} />;
        } else {
            return (
                <div className="login__background">

                    <div className="l-container login__container--flexDirection">
                        {this.renderAdditionalText()}
                        {!this.state.isLoginOk ? (this.renderLoginForm()) : this.renderColorSchemeSelection()}
                    </div>
                    <LicenseRegPanel parentHeadingLevel={0} {...this.props} />
                    <div className="l-container login__info">
                        {this.renderLoginInfo()}
                    </div>
                </div>
            );
        }
    }

    public async componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('Login:Login');
        window.addEventListener('message', this.handleLoginInfoDocHeightMessage, false);
        this._isMounted = true;
        if (globalConfig.loginProperties.enableSSO && !Session.instance.getHasLoggedOut) {
            const response = await Session.instance.sso();
            if (isSuccess<boolean>(response)) {
                if (response) {
                    // Scheme
                    this.setColorSchemeAfterLogin(response);
                }
            }
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('message', this.handleLoginInfoDocHeightMessage);
        this._isMounted = false;
    }

    public onStaySignedInChanged() {
        this.setState({ staySignedIn: !this.state.staySignedIn, focusTo: 'cbStySignedIn' });
    }

    /**
     * Pass event to parent and redirect to SelfRegistration
     */
    public onSelfRegisterClick(): void {
        this.props.history.push('/selfRegistration');
    }

    /**
     * Remove the SSO Logout sessionStorage and login with SSO
     */
    public onSSOLoginClick() {
        Session.instance.setHasLoggedOut(false);
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    }

    /**
     * Get the credentials of the guest account from the WebAPI and do the login action with it.
     */
    public async onGuestLoginClick() {
        if (this._isMounted) {
            this.setState({ isLoggingIn: true });
        }
        const guestCredentials: GuestUserCredentials | GtError = await ConfigService.instance.getGuestUserCredentials(Session.instance.getUserLanguageCodeOrFallBack);
        if (isSuccess<GuestUserCredentials>(guestCredentials)) {
            const loginOk = await Session.instance.login(guestCredentials.domain, guestCredentials.username, guestCredentials.password, false);
            if (isSuccess<boolean>(loginOk)) {
                if (loginOk) {
                    this.setColorSchemeAfterLogin(loginOk);
                } else {
                    if (this._isMounted) {
                        this.setState({
                            errorMessage: 'ErrorMessage:GuestLoginFailed',
                            isLoginOk: false
                        });
                    }
                }
            }
        } else {
            if (this._isMounted) {
                if (Session.instance.lastErrorMessage.length > 0 && !Session.instance.lastErrorMessage.toString().includes('403')) {
                    this.setState({
                        errorMessage: Session.instance.lastErrorMessage,
                        isLoginOk: false
                    });
                } else {
                    this.setState({
                        errorMessage: 'ErrorMessage:GuestLoginFailed',
                        isLoginOk: false
                    });
                }
            }
        }
        if (this._isMounted) {
            this.setState({ isLoggingIn: false });
        }
    }

    // set login info iframe size (+ 4 for ie)
    public setLoginInfoHeight(height: number) {
        if (this.loginInfoRef != null) {
            this.loginInfoRef.style.height = (height + 4).toString() + 'px'
        }
    }

    // iframed login info sends its height using postMessage
    public handleLoginInfoDocHeightMessage(e: any) {
        if (e.data != null && e.data.toString().indexOf('docHeight') >= 0) {
            const data = JSON.parse(e.data);
            // tslint:disable-next-line:no-string-literal
            if (data['docHeight']) {
                this.setLoginInfoHeight(data.docHeight);
            }
        }
    }

    protected renderLoginForm(): JSX.Element {

        const userAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'username',
            label: 'Login:UserName',
            value: this.state.username,
            class: 'box__login',
            isReadOnly: false || this.state.isDisableInput,
            isRequired: true,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'username' || (this.state.errorMessage != null && this.state.errorMessage.length > 0),
            placeholder: '',
            editMode: true,
            autoComplete: 'username'
        };

        const pwdAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'password',
            label: 'Login:Password',
            value: this.state.password,
            class: 'box__login',
            isReadOnly: false || this.state.isDisableInput,
            isRequired: true,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'password',
            placeholder: '',
            editMode: true,
            setFocus: this.ref,
            autoComplete: 'current-password'
        };
        if (this.state.isLoggingIn) {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">
                        <div className="login__spinner">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        } else if (globalConfig.loginProperties.disableDefaultLogin && !this.state.showLoginForSso) {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">
                        <div className="box__login login__LoginPanel">
                            {this.renderSsoOptions()}
                        </div>
                        <form className="box__login">
                            {this.state.errorMessage != null && this.state.errorMessage.length > 0 &&
                                <div className="box__login__error">
                                    <span className={'input-message error'}>
                                        <Translate>{this.state.errorMessage}</Translate>
                                    </span>
                                </div>}
                            {globalConfig.loginProperties.isStaySignedInEnabled &&
                                <div className="box__login login__StaySignedIn">
                                    {this.renderOptionStaySignedId()}
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">

                        {!globalConfig.loginProperties.disableDefaultLogin || (this.state.showLoginForSso && !globalConfig.loginProperties.disableDefaultLoginSso) ? <form className="box__login login__default">
                            {this.state.errorMessage != null && this.state.errorMessage.length > 0 &&
                                <div className="box__login__error">
                                    <span className={'input-message error'}>
                                        <Translate>{this.state.errorMessage}</Translate>
                                    </span>
                                </div>}
                            <InputText
                                attributes={userAttributes}
                                onChange={(id, text) => this.onTextChange(id, text)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                                hasError={this.state.errorMessage != null && this.state.errorMessage.length > 0}
                                errorMsg={this.state.errorMessage}
                            />
                            <InputPassword
                                attributes={pwdAttributes}
                                onChange={(id, text) => this.onTextChange(id, text)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                        </form> : <></>}

                        {globalConfig.loginProperties.isStaySignedInEnabled &&
                            <div className="box__login login__StaySignedIn">
                                {this.renderOptionStaySignedId()}
                            </div>
                        }

                        {(globalConfig.loginProperties.isSelfRegistrationEnabled || globalConfig.loginProperties.isLoginAsGuestEnabled) &&
                            <div className="box__login login__NotRegisteredUsers">
                                {this.renderOptionsForNotRegisteredUsers()}
                            </div>
                        }

                        {(globalConfig.loginProperties.enableSSO || globalConfig.loginProperties.isResetPasswordEnabled) &&
                            <div className="box__login login__SSOandPWReset">
                                {this.renderOptionLoginWithSmartcard()}
                                {this.renderOptionResetPassword()}
                            </div>
                        }


                        <div className="box__login login__LoginPanel">
                            {(!globalConfig.loginProperties.disableDefaultLogin || this.state.showLoginForSso) && <GTButton
                                onClick={() => this.login()}
                                defaultButtonProps={{ disabled: !this.arePasswordFormInputsFieldsNotEmpty() || this.state.isDisableInput }}
                                id="btnManualLoginButton" >
                                <Translate>Login:Login</Translate>
                            </GTButton>}
                            {this.renderSsoOptions()}
                        </div>

                    </div>
                </div>
                {globalConfig.loginProperties.isResetPasswordEnabled && <ModalPopup isOpen={this.state.isPasswordResetPopupVisible}
                    onRequestClose={() => this.closePasswordResetPopup()}>
                    <PasswordReset onResetClick={() => this.onPasswordReset()} />
                </ModalPopup>
                }
            </React.Fragment>
        }

    }

    protected renderLoginInfo(): JSX.Element {
        return this.state.loginInfoDocument != null ?
            <iframe
                src={this.state.loginInfoDocument.url}
                ref={e => {
                    this.loginInfoRef = e;
                }}
            /> :
            <React.Fragment />
    }

    protected renderColorSchemeSelection(): JSX.Element {
        return <div className="login__scheme">
            <ColorSchemeSelection
                {...this.props}
                colorSchemes={globalConfig.colorSchemes.colorSchemeDefinitions}
                currentScheme={Session.instance.getLoginUserPreferencesColorScheme()}
                onColorSchemeSelected={() => this.onColorSchemeSelected()}
                userPreferencesService={UserPreferencesService.instance}
                isPopup={true}
                parentHeadingLevel={0}
                headingCssClassName="heading__Title"
                schemeHeadingCssName="heading_Level2" />
        </div>
    }

    protected renderOptionStaySignedId(): JSX.Element | null {
        return (
            <React.Fragment>
                <CheckBox id="cbStySignedIn" onClick={() => this.onStaySignedInChanged()} />
                <label id="lblStaySignedIn" htmlFor="cbStySignedIn"><Translate>Login:StaySignedIn</Translate></label>
            </React.Fragment>
        );
    }

    protected renderOptionLoginWithSmartcard(): JSX.Element | null {
        if (globalConfig.loginProperties.enableSSO) {
            return (
                <React.Fragment>
                    <GTButton
                        additionalClassNames="button-link button-link--colorized"
                        onClick={() => this.onSSOLoginClick()}>
                        <Translate>Login:SSOLogin</Translate>
                    </GTButton>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    protected renderOptionResetPassword(): JSX.Element | null {
        if (globalConfig.loginProperties.isResetPasswordEnabled) {
            return (
                <React.Fragment>
                    <OpenDialogLink
                        ariaPopupvalue={"dialog"}
                        id="lblPasswordForgotten"
                        title="Login:PWResetTitle"
                        openDialog={() => this.openPasswordResetPopup()} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    protected renderOptionsForNotRegisteredUsers(): JSX.Element[] {
        const selfReg = globalConfig.loginProperties.isSelfRegistrationEnabled;
        const guestLogin = globalConfig.loginProperties.isLoginAsGuestEnabled;
        let keyCounter: number = 0;
        const el: JSX.Element[] = [];
        if (selfReg || guestLogin) {
            el.push(<Translate key={keyCounter++}>Login:NotRegistered</Translate>);
            el.push(<br key={keyCounter++} />);
            if (selfReg) {
                el.push(
                    <GTButton
                        key={keyCounter++}
                        additionalClassNames="button-link button-link--colorized"
                        onClick={() => this.onSelfRegisterClick()}>
                        <Translate>Login:SelfRegister</Translate>
                    </GTButton>
                );
            }
            if (selfReg && guestLogin) {
                el.push(<Translate key={keyCounter++}>Login:Or</Translate>)
            }
            if (guestLogin) {
                el.push(
                    <GTButton
                        key={keyCounter++}
                        additionalClassNames="button-link button-link--colorized"
                        onClick={() => this.onGuestLoginClick()}
                        id="btnGuestLogin">
                        <Translate key={keyCounter++}>Login:GuestLogin</Translate>
                    </GTButton>
                );
            }
        }
        return el;
    }

    /**
     * Renders sso buttons
     * @protected
     * @returns {(JSX.Element | null)}
     * @memberof FullScreenLogin
     */
    protected renderSsoOptions(): JSX.Element | null {
        if (!SsoServiceProviderService.instance.isAnySsoEnabled() || this.state.showLoginForSso) {
            return null; // no sso
        }

        const props = {
            match: this.props.match,
            history: this.props.history,
            location: this.props.location
        };

        return (
            <SsoButtonContainer
                {...props}
                stopAutoSso={this.state.isAutoSsoStopped}
                onLoggedIn={() => this.setColorSchemeAfterLogin(true)}
                onDoShowLoginForSso={() => this.setState({ showLoginForSso: true })} />
        );
    }

    /**
     * Callback for TextInput component: text has changed.
     * @param id Id of the TextInput triggering this callback.
     * @param text Current text from TextInput.
     */
    protected onTextChange(id: string, text: string) {
        switch (id) {
            case 'username':
                if (this._isMounted) {
                    this.setState({ username: text });
                }
                break;
            case 'password':
                if (this._isMounted) {
                    this.setState({ password: text });
                }
                break;
        }
        this.setState({ focusTo: '', isAutoSsoStopped: true });
        SsoServiceProviderService.instance.setStopAutoSso(true);
    }

    protected handleClick = () => {
        if (this.ref !== undefined) {
            if (this.ref.innerRef !== undefined && this.ref.innerRef.current !== null) {
                this.ref.innerRef.current.focus()
            }
        }
    };

    /**
     * Callback for TextInput component: user has entered a Carriage Return ('Enter' key).
     * @param id Id of the TextInput triggering this callback.
     */
    protected onCrEntered(id: string) {
        if (id === 'username') {
            if (this._isMounted) {
                this.setState({ focusTo: 'password' })
            }
        } else if (id === 'password') {
            this.login();
        }
        this.setState({ isAutoSsoStopped: true });
        SsoServiceProviderService.instance.setStopAutoSso(true);
    }

    protected onColorSchemeSelected() {
        if (this._isMounted) {
            this.setState({ isColorSchemeDefined: true });
        }
    }

    /**
     * Callback for Login button.
     */
    private async login() {
        if (this._isMounted) {
            this.setState({ isLoggingIn: true });
        }
        const methodName = 'login()';
        let domain = '';
        let username = this.state.username;
        const posSlash = username.indexOf('\\');
        if (posSlash >= 0) {
            domain = username.substr(0, posSlash);
            username = username.substr(posSlash + 1);
        }
        if (posSlash === -1 && globalConfig.loginProperties && globalConfig.loginProperties.defaultDomain && domain === '') {
            domain = globalConfig.loginProperties.defaultDomain;
        }
        Logger.log(this.loggerLocality, `${methodName} : login in user ${domain}\\${username}`);
        const loginOk = await Session.instance.login(domain, username, this.state.password, this.state.staySignedIn);
        if (isSuccess<boolean>(loginOk)) {
            if (loginOk) {
                localStorage.setItem(this.localStorageKeyForSavedUsername, this.state.username);
                // Scheme
                this.setColorSchemeAfterLogin(loginOk);
            } else {
                if (this._isMounted) {
                    this.setState({ errorMessage: 'ErrorMessage:LoginFailed' });
                }
                this.handleClick();
                localStorage.removeItem(this.localStorageKeyForSavedUsername);
            }
        } else {
            if (loginOk != null && loginOk.detailedObject != null) {
                if (this._isMounted) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(loginOk.detailedObject.subStatusCode) });
                }
            } else {
                if (this._isMounted) {
                    this.setState({ errorMessage: 'ErrorMessage:LoginFailed' });
                }
            }
            this.handleClick();
            localStorage.removeItem(this.localStorageKeyForSavedUsername);
        }
        if (this._isMounted) {
            this.setState({ isLoggingIn: false });
        }
    }

    private closePasswordResetPopup(): void {
        if (this._isMounted) {
            this.setState({ isPasswordResetPopupVisible: false, passwordResetPopupTitle: 'Login:PWResetTitle' }); // Replace with actual Titel translation
        }
    }
    private openPasswordResetPopup(): void {
        if (this._isMounted) {
            this.setState({ isPasswordResetPopupVisible: true, passwordResetPopupTitle: 'Login:PWResetTitle' }); // Replace with actual Titel translation
        }
    }

    private onPasswordReset() {
        this.closePasswordResetPopup();
    }
    /**
     * Check if both fields (username and password) are not empty
     */
    private arePasswordFormInputsFieldsNotEmpty(): boolean {
        let isValid: boolean = true;
        if (this.state.username === '' || this.state.password === '') {
            isValid = false;
        }
        return isValid;
    }

    /**
     * Set the color scheme after login
     */
    private setColorSchemeAfterLogin(loginOk: boolean) {
        // Scheme
        const userColorScheme = Session.instance.getLoginUserPreferencesColorScheme();
        if (userColorScheme != null) {
            Session.instance.applyColorScheme(userColorScheme);
            if (this._isMounted) {
                this.setState({ isLoginOk: loginOk, focusTo: '', isColorSchemeDefined: true });
            }
        } else {
            if (this._isMounted) {
                this.setState({ isLoginOk: loginOk, focusTo: '', isColorSchemeDefined: false });
            }
        }
    }

    protected renderAdditionalText(): JSX.Element {
        let AdditionalText1 = Session.instance.storage.translation.GetString('Login:AdditionalText1');
        let AdditionalText2 = Session.instance.storage.translation.GetString('Login:AdditionalText2');
        if (AdditionalText1.length <= 1) {
            AdditionalText1 = '';
        }
        if (AdditionalText2.length <= 1) {
            AdditionalText2 = '';
        }
        return (
            <div className="login_AdditionalText">
                {
                    AdditionalText1.length > 1 || AdditionalText2.length > 1 ?
                        <>
                            <span dangerouslySetInnerHTML={{ __html: AdditionalText1 }} /> <span dangerouslySetInnerHTML={{ __html: AdditionalText2 }} />
                        </> : null
                }
            </div>
        );

    }
}

