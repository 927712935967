import React, { useState, useEffect } from 'react';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';

import { Translate } from '$components/shared/Translate';

import { PaymentClassSearchFilter } from '$src/storage/models/Payment/PaymentClassSearchFilter';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { PaymentClassSearchResponse } from '$src/storage/models/Payment/PaymentClassSearchResponse';
import { isSuccess } from '$src/util/Result';
import Session from '$src/core/Session';

interface IClassSearchSearchBoxProps {
	filter: PaymentClassSearchFilter;
	onClassSelected: (selectedClass: PaymentClassSearchResponse) => void;
	/** Start search on component bound and ignore min input length */
	searchImmediately?: boolean;
    /** For the MyTeam Boss View. Restricts the class selection to courses where the current boss user has the publish right */
	bossRelationCode?: string;
}

const MININPUTLENGH = 3;
export const DefaultFilter: PaymentClassSearchFilter = {
	classSearchText: '',
	dateFrom: null,
	dateTo: null,
	onlyFuture: true,
	onlyWithFreeSeats: true,
	itemId: undefined
};

/**
 * Component for payment admins search for a class to book
 * This is only the auto search input box
 * @param props RouteComponentProps
 * @returns Hook - complete component
 */
export default function ClassSearchSearchBox(props: IClassSearchSearchBoxProps) {
	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState<PaymentClassSearchResponse[]>([]);
	// must encapsulate filter.classSearchText because kendo resets the filter object every time....
	const [classSearchText, setClassSearchText] = useState<string>(DefaultFilter.classSearchText);

	const dropdownString = Session.instance.storage.translation.GetString('PaymentBooking:ClassSearchDropDownClassname');

	/** reload combobox */
	useEffect(() => {
		props.filter.classSearchText = classSearchText; // no need to setFilter() because classSearchText needed to be encapusalted in its own state
		if ((props.filter.classSearchText && props.filter.classSearchText.length >= MININPUTLENGH) || props.searchImmediately) {
			setLoading(true);
			const filter: PaymentClassSearchFilter = (props.filter);
			if (props.filter.dateTo != null) {
				const tmpDate = props.filter.dateTo;
				tmpDate.setUTCHours(23);
				tmpDate.setUTCMinutes(59);
				tmpDate.setUTCSeconds(59);
				filter.dateTo = tmpDate;
			}
	
			PaymentAdminService.instance.getClassSearch(filter, props.bossRelationCode).then((res) => {
				if (isSuccess(res)) {
					setSearchResults(res as PaymentClassSearchResponse[]);
					setLoading(false);
				}
			});
		}
		else if (!props.filter.classSearchText || props.filter.classSearchText.length < MININPUTLENGH) {
			setSearchResults([]);
		}
	}, [props.filter, classSearchText]);

	/** When one item of the search results is rendered to kendo combo box */
	const classSearchComboBoxItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const result = itemProps.dataItem as PaymentClassSearchResponse;
		const text = (
			<span>
				{result.replacePlaceholders(dropdownString)}
			</span>
		);

		return React.cloneElement(li, li.props, text);
	};

	/** No data found or input length to short */
	const classSearchComboBoxNoData = (element: React.ReactElement<HTMLDivElement>) => {
		const length = props.filter.classSearchText?.length || 0;

		const noData = (
			<span>
				{length < MININPUTLENGH && <Translate>PaymentBooking:MinimumInputLengthValidator</Translate>}
				{length >= MININPUTLENGH && <Translate>PaymentBooking:NoData</Translate>}
			</span>
		);

		return React.cloneElement(element, { ...element.props }, noData);
	};

	return (
		<>
			<ComboBox
				loading={loading}
				style={{ width: '100%' }}
				filterable={true}
				onFilterChange={(e) => setClassSearchText(e.filter.value)}
				itemRender={classSearchComboBoxItemRender}
				listNoDataRender={classSearchComboBoxNoData}
				onChange={(e) => props.onClassSelected(e.value)}
				value={classSearchText}
				data={searchResults}
			/>
		</>
	);
}