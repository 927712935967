import Logger from '$src/core/Logger';
import XCache from '$src/storage/cache/Cache';
import CachedObject from '$src/storage/cache/CachedObject';

// Use this Cache if you want to save objects into the Local Cache

export default class LocalCache<TKey, TObject> extends XCache<TKey, TObject> {
    protected className = 'LocalCache';
    protected loggerLocality = 'Storage.LocalCache';

    private _storage: Storage;
    private _storagePrefix: string;

    constructor(cachingDuration: number = 3600,
                storage: Storage,
                storagePrefix: string,
                autoRemoveExpiredData?: boolean,
                isLanguageDependant?: boolean) {
        super(cachingDuration, autoRemoveExpiredData, isLanguageDependant);
        this._storage = storage;
        this._storagePrefix = storagePrefix;
        Logger.log(this.loggerLocality, 
        `Create LocalCache Object, storagePrefix = ${storagePrefix}`);
    }

    public isCacheExpired(): boolean {
        return false;
    }


    protected getCachedObjectFromCache(key: TKey): CachedObject<TObject> | null {
        let retVal: CachedObject<TObject> | null = null;
        let cachedObject: string | null = null;
        cachedObject = this._storage.getItem(this._storagePrefix + JSON.stringify(key));
        
        if (cachedObject != null) {
            retVal = Object.create(CachedObject.prototype)
            Object.assign(retVal, JSON.parse(cachedObject))
        }
        return retVal;
    }

    protected getCachedObjectsFromCache(): Array<CachedObject<TObject>> {
        const keys = this.getAllStorageKeys();
        const retVal: Array<CachedObject<TObject>> = [];

        for (const k of keys) {
            const obj = this.getCachedObjectWithOrignalKey(k);
            if (obj != null) {
                retVal.push(obj);
            }
        }
        return retVal;
    }

    protected saveObjectToCache(key: TKey, object: TObject): void {
        const cachedObject = new CachedObject(object);
        this._storage.setItem(this._storagePrefix + JSON.stringify(key), JSON.stringify(cachedObject));
    }

    protected removeObjectFromCache(key: TKey): void {
        this._storage.removeItem(this._storagePrefix + JSON.stringify(key));
    }

    protected getAllKeys(): TKey[] {
        return this.getAllStorageKeys().map(k => JSON.parse(k));
    }

    protected clear(): void {
        this.getAllStorageKeys().map(k => this._storage.removeItem(k));
    }

    private getAllStorageKeys(): string[]  {
        return Object.keys(this._storage).filter(f => f.startsWith(this._storagePrefix));
    }

    private getCachedObjectWithOrignalKey(originalKey: string): CachedObject<TObject> | null {
        let retVal: CachedObject<TObject> | null = null;
        let cachedObject: string | null = null;
        cachedObject = this._storage.getItem(originalKey);
        
        if (cachedObject != null) {
            retVal = Object.create(CachedObject.prototype)
            Object.assign(retVal, JSON.parse(cachedObject))
        }
        return retVal;
    }


}
