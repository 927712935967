import { JsonObject, JsonProperty } from 'json2typescript';



@JsonObject
export class PWResetRequest {

    @JsonProperty('langCode', String, false)
    public langCode: string = '';

    @JsonProperty('username', String, false)
    public username: string = '';
    
    @JsonProperty('domain', String, false)
    public domain: string = '';

    @JsonProperty('mobileOrEmail', String, false)
    public mobileOrEmail: string = '';

    @JsonProperty('email', String, false)
    public email: string = '';

    @JsonProperty('url', String, false)
    public url: string = '';



  
}
