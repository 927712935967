
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { RecoverPasswordResponse } from '$src/storage/models/RecoverPasswordResponse';
import { PWChangeRequest } from '$src/storage/models/RequestObjects/PWChangeRequest';
import { PWResetRequest } from '$src/storage/models/RequestObjects/PWResetRequest';
import { User } from '$src/storage/models/User';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * PasswordService provides all Service Methods for the password
 */
export default class PasswordService extends ServiceClient {
    protected static _instance: PasswordService | null = null;

    protected className = 'PasswordService';
    protected loggerLocality = 'PasswordService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PasswordService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Requests a new password for the user.
     * @param langCode 
     * @param username 
     * @param email 
     * @param mobileOrEmail 
     * @param url 
     * @param domain 
     */
    // tslint:disable-next-line:max-line-length
    public async recoverPassword(langCode: string, username: string, email: string, mobileOrEmail: string, domain: string): Promise<RecoverPasswordResponse | GtError> {
        if (domain === null) {
            domain = '';
        }
        // eslint-disable-next-line @typescript-eslint/camelcase
        const suiName = __webpack_public_path__.endsWith('/') ? __webpack_public_path__.substring(0, __webpack_public_path__.length - 1) : __webpack_public_path__;
        // eslint-disable-next-line @typescript-eslint/camelcase
        const url = window.location.href.substring(0, window.location.href.indexOf(suiName)) + (!__webpack_public_path__.startsWith('/') ? '/' : '') + __webpack_public_path__;
        const obj = new PWResetRequest();
        obj.username = username;
        obj.langCode = langCode;
        obj.email = email;
        obj.mobileOrEmail = mobileOrEmail;
        obj.url = url;
        obj.domain = domain;
        const response = await this.post<RecoverPasswordResponse>('password/recoverPassword', obj, RecoverPasswordResponse, undefined);
        return response;
    }

    /**
     * Saves the password with a deepLink hash to verify the user.
     *
     * @param {string} langCode
     * @param {string} username
     * @param {string} domain
     * @param {string} password
     * @param {string} timestamp
     * @param {string} hash
     * @returns
     * @memberof PasswordService
     */
    public async savePasswordWithDeepLink(langCode: string, username: string, domain: string, password: string, timestamp: string, hash: string) {
        if (domain === null || domain === '') {
            domain = '0';
        }

        const request = new PWChangeRequest();
        request.langCode = langCode;
        request.username = username;
        request.domain = domain;
        request.newPassword = password;
        request.timestamp = timestamp;
        request.hash = hash;
        return await this.post<User>('password/SavePasswordDeepLink', request, User, undefined);
    }

    /**
     * Saves the password with the security recovery pin to verify the user.
     *
     * @param {string} langCode
     * @param {string} username
     * @param {string} pin
     * @param {string} password
     * @param {string} domain
     * @returns {(Promise<BooleanResponse | GtError>)}
     * @memberof PasswordService
     */
    public async savePasswordWithRecoveryPIN(langCode: string, username: string, pin: string, password: string, domain: string, timestamp: string): Promise<BooleanResponse | GtError> {
        if (domain === null || domain === '') {
            domain = '0';
        }
        const obj = new PWChangeRequest();
        obj.langCode = langCode;
        obj.username = username;
        obj.newPassword = password;
        obj.domain = domain;
        obj.pin = pin;
        obj.timestamp = timestamp;
        return await this.post<BooleanResponse>('password/savePasswordWithRecoveryPIN', obj, BooleanResponse, undefined);
    }

    /**
     * Saves the password after a password change.
     *
     * @param {string} langCode
     * @param {string} username
     * @param {string} oldPassword
     * @param {string} newPassword
     * @param {string} domain
     * @returns {(Promise<BooleanResponse | GtError>)}
     * @memberof PasswordService
     */
    public async savePassword(langCode: string, username: string, oldPassword: string, newPassword: string, domain: string): Promise<BooleanResponse | GtError> {
        if (domain === null || domain === '') {
            domain = '0';
        }
        const obj = new PWChangeRequest();
        obj.oldPassword = oldPassword;
        obj.newPassword = newPassword;
        obj.langCode = langCode;
        obj.username = username;
        obj.domain = domain;
        const retVal = await this.post<BooleanResponse>('password/savePassword', obj, BooleanResponse, undefined);
        return retVal;
    }

    /**
     * Saves the password after a password change. Only for SSO Users
     *
     * @param {string} langCode
     * @param {string} username
     * @param {string} oldPassword
     * @param {string} newPassword
     * @param {string} domain
     * @returns {(Promise<BooleanResponse | GtError>)}
     * @memberof PasswordService
     */
    public async savePasswordSSO(langCode: string, username: string, oldPassword: string, newPassword: string, domain: string): Promise<BooleanResponse | GtError> {
        if (domain === null || domain === '') {
            domain = '0';
        }
        const obj = new PWChangeRequest();
        obj.oldPassword = oldPassword;
        obj.newPassword = newPassword;
        obj.langCode = langCode;
        obj.username = username;
        obj.domain = domain;
        const retVal = await this.post<BooleanResponse>('password/savePasswordSSO', obj, BooleanResponse, undefined);
        return retVal;
    }
}