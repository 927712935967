import Session from '$src/core/Session';
import { SsoSuiConfigurationIdentityProvider } from '$src/storage/models/SsoSuiConfigurationIdentityProvider';
import React, { useEffect, useRef, useState } from 'react';
import GoogleButton from 'react-google-button';
import GTButton from '../shared/Atoms/GTButton';
import { Translate } from '../shared/Translate';
import { Alert } from '../shared/WarningsAndErrors/Alert';

/** Props for SsoButton hook */
export interface ISsoButtonProps extends SsoSuiConfigurationIdentityProvider {
    onClick(identityProvider: string): void;
    onTriggerAutoSso(identityProvider: string): void;
    autoSsoDelay?: number;
    stopAutoSso?: boolean;
}

/**
* Renders sso button and info
*/
export default function SsoButton(ssoButtonProps: ISsoButtonProps) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [ssoStarted, setSsoStarted] = useState(false);
    const autoSsoTimeout = useRef(0);

    useEffect(() => {
        let disabled: boolean = false;

        if (!ssoButtonProps.isClientIPEnabled) {
            setIsDisabled(true);
            disabled = true;
        }

        if (ssoButtonProps.stopAutoSso === true && autoSsoTimeout.current > 0) {
            window.clearTimeout(autoSsoTimeout.current);
            autoSsoTimeout.current = 0;
        }

        if (ssoButtonProps.autoSsoDelay !== undefined && !disabled) {
            if (ssoButtonProps.autoSsoDelay === 0) {
                ssoButtonProps.onClick(ssoButtonProps.identityProvider);
            } else {
                autoSsoTimeout.current = window.setTimeout(() => {
                    if (!ssoButtonProps.stopAutoSso) {
                        ssoButtonProps.onTriggerAutoSso(ssoButtonProps.identityProvider)
                        setSsoStarted(true);
                    }
                }, ssoButtonProps.autoSsoDelay);
            }
        }
    }, []);

    const UseGoogleButton = () => {
        return (
            <GoogleButton
                className="ssoButtonContainer__button-GoogleOpenIdConnect"
                label={Session.instance.storage.translation.GetString('Login:SignInWithGoogle')}
                onClick={() => ssoButtonProps.onClick(ssoButtonProps.identityProvider)}
                disabled={isDisabled}
            />
        );
    }

    const UseDefaultButton = () => {
        return (
            <GTButton
                additionalClassNames={"login__" + ssoButtonProps.identityProvider.toLowerCase()}
                onClick={() => ssoButtonProps.onClick(ssoButtonProps.identityProvider)}
                defaultButtonProps={{ disabled: isDisabled }}                
            >
                <Translate>{'Login:SignInWith' + ssoButtonProps.identityProvider}</Translate>
            </GTButton>
        );
    }

    const UseDisabledForIpInfo = () => {
        const text: string = Session.instance.storage.translation.GetString('Login:SsoDisabledForIp').replace('{ip}', ssoButtonProps.clientIP);

        return (
            <Alert alertAppereance="single-line" alertType="info" message={text}></Alert>
        )
    }

    const UseAutoSsoInfo = () => {
        if (ssoButtonProps.stopAutoSso === true) {
            return <></>;
        }

        return (
            <>
                {!ssoStarted && <Alert alertAppereance="single-line" alertType="info" message="Login:SsoAutoLoginMessage"></Alert>}
                {ssoStarted && <Alert alertAppereance="single-line" alertType="info" message="Login:SsoAutoLoginStartedMessage"></Alert>}                
            </>
        );
    }

    return (
        <>
             {ssoButtonProps.identityProvider === 'GoogleOpenIdConnect' && <UseGoogleButton />}
             {ssoButtonProps.identityProvider !== 'GoogleOpenIdConnect' && <UseDefaultButton />}
             {ssoButtonProps.autoSsoDelay !== undefined && ssoButtonProps.isClientIPEnabled !== false && <UseAutoSsoInfo />}
             {ssoButtonProps.isClientIPEnabled === false && <UseDisabledForIpInfo />}
        </>
    )
}