import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';

import { Translate } from '$components/shared/Translate';

import Iconaui from '$resources/svgs/extendedMenu/administrator/aui.svg';
import IconlessonRequests from '$resources/svgs/extendedMenu/boss/lesson-request-employees.svg';
import IconcourseRequests from '$resources/svgs/extendedMenu/boss/lesson-request-employees.svg';
import IconmyEmployees from '$resources/svgs/extendedMenu/boss/my-employees.svg';
import IconmyNews from '$resources/svgs/extendedMenu/student/chat.svg';
import Iconcolorschemeselection from '$resources/svgs/extendedMenu/student/color-scheme.svg';
import IconnewExternalCourseRequest from '$resources/svgs/extendedMenu/student/external-lesson-request.svg';
import IconmyFileSharing from '$resources/svgs/extendedMenu/student/file-share.svg';
import Iconglossary from '$resources/svgs/extendedMenu/student/glossary.svg';
import Iconmycertificates from '$resources/svgs/extendedMenu/student/my-certificates.svg';
import IconmyCourses from '$resources/svgs/extendedMenu/student/my-courses.svg';
import Iconmyfavorites from '$resources/svgs/extendedMenu/student/my-favorites.svg';
import IconmyLessons from '$resources/svgs/extendedMenu/student/my-lessons.svg';
import IconmyPerformanceReview from '$resources/svgs/extendedMenu/student/my-performance-review.svg';
import IconmyPerformanceSupport from '$resources/svgs/extendedMenu/student/my-performance-support.svg';
import Iconmyskill from '$resources/svgs/extendedMenu/student/my-skills.svg';
import Iconmytrainingplans from '$resources/svgs/extendedMenu/student/my-training-plans.svg';
import Iconmywatcheditems from '$resources/svgs/extendedMenu/student/my-watchlist.svg';
import IconswitchSui from '$resources/svgs/extendedMenu/student/switch-sui.svg';
import Iconmyprofile from '$resources/svgs/extendedMenu/student/user-profile.svg';
import Icontodo from '$resources/svgs/extendedMenu/student/work.svg';
import Icontrainer from '$resources/svgs/extendedMenu/trainer/trainer-virtualMeetings.svg';
import Iconreports from '$resources/svgs/extendedMenu/tutor/reports.svg';
import IconPublish from '$resources/svgs/offlineAdmin/publish.svg';
import Mail from '$resources/svgs/objects/mail.svg';
import TrainerBookings from '$resources/svgs/trainer/trainer_booking.svg';
import Iconpowerbireports from '$resources/svgs/extendedMenu/tutor/home-analytics.svg';
import Iconprivacy from '$resources/svgs/extendedMenu/student/privacy.svg';
import IconUserInterests from '$resources/svgs/extendedMenu/userinterests/userinterests.svg';
import OpenInBrowser from '$resources/svgs/extendedMenu/student/open_in_browser.svg';
import CommaBox from '$resources/svgs/subjectSubscriptions/comma-box.svg';
import IconShoppingBasket from '$resources/svgs/navi/shopping-basket.svg';
import IconSurvey from '$resources/svgs/lessonTypes/questionnaire.svg';
import IconVoucher from '$resources/svgs/misc/ticket-percent.svg';
import IconPaymentBookingOverview from '$resources/svgs/extendedMenu/student/payments.svg';
import IconPaymentBookingJournal from '$resources/svgs/extendedMenu/student/payment-booking-journal.svg';
import IconPaymentBooking from '$resources/svgs/extendedMenu/student/paymentBooking.svg';
import IconPerformanceCheck from '$resources/svgs/extendedMenu/student/my-performance-check.svg';
import IconCampaignOverview from '$resources/svgs/voucherCampaign/CampaignOverview.svg';
import IconGamification from '$resources/svgs/extendedMenu/student/gamification.svg';
import IconConfiguration from '$resources/svgs/extendedMenu/administrator/configuration.svg';
import IconMailTo from '$resources/svgs/objects/mailTo.svg';
import IconEvaluationOfSkills from '$resources/svgs/reports/evaluationOfSkillsIcon.svg';
import IconEvaluationOfTplans from '$resources/svgs/reports/evaluationOfTplansIcon.svg';

interface IProps {
    text: string;
    link: string;
    isExternalLink?: boolean;
    id: string;
    backgroundColor?: string;
    iconColor?: string;
    openNewWindow?: boolean;
}

interface IState {
    icon: string;
}

export class NavigationPoint extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        switch (this.props.id) {
            case ('Favorites'):
                this.state = ({ icon: Iconmyfavorites });
                break;
            case ('UserProfile'):
                this.state = ({ icon: Iconmyprofile });
                break;
            case ('MyLessons'):
                this.state = ({ icon: IconmyLessons });
                break;
            case ('MyCourses'):
                this.state = ({ icon: IconmyCourses });
                break;
            case ('ColorSchemeSelection'):
                this.state = ({ icon: Iconcolorschemeselection });
                break;
            case ('MyEmployeesList'):
                this.state = ({ icon: IconmyEmployees });
                break;
            case ('MyTeam'):
                this.state = ({ icon: IconmyEmployees });
                break;
            case ('CourseRequests'):
                this.state = ({ icon: IconcourseRequests });
                break;
            case ('TodoList'):
                this.state = ({ icon: Icontodo });
                break;
            case ('MyTrainingPlans'):
                this.state = ({ icon: Iconmytrainingplans });
                break;
            case ('WatchedItems'):
                this.state = ({ icon: Iconmywatcheditems });
                break;
            case ('MySkills'):
                this.state = ({ icon: Iconmyskill });
                break;
            case ('MyPerformanceReview'):
                this.state = ({ icon: IconmyPerformanceReview });
                break;
            case ('MyPerformanceSupport'):
                this.state = ({ icon: IconmyPerformanceSupport });
                break;
            case ('MyCertificates'):
                this.state = ({ icon: Iconmycertificates });
                break;
            case ('OldSui'):
                this.state = ({ icon: IconswitchSui });
                break;
            case ('MyNews'):
                this.state = ({ icon: IconmyNews });
                break;
            case ('LessonRequests'):
                this.state = ({ icon: IconlessonRequests });
                break;
            case ('MyFileSharing'):
                this.state = ({ icon: IconmyFileSharing });
                break;
            case ('NewExternalCourseRequest'):
                this.state = ({ icon: IconnewExternalCourseRequest });
                break;
            case ('Glossary'):
                this.state = ({ icon: Iconglossary });
                break;
            case ('Aui'):
                this.state = ({ icon: Iconaui });
                break;
            case ('VirtualMeetingsStudent'):
                this.state = ({ icon: Icontrainer });
                break;
            case ('VirtualMeetingsTrainer'):
                this.state = ({ icon: Icontrainer });
                break;
            case ('LessonStatusImportOffline'):
                this.state = ({ icon: IconPublish });
                break;
            case ('SendMessage'):
                this.state = ({ icon: Mail });
                break;
            case ('PowerBiReports'):
                this.state = ({ icon: Iconpowerbireports });
                break;
            case ('Privacy'):
                this.state = ({ icon: Iconprivacy });
                break;
            case ('MyRecommendations'):
                this.state = ({ icon: IconUserInterests });
                break;
            case ('ExternalLink'):
                this.state = ({ icon: OpenInBrowser });
                break;
            case ('MySubjectSubscriptionsEditView'):
                this.state = ({ icon: CommaBox });
                break;
            case ('ShoppingBasket'):
                this.state = ({ icon: IconShoppingBasket });
                break;
            case ('LessonSurveys'):
                this.state = ({ icon: IconSurvey });
                break;
            case ('VoucherValidation'):
                this.state = ({ icon: IconVoucher });
                break;
            case ('PaymentBookingOverview'):
                this.state = ({ icon: IconPaymentBookingOverview });
                break;
            case ('PaymentBookingJournal'):
                this.state = ({ icon: IconPaymentBookingJournal });
                break;
            case ('PaymentBooking'):
                this.state = ({ icon: IconPaymentBooking });
                break;
            case ('MyPerformanceCheck'):
                this.state = ({ icon: IconPerformanceCheck });
                break;
            case ('PaymentCampaignOverview'):
                this.state = ({ icon: IconCampaignOverview });
                break;
            case ('PerformanceCheckRequest'):
                this.state = ({ icon: IconPerformanceCheck });
                break;
            case ('Gamification'):
                this.state = ({ icon: IconGamification });
                break;
            case ('Configuration'):
                this.state = ({ icon: IconConfiguration });
                break;
            case ('MailTo'):
                this.state = ({ icon: IconMailTo });
                break;
            case ('EvaluationOfSkills'):
                this.state = ({ icon: IconEvaluationOfSkills });
                break;
            case ('EvaluationOfTplans'):
                    this.state = ({ icon: IconEvaluationOfTplans });
                    break;
            default:
                this.state = ({ icon: '' });
        }
        if (this.props.id.startsWith('Reporting')) {
            this.state = ({ icon: Iconreports });
        }
        if (this.props.id.startsWith('MyTeam')) {
            this.state = ({ icon: IconmyEmployees });
        }
        if (this.props.id.startsWith('Trainer_Booking_View')) {
            this.state = ({ icon: TrainerBookings });
        }
    }

    public async componentDidMount() {
        const iconColor = this.props.iconColor != null ? this.props.iconColor : '#fff';
        await this.delay(0); // TODO: Make nice ?? No clue why it works
        const iconElements = document.querySelectorAll('#' + this.props.id + '  .svg-color');
        if (iconElements != null && iconElements.length > 0) {
            for (const iconElement of Array.from(iconElements)) {
                iconElement.setAttribute('style', 'fill:' + iconColor);
            }
        }
    }

    public render() {
        const BACKGROUND_COLOR = {
            backgroundColor: this.props.backgroundColor != null ? this.props.backgroundColor : 'none'
        }
        return (
            this.props.isExternalLink === true ?
                <li>
                    <a
                        className="navigation__navigation-point"
                        style={BACKGROUND_COLOR}
                        href={this.props.link}
                        target={this.props.openNewWindow ? '_blank' : ''}>
                        <div id={this.props.id} className="navigation__navigation-point-icon">
                            <InlineSVG src={this.state.icon} />
                        </div>
                        <div className="navigation__navigation-point-text">
                            <Translate>{this.props.text}</Translate>
                        </div>
                    </a>
                </li>
                :
                <li>
                    <NavLink
                        className="navigation__navigation-point"
                        exact={true}
                        style={BACKGROUND_COLOR}
                        to={this.props.link}
                        target={this.props.openNewWindow ? '_blank' : ''}
                        rel="opener">
                        <div id={this.props.id} className="navigation__navigation-point-icon">
                            <InlineSVG src={this.state.icon} />
                        </div>
                        <div className="navigation__navigation-point-text">
                            <Translate>{this.props.text}</Translate>
                        </div>
                    </NavLink>
                </li>
        );
    }

    private async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
