import { JsonObject, JsonProperty } from 'json2typescript';
import ShoppingBasketItem from "$src/storage/models/ShoppingBasket/ShoppingBasketItem";
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';

export const VALID_BASKET_VERSION = 2;

/** 
 * Contents of the shopping basket
 * Model class used for 
 * - local persistent storage in singleton ShoppingBasketStorage.instance
 * - and as parameter for the purchase api in gtsevices
 */
@JsonObject
export default class ShoppingBasket {
    /**
     * Important: If you add new properties to the ShoppingBasket, keep in mind that
     *            there may be ShoppingBasket instances persistently stored in the
     *            browser's local storage that do no have these properties when
     *            they are restored. 
     *            In this case you either need to handle the potentially missing properties
     *            in the code or (easier) you can increase the VALID_BASKET_VERSION.
     *            The ShoppingBasketStorage class will then discard any stored ShoppingBaskets
     *            that don't have the new version number.
     */           
    @JsonProperty('version', Number, false)
    public version: number = VALID_BASKET_VERSION;

    /** Timestamp when the ShoppingBasket was stored; used by ShoppingBasketStorage */
    @JsonProperty('saveTimeStamp', DateTimeConverter, false)
    public saveTimeStamp: Date | null = null;

    @JsonProperty('shoppingBasketItems', [ShoppingBasketItem], false)
    public shoppingBasketItems: ShoppingBasketItem[] = [];
    
    @JsonProperty('voucherCode', String, false)
    public voucherCode: string = '';

    // Note: The price fields below are in "current currency" i.e. the currency of all shoppingBasketItems.

    /** total price before discount, calculated by gtservices */
    @JsonProperty('totalPriceBeforeDiscount', Number, false)
    public totalPriceBeforeDiscount: number = 0;

    /** total voucher discount, calculated by gtservices */
    @JsonProperty('discount', Number, false)
    public discount: number = 0;

    /** final price after discount, calculated by gtservices */
    @JsonProperty('finalPrice', Number, false)
    public finalPrice: number = 0;

    /** Payment provider id to use for payment -use default payment provider id from settings if this value is null */
    @JsonProperty('selectedPaymentProvider', String, true)
    public selectedPaymentProvider: string | undefined = undefined;
}