import React, { useEffect } from 'react';

/**
 * This component closes the current tab
 * It is used in the context of payments when the link to the payment provider is opened in a 
 * separate tab (EPayLinkUsage.NewTab). In this case the route calling this component must  be
 * configured in the payment gateway as redirect url after payment completion/error.
 * Note: This component only works for tabs opened via Javescript, not for the main tab.
 */
 export default function CloseCurrentTab() {       

    useEffect(() => {
        close();
    });


    return ( <></> );
}