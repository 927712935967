import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class TimeZoneValue {
    [key: string]: number | string;

    @JsonProperty('id', String, false)
    public id: string = '';    

    @JsonProperty('text', String, false)
    public text: string = '';      
}
