import { JsonObject, JsonProperty } from 'json2typescript';

/** Response of a user book on class validation search */
@JsonObject
export class ValidateUserRegistrationOnClassResponse {
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** User is already booked on this class */
    @JsonProperty('alreadyBookedValidationFailed', Boolean, false)
    public alreadyBookedValidationFailed: boolean = false;

    /** User is booked on another class and multiple booking is disabled */
    @JsonProperty('noMultiBookingValidationFailed', Boolean, false)
    public noMultiBookingValidationFailed: boolean = false;
}