import { JsonObject, JsonProperty } from 'json2typescript';
import { ScheduleStatusConverter } from '../converters/ScheduleStatusConverter';
import { WorkflowActivityStatusConverter } from '../converters/WorkflowActivityStatusConverterr';
import { EClassStatus, EWorkflowActivityStatus } from '../enums';


/**
 * class for workflow activities
 */
@JsonObject('WfActivity')
export class WorkflowActivity {

    /** Id of the corresponding item. */
    @JsonProperty('id', Number, false)
    public id: number = 0;

    /** String Id of the corresponding item. */
    @JsonProperty('sid', String, false)
    public sid: string = '';

    /** String Description of the corresponding item. */
    @JsonProperty('description', String, false)
    public description: string = '';
    
    /** LinkText */
    @JsonProperty('linkText', String, false)
    public linkText: string = '';

    /** LinkUrl */
    @JsonProperty('linkUrl', String, false)
    public linkUrl: string = '';

    /** Title (in selected language). */
    @JsonProperty('title', String, false)
    public title: string = '';
    
    /** Guid . */
    @JsonProperty('guid', String, false)
    public guid: string = '';

    /** WorkflowId of the corresponding item. */
    @JsonProperty('workflowId', Number, false)
    public workflowId: number = 0;

    /** String WorkflowSid of the corresponding item. */
    @JsonProperty('workflowSid', String, false)
    public workflowSid: string = '';

    /** String WorkflowTitle of the corresponding item. */
    @JsonProperty('workflowTitle', String, false)
    public workflowTitle: string = '';

    /** String WorkflowDescription of the corresponding item. */
    @JsonProperty('workflowDescription', String, false)
    public workflowDescription: string = '';

    /** String WorkflowStartEvent of the corresponding item. */
    @JsonProperty('workflowStartEvent', String, false)
    public workflowStartEvent: string = '';

    @JsonProperty('type', String, false)
    public type: string = '';

    @JsonProperty('result', String, false)
    public result: string = '';

  
    @JsonProperty('nextActivities', [[String]], false)
    public nextActivities: string[][] =[];
    
    @JsonProperty('xml', String, false)
    public xml: string = '';

    @JsonProperty('status', WorkflowActivityStatusConverter, false)
    public status: EWorkflowActivityStatus = EWorkflowActivityStatus.Locked;

    @JsonProperty('validResponses', [String], false)
    public validResponses: string[] = [];

    @JsonProperty('completedDate', String, false)
    public completedDate: string = '';

    @JsonProperty('dueDate', String, false)
    public dueDate: string = '';

    @JsonProperty('comment', String, false)
    public comment: string = '';

    @JsonProperty('userName', String, false)
    public userName: string = '';

    @JsonProperty('itemDescription', String, false)
    public itemDescription: string = '';

    @JsonProperty('cancellationId', Number, false)
    public cancellationId: number = 0;

    @JsonProperty('classStatus', ScheduleStatusConverter, false)
    public classStatus: EClassStatus = EClassStatus.Undefined;

    public searchString: string = '';

    public BuildSearchString()
    {
        this.searchString = this.userName + ' ' + this.itemDescription;
        this.workflowDescription = this.workflowDescription.replace(',','X')
    }

}
