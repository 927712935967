import { SingleAttribute } from '$components/shared/Attributes/SingleAttribute';
import Logger from '$src/core/Logger';
import { Attribute } from '$storage/models/Attribute';
import React from 'react';

interface IProps {
    customAttributeConfig: ICustomAttribute[];
    attributes: Attribute[];
    headingCssClass: string;
    attributeCssClassName: string;
    parentHeadingLevel: number;
}

export default function AttributeList(props: IProps) {

    const loggerLocality = 'Components.AttributeList';
    const config = props.customAttributeConfig;

    return <>
        {config != null && config.length > 0 && config.map(c => {
            let attr = props.attributes.filter(attribute => attribute.name === c.name);
            if (attr.length > 0) {
                attr = attr.sort((ordinal1, ordinal2) => ordinal1.ordinal - ordinal2.ordinal);
                return <SingleAttribute
                    attributeCssClassName={props.attributeCssClassName}
                    headingCssClass={props.headingCssClass}
                    listingType={c.listingType}
                    parentHeadingLevel={props.parentHeadingLevel}
                    attribut={attr}
                    key={c.name}
                    titleIsVisible={c.titleIsVisible}
                    enableHTMLFormatter={c.enableHTMLFormatter} />
            }
            else {
                Logger.log(loggerLocality, `Attribute ${c} not found.`);
                return <React.Fragment key={`atl_${c.name}`} />
            }
        }
        )}
    </>
}