import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { ItemSummaryFavorites } from '$components/item/itemSummaries/ItemSummaryFavorites';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { Favorite } from '$storage/models/Favorite';
import { RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps<{}> {
    parentHeadingLevel?: number;
}

interface IState {
    favorites: Favorite[] | null;
    isDataLoaded: boolean;
    errorMessage: string;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class Favorites extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            favorites: null,
            isDataLoaded: false,
        };
    }

    public UNSAFE_componentWillMount() {
        this.loadData();
    }

    public render() {
        return (
            <div>
                <div className="l-box-container mylist-container">
                    <div>
                        <MenuBreadCrumb {...this.props} />
                        <div className="l-box--wide">
                            <Heading headingLevel={this.props.parentHeadingLevel != null ? (this.props.parentHeadingLevel + 1) : 1} cssClass="heading__Title">
                                <Translate>Favorite:Title</Translate>
                            </Heading>
                            <div>
                                <Translate>Favorite:SubTitle</Translate>
                            </div>
                        </div>
                        <div>
                            <ul>
                                {this.renderFavorites()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public renderFavorites(): JSX.Element | JSX.Element[] {
        const elements: JSX.Element[] = [];
        if (this.state.favorites != null && this.state.isDataLoaded) {
            if (this.state.favorites.length > 0) {
                this.state.favorites.map((fav, index) => {
                    elements.push(
                        <ItemSummaryFavorites {...this.props} onRemove={() => this.onRemoveFavorite()} key={index} favorite={fav} />
                    );
                })
            } else {
                elements.push(<NoDataFound message={Session.instance.storage.translation.GetString('Favorite:NoData')} />);
            }

        } else if (!this.state.isDataLoaded) {
            elements.push(<ProgressSpinner key={1} />);
        }
        return elements;
    }

    public componentDidMount() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
    }

    public onRemoveFavorite() {
        this.loadData();
    }

    private loadData() {
        const response = Session.instance.storage.favorites.getObjectsFromCache();
        this.setState({
            errorMessage: '',
            favorites: response,
            isDataLoaded: true
        });
    }
}
export default Favorites;