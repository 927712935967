
import Logger from '$src/core/Logger';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import SessionCache from '$src/storage/cache/SessionCache';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';

const CACHING_DURATION = 300;

export default class ExternalCourseProviderStorage extends SessionCache<number, ExternalCourseProvider> {
    protected className = 'ExternalCourseProviderStorage';
    protected loggerLocality = 'Storage.ExternalCourseProviderStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    /**
     * Get a list of active external course in current ui language and for specified domain
     *
     * @returns {(Promise<CourseProvider[] | GtError>)}
     * @memberof ExternalCourseProviderStorage
     */
    public async getExternalCourseProviderList(): Promise<ExternalCourseProvider[] | GtError> {
        const methodName = `${this.className}:getExternalCourseProviderList()`;
        let providers = this.getObjectsFromCache();
        if (providers == null || providers.length === 0) {
            this.clear();
            Logger.log(this.loggerLocality, `${methodName} getting external course providers from server, language=${Session.instance.languageCode}.`);
            const response = await ExternalCourseService.instance.getExternalCourseProviders();
            if (isSuccess<ExternalCourseProvider[]>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality,
                    `${methodName} got external course providers from server, language=${Session.instance.languageCode}, number of elements=${elementCount}.`);
                response.map(p => {
                    this.saveObjectToCache(p.providerId, p);
                })
                Logger.log(this.loggerLocality, `${methodName} added ${response.length} to storage.`);
                providers = this.getObjectsFromCache();
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get external course providers from server, language=${Session.instance.languageCode}.`);
                return response;
            }
        }

        return providers;
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}