import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject
export class RemoveSkillProfileResponse {
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('skillProfileId', Number, false)
    public skillProfileId: number = 0;

    @JsonProperty('removed', Boolean, false)
    public removed: boolean = false;
}

// This container is needed to be able to json convert the array
// tslint:disable-next-line: max-classes-per-file
@JsonObject
export class RemoveSkillProfileResponseContainer {
    @JsonProperty('removeSkillProfileResponses', [RemoveSkillProfileResponse], false)
    public removeSkillProfileResponses: RemoveSkillProfileResponse[] = [];
}