/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-use-before-define */
import './styles/gt.scss';

import { configure as configureMobX } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer, setConfig } from 'react-hot-loader';
import { BrowserRouter } from 'react-router-dom';
import { InjectPreLoginConfiguration } from './configBoot';

import Logger from '$core/Logger';
import Release from '$core/Release';
import * as RoutesModule from '$core/routes';
import Session from '$core/Session';
import { CbtStartParameters } from '$storage/models/CbtStartParameters';
import { BrowserHelper } from '$util/BrowserHelper';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import 'url-search-params-polyfill';
import { EItemDetailCallerContextType } from './storage/models/enums';
import PaymentAdminService from '$core/Services/PaymentAdminService';

let appBaseUrl = '';
let _appRoutes: JSX.Element;
InjectPreLoginConfiguration(async () => await ReadConfiguration());

async function ReadConfiguration() {
    console.log('READ CONFIGURATION');
    /**
     * This module allows overriding the __webpack_public_path__ variable which is used by the webpack bundle to form the URL
     * for loading resources that are not contained in the bundle (e.g fonts, large images). In other words this is the  
     * URL path where the SUI is installed, including slashes at both ends ('/sui/').
     * The value of this variable is initially defined at build time through an environment variable (ASSET_PATH) which is
     * used in the webpack.config.js and also is exposed as process.env.PUBLIC_URL (without trailing slash) to the app.
     * 
     * It is 'best practice' for webpack apps to definine the application URL at build time as described above and to
     * use process.env.PUBLIC_URL in the app whenever the URL path is needed.
     * However, in the new SUI we need to be able to configure different app installation paths without rebuilding the app.
     * The solution I finally found consists of the following parts
     * - manually edit the URL paths in the installed index.html when a non-standard URL is required
     * - loading the globalConfig.js in index.html (from the corrected path)
     * - having a globalConfig.appProperties.appPathOverride parameter in the globalConfig.js when a non-standard URL is required
     * - using the above parameter to override the __webpack_public_path__ variable ensuring correct loading of external resources
     * - using the correct URL to initialize the react router in boot.tsx
     * 
     * The __webpack_public_path__ is not well documented and it is very tricky to use. It does not work when it is assigned in a
     * Module with other import statements (as boot.tsx), therefore the assignment must be placed in a sepatate module and it must  
     * be executed before all other imports. That's why the import of this module is places at the very top of boot.tsx.
     * See also: https://webpack.js.org/guides/public-path/import { ObjectHelper } from './util/ObjectHelper';
     *
     *      or   https://github.com/webpack/webpack/pull/971#issuecomment-245913214 for an alternative solution
     */

    if (globalConfig.appProperties.appPathOverride != null) {
        // If an appPathOverride is configured, we use this value
        appBaseUrl = globalConfig.appProperties.appPathOverride;
        __webpack_public_path__ = `${appBaseUrl}${!appBaseUrl.endsWith('/') ? '/' : ''}`;
        console.log(`AppBaseUrl: '${appBaseUrl}' (globalConfig.js)'`)
    } else if (process.env.PUBLIC_URL != null) {
        // otherwise we use the path that was set at build time
        appBaseUrl = process.env.PUBLIC_URL;
        console.log(`AppBaseUrl: '${appBaseUrl}' (build time definition)'`)
    } else {
        // this should never be the case
        console.log(`AppBaseUrl: '${appBaseUrl}' (default)'`)
    }

    Release.logReleaseInfo();
    // Logger.log('ShowAppBaseUrl', `appBaseUrl: '${appBaseUrl}'`); 

    _appRoutes = RoutesModule.routes;
    await bootApp();
}

/**
 * Boot the React single page application.
 */
async function bootApp() {
    console.log('%cHi, nice to see you!', 'background: #222; color: #bada55');

    // Configure MobX state management
    configureMobX({
        enforceActions: 'observed',
    });

    // Initialize ServiceClient. 
    // For the connection parameters we have multiple override mechanisms:
    // - The default values are given here in the code.
    // - They may be overridden by process.env.REACT_APP_... environment variables defined at BUILD TIME (in run.cmd or CI/CD Pipeline)
    // - They may be overridden at RUNTIME by values in globalConfig.serviceProperties defined in the globalConfig.js file
    const svcProtocol: string = globalConfig.serviceProperties.overrideSvcProtocol || process.env.REACT_APP_API_PROTOCOL || window.location.protocol.replace(':', '');
    const svcHostname: string = globalConfig.serviceProperties.overrideSvcHostName || process.env.REACT_APP_API_HOSTNAME || window.location.hostname;
    const svcPortSuffix: string = globalConfig.serviceProperties.overrideSvcPortSuffix || process.env.REACT_APP_API_PORT_SUFFIX || '';
    const svcBaseUrl: string = globalConfig.serviceProperties.overrideSvcBaseUrl || process.env.REACT_APP_API_BASE_URL || 'gtservices/suisvc/v1/'; // must end with '/'
    Logger.log('ServiceParameters', `svcProtocol=${svcProtocol}, svcHostname=${svcHostname}, svcPortSuffix=${svcPortSuffix}, svcBaseUrl=${svcBaseUrl}`);
    // and finally initialize the ServiceClient and all the other Services using the above parameters

    // Service Initialization
    const servicePromises: Array<Promise<void>> = [];

    servicePromises.push(import('$core/Services/AssessmentService').then(({ default: AssessmentService }) => { AssessmentService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/AssignmentService').then(({ default: AssignmentService }) => { AssignmentService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/AttributeService').then(({ default: AttributeService }) => { AttributeService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/AuthService').then(({ default: AuthService }) => { AuthService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/CatalogService').then(({ default: CatalogService }) => { CatalogService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/CertificateService').then(({ default: CertificateService }) => { CertificateService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/F2FService').then(({ default: F2FService }) => { F2FService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/FileSharingService').then(({ default: FileSharingService }) => { FileSharingService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/GlobalDescriptionService').then(({ default: GlobalDescriptionService }) => { GlobalDescriptionService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/ItemService').then(({ default: LessonService }) => { LessonService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/NewsService').then(({ default: NewsService }) => { NewsService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/PasswordService').then(({ default: PasswordService }) => { PasswordService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/RegistrationService').then(({ default: RegistrationService }) => { RegistrationService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/SearchService').then(({ default: SearchService }) => { SearchService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/SharedService').then(({ default: SharedService }) => { SharedService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/TrainingPlanService').then(({ default: TrainingPlanService }) => { TrainingPlanService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/TranslationService').then(({ default: TranslationService }) => { TranslationService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/UserService').then(({ default: UserService }) => { UserService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/ServiceClient').then(({ default: ServiceClient }) => { ServiceClient.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/WorkflowActivityService').then(({ default: WorkflowActivityService }) => { WorkflowActivityService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/FavoriteService').then(({ default: FavoriteService }) => { FavoriteService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/WatchlistService').then(({ default: WatchlistService }) => { WatchlistService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/UserPreferencesService').then(({ default: UserPreferencesService }) => { UserPreferencesService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/ExternalCourseService').then(({ default: ExternalCourseService }) => { ExternalCourseService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/SkillService').then(({ default: SkillService }) => { SkillService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/ConfigService').then(({ default: ConfigService }) => { ConfigService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/GlossaryService').then(({ default: GlossaryService }) => { GlossaryService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/LicenseRegistrationService').then(({ default: LicenseRegistrationService }) => { LicenseRegistrationService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/MediaService').then(({ default: MediaService }) => { MediaService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/SkillProfileService').then(({ default: SkillProfileService }) => { SkillProfileService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/TeamService').then(({ default: TeamService }) => { TeamService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/OfflineAdminService').then(({ default: OfflineAdminService }) => { OfflineAdminService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/TutoringReportService').then(({ default: TutoringReportService }) => { TutoringReportService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/TrainerService').then(({ default: TrainerService }) => { TrainerService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/PowerBiService').then(({ default: PowerBiService }) => { PowerBiService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/PrivacyService').then(({ default: PrivacyService }) => { PrivacyService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/ItemRatingService').then(({ default: ItemRatingService }) => { ItemRatingService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/UserInterestsService').then(({ default: UserInterestsService }) => { UserInterestsService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/UserSubjectSubscriptionService').then(({ default: UserSubjectSubscriptionService }) => { UserSubjectSubscriptionService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/PurchaseService').then(({ default: PurchaseService }) => { PurchaseService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/PaymentCampaignService').then(({ default: PaymentCampaignService }) => { PaymentCampaignService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/PaymentAdminService').then(() => PaymentAdminService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl)));
    servicePromises.push(import('$core/Services/GamificationService').then(({ default: GamificaitonService }) => { GamificaitonService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/CollaborationProviderService').then(({ default: CollaborationProviderService }) => { CollaborationProviderService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/MultiFactorAuthenticationService').then(({ default: MultiFactorAuthenticationService }) => { MultiFactorAuthenticationService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));
    servicePromises.push(import('$core/Services/ReportingService').then(({ default: ReportingService }) => { ReportingService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcBaseUrl) }));

    // SSOSP service initialization
    const svcSsoSpBaseUrl: string = globalConfig.serviceProperties.overrideSsoSpSvcBaseUrl || process.env.REACT_APP_SSOSP_API_BASE_URL || 'GTSsoServiceProvider/'; // end with '/'
    Logger.log('ServiceParameters', `svcProtocol=${svcProtocol}, svcHostname=${svcHostname}, svcPortSuffix=${svcPortSuffix}, svcBaseUrl=${svcSsoSpBaseUrl}`);
    servicePromises.push(import('$core/Services/SsoServiceProviderService').then(({ default: SsoServiceProviderService }) => { SsoServiceProviderService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcSsoSpBaseUrl) }));

    // Payment Provider service
    const svcPaySpBaseUrl: string = globalConfig.serviceProperties.overridePaymentProviderSpSvcBaseUrl || process.env.REACT_APP_PAYMENTPROVIDER_API_BASE_URL || 'PaymentGateway/'; // end with '/'
    servicePromises.push(import('$core/Services/PaymentProviderService').then(({ default: PaymentProviderService }) => { PaymentProviderService.instance.init(svcProtocol, svcHostname, svcPortSuffix, svcPaySpBaseUrl) }));

    // End Service Initialization
    await Promise.all(servicePromises);

    Logger.log('All Services are ready to serve');
    await renderApp();
}
/**
 * Start up React app, set up routing configuration and inject app into DOM element "root".
 */
async function renderApp() {
    await loadData();

    ReactDOM.render(
        <AppContainer>
            <div className="root">
                <BrowserRouter basename={appBaseUrl}>
                    {_appRoutes}
                </BrowserRouter>
            </div>
        </AppContainer>,
        document.getElementById('root'),
    );
}
/**
 * Load any data needed before first render
 *
 * @returns {Promise<void>}
 */
async function loadData(): Promise<void> {
    Logger.log('boot', 'Set Language Code');
    await Session.instance.setLanguageCode();
    Logger.log('boot', 'Prepare User Token');
    await Session.instance.prepareUserTokens();
}

// Allow Hot Module Replacement
if (module.hot) {
    module.hot.accept('./core/routes', () => {
        _appRoutes = require<typeof RoutesModule>('./core/routes').routes;
        renderApp();
    });
    // TODO: To be removed at least for production
    setConfig({ logLevel: 'debug' });
}

BrowserHelper.applyBrowserSpecificFixes();

//#region functions are used to start lessons
// =================================================================================================
// The following functions are used to start lessons
// =================================================================================================

// tslint:disable:variable-name
let __gt_lessonWindow: any = null;
let __gt_lessonWindowTimer: any = null;
let __gt_lessonCbtWindow: any = null;
let __gt_lessonCbtWindowTimer: any = null;
let __gt_lessonRefreshWindowTimer: any = null;
let __gt_cbtStartParameters: CbtStartParameters;

/**
 * Start a WBT lesson.
 * Remark: The WBT lesson should be started from the top of the component hierarchy, so that we can
 * still track the open window, even if the user jumps to a different view in the UI. That's why we 
 * implement it here in the global scope.
 * @param wbtId 
 * @param assignmentId 
 * @param tplanId 
 * @param tplanClassId 
 */
export async function __gt_startWbt(wbtWindow: Window): Promise<void> {
    __gt_lessonWindow = wbtWindow;
    // Start timer to track the WBT lesson's window. 
    __gt_lessonWindowTimer = window.setInterval(__gt_waitForLessonWindowClose, 250);
}

/**
 *
 */
export async function __gt_startCbt(cbtId: number, startButton: number, itemContext: EItemDetailCallerContextType, assignmentId: number = 0, tplanId: number = 0, tplanClassId: number = 0): Promise<GtError | undefined> {
    return import('$core/Services/ItemService').then(async ({ default: LessonService }) => {
        const response = await LessonService.instance.getCbtStartParameters(cbtId, startButton, itemContext, assignmentId, tplanId, tplanClassId);
        if (isSuccess<CbtStartParameters>(response)) {
            __gt_cbtStartParameters = response;
            const startUrl = __gt_cbtStartParameters.startUrl;
            Logger.log('boot', `opening CBT lesson ${cbtId.toString()} using URL ${startUrl}`);
            __gt_lessonCbtWindow = window.open(startUrl, 'CBT');
            // Start timer to track the WBT lesson's window. 
            __gt_lessonCbtWindowTimer = window.setInterval(__gt_waitForLessonCbtWindowClose, 250);
            return undefined;
        }
        else {
            return response;
        }
    });
}

/**
 * Start a Questionnaire lesson. The url is the same as for WBTs
 * Remark: The Questionnaire lesson should be started from the top of the component hierarchy, so that we can
 * still track the open window, even if the user jumps to a different view in the UI. That's why we 
 * implement it here in the global scope.
 * @param questionnaireId 
 * @param assignmentId 
 * @param tplanId 
 * @param tplanClassId 
 */
export async function __gt_startQuestionnaire(questionnaireWindow: Window): Promise<void> {
    __gt_lessonWindow = questionnaireWindow;
    // Start timer to track the WBT lesson's window. 
    __gt_lessonWindowTimer = window.setInterval(__gt_waitForLessonWindowClose, 250);
}

/**
 * Timer handler used to track the the WBT lesson's window to detect closure of the lesson, i.e window.
 * As soon as the window closure is detected we reload the UI to update it, i.e any status display will
 * be updated.
 */
function __gt_waitForLessonWindowClose() {
    if (!__gt_lessonWindow) {
        window.clearInterval(__gt_lessonWindowTimer);
        return;
    }

    let isLessonWindowClosed = false;
    try {
        // Has WBT lesson's window been closed?
        isLessonWindowClosed = !__gt_lessonWindow || __gt_lessonWindow.closed;
    }
    // tslint:disable-next-line:no-empty
    catch (e) {
        console.warn(e);
        // Ignore any exception due to e.g. access violation to the WBT lesson's window 
        // (a non-SCORM-compatible lesson may have loaded content from a different host).
    }
    if (isLessonWindowClosed) {
        window.clearInterval(__gt_lessonWindowTimer);

        // Reload UI.
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
    }
}

function __gt_waitForLessonCbtWindowClose() {
    let isLessonWindowClosed = false;

    try {
        // Has CBT lesson's window been closed?
        isLessonWindowClosed = __gt_lessonCbtWindow.closed;
    }
    // tslint:disable-next-line:no-empty
    catch (e) {
        // Ignore any exception due to e.g. access violation to the CBT lesson's window 
    }
    if (isLessonWindowClosed) {
        window.clearInterval(__gt_lessonCbtWindowTimer);
        const delay = globalConfig.cbtExecutionProperties.refreshDelay != null ? globalConfig.cbtExecutionProperties.refreshDelay : 10
        __gt_lessonRefreshWindowTimer = window.setInterval(__gt_waitForLessonStatusUpdate, delay);
    }
}


function __gt_waitForLessonStatusUpdate() {
    window.clearInterval(__gt_lessonRefreshWindowTimer);
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;

}
//#endregion