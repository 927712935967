import React from 'react';
import { Translate } from '../shared/Translate';



interface IProps {
    listOfErrors: JSX.Element[];
    
}

export class ErrorSummary extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }
    
// 'input-message error'
    public render() {
      if (this.props.listOfErrors.length >0){
            
        return (
            <div className="l-box--wide error-summary">
                <h2 className="l-form-container__section-title heading__Level2"><Translate>{'UserProfile:ErrorSummary'}</Translate></h2>
                <ul>
                    {this.props.listOfErrors.map((errorEntry, index) => 
                        <li key={index} className="input-message error">{errorEntry}</li>)}
                </ul>
            </div>
        );
    }
    else{
        return null;
    }
    }
}
