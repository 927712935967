import Logger from '$src/core/Logger';
import XCache from '$src/storage/cache/Cache';
import CachedObject from '$src/storage/cache/CachedObject';

// Use this Cache if you want to save objects into the Session Cache

export default abstract class SessionCache<TKey, TObject> extends XCache<TKey, TObject> {
    protected className = 'SessionCache';
    protected loggerLocality = 'Storage.SessionCache';
    
    protected _localCache: Map<TKey, CachedObject<TObject>>;

    constructor(cachingDuration: number = 3600,
                autoRemoveExpiredData?: boolean,
                isLanguageDependant?: boolean) {
        super(cachingDuration, autoRemoveExpiredData, isLanguageDependant);
        this._localCache = new Map<TKey, CachedObject<TObject>>();

        Logger.log(this.loggerLocality, `Create SessionCache Object`);
    }

    protected getCachedObjectFromCache(key: TKey): CachedObject<TObject> | null {
        const cachedObject = this._localCache.get(key);
        return (cachedObject != null) ? cachedObject : null;
    }
    protected getCachedObjectsFromCache(): Array<CachedObject<TObject>> {
        return Array.from(this._localCache.values());
    }

    protected saveObjectToCache(key: TKey, object: TObject): void {
        const cachedObject = new CachedObject(object);
        this._localCache.set(key, cachedObject);
    }

    protected removeObjectFromCache(key: TKey): void {
        this._localCache.delete(key);
    }

    protected getAllKeys(): TKey[] {
        return Array.from(this._localCache.keys());
    }

    protected clear(): void {
        this._localCache.clear();
    }
}
