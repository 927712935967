import { JsonObject, JsonProperty } from 'json2typescript';
import { EItemType } from '$storage/models/enums';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import ShoppingBasketItemUser from '$storage/models/ShoppingBasket/ShoppingBasketItemUser';
import PriceInfo from '$storage/models/ShoppingBasket/PriceInfo';
import { DateTimeConverter } from '$storage/models/converters/DateTimeConverter';

/**
 * Represents an item (WBT or TP/F2F class) in the shopping basket
 * (must be in sync with the corresponding gtservices model class)
 */
@JsonObject
export default class ShoppingBasketItem {

    constructor(itemId: number, classId: number, itemType: EItemType, sId: string, classCode: string, title: string, additionalInfo: string, finalPrice: number, currency: string, f2fClassIdsForTP: number[], bookUsers: ShoppingBasketItemUser[], classStart?: Date, classEnd?: Date, classLocation?: string) {
        this.itemId = itemId;
        this.classId = classId;
        this.itemType = itemType;
        this.sId = sId;
        this.classCode = classCode;
        this.title = title;
        this.additionalInfo = additionalInfo;
        this.finalPrice = new PriceInfo(finalPrice);
        this.currency = currency;
        this.f2fClassIdsForTP = f2fClassIdsForTP;
        this.bookUsers = bookUsers;
        this.classStart = classStart;
        this.classEnd = classEnd;
        this.classLocation = classLocation;
    }

    /** ItemId of the F2F Course, TP or WBT */
    @JsonProperty('itemId', Number, false)
    public itemId: number;

    /** F2F/TPlan ClassId, 0 for WBT  */
    @JsonProperty('classId', Number, false)
    public classId: number;

    /** Type of the item */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType;
    
    /** Title of the item as it will be displayed in the shopping basket */
    @JsonProperty('title', String, false)
    public title: string;
    
    /** Additional info to be displayed in the shopping basket */
    @JsonProperty('additionalInfo', String, false)
    public additionalInfo: string;
    
    /** Only for TPlan Items: Ids of F2F classes that are part of the TPlan and must also be registered */
    @JsonProperty('f2fClassIdsForTP', [Number], false)
    public f2fClassIdsForTP: number[] = [];

    /** Final Price of the item with sum of all users and discounts */
    @JsonProperty('finalPrice', PriceInfo, false)
    public finalPrice: PriceInfo = new PriceInfo(0);    

    /** Currency of the item */
    @JsonProperty('currency', String, false)
    public currency: string;    

    /** The item sId is redundant but handy for debugging  */
    @JsonProperty('sId', String, false)
    public sId: string; 

    /** The classCode is redundant but handy for debugging */
    @JsonProperty('classCode', String, false)
    public classCode: string; 

    /** Only for booking admins and bosses who book for other users - when this array is filled, only these users will be booked */
    @JsonProperty('bookUsers', [ShoppingBasketItemUser], true)
    public bookUsers?: ShoppingBasketItemUser[] = [];

    /** Start of the class. Undefined if item is wbt,... */
    @JsonProperty('classStart', DateTimeConverter, true)
    public classStart?: Date;
    
    /** End of the class. Undefined if item is wbt,... */
    @JsonProperty('classEnd', DateTimeConverter, true)
    public classEnd?: Date;
    
    /** Location of the class. Undefined if item is wbt,... */
    @JsonProperty('classLocation', String, true)
    public classLocation?: string;
}
