import { JsonObject, JsonProperty } from 'json2typescript';

/** Response from the validateBeforePurchase api */
@JsonObject
export class ItemPurchaseValidationResponse {

    /** Item (WBT, F2F, TP) for which this response is */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** F2F/TP class for which this response is */
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    /** true if this item is valid to purchase */
    @JsonProperty('canBePurchased', Boolean, false)
    public canBePurchased: boolean = false;

    /** reason if this item is NOT valid to purchase */
    @JsonProperty('reason', String, false)
    public reason: string = '';
}