export default class SystemRoles {

    public get Guest(): string {
        return this._Guest;
    }

    public get SystemConfig(): string {
        return this._SystemConfig;
    }
    public get Admin(): string {
        return this._Admin;
    }
    public get DomainAdmin(): string {
        return this._DomainAdmin;
    }

    public get LessonAdmin(): string {
        return this._LessonAdmin;
    }

    public get CatalogAdmin(): string {
        return this._CatalogAdmin;
    }
    public get AttributeAdmin(): string {
        return this._AttributeAdmin;
    }

    public get SkillAdmin(): string {
        return this._SkillAdmin;
    }
    public get UserAdmin(): string {
        return this._UserAdmin;
    }
    public get UserImporter(): string {
        return this._UserImporter;
    }
    public get TplanAdmin(): string {
        return this._TplanAdmin;
    }
    public get ScoAdmin(): string {
        return this._ScoAdmin;
    }
    public get VcAdmin(): string {
        return this._VcAdmin;
    }
    public get Student(): string {
        return this._Student;
    }
    public get Tutor(): string {
        return this._Tutor;
    }
    public get ForumManager(): string {
        return this._ForumManager;
    }
    public get Mentor(): string {
        return this._Mentor;
    }

    public get Reporter(): string {
        return this._Reporter;
    }
    public get GtApi(): string {
        return this._GtApi;
    }
    public get AssignmentAdmin(): string {
        return this._AssignmentAdmin;
    }
    public get SchoolAdmin(): string {
        return this._SchoolAdmin;
    }
    public get WorkflowAdmin(): string {
        return this._WorkflowAdmin;
    }
    public get TrainingManager(): string {
        return this._TrainingManager;
    }
    public get F2FAdmin(): string {
        return this._F2FAdmin;
    }
    public get GlossarySuperAdmin(): string {
        return this._GlossarySuperAdmin;
    }
    public get GlossaryAdmin(): string {
        return this._GlossaryAdmin;
    }
    public get GlossarySubjectAdmin(): string {
        return this._GlossarySubjectAdmin;
    }
    public get GlossaryTermAdmin(): string {
        return this._GlossaryTermAdmin;
    }
    public get GlossaryTermTranslator(): string {
        return this._GlossaryTermTranslator;
    }
    public get GlossaryTermApprover(): string {
        return this._GlossaryTermApprover;
    }
    public get PaymentVoucherAdmin(): string {
        return this._PaymentVoucherAdmin;
    }
    public get PaymentBookingOverviewAdmin(): string {
        return this._PaymentBookingOverviewAdmin;
    }

    public static get instance(): SystemRoles {
        return this._instance || (this._instance = new this());
    }


    protected static _instance: SystemRoles | null = null;
    
    private _Admin: string = 'Admin'

    private _SystemConfig: string = 'SystemConfig';

    private _DomainAdmin: string = 'DomainAdmin';
    ///  Lesson administrator  
    private _LessonAdmin: string = 'LessonAdmin';
    ///  Catalog administrator  
    private _CatalogAdmin: string = 'CatalogAdmin';
    ///  Attribute administrator  
    private _AttributeAdmin: string = 'AttributeAdmin';
    ///  Skill administrator  
    private _SkillAdmin: string = 'SkillAdmin';
    ///  User administrator  
    private _UserAdmin: string = 'UserAdmin';
    ///  User importer  
    private _UserImporter: string = 'UserImporter';
    ///  Training plan administrator  
    private _TplanAdmin: string = 'TplanAdmin';
    ///  SCO administrator  
    private _ScoAdmin: string = 'ScoAdmin';
    ///  VC administrator  
    private _VcAdmin: string = 'VcAdmin';
    ///  Guest  
    private _Guest: string = 'Guest';
    ///  Student  
    private _Student: string = 'Student';
    ///  Tutor  
    private _Tutor: string = 'Tutor';
    ///  ForumManager  
    private _ForumManager: string = 'ForumManager';
    ///  Mentor  
    private _Mentor: string = 'Mentor';
    ///  Reporter  
    private _Reporter: string = 'Reporter';
    ///  GT-API user  
    private _GtApi: string = 'GtApi';
    ///  GT-API user  
    private _AssignmentAdmin: string = 'AssignmentAdmin';
    ///  SchoolAdmin  
    private _SchoolAdmin: string = 'SchoolAdmin';
    ///  WorkflowAdmin  
    private _WorkflowAdmin: string = 'WorkflowAdmin';
    ///  TrainingManager  
    private _TrainingManager: string = 'TrainingManager';
    ///  F2FManager  
    private _F2FAdmin: string = 'F2FAdmin';
    ///  Glossary Super Admin  
    private _GlossarySuperAdmin: string = 'GlossarySuperAdmin';
    ///  GlossaryAdmin  
    private _GlossaryAdmin: string = 'GlossaryAdmin';
    ///  GlossarySubjectAdmin  
    private _GlossarySubjectAdmin: string = 'GlossarySubjectAdmin';
    ///  GlossaryTermAdmin  
    private _GlossaryTermAdmin: string = 'GlossaryTermAdmin';
    ///  GlossaryTermTranslator  
    private _GlossaryTermTranslator: string = 'GlossaryTermTranslator';
    ///  GlossaryTermApprover  
    private _GlossaryTermApprover: string = 'GlossaryTermApprover';
    ///  PaymentVoucherAdmin  
    private _PaymentVoucherAdmin: string = 'PaymentVoucherAdmin';
    ///  PaymentBookingOverviewAdmin  
    private _PaymentBookingOverviewAdmin: string = 'PaymentBookingOverviewAdmin';
}