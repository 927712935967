import { SortableHeaderElement } from '$components/shared/SortableHeaderElement';
import { skillAquired, skillExpired, skillLevel, skillRequiredLevel, skillTitle, skillStatus } from '$src/components/Skills/SkillModel';
import { SkillTitle } from '$components/Skills/SkillTitle';
import Session from '$core/Session';
import IconSkillExpired from '$resources/svgs/skills/skill-expired.svg'
import IconSkillMet from '$resources/svgs/skills/skill-met.svg'
import IconSkillNotMet from '$resources/svgs/skills/skill-not-met.svg'
import IconSkillLow from '$resources/svgs/skills/skill-low.svg'
import IconRecommendations from '$resources/svgs/skills/recommendations.svg';
import IconRecommendationsMissing from '$resources/svgs/skills/recommendationsMissing.svg';
import { StringHelper } from '$src/util/StringHelper';
import { ESkillStatus, ESortDirection, EItemDetailCallerContextType } from '$storage/models/enums';
import React from 'react';
import { UnmountClosed } from 'react-collapse';
// import ReactDOM from 'react-dom';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';
import { Translate } from '$components/shared/Translate';
import { NavLink } from 'react-router-dom';
import { UserCertificateViewModel } from '$src/storage/models/Certificates/UserCertificateViewModel';

import Iconcertificate from '$resources/svgs/objects/certificate.svg';
import AuthService from '$src/core/Services/AuthService';
import { Attribute } from '$src/storage/models/Attribute';
import FileHelper from '$src/util/FileHelper';

interface IProps {
    bossRealtionCode?: string;
    context: EItemDetailCallerContextType;
    skill: SkillTableItem;
    isActiveHeader: boolean;
    isTargetSkill: boolean;
    userId: number;
    onActiveHeaderChanged: (avtiveHeaderId: string, sortDirection: ESortDirection) => void;
    onAssignPreselectedSkill?: (preselectedSkillId: number, preselectedLevelSetId: number | null, requiredSkillLevelId: number | null) => void;
    onCollapseStateChanged: (item: SkillItem) => void;

}

interface IState {
    activeHeaderId: string;
    show: boolean;
}

/**
 * SkillItem renders all information for one skill. It forwards the onClicked of the SkillTitle to the parent (MySkills.tsx)
 */

export class SkillItem extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            activeHeaderId: '',
            show: false
        }
    }

    public collapse() {

        if (this.state.show) {
            this.setState({ show: false })
        }
    }



    public render() {
        const { skill, isActiveHeader, onActiveHeaderChanged } = this.props
        const text = skill.skillTitle + ': ' + Session.instance.storage.translation.GetString('Skill:' + ESkillStatus[skill.skillStatus]);
        const recommendedLessonsText = Session.instance.storage.translation.GetString('Skill:RecommendedLessons').Format(skill.skillTitle);
        const recommendedLessonsMissingText = Session.instance.storage.translation.GetString('Skill:NoRecommendedLessonsFound').Format(skill.skillTitle);
        const skillDescription = this.props.skill.skillDescription ? this.props.skill.skillDescription : Session.instance.storage.translation.GetString('Skill:NoSkillDescription')
        return (
            <React.Fragment>
                <div role="row" key={'Skill' + skill.skillId} >
                    <div className="skill__table__row row__content" >
                        <div role="columnheader" className="mobileOnly">
                            <SortableHeaderElement
                                id={skillTitle}
                                isActive={isActiveHeader}
                                header="Skill:SkillName"
                                initialSortDirection={ESortDirection.Down}
                                OnSortDirectionChanged={(id, direction) => onActiveHeaderChanged(id, direction)}
                            />
                        </div>
                        <div role="cell" >
                            <SkillTitle onCollapseStateChanged={() => this.onTitleCollapseStateChanged()}
                                skill={skill} />
                        </div>

                        <UnmountClosed
                            title={this.props.skill.skillTitle}
                            isOpened={this.state.show}
                        >
                            <div key={'OverlayMobileDescription' + skill.skillId} className="skill__table____row--collapsable mobileOnly" >
                                <fieldset className="skill__details__box mobileOnly" >
                                    <legend className="skill__details__box__legend"><Translate>Skill:SkillDescription</Translate></legend>
                                    {globalConfig.skillTableProperties.enableHTMLFormatterForDescriptionField ?
                                        <span dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(skillDescription) }} /> :
                                        skillDescription
                                    }
                                </fieldset>
                            </div>
                            {this.props.skill.skillReasonForAssignment &&
                                <div key={'OverlayMobileReasonForAssignment' + skill.skillId} className="skill__table____row--collapsable mobileOnly" >

                                    <fieldset className="skill__details__box mobileOnly" >
                                        <legend className="skill__details__box__legend"><Translate>Skill:ReasonForAssignment</Translate></legend>
                                        {this.props.skill.skillReasonForAssignment}
                                    </fieldset>
                                </div>
                            }
                        </UnmountClosed>


                        {this.props.isTargetSkill &&
                            <React.Fragment><div role="columnheader" className="mobileOnly">
                                <SortableHeaderElement
                                    id={skillRequiredLevel}
                                    isActive={isActiveHeader}
                                    header="Skill:SkillRequiredLevel"
                                    initialSortDirection={ESortDirection.Down}
                                    OnSortDirectionChanged={(id, direction) => onActiveHeaderChanged(id, direction)}
                                />
                            </div>
                                <div role="cell">{skill.skillRequiredLevel} </div>
                            </React.Fragment>
                        }

                        <div role="columnheader" className="mobileOnly">
                            <SortableHeaderElement
                                id={skillLevel}
                                isActive={isActiveHeader}
                                header="Skill:SkillLevel"
                                initialSortDirection={ESortDirection.Down}
                                OnSortDirectionChanged={(id, direction) => onActiveHeaderChanged(id, direction)}
                            />
                        </div>
                        <div role="cell">{skill.skillLevel} </div>

                        <div role="columnheader" className="mobileOnly">
                            <SortableHeaderElement
                                id={skillAquired}
                                isActive={isActiveHeader}
                                header="Skill:SkillAquired"
                                initialSortDirection={ESortDirection.Down}
                                OnSortDirectionChanged={(id, direction) => onActiveHeaderChanged(id, direction)}
                            />
                        </div>
                        <div role="cell">{this.getDateString(skill.skillAquired)} </div>

                        <div role="columnheader" className="mobileOnly">
                            <SortableHeaderElement
                                id={skillExpired}
                                isActive={isActiveHeader}
                                header="Skill:SkillExpired"
                                initialSortDirection={ESortDirection.Down}
                                OnSortDirectionChanged={(id, direction) => onActiveHeaderChanged(id, direction)}
                            />
                        </div>
                        <div role="cell">{this.getDateString(skill.skillExpired)} </div>

                        <div role="columnheader" className="mobileOnly">
                            <SortableHeaderElement
                                id={skillStatus}
                                isActive={isActiveHeader}
                                header="Skill:SkillValid"
                                initialSortDirection={ESortDirection.Down}
                                OnSortDirectionChanged={(id, direction) => onActiveHeaderChanged(id, direction)}
                            />
                        </div>
                        <div role="cell"
                            data-tip={text}
                            data-for={`${skill.skillId}_skillStatusTooltip`}
                            className="small-cell">
                            {(this.props.bossRealtionCode && this.props.isTargetSkill && this.props.onAssignPreselectedSkill != null) ?
                                <a onClick={() => this.onAssignPreselectedSkill(skill.skillId, skill.skillLevelSetId, skill.skillRequiredLevelId)}>
                                    <InlineSVG
                                        aria-label={text}
                                        src={this.getStatusIcon(skill.skillStatus)} />
                                    <Tooltip id={`${skill.skillId}_skillStatusTooltip`} />
                                </a>
                                :
                                <>
                                    <InlineSVG
                                        aria-label={text}
                                        src={this.getStatusIcon(skill.skillStatus)} />
                                    <Tooltip id={`${skill.skillId}_skillStatusTooltip`} />
                                </>}

                        </div>

                        <div role="columnheader" className="mobileOnly">
                            <Translate>Skill:Recommendations</Translate>
                        </div>
                        <div role="cell"
                            className="small-cell">
                            {skill.skillNumberOfRecommendaedLessons > 0 ?
                                <div className="badge-parent">
                                    <NavLink
                                        to={`/trainings/${skill.skillId}/${this.props.userId}/0/0/0/0/0/?context=${this.props.context}${this.props.bossRealtionCode ? '&bossRelationCode=' + this.props.bossRealtionCode : ''}${skill.skillProfileId ? '&skillProfileId=' + skill.skillProfileId : ''}`}
                                        aria-label={recommendedLessonsText}
                                        data-tip={recommendedLessonsText}
                                        data-for={`${skill.skillId}_skillReccomandationTooltip`} >
                                        <InlineSVG src={IconRecommendations} />
                                        <div className="badge__filled" >{skill.skillNumberOfRecommendaedLessons}</div>
                                        <Tooltip id={`${skill.skillId}_skillReccomandationTooltip`} />
                                    </NavLink>
                                </div> :
                                <div className="badge-parent"
                                    aria-label={recommendedLessonsMissingText}
                                    data-tip={recommendedLessonsMissingText}
                                    data-for={`${skill.skillId}_skillReccomandationTooltip`} >
                                    <InlineSVG src={IconRecommendationsMissing} />
                                    <div className="badge__outlined" >{skill.skillNumberOfRecommendaedLessons}</div>
                                    <Tooltip id={`${skill.skillId}_skillReccomandationTooltip`} />
                                </div>}
                        </div>
                    </div>
                    <UnmountClosed
                        title={this.props.skill.skillTitle}
                        isOpened={this.state.show}
                    >
                        <div key={'OverlayDescription' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                            <fieldset className="skill__details__box notMobile" >
                                <legend className="skill__details__box__legend" ><Translate>Skill:SkillDescription</Translate></legend>
                                {globalConfig.skillTableProperties.enableHTMLFormatterForDescriptionField ?
                                    <span dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(skillDescription) }} />
                                    : skillDescription
                                }
                            </fieldset>
                        </div>
                        {this.props.skill.skillReasonForAssignment &&
                            <div key={'OverlayReasonForAssignment' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                                <fieldset className="skill__details__box notMobile" >
                                    <legend className="skill__details__box__legend"><Translate>Skill:ReasonForAssignment</Translate></legend>
                                    {this.props.skill.skillReasonForAssignment}
                                </fieldset>
                            </div>
                        }
                        <div key={'OverlayCertificates' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                            {this.renderUserskillCertificates()}
                        </div>
                        <div key={'OverlayMiscFiles' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                            {this.renderMiscDocuments()}
                        </div>
                    </UnmountClosed>
                </div>

            </React.Fragment>
        );

    }

    private getStatusIcon(status: ESkillStatus) {
        switch (status) {
            case ESkillStatus.LevelMissing:
                return IconSkillLow;
            case ESkillStatus.Expired:
                return IconSkillExpired;
            case ESkillStatus.Valid:
            case ESkillStatus.ExpiredButValid:
                return IconSkillMet;
            default:
                return IconSkillNotMet;
        }
    }

    private getDateString(x: Date | null) {
        if (x === null) {
            return '---';
        }
        else {
            return StringHelper.dateString(x);
        }
    }


    private onTitleCollapseStateChanged() {
        this.setState({ show: true })
        this.props.onCollapseStateChanged(this);
    }

    private onAssignPreselectedSkill(skillId: number, skillLevelSetId: number | null, levelId: number | null) {
        if (this.props.onAssignPreselectedSkill) {
            this.props.onAssignPreselectedSkill(skillId, skillLevelSetId, levelId);
        }
    }

    private renderUserskillCertificates(): JSX.Element {
        if (this.props.skill.certificates && this.props.skill.certificates.length > 0) {
            return (
                <fieldset className="skill__details__box notMobile" >
                    <legend className="skill__details__box__legend" ><Translate>Skill:Certificates</Translate></legend>

                    <ul className="no-bullets">
                        {this.props.skill.certificates.map(cert => {
                            return (
                                <li key={cert.certificate?.id}>
                                    <div className="inlineFlex">
                                        <InlineSVG src={Iconcertificate} />
                                        <a href={cert.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={async () => { await AuthService.instance.GetPing(); return true }}
                                            className="marginLeft5">
                                            {cert.certificate?.fileName}
                                        </a>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </fieldset>
            )
        } else {
            return <></>
        }
    }

    private renderMiscDocuments() {
        if (this.props.skill.attributes && this.props.skill.attributes.length > 0) {
            const miscDocuments = this.props.skill.attributes.filter(a => a.name === 'PerformanceCheck_MiscFiles');
            console.log(miscDocuments);

            return (
                <fieldset className="skill__details__box notMobile" >
                    <legend className="skill__details__box__legend" ><Translate>Skill:MiscFiles</Translate></legend>
                    <ul className="no-bullets">
                        {miscDocuments.map(misc => {
                            return (
                                <li key={misc.id} >
                                    <div className="inlineFlex">
                                        <a href="#"
                                            onClick={() => { FileHelper.openMediaFile(misc, null); return false; }}
                                            className="marginLeft5">
                                            {misc.value}
                                        </a>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </fieldset>
            )
        }

        return <></>;
    }
}

/**
 * This class defines the data structure the SkillItem components needs.
 */
export class SkillTableItem {
    public skillId: number = 0;
    public skillTitle: string = '';
    public skillDescription: string | null = null;
    public skillRequiredLevel: string | null = null;
    public skillRequiredLevelId: number | null = null;
    public skillLevel: string | null = null;
    public skillLevelSetId: number | null = null;
    public skillAquired: Date | null = null;
    public skillExpired: Date | null = null;
    public skillStatus: ESkillStatus = ESkillStatus.NotAcquired;
    public skillNumberOfRecommendaedLessons: number = 0;
    public skillReasonForAssignment: string | null = null;
    public skillProfileId?: number = undefined;
    public certificates?: UserCertificateViewModel[] = [];
    public hasAccessRight?: boolean;
    public attributes: Attribute[];
    public sortOrder: number;
}

export class SkillTableItemContainer {
    public skillTableItem: SkillTableItem[] = [];
    public hasAllShowRights: boolean = true;
}