import Logger from '$src/core/Logger';
import CatalogService from '$src/core/Services/CatalogService';
import SessionCache from '$src/storage/cache/SessionCache';
import { CatalogElement } from '$src/storage/models/Catalog';
import { isSuccess } from '$src/util/Result';

const CACHING_DURATION = 1;

/**
 * This class allows to get catalog folders (including their direct child catalog elements) from server. To improve performance 
 * catalog folders (including children) are stored in a local cache, too. 
 */
export default class CatalogStorage extends SessionCache<string, CatalogElement> {
    protected className = 'CatalogStorage';
    protected loggerLocality = 'Storage.CatalogStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    /**
     * Get catalog folder (including direct child catalog elements) from cache or server.
     * @param parentId Id of the parent catalog folder.
     */
    public async getCatalogFolder(parentId: number): Promise<CatalogElement | null> {
        const methodName = `${this.className}:getCatalogFolder()`;
        if (parentId <= 1) {
            parentId = 9999;
        }
        const strParentId = parentId.toString();
        if (this.isObjectInCache(strParentId)) {
            Logger.log(this.loggerLocality, `${methodName} getting catalogFolder from cache, parentId=${parentId}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting catalogFolder from server, parentId=${parentId}.`);
            const response = await CatalogService.instance.getCatalogFolder(parentId);
            if (isSuccess<CatalogElement>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality, `${methodName} got catalogFolder from server, parentId=${parentId}, number of elements=${elementCount}.`);
                this.saveObjectToCache(strParentId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get catalogFolder from server, parentId=${parentId}.`);
                this.removeObjectFromCache(strParentId);
            }
        }
        return this.getObjectFromCache(strParentId);
    }

    public async getCatalogFolderBySIds(catalogFolderSIDs: string[]): Promise<CatalogElement | null> {
        const methodName = `${this.className}:getCatalogFolderBySIds()`;
        const strCatalogFoldersIDs = catalogFolderSIDs.join(',');
        if (this.isObjectInCache(strCatalogFoldersIDs)) {
            Logger.log(this.loggerLocality, `${methodName} getting catalogFolder from cache, strCatalogFoldersIDs=${strCatalogFoldersIDs}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting catalogFolder from server, strCatalogFoldersIDs=${strCatalogFoldersIDs}.`);
            const response = await CatalogService.instance.getCatalogFolderBySIds(catalogFolderSIDs);
            if (isSuccess<CatalogElement>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality, `${methodName} got catalogFolder from server, strCatalogFoldersIDs=${strCatalogFoldersIDs}, number of elements=${elementCount}.`);
                this.saveObjectToCache(strCatalogFoldersIDs, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get catalogFolder from server, strCatalogFoldersIDs=${strCatalogFoldersIDs}.`);
                this.removeObjectFromCache(strCatalogFoldersIDs);
            }
        }
        return this.getObjectFromCache(strCatalogFoldersIDs);
    }

    /**
     * Rmove all catalog folders from cache.
     */
    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}
