import * as React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import Session from '$core/Session';
import { RouteComponentProps } from 'react-router';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Logger from '$core/Logger';
import TutoringReportService from '$src/core/Services/TutoringReportService';
import { EItemType } from '$storage/models/enums';
import { ItemsReportResponse } from '$storage/models/Reports/ItemsReportResponse';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import { ReportSummary } from '$components/Reporting/ReportSummary';
import { AttributeValue } from '$storage/models/AttributeValue';
import { Translate } from '$components/shared/Translate';
import { TutoringFilter } from '$storage/models/TutoringFilter';

interface IStateFilter {
    filterText: string;
    filterItemType: number;
    filterSubjectId: number;
}

interface IStateFilterData {
    filterDataSubjects: AttributeValue[] | null;
    filterDataItemTypes: EItemType[] | null;
    filterDataLoaded: boolean; 
}

interface IStateItemData {
    dataIsLoaded: boolean;
    dataIsLoading: boolean;
    data: ItemsReportResponse[] | null;
    dataCurrentPage: number;
    dataPageSize: number;
    dataHasMore: boolean;
}

interface IStateError {
    errorMessage: string;
}
interface IState extends IStateFilter, IStateFilterData, IStateItemData, IStateError {
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class ReportTutoring extends React.Component<RouteComponentProps, IState> {

    protected inputControl: HTMLInputElement | null;
    protected className = 'ReportTutoring';
    protected loggerLocality = 'Components.ReportTutoring';
    private TRANSLATION_PREFIX = 'ReportTutoring';
    private aclTutorize: number = 4;

    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            filterText: '',
            filterItemType: 0,
            filterSubjectId: 0,

            filterDataSubjects: null,
            filterDataItemTypes: [EItemType.F2FCourse, EItemType.TrainingPlan, EItemType.WBT, EItemType.Questionnaire, EItemType.CBT, EItemType.Assessment],
            filterDataLoaded: false,

            dataHasMore: false,
            dataIsLoaded: false,
            dataIsLoading: false,
            data: null,
            dataCurrentPage: 0,
            dataPageSize: 10,

            errorMessage: '',
        }

    }

    public componentDidMount() {

        const filters = Session.instance.getTutoringFilters;

        // Apply saved filter
        this.setState({
            filterText: filters != null ? filters.filterText : this.state.filterText,
            filterItemType: filters != null ? filters.filterItemType : this.state.filterItemType,
            filterSubjectId: filters != null ? filters.filterSubjectId : this.state.filterSubjectId,
        }, () => {
            // Filter Data
            const filterData = this.loadFilterData();

            // Initial Data Load if configured or needed based on filter
            let data = {};
            if (globalConfig.tutoring.tutoring.loadDataOnLoad || filters != null) {
                data = this.loadItems(this.state.dataCurrentPage, true);
            }

            Promise.all([filterData, data]);
        });
    }

    private loadFilterData = async () => {
        const subjects = await Session.instance.storage.attributeValue.getAttributeValuesAsync(16);
        this.setState({ filterDataSubjects: subjects, filterDataLoaded: true });
    }

    private loadItems = async (dataCurrentPage: number, clearItems: boolean) => {
        const items: ItemsReportResponse[] | GtError =
            await TutoringReportService.instance.getItems(
                Session.instance.getUserLanguageCodeOrFallBack,
                this.aclTutorize,
                this.state.dataPageSize,
                dataCurrentPage + 1,
                this.state.filterItemType,
                this.state.filterSubjectId,
                this.state.filterText);

        if (isSuccess<ItemsReportResponse[]>(items)) {
            Logger.log(this.loggerLocality, `Successfully loaded items from server.`);
            const moreDataToLoad = this.state.dataPageSize < items.length;
            if (moreDataToLoad) items.pop(); // Remove additonal loaded Item if needed
            this.setState({
                dataIsLoaded: true,
                dataIsLoading: false,
                data: !clearItems ? [...(this.state.data || []), ...items] : items,
                dataHasMore: moreDataToLoad,
                dataCurrentPage: dataCurrentPage + 1,
                errorMessage: ''
            });

        } else {
            const msg = 'Failed to load items from server.';
            console.error(msg);
            Logger.log(this.loggerLocality, msg);
            this.setState({ errorMessage: this.getTranslation('ErrorOcurred'), dataIsLoading: false });
        }
    }

    public render() {
        if (this.state.errorMessage.length > 0) {
            return (
                <Alert
                    alertType={'error'}
                    alertAppereance={'box'}
                    message={this.state.errorMessage} />
            );
        } else {
            return (
                <div className="l-box-container reporting__container" >
                    <div>
                        <MenuBreadCrumb
                            breadCrumbElementsToAppend={[{
                                navigationPath: this.props.location.pathname,
                                title: Session.instance.storage.translation.GetString('Navigation:ReportTutoring'),
                                translateTitle: true
                            }]}
                            routePathName="/reporting"
                            {...this.props} />
                        <div className="l-box--wide">
                            <Heading headingLevel={1} cssClass="heading__Title">
                                {this.getTranslation('Title')}
                            </Heading>
                        </div>
                        <div className="reporting__filter-actions-wrapper">
                            {this.renderTextFilter()}
                            {this.renderDropdownFilters()}
                            {this.renderApplyFilterbutton()}
                        </div>
                        {this.renderItems()}
                        {this.renderLoadMoreButton()}
                        {this.state.dataIsLoading ? <ProgressSpinner /> : null}
                    </div>
                </div>
            )
        }
    }

    private renderTextFilter = (): JSX.Element => {
        const placeholder = Session.instance.storage.translation.GetString('Filter:FilterPlaceholder');
        return (
            <div className="reporting__filter-container">
                <div className="input-title__container">
                    <label id="ReportingTextFilter" className="heading__Level4">{this.getTranslation('SearchFilterTitle')}</label>
                </div>
                <div className="input-field__container">
                    <input
                        id="txtFilter"
                        className="reporting__filter-action"
                        name="txtFilter"
                        type="text"
                        autoComplete={'on'}
                        // aria-label={Session.instance.storage.translation.GetString('Filter:FilterFieldLabel')}
                        aria-labelledby="ReportingTextFilter"
                        ref={(e) => this.inputControl = e}
                        placeholder={placeholder}
                        onChange={(e) => { this.setState({ filterText: e.target.value }) }}
                        value={this.state.filterText}
                    />
                </div>
            </div>
        );
    }

    private renderDropdownFilters = (): JSX.Element => {
        return (
            <React.Fragment>
                {/* Filter by Item Type */}
                <div className="reporting__filter-container">
                    <div className="input-title__container">
                        <label id="ReportingItemTypeFilter" className="heading__Level4">{this.getTranslation('LessonType')}</label>
                    </div>
                    <div className="input-field__container">
                        <select
                            className="reporting__filter-action"
                            onChange={e => this.setState({ filterItemType: + e.target.value })}
                            aria-labelledby="ReportingItemTypeFilter">
                            <option value="0" selected={0 === this.state.filterItemType}>{this.getTranslation('All')}</option>
                            {this.createItemTypeDropdownOptions()}
                        </select>
                    </div>
                </div>

                {/* Filter by Topic */}
                <div className="reporting__filter-container">
                    <div className="input-title__container">
                        <label id="ReportingSubjectFilter" className="heading__Level4">{this.getTranslation('Subject')}</label>
                    </div>
                    <div className="input-field__container">
                        <select
                            className="reporting__filter-action"
                            onChange={e => this.setState({ filterSubjectId: + e.target.value })}
                            aria-labelledby="ReportingSubjectFilter">
                            <option value="0" selected={0 === this.state.filterSubjectId}>{this.getTranslation('All')}</option>
                            {this.createSubjectDropdownOptions()}
                        </select>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private renderApplyFilterbutton = (): JSX.Element => {
        return (
            <div className="reporting__filter-container">
                <div className="input-title__container">
                </div>
                <div className="input-field__container">
                    <button type="button" className="btn--md btn--primary" onClick={() => this.applyFilter()} id="btnApplyFilter">
                        <Translate>ReportTutoring:ApplyFilter</Translate>
                    </button>
                </div>
            </div>
        )
    }
    private applyFilter = async () => {
        const filter = new TutoringFilter(this.state.filterText, this.state.filterItemType, this.state.filterSubjectId);
        if (filter !== null) {
            Session.instance.setTutoringFilters(filter);
        }
        this.setState({ dataIsLoading: true, data: null, dataIsLoaded: false });
        await this.loadItems(0, true);
    }

    private createItemTypeDropdownOptions = () => {
        const elements: Array<JSX.Element> = [];

        if (this.state.filterDataItemTypes != null) {
            this.state.filterDataItemTypes.forEach(itemType => {
                elements.push(
                    <option key={itemType} value={itemType} selected={itemType === this.state.filterItemType}>
                        {Session.instance.storage.translation.GetString('ItemType:' + EItemType[itemType].toString())}
                    </option>);
            });
        }
        return elements;
    }

    private createSubjectDropdownOptions = () => {
        const elements: Array<JSX.Element> = [];

        if (this.state.filterDataSubjects !== null) {
            this.state.filterDataSubjects.sort((a, b) => a.text.localeCompare(b.text)).forEach(subject => {
                elements.push(
                    <option key={subject.id} value={subject.id} selected={subject.id === this.state.filterSubjectId}>
                        {subject.text}
                    </option>);
            });
        }
        return elements;
    }

    private renderItems = () => {
        const items = this.state.data;
        if (items != null && items.length > 0) {
            return this.renderItemsList(items);
        }
        if ((items == null || items.length === 0) && this.state.dataIsLoaded) {
            let msg = '';
            if (this.state.filterText === '') {
                msg = Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:FilterNoDataFound`);
            }
            else {
                msg = Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:NoItemsFound`);
            }
            return <NoDataFound message={msg} />
        }
        return null;
    }

    private renderItemsList = (items: ItemsReportResponse[]): JSX.Element | JSX.Element[] => {
        const elements: JSX.Element[] = [];
        items.map((item, index) => {
            if (index < items.length || !this.state.dataHasMore) {
                elements.push(
                    <ReportSummary
                        {...this.props}
                        parentTrainingPlanId={0}
                        parentAssignmentId={0}
                        parentCatalogFolderId={-1}
                        key={`ReportSummary_${index}_${item.itemId}`}
                        item={item} />);
            }
        });

        return <ul>{elements}</ul>;
    }

    private renderLoadMoreButton = (): JSX.Element | null => {
        if (this.state.errorMessage.length > 0) return null;

        if (this.state.dataHasMore && !this.state.dataIsLoading) {
            return <div className="reporting__load-more-btn">
                <button
                    className="btn--md btn--primary"
                    onClick={() => this.loadNextPage()}>
                    {this.getTranslation('loadMore')}
                </button>
            </div>
        }

        return null;
    }

    private loadNextPage = async () => {
        this.setState({ dataIsLoading: true });
        await this.loadItems(this.state.dataCurrentPage, false);
    }

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }
}
export default ReportTutoring;