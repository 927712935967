import { IOSkill } from '$src/storage/models/IOSkill';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class TrainingPlanOutputSkill {


    @JsonProperty('outputSkill', IOSkill, false)
    public outputSkill: IOSkill | undefined = undefined;

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('fulfilled', Boolean, false)
    public fulfilled: boolean = false;

    @JsonProperty('isExpired', Boolean, false)
    public isExpired: boolean = false;
    
}
