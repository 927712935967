import React from 'react';

import { NavigationPoint } from '$components/layout/menu/NavigationPoint';
import { ReportHelper } from '$util/ReportHelper';

interface IProps {
    text: string;
    reportName: string;
    reportMode: string;
    id: string;
    backgroundColor?: string;
    iconColor?: string;
    userId?: number;
    bossRelationCode?: string;
}

interface IState {
    icon: string;
}

export class ReportNavigationPoint extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const link = ReportHelper.getReportLink(this.props.reportName, 
                                                this.props.reportMode, 
                                                this.props.userId,
                                                this.props.bossRelationCode)
        return <NavigationPoint 
            id={`Reporting_${this.props.id}`} 
            link={link}
            text={this.props.text}
            backgroundColor={this.props.backgroundColor} 
            iconColor={this.props.iconColor} 
            openNewWindow={true}  />
    }

}
