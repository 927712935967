import React, { HTMLProps } from "react";
import { Tooltip } from "$components/shared/Tooltip";
import { Guid } from "$src/util/Guid";
import Session from "$src/core/Session";
import InlineSVG from "react-inlinesvg";

/**
 * The GTTooltipIcon Props
 */
export interface IGTTooltipIconProps extends HTMLProps<HTMLSpanElement> {
    /**
     * Tool tip text. If undefined, the tool tip won't show.
	 * Will be load from language strings
     */
    text: string;
	/**
	 * Icon for <InlineSVG component
	 */
	src: string;
    /**
     * Height in px
     */
    size: number;
}

/**
 * Global Teach icon with tooltip component
 */
export default function GTTooltipIcon(props: IGTTooltipIconProps) {    
    const id = Guid.newGuid().toLocaleLowerCase();    
    const text = Session.instance.storage.translation.GetString(props.text) || props.text;

    return (
        <span data-tip={text} data-for={id} style={{maxHeight: props.size}} {...props}>
            <InlineSVG src={props.src} style={{height: props.size}}></InlineSVG>
            <Tooltip id={id} />
        </span>
    )
}