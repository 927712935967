import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserBossRelation {

    @JsonProperty('bossTypeCode', String, false)
    public bossTypeCode: string = '';

    @JsonProperty('bossTypeTitle', String, false)
    public bossTypeTitle: string = '';

    @JsonProperty('isDefault', Boolean, false)
    public isDefault: boolean = false;

}