import Session from '$src/core/Session'

export class NumberHelper {
    /**
     * Converts the Price as String into a number 
     * @param priceAsString 
     */
    public static priceAsDecimal(priceAsString: string): number {
        let priceAsDecimal = 0;
        const currentFormat = Session.instance.getUserLanguageCodeOrFallBack;
        switch (currentFormat) {
            case 'de-CH':
                priceAsString = priceAsString.replace(/'/, '');
                break;
            case 'en-US':
                priceAsString = priceAsString.replace(/,/, '');
                break;
            case 'de-DE':
                priceAsString = priceAsString.replace(/\./, '').replace(/,/, '.');
                break;
            case 'fr-CH':
                priceAsString = priceAsString.replace(/\s/, '').replace(/,/, '.');
                break;
            default:
                priceAsString = priceAsString.replace(/,/, '');
                break;
        }
        priceAsDecimal = parseFloat(priceAsString);
        return priceAsDecimal;
    }

    /**
     * returns the current Regex, using the current language
     */
    public static regexForPrice(): string {
        const currentFormat = Session.instance.getUserLanguageCodeOrFallBack;
        let currentStringRegex = '';
        switch (currentFormat) {
            case 'de-CH':
                currentStringRegex = '^(\\d{1,3}\'?)?(\\d{0,3})?(\\d{1,3}\\.\\d{0,2})?$';
                break;
            case 'de-DE':
                currentStringRegex = '^(\\d{1,3}\\.?)?(\\d{0,3})?(\\d{1,3},\\d{0,2})?$';
                break;
            case 'en-Us':
                currentStringRegex = '^(\\d{1,3},?)?(\\d{0,3})?(\\d{1,3}\\.\\d{0,2})?$';
                break;
            case 'fr-CH':
                currentStringRegex = '^(\\d{1,3}\\s?)?(\\d{0,3})?(\\d{1,3},\\d{0,2})?$';
                break;
            default:
                currentStringRegex = '^(\\d{1,3},?)?(\\d{0,3})?(\\d{1,3}\\.\\d{0,2})?$';
                break;
        }
        return currentStringRegex;
    }

    /**
     * Returns a properly formatted price 
     */
    public static getFormattedPrice(price: number, currency: string): string {
        return new Intl.NumberFormat(Session.instance.getUserLanguageCodeOrFallBack, 
                    { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2})
                    .format(price) + ' ' + currency;
    }

}