

import { JsonObject, JsonProperty } from 'json2typescript';
import { EVirtualMeetingsViewType } from '$storage/models/enums';
import { VirtualMeetingsViewTypeConverter } from '$storage/models/converters/VirtualMeetingLinkTypeConverter';
import { DateTimeConverter } from '$storage/models/converters/DateTimeConverter';

@JsonObject
export class VirtualMeetingLink {

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('url', String, false)
    public url: string = '';

    @JsonProperty('startDateTime', DateTimeConverter, false)
    public startDateTime: Date | undefined = undefined;

    @JsonProperty('endDateTime', DateTimeConverter, false)
    public endDateTime: Date | undefined = undefined;

    @JsonProperty('minutesOpenedBeforeStartTime', Number, false)
    public minutesOpenedBeforeStartTime: number = 0;

    @JsonProperty('type', VirtualMeetingsViewTypeConverter, false)
    public type: EVirtualMeetingsViewType = EVirtualMeetingsViewType.All;

    /** Id of the corresponding registration */
    @JsonProperty('registrationId', Number, false)
    public registrationId: number = 0;

    /** Is Auto Confirmation when opening join link enabled */
    @JsonProperty('participationAutoConfirmationForVCG', Boolean, false)
    public participationAutoConfirmationForVCG: boolean = false;
}
