import { InputAttributes } from '$components/shared/InputAttributes';
import { InputTextArea } from '$components/shared/InputTextArea';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import Logger from '$src/core/Logger';
import WorkflowActivityService from '$src/core/Services/WorkflowActivityService';
import { ECancellationType } from '$src/storage/models/enums';
import { CancellationReasonModel } from '$src/storage/models/Workflow/CancellationReasonModel';
import { isSuccess } from '$util/Result';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';

interface IProps {
    onCancellationReasonChanged?: (cancellationReasonId: number) => void;
    onCancellationReasonTextChanged?: (reason: string) => void;
    onChange?: (cancellationReasonId: number, reason: string) => void;
    cancellationReasonTextValue?: string;
    cancellationReasonId?: number;
    readonly?: boolean;
    cancellationReasonType: ECancellationType;
    itemID: number;
}

interface IState {
    dataLoaded: boolean;
    errorMessage: string;
    cancellationReasons: CancellationReasonModel[];
    cancellationReasonText: string;
    selectedCancellationReasonId?: number;
}

export class CancellationReasonSelection extends React.Component<IProps, IState> {
    protected className = 'CancellationReasonSelection';
    protected loggerLocality = 'Components.CancellationReasonSelection';
    constructor(props: IProps) {
        super(props);
        this.state = {
            cancellationReasonText: '',
            cancellationReasons: [],
            dataLoaded: false,
            errorMessage: '',
            selectedCancellationReasonId: 0
        }

        this.onCancellationReasonChangedInternal = this.onCancellationReasonChangedInternal.bind(this);
        this.onCancellationReasonTextChangedInternal = this.onCancellationReasonTextChangedInternal.bind(this);
        this.onChangeInternal = this.onChangeInternal.bind(this);
    }

    public async componentDidMount() {
        await this.loadCancellationReasons();
    }

    public render() {
        if (!this.state.dataLoaded) {
            return (
                <ProgressSpinner isSmall={true} />
            )
        } else {
            const cancellationReasonTextAttributes: InputAttributes =
            {
                attributeValueType: undefined,
                class: '',
                editMode: true,
                hasFocus: true,
                id: 'cancellationReasonText',
                initialValidation: true,
                isReadOnly: this.props.readonly ? this.props.readonly : false,
                isRequired: globalConfig.registrationCancellation.isCancellationReasonTextMandatory,
                label: 'CancellationReason:CancellationReasonText',
                labelClassName: 'heading__Level5',
                placeholder: Session.instance.storage.translation.GetString('CancellationReason:CancellationReasonTextPlaceholder'),
                regExpression: undefined,
                rows: 5,
                maxLength: 255,
                url: undefined,
                value: this.props.cancellationReasonTextValue ? this.props.cancellationReasonTextValue : '',
            };
            return <div className="cancellation-reason-selection">
                <div className="cancellation-reason-selection__reason">
                    <DropDownList
                        data={this.state.cancellationReasons}
                        textField="title"
                        value={this.state.cancellationReasons.filter(c => c.id === this.props.cancellationReasonId)[0]}
                        onChange={(e) => this.onCancellationReasonChangedInternal(e)}
                        required={globalConfig.registrationCancellation.isCancellationReasonTextMandatory}
                        label={Session.instance.storage.translation.GetString('CancellationReason:CancellationReason')}
                        disabled={this.props.readonly}
                    />
                    {(this.state.selectedCancellationReasonId == null ||
                        this.state.selectedCancellationReasonId === 0) &&
                        (this.props.cancellationReasonId && this.props.cancellationReasonId === 0) ?
                        <span key={'DropDownList_CancellationReason_ErrorMsg'} className={'input-message error'}>
                            <Translate>ErrorMessage:Required</Translate>
                        </span> : null}
                </div>
                <div className="cancellation-reason-selection__reason-text">
                    <InputTextArea
                        attributes={cancellationReasonTextAttributes}
                        onChange={(id: string, value: string) => (this.onCancellationReasonTextChangedInternal(value))} />
                </div>
                <div className="cancellation-reason-selection__error">
                    <Translate>{this.state.errorMessage}</Translate>
                </div>
            </div>
        }
    }

    private async loadCancellationReasons() {
        const methodName = `${this.className}:componentDidMount()`;
        // Cancel Registration : Load data
        const cancellationReasons = await WorkflowActivityService.instance.getCancellationReasonsByType(this.props.cancellationReasonType, this.props.itemID);
        if (isSuccess<CancellationReasonModel[]>(cancellationReasons)) {
            this.setState({ cancellationReasons, dataLoaded: true });
        }
        else {
            const errorMessage = `${methodName} failed to load cancellation reasons, exception ${cancellationReasons.message}`
            Logger.log(this.loggerLocality, errorMessage);
            this.setState({ errorMessage: 'ErrorMessage:LoadCancellationReasonsFailed', dataLoaded: true });
            console.error(errorMessage);
        }
    }

    private onCancellationReasonChangedInternal(event: DropDownListChangeEvent) {
        const selectedValue: CancellationReasonModel = event.target.value;
        this.setState({ selectedCancellationReasonId: selectedValue.id })
        if (this.props.onCancellationReasonChanged != null) {
            this.props.onCancellationReasonChanged(selectedValue.id);
        }
        this.onChangeInternal(selectedValue.id, this.state.cancellationReasonText);
    }

    private onCancellationReasonTextChangedInternal(reason: string) {
        this.setState({ cancellationReasonText: reason });
        if (this.props.onCancellationReasonTextChanged != null) {
            this.props.onCancellationReasonTextChanged(reason);
        }
        this.onChangeInternal(this.state.selectedCancellationReasonId || 0, reason);
    }

    private onChangeInternal(cancellationReasonId: number, reason: string) {
        if (this.props.onChange != null) {
            this.props.onChange(cancellationReasonId, reason);
        }
    }
}