import { observer } from 'mobx-react';
import React from 'react';
import { UnmountClosed } from 'react-collapse';
import InlineSVG from 'react-inlinesvg';

import { FileSharingUploadPanel } from '$src/components/fileSharing/FileSharingUploadPanel';
import { HeadingCollapsible } from '$src/components/shared/HeadingCollapsible';
import { Translate } from '$src/components/shared/Translate';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import FileSharingService from '$src/core/Services/FileSharingService';
import Session from '$src/core/Session';
import { FileSharingDocument } from '$src/storage/models/FileSharing/FileSharingDocument';
import { FileSharingLesson } from '$src/storage/models/FileSharing/FileSharingLesson';
import { FileSharingUser } from '$src/storage/models/FileSharing/FileSharingUser';
import { Item } from '$src/storage/models/Item';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';

import Icondownloadall from '$resources/svgs/filesharing/download-all.svg';
import Icondownload from '$resources/svgs/filesharing/download.svg';
import { StringResponse } from '$src/storage/models/StringResponse';
import { FileSharingTag } from '$src/storage/models/FileSharing/FileSharingTag';
import FileSharingDocumentTags from '$src/components/fileSharing/FileSharingDocumentTags';
import { CheckBox } from '$src/components/shared/CheckBox';
import GTIconButton from '$components/shared/Atoms/GTIconButton';
import TagEditIcon from '$resources/svgs/misc/tag_edit.svg';
import { EFileSharingSortEnum, ESortDirection } from '$src/storage/models/enums';
import SortingAlgorithm from '$src/util/SortingAlgorithm';
import { Sortable } from '@progress/kendo-react-sortable';
import IconDragVertical from '$resources/svgs/misc/drag-vertical.svg';
import { StoreFileSharingDocumentOrderRequest } from '$src/storage/models/RequestObjects/FileSharing/StoreFileSharingDocumentOrderRequest';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';

interface IProps {
    lesson: Item;
    fileSharing: FileSharingLesson;
    fileSharingUser: FileSharingUser;
    availableFileSharingTags: FileSharingTag[];
    onCbxDeleteIsClicked: (document: FileSharingDocument) => void;
    onReload?: () => void;
    searchText: string;
    sortingMode: EFileSharingSortEnum;
}

interface IState {
    isCollapsed: boolean;
    tempDownloadUrl: string | null;
    fileSharingUser: FileSharingUser;
    downloadTarget: string;
    errorMessage: string | null;
    deleteLoading: boolean;
    editDocumentId: number;
    userIsMe: boolean;
}

@observer // observing Session.instance.storage.registeredItem.isInitialized
export class FileSharingUserPanel extends React.Component<IProps, IState> {
    protected userId = Session.instance.loginUser ? Session.instance.loginUser.id : 0;
    protected allowSortDocuments = false;
    constructor(props: IProps) {
        super(props);

        this.state = {
            deleteLoading: false,
            downloadTarget: '',
            errorMessage: null,
            fileSharingUser: props.fileSharingUser,
            isCollapsed: false,
            tempDownloadUrl: null,
            editDocumentId: 0,
            userIsMe: props.fileSharingUser.userId === this.userId
        };
    }

    public UNSAFE_componentWillReceiveProps(props: IProps) {
        this.setState({ fileSharingUser: props.fileSharingUser });
    }

    public render() {

        return (
            <React.Fragment>
                <div className="item-detail__file-sharing__user-panel">
                    <HeadingCollapsible
                        headingLevel={3}
                        containerCssClass=""
                        headingCssClass="heading__Level3"
                        isOpened={!this.state.isCollapsed}
                        onToggleOpenedState={() => this.setState({ isCollapsed: !this.state.isCollapsed })}>
                            <>
                        {this.state.fileSharingUser.name}
                        {this.state.fileSharingUser.isTutor.value ? <Translate>FileSharing:UserIsTutor</Translate> : null}
                        {this.state.userIsMe ? <Translate>FileSharing:UserIsMe</Translate> : null}
                        </>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={!this.state.isCollapsed}>
                        {this.state.userIsMe && this.renderSortingInfo()}
                        {this.renderUploadPanel()}
                        {this.renderDocuments()}
                        {this.renderDownloadAll()}
                    </UnmountClosed>
                </div>
            </React.Fragment >
        );
    }

    protected renderSortingInfo(): JSX.Element {
        if (this.props.availableFileSharingTags.length > 0) {
            return (
                <p><Translate>FileSharing:FileSharingSortingInfo</Translate></p>
            )
        } else {
            return (
                <p><Translate>FileSharing:FileSharingSortingInfoWithoutTags</Translate></p>
            )
        }
    }

    protected renderUploadPanel() {
        if (!this.state.fileSharingUser.allowUpload) {
            return null;
        }

        return (
            <FileSharingUploadPanel
                fileSharingUser={this.state.fileSharingUser}
                fileSharing={this.props.fileSharing}
                allowedFileExtensions={this.props.fileSharing ? this.props.fileSharing.allowedFileExtensionsString.valueOf() : ''}
                onDocumentUploaded={this.onDocumentUploaded} />
        );
    }

    protected renderDocuments() {
        const documents = [...this.state.fileSharingUser.documents];
        const filteredDocuments = this.props.searchText.length > 0 ? documents.filter(doc =>
            doc.fileSharingTags.filter(tag =>
                tag.tagName.toLowerCase().includes(this.props.searchText.toLowerCase())).length > 0) :
            documents;
        if (this.props.sortingMode !== EFileSharingSortEnum.Manually) {
            const sortOrder = this.props.sortingMode === EFileSharingSortEnum.Ascending ? ESortDirection.Up : ESortDirection.Down
            filteredDocuments.sort((a, b): number => {
                return SortingAlgorithm.sortByColumnAndDirection(a, b, 'name', sortOrder);
            });
        }

        this.allowSortDocuments = this.state.fileSharingUser.documents.length === filteredDocuments.length
            && this.props.sortingMode === EFileSharingSortEnum.Manually;

        if (filteredDocuments.length <= 0) {
            return null;
        }
        return (
            <React.Fragment>
                <div className="document-table" role="table">
                    <div role="rowgroup">
                        <div className="tr" role="row">
                            <div role="columnheader" className="th">
                                <Translate>FileSharing:Name</Translate>
                                <ErrorMessage errorMessage={this.state.errorMessage} />
                            </div>
                            <div role="columnheader" className="th min">
                                <Translate>FileSharing:Size</Translate>
                            </div>
                            <div role="columnheader" className="th min">
                                <Translate>FileSharing:ModifyDate</Translate>
                            </div>
                            <div role="columnheader" className="th min">

                                {this.state.fileSharingUser.documents.some(document => document.allowDelete == true) ?
                                    <Translate>FileSharing:Actions</Translate> :
                                    <span className="spacer"></span>}

                            </div>
                            {this.props.availableFileSharingTags.length > 0 &&
                                <div role="columnheader" className="th min">

                                    {this.state.userIsMe ?
                                        <Translate>FileSharing:EditTag</Translate> :
                                        <span className="spacer"></span>}

                                </div>}
                        </div>
                    </div>
                    <div role="rowgroup">
                        {
                            this.state.userIsMe && this.allowSortDocuments ?
                                <Sortable
                                    idField="id"
                                    navigation={true}
                                    data={filteredDocuments}
                                    onDragOver={(event) => this.reorderDocuments(event.newState as FileSharingDocument[])}
                                    onDragEnd={(event) => this.storeFileSharingDocumentOder(event.newState as FileSharingDocument[])}
                                    onNavigate={(event) => this.storeFileSharingDocumentOder(event.newState as FileSharingDocument[])}
                                    itemUI={this.sortableItemUI}
                                /> :
                                filteredDocuments.map(document => {
                                    return this.renderDocumentRow(document);
                                })
                        }
                    </div>
                </div>

                {this.renderTempDownloadAnchor()}
            </React.Fragment>
        );
    }

    protected renderDocumentRow(document: FileSharingDocument) {
        return (
            <div key={`DocumentRow_${document.id}`}>
                <div key={document.id.valueOf()} className="tr" role="row">
                    <div className="td" role="cell">
                        {this.state.userIsMe && this.allowSortDocuments && <InlineSVG src={IconDragVertical} />}
                        {this.renderDownloadButton(document)}
                    </div>
                    <div className="td min" role="cell">
                        {StringHelper.formatFileSize(document.size.valueOf())}
                    </div>
                    <div className="td min" role="cell">
                        {StringHelper.dateString(document.modifiedAt)}
                    </div>
                    <div className="td min" role="cell">
                        {
                            document.allowDelete ?
                                <CheckBox id={"cbDelete_" + document.id} onClick={() => this.onCbxDeleteClicked(document)}
                                    ariaLabel={Session.instance.storage.translation.GetString('FileSharing:DeleteCheckbox')} /> :
                                <span className="spacer" />
                        }
                    </div>
                    <div className="td min" role="cell">
                        {this.state.userIsMe && this.props.availableFileSharingTags.length > 0 ?
                            <GTIconButton
                                id={`EditTags_${document.id}`}
                                ariaLabel={this.state.editDocumentId == document.id ?
                                    Session.instance.storage.translation.GetString('FileSharing:FinishEdit') :
                                    Session.instance.storage.translation.GetString('FileSharing:EditTagForDocument').Format(document.name)}
                                tooltipText={this.state.editDocumentId == document.id ?
                                    Session.instance.storage.translation.GetString('FileSharing:FinishEdit') :
                                    Session.instance.storage.translation.GetString('FileSharing:EditTagForDocument').Format(document.name)}
                                onClick={() => this.setState({ editDocumentId: document.id == this.state.editDocumentId ? 0 : document.id })}>
                                <InlineSVG src={TagEditIcon} />
                            </GTIconButton> :
                            <span className="spacer"></span>
                        }
                    </div>
                </div>
                <FileSharingDocumentTags
                    document={document}
                    availableFileSharingTags={this.props.availableFileSharingTags}
                    isReadonly={document.id != this.state.editDocumentId}
                    assignmentId={0}
                    tPlanId={0}
                    scheduleId={0}
                    itemId={this.props.lesson.itemId}
                    onReload={this.props.onReload} />
            </div>
        );
    }

    protected onCbxDeleteClicked = (document: FileSharingDocument) => {
        document.isCbxClicked = !document.isCbxClicked;
        this.props.onCbxDeleteIsClicked(document);
    }

    protected renderDownloadButton(document: FileSharingDocument) {
        if (!document.allowDownload) {
            return <span className="download-link">{document.name}</span>;
        }

        return (
            <button className="link-button download-link" onClick={(e) => this.downloadClicked(e, document)}>
                <InlineSVG src={Icondownload} />&nbsp;{document.name}
            </button>
        );
    }

    protected renderDownloadAll() {
        let anyFiles: number = 0;
        this.state.fileSharingUser.documents.forEach((document) => {
            anyFiles = anyFiles + (document.allowDownload.valueOf() ? 1 : 0);
        });
        if (anyFiles <= 1) {
            return null;
        }

        return (
            <button className="link-button" onClick={(e) => this.downloadClicked(e, null)}>
                <InlineSVG src={Icondownloadall} />&nbsp;<Translate>FileSharing:DownloadAll</Translate>
            </button>
        );
    }


    /**
     * Invisible html anchor that will be populated with the download token for a file download after clicking the download link
     * Since the download token will be generated for each download request, we need this workaround
     */
    protected renderTempDownloadAnchor() {
        if (!this.state.tempDownloadUrl) {
            return null;
        }

        return (
            <a id="fs-download" href={this.state.tempDownloadUrl} style={{ 'display': 'none' }} target="_blank">Download</a>
        );
    }

    /* FUNCTIONS */

    /**
     * Get download token, populate invisible anchor and click it
     * @param documentIds The ids of the FileSharingDocument entries that will be downloaded
     */
    protected download = async (documentIds: number[]) => {
        if (!this.props.fileSharing) {
            return;
        }

        const token = await FileSharingService.instance.getDownloadToken(
            documentIds,
            this.props.lesson.itemId,
            this.props.fileSharing.context.assignmentId,
            this.props.fileSharing.context.tPlanId,
            this.props.fileSharing.context.scheduleId);
        const target: string = '_blank';

        if (isSuccess<StringResponse>(token)) {
            const url = `${FileSharingService.instance.getUrl()}filesharing/download/${token.value}`;
            this.setState({ tempDownloadUrl: url, downloadTarget: target }, () => {
                const link: HTMLElement = document.getElementById('fs-download') as HTMLAnchorElement;
                if (link) {
                    link.click();
                    this.setState({ tempDownloadUrl: null });
                }
            });
        } else {
            this.setState({ errorMessage: 'FileSharing:Error_LoadingDownloadToken' });
        }
    }

    /* EVENTS */

    /**
     * Get download token of one or some files
     * @param fileSharingDocument if null, then download all
     */
    protected downloadClicked = async (event: React.FormEvent<HTMLButtonElement>, fileSharingDocument: FileSharingDocument | null) => {
        let ids: number[] = [];
        if (!fileSharingDocument) {
            this.state.fileSharingUser.documents.forEach(document => {
                ids.push(document.id.valueOf());
            });
        } else {
            ids = [fileSharingDocument.id.valueOf()]
        }

        await this.download(ids);
    }

    /**
     * A new document was uploaded. Add it to the documents list
     * @param document The new FileSharingDocument entry
     */
    protected onDocumentUploaded = (document: FileSharingDocument) => {
        const stateFileSharingUser = { ...this.state.fileSharingUser };
        // add to state, if not already in list, otherwise update

        const overwrittenFileIndex = stateFileSharingUser.documents.findIndex(d => d.id === document.id);
        if (overwrittenFileIndex != null && overwrittenFileIndex > -1) {
            // Replace overwritten document
            const removedFile = stateFileSharingUser.documents.splice(overwrittenFileIndex, 1, document)
            // Recalculate space
            stateFileSharingUser.usedSpace = stateFileSharingUser.usedSpace.valueOf() + document.size.valueOf() - removedFile[0].size.valueOf();
        }
        else {
            // Add new document
            stateFileSharingUser.documents.push(document);
            // Recalculate Space
            stateFileSharingUser.usedSpace = stateFileSharingUser.usedSpace.valueOf() + document.size.valueOf();
        }
        this.setState({ fileSharingUser: stateFileSharingUser })
    }

    private sortableItemUI = (props: any) => {
        const { isDisabled, isActive, style, attributes, dataItem, forwardRef } =
            props;
        const classNames = [];

        if (isDisabled) {
            classNames.push("k-state-disabled");
        }

        return (
            <div
                ref={forwardRef}
                {...attributes}
                style={{ ...style }}
                className={isActive ? 'item-detail__file-sharing__user-panel__sortable-row__isActive' : ''}
            >
                {this.renderDocumentRow(dataItem)}
            </div>
        );
    };

    private async storeFileSharingDocumentOder(reorderedDocuments: FileSharingDocument[]) {
        const fileSharingUser = this.state.fileSharingUser;
        const request: StoreFileSharingDocumentOrderRequest[] = [];
        reorderedDocuments.map(doc => {
            request.push({
                documentId: doc.id,
                sortOrder: reorderedDocuments.indexOf(doc) + 1
            })
        });
        await FileSharingService.instance.storeFileSharingDocumentOrder(this.props.lesson.itemId, request, 0, 0, 0)
            .then(resp => {
                let errorMessage = '';
                if (isSuccess<BooleanResponse>(resp)) {
                    if (!resp.status) {
                        errorMessage = 'FileSharing:FailedToSortDocument';
                    } else {
                        fileSharingUser.documents = reorderedDocuments;
                    }
                } else {
                    errorMessage = 'FileSharing:FailedToSortDocument';
                }
                this.setState({ errorMessage, fileSharingUser });
            });
    }

    private reorderDocuments(reorderedDocuments: FileSharingDocument[]) {
        const fileSharingUser = this.state.fileSharingUser;
        fileSharingUser.documents = reorderedDocuments;
        this.setState({ fileSharingUser });
    }
}