import Session from "$src/core/Session";

export const getEstimateDurationText = (estimateDuration: number) => {
    const tr = Session.instance.storage.translation;
    let estimateDurationText = '';
    if (estimateDuration !== undefined) {
        if (estimateDuration > 59) {
            if (estimateDuration % 60 !== 0) {
                if (globalConfig.trainingPlanProperties.displayLearningTimeAsDay && estimateDuration > 1440) {
                    estimateDurationText = tr.GetString('EstimateDuration:EstimateDaysAndHoursAndMinutes').Format(Math.trunc(estimateDuration / 1440).toString(), Math.trunc(estimateDuration / 60 % 24).toString(), (estimateDuration % 60).toString());

                } else {
                    estimateDurationText = tr.GetString('EstimateDuration:EstimateHoursAndMinutes').Format(Math.trunc(estimateDuration / 60).toString(), (estimateDuration % 60).toString());
                }
            } else {
                if (globalConfig.trainingPlanProperties.displayLearningTimeAsDay && estimateDuration > 1440) {
                    estimateDurationText = tr.GetString('EstimateDuration:EstimateDaysAndHours').Format(Math.trunc(estimateDuration / 1440).toString(), Math.trunc(estimateDuration / 60 % 24).toString());
                } else {
                    estimateDurationText = tr.GetString('EstimateDuration:EstimateHours').Format(Math.trunc(estimateDuration / 60).toString());
                }
            }
        }
        else {
            estimateDurationText = tr.GetString('EstimateDuration:EstimateMinutes').Format(estimateDuration.toString());
        }
    }
    return estimateDurationText;
}