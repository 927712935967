import { CheckBox } from '$src/components/shared/CheckBox';
import { DatePicker } from '$components/shared/DatePicker';
import { Heading } from '$src/components/shared/Heading';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import TeamService from '$src/core/Services/TeamService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Deputy } from '$src/storage/models/Deputy';
import { DeputyDeleteRequest } from '$src/storage/models/RequestObjects/DeputyDeleteRequest';
import { DeputyUpdateRequest } from '$src/storage/models/RequestObjects/DeputyUpdateRequest';
import { isSuccess } from '$src/util/Result';
import * as React from 'react';
import InlineSVG from 'react-inlinesvg';

import IconDelete from '$resources/svgs/misc/delete.svg';
import IconEdit from '$resources/svgs/navi/settings.svg';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import DateHelper from '$src/util/DateHelper';

interface IProps {
    parentHeadingLevel: number;
    bossRelationCode: string;
    refreshDeputies: boolean;
}

interface ISerivceResponse {
    deletedDeputy: Deputy | undefined;
}

interface IEditRow {
    editableDeputyId: number | undefined;
    editedValidFrom: Date | undefined;
    editedValidTo: Date | undefined;
    edititedIsNotificationOn: boolean;
}

interface IState extends IEditRow, ISerivceResponse {
    errorMessage: string | undefined;
    deputies: Deputy[] | undefined;
    dataLoaded: boolean;
}

/**
 * Displays a list of all the employees of this user
 */
export class DeputyList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            deletedDeputy: undefined,
            deputies: undefined,
            editableDeputyId: undefined,
            editedValidFrom: undefined,
            editedValidTo: undefined,
            errorMessage: undefined,
            edititedIsNotificationOn: false
        }
    }

    public componentDidMount() {
        this.loadDeputies(this.props.bossRelationCode);
    }

    public UNSAFE_componentWillReceiveProps(props: IProps) {
        if ((this.props.refreshDeputies !== props.refreshDeputies) || this.props.bossRelationCode !== props.bossRelationCode) {
            this.loadDeputies(props.bossRelationCode);
            this.setState({ deletedDeputy: undefined });
        }
    }

    public render() {
        let ele: JSX.Element;
        if (this.state.dataLoaded && this.state.deputies != null && this.state.deputies.length > 0) {
            ele = this.renderTable();
        } else if (this.state.dataLoaded && this.state.deputies != null && this.state.deputies.length === 0) {
            ele = <NoDataFound message={Session.instance.storage.translation.GetString('Deputy:NoDeputies')} />
        } else if (!this.state.dataLoaded) {
            ele = <ProgressSpinner />
        } else {
            ele = <React.Fragment />
        }

        return (
            <div>
                <Heading headingLevel={this.props.parentHeadingLevel + 1} cssClass="heading__Level3">
                    <Translate>Deputy:MyDeputies</Translate>
                </Heading>
                {ele}
            </div>
        );
    }


    private renderTable(): JSX.Element {
        const tableRows: JSX.Element[] = [];
        if (this.state.deputies != null) {
            this.state.deputies.map((dep, index) => {
                tableRows.push(this.renderTableRow(index, dep));
            });
        }
        return (
            <div>
                {this.state.errorMessage !== undefined ?
                    (
                        <ErrorMessage errorMessage={this.state.errorMessage} />
                    ) : ('')}
                {this.state.deletedDeputy !== undefined ?
                    (
                        <Alert
                            alertType={'success'}
                            alertAppereance={'single-line'}
                            message={Session.instance.storage.translation.GetString('Deputy:Deleted').Format(
                                this.state.deletedDeputy != null ? (
                                    this.state.deletedDeputy.firstName + ' ' + this.state.deletedDeputy.lastName) : (''))} />
                    ) : ('')}
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row zebra">
                            <div role="columnheader" className="notMobile">
                                <Translate>Deputy:Name</Translate>
                            </div>
                            <div role="columnheader" className="notMobile">
                                <Translate>Deputy:ValidFrom</Translate>
                            </div>
                            <div role="columnheader" className="notMobile">
                                <Translate>Deputy:ValidTo</Translate>
                            </div>
                            <div role="columnheader" className="notMobile">
                                <Translate>Deputy:Notification</Translate>
                            </div>
                            <div role="columnheader" className="notMobile">
                                {''}
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        {tableRows}
                    </div>
                </div>

            </div>);
    }

    private renderTableRow(index: number, dep: Deputy): JSX.Element {
        return (
            <div key={index} role="row" className="div-table__horizontal-table-row zebra">
                <div role="columnheader" className="mobileOnly">
                    <Translate>Deputy:Name</Translate>
                </div>
                <div role="cell">
                    {dep.firstName + ' ' + dep.lastName}
                </div>
                <div role="columnheader" className="mobileOnly">
                    <Translate>Deputy:ValidFrom</Translate>
                </div>
                <div role="cell">{this.state.editableDeputyId === dep.id ?
                    (
                        <React.Fragment>
                            <DatePicker
                                hasFocus={true}
                                onChange={e => this.onValidFromChanged(e)}
                                value={this.state.editedValidFrom === undefined ? null : this.state.editedValidFrom} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {dep.validFrom != null ? DateHelper.toDateString(dep.validFrom) : '~'}
                        </React.Fragment>
                    )}

                </div>
                <div role="columnheader" className="mobileOnly">
                    <Translate>Deputy:ValidTo</Translate>
                </div>
                <div role="cell">{this.state.editableDeputyId === dep.id ?
                    (
                        <React.Fragment>
                            <DatePicker
                                onChange={e => this.onValidToChanged(e)}
                                value={this.state.editedValidTo === undefined ? null : this.state.editedValidTo} />
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {dep.validTo != null ? DateHelper.toDateString(dep.validTo) : '~'}
                        </React.Fragment>
                    )}

                </div>
                <div role="columnheader" className="mobileOnly">
                    <Translate>Deputy:Notification</Translate>
                </div>
                <div role="cell">
                    {this.state.editableDeputyId === dep.id ?
                        <CheckBox id="cbIsWithNotification"
                            defaultChecked={this.state.edititedIsNotificationOn}
                            onClick={() => {
                                const edititedIsNotificationOn = this.state.edititedIsNotificationOn;
                                this.setState({ edititedIsNotificationOn: !edititedIsNotificationOn })
                            }} />
                        :
                        <CheckBox id="cbIsWithNotification"
                            disabled={true}
                            defaultChecked={dep.isNotificationOn} />
                    }
                </div>
                <div role="columnheader" className="mobileOnly">
                    {''}
                </div>
                <div role="cell">
                    {this.state.editableDeputyId === undefined || this.state.editableDeputyId !== dep.id ? (
                        <React.Fragment>
                            <button onClick={e => this.toggleEditableRow(e, dep)} className={'button-link'}
                                aria-label={Session.instance.storage.translation.GetString('Deputy:Edit')}>
                                <InlineSVG src={IconEdit} />
                            </button>
                            <button onClick={e => this.deleteDeputy(e, dep)} className={'button-link'}
                                aria-label={Session.instance.storage.translation.GetString('Deputy:Delete')} >
                                <InlineSVG src={IconDelete} />
                            </button>
                        </React.Fragment>) :
                        <div className="deputy-table__actionbuttons">
                            <button onClick={() => this.saveDeputy()} className="btn--sm btn--secondary my-team__action">
                                <Translate>Deputy:Save</Translate>
                            </button>
                            <button onClick={() => this.cancelSaving()} className="btn--sm btn--secondary my-team__action">
                                <Translate>Deputy:Cancel</Translate>
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }

    private onValidToChanged(date: Date | null): void {
        this.setState({ editedValidTo: date == null ? undefined : date });
    }

    private onValidFromChanged(date: Date | null): void {
        this.setState({ editedValidFrom: date == null ? undefined : date });
    }

    private cancelSaving(): void {
        this.setState({ editableDeputyId: undefined, editedValidTo: undefined, editedValidFrom: undefined, edititedIsNotificationOn: false });
    }

    private deleteDeputy(event: React.FormEvent<HTMLButtonElement>, dep: Deputy): void {
        const req: DeputyDeleteRequest = new DeputyDeleteRequest();
        req.bossRelationCode = this.props.bossRelationCode;
        req.id = dep.id;

        TeamService.instance.deleteDeputy(req).then(resp => {
            if (isSuccess<BooleanResponse>(resp)) {
                if (resp.status) {
                    this.setState({ deletedDeputy: dep, errorMessage: undefined });
                    this.loadDeputies(this.props.bossRelationCode);
                } else {
                    this.setState({ errorMessage: 'Deputy:DeleteError', deletedDeputy: undefined });
                }
            } else {
                this.setState({ errorMessage: 'Deputy:DeleteError', deletedDeputy: undefined });
            }
        });
    }

    private async saveDeputy(): Promise<void> {
        if (this.state.editableDeputyId != null && this.state.editedValidFrom != null) {
            const req: DeputyUpdateRequest = new DeputyUpdateRequest();
            req.validFrom = DateHelper.toUtcDate(this.state.editedValidFrom);
            if (this.state.editedValidTo != null) {
                req.validTo = DateHelper.toUtcDate(this.state.editedValidTo);
            } else {
                req.validTo = undefined;
            }
            req.id = this.state.editableDeputyId;
            req.bossRelationCode = this.props.bossRelationCode;
            req.isNotificationOn = this.state.edititedIsNotificationOn;
            const response = await TeamService.instance.updateDeputy(req);
            if (isSuccess<BooleanResponse>(response)) {
                if (!response.status) {
                    this.setState({ errorMessage: 'Deputy:UpdateError', deletedDeputy: undefined });
                } else {

                    this.setState({ errorMessage: undefined, deletedDeputy: undefined });
                    this.loadDeputies(this.props.bossRelationCode);
                }
            }
        }
        this.setState({ editableDeputyId: undefined, editedValidTo: undefined, editedValidFrom: undefined, edititedIsNotificationOn: false });
    }

    private toggleEditableRow(event: React.FormEvent<HTMLButtonElement>, dep: Deputy): void {
        this.setState({ editableDeputyId: dep.id, editedValidFrom: dep.validFrom, editedValidTo: dep.validTo, edititedIsNotificationOn: dep.isNotificationOn });
    }

    private loadDeputies(bossRelationCode: string): void {
        TeamService.instance.getDeputiesByBossRelation(bossRelationCode).then(res => {
            if (isSuccess<Deputy[]>(res)) {
                this.setState({ deputies: res, dataLoaded: true, errorMessage: undefined });
            } else {
                this.setState({ errorMessage: 'Deputy:LoadError', dataLoaded: true });
            }
        });
    }
}