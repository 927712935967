import React from 'react';

import Iconcancelled from '$resources/svgs/status/cancelled.svg';
import Iconaccepted from '$resources/svgs/status/confirmed.svg';
import Iconconflict from '$resources/svgs/status/conflict.svg';
import Iconwarning from '$resources/svgs/status/warning.svg';
import Iconlocked from '$resources/svgs/status/locked.svg';
import IconnotRegistered from '$resources/svgs/status/not-registered.svg';
import Iconrejected from '$resources/svgs/status/rejected.svg';
import Iconrequested from '$resources/svgs/status/requested.svg';
import IconwaitingList from '$resources/svgs/status/waiting-list.svg';

import Session from '$core/Session';
import { EItemType, ERegistrationStatus } from '$storage/models/enums';
import { Item } from '$storage/models/Item';
import InlineSVG from 'react-inlinesvg';
import { StringHelper } from '$src/util/StringHelper';
import { Tooltip } from '$src/components/shared/Tooltip';

interface IProps {
    item: Item;
    textVisible: boolean;
    classId?: number;
    classTitle?: string;
    hasRegisterRight?: boolean;
    canSelfRegister?: boolean;
    isInRegistrationPeriod?: boolean;
    hasOverlappingRegistration?: boolean;
    checkingDateOfStartCondition?: Date;
}
interface IState {
    registrationStatus: ERegistrationStatus;
}

export class ToolbarRegistrationStatus extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)

        let regStat = ERegistrationStatus.Undefined;

        if (this.props.classId != null) {
            if (this.props.item.allRegistrations != null && this.props.item.allRegistrations.length > 0) {
                const relatedRegistration = this.props.item.allRegistrations.find(r => r.classId === this.props.classId);
                if (relatedRegistration != null) {
                    regStat = relatedRegistration.registrationStatus;
                }
            }
        }
        else {
            regStat = this.props.item.registrationStatus;
        }
        this.state = { registrationStatus: regStat }
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        const item = this.props.item;
        let icon = '';
        let text: string = '';
        let hoverText = '';
        let hoverLockedReason = '';

        if (item.itemType === EItemType.TrainingPlan) {
            hoverText = tr.GetString('ItemDetail:LessonTPlanStatus');
        } else {
            hoverText = tr.GetString('ItemDetail:RegistrationStatus');
        }
        if ((item.isLocked && this.props.item.isRegistered === false)
            || this.props.hasRegisterRight === false && this.props.item.isRegistered === false
            || this.props.isInRegistrationPeriod === false && this.props.item.isRegistered === false
            || this.props.canSelfRegister === false && this.props.item.isRegistered === false) {
            icon = Iconlocked;
            text = tr.GetString('LessonStatus:Locked');
            const startLockedReasonText = ' ' + tr.GetString('LessonStatus:LockedReason') + ':\r\n';
            if (this.props.hasRegisterRight === false || this.props.canSelfRegister === false) {
                hoverLockedReason = startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonPermissionMissing') + '\r\n';
            }
            if (item.isLockedDueToRequiredSkills) {
                if(this.props.checkingDateOfStartCondition && this.props.checkingDateOfStartCondition >= new Date()) {
                    text = tr.GetString('RegistrationStatus:NotRegistered');
                    hoverLockedReason += `\r\n ${tr.GetString('LessonStatus:StartConditionCheck').Format(StringHelper.dateString(this.props.checkingDateOfStartCondition))} \r\n`;
                    icon = Iconwarning;
                } else {
                    hoverLockedReason += startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonRequiredSkillsMissing') + '\r\n';
                }
            }
            if (item.isLockedDueToAssignments) {
                hoverLockedReason += startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonAssignmentNotInLearningPeriod') + '\r\n';
            }
            if (this.props.isInRegistrationPeriod === false) {
                hoverLockedReason += startLockedReasonText + '- ' + tr.GetString('LessonStatus:LockedReasonNotInRegistrastionPeriod') + '\r\n';
            }
        }
        else {
            switch (this.state.registrationStatus) {
                case ERegistrationStatus.Requested:
                    icon = Iconrequested;
                    text = tr.GetString('RegistrationStatus:Requested');
                    break;
                case ERegistrationStatus.Accepted:
                    icon = Iconaccepted;
                    text = tr.GetString('RegistrationStatus:Accepted');
                    break;
                case ERegistrationStatus.Rejected:
                    icon = Iconrejected;
                    text = tr.GetString('RegistrationStatus:Rejected');
                    break;
                case ERegistrationStatus.Cancelled:
                    icon = Iconcancelled;
                    text = tr.GetString('RegistrationStatus:Cancelled');
                    break;
                case ERegistrationStatus.ScheduleCancelled:
                    icon = Iconcancelled;
                    text = tr.GetString('RegistrationStatus:ScheduleCancelled');
                    break;
                case ERegistrationStatus.InWaitingList:
                    icon = IconwaitingList;
                    text = tr.GetString('RegistrationStatus:InWaitingList');
                    break;
                default:
                    if (this.props.hasOverlappingRegistration) {
                        icon = Iconconflict;
                    } else {
                        icon = IconnotRegistered;
                    }
                    text = tr.GetString('RegistrationStatus:NotRegistered');
                    break;
            }
        }
        if (this.props.item.itemType === EItemType.Document && (item.isLocked === false)) {
            return <div className="l-box__toolbar__icon lesson-tools lesson-tools--status" />;
        } else {
            if (this.props.classTitle != null && this.props.classTitle !== '') {
                hoverText = hoverText + ' ' + this.props.classTitle + ': ' + text;
            } else {
                hoverText = hoverText + ' ' + this.props.item.title + ': ' + text;
            }
            
            if (hoverLockedReason) {
                hoverText += hoverLockedReason.slice(0, hoverLockedReason.lastIndexOf('\r\n'));
                hoverText = StringHelper.htmlFormat(hoverText);
            }

            if (this.props.hasOverlappingRegistration) {
                hoverText += `\r\n(${tr.GetString('Course:Conflicts')})`;
            }
            return (
                <React.Fragment>
                    <div data-tip={true} data-for={`${this.props.item.itemId}_${this.props.classId}_toolbarRegistrationStatus_hoverText`} aria-label={hoverText}
                        className="l-box__toolbar__icon lesson-tools lesson-tools--status lesson-tools--status--icon-desc">
                        <InlineSVG src={icon} />
                        {this.props.textVisible ? text : null}
                    </div>
                    <Tooltip id={`${this.props.item.itemId}_${this.props.classId}_toolbarRegistrationStatus_hoverText`} html={true} >
                        {hoverText}
                    </Tooltip>
                </React.Fragment>

            )
        }
    }
}
