import React from 'react';

import { TrainingPlanElementsContents } from '$src/components/trainingPlan/trainingPlanElements/TrainingPlanElementsContents';
import { TrainingPlanElementsModulSummary } from '$src/components/trainingPlan/trainingPlanElements/TrainingPlanElementsModulSummary';
import { TrainingPlanElementsNavigation } from '$src/components/trainingPlan/trainingPlanElements/TrainingPlanElementsNavigation';
import Session from '$src/core/Session';
import { EItemType, ERegistrationStatus } from '$src/storage/models/enums';
import { TrainingPlan } from '$src/storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import { StringHelper } from '$src/util/StringHelper';
import { EItemDetailCallerContextType } from '$storage/models/enums';
import { RouteComponentProps } from 'react-router';

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IProps extends RouteComponentProps<{}> {
    trainingPlan: TrainingPlan | null;
    trainingPlanElements: TrainingPlanElement[] | null;
    activeElement?: TrainingPlanElement;
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
}

interface IState {
    activeElement: TrainingPlanElement | null;
}
/**
 * @deprecated The method should not be used
 */
export class TrainingPlanElementsContainer extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { activeElement: this.props.activeElement != null ? this.props.activeElement : null };
        this.onTrainingPlanElementSelectedInternal = this.onTrainingPlanElementSelectedInternal.bind(this);
    }

    public render() {
        let warning = ''; 
        const tr = Session.instance.storage.translation;
        if (this.props.trainingPlan != null) {
            switch (this.props.trainingPlan.registrationStatus) {
                case ERegistrationStatus.Undefined:
                case ERegistrationStatus.NotRegistered:
                case ERegistrationStatus.Cancelled:
                    warning = tr.GetString('TrainingPlan:RegistrationHint');
                    break;
                case ERegistrationStatus.Requested:
                    warning = tr.GetString('TrainingPlan:RegistrationConfirmationHint');
                    break;
                default:
                    if (!this.props.trainingPlan.isInLearningPhase) {
                        const learningPeriodFrom =  this.props.trainingPlan.learningPeriodStartDate ? StringHelper.dateString(this.props.trainingPlan.learningPeriodStartDate) : '';
                        const learningPeriodTo = this.props.trainingPlan.learningPeriodEndDate ? StringHelper.dateString(this.props.trainingPlan.learningPeriodEndDate) : '';
                        warning =  tr.GetString('TrainingPlan:NotInLearningPhase').Format(learningPeriodFrom, learningPeriodTo);
                    }
                    break;
            }
        }
        return (
            < React.Fragment >
                <span className={'input-message'}>
                    {warning}
                </span>
                <TrainingPlanElementsNavigation
                    activeElement={this.state.activeElement}
                    trainingPlan={this.props.trainingPlan}
                    trainingPlanElements={this.props.trainingPlanElements}
                    onNavigation={this.onTrainingPlanElementSelectedInternal} />
                <TrainingPlanElementsModulSummary activeElement={this.state.activeElement} />
                {this.renderTPContents()}
            </React.Fragment >);
    }

    private renderTPContents() {
        if (this.props.trainingPlan != null) {
            return <TrainingPlanElementsContents
                trainingPlan={this.props.trainingPlan}
                trainingPlanElements={this.props.trainingPlanElements}
                onTrainingPlanModulSelected={(trainingPlanElement) => this.onTrainingPlanElementSelectedInternal(trainingPlanElement)}
                activeElement={this.state.activeElement}
                history={this.props.history}
                location={this.props.location}
                match={this.props.match}
                itemDetailCallerContextType={this.props.itemDetailCallerContextType}
                parentAssignmentId={this.props.parentAssignmentId}
                parentCatalogFolderId={this.props.parentCatalogFolderId}
            />
        }
        else { return null }
    }

    private onTrainingPlanElementSelectedInternal(trainingPlanElement: TrainingPlanElement | undefined) {
        if (trainingPlanElement === undefined) {
            this.setState({ activeElement: null }); // Root
        }
        else if (trainingPlanElement.type === EItemType.Block) {
            this.setState({ activeElement: trainingPlanElement });
        }
    }
}