import Logger from '$src/core/Logger';
import CertificateService from '$src/core/Services/CertificateService';
import Session from '$src/core/Session';
import { UserCertificateValidated } from '$src/storage/models/Certificates/UserCertificateValidated';
import TranslationStorage from '$src/storage/TranslationStorage';
import { isError, isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ProgressSpinner } from '../shared/ProgressSpinner';
import { ErrorMessage } from '../shared/WarningsAndErrors/ErrorMessage';
import { ValidateUserCertificateDetail } from './ValidateUserCertificateDetail';

interface IValidateUserCertificateProps {
    translationStorage: TranslationStorage;
}


export const ValidateUserCertificate: React.FC<IValidateUserCertificateProps> = (props: IValidateUserCertificateProps) => {
    const [certificate, setCertificate] = useState<UserCertificateValidated>();
    const [validating, setValidating] = useState<boolean>(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [errorValidating, setErrorValidating] = useState<boolean>(false);
    const [noAccess, setNoAccess] = useState<boolean>(false);
    const [validationFailed, setValidateionFailed] = useState<boolean>(false);
    const userIdParam = useParams<{ userId: string }>();
    const userCertificateIdParam = useParams<{ userCertificateId: string }>();
    const checksumParam = useParams<{ checksum: string }>();

    const loggerLocality = 'ValidateUserCertificate';

    useEffect(() => {
        async function fetchData() {
            setValidating(true);
            const userId: number = userIdParam != null && !isNaN(Number(userIdParam.userId)) ? Number(userIdParam.userId) : 0;
            const userCertificateId: number = userCertificateIdParam != null && !isNaN(Number(userCertificateIdParam.userCertificateId)) ? Number(userCertificateIdParam.userCertificateId) : 0;
            const checksum: string = checksumParam.checksum;

            if(userId === 0 || userCertificateId == 0 || checksum == null || checksum.length == 0) {
                setErrorValidating(true);
            }
            else {
                await CertificateService.instance.getValidatedUserCertificate(userId,userCertificateId,checksum, Session.instance.getUserLanguageCodeOrFallBack)
                    .then((result) => {
                        if (isSuccess<UserCertificateValidated>(result)) {
                            setCertificate(result);
                            setErrorValidating(false);
                        }
                        else if (isError(result)) {
                            console.log(result);
                            console.log(result.statusCode);
                            setNoAccess(result.statusCode === 405);
                            setValidateionFailed(result.statusCode === 400);
                            setErrorValidating(true);
                            Logger.log(loggerLocality, `failed to get data`);
                            console.error(`${loggerLocality} failed to get data`);
                        }
                        else {
                            setErrorValidating(true);
                            Logger.log(loggerLocality, `failed to get data`);
                            console.error(`${loggerLocality} failed to get data`);
                        }
                    });
            }
            setValidating(false);
            setValidated(true);
        }
        fetchData();
    }, [userIdParam, userCertificateIdParam, checksumParam]);

    return (
        <div className='validate-user-certificate__detail'>
            {errorValidating && (!noAccess && !validationFailed) && <ErrorMessage appearance='box' errorMessage='Common:ErrorLoadingData' />}
            {errorValidating && noAccess && <ErrorMessage appearance='box' errorMessage='ValidateUserCertificate:ErrorLoadingDataNoAccess' />}
            {errorValidating && validationFailed && <ErrorMessage appearance='box' errorMessage='ValidateUserCertificate:ErrorValidationFailed' />}
            {certificate != null && <ValidateUserCertificateDetail certificate={certificate} translationStorage={props.translationStorage} />}
            {validating && !validated && <ProgressSpinner />}
        </div>
    )
}