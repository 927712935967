import { ExternalCourse } from '$src/storage/models/ExternalCourse/ExternalCourse';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import { ExternalCourseSchedule } from '$src/storage/models/ExternalCourse/ExternalCourseSchedule';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseRequest {

    @JsonProperty('provider', ExternalCourseProvider, false)
    public provider: ExternalCourseProvider;

    @JsonProperty('course', ExternalCourse, false)
    public course: ExternalCourse;

    @JsonProperty('schedule', ExternalCourseSchedule, false)
    public schedule: ExternalCourseSchedule;

    @JsonProperty('fileCollection', [FileDataRequest], false)
    public fileCollection: FileDataRequest[];

    @JsonProperty('requestReason', String, false)
    public requestReason: string;

    @JsonProperty('createdCourseDocuments', [FileDataRequest], false)
    public createdCourseDocuments: FileDataRequest[];

    @JsonProperty('selectedBossId', Number, false)
    public selectedBossId: number;
}