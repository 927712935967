import { CSSProperties } from 'react';

/** This is an Element used in the Breadcrumb. */
export class BreadCrumbElement {

    public navigationPath: string = '';

    public title: string = '';

    public style?: CSSProperties;

    public translateTitle?: boolean = false;

}
