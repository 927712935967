import { JsonObject, JsonProperty } from 'json2typescript';
import { RegistrationStatusConverter } from '../../converters/RegistrationStatusConverter';
import { ERegistrationStatus } from '../../enums';

@JsonObject
export class ExternalCourseUpdateRegistrationStatus {

    @JsonProperty('registrationId', Number, false)
    public registrationId: number;

    @JsonProperty('userId', Number, false)
    public userId: number;

    @JsonProperty('bossComment', String, false)
    public bossComment: string;

    @JsonProperty('externalCourseClassId', Number, false)
    public externalCourseClassId: number;

    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus;
}