import { ExternalCourseRequestData } from '$components/externalCourse/ExternalCourseRequestData';
import { ExternalCourseScheduleTable } from '$components/externalCourse/ExternalCourseScheduleTable';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import React from 'react';
import { SummaryAttribute } from '../shared/Attributes/SummaryAttribute';


interface IProps {
    externalCourseRegistration: ExternalCourseRegistration;
    parentHeadingLevel: number;
    isBossView: boolean;
}


export class ExternalCourseRequestRegistration extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const externalCourse = this.props.externalCourseRegistration.externalCourse;

        return (
            <React.Fragment>
                <div className="step-content__block">
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                        <Translate>ExternalCourse:CourseProvider</Translate>
                    </Heading>
                    {this.props.externalCourseRegistration.externalCourseProvider &&
                        <span>{this.props.externalCourseRegistration.externalCourseProvider.name}</span>
                    }
                </div>
                <div className="step-content__block">
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                        <Translate>ExternalCourse:Course</Translate>
                    </Heading>
                    {this.props.externalCourseRegistration.externalCourse &&
                        <span>{this.props.externalCourseRegistration.externalCourse.title}</span>
                    }

                </div>

                {externalCourse && externalCourse.description !== '' &&
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:CourseDescription</Translate>
                        </Heading>
                        <SummaryAttribute summary={externalCourse.description} />
                    </div>}

                <div className="step-content__block">
                    {this.props.externalCourseRegistration.externalCourseClass &&
                        <ExternalCourseScheduleTable
                            externalCourseSchedule={this.props.externalCourseRegistration.externalCourseClass}
                            showRequestStatus={true}
                            externalCourseRequestStatus={this.props.externalCourseRegistration.registrationStatus}
                        />
                    }

                </div>

                <ExternalCourseRequestData
                    externalCourseRegistration={this.props.externalCourseRegistration}
                    parentHeadingLevel={this.props.parentHeadingLevel}
                    isBossView={this.props.isBossView} />

            </React.Fragment>
        )
    }

}