import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from './converters/DateTimeConverter';

@JsonObject
export class News {

    @JsonProperty('pK_News', Number, false)
    public pkNews: number = 0;

    @JsonProperty('priority', Number, false)
    public priority: number = 0;

    @JsonProperty('size', Number, false)
    public size: number = 0;

    @JsonProperty('dateFrom', DateTimeConverter, false)
    public dateFrom: Date = new Date();

    @JsonProperty('dateTo', DateTimeConverter, false)
    public dateTo: Date | null = new Date();
    
    @JsonProperty('language', String, false)
    public language: string = '';

    @JsonProperty('html', String, false)
    public html: string = '';
    
    @JsonProperty('popupText', String, false)
    public popupText: string = '';

    @JsonProperty('backgroundImageLargeID', Number, false)
    public backgroundImageLargeID: number = 0;
    
    @JsonProperty('backgroundImageMiddleID', Number, false)
    public backgroundImageMiddleID: number = 0;
    
    @JsonProperty('backgroundImageSmallID', Number, false)
    public backgroundImageSmallID: number = 0;

    public divStyle: React.CSSProperties = {};

    public getImageNumber(screenSizeType: string): number {
        let imageID: number = 0;
        if (screenSizeType === 'small') {
            imageID = this.backgroundImageSmallID;
        } else if (screenSizeType === 'middle') {
            imageID = this.backgroundImageMiddleID;
        } else if (screenSizeType === 'large') {
            imageID = this.backgroundImageLargeID;
        }

        return imageID;
    }
}
