import React from 'react';

import Session from '$src/core/Session';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import IconAndText from '$src/components/shared/Atoms/IconAndText';
import IconTime from '$resources/svgs/toolbar/time.svg';
import TranslationStorage from '$src/storage/TranslationStorage';

interface IProps {
    trainingPlanElement: TrainingPlanElement;
    translationStorage: TranslationStorage;
}

const getEstimateDurationText = (estimateDuration: number) => {
    const tr = Session.instance.storage.translation;
    let estimateDurationText = '';
    if (estimateDuration !== undefined) {
        if (estimateDuration > 59) {
            if (estimateDuration % 60 !== 0) {
                estimateDurationText = tr.GetString('EstimateDuration:EstimateHoursAndMinutes').Format(Math.trunc(estimateDuration / 60).toString(), (estimateDuration % 60).toString());
            } else {
                estimateDurationText = tr.GetString('EstimateDuration:EstimateHours').Format(Math.trunc(estimateDuration / 60).toString());
            }
        }
        else {
            estimateDurationText = tr.GetString('EstimateDuration:EstimateMinutes').Format(estimateDuration.toString());
        }
    }
    return estimateDurationText;
}

export default function ToolbarTrainingPlanElementDurationV2(props: IProps) {

    const text = getEstimateDurationText(props.trainingPlanElement.estimateDuration);
    const hoverText = props.translationStorage.GetString('ItemDetail:LearningDuration') + ': ' + text;
    return (
        <IconAndText
            id={"ttpeduration_" + props.trainingPlanElement.sId}
            icon={IconTime}
            ariaLabel={hoverText}
            tooltipText={hoverText}
            iconText={text}
            cssClass="duration"
        />
    )
}
