import { isSuccess } from "$src/util/Result";
import React, { useEffect, useState } from "react";
import { UserInterests } from "$src/storage/models/UserInterests/UserInterests"
import AttributeService from "$src/core/Services/AttributeService";
import { AttributeValue } from '$src/storage/models/AttributeValue';
import UserInterestsAdministrationSubject from "$src/components/userInterestsAdministration/UserInterestsAdministrationSubject";

interface IProps {
    userInterests: Array<UserInterests>;
    onItemsDelete: (userInterest: UserInterests[]) => Promise<void>;
    onItemInsert: (idAttributeValue: number, orderNumber: number) => Promise<void>;
}

export default function UserInterestsAdministrationSubjects(props: IProps) {
    const [isLoadedSubjects, setIsLoadedSubjects] = useState<boolean>(false);
    const [subjectsData, setSubjectsData] = useState<Array<AttributeValue>>([]);


    useEffect(() => {
        AttributeService.instance.getAttributeValues(16).then(subjects => {
            if (!isSuccess(subjects)) {
                setIsLoadedSubjects(true);
            }
            else {
                const s = subjects as Array<AttributeValue>;
                setSubjectsData([...s]);
                setIsLoadedSubjects(true);
            }
        });
    }, [])

    return <>
        { isLoadedSubjects && <>
            <div className="user-interests__setting">
                <UserInterestsAdministrationSubject 
                userInterests={props.userInterests} 
                orderNumber={1}
                subjectsData={subjectsData}
                onItemInsert={props.onItemInsert}
                onItemsDelete={props.onItemsDelete} />
            </div>
            <div className="user-interests__setting">
            <UserInterestsAdministrationSubject 
                userInterests={props.userInterests} 
                orderNumber={2}
                subjectsData={subjectsData}
                onItemInsert={props.onItemInsert}
                onItemsDelete={props.onItemsDelete} />
            </div>
        </>
        }
    </>;

}