import { Translate } from '$components/shared//Translate';
import {
    GridCellProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { KendoGridCellHelper } from './KendoGridCellHelper';

interface IProps extends GridCellProps {
    trueText: string;
    falseText: string;
}

export const KendoBoolGridCell: React.FC<IProps> = (props: IProps) => {
        const value = KendoGridCellHelper.getValue(props);
        return <td>
            {
                typeof value === 'boolean' ?
                <Translate>{value === true ? props.trueText : props.falseText }</Translate> : 
                null
            }
        </td>
}