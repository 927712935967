import Logger from '$core/Logger';
import Session from '$core/Session';
import SessionCache from '$storage/cache/SessionCache';
import { UserSkill } from '$storage/models/Skill/UserSkill';
import { PerformanceSupportPanelBoxModel } from '$src/components/PerformanceSupport/PerformanceSupportPanelBox';
import { UserSkillProfileSkill } from '$storage/models/SkillProfile/UserSkillProfileSkill';
import { ESkillStatus } from '$storage/models/enums';
import DateHelper from '$src/util/DateHelper';

const CACHING_DURATION = 300;

export default class PerformanceSupportStorage extends SessionCache<number, PerformanceSupportSkills> {
    protected className = 'PerformanceSupportStorage';
    protected loggerLocality = 'Storage.PerformanceSupportStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getPerformanceSupportSkills(onlySkillWithLessons?: boolean, expiringInDaysFromNow?: number, requestedUserId?: number): Promise<PerformanceSupportSkills | null> {
        const methodName = `${this.className}:getPerformanceSupportSkills()`;
        const userId = requestedUserId ? requestedUserId : Session.instance.loginUser!.id;

        if (this.isObjectInCache(userId)) {
            Logger.log(this.loggerLocality, `${methodName} getting user performance support skill from storage for userId=${userId}.`);
        }
        else {
            const expiredTargetSkills: PerformanceSupportPanelBoxModel[] = [];
            const soonExpiringTargetSkills: PerformanceSupportPanelBoxModel[] = [];
            const missingLevelTargetSkills: PerformanceSupportPanelBoxModel[] = [];

            const userSkillAndTargetSkill = await this.getUserSkillAndTargetSkillData(userId, "");

            let userSkills = userSkillAndTargetSkill!.userSkills;
            let targetSkills = userSkillAndTargetSkill!.targetSkills;

            if (onlySkillWithLessons) {
                targetSkills = targetSkills.filter(sk => sk.numberOfRecommendedLessons > 0);
                if (userSkills) {
                    userSkills = userSkills.filter(sk => sk.numberOfRecommendedLessons > 0);
                }
            }

            let expiringDate: Date;
            if (expiringInDaysFromNow) {
                const futureDate = new Date();
                futureDate.setDate(futureDate.getDate() + expiringInDaysFromNow);
                expiringDate = futureDate;
            }

            targetSkills.map(targetSkill => {
                const userSkill = userSkills && userSkills.find(us => us.skillId === targetSkill.skillId);
                if (userSkill != null && userSkill.skillLevelValue >= targetSkill.skillProfileSkillLevelValue) {
                    if (userSkill.skillStatus === ESkillStatus.Expired) {
                        expiredTargetSkills.push({
                            SkillId: targetSkill.skillId,
                            SkillTitle: targetSkill.skillTitle,
                            UserSkillLevelTitle: userSkill.skillLevelTitle,
                            TargetSkillLevelTitle: targetSkill.skillProfileSkillLevelTitle,
                            ExpirationDate: userSkill.expirationDate || undefined,
                            MayBeExpired: targetSkill.mayBeExpired,
                            skillProfileId: targetSkill.skillProfileId
                        });
                    }
                    else if (userSkill.skillStatus === ESkillStatus.Valid && userSkill.expirationDate != null
                        && DateHelper.CompareDate(expiringDate, userSkill.expirationDate) >= 0) {
                        soonExpiringTargetSkills.push({
                            SkillId: targetSkill.skillId,
                            SkillTitle: targetSkill.skillTitle,
                            UserSkillLevelTitle: userSkill.skillLevelTitle,
                            TargetSkillLevelTitle: targetSkill.skillProfileSkillLevelTitle,
                            ExpirationDate: userSkill.expirationDate,
                            MayBeExpired: targetSkill.mayBeExpired,
                            skillProfileId: targetSkill.skillProfileId
                        });
                    }
                }
                else {
                    missingLevelTargetSkills.push({
                        SkillId: targetSkill.skillId,
                        SkillTitle: targetSkill.skillTitle,
                        UserSkillLevelTitle: userSkill?.skillLevelTitle,
                        TargetSkillLevelTitle: targetSkill.skillProfileSkillLevelTitle,
                        ExpirationDate: userSkill?.expirationDate || undefined,
                        MayBeExpired: targetSkill.mayBeExpired,
                        skillProfileId: targetSkill.skillProfileId
                    });
                }
            });

            //Add userskills that have no target skills
            if (userSkills && userSkills.length > 0) {
                userSkills.map(userSkill => {
                    if (userSkill.skillStatus === ESkillStatus.Expired) {
                        expiredTargetSkills.push({
                            SkillId: userSkill.skillId,
                            SkillTitle: userSkill.skillTitle,
                            UserSkillLevelTitle: userSkill.skillLevelTitle,
                            TargetSkillLevelTitle: userSkill.skillLevelTitle,
                            ExpirationDate: userSkill.expirationDate || undefined,
                            MayBeExpired: false
                        });
                    }
                    if (userSkill.skillStatus === ESkillStatus.Valid && userSkill.expirationDate != null
                        && DateHelper.CompareDate(expiringDate, userSkill.expirationDate) >= 0) {
                        soonExpiringTargetSkills.push({
                            SkillId: userSkill.skillId,
                            SkillTitle: userSkill.skillTitle,
                            UserSkillLevelTitle: userSkill.skillLevelTitle,
                            TargetSkillLevelTitle: userSkill.skillLevelTitle,
                            ExpirationDate: userSkill.expirationDate,
                            MayBeExpired: false
                        });
                    }
                })
            }

            expiredTargetSkills.sort((skill1, skill2) => DateHelper.CompareDate(skill1.ExpirationDate, skill2.ExpirationDate));
            soonExpiringTargetSkills.sort((skill1, skill2) => DateHelper.CompareDate(skill1.ExpirationDate, skill2.ExpirationDate));
            missingLevelTargetSkills.sort((skill1, skill2) => skill1.SkillTitle.localeCompare(skill2.SkillTitle));

            const performanceSupportSkills: PerformanceSupportSkills = {
                expiredTargetSkills,
                soonExpiringTargetSkills,
                missingLevelTargetSkills
            };

            Logger.log(this.loggerLocality, `${methodName} save user performance skills to storage for userId=${userId}.`);
            this.saveObjectToCache(userId, performanceSupportSkills);
        }

        return this.getObjectFromCache(userId);
    }

    public async getUserSkillAndTargetSkillData(requestedUserId?: number, bossRelationCode?: string): Promise<UserSkillAndTargetSkill | undefined> {
        const userId = requestedUserId ? requestedUserId : Session.instance.loginUser!.id;
        let userSkillAndTargetSkill: UserSkillAndTargetSkill | undefined = undefined;
        await Promise.all([
            Session.instance.storage.userSkillProfile.getUserSkillProfiles(userId, false, bossRelationCode),
            Session.instance.storage.userTargetSkill.getUserTargetSkills(userId, false, bossRelationCode),
            Session.instance.storage.userSkill.getUserSkills(userId),
        ]).then((results) => {
            const userProfiles = results[0];
            let targetSkillsProfileSkills: UserSkillProfileSkill[] = [];
            userProfiles?.map(userProfile => {
                if (userProfile.skillContainer !== undefined) {
                    userProfile.skillContainer.userSkillProfileSkills.map(skill => skill.skillProfileId = userProfile.skillProfileId)
                    targetSkillsProfileSkills = targetSkillsProfileSkills.concat(userProfile.skillContainer.userSkillProfileSkills);
                }
            });

            userSkillAndTargetSkill = {
                targetSkills: results[1] ? results[1].userSkillProfileSkills.concat(targetSkillsProfileSkills) : targetSkillsProfileSkills,
                userSkills: results[2]
            }
        });

        return userSkillAndTargetSkill;
    }


    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}

export interface UserSkillAndTargetSkill {
    userSkills: UserSkill[] | null;
    targetSkills: UserSkillProfileSkill[];
}

export interface PerformanceSupportSkills {
    expiredTargetSkills: PerformanceSupportPanelBoxModel[];
    soonExpiringTargetSkills: PerformanceSupportPanelBoxModel[];
    missingLevelTargetSkills: PerformanceSupportPanelBoxModel[];
}