import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { UserSkillCommentUpdateRequest } from '$src/storage/models/RequestObjects/UserSkillCommentUpdateRequest';
import { ItemSkillDisplay } from '$src/storage/models/Skill/ItemSkillDisplay';
import { ItemSkillLevelTranslatedDisplay } from '$src/storage/models/Skill/ItemSkillLevelTranslatedDisplay';
import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import { UserSkillProfileSkillContainer } from '$src/storage/models/SkillProfile/UserSkillProfileSkillContainer';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';
import { UserSkillCommentCreateRequest } from '$src/storage/models/RequestObjects/UserSkillCommentCreateRequest';
import { Skill } from '$src/storage/models/Skill/Skill';
import { AddUserTargetSkillRequest } from '$src/storage/models/RequestObjects/AddUserTargetSkillRequest';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import { UserSkillRequest } from '$src/storage/models/RequestObjects/UserSkillRequest';
import { PerformanceReviewCommentResponse } from '$src/storage/models/PerformanceReviewCommentResponse';

export default class SkillService extends ServiceClient {
    protected static _instance: SkillService | null = null;

    protected className = 'SkillService';
    protected loggerLocality = 'SkillService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): SkillService {
        return this._instance || (this._instance = new this());
    }

    public async getSkill(skillId: number, language: string): Promise<Skill | GtError> {
        const response: Skill | GtError = await this.get<Skill>('skill', Skill, undefined,
            [{ name: 'language', value: language },
            { name: 'skillId', value: skillId.toString() }]);

        return response;
    }


    //get all skill which are approved and the user doesnt have
    public async getAssignableSkills(language: string, bossRelationCode?: string): Promise<Skill[] | GtError> {
        const response: Skill[] | GtError = await this.get<Skill[]>('assignableSkills', Skill, undefined,
            [{ name: 'language', value: language },
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}]);
        return response;
    }

    public async getUserSkills(language: string, userId?: number, bossRelationCode?: string): Promise<UserSkill[] | GtError> {
        let response: UserSkill[] | GtError;
        if (userId && bossRelationCode) {
            response = await this.get<UserSkill[]>('userSkills', UserSkill, undefined,
                [{ name: 'language', value: language },
                { name: 'userId', value: userId.toString() },
                { name: 'bossRelationCode', value: bossRelationCode}]);
        } else {
            response = await this.get<UserSkill[]>('userSkills', UserSkill, undefined,
                [{ name: 'language', value: language }]);
        }

        return response;
    }

    public async getSkillsWithAssignRight(language: string, bossRelationCode: string): Promise<ItemSkillDisplay[] | GtError> {
        const response = await this.get<ItemSkillDisplay[]>('itemSkillsWithAssignRight', ItemSkillDisplay, undefined,
            [{ name: 'language', value: language },
            { name: 'bossRelationCode', value: bossRelationCode}]);
        return response;
    }

    public async getSkillLevelsTranslated(language: string, skillLevelSetId: number): Promise<ItemSkillLevelTranslatedDisplay[] | GtError> {
        const response = await this.get<ItemSkillLevelTranslatedDisplay[]>('skillLevelsTranslated', ItemSkillLevelTranslatedDisplay,
            undefined, [{ name: 'language', value: language }], skillLevelSetId.toString());
        return response;
    }

    public async getUserTargetSkills(language: string, userId: number, bossRelationCode?: string): Promise<UserSkillProfileSkillContainer | GtError> {
        const response = await this.get<UserSkillProfileSkillContainer>('targetSkills', UserSkillProfileSkillContainer, undefined,
            [{ name: 'language', value: language }, { name: 'userId', value: userId.toString() },
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}]);
        return response;
    }

    //get all targetskills for the user where the status is not undefined
    public async getUserTargetSkillsForPerformanceCheck(language: string, userId: number, bossRelationCode?: string): Promise<UserSkillProfileSkill[] | GtError> {
        const response = await this.get<UserSkillProfileSkill[]>('targetSkillsForPerformanceCheck', UserSkillProfileSkill, undefined,
            [{ name: 'language', value: language }, { name: 'userId', value: userId.toString() },
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}]);
        return response;
    }

    //get all targetskills for the user where the status is not undefined
    public async getUserSkillsForPerformanceCheck(language: string, userId: number, bossRelationCode?: string): Promise<UserSkill[] | GtError> {
        const response = await this.get<UserSkill[]>('actualSkillsForPerformanceCheck', UserSkill, undefined,
            [{ name: 'language', value: language }, { name: 'userId', value: userId.toString() },
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}]);
        return response;
    }

    public async updateUserSkillComments(request: UserSkillCommentUpdateRequest): Promise<PerformanceReviewCommentResponse | GtError> {
        return await this.post<PerformanceReviewCommentResponse>('userSkillComments/update', request, PerformanceReviewCommentResponse, undefined);
    }

    //add a new target skill to the user with status requested
    public async addUserTargetSkill(request: AddUserTargetSkillRequest): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('addUserTargetSkill', request, BooleanResponse, undefined);
    }

    public async createUserSkillComments(request: UserSkillCommentCreateRequest) {
        return await this.post<BooleanResponse>('userSkillComments/create', request, BooleanResponse, undefined);
    }

    public async requestUserSkill(request: UserSkillRequest): Promise<BooleanResponse | GtError> {
        const formData = new FormData();
        let key: keyof UserSkillRequest;
        for (key in request) {
            if (key == "fileCollection") {
                const aF = request[key];
                aF.forEach(f => {
                    formData.append("[fileName]:" + f.fileName, f.file as File);
                    formData.append("[fileType]:" + f.fileName, f.fileType.toString());
                })
            }
            else {
                formData.append(key, request[key].toString());
            }
        }

        return await this.postFormData<BooleanResponse>('requestUserSkill', formData, BooleanResponse);
    }
}