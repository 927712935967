import React from 'react';
import { RouteComponentProps } from 'react-router';

import { CatalogBreadCrumb } from '$components/breadCrumb/CatalogBreadCrumb';
import { ItemSummary } from '$components/item/itemSummaries/ItemSummary';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { CatalogElement } from '$storage/models/Catalog';
import { EItemDetailCallerContextType } from '$storage/models/enums'
import { isSuccess } from '$util/Result';
import { StringHelper } from '$src/util/StringHelper';

interface IMatchParams {
    folderId: string;
}
type IProps = RouteComponentProps<IMatchParams>
interface IState {
    catalogFolder: CatalogElement | null;
    folderIdLoaded: number;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class Catalog extends React.Component<IProps, IState> {
    protected _lastResponse: CatalogElement | null;
    constructor(props: IProps) {
        super(props);
        this.state = {
            catalogFolder: null,
            folderIdLoaded: 0,
        };
    }

    public render() {
        const { folderId: folderIdAsString } = this.props.match.params;
        const folderId = folderIdAsString != null && !isNaN(Number(folderIdAsString)) ? Number(folderIdAsString) : 1;
        const folderDescription = this.state.catalogFolder != null && this.state.catalogFolder.attributes != null ? this.state.catalogFolder.attributes.find(f => f.name === 'FolderDescription') : undefined;

        if (folderId !== this.state.folderIdLoaded) {
            this.getCatalogFolder(folderId);
            return <ProgressSpinner />;
        }
        if (this.state.catalogFolder != null) {
            document.title = globalConfig.appProperties.title + (this.state.catalogFolder.title !== '' ?
                ': ' + this.state.catalogFolder.title :
                ': ' + Session.instance.storage.translation.GetString('Navigation:Catalog'));
            return (
                <div className={'l-box-container--column-flex catalog catalog__background' +
                    ((folderId === 1 && globalConfig.layoutProperties.displayBackgroundImageOnCatalogRoot) || folderId !== 1 ? ' background__with-image' : '')}>
                    <div className={(folderId !== 1 ? 'catalog__background-image' : '')}>
                        <div className="l-box-container l-box-container--column-flex">
                            <div className="l-box-container">
                                <CatalogBreadCrumb
                                    folderId={this.state.folderIdLoaded}
                                    itemStyle={this.isRoot() ?
                                        globalConfig.breadCrumbProperties.catalogBreadCrumbProperties.catalogRootItemStyle :
                                        globalConfig.breadCrumbProperties.catalogBreadCrumbProperties.itemStyle} />
                            </div>
                            <div className="l-box-container">
                                <h1 className={(folderId === 1 && !globalConfig.layoutProperties.displayBackgroundImageOnCatalogRoot ?
                                    '' : 'heading--secondary') + ' heading__Title' + (folderId === 1 ? ' catalog__root' : ' catalog__sub')}>
                                    {this.renderCatalogTitle(this.state.catalogFolder.title)}
                                </h1>
                            </div>
                            {folderDescription != null ?
                                (this.renderFolderDescription(folderDescription.value, !(folderId === 1 && !globalConfig.layoutProperties.displayBackgroundImageOnCatalogRoot)))
                                :
                                ('')}
                            {this.state.catalogFolder.children != null && this.state.catalogFolder.children.length > 0 ?
                                <ul className="item-box-tile__parent">
                                    {this.renderCatalogElements(this.state.catalogFolder)}
                                </ul>
                                :
                                <div className="l-box l-box--wide l-box--wide--no-margin">
                                    <NoDataFound message={Session.instance.storage.translation.GetString('Home:NoTrainingsFound')} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className="l-container"><NoDataFound message={Session.instance.storage.translation.GetString('Catalog:NoData')} /></div>;
        }
    }

    protected renderCatalogElements(parentFolder: CatalogElement): JSX.Element[] | null {
        if (parentFolder.children != null && parentFolder.children.length > 0) {
            return parentFolder.children.map(catElement =>
                    <ItemSummary
                        key={catElement.sId}
                        item={catElement}
                        parentHeadingLevel={1}
                        itemCallerContext={EItemDetailCallerContextType.Catalog}
                        parentCatalogFolderId={this.state.folderIdLoaded}
                        parentTrainingPlanId={0}
                        parentAssignmentId={0}
                        {...this.props} />
            );
        }
        return null;
    }

    protected renderFolderDescription(folderDescription: string, secondaryColor: boolean): JSX.Element {
        return (
            <React.Fragment>
                <div className={"l-box l-box--wide l-box--wide--no-margin folder_Description"}>
                    <span className={secondaryColor ? 'heading--secondary' : 'folder_Description--primaryColor'} dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(folderDescription) }} />
                </div>
            </React.Fragment>
        );
    }

    protected renderCatalogTitle(folderTitle: string) {
        return this.isRoot() ? <Translate>Catalog:Title</Translate> : folderTitle;
    }

    protected isRoot(): boolean {
        return this.state.folderIdLoaded === 1
    }

    protected async getCatalogFolder(folderId: number): Promise<void> {
        const response: CatalogElement | null = await Session.instance.storage.catalog.getCatalogFolder(folderId);
        if (isSuccess<CatalogElement | null>(response)) {
            this._lastResponse = response;
            this.setState({ catalogFolder: response, folderIdLoaded: folderId });
        }
    }
}
export default Catalog;