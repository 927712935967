import { JsonObject, JsonProperty } from 'json2typescript';
import { FavoriteTypeConverter } from '../converters/FavoriteTypeConverter';
import { EFavoriteType } from '../enums';

@JsonObject
export class AddFavoriteRequest {

    @JsonProperty('objectId', Number, false)
    public objectId: number = 0;

    @JsonProperty('type', FavoriteTypeConverter, false)
    public type: EFavoriteType = EFavoriteType.Item;

    @JsonProperty('language', String, false)
    public language: string = '';
}