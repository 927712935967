import { CheckBox } from '$src/components/shared/CheckBox';
import { DatePicker } from '$src/components/shared/DatePicker';
import { GenericFilter, IGenericFilterItem } from '$src/components/shared/GenericFilter';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import TeamService from '$src/core/Services/TeamService';
import UserService from '$src/core/Services/UserService';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { DeputyInsertRequest } from '$src/storage/models/RequestObjects/DeputyInsertRequest';
import { User } from '$src/storage/models/User';
import DateHelper from '$src/util/DateHelper';
import { isSuccess } from '$src/util/Result';
import * as React from 'react';

interface IProps {
    parentHeadingLevel: number;
    bossRelationCode: string;
    onDeputyCreated?: () => void;
}

interface ICreateDeputy {
    deputyUserId: number | undefined;
    validFrom: Date | undefined;
    validTo: Date | undefined;
    isNotificationOn: boolean;
}

interface IState extends ICreateDeputy {
    errorMessage: string | undefined;
    employees: IGenericFilterItem[] | undefined;
    dataLoaded: boolean;
}
/**
 * Displays a list of all the employees of this user
 */
export class CreateDeputy extends React.Component<IProps, IState> {

    private defaultTeamMatch = 'default';

    constructor(props: IProps) {
        super(props);

        this.state = {
            dataLoaded: false,
            deputyUserId: undefined,
            employees: undefined,
            errorMessage: undefined,
            validFrom: undefined,
            validTo: undefined,
            isNotificationOn: false,
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderForm()}
            </React.Fragment>);
    }

    public async componentDidMount() {
        await this.loadEmployees(this.props.bossRelationCode);
    }

    public async UNSAFE_componentWillReceiveProps(props: IProps) {
        if (props.bossRelationCode !== this.props.bossRelationCode) {
            this.setState({ employees: undefined, dataLoaded: false });
            await this.loadEmployees(props.bossRelationCode);
        }
    }

    private renderForm(): JSX.Element {
        let ele: JSX.Element;
        if (!this.state.dataLoaded) {
            ele = <ProgressSpinner />;
        } else if (this.state.errorMessage !== undefined) {
            ele = (
                <ErrorMessage errorMessage={this.state.errorMessage} />
            );
        } else {
            ele = (
                <React.Fragment>
                    <div role="table" className="div-table__vertical-table deputy-create__table">
                        <div role="rowgroup">
                            <div role="row" className="div-table__vertical-table-row">
                                <div role="columnheader">
                                    <Translate>Deputy:DeputyName</Translate>
                                </div>
                                <div role="cell">
                                    {this.state.employees != null ? (
                                        <GenericFilter
                                            initValue={undefined}
                                            fetchedItemList={this.state.employees}
                                            filterable={true}
                                            suggest={true}
                                            onSelectedItemChange={(itm) => this.seletedUserChanged(itm)}
                                            className="my-team__item-filter"
                                            required={true}
                                        />
                                    ) : ('')}

                                </div>
                            </div>
                            <div role="row" className="div-table__vertical-table-row">
                                <div role="columnheader">
                                    <Translate>Deputy:Timespan</Translate>
                                </div>
                                <div role="cell" className="time-zone__cell">
                                    <DatePicker
                                        value={this.state.validFrom === undefined ? null : this.state.validFrom}
                                        onChange={e => this.onValidFromChanged(e)}
                                        required={true} />
                                    <span className="time-zone__cell--to">
                                        <Translate>Deputy:To</Translate>
                                    </span>
                                    <DatePicker
                                        value={this.state.validTo === undefined ? null : this.state.validTo}
                                        minDate={this.state.validFrom}
                                        onChange={e => this.onValidToChanged(e)} />
                                </div>
                            </div>
                            <div role="row" className="div-table__vertical-table-row">
                                <div role="columnheader">
                                    <Translate>Deputy:Notification</Translate>
                                </div>
                                <div role="cell">
                                    <CheckBox id="cbIsWithNotification"
                                        defaultChecked={this.state.isNotificationOn}
                                        onClick={() => {
                                            const isNotificationOn = this.state.isNotificationOn;
                                            this.setState({ isNotificationOn: !isNotificationOn });
                                        }} />
                                </div>
                            </div>
                            <div role="row" className="div-table__vertical-table-row">
                                <div role="cell" className="actionBar" >
                                    <button
                                        disabled={this.state.deputyUserId === undefined || 
                                            this.state.validFrom === undefined || 
                                            (this.state.validTo != null && this.state.validFrom != null && this.state.validFrom >= this.state.validTo)}
                                        onClick={() => this.saveDeputy()}
                                        className="btn--md btn--primary my-team__action">
                                        <Translate>Deputy:Save</Translate>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        return ele;
    }

    private onValidToChanged(date: Date | null): void {
        this.setState({ validTo: date == null ? undefined : date });
    }

    private onValidFromChanged(date: Date | null): void {
        this.setState({ validFrom: date == null ? undefined : date });
    }

    private seletedUserChanged(item: IGenericFilterItem | undefined): void {
        if (item != null) {
            this.setState({ deputyUserId: item.id });
        }
    }

    private async loadEmployees(relationCode: string): Promise<void> {
        let emps = null;
        if (relationCode != null && relationCode.toLocaleLowerCase() !== this.defaultTeamMatch.toLocaleLowerCase()) {
            emps = await UserService.instance.getEmployeesOfRelation(relationCode);
        }
        else {
            emps = await UserService.instance.getEmployees();
        }
        
        if (isSuccess<User[]>(emps)) {
            const employeeFilter: Array<IGenericFilterItem> = [];
            emps.sort((a, b) => this.defaultSortEmployees(a, b)).map((item) => {
                employeeFilter.push({ id: item.id, text: item.fullName, helperItem: undefined } as IGenericFilterItem);
            });
            this.setState({ employees: employeeFilter, dataLoaded: true });
        } else {
            this.setState({ errorMessage: 'Deputy:FailedToLoadEmployees', dataLoaded: true })
        }
    }

    private async saveDeputy(): Promise<void> {
        const req: DeputyInsertRequest = new DeputyInsertRequest();
        if (this.state.deputyUserId != null && this.state.validFrom != null) {
            req.deputyUserId = this.state.deputyUserId;
            req.bossRelationCode = this.props.bossRelationCode;
            req.validFrom = DateHelper.toUtcDate(this.state.validFrom);

            if (this.state.validTo != null) {
                req.validTo = DateHelper.toUtcDate(this.state.validTo);
            } else {
                req.validTo = undefined;
            }
            req.isNotificationOn = this.state.isNotificationOn;

            await TeamService.instance.insertDeputy(req).then(resp => {
                if (isSuccess<BooleanResponse>(resp)) {
                    if (resp.status) {
                        if (this.props.onDeputyCreated != null) {
                            this.props.onDeputyCreated();
                        }
                        this.setState({ validFrom: undefined, validTo: undefined, deputyUserId: undefined, errorMessage: undefined, isNotificationOn: false }, () => this.render());
                    } else {
                        this.setState({ errorMessage: 'Deputy:FailedToInsertDeputy' });
                    }
                } else {
                    this.setState({ errorMessage: 'Deputy:FailedToInsertDeputy' });
                }
            });
        }
    }

    private defaultSortEmployees(a: User, b: User): number {
        if (a.lastName > b.lastName) { return 1 }
        if (a.lastName < b.lastName) { return -1 }
        if (a.lastName === b.lastName && a.firstName > b.firstName) { return 1 }
        if (a.lastName === b.lastName && a.firstName < b.firstName) { return -1 }
        return 0;
    }
}