import { ItemSummaryBasic } from '$components/item/itemSummaries/ItemSummaryBasic';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { CatalogElement } from '$storage/models/Catalog';
import { EItemDetailCallerContextType } from '$storage/models/enums';
import { isSuccess } from '$util/Result';
import React from 'react';
import CatalogService from '$src/core/Services/CatalogService';
import { NavLink } from 'react-router-dom';
import { Translate } from '$components/shared/Translate';

interface IProps {
    catalogFolderSIds: string[];
    parentHeadingLevel: number;

    itemStyle?: React.CSSProperties;
    itemLinkStyle?: React.CSSProperties;
    showItemBackground: boolean;

    showNavigationButton: boolean;
    navigationButtonText?: string;
    navigationButtonStyle?: React.CSSProperties;

    maxItems?: number;

    catalogElement?: CatalogElement | null;
}

interface IState {
    catalogFolderId: number;
    catalogFolderContent: CatalogElement[];
    errorMessage: string;
    isDataLoaded: boolean;
    maxItems: number;
}

export class CatalogFolderContentList extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            catalogFolderContent: [],
            catalogFolderId: -1,
            errorMessage: '',
            isDataLoaded: false,
            maxItems: this.props.maxItems || 0,
        };

    }

    public async componentDidMount() {
        if (this.state.isDataLoaded === false) {
            await this.getCatalogFolder(this.props.catalogFolderSIds);
        }
    }
    public render() {
        if (this.state.isDataLoaded === false) {
            return <ProgressSpinner />
        }
        else if (this.state.isDataLoaded === true && this.state.catalogFolderContent !== null && this.state.catalogFolderContent.length > 0) {
            return <React.Fragment><ol className="l-box-container catalog-folder-list">
                {this.state.catalogFolderContent.slice(0,
                    this.state.maxItems === 0 ? this.state.catalogFolderContent.length : this.state.maxItems)
                    .map(catElement =>
                        <li key={`li_${catElement.sId}`} style={this.props.itemStyle} className="l-box--short l-box--shadow item-summary">
                            <ItemSummaryBasic
                                item={catElement}
                                itemLinkStyle={this.props.itemLinkStyle}
                                key={catElement.sId}
                                itemDetailCallerContextType={EItemDetailCallerContextType.Catalog}
                                parentCatalogFolderId={this.state.catalogFolderId} // TODO: This is the wrong catalogfolder id
                                parentAssignmentId={0}
                                parentTrainingPlanId={0}
                                {...this.props} />
                        </li>
                    )
                }
            </ol>
                {this.props.showNavigationButton && this.props.catalogFolderSIds.length === 1 ?
                    <div className="l-box-container catalog-folder-list__navigation">
                        <NavLink to={`/catalog/${this.state.catalogFolderId}`}
                            style={this.props.navigationButtonStyle}
                            className="btn btn--md btn--primary">
                            <Translate>{this.props.navigationButtonText}</Translate>
                        </NavLink>
                    </div> : null}
            </React.Fragment>
        }
        else {
            return <div />
        }
    }

    protected async getCatalogFolder(catalogFolderSIds: string[]): Promise<void> {
        const response = this.props.catalogElement != null ? 
            this.props.catalogElement : await CatalogService.instance.getCatalogFolderContentsBySIds(catalogFolderSIds);
        if (isSuccess<CatalogElement | null>(response)) {
            let folderContent: CatalogElement[] = [];
            if (response != null && response.children != null) {
                folderContent = response.children;
            }
            this.setState({
                catalogFolderContent: folderContent,
                catalogFolderId: response.itemId,
                errorMessage: '',
                isDataLoaded: true
            });
        }
    }
}