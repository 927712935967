import Logger from '$src/core/Logger';
import CachedObject from '$src/storage/cache/CachedObject';

export default abstract class XCache<TKey, TObject> {
    protected className = 'XCache';
    protected loggerLocality = 'Storage.XCache';
    protected _cachingDuration: number; // seconds
    protected _autoRemoveExpiredData: boolean = true;
    protected _isLanguageDependant: boolean = false;

    constructor(cachingDuration: number = 3600,
                autoRemoveExpiredData?: boolean,
                isLanguageDependant?: boolean) {
        this._cachingDuration = cachingDuration;
        if (autoRemoveExpiredData !== undefined) {
            this._autoRemoveExpiredData = autoRemoveExpiredData
        }        
        if (isLanguageDependant !== undefined) {
            this._isLanguageDependant = isLanguageDependant
        } 
          
        Logger.log(this.loggerLocality, 
        `Create XCache Object,  cachingDuration = ${cachingDuration.toString()}, 
                                autoRemoveExpiredData = ${this._autoRemoveExpiredData.toString()},
                                isLanguageDependant = ${this._isLanguageDependant.toString()}`);
    }

    public getObjectFromCache(key: TKey): TObject | null {
        const cachedObject = this.getCachedObjectFromCache(key);
        return (cachedObject != null) ? cachedObject.object : null;
    }

    public getObjectsFromCache(): TObject[] {
        const cachedObjects = this.getCachedObjectsFromCache();
        const retVal: TObject[] = []
        if (cachedObjects != null ) {
            cachedObjects.map(f => retVal.push(f.object));
        }
        return retVal;
    }

    protected get cachingDuration(): number {
        return this._cachingDuration;
    }
    
    public get isLanguageDependant(): boolean {
        return this._isLanguageDependant;
    }

    protected abstract getCachedObjectFromCache(key: TKey): CachedObject<TObject> | null

    protected abstract getCachedObjectsFromCache(): Array<CachedObject<TObject>> | null

    protected abstract saveObjectToCache(key: TKey, object: TObject): void

    protected abstract removeObjectFromCache(key: TKey): void

    protected abstract getAllKeys(): TKey[]

    protected abstract clear(): void

    protected abstract isCacheExpired(): boolean;

    protected isObjectExpired(key: TKey): boolean {
        let isObjectExpired: boolean = false;
        const cobj = this.getCachedObjectFromCache(key);
        if (cobj !== null && cobj !== undefined) {
            isObjectExpired = cobj.isValid(this.cachingDuration);
        }
        return isObjectExpired;
    }

    protected isObjectInCache(key: TKey): boolean {
        const methodName = `${this.className}:isObjectInCache()`;
        let isInCache: boolean = false;
        const cobj = this.getCachedObjectFromCache(key);
        if (cobj !== null && cobj !== undefined) {
            if (!this._autoRemoveExpiredData ||
                (this._autoRemoveExpiredData && cobj.isValid(this.cachingDuration))) {
                isInCache = true;
            } else {
                this.removeObjectFromCache(key);
                Logger.log(this.loggerLocality, `${methodName} auto remove expired data from cache, key = ${key}`);
            }
        }
        return isInCache;
    }
}
