import Logger from '$core/Logger';
import Session from '$core/Session';
import { HomePanel } from '$storage/models/HomePanel';
import { UserConfigPanel } from '$storage/models/UserPreferences/UserConfigPanel';
import { JsonObject, JsonProperty } from 'json2typescript';
import { MorePanel } from '$storage/models/MorePanel';

@JsonObject
export class Preferences {

    public static className = 'Preferences';
    public static loggerLocality = 'Models.Preferences';

    @JsonProperty('scheme', String, true)
    public scheme: string;

    @JsonProperty('homePanels', [HomePanel], true)
    public homePanels: HomePanel[] | null = null;

    @JsonProperty('morePanels', [MorePanel], true)
    public morePanels: MorePanel[] | null = null;

    // Get the default config for homepanels
    public static getDefaultHomePanels(): HomePanel[] {
        const methodName = 'getDefaultHomePanels';
        Logger.log(Preferences.loggerLocality, `${methodName} getting Default Home Panels`);
        const initHomePanel: HomePanel[] = [];
        globalConfig.homeProperties.panels.forEach(cpanel => {
            if (!cpanel.isFixed) { // If the config panel does not exist in the userPreferences
                initHomePanel.push(new HomePanel(cpanel.id, cpanel.defaultOrder, true, false));
            }
        });
        Logger.log(Preferences.loggerLocality, `${methodName} default home panels are ${JSON.stringify(initHomePanel)}`);
        return initHomePanel;
    }

    public static convert(panel: UserConfigPanel, configPanel: {
        id: string;
        component: string;
        defaultOrder: number;
        isFixed: boolean;
        translationString: string;
        headingStyle: number;
        isTitleVisible: boolean;

        catalogFolderContentListConfiguration?: {
            catalogFolderSIds: string[];
            itemStyle?: React.CSSProperties;
            itemLinkStyle?: React.CSSProperties;
            showItemBackground: boolean;
            showNavigationButton: boolean;
            navigationButtonText?: string;
            navigationButtonStyle?: React.CSSProperties;
            maxItems?: number;
        };
        catalogFolderListConfigurationWithAllowedGroups?: Array<{
            catalogFolderSId: string;
            allowedGroups?: string[];
        }>;
        nextTrainingConfiguration?: {
            maxAssignments: number;
            maxRegisteredItems: number;
            maxRecommendedItems: number;
            displayCountOfRegisteredItems: boolean;
        };
        assignmentConfiguration?: { maxAssignments: number };
        welcomePanelConfiguration?: { title: string };
        performanceSupportPanelConfiguration?: { maxItems: number; expiringInDaysFromNow: number };
        externalPageConfiguration?: {
            pageId: string;
        };
        allowedGroups?: string[];
    }) {
        panel.id = configPanel.id;
        panel.component = configPanel.component;
        panel.defaultOrder = configPanel.defaultOrder;
        panel.isFixed = configPanel.isFixed;
        panel.translationString = configPanel.translationString;
        panel.headingStyle = configPanel.headingStyle;
        panel.isTitleVisible = configPanel.isTitleVisible;
        panel.catalogFolderContentListConfiguration = configPanel.catalogFolderContentListConfiguration;
        panel.catalogFolderListConfigurationWithAllowedGroups = configPanel.catalogFolderListConfigurationWithAllowedGroups;
        panel.nextTrainingConfiguration = configPanel.nextTrainingConfiguration;
        panel.assignmentConfiguration = configPanel.assignmentConfiguration;
        panel.welcomePanelConfiguration = configPanel.welcomePanelConfiguration;
        panel.performanceSupportPanelConfiguration = configPanel.performanceSupportPanelConfiguration;
        panel.externalPageConfiguration = configPanel.externalPageConfiguration;
        panel.allowedGroups = configPanel.allowedGroups;
    }

    // Get the combined homepanels / configpanels of the current loged in user (with cleanup of the data if needed)
    public static getUserConfigPanels(): UserConfigPanel[] {
        const methodName = 'getUserConfigPanels';

        const userConfigPanels: UserConfigPanel[] = [];
        const cleanedUserConfigPanels: UserConfigPanel[] = [];
        const fixedConfigPanels = globalConfig.homeProperties.panels.filter(cp => cp.isFixed);
        if (Session.instance.loginUserPreferences == null) {
            throw Error('Session.instance.loginUserPreferences is not initialized');
        }
        else if (Session.instance.loginUserPreferences != null &&
            Session.instance.loginUserPreferences.preferences == null) {
            throw Error('Session.instance.loginUserPreferences.preferences is not initialized');
        }
        else if (Session.instance.loginUserPreferences != null &&
            Session.instance.loginUserPreferences.preferences != null &&
            Session.instance.loginUserPreferences.preferences.homePanels == null ) {
            throw Error('Session.instance.loginUserPreferences.preferences.homePanels is not initialized');
        }
        else {

            // Check if user preferences panel contains a fixed panel
            for (const fixedConfigPanel of fixedConfigPanels) {
                if (Session.instance.loginUserPreferences != null && Session.instance.loginUserPreferences.preferences.homePanels != null) {
                    const invalidHomePanel = Session.instance.loginUserPreferences.preferences.homePanels.find(ppanel => ppanel.id === fixedConfigPanel.id);
                    if (invalidHomePanel != null) {
                        Logger.log(Preferences.loggerLocality, `${methodName} user preferences containing fixed panels, resetting user preferences}`);
                        // user preferences contains a fixed panel, reset user preferences
                        Session.instance.loginUserPreferences.preferences.homePanels = Preferences.getDefaultHomePanels();
                        break;
                    }
                }
            }
            if (Session.instance.loginUserPreferences != null && Session.instance.loginUserPreferences.preferences.homePanels != null) {
                // Check if user preferences panels contains invalid panels
                for (const homePanel of Session.instance.loginUserPreferences.preferences.homePanels) {
                    if (globalConfig.homeProperties.panels.findIndex(configPanel => configPanel.id === homePanel.id) === -1) {
                        // user preferences contains a invalid panel, reset user preferences
                        Logger.log(Preferences.loggerLocality, `${methodName} user preferences containing invalid panels, resetting user preferences}`);
                        Session.instance.loginUserPreferences.preferences.homePanels = Preferences.getDefaultHomePanels();
                        break;
                    }
                }


                // Create combined config of ConfigPanels and HomePanels

                // Add fixed panels
                for (const fixedConfigPanel of fixedConfigPanels) {
                    const ucp: UserConfigPanel = new UserConfigPanel();
                    this.convert(ucp, fixedConfigPanel);
                    ucp.expanded = true; // Fixed Panels are always expanded
                    userConfigPanels[fixedConfigPanel.defaultOrder - 1] = ucp;
                }

                // Add user preferences panels
                for (const homePanel of Session.instance.loginUserPreferences.preferences.homePanels.sort((a, b) => a.order > b.order ? 1 : -1)) {
                    const configPanel = globalConfig.homeProperties.panels.find(cp => cp.id === homePanel.id);
                    if (configPanel != null) {
                        let counter = -1;
                        let itemAdded = false;
                        while (!itemAdded) {
                            if (userConfigPanels[homePanel.order + counter] == null) {
                                const ucp: UserConfigPanel = new UserConfigPanel();
                                this.convert(ucp, configPanel);
                                ucp.isVisible = homePanel.visible; // Can be modified through user preferences
                                ucp.expanded = homePanel.expanded; // Can be modified through user preferences
                                userConfigPanels[homePanel.order + counter] = ucp;
                                itemAdded = true;
                                Logger.log(Preferences.loggerLocality, `${methodName} added user preferences panel ${configPanel.id} at position
                                ${homePanel.order + counter}, wished position is ${homePanel.order - 1}}`);
                            }
                            else {
                                counter++;
                            }
                        }
                        counter = -1;
                    }
                }

            // Add missing panels
            for (const configPanel of globalConfig.homeProperties.panels) {
                if (!configPanel.isFixed) {
                    const homePanel = Session.instance.loginUserPreferences.preferences.homePanels.find(hp => hp.id === configPanel.id)
                    if (homePanel == null) {
                        let counter = -1;
                        let itemAdded = false;
                        while (!itemAdded) {
                            if (userConfigPanels[configPanel.defaultOrder + counter] == null) {
                                const ucp: UserConfigPanel = new UserConfigPanel();
                                this.convert(ucp, configPanel);
                                userConfigPanels[configPanel.defaultOrder + counter] = ucp;
                                ucp.isVisible = true; // Default for missing panel is visible = true
                                ucp.expanded = configPanel.isFixed; // Default for missing panel expanded = false for nonfixed panels
                                itemAdded = true;
                                Logger.log(Preferences.loggerLocality, `${methodName} adding missing user preferences panel ${configPanel.id} at position
                                    ${configPanel.defaultOrder + counter}, wished position is ${configPanel.defaultOrder - 1}}`);
                            }
                            else {
                                counter++;
                            }
                        }
                    }
                }
            }

            // Remove empty
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < userConfigPanels.length; i++) {
                if (userConfigPanels[i] != null) {
                    cleanedUserConfigPanels.push(userConfigPanels[i]);
                }
            }

            // Clean up order
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < cleanedUserConfigPanels.length; i++) {
                if (cleanedUserConfigPanels[i] != null) {
                    cleanedUserConfigPanels[i].defaultOrder = i + 1;
                }
            }
        }
    }
        return cleanedUserConfigPanels;
    }
}
