import { User } from '$storage/models/User';
import { JsonObject, JsonProperty } from 'json2typescript';

// import { DateConverter } from '$storage/models/converters/dateConverter';

@JsonObject
export class Boss extends User {

    @JsonProperty('bossType', String, false)
    public bossType: string = '';

    @JsonProperty('bossTypeCode', String, false)
    public bossTypeCode: string = '';

    public get fullNameAndEmail(): string {
        return `${this.firstName} ${this.lastName} (${this.email})`;
    }
}