import React from 'react';

import { ItemSummaryTrainingPlanElement } from '$components/item/itemSummaries/ItemSummaryTrainingPlanElement';
import Logger from '$core/Logger';
import { EItemDetailCallerContextType, EItemType } from '$storage/models/enums';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import { ItemHelper } from '$util/ItemHelper';
import { RouteComponentProps } from 'react-router';

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IProps extends RouteComponentProps<{}> {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    trainingPlan: TrainingPlan;
    trainingPlanElements: TrainingPlanElement[] | null;
    activeElement: TrainingPlanElement | null;
    onTrainingPlanModulSelected?: (trainingPlanElement: TrainingPlanElement) => void;
}

interface IState {
    activeElement: TrainingPlanElement | null;
}

/**
 * @deprecated The method should not be used
 */
export class TrainingPlanElementsContents extends React.Component<IProps, IState> {
    protected className = 'TrainingPlanElementsContents';
    protected logger = 'Components.TrainingPlanElementsContents';

    constructor(props: IProps) {
        super(props);
        this.state = { activeElement: this.props.activeElement != null ? this.props.activeElement : null };
    }

    public render() {
        const elements: JSX.Element[] = [];
        if (this.props.trainingPlanElements != null) {

            this.props.trainingPlanElements.map(
                (element, index) => {
                    Logger.log(this.logger, `Rendering Training Plan Element with id: ${element.sId}, 
                                             title: ${element.title}, type: ${element.type.toString()}`);
                    if ((element.parentItemId === 'root' && this.props.activeElement == null) ||
                        (this.props.activeElement != null && element.parentItemId === this.props.activeElement.sId)) {
                        elements.push(
                            <ItemSummaryTrainingPlanElement
                                key={index}
                                trainingPlanElement={element}
                                parentHeadingLevel={1}                                
                                onItemClicked={() =>
                                    (element.allowSelection(this.props.trainingPlan) ?
                                        this.onTrainingPlanElementSelectedInternal(element) : null)}
                                allowSelection={element.allowSelection(this.props.trainingPlan)}
                            />);
                    }
                });
        }
        else {
            Logger.log(this.logger, 'Got no Training Plan Elements');
        }
        return <ol className="l-box-container tp__item-summary__container">
            {elements}
        </ol>;
    }

    private onTrainingPlanElementSelectedInternal(trainingPlanElement: TrainingPlanElement) {
        if (trainingPlanElement.type === EItemType.Block) {
            Logger.log(this.logger, `Setting training plan element with id ${trainingPlanElement.sId} and title ${trainingPlanElement.title} as active element`);
            this.setState({ activeElement: trainingPlanElement });
            if (this.props.onTrainingPlanModulSelected != null) {
                Logger.log(this.logger, `Calling props onTrainingPlanElementSelected for element 
                                         with id ${trainingPlanElement.sId} and title ${trainingPlanElement.title} as active element`);
                this.props.onTrainingPlanModulSelected(trainingPlanElement);
            }
        }
        else {
            Logger.log(this.logger, `Loading Item Details for id ${trainingPlanElement.sId} and title ${trainingPlanElement.title} `);
            this.props.history.push(ItemHelper.getItemDetailLink(trainingPlanElement.itemId,
                                                                 this.props.itemDetailCallerContextType, 
                                                                 this.props.parentCatalogFolderId, 
                                                                 this.props.parentAssignmentId, 
                                                                 this.props.trainingPlan.itemId,
                                                                 trainingPlanElement.tpeId))
        }
    }
}