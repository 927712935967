import React from "react";
import { ModalPopup } from "$components/shared/ModalPopup";
import GTConfirm from '$components/shared/Atoms/GTConfirm';

export interface IGTAlertDialogProps {
    alertText: string;
    onResponse: (confirm: boolean) => void;
}

export default function GTAlertDialog(props: IGTAlertDialogProps) {
    return (
        <ModalPopup
            isOpen={true}
            disableClosing={true}
            onRequestClose={() => { console.log('closing disabled'); }}
            contentCssClass="confirmation alert-dialog"
            isAlert={true} >
            <p>
                {props.alertText}
            </p>
            <GTConfirm
                onConfirm={() => props.onResponse(true)}
                onCancel={() => props.onResponse(false)} />
        </ModalPopup>
    )
}