import { JsonObject, JsonProperty } from 'json2typescript';
import { ECheckoutRegistrationStatus } from '$src/storage/models/enums';
import { DateTimeConverter } from '../converters/DateTimeConverter';

/** Registration status and other data of a purchased item */
@JsonObject
export class CheckoutItemsStatusResponse {
    /** status of the registration after payment */
    @JsonProperty('registrationStatus', Number, false)
    public registrationStatus: ECheckoutRegistrationStatus = ECheckoutRegistrationStatus.NotProcessed;

    /** id of the user */
    @JsonProperty('usrId', Number, false)
    public usrId: number = 0;

    /** id of the class */
    @JsonProperty('clsId', Number, false)
    public clsId: number = 0;

    /** title of the lesson */
    @JsonProperty('course', String, false)
    public itemTitle: string = '';

    /** title of the class */
    @JsonProperty('class', String, false)
    public class: string = '';

    /** name of the user */
    @JsonProperty('user', String, false)
    public user: string = '';

    /** cost after all discounts */
    @JsonProperty('cost', Number, false)
    public cost: number = 0;

    /** currency of the cost */
    @JsonProperty('currency', String, false)
    public currency: string = '';

    /** start date of the class */
    @JsonProperty('classStartDate', DateTimeConverter, false)
    public classStartDate: Date = new Date();

    /** end date of the class */
    @JsonProperty('classEndDate', DateTimeConverter, false)
    public classEndDate: Date = new Date();

    /** city(s) of the class */
    @JsonProperty('classCities', String, false)
    public classCities: string = '';    
}
