import { ERegistrationRequestType } from '../enums';
import { RegistrationRequestTypeConverter } from '../converters/RegistrationRequestTypeConverter';
import { User } from '$src/storage/models/User';
import { JsonObject, JsonProperty } from 'json2typescript';
import { UserTCApprovalStatus } from '../Privacy/UserTCApprovalStatus';

@JsonObject
export class RegisterNewUserRequest {

    @JsonProperty('fileType', RegistrationRequestTypeConverter, false)
    public registrationRequestType: ERegistrationRequestType ;

    @JsonProperty('termsAndConditionApprovalLanguage', String, false)
    public termsAndConditionApprovalLanguage: string;

    @JsonProperty('password', String, false)
    public password: string;

    @JsonProperty('bossEmployeeRelationCode', String, false)
    public bossEmployeeRelationCode: string;

    @JsonProperty('isAnonymousUser', Boolean, false)
    public isAnonymousUser: boolean;

    @JsonProperty('user', User, false)
    public user: User;

    @JsonProperty('userTCApprovalStatus', UserTCApprovalStatus, false)
    public userTCApprovalStatus?: UserTCApprovalStatus;

    @JsonProperty('tokenForSecondStep', String, false)
    public tokenForSecondStep: string;   

    @JsonProperty('licenseKey', String, false)
    public licenseKey: string;    
}
