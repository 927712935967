import { EItemType } from '$storage/models/enums';
import ItemTypeModel from './ItemTypeModel';

import Session from '$src/core/Session';


export default class ItemTypeFactory {
    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ItemTypeFactory {
        return this._instance || (this._instance = new this());
    }

    protected static _instance: ItemTypeFactory | null = null;

    private itemTypeList: ItemTypeModel[] = []

    private itemTypeListComplete: ItemTypeModel[] = [];

    constructor() {
        const tr = Session.instance.storage.translation;
        this.itemTypeList.push({ itemType: EItemType.F2FCourse, text: tr.GetString('ItemType:F2FCourse') })
        this.itemTypeList.push({ itemType: EItemType.WBT, text: tr.GetString('ItemType:WBT') })
        this.itemTypeList.push({ itemType: EItemType.TrainingPlan, text: tr.GetString('ItemType:TrainingPlan') })
        this.itemTypeList.push({ itemType: EItemType.CBT, text: tr.GetString('ItemType:CBT') })
        this.itemTypeList.push({ itemType: EItemType.Document, text: tr.GetString('ItemType:Document') })
        this.itemTypeList.push({ itemType: EItemType.Questionnaire, text: tr.GetString('ItemType:Questionnaire') })
        this.itemTypeList.push({ itemType: EItemType.Assessment, text: tr.GetString('ItemType:Assessment') })

        this.itemTypeListComplete.push({ itemType: EItemType.F2FCourse, text: tr.GetString('ItemType:F2FCourse') })
        this.itemTypeListComplete.push({ itemType: EItemType.WBT, text: tr.GetString('ItemType:WBT') })
        this.itemTypeListComplete.push({ itemType: EItemType.TrainingPlan, text: tr.GetString('ItemType:TrainingPlan') })
        this.itemTypeListComplete.push({ itemType: EItemType.CBT, text: tr.GetString('ItemType:CBT') })
        this.itemTypeListComplete.push({ itemType: EItemType.Document, text: tr.GetString('ItemType:Document') })
        this.itemTypeListComplete.push({ itemType: EItemType.Questionnaire, text: tr.GetString('ItemType:Questionnaire') })
        this.itemTypeListComplete.push({ itemType: EItemType.Assessment, text: tr.GetString('ItemType:Assessment') })
        this.itemTypeListComplete.push({ itemType: EItemType.Folder, text: tr.GetString('ItemType:Folder') })
        this.itemTypeListComplete.push({ itemType: EItemType.FileSharing, text: tr.GetString('ItemType:FileSharing') })

        this.ItemType = this.ItemType.bind(this)
        this.GetItemTypes = this.GetItemTypes.bind(this)
    }

    public GetItemTypes(includeAllItemTypes: boolean) {
        if (includeAllItemTypes) {
            return this.itemTypeListComplete;
        } else {
            return this.itemTypeList;
        }
    }

    public ItemType(itemType: EItemType) {
        return this.itemTypeList[itemType];
    }

}