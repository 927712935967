
import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


/**
 * Displays toast messages if toast() is called. To display an error message use toast.error()
 * @returns Container for toast messages (only use once in entire application)
 */
export default function ToastMessages() {
    return (
        <ToastContainer
            transition={Slide} />
    );
}