import React from 'react';

interface IGTWhitePanelProps {
    children: JSX.Element | string;
    className?: string;
}

export default function GTWhitePanel(props: IGTWhitePanelProps) {

    return (
        <div className={`whitepanel ${props.className}`}>
            <div className="l-form-container">
                {props.children}
            </div>
        </div>
    )
}