import { JsonObject, JsonProperty } from 'json2typescript';


/**
 * class for cancellation reason
 */
@JsonObject('CancellationReasonModel')
export class CancellationReasonModel {

    /** Id of the corresponding item. */
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('title', String, false)
    public title: string = '';

}