import React from 'react';

import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NavigationUtil } from '$util/NavigationUtil';

export class LmsSui extends React.Component<{}, {}> {

    public componentDidMount() {
        let forwardURL = '';
        const parameters = new URLSearchParams(window.location.search);

        const forwardUrlParam = parameters.get('forwardurl');
        const forwardUrlParam1Param = parameters.get('p1');
        const forwardUrlParam2Param = parameters.get('p2');
        const forwardUrlParam3Param = parameters.get('p3');
        const forwardUrlParam4Param = parameters.get('p4');
        const forwardUrlParam5Param = parameters.get('p5');
        const forwardUrlParam6Param = parameters.get('p6');

        if(forwardUrlParam != null && forwardUrlParam !== '') {
            forwardURL += forwardUrlParam;
        }
        if(forwardUrlParam1Param != null && forwardUrlParam1Param !== '') {
            forwardURL += `?${forwardUrlParam1Param}`

            if(forwardUrlParam2Param != null && forwardUrlParam2Param !== '') {
                forwardURL += `&${forwardUrlParam2Param}`
            }
            if(forwardUrlParam3Param != null && forwardUrlParam3Param !== '') {
                forwardURL += `&${forwardUrlParam3Param}`
            }
            if(forwardUrlParam4Param != null && forwardUrlParam4Param !== '') {
                forwardURL += `&${forwardUrlParam4Param}`
            }
            if(forwardUrlParam5Param != null && forwardUrlParam5Param !== '') {
                forwardURL += `&${forwardUrlParam5Param}`
            }
            if(forwardUrlParam6Param != null && forwardUrlParam6Param !== '') {
                forwardURL += `&${forwardUrlParam6Param}`
            }
            forwardURL += '&nsui=nsui' // Indicates Request comes from NSUI
        } else {
            forwardURL += '?nsui=nsui' // Indicates Request comes from NSUI
        }
        
        NavigationUtil.loginSui(forwardURL, window);
    }
    public render() {
        return <div className="l-container">
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>GtApplicationSwitch:LmsSui</Translate>
                </Heading>
                <ProgressSpinner />
            </div>;
    }
}
export default LmsSui;