import { EUserSkillCommentType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';
@JsonConverter
export class UserSkillCommentTypeConverter implements JsonCustomConvert<EUserSkillCommentType> {  
    public serialize(commentType: EUserSkillCommentType): number {
        switch (commentType) {         
            case EUserSkillCommentType.Undefined: return 0;
            case EUserSkillCommentType.MySkill_Boss: return 1;
            case EUserSkillCommentType.MySkill_User: return 2; 
            case EUserSkillCommentType.MyPotential_Boss: return 3; 
            case EUserSkillCommentType.MyPotential_User: return 4; 
            case EUserSkillCommentType.MyTargets: return 5;            
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EUserSkillCommentType {
        switch (jsonValue) {
         
            case 0: return EUserSkillCommentType.Undefined;
            case 1: return EUserSkillCommentType.MySkill_Boss;
            case 2: return EUserSkillCommentType.MySkill_User; 
            case 3: return EUserSkillCommentType.MyPotential_Boss; 
            case 4: return EUserSkillCommentType.MyPotential_User; 
            case 5: return EUserSkillCommentType.MyTargets;         
            default: return EUserSkillCommentType.Undefined;
        }
    }
}