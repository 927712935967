import { JsonObject, JsonProperty } from 'json2typescript';

import { RegisteredItem } from '$storage/models/RegisteredItem';

/**
 * This class holds information about a user's registration for a F2F or external course class
 * (with information about the F2F course/class, external course/class registration).
 */
@JsonObject
export class EmployeeRegisteredItem extends RegisteredItem {

    /** This is the id of the registration itself, whereas the registrationId is the id of the next registration of all registrations */
    @JsonProperty('id', Number, true)
    public itemRegistrationId: number = 0;

    /** Firstname, Lastname and Email of employee in one string  */
    @JsonProperty('userNameAndEmail', String, true)
    public userNameAndEmail: string = '';

    /** Costs of the class (Price)  */
    @JsonProperty('classCosts', Number, true)
    public classCosts: number = 0;

    /** Currency of the costs (class)  */
    @JsonProperty('classCurrency', String, true)
    public classCurrency: string = '';

}
