import { DateOrNullConverter } from '$src/storage/models/converters/DateOrNullConverter';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { SkillStatusConverter } from '$src/storage/models/converters/SkillStatusConverter';
import { ESkillStatus } from '$src/storage/models/enums';
import { UserSkillComment } from '$src/storage/models/Skill/UserSkillComment';
import { JsonObject, JsonProperty } from 'json2typescript';
import { UserCertificateViewModel } from '$src/storage/models/Certificates/UserCertificateViewModel';
import { Attribute } from '$src/storage/models/Attribute';

@JsonObject
export class UserSkill {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    /** The Id of the User */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** Id of the corresponding UserSkill */
    @JsonProperty('userSkillId', Number, false)
    public userSkillId: number = 0;

    /** Id of the skill type */
    @JsonProperty('skillTypeId', Number, true)
    public skillTypeId: number | null = null;

    /** Id of the skill */
    @JsonProperty('skillId', Number, false)
    public skillId: number = 0;

    /** Id of the skill level */
    @JsonProperty('skillLevelId', Number, false)
    public skillLevelId: number = 0;

    /** Code of the skill type (code of AttributeValue) */
    @JsonProperty('skillTypeCode', String, true)
    public skillTypeCode: string | null = null;

    /** String Id of the skill */
    @JsonProperty('skillSId', String, false)
    public skillSId: string = '';

    /** Skill type name */
    @JsonProperty('skillTypeName', String, false)
    public skillTypeName: string = '';

    /** Skill title */
    @JsonProperty('skillTitle', String, false)
    public skillTitle: string = '';

    /** Skill level title */
    @JsonProperty('skillLevelTitle', String, false)
    public skillLevelTitle: string = '';

    /** Skill level value, used to compare skill levels */
    @JsonProperty('skillLevelValue', Number, false)
    public skillLevelValue: number = 0;

    /** Date & time skill was acquired by user */
    @JsonProperty('dateAcquired', DateTimeConverter, false)
    public dateAcquired: Date = new Date();

    /** Date & time skill will expire */
    @JsonProperty('expirationDate', DateOrNullConverter, true)
    public expirationDate: Date | null = null;

    /**Date & time skill is requested from the user (PerformanceCheck)*/
    @JsonProperty('requestDate', DateOrNullConverter, false)
    public requestDate: Date = new Date();

    /**First name of the boss which has edit the requested skill (PerformanceCheck)  */
    @JsonProperty('bossFirstName', String, true)
    public bossFirstName: string | null = '';

    /**Last name of the boss which has edit the requested skill (PerformanceCheck)  */
    @JsonProperty('bossLastName', String, true)
    public bossLastName: string | null = '';

    /** Status of skill assignment (enum) */
    @JsonProperty('skillStatus', SkillStatusConverter, false)
    public skillStatus: ESkillStatus = ESkillStatus.Undefined;

    /** Status of skill assignment (string) */
    @JsonProperty('skillStatusString', String, false)
    public skillStatusString: string = '';

    /** Optional comment added when skill was manually assigned to user */
    @JsonProperty('reasonForAssignment', String, true)
    public reasonForAssignment: string | null = '';

    /**Comment from the employee when he request a skill (PerformanceCheck)  */
    @JsonProperty('requestReason', String, true)
    public requestReason: string | null = '';

    /**Comment from the employee when he request a skill (PerformanceCheck)  */
    @JsonProperty('responseReason', String, true)
    public responseReason: string | null = '';


    /** Optional description of the skill */
    @JsonProperty('skillDescription', String, true)
    public skillDescription: string | null = '';

    @JsonProperty('userSkillComments', [UserSkillComment], false)
    public userSkillComments: UserSkillComment[] = [];

    @JsonProperty('numberOfRecommendedLessons', Number, false)
    public numberOfRecommendedLessons: number = 0;

    @JsonProperty('userSkillCertificates', [UserCertificateViewModel], false)
    public userSkillCertificates: UserCertificateViewModel[] = [];

    @JsonProperty('attributes', [Attribute], false)
    public attributes: Attribute[] = [];
}