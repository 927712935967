import Session from '$core/Session';
import { StringHelper } from '$src/util/StringHelper';
import { observer } from 'mobx-react';
import React from 'react';

interface IProps {
    studentSpaceQuota: number;
    usedSpace: number;
    requestedSpace: number;
}
interface IState {
    text: string;
}

@observer // observing Session.instance.storage.registeredItem.isInitialized
export class StudentSpaceQuota extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { text: this.getText() };
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props !== prevProps) {
            this.setState({ text: this.getText() });
        }
    }

    public render() {
        return (
            <div>
                {this.state.text}
                {this.renderBar()}
            </div>
        );
    }

    public renderBar() {
        const usedMB: number = this.props.usedSpace / 1024.0 / 1024.0;
        const requestedMB: number = this.props.requestedSpace / 1024.0 / 1024.0;
        const usedPercent: number = Math.min(usedMB / (this.props.studentSpaceQuota / 100), 100);
        const requestedPercent: number = Math.min(requestedMB / (this.props.studentSpaceQuota / 100), 100);

        let exceeded = '';
        if (requestedMB + usedMB >= this.props.studentSpaceQuota) {
            exceeded = 'space-exceeded';
        }

        return (
            <div className="item-detail__file-sharing__student-space-quota">
                <div className="used-space" style={{ width: usedPercent + '%' }} />
                <div className={'requested-space ' + exceeded} style={{ width: requestedPercent + '%' }} />
            </div>
        );
    }

    /* FUNCTIONS */

    /**
     * Loads the text and replaces the placeholders
     * Example result: Storage space for uploaded files: 10 MB. Remaining free space: 9.2 MB
     */
    protected getText = (): string => {
        const remainingSpace = (this.props.studentSpaceQuota * 1024 * 1024 - this.props.usedSpace - this.props.requestedSpace);      

        let text = Session.instance.storage.translation.GetString('FileSharing:UsedSpace');
        text = text.replace('{0}', this.props.studentSpaceQuota.toString());
        text = text.replace('{1}', StringHelper.formatFileSize(remainingSpace));

        return text;
    }
}