import React, { useState, useRef } from "react";
import InlineSVG from "react-inlinesvg";
import searchIcon from '$resources/svgs/navi/search.svg';
import { KeyboardEvent } from 'react';
import Session from "$src/core/Session";
import GTIconButton from "./GTIconButton";

/**
 * **GTSearch Button Props**
 *
 * @export
 * @interface IGTSearchButtonProps
 */
export interface IGTSearchButtonProps {

    /**
     * Aria-label for search button, used also for tooltip text, default: "Search:AriaSearchButton"
     */
    searchButtonAriaLabel: string;
    /**
     * **Optional** onSearchButtonClick callback, return the search Text from input field
     */
    onSearchButtonClick?: (searchText: string) => void;
    /**
     * **Optional** Text for tooltip text, default: no tooltip
     */
    searchTooltipText?: string;
}

/**
 * 
 * **GTSearch Input Field Props**
 *
 * @export
 * @interface IGTSearchInputFieldProps
 */
export interface IGTSearchInputFieldProps {
    /**
     * **Optional** onSearchTextChanged callback, returns the searcht Text from input field
     */
    onSearchTextChanged?: (searchText: string) => void;
    /**
     * **Optional** Search placeholder in input field, default: Search:TextboxPlaceholder
     */
    searchPlaceholder?: string;
    /**
     * **Optional** Text filter size, default: middle (70%)
     */
    textFilterSize?: ETextFilterSize;
    /**
     * **Optional** Default react input attributes
     */
    defaultInputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}

export interface IGTSearchProps extends IGTSearchButtonProps, IGTSearchInputFieldProps {
    /**
     * **Optional** Component unique id, default: "GTSearch"
     */
    id?: string;
    /**
     * **Optional** Flag to show search button or not, default: false
     * **Important** if this flag is set to true, searchButtonAriaDescription must be set
     */
    showSearchButton?: boolean;
    /**
     * **Optional** Additional css class, default: ""
     */
    additionalClassNames?: string;
}

export enum ETextFilterSize {
    /**
     * Small = 50%
     */
    Small = '50',
    /**
     * Middle = 70%
     */
    Middle = '70',
    /**
     * Large = 100%
     */
    Large = '100'
}

/**
 * Global Teach Search Component
 * **Important** to use search button, the props searchButtonAriaDescription must be set too for A11y (Accessibility)
 */
export default function GTSearch(props: IGTSearchProps) {
    const textInput = useRef(null);
    const [currentFilter, setCurrentFilter] = useState('');

    function onSearchTextChanged(searchText: string) {
        if (props.onSearchTextChanged) {
            props.onSearchTextChanged(searchText);
        }
        setCurrentFilter(searchText)
    }

    function onSearchButtonClick() {
        if (props.onSearchButtonClick) {
            props.onSearchButtonClick(currentFilter);
        }
    }

    /**
     * Callback if enter click
     *
     * @param {KeyboardEvent<HTMLInputElement>} event
     */
    function onKeyUp(event: KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13) {
            onSearchButtonClick();
        }
    }

    return (
        <div className={`gtSearch ${props.additionalClassNames}`}>
            <input
                id={props.id}
                className={`gtSearch__txtFilter gtSearch__txtFilter--size-${props.textFilterSize}`}
                type="text"
                ref={textInput}
                placeholder={props.searchPlaceholder}
                onChange={(e) => onSearchTextChanged(e.target.value)}
                onKeyUp={(e) => onKeyUp(e)}
                value={currentFilter}
                {...props.defaultInputProps}
            />
            {props.showSearchButton &&
                <GTIconButton
                    onClick={() => onSearchButtonClick()}
                    id="SearchButton"
                    ariaLabel={props.searchButtonAriaLabel}
                >
                    <InlineSVG src={searchIcon} />
                </GTIconButton>
            }
        </div>
    )
}

GTSearch.defaultProps = {
    id: 'GTSearch',
    showSearchButton: false,
    additionalClassNames: '',
    searchPlaceholder: Session.instance.storage.translation.GetString('Search:TextboxPlaceholder'),
    searchButtonAriaLabel: Session.instance.storage.translation.GetString('Search:AriaSearchButton'),
    textFilterSize: ETextFilterSize.Middle
}