import InfoIcon from '$resources/svgs/misc/info.svg';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';

import { ExternalCourseScheduleTable } from '$src/components/externalCourse/ExternalCourseScheduleTable';
import { Heading } from '$src/components/shared/Heading';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { ERegistrationStatus } from '$src/storage/models/enums';
import { ExternalCourse } from '$src/storage/models/ExternalCourse/ExternalCourse';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import { ExternalCourseSchedule } from '$src/storage/models/ExternalCourse/ExternalCourseSchedule';
import { isSuccess } from '$src/util/Result';
import { DropDownList, DropDownListChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { CreateExternalCourseSchedule } from '../CreateDialogs/CreateExternalCourseSchedule';

interface IProps {
    parentHeadingLevel: number;
    selectedCourseProvider: ExternalCourseProvider | undefined;
    selectedCourse: ExternalCourse | undefined;
    selectedSchedule: ExternalCourseSchedule | undefined;
    onStepClicked?: (stepNumber: number) => void;
    onScheduleChanged?: (selectedSchedule: ExternalCourseSchedule) => void;
    isNew: boolean;
}

interface IState {
    errorMessage: string;
    scheduleList: ExternalCourseSchedule[];
    dataLoaded: boolean;
    selectedSchedule: ExternalCourseSchedule | undefined;
    shouldShowModal: boolean;
    copyClass: ExternalCourseSchedule | undefined;
}

export class StepSchedule extends React.Component<IProps, IState> {
    protected courseStepNumber = 2; // step number of course step defined in RequestWizard.tsx
    protected requestStepNumber = 4; // step number of request step defined in RequestWizard.tsx

    constructor(props: IProps) {
        super(props);

        this.state = {
            copyClass: undefined,
            dataLoaded: false,
            errorMessage: '',
            scheduleList: [],
            selectedSchedule: this.props.selectedSchedule,
            shouldShowModal: false,
        }
    }

    public async componentDidMount() {
        if (this.props.isNew) {
            await this.getSchedule();
            if (this.props.onScheduleChanged && this.state.selectedSchedule) {
                this.props.onScheduleChanged(this.state.selectedSchedule);
            }
        } else {
            this.setState({ dataLoaded: true });
        }
    }

    public render() {
        if (!this.state.dataLoaded) {
            return <ProgressSpinner />
        } else if (this.props.selectedCourse && this.props.selectedCourseProvider) {
            const ariaLabelNextStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToRequest')
            const ariaLabelPreviousStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToCourse')
            return (
                <div className="step-content">
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:CourseProvider</Translate>
                        </Heading>
                        <span>{this.props.selectedCourseProvider.name}</span>
                    </div>
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:Course</Translate>
                        </Heading>
                        <span>{this.props.selectedCourse.title}</span>
                    </div>
                    <div className="step-content__block">
                        <DropDownList
                            data={this.state.scheduleList}
                            itemRender={(li, p) => this.itemRender(li, p)}
                            value={this.state.selectedSchedule}
                            defaultValue={this.state.scheduleList[0]}
                            valueRender={(element, value) => this.valueRender(element, value)}
                            label={Session.instance.storage.translation.GetString('ExternalCourse:SelectSchedule')}
                            onChange={(e) => this.onChange(e)}
                            disabled={!this.props.isNew} />
                    </div>

                    {this.state.errorMessage.length > 0 &&
                        <div className="step-content__block">
                            <span className={'input-message error--dark'}>
                                <Translate>{this.state.errorMessage}</Translate>
                            </span>
                        </div>
                    }

                    <div className="step-content__block">
                        {this.renderScheduleDetails()}
                    </div>

                    {this.props.isNew &&
                        <React.Fragment>
                            {globalConfig.externalCourse.showCreateClassLink &&
                            (this.state.scheduleList == null || 
                            this.state.scheduleList.filter(sl => sl.scheduleId === 0).length === 0) &&
                                <button className="button-link button-link--colorized-dark create-link"
                                    onClick={() => this.createNewClass()}
                                    id="btnCreateClass">
                                    <Translate>ExternalCourse:CreateNewClass</Translate>
                                </button>
                            }
                            {globalConfig.externalCourse.showCopyClassLink && 
                            this.state.scheduleList != null && 
                            this.state.scheduleList.filter(sl => sl.scheduleId > 0).length > 0 &&
                                <button className="button-link button-link--colorized-dark create-link"
                                    onClick={() => this.copySelectedClass()}>
                                    <Translate>ExternalCourse:CopySelectedClass</Translate>
                                </button>
                            }
                            {this.state.scheduleList != null && 
                            this.state.scheduleList.filter(sl => sl.scheduleId === 0).length > 0 &&
                                <button className="button-link button-link--colorized-dark create-link"
                                    onClick={() => this.copySelectedClass()}>
                                    <Translate>ExternalCourse:ModifyCreatedClass</Translate>
                                </button>
                            }
                            <ModalPopup
                                isOpen={this.state.shouldShowModal}
                                onRequestClose={() => this.closeModal()}
                                title={Session.instance.storage.translation.GetString('ExternalCourse:CreateNewScheduleTitle')}>
                                <div className="modal__spread buttons">
                                    <CreateExternalCourseSchedule
                                        parentHeadingLevel={this.props.parentHeadingLevel}
                                        onSaveExternalCourseSchedule={(confirmed, createdSchedule) => this.onSaveExternalCourseSchedule(confirmed, createdSchedule)}
                                        scheduleToCopy={this.state.copyClass}
                                    />
                                </div>
                            </ModalPopup>
                            <div className="step-info__block">
                                <div className="inlineFlex">
                                    <InlineSVG src={InfoIcon} />
                                    <span>
                                        <Translate>ExternalCourse:HintDataSavedOnStepRequest</Translate>
                                    </span>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    <div className="step-navigation">
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.goToCourse()}
                            aria-label={ariaLabelPreviousStep}
                            id="btnGoToCourse">
                            <div className="inlineFlex">
                                <InlineSVG src={ArrowLeft} />
                                <span className="notMobile"><Translate>ExternalCourse:Course</Translate></span>
                            </div>
                        </button>
                        <span><Translate>ExternalCourse:Schedule</Translate></span>
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.goToRequest()}
                            aria-label={ariaLabelNextStep}
                            disabled={!this.state.selectedSchedule}
                            id="btnGoToRequest">
                                {
                                    this.state.selectedSchedule ? 
                                        <div className="inlineFlex">
                                            <span className="notMobile"><Translate>ExternalCourse:Request</Translate></span>
                                            <InlineSVG src={ArrowRight} />
                                        </div>
                                    :
                                        <div className="inlineFlex external-courses__link__disabled">
                                            <span className="notMobile"><Translate>ExternalCourse:Request</Translate></span>
                                        </div>
                                }
                        </button>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    private renderScheduleDetails(): JSX.Element | null {
        if (this.state.selectedSchedule) {
            return (
                <ExternalCourseScheduleTable
                    externalCourseSchedule={this.state.selectedSchedule}
                    showRequestStatus={false}
                    externalCourseRequestStatus={ERegistrationStatus.Undefined}
                />
            )
        } else {
            return null;
        }
    }

    private async getSchedule() {
        if (this.props.selectedCourse) {
            const schedules = await ExternalCourseService.instance.getExternalCourseClassListByCourse(this.props.selectedCourse.courseId);
            if (isSuccess<ExternalCourseSchedule[]>(schedules)) {
                const selectedSchedule = this.state.selectedSchedule ? this.state.selectedSchedule : schedules[0];
                if(selectedSchedule != null && selectedSchedule.scheduleId === 0) {
                    schedules.push(selectedSchedule);
                }
                this.setState({ scheduleList: schedules, selectedSchedule, dataLoaded: true });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCourseScheduleFailed', dataLoaded: true })
            }
        }
        else {
            this.setState({ dataLoaded: true });
        }
    }

    private itemRender(li: any, itemProps: ListItemProps) {
        const schedule = itemProps.dataItem as ExternalCourseSchedule;
        const itemChildren = <span>{`${schedule.formatedFirstAppointment} - ${schedule.formatedLastAppointment} ${schedule.location}`}</span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    private valueRender(element: any, value: any) {
        if (!value) {
            return element;
        }
        const schedule = value as ExternalCourseSchedule;
        const children = [
            <span key={1}>{`${schedule.formatedFirstAppointment} - ${schedule.formatedLastAppointment} ${schedule.location}`}</span>
        ];

        return React.cloneElement(element, { ...element.props }, children);
    }

    private onChange(event: DropDownListChangeEvent) {
        this.setState({
            selectedSchedule: event.target.value
        });
        
        if (this.props.onScheduleChanged && event.target.value) {
            this.props.onScheduleChanged(event.target.value)
        }
    }

    private createNewClass() {
        this.openModal();
    }

    private copySelectedClass() {
        if (this.state.selectedSchedule) {
            const copyClass = this.state.selectedSchedule;
            // Remove time, the correct time is set in the Web api
            if(copyClass.firstAppointment) {
                copyClass.firstAppointment.setHours(0);
            }
            if(copyClass.lastAppointment) {
                copyClass.lastAppointment.setHours(0);
            }
            this.setState({ copyClass: this.state.selectedSchedule });
            this.openModal();
        }
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false, copyClass: undefined });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private onSaveExternalCourseSchedule(confirmed: boolean, createdSchedule: ExternalCourseSchedule | undefined) {
        if (confirmed && createdSchedule) {
            const scheduleList = [...this.state.scheduleList.filter(sl => sl.scheduleId != 0)] || new Array<ExternalCourseSchedule>(); // Copy Schedules
            scheduleList.push(createdSchedule);
            this.setState({ scheduleList, selectedSchedule: createdSchedule });
            if (this.props.onScheduleChanged && createdSchedule) {
                this.props.onScheduleChanged(createdSchedule)
            }
        }
        this.closeModal();
    }

    private goToCourse() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.courseStepNumber);
        }
    }

    private goToRequest() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.requestStepNumber);
        }
    }
}