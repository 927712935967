import React from 'react';

import Session from '$core/Session';

interface IProps {
    classCosts?: number;
    classCurrency?: string;
    cssClass?: string;
}

export function CourseCosts(props: IProps) {

    if (!props.classCosts || props.classCosts === 0) {
        return null;
    } else {
        const costAndCurrency = Session.instance.storage.translation.GetString('CourseRequests:Costs') + ' ' +
            props.classCurrency + ' ' + props.classCosts.toFixed(2);
        return (
            <div className={props.cssClass} aria-label={costAndCurrency}>
                {costAndCurrency}
            </div>
        );
    }
}