import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';

import { Translate } from '$components/shared/Translate';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';

import { PaymentClassSearchResponse } from '$src/storage/models/Payment/PaymentClassSearchResponse';
import { PaymentUserSearchResponse } from '$src/storage/models/Payment/PaymentUserSearchResponse';

import IconDelete from '$resources/svgs/misc/cancel.svg';
import IconWarning from '$resources/svgs/misc/info--disabled.svg';
import { ValidateUserRegistrationOnClassResponse } from '$src/storage/models/Payment/ValidateUserRegistrationOnClassResponse';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { isSuccess } from '$src/util/Result';
import Session from '$src/core/Session';

interface IPaymentBookingValidationProps {
    selectedClass?: PaymentClassSearchResponse;
    selectedUser: PaymentUserSearchResponse[];

    /** When user was removed from selected list */
    onUserChanged: (newUserList: PaymentUserSearchResponse[]) => void;

    /** Returns only the valid users from selected list */
    onUserValidated: (validatedUser: PaymentUserSearchResponse[]) => void;

    /** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
    bossRelationCode?: string;
}

class PaymentBookingValidationEntry {
    user: PaymentUserSearchResponse;
    hasFreeSeat: boolean;
    /** True when validation is ok - False when user is already booked on this class */
    hasNoRegistration: boolean;
    /** True when validation is ok - False when user is already booked on another class and multi booking is disabled */
    hasNoMultipleRegistration: boolean;
    /** Async validation process is running */
    isValidating?: boolean;
    isValid?: boolean;
}

/**
 * Renders the selected users of user search with a validation message, if validation fails
 * @param props Selected users, class and return function to give the validated users back
 * @returns 
 */
export default function PaymentBookingValidation(props: IPaymentBookingValidationProps) {
    const [selectedUser, setSelectedUser] = useState<PaymentUserSearchResponse[]>(props.selectedUser); // Just the selected users from user search
    const [selectedClass, setSelectedClass] = useState<PaymentClassSearchResponse | undefined>(props.selectedClass); // Just the selected class from class search
    const [validatedUser, setValidatedUser] = useState<PaymentBookingValidationEntry[]>([]); // Users after validation - these users will be rendered in this control
    const [cachedRegistrationValidations, setCachedRegistrationValidations] = useState<ValidateUserRegistrationOnClassResponse[]>([]);
    const isUserPaymentAdmin = Session.instance.loginUser?.roles.includes("PaymentBookingOverviewAdmin") ? true : false;

    /** Update selected users & class from outside */
    useEffect(() => {
        setSelectedUser(props.selectedUser);
    }, [props.selectedUser]);
    useEffect(() => {
        setSelectedClass(props.selectedClass);
    }, [props.selectedClass]);

    /** Recalulate status of selected users for rendering or update status after new user registration info */
    useEffect(() => {
        const validated: PaymentBookingValidationEntry[] = [];
        let validCount = 0;

        for (let i = 0; i < selectedUser.length; i++) {
            const entry: PaymentBookingValidationEntry = {
                user: selectedUser[i],
                hasFreeSeat: selectedClass === undefined || selectedClass.freeSeats - validCount > 0,
                hasNoRegistration: cachedRegistrationValidations.some(p => p.userId === selectedUser[i].userId && p.classId === selectedClass?.classId && !p.alreadyBookedValidationFailed),
                hasNoMultipleRegistration: cachedRegistrationValidations.some(p => p.userId === selectedUser[i].userId && p.classId === selectedClass?.classId && !p.noMultiBookingValidationFailed),
            };
            entry.isValidating = entry.hasFreeSeat && !cachedRegistrationValidations.some(p => p.userId === selectedUser[i].userId && p.classId === selectedClass?.classId);
            if (isUserPaymentAdmin) {
                entry.hasFreeSeat = true;
            }

            entry.isValidating = entry.hasFreeSeat && !cachedRegistrationValidations.some(p => p.userId === selectedUser[i].userId && p.classId === selectedClass?.classId);
            entry.isValid = entry.hasFreeSeat && entry.hasNoRegistration && entry.hasNoMultipleRegistration && !entry.isValidating;

            validated.push(entry);
            if (entry.isValid) validCount++;
        }

        setValidatedUser(validated);
    }, [selectedUser, selectedClass, cachedRegistrationValidations]);

    /** Will call api to validate user registration on class */
    useEffect(() => {
        if (!selectedClass?.classId) {
            return;
        }

        validatedUser.filter(p => p.isValidating).forEach(entry => {
            PaymentAdminService.instance.validateUserForClass(entry.user.userId, selectedClass?.classId, props.bossRelationCode).then((validated) => {
                if (isSuccess<ValidateUserRegistrationOnClassResponse>(validated)) {
                    setCachedRegistrationValidations([...cachedRegistrationValidations, validated]);
                }
                return entry;
            });
            return entry;
        });
        props.onUserValidated(validatedUser.filter(p => p.isValid).map(p => p.user));

    }, [validatedUser]);

    /** When remove removed is clicked */
    function removeEntry(entry: PaymentUserSearchResponse) {
        const entries: PaymentUserSearchResponse[] = selectedUser.filter(p => p.userId !== entry.userId);
        setSelectedUser(entries);
        props.onUserChanged(entries);
    }

    /** first name last name and optional (company) */
    function renderName(entry: PaymentUserSearchResponse) {
        const company = entry.company && <>({entry.company})</>;
        return (
            <span>{entry.firstName} {entry.lastName} {company}</span>
        );
    }

    /** Renders validating spinner or validation error messages per user */
    function renderValidation(entry: PaymentBookingValidationEntry) {
        if (!selectedClass) {
            return;
        }

        if (entry.isValidating) {
            return <div className="spinner"><ProgressSpinner size={16} /></div>
        }

        return <>
            {!entry.isValid && <InlineSVG src={IconWarning} />}
            {!entry.hasFreeSeat && <Translate>PaymentBooking:UserNoFreeSeat</Translate>}
            {entry.hasFreeSeat && !entry.hasNoRegistration && <Translate>PaymentBooking:HasAlreadyRegistration</Translate>}
            {entry.hasFreeSeat && !entry.hasNoMultipleRegistration && <Translate>PaymentBooking:HasAlreadyRegistrationOtherClass</Translate>}
        </>
    }

    return (
        <div role="table" className="div-table__vertical-table payment-booking__validation">
            <div role="rowgroup">
                <div role="row" className="div-table__vertical-table-row">
                    <div role="columnheader" className='user-search__selected-user_header'>
                        <Translate>PaymentBooking:UserSearchSelectedUser</Translate>
                    </div>
                    <div role="cell">
                        {
                            validatedUser.map((entry) => {
                                return (
                                    <div key={entry.user.userId} className="flexOneline">
                                        <button className="link-button" onClick={() => removeEntry(entry.user)}><InlineSVG src={IconDelete} /></button>&nbsp;

                                        <span className={`user-search__selected-user ${!entry.isValid && !entry.isValidating ? 'disabled' : ''}`}>
                                            {renderName(entry.user)}
                                        </span>

                                        <div className='user-search__status'>
                                            {renderValidation(entry)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}