import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class TrainingPlanRegisterRequest {

    @JsonProperty('langCode', String, false)
    public langCode: string = '';

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('f2fClassIds', [Number], false)
    public f2fClassIds: number[] | null = null;

    @JsonProperty('selectedBossId', Number, false)
    public selectedBossId: number = 0;

    @JsonProperty('registerAsStatusRequested', Boolean, false)
    public registerAsStatusRequested: boolean = false;
}