import React, { useEffect, useState } from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import InlineSVG from 'react-inlinesvg';
import { toast } from 'react-toastify';

import GTIconButton from '$components/shared/Atoms/GTIconButton';
import { Translate } from '$components/shared/Translate';
import ClassSearchSearchBox, { DefaultFilter } from '$components/paymentBooking/ClassSearchSearchBox';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';

import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import SaveIcon from '$resources/svgs/PaymentBookingJournal/pay_save.svg';
import { PaymentClassSearchResponse } from '$src/storage/models/Payment/PaymentClassSearchResponse';
import Session from '$src/core/Session';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { isSuccess } from '$src/util/Result';
import { EItemTypeAttributeValue } from '$src/storage/models/enums';
import DateHelper from '$src/util/DateHelper';

interface IActionChangeClassProps {
	data: PaymentBookingJournalResponse;
	onUpdated: (newData: PaymentBookingJournalResponse) => void;
	/** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
	bossRelationCode?: string;
}

/**
 * Component to change a class of a payment checkout item and return the updated pcoi back
 * @param props 
 * @returns 
 */
export default function ActionChangeClass(props: IActionChangeClassProps) {
	const [newClass, setNewClass] = useState<PaymentClassSearchResponse>();
	const [comment, setComment] = useState<string>();
	const [currentClassString, setCurrentClassString] = useState('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isOk, setIsOk] = useState<boolean>();
	const [errorMsg, setErrorMsg] = useState<string>();
	
	const selectedclassString = Session.instance.storage.translation.GetString('PaymentBooking:ClassSearchSelectedClassname');

	useEffect(() => {
		const s = '{Course}, {Class}, {Start}'
			.replace('{Course}', props.data.lesson || '-')
			.replace('{Class}', props.data.classTitle || '-')
			.replace('{Start}', DateHelper.toDateTimeString(props.data.classDate))
		setCurrentClassString(s);
	}, []);

	function commit() {
		// button shall not be visible - check nevertheless
		if (props.data.itemType !== EItemTypeAttributeValue.F2FCourse) {
			toast.error('Not supportet');
			return;
		}

		setIsLoading(true);
		PaymentAdminService.instance.changeClass(props.data.id, props.data.participantId, props.data.itemId, newClass!.classId, comment, props.bossRelationCode).then((res) => {
			setIsLoading(false);
			if (isSuccess<PaymentBookingJournalResponse>(res)) {
				setIsOk(true);
				props.onUpdated(res);
			} else {
				setIsOk(false);
				setErrorMsg(res.message);
			}
		});
	}

	function renderAlerts() {
		if (isLoading) return;
		if (isOk === true) {
			return <Alert alertAppereance='single-line' alertType='success' message='PaymentBookingJournal:ChangeClassOk' />
		} else if (isOk === false) {
			return <Alert alertAppereance='box' alertType='error' message='PaymentBookingJournal:ChangeClassFailed'>{errorMsg && <p>{errorMsg}</p>}</Alert>
		}
		return;
	}

	function renderForm() {
		if (isLoading || isOk === true) return;

		return (
			<div className='payment-booking-journal-action__grid'>
				<div>
					<Translate>PaymentBookingJournal:ChangeClassCurrentClass</Translate>
				</div>
				<div className='payment-booking-journal-action__current'>
					{currentClassString}
				</div>
				<div>&nbsp;</div>

				<div>
					<Translate>PaymentBookingJournal:Class</Translate>
				</div>
				<div>
					<ClassSearchSearchBox bossRelationCode={props?.bossRelationCode} filter={{ ...DefaultFilter, itemId: props?.data.itemId }} searchImmediately={true} onClassSelected={(e) => setNewClass(e)} />
					<div className='highlight-selected'>
						{newClass?.replacePlaceholders(selectedclassString)}
					</div>
				</div>
				<div></div>

				<div>
					<Translate>PaymentBookingJournal:Comment</Translate>
				</div>
				<div>
					<TextArea className="input-field" autoSize={true} onChange={(e) => setComment(e.value.toString())} />
				</div>
				<div>
					<GTIconButton id='save' ariaLabel='save' onClick={() => commit()} defaultButtonProps={{ disabled: !newClass || newClass.classId === props?.data.classId }} >
						<InlineSVG src={SaveIcon} />
					</GTIconButton>
				</div>
			</div>
		)
	}

	return (
		<div>
			<p>
				<Translate>PaymentBookingJournal:ChangeClassText</Translate>
			</p>

			{isLoading && <ProgressSpinner />}
			{renderAlerts()}
			{renderForm()}
		</div>
	);
}