import Logger from '$src/core/Logger';
import UserService from '$src/core/Services/UserService';
import SessionCache from '$src/storage/cache/SessionCache';
import { User } from '$src/storage/models/User';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';

const CACHING_DURATION = 100; // seconds

/**
 * This class allows to get users from server or store users to server. To improve performance users
 * are stored in a local cache, too. 
 */
export default class UsersStorage extends SessionCache<number, User> {
    protected className = 'UsersStorage';
    protected loggerLocality = 'Storage.UsersStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, false);
    }

    /**
     * Get User from cache or server.
     * @param userId Id of user to get.
     */
    public async getUser(userId: number, reload: boolean = false): Promise<User | null> {

        const methodName = `${this.className}:getUser()`;

        if (reload) {
            Logger.log(this.loggerLocality, `${methodName} removing user from cache and reload, userId=${userId}.`);            
            this.removeObjectFromCache(userId);
        }        

        if (this.isObjectInCache(userId)) {
            Logger.log(this.loggerLocality, `${methodName} getting user from cache, userId=${userId}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting user from server, userId=${userId}.`);
            const response = await UserService.instance.getUser(userId);
            if (isSuccess<User>(response)) {
                Logger.log(this.loggerLocality, `${methodName} got user from server, userId=${response.id}, username=${response.domainName}\\${response.username}.`);
                this.saveObjectToCache(userId, response);
            } else {
                this.removeObjectFromCache(userId);
            }
        }
        return this.getObjectFromCache(userId);
    }

    /**
     * Save user to server and cache.
     * @param user User to be stored.
     */
    public async saveUser(user: User): Promise<User | GtError> {
        // TODO @MS User sollte auch im Cache (s. Basisklasse) gespeichert bzw. nachgeführt oder ersetzt werden
        const response = await UserService.instance.saveUser(user);
        return response;
    }

    /**
     * Rmove all users from cache.
     */
    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}
