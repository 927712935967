import ServiceClient from '../ServiceClient';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Assessment } from '$src/storage/models/Assessment';

/**
 * AssessmentService provides all Service Methods for Assessments
 */
export default class AssessmentService extends ServiceClient {
    protected static _instance: AssessmentService | null = null;

    protected className = 'AssessmentService';
    protected loggerLocality = 'AssessmentService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): AssessmentService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Set lesson status of assessment. 
     * @param itemId id of item to set the status
     */
    public async setAssessmentLessonStatus(itemId: number) {
        return await this.post<BooleanResponse>('assessment/LessonStatus', itemId, BooleanResponse, undefined);
    }

    /**
     * Get details of assessment, for example if the user can ste lesson status. 
     * @param itemId id of item to set the status
     */
    public async getAssessmentDetails(itemId: number) {
        return await this.get<Assessment>('assessment/details', Assessment, undefined, undefined, itemId.toString());
    }

} 