import jwtDecode from 'jwt-decode';
import { action, computed, observable } from 'mobx';

import Logger from '$src/core/Logger';

import { App } from '$src/components/layout/App';
import ServiceClient from '$src/core/ServiceClient';
import AuthService from '$src/core/Services/AuthService';
import ItemRatingService from '$src/core/Services/ItemRatingService';
import UserPreferencesService from '$src/core/Services/UserPreferencesService';
import UserService from '$src/core/Services/UserService';
import { ELessonFilterContext } from '$src/storage/models/enums';
import MyLessonFilter from '$src/storage/models/MyLessonFilter';
import { RenewTokensRequest } from '$src/storage/models/RequestObjects/RenewTokensRequest';
import { SsoAuthenticationStatus } from '$src/storage/models/SsoAuthenticationStatus';
import { Auth, User } from '$src/storage/models/User';
import { UserBossRelation } from '$src/storage/models/UserBossRelation';
import { ColorScheme } from '$src/storage/models/UserPreferences/ColorScheme';
import { UserPreferences } from '$src/storage/models/UserPreferences/UserPreferences';
import Storage from '$src/storage/Storage';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { TutoringFilter } from '$src/storage/models/TutoringFilter';
import { InjectAfterLoginConfiguration } from '$src/configAfterBoot';
import { BookingsFilter } from '$src/storage/models/Trainer/BookingsFilter';
import SystemRoles from '$src/core/SystemRoles';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import PrivacyService from './Services/PrivacyService';
import ConfigService from './Services/ConfigService';
import { StringResponse } from '$src/storage/models/StringResponse';

/**
 * Session object used to store information during a user session.
 */
export default class Session {

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): Session {
        return this._instance || (this._instance = new this());
    }

    public get storage(): Storage {
        return this._storage;
    }

    /**
     * Reference to the 'main' App
     */
    public app: App;

    public get isUserLoggedIn(): boolean {
        let retVal = false;

        if (this.jwtToken !== '' && this.refreshToken !== '' && this._loginUser != null) {
            retVal = true;
        } else {
            retVal = false;
        }
        return retVal;
    }

    @computed
    public get loginUser(): User | null {
        return this._loginUser;
    }

    @computed
    public get isLoggedInUserBossOfDefaultRelation(): boolean {
        if (this._loginUserIsBossOfDefaultRelation != null) {
            return this._loginUserIsBossOfDefaultRelation;
        }
        return false;
    }

    @computed
    public get isPasswordExpired(): boolean {
        const isPasswordExpired = this.jwtTokenDecoded['gt-user-passwordexpired-status'];
        if (isPasswordExpired != null && isPasswordExpired.toLowerCase() === 'true') {
            return true;
        } else {
            return false;
        }
    }

    @computed
    public get appliedColorScheme(): string | null {
        return this._appliedColorScheme;
    }

    @computed
    public get languageCode(): string | null {
        return localStorage.getItem('languageCode') || this._languageCode;
    }

    public get loginUserPreferences(): UserPreferences | undefined {
        return this._loginUserPreferences;
    }

    @computed({ name: 'LAST ERROR MESSAGE' })
    public get lastErrorMessage() { return this._lastErrorMessage; }

    public get jwtToken(): string {
        const parameters = new URLSearchParams(window.location.search);
        const urlToken = parameters.get('token');
        const urlRefreshToken = parameters.get('refreshToken');
        if (urlToken != null && urlRefreshToken != null) {
            this.setJwtToken(urlToken, urlRefreshToken, false);

            parameters.delete('token');
            parameters.delete('refreshToken');
            window.location.search = parameters.toString();

            return urlToken;
        }
        const sessionJWT = sessionStorage.getItem('jwtToken');
        const localJWT = localStorage.getItem('jwtToken');
        return (sessionJWT == null ? (localJWT != null ? localJWT : '') : sessionJWT);
    }

    public get SrvClientTimeDif(): number {
        const sessionTimeDif = sessionStorage.getItem('srvClientTimeDif');
        const localTimeDif = localStorage.getItem('srvClientTimeDif');
        return sessionTimeDif == null ? (localTimeDif == null ? 0 : Number(localTimeDif)) : Number(sessionTimeDif);
    }

    public get isJWTStoredLocal(): boolean {
        let retVal: boolean = false;
        const sessionJWT = sessionStorage.getItem('jwtToken');
        const localJWT = localStorage.getItem('jwtToken');
        if (localJWT !== null && sessionJWT === null) {
            retVal = true;
        } else {
            retVal = false;
        }
        return retVal;
    }

    public get refreshToken(): string {
        const sessionToken = sessionStorage.getItem('refreshToken');
        const localToken = localStorage.getItem('refreshToken');
        return sessionToken === null ? (localToken === null ? '' : localToken) : sessionToken;
    }

    public get jwtTokenDecoded(): any {
        if (this.jwtToken !== '') {
            return jwtDecode(this.jwtToken);
        }
        return undefined;
    }

    public passwordRecoverTimestamp: string;


    @observable public isSmallScreen: boolean;
    @observable public isSmallScreenMenuVisible: boolean = false;
    @observable public activePage: string = 'Home';
    @observable public activeTabHome: number;
    public ie11CssStyleSheet: Map<string, string>;


    protected static _instance: Session | null = null;

    protected className = 'Session';
    protected loggerLocality = 'Session';
    protected _storage: Storage;

    @observable protected _loginUser: User | null = null;
    @observable protected _languageCode: string | null = null;
    protected _loginUserPreferences: UserPreferences | undefined = undefined;
    @observable protected _lastErrorMessage: string = '';
    @observable protected _loginUserIsBossOfDefaultRelation: boolean | null;
    protected _loginUserBossRelations: UserBossRelation[] = [];
    protected _lessonFilter: { [id: string]: MyLessonFilter } = {};
    protected _isRenewingTokens: boolean = false;
    @observable protected _appliedColorScheme: string | null = null;
    @observable protected _hasFavorites: boolean = false;
    protected _srvTime: number = 0;
    @observable protected _colorSchemeSwitching: boolean = false;
    @observable protected _tutoringFilters: TutoringFilter | null = null;
    @observable protected _bookingFilters: BookingsFilter | null = null;
    protected _bossAllowedToRegisterNewUsers: boolean;
    protected _hasOutdatedTermsAndConditions = false;
    protected _isGdprEnabled = false;

    protected _isSSOSession: boolean = false;

    /** session initiated by SSOSP (or other external login) */
    protected _isExternalLoginSession: boolean = false;

    /** Constructor is private -> use Session.instance (singleton) */
    private constructor() {
        this._storage = new Storage();
    }

    public get isRenewingTokens(): boolean {
        const methodName = `${this.className}:getIsRenewingTokens()`;
        Logger.log(this.loggerLocality, `${methodName}: getting isRenewingTokens, value is: ${this._isRenewingTokens}`);
        return this._isRenewingTokens;
    }

    public set isRenewingTokens(value: boolean) {
        const methodName = `${this.className}:setIsRenewingTokens()`;
        Logger.log(this.loggerLocality, `${methodName}: setting isRenewingTokens to ${value}`);
        this._isRenewingTokens = value;
    }

    @action public toggleSmallScreenMenu(): void {
        this.isSmallScreenMenuVisible = !this.isSmallScreenMenuVisible;
        if (document.documentElement != null) {
            if (this.isSmallScreenMenuVisible) {
                document.documentElement.classList.add('small-screen-menu-visible')
            } else {
                document.documentElement.classList.remove('small-screen-menu-visible');
            }
        }
    }

    @action public setSmallScreenMenuVisibility(visible: boolean): void {
        this.isSmallScreenMenuVisible !== visible ? this.toggleSmallScreenMenu() : visible;
    }

    @action public setIsSmallScreen(value: boolean): void {
        const methodName = `${this.className}:setIsSmallScreen()`;
        this.isSmallScreen = value;
        Logger.log(this.loggerLocality, `${methodName} = ${value}`);
    }

    @action public setHasLoggedOut(value: boolean): void {
        const methodName = `${this.className}:setHasLoggedOut()`;
        sessionStorage.setItem('sso_userHasLoggedOut', value.toString());
        Logger.log(this.loggerLocality, `${methodName} = ${value}`);
    }

    @action
    public setLoginUserPreferences(userPreferences: UserPreferences) {
        const methodName = `${this.className}:userPreferences()`;
        Logger.log(this.loggerLocality, `${methodName} = ${JSON.stringify(userPreferences)}`);
        this._loginUserPreferences = userPreferences;

    }

    @action
    public setIsSSOSession(value: boolean) {
        const methodName = `${this.className}:setIsSSOSession()`;
        Logger.log(this.loggerLocality, `${methodName} = Set isSSOSession to ${value} `);
        this._isSSOSession = value;
    }

    @computed
    public get getIsSSOSession() {
        return this._isSSOSession;
    }

    @action
    public setIsExternalLoginSession(value: boolean) {
        const methodName = `${this.className}:setIsExternalLoginSession()`;
        Logger.log(this.loggerLocality, `${methodName} = Set isExternalLoginSession to ${value} `);
        this._isExternalLoginSession = value;
    }
    @computed
    public get getIsExternalLoginSession() {
        return this._isExternalLoginSession;
    }

    @action
    public setHasFavorites(hasFavorites: boolean) {
        this._hasFavorites = hasFavorites;
    }

    @computed
    public get getHasFavorites(): boolean {
        return this._hasFavorites;
    }

    @computed
    public get getTutoringFilters(): TutoringFilter | null {
        return this._tutoringFilters;
    }

    @action
    public setTutoringFilters(filters: TutoringFilter) {
        this._tutoringFilters = filters;
    }

    @action
    public setBookingFilters(filters: BookingsFilter) {
        this._bookingFilters = filters;
    }

    @action
    public setColorSchemeSwitching(colorSchemeSwitching: boolean) {
        this._colorSchemeSwitching = colorSchemeSwitching;
    }

    @computed
    public get getColorSchemeSwitching(): boolean {
        return this._colorSchemeSwitching;
    }

    @computed
    public get getHasLoggedOut(): boolean {
        if (sessionStorage.getItem('sso_userHasLoggedOut') === 'true') {
            return true;
        }
        return false;
    }

    public isLoggedInUserBossOfRelation(relationCode: string): boolean {
        if (this._loginUserBossRelations != null) {
            return this._loginUserBossRelations.filter(r => r.bossTypeCode.toLocaleUpperCase() === relationCode.toLocaleUpperCase()).length > 0;
        }
        return false;
    }

    public getLoggedInUserBossrelations(): UserBossRelation[] {
        return this._loginUserBossRelations;
    }

    public isBossAllowedToRegisterNewUsers(): boolean {
        return this._bossAllowedToRegisterNewUsers;
    }

    public setLessonFilter(id: string, lessonFilter: MyLessonFilter) {
        const methodName = `${this.className}:setLessonFilter()`;
        Logger.log(this.loggerLocality, `${methodName} = ${JSON.stringify(lessonFilter)}`);
        this._lessonFilter[id] = lessonFilter;
    }

    public getLessonFilter(id: string, context: ELessonFilterContext): MyLessonFilter {
        if (this._lessonFilter[id] == null) {
            this._lessonFilter[id] = new MyLessonFilter(context)
        }
        return this._lessonFilter[id];
    }

    @action
    public applyColorScheme(colorScheme: ColorScheme | undefined) {

        if (document.documentElement != null) {
            // Remove all possible scheme classes
            for (let i = globalConfig.colorSchemes.colorSchemeDefinitions.length - 1; i >= 0; i--) {
                document.documentElement.classList.remove(globalConfig.colorSchemes.colorSchemeDefinitions[i].cssClassName);
            }
            // Add new scheme class
            if (colorScheme != null) {

                document.documentElement.classList.add(colorScheme.cssClassName);
                this._appliedColorScheme = colorScheme.cssClassName;
            }
            else {
                this._appliedColorScheme = '';
            }
        }
    }

    public getLoginUserPreferencesColorScheme(): ColorScheme | undefined {
        let userScheme = globalConfig.colorSchemes.colorSchemeDefinitions.find(
            s => (Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest) &&
                 s.isDefault) ||
                (Session.instance.loginUserPreferences != null &&
                Session.instance.loginUserPreferences.preferences != null &&
                Session.instance.loginUserPreferences.preferences.scheme != null &&
                s.name.toUpperCase() === Session.instance.loginUserPreferences.preferences.scheme.toUpperCase()))

        // if user is guest and no default scheme is configured, take the first one
        if(Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest) && !userScheme) {
            userScheme = globalConfig.colorSchemes.colorSchemeDefinitions[0];
        }
        
        return userScheme;
    }

    @action public setActiveTabHome(activeTab: number): void {
        this.activeTabHome = activeTab;
    }

    @action public setActivePage(activePage: string): void {
        this.activePage = activePage;
    }

    @computed
    public get getActivePage(): string {
        return this.activePage;
    }

    @computed
    public get getUserLanguageCodeOrFallBack(): string {
        return this.languageCode === null ? globalConfig.languageProperties.fallBackLanguage : this.languageCode;
    }

    public async reloadLoginUser(): Promise<void> {
        if (this._loginUser !== null) {
            const tempUser = await this.storage.user.getUser(this._loginUser.id, true)
            this.setLoginUser(tempUser, await this.isGdprEnabled());
        }
    }

    private isGdprEnabled = async () => {
        const isGdprEnabled: BooleanResponse | GtError =
            await PrivacyService.instance.isGdprEnabled();

        if (isSuccess<BooleanResponse>(isGdprEnabled)) {
            return isGdprEnabled.status;
        }
        return false;
    }

    @action('Session.setLastErrorMessage()')
    public setLastErrorMessage(message: string) { this._lastErrorMessage = message; }

    @action
    public storeCurrentSsoIdP(identityProvider: string): void {
        sessionStorage.setItem('sso_setCurrentSsoIdP', identityProvider);
    }

    @computed
    public get getCurrentSsoIdP(): string {
        return sessionStorage.getItem('sso_setCurrentSsoIdP') || '';
    }

    @action
    public setReturnUrlAfterLogin(url: string): void {
        sessionStorage.setItem('returnUrlAfterLogin', url);
    }

    @computed
    public get getReturnUrlAfterLogin(): string {
        return sessionStorage.getItem('returnUrlAfterLogin') || '';
    }

    @action
    public setHasOutdatedTermsAndConditions(isOutdated: boolean): void {
        this._hasOutdatedTermsAndConditions = isOutdated;
    }

    @computed
    public get getHasOutdatedTermsAndConditions(): boolean {
        return this._hasOutdatedTermsAndConditions;
    }

    @action
    public setIsGdprEnabled(isGdprEnabled: boolean): void {
        this._isGdprEnabled = isGdprEnabled;
    }

    @computed
    public get getIsGdprEnabled(): boolean {
        return this._isGdprEnabled;
    }

    @computed
    public get getIgnoreRequiredUserProfileData(): boolean {
        return sessionStorage.getItem('IgnoreRequiredUserProfileData') === 'true';
    }

    @action
    public setIgnoreRequiredUserProfileData(): void {
        sessionStorage.setItem('IgnoreRequiredUserProfileData', 'true');
    }

    /**
     * Check if current user has role.
     * 
     * @param role Role
     */
    public hasCurrentUserRole(role: string): boolean {
        if (this.loginUser != null && this.loginUser.roles !== undefined) {
            if (this.loginUser.roles.find(usrRole => usrRole === role) !== undefined) {
                return true;
            }
        }
        return false;
    }

    /**
     * Check if current user has at least on of the roles.
     * @param roles Array of roles
     */
    public hasCurrentUserAtLeastOneOfRoles(roles: string[]) {
        if (roles !== undefined && roles != null && roles.length > 0) {
            for (const role of roles) {
                if (this.hasCurrentUserRole(role)) {
                    return true;
                }
            }
        }
        return false;
    }

    public isCurrentUserTrainer() {
        if (this.loginUser != null) {
            return this.loginUser.isTrainer;
        } else {
            return false;
        }
    }
    /**
     * Check if current user is member of group.
     * Remark: The groups the user belongs to are got from the JWToken.
     * @param groupName Name of the group
     */
    public isCurrentUserMemberOfGroup(groupName: string): boolean {
        groupName = groupName.toLowerCase();
        const groupsFromJwt = this.jwtTokenDecoded != null ? this.jwtTokenDecoded['gt-group'] : null;
        if (groupsFromJwt != null && groupsFromJwt.length > 0) {
            if (Array.isArray(groupsFromJwt)) { // groupsFromJwt is an array if there ara multiple groups in the token
                for (const g of groupsFromJwt) {
                    if (g.toLocaleLowerCase() === groupName) {
                        return true;
                    }
                }
            } else { // groupsFromJwt is a string if there is only one group in the token
                if (groupsFromJwt.toLocaleLowerCase() === groupName) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Check if current user is member of at least on of the groups.
     * @param roles Array of group names
     */
    public isCurrentUserMemberOfAtLeastOneOfGroups(groups: string[]) {
        if (groups !== undefined && groups != null && groups.length > 0) {
            for (const group of groups) {
                if (this.isCurrentUserMemberOfGroup(group)) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Try to log in user with client certificate (single-sign-on).
     */
    public async sso(): Promise<boolean> {
        let authOk = false;

        // Clear any data from previous session.
        await this.logout();

        await this.refreshStrings();

        // Authenticate user with web API.         
        const response: Auth | GtError = await ServiceClient.instance.get<Auth>('sso', Auth);

        // If the WebAPI Call was successfull
        if (isSuccess<Auth>(response)) {
            authOk = true;

            await this.initAfterSuccessfulLogin(response, false);
            Session.instance.setIsSSOSession(true);
        }
        return authOk;
    }

    /**
     * Initiates the session after sso
     *
     * @param {SsoAuthenticationStatus} status
     * @returns {Promise<boolean>}
     * @memberof Session
     */
    public async initSsoSession(status: SsoAuthenticationStatus): Promise<boolean> {
        await this.initAfterSuccessfulLogin(status, false);
        Session.instance.setIsExternalLoginSession(true);
        return true;
    }

    /**
     * Log in user with credentials from parameters.
     * If login is successful, load user from server to {@link Session._loginUser}, too.
     *
     * @param {string} domainName name of user's domain
     * @param {string} username user's username
     * @param {string} password user's password
     * @returns {Promise<boolean>} true if login was successful
     * @memberof Session
     */
    public async login(domainName: string, username: string, password: string, staySignedIn: boolean): Promise<boolean | GtError> {
        // const methodName = `${this.className}:login()`;

        let authOk = false;

        // Clear any data from previous session.
        await this.logout();

        await this.refreshStrings();

        // Authenticate user with web API.     
        const authHeader = { Authorization: `Basic ${btoa(unescape(encodeURIComponent(domainName + ':' + username + ':' + password)))}`, RefreshToken: Session.instance.refreshToken };
        const response: Auth | GtError = await ServiceClient.instance.get<Auth>('auth', Auth, authHeader);

        // If the WebAPI Call was successfull
        if (isSuccess<Auth>(response)) {
            authOk = true;
            await this.initAfterSuccessfulLogin(response, staySignedIn);
        } else {
            return response;
        }
        return authOk;
    }


    /**
     * Logout current user, clear all locally cached data.
     */
    @action('Session.logout() Remove all userspecific cached / stored information')
    public async logout() {
        if (this._loginUser !== null) {
            await this.removeRefreshTokenFromDB(this.refreshToken, this._loginUser.id);
        }

        sessionStorage.removeItem('refreshToken');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('IgnoreRequiredUserProfileData');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('isLoggedInUserBoss');
        localStorage.removeItem('srvClientTimeDif');

        this.setLoginUser(null, false);
        this.setLastErrorMessage('');
        this.setIsSSOSession(false);
        this._storage.clearAll();
        this._loginUserIsBossOfDefaultRelation = null;
        this.setBossAllowedToRegisterNewUsers(false);
    }

    @action('Session.setLanguageCode(langCode?: string) save LanguageCode in Session')
    public async setLanguageCode(langCode?: string): Promise<void> {
        const methodName = `${this.className}:setLanguageCode()`;
        const langProps = globalConfig.languageProperties;
        let curLangCode: string | undefined = langCode;

        // Priorities
        // ( 1. Param ) Only from Language Switcher
        // 2. URL Language Parameter
        // 3. Local Storage
        // 4. Notification Language
        // 4. Browser Language / OS Language
        // 5. Fall Back Language

        // Try getting  URL Language if no param
        if (curLangCode === undefined) {
            const urlLanguage: string | undefined = this.getURLLanguageCode(langProps.urlParameterName);
            if (urlLanguage != null && this.isLanguageValid(urlLanguage)) {
                curLangCode = urlLanguage;
                Logger.log(this.loggerLocality, `${methodName} : taking language from URL Parameter (${curLangCode})`);
            }
        }

        // Try getting  Storage Language if no param and URL
        if (curLangCode === undefined) {
            const storageLanguage: string | undefined | null = localStorage.getItem('languageCode');
            if (storageLanguage != null && this.isLanguageValid(storageLanguage)) {
                curLangCode = storageLanguage;
                Logger.log(this.loggerLocality, `${methodName} : taking language from local storage (${curLangCode})`);
            }
            else if (this.isUserLoggedIn && this.loginUser != null) {
                // First time login with this account
                curLangCode = this.loginUser.notificationLanguage;
                localStorage.setItem('languageCode', curLangCode);
                Logger.log(this.loggerLocality, `${methodName} : taking language from Notification Language (${curLangCode})`);
            }
        }

        // Try gettign Browser language if no param and URL and storage
        if (curLangCode === undefined) {
            const browserLanguage: string | undefined | null = this.getBrowserLanguageCode();
            if (browserLanguage != null && this.isLanguageValid(browserLanguage)) {
                curLangCode = browserLanguage;
                Logger.log(this.loggerLocality, `${methodName} : taking language from Browser (${curLangCode})`);
            }
        }

        if (curLangCode === undefined) {
            curLangCode = langProps.fallBackLanguage
            Logger.log(this.loggerLocality, `${methodName} : taking language from fall back (${curLangCode})`);
        }

        if (this.languageCode !== curLangCode) {
            this._languageCode = curLangCode;
            localStorage.setItem('languageCode', curLangCode);

            // Clear Language Dependant Storage
            this.storage.clearLanguageDependantCache();

            // Reload Translation Storage
            if (!Session.instance.storage.translation.isObjectInCacheAndValid(curLangCode)) {
                await Session.instance.storage.translation.InitializeTranslationStore(curLangCode);
            }
        }

        // Set HTML Lang Attribute 
        // DIRECT DOM ACCESS
        Logger.log(this.loggerLocality, `${methodName} : set html document language to ${curLangCode}`);
        if (document.documentElement != null) {
            document.documentElement.lang = globalConfig.appProperties.fullLanguageCodeInHTML ? curLangCode : curLangCode.substring(0, 2);
        }

        await this.loadStrings();

        Logger.log(this.loggerLocality, `${methodName} : choosen language code is ${curLangCode}`);
    }

    public isLanguageValid(language: string): boolean {
        const langProps = globalConfig.languageProperties;
        return ((langProps.languages.filter(i => i.Code === language)).length > 0);
    }

    public async loadStrings(): Promise<void> {
        const methodName = `${this.className}:loadStrings()`;
        const curLangCode: string | null = this.languageCode;
        Logger.log(this.loggerLocality, `${methodName} : loading strings, langCode=${curLangCode}`);
        if (curLangCode != null) {
            if (!this.storage.translation.isObjectInCacheAndValid(curLangCode)) {
                Logger.log(this.loggerLocality, `${methodName} : initializing TranslationStore, langCode=${curLangCode}`);
                return await this.storage.translation.InitializeTranslationStore(curLangCode);
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} : strings already in cache and valid, langCode=${curLangCode}`);
            }
        }
    }

    public isJWTTokenValid(): boolean {
        let retVal: boolean = false;

        if (this.jwtToken !== '') {
            const timeDifference = this.SrvClientTimeDif;
            const clientTime = (Date.now() / 1000);

            // tslint:disable-next-line:no-string-literal
            let expTime = this.jwtTokenDecoded != null ? (this.jwtTokenDecoded['exp'] - 180) : 0;     // exp = ExpirationDate in UNIX time - 3 Minutes before expiration
            expTime = expTime + timeDifference;

            if (expTime > clientTime) {
                retVal = true;
            }
        }
        return retVal;
    }

    @action('Session.setJwtToken() Save JWTokens in local or session storage')
    public setJwtToken(jwtToken: string, refreshToken: string, staySignedIn: boolean) {
        // Save the tokens depending on StaySignedIn to local or session storage
        if (staySignedIn) {
            // Save to localStorage
            localStorage.setItem('jwtToken', jwtToken);
            localStorage.setItem('refreshToken', refreshToken);
        } else {
            // Save to sessionStorage
            sessionStorage.setItem('jwtToken', jwtToken);
            sessionStorage.setItem('refreshToken', refreshToken);
        }
    }

    public setSrvClientTimeDif(srvClientTimeDif: number, staySignedIn: boolean) {
        if (staySignedIn) {
            localStorage.setItem('srvClientTimeDif', srvClientTimeDif.toString());
        } else {
            sessionStorage.setItem('srvClientTimeDif', srvClientTimeDif.toString());
        }
    }


    public async prepareUserTokens() {
        const methodName = 'prepareUserTokens()'
        Logger.log(this.loggerLocality, `${methodName} - starting - jwtToken != "" is: ${this.jwtToken !== ''}, loginUser != null is ${this._loginUser !== null}`);
        if ((this.jwtToken !== '') && (this._loginUser == null)) { // IF there is a JWT Token but the Session LoginUser is empty
            if (this.isJWTTokenValid()) { // IF the JWT Token is still valid

                Logger.log(this.loggerLocality, `${methodName} - jwtToken is not empty but user is null, restoring user;`);

                // Restore the user into session   
                await this.loadUserIntoSession();
                if (this.loginUser !== null) {
                    Logger.log(this.loggerLocality, `${methodName} - login user !== null;`);

                    await this.restoreUserData();
                }
                else {
                    Logger.log(this.loggerLocality, `${methodName} - login user is undefined or null;`);
                }

            } else { // If the JWT Token is expired                             
                if (!Session.instance.isRenewingTokens) {
                    Logger.log(this.loggerLocality, `${methodName} - jwtToken is expired and not renewing;`);
                    await this.getNewJWTTokens();// If the JWT Token is not valid anymore, get new token with refresh token from WebAPI
                }
                else {
                    Logger.log(this.loggerLocality, `${methodName} - jwtToken is expired and but renewing;`);
                }
            }
        } else if ((this.jwtToken !== '') && (this._loginUser != null)) { // If there is a JWT Token and a user is in the Session
            if (!this.isJWTTokenValid()) {
                Logger.log(this.loggerLocality, `${methodName} - jwtToken is NOT valid and user is in the session;`);
                if (!Session.instance.isRenewingTokens) {
                    Logger.log(this.loggerLocality, `${methodName} - renewing jwtToken;`);
                    await this.getNewJWTTokens();
                }
                if (this.loginUser !== null) {
                    await this.restoreUserData();
                }
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} - jwtToken is valid and user is in the session;`);
            }
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} - nothing to do;`);
        }
        Logger.log(this.loggerLocality, `${methodName} - finished`);
    }
    /**
     * Save rating form user for item.
     * @param itemId Id og the corresponding item.
     * @param rating Rating 1...10.
     * @param comment Optional comment.
     * @param title Optional Title.
     */
    public async saveRating(itemId: number, rating: number, comment: string, title: string): Promise<boolean> {
        let success = false;
        const methodName = `${this.className}:saveRating()`;
        Logger.log(this.loggerLocality, `${methodName} saving rating ${rating} for item ${itemId}.`);
        // const response = await ItemService.instance.saveRating(itemId, rating);
        const response = await ItemRatingService.instance.saveItemRating({itemId, rating, comment, title});
        if (isSuccess<void>(response)) {
            success = true;
            // Invalidate all caches containing rating.
            this._storage.clearCachesWithRating();
        }
        return success;
    }

    @action
    protected setLoginUser(user: User | null, isGdprEnabled: boolean): void {
        const methodName = `${this.className}:setLoginUser()`;
        Logger.log(this.loggerLocality, `${methodName} : ${JSON.stringify(user)}`);
        this._loginUser = user;
        user == null ?
            this.clearUserDependentStorages() :
            this.loadUserDependentStorages()
        if (user != null) {
            this.setHasOutdatedTermsAndConditions(user.hasOutdatedTermsAndConditions);
            this.setIsGdprEnabled(isGdprEnabled);
        }
    }

    @action
    protected setUserBossRelationCodes(userBossRelations: UserBossRelation[]): void {
        this._loginUserIsBossOfDefaultRelation = userBossRelations.filter(ubr => ubr.isDefault).length > 0;
        this._loginUserBossRelations = userBossRelations;
    }

    @action
    protected setBossAllowedToRegisterNewUsers(isAllowed: boolean): void {
        this._bossAllowedToRegisterNewUsers = isAllowed;
    }

    private getBrowserLanguageCode(): string | undefined {
        const methodName = `${this.className}:getBrowserLanguageCode()`;
        const curLangCode: string | undefined = navigator.language;
        Logger.log(this.loggerLocality, `${methodName} : Browser Language is ${curLangCode}`);
        return curLangCode;
    }

    private getURLLanguageCode(paramName: string): string | undefined {
        const methodName = `${this.className}:getBrowserLanguageCode()`;
        let curLangCode: string | undefined;
        // Get QueryString language if existing       
        const parameters = new URLSearchParams(window.location.search);
        const lang = parameters.get(paramName);
        if (lang !== null && lang !== undefined) {
            curLangCode = lang;
            Logger.log(this.loggerLocality, `${methodName} : URL Language is ${curLangCode}`);
        }
        return curLangCode;
    }

    private async loadUserIntoSession() {
        const methodName = `${this.className}:loadUserIntoSession()`;
        const loginUserId = this.jwtTokenDecoded != null ? this.jwtTokenDecoded['gt-user-id'] : 0;
        if (loginUserId == null || loginUserId < 0) {
            Logger.log(this.loggerLocality, `${methodName} : Logout current user`);
            await this.logout();
        } else {
            const curUser = await this.storage.user.getUser(loginUserId);
            Logger.log(this.loggerLocality, `${methodName} : Login user ${JSON.stringify(curUser)}`);
            this.setLoginUser(curUser, await Session.instance.isGdprEnabled());
        }
    }

    /**
     * Get the new refreshed JWT und refresh Tokens from webAPI
     * And Save them into storage if they aren't empty
     */
    @action
    private async getNewJWTTokens() {
        const methodName = `${this.className}:getNewJWTTokens()`;
        this.isRenewingTokens = true;
        try {
            if (Session.instance.jwtToken !== '' && Session.instance.refreshToken !== '') { // IF the current tokens are not empty
                const obj = new RenewTokensRequest();
                obj.jwtToken = Session.instance.jwtToken;
                obj.refreshToken = Session.instance.refreshToken;
                const auth = await ServiceClient.instance.getNewTokens<Auth>('auth/renewTokens', obj, Auth, undefined);
                if (isSuccess<Auth>(auth)) { // If the returned values from the WebAPI are the tokens
                    if (auth.refreshToken !== '' && auth.jwtSecurityToken !== '') { // if the tokens are not empty
                        this.setJwtToken(auth.jwtSecurityToken, auth.refreshToken, this.isJWTStoredLocal);
                        this._srvTime = Number(auth.srvTime);

                        this.setSrvClientTimeDif(this.calculateSrvClientTimeDifference(), this.isJWTStoredLocal);
                        await this.loadUserIntoSession();
                    } else {
                        await this.logout(); // If one of the tokens is empty
                    }
                } else {
                    await this.logout(); // if return value is not an auth object
                }
            } else {
                await this.logout(); // If current tokens are empty
            }
        } catch {
            console.error(`${this.loggerLocality} ${methodName} Getting JWT Tokens failed`);
            Logger.log(this.loggerLocality, `${methodName} Getting JWT Tokens failed`);
            await this.logout();
        }
        this.isRenewingTokens = false;
    }

    /**
     * Removes a RefreshToken from the DB.
     * @param refreshToken The refresh token from the client to be deleted
     * @param userID  The user mapped to the refresh token
     */
    private async removeRefreshTokenFromDB(refreshToken: string, userID: number) {
        await AuthService.instance.removeRefreshToken(refreshToken, userID);
        Logger.log(this.loggerLocality, 'Remove Refresh Tokens completed');
    }

    private async getUserPreferencesFromDB() {
        return UserPreferencesService.instance.getUserPreferences();
    }

    private clearUserDependentStorages() {
        this.storage.favorites.clear()
        this.storage.watchList.clear()
    }

    private loadUserDependentStorages() {
        this.storage.favorites.loadFavoritesFromServer()
        this.storage.watchList.loadWatchlistFromServer()
    }
    /**
     * Refresh strings if neccessary.
     */
    private async refreshStrings() {
        if (!this._storage.translation.isObjectInCacheAndValid) {
            await this._storage.translation.InitializeTranslationStore(this.getUserLanguageCodeOrFallBack);
        }
    }

    /**
     * Initialize JWToken and login user after successful login.
     * @param authResponse Reporns from auth or sso request
     * @param staySignedIn true if user shall stay signed in
     */
    private async initAfterSuccessfulLogin(authResponse: Auth | SsoAuthenticationStatus, staySignedIn: boolean) {
        const methodName = `${this.className}:initAfterSuccessfulLogin()`;
        // Save JWT security token in specifix storage
        this.setJwtToken(authResponse.jwtSecurityToken, authResponse.refreshToken, staySignedIn);

        this._srvTime = Number(authResponse.srvTime);
        this.setSrvClientTimeDif(this.calculateSrvClientTimeDifference(), staySignedIn);

        // Init this._loginUser with user just authenticated
        const loginUserId = this.jwtTokenDecoded != null ? this.jwtTokenDecoded['gt-user-id'] : 0;
        const curUser = this.storage.user.getUser(loginUserId);
        const userPreferences = this.getUserPreferencesFromDB();
        const curUserBossRelationCodes = UserService.instance.getBossRelationCodes(this.getUserLanguageCodeOrFallBack);
        const bossRelationsAllowedToRegisterNewUsers = ConfigService.instance.getBossRelationsAllowedToRegisterNewUsers();

        const allProm = await Promise.all([curUser, userPreferences, curUserBossRelationCodes, bossRelationsAllowedToRegisterNewUsers]);

        const userPref = allProm[1];
        if (userPref != null) {
            if (isSuccess<UserPreferences>(userPref)) {
                this.setLoginUserPreferences(userPref);
            } else {
                const errorMessage = `${methodName} : failed to get and set user preferences: ${userPref.message}}`
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);
            }
        }
        else {
            const errorMessage = `${methodName} : failed to get and set user preferences.}`
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);
        }

        this.setLoginUser(allProm[0], await this.isGdprEnabled());

        const userBossRelationCodes = allProm[2];
        if (userBossRelationCodes != null) {
            if (isSuccess<UserBossRelation[]>(userBossRelationCodes)) {
                this.setUserBossRelationCodes(userBossRelationCodes);
            } else {
                const errorMessage = `${methodName} : failed to get and set user boss relation codes: ${userBossRelationCodes.message}}`
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);
            }
        }
        else {
            const errorMessage = `${methodName} : failed to get and set user boss relation codes.}`
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);
        }

        const configBossRelationsAllowedToRegisterNewUsers = allProm[3];
        this.setBossAllowedToRegisterNewUsers(false);
        if (isSuccess<StringResponse>(configBossRelationsAllowedToRegisterNewUsers)) {
            const loginUserRelations = this.getLoggedInUserBossrelations();
            const configList = configBossRelationsAllowedToRegisterNewUsers.value.split(',');
            for (let i = 0; i < loginUserRelations.length; i++) {
                if (configList.includes(loginUserRelations[i].bossTypeCode)) {
                    this.setBossAllowedToRegisterNewUsers(true);
                    break;
                }
            }
        }

        ShoppingBasketStorage.instance.onUserChanged();

        // Domain Specific Configs
        console.debug('LoadDomainSpecific Configs');
        await InjectAfterLoginConfiguration();
    }

    private calculateSrvClientTimeDifference(): number {
        const srvTime = Number(this._srvTime);
        const clientTime = (Date.now() / 1000);
        let timeDifferenceBtwSrvAndClient = 0;

        if (srvTime > 0) {
            if (clientTime - srvTime >= 60 || srvTime - clientTime >= 60) {
                timeDifferenceBtwSrvAndClient = clientTime - srvTime;
            }
        }

        return timeDifferenceBtwSrvAndClient;
    }
    
    private async restoreUserData() {
        const methodName = 'restoreUserData()'

        // Restoring domain specific configuration
        await InjectAfterLoginConfiguration();
        
        const userPreferences = this.getUserPreferencesFromDB();
        const curUserBossRelationCodes = UserService.instance.getBossRelationCodes(this.getUserLanguageCodeOrFallBack);
        const setLanguage = this.setLanguageCode();
        const bossRelationsAllowedToRegisterNewUsers = ConfigService.instance.getBossRelationsAllowedToRegisterNewUsers();
        const allProm = await Promise.all([userPreferences, setLanguage, curUserBossRelationCodes, bossRelationsAllowedToRegisterNewUsers]);
        const up = allProm[0];
        if (isSuccess<UserPreferences>(up)) {
            Logger.log(this.loggerLocality, `${methodName} - successfully got user preferences, applying color scheme;`);
            this.setLoginUserPreferences(up);
            this.applyColorScheme(this.getLoginUserPreferencesColorScheme())
        }
        else {
            const errorMessage = `${methodName} : failed to get and set user preferences: ${up.message}}`
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);
        }

        const userBossRelationCodes = allProm[2];
        if (userBossRelationCodes != null) {
            if (isSuccess<UserBossRelation[]>(userBossRelationCodes)) {
                this.setUserBossRelationCodes(userBossRelationCodes);
            } else {
                const errorMessage = `${methodName} : failed to get and set user boss relation codes: ${userBossRelationCodes.message}}`
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);
            }
        }
        else {
            const errorMessage = `${methodName} : failed to get and set user boss relation codes.}`
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);
        }

        const configBossRelationsAllowedToRegisterNewUsers = allProm[3];
        this.setBossAllowedToRegisterNewUsers(false);
        if (isSuccess<StringResponse>(configBossRelationsAllowedToRegisterNewUsers)) {
            const loginUserRelations = this.getLoggedInUserBossrelations();
            const configList = configBossRelationsAllowedToRegisterNewUsers.value.split(',');
            for (let i = 0; i < loginUserRelations.length; i++) {
                if (configList.includes(loginUserRelations[i].bossTypeCode)) {
                    this.setBossAllowedToRegisterNewUsers(true);
                    break;
                }
            }
        }
    }


}