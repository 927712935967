import React from 'react';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import VoucherValidation from '$src/components/shoppingBasket/VoucherValidation';

/** 
 * Component that shows the VoucherValidation component as a standalone page 
 * (for use in navigation) 
 */
export default function VoucherValidationPage() {       
    return (
        <div className="l-container">
            <MenuBreadCrumb />
            
            <Heading headingLevel={1} cssClass="heading__Title">
                <Translate>ShoppingBasketContent:VoucherSubTitle</Translate>
            </Heading>
            <VoucherValidation enableAutomaticRegistrationInVoucherPanel={globalConfig.shoppingProperties.enableAutomaticRegistrationInVoucherPanel} />
        </div>
    )
}