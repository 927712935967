import Logger from '$src/core/Logger';
import UserService from '$src/core/Services/UserService';
import SessionCache from '$src/storage/cache/SessionCache';
import { isSuccess } from '$src/util/Result';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';
import Session from '$src/core/Session';

const CACHING_DURATION = 300; // seconds

/**
 * This class allows to get users from server or store users to server. To improve performance users
 * are stored in a local cache, too. 
 */
export default class ValidBossesStorage extends SessionCache<string, Array<BossSearchResult>> {
    protected className = 'ValidBossesStorage';
    protected loggerLocality = 'Storage.ValidBossesStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, false);
    }

    /**
     * Get User from cache or server.
     * @param userId Id of user to get.
     */
    public async getBosses(searchText: string): Promise<BossSearchResult[] | null> {

        const methodName = `${this.className}:getBosses()`;
        const matchingCache: BossSearchResult[] | null = this.getMatchingCache(searchText);
        const internalSearchText = this.getInternalSearchText(searchText);
        if (matchingCache != null) {
            Logger.log(this.loggerLocality, `${methodName} getting valid bosses from cache, searchtext=${internalSearchText}.`);
            return this.filterInternal(matchingCache, internalSearchText);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} getting valid bosses from server, searchtext=${internalSearchText}.`);
            const response = await UserService.instance.getValidBosses(Session.instance.getUserLanguageCodeOrFallBack, searchText);
            if (isSuccess<BossSearchResult[]>(response)) {
                Logger.log(this.loggerLocality, `${methodName} got valid bosses from server`);
                if (response.length <= globalConfig.bossSelection.maxSearchResults) {
                    // Save with lowercase key
                    this.saveObjectToCache(internalSearchText, response);
                }
                return response;
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get valid bosses from server, searchtext=${internalSearchText}.`);
                return null;
            }
        }
    }

    /**
     * Rmove all users from cache.
     */
    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }

    private getMatchingCacheKey(searchText: string): string | null {
        let matchingKey: string | null = null;
        for (const [key] of this._localCache) {
            if (this.getInternalSearchText(searchText).startsWith(key)) {
                matchingKey = key;
                break;
            }
        }
        return matchingKey;
    }

    private getMatchingCache(searchText: string): BossSearchResult[] | null {
        let matchingCache: BossSearchResult[] | null = null;
        const matchingCacheKey: string | null = this.getMatchingCacheKey(searchText);
        if (matchingCacheKey != null) {
            const matchingCacheObject = this._localCache.get(matchingCacheKey)
            if (matchingCacheObject != null) {
                matchingCache = matchingCacheObject.object;
            }
        }
        return matchingCache;
    }

    private getInternalSearchText(searchText: string): string {
        return searchText?.toLocaleLowerCase();
    }

    private filterInternal(bosses: BossSearchResult[], internalSearchText: string): BossSearchResult[] | null {
        if(bosses == null || bosses.length == 0) return null;
        return bosses.filter(b => 
            b.firstName?.toLocaleLowerCase().includes(internalSearchText) ||
            b.lastName.toLocaleLowerCase().includes(internalSearchText) ||
            b.fullName?.toLocaleLowerCase().includes(internalSearchText) ||
            b.fullNameStartingByLastName?.toLocaleLowerCase().includes(internalSearchText) ||
            b.email?.toLocaleLowerCase().includes(internalSearchText) ||
            b.email2?.toLocaleLowerCase().includes(internalSearchText) ||
            b.address1?.toLocaleLowerCase().includes(internalSearchText) ||
            b.address2?.toLocaleLowerCase().includes(internalSearchText) ||
            b.username?.toLocaleLowerCase().includes(internalSearchText) ||
            b.altLogin?.toLocaleLowerCase().includes(internalSearchText) ||
            b.phoneNumber?.toLocaleLowerCase().includes(internalSearchText) ||
            b.company?.toLocaleLowerCase().includes(internalSearchText) ||
            b.department?.toLocaleLowerCase().includes(internalSearchText) ||
            b.text1?.toLocaleLowerCase().includes(internalSearchText) ||
            b.text2?.toLocaleLowerCase().includes(internalSearchText) ||
            b.text3?.toLocaleLowerCase().includes(internalSearchText) ||
            b.text4?.toLocaleLowerCase().includes(internalSearchText) ||
            b.text5?.toLocaleLowerCase().includes(internalSearchText)
        );
    }
}
