import { InputAttributes, ISetFocus } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import { FieldValidators } from '$src/util/Fieldvalidators';
import { RefObject } from 'react';
import React from 'react';
import { KeyboardEvent } from 'react';

interface IProps {
    attributes: InputAttributes;
    errorMessage?: string;
    onChange?: (id: string, value: string, errMsg: string, setFocus?: ISetFocus) => void;
    onCrEntered?: (id: string) => void;
}

interface IState {
    value: string;
    isValid: boolean;
    errorMsg: string;
}
// tslint:disable:object-literal-sort-keys
export class InputPassword extends React.Component<IProps, IState> {
    protected ref: RefObject<HTMLInputElement>;
    protected className = 'InputPassword';
    protected loggerLocality = 'Components\shared.InputPassword';

    constructor(props: IProps) {
        super(props);
        if(this.props.attributes.setFocus != null && this.props.attributes.setFocus.innerRef != null) {
            this.ref = this.props.attributes.setFocus.innerRef;
        }
        else
        {
            this.ref = React.createRef();
        }

        const sourceValue: string = this.props.attributes.value;
        const initialValidation: boolean = (this.props.attributes.initialValidation === undefined) ? false : this.props.attributes.initialValidation;

        let errorMessage = ''
        if (this.props.attributes.errorMessage != null) { 
            // If property errorMessage has a value, this error message has precedence.
            errorMessage = this.props.attributes.errorMessage 
        } else {
            // Validate input value (required field must not be empty AND check value against regexp if defined).
            errorMessage = this.validateField(initialValidation, sourceValue);
        }
        const controlValid = errorMessage.length === 0 ? true : false;

        // Initialize state based on validation check.
        this.state = {
            value: sourceValue,
            isValid: controlValid,
            errorMsg: errorMessage,
        }

        // If the control is invalid, the event will be triggered to inform parent component.
        if (!controlValid) {
            if (this.props.onChange) {
                this.props.onChange(this.props.attributes.id, sourceValue, errorMessage);
            }
        }
    }

    public componentDidMount(): void {
        if (this.props.attributes.editMode && this.props.attributes.hasFocus) {
            this.setFocus();
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {
        const lblClass: string = 'input-label';
        let txtClass: string = 'input-field';
        txtClass = txtClass + this.errorClass(this.state.errorMsg)

        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'
        const placeholder: string = (this.props.attributes.placeholder === undefined || this.props.attributes.placeholder === null) ? '' : this.props.attributes.placeholder;

        // Build final component element        
        const inputElement: JSX.Element[] = [];

        inputElement.push(
            <label
                id={'InputPassword_Label_' + this.props.attributes.id} 
                key={'InputPassword_Label_' + this.props.attributes.id}
                htmlFor={'InputPassword_Input_' + this.props.attributes.id} 
                className={lblClass}>
                <Translate>{this.props.attributes.label}</Translate>
                {(this.props.attributes.isRequired && this.props.attributes.editMode) ? ' *' : ''}
            </label>
        )

        inputElement.push(
            <input
                id={'InputPassword_Input_' + this.props.attributes.id}    
                key={'InputPassword_Input_' + this.props.attributes.id}
                name={'InputPassword_Input_' + this.props.attributes.id}
                className={txtClass}
                type="password"
                aria-labelledby={'InputPassword_Label_' + this.props.attributes.id}
                readOnly={this.props.attributes.isReadOnly}
                required={this.props.attributes.isRequired}
                value={this.state.value}
                placeholder={placeholder}
                onChange={(e) => this.onChange(e)} onKeyUp={this.onKeyUp} ref={this.ref}
                autoComplete={this.props.attributes.autoComplete} />
        )

        let errorMessage = ''
        if (this.props.attributes.errorMessage != null) { 
            // If property errorMessage has a value, this error message has precedence.
            errorMessage = this.props.attributes.errorMessage 
        } else {
            // Validate input value (required field must not be empty AND check value against regexp if defined).
            errorMessage = this.state.errorMsg;
        }

        inputElement.push(
            <span key={'InputPassword_ErrorMsg_' + this.props.attributes.id} className={'input-message error'}>
                <Translate>{errorMessage}</Translate>
            </span>
        )

        return (
            <div key={'InputPassword_Div_' + this.props.attributes.id} className={boxClass}>
                {inputElement}
            </div >
        )
    }

    /**
     * Retrieve error class.
     */
    protected errorClass(errorMsg: string) {
        return (errorMsg.length === 0 ? '' : ' has-error');
    }

    /**
     * Validate input value.
     */
    protected validateField(validateValue: boolean, value: string): string {
        let message: string = '';
        
        if (validateValue) {
            if (message.length === 0) {
                message = FieldValidators.Required(value, this.props.attributes.isRequired);
            }

            if (message.length === 0) {
                message = FieldValidators.RegExpression(value, this.props.attributes.regExpression)
            }
        }
        return message;
    }

    /**
     * Set input focus to input field.
     */
    protected setFocus() {
        if (this.ref.current !== null) {
            this.ref.current.focus();
        }
    }

    /**
     * Callback for text input: text has changed.
     */
    protected onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.currentTarget as HTMLInputElement;
        const targetValue: string = target.value;

        // Validate input value
        const errorMessage = this.validateField(true, targetValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        this.setState({
            value: targetValue,
            isValid: controlValid,
            errorMsg: errorMessage,
        })

        // Callback for control: Text has changed.
        if (this.props.onChange) {
            this.props.onChange(this.props.attributes.id, targetValue, errorMessage, this.props.attributes.setFocus);
        }
    }

    /**
     * Callback called each time a key is released (key up event).
     */
    protected onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13) {
            if (this.props.onCrEntered) {
                this.props.onCrEntered(this.props.attributes.id);
                event.preventDefault();
            }
        }
    }
}