
import { F2FClassDetail } from '$src/storage/models/F2F/F2FClassDetail';
import { F2FDocumentList } from '$src/storage/models/F2F/F2FDocumentList';
import { LocationDocumentList } from '$src/storage/models/F2F/LocationDocumentList';
import GtError from '$util/GtError';
import ServiceClient from '../ServiceClient';
import { ClassFileDataRequest } from '$src/storage/models/RequestObjects/ClassFileDataRequest';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ClassDocumentUploadRequest } from '$src/storage/models/RequestObjects/ClassDocumentUploadRequest';
import { ClassDocumentDeleteRequest } from '$src/storage/models/RequestObjects/ClassDocumentDeleteRequest';
import { SetF2FLessonStatuscompletedAndHasAttendedRequest } from '$src/storage/models/RequestObjects/SetF2FLessonStatuscompletedAndHasAttendedRequest';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { ItemHelper } from '$src/util/ItemHelper';

/**
 * F2FService provides all Service Methods for the F2F Objects 
 */
export default class F2FService extends ServiceClient {
    protected static _instance: F2FService | null = null;

    protected className = 'F2FService';
    protected loggerLocality = 'F2FService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): F2FService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get F2F Class details of a selected item
     *
     * @param {number} itemId
     * @param {string} language
     * @returns {(Promise<ClassDetails | GtError>)}
     * @memberof ServiceClient
     */
    public async getF2FClassList(itemId: number, language: string, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<F2FClassDetail[] | GtError> {
        let response: F2FClassDetail[] | GtError;
        response = await this.get<F2FClassDetail[]>('lesson/f2fClassList',
            F2FClassDetail,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'tpId', value: parentTrainingPlanId.toString() },
            ],
            itemId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getF2FClassDetails(classId: number, language: string, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<F2FClassDetail | GtError> {
        let response: F2FClassDetail | GtError;
        response = await this.get<F2FClassDetail>('lesson/f2fClassDetails',
            F2FClassDetail,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'tpId', value: parentTrainingPlanId.toString() },
            ],
            classId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getF2FDocuments(courseId: number, courseItemId: number, classId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<F2FDocumentList | GtError> {
        let response: F2FDocumentList | GtError;
        response = await this.get<F2FDocumentList>('lesson/f2fDocuments',
            F2FDocumentList,
            undefined,
            [
                { name: 'tpId', value: parentTrainingPlanId.toString() },
            ],
            courseId.toString(),
            courseItemId.toString(),
            classId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getF2FStandardDocuments(): Promise<string[] | GtError> {
        return await this.get<string[]>('lesson/f2fStandardDocuments', String);
    }

    public async getLocationDocuments(courseItemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0, classId: number | null, roomId: number | null, venueId: number | null): Promise<LocationDocumentList | GtError> {
        let response: LocationDocumentList | GtError;
        const venueIdString = venueId ? venueId.toString() : 'null';
        const classIdString = classId ? classId.toString() : 'null';
        const roomIdString = roomId ? roomId.toString() : 'null';
        response = await this.get<LocationDocumentList>('lesson/locationDocuments',
            LocationDocumentList,
            undefined,
            [
                { name: 'tpId', value: parentTrainingPlanId.toString() },
            ],
            classIdString,
            roomIdString,
            venueIdString,
            courseItemId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    /**
     * Uploads f2f class documents
     * @param classId 
     * @param documents 
     */
    public async uploadF2FDocuments(classId: number, documents: ClassFileDataRequest[]): Promise<BooleanResponse | GtError> {
        const request = new ClassDocumentUploadRequest();
        request.classId = classId;
        request.documents = documents;

        const formData = new FormData();
        let key: keyof ClassDocumentUploadRequest;
        for (key in request) {
            if (key == "documents") {
                const aF = request[key];
                aF.forEach(f => {
                    formData.append("[fileName]:" + f.fileName, f.file as File);
                    formData.append("[fileType]:" + f.fileName, f.fileType.toString());

                    formData.append("[documentName]:" + f.fileName, f.name);
                    formData.append("[documentType]:" + f.fileName, f.documentType.toString());
                    formData.append("[linkedDocument]:" + f.fileName, f.linkedDocument);
                })
            }
            else {
                formData.append(key, request[key].toString());
            }
        }

        const response = await this.postFormData<BooleanResponse>('lesson/uploadF2FDocuments', formData, BooleanResponse);
        return response;
    }

    /**
     * Delete f2f class document
     * @param documentId 
     */
    public async deleteF2FDocument(documentId: number): Promise<BooleanResponse | GtError> {
        const request = new ClassDocumentDeleteRequest();
        request.documentId = documentId;
        const response = await this.post<BooleanResponse>('lesson/f2fDocuments/delete', request, BooleanResponse, undefined);
        return response;
    }

    /**
     * Set f2f lesson status (self confirmation of attendance)
     * @param registrationId 
     */
    public async setLessonStatusCompletedAndHasAttended(registrationId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<BooleanResponse | GtError> {
        const request = new SetF2FLessonStatuscompletedAndHasAttendedRequest();
        request.registrationId = registrationId;
        request.itemSecurityContext = ItemHelper.convertCallerContextToSecurityContext(itemContext);
        request.parentTrainingPlanId = parentTrainingPlanId;
        const response = await this.post<BooleanResponse>('lesson/f2fstatus/completed', request, BooleanResponse, undefined);
        return response;
    }

    /**
     * Set f2f virtual lesson status (auto confirmation of attendance)
     * @param registrationId 
     */
    public async setLessonStatusCompletedAndHasAttendedForVirtualMeeting(registrationId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<BooleanResponse | GtError> {
        const request = new SetF2FLessonStatuscompletedAndHasAttendedRequest();
        request.registrationId = registrationId;
        request.registrationId = registrationId;
        request.itemSecurityContext = ItemHelper.convertCallerContextToSecurityContext(itemContext);
        request.parentTrainingPlanId = parentTrainingPlanId;
        const response = await this.post<BooleanResponse>('lesson/f2fstatus/virtualmeeting/completed', request, BooleanResponse, undefined);
        return response;
    }
} 