
import { UserSkillComment } from '$src/storage/models/Skill/UserSkillComment';
import React from 'react';
import { EUserSkillCommentType } from '$src/storage/models/enums';
import Session from '$src/core/Session';

interface IProps {
    selectedEvaluation: UserSkillComment[];
    selectedVersion: string;
}

/**
 * This component renders the selected user skill comments (evaluation) for the related user skill 
 * in fields for each type of EUserSkillCommentType for the print version.
 */
export class EvaluationsToPrint extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

    }

    public render() {
        const version = Session.instance.storage.translation.GetString("PerformanceReview:Version").Format(this.props.selectedVersion)
        return (
            <div>
                <div className="header__logo" />
                <div className="heading__Level2">
                    {version}
                </div>
                {this.renderEvaluationTable()}
            </div>
        )
    }

    private renderEvaluationTable(): JSX.Element {
        return (
            <React.Fragment>
                {this.renderEvaluationText(EUserSkillCommentType.MySkill_User)}
                {this.renderEvaluationText(EUserSkillCommentType.MySkill_Boss)}
                {this.renderEvaluationText(EUserSkillCommentType.MyPotential_User)}
                {this.renderEvaluationText(EUserSkillCommentType.MyPotential_Boss)}
                {this.renderEvaluationText(EUserSkillCommentType.MyTargets)}
            </React.Fragment>
        )
    }

    private renderEvaluationText(userSkillCommentType: EUserSkillCommentType): JSX.Element | undefined {
        let result: JSX.Element | undefined;
        const userSkillComment = this.props.selectedEvaluation.find(usk => usk.commentType === userSkillCommentType);
        if (userSkillComment) {
            let title = '';
            switch (userSkillCommentType) {
                case EUserSkillCommentType.MySkill_User:
                case EUserSkillCommentType.MySkill_Boss:
                    title = Session.instance.storage.translation.GetString('PerformanceReview:MySkill');
                    break;
                case EUserSkillCommentType.MyPotential_User:
                case EUserSkillCommentType.MyPotential_Boss:
                    title = Session.instance.storage.translation.GetString('PerformanceReview:MyPotential');
                    break;
                case EUserSkillCommentType.MyTargets:
                    title = Session.instance.storage.translation.GetString('PerformanceReview:MyTargets');
                    break;
            }
            result = (
                <React.Fragment>
                    <div className="heading__Level3">
                        {title} - {userSkillComment.commentedUserFirstName + ' ' + userSkillComment.commentedUserLastName}
                    </div>
                    <div className="l-box--shadow performanceReview-evaluation__box">
                        <p>{userSkillComment.comment}</p>
                    </div>
                </React.Fragment>
            )
        }
        return result;
    }


}