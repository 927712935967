import { JsonObject, JsonProperty } from 'json2typescript';

/**
 *This Model is holding the data for the groups
 */
@JsonObject
export class EvaluationOfTplan {

    /** itmId  */
    @JsonProperty('itmId', Number, false)
    public itmId: number = 0;

    /** title*/
    @JsonProperty('title', String, false)
    public title: string = "";

    //** schedule id with title/scecode*/
    @JsonProperty('scheduleDict', Object, false)
    public scheduleDict:  { [key: number]: string } = {};
}
