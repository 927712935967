import Logger from '$src/core/Logger';
import RegistrationService from '$src/core/Services/RegistrationService';
import Session from '$src/core/Session';
import ContainerSessionCache from '$src/storage/cache/ContainerSessionCache';
import { ELessonStatus } from '$src/storage/models/enums';
import { RegisteredItem } from '$src/storage/models/RegisteredItem';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { action } from 'mobx';

const CACHING_DURATION = 300;

export default class RegisteredItemStorage extends ContainerSessionCache<number, RegisteredItem> {
    protected className = 'RegisteredItemStorage';
    protected loggerLocality = 'Storage.RegisteredItemStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    /**
     * Rmove all registered items from cache.
     */
    @action public clear(): void {
        super.clear();
        this.isInitialized = false;
    }

    public sort(items: RegisteredItem[]): void {
        items.sort((a1: RegisteredItem, a2: RegisteredItem): number => {
            const rh1Date = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ?
                                a1.learningPeriodEndDate : a1.learningPeriodStartDate;
            const rh2Date = globalConfig.lessonToolbarProperties.caclulateDueDateBasedOnLastDate ?
                                a2.learningPeriodEndDate : a2.learningPeriodStartDate;

            const rh1 = rh1Date !== undefined && 
                        a1.lessonStatus !== ELessonStatus.Completed &&
                        a1.lessonStatus !== ELessonStatus.Passed ?
                        rh1Date : 0;
            const rh2 = rh2Date !== undefined && 
                        a2.lessonStatus !== ELessonStatus.Completed &&
                        a2.lessonStatus !== ELessonStatus.Passed ?
                        rh2Date : 0;
            if (rh1 > rh2) { return -1; }
            if (rh1 < rh2) { return 1 }
            return 0;
        })
    }


    public static async  GetAllRegistrations() {
        const response = await RegistrationService.instance.getRegisteredItems(Session.instance.getUserLanguageCodeOrFallBack, true);
        if (isSuccess<RegisteredItem[]>(response)) {
            return response;
        }
        return [];
    }


    @action
    protected async loadData(): Promise<RegisteredItem[] | GtError> {
        return this.loadDataInternal(Session.instance.getUserLanguageCodeOrFallBack);
    }

    /**
     * Get Registered Items of current user from cache or server.
     * @param language Language
     */
    @action
    private async loadDataInternal(language: string): Promise<RegisteredItem[] | GtError> {
        const methodName = `${this.className}:loadDataInternal()`;
        if (language === null || language === undefined) {
            language = Session.instance.getUserLanguageCodeOrFallBack;
        }
        this.clear();
        Logger.log(this.loggerLocality, `${methodName} getting registered items from server, language=${language}.`);
        const response = await RegistrationService.instance.getRegisteredItems(language, false);
        if (isSuccess<RegisteredItem[]>(response)) {
            const elementCount = (Array.isArray(response)) ? response.length : 0;
            Logger.log(this.loggerLocality, `${methodName} got registered items from server, language=${language}, number of elements=${elementCount}.`);
            const distinctItems: RegisteredItem[] = this.getDistinctRegisteredItems(response);
            if (distinctItems && distinctItems.length > 0) {
                for (const item of distinctItems) {
                    this.saveObjectToCache(item.itemId, item);
                }
            }
            return this.getObjectsFromCache();
        } 
        else {
            Logger.log(this.loggerLocality, `${methodName} failed to get registered items from server, language=${language}.`);
            return response;
        }
    }

    /**
     * Build a distinct list of RegisteredItems from parameter registeredItems. If the input list contains more than one
     * item with the same itemId, take the one with the newest registration status.
     */
    private getDistinctRegisteredItems(registeredItems: RegisteredItem[]): RegisteredItem[] {
        const distinctItems: RegisteredItem[] = [];
        if (registeredItems && registeredItems.length > 0) {
            const distinctMap = new Map<number, RegisteredItem>();
            for (const item of registeredItems) {
                const itemFromMap = distinctMap.get(item.itemId);
                if (itemFromMap !== undefined) {
                    // Item already exists in map, check if it should be replaced 
                    if (itemFromMap.registrationDate && item.registrationDate
                        && itemFromMap.registrationDate.getTime() < item.registrationDate.getTime()) {
                        // Replace item in map because the current one has a newer registration
                        distinctMap.delete(itemFromMap.itemId);
                        distinctMap.set(item.itemId, item);
                    }
                } else {
                    // Item isn't in map => add it
                    distinctMap.set(item.itemId, item);
                }
            }
            // Create array from map.
            distinctMap.forEach((value: RegisteredItem) => {
                distinctItems.push(value);
            });
        }
        return distinctItems;
    }
}