import React from 'react';

import Session from '$src/core/Session';
import { KeyPressHelper } from '$src/util/KeyPressHelper';

interface IProps {
    id: string;
    title: string;    
    openDialog: () => void;
    content?: JSX.Element;
    className?: string;
    ariaPopupvalue: boolean | 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
}
 
export class OpenDialogLink extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);

        this.OnClick = this.OnClick.bind(this)
    }
    

    public render() {
        const title = Session.instance.storage.translation.GetString(this.props.title) 
        const cls = this.props.className != null ? this.props.className : 'button-link button-link--colorized'
                return (
                            <button
                                className={cls}
                                id = {this.props.id}
                                aria-haspopup={this.props.ariaPopupvalue}
                                onClick={this.OnClick }
                                onKeyUp={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => this.props.openDialog()) }}>
                                {this.props.content === undefined ? <span>{title}</span>: this.props.content}
                            </button>
                );                
    } 

    public OnClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault()
        this.props.openDialog()
    }
}
