import { Assignments } from '$components/assignment/Assignments';
import { CatalogFolderList } from '$components/CatalogFolderList/CatalogFolderList';
import { HomeCollapsablePanel } from '$components/home/HomeCollapsablePanel';
import { HomeTabs } from '$components/home/HomeTabs';
import { HomePanelOrganizer } from '$components/HomePanelOrganizer';
import NewsPanel from '$components/newsPanel/NewsPanel';
import PerformanceSupportPanel from '$components/performanceSupport/PerformanceSupportPanel';
import { Heading } from '$components/shared/Heading';
import { HighlightTitle } from '$components/shared/HighlightTitle';
import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import UserPreferencesService from '$core/Services/UserPreferencesService';
import Session from '$core/Session';
import Settings from '$resources/svgs/navi/settings.svg';
import NewsService from '$src/core/Services/NewsService';
import { AssignedItem } from '$src/storage/models/AssignedItem';
import { CatalogElement } from '$src/storage/models/Catalog';
import { News } from '$src/storage/models/News';
import { isSuccess } from '$src/util/Result';
import { Preferences } from '$storage/models/UserPreferences/Preferences';
import { UserConfigPanel } from '$storage/models/UserPreferences/UserConfigPanel';
import { observer } from 'mobx-react';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';
import { CatalogFolderContentList } from '$components/catalogFolderContentList/CatalogFolderContentList';
import ExternalNavigationPage from '$components/externalPage/ExternalNavigationPage';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import SystemRoles from '$src/core/SystemRoles';
import { PerformanceSupportSkills } from '$src/storage/PerformanceSupportStorage';
import MyRecommendationsByInterests from '$src/components/myRecommendations/MyRecommendationsByInterests';
import SearchHomePanel from './search/elk/SearchHomePanel';
import Snowfall from '$components/shared/Snowfall';
import Firework from '$components/shared/Firework';
import VoucherValidation from '$src/components/shoppingBasket/VoucherValidation';
import UserSubjectSubscriptionsPanel from '$src/components/userSubjectSubscriptions/UserSubjectSubscriptionsPanel';
import CatalogService from '$src/core/Services/CatalogService';
import { GamificationUserHighscoreRankingList } from './gamification/GamificationUserHighscoreRankingList';
import { GamificationGroupHighscoreRankingList } from './gamification/GamificationGroupHighscoreRankingList';

interface IState {
    isOrganizerOpen: boolean;
    panelsToggled: number;
    configPanels: UserConfigPanel[];
    configPanelsLoaded: boolean;
    requiredAssignments: AssignedItem[] | null;
    recommendedAssignments: AssignedItem[] | null;
    news: News[] | undefined;
    performanceSupportSkills: PerformanceSupportSkills | null;
    showOtherModal: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
@observer
export class Home extends React.Component<RouteComponentProps<{}>, IState> {
    protected className = 'Home';
    protected loggerLocality = 'Components.Home';
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            configPanels: [],
            isOrganizerOpen: false,
            panelsToggled: 0,
            configPanelsLoaded: false,
            requiredAssignments: [],
            recommendedAssignments: [],
            news: undefined,
            performanceSupportSkills: null,
            showOtherModal: false
        };
    }

    public async componentDidMount() {
        if (Session.instance.loginUserPreferences != null) {
            // Initialize login user home panels if empty
            const homePanelsPreference = Session.instance.loginUserPreferences.preferences.homePanels;
            if (homePanelsPreference == null) {
                // Get Default Configuration and save it
                Session.instance.loginUserPreferences.preferences.homePanels = Preferences.getDefaultHomePanels();
            }
            // Adapt Prefrences in case the home panels configuration changed
            else {
                const defaultHomePanels = Preferences.getDefaultHomePanels()
                const homePanels = defaultHomePanels.map(homePanel => {
                    return homePanelsPreference.find(homePanelPreference => homePanelPreference.id === homePanel.id) || homePanel;
                });
                Session.instance.loginUserPreferences.preferences.homePanels = homePanels;
            }
            UserPreferencesService.instance.saveUserPreferences(Session.instance.loginUserPreferences);
        }
        const configPanels = Preferences.getUserConfigPanels();
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const filterRequiredAssignments: (a: AssignedItem) => boolean = Session.instance.storage.assignment.filterRequiredAssignments;
        const requiredAssignments: AssignedItem[] | null = await this.GetAssignedItems(filterRequiredAssignments);

        // eslint-disable-next-line @typescript-eslint/unbound-method
        const filterRecommendedAssignments: (a: AssignedItem) => boolean = Session.instance.storage.assignment.filterRecommendedAssignments
        const recommendedAssignments: AssignedItem[] | null = await this.GetAssignedItems(filterRecommendedAssignments);

        const tempNews = await NewsService.instance.getNewsByUser(Session.instance.getUserLanguageCodeOrFallBack);
        let news: News[] | undefined = undefined;
        if (isSuccess<News[]>(tempNews)) {
            news = tempNews;

        }
        //Check if performance support panel is shown and if so get the expiringInDaysFromNow value and pass it to storage.performanceSupport.getPerformanceSupportSkills
        const performanceSupportPanel = configPanels.find(panel => panel.id == 'PerformanceSupportPanel')
        const expiringInDaysFromNow = performanceSupportPanel?.performanceSupportPanelConfiguration?.expiringInDaysFromNow;
        const performanceSupportSkills = await Session.instance.storage.performanceSupport.getPerformanceSupportSkills(true, expiringInDaysFromNow);

        let catalogFolderSIDs = new Array<string>();
        for (let i = 0; i < configPanels.length; i++) {
            const element = configPanels[i];
            switch (element.component) {
                case "CatalogFolderList":
                    catalogFolderSIDs = new Array<string>();
                    if (element.catalogFolderListConfigurationWithAllowedGroups != null) {
                        element.catalogFolderListConfigurationWithAllowedGroups.forEach(cFlAllowedGroup => {
                            //allowedGroups kann null sein -> alle dürfen das CatalogFolderListItem sehen
                            if (cFlAllowedGroup.allowedGroups == null || Session.instance.isCurrentUserMemberOfAtLeastOneOfGroups(cFlAllowedGroup.allowedGroups)) {
                                catalogFolderSIDs.push(cFlAllowedGroup.catalogFolderSId);
                            }
                        });
                    }
                    catalogFolderSIDs = catalogFolderSIDs.filter((x, i, a) => a.indexOf(x) === i);
                    element.catalogFolderSIDs = catalogFolderSIDs;
                    element.catalogElement = await this.getCatalogFolderBySIds(catalogFolderSIDs);
                    break;
                case "CatalogFolderContentList":
                    if (element.catalogFolderContentListConfiguration != null) {
                        // Check if we get data to know if the panel needs to be rendered
                        if (element.catalogFolderContentListConfiguration != null) {
                            const contents = await CatalogService.instance.getCatalogFolderContentsBySIds(element.catalogFolderContentListConfiguration.catalogFolderSIds);
                            if (isSuccess<CatalogElement | null>(contents)) {
                                if (contents != null && contents.childCount == 0) {
                                    element.catalogElement = null;
                                }
                                else {
                                    element.catalogElement = contents;
                                }
                            }
                            else {
                                element.catalogElement = null;
                            }
                        }
                    }
                    else {
                        element.catalogElement = null;
                    }
                    break;
            }
        }
        this.setState({ configPanels, requiredAssignments, recommendedAssignments, news: news, performanceSupportSkills }, () => this.setState({ configPanelsLoaded: true }));
    }

    private async getCatalogFolderBySIds(catalogFolderSIDs: string[]): Promise<CatalogElement | null> {
        const response: CatalogElement | null = await Session.instance.storage.catalog.getCatalogFolderBySIds(catalogFolderSIDs);
        if (isSuccess<CatalogElement | null>(response)) {
            if (response !== null && response.children !== null) {
                return response;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    public render() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('Navigation:Home');

        if (!this.state.configPanelsLoaded) {
            return <ProgressSpinner />
        }

        const homeBackGroundImageCSS = globalConfig.homeProperties.displayBackgroundImage ? 'home__background-image' : '';
        const homeBackGroundImageFixedCSS = globalConfig.homeProperties.displayBackgroundImage && globalConfig.homeProperties.fixBackgroundImage ? 'home__background-image--fixed' : '';

        const panels = this.renderPanels(0, globalConfig.homeProperties.displayBackgroundImage ? globalConfig.homeProperties.endBackgroundImageAfterPanels : 0, true);
        const collPanels = this.renderPanels(globalConfig.homeProperties.displayBackgroundImage ? globalConfig.homeProperties.endBackgroundImageAfterPanels : 0, 50, false);
        const res = <div>
            {this.state.isOrganizerOpen ? (<HomePanelOrganizer onChange={() => this.panelPreferencesChanged()} onClose={() => this.closeOrganizer()} />) : null}
            {globalConfig.homeProperties.endBackgroundImageAfterPanels > 0 ?
                <React.Fragment>
                    <div key="home_background" className={`home__panel-container ${homeBackGroundImageCSS} ${homeBackGroundImageFixedCSS}`}>
                        {globalConfig.homeProperties.showSnowfall && <Snowfall />}
                        {globalConfig.homeProperties.showFireworks && <Firework />}
                        {this.renderHomeEditButton()}
                        {panels}
                    </div>
                </React.Fragment> : this.renderHomeEditButton()}
            {collPanels}

        </div>;

        return res;
    }

    protected closeOrganizer() {
        this.setState({ isOrganizerOpen: !this.state.isOrganizerOpen })
    }

    protected renderHomeEditButton(): JSX.Element | null {
        if (globalConfig.homeProperties.allowUserPanelOrganization && !Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)) {
            return <div className="home__edit-page">
                <button onClick={() => this.openOrganizer()} className="home__edit-page__button">
                    <Translate>Home:EditPage</Translate>
                    <InlineSVG src={Settings} />
                </button>
            </div>
        }
        return null;
    }

    /*
        Setup would be better in componentdidmount
    */
    protected renderPanels(startIndex: number, count: number, noBackground: boolean) {
        const panels = new Array<JSX.Element>();
        this.state.configPanels.map((panel, index) => {
            const component: JSX.Element | null = this.getComponent(panel, index);

            if (component != null) {
                // Display all configured panels within the given range
                if (panel.id != null && panel.isVisible && (index >= startIndex && index < startIndex + count)) {
                    if (panel.isFixed) {
                        const cssClass = globalConfig.homeProperties.strippedLayout ?
                            !noBackground ? !this.state.isOrganizerOpen ? 'l-element--striped-reverse' : 'l-element--striped' : '' : '';
                        panels.push(
                            <div key={'homePanel-Element_' + index} id={panel.id} className={cssClass}>
                                <div className="l-container">
                                    <div className="home__panel">
                                        {panel.isTitleVisible ?
                                            <Heading headingLevel={1} cssClass={panel.headingStyle == null || panel.headingStyle === 1 ?
                                                'heading__Title' :
                                                'heading__Title heading--secondary'}>
                                                <Translate>{panel.translationString}</Translate>
                                            </Heading> : null}
                                        {component}
                                    </div>
                                </div>
                            </div>);
                    } else {
                        panels.push(<HomeCollapsablePanel key={'homeCollapsablePanel-Element_' + index} panelId={panel.id} panelExpanded={panel.expanded} panelIsFixed={panel.isFixed} panelIsTitleVisible={panel.isTitleVisible}
                            panelTranslationString={panel.translationString} component={component} isOrganizerOpen={this.state.isOrganizerOpen}
                            togglePanel={() => this.togglePanel(panel.id)} startIndex={startIndex} noBackground={noBackground} />);
                    }
                }
            }
        });

        return panels;
    }

    protected panelPreferencesChanged() {
        // We need the order returend by getUserConfigPanels but need to keep catalogElement value
        const configPanels = [...Preferences.getUserConfigPanels()];
        const stateConfigPanels = [...this.state.configPanels];
        configPanels.forEach((cp, index) => {
            const mappedConfigPanel = stateConfigPanels.filter(scp => scp.id === cp.id);
            if (mappedConfigPanel.length === 1) {
                configPanels[index].catalogElement = mappedConfigPanel[0].catalogElement;
            }
        });
        this.setState({ configPanels: configPanels });
    }

    protected async togglePanel(panelId: string) {

        const preferences = Session.instance.loginUserPreferences;

        if (preferences != null) {
            if (preferences.preferences != null && preferences.preferences.homePanels != null) {
                const homePanel = preferences.preferences.homePanels.find(pnl => pnl.id === panelId)
                if (homePanel != null) {
                    homePanel.expanded = !homePanel.expanded;
                    await UserPreferencesService.instance.saveUserPreferences(preferences);
                }
            }
        }
    }

    protected getComponent(panel: UserConfigPanel, key: number) {
        const methodName = `${this.className}:getComponent()`;
        let component: JSX.Element | null = null;
        const compId = panel.component;

        if (this.isAllowed(panel)) {
            switch (compId) {
                case 'NewsPanel':
                    if (this.state.news != null && this.state.news.length > 0) {
                        component = <NewsPanel key={`${panel.id}_${compId}_${key}`}
                            news={this.state.news}
                            parentHeadingLevel={0}
                            {...this.props} />;
                    }
                    else {
                        return null;
                    }
                    break;
                case 'CatalogFolderList':
                    if (panel.catalogFolderListConfigurationWithAllowedGroups != null) {
                        if (panel.catalogElement != null && panel.catalogElement.childCount > 0) {
                            component = <CatalogFolderList key={`${panel.id}_${compId}_${key}`}
                                catalogElement={panel.catalogElement}
                                parentHeadingLevel={1}
                                catelogFoldersIdsWithGroupNames={panel.catalogFolderListConfigurationWithAllowedGroups}
                                {...this.props} />
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in homepanel ${compId}, configuration catalogFolderListConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'NextTrainingsPanel':
                    if (panel.nextTrainingConfiguration != null) {
                        component = <HomeTabs key={`${panel.id}_${compId}_${key}`}
                            parentHeadingLevel={1}
                            maxAssignments={panel.nextTrainingConfiguration.maxAssignments}
                            maxRecommendedItems={panel.nextTrainingConfiguration.maxRecommendedItems}
                            maxRegisteredItems={panel.nextTrainingConfiguration.maxRegisteredItems}
                            displayCountOfRegisteredItems={panel.nextTrainingConfiguration.displayCountOfRegisteredItems}
                            {...this.props} />;
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in NextTrainingsPanel ${compId}, configuration nextTrainingConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'RequiredAssignments':
                    if (panel.assignmentConfiguration != null) {
                        if (this.state.requiredAssignments != null && this.state.requiredAssignments.length > 0) {
                            component = <Assignments key={`${panel.id}_${compId}_${key}`}
                                AssignmentItems={this.state.requiredAssignments}
                                parentHeadingLevel={1}
                                maxItemsToDisplay={panel.assignmentConfiguration.maxAssignments}
                                filterExpression={(assignedItem) => Session.instance.storage.assignment.filterRequiredAssignments(assignedItem)}
                                expandItemsText={'Assignments:ExpandAllRequired'}
                                expandItemsAriaText={'Assignments:ExpandAllRequiredAria'}
                                expandInline={true}
                                showSortOrderButton={globalConfig.homeProperties.showSortButtonForRequiredAssignments}
                                {...this.props} />;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in RequiredAssignments ${compId}, configuration assignmentConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'RecommendedAssignments':
                    if (panel.assignmentConfiguration != null) {
                        if (this.state.recommendedAssignments != null && this.state.recommendedAssignments.length > 0) {
                            component = <Assignments
                                AssignmentItems={this.state.recommendedAssignments}
                                parentHeadingLevel={1}
                                maxItemsToDisplay={panel.assignmentConfiguration.maxAssignments}
                                filterExpression={(assignedItem) => Session.instance.storage.assignment.filterRecommendedAssignments(assignedItem)}
                                key={`${panel.id}_${compId}_${key}`}
                                expandItemsText={'Assignments:ExpandAllRecommended'}
                                expandItemsAriaText={'Assignments:ExpandAllRecommendedAria'}
                                expandInline={true}
                                {...this.props} />;
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in RecommendedAssignments ${compId}, configuration assignmentConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'WelcomePanel':
                    if (panel.welcomePanelConfiguration != null) {
                        component = <HighlightTitle key={`${panel.id}_${compId}_${key}`} {...this.props} titleString={panel.welcomePanelConfiguration.title} />;
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in WelcomePanel ${compId}, configuration welcomePanelConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'MyRecommendations':
                    component = <MyRecommendationsByInterests
                        key={`${panel.id}_${compId}_${key}`}
                        appereance={'HomePanel'}
                        {...this.props} />;
                    break;
                case 'MySubjectSubscriptions':
                    component = <UserSubjectSubscriptionsPanel
                        key={`${panel.id}_${compId}_${key}`}
                        {...this.props} />;
                    break;
                case 'CatalogFolderContentList':
                    if (panel.catalogFolderContentListConfiguration != null) {
                        if (panel.catalogElement != null) {
                            component = <CatalogFolderContentList key={`${panel.id}_${compId}_${key}`}
                                catalogElement={panel.catalogElement}
                                catalogFolderSIds={panel.catalogFolderContentListConfiguration.catalogFolderSIds}
                                parentHeadingLevel={1}
                                itemStyle={panel.catalogFolderContentListConfiguration.itemStyle}
                                itemLinkStyle={panel.catalogFolderContentListConfiguration.itemLinkStyle}
                                showItemBackground={panel.catalogFolderContentListConfiguration.showItemBackground}
                                showNavigationButton={panel.catalogFolderContentListConfiguration.showNavigationButton}
                                navigationButtonText={panel.catalogFolderContentListConfiguration.navigationButtonText}
                                navigationButtonStyle={panel.catalogFolderContentListConfiguration.navigationButtonStyle}
                                maxItems={panel.catalogFolderContentListConfiguration.maxItems}
                                {...this.props} />
                        }
                        else {
                            return null;
                        }
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in CatalogFolderContentList ${compId}, configuration catalogFolderContentListConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'PerformanceSupportPanel':
                    if (panel.performanceSupportPanelConfiguration != null) {
                        if (this.state.performanceSupportSkills != null
                            && (this.state.performanceSupportSkills.expiredTargetSkills.length > 0
                                || this.state.performanceSupportSkills.missingLevelTargetSkills.length > 0
                                || this.state.performanceSupportSkills.soonExpiringTargetSkills.length > 0)) {
                            component = <PerformanceSupportPanel key={`${panel.id}_${compId}_${key}`}
                                maxItems={panel.performanceSupportPanelConfiguration.maxItems}
                                expiringInDaysFromNow={panel.performanceSupportPanelConfiguration.expiringInDaysFromNow}
                                parentHeadingLevel={1}
                                performanceSupportSkills={this.state.performanceSupportSkills}
                                {...this.props} />
                        }
                    }
                    else {
                        const errorMessage = `${methodName} configuration error in homepanel ${compId}, configuration performanceSupportPanelConfiguration not found. `
                        Logger.log(this.loggerLocality, errorMessage);
                        console.error(errorMessage);
                    }
                    break;
                case 'ExternalPagePanel': {
                    if (panel.externalPageConfiguration != null) {
                        const config = panel.externalPageConfiguration;
                        component = <ExternalNavigationPage
                            pageConfiguration={config}
                            {...this.props}
                        />
                    }
                    break;
                }
                case 'SearchPanel': {
                    component = <SearchHomePanel />
                    break;
                }
                case 'VoucherValidation': {
                    component = <VoucherValidation enableAutomaticRegistrationInVoucherPanel={globalConfig.shoppingProperties.enableAutomaticRegistrationInVoucherPanel} />
                    break;
                }
                case 'UserHighscore': {
                    component = <GamificationUserHighscoreRankingList />
                    break;
                }
                case 'GroupHighscore': {
                    component = <GamificationGroupHighscoreRankingList />
                    break;
                }
                default: {
                    const errorMessage = `${methodName} unknown homepanel ${compId}`
                    Logger.log(this.loggerLocality, errorMessage);
                    console.error(errorMessage);
                    return null;
                }
            }
        }

        return component;
    }

    private async GetAssignedItems(filterExpression: (a: AssignedItem) => boolean): Promise<AssignedItem[] | null> {
        let assignments: AssignedItem[] | null = [];
        const assignmentStorage = Session.instance.storage.assignment;
        assignments = await assignmentStorage.getObjects();
        if (assignments != null) {
            assignments = assignments.filter(filterExpression)
        }

        return assignments;
    }

    private isAllowed(panel: UserConfigPanel): boolean {
        let isAllowed = false;

        if (!panel.allowedGroups) {
            isAllowed = true;
        }
        else if (Session.instance.isCurrentUserMemberOfAtLeastOneOfGroups(panel.allowedGroups)) {
            isAllowed = true;
        }

        return isAllowed;
    }

    protected openOrganizer() {
        this.setState({ isOrganizerOpen: true });
    }
}

export default Home;