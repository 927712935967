import { JsonObject, JsonProperty } from 'json2typescript';

// import { DateConverter } from '$storage/models/converters/dateConverter';

@JsonObject
export class Mentor {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    @JsonProperty('email', String, false)
    public email: string = '';


}