import Session from '$src/core/Session';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { toast } from 'react-toastify';

export enum GtErrorType {
    ClientError = 1,
    ServerError = 2,
    UnknownError = 3,
}

/**
 * GT specific Error class to be used for functions returning 'data or error'.
 *
 * @export
 * @class GtError
 * @extends {Error}
 */
export default class GtError extends Error {
    public detailedObject: CustomErrorMessage | undefined = undefined;
    public detailedObjects: CustomErrorMessage[] | undefined = undefined;
    public statusCode: number | undefined;
    private errorType: GtErrorType;
    private url: string;
    private verb: string;

    /**
     * Create new GtError object.
     * @param errorType type of the error, one of GtErrorType
     * @param errorCode error code (short text, one word, may be used as string id for multi-lingual error messages)
     * @param errorText error text
     */
    constructor(errorType?: GtErrorType, errorCode?: string, errorText?: string) {
        super(errorText || '');
        // restore prototype chain - see https://stackoverflow.com/questions/41102060/typescript-extending-error-class
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        } else {
            throw Error('Object.setPrototypeOf not supported?');
            // TODO: find solution fpr followin line not compiling
            // this.__proto__ = actualProto;
        }

        this.errorType = errorType || GtErrorType.UnknownError;
        this.name = errorCode || '';
        this.url = '';
        this.verb = '';
    }




    /**
     * Initialize GtError object with error returned from RequestPromise. Used by {ServiceClient}.
     * Sample usage:
     *   RequestPromise.xxx().catch((err) => { response = new GtError().initFormHttpResponseError(err); });     *
     *
     * @param {*} err error returned from RequestPromise.xxx().catch()
     * @returns {GtError} Enable function chaining
     * @memberof GtError
     */
    public initFromHttpResponseError(err: any, showErrorAsToast: boolean = true): GtError {
        if (err.name === 'RequestError') {
            this.errorType = GtErrorType.ClientError;
            this.name = ''; // TODO t.b.d.
            this.message = err.message;
            this.url = err.options.uri;
            this.verb = err.options.method;
            if (this.IsJsonString(err.error)) {
                this.detailedObject = JSON.parse(err.error);
                if (Array.isArray(this.detailedObject)) {
                    this.detailedObjects = JSON.parse(err.error);
                }
            }
        } else if (err.name === 'StatusCodeError') {
            this.errorType = GtErrorType.ServerError;
            this.name = err.response.statusMessage;
            this.message = err.response.body;
            this.url = err.options.uri;
            this.verb = err.options.method;
            this.statusCode = err.statusCode;
            if (this.IsJsonString(err.error)) {
                this.detailedObject = JSON.parse(err.error);
                if (Array.isArray(this.detailedObject)) {
                    this.detailedObjects = JSON.parse(err.error);
                }
            }
        } else {
            this.errorType = GtErrorType.UnknownError;
            this.name = '';
            this.message = '';

        }

        // Toast error message, only triggers if an http error 403 occures
        if (showErrorAsToast) {
            let message = this.message;
            const translator = Session.instance.storage.translation;
            switch (this.statusCode) {
                case 403: 
                    message = translator.GetString('ErrorMessage:HttpAccessDenied');
                    break;
                default:
                    return this;
            }
            toast.error(message,
                {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
        }
        return this;
    }
    /**
     * Write error message to {Session.setLastErrorMessage}.
     *
     * @returns {GtError} Enable function chaining
     * @memberof GtError
     */
    public writeToSession(): GtError {
        let msg: string = '';
        switch (this.errorType) {
            case GtErrorType.ClientError:
                msg = `${GtErrorType[this.errorType]}: ${this.name}: ${this.verb} ${this.url}: ${this.message}`;
                break;
            case GtErrorType.ServerError:
                // tslint:disable-next-line:max-line-length
                msg = `HTTP error ${this.statusCode} / error type: ${GtErrorType[this.errorType]}: ${this.name}: ${this.verb} ${this.url} / message: ${this.message} / SubCode: ${this.detailedObject !== undefined ? this.detailedObject.subStatusCode : ''}`;
                break;
            case GtErrorType.UnknownError:
                msg = 'unknown error';
                break;
            default:
        }
        Session.instance.setLastErrorMessage(msg);
        return this;
    }

    /**
     * Return error as string.
     *
     * @returns {string}
     * @memberof GtError
     */
    public toString(): string {
        return `error type: ${this.errorType.toString()}, error name: ${this.name}, error message: ${this.message}`;
    }


    private IsJsonString(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
