import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Request Object send to Server
 * Used to send all information needed to delete a voucher
 */
@JsonObject
export class PaymentVoucherDeleteRequest {

    @JsonProperty('voucherCode', Number, false)
    public voucherCode: number = 0;
}


