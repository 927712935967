import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseProvider {
    @JsonProperty('providerId', Number, false)
    public providerId: number = 0;

    @JsonProperty('sid', String, false)
    public sid: string = '';

    @JsonProperty('name', String, false)
    public name: string = '';

    @JsonProperty('url', String, false)
    public url: string = '';
}