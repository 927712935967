import { ESortDirection } from "$src/storage/models/enums";
import DateHelper from "$src/util/DateHelper";

/**
 * Sorting Algorithm
 *
 * @export
 * @class SortingAlgorithm
 */
export default class SortingAlgorithm {

    /**
     * Logic for sorting. To use with Array.sort((element1: any, element2: any) => SortingAlgorithm.sortByColumnAndDirection(element1, element2, column, direction))
     * Distinguishes Date, Number and string, default string
     * @param element1 first element to compare
     * @param element2 second element to compare
     * @param column name of the column the list should be sorted by
     * @param direction Enum of sorting direction
     */
    public static sortByColumnAndDirection(element1: any, element2: any, column: string, direction: ESortDirection) {
        if (element1[column] instanceof Date || element2[column] instanceof Date) {
            return this.sortByDateColumnAndDirection(element1, element2, column, direction);
        } else if (typeof (element1[column]) === 'number' || typeof (element2[column]) === 'number') {
            return this.sortByNumberColumnAndDirection(element1, element2, column, direction);
        } else if (typeof (element1[column]) === 'boolean' || typeof (element2[column]) === 'boolean') {
            return this.sortByBooleanColumnAndDirection(element1, element2, column, direction);
        } else {
            return this.sortByStringColumnAndDirection(element1, element2, column, direction);
        }
    }

    /**
     * Logic for sorting strings. To use with Array.sort((element1: any, element2: any) => SortingAlgorithm.sortByStringColumnAndDirection(element1, element2, column, direction))
     * @param element1 first element to compare
     * @param element2 second element to compare
     * @param column name of the column the list should be sorted by
     * @param direction Enum of sorting direction
     */
    public static sortByStringColumnAndDirection(element1: any, element2: any, column: string, direction: ESortDirection) {
        const element1Column: string = element1[column] != null ? element1[column].toLocaleLowerCase() : '';
        const element2Column: string = element2[column] != null ? element2[column].toLocaleLowerCase() : '';
        if (direction === ESortDirection.Up) {
            return element1Column.localeCompare(element2Column);
        } else {
            return element2Column.localeCompare(element1Column);
        }
    }

    /**
     * Logic for sorting dates. To use with Array.sort((element1: any, element2: any) => SortingAlgorithm.sortByStringColumnAndDirection(element1, element2, column, direction))
     * @param element1 first element to compare
     * @param element2 second element to compare
     * @param column name of the column the list should be sorted by
     * @param direction Enum of sorting direction
     */
    public static sortByDateColumnAndDirection(element1: any, element2: any, column: string, direction: ESortDirection) {
        const element1Column = element1[column] != null ? element1[column] : new Date(Number.MIN_VALUE);
        const element2Column = element2[column] != null ? element2[column] : new Date(Number.MIN_VALUE);
        if (direction === ESortDirection.Up) {
            return DateHelper.CompareDate(element1Column, element2Column);
        } else {
            return DateHelper.CompareDate(element2Column, element1Column);
        }
    }

    /**
     * Logic for sorting dates. To use with Array.sort((element1: any, element2: any) => SortingAlgorithm.sortByStringColumnAndDirection(element1, element2, column, direction))
     * @param element1 first element to compare
     * @param element2 second element to compare
     * @param column name of the column the list should be sorted by
     * @param direction Enum of sorting direction
     */
    public static sortByNumberColumnAndDirection(element1: any, element2: any, column: string, direction: ESortDirection) {
        const element1Column = element1[column] != null ? element1[column] : Number.NEGATIVE_INFINITY;
        const element2Column = element2[column] != null ? element2[column] : Number.NEGATIVE_INFINITY;
        if (direction === ESortDirection.Up) {
            return element1Column - element2Column;
        } else {
            return element2Column - element1Column;
        }
    }

    /**
     * Logic for sorting boolean. To use with Array.sort((element1: any, element2: any) => SortingAlgorithm.sortByStringColumnAndDirection(element1, element2, column, direction))
     * @param element1 first element to compare
     * @param element2 second element to compare
     * @param column name of the column the list should be sorted by
     * @param direction Enum of sorting direction
     */
     public static sortByBooleanColumnAndDirection(element1: any, element2: any, column: string, direction: ESortDirection) {
        const element1Column: boolean = element1[column] != null ? element1[column] : false;
        const element2Column: boolean = element2[column] != null ? element2[column] : false;
        if (direction === ESortDirection.Up) {
            return element1Column ? -1 : element1Column === element2Column ? 0 : 1;
        } else {
            return element2Column ? -1 : element1Column === element2Column ? 0 : 1;
        }
    }
}