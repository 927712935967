import { Item } from '$src/storage/models/Item';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '$storage/models/converters/DateTimeConverter';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import { EItemType, ELessonStatus, ERegistrationStatus } from '$storage/models/enums';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanBlockCompletitionRequirement } from '$storage/models/TrainingPlan/TrainingPlanBlockCompletitionRequirement'
import { TrainingPlanBlockCompletitionRequirementStatus } from '$storage/models/TrainingPlan/TrainingPlanBlockCompletitionRequirementStatus'
import Logger from '$src/core/Logger';

@JsonObject
export class TrainingPlanElement extends Item {




    public get itemType(): EItemType {
        return this.type;
    }
    public set itemType(type: EItemType) {
        this.type = type;
    }


    /** Use this property to check if a TPElement is Locked or not */
    public get isLocked(): boolean {
        return (!globalConfig.trainingPlanProperties.allowOpeningOfLockedItemsDueToItemSkillNotReached && this.isLockedDueToRequiredSkills) || this.isTPElementLocked;
    }

    @JsonProperty('tpeId', Number, false)
    public tpeId: number = 0;

    @JsonProperty('language', String, false)
    public language: string = ''

    @JsonProperty('tpElementSid', String, false)
    public tPElementSid: string = ''

    @JsonProperty('elementGUID', String, false)
    public elementGUID: string = ''

    @JsonProperty('lockedReason', String, false)
    public lockedReason: string = ''

    @JsonProperty('completitionRequirements', [TrainingPlanBlockCompletitionRequirement], true)
    public completitionRequirements: TrainingPlanBlockCompletitionRequirement[] | undefined = undefined;

    @JsonProperty('completitionRequirementsStatus', [TrainingPlanBlockCompletitionRequirementStatus], true)
    public completitionRequirementsStatus: TrainingPlanBlockCompletitionRequirementStatus[] | undefined = undefined;
    
    @JsonProperty('lockedReasonDetail', String, false)
    public lockedReasonDetail: string = ''

    // These properties are only needed if we implement a module detail component
    /*@JsonProperty('prerequisites', [TrainingPlanPrerequisite], true)
    public prerequisites: TrainingPlanPrerequisite[] | undefined = undefined;

     @JsonProperty('userInputSkills', [TrainingPlanInputSkill], true)
    public userInputSkills: TrainingPlanInputSkill[] | undefined = undefined;

    @JsonProperty('crMinSumOfScoreToComplete', Number, true)
    public crMinSumOfScoreToComplete: number = 0;

    @JsonProperty('crMinNumberOfRulesToComplete', Number, true)
    public crMinNumberOfRulesToComplete: number = 0;
    
    @JsonProperty('prMinNumberOfRules', Number, false)
    public prMinNumberOfRules: number = 0;

    @JsonProperty('prMinSumOfScore', Number, false)
    public prMinSumOfScore: number = 0;

    @JsonProperty('masteryScore', Number, false)
    public masteryScore: number = 0;

    @JsonProperty('creditState', String, false)
    public creditState: string = '';*/

    @JsonProperty('parentItemId', String, false)
    public parentItemId: string = '';

    @JsonProperty('tPlanId', Number, false)
    public tPlanId: number = 0;

    @JsonProperty('treeLevel', Number, false)
    public treeLevel: number = 0;

    @JsonProperty('childOrder', Number, false)
    public childOrder: number = 0;

    @JsonProperty('typeId', Number, false)
    public typeId: number = 0;

    /** Type of the item. */
    @JsonProperty('type', ItemTypeConverter, false)
    public type: EItemType = EItemType.Undefined;

    @JsonProperty('startDate', DateTimeConverter, false)
    public startDate: Date | undefined = undefined;

    @JsonProperty('isStartDateUsed', Boolean, false)
    public isStartDateUsed: boolean = false;

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('description', String, false)
    public description: string = '';


    @JsonProperty('childElements', Number, false)
    public childElements: number = 0;

    @JsonProperty('estimateDuration', Number, false)
    public estimateDuration: number = 0;

    protected className = 'TrainingPlanElement';
    protected logger = 'Models.TrainingPlanElement';

    // Private since islocked should be used.
    @JsonProperty('isTPElementLocked', Boolean, false)
    private isTPElementLocked: boolean = false;

    public allowSelection(trainingPlan: TrainingPlan): boolean {

        Logger.log(this.logger, `Clicking Allowed: TP Element Locked: ${this.isLocked}, 
        TP Locked: ${trainingPlan.isLocked}, TP in Learning Phase: ${trainingPlan.isInLearningPhase}, 
        TP Element Type: ${this.type.toString()}, TP Lesson Status: ${trainingPlan.lessonStatus.toString()}`);

        const isNotLocked = !this.isLocked &&
            (!trainingPlan.isLocked ||     // If the tplan is locked due to missing skills, but the user is already registered , then don't lock elements
                (trainingPlan.registrationStatus === ERegistrationStatus.Accepted ||
                    trainingPlan.registrationStatus === ERegistrationStatus.Requested ||
                    trainingPlan.registrationStatus === ERegistrationStatus.InWaitingList));

        const statusAllowesSelectionOutsideLearningPhase = (trainingPlan.lessonStatus === ELessonStatus.Completed) || (trainingPlan.lessonStatus === ELessonStatus.Passed);

        const _allowSelection = (
            isNotLocked &&
            trainingPlan.registrationStatus === ERegistrationStatus.Accepted &&
            (
                trainingPlan.isInLearningPhase ||
                this.type === EItemType.Block ||
                statusAllowesSelectionOutsideLearningPhase
            ));
        Logger.log(this.logger, `AllowSelection for trainingPlan Element ${trainingPlan.sId} ${trainingPlan.title} 
                                                - ${this.sId} ${this.title} is  ${_allowSelection}`);
        return _allowSelection;
    }
}
