import { JsonObject, JsonProperty } from 'json2typescript';
import { UtcDateTimeConverter } from './converters/UtcDateTimeConverter';

@JsonObject
export class Deputy {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('userId', Number, false)
    public userid: number = 0;

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    @JsonProperty('groupId', Number, false)
    public groupId: number = 0;

    @JsonProperty('validFrom', UtcDateTimeConverter, false)
    public validFrom: Date | undefined = undefined;

    @JsonProperty('validTo', UtcDateTimeConverter, false)
    public validTo: Date | undefined = undefined;

    @JsonProperty('isNotificationOn', Boolean, false)
    public isNotificationOn: boolean = false;
}