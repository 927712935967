import React from 'react';

import { Translate } from '$src/components/shared/Translate';

export interface IAlertProps {
    /**
     * **Optional** A single message to show. Will be translated with Translate component
     */
    message?: string;
    /**
     * Alert type
     */
    alertType: 'warning' | 'error' | 'info' | 'success' | 'mixed';
    /**
     * Alert appereance
     */
    alertAppereance: 'input-message' | 'box' | 'single-line';
    /**
     * **Optional** Additional classname
     */
    className?: string;
    /**
     * **Optional** List of messages and alert types for multiple messages
     */
    messages?: Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }>;
}

/**
 * Alert component for info, error, warning or success messages
 */
export class Alert extends React.Component<IAlertProps, {}> {

    constructor(props: IAlertProps) {
        super(props);
    }

    public render() {
        const cssClasses = this.getCssClasses(this.props.alertType, this.props.alertAppereance, this.props.className);
        const message = <Translate>{this.props.message}</Translate>;
        const messages = this.getMessages();
        switch (this.props.alertAppereance) {
            case 'input-message':
                return <p className={cssClasses}>
                    {message}
                    {this.props.children}
                </p>
            case 'box':
                return <div className={cssClasses}>
                    <div>
                        {message}
                        {messages}
                        {this.props.children}
                    </div>
                </div>
            case 'single-line':
                return <span className={cssClasses}>
                    {message}
                    {messages}
                    {this.props.children}
                </span>
        }
    }

    private getCssClasses(alertType: 'warning' | 'error' | 'info' | 'success' | 'mixed',
        alertAppereance: 'input-message' | 'box' | 'single-line',
        additionalClassName?: string): string {
        return `${alertType.toString()} ${alertType.toString()}__` +
            `${alertAppereance.toString()} ${alertAppereance === 'input-message' ? alertAppereance : ''} ` +
            `${additionalClassName != null ? additionalClassName : ''}`
    }

    private getMessages(): JSX.Element | JSX.Element[] {
        if (this.props.messages != null) {
            return <ul>
                {this.props.messages.map((m, index) => <li
                    key={`message__${index}`}
                    className={m.alertType.toString()}>
                    <Translate>{m.message}</Translate>
                </li>)
                }
            </ul>
        }
        return <React.Fragment />;
    }
}