import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { useParams } from 'react-router';
import RegistrationService from '$src/core/Services/RegistrationService';
import { F2FParticipantListTutorToPrint } from './F2FParticipantListTutorToPrint';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import ReactToPrint from 'react-to-print';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import SystemRoles from '$src/core/SystemRoles';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import { ParticipantListForTutor } from '$src/storage/models/ParticipantListForTutor';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { InputCheckbox } from '$src/components/shared/InputCheckbox';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { User } from '$src/storage/models/User';
import GTButton from '$src/components/shared/Atoms/GTButton';


/**
 * Renders the settings and print details for the participant list for tutors and admins
 * Can be reached through NSUI (f2f class details -> print participant list button) or AUI (f2f class auto documents -> print participant list button)
 * Only tutors and admins get access to the information
 * Feature can be disabled for tutors (globalConfig:f2fProperties -> showF2FParticipantListTutor and GTService:ParticipantListTutor:Enabled)
 */
export default function F2FParticipantListTutor() {
    const parameters = new URLSearchParams(window.location.search);
    // if is called form NSUI or AUI
    const callerContext = parameters.get('callercontext') || '';
    const { classIdParam } = useParams<{ classIdParam: string }>();
    const classId: number = classIdParam != null && !isNaN(Number(classIdParam)) ? Number(classIdParam) : 0;
    const tr = Session.instance.storage.translation;

    const [participantList, setParticipantList] = useState<ParticipantListForTutor>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showSignatureColumnLocal, setShowSignatureColumnLocal] = useState(true);
    const [allowedUserFields, setAllowedUserFields] = useState<string[]>([]);
    const [allowedNotEmptyUserFields, setAllowedNotEmptyUserFields] = useState<string[]>([]);
    const [documentTitle, setDocumentTitle] = useState<string>();

    useEffect(() => {
        document.title = globalConfig.appProperties.title + ' - ' + tr.GetString('ParticipantListTutor:Title');
    });

    function filterAllowedUserFieldsIfEmpty(allowedFields: string[], participants: User[]) {
        if (allowedFields.length > 0 && participants.length > 0) {
            const notEmptyFields: string[] = [];
            const emptyUser = new User();
            allowedFields.forEach(allowedField => {
                if (participants.some(participant => participant[allowedField] !== emptyUser[allowedField])) {
                    notEmptyFields.push(allowedField);
                }
            });
            setAllowedNotEmptyUserFields(notEmptyFields);
        }
    }

    function camelize(string: string) {
        return string[0].toLowerCase() + string.slice(1);
    }

    useEffect(() => {
        async function fetchData() {
            const response = await RegistrationService.instance.getParticipantListForTutor(classId);
            if (isSuccess<ParticipantListForTutor>(response)) {
                const camelizedAllowedUserFields: string[] = []
                response.allowedUserFields.forEach(allowedField => camelizedAllowedUserFields.push(camelize(allowedField)));
                filterAllowedUserFieldsIfEmpty(camelizedAllowedUserFields, response.users);
                setAllowedUserFields(camelizedAllowedUserFields);
                response.allowedUserFields = camelizedAllowedUserFields;
                setDocumentTitle(tr.GetString('ParticipantListTutor:DocumentNamePrint').Format(response.courseTitle).replace(/[/\\?%*:|"<>]/g, ''));
                setParticipantList(response);
            } else {
                setErrorMessage(response.message || tr.GetString('ErrorMessage:General'));
            }
            setIsLoading(false);
        }

        if ((globalConfig.f2fProperties.showF2FParticipantListTutor && Session.instance.hasCurrentUserRole(SystemRoles.instance.Tutor))
            || Session.instance.hasCurrentUserRole(SystemRoles.instance.F2FAdmin) || Session.instance.hasCurrentUserRole(SystemRoles.instance.DomainAdmin)) {
            fetchData();
        } else {
            setErrorMessage(tr.GetString('ErrorMessage:Forbidden'));
            setIsLoading(false);
        }

    }, [classId]);

    function printButton(): JSX.Element {
        if (!errorMessage && !isLoading) {
            return (
                <button id="participant-list-tutor-print-button" className="btn--md btn--primary" >
                    <Translate>ParticipantListTutor:PrintButton</Translate>
                </button>
            );
        } else {
            return (<></>);
        }
    }

    // logout and close window if opened by AUI, otherwise just close window
    async function closeWindow() {
        if (callerContext?.toLocaleLowerCase() === 'aui') {
            await Session.instance.logout();
        }
        window.close();
    }

    function onChangeShowSignatureColumn() {
        setShowSignatureColumnLocal(!showSignatureColumnLocal);
    }

    function onChangeShowEmptyColumns(value: boolean) {
        if (value && participantList) {
            participantList.allowedUserFields = allowedUserFields;
            setParticipantList({ ...participantList });

        } else if (participantList && !value) {
            participantList.allowedUserFields = allowedNotEmptyUserFields;
            setParticipantList({ ...participantList });
        }
    }

    const componentRef: MutableRefObject<any> = useRef();
    const pageSytle = "@page { size: A4 landscape; } @media print { body { -webkit-print-color-adjust: exact; } }";
    const cbInputAttributesSignatureColumn: InputAttributes = {
        id: 'F2FParticipantListTutor_Checkbox_Signature_Column',
        label: tr.GetString('ParticipantListTutor:ShowSignatureColumn'),
        value: 'TRUE',
        isReadOnly: false,
        isRequired: false,
        hasFocus: false,
        editMode: true
    }
    const cbInputAttributesEmptyColumns: InputAttributes = {
        id: 'F2FParticipantListTutor_Checkbox_Empty_Colums',
        label: tr.GetString('ParticipantListTutor:ShowEmptyColumns'),
        value: 'TRUE',
        isReadOnly: false,
        isRequired: false,
        hasFocus: false,
        editMode: true
    }

    return (
        <div className="l-container participant-list-tutor__container">
            <div>
                <h1 className="heading__Title">
                    <Translate>ParticipantListTutor:Title</Translate>
                </h1>
            </div>
            {isLoading ?
                <ProgressSpinner />
                : errorMessage ?
                    <ErrorMessage errorMessage={errorMessage} appearance="box" /> :
                    participantList && participantList.users.length === 0 ?
                        <ErrorMessage errorMessage={tr.GetString('ParticipantListTutor:NoParticipantsFound')} appearance="box" />
                        : <>
                            <div className="participant-list-tutor__checkbox-settings">
                                {globalConfig.participantListTutor.showSignatureColumn &&
                                    <InputCheckbox attributes={cbInputAttributesSignatureColumn} onChange={() => onChangeShowSignatureColumn()} />}
                                <InputCheckbox attributes={cbInputAttributesEmptyColumns} onChange={(id: string, value: boolean) => onChangeShowEmptyColumns(value)} />
                            </div>
                            <GTButton onClick={() => closeWindow()} additionalClassNames="participant-list-tutor__exit-button">
                                <Translate>ParticipantListTutor:ExitButton</Translate>
                            </GTButton>

                            <ReactToPrint
                                trigger={() => printButton()}
                                content={() => componentRef.current}
                                documentTitle={documentTitle}
                                pageStyle={pageSytle} />
                            <div>
                                <div ref={componentRef}>
                                    <F2FParticipantListTutorToPrint participantList={participantList} showSignatureColumnLocal={showSignatureColumnLocal} />
                                </div>
                            </div>
                        </>
            }
        </div>
    );


}
