import { JsonObject, JsonProperty } from 'json2typescript';
import { AssignedItem } from '$src/storage/models/AssignedItem';

/**
 * This class holds information for the overivew for the requirements
 */
@JsonObject
export class RequirementsOverviewData {

    /** Id of the assignment. */
    @JsonProperty('userFirstAndLastName', String, false)
    public userFirstAndLastName: string = "";

    /** Id of the assignment. */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** Id of the user or group the assignment is associated to. */
    @JsonProperty('requiredTrainings', [AssignedItem], false)
    public requiredTrainings: AssignedItem[] = [];
}
