import {
    GridCellProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { KendoGridCellHelper } from './KendoGridCellHelper';

interface IProps extends GridCellProps {
    filterExpression: <T>(a: T) => boolean;
    arrayDataField?: string;
}

export const KendoArrayGridCell: React.FC<IProps> = (props: IProps) => {
    let valueList: JSX.Element[] = [];
    const value = KendoGridCellHelper.getValue(props);
    if (value instanceof Array) {
        valueList = value.filter(props.filterExpression).map((v, i) => {
            return <li key={`${props.field}_${i}`}>
                {props.arrayDataField != null ?
                    v[props.arrayDataField] :
                    v}
            </li>
        });
    }
    return <td>
        <ul> {valueList} </ul>
    </td>
}