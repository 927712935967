import { JsonObject, JsonProperty } from 'json2typescript';
import { Boss } from './Boss';

@JsonObject
export class BossSearchResult {
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('guid', String, false)
    public guid: string = '';

    @JsonProperty('domainName', String, false)
    public domainName: string = '';

    @JsonProperty('domainId', Number, false)
    public domainId: number = 0;

    @JsonProperty('username', String, false)
    public username: string = '';

    @JsonProperty('altLogin', String, false)
    public altLogin: string = '';

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    @JsonProperty('email', String, false)
    public email: string = ''; 

    @JsonProperty('email2', String, false)
    public email2: string = '';     

    @JsonProperty('company', String, false)
    public company: string = ''; 

    @JsonProperty('department', String, false)
    public department: string = '';     

    @JsonProperty('address1', String, false)
    public address1: string = '';     

    @JsonProperty('address2', String, false)
    public address2: string = '';     

    @JsonProperty('city', String, false)
    public city: string = '';     

    @JsonProperty('zipCode', String, false)
    public zipCode: string = '';    

    @JsonProperty('phoneNumber', String, false)
    public phoneNumber: string = '';  

    @JsonProperty('mobileNumber', String, false)
    public mobileNumber: string = '';   

    @JsonProperty('info', String, false)
    public info: string = '';   
    
    @JsonProperty('text1', String, false)
    public text1: string = '';      

    @JsonProperty('text2', String, false)
    public text2: string = '';   

    @JsonProperty('text3', String, false)
    public text3: string = '';   

    @JsonProperty('text4', String, false)
    public text4: string = '';   

    @JsonProperty('text5', String, false)
    public text5: string = '';     
    
    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }

    public get fullNameStartingByLastName(): string {
        return `${this.lastName} ${this.firstName}`
    }

    public get fullNameAndEmail(): string {
        return `${this.firstName} ${this.lastName} (${this.email})`;
    }

    public static Convert(boss: Boss): BossSearchResult | undefined {
        if(boss === undefined) return undefined;

        const bsr = new BossSearchResult();
        bsr.id = boss.id;
        bsr.guid = boss.guid;
        bsr.domainName = boss.domainName;
        bsr.domainId = boss.domainId;
        bsr.username = boss.username;
        bsr.altLogin = boss.altLogin;
        bsr.firstName = boss.firstName;
        bsr.lastName = boss.lastName;
        bsr.email = boss.email;
        bsr.email2 = boss.email2;
        bsr.company = boss.company;
        bsr.department = boss.department;
        bsr.address1 = boss.address1;
        bsr.address2 = boss.address2;
        bsr.city = boss.city;
        bsr.zipCode = boss.zipCode;
        bsr.phoneNumber = boss.phoneNumber;
        bsr.mobileNumber = boss.mobileNumber;
        bsr.info = boss.info;
        bsr.text1 = boss.text1;
        bsr.text2 = boss.text2;
        bsr.text3 = boss.text3;
        bsr.text4 = boss.text4;
        bsr.text5 = boss.text5;
        return bsr;
    }
}