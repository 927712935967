import React from 'react';

import { Heading } from '$components/shared/Heading';
import { Collapsible } from './Collapsible';

interface IProps extends WithChildren {
    headingLevel: number;
    headingCssClass: string;
    containerCssClass: string;
    isOpened?: boolean;
    onToggleOpenedState?: (expanded: boolean) => void;
    disallowTogglingOpenState?: boolean;
}

export const HeadingCollapsible: React.FC<IProps> = (props: IProps) => {

    return (
        <Collapsible {...props}>
            <Heading cssClass={props.headingCssClass} headingLevel={props.headingLevel}>
                {props.children}
            </Heading>
        </Collapsible>
    );
}
