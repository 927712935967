export const name = 'name';
export const firstName = 'firstName';
export const mail = 'mail';
export const allocationDate = 'allocationDate';
export const expirationDate = 'expirationDate';
export const skillLevel = 'skillLevel'


export class EvaluationOfSkillsModel {
    public columnDictionary: { [key: number]: string } = {};

    constructor() {
        this.columnDictionary[0] = name;
        this.columnDictionary[1] = firstName;
        this.columnDictionary[2] = mail;
        this.columnDictionary[3] = allocationDate;
        this.columnDictionary[4] = expirationDate;
        this.columnDictionary[5] = skillLevel;
    }
}