import { StringHelper } from '$src/util/StringHelper';
import React from 'react';

interface IProps {
    summary: string;
    className?: string;
}

export class SummaryAttribute extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <div className={this.props.className}>
                {globalConfig.itemProperties.enableHTMLFormatterForSummary ?
                    (<span dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(this.props.summary) }} />) :
                    (<span dangerouslySetInnerHTML={{ __html: this.props.summary }} />)
                }
            </div>
        )
    }
}