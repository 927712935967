import { CheckBox } from '$src/components/shared/CheckBox';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import { FieldValidators } from '$src/util/Fieldvalidators';
import React from 'react';
import { KeyboardEvent } from 'react';

interface IProps {
    attributes: InputAttributes;
    onChange?: (id: string, value: boolean, errMsg: string) => void;
    onCrEntered?: (id: string) => void;
}

interface IState {
    value: string;
    isValid: boolean;
    errorMsg: string;
}
// tslint:disable:object-literal-sort-keys
/**
 * OBSOLETE -> use CheckBox.tsx
 */
export class InputCheckbox extends React.Component<IProps, IState> {
    protected controlCheckbox: HTMLInputElement | null;
    protected className = 'InputCheckbox';
    protected loggerLocality = 'Components\\shared.InputCheckbox';

    constructor(props: IProps) {
        super(props);

        const sourceValue: boolean = this.props.attributes.value.toLocaleUpperCase() === 'TRUE';
        const initialValidation: boolean = (this.props.attributes.initialValidation === undefined) ? false : this.props.attributes.initialValidation;

        // Validate input value.
        const errorMessage = this.validateField(initialValidation, sourceValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        // Initialize state based on validation check.        
        this.state = {
            value: sourceValue.toString(),
            isValid: controlValid,
            errorMsg: errorMessage
        }

        // If the control is invalid, the event will be triggered to inform parent component.
        if (!controlValid) {
            if (this.props.onChange) {
                this.props.onChange(this.props.attributes.id, sourceValue, errorMessage);
            }
        }
    }

    public componentDidMount(): void {
        if (this.props.attributes.editMode && this.props.attributes.hasFocus) {
            this.setFocus();
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {

        const lblClass: string = 'input-label';
        let txtClass: string = 'input-field';
        txtClass = txtClass + this.errorClass(this.state.errorMsg)

        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input--auto l-box__input--edit'

        const valueConverted: boolean = this.state.value.toLocaleUpperCase() === 'TRUE' ? true : false;

        const inputElement: JSX.Element[] = [];


        // Build final component element        
        inputElement.push(
            <label  key={'InputCheckbox_Label_' + this.props.attributes.id}                        
                    htmlFor={this.props.attributes.id} className={lblClass}>
                <Translate>{this.props.attributes.label}</Translate>
                {(this.props.attributes.isRequired && this.props.attributes.editMode) ? ' *' : ''}
            </label>
        )

        inputElement.push(
            <CheckBox
                key={'InputCheckbox_Input_' + this.props.attributes.id}
                id={this.props.attributes.id}
                name={this.props.attributes.id}
                className={txtClass}
                disabled={this.props.attributes.isReadOnly}
                required={this.props.attributes.isRequired}
                value={this.state.value}
                defaultChecked={valueConverted}
                onChange={(e) => this.onChange(e)}
                onKeyUp={this.onKeyUp}
                ref={(ref) => { if (ref !== null) { this.controlCheckbox = ref.inputCheckBox } }} />
        );

        inputElement.push(
            <span key={'InputCheckbox_ErrorMsg_' + this.props.attributes.id} className={'input-message error'}>
                <Translate>{this.state.errorMsg}</Translate>
            </span>
        )

        return (
            <div key={'InputCheckbox_Div_' + this.props.attributes.id} 
                 className={boxClass}>
                {inputElement}
            </div>
        )
    }

    /**
     * Retrieve error class.
     */
    protected errorClass(errorMsg: string) {
        return (errorMsg.length === 0 ? '' : ' has-error');
    }

    /**
     * Validate input value.
     */
    protected validateField(validateValue: boolean, value: boolean): string {
        let message: string = '';

        if (validateValue) {
            const valueConverted = value === true ? 'TRUE' : ''

            if (message.length === 0) {
                message = FieldValidators.Required(valueConverted, this.props.attributes.isRequired);
            }
        }
        return message;
    }

    /**
     * Set input focus to input field.
     */
    protected setFocus() {
        if (this.controlCheckbox !== null) {
            const htmlInput = this.controlCheckbox;
            htmlInput.focus();
        }
    }

    /**
     * Callback for text input: text has changed.
     */
    protected onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.currentTarget as HTMLInputElement;
        const targetValue: boolean = target.checked;

        // Validate input value
        const errorMessage = this.validateField(true, targetValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        this.setState({
            value: targetValue.toString(),
            isValid: controlValid,
            errorMsg: errorMessage,
        })

        if (this.props.onChange) {
            this.props.onChange(this.props.attributes.id, targetValue, errorMessage);
        }
    }

    /**
     * Callback called each time a key is released (key up event).
     */
    protected onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {  // ******* event type?
        if (event.keyCode === 13) {
            if (this.props.onCrEntered) {
                this.props.onCrEntered(this.props.attributes.id);
                event.preventDefault();
            }
        }
    }
}