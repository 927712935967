import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Schedule id and schedule code
 * @export
 * @class ScheduleCode
 */
@JsonObject
export class ScheduleCode {

    @JsonProperty('item1', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('item2', String, false)
    public code: string = '';

}