import { isSuccess } from "$src/util/Result";
import React, { useEffect, useState } from "react";
import { Translate } from "$components/shared/Translate";
import { Heading } from "$src/components/shared/Heading";
import { SubjectsSubscribedTo, UserSubjectSubscriptions } from '$src/storage/models/UserSubjectSubscriptions/UserSubjectSubscriptions';
import UserSubjectSubscriptionService from '$src/core/Services/UserSubjectSubscriptionService';
import { AttributeValue } from "$src/storage/models/AttributeValue";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import AttributeService from "$src/core/Services/AttributeService";
import { Alert } from "$src/components/shared/WarningsAndErrors/Alert";
import { MenuBreadCrumb } from "../breadCrumb/MenuBreadCrumb";
import Session from "$src/core/Session";
import { MultiSelectCheckboxItemRender } from "$components/shared/Atoms/MultiSelectCheckboxItemRender";
interface IProps {
    isOnProfile: boolean;
}

/**
 * Editing View for the Subect Subscriptions of a User. With a Multiselect the User can select up to 4 Subject he wants to subscribe himself to.
 * @param props Properties of the functional Component
 */
export default function MySubjectSubscriptionsEditView(props: IProps) {
    const [isLoadedSubjects, setIsLoadedSubjects] = useState<boolean>(false);
    const [userSubjectSubscriptions, setUserSubjectSubscriptions] = useState<UserSubjectSubscriptions>();
    const [isLoadedAttributes, setIsLoadedAttributes] = useState<boolean>(false);
    const [attributeData, setAttributeData] = useState<Array<AttributeValue>>([]);
    const [selectedAttributeData, setselectedAttributeData] = useState<Array<AttributeValue>>([]);
    const [message, setMessage] = useState<string>('');
    const [messageType, setMessageType] = useState<'warning' | 'error' | 'info' | 'success' | 'mixed'>('info');

    /**
     * Get and Set all attributes of Type Subject
     */
    useEffect(() => {
        AttributeService.instance.getAttributeValues(16).then(subjectAttributes => {
            if (!isSuccess(subjectAttributes)) {
                setIsLoadedAttributes(true);
            }
            else {
                const attributes = subjectAttributes as Array<AttributeValue>;
                setAttributeData([...attributes])

                setIsLoadedAttributes(true);
            }
        });
    }, []);

    /**
     * Get all the Subjects the User has selected already from the SRV
     */
    useEffect(() => {
        UserSubjectSubscriptionService.instance.getSubjectSubscriptionsFromUser().then(userSubjects => {
            if (!isSuccess(userSubjects)) {
                setIsLoadedSubjects(true);
                setselectedAttributeData(attributeData);
            }
            else {
                setUserSubjectSubscriptions(userSubjects as UserSubjectSubscriptions); 
                setIsLoadedSubjects(true);
            }
        });
    }, []); 

    const saveNewUserSubjectSubscription = async (itemToAddId: number): Promise<void> => {
        const userSubscriptions = await UserSubjectSubscriptionService.instance.addSubjectSubscriptionsFromUser(itemToAddId);
        const subjectName = attributeData.find(atd => atd.id === itemToAddId)?.text;
        if (isSuccess(userSubscriptions) && userSubjectSubscriptions) {
            const subscriptions = userSubjectSubscriptions.subscribedSubjects;
            const newSubscription = new SubjectsSubscribedTo();

            const attr = attributeData.find(a => a.id === itemToAddId);
            if(attr !== undefined){
                newSubscription.subjectName = attr.text;
                newSubscription.subjectId = attr.id
                newSubscription.numberOfNewItemsInSubject = 0;
                subscriptions?.push(newSubscription);
                userSubjectSubscriptions.subscribedSubjects = subscriptions;
                setUserSubjectSubscriptions(userSubjectSubscriptions)
                setMessageType('success');
                if(subjectName) {
                    setMessage(Session.instance.storage.translation.GetString('UserSubjectSubscriptions:Successfully').Format(subjectName));
                }
            }
        } else
        {
            setMessageType('error');
            if(subjectName) {
                setMessage(Session.instance.storage.translation.GetString('UserSubjectSubscriptions:ErrorOccurred').Format(subjectName));
            }
        }
    }

    const deleteUserSubjectSubscription = async (itemToDeleteId: number): Promise<void> => {
        const userSubscriptions = await UserSubjectSubscriptionService.instance.deleteSubjectSubscriptionsFromUser(itemToDeleteId);
        const subjectName = attributeData.find(atd => atd.id === itemToDeleteId)?.text;
        if (isSuccess(userSubscriptions) && userSubjectSubscriptions) {
            const subscriptions = userSubjectSubscriptions.subscribedSubjects;
            subscriptions?.splice(subscriptions?.findIndex(s => s.subjectId === itemToDeleteId));
            userSubjectSubscriptions.subscribedSubjects = subscriptions;
            setUserSubjectSubscriptions(userSubjectSubscriptions);
            setMessageType('success');
            if(subjectName) {
                setMessage(Session.instance.storage.translation.GetString('UserSubjectSubscriptions:SuccessfullyRemoved').Format(subjectName));
            }
        }else{
            setMessageType('error');
            if(subjectName) {
                setMessage(Session.instance.storage.translation.GetString('UserSubjectSubscriptions:ErrorOnRemove').Format(subjectName));
            }
        }
    }

    // Check if only 4 have been selected
    const onSelectionChanged = (selectedValues: Array<AttributeValue>): void => {
        if( userSubjectSubscriptions && 
            userSubjectSubscriptions.subscribedSubjects && 
            selectedValues.length <= 4 &&
            attributeData)
        {
            const oldSubjects = attributeData.filter(atr => userSubjectSubscriptions.subscribedSubjects?.findIndex(us => us.subjectId === atr.id) !== -1); // !== -1 Found attributes in my already existing userSubjects
            const itemsToDelete = oldSubjects.filter(oSubj => selectedValues.findIndex(sv => sv.id === oSubj.id ) === -1); // === -1 Values which are Not Found in my old Subjects anymore, gotta delete those
            const itemsToAdd = selectedValues.filter(nSubj => oldSubjects.findIndex(oSubj => oSubj.id === nSubj.id) === -1) // All the ones I cannot find inside my old Subjects, those need to be added.

            if(itemsToDelete.length > 0){
                itemsToDelete.map(async (itemToDelete) =>  {
                    await deleteUserSubjectSubscription(itemToDelete.id);
                })
            }
            if(itemsToAdd.length > 0){
                itemsToAdd.map(async (itemToAdd) =>  {
                    await saveNewUserSubjectSubscription(itemToAdd.id);
                })
            }
            setselectedAttributeData(selectedValues);
        } else {
            setMessage(Session.instance.storage.translation.GetString('UserSubjectSubscriptions:MaxReachedInfo'));
        }
    }
    
    // partial components

    const SubjectAdministration = () => {
        const data = selectedAttributeData.length > 0 ? selectedAttributeData : attributeData.filter(ad => userSubjectSubscriptions?.subscribedSubjects?.findIndex(us => us.subjectId === ad.id) !== -1 );
        return (
            <div className="user-subscriptions__settings">
                <div className="user-subscriptions__settings__value">
                    <MultiSelect
                        className="user-subscriptions__settings__value__multi-select"
                        itemRender={MultiSelectCheckboxItemRender}
                        data={attributeData}
                        onChange={(e) => { onSelectionChanged(e.target.value as AttributeValue[]) }}
                        value={data}
                        textField="text"
                    />
                </div>
            </div>
        );
    }
        

    const MultiTagSelection = () => 
    <>
        { isLoadedSubjects && <>
            <div className="user-subscriptions__settings">
                <SubjectAdministration />
            </div>
        </>
        }
    </>;


    return <>

        { isLoadedSubjects && isLoadedAttributes && props.isOnProfile &&
            <div className="MySubjectSubscriptions">
                <Heading  headingLevel={1} cssClass="heading__Level4" >
                    <Translate>UserSubjectSubscriptions:DefineSubjects</Translate>
                </Heading>
                <MultiTagSelection />
                {   message.length > 0 &&
                    <Alert alertType={messageType} alertAppereance={'box'} message={message} />  
                }
            </div>
        }
        {
            isLoadedSubjects && isLoadedAttributes && !props.isOnProfile &&
            <div className="MySubjectSubscriptions">
                <div className="l-container">
                    <MenuBreadCrumb /> 
                    <Heading headingLevel={1} cssClass="heading__Title">
                        <Translate>Navigation:MySubjectSubscriptions</Translate>
                    </Heading>
                    <div>
                        <Translate>UserSubjectSubscriptions:DefineSubjects</Translate> 
                    </div>
                </div>
                <div className="l-container">
                    <MultiTagSelection />
                    {   message.length > 0 &&
                        <Alert alertType={messageType} alertAppereance={'box'} message={message} />  
                    }
                </div>
            </div>
        }
        </>;
}