import * as React from 'react';
import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';
import { Item } from '$src/storage/models/Item';
import { ApplyRating } from '$src/components/itemRating/ApplyRating';
import ItemRatings from '$components/itemRating/ItemRatings';
import { Rating } from '@progress/kendo-react-inputs';
import Session from '$src/core/Session';
import { EItemType, ELessonStatus } from '$src/storage/models/enums';


interface IProps {
    headingClassName?: string;
    headingLevel: number;
    item: Item;
    parentTrainingPlanId: number;
}

interface IStateData {
    dataLoaded: boolean;
}

interface IState extends IStateData {
    activateMyRating: boolean;
    errorMessage: string;
    isCollapsed: boolean;
    usersRating: number;
    showApplyRating: boolean;
}

export class ItemRatingDetails extends React.Component<IProps, IState>{

    protected className = 'ItemRatingModal';
    protected loggerLocality = 'Components.ItemRatingModal';

    constructor(props: IProps) {
        super(props);

        this.state = {
            activateMyRating: false,
            errorMessage: '',
            dataLoaded: false,
            usersRating: this.props.item.ratingMine,
            isCollapsed: false,
            showApplyRating: false
        }
    }

    public render() {
        const lessonStatus = this.props.item.lessonStatus;
        const tr = Session.instance.storage.translation;
        const ratingAverage = this.props.item.ratingAverage / 2;
        return <div className="item-rating__container">
            <div className="item-rating__container__total">
                <label key={`star-rating_${this.props.item.itemId}`}
                    className={"lesson-tools"}
                    data-tip={tr.GetString('Rating:NotRated').replace('{0}', this.props.item.ratingCount.toString())
                        .replace('{1}', ratingAverage.toString())}
                    data-for={`${this.props.item.itemId}_myRatingTooltip`}
                    data-event={'focus mouseover mouseenter'}
                    data-event-off={'blur mouseleave mouseout'}>
                    <Rating
                        value={ratingAverage}
                        disabled={true} />
                    <div>{
                        tr.GetString('Rating:RatingAverage')
                            .replace('{0}', (this.props.item.ratingAverage / 2).toString())
                            .replace('{1}', (this.props.item.ratingCount).toString())}</div>
                </label>
            </div>

            {this.state.usersRating === 0 &&
                this.state.activateMyRating &&
                <div className="item-rating__container__apply-rating">
                    <Heading
                        headingLevel={this.props.headingLevel}
                        cssClass={'heading__Level3'}>
                        <Translate>{'Rating:MyRating'}</Translate>
                    </Heading>
                    <ApplyRating
                        item={this.props.item}
                        onCancle={() => this.setState({ activateMyRating: false, usersRating: this.props.item.ratingMine })}
                        onSave={() => this.setState({ activateMyRating: false, usersRating: this.props.item.ratingMine })} />
                </div>}
                {!this.state.activateMyRating?
            <div className="item-rating__container__action">
                {this.state.usersRating === 0 &&
                    (lessonStatus === ELessonStatus.Completed ||
                        lessonStatus === ELessonStatus.Failed ||
                        lessonStatus === ELessonStatus.Passed ||
                        this.props.item.itemType === EItemType.Document) &&
                        <button type="button"
                            className="btn--sm btn--primary"
                            onClick={() => (this.showApplyRating())}>
                            <Translate>Rating:RateNow</Translate>
                        </button>
                        }

                {this.state.usersRating === 0 &&
                    !(lessonStatus === ELessonStatus.Completed ||
                        lessonStatus === ELessonStatus.Failed ||
                        lessonStatus === ELessonStatus.Passed ||
                        this.props.item.itemType === EItemType.Document) &&
                    <Translate>{'Rating:CompleteLessonBeforeRating'}</Translate>
                }
            </div>:''}

            <ItemRatings
                itemId={this.props.item.itemId}
                parentTrainingPlanId={this.props.parentTrainingPlanId}
                parentHeadingLevel={this.props.headingLevel} />
        </div>
    }

    public showApplyRating() {
        this.setState({ activateMyRating: true })
        if (this.state.usersRating > 0) {
            this.setState({ showApplyRating: false });
        }
        else if (this.state.usersRating === 0) {
            this.setState({ showApplyRating: true });
        }
    }
}