import { PowerBIEmbed } from 'powerbi-client-react';
import React, { useEffect, useState } from "react";

import { Heading } from "$src/components/shared/Heading";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import { Translate } from "$src/components/shared/Translate";
import PowerBiService from "$src/core/Services/PowerBiService";
import { PowerBiEmbedConfiguration } from "$src/storage/models/PowerBiEmbedConfiguration";
import { isSuccess } from "$src/util/Result";

interface IProps {
    name: string;
    parentHeadingLevel: number;
}

/**
 * Component that renders one Power BI report
 * @param name The name of the report 
 */
export default function PowerBiReport(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [reportConfig, setReportConfig] = useState<PowerBiEmbedConfiguration>({} as PowerBiEmbedConfiguration);

    useEffect(() => {
        PowerBiService.instance.getReportConfig(props.name).then(config => {
            if (!isSuccess(config)) {
                config = new PowerBiEmbedConfiguration();
                config.loadingFailed = true;
            }
            setReportConfig(config as PowerBiEmbedConfiguration);
            setIsLoaded(true);
        });
    }, [])

    if (!isLoaded) {
        return <ProgressSpinner/>
    }
    return (
        <>
            {
                (reportConfig.nsuiTitleStringId || reportConfig.nsuiDescriptionStringId) &&
                <div className="l-container">
                    <Heading headingLevel={props.parentHeadingLevel + 1} cssClass={"heading__Level" + (props.parentHeadingLevel + 1) }>
                        <Translate>{ reportConfig.nsuiTitleStringId }</Translate>
                    </Heading>

                    <div className="l-box--wide text">
                        <Translate>{ reportConfig.nsuiDescriptionStringId }</Translate>
                    </div>
                </div>
            }
            {
                !reportConfig.loadingFailed &&
                <PowerBIEmbed cssClassName="powerbi__embed-component" embedConfig = {{
                    type: reportConfig.type,
                    id: reportConfig.id,
                    embedUrl: reportConfig.embedUrl,
                    accessToken: reportConfig.accessToken,
                    tokenType: 1
                }} />
            }
            {
                reportConfig.loadingFailed &&
                <div className="l-container">
                    <div className="error__box error">
                        <Translate>{"Report:PowerBiLoadingErrorMessasge"}</Translate>
                    </div>
                </div>
            }
        </>
    )
}