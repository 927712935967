import React from "react";
import { Tooltip } from "$components/shared/Tooltip";
import { EItemSubType, EItemType, ELessonStatus } from "$src/storage/models/enums";
import IconFolder from '$resources/svgs/lessonTypes/catalog-folder.svg';
import IconAssessment from '$resources/svgs/lessonTypes/assessment.svg';
import IconDocument from '$resources/svgs/lessonTypes/document.svg';
import IconCBT from '$resources/svgs/lessonTypes/cbt-lesson.svg';
import IconF2F from '$resources/svgs/lessonTypes/internal-lesson.svg';
import IconFileSharing from '$resources/svgs/lessonTypes/share.svg';
import IconQuestionnaire from '$resources/svgs/lessonTypes/questionnaire.svg';
import IconTrainingPlan from '$resources/svgs/lessonTypes/training-plan.svg';
import IconWBT from '$resources/svgs/lessonTypes/online-lesson.svg';
import IconSurvey from '$resources/svgs/lessonTypes/survey.svg';
import IconExternal from '$resources/svgs/lessonTypes/external-lesson.svg';
import InlineSVG from "react-inlinesvg";
import Session from "$src/core/Session";
import ItemStatusIcon from "$src/components/shared/Atoms/ItemStatusIcon";
import { Attribute } from "$src/storage/models/Attribute";
import { ItemHelper } from "$src/util/ItemHelper";

/**
 * The GT Item Icon and Status Props
 */
export interface IItemIconAndStatusProps {

    /**
     * Component unique id
     */
    id: string;

    /**
     * Type of lesson. Determins the icon
     */
    itemType: EItemType;

    /**
     * Sub type of lesson. Determins the icon
     */
    itemSubType: EItemSubType;

    /**
     * Lesson statuf of a user, to display an status icon.
     */
    itemStatus: ELessonStatus;

    /**
     * Item title
     */
    itemTitle: string;

    /**
     * All custom attributes of the item
     */
    itemAttributes: Attribute[];

    /**
     * Custom item icon
     */
    itemIcon: string;

    /**
     * **Optional** Alternative icon width. Default = 41px
     */
    iconWidth?: number;

    /**
     * **Optional** Alternative icon height. Default = 41px
     */
    iconHeight?: number;

    /**
     * **Optional** Text to display in the tool tip. If empty no tool tip will be displayed
     */
    tooltipText?: string;

    /**
     * **Optional** Flag if the item type are shown as text or not
     */
     hideItemTypeText?: boolean;

}


/**
 * Global Teach Lesson Icon and Status Component
 */
export default function ItemIconAndStatus(props: IItemIconAndStatusProps) {

    const tr = Session.instance.storage.translation;

    let icon = '';
    let text = '';
    let ariaLabel = tr.GetString('ItemDetail:ItemType') + ': ';

    switch (props.itemType) {
        case EItemType.Document:
            icon = IconDocument;
            text = tr.GetString('ItemType:Document');
            break;
        case EItemType.F2FCourse:
            if (props.itemSubType === EItemSubType.External) {
                icon = IconExternal;
                text = tr.GetString('ItemSubType:External');
            } else if (props.itemSubType === EItemSubType.FaceToFace) {
                icon = IconF2F;
                text = tr.GetString('ItemSubType:FaceToFace');
            } else {
                icon = IconF2F;
                text = tr.GetString('ItemType:F2FCourse');
            }
            break;
        case EItemType.WBT:
            icon = IconWBT;
            text = tr.GetString('ItemType:WBT');
            break;
        case EItemType.CBT:
            icon = IconCBT;
            text = tr.GetString('ItemType:CBTShort');
            break;
        case EItemType.Questionnaire:
            icon = IconQuestionnaire;
            if (props.itemSubType === EItemSubType.Random) {
                text = tr.GetString('ItemSubType:Random');
            } else if (props.itemSubType === EItemSubType.Survey) {
                icon = IconSurvey;
                text = tr.GetString('ItemSubType:Survey');
            } else if (props.itemSubType === EItemSubType.Standard) {
                text = tr.GetString('ItemSubType:Standard');
            } else {
                text = tr.GetString('ItemType:Questionnaire');
            }
            break;
        case EItemType.TrainingPlan:
            icon = IconTrainingPlan;
            text = tr.GetString('ItemType:TrainingPlan');
            break;
        case EItemType.Assessment:
            icon = IconAssessment;
            text = tr.GetString('ItemType:Assessment');
            break;
        case EItemType.FileSharing:
            icon = IconFileSharing;
            text = tr.GetString('ItemType:FileSharing');
            break;
        case EItemType.Folder:
            icon = IconFolder;
            text = tr.GetString('Favorite:TypeCatalogFolder');
            break;
    }

    if (globalConfig.itemProperties.isCustomLessonTypeTextSupported) {
        const customLessonTypeTextAttributeName = globalConfig.itemProperties.customLessonTypeTextAttributeName
        const customLessonType = props.itemAttributes != null ?
            props.itemAttributes.find(x => x.name.toUpperCase() === customLessonTypeTextAttributeName.toUpperCase()) : null;
        if (customLessonType != null) {
            text = customLessonType.value
        }
    }

    const isDefaultIcon = ItemHelper.isDefaultIcon(props.itemIcon, props.itemType)
    let isUserDefinedIcon = false;

    if (props.itemIcon && props.itemIcon.length > 0 && !isDefaultIcon) {
        icon = globalConfig.itemProperties.iconsUrl + '/' + props.itemIcon;
        isUserDefinedIcon = true;
    }
    ariaLabel += text;
    const iconElement = isUserDefinedIcon ? <img alt={ariaLabel} src={icon} /> :
        <InlineSVG src={icon} width={props.iconWidth || 41} height={props.iconHeight || 41} />

    return (
        <div
            key={`ItemIconAndStatus_${props.id}`}
            className="item-icon"
        >
            <div className="item-icon__status" >
                <ItemStatusIcon id={props.id} itemStatus={props.itemStatus} itemType={props.itemType} itemTitle={props.itemTitle} showToolTipText={true} />
            </div>
            <div
                className="item-icon__icon"
                aria-label={ariaLabel}
                data-tip={props.tooltipText}
                data-for={`TT_ItemIconAndStatus_${props.id}`}
                role="img"
            >
                {iconElement}
                {props.tooltipText &&
                    <Tooltip id={`TT_ItemIconAndStatus_${props.id}`} delayShow={500} />
                }
            </div>
            {!props.hideItemTypeText &&
                <div className="item-icon__text" tabIndex={0}>
                    {text.toLocaleUpperCase()}
                </div>}

        </div>
    );
}