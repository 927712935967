import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ItemRequest {

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('uiCulture', String, false)
    public uiCulture: string = '';

}