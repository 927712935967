import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';

import IconEyeOn from '$resources/svgs/favorites/watchlist-on.svg';

import ToolbarItemType from '$components/item/Toolbar/ToolbarItemType';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import { Item } from '$src/storage/models/Item';
import { EItemDetailCallerContextType, EItemType } from '$storage/models/enums';
import { WatchedItemModel } from '$storage/models/WatchedItemModel';
import { ItemHelper } from '$util/ItemHelper';
import { KeyPressHelper } from '$util/KeyPressHelper';
import { Tooltip } from '$components/shared/Tooltip';
import ItemBoxSimple from '$src/components/shared/itemBox/ItemBoxSimple';


// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IProps extends RouteComponentProps<{}> {
    watchedItem: WatchedItemModel;
    parentHeadingLevel?: number;
    onRemove?: () => void;
}

interface IState {
    item?: Item;

}
export class ItemSummaryWatchedItem extends React.Component<IProps, IState> {

    protected itemContext = EItemDetailCallerContextType.MyWatchlist;

    constructor(props: IProps) {
        super(props);
        this.state = { item: undefined };
        this.onClick = this.onClick.bind(this);
        this.prepareRender = this.prepareRender.bind(this);

    }

    public async UNSAFE_componentWillMount() {
        const itemResult = await Session.instance.storage.item.getItemDetail(this.props.watchedItem.itemId,
            this.itemContext, undefined, false);
        if (itemResult != null) {
            this.setState({ item: itemResult })
        }

    }

    public render() {
        return (
            <ItemBoxSimple id={this.props.watchedItem.id} isListItem={true} additionalCss="item-summary" >
                <div tabIndex={0}
                    className="item-summary__mylists"
                    onClick={() => this.onClick()}
                    onKeyUp={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => this.onClick()) }}>
                    <div className="title">
                        <Heading headingLevel={this.props.parentHeadingLevel != null ? (this.props.parentHeadingLevel + 1) : (1)} cssClass="heading__Level4">
                            {this.props.watchedItem.title}
                        </Heading>
                    </div>
                    <div className="l-box__toolbar lesson__toolbar">
                        {this.prepareRender()}
                        <div className="l-box__toolbar__icon lesson-tools mylist_action">
                            <button
                                aria-label={Session.instance.storage.translation.GetString('WatchedItems:Remove') + ': ' + this.props.watchedItem.title}
                                data-tip={Session.instance.storage.translation.GetString('WatchedItems:Remove') + ': ' + this.props.watchedItem.title}
                                data-for={`${this.props.watchedItem.id}_toggleWatchedItemTooltip`}
                                onClick={(e) => this.onWatchedItemRemove(e)}>
                                <InlineSVG src={IconEyeOn} />
                                <Translate>WatchedItems:Remove2</Translate>
                                <Tooltip id={`${this.props.watchedItem.id}_toggleWatchedItemTooltip`} />
                            </button>
                        </div>
                    </div>
                </div>
            </ItemBoxSimple>
        );
    }

    public renderFavorites(): JSX.Element | JSX.Element[] {
        return <React.Fragment />;
    }


    public async onWatchedItemRemove(event: React.FormEvent<HTMLButtonElement>) {
        event.stopPropagation();
        await Session.instance.storage.watchList.RemoveSubscriber(this.props.watchedItem.itemId);
        if (this.props.onRemove != null) {
            this.props.onRemove();
        }
    }

    public onClick() {
        let link = '';
        switch (this.props.watchedItem.itemType) {
            case EItemType.TrainingPlan:
                link = ItemHelper.getTrainingPlanDetailLink(this.props.watchedItem.itemId,
                    this.itemContext,
                    0,
                    0);
                break;
            default:
                link = ItemHelper.getItemDetailLink(this.props.watchedItem.itemId,
                    this.itemContext,
                    0,
                    0,
                    0,
                    0);
        }
        this.props.history.push(link);
    }

    private prepareRender() {
        const item = this.state.item
        if (item == null) {
            return (<React.Fragment />)
        }
        return <ToolbarItemType item={item} className="l-box__toolbar__icon lesson-tools lesson-tools--type mylist-item-type" textVisible={true} />
    }


}
