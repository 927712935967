import { StringHelper } from '$src/util/StringHelper';
import {
    GridCellProps,
} from '@progress/kendo-react-grid';
import React from 'react';
import { KendoGridCellHelper } from './KendoGridCellHelper';

// tslint:disable-next-line: no-empty-interface
type IProps = GridCellProps


export const KendoDateGridCell: React.FC<IProps> = (props: IProps) => {
    const value = KendoGridCellHelper.getValue(props);
    return <td>
        {
            value instanceof Date ?
                StringHelper.dateString(value) :
                null
        }
    </td>
}