import React from 'react';

import CourseAttendance001 from '$resources/svgs/reports/CourseAttendance001.svg';
import CourseRegistrationStatus001 from '$resources/svgs/reports/CourseRegistrationStatus001.svg';
import CourseRegistrationStatus002 from '$resources/svgs/reports/CourseRegistrationStatus002.svg';
import Questionnaire001 from '$resources/svgs/reports/Questionnaire001.svg';
import Survey001 from '$resources/svgs/reports/Survey001.svg';
import TPlanUnlockLessons001 from '$resources/svgs/reports/TPlanUnlockLessons001.svg';
import UserLessonStatus001 from '$resources/svgs/reports/UserLessonStatus001.svg';
import UserLessonStatus002 from '$resources/svgs/reports/UserLessonStatus002.svg';
import TPlanStatus001 from '$resources/svgs/reports/TPlanStatus001.svg';

import ToolbarItemType from '$components/item/Toolbar/ToolbarItemType';
import { Heading } from '$components/shared/Heading';
import { Item } from '$storage/models/Item';
import InlineSVG from 'react-inlinesvg';
import { ItemsReportResponse } from '$src/storage/models/Reports/ItemsReportResponse';
import { EItemType, EItemDetailCallerContextType } from '$src/storage/models/enums';
import { NavigationUtil } from '$src/util/NavigationUtil';
import { EItemSubType } from '$storage/models/enums';
import Tooltip from "react-tooltip";
import Session from '$src/core/Session';
import { NavLink } from 'react-router-dom';
import { ItemHelper } from '$src/util/ItemHelper';

interface IProps {
    item: ItemsReportResponse;
    parentHeadingLevel?: number;
    itemLinkStyle?: React.CSSProperties;
    parentCatalogFolderId: number;
    parentAssignmentId: number;
    parentTrainingPlanId: number | null;
}

interface IState {
    itemType: EItemType;
    item?: Item;
    itemLinkStyle?: React.CSSProperties;
}
export class ReportSummary extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { itemType: EItemType.Undefined, item: undefined };
    }

    public render() {

        return (
            <li
                className="l-box--wide l-box--shadow item-summary report-item-summary">
                <NavLink to={this.getLink()}>
                    <div className="title">

                        <Heading headingLevel={this.props.parentHeadingLevel != null ? (this.props.parentHeadingLevel + 1) : (1)} cssClass="heading__Level4">
                            {this.props.item.completeTitle}
                        </Heading>
                    </div>

                </NavLink>
                <div className="l-box__toolbar report-item__toolbar">
                    {this.prepareRender()}
                    <div className="l-box__toolbar__icon report-item__available-reports">
                        <React.Fragment>
                            {this.renderItemTypeSVG()}
                        </React.Fragment>
                    </div>
                </div>
            </li>
        );
    }

    private prepareRender() {
        const item = this.props.item
        if (item == null) {
            return (<React.Fragment />)
        }
        return <ToolbarItemType item={item} className="l-box__toolbar__icon report-item__item-type" textVisible={true} />
    }

    private onNavigate(event: React.MouseEvent<Element>, url: string, doAUILogin: boolean, doLMSSUILogin: boolean) {
        if (doLMSSUILogin) {
            const win = window.open('about:blank', '_blank');
            event.preventDefault();
            if (win != null) {
                NavigationUtil.loginSui(url, win);
            }
        }
        else if (doAUILogin) {
            const win = window.open('about:blank', '_blank');
            event.preventDefault();
            if (win != null) {
                NavigationUtil.loginAui(win);
            }
        }
    }

    protected getLink(): string {
        const id = this.props.item.itemId;
        if (this.props.item.itemType === EItemType.TrainingPlan) {
            return ItemHelper.getTrainingPlanDetailLink(id,
                EItemDetailCallerContextType.TutoringReport,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId)
        } else {
            return ItemHelper.getItemDetailLink(id,
                EItemDetailCallerContextType.TutoringReport,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId,
                this.props.parentTrainingPlanId,
                0)
        }
    }

    public renderItemIcon = (): JSX.Element => {
        return <ToolbarItemType textVisible={false} itemType={this.props.item.itemType} itemSubType={this.props.item.itemSubType} />;
    }


    private renderItemTypeSVG = (): Array<JSX.Element> => {
        const svgArray: Array<JSX.Element> = [];
        const item = this.props.item;
        const itemType = item.itemType;
        const itemSubType = item.itemSubType;

        switch (itemType) {
            case EItemType.WBT:
                item.reportUrl.map((report, i) => {
                    let svg = (report.reportName === 'UserLessonStatus001') ? UserLessonStatus001 :
                        (report.reportName === 'UserLessonStatus002') ? UserLessonStatus002 : '';
                    const tooltipId = `${item.itemId}_reportName_${report.reportName}`;
                    const reportName = Session.instance.storage.translation.GetString('Report:' + report.reportName);
                    svgArray.push(<button
                        onClick={(event) => this.onNavigate(event, report.reportUrl, false, true)}
                        className="report-item__button"
                        key={`ReportButton_${i}_On_${tooltipId}`}
                        aria-label={reportName}
                        data-tip={reportName}
                        data-for={tooltipId}>
                        <InlineSVG src={svg} />
                        <Tooltip id={tooltipId} />
                    </button>);
                });
                break;
            case EItemType.F2FCourse:
                item.reportUrl.map((report, i) => {
                    const tooltipId = `${item.itemId}_reportName_${report.reportName}`;
                    const reportName = Session.instance.storage.translation.GetString('Report:' + report.reportName);
                    const svg = (report.reportName === 'CourseRegistrationStatus001') ? CourseRegistrationStatus001 :
                        (report.reportName === 'CourseRegistrationStatus002') ? CourseRegistrationStatus002 :
                            (report.reportName === 'CourseAttendance001') ? CourseAttendance001 : '';

                    svgArray.push(<button onClick={(event) => this.onNavigate(event, report.reportUrl, false, true)}
                        className="report-item__button"
                        aria-label={reportName}
                        key={`ReportButton_${i}_On_${tooltipId}`}
                        data-tip={reportName}
                        data-for={tooltipId}>
                        <InlineSVG src={svg} />
                        <Tooltip id={tooltipId} />
                    </button>);
                });
                break;
            case EItemType.TrainingPlan:
                item.reportUrl.map((report, i) => {
                    const tooltipId = `${item.itemId}_reportName_${report.reportName}`;
                    const reportName = Session.instance.storage.translation.GetString('Report:' + report.reportName);
                    const svg = (report.reportName === 'CourseRegistrationStatus001') ? CourseRegistrationStatus001 :
                        (report.reportName === 'CourseRegistrationStatus002') ? CourseRegistrationStatus002 :
                            (report.reportName === 'TPlanUnlockLessons001') ? TPlanUnlockLessons001 :
                                (report.reportName === 'TPlanStatus001') ? TPlanStatus001 : '';

                    svgArray.push(<button onClick={(event) => this.onNavigate(event, report.reportUrl, false, true)}
                        className="report-item__button"
                        aria-label={reportName}
                        key={`ReportButton_${i}_On_${tooltipId}`}
                        data-tip={reportName}
                        data-for={tooltipId}>
                        <InlineSVG src={svg} />
                        <Tooltip id={tooltipId} />
                    </button>);
                });
                break;
            case EItemType.Questionnaire:
                if (item.reportUrl) {
                    const svg = (itemSubType === EItemSubType.Survey) ? Survey001 :
                        (itemSubType === EItemSubType.Standard || itemSubType === EItemSubType.Random) ? Questionnaire001 : '';
                    const tooltipId = `${item.itemId}_reportName_${item.reportUrl[0].reportName}`;
                    const reportName = Session.instance.storage.translation.GetString('Report:' + item.reportUrl[0].reportName);

                    svgArray.push(<button onClick={(event) => this.onNavigate(event, item.reportUrl[0].reportUrl, false, true)}
                        className="report-item__button"
                        key={tooltipId}
                        aria-label={reportName}
                        data-tip={reportName}
                        data-for={tooltipId}>
                        <InlineSVG src={svg} />
                        <Tooltip id={tooltipId} />
                    </button>);
                }
                break;
            case EItemType.CBT:
            case EItemType.Assessment:
                item.reportUrl.map((report, i) => {
                    const tooltipId = `${item.itemId}_reportName_${report.reportName}`;
                    const reportName = Session.instance.storage.translation.GetString('Report:' + report.reportName);
                    const svg = report.reportName === 'UserLessonStatus001' ? UserLessonStatus001 : 
                                report.reportName === 'UserLessonStatus002' ? UserLessonStatus002 : '';

                    svgArray.push(<button onClick={(event) => this.onNavigate(event, report.reportUrl, false, true)}
                        className="report-item__button"
                        aria-label={reportName}
                        key={`ReportButton_${i}_On_${tooltipId}`}
                        data-tip={reportName}
                        data-for={tooltipId}>
                        <InlineSVG src={svg} />
                        <Tooltip id={tooltipId} />
                    </button>);
                });
                break;
            default:
                break;
        }

        return svgArray;
    }

}
