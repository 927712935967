import React, { useEffect, useState } from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Translate } from '$components/shared/Translate';
import Session from '$src/core/Session';

interface IDateRangeSelectProps {
	onMinSelected: (selected: Date | undefined) => void;
	onMaxSelected: (selected: Date | undefined) => void;
}

/**
 * DatePicker for min and max date range select
 * Selected date will be returned without any time zone info as Z00:00 to prevent any time zone converting troubles
 * @param props 
 * @returns 
 */
export default function GTDateRangeSelect(props: IDateRangeSelectProps) {
	const [min, setMin] = useState<Date>();
	const [max, setMax] = useState<Date>();

	useEffect(() => {
		props.onMinSelected(min ? new Date(Date.UTC(min.getFullYear(), min.getMonth(), min.getDate())) : undefined);
	}, [min]);
	useEffect(() => {
		props.onMaxSelected(max ? new Date(Date.UTC(max.getFullYear(), max.getMonth(), max.getDate())) : undefined);
	}, [max]);

	return (
		<div className='date-range-select'>
			<LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
				<IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
					<DatePicker
						onChange={(e) => { setMin(e.value || undefined) }}
						max={max}
						value={min || null}
						format={Session.instance.storage.translation.GetString('DatePicker:Format')}
						formatPlaceholder={{
							day: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderDay'),
							month: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderMonth'),
							year: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderYear'),
						}}
					/>
					<span>
						<Translate>DateRangeSelect:And</Translate>
					</span>
					<DatePicker
						onChange={(e) => setMax(e.value || undefined)}
						min={min}
						value={max || null}
						format={Session.instance.storage.translation.GetString('DatePicker:Format')}
						formatPlaceholder={{
							day: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderDay'),
							month: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderMonth'),
							year: Session.instance.storage.translation.GetString('DatePicker:FormatPlaceholderYear'),
						}}
					/>
				</IntlProvider>
			</LocalizationProvider>
		</div>
	);
}