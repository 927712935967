import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { UserSkillCommentTypeConverter } from '$src/storage/models/converters/UserSkillCommentTypeConverter';
import { EUserSkillCommentType } from '$src/storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';
@JsonObject
export class UserSkillComment {
    @JsonProperty('userSkillCommentId', Number, false)
    public userSkillCommentId: number = 0;
    /** Id of the commented user */
    @JsonProperty('commentedUserId', Number, false)
    public commentedUserId: number = 0;
    /** Id of the comment type. */
    @JsonProperty('commentTypeId', Number, false)
    public commentTypeId: number = 0;
    /** The comment */
    @JsonProperty('comment', String, false)
    public comment: string = '';
    /** The user skill id */
    @JsonProperty('userSkillId', Number, false)
    public userSkillId: number = 0;

    /** The date which corresponds to the creation or finalization */
    @JsonProperty('versionDate', DateTimeConverter, false)
    public versionDate: Date = new Date();
    /** Flag if the comment is finalized (No longer allows changes to the comment) */
    @JsonProperty('isFinalized', Boolean, false)
    public isFinalized: boolean = false;
    /** The user commented users firstname (of the employee or boss) */
    @JsonProperty('commentedUserFirstName', String, false)
    public commentedUserFirstName: string = '';
    /** The user commented users lastname (of the employee or boss) */
    @JsonProperty('commentedUserLastName', String, false)
    public commentedUserLastName: string = '';
    
    /** The comment type */
    @JsonProperty('commentType', UserSkillCommentTypeConverter, false)
    public commentType: EUserSkillCommentType = EUserSkillCommentType.Undefined;
}