import { JsonObject, JsonProperty } from 'json2typescript';



@JsonObject
export class PWChangeRequest {

    @JsonProperty('langCode', String, false)
    public langCode: string = '';

    @JsonProperty('username', String, false)
    public username: string = '';
    
    @JsonProperty('domain', String, false)
    public domain: string = '';

    @JsonProperty('oldPassword', String, false)
    public oldPassword: string = '';

    @JsonProperty('newPassword', String, false)
    public newPassword: string = '';

    @JsonProperty('timestamp', String, false)
    public timestamp: string = '';

    @JsonProperty('hash', String, false)
    public hash: string = '';

    @JsonProperty('pin', String, false)
    public pin: string = '';



  
}
