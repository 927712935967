import React from 'react';
import { Notification } from '$storage/models/InAppNotifications/Notification';
import InlineSVG from 'react-inlinesvg';
import Session from '$src/core/Session';
import InAppNotificationService from '$src/core/Services/InAppNotificationService';

// Icons
import IconAssignment from '$resources/svgs/notifications/assignment.svg';
import IconF2F from '$resources/svgs/notifications/internal-lesson.svg';
import IconVirtual from '$resources/svgs/notifications/virtual-lesson.svg';
import IconFallback from '$resources/svgs/notifications/fallback.svg';
import { useHistory } from 'react-router';

interface IProps {
    notification: Notification;
    onClick?: () => void;
}
export default function IANotification(props: IProps) {
    const history = useHistory();
    async function markAsRead() {
        if (props.notification.readDate == null) {
            const res = await InAppNotificationService.instance.setNotificationAsRead(props.notification.notificationID);
            if (res) {
                props.notification.readDate = new Date();
            }
        }
    }

    async function openRedirectLinkKeyboard(e: React.KeyboardEvent<HTMLLIElement>) {
        if (e.keyCode == 13) {
            history.push(props.notification.redirectLink);
            await markAsRead();
            if (props.onClick != null) {
                props.onClick();
            }
        }
    }

    async function openRedirectLink(e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>) {
        e.stopPropagation();
        e.preventDefault();
        history.push(props.notification.redirectLink);
        await markAsRead();
        if (props.onClick != null) {
            props.onClick();
        }
    }

    function renderIcon(): JSX.Element {
        let icon = IconFallback;
        let translation = "";
        if (props.notification != null) {
            switch (props.notification.iaNotificationType) {
                case 1:
                    icon = IconAssignment;
                    translation = Session.instance.storage.translation.GetString('IAN:NotificationTypeExpiringAssignments');
                    break;
                case 2:
                    icon = IconF2F;
                    translation = Session.instance.storage.translation.GetString('IAN:NotificationTypeUpcomingF2FClasses');
                    break;
                case 3:
                    icon = IconVirtual;
                    translation = Session.instance.storage.translation.GetString('IAN:NotificationTypeUpcomingVirtualClasses');
                    break;
                default:
                    icon = IconFallback;
                    translation = Session.instance.storage.translation.GetString('IAN:NotificationTypeUnknownType');
                    break;
            }
            return (
                <React.Fragment>
                    <InlineSVG
                        aria-label={translation}
                        src={icon} />                   
                </React.Fragment>

            );
        } else {
            return <React.Fragment />;
        }
    }

    function renderTranslatedNotification(): string {
        let notification = Session.instance.storage.translation.GetString("IAN:" + props.notification.iaNotificationType);
        props.notification.placeholders?.forEach(pl => {
            notification = notification.replace("{" + pl.key + "}", "<span class='highlight'>" + pl.value + "</span>");
        });
        return notification;
    }

    return (
        <li className="ianotificaions__notification"
            onKeyDown={(e) => openRedirectLinkKeyboard(e)}
            onClick={(e) => openRedirectLink(e)}
            tabIndex={0} >
            <div>
                {renderIcon()}
            </div>
            <div className="ianotificaions__notification__text--div">
                <span dangerouslySetInnerHTML={{ __html: renderTranslatedNotification() }} />
            </div>
            <div className={props.notification.readDate == null ? ('ianotificaions__notification--unread') : ('')} />
        </li>
    );

}