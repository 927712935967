import { AttributeValue } from '$src/storage/models/AttributeValue';
import ItemTypeModel from '$src/storage/models/ItemTypeModel';

/**
 * Filter model class for the new ELK Search components.
 */
export class SearchFilter {
    public dateFrom: Date | null = null;
    public dateTo: Date | null = null;
    public itemTypes: Array<ItemTypeModel> = [];
    public subjects: Array<AttributeValue> = [];
    public durationMin: number = 0;
    public durationMax: number = 0;
}