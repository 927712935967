import { ECertificateType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class CertificateTypeConverter implements JsonCustomConvert<ECertificateType> {

    public serialize(certificateType: ECertificateType): any {
        switch (certificateType) {
            case ECertificateType.Skill: return 1;
            case ECertificateType.Competence: return 2;
            case ECertificateType.Lesson: return 3;
            case ECertificateType.TPlan: return 4;
            case ECertificateType.TplanBlock: return 5;          

            default: return 0;
        }
    }
    public deserialize(jsonValue: number): ECertificateType {
        switch (jsonValue) {
            case 1: return ECertificateType.Skill;
            case 2: return ECertificateType.Competence;
            case 3: return ECertificateType.Lesson;
            case 4: return ECertificateType.TPlan;
            case 5: return ECertificateType.TplanBlock;        

            default: return ECertificateType.Undefined;
        }
    }
}
