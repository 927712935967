import Logger from '$src/core/Logger';
import TrainingPlanService from '$src/core/Services/TrainingPlanService';
import Session from '$src/core/Session';
import SessionCache from '$src/storage/cache/SessionCache';
import { isSuccess } from '$src/util/Result';
import { EItemDetailCallerContextType } from './models/enums';
import { TrainingPlanElement } from './models/TrainingPlan/TrainingPlanElement';

const CACHING_DURATION = 800;

export default class TrainingPlanElementStorage extends SessionCache<string, TrainingPlanElement[]> {
    protected className = 'TrainingPlanElementStorage';
    protected loggerLocality = 'Storage.TrainingPlanElementStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getTrainingPlanElements(itemId: number, itemContext: EItemDetailCallerContextType, forceLoadingFromServer = true): Promise<TrainingPlanElement[] | null> {
        const methodName = `${this.className}:getTrainingPlanElements()`;

        if (this.isObjectInCache(itemId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack) && !forceLoadingFromServer) {
            Logger.log(this.loggerLocality, `${methodName} getting TrainingPlanElements from cache, itemId=${itemId}_${Session.instance.getUserLanguageCodeOrFallBack}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting TrainingPlanElements from server, itemId=${itemId}_${Session.instance.getUserLanguageCodeOrFallBack}`);
            const response = await TrainingPlanService.instance.getTrainingPlanElements(Session.instance.getUserLanguageCodeOrFallBack, itemId, itemContext);
            if (isSuccess<TrainingPlanElement[]>(response)) {
                this.saveObjectToCache(itemId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack, response);
            } else {
                // tslint:disable-next-line:max-line-length
                Logger.log(this.loggerLocality, `${methodName} failed to get TrainingPlanElements from server, itemId=${itemId}_${Session.instance.getUserLanguageCodeOrFallBack}.`);
            }
        }
        return this.getObjectFromCache(itemId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack);
    }

    public removeItemFromCache(itemId: number): void{
        super.removeObjectFromCache(itemId.toString() + '_' + Session.instance.getUserLanguageCodeOrFallBack);
    }
    
    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}