import { CommentDialogModal } from '$src/components/shared/CommentDialog/CommentDialogModal';
import { Translate } from '$src/components/shared/Translate';
import { TodoGlobalState } from '$src/components/TodoList/TodoGlobalState';
import WorkflowActivityService, { INameValuePair } from '$src/core/Services/WorkflowActivityService';
import Session from '$src/core/Session';
import { EClassStatus, EWorkflowActivityStatus } from '$src/storage/models/enums';
import { WorkflowActivity } from '$src/storage/models/Workflow/WorkflowActivity';
import { StringHelper } from '$src/util/StringHelper';
import { observer } from 'mobx-react';
import React from 'react';
import GTInfoMessage from '$src/components/shared/Atoms/GTInfoMessage';

interface IProps {
    workflowActivity: WorkflowActivity | undefined;
    onReload: () => void;
    onUpdateInProgress: () => void;
}

interface IState {
    selectedOption: string;
    comment: string;
    selectedCancellationId: number;
}


@observer
export class WorkflowActivityResponse extends React.Component<IProps, IState> {
    private item: WorkflowActivity;
    private cancellationReason: string;

    constructor(props: IProps) {
        super(props);
        this.item = props.workflowActivity!;
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getComment = this.getComment.bind(this);
        this.commentChangedHandler = this.commentChangedHandler.bind(this);
        this.CancellationSelectionChanged = this.CancellationSelectionChanged.bind(this);
        this.UpdateWorkflowItem = this.UpdateWorkflowItem.bind(this);
        this.cancellationReason = '';
        this.state = {
            comment: this.getComment(),
            selectedCancellationId: this.item.cancellationId,
            selectedOption: this.GetSelectedOption(this.item),
        }
    }
    public render() {
        if (this.item === undefined) {
            return <React.Fragment />
        }
        else {
            const option1 = this.item.validResponses[0].toString();
            const option2 = this.item.validResponses[1].toString();
            return (
                <div className="todo-container-column">
                    <div><span dangerouslySetInnerHTML={{ __html: this.item.workflowDescription }} /></div>
                    <div><span dangerouslySetInnerHTML={{ __html: this.item.description }} /></div>
                    {this.item.classStatus === EClassStatus.Cancelled &&
                        <GTInfoMessage 
                            id={`TodoListItem_${this.item.id}`}
                            message={Session.instance.storage.translation.GetString('Todo:ClassCancelledHint')}
                            showInfoIcon={true}/>
                    }
                    {this.item.result === 'timeout' ?
                        <div className="todo-automated-closed-hint">
                            {Session.instance.storage.translation.GetString('Todo:AutomatedClosedHint').Format(StringHelper.dateTimeIsoString(this.item.completedDate))}
                        </div> :
                        this.item.result &&
                        <div className="todo-completed-hint">
                            {Session.instance.storage.translation.GetString('Todo:CompletedHint').Format(StringHelper.dateTimeIsoString(this.item.completedDate))}
                        </div>
                    }
                    <div className="todo-response-box todo-container-column">
                        <div className="todo-container-row">
                            <div className="todo-label">
                                <Translate>Todo:ResponseLabel</Translate>:
                            </div>
                            <div className="todo-content">
                                <div className="todo-margin--next">
                                    <label>
                                        <input type="radio" value={option1} className={this.SetClassName(this.item)}
                                            disabled={this.SetDisabled(this.item)}
                                            checked={this.state.selectedOption === option1}
                                            onChange={this.handleOptionChange} />
                                        {option1}
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio" value={option2}
                                            checked={this.state.selectedOption === option2}
                                            disabled={this.SetDisabled(this.item)}
                                            onChange={this.handleOptionChange} />
                                        {option2}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="todo-container-row">
                            <div className="todo-label">
                                <div><Translate>Common:CommentLabel</Translate>:</div>
                            </div>

                            <div className="todo-content">
                                <div className="todo-margin--next">{this.state.comment}</div>
                                <CommentDialogModal comment={this.state.comment} onCommentChanged={this.commentChangedHandler} />
                            </div>
                        </div>
                        <div className="todo-container-row todo-container-column todo-100percent">
                            <div className="todo-label">
                                <div><Translate>Common:ReasonLabel</Translate>:</div>
                            </div>
                            <select className="todo-content-select" onChange={this.CancellationSelectionChanged} value={this.state.selectedCancellationId}>
                                <option />
                                {TodoGlobalState.instance.cancellationReasons.map(x =>
                                    <option label={x.title} value={x.id} id={x.id.toString()} key={x.id}>{x.title}</option>)}
                            </select>
                        </div>
                        <button className="btn--md todo-content-center"
                            type="button"
                            onClick={this.handleSubmit}>
                            <Translate>Button:Save</Translate>
                        </button>
                    </div>
                </div>
            );
        }
    }

    protected async handleSubmit() {
        const arr: INameValuePair[] = [];
        arr.push({ Name: 'workflowId', Value: this.item.workflowId.toString() })
        arr.push({ Name: 'activityId', Value: this.item.id.toString() })
        arr.push({ Name: 'result', Value: this.state.selectedOption })
        arr.push({ Name: 'Comment', Value: this.item.comment })
        arr.push({ Name: 'CancellationReason', Value: this.cancellationReason })
        const arr2: INameValuePair[][] = [arr];
        await this.UpdateWorkflowItem(arr2);
    }

    protected handleOptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            selectedOption: event.target.value
        });
    }
    protected getComment() {
        if (this.item === undefined) {
            return '';
        }
        return this.item.comment;
    }

    protected commentChangedHandler(changedComment: string) {
        this.item.comment = changedComment;
        this.setState({ comment: changedComment });

    }

    protected async UpdateWorkflowItem(arr: INameValuePair[][]) {
        this.props.onUpdateInProgress();
        await WorkflowActivityService.instance.setWorkflowActivity(arr);
        this.props.onReload();
    }


    protected CancellationSelectionChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement
        this.cancellationReason = target.value
        const id = parseInt(target.value, 10)
        this.setState({ selectedCancellationId: id });

    }

    protected SetDisabled(item: WorkflowActivity) {
        if (item.status === EWorkflowActivityStatus.Completed) {
            return true;
        }
        return false;
    }
    protected GetSelectedOption(item: WorkflowActivity) {
        if (item.result === '') {
            return this.item.validResponses[0].toString();

        }
        return item.result;

    }
    protected SetClassName(item: WorkflowActivity) {
        if (item.status === EWorkflowActivityStatus.Completed) {
            return 'disabled';
        }
        return '';
    }

}
