import Logger from '$src/core/Logger';
import AuthService from '$src/core/Services/AuthService';
import Session from '$src/core/Session';
import { StringResponse } from '$src/storage/models/StringResponse';
import { isSuccess } from '$src/util/Result';
import { OldSuiUrlResponse } from '$src/storage/models/OldSuiUrlResponse';


export class NavigationUtil {
    public static async loginSui(link: string, window: Window) {
        if (Session.instance.loginUser != null) {
            // invalid characters are mapped to the global domain
            const domain = Session.instance.loginUser.domainName === '' ||
                Session.instance.loginUser.domainName === '/' ||
                Session.instance.loginUser.domainName === '\\'
                ? 'Global' : Session.instance.loginUser.domainName;
            const response = await AuthService.instance.GetOldSuiUrl(domain);
            if (isSuccess<OldSuiUrlResponse>(response)) {
                let suiURL = response.url;
                suiURL = suiURL.endsWith('/') ? suiURL.substring(0, suiURL.length - 1) : suiURL;
                let url = `${suiURL}/${globalConfig.loginSuiProperties.loginPage != null ?
                    globalConfig.loginSuiProperties.loginPage : 'login.aspx'}`;
                if (!url.startsWith('http')) {
                    const baseUrl = `${location.protocol}//${location.hostname}`;
                    url = baseUrl + url;
                }
                const returnurl = link == null || link === '#' ? globalConfig.loginSuiProperties.returnUrl : link;
                const configName = globalConfig.loginSuiProperties.configName;
                const languageCode = Session.instance.getUserLanguageCodeOrFallBack;

                Logger.log('components\\layout\\menu', 'Return URL is ' + returnurl);
                
                const loadLocation = url + '?accesstoken=' + response.accessToken +
                    '&cfg=' + configName +
                    '&returnurl=' + (returnurl == null || returnurl === '' ? '' : (returnurl.split('?').join('%3F').split('&').join('%26'))) +
                    '&language=' + languageCode;
                window.location.href = loadLocation

                Logger.log('components\\layout\\menu', loadLocation);
            }
            else {
                Session.instance.setLastErrorMessage(response.message);
                Logger.log('components\\layout\\menu', `loginSui failed:` + response.message);
            }
        }
    }

    public static async loginAui(window: Window) {
        if (Session.instance.loginUser != null) {
            const response = await AuthService.instance.GetAuiUrl();
            if (isSuccess<StringResponse>(response)) {
                window.location.href = response.value;
            }
            else {
                Session.instance.setLastErrorMessage(response.message);
                Logger.log('components\\layout\\menu', `loginAui failed:` + response.message);
            }
        }
    }

    /**
     * Extracts an attribute value of the navigation item with the specified id. Finds navigation items from the globalConfig file: navigationProperties.navigationItems or
     * navigationProperties.extendedNavigationItems
     * @param id id of the navigation item to extract the attribute value from
     * @param attribute name of attribute value to extract (id, link, text, iconColor, ... for more see globalConfig.d.ts)
     */
    public static getNavigationItemAttributeById(id: string, attribute: string): any {
        const fitstNavItemById = globalConfig.navigationProperties.navigationItems.filter(item => item.id === id)[0];
        const firstExtendedNavItemById = globalConfig.navigationProperties.extendedNavigationItems.filter(item => item.id === id)[0];

        if (fitstNavItemById) {
            return fitstNavItemById[attribute as keyof typeof fitstNavItemById];
        } else if (firstExtendedNavItemById) {
            return firstExtendedNavItemById[attribute as keyof typeof firstExtendedNavItemById];
        }
        return null;
    }

}