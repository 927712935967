import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UpdateData {

    @JsonProperty('initialCode', String, false)
    public initialCode: string = '';

    @JsonProperty('username', String, false)
    public username: string = '';

    @JsonProperty('domainName', String, false)
    public domainName: string = '';

    @JsonProperty('email', String, false)
    public email: string = '';

    @JsonProperty('mobileNumber', String, false)
    public mobileNumber: string = '';
}
