import React from 'react';
import SearchPanel from '$src/components/search/elk/SearchPanel';
import SearchByKeywords from '$src/components/search/elk//SearchByKeywords';
import { useHistory } from 'react-router';

export default function SearchHomePanel() {
    const history = useHistory();
    function onSearch(searchValue: string) {
        history.push({
            pathname: '/search',
            search: '?value=' + encodeURIComponent(searchValue)
        });
    }

    if (!globalConfig.searchProperties.useLegacySearch) {
        return (
            <div className="search-homePanel__container">
                <SearchPanel classPrefix={"quicksearch-homepanel"} onSearch={(value) => onSearch(value)} searchValue='' />
                <SearchByKeywords onSearch={(value) => onSearch(value)} />
            </div >
        );
    } else {
        return <React.Fragment />
    }

}

