import React from 'react';

import IconStarOn from '$resources/svgs/favorites/favorite-on.svg';

import ToolbarItemType from '$components/item/Toolbar/ToolbarItemType';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import TrainingPlanService from '$core/Services/TrainingPlanService';
import Session from '$core/Session';
import { EFavoriteType, EItemType } from '$storage/models/enums';
import { EItemDetailCallerContextType } from '$storage/models/enums';
import { Favorite } from '$storage/models/Favorite';
import { Item } from '$storage/models/Item';
import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import { ItemHelper } from '$util/ItemHelper';
import { KeyPressHelper } from '$util/KeyPressHelper';
import { isSuccess } from '$util/Result';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';
import ItemBoxSimple from '$src/components/shared/itemBox/ItemBoxSimple';
import { RouteComponentProps } from 'react-router';


interface IProps extends RouteComponentProps<{}> {
    favorite: Favorite;
    parentHeadingLevel?: number;
    onRemove?: () => void;
}

interface IState {
    itemType: EItemType;
    item?: Item;
    tpElementItem?: TrainingPlanElement;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class ItemSummaryFavorites extends React.Component<IProps, IState> {

    protected itemContext = EItemDetailCallerContextType.MyFavorites;

    constructor(props: IProps) {
        super(props);
        this.state = { itemType: EItemType.Undefined, item: undefined };
    }
    public async UNSAFE_componentWillMount() {
        if (this.props.favorite.type === EFavoriteType.TPlanElement) {
            const tpItem = await TrainingPlanService.instance.getTrainingPlanElement(this.props.favorite.objectId, Session.instance.getUserLanguageCodeOrFallBack, EItemDetailCallerContextType.MyFavorites);
            if (isSuccess<TrainingPlanElement>(tpItem)) {
                this.setState({ tpElementItem: tpItem, itemType: tpItem.itemType })
            }
        }
        else {
            const itemResult = await Session.instance.storage.item.getItemDetail(this.props.favorite.objectId,
               this.itemContext, undefined, false)
            if (itemResult != null) {
                this.setState({ item: itemResult, itemType: itemResult.itemType })
            }
        }
    }

    public render() {

        return (
            <ItemBoxSimple id={this.props.favorite.id} isListItem={true} additionalCss="item-summary" >
                <div tabIndex={0}
                    className="item-summary__mylists"
                    onClick={() => this.onClick()}
                    onKeyUp={(e) => { KeyPressHelper.executePromiseWhenSpaceOrEnter(e, async () => await this.onClick()) }}>
                    <div className="title">
                        <Heading headingLevel={this.props.parentHeadingLevel != null ? (this.props.parentHeadingLevel + 1) : (1)} cssClass="heading__Level4">
                            {this.props.favorite.title}
                        </Heading>
                    </div>
                    <div className="l-box__toolbar lesson__toolbar">
                        {this.prepareRender()}
                        <div className="l-box__toolbar__icon lesson-tools mylist_action">
                            <button
                                aria-label={Session.instance.storage.translation.GetString('Favorite:Remove') + ': ' + this.props.favorite.title}
                                data-tip={Session.instance.storage.translation.GetString('Favorite:Remove') + ': ' + this.props.favorite.title}
                                data-for={`${this.props.favorite.id}_ToggleFavoriteTooltip`}
                                onClick={(e) => this.onFavoriteRemove(e)}>
                                <InlineSVG src={IconStarOn} />
                                <Translate>Favorite:Remove2</Translate>
                                <Tooltip id={`${this.props.favorite.id}_ToggleFavoriteTooltip`} />
                            </button>
                        </div>
                    </div>
                </div>
            </ItemBoxSimple>
        );
    }

    public renderFavorites(): JSX.Element | JSX.Element[] {
        return <React.Fragment />;
    }

    public getFavoriteTypeTranslation(type: EFavoriteType): string {
        let translationKey = 'Favorite:TypeItem';
        switch (type) {
            case EFavoriteType.CatalogFolder:
                translationKey = 'Favorite:TypeCatalogFolder';
                break;
            case EFavoriteType.Item:
                translationKey = 'Favorite:TypeItem';
                break;
            case EFavoriteType.TPlanElement:
                translationKey = 'Favorite:TypeTPlanElement';
                break;
        }
        return Session.instance.storage.translation.GetString(translationKey);
    }

    public async onFavoriteRemove(event: React.FormEvent<HTMLButtonElement>) {
        event.stopPropagation();
        await Session.instance.storage.favorites.removeFavorite(this.props.favorite.id);
        if (this.props.onRemove != null) {
            this.props.onRemove();
        }
    }

    public async onClick() {
        let link = '';
        switch (this.props.favorite.type) {
            case EFavoriteType.CatalogFolder:
                link = '/catalog/' + this.props.favorite.objectId;
                break;
            case EFavoriteType.Item:
                {
                    const type: EItemType = await this.returnItemType(this.props.favorite.objectId);
                    switch (type) {
                        // TODO : Assignment Context gets ignored, data is not available
                        case EItemType.TrainingPlan:

                            link = ItemHelper.getTrainingPlanDetailLink(this.props.favorite.objectId,
                                this.itemContext,
                                0,
                                0)
                            break;
                        default:
                            link = ItemHelper.getItemDetailLink(this.props.favorite.objectId,
                                this.itemContext,
                                0,
                                0,
                                0,
                                0)
                    }
                    break;
                }
            case EFavoriteType.TPlanElement:
                {
                    if (this.state.tpElementItem != null) {
                        link = ItemHelper.getItemDetailLink(this.state.tpElementItem.itemId,
                            this.itemContext,
                            0,
                            0,
                            this.state.tpElementItem.tPlanId,
                            this.state.tpElementItem.tpeId);
                    }
                    break;
                }
            default:
                link = '/'; // TODO: Show error
        }
        this.props.history.push(link);
    }
    public async renderFavoriteIcon(): Promise<JSX.Element> {
        let element: JSX.Element;
        switch (this.props.favorite.type) {
            case EFavoriteType.Item:
                element = (<ToolbarItemType textVisible={false} itemType={await this.returnItemType(this.props.favorite.objectId)} />);
                break;
            case EFavoriteType.CatalogFolder:
                element = <React.Fragment />
                break;
            case EFavoriteType.TPlanElement:
                element = <React.Fragment />
                break;
            default:
                element = <React.Fragment />
        }

        return element;
    }

    protected async returnItemType(itemId: number): Promise<EItemType> {
        const response: Item | null = await Session.instance.storage.item.getItemDetail(itemId, this.itemContext);
        if (isSuccess<Item | null>(response)) {
            if (response != null) {
                return response.itemType;
            } else {
                return EItemType.Undefined;
            }
        } else {
            return EItemType.Undefined;
        }
    }

    private prepareRender() {
        const item = this.state.item || this.state.tpElementItem;
        if (item == null) {
            return (<></>)
        }
        return <ToolbarItemType item={item} className="l-box__toolbar__icon lesson-tools lesson-tools--type mylist-item-type" textVisible={true} />
    }
}