
import { JsonObject, JsonProperty } from 'json2typescript';
import { EItemSecurityContext } from '$src/storage/models/enums';

@JsonObject
export class SetF2FLessonStatuscompletedAndHasAttendedRequest {

    @JsonProperty('registrationId', Number, true)
    public registrationId: number = 0;

    @JsonProperty('itemContext', Number, true)
    public itemSecurityContext: number = EItemSecurityContext.Undefined;

    @JsonProperty('parentTrainingPlanId', Number, true)
    public parentTrainingPlanId: number = 0;
}