import React, { useEffect, useState } from 'react';

import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import PrivacyService from '$src/core/Services/PrivacyService';
import Session from '$src/core/Session';
import { isSuccess } from '$src/util/Result';
import { TCDocument } from '$src/storage/models/Privacy/TCDocument';
import GtError from '$src/util/GtError';


/**
 * Decline the pricacy statement
 */
export default function RevokeMyPrivacy() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [tcDocument, setTCDocument] = useState<TCDocument>();
    
    useEffect(() => {
        (async function loadTcDocument() {
            const tc: TCDocument | GtError = await PrivacyService.instance.getCurrentTermsAndConditionsDocument();
        if (isSuccess(tc) && tc) {
            setTCDocument(tc as TCDocument);     
        } else {
            console.error('No TC Document found.');
            setErrorMessage('MyPrivacy:Error_PopUp')
        }
        })();
    }, []);

    const revoke = async (tcDocumentId: number) => {
        setModalIsLoading(true);
        const res = await PrivacyService.instance.declineTermsAndConditions(tcDocumentId);

        if (isSuccess(res)) {
            Session.instance.logout();
        } else {
            setModalIsLoading(false);
            setErrorMessage('MyPrivacy:Error_Revoke');
        }
    }

    /**
     * Renders revoke and cancel button
     */
    const UseRevokeModalButtons = () => {
        return <>
            {tcDocument && <div className="my-privacy__revoke-button-container">
                <button className="button button-small button--primary" onClick={() => setOpenModal(false)} >
                    <Translate>MyPrivacy:RevokeDoCancel</Translate>
                </button>
                <button className="button button-small button--secondary" onClick={() => revoke(tcDocument.id)} >
                    <Translate>MyPrivacy:RevokeDoRevoke</Translate>
                </button>
            </div>}
        </>;
    }
    
    return (
        <>
            <p>
                <Translate>MyPrivacy:Revoke</Translate>
            </p>
            <p>
                <button className="button button-small button--secondary" onClick={() => setOpenModal(true)} >
                    <Translate>MyPrivacy:RevokeButton</Translate>
                </button>
            </p>

            <ModalPopup isOpen={openModal} onRequestClose={() => setOpenModal(false)} contentCssClass="my-privacy__revoke-modal-content" >
                <p>
                    <Translate>MyPrivacy:RevokeText</Translate>
                </p>

                {modalIsLoading ? <ProgressSpinner /> : <UseRevokeModalButtons />}
                {errorMessage && <Alert alertAppereance="single-line" alertType="error" message={errorMessage} />}
            </ModalPopup>
        </>
    )
}