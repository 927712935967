/**
 * Some definitions concerning the current release
 */
export default class Release
{
    // Version string to display 
    public static version = '9.8.10.1'; 

    // Build ID from the CI pipeline
    public static buildId = process.env.REACT_APP_BUILD_ID || 'Unknown build ID';

    // true if this is a production build
    public static isProductionBuild: boolean = (process.env.NODE_ENV === 'production');

    // true if this is a development build
    public static isDevelopmentBuild: boolean = (process.env.NODE_ENV === 'development');
    
    /**
     * Log a release information string to the console
     */
    public static logReleaseInfo() {
        if (Release.isProductionBuild) {
            console.log(`%cProduction build, BuildId: ${this.buildId}, Version:${this.version}`, 'background: #222; color: #bada55');
        } else if (Release.isDevelopmentBuild) {
            console.log(`%cDevelopment build, Version: ${this.version}`, 'background: #222; color: #FFA500');
        } else {
            console.log(`%cUnknown build type ${process.env.NODE_ENV}`, 'background: #222; color: #FF0000');        
        }                
    }
}
