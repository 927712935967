import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class LegacySearchRequest {

    @JsonProperty('searchValue', String, true)
    public searchValue: string = '';

    @JsonProperty('language', String, true)
    public language: string = '';

    @JsonProperty('itemTypeArrayAsString', String, true)
    public itemTypeArrayAsString: string ='';

    @JsonProperty('from', String, true)
    public from: string = ''; 

    @JsonProperty('to', String, true)
    public to: string = '';

}