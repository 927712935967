import React from "react";
import { RouteComponentProps } from "react-router";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import Session from "$src/core/Session";
import { HeadingCollapsible } from "$src/components/shared/HeadingCollapsible";
import { Translate } from "$src/components/shared/Translate";
import { UnmountClosed } from "react-collapse";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import { NoDataFound } from "$src/components/shared/WarningsAndErrors/NoDataFound";
import { ECancellationPermission, ECancellationType, EItemDetailCallerContextType, EItemSubType, EItemType, ELessonStatus, ERegistrationStatus } from "$src/storage/models/enums";
import LessonService from '$core/Services/ItemService';
import DateHelper from "$src/util/DateHelper";
import { isSuccess } from "$src/util/Result";
import CustomErrorMessage from "$src/util/CustomErrorMessage";
import { DatePicker } from "$src/components/shared/DatePicker";
import { Tooltip } from "$src/components/shared/Tooltip";
import { isNumber } from 'util';
import { ItemSubTypeConverter } from "$src/storage/models/converters/ItemSubTypeConverter";
import { BookingOverviewTable } from "$src/components/myEmployees/BookingOverviewTable ";
import { LessonUser } from "$src/storage/models/LessonUser";
import { ModalPopup } from "$src/components/shared/ModalPopup";
import { ItemRegistrationActionConfirmation } from "$src/components/item/ItemRegistrationActionConfirmation";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import Logger from "$src/core/Logger";
import RegistrationService from "$src/core/Services/RegistrationService";
import TranslationStorage from "$src/storage/TranslationStorage";
import GtError from "$src/util/GtError";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { StringHelper } from "$src/util/StringHelper";
import TrainingPlanService from "$src/core/Services/TrainingPlanService";

interface IMatchParams {
    bossRelationCode: string;
}

type IProps = RouteComponentProps<IMatchParams>

interface IStateCancelRegistration {
    selectedRegistrationId: number;

    //Modal Properties
    modalLessonId: number;
    modalCancellationType?: ECancellationPermission;
    modalLessonTitle: string;
    itemType?: EItemType;
    modalClassId: number;
    selectedEmployeeId: number;
}

interface IState extends IStateCancelRegistration {
    errorMessage: string;
    autoCompleteTitlesFiltered: string[] | undefined;

    lessons: LessonUser[] | undefined;

    filterMaxDisplayItems: number;
    filterPanelCollapsed: boolean;
    filterItemType: number;
    filterLessonStatus: number;
    filterExcludeCompleted: boolean;
    filterRegistrationStatus: number;
    filterDateFrom: Date | null;
    filterDateTo: Date | null;
    filterItemSubType: number;
    filterTitle: string;
    isAutoCompleteLoading: boolean;
    isDataSearching: boolean;
    filterUser: string;

    shouldShowModal: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class BookingOverview extends React.Component<IProps, IState>{
    protected loggerLocality = 'Components.BookingOverview';
    protected className = 'BookingOverview';
    private TRANSLATION_PREFIX = 'BookingOverview';

    private title = Session.instance.storage.translation.GetString('Navigation:BookingOverview');

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            filterDateFrom: null,
            filterDateTo: null,
            filterExcludeCompleted: false,
            filterItemSubType: EItemSubType.Undefined,
            filterItemType: EItemType.Undefined,
            filterLessonStatus: ELessonStatus.Undefined,
            filterMaxDisplayItems: 10,
            filterPanelCollapsed: !globalConfig.bookingOverviewProperties.isFilterPanelExpanded,
            filterRegistrationStatus: ERegistrationStatus.Undefined,
            filterTitle: '',

            isDataSearching: false,
            lessons: undefined,
            filterUser: '',
            isAutoCompleteLoading: false,
            autoCompleteTitlesFiltered: undefined,

            modalLessonId: 0,
            modalLessonTitle: '',
            modalCancellationType: undefined,
            shouldShowModal: false,
            modalClassId: 0,
            selectedEmployeeId: 0,

            selectedRegistrationId: 0,
        }
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + this.title;
    }

    public render() {
        const translation = Session.instance.storage.translation;
        const idLoadSearchText = this.getTranslation("LoadFilter");

        return (
            <div>
                {/* Modal Popup */}
                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={() => this.closeModal()}>

                    <ItemRegistrationActionConfirmation
                        itemID={this.state.modalLessonId}
                        classTitle={this.state.modalLessonTitle}
                        parentHeadingLevel={2}
                        register={false}
                        onExitClicked={() => this.closeModal()}
                        onCancelRegistrationConfirmedClick={(cancellationId, cancellationReason) => this.cancelRegistrationConfirmed(cancellationId, cancellationReason)}
                        cancellationType={this.state.modalCancellationType}
                        cancellationReasonType={ECancellationType.Enrolment}
                        bossSelectionReadonly={false}
                        bossSelectionRequired={false}
                        bossSelectionVisible={false} />
                </ModalPopup>
                <div className="l-container">
                    <MenuBreadCrumb
                        key="MenuBreadCrumb"
                        routePathName={`/myteam/${this.props.match.params.bossRelationCode}`}
                        breadCrumbElementsToAppend={[{
                            navigationPath: this.props.location.pathname,
                            title: this.title,
                            translateTitle: true
                        }]} {...this.props} />
                    <h1 className="heading__Title">
                        {this.title}
                    </h1>
                    <div className="l-box--wide"><Translate>BookingOverview:Info</Translate></div>
                </div>
                <div className="booking-overview__container l-element--striped-reverse booking-overview__container--padding">
                    <div className="l-container">
                        <HeadingCollapsible
                            headingLevel={2}
                            containerCssClass="booking-overview__container--no-margin "
                            headingCssClass="heading__Level2"
                            isOpened={!this.state.filterPanelCollapsed}
                            onToggleOpenedState={() => this.setState({ filterPanelCollapsed: !this.state.filterPanelCollapsed })}>
                            <Translate>BookingOverview:Filter</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={!this.state.filterPanelCollapsed} className="l-container">
                            {this.renderFilter(translation)}
                        </UnmountClosed>
                        <div className="input-field__container">
                            <GTButton
                                additionalClassNames="btn--md booking-overview__filter-btn"
                                onClick={() => this.setState({ isDataSearching: true }, () => { this.LoadLessons() })}>
                                {idLoadSearchText}
                            </GTButton>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        this.state.isDataSearching ? <ProgressSpinner /> : this.renderLessons()
                    }
                    {this.state.errorMessage !== '' ? (
                        <div className="booking-overview__container l-box-container">
                            <NoDataFound message={Session.instance.storage.translation.GetString(this.state.errorMessage)} />
                        </div>
                    ) : ('')
                    }
                </div>
            </div>
        );
    }

    private renderFilter(translation: TranslationStorage): JSX.Element {
        return (
            <div role="table" className="booking-overview__filter-table">
                <div role="rowgroup">
                    {/* Filter User */}
                    <div role="row" className="booking-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>BookingOverview:SearchByUser</Translate>
                        </div>
                        <div role="cell">
                            <input
                                type="text"
                                aria-label={translation.GetString('BookingOverview:SearchByUser')}
                                placeholder={translation.GetString('BookingOverview:SearchByUser')}
                                value={this.state.filterUser}
                                className="input-field"
                                onChange={(e) => { this.setState({ filterUser: e.target.value }) }}
                            />
                        </div>
                    </div>
                    {/* Filter Title */}
                    <div role="row" className="booking-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>BookingOverview:SearchByTitle</Translate>
                        </div>
                        <div role="cell">
                            <input
                                type="text"
                                aria-label={translation.GetString('BookingOverview:SearchByTitle')}
                                placeholder={translation.GetString('BookingOverview:SearchByTitle')}
                                value={this.state.filterTitle}
                                className="input-field"
                                onChange={(e) => { this.setState({ filterTitle: e.target.value }) }}
                            />
                        </div>
                    </div>
                    {/* Filter Type */}
                    <div role="row" className="booking-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>BookingOverview:LessonTypeFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlItemTypeFilter"
                                data-tip={translation.GetString('BookingOverview:LessonTypeFilter')}
                                aria-label={translation.GetString('BookingOverview:LessonTypeFilter')}
                                data-for="ddlItemTypeFilter_Tooltip"
                                className="input-field"
                                onChange={(e) => this.filterItemTypeChanged(e)}
                                defaultValue={this.state.filterItemType.toString()}>
                                {this.createitemTypeFilter()}
                            </select>
                            <Tooltip id="ddlItemTypeFilter_Tooltip" />
                        </div>
                    </div>
                    <div role="row" className="booking-overview__filter-table-row">
                        {/* Filter Subtype */}
                        <div role="columnheader">
                            <Translate>BookingOverview:LessonSubTypeFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlItemSubTypeFilter"
                                data-tip={translation.GetString('BookingOverview:LessonSubTypeFilter')}
                                data-for="ddlItemSubTypeFilter_Tooltip"
                                aria-label={translation.GetString('BookingOverview:LessonSubTypeFilter')}
                                className="input-field"
                                onChange={(e) => this.filterItemSubTypeChanged(e)}
                                defaultValue={this.state.filterItemSubType.toString()}>
                                {this.createItemSubTypeFilter()}
                            </select>
                            <Tooltip id="ddlItemSubTypeFilter_Tooltip" />
                        </div>
                    </div>
                    {/* Filter Date */}
                    <div role="row" className={'booking-overview__filter-table-row'}>
                        <div role="columnheader" className="booking-Overview__columnheader-autocomplete">
                            {this.state.filterItemType !== EItemType.F2FCourse ?
                                (<Translate>BookingOverview:LastUseBetween</Translate>) :
                                (<Translate>BookingOverview:TakesPlaceBetween</Translate>)
                            }
                        </div>
                        <div role="cell" className="datePicker" >
                            <DatePicker
                                key={'txt_Filter_DateFrom'}
                                onChange={(v) => this.filterDateFromChanged(v)}
                                value={this.state.filterDateFrom}
                            />
                        </div>
                        <div role="columnheader" className="connectorText booking-Overview__columnheader-autocomplete">
                            {this.state.filterItemType !== EItemType.F2FCourse ?
                                (<Translate>BookingOverview:LastUseBetweenAnd</Translate>) :
                                (<Translate>BookingOverview:TakesPlaceBetweenAnd</Translate>)
                            }
                        </div>
                        <div role="cell" className="datePicker" >
                            <DatePicker
                                key={'txt_Filter_DateTo'}
                                onChange={(v) => this.filterDateToChanged(v)}
                                value={this.state.filterDateTo} />
                        </div>
                    </div>
                    {/* Filter Status */}
                    <div role="row" className="booking-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>BookingOverview:LessonStatusFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlLessonStatusFilter"
                                data-tip={translation.GetString('BookingOverview:LessonStatusFilter')}
                                data-for="ddlLessonStatusFilter_Tooltip"
                                aria-label={translation.GetString('BookingOverview:LessonStatusFilter')}
                                className="input-field"
                                onChange={(e) => this.filterLessonStatusChanged(e)}
                                defaultValue={this.state.filterLessonStatus.toString()}>
                                {this.createLessonStatusFilter()}
                            </select>
                            <Tooltip id="ddlLessonStatusFilter_Tooltip" />
                        </div>
                    </div>
                    {/* Filter Count */}
                    <div role="row" className="booking-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>BookingOverview:FilterResultCount</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlResultCountFilter"
                                data-tip={translation.GetString('BookingOverview:FilterResultCount')}
                                data-for="ddlResultCountFilter_Tooltip"
                                aria-label={translation.GetString('BookingOverview:FilterResultCount')}
                                className="input-field"
                                onChange={(e) => this.filterMaxResultCountChanged(e)}
                                defaultValue={this.state.filterMaxDisplayItems.toString()}>
                                {this.createResultCountFilter()}
                            </select>
                            <Tooltip id="ddlResultCountFilter_Tooltip" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderLessons(): JSX.Element[] {
        const renderedLessons: JSX.Element[] = [];
        if (this.state.lessons != null && this.state.lessons.length > 0) {
            let lessonsToRender = this.state.lessons;

            //Filter lessons according to user input in from userFilter
            lessonsToRender = lessonsToRender.filter(lesson =>
                (this.state.filterUser === '' || lesson.userName.toLocaleLowerCase().includes(this.state.filterUser.toLocaleLowerCase()))
            )

            renderedLessons.push(<BookingOverviewTable
                key={'sum_lessons'}
                lessons={lessonsToRender}
                openAnswerModal={(registrationId: number,
                    lessonId: number,
                    lessonTitle: string,
                    cancellationType: ECancellationPermission,
                    itemType: EItemType,
                    classId: number,
                    selectedEmployeeId: number) =>
                    this.openModal(registrationId, lessonId, lessonTitle, cancellationType, itemType, classId, selectedEmployeeId)}
                bossRelationCode={this.props.match.params.bossRelationCode}
                parentCatalogFolderId={0}
                parentTrainingPlanId={0}
                itemDetailCallerContextType={EItemDetailCallerContextType.BookingOverview}
                {...this.props}
            />);
        }
        else if (this.state.lessons != null && this.state.lessons.length > 0) {
            renderedLessons.push(<NoDataFound message={Session.instance.storage.translation.GetString('BookingOverview:NoDataWithFilter')} />);
        }
        return renderedLessons;
    }

    private async LoadLessons() {
        const tmp = await LessonService.instance.getLessonWithUsers(Session.instance.getUserLanguageCodeOrFallBack,
            this.state.filterUser,
            'true',
            this.state.filterItemType,
            this.state.filterItemSubType,
            this.state.filterLessonStatus,
            this.state.filterExcludeCompleted,
            this.state.filterTitle,
            DateHelper.toSqlDateString(this.state.filterDateFrom),
            DateHelper.toSqlDateString(this.state.filterDateTo),
            this.state.filterMaxDisplayItems,
            this.props.match.params.bossRelationCode);
        if (isSuccess<LessonUser[]>(tmp)) {
            this.sortLessons(tmp);
            this.setState({ lessons: tmp, errorMessage: tmp.length > 0 ? '' : 'BookingOverview:NoLessonsFound', isDataSearching: false });
        } else {
            if (tmp.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(tmp.detailedObject.subStatusCode), isDataSearching: false })
            } else {
                this.setState({ errorMessage: 'BookingOverview:BookingOverviewFailed', isDataSearching: false });
            }
        }
    }

    private sortLessons(lessons: LessonUser[]) {
        lessons.sort((a, b) => {
            const compareTitle = (aTitle: string, bTitle: string) => aTitle < bTitle ? -1 : bTitle < aTitle ? 1 : 0

            const aEnddate = (a.displayEndDate === undefined) ? "" : "" + StringHelper.dateString(a.displayEndDate);
            const bEnddate = (b.displayEndDate === undefined) ? "" : "" + StringHelper.dateString(b.displayEndDate);
            const compareEndDate = () => aEnddate < bEnddate ? -1 : bEnddate < aEnddate ? 1 : 0

            const compareUsername = (aUserName: string, bUserName: string) => aUserName < bUserName ? -1 : bUserName < aUserName ? 1 : 0

            return compareUsername(a.userName.toLocaleLowerCase(), b.userName.toLocaleLowerCase()) ||
                compareTitle(a.title.toLocaleLowerCase(), b.title.toLocaleLowerCase()) ||
                compareEndDate()
        })
    }

    private createResultCountFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const filters = globalConfig.bookingOverviewProperties.filter_numberOfResultSteps;
        filters.map(num => {
            elements.push(
                <option key={'resultCountFilter_' + num} value={num}>{num}</option>
            );
        });
        elements.push(
            <option
                key={'itemTypeFilterOption_ShowAll'}
                value={-1}>{Session.instance.storage.translation.GetString('BookingOverview:ShowAll')}</option>
        );
        return elements;
    }

    private createLessonStatusFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const abc = Object.keys(ELessonStatus);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = abc.map(k => ELessonStatus[k as any]).map(v => v as any as ELessonStatus);
        values = values.filter(val => !isNumber(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const status in values) {
            elements.push(
                <option key={'lessonStatusFilterOption_' + status}
                    value={status}>
                    {status === '0' ?
                        Session.instance.storage.translation.GetString('BookingOverview:All')
                        : Session.instance.storage.translation.GetString('LessonStatus:' + ELessonStatus[status].toString())}</option>
            );
        }
        return elements;
    }

    // the creation of the sub type filter differs form the others, because the enum numbers are not continuous
    private createItemSubTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        let filters: number[] = [];
        if (this.state.filterItemType === EItemType.F2FCourse) {
            filters = globalConfig.bookingOverviewProperties.filter_itemSubTypes_Course;
        } else if (this.state.filterItemType === EItemType.Questionnaire) {
            filters = globalConfig.bookingOverviewProperties.filter_itemSubTypes_Questionnaire;
        }

        const itemSubTypeConverter = new ItemSubTypeConverter();
        // tslint:disable-next-line:forin
        for (const type in EItemSubType) {
            const itemSubTypeNumber = itemSubTypeConverter.stringToInt(type);
            if (filters.includes(itemSubTypeNumber) || itemSubTypeNumber === 0) {
                elements.push(
                    <option key={'itemSubTypeFilterOption_' + itemSubTypeNumber} value={itemSubTypeNumber}>
                        {
                            itemSubTypeNumber === 0 ?
                                Session.instance.storage.translation.GetString('BookingOverview:All') :
                                Session.instance.storage.translation.GetString('ItemSubType:' + type)
                        }
                    </option>
                );
            }
        }
        return elements;
    }

    private createitemTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const abc = Object.keys(EItemType);
        const filters: number[] = globalConfig.bookingOverviewProperties.filter_itemTypes;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = abc.map(k => EItemType[k as any]).map(v => v as any as EItemType);
        values = values.filter(val => !isNumber(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const type in values) {
            if (filters.includes(Number.parseInt(type, 10)) || type === '0') {
                elements.push(
                    <option key={'itemTypeFilterOption_' + type} value={type}>
                        {
                            type === '0' ?
                                Session.instance.storage.translation.GetString('BookingOverview:All') :
                                Session.instance.storage.translation.GetString('ItemType:' + EItemType[type].toString())
                        }
                    </option>
                );
            }
        }
        return elements;
    }

    //#region Event handlers
    private async cancelRegistrationConfirmed(cancellationReasonId: number | undefined, cancellationReasonText: string) {
            let response: GtError | BooleanResponse | undefined = undefined;
            if (this.state.itemType === EItemType.F2FCourse) {
                response = await RegistrationService.instance.cancelRegistration(this.state.selectedRegistrationId, 
                    cancellationReasonId != null ? cancellationReasonId : 0, 
                    cancellationReasonText);
            } else if (this.state.itemType === EItemType.TrainingPlan) {
                response = await TrainingPlanService.instance.cancelRegistration(this.state.modalLessonId,
                    this.state.modalClassId,
                    Session.instance.getUserLanguageCodeOrFallBack,
                    cancellationReasonId != null ? cancellationReasonId : 0,
                    cancellationReasonText,
                    this.state.selectedEmployeeId);
            }
            if (response) {
                if (!isSuccess<BooleanResponse>(response) || !response.status) {
                    const methodName = `${this.className}:onCancelRegistration()`;
                    let errorMessage = `${methodName} - Error occured: ${response.message}`;
                    if (response instanceof GtError && response.detailedObject !== undefined) {
                        errorMessage = CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode);
                    }
                    Logger.log(this.loggerLocality, errorMessage);
                    this.setState({ errorMessage: 'ErrorMessage:CancelRegistrationFailed' })
                }
            }
            this.closeModal();
    }

    private filterMaxResultCountChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterMaxDisplayItems: Number(target.value), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterLessonStatusChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterLessonStatus: Number(target.value), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterItemSubTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterItemSubType: Number(target.value), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterItemTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        if (Number(target.value) !== EItemType.F2FCourse) {
            this.setState({
                filterItemSubType: 0,
                filterItemType: Number(target.value),
                filterRegistrationStatus: 0,
                filterTitle: '',
                autoCompleteTitlesFiltered: undefined
            });
        } else {
            this.setState({
                filterDateFrom: null,
                filterDateTo: null,
                filterItemSubType: 0,
                filterItemType: Number(target.value),
                filterLessonStatus: 0,
                filterRegistrationStatus: 0, filterTitle: '',
                autoCompleteTitlesFiltered: undefined
            });
        }
    }

    private filterDateToChanged(value: Date | null) {
        this.setState({ filterDateTo: value, filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterDateFromChanged(value: Date | null) {
        this.setState({ filterDateFrom: value, filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private closeModal(): void {
        this.setState({ modalLessonId: 0, modalLessonTitle: '', selectedRegistrationId: 0, shouldShowModal: false });
        this.LoadLessons();
    }

    private openModal(registrationId: number, lessonId: number, lessonTitle: string, cancellationType: ECancellationPermission, itemType: EItemType, classId: number, selectedEmployeeId: number): void {
        this.setState({
            modalLessonId: lessonId,
            modalLessonTitle: lessonTitle,
            selectedRegistrationId: registrationId,
            modalCancellationType: cancellationType,
            shouldShowModal: true,
            itemType,
            modalClassId: classId,
            selectedEmployeeId
        });
    }
    //#endregion

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }
}