import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class AssignSkillProfileResponse {

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('skillProfileId', Number, false)
    public skillProfileId: number = 0;

    @JsonProperty('assigned', Boolean, false)
    public assigned: boolean = false;

    @JsonProperty('isAlreadyAssigned', Boolean, false)
    public isAlreadyAssigned: boolean = false;

}

// This container is needed to be able to json convert the array
// tslint:disable-next-line: max-classes-per-file
@JsonObject
export class AssignSkillProfileResponseContainer {

    @JsonProperty('assignSkillProfileResponse', [AssignSkillProfileResponse], false)
    public assignSkillProfileResponse: AssignSkillProfileResponse[] = [];

}