import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class Trainer {
    /** Id of the corresponding trainer */
    @JsonProperty('trainerId', Number, false)
    public trainerId: number = 0;

    /** Firstname of the corresponding trainer */
    @JsonProperty('trainerFirstName', String, false)
    public trainerFirstName: string = '';

    /** Lastname of the corresponding trainer */
    @JsonProperty('trainerLastName', String, false)
    public trainerLastName: string = '';

    /** Email of the corresponding trainer */
    @JsonProperty('trainerEmail', String, false)
    public trainerEmail: string = '';
}