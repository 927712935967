import React from 'react';

import Logger from '$src/core/Logger';

import { Tab } from '$src/components/shared/tab/Tab';
import { TabLink } from '$src/components/shared/tab/TabLink';

interface IProps {
    defaultActiveTabIndex: number;
    onTabIndexChanged: (tabIndex: number) => void;
    parentHeadingLevel: number;
    tabs: Array<{tabId: string; key: string; title: string; content: JSX.Element}>;
}

interface IState {
    activeTabIndex: number;
}

// tslint:disable:object-literal-sort-keys
export class TabsContainer extends React.Component<IProps, IState> {
    protected className = 'TabsContainer';
    protected loggerLocality = 'Components.Tab';

    constructor(props: IProps) {
        super(props);
        this.state = {
            activeTabIndex: this.props.defaultActiveTabIndex || 0
        };
        this.onTabIndexChangedInternal = this.onTabIndexChangedInternal.bind(this);
    }

    public render() {
        return (
            <div className="tabs-container">
                <ul className="tabs-navigation" role="tablist">
                    {this.renderTabNavigation()}
                </ul>
                {this.renderContent()}
            </div>
        );
    }

    private onTabIndexChangedInternal(tabIndex: number): void {
        const methodName = `${this.className}:onTabSelected()`;
        Logger.log(this.loggerLocality, `${methodName} set active Tab, tabIndex=${tabIndex}.`);
        this.setState({
            activeTabIndex: tabIndex
        });
        this.props.onTabIndexChanged(tabIndex);
    }

    private renderTabNavigation(): Array<React.ReactElement<TabLink>> {

        return this.props.tabs.map((tab, index) => {
                return <TabLink
                    tabId={tab.tabId}
                    onTabSelected={this.onTabIndexChangedInternal}
                    tabIndex={index}
                    isActive={index === this.state.activeTabIndex}
                    linkClassName={'tabs-navigation__item'}
                    title={tab.title}
                    key={index}
                    parentHeadingLevel={this.props.parentHeadingLevel} />
            });
    }

    private renderContent(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        this.props.tabs.map((tab, index) => {
            const active = (this.state.activeTabIndex === index);
            elements.push(<div key={index} className={active ? 'tab-content--active' : 'hidden'}>
                    <Tab key={ tab.key } tabId={ tab.tabId } title={ tab.title } content={ tab.content } isActive={ this.state.activeTabIndex === index } />
                   </div>)
        });
        return elements;
    }
}