import Logger from '$src/core/Logger';
import F2FService from '$src/core/Services/F2FService';
import Session from '$src/core/Session';
import SessionCache from '$src/storage/cache/SessionCache';
import { F2FClassDetail } from '$src/storage/models/F2F/F2FClassDetail';
import { isSuccess } from '$src/util/Result';
import { EItemDetailCallerContextType } from './models/enums';

const CACHING_DURATION = 300;

export default class F2FClassListStorage extends SessionCache<number, F2FClassDetail[]> {
    protected className = 'F2FClassListStorage';
    protected loggerLocality = 'Storage.F2FClassListStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getF2FClassList(itemId: number, itemContext: EItemDetailCallerContextType, language?: string, parentTrainingPlanId = 0): Promise<F2FClassDetail[] | null> {
        const methodName = `${this.className}:getF2FClassList()`;

        if (language === null || language === undefined) {
            language = Session.instance.getUserLanguageCodeOrFallBack;
        }
        
        if(this.isObjectInCache(itemId)) {
            Logger.log(this.loggerLocality, `${methodName} getting ClassDetails list from cache, itemId=${itemId}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting ClassDetails list from server, itemId=${itemId}.`);
            const response = await F2FService.instance.getF2FClassList(itemId, language, itemContext, parentTrainingPlanId);
            if(isSuccess<F2FClassDetail[]>(response))
            {
                this.saveObjectToCache(itemId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get ClassDetails list from server, itemId=${itemId}`);
            }
        }
        return this.getObjectFromCache(itemId);
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}