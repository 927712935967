import React from 'react';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';
import { PerformanceSupport } from '$src/components/performanceSupport/PerformanceSupport';
import Session from '$src/core/Session';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

export class MyPerformanceSupport extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
    }

    public render() {
        const user = Session.instance.loginUser;
        return(
            <div className="">
                <div className="l-container">
                    <MenuBreadCrumb {...this.props} />
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title"><Translate>PerformanceSupport:Title</Translate></Heading>
                    <div>
                        <Translate>PerformanceSupport:SubTitle</Translate>
                    </div>
                </div>
                {user != null && <PerformanceSupport context={EItemDetailCallerContextType.MyPerformanceSupport} user={user} parentHeadingLevel={1} {...this.props}/>}
            </div>
        )
    }
}