import { Heading } from '$components/shared/Heading';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ParticipantList } from '$components/shared/ParticipantList';
import { Translate } from '$components/shared/Translate';
import Iconcancelled from '$resources/svgs/status/cancelled.svg';
import Iconaccepted from '$resources/svgs/status/confirmed.svg';
import Iconrejected from '$resources/svgs/status/rejected.svg';
import Iconrequested from '$resources/svgs/status/requested.svg';
import IconwaitingList from '$resources/svgs/status/waiting-list.svg';
import Session from '$src/core/Session';
import { ERegistrationStatus, EItemType, EItemSubType, EItemDetailCallerContextType } from '$src/storage/models/enums';
import { F2FClassDetail } from '$src/storage/models/F2F/F2FClassDetail';
import DateHelper from '$src/util/DateHelper';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { SummaryAttribute } from '$src/components/shared/Attributes/SummaryAttribute';

interface IProps {
    classId: number;
    courseTitle: string;
    courseDescription: string;
    parentHeadingLevel: number;
    registrationStatus: ERegistrationStatus;
    itemContext: EItemDetailCallerContextType;
}


interface IState {
    errorMessage: string;
    classDetails: F2FClassDetail | null;
    shouldShowModal: boolean;
}

export default class F2FRequest extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            classDetails: null,
            errorMessage: '',
            shouldShowModal: false,
        }
        this.closeModal = this.closeModal.bind(this); 
        this.openModal = this.openModal.bind(this);
    }

    public async componentDidMount() {
        const classDetails = await Session.instance.storage.classDetails.getF2FClassDetails(this.props.classId, this.props.itemContext);

        this.setState({ classDetails });
    }

    public render() {
        if (this.state.classDetails != null) {
            return (
                <React.Fragment>
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>Course:CourseTitle</Translate>
                        </Heading>
                        {this.props.courseTitle}
                    </div>
                    {this.props.courseDescription && <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>Course:CourseDescription</Translate>
                        </Heading>
                        <SummaryAttribute summary={this.props.courseDescription} />

                    </div>}

                    <div className="step-content__block">
                        {this.renderScheduleTable()}

                    </div>
                    {globalConfig.requestDetail.showParticipantList &&
                        <React.Fragment>
                            <div className="step-content__block">
                                <button className="button-link button-link--colorized-dark"
                                    onClick={() => this.openModal()}
                                    aria-label={Session.instance.storage.translation.GetString('Course:OpenParticipantList')}>
                                    <Translate>Course:OpenParticipantList</Translate>
                                </button>
                            </div>

                            <ModalPopup
                                isOpen={this.state.shouldShowModal}
                                onRequestClose={this.closeModal}
                                contentCssClass="modal__participantList">
                                <div>
                                    <ParticipantList 
                                        parentHeadingLevel={this.props.parentHeadingLevel} 
                                        scheduleId={this.props.classId}
                                        itemType={EItemType.F2FCourse}
                                        itemSubType={EItemSubType.FaceToFace} />
                                </div>
                            </ModalPopup>
                        </React.Fragment>}
                </React.Fragment>
            )
        } else {
            return <div />
        }

    }

    private renderScheduleTable(): JSX.Element {
        if (this.state.classDetails == null) {
            return (<div />)
        } else {
            const regStatus = ERegistrationStatus[this.props.registrationStatus];
            let icon = '';
            switch (this.props.registrationStatus) {
                case ERegistrationStatus.Requested:
                    icon = Iconrequested;
                    break;
                case ERegistrationStatus.Accepted:
                    icon = Iconaccepted;
                    break;
                case ERegistrationStatus.Rejected:
                    icon = Iconrejected;
                    break;
                case ERegistrationStatus.Cancelled:
                case ERegistrationStatus.ScheduleCancelled:
                    icon = Iconcancelled;
                    break;
                case ERegistrationStatus.InWaitingList:
                    icon = IconwaitingList;
                    break;
            }
            return (
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>Course:SingleDate</Translate>
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Duration</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>Course:SingleDate</Translate>
                            </div>
                            <div role="cell">
                                {DateHelper.getDatePeriod(this.state.classDetails.learningPeriodStart, this.state.classDetails.learningPeriodEnd)}
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Duration</Translate>
                            </div>
                            <div role="cell">
                                {Session.instance.storage.translation.GetString('Class:DurationInDay').Format(this.state.classDetails.classDurationInDays.toString())}
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>Class:Location</Translate>
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>Course:ClassCode</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>Class:Location</Translate>
                            </div>
                            <div role="cell">
                                {this.state.classDetails.venueCity}
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>Course:ClassCode</Translate>
                            </div>
                            <div role="cell">
                                {this.state.classDetails.classCode}
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>Course:Price</Translate>
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:RequestStatus</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>Course:Price</Translate>
                            </div>
                            <div role="cell" className="inlineFlex">
                                {this.state.classDetails.price + ' ' + this.state.classDetails.currency}
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:RequestStatus</Translate>
                            </div>
                            <div role="cell" className={`inlineFlex registrationStatus registrationStatus__${regStatus}`}>
                                <InlineSVG src={icon} />
                                <Translate>{`RegistrationStatus:${regStatus}`}</Translate>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }
}