export class KeyPressHelper {
    /* Used KeyCodes:
        13 = Enter
        27 = ESC
        32 = Space
    */
    public static isSpaceOrEnter(e: React.KeyboardEvent) {
        return (e.keyCode === 13 || e.keyCode === 32)
    }

    public static isEnter(e: React.KeyboardEvent){
        return (e.keyCode === 13)
    }

    public static isESC(e: React.KeyboardEvent){
        return (e.keyCode === 27)
    }

    public static executeWhenSpaceOrEnter(e: React.KeyboardEvent, exec: () => void) {
        if(this.isSpaceOrEnter(e)){
            return exec();
        }
    }

    public static async executePromiseWhenSpaceOrEnter(e: React.KeyboardEvent, exec: () => Promise<void>) {
        if(this.isSpaceOrEnter(e)){
            return await exec();
        }
    }

    public static executeWhenEnter(e: React.KeyboardEvent, exec: () => void){
        if(this.isEnter(e)){
            exec();
        }
    }

    public static executeWhenESC(e: React.KeyboardEvent, exec: () => void){
        if(this.isESC(e)){
            exec();
        }
    }
}
