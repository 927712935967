import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Request Object send to Server
 * Used to send all information needed in order to add a new Voucher Campaign
 */
@JsonObject
export class PaymentVoucherCampaignDeleteRequest {

    @JsonProperty('campaignId', Number, false)
    public campaignId: number = 0;
}


