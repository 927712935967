import { CookieHelper } from '$src/util/CookieHandler';
import React from 'react';
import { Translate } from './Translate';


export class Cookiebar extends React.Component<{}, {}>  {

    public cookieName: string = 'cookiebarPolicies_accepted';

    constructor(props: {}) {
        super(props);
        this.OnAcceptClickHandle = this.OnAcceptClickHandle.bind(this);
    }

    public render() {
        const cookiebar = (

            <div className="cookiebar__container">
                <div>
                    <Translate>Cookiebar:Title</Translate>
                </div>
                <div className="push">
                    <a href={globalConfig.cookiebarProperties.policyLink} target="_blank"><Translate>Cookiebar:PolicyLink</Translate></a>
                </div>
                <div>
                    <button className="button button-small" id="btnAccept" onClick={this.OnAcceptClickHandle}>
                        <Translate>{'Cookiebar:Accept'}</Translate>
                    </button>
                </div>
            </div>
        );

        if (!this.hasCookiebarBeenAccepted() && globalConfig.cookiebarProperties.cookiebar_enabled) { // Show cookiebar only when it has not been accepted
            return cookiebar;
        } else {
            return null;
        }
    }

    private OnAcceptClickHandle() {
        this.CreateCookie();
        this.forceUpdate();
    }

    private CreateCookie() {
        CookieHelper.setCookie('cookiebarPolicies_accepted', 'true');
    }

    private hasCookiebarBeenAccepted(): boolean {
        let retVal = false;
        const tempCookie = CookieHelper.getCookie(this.cookieName);
        if (tempCookie !== undefined && tempCookie !== '' && tempCookie !== null) {
            retVal = true;
        }
        return retVal;
    }
}
