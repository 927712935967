import React from 'react';

import Session from '$src/core/Session';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import { Tooltip } from '$components/shared/Tooltip';
import InlineSVG from 'react-inlinesvg';
import Settings from '$resources/svgs/toolbar/time.svg';
import { getEstimateDurationText } from '$src/util/TrainingPlanHelper';

interface IProps {
    trainingPlanElement: TrainingPlanElement;
    className: string;
    includeIcon?: boolean;
}

/**
 * @deprecated The method should not be used
 */
export class ToolbarTrainingPlanElementDuration extends React.Component<IProps, {}> {
    constructor (props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        let hoverText = tr.GetString('ItemDetail:LearningDuration');
        hoverText = hoverText + ': ' + getEstimateDurationText(this.props.trainingPlanElement.estimateDuration);
        return (
            <div aria-label={hoverText} 
                data-tip={hoverText} 
                data-for={`${this.props.trainingPlanElement.tpeId}_tpElementDurationTooltip`}
                className={this.props.className}>
                    {this.props.includeIcon && <InlineSVG src={Settings} />}
                {getEstimateDurationText(this.props.trainingPlanElement.estimateDuration)}
                <Tooltip id={`${this.props.trainingPlanElement.tpeId}_tpElementDurationTooltip`} />
            </div>
        )
    }
}
