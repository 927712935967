interface String {
    Ellipsis(maxLength: number): string;
    Format(...replacements: string[]): string;
    ChunkSubstr(size: number): string[];
}

String.prototype.Ellipsis = function(maxLength: number): string {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const text = this;
    if (text.length > maxLength) {
        let truncatedText = text.substring(0, maxLength-4);
        if (text.charAt(maxLength) === ' ') {
            return truncatedText + ' ...';
        } else {
            truncatedText = truncatedText.substring(0, truncatedText.lastIndexOf(' '));
            return truncatedText + ' ...';
        }
    } else {
        return String(text);
    }
}

if (!String.prototype.Format) {
    String.prototype.Format = function() {
      // eslint-disable-next-line prefer-rest-params
      const args = arguments;
      return this.replace(/{(\d+)}/g, function(match, index: number) { 
        return typeof args[index] !== 'undefined'
          ? args[index]
          : match
        ;
      });
    };
  }

  String.prototype.ChunkSubstr = function(size: number): string[] {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const str = this;
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array<string>(numChunks)

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size)
    }

    return chunks;
}