import React from 'react';

import { Translate } from '$components/shared/Translate';

interface IProps {
    errorMessage: string | null | undefined;
    appearance?: 'input-message' | 'single-line' | 'box' | undefined;
}

export class ErrorMessage extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        if (!this.props.errorMessage) {
            return null;
        }

        if (this.props.appearance === undefined || this.props.appearance === 'input-message') {
            return <p className="error input-message error__input-message">
                <Translate>{this.props.errorMessage}</Translate>
            </p>
        }
        
        if(this.props.appearance != null && this.props.appearance === 'single-line') {
            return <span className="error error__signle-line">
                <Translate>{this.props.errorMessage}</Translate>
            </span>
        }

        return <div className="error error__box">
            <Translate>{this.props.errorMessage}</Translate>
        </div>
    }
}