import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { filterListData } from '$src/util/UserHelper';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Group } from './Group';

@JsonObject
export class User {
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('guid', String, false)
    public guid: string = '';

    @JsonProperty('domainName', String, false)
    public domainName: string = '';

    @JsonProperty('domainId', Number, false)
    public domainId: number = 0;

    @JsonProperty('username', String, false)
    public username: string = '';

    @JsonProperty('altLogin', String, false)
    public altLogin: string = '';

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    @JsonProperty('email', String, false)
    public email: string = ''; 

    @JsonProperty('email2', String, false)
    public email2: string = '';     

    @JsonProperty('deputy', Boolean, false)
    public deputy: boolean = false;     

    @JsonProperty('birthdate', DateTimeConverter, false)
    public birthdate: Date = new Date('1899-12-31');  // Self-registration: a date before 1.1.1900 means that the user has not selected a date
    
    @JsonProperty('nativeLanguageId', Number, false)
    public nativeLanguageId: number = 0;    

    @JsonProperty('company', String, false)
    public company: string = ''; 

    @JsonProperty('department', String, false)
    public department: string = '';     

    @JsonProperty('address1', String, false)
    public address1: string = '';     

    @JsonProperty('address2', String, false)
    public address2: string = '';     

    @JsonProperty('city', String, false)
    public city: string = '';     

    @JsonProperty('zipCode', String, false)
    public zipCode: string = '';    

    @JsonProperty('countryId', Number, false)
    public countryId: number = 0;   

    @JsonProperty('countryCode', String, false)
    public countryCode: string = '';   

    @JsonProperty('phoneNumber', String, false)
    public phoneNumber: string = '';  

    @JsonProperty('mobileNumber', String, false)
    public mobileNumber: string = '';   

    @JsonProperty('videoConfId', String, false)
    public videoConfId: string = '';    

    @JsonProperty('timeZone', String, false)
    public timeZone: string = '';    

    @JsonProperty('info', String, false)
    public info: string = '';   
    
    @JsonProperty('notificationLanguage', String, false)
    public notificationLanguage: string = '';   
     
    @JsonProperty('notificationLanguageId', Number, false)
    public notificationLanguageId: number = 0;    

    @JsonProperty('text1', String, false)
    public text1: string = '';      

    @JsonProperty('text2', String, false)
    public text2: string = '';   

    @JsonProperty('text3', String, false)
    public text3: string = '';   

    @JsonProperty('text4', String, false)
    public text4: string = '';   

    @JsonProperty('text5', String, false)
    public text5: string = '';     
    
    @JsonProperty('attribute1', Number, false)
    public attribute1: number = 0;  

    @JsonProperty('attribute2', Number, false)
    public attribute2: number = 0;   

    @JsonProperty('attribute3', Number, false)
    public attribute3: number = 0;  

    @JsonProperty('attribute4', Number, false)
    public attribute4: number = 0;  

    @JsonProperty('attribute5', Number, false)
    public attribute5: number = 0;

    @JsonProperty('isPublic', Boolean, true)
    public isPublic: boolean = false;

    @JsonProperty('isActive', Boolean, true)
    public isActive: boolean = true;

    @JsonProperty('roles', [String], false)
    public roles: string[] =[];

    @JsonProperty('groups', [Group], false)
    public groups: Group[] =[];

    @JsonProperty('isTrainer', Boolean, true)
    public isTrainer: boolean = true;

    @JsonProperty('canSelfRegisterToCourse', Boolean, true)
    public canSelfRegisterToCourse: boolean = true;

    @JsonProperty('canSelfRegisterToTP', Boolean, true)
    public canSelfRegisterToTP: boolean = true;

    @JsonProperty('canSelfUnregisterFromTP', Boolean, true)
    public canSelfUnregisterFromTP: boolean = true;

    @JsonProperty('canSelfUnregisterOfCourse', Boolean, true)
    public canSelfUnregisterOfCourse: boolean = true;

    @JsonProperty('hasOutdatedTermsAndConditions', Boolean, true)
    public hasOutdatedTermsAndConditions: boolean = false;

    @JsonProperty('hasSsoId', Boolean, true)
    public hasSsoId: boolean = false;
    
    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }

    public get fullNameStartingByLastName(): string {
        return `${this.lastName} ${this.firstName}`
    }

    [key: string]: string | number | boolean | Date | DateTimeConverter | string[] | Group[] // | undefined

    public get groupsJoined(): string {
        return this.groups.filter(filterListData).map(g => g.groupName.includes('\\') ? g.groupName.split('\\')[1] : g.groupName ).join(', ');
    }  
}

// tslint:disable-next-line:max-classes-per-file
@JsonObject
export class Auth {
    @JsonProperty('jwtSecurityToken', String, false)
    public jwtSecurityToken: string = '';

    @JsonProperty('refreshToken', String, false)
    public refreshToken: string = '';

    @JsonProperty('srvTime', String, false)
    public srvTime: string = '';
}