import React from "react";
import { Tooltip } from "$components/shared/Tooltip";
import InlineSVG from "react-inlinesvg";
import { EColor } from "$src/storage/models/enums";

/**
 * The GT Icon and Text Props
 */
export interface IIconAndTextProps {
    /**
     * Component unique id
     */
    id: string;

    /**
     * Aria label for web accessibility
     */
    ariaLabel: string;

    /**
     * Icon string
     */
    icon: string;

    /**
     * **Optional** Height of the icon
     */
    iconHeight?: number;

    /**
     * **Optional** Width of the icon
     */
    iconWidth?: number;

    /**
     * **Optional** Text to dispaly with the icon, on the right side
     */
    iconText?: string;

    /**
     * **Optional** Color of the text, if and text (iconText) is provided
     */
    textColor?: EColor;

    /**
     * **Optional** Additional css class
     */
    cssClass?: string;

    /**
     * **Optional** Text to display in the tool tip. If empty no tool tip will be displayed
     */
    tooltipText?: string;

    /**
     * **Optional**The Button children
     */
    children?: JSX.Element | string;

}


/**
 * Global Teach Icon and Text Component
 */
export default function IconAndText(props: IIconAndTextProps) {
    const iconHeight = props.iconHeight || 25;
    const iconWidth = props.iconWidth || 25;
    let cssClass = 'icon-and-text';
    let cssClassText = 'icon-and-text__text';

    switch (props.textColor) {
        case EColor.BaseColor:
            cssClassText += ' icon-and-text__text-color--base'
            break;
        case EColor.Highlight:
            cssClassText += ' icon-and-text__text-color--highlight'
            break;
        case EColor.HighlightDark:
            cssClassText += ' icon-and-text__text-color--highlight-dark'
            break;
        case EColor.Error:
            cssClassText += ' icon-and-text__text-color--error'
            break;
    }

    cssClassText += ' text-overflow--ellipsis';

    if (props.cssClass != null) {
        cssClass += ' ' + props.cssClass;
    }

    return (
        <div
            key={`IconAndText_${props.id}`}
            className={cssClass}
            aria-label={props.ariaLabel}
            data-tip={props.tooltipText}
            data-for={`TT_IconAndText_${props.id}`}
            role={props.iconText != null ? undefined : true && "img"} // Add Aria Role Img if no text was given
        >
            <div><InlineSVG src={props.icon} height={iconHeight} width={iconWidth} /></div>
            {props.iconText && <div className={cssClassText}>{props.iconText}</div>}
            {props.tooltipText &&
                <Tooltip id={`TT_IconAndText_${props.id}`} html={true} delayShow={500} />
            }
            {props.children && props.children}
        </div>
    );
}