import { JsonObject, JsonProperty } from 'json2typescript';
import { PaymentVoucherCode } from '$src/storage/models/Payment/PaymentVoucherCode';

/**
 * Object used to respresent a Voucher. Used for Export of Voucher Data
 */
export class Voucher{
    public pvcrId: number = 0;

    public voucherCode: string = '';

    public campaignName: string = '';

    public expireTime: string = '';

    public discount: number = 0;

    public usedBy: string = '';

    public usedAt: string = '';

    public voucherStatus: string = '';

    public voucherCodeId: number = 0;

    public isRevoked: boolean = false;
}

/**
 * Response Object used to get all Campaigns with their Vouchers from the Server.
 * Includes also all Voucher Codes for each campaign
 */
@JsonObject
export class PaymentVoucherCampaignResponse {
    /** Id of campaign */
    @JsonProperty('id', Number, false)
    public id: number = 0;

    /** domainId of campaign */
    @JsonProperty('domainId', Number, false)
    public domainId: number = 0;

    /** title of campaign */
    @JsonProperty('title', String, false)
    public title: string = '';

    /** expireTime of campaign */
    @JsonProperty('expireTime', String, false)
    public expireTime: string = '';

    /** discount of campaign */
    @JsonProperty('discount', Number, false)
    public discount: number = 0;

    /** isAbsolute of campaign */
    @JsonProperty('isAbsolute', Boolean, false)
    public isAbsolute: boolean = false;

    /** isoCurrencyCode of campaign */
    @JsonProperty('isoCurrencyCode', String, false)
    public isoCurrencyCode: string = '';
    
    /** createdAt of campaign */
    @JsonProperty('createdAt', String, false)
    public createdAt: string = '';

    /** createdBy of campaign */
    @JsonProperty('createdBy', String, false)
    public createdBy?: string = '';

    /** vouchers of campaign */
    @JsonProperty('vouchers', [PaymentVoucherCode], false)
    public vouchers: PaymentVoucherCode[] = [];

    /** Item/Course Restrictions of Campaign */
    @JsonProperty('paymentVoucherCampaignRestrictions', String, false)
    public paymentVoucherCampaignRestrictions: string = "";

    //** Name of the attribute, the restrictedItemIds values should has been checked against */
    @JsonProperty('itemAttributeName', String, false)
    public itemAttributeName: string = "";

    //** Number of days the items covered by the voucher can be accessed – starting from the day of redemption (see also PaymentVoucherItemLifecycleCancellationService) */
    @JsonProperty('itemLifecycleInDays', Number, false)
    public itemLifecycleInDays: number = 0;

    //** Array of metadata key/value pair data in JSON format, that we should store for further use, like UserIDs etc */
    @JsonProperty('metaData', String, false)
    public metaData: string = "";

    /**
     * Flag for kendo grid component to show the detail row component
     * @type {boolean}
     * @memberof PaymentVoucherCampaignResponse
     */
     public isGridDetailExpanded: boolean;

     /** Get the number of vouchers */
     public get numberOfVouchers(): number {
         return this.vouchers.length;
     }
}

/**
 * 
 */
@JsonObject
export class RestrictionItems {
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('itemTitle', String, false)
    public itemTitle: string = "";
}

/**
 * Gets all Items a campaign can be restricted to
 */
@JsonObject
export class PaymentVoucherCampaignItemsToRestrictResponse {
    @JsonProperty('restrictionItems', [RestrictionItems], false)
    public restrictionItems: RestrictionItems[] | null = null;
}




