
import React, { useEffect, useState } from 'react';
import { FileSharingTag } from '$src/storage/models/FileSharing/FileSharingTag';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { FileSharingDocument } from '$src/storage/models/FileSharing/FileSharingDocument';

import FileSharingService from '$src/core/Services/FileSharingService';
import { isSuccess } from '$src/util/Result';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import GTIconButton from '$components/shared/Atoms/GTIconButton';
import InlineSVG from 'react-inlinesvg';
import CancelIcon from '$resources/svgs/misc/cancel.svg';
import FinishEditIcon from '$resources/svgs/confirmation/Confirm.svg';
import Session from '$src/core/Session';

interface IProps {
    availableFileSharingTags: FileSharingTag[];
    document: FileSharingDocument;
    isReadonly: boolean;
    assignmentId: number;
    tPlanId: number;
    scheduleId: number;
    itemId: number;
    onReload?: () => void;
}

export default function FileSharingDocumentTags(props: IProps) {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [documentTags, setDocumentTags] = useState<FileSharingTag[]>();
    const [availableTags, setAvailableTags] = useState<FileSharingTag[]>();

    useEffect(() => {
        setAvailableTags(props.availableFileSharingTags);
        setDocumentTags(props.document.fileSharingTags)
    }, [])

    useEffect(() => {
        if(documentTags) {
            let availableTags = props.availableFileSharingTags;
            availableTags = availableTags.filter(tag => documentTags.filter(docTag => docTag.id == tag.id).length == 0);
            setAvailableTags([...availableTags]);
        }
    }, [documentTags])

    async function onAddTag(tagName: string) {
        const selectedTag = props.availableFileSharingTags.filter(t => t.tagName == tagName)[0];
        if(selectedTag) {
            await FileSharingService.instance.addTagToDocument(props.itemId, props.document.id, { id: selectedTag.id }, props.assignmentId, props.tPlanId, props.scheduleId)
                .then(resp => {
                    if (isSuccess<BooleanResponse>(resp)) {
                        setErrorMessage('')
                        if (!resp.status) {
                            setErrorMessage('FileSharing:FailedToAddTag');
                        } else {
                            documentTags!.push(selectedTag);
                            setDocumentTags([...documentTags!]);
                        }
                    } else {
                        setErrorMessage('FileSharing:FailedToAddTag');
                    }
                });
        }
    }

    async function onDeleteTag(tagId: number) {
        const deletedTag = props.document.fileSharingTags.filter(t => t.id== tagId)[0];
        await FileSharingService.instance.removeTagFromDocument(props.itemId, props.document.id, { id: tagId }, props.assignmentId, props.tPlanId, props.scheduleId)
            .then(resp => {
                if (isSuccess<BooleanResponse>(resp)) {
                    setErrorMessage('')
                    if (!resp.status) {
                        setErrorMessage('FileSharing:FailedToRemoveTag');
                    } else {
                        documentTags!.splice(documentTags!.indexOf(deletedTag), 1);
                        setDocumentTags([...documentTags!]);
                    }
                } else {
                    setErrorMessage('FileSharing:FailedToRemoveTag');
                }
            });
    }

    function renderTags(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        documentTags?.map(tag => {
            elements.push(
                <li key={tag.id} className={props.isReadonly ? 'isReadonly' : ''}>
                    {tag.tagName}
                    {!props.isReadonly &&
                        <GTIconButton
                            id={`DeleteTag_${tag.id}`}
                            ariaLabel={Session.instance.storage.translation.GetString('FileSharing:DeleteTag').Format(tag.tagName)}
                            tooltipText={Session.instance.storage.translation.GetString('FileSharing:DeleteTag').Format(tag.tagName)}
                            onClick={() => onDeleteTag(tag.id)}>
                            <InlineSVG src={CancelIcon} className="item-detail__file-sharing__tag-list__delete-icon" />
                        </GTIconButton>}
                </li>
            )
        })

        return elements;
    }

    return (
        <div className="item-detail__file-sharing__tag-management" key={`TagManagement_${props.document.id}`} id={`TagManagement_${props.document.id}`}>
            {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
            {!props.isReadonly &&
                <div className="item-detail__file-sharing__tag-edit">
                    <AutoComplete
                        data={availableTags}
                        label={Session.instance.storage.translation.GetString('FileSharing:AddFileSharingTag')}
                        suggest={false}
                        textField="tagName"
                        onClose={(e) => onAddTag(e.target.value)}
                    />
                    <GTIconButton
                        id={`FinishEdit_${props.document.id}`}
                        ariaLabel={Session.instance.storage.translation.GetString('FileSharing:FinishEdit')}
                        tooltipText={Session.instance.storage.translation.GetString('FileSharing:FinishEdit')}
                        onClick={() => props.onReload && props.onReload()}>
                        <InlineSVG src={FinishEditIcon} />
                    </GTIconButton>
                </div>
            }
            <ul className="item-detail__file-sharing__tag-list">
                {renderTags()}
            </ul>
        </div>

    )
}