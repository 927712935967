import React from 'react';

import { Heading } from '$src/components/shared/Heading';
import Session from '$src/core/Session';
import PerformanceSupportPanelBox, { EPerformanceSupportPanelType } from '$src/components/PerformanceSupport/PerformanceSupportPanelBox';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import { Translate } from '$src/components/shared/Translate';
import { NavLink } from 'react-router-dom';
import { PerformanceSupportSkills } from '$src/storage/PerformanceSupportStorage';

interface IProps {
    maxItems: number;
    expiringInDaysFromNow: number;

    parentHeadingLevel: number;
    performanceSupportSkills: PerformanceSupportSkills;
}


export default function PerformanceSupportPanel(props: IProps) {
    const userId = Session.instance.loginUser?.id || 0;

    return (
        <div className="l-box-container l-box-container--column-flex--align-start">

            {props.performanceSupportSkills.expiredTargetSkills
                .filter((skills, index, self) => self.findIndex(t => t.SkillId === skills.SkillId) === index)
                .filter((self) => (self.MayBeExpired != true))
                .length > 0 &&
                <>
                    <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level2" >
                        <Translate>PerformanceSupport:ExpiredTargetSkillsTitle</Translate>
                    </Heading>
                    <ul className="performanceSupportPanel__skill-list">
                        {props.performanceSupportSkills.expiredTargetSkills
                            .filter((skills, index, self) => self.findIndex(t => t.SkillId === skills.SkillId) === index)
                            .filter((self) => (self.MayBeExpired != true))
                            .slice(0, props.maxItems !== 0 ? props.maxItems : props.performanceSupportSkills.expiredTargetSkills.length)
                            .map(expiredTargetSkill => {
                                return <PerformanceSupportPanelBox {...props} key={expiredTargetSkill.SkillId} skill={expiredTargetSkill} skillType={EPerformanceSupportPanelType.Expired} userId={userId} />
                            })}
                    </ul>
                </>
            }

            {props.performanceSupportSkills.soonExpiringTargetSkills
                .filter((skills, index, self) => self.findIndex(t => t.SkillId === skills.SkillId) === index)
                .filter((self) => (self.MayBeExpired != true))
                .length > 0 &&
                <>
                    <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level2" >
                        {Session.instance.storage.translation.GetString('PerformanceSupport:SoonExpiringTargetSkillsTitle').Format(props.expiringInDaysFromNow.toString())}
                    </Heading>
                    <ul className="performanceSupportPanel__skill-list">
                        {props.performanceSupportSkills.soonExpiringTargetSkills
                            .filter((skills, index, self) => self.findIndex(t => t.SkillId === skills.SkillId) === index)
                            .filter((self) => (self.MayBeExpired != true))
                            .slice(0, props.maxItems !== 0 ? props.maxItems : props.performanceSupportSkills.soonExpiringTargetSkills.length)
                            .map(soonExpiringTargetSkill => {
                                return <PerformanceSupportPanelBox {...props} key={soonExpiringTargetSkill.SkillId} skill={soonExpiringTargetSkill} skillType={EPerformanceSupportPanelType.Expiring} userId={userId} />
                            })}
                    </ul>
                </>
            }

            {props.performanceSupportSkills.missingLevelTargetSkills
                .filter((skills, index, self) => self.findIndex(t => t.SkillId === skills.SkillId) === index)
                .length > 0 &&
                <>
                    <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level2" >
                        <Translate>PerformanceSupport:MissingLevelTargetSkillsTitle</Translate>
                    </Heading>
                    <ul className="performanceSupportPanel__skill-list">
                        {props.performanceSupportSkills.missingLevelTargetSkills
                            .filter((skills, index, self) => self.findIndex(t => t.SkillId === skills.SkillId) === index)
                            .slice(0, props.maxItems !== 0 ? props.maxItems : props.performanceSupportSkills.missingLevelTargetSkills.length)
                            .map(missingLevelTargetSkill => {
                                return <PerformanceSupportPanelBox {...props} key={missingLevelTargetSkill.SkillId} skill={missingLevelTargetSkill} skillType={EPerformanceSupportPanelType.MissingLevel} userId={userId} />
                            })}
                    </ul>
                </>
            }

            {props.performanceSupportSkills.expiredTargetSkills.length === 0 && props.performanceSupportSkills.soonExpiringTargetSkills.length === 0 && props.performanceSupportSkills.missingLevelTargetSkills.length === 0 &&
                <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceSupport:NoTargetSkillsFound')} />
            }
            <div className="performanceSupportPanel__actions">
                <div className="performanceSupportPanel__action">
                    <NavLink to="/myPerformanceSupport" className="btn--md btn--primary">
                        <Translate>PerformanceSupport:ToMyPerformanceSupportButton</Translate>
                    </NavLink>
                </div>
                <div className="performanceSupportPanel__action">
                    <NavLink to="/myskills" className="btn--md btn--primary">
                        <Translate>PerformanceSupport:ToMySkillsButton</Translate>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}