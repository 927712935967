import Moment from 'moment';

export class FieldValidators {

    public static Required(value: string, isRequired: boolean | undefined): string {
        if (isRequired) {
            if (value.length === 0) {
                return 'ErrorMessage:Required';
            }
        }
        return '';
    }

    public static RegExpression(value: string, regExpression: RegExp | undefined): string {
        if (regExpression !== undefined && value.length > 0) {
            const regExisValid: RegExpMatchArray | null = value.match(regExpression);
            if (regExisValid === null) {
                return 'ErrorMessage:RegExpression';
            }
        }
        return '';
    }

    public static DateValidator(value: Date | null): string {
        if (value === null) {
            return ''
        }

        if (!Moment(value).isValid() || Moment(value).year() < 1900) {
            return 'ErrorMessage:DateInvalid';
        }

        return '';
    }

    // Date must be valid and less or equal than today
    public static DateValidatorToday(value: Date | null): string {
        if (value === null) {
            return ''
        }
        
        if (!Moment(value).isValid() || Moment(value).year() < 1900) {
            return 'ErrorMessage:DateInvalid';
        }
        const today = new Date()
        if( value > today) {
            return 'ErrorMessage:DateToday';
        }

        return '';
    }

    public static ConfirmPasswordMatching(pw: string | null, confirmPw: string | null) {
        let msg: string = '';
        if ((pw === null || confirmPw === null) || pw !== confirmPw) {
            msg = 'ErrorMessage:ConfirmPasswordNotMatching';
        }
        return msg;
    }
}

