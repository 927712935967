

import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Glossar } from '$src/storage/models/Glossary/Glossar';
import { Glossary } from '$src/storage/models/Glossary/Glossary';
import { GlossaryTermDetail } from '$src/storage/models/Glossary/GlossaryTermDetail';
import GtError from '$src/util/GtError';
import ServiceClient from '$src/core/ServiceClient';
import Session from '$src/core/Session';
import { GlossaryTermErrorInsertRequest } from '$src/storage/models/RequestObjects/GlossaryTermErrorInsertRequest';


/**
 * GlossaryService provides all Service Methods for the Glossary Functionality
 */
export default class GlossaryService extends ServiceClient {
    protected static _instance: GlossaryService | null = null;

    protected className = 'GlossaryService';
    protected loggerLocality = 'GlossaryService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): GlossaryService {
        return this._instance || (this._instance = new this());
    }


    /**
     * Check if the Item has a glossary attached
     * @param itemId 
     * @param language 
     */
    public async isGlossaryAssignedToItem(itemId: number): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        response = await this.get<BooleanResponse>('glossary/isGlossaryAssignedToItem', BooleanResponse, undefined, undefined, itemId.toString());
        return response;
    }

    public async getGlossariesForItem(itemId: number, language: string): Promise<Glossary[] | GtError> {
        let response: Glossary[] | GtError;
        response = await this.get<Glossary[]>('glossary/getGlossariesForItem', Glossary, undefined, [{ name: 'language', value: language }], itemId.toString());
        return response;
    }

    public async getMyGlossaries(language: string, searchText: string): Promise<Glossary[] | GtError> {
        let response: Glossary[] | GtError;
        response = await this.get<Glossary[]>(`glossary/getMyGlossaries`, Glossary, undefined, [{ name: 'language', value: language }, {name: 'searchText', value: searchText}]);
        return response;
    }

    public async getGlossar(glossarId: number): Promise<Glossar | GtError> {
        let response: Glossar | GtError;
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        response = await this.get<Glossar>('glossary/getGlossary', Glossar, undefined, [{ name: 'language', value: language }], glossarId.toString());
        return response;
    }

    public async setIsViewed(termSummaryId: number): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        response = await this.post<BooleanResponse>(`glossary/setViewed/${String(termSummaryId)}`, {}, BooleanResponse, undefined, undefined);
        return response;
    }

    public async setBookmarked(termSummaryId: number, isBookmarked: boolean): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        response = await this.post<BooleanResponse>(`glossary/setBookmark/${String(termSummaryId)}`, { isBookmarked }, BooleanResponse, undefined, undefined);
        return response;
    }

    /**
     * get a glossary Term details with the glossarterm id
     *
     * @param {number} glossarTermId
     * @return {*}  {(Promise<GlossaryTermDetail | GtError>)}
     * @memberof GlossaryService
     */
    public async getGlossarieTermDetail(glossarTermId: number): Promise<GlossaryTermDetail | GtError> {
        let response: GlossaryTermDetail | GtError;
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        response = await this.get<GlossaryTermDetail>('glossary/getGlossaryTerm', GlossaryTermDetail, undefined, [{ name: 'language', value: language }], glossarTermId.toString());
        return response;
    }

    /**
     * creates a error message for a glossary term
     *
     * @param {number} glossarTermId
     * @param {GlossaryTermErrorInsertRequest} request
     * @return {*}  {(Promise<BooleanResponse | GtError>)}
     * @memberof GlossaryService
     */
    public async createErrorMessage(glossarTermId: number, request: GlossaryTermErrorInsertRequest): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        response = await this.post<BooleanResponse>(`glossary/createErrorMessage/${String(glossarTermId)}`, request, BooleanResponse, undefined, [{ name: 'language', value: language }]);
        return response;
    }

}