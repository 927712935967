import React, { useEffect, useState } from 'react';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import SearchService from '$src/core/Services/SearchService';
import Session from '$src/core/Session';
import { SearchFilter } from '$src/storage/models/search/elk/SearchFilter';
import { SearchResponse } from '$src/storage/models/search/elk/SearchResponse';
import { SearchResult } from '$src/storage/models/search/elk/SearchResult';
import { isSuccess } from '$src/util/Result';
import SearchFilters from '$components/search/elk/SearchFilters';
import SearchPanel from '$components/search/elk/SearchPanel';
import SearchResults from '$components/search/elk/SearchResults';
import { Translate } from '$src/components/shared/Translate';
import { InputSelect } from '$src/components/shared/InputSelect';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Heading } from '$src/components/shared/Heading';
import SearchByKeywords from '$components/search/elk/SearchByKeywords';
import { useHistory, useLocation } from 'react-router';

export default function SearchPage() {
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const [searchFilter, setSearchFilter] = useState<SearchFilter>(new SearchFilter());
    const [currentSearchValue, setCurrentSearchValue] = useState<string>("");
    const [currentSortOrder, setCurrentSortOrder] = useState<string>("Relevance");
    const history = useHistory();
    const location = useLocation();

    function onSearch(value: string) {
        SearchService.instance.getSearchItemsv2(Session.instance.getUserLanguageCodeOrFallBack, value, searchFilter, currentSortOrder).then((result) => {
            if (isSuccess<SearchResponse>(result)) {
                setSearchResults(result.searchResults);
                history.push({
                    pathname: '/search',
                    search: '?value=' + encodeURIComponent(value)
                });
            } else {
                setSearchResults([]);
            }
        });
        setCurrentSearchValue(value);
    }

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search);
        const val = parameters.get('value');
        if (val != null) {
            if(val != currentSearchValue) {
                onSearch(val)
            }
        } 
    }, [])

    useEffect(() => {
        if (currentSearchValue != "") {
            onSearch(currentSearchValue);
        }
    }, [currentSortOrder]); 

    function onFiltersChanged(filters: SearchFilter) {
        setSearchFilter(filters);
    }

    function onSearchSortOrderChanged(id: string, code: string): void {
        setCurrentSortOrder(code);
    }

    const searchSortOrderAttributes: InputAttributes = {
        attributeValueType: 72,
        class: 'search-sortorder',
        editMode: true,
        hasFocus: false,
        id: 'soSelect',
        initialValidation: false,
        isReadOnly: false,
        isRequired: false,
        label: '',
        labelClassName: 'screen-reader-only',
        maxLength: 200,
        value: currentSortOrder,
        placeholder: ''
    }

    return (
        <div>
            <div className="l-container">
                <MenuBreadCrumb
                    breadCrumbElementsToAppend={[{ title: 'Search:Title', navigationPath: location.pathname, translateTitle: true }]} />
            </div>
            <div className="l-container">
                <SearchPanel classPrefix={"search-panel"} onSearch={(value) => onSearch(value)} searchValue={currentSearchValue} />
            </div>
            {!globalConfig.searchProperties.useLegacySearch &&
                <div className="l-container">
                    <SearchByKeywords onSearch={(value) => onSearch(value)} />
                </div>
            }
            <div className="l-container">
                <div className="search-filter__container">
                    <div className="search-filter__filter-row">
                        <div className="search-filter__filter-row-title">
                            <Heading headingLevel={3} cssClass="search-filter__filter-row-title-heading"><Translate>Search:SortOrder</Translate></Heading>
                        </div>
                        <div className="search-filter__filter-row-filter">
                            <InputSelect attributes={searchSortOrderAttributes} onChange={(id, val, errMessage, code) => onSearchSortOrderChanged(id, code)} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-container">
                <SearchFilters onFiltersChanged={(value) => onFiltersChanged(value)} />
            </div>
            <div className="l-container">
                <SearchResults searchResults={searchResults} onSearch={(e) => onSearch(e)} searchValue={currentSearchValue} />
            </div>
        </div>
    );
}