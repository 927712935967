import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import ClassSearch from '$components/PaymentBooking/ClassSearch';
import UserSearch from '$components/PaymentBooking/UserSearch';
import Comments from '$components/PaymentBooking/Comments';
import { usePaymentBookingValidation } from '$components/paymentBooking/usePaymentBookingValidation';
import PaymentBookingValidation from '$components/paymentBooking/PaymentBookingValidation';

import { PaymentClassSearchResponse } from '$src/storage/models/Payment/PaymentClassSearchResponse';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import { EItemType } from '$src/storage/models/enums';
import { PaymentUserSearchResponse } from '$src/storage/models/Payment/PaymentUserSearchResponse';
import ShoppingBasketItemUser from '$src/storage/models/ShoppingBasket/ShoppingBasketItemUser';
import Session from '$src/core/Session';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import PriceInfo from '$src/storage/models/ShoppingBasket/PriceInfo';

interface IMatchParams {
    /** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
    bossRelationCode: string;
}

type IProps = RouteComponentProps<IMatchParams>

/**
 * Page for payment admins or managers to book users to courses and pay for it
 * @param props RouteComponentProps
 * @returns Hook - complete page
 */
export default function PaymentBooking(props: IProps) {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const history = useHistory();

    const [selectedClass, setSelectedClass] = useState<PaymentClassSearchResponse | undefined>(undefined);
    const [selectedUsers, setSelectedUsers] = useState<PaymentUserSearchResponse[]>([]); // each selected user
    const [validatedUsers, setValidatedUsers] = useState<PaymentUserSearchResponse[]>([]); // only valid user of selection
    const [comments, setComments] = useState<string>('');
    const [showComment, setShowComment] = useState<boolean>(false);
    const [bossRelationCode, setBossRelationCode] = useState<string | undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [validate, validationMessage] = usePaymentBookingValidation();

    const defaultTeamMatch = 'default';

    function getSectionConfigurationByBossRelationCode(bossRelationCode: string) {
        const defaultBossRelation = Session.instance.getLoggedInUserBossrelations().find(r => r.isDefault);
        if (bossRelationCode === defaultTeamMatch && defaultBossRelation != null) {
            bossRelationCode = defaultBossRelation.bossTypeCode;
        }
        let sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
            item => item.id.toLocaleLowerCase() === bossRelationCode.toLocaleLowerCase());
        if (sectionsConfig == null) {
            sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
                item => item.id.toLocaleLowerCase() === defaultTeamMatch);
        }
        sectionsConfig?.sectionsOrderAndDisplay.sort((n1, n2) => n1.order - n2.order);
        return sectionsConfig;
    }

    /** Validate free seats & one company restriction */
    useEffect(() => {
        validate(selectedClass, validatedUsers, bossRelationCode ? false : true);
        setErrorMessage(validationMessage);
    });

    /** Get the conf for the myTeam */
    useEffect(() => {
        const bossRelationCodeTmp = props.match.params.bossRelationCode;

        if (bossRelationCodeTmp) {
            const conf = getSectionConfigurationByBossRelationCode(bossRelationCodeTmp);
            setBossRelationCode(bossRelationCodeTmp);
            setShowComment(conf?.payComment ? true : false);
        } else {
            setBossRelationCode(undefined);
            setShowComment(true);
        }

        setIsLoaded(true);
    }, [isLoaded]);

    /** Add and then navigate to basket */
    function addToBasket() {
        if (!selectedClass || !validatedUsers) {
            return;
        }

        const users: ShoppingBasketItemUser[] = validatedUsers.map((user) => {
            return {
                userId: user.userId,
                display: user.firstName + ' ' + user.lastName + (user.company ? ' (' + user.company + ')' : ''),
                company: user.company,
                price: new PriceInfo(selectedClass.price),
            } as ShoppingBasketItemUser;
        });

        const item: ShoppingBasketItem = {
            sId: selectedClass.itmItemId,
            itemId: selectedClass.itmId,
            classId: selectedClass.classId,
            classCode: selectedClass.clsCode,
            itemType: EItemType.F2FCourse,
            bookUsers: users,
            title: selectedClass.course,
            additionalInfo: comments,
            f2fClassIdsForTP: [],
            finalPrice: new PriceInfo(selectedClass.price * users.length),
            currency: selectedClass.currency
        };

        // check if class is already in basket - then distinct merge the bookUsers
        const doublet = ShoppingBasketStorage.instance.shoppingBasketContent.shoppingBasketItems.filter(p => p.classId == item.classId)
        if (doublet.length > 0) {
            const distinct = [...doublet[0].bookUsers!, ...item.bookUsers!].filter((value, index, array) => {
                return array.findIndex(p => p.userId === value.userId) == index;
            });
            item.bookUsers = distinct;
            ShoppingBasketStorage.instance.removeItemFromBasket(item.itemId, item.classId);
        }

        ShoppingBasketStorage.instance.addItemToBasket(item);
        history.push('/shoppingBasketContent');
    }

    function getBcElements() {

        if (bossRelationCode === undefined) {
            return undefined;
        }

        const bcElements: BreadCrumbElement[] = [];
        bcElements.push(
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/myTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:MyTeam')
            },
            {
                navigationPath: `/paymentBookingMyTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:PaymentBooking')
            }
        );
        return bcElements;
    }

    return (
        <>
            <div className="l-container payment-booking">
                <div className="">
                    <MenuBreadCrumb breadCrumbElementsToAppend={getBcElements()} />

                    <Heading headingLevel={1} cssClass="heading__Title">
                        <Translate>Payment:Booking</Translate>
                    </Heading>

                    <div>
                        <Translate>PaymentBooking:SubTitle</Translate>
                    </div>
                </div>

                {isLoaded && <ClassSearch bossRelationCode={bossRelationCode} onClassSelected={(value) => setSelectedClass(value)} />}
                {isLoaded && <UserSearch bossRelationCode={bossRelationCode} onUserSelected={(value) => setSelectedUsers(value)} selectedUser={selectedUsers} />}
                {isLoaded && <PaymentBookingValidation bossRelationCode={bossRelationCode} selectedClass={selectedClass} selectedUser={selectedUsers} onUserChanged={(e) => setSelectedUsers(e)} onUserValidated={(e) => setValidatedUsers(e)} />}

                {errorMessage && <Alert alertAppereance="box" alertType="error" message={errorMessage} />}

                {showComment && <Comments onTextEntered={(value) => setComments(value)} />}

                <div className=''>
                    <button
                        type="button"
                        className="btn--md btn--primary"
                        disabled={(errorMessage?.length || 0) > 0 || !selectedClass || !validatedUsers || validatedUsers.length === 0}
                        onClick={() => addToBasket()}
                    >
                        <span><Translate>ItemDetail:AddToShoppingBasket</Translate></span>
                    </button>
                </div>
            </div>
        </>
    );
}