import { JsonObject, JsonProperty } from 'json2typescript';



@JsonObject
export class RenewTokensRequest {

    @JsonProperty('refreshToken', String, false)
    public refreshToken: string = '';

    @JsonProperty('jwtToken', String, false)
    public jwtToken: string;

}
