import React from 'react';

import { Translate } from '$src/components/shared/Translate';

interface IProps {
    message?: string;
    displayMessage?: boolean;
    isSmall?: boolean;
    size?: number;
}

interface IState {
    isSmall: boolean;
    size: number;
}

// tslint:disable:object-literal-sort-keys
export class ProgressSpinner extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isSmall: this.props.isSmall != null && this.props.isSmall === true,
            size: this.props.size || 0
        }
    }

    public render() {
        let additionalCssClass = this.state.isSmall ? 'progress-spinner--small' : '';
        additionalCssClass = this.state.size > 0 ? 'progress-spinner--inherit-size' : additionalCssClass;
        const size = this.state.size > 0 ? this.state.size + 'px' : 'unset';

        return (
            <>
                <div className={`progress-spinner ${additionalCssClass}`} style={{ width: size, height: size }}>
                    <div className="progress-spinner__animation">
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                    </div>
                    <div className="progress-spinner__message">
                        {this.renderMessage()}
                    </div>
                </div>
                { this.props.children }
            </>
        );
    }

    private renderMessage() {
        if (this.props.displayMessage != null && this.props.displayMessage) {
            if (this.props.message != null) {
                return this.props.message
            }
            else {
                return <Translate>ProgressSpinner:DefaultMessage</Translate>
            }
        }
        else {
            return null
        }
    }
}