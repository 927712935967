import React from 'react';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputText } from '$components/shared/InputText';
import { InputTextArea } from '$components/shared/InputTextArea';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { isSuccess } from '$src/util/Result';
import { EFileType } from '$storage/models/enums';
import { ExternalCourse } from '$storage/models/ExternalCourse/ExternalCourse';
import { FileDataRequest } from '$storage/models/RequestObjects/FileDataRequest';
import GTFileUpload from '$src/components/shared/Molecules/GTFileUpload';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';


interface IProps {
    onSaveExternalCourse?: (confirmed: boolean, externalCourse: ExternalCourse | undefined, courseFiles: FileDataRequest[]) => void;
    parentHeadingLevel: number;
}

interface IState {
    errorMessage: string;
    uploadErrorMessage: string;
    externalCourse: ExternalCourse;
    uploadedFiles: File[];
    fileDataRequest: FileDataRequest[];
    onSave: boolean;
}

export class CreateExternalCourse extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            uploadErrorMessage: '',
            externalCourse: new ExternalCourse(),
            fileDataRequest: [],
            uploadedFiles: [],
            onSave: false,
        }
    }

    public render() {
        const ariaLabelCancel = Session.instance.storage.translation.GetString('Button:Cancel');
        const ariaLabelSave = Session.instance.storage.translation.GetString('Button:Save');

        // disable save button if required fields aren't filled
        const saveDisabled = this.state.externalCourse.title.length === 0;

        const courseTitle: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: true,
            id: 'courseTitle',
            initialValidation: false,
            isReadOnly: false,
            isRequired: true,
            label: Session.instance.storage.translation.GetString('ExternalCourse:CourseTitle'),
            labelClassName: 'heading__Level5',
            maxLength: 200,
            regExpression: undefined,
            url: undefined,
            value: this.state.externalCourse.title,
        };

        const courseDescription: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'courseDescription',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: Session.instance.storage.translation.GetString('ExternalCourse:CourseDescription'),
            labelClassName: 'heading__Level5',
            regExpression: undefined,
            rows: 8,
            url: undefined,
            value: this.state.externalCourse.description,
        };

        const courseUrl: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'courseUrl',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: Session.instance.storage.translation.GetString('ExternalCourse:OptionalCourseUrl'),
            labelClassName: 'heading__Level5',
            regExpression: undefined,
            url: undefined,
            value: this.state.externalCourse.title,
        };
        return (
            <div>
                <span className={'input-message error'}>
                    <Translate>{this.state.errorMessage}</Translate>
                </span>
                <InputText attributes={courseTitle} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                <InputTextArea attributes={courseDescription} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                <InputText attributes={courseUrl} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                <GTFileUpload
                    parentHeadingLevel={this.props.parentHeadingLevel}
                    label={Session.instance.storage.translation.GetString("ExternalCourse:OptionalCourseDocuments")}
                    allowedFileExtensions={globalConfig.externalCourse.requestFileUpload.acceptFilter}
                    multipleUploadAllowed={true}
                    maxFileSizeInByte={globalConfig.externalCourse.requestFileUpload.maxFileSize}
                    onUploadFiles={(files) => this.onUploadChange(files)}
                    defaultFileType={EFileType.Undefined}
                    allowDuplicateFileNames={false}
                    duplicateErrorMessage='ExternalCourse:DocumentAlreadyUploaded'
                />
                <span className={'input-message error'}>
                    <Translate>{this.state.uploadErrorMessage}</Translate>
                </span>
                <div className="modal__spread-buttons">
                    {this.state.onSave ? <ProgressSpinner size={24} /> :
                        <>
                            <button type="button"
                                aria-label={ariaLabelSave}
                                className="btn btn--sm marginRight-5"
                                onClick={() => this.onButtonClickSave()}
                                disabled={saveDisabled}
                                id="btnSave">
                                <Translate>Button:Save</Translate>
                            </button>
                            <button type="button"
                                aria-label={ariaLabelCancel}
                                className="btn btn--sm"
                                onClick={() => this.onButtonClickClose()}
                                id="btnCancel">
                                <Translate>Button:Cancel</Translate>
                            </button>
                        </>
                    }
                </div>
            </div>
        )
    }

    /**
     * Callback for InputFile component: input has changed
     * Returns a list of files
     * @param files Selected files in input file
     */
    private onUploadChange(files: FileDataRequest[]) {
        const tmpFiles: File[] = new Array<File>();
        files.map(f => {
            if (f.file) {
                tmpFiles.push(f.file)
            }
        });
        this.setState({ uploadedFiles: tmpFiles, fileDataRequest: files });
    }

    private onInputChange(id: string, value: string) {
        const externalCourse = this.state.externalCourse;
        switch (id) {
            case 'courseTitle':
                externalCourse.title = value;
                break;
            case 'courseDescription':
                externalCourse.description = value;
                break;
            case 'courseUrl':
                externalCourse.courseUrl = value;
                break;
        }
        this.setState({ externalCourse });
    }

    private onButtonClickClose() {
        if (this.props.onSaveExternalCourse) {
            this.props.onSaveExternalCourse(false, undefined, []);
        }
    }

    private async onButtonClickSave() {
        this.setState({onSave: true});
        const sidAlreadyExists = await ExternalCourseService.instance.externalCourseExists(this.state.externalCourse.title);
        if (isSuccess<BooleanResponse>(sidAlreadyExists)) {
            if (!sidAlreadyExists.status) {
                if (this.props.onSaveExternalCourse) {
                    this.props.onSaveExternalCourse(true, this.state.externalCourse, this.state.fileDataRequest);
                }
            } else {
                this.setState({ errorMessage: 'ErrorMessage:ExternalCourseAlreadyExists', onSave: false });
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:FailedToCheckIfExist', onSave: false });
        }

    }

}