import { ExternalCourseRequestRegistration } from '$components/externalCourse/ExternalCourseRequestRegistration';
import IconattachFile from '$resources/svgs/misc/attach-file.svg';
import Delete from '$resources/svgs/misc/delete.svg';
import { Heading } from '$src/components/shared/Heading';
import InputFile from '$src/components/shared/InputFile';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ECertificateType, EFileType, EItemSubType, ELessonStatus, ERegistrationStatus } from '$src/storage/models/enums';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { ExternalCourseRevertStatusRequest } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseRevertStatusRequest';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import { UserCertificate } from '$src/storage/models/Certificates/UserCertificate';
import { UserCertificateViewModel } from '$src/storage/models/Certificates/UserCertificateViewModel';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import FileHelper from '$src/util/FileHelper';
import { isSuccess } from '$src/util/Result';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';
import { ModalPopup } from '$components/shared/ModalPopup';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputTextArea } from '$components/shared/InputTextArea';
import { UpdateDocumentRequest } from '$src/storage/models/RequestObjects/ExternalCourse/UpdateDocumentRequest';

interface IProps extends RouteComponentProps<{}> {
    externalCourseRegistration: ExternalCourseRegistration;
    parentHeadingLevel: number;
}

interface IState {
    cancelEnabled: boolean;
    errorMessage: string;
    externalCourseRegistration: ExternalCourseRegistration | undefined;
    isDataLoaded: boolean;
    uploadedFiles: Blob[];
    uploadErrorMessage: string;
    filesToUpload: File[];
    fileDataRequest: FileDataRequest[];
    updateDocuments: UpdateDocumentRequest[];
    revertLessonStatusComment: string;
    shouldShowModal: boolean;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export default class ExternalCourseRequest extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            cancelEnabled: false,
            errorMessage: '',
            externalCourseRegistration: this.props.externalCourseRegistration,
            fileDataRequest: [],
            filesToUpload: [],
            isDataLoaded: false,
            updateDocuments: [],
            uploadedFiles: [],
            revertLessonStatusComment: '',
            shouldShowModal: false,
            uploadErrorMessage: ''
        }
    }

    public componentDidMount() {
        document.title = this.state.externalCourseRegistration ?
            Session.instance.storage.translation.GetString('CourseRequest:RequestDetail').Format(this.state.externalCourseRegistration.registeredUserFullName)
            : '';
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        this.setState({ externalCourseRegistration: nextProps.externalCourseRegistration });
    }

    public render() {
        if (!this.state.isDataLoaded) {
            this.getExternalCourseRegistration();
            return (<ProgressSpinner />)
        } else {
            return (
                <div>
                    {this.state.errorMessage.length > 0 ?
                        (<span className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>)
                        :
                        (this.renderDetails())
                    }
                </div>
            )
        }

    }

    private renderDetails(): JSX.Element {
        if (this.state.externalCourseRegistration) {
            const courseTitle = this.state.externalCourseRegistration.externalCourse ? this.state.externalCourseRegistration.externalCourse.title : '';
            const registeredUser = this.state.externalCourseRegistration.registeredUserFullName;
            return (
                <div>
                    <ExternalCourseRequestRegistration
                        externalCourseRegistration={this.state.externalCourseRegistration}
                        parentHeadingLevel={1}
                        isBossView={true} />
                    {this.state.externalCourseRegistration.lessonStatus >= ELessonStatus.Completed && 
                        this.state.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted &&
                        <React.Fragment>
                            < div className="step-content__block">
                                <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                    <Translate>ExternalCourse:RevertLessonStatusLabel</Translate>
                                </Heading>
                                <button className="button-link button-link--colorized-dark"
                                    onClick={() => this.setState({ shouldShowModal: true })}
                                    aria-label={Session.instance.storage.translation.GetString('ExternalCourse:RevertLessonStatusFor').Format(courseTitle, registeredUser)}>
                                    <Translate>ExternalCourse:RevertLessonStatus</Translate>
                                </button>
                                <ModalPopup
                                    isOpen={this.state.shouldShowModal}
                                    onRequestClose={() => this.setState({ shouldShowModal: false })}>
                                    {this.renderRevertLessonStatusDialog()}
                                </ModalPopup>
                            </div>
                            {this.renderDocumentsAndInput()}

                            < div className="step-content__block btn-group--row-flex">
                                <button
                                    className="btn btn--sm"
                                    onClick={() => this.onUpdateDocuments()}
                                    aria-label={Session.instance.storage.translation.GetString('ExternalCourse:SaveDocumentsFor').Format(courseTitle, registeredUser)}
                                    disabled={this.isDocumentTypeMissing()}>
                                    <Translate>Button:Save</Translate>
                                </button>
                                {this.state.cancelEnabled &&
                                    <button
                                        className="btn btn--sm"
                                        onClick={() => this.cancelChanges()}
                                        aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DiscardChanges').Format(courseTitle, registeredUser)}>
                                        <Translate>Button:Cancel</Translate>
                                    </button>
                                }
                            </div>
                        </React.Fragment>
                    }
                </div>
            )
        } else {
            return (
                <div>
                    <Translate>ExternalCourse:NoRegistrationFound</Translate>
                </div>
            )
        }
    }

    private renderDocumentsAndInput(): JSX.Element {
        return (
            <div className="step-content__block">
                <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                    <Translate>ExternalCourse:AttendanceDocumentsTitle</Translate>
                </Heading>
                {this.renderDocuments()}
                <InputFile
                    acceptFilter={globalConfig.externalCourse.requestFileUpload.acceptFilter}
                    isMultiple={globalConfig.externalCourse.requestFileUpload.multipleAllowed}
                    labeledBy="lblDocuments"
                    onUploadChange={(files) => this.onUploadChange(files)}
                    maxFileSizeInByte={globalConfig.externalCourse.requestFileUpload.maxFileSize}
                    linkCssClass="button-link button-link--colorized-dark"
                />
                <span className={'input-message error'}>
                    <Translate>{this.state.uploadErrorMessage}</Translate>
                </span>
            </div>
        )
    }

    private renderDocuments(): JSX.Element {
        const uploadedFileTypes = [
            { id: EFileType.Certificate, text: Session.instance.storage.translation.GetString('ExternalCourse:FileTypeCertificate') },
            { id: EFileType.Confirmation, text: Session.instance.storage.translation.GetString('ExternalCourse:FileTypeExpenseReport') }
        ]
        const defaultFileTypeValue = { id: EFileType.Undefined, text: Session.instance.storage.translation.GetString('ExternalCourse:ChooseFileType') }
        if (this.state.externalCourseRegistration) {
            const expenseReportDocs = this.state.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_ExpenseReport');
            return (
                <div className="step-content__block">
                    <div role="table" className="div-table__horizontal-table">
                        <div role="rowgroup" className="div-table__horizontal-table-row screen-reader-only">
                            <div role="row">
                                <div role="columnheader">
                                    <Translate>ExternalCourse:Filename</Translate>
                                </div>
                                <div role="columnheader">
                                    <Translate>ExternalCourse:Filetype</Translate>
                                </div>
                            </div>
                        </div>
                        <div role="rowgroup">
                            {this.state.filesToUpload.map(doc => {
                                const fileData = this.state.fileDataRequest.filter(f => f.fileName === doc.name)[0];
                                const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                    .Format(doc.name);
                                return (
                                    <div key={doc.name} role="row" className="div-table__horizontal-table-row">
                                        <div role="cell" className="inlineFlex">
                                            <InlineSVG src={IconattachFile} />
                                            <button
                                                className="button-link button-link--colorized-dark"
                                                aria-label={arriaLabelOpenDoc}
                                                onClick={() => FileHelper.openFile(doc)}>
                                                {doc.name}
                                            </button>
                                            <button className="button-link"
                                                onClick={() => this.onDeleteFile(doc.name)}
                                                aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName').Format(doc.name)}>
                                                <InlineSVG src={Delete} />
                                            </button>
                                        </div>
                                        <div role="cell">
                                            <DropDownList
                                                data={uploadedFileTypes}
                                                textField="text"
                                                dataItemKey="id"
                                                defaultValue={defaultFileTypeValue}
                                                value={fileData ? uploadedFileTypes.filter(ft => ft.id === fileData.fileType)[0] : undefined}
                                                onChange={(e) => this.fileTypeChange(e, doc.name)} />
                                            {(fileData === undefined || fileData.fileType === EFileType.Undefined) &&
                                                <div className="step-content__block dropdown-error">
                                                    <span className={'input-message error--dark'}>
                                                        <Translate>ErrorMessage:MissingFileType</Translate>
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                            {this.state.fileDataRequest.length > 0 && this.state.fileDataRequest.map((doc, index) => {
                                const fileName = doc.fileName; // FileHelper.getFileNameFromBase64(doc.base64String, doc.fileName);
                                const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                    .Format(fileName);
                                if (this.state.filesToUpload.some(f => f.name === fileName)) {
                                    return null;
                                } else {
                                    return (
                                        <div key={`doc_${index}`} role="row" className="div-table__horizontal-table-row">
                                            <div role="cell" className="inlineFlex">
                                                <InlineSVG src={IconattachFile} />
                                                <button
                                                    className="button-link button-link--colorized-dark"
                                                    aria-label={arriaLabelOpenDoc}
                                                    onClick={() => FileHelper.openMediaFile(null, doc.url)}>
                                                    {fileName}
                                                </button>
                                                <button className="button-link"
                                                    onClick={() => this.onDeleteFile(fileName)}
                                                    aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName').Format(fileName)}>
                                                    <InlineSVG src={Delete} />
                                                </button>
                                            </div>
                                            <div role="cell">
                                                <DropDownList
                                                    data={uploadedFileTypes}
                                                    textField="text"
                                                    dataItemKey="id"
                                                    defaultValue={defaultFileTypeValue}
                                                    value={uploadedFileTypes.filter(ft => ft.id === doc.fileType)[0]}
                                                    onChange={(e) => this.fileTypeChange(e, fileName)} />
                                                {doc.fileType === EFileType.Undefined &&
                                                    <div className="step-content__block dropdown-error">
                                                        <span className={'input-message error--dark'}>
                                                            <Translate>ErrorMessage:MissingFileType</Translate>
                                                        </span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {expenseReportDocs.length > 0 && expenseReportDocs.map((doc, index) => {
                                const fileName = doc.value; // FileHelper.getFileNameFromBase64(doc.value);
                                const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                    .Format(fileName);
                                const updatedDoc = this.state.updateDocuments.filter(uDoc => uDoc.attributeId === doc.id);
                                if (updatedDoc && updatedDoc.length > 0 && updatedDoc[0].isDeleted) {
                                    return null;
                                } else {
                                    const fileType = updatedDoc && updatedDoc.length > 0 ? updatedDoc[0].fileType : EFileType.Confirmation;
                                    return (
                                        <div key={`doc_${index}`} role="row" className="div-table__horizontal-table-row">
                                            <div role="cell" className="inlineFlex">
                                                <InlineSVG src={IconattachFile} />
                                                <button
                                                    className="button-link button-link--colorized-dark"
                                                    aria-label={arriaLabelOpenDoc}
                                                    onClick={() => FileHelper.openMediaFile(doc, null)}>
                                                    {fileName}
                                                </button>
                                                <button className="button-link"
                                                    onClick={() => this.onDeleteAttribute(doc.id)}
                                                    aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName').Format(fileName)}>
                                                    <InlineSVG src={Delete} />
                                                </button>
                                            </div>
                                            <div role="cell">
                                                <DropDownList
                                                    data={uploadedFileTypes}
                                                    textField="text"
                                                    dataItemKey="id"
                                                    defaultValue={defaultFileTypeValue}
                                                    value={uploadedFileTypes.filter(ft => ft.id === fileType)[0]}
                                                    onChange={(e) => this.updateFileTypeChange(e, doc.id)} />
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {this.lessonCertificates().map(cert => {
                                if (cert.certificate) {
                                    const certificate = cert.certificate;
                                    if (this.state.updateDocuments.some(uDoc => uDoc.certificateId === certificate.id && uDoc.isDeleted)) {
                                        return null;
                                    } else {
                                        return (
                                            <div key={certificate.id} role="row" className="div-table__horizontal-table-row">
                                                <div role="cell" className="inlineFlex">
                                                    <InlineSVG src={IconattachFile} />
                                                    <a href={cert.url} target={'_blank'} rel="noopener noreferrer" className="button-link button-link--colorized-dark">
                                                        {`${FileHelper.getFileNameFromUrl(cert.url)}${cert.fileExtension}`}
                                                    </a>
                                                    <button className="button-link"
                                                        onClick={() => this.onDeleteCertificateFile(certificate)}
                                                        aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName')
                                                            .Format(`${FileHelper.getFileNameFromUrl(cert.url)}${cert.fileExtension}`)}>
                                                        <InlineSVG src={Delete} />
                                                    </button>
                                                </div>
                                                <div role="cell">
                                                    <DropDownList
                                                        data={uploadedFileTypes}
                                                        textField="text"
                                                        dataItemKey="id"
                                                        defaultValue={defaultFileTypeValue}
                                                        value={uploadedFileTypes.filter(ft => ft.id === EFileType.Certificate)[0]}
                                                        disabled={true} />
                                                </div>
                                            </div>
                                        )
                                    }
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    </div>
                </div>
            )
        } else {
            return <div />;
        }

    }

    private renderRevertLessonStatusDialog(): JSX.Element {
        const revertStatusReasonTextAttributes: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: true,
            id: 'revertStatusReasonText',
            initialValidation: true,
            isReadOnly: false,
            isRequired: true,
            label: 'ExternalCourse:RevertStatusReasonText',
            labelClassName: 'heading__Level5',
            placeholder: Session.instance.storage.translation.GetString('ExternalCourse:RevertStatusReasonTextPlaceholder'),
            regExpression: undefined,
            rows: 5,
            url: undefined,
            value: '',
        };
        return (
            <div>
                <InputTextArea
                    attributes={revertStatusReasonTextAttributes}
                    onChange={(id: string, value: string) => this.setState({ revertLessonStatusComment: value })} />
                <div className="modal__spread-buttons">
                    <button type="button"
                        aria-label={Session.instance.storage.translation.GetString('ExternalCourse:RevertLessonStatusConfirm')}
                        className="btn btn--sm marginRight-5"
                        onClick={() => this.onRevertLessonStatus()}
                        disabled={!this.state.revertLessonStatusComment}>
                        <Translate>Button:Confirm</Translate>
                    </button>
                    <button type="button"
                        aria-label={Session.instance.storage.translation.GetString('ExternalCourse:RevertLessonStatusCancel')}
                        className="btn btn--sm"
                        onClick={() => this.setState({ shouldShowModal: false })}
                        disabled={false}>
                        <Translate>Button:Cancel</Translate>
                    </button>
                </div>
            </div>
        )
    }

    //#region File Functions

    /**
     * Callback for InputFile component: input has changed
     * Returns a list of files
     * @param files Selected files in input file
     */
    private onUploadChange(files: File[]) {
        const filesToUpload = this.state.filesToUpload;
        const fileDataRequest = this.state.fileDataRequest;
        let uploadErrorMessage = '';
        files.map(f => {
            if (this.documentAlreadyExists(f.name)) {
                uploadErrorMessage = 'ExternalCourse:DocumentAlreadyUploaded';
            } else {
                fileDataRequest.push({
                    base64String: '',
                    file: f,
                    contentType: f.type,
                    fileName: f.name,
                    fileType: EFileType.Undefined,
                    url: ''
                })
            }
            filesToUpload.push(f)
        });
        this.setState({ filesToUpload: Array.from(new Set(filesToUpload)), cancelEnabled: true, uploadErrorMessage });
    }

    // Checks if the document already exists or if a document with the same name is deleted
    private documentAlreadyExists(filename: string): boolean {
        const filesToUpload = this.state.filesToUpload;
        const expenseReportDocs = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_ExpenseReport');
        const existentExpenseReportDoc = expenseReportDocs.find(doc => doc.value == filename);
        const certificates = this.lessonCertificates();
        const existenCertificate = certificates.find(doc => `${FileHelper.getFileNameFromUrl(doc.url)}${doc.fileExtension}` == filename);

        const documentsToDelete = this.state.updateDocuments.filter(doc => doc.isDeleted) 

        if (filesToUpload.find(uploadedFile => uploadedFile.name == filename) ||
            (existentExpenseReportDoc && !documentsToDelete.find(docToDelete => docToDelete.attributeId == existentExpenseReportDoc.id)) ||
            (existenCertificate && !documentsToDelete.find(docToDelete => docToDelete.certificateId == existenCertificate.certificate!.id))) {
            return true;
        } else {
            return false;
        }
    }

    private onDeleteFile(docName: string) {
        const filesToUpload = this.state.filesToUpload;
        const fileDataRequest = this.state.fileDataRequest
        this.setState({
            cancelEnabled: true,
            fileDataRequest: fileDataRequest.filter(file => file.fileName !== docName),
            filesToUpload: filesToUpload.filter(doc => doc.name !== docName),
            uploadErrorMessage: ''
        });
    }

    private onDeleteAttribute(attId: number) {
        const updateDocuments = this.state.updateDocuments;
        this.state.updateDocuments.map(doc => {
            if (doc.attributeId == attId) {
                doc.isDeleted = true;
                updateDocuments.push(doc);
            } else {
                updateDocuments.push({
                    attributeId: attId,
                    isDeleted: true,
                    fileType: EFileType.Undefined,
                    certificateId: 0
                });
            }
        })
        if (updateDocuments.length === 0) {
            updateDocuments.push({
                attributeId: attId,
                isDeleted: true,
                fileType: EFileType.Undefined,
                certificateId: 0
            });
        }
        this.setState({ updateDocuments, uploadErrorMessage: '' });
    }

    private onDeleteCertificateFile(certificate: UserCertificate | undefined) {
        if (certificate) {
            const updateDocuments = this.state.updateDocuments;
            this.state.updateDocuments.map(doc => {
                if (doc.attributeId == certificate.id) {
                    doc.isDeleted = true;
                    updateDocuments.push(doc);
                } else {
                    updateDocuments.push({
                        attributeId: 0,
                        isDeleted: true,
                        fileType: EFileType.Undefined,
                        certificateId: certificate.id
                    });
                }
            })
            if (updateDocuments.length === 0) {
                updateDocuments.push({
                    attributeId: 0,
                    isDeleted: true,
                    fileType: EFileType.Undefined,
                    certificateId: certificate.id
                });
            }
            this.setState({ updateDocuments, uploadErrorMessage: '' });
        }
    }

    private fileTypeChange(event: DropDownListChangeEvent, docName: string) {
        const fileDataRequest = this.state.fileDataRequest;
        const fileType: number = event.target.value.id;
        fileDataRequest.map(file => {
            if (file.fileName === docName) {
                switch (fileType) {
                    case 1:
                        file.fileType = EFileType.Certificate
                        break;
                    case 2:
                        file.fileType = EFileType.Confirmation
                        break;
                    default:
                        file.fileType = EFileType.Undefined
                        break;
                }
            }
        });
        this.setState({ fileDataRequest, cancelEnabled: true });
    }

    private updateFileTypeChange(event: DropDownListChangeEvent, attId: number) {
        const updateDocuments = this.state.updateDocuments;
        const fileType: number = event.target.value.id;
        let docFileType = EFileType.Undefined;
        switch (fileType) {
            case 1:
                docFileType = EFileType.Certificate
                break;
            case 2:
                docFileType = EFileType.Confirmation
                break;
        }
        updateDocuments.map(doc => {
            if (doc.attributeId === attId) {
                doc.fileType = docFileType;
            } else {
                updateDocuments.push({
                    attributeId: attId,
                    isDeleted: false,
                    fileType: docFileType,
                    certificateId: 0
                });
            }
        });
        if (updateDocuments.length === 0) {
            updateDocuments.push({
                attributeId: attId,
                isDeleted: false,
                fileType: docFileType,
                certificateId: 0
            });
        }

        this.setState({ updateDocuments, cancelEnabled: true });
    }

    private isDocumentTypeMissing(): boolean {
        if (this.state.fileDataRequest.length === 0 && this.state.filesToUpload.length === 0) {
            return false;
        }
        if (this.state.fileDataRequest.findIndex(file => file.fileType === EFileType.Undefined) !== -1) {
            return true;
        } else {
            // Uploaded documents do not have an EDocumentType and are stored only in this.state.filesToUpload
            // The moment the document type is set (or a new file is uploaded), the document will get a copy in this.state.fileDataRequest with an EDocumentType
            // To know if a document in filesToUpload is missing the EDocumentType, the following code will check for their copies in fileDataRequest
            return this.state.filesToUpload.findIndex(doc => {
                return this.state.fileDataRequest.findIndex(f => f.fileName === doc.name) !== -1
            }) === -1;
        }
    }

    private lessonCertificates(): UserCertificateViewModel[] {
        const lessonCertificates: UserCertificateViewModel[] = [];
        if (this.state.externalCourseRegistration) {
            for (const cert of this.state.externalCourseRegistration.certificates) {
                if (cert.certificate && cert.certificate.certificateType === ECertificateType.Lesson
                    && cert.certificate.owningObjectId === this.state.externalCourseRegistration.itemId) {
                    lessonCertificates.push(cert);
                }
            }
        }
        return lessonCertificates;
    }


    //#endregion

    private async getExternalCourseRegistration() {
        const registration = await ExternalCourseService.instance.getExternalCourseRegistration(this.props.externalCourseRegistration.registrationId, EItemSubType.External);
        if (isSuccess<ExternalCourseRegistration>(registration)) {
            this.setState({ externalCourseRegistration: registration, isDataLoaded: true })
        } else {
            if (registration.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(registration.detailedObject.subStatusCode), isDataLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesRegistrationFailed', isDataLoaded: true });
            }
        }
    }

    private async onRevertLessonStatus() {
        if (this.state.externalCourseRegistration) {
            const request = new ExternalCourseRevertStatusRequest();
            request.registrationId = this.state.externalCourseRegistration.registrationId;
            request.userId = this.state.externalCourseRegistration.registeredUserId;
            request.revertLessonStatusBossComment = this.state.revertLessonStatusComment;
            const response = await ExternalCourseService.instance.revertLessonStatus(request);
            if (isSuccess<BooleanResponse>(response)) {
                if (!response.status) {
                    this.setState({ errorMessage: 'ErrorMessage:ReverLessonStatusFailed ' });
                } else {
                    this.getExternalCourseRegistration();
                }
            } else {
                if (response.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
                } else {
                    this.setState({ errorMessage: 'ErrorMessage:ReverLessonStatusFailed' });
                }
            }
        }
    }

    private cancelChanges() {
        this.props.history.goBack();
    }

    private async onUpdateDocuments() {
        if (this.state.externalCourseRegistration) {
            const response = await ExternalCourseService.instance.updateDocuments({
                fileCollection: this.state.fileDataRequest,
                itemId: this.state.externalCourseRegistration.itemId,
                registeredUserId: this.state.externalCourseRegistration.registeredUserId,
                registrationId: this.state.externalCourseRegistration.registrationId,
                updateDocumentList: this.state.updateDocuments
            });
            if (isSuccess<BooleanResponse>(response)) {
                if (!response.status) {
                    this.setState({ errorMessage: 'ErrorMessage:UpdateDocumentsFailed ' });
                } else {
                    this.setState({ isDataLoaded: false, cancelEnabled: false, fileDataRequest: [], filesToUpload: [], updateDocuments: [] });
                }
            } else {
                if (response.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
                } else {
                    this.setState({ errorMessage: 'ErrorMessage:UpdateDocumentsFailed' });
                }
            }
        }

    }
}