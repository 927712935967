import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Translate } from '$components/shared/Translate';
import { BossesTable } from '$components/userProfile//BossesTable';
import UserDetails from '$components/userProfile/UserDetails';
import Session from '$core/Session';
import SystemRoles from '$src/core/SystemRoles';
import React from 'react';
import { Redirect } from 'react-router';
import { UnmountClosed } from 'react-collapse';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';

interface IState {
    userProfilePanelOpen: boolean;
    superiorPanelOpen: boolean;
}
export class UserProfile extends React.Component<{}, IState> {


    protected className = 'UserProfile';
    protected loggerLocality = 'Components.UserProfile';

    constructor(props: {}) {
        super(props);
        this.state = {
            userProfilePanelOpen: false,
            superiorPanelOpen: false,
        };
    }

    public render() {
        if (Session.instance.loginUser === null || Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)) {
            return <Redirect push={true} to="/" />;
        }
        return (<div className="l-container">
            <MenuBreadCrumb key="MenuBreadCrumb" {...this.props} />
            <>
                <HeadingCollapsible
                    headingLevel={1}
                    headingCssClass='heading__Title'
                    containerCssClass=""
                    isOpened={this.state.userProfilePanelOpen}
                    onToggleOpenedState={() => this.setState({ userProfilePanelOpen: !this.state.userProfilePanelOpen })}>
                    <Translate>{'UserProfile:PageTitle'}</Translate>
                </HeadingCollapsible>
            </>
            <div className="l-form-container">
                <UnmountClosed isOpened={this.state.userProfilePanelOpen}>

                    <UserDetails
                        user={Session.instance.loginUser}
                        defaultConfiguration={globalConfig.userProfileProperties.fieldGroups}
                        extendedConfigurations={globalConfig.userProfileExtendedProperties}
                        formClassName="user-profile"
                        headingClassName="heading__Level2"
                        headingLevel={2} />

                </UnmountClosed></div>


            { globalConfig.userProfileProperties.displayBossPanel && <>
                <HeadingCollapsible
                    headingLevel={1}
                    headingCssClass='heading__Title'
                    containerCssClass=""
                    isOpened={this.state.superiorPanelOpen}
                    onToggleOpenedState={() => this.setState({ superiorPanelOpen: !this.state.superiorPanelOpen })}>
                    <Translate>{'UserProfile:Bosses'}</Translate>
                </HeadingCollapsible>
                <UnmountClosed isOpened={this.state.superiorPanelOpen}>
                    <BossesTable {...this.props} />
                </UnmountClosed>
            </>
            }
        </div>
        );
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('UserProfile:PageTitle');
    }

}

export default UserProfile;