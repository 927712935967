import { JsonObject, JsonProperty } from 'json2typescript';
import { UserSkillProfileSkillContainer } from './UserSkillProfileSkillContainer';

@JsonObject
export class UserSkillProfile {
    /** The user skill profile id */
    @JsonProperty('userSkillProfileId', Number, false)
    public userSkillProfileId: number = 0;

    /** The user skill profile status (1 if all skills are achieved) */
    @JsonProperty('userSkillProfileStatus', Number, false)
    public userSkillProfileStatus: number = 0;

    /** The userId related to the user skill profile */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** The skill profile id related to the user skill profile */
    @JsonProperty('skillProfileId', Number, false)
    public skillProfileId: number = 0;

    /** The skill profile type id related to the user skill profile */
    @JsonProperty('skillProfileTypeId', Number, false)
    public skillProfileTypeId: number = 0;

    /** The skill profile type related to the user skill profile */
    @JsonProperty('skillProfileType', String, false)
    public skillProfileType: string = '';

    /** Flag if the user skill profile is for performance review */
    @JsonProperty('showSkillProfileInPerformanceReview', Boolean, false)
    public showSkillProfileInPerformanceReview: boolean = false;

    /** The skill profile title in the requested or default language */
    @JsonProperty('skillProfileTitle', String, false)
    public skillProfileTitle: string = '';

    /** The skills for this profile */
    @JsonProperty('userSkillProfileSkillContainer', UserSkillProfileSkillContainer, false)
    public skillContainer?: UserSkillProfileSkillContainer = undefined;
}