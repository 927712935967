import React, { useState } from "react";
import { Translate } from "$src/components/shared/Translate";
import { ESortDirection } from "$src/storage/models/enums";
import GTHorizontalTable from "$src/components/shared/Molecules/GTHorizontalTable";
import GTHorizontalTableHeaderRow, { GTHeaderContent } from "$src/components/shared/Atoms/GTHorizontalTableHeaderRow";
import { SortableHeaderElement } from "$src/components/shared/SortableHeaderElement";
import SortingAlgorithm from "$src/util/SortingAlgorithm";
import RequirementsOverviewItem, { RequirementTableItem } from "./RequirementsOverviewItem";
import { RequirementsOverviewModel } from "./RequirementsOverviewModel";
import { ModalPopup } from "$src/components/shared/ModalPopup";
import Session from "$src/core/Session";
import GTButton from "$src/components/shared/Atoms/GTButton";
import AssignmentService from "$src/core/Services/AssignmentService";
import { isSuccess } from "$src/util/Result";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { RequirementsDeleteRequest } from "$src/storage/models/RequestObjects/RequirementsDeleteRequest";


interface IProps {
    overviewData: RequirementTableItem[];
    bossRelationCode: string;
    groupedBy: "none" | "user" | "requirement";
    reloadData: () => void;
}

interface ISort {
    id: string;
    direction: ESortDirection;
}


export default function RequirementsOverviewTable(props: IProps) {
    const [activeHeader, setActiveHeader] = useState<string>('userFirstAndLastName');
    const [sort, setSort] = useState<ISort>({ id: 'userFirstAndLastName', direction: ESortDirection.Up });
    const [overviewData] = useState<RequirementTableItem[]>(props.overviewData);
    const [userId, setUserId] = useState<number>(0);
    const [assignmentId, setAssignmentId] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [succes, setSucces] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const success = Session.instance.storage.translation.GetString("RequirementsOverview:Success");
    const error = Session.instance.storage.translation.GetString("RequirementsOverview:Error");
    const groupedBy = props.groupedBy;


    const requirementsModel = new RequirementsOverviewModel;
    const test = false;

    function sortRows(id: string, direction: ESortDirection) {
        overviewData.sort((a: RequirementTableItem, b: RequirementTableItem): number => {
            return SortingAlgorithm.sortByColumnAndDirection(a, b, id, direction);
        })
    }

    function isActiveHeader(id: string) {
        return id === activeHeader;
    }

    function onSortDirectionChanged(id: string, direction: ESortDirection) {
        setActiveHeader(id);
        setSort({
            id, direction
        });
    }

    function openModal(userId: number, assignmentId: number) {
        setUserId(userId);
        setAssignmentId(assignmentId);
        setShowModal(true);
    }

    function RenderRows() {
        const rowElements: JSX.Element[] = [];
        overviewData.map((data, index) => {
            rowElements.push(
                <RequirementsOverviewItem
                    key={`${data.itemId}_${data.userId}_${index}`}
                    groupedBy={groupedBy}
                    requirement={data}
                    bossRelationCode={props.bossRelationCode}
                    isActiveHeader={isActiveHeader(requirementsModel.columnDictionary[index])}
                    onActiveHeaderChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                    openModal={(userId, assignmentId) => openModal(userId, assignmentId)}
                />
            )
        })
        return rowElements;
    }

    function RenderTableHeader() {
        const headerElements: GTHeaderContent[] = [];
        if (groupedBy != "user") {
            headerElements.push({

                cellId: 'userFirstAndLastName',
                cellElement: <SortableHeaderElement
                    id={'userFirstAndLastName'}
                    isActive={isActiveHeader('userFirstAndLastName')}
                    header='RequirementsOverview:UserFirstAndLastName'
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                />,
            });
        }

        if (groupedBy != "requirement") {
            headerElements.push({

                cellId: 'lessonTitle',
                cellElement: <SortableHeaderElement
                    id={'lessonTitle'}
                    isActive={isActiveHeader('lessonTitle')}
                    header='RequirementsOverview:LessonTitle'
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                />
            });
        }


        headerElements.push({

            cellId: 'date',
            cellElement: <SortableHeaderElement
                id={'date'}
                isActive={isActiveHeader('date')}
                header='RequirementsOverview:Date'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'lessonStatus',
            cellElement: <SortableHeaderElement
                id={'lessonStatus'}
                isActive={isActiveHeader('lessonStatus')}
                header='RequirementsOverview:LessonStatus'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'score',
            cellElement: <SortableHeaderElement
                id={'score'}
                isActive={isActiveHeader('score')}
                header='RequirementsOverview:Score'
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
            />
        });

        headerElements.push({

            cellId: 'icons',
            cellElement: <Translate>RequirementsOverview:Icons</Translate>,
            cellClassName: 'not-sortable-header small-cell',
        });

        return <GTHorizontalTableHeaderRow
            headerElements={headerElements}
            tableRowClassName="requirementsOverview__row not mobile row__header" />
    }

    function deleteAssigmentFromUser() {
        const request: RequirementsDeleteRequest = { userId: userId, assignmentId: assignmentId, bossRelationCode: props.bossRelationCode };
        AssignmentService.instance.deleteAssignmentFromUser(request).then(response => {
            if (isSuccess<BooleanResponse>(response) && response.status) {
                setSucces(true);
                setSent(true);
            } else {
                setSucces(false);
            }
        })
    }

    function closeModal() {
        setShowModal(false);
        setSent(false);
        setSucces(false);
        props.reloadData();
    }

    function renderModal() {
        return (
            <ModalPopup isOpen={true} onRequestClose={() => closeModal()}>
                <div>
                    <Translate>RequirementsOverview:ModalText</Translate>
                    {sent ? succes ? <React.Fragment>
                        <Alert
                            alertType={'success'}
                            alertAppereance={'box'}
                            message={success} />
                    </React.Fragment> :

                        <React.Fragment>
                            <legend>{error}</legend>
                            <Alert
                                alertType={'error'}
                                alertAppereance={'box'}
                                message={error} />
                        </React.Fragment> : null}
                    {!sent && <div className="modal__spread-buttons">
                        <GTButton onClick={() => deleteAssigmentFromUser()}
                            ariaLabel={Session.instance.storage.translation.GetString('RequirementsOverView:Ok')}>
                            <Translate>RequirementsOverview:Ok</Translate>
                        </GTButton>
                        <GTButton onClick={() => closeModal()}
                            ariaLabel={Session.instance.storage.translation.GetString('RequirementsOverView:Cancel')}
                            isPrimary={false}>
                            <Translate>RequirementsOverview:Cancel</Translate>
                        </GTButton>
                    </div>}

                </div>
            </ModalPopup>
        )
    }

    return (
        <>
            {!test ? sortRows(sort.id, sort.direction) : null}
            {showModal ? renderModal() : null}
            <GTHorizontalTable tableClassName="requirementsOverview__table"
                headerElement={RenderTableHeader()}
                rows={RenderRows()} />
        </>
    )
}

