
import React from "react";

import Session from "$src/core/Session";
import { ERecommendedItemsByUserInterestsOrdering } from "$src/storage/models/enums";
import { Translate } from "$components/shared/Translate";
import { Tooltip } from "$components/shared/Tooltip";

interface IProps {
    onChange: (value: string) => void;
    value: ERecommendedItemsByUserInterestsOrdering;
}
export default function MyRecommendationsByInterestsOrderBy(props: IProps) {
    const tr = Session.instance.storage.translation;

    const orderByOptions = () => {
        const allOrderBys = Object.keys(ERecommendedItemsByUserInterestsOrdering)
            .filter(key => !isNaN(Number(key)) && Number(key) > 0);
        return allOrderBys.map(o => <option key={'ordering_' + o}
            value={o}>{tr.GetString('RecommendedItemsOrdering:' + ERecommendedItemsByUserInterestsOrdering[o as unknown as number].toString())}</option>)
    }
    return <><label
        id="MyRecommendationsOrderBy">
        <Translate>MyRecommendations:OrderByLabel</Translate>
    </label>
        <select
            id="MyRecommendationsOrderBySelect"
            data-tip={tr.GetString('MyRecommendations:OrderByToolTip')}
            aria-labelledby="MyRecommendationsOrderBy"
            data-for="MyRecommendationsOrderBySelect_Tooltip"
            className="input-field"
            onChange={(e) => props.onChange(e.target.value)}
            value={props.value as number}>
            {orderByOptions()}
        </select>
        <Tooltip id="MyRecommendationsOrderBySelect_Tooltip" />
</>
}