import React from "react";

import ItemBoxTile from "$src/components/shared/itemBox/ItemBoxTile";
import { CatalogElement } from "$src/storage/models/Catalog";
import { EColor, EItemDetailCallerContextType, EItemType } from "$src/storage/models/enums";
import { Item } from "$src/storage/models/Item";
import ItemSummaryCatalogFolder from "$src/components/item/itemSummaries/ItemSummaryCatalogFolder";
import ItemSummaryDetail from "$src/components/item/itemSummaries/ItemSummaryDetail";
import ItemSummaryHelper from "$src/util/ItemSummaryHelper";

interface IPropsItemSummary {
    itemCallerContext: EItemDetailCallerContextType;
    item: Item | CatalogElement;
    parentAssignmentId: number;
    parentHeadingLevel: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number;
    skillId?: number; // Used for PerformanceReview
    userId?: number; // Used for PerformanceReview
    profileId?: number; // Used for PerformanceReview
    isBoss?: boolean;
    bossRelationCode?: string; // Used for PerformanceReview
    onButtonClicked?: () => void; // Can be used for any additional button in the item summary, based on the item context
    highlight?: boolean;
}

/**
 * Item summary basic component
 * Used for every display of an item (lesson, training plan). The format changes based on the item type and the caller context (itemCallerContext).
 * Can be expended for every new item type and caller context
 */
export function ItemSummary(props: IPropsItemSummary) {

    const detailLink = ItemSummaryHelper.getLinkForItemDetail(props.item,
        props.itemCallerContext,
        props.parentCatalogFolderId,
        props.parentAssignmentId,
        props.item.registrationId,
        props.parentTrainingPlanId,
        props.skillId,
        props.userId,
        props.profileId,
        props.bossRelationCode);

    switch (props.itemCallerContext) {
        case EItemDetailCallerContextType.MyFavorites:
            break;
        case EItemDetailCallerContextType.Search:
            break;
        //case EItemDetailCallerContextType.TrainingPlan: Not yet implemented
        //    break;
        case EItemDetailCallerContextType.MyWatchlist:
            break;
        //case EItemDetailCallerContextType.CatalogFolderContentList: Not yet implemented
        //    break;
        case EItemDetailCallerContextType.Assignment:
        case EItemDetailCallerContextType.Recommendations:
        case EItemDetailCallerContextType.CourseRequests:
        case EItemDetailCallerContextType.RegisteredItem:
        case EItemDetailCallerContextType.MyRecommendationsByInterests:
        case EItemDetailCallerContextType.MyCourses:
        case EItemDetailCallerContextType.MyLesson:
        case EItemDetailCallerContextType.RecommendedItems:
        case EItemDetailCallerContextType.Catalog:
        default: {
            if (props.item.itemType === EItemType.Folder && props.item instanceof CatalogElement) {
                const folderDetails = ItemSummaryHelper.getItemSummaryCatalogFolderDetails(props.item);
                return (
                    <ItemBoxTile
                        id={props.item.itemId}
                        borderColor={EColor.Highlight}
                        isPositionRelative={true}
                        isListItem={true}>
                        <ItemSummaryCatalogFolder
                            id={props.item.sId}
                            folderDetails={folderDetails}
                            parentHeadingLevel={props.parentHeadingLevel} />

                    </ItemBoxTile>
                );
            }
            const itemDetails = ItemSummaryHelper.getItemSummaryDetails(props.item, props.parentAssignmentId, detailLink);
            const borderColor = props.itemCallerContext ===  EItemDetailCallerContextType.Assignment && props.item.expiresInTheNextFewDays ? EColor.Highlight : EColor.Undefined;
            return (
                <ItemBoxTile
                    id={props.item.itemId}
                    isPositionRelative={true}
                    isListItem={props.itemCallerContext !== EItemDetailCallerContextType.Recommendations && props.itemCallerContext !== EItemDetailCallerContextType.MyRecommendationsByInterests}
                    borderColor={borderColor}
                    highlight={props.highlight}>
                    <ItemSummaryDetail
                        id={props.item.sId}
                        itemDetails={itemDetails}
                        parentHeadingLevel={props.parentHeadingLevel}
                        itemCallerContext={props.itemCallerContext}
                        isBoss={props.isBoss}
                        onButtonClicked={props.onButtonClicked} />
                </ItemBoxTile>

            );}
        
    }

    return null;

}