import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * All relevant ids of an FileSharing lesson and its context in which the lesson was opened
 * @export
 * @class FileSharingContext
 */
@JsonObject
export class FileSharingContext {

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('assignmentId', Number, false)
    public assignmentId: number | null = null;

    @JsonProperty('tPlanId', Number, false)
    public tPlanId: number | null = null;

    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number | null = null;

}