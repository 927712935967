import { JsonObject, JsonProperty } from 'json2typescript';
import { DateOrNullConverter } from '$src/storage/models/converters/DateOrNullConverter';
import { SkillStatusConverter } from './converters/SkillStatusConverter';
import { ESkillStatus } from './enums';

/**
 *This Model is for showing the groups for MailTo
 */
@JsonObject
export class EvaluationSkillDataUser {

    /** UserId  */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('userName', String, false)
    public userName: string = ''

    @JsonProperty('userDisplayName', String, false)
    public userDisplayName: string = ''

    /** firstName*/
    @JsonProperty('firstName', String, false)
    public firstName: string = "";

    /** lastName*/
    @JsonProperty('lastName', String, false)
    public lastName: string = "";

    /** mail*/
    @JsonProperty('mail', String, false)
    public mail: string = "";

    /** allocationDate*/
    @JsonProperty('allocationDate', DateOrNullConverter, false)
    public allocationDate: Date | undefined = undefined;

    /** expirationDate*/
    @JsonProperty('expirationDate', DateOrNullConverter, false)
    public expirationDate: Date | undefined = undefined;

    /** skillLevel*/
    @JsonProperty('skillLevel', String, false)
    public skillLevel: string = "";

    /** skillTitle*/
    @JsonProperty('skillTitle', String, false)
    public skillTitle: string = "";

    /** skillStatusString*/
    @JsonProperty('skillStatusString', String, false)
    public skillStatusString: string = "";

    /** Status of skill assignment (enum) */
    @JsonProperty('skillStatus', SkillStatusConverter, false)
    public skillStatus: ESkillStatus = ESkillStatus.Undefined;

}
