import React from 'react';

import BackIcon from '$resources/svgs/navi/back.svg';

import { Translate } from '$components/shared/Translate';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class BackButton extends React.Component<RouteComponentProps<{}>, {}> {
    protected backButtonRef: React.RefObject<HTMLButtonElement> | undefined
    

    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.backButtonRef = React.createRef();

    }

    public render() {
        return (
            <button
                ref={ this.backButtonRef }
                onClick={() => this.onClick()}
                className="nav__back button-link">
                <InlineSVG src={BackIcon} />
                <Translate>Navigation:Back</Translate>
            </button>
        )
    }

    protected onClick() {
        this.props.history.goBack();
        if(this.backButtonRef != null && this.backButtonRef.current != null) {
            this.backButtonRef.current.blur();
        }
    }

}

export default withRouter(BackButton);