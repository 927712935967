import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import SkillService from '$core/Services/SkillService';
import Session from '$core/Session';
import { UserSkill } from '$storage/models/Skill/UserSkill';
import { isSuccess } from '$util/Result';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { UserSkills } from '$components/Skills/UserSkills';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';


interface IProps {
    userId: number | undefined;
}

interface IState {
    fetchedSkills: UserSkill[] | undefined;
    errorMessage: string;
    currentFilter: string;
    selectedYearFilter: string;
}

// Die Klasse "MySkills" zeigt die Kompetenzen des angemeldeten Benutzers in einer
// Tabelle mit sortierbar Headern an. Die Daten werden über die GTServices mit der
// WebMethod "UserSkills" geholt.
// Ein Element zur Zeit kann die Beschreibung der Kompetenz in einer eigenen Zeile anzeigen.
export class MySkills extends React.Component<IProps, IState> {
    protected inputControl: HTMLInputElement | null;

    constructor(props: IProps) {
        super(props);
        this.state = {
            currentFilter: '',
            errorMessage: '',
            fetchedSkills: undefined,
            selectedYearFilter: 'all',
        }
    }

    public async componentDidMount() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
        await this.getSkills();
    }

    public render() {

        return (
            <div className="l-container">
                <MenuBreadCrumb {...this.props} />
                <div className="l-box--wide">
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title"><Translate>MySkill:Title</Translate></Heading>
                    <Translate>MySkill:SubTitle</Translate>
                </div>
                <div className="l-box__input--auto my-skills__skill-filter">
                    <input                    
                        id="txtFilter"
                        name="txtFilter"
                        className="skill__filter--name"
                        type="text"
                        autoComplete={'on'}
                        aria-label={Session.instance.storage.translation.GetString('Filter:FilterFieldLabel')}
                        ref={(e) => this.inputControl = e}
                        placeholder={Session.instance.storage.translation.GetString('Filter:FilterPlaceholder')}
                        onChange={(e) => { this.setState({ currentFilter: e.target.value })} }
                        value={this.state.currentFilter}
                    />
                    <select className="skill__filter--date"
                        onChange={(e) => { 
                            this.setState({selectedYearFilter: e.target.value})
                            }}> 
                        <option value="all">{Session.instance.storage.translation.GetString('MySkill:DropdownFilterAllYears')}</option>
                        {this.CreateDropDownFilterOptions()}
                    </select> 
                </div>  
                {this.renderSkills()}
                <div>
                    <span className={'input-message error'}>
                        <Translate>{this.state.errorMessage}</Translate>
                    </span>
                </div>
            </div>
        );
    }

    private CreateDropDownFilterOptions(){
        const options: string[] = [];
        const elements: Array<JSX.Element> = [];

        if(this.state.fetchedSkills !== undefined){
            const skills = [...this.state.fetchedSkills];
            skills.sort((a, b) => a.dateAcquired.getFullYear() > b.dateAcquired.getFullYear() ? -1 : 1 );

            skills.forEach(item => {
                if(item !== undefined && !options.includes(item.dateAcquired.getFullYear().toString())){
                    options.push(item.dateAcquired.getFullYear().toString()); 
                }
            });
            options.forEach(option => {
                elements.push(
                    <option value={option} key={option}>{option}</option>
                );
            });
        }
        return elements;
    }

    private FilterSkillsByTitle(skills: UserSkill[]){
        const filter = this.state.currentFilter.replace(/[*\\]/g,''); // Escape all backslash and * symbols 
        return skills.filter(skill => this != null && filter !== '' ? skill.skillTitle.toLowerCase().search(filter) > -1 : true);
    }

    private FilterSkillsByYear(skills: UserSkill[]){
        if(!this.state.selectedYearFilter.toLowerCase().includes('all')){
            skills = skills.filter(skill => this != null && this.state.selectedYearFilter !== '' ?
            skill.dateAcquired.getFullYear().toString().search(this.state.selectedYearFilter) > -1 : true);
        }
        return skills;
    }

    private renderSkills() {
        const skills = this.state.fetchedSkills != null ? this.FilterSkillsByYear(this.FilterSkillsByTitle(this.state.fetchedSkills)) : undefined;
        if (skills === undefined) { 
            if (this.state.errorMessage === '') {
                return (<ProgressSpinner key={1} />);
            } 
        }
        else {
            if (skills.length > 0) {
                return (<UserSkills context={EItemDetailCallerContextType.MySkills} userSkills={skills} userId={Session.instance.loginUser ? Session.instance.loginUser.id : 0} />)
            } else {
                if(this.state.currentFilter === ''){
                    return <NoDataFound message={Session.instance.storage.translation.GetString('MySkill:NoData')} />
                }
                else {
                    return <NoDataFound message={Session.instance.storage.translation.GetString('MySkill:NoDataWithFilter')} />
                }
            }
        }
        return <React.Fragment/>
    }


    private async getSkills(): Promise<void> {
        const lang = Session.instance.getUserLanguageCodeOrFallBack;
        const response = await SkillService.instance.getUserSkills(lang);
        if (isSuccess<UserSkill[]>(response)) {

            this.setState({
                fetchedSkills: response
            })
        }
        else {
            Session.instance.setLastErrorMessage(response.message);
            this.setState({ errorMessage: response.message });
        }
    }

}
export default MySkills;