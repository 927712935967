import React from 'react';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { ValidateUserCertificate } from './ValidateUserCertificate';
import Session from '$src/core/Session';

export const ValidateUserCertificatePage: React.FC = () => {

    
    const tr = Session.instance.storage.translation;

    return (
        <div className="validate-user-certificate">
            <div className="l-container title">
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>ValidateUserCertificate:Title</Translate>
                </Heading>
                <div>
                    <Translate>ValidateUserCertificate:SubTitle</Translate>
                </div>
            </div>
            <div className="l-container title">
                <Heading headingLevel={2} cssClass="heading__Secondary">
                    <Translate>ValidateUserCertificate:CertificateValues</Translate>
                </Heading>
            </div>
            <div className="l-container">
                <ValidateUserCertificate translationStorage={tr} />
            </div>

        </div>
    )

}
