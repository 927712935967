import ServiceClient from '$src/core/ServiceClient';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { UserInterests } from '$src/storage/models/UserInterests/UserInterests';
import GtError from '$src/util/GtError';


/**
 * UserPreferencesService provides all Service Methods for the User Preferences Functionallity
 */
export default class UserInterestsService extends ServiceClient {
    protected static _instance: UserInterestsService | null = null;

    protected className = 'UserInterestsService';
    protected loggerLocality = 'UserInterestsService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): UserInterestsService {
        return this._instance || (this._instance = new this());
    }

    public async getUserInterests(): Promise<UserInterests[] | GtError> {
        const response = await this.get<UserInterests[]>('userinterests', UserInterests);
        return response;
    }

    public async saveUserInterests(idAttributeValue: number, orderNumber: number): Promise<UserInterests | GtError> {
        const response = await this.post<UserInterests>('userinterests/insert', {idAttributeValue: idAttributeValue,
                                                                              OrderNumber: orderNumber}, UserInterests);
        return response;
    }

    public async deleteUserInterests(idUserInterest: number): Promise<BooleanResponse | GtError> {
        const response = await this.post<BooleanResponse>(`userinterests/delete/${idUserInterest}`, {}, BooleanResponse);
        return response;
    }
} 