import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * This Model is used for pure number responses from the WebAPI. 
 * If the value is not packed inside this model, the Jscon2TypescriptConverter has problems
 * reading the value.
 */
@JsonObject
export class NumberResponse {

    /** String value  */
    @JsonProperty('value', Number, false)
    public value: number = 0;

    [key: number]:  number 
   
}
