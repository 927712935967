import React from 'react';
import InlineSVG from 'react-inlinesvg';

import Iconcertificate from '$resources/svgs/objects/certificate.svg';

import { Heading } from '$components/shared/Heading';
import { SortableHeaderElement } from '$components/shared/SortableHeaderElement';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { ESortDirection } from '$storage/models/enums';
import { UserCertificateViewModel } from '$storage/models/Certificates/UserCertificateViewModel';
import DateHelper from '$util/DateHelper';
import { StringHelper } from '$util/StringHelper';
import AuthService from '$src/core/Services/AuthService';

interface IProps {
    userCertificates: UserCertificateViewModel[];
    title: string;
    headingLevel: number;
    type: string;
    filter: string;
    yearFilter: string;
}
interface IState {
    sortedCertificates: UserCertificateViewModel[];
    activeHeaderId: string;
    errorMessage: string;
    fileHeader: string;
    dateHeader: string;
}

// This class renders all certificates of one type.
// The headers are responsive to sort the items 
export class MyCertificatesGroup extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.OnSort = this.OnSort.bind(this);
        this.IsActiveHeader = this.IsActiveHeader.bind(this);
        this.sortCertificatesByDate = this.sortCertificatesByDate.bind(this);
        this.sortCertificatesByDateDown = this.sortCertificatesByDateDown.bind(this);
        this.RenderTableHeader = this.RenderTableHeader.bind(this);

        const certificates = this.props.userCertificates.filter(x => x.certificate != null && this.props.filter !== '' ?
            x.certificate.fileName.toLowerCase().search(this.props.filter) > -1 : true)
        this.state = {
            activeHeaderId: 'Date_' + this.props.type,
            dateHeader: 'Date_' + this.props.type,
            errorMessage: '',
            fileHeader: 'Filename_' + this.props.type,
            sortedCertificates: certificates.sort(this.sortCertificatesByDateDown),
        }
    }

    /**
     * Sort the certificates alphabeticly
     * @param a      
     * @param b 
     */
    public sortCertificatesByName(a: any, b: any) {
        return b.certificate.fileName.toLowerCase().localeCompare(a.certificate.fileName.toLowerCase());
    }

    public sortCertificatesByNameDown(a: any, b: any) {
        return a.certificate.fileName.toLowerCase().localeCompare(b.certificate.fileName.toLowerCase());
    }

    public sortCertificatesByDate(a: UserCertificateViewModel, b: UserCertificateViewModel) {
        return DateHelper.CompareDate(a.certificate!.acquiredDate, b.certificate!.acquiredDate);
    }

    public sortCertificatesByDateDown(a: UserCertificateViewModel, b: UserCertificateViewModel) {
        return DateHelper.CompareDate(b.certificate!.acquiredDate, a.certificate!.acquiredDate);
    }


    public render() {
        const elements: JSX.Element[] = [];
        let certificates = this.FilterCertificatesByTitle(this.state.sortedCertificates)
        certificates = this.FilterCertificatesByYear(certificates);

        for (const item of certificates) {
            if (item.certificate !== undefined) {
                let element: JSX.Element; // Certificate entry
                element = (
                    <tr key={item.certificate.id}>
                        <td className="certificateTable__col--file-extension">
                            <InlineSVG src={Iconcertificate} />
                        </td>
                        <td className="certificateTable__col--name">
                            <a href={item.url} target="_blank" onClick={async () => {await AuthService.instance.GetPing(); return true}}>{item.certificate.fileName}</a>
                        </td>
                        <td className="certificateTable__col--date">
                            {StringHelper.dateString(item.certificate.acquiredDate)}
                        </td>
                    </tr>
                );
                elements.push(element);
            }
        }

        return (
            <div>
                <Heading headingLevel={this.props.headingLevel} cssClass="heading__Level2"><Translate>{this.props.title}</Translate></Heading>
                {elements.length > 0 ?
                    <table className="my-certificates__certificate-table">
                        <thead>
                            {this.RenderTableHeader()}
                        </thead>
                        <tbody>
                            {elements}
                        </tbody>
                    </table>
                    : null}
                {this.state.sortedCertificates.length > 0 && elements.length === 0 ?
                    <NoDataFound message={Session.instance.storage.translation.GetString('MyCertificates:NoDataWithFilter')} /> : null}
            </div>)
    }


    private FilterCertificatesByTitle(certificates: UserCertificateViewModel[]) {
        const filter = this.props.filter.replace(/[\*\\]/g, ''); // Escape all backslash and * symbols  
        return certificates.filter(x => x.certificate != null && filter !== '' ?
            x.certificate.fileName.toLowerCase().search(filter) > -1 : true);
    }

    private FilterCertificatesByYear(certificates: UserCertificateViewModel[]) {
        if (!this.props.yearFilter.toLowerCase().includes('all')) {
            certificates = certificates.filter(x => x.certificate != null && this.props.yearFilter !== '' ?
                x.certificate.acquiredDate.getFullYear().toString().search(this.props.yearFilter) > -1 : true);
        }
        return certificates;
    }

    private OnSort(id: string, direction: ESortDirection) {
        if (this.state.sortedCertificates != null) {
            if (id === this.state.fileHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.fileHeader,
                        sortedCertificates: this.state.sortedCertificates.sort(this.sortCertificatesByName)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.fileHeader,
                        sortedCertificates: this.state.sortedCertificates.sort(this.sortCertificatesByNameDown)
                    });
                }
            }
            if (id === this.state.dateHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.dateHeader,
                        sortedCertificates: this.state.sortedCertificates.sort(this.sortCertificatesByDate)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.dateHeader,
                        sortedCertificates: this.state.sortedCertificates.sort(this.sortCertificatesByDateDown)
                    });
                }
            }
        }
    }

    private IsActiveHeader(id: string) {
        return id === this.state.activeHeaderId;
    }

    private RenderTableHeader() {
        const tableHeader: JSX.Element = (
            <tr>
                <th className="certificateTable__th--file-extension">
                    <span className="screen-reader-only"><Translate>MyCertificates:ItemType</Translate></span>
                </th>
                <th className="certificateTable__th--name">
                    <SortableHeaderElement
                        id={this.state.fileHeader}
                        isActive={this.IsActiveHeader(this.state.fileHeader)}
                        header="MyCertificates:Filename"
                        className="heading__Level4"
                        initialSortDirection={ESortDirection.Down}
                        OnSortDirectionChanged={this.OnSort}
                    />
                </th>
                <th className="certificateTable__th--date">
                    <SortableHeaderElement
                        id={this.state.dateHeader}
                        isActive={this.IsActiveHeader(this.state.dateHeader)}
                        header="MyCertificates:AquiredDate"
                        className="heading__Level4"
                        initialSortDirection={ESortDirection.None}
                        OnSortDirectionChanged={this.OnSort}
                    />
                </th>
            </tr>
        );
        return tableHeader
    }

}