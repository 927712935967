import { JsonObject, JsonProperty } from 'json2typescript';
import PriceInfo from '$storage/models/ShoppingBasket/PriceInfo';

/**
 * Admins and bosses may book other users in a shopping basket. These are the relevant user information to book 
 */
@JsonObject
export default class ShoppingBasketItemUser {

    /** Only for booking admins and bosses who book for other users - when this array is filled, only these users will be booked */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** Display string of the users name - not needed for GTServices, but since GTServices will check entire basket and give a new one back, we need to pass this info */    
    @JsonProperty('display', String, false)
    public display: string = '';

    /** For only one company restriction */
    @JsonProperty('company', String, false)
    public company: string = '';

    /** Information about the price of the purchased item for this user */
    @JsonProperty('price', PriceInfo, false)
    public price: PriceInfo = new PriceInfo(0);
}
