import Logger from '$core/Logger';
import { isSuccess } from '$util/Result';
import { Assessment } from './models/Assessment';
import SessionCache from './cache/SessionCache';
import AssessmentService from '$src/core/Services/AssessmentService';

const CACHING_DURATION = 300;

export default class AssessmentStorage extends SessionCache<number, Assessment> {
    protected className = 'AssignmentStorage';
    protected loggerLocality = 'Storage.AssignmentStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public clear(): void {
        super.clear();
    } 

    public isCacheExpired(): boolean {
        return false;
    }

    public async getAssessmentDetail(itemId: number, forceLoadingFromServer = true): Promise<Assessment | null> {
        const methodName = `${this.className}:getAssessmentDetail()`;

        if (this.isObjectInCache(itemId) && !forceLoadingFromServer) {
            Logger.log(this.loggerLocality, `${methodName} getting assessment details from cache, lessonId=${itemId}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting assessment details from server, lessonId=${itemId}`);
            const response = await AssessmentService.instance.getAssessmentDetails(itemId);
            if (isSuccess<Assessment>(response)) {
                this.saveObjectToCache(itemId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get lessonDetail from server, lessonID=${itemId}.`);
            }
        }
        return this.getObjectFromCache(itemId);
    }
 
}
