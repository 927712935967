import { JsonObject, JsonProperty } from 'json2typescript';
import { Item } from '$storage/models/Item';

/** One purchased item including additional info */
@JsonObject
export class PurchasedItem {
    /** the purchased item*/
    @JsonProperty('item', Item, false)
    public item: Item = new Item();

    /** additional info like class dates and locations */
    @JsonProperty('additionalInfo', String, false)
    public additionalInfo: string = '';
}
