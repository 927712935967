import DateHelper from '$src/util/DateHelper';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { EPaymentCheckoutItemHistoryType } from '$src/storage/models/enums';

@JsonObject
export class PaymentCheckoutItemHistoryResponse {
	/** pcoih id */
	@JsonProperty('id', Number, false)
	public id: number = 0;

	@JsonProperty('historyType', Number, false)
	public historyType: EPaymentCheckoutItemHistoryType = EPaymentCheckoutItemHistoryType.Unknown;

	@JsonProperty('oldId', Number, false)
	public oldId: number = 0;

	@JsonProperty('newId', Number, false)
	public newId: number = 0;

	@JsonProperty('displayOld', String, false)
	public displayOld: string = '';

	@JsonProperty('displayNew', String, false)
	public displayNew: string = '';

	@JsonProperty('createdAt', DateTimeConverter, false)
	public createdAt: Date = new Date();

	@JsonProperty('createdBy', Number, false)
	public createdBy: number = 0;

	@JsonProperty('displayCreatedBy', String, false)
	public displayCreatedBy: string = '';

	/** Function to replace the placeholders in given string */
	replacePlaceholders(text: string): string {
		return text
			.replace('{displayOld}', this.displayOld || '-')
			.replace('{displayNew}', this.displayNew || '-')
			.replace('{createdAt}', DateHelper.toDateTimeString(this.createdAt))
			.replace('{createdBy}', this.displayCreatedBy);
	}
}