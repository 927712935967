
import ServiceClient from '$src/core/ServiceClient';
import { PaymentVoucherCampaignItemsToRestrictResponse, PaymentVoucherCampaignResponse } from '$src/storage/models/Campaign/PaymentVoucherCampaignResponse';
import GtError from '$util/GtError';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { PaymentVoucherCampaignRequest } from '$src/storage/models/Campaign/PaymentVoucherCampaignRequest';
import { PaymentVoucherCampaignDeleteRequest } from '$src/storage/models/Campaign/PaymentVoucherCampaignDeleteRequest';
import { PaymentVoucherDeleteRequest } from '$src/storage/models/Campaign/PaymentVoucherDeleteRequest';

/**
 * Service used to handle all data regarding the Administration of Voucher Campaigns 
 * Communicates with PaymentVoucherController
 */
export default class PaymentCampaignService extends ServiceClient {

    protected static _instance: PaymentCampaignService | null = null;

    protected className = 'CampaignService';
    protected loggerLocality = 'CampaignService';
    protected controllerName = 'paymentVoucher'

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PaymentCampaignService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Adds new subject to the Subscriptions of the User, and return the created object from the db
     * @param subjectIdToBeAdded : Id of the subject to be added to the users Subscriptions List
     */
     public async addVoucherCampaign(request: PaymentVoucherCampaignRequest): Promise<PaymentVoucherCampaignResponse[] | GtError> {
        return await this.post<PaymentVoucherCampaignResponse[]>(`${this.controllerName}/addNewCampaign`, request, PaymentVoucherCampaignResponse, undefined);
    }

    /**
     * Adds new subject to the Subscriptions of the User
     * @param campaignId : Id of the campaign to be deleted
     */
     public async deleteVoucherCampaign(campaignId: number): Promise<BooleanResponse | GtError> {
        const request = new PaymentVoucherCampaignDeleteRequest();
        request.campaignId = campaignId;
        return await this.post<BooleanResponse>(`${this.controllerName}/deleteCampaign`, request, BooleanResponse, undefined);
    }

    /**
     * Gets all item info needed in order to restrict a campaign to it
     */
     public async getRestrictionItems(): Promise<PaymentVoucherCampaignItemsToRestrictResponse | GtError> {
        return await this.get<PaymentVoucherCampaignItemsToRestrictResponse>(`${this.controllerName}/getItemsToRestrict`, PaymentVoucherCampaignItemsToRestrictResponse, undefined);
    }

    /**
     * Gets all campaigns from DB
     */
    public async getCampaignsWithFilter(searchText?: string, ignoreExpired?: boolean): Promise<PaymentVoucherCampaignResponse[] | GtError> {
        return await this.get<PaymentVoucherCampaignResponse[]>(`${this.controllerName}/getCampaignsWithFilter`,
        PaymentVoucherCampaignResponse,
            undefined,
            [
                {name: 'searchText', value: searchText ? searchText : ''},
                {name: 'ignoreExpired', value: ignoreExpired ? '1' : '0'}
            ]            
        );
    }

    /**
     * delets the specified voucher Code
     * @param voucherCodeId : voucher Code Id
     */
         public async deleteVoucherCode(voucherCodeId: number): Promise<BooleanResponse | GtError> {
            const request = new PaymentVoucherDeleteRequest();
            request.voucherCode = voucherCodeId;
            return await this.post<BooleanResponse>(`${this.controllerName}/deleteVoucherCode`, request, BooleanResponse, undefined);
        }


}