import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import RequestDetail from '$components/myEmployees/RequestDetail';
import { Heading } from '$components/shared/Heading';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputText } from '$components/shared/InputText';
import { ModalPopup } from '$components/shared/ModalPopup';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import IconexternalCourseCosts from '$resources/svgs/extendedMenu/tutor/reports.svg';
import { CheckBox } from '$src/components/shared/CheckBox';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import RegistrationService from '$src/core/Services/RegistrationService';
import { EmployeeRegisteredItem } from '$src/storage/models/EmployeeRegisteredItem';
import { EItemDetailCallerContextType, EItemSubType, ERegistrationStatus } from '$src/storage/models/enums';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import DateHelper from '$src/util/DateHelper';
import { NavigationUtil } from '$src/util/NavigationUtil';
import { isSuccess } from '$src/util/Result';
import { RouteComponentProps } from 'react-router';
import ContainerButton from '$src/components/shared/ContainerButton';
import { ItemSummary } from '$src/components/item/itemSummaries/ItemSummary';

interface IState {
    context: number;
    errorMessage: string;
    filterExternalCourse: boolean;
    filterF2fCourse: boolean;
    filterRegistrationStatus: ERegistrationStatus;
    filterYear: number;
    isDataLoaded: boolean;
    modalCourseType: EItemSubType;
    modalRegistrationId: number;
    parentHeadingLevel: number;
    registeredItems: EmployeeRegisteredItem[] | null;
    searchString: string;
    shouldShowModal: boolean;
}

/**
 * Displays a list of all the course requests of the users (logged in) employees (only from one boss-employee-relation: configured in GTServices web.config)
 */
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class CourseRequests extends React.Component<RouteComponentProps, IState> {
    constructor(props: RouteComponentProps) {
        super(props);

        const parameters = new URLSearchParams(window.location.search);
        const user = parameters.get('user');
        const contextString = parameters.get('context');

        const context = contextString ? +contextString : 0;

        const currentDate = new Date();
        this.state = {
            context: context === EItemDetailCallerContextType.CourseRequests ? 0 : context,
            errorMessage: '',
            filterExternalCourse: true,
            filterF2fCourse: true,
            filterRegistrationStatus: ERegistrationStatus.Undefined,
            filterYear: currentDate.getFullYear(),
            isDataLoaded: false,
            modalCourseType: EItemSubType.Undefined,
            modalRegistrationId: 0,
            parentHeadingLevel: 0,
            registeredItems: [],
            searchString: user ? user : '',
            shouldShowModal: false
        }
    }


    public async componentDidMount() {
        await this.LoadMyEmployeesCourseRequests();

        // If available years do not include the current year, set filter_year to 0 (= All years)
        const availableYears = this.getAllAvailableYears();
        if (!availableYears.includes(new Date().getFullYear())) {
            this.setState({ filterYear: 0 });
        }
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return <ProgressSpinner />;
        } else {
            const searchboxAttributes: InputAttributes =
            {
                // tslint:disable:object-literal-sort-keys
                attributeValueType: undefined,
                class: 'course-request-Input',
                editMode: false,
                hasFocus: false,
                id: 'searchText',
                initialValidation: false,
                isReadOnly: false,
                isRequired: false,
                label: Session.instance.storage.translation.GetString('CourseRequests:TextFilter'),
                placeholder: Session.instance.storage.translation.GetString('CourseRequests:SearchPlaceholder'),
                regExpression: undefined,
                rows: undefined,
                url: undefined,
                value: this.state.searchString,
            };
            const buttonText = Session.instance.storage.translation.GetString(NavigationUtil.getNavigationItemAttributeById('Reporting_ExternalCourse', 'text'));
            const navigationBreadCrumb = EItemDetailCallerContextType[this.state.context].toString();
            const iconColor = NavigationUtil.getNavigationItemAttributeById('Reporting_ExternalCourse', 'iconColor');
            return (
                <div className="l-container">
                    <ModalPopup
                        isOpen={this.state.shouldShowModal}
                        onRequestClose={() => this.closeModal()}
                        contentCssClass="modal__courseRequest">
                        <div>
                            <RequestDetail registrationId={this.state.modalRegistrationId} parentHeadingLevel={this.state.parentHeadingLevel + 1}
                                courseType={this.state.modalCourseType} itemContext={this.state.context} {...this.props} />
                        </div>
                    </ModalPopup>

                    {this.state.context ? <MenuBreadCrumb
                        breadCrumbElementsToAppend={[{
                            navigationPath: this.props.location.pathname,
                            title: `Navigation:CourseRequests`,
                            translateTitle: true
                        }]}
                        routePathName={`/${navigationBreadCrumb}`}
                        {...this.props} /> :
                        <MenuBreadCrumb {...this.props} />}
                    <Heading headingLevel={this.state.parentHeadingLevel + 1} cssClass="heading__Title">
                        <Translate>CourseRequests:Title</Translate>
                    </Heading>
                    <InputText attributes={searchboxAttributes} onChange={(id, value) => this.onSearchChanged(id, value)} />

                    <div className="filter-bar">
                        {this.renderFilter()}
                    </div>

                    <span className={'input-message error'}>
                        <Translate>{this.state.errorMessage}</Translate>
                    </span>

                    <div className="l-box-container l-box-container--column-flex">
                        {this.renderRegisteredItems()}
                    </div>

                    <ContainerButton ariaLabel={buttonText} buttonText={buttonText} cssClass="course-request-link" icon={IconexternalCourseCosts}
                        Iconcolor={iconColor} id="toReportExternalCourseCostsLink" onButtonClicked={() => this.toReportExternalCourseCosts()} />
                </div>

            );
        }
    }

    public renderFilter(): JSX.Element {
        const tr = Session.instance.storage.translation;
        return (
            <React.Fragment>
                <div className="filter-select">
                    <label id="lblRegistrationStatusFilter" htmlFor="ddlRegistrationStatusFilter" className="input-label">
                        <Translate>CourseRequests:RegistrationsStatusFilter</Translate>
                    </label>
                    <select
                        id="ddlRegistrationStatusFilter"
                        aria-label={tr.GetString('CourseRequests:RegistrationStatusFilter')}
                        className="input-field select-item"
                        onChange={(e) => this.filterRegistrationStatusChanged(e)}
                        defaultValue={this.state.filterRegistrationStatus.toString()}>
                        {this.createRegistrationStatusFilter()}
                    </select>
                </div>
                <div className="filter-select">
                    <label id="lblYearFilter" htmlFor="ddlYearFilter" className="input-label"><Translate>CourseRequests:YearFilter</Translate></label>
                    <select
                        id="ddlYearFilter"
                        aria-label={tr.GetString('CourseRequests:YearFilter')}
                        className="input-field select-item"
                        onChange={(e) => this.filterYearChanged(e)}
                        defaultValue={this.state.filterYear.toString()}  >
                        {this.createYearFilter()}
                    </select>
                </div>
                <div className="filter-checkbox">
                    <CheckBox id="cbExternalCourse"
                        onClick={() => this.filterExternalCourseChanged()}
                        value={this.state.filterExternalCourse.toString()}
                        defaultChecked={this.state.filterExternalCourse}
                        labelId="lblExternalCourse" />
                    <label id="lblExternalCourse" htmlFor="cbExternalCourse"><Translate>CourseRequests:ExternalCourse</Translate></label>
                </div>
                <div className="filter-checkbox">
                    <CheckBox id="cbf2fCourse"
                        onClick={() => this.filterF2fCourseChanged()}
                        value={this.state.filterF2fCourse.toString()}
                        defaultChecked={this.state.filterF2fCourse}
                        labelId="lblf2fCourse" />
                    <label id="lblf2fCourse" htmlFor="cbf2fCourse"><Translate>CourseRequests:F2FCourse</Translate></label>
                </div>
            </React.Fragment>
        );
    }

    protected renderRegisteredItems(): JSX.Element | JSX.Element[] {
        const elements = this.state.registeredItems;

        if (elements == null || elements.length === 0) {
            return <NoDataFound message={Session.instance.storage.translation.GetString('RegisteredItems:NoData')} />;
        }
        else {
            return (
                <ul className="item-box-tile__parent">
                    {this.renderRegisteredFilteredItems(elements)}
                </ul>
            )
        }
    }

    protected renderRegisteredFilteredItems(elements: EmployeeRegisteredItem[]): JSX.Element[] | null {
        const yearHardBoundaries = globalConfig.filterProperties.yearFilterHardBoundaries;
        elements = elements.filter(item =>
            (this.state.searchString === '' || item.userNameAndEmail.toLocaleLowerCase().includes(this.state.searchString.toLocaleLowerCase())) &&
            (this.state.filterRegistrationStatus === ERegistrationStatus.Undefined || this.state.filterRegistrationStatus === item.registrationStatus) &&
            (this.state.filterYear === 0 ||
                DateHelper.datesWithinBoundariesByYear(this.state.filterYear, item.learningPeriodStartDate, item.learningPeriodEndDate, yearHardBoundaries)) &&
            (this.state.filterExternalCourse || item.itemSubType !== EItemSubType.External) &&
            (this.state.filterF2fCourse || item.itemSubType !== EItemSubType.FaceToFace)
        );
        elements.forEach(item => item.registrationId = item.itemRegistrationId); // prevent showing the anser button for the >>next<< registration, instead of >>this<< registration


        return elements.map(registeredItem => {
            return <ItemSummary
                key={registeredItem.sId + registeredItem.classCode + registeredItem.userNameAndEmail}
                item={registeredItem}
                itemCallerContext={EItemDetailCallerContextType.CourseRequests}
                parentHeadingLevel={this.state.parentHeadingLevel}
                parentAssignmentId={0}
                parentCatalogFolderId={0}
                parentTrainingPlanId={0}
                onButtonClicked={() => this.openModal(registeredItem.registrationId, registeredItem.itemSubType)} />
        });
    }

    private toReportExternalCourseCosts() {
        this.props.history.push(`/reporting/reportExternalCourseRegistration`);
    }

    private closeModal(): void {
        this.setState({ modalCourseType: EItemSubType.Undefined, modalRegistrationId: 0, shouldShowModal: false });
        this.LoadMyEmployeesCourseRequests();
    }

    private openModal(registrationId: number, courseType: EItemSubType): void {
        this.setState({ modalCourseType: courseType, modalRegistrationId: registrationId, shouldShowModal: true });
    }

    private onSearchChanged(id: string, value: string) {
        this.setState({
            searchString: value
        });
    }

    private filterRegistrationStatusChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterRegistrationStatus: Number(target.value) });
    }

    private filterYearChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterYear: Number(target.value) });
    }

    private filterExternalCourseChanged() {
        this.setState((prevState) => ({
            filterExternalCourse: !prevState.filterExternalCourse
        }));
    }

    private filterF2fCourseChanged() {
        this.setState((prevState) => ({
            filterF2fCourse: !prevState.filterF2fCourse
        }));
    }

    private createRegistrationStatusFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        const values: ERegistrationStatus[] = [ERegistrationStatus.Undefined, ERegistrationStatus.Requested, ERegistrationStatus.Accepted, ERegistrationStatus.InWaitingList];
        // tslint:disable-next-line:forin
        values.forEach((regStatus: ERegistrationStatus) => {
            elements.push(
                <option key={'registrationStatusFilterOption_' + regStatus}
                    value={regStatus}>
                    {regStatus === ERegistrationStatus.Undefined ?
                        Session.instance.storage.translation.GetString('CourseRequests:All')
                        : Session.instance.storage.translation.GetString('RegistrationStatus:' + ERegistrationStatus[regStatus.valueOf()].toString())}</option>
            );
        })
        return elements;
    }

    private createYearFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const values = this.getAllAvailableYears();
        // tslint:disable-next-line:forin
        values.forEach((year: number) => {
            elements.push(
                <option key={'yearFilterOption_' + year}
                    value={year.toString()}>
                    {year === 0 ?
                        Session.instance.storage.translation.GetString('CourseRequests:All')
                        : year}</option>
            );
        })
        return elements;
    }

    /**
     * Returns all years in which the available registration items have their learning period (open: one date is enough)
     * @returns list of all available years as an array
     */
    private getAllAvailableYears(): number[] {
        const years: number[] = [0];
        this.state.registeredItems!.forEach((regItem) => {
            const dateForm = regItem.learningPeriodStartDate;
            const dateTo = regItem.learningPeriodEndDate;
            if (dateForm && dateTo) {
                years.push(dateForm.getFullYear());
                years.push(dateTo.getFullYear());
            }
        });

        return years.filter((value, index, self) => {
            return self.indexOf(value) === index;
        }).sort((year1, year2) => {
            if (year1 > year2) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    private async LoadMyEmployeesCourseRequests() {
        const tmp = await RegistrationService.instance.getMyEmployeesRegisteredCourseItems();

        if (isSuccess<EmployeeRegisteredItem[]>(tmp)) {
            this.setState({ registeredItems: tmp, errorMessage: tmp.length > 0 ? '' : 'CourseRequests:NoCourseRequestsFound', isDataLoaded: true });
        } else {
            if (tmp.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(tmp.detailedObject.subStatusCode), isDataLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:CourseRequestsFailed', isDataLoaded: true });
            }
        }
    }
}
export default CourseRequests