import React from 'react';

export class NavigationPointContainer extends React.Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    public render() {
        return <ul className="navigation__extended-menu">
            {this.props.children}
        </ul>
    }

}
