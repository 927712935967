import React, { useState, useEffect, useMemo } from 'react';
import Session from '$src/core/Session';
import { Heading } from '$components/shared/Heading';
import GlossaryService from '$src/core/Services/GlossaryService';
import { isSuccess } from '$src/util/Result';
import { Glossar } from '$src/storage/models/Glossary/Glossar';
import { ErrorMessage } from '$src/components/shared/WarningsAndErrors/ErrorMessage';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import InlineSVG from 'react-inlinesvg';
import BookmarkedIcon from '$resources/svgs/glossary/glossary-term-bookmarked.svg';
import NotBookmarkedIcon from '$resources/svgs/glossary/glossary-term-not-bookmarked.svg';
import GlossaryTerm from '$src/components/glossary/GlossaryTerm';
import GTSearch, { ETextFilterSize } from '$src/components/shared/Atoms/GTSearch';
import SortingAlgorithm from '$src/util/SortingAlgorithm';
import { GlossaryTermSummary } from '$src/storage/models/Glossary/GlossaryTermSummary';
import { EItemDetailCallerContextType, ESortDirection } from '$src/storage/models/enums';
import { Translate } from '$src/components/shared/Translate';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import { Item } from '$src/storage/models/Item';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import Logger from '$src/core/Logger';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';
import CollapsedIcon from '$resources/svgs/navi/arrow-down.svg';
import ExpandedIcon from '$resources/svgs/navi/arrow-up.svg';
import GTButton from '$src/components/shared/Atoms/GTButton';
import { useParams } from 'react-router';

enum ISortingOption {
    AToZ = 1,
    ZToA = 2,
    IsViewed = 3,
    IsNotViewed = 4,
    IsBookmarked = 5,
    IsNotBookmarked = 6
}

/**
 * Displays one Glossar with its terms
 *
 * @export
 * @param {IProps} props
 * @return {*} 
 */
export default function GlossarDetail() {
    const glossarIdParam = useParams<{ glossarIdParam: string }>();
    const itemIdParam = useParams<{ itemId: string }>();
    const contextParam = useParams<{ context: string }>();
    const catIdParam = useParams<{ catId: string }>();
    const asIdParam = useParams<{ asId: string }>();
    const tpIdParam = useParams<{ tpId: string }>();
    const tpeIdParam = useParams<{ tpeId: string }>();

    const glossarId: number = glossarIdParam != null && !isNaN(Number(glossarIdParam.glossarIdParam)) ? Number(glossarIdParam.glossarIdParam) : 0;
    const itemId: number = itemIdParam != null && !isNaN(Number(itemIdParam.itemId)) ? Number(itemIdParam.itemId) : 0;
    const context: number = contextParam != null && !isNaN(Number(contextParam.context)) ? Number(contextParam.context) : 0;
    const catId: number = catIdParam != null && !isNaN(Number(catIdParam.catId)) ? Number(catIdParam.catId) : 0;
    const asId: number = asIdParam != null && !isNaN(Number(asIdParam.asId)) ? Number(asIdParam.asId) : 0;
    const tpId: number = tpIdParam != null && !isNaN(Number(tpIdParam.tpId)) ? Number(tpIdParam.tpId) : 0;
    const tpeId: number = tpeIdParam != null && !isNaN(Number(tpeIdParam.tpeId)) ? Number(tpeIdParam.tpeId) : 0;

    const tr = Session.instance.storage.translation;
    const loggerLocality = 'Components.GlossaryDetail';
    const className = 'GlossarDetail';


    const [filter, setFilter] = useState<string>('');
    const [itemsToDisplay, setItemsToDisplay] = useState<number>(50);
    const [openedPanelId, setOpenedPanelId] = useState<number>(0);
    const [glossar, setGlossar] = useState<Glossar>();
    const [hasError, setHasError] = useState<boolean>(false);
    const [item, setItem] = useState<Item>();
    const memoizedGlossar = useMemo(() => glossar && glossar.glossaryTermSummaries && glossar.glossaryTermSummaries.filter(termSummary =>
        (termSummary.title.toLocaleLowerCase().includes(filter) ||
            termSummary.synonyms.toLocaleLowerCase().includes(filter) ||
            termSummary.abbreviation.toLocaleLowerCase().includes(filter) ||
            termSummary.text.toLocaleLowerCase().includes(filter))), [filter, glossar]);


    useEffect(() => {
        document.title = globalConfig.appProperties.title + ': ' + tr.GetString('Glossary:Title');
    });

    useEffect(() => {
        let ignore = false;
        const methodName = `${className}:fetchData()`;

        async function fetchData() {
            if (!ignore) {
                if (context != EItemDetailCallerContextType.MyGlossary) {
                    await Session.instance.storage.item.getItemDetail(itemId, context, tpId).then((result) => {
                        if (result) {
                            if (isSuccess<Item>(result)) {
                                setItem(result);
                            } else {
                                const errorMessage = `${methodName} failed to get Item with id=${itemId}`;
                                Logger.log(loggerLocality, `${methodName} called`);
                                console.log(errorMessage)
                            }
                        }
                    });
                }
                await GlossaryService.instance.getGlossar(glossarId)
                    .then((result) => {
                        if (isSuccess<Glossar>(result)) {
                            setGlossar(result);
                        } else {
                            const errorMessage = `${methodName} failed to get glossar with id=${glossarId}`;
                            Logger.log(loggerLocality, `${methodName} called`);
                            console.log(errorMessage);
                            setHasError(true);
                        }
                    });
            }
        }
        fetchData();
        return () => { ignore = true; }
    }, [glossarId]);

    function renderSubTitle() {
        return (
            <Translate>Glossary:SubTitle</Translate>
        )
    }

    async function onToggleOpenTerm(termId: number) {
        const methodName = `${className}:onToggleOpenTerm()`;
        if (openedPanelId !== termId) {
            setOpenedPanelId(termId);
            await GlossaryService.instance.setIsViewed(termId).then((result) => {
                if (isSuccess<BooleanResponse>(result) && result.status && glossar != null) {
                    const glossarCopy = { ...glossar };
                    const viewedTermSummary = glossarCopy?.glossaryTermSummaries.find(termSummary => termSummary.id === termId);
                    if (viewedTermSummary && !viewedTermSummary.isViewed) {
                        viewedTermSummary.isViewed = true;
                        setGlossar(glossarCopy);
                    } else {
                        const errorMessage = `${methodName} failed to setIsViewd  with id=${termId}`;
                        Logger.log(loggerLocality, `${methodName} called`);
                        console.log(errorMessage);
                    }
                }
            });
        } else {
            setOpenedPanelId(0);
        }
    }

    async function onToggleBookmarkedTerm(termId: number, isBookmarked: boolean) {
        const methodName = `${className}:onToggleBookmarkedTerm()`;
        await GlossaryService.instance.setBookmarked(termId, !isBookmarked).then((result) => {
            if (isSuccess<BooleanResponse>(result) && result.status && glossar != null) {
                const glossarCopy = { ...glossar };
                const termSummary = glossarCopy?.glossaryTermSummaries.find(termSummary => termSummary.id === termId);
                if (termSummary) {
                    termSummary.isBookmarked = !isBookmarked;
                    setGlossar(glossarCopy);
                } else {
                    const errorMessage = `${methodName} failed to setBookmarked  with id=${termId}`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            }
        });
    }

    function onChangeGlossarySorting(event: DropDownListChangeEvent) {
        const target = event.target;
        if (glossar) {
            const glossarCopy = { ...glossar };

            const sorting: ISortingOption = Number(target.value.id);
            switch (sorting) {
                case ISortingOption.ZToA:
                    glossarCopy.glossaryTermSummaries?.sort((element1: GlossaryTermSummary, element2: GlossaryTermSummary) =>
                        SortingAlgorithm.sortByColumnAndDirection(element1, element2, "title", ESortDirection.Down));
                    break;
                case ISortingOption.IsViewed:
                    glossarCopy.glossaryTermSummaries?.sort((element1: GlossaryTermSummary, element2: GlossaryTermSummary) =>
                        SortingAlgorithm.sortByColumnAndDirection(element1, element2, "isViewed", ESortDirection.Up));
                    break;
                case ISortingOption.IsNotViewed:
                    glossarCopy.glossaryTermSummaries?.sort((element1: GlossaryTermSummary, element2: GlossaryTermSummary) =>
                        SortingAlgorithm.sortByColumnAndDirection(element1, element2, "isViewed", ESortDirection.Down));
                    break;
                case ISortingOption.IsBookmarked:
                    glossarCopy.glossaryTermSummaries?.sort((element1: GlossaryTermSummary, element2: GlossaryTermSummary) =>
                        SortingAlgorithm.sortByColumnAndDirection(element1, element2, "isBookmarked", ESortDirection.Up));
                    break;
                case ISortingOption.IsNotBookmarked:
                    glossarCopy.glossaryTermSummaries?.sort((element1: GlossaryTermSummary, element2: GlossaryTermSummary) =>
                        SortingAlgorithm.sortByColumnAndDirection(element1, element2, "isBookmarked", ESortDirection.Down));
                    break;
                default:
                    glossarCopy.glossaryTermSummaries?.sort((element1: GlossaryTermSummary, element2: GlossaryTermSummary) => SortingAlgorithm.sortByColumnAndDirection(element1, element2, "title", ESortDirection.Up));
            }
            setGlossar(glossarCopy);
        }
    }

    function isFilterTextExisting(glossar: Glossar) {
        const tmp = glossar.glossaryTermSummaries.find(termSummary => termSummary.title.toLocaleLowerCase().includes(filter) || termSummary.synonyms.toLocaleLowerCase().includes(filter) || termSummary.abbreviation.toLocaleLowerCase().includes(filter) ||
            termSummary.text.toLocaleLowerCase().includes(filter));
        if (tmp == undefined) {
            return false;
        } else {
            return true;
        }
    }

    const sortData = [
        { id: ISortingOption.ZToA, text: tr.GetString("Glossary:" + ISortingOption[ISortingOption.ZToA]), value: ISortingOption.ZToA.valueOf() },
        { id: ISortingOption.IsViewed, text: tr.GetString("Glossary:" + ISortingOption[ISortingOption.IsViewed]), value: ISortingOption.IsViewed.valueOf() },
        { id: ISortingOption.IsNotViewed, text: tr.GetString("Glossary:" + ISortingOption[ISortingOption.IsNotViewed]), value: ISortingOption.IsNotViewed.valueOf() },
        { id: ISortingOption.IsBookmarked, text: tr.GetString("Glossary:" + ISortingOption[ISortingOption.IsBookmarked]), value: ISortingOption.IsBookmarked.valueOf() },
        { id: ISortingOption.IsNotBookmarked, text: tr.GetString("Glossary:" + ISortingOption[ISortingOption.IsNotBookmarked]), value: ISortingOption.IsNotBookmarked.valueOf() },
    ]

    const defaultSortData = { id: ISortingOption.AToZ, text: tr.GetString("Glossary:" + ISortingOption[ISortingOption.AToZ]), value: ISortingOption.AToZ.valueOf() }

    if (glossar == null && !hasError) {
        return <ProgressSpinner />;
    } else if (glossar != null) {
        const bcElements: BreadCrumbElement[] = [];
        switch (context) {
            case EItemDetailCallerContextType.Catalog: {
                let lessonBreadCrumb = new BreadCrumbElement();
                if (tpId === itemId) {
                    lessonBreadCrumb =
                    {
                        navigationPath: `/trainingPlanDetail/${itemId}?context=${context}&catId=${catId}&asId=${asId}`,
                        title: item!.title
                    }
                }
                else {
                    lessonBreadCrumb =
                    {
                        navigationPath: `/itemDetail/${itemId}?context=${context}&catId=${catId}&asId=${asId}&tpId=${tpId}&tpe=${tpeId}`,
                        title: item!.title
                    }
                }
                bcElements.push(
                    {
                        navigationPath: "/catalog/1",
                        title: Session.instance.storage.translation.GetString('Navigation:Catalog')
                    },
                    lessonBreadCrumb,
                    {
                        navigationPath: `/glossar/${glossarId}/${context}/${item?.itemId}/${catId}/${asId}/${tpId}/${tpeId}`,
                        title: glossar.title
                    }
                );
                break;
            }
            case EItemDetailCallerContextType.MyGlossary:
                bcElements.push(
                    {
                        navigationPath: "/more",
                        title: Session.instance.storage.translation.GetString('Navigation:More')
                    },
                    {
                        navigationPath: "/myGlossaries",
                        title: Session.instance.storage.translation.GetString('Navigation:Glossary')
                    },
                    {
                        navigationPath: `/glossar/${glossarId}/${context}/0/0/0/0/0`,
                        title: glossar.title ? glossar.title : Session.instance.storage.translation.GetString('MyGlossary:MyGlossary')
                    }
                );
                break;
        }


        return (
            <div className="l-container">
                <div>
                    <MenuBreadCrumb breadCrumbElementsToAppend={bcElements} />
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title">
                        {glossar.title ? glossar.title : <Translate>MyGlossary:MyGlossary</Translate>}
                    </Heading>
                    <div className="glossar-details-subTitle">
                        {renderSubTitle()}
                    </div>
                    <div className="glossar-details__filter">
                        <div className="glossar-details__search">
                            <GTSearch
                                showSearchButton={true}
                                onSearchTextChanged={(searchText) => setFilter(searchText.toLocaleLowerCase())}
                                searchPlaceholder={tr.GetString("Glossary:SearchPlaceholder")}
                                defaultInputProps={{ "aria-label": tr.GetString('Glossary:AriaSearch') }}
                                textFilterSize={ETextFilterSize.Large}
                            />
                        </div>
                        <div className="glossar-details__dropDown">
                            <DropDownList
                                data={sortData}
                                onChange={(e) => onChangeGlossarySorting(e)}
                                defaultValue={defaultSortData}
                                defaultItem={defaultSortData}
                                textField="text"
                                label={tr.GetString("Glossary:SortDropDownList")} />
                        </div>
                    </div>

                    <div className="glossar-details__container" >
                        {memoizedGlossar && glossar.glossaryTermSummaries && isFilterTextExisting(glossar) ?
                            <> {memoizedGlossar.slice(0, itemsToDisplay).map(termSummary => {
                                    return (
                                        <div className={`glossar-details__item`} key={"glossaryTerm_" + termSummary.id}>
                                                <div className={`glossar-details__item__header`}>
                                                    <div className="glossar-details__item-title" onClick={() => onToggleOpenTerm(termSummary.id)}>
                                                        <div className="glossar-details__item-title__synonyms">
                                                            {termSummary.synonyms}
                                                        </div>
                                                        <Heading headingLevel={2} cssClass={`heading__Level4 ${!termSummary.isViewed ? "glossar-details__item--not-viewed" : ""}`}>{termSummary.title}</Heading>
                                                    </div>
                                                    <div className="glossar-details__item-bookmarked" >

                                                        {termSummary.isBookmarked ?
                                                            <GTIconButton
                                                                id={`GlossaryTermBookmarked${termSummary.id}`}
                                                                ariaLabel={Session.instance.storage.translation.GetString('Glossary:Bookmarked')}
                                                                tooltipText={Session.instance.storage.translation.GetString('Glossary:Bookmarked')}
                                                                onClick={() => onToggleBookmarkedTerm(termSummary.id, termSummary.isBookmarked)}>
                                                                <InlineSVG src={BookmarkedIcon} />
                                                            </GTIconButton> : <GTIconButton
                                                                id={`GlossaryTermNotBookmarked${termSummary.id}`}
                                                                ariaLabel={Session.instance.storage.translation.GetString('Glossary:NotBookmarked')}
                                                                tooltipText={Session.instance.storage.translation.GetString('Glossary:NotBookmarked')}
                                                                onClick={() => onToggleBookmarkedTerm(termSummary.id, termSummary.isBookmarked)}>
                                                                <InlineSVG src={NotBookmarkedIcon} />
                                                            </GTIconButton>}
                                                        {openedPanelId === termSummary.id ? <GTIconButton
                                                            id={`GlossaryTermClose${termSummary.id}`}
                                                            ariaLabel={Session.instance.storage.translation.GetString('Glossary:Close')}
                                                            tooltipText={Session.instance.storage.translation.GetString('Glossary:Close')}
                                                            onClick={() => onToggleOpenTerm(termSummary.id)}>
                                                            <InlineSVG src={ExpandedIcon} />
                                                        </GTIconButton> : <GTIconButton
                                                            id={`GlossaryTermOpen${termSummary.id}`}
                                                            ariaLabel={Session.instance.storage.translation.GetString('Glossary:Open')}
                                                            tooltipText={Session.instance.storage.translation.GetString('Glossary:Open')}
                                                            onClick={() => onToggleOpenTerm(termSummary.id)}>
                                                            <InlineSVG src={CollapsedIcon} />
                                                        </GTIconButton>}
                                                    </div>
                                                </div>
                                                {openedPanelId === termSummary.id &&
                                                    <GlossaryTerm glossarieTermId={termSummary.id} />
                                                }
                                        </div>
                                    );
                                })}
                                {memoizedGlossar.length > itemsToDisplay && <GTButton onClick={() => setItemsToDisplay(itemsToDisplay + 20)}>
                                    <Translate>Glossary:DisplayMore</Translate>
                                </GTButton>}
                            </>

                            : <NoDataFound message={tr.GetString('Glossary:NoGlossarieTermFound')} />}

                    </div>
                </div>
                <div>&nbsp;</div>
            </div>
        );
    }
    else {
        return (
            <div className="l-container">
                {hasError ?
                    <ErrorMessage errorMessage={tr.GetString("ErrorMessage:FailedLoadingGlossar")} appearance="box" /> :
                    <ErrorMessage errorMessage={tr.GetString("ErrorMessage:Error")} appearance="box" />
                }
            </div>
        );
    }

}