import { JsonObject, JsonProperty } from 'json2typescript';

/**
 *This Model is holding the data for the skill
 */
@JsonObject
export class EvaluationSkill {

    /** skillId  */
    @JsonProperty('skillId', Number, false)
    public skillId: number = 0;

    /** skillName*/
    @JsonProperty('skillName', String, false)
    public skillName: string = "";

    /** skillDisplayName*/
    @JsonProperty('skillDisplayName', String, false)
    public skillDisplayName: string = "";
}
