import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class BookingsFilter {

    @JsonProperty('filterCourse', String, false)
    public filterCourse: string = '';

    @JsonProperty('filterClass', String, false)
    public filterClass: string = '';

    @JsonProperty('filterMonth', Number, false)
    public filterMonth: number = 0;

    @JsonProperty('filterYear', Number, false)
    public filterYear: number = 0;

    constructor(filterCourse: string, filterClass: string,  filterMonth: number, filterYear: number){
        this.filterCourse = filterCourse;
        this.filterClass = filterClass;
        this.filterCourse = filterCourse;
        this.filterMonth = filterMonth;
        this.filterYear = filterYear;
    }

}