import Logger from '$src/core/Logger';
import SearchService from '$src/core/Services/SearchService';
import SessionCache from '$src/storage/cache/SessionCache';
import { LegacySearchResponse } from '$src/storage/models/search/legacy/LegacySearchResponse';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';

const CACHING_DURATION = 300;

/**
 * Wrapper for caching search results in session
 *
 * @export
 * @class SearchStorage
 * @extends {SessionCache<SearchResponse, Item>}
 */
export default class SearchStorage extends SessionCache<string, LegacySearchResponse> {
    protected className = 'SearchStorage';
    protected loggerLocality = 'Storage.SearchStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    /**
     * generates key from input params and checks for searchresponse in sessioncache
     * 
     * @param {string} language
     * @param {string} searchText
     * @param {string} itemsAsString
     * @param {(Date | null)} dateFrom
     * @param {(Date | null)} dateTo
     * @param {boolean} [forceLoadingFromServer=true]
     * @returns {(Promise<SearchResponse | null>)}
     * @memberof SearchStorage
     */
    public async getSearchItems(
        language: string,
        searchText: string,
        itemsAsString: string,
        dateFrom: Date | null,
        dateTo: Date | null,
        forceLoadingFromServer = true): Promise<LegacySearchResponse | GtError | null> {
        const methodName = `${this.className}:getSearchItems()`;

        const dateFromKeyPart = dateFrom instanceof Date ? dateFrom.getTime() : '';
        const dateToKeyPart = dateTo instanceof Date ? dateTo.getTime() : '';
        const cacheKey = `${searchText}-${itemsAsString}-${dateFromKeyPart}-${dateToKeyPart}`;
        if (this.isObjectInCache(cacheKey) && !forceLoadingFromServer) {
            Logger.log(this.loggerLocality, `${methodName} getting SearchResponse from cache, cacheKey=${cacheKey}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting SearchResponse from server, cacheKey=${cacheKey}`);
            const response = await SearchService.instance.getSearchItems(language, searchText, itemsAsString, dateFrom, dateTo);
            if(isSuccess<LegacySearchResponse>(response))
            {
                this.saveObjectToCache(cacheKey, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get SearchResponse from server, cacheKey=${cacheKey}.`);
            }
        }
        return this.getObjectFromCache(cacheKey);
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}