import { Group } from "$src/storage/models/Group";

export function filterListData <T>(d: T) {
    if (d instanceof Group) {
        if (d.isBossEmployeeGroup) { return false }
        if (globalConfig.myTeamProperties.employeeList.employeeGroupColumnTypeFilter.length === 0) {
            return true
        }
        return globalConfig.myTeamProperties.employeeList.employeeGroupColumnTypeFilter.find(v =>
            v.toLocaleUpperCase() === d.groupTypeCode.toLocaleUpperCase()) != null;
    }
    return true;
}