import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class AttributeValue {
    [key: string]: number | string;

    @JsonProperty('typeId', Number, false)
    public typeId: number = 0;

    @JsonProperty('id', Number, false)
    public id: number = 0;    

    @JsonProperty('language', String, false)
    public language: string = '';

    @JsonProperty('code', String, false)
    public code: string = '';       

    @JsonProperty('text', String, false)
    public text: string = '';    

    @JsonProperty('sortOrder', String, false)
    public sortOrder: string = '';    
}
