import Session from '$src/core/Session';
import * as signalR from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import { Notification } from '$storage/models/InAppNotifications/Notification'
import { isSuccess } from '$src/util/Result';
import { Tooltip } from '$components/shared/Tooltip';

//Icon
import IconNotificationBell from '$resources/svgs/navi/notificationBell.svg'
import InAppNotificationService from '$src/core/Services/InAppNotificationService';
import IANotificationList from './IANotificationList';
import Logger from '$src/core/Logger';


export default function IANotificationMain() {
    const loggerLocality = 'Components.IANotificationMain';
    // SignalR
    const [hubConnection, setHubConnection] = useState<signalR.HubConnection>();
    const [, setIsConnected] = useState<boolean>(false);
    // Data
    const [notifications, setNotifications] = useState<Notification[]>([]);
    // Viewstate
    const [isNotificaitonListOpen, setIsNotificaitonListOpen] = useState<boolean>(false);

    useEffect(() => {
        async function connectSignalR() {
                const hc = new signalR.HubConnectionBuilder()
                    .withUrl(globalConfig.inAppNotificationProperties.serviceConnection + "/notificationHub", { accessTokenFactory: () => Session.instance.jwtToken })
                    .configureLogging(signalR.LogLevel.Debug)
                    .withAutomaticReconnect()
                    .build();

                hc.on("NewNotification", (message) => {
                    console.log(notifications);
                    if (isSuccess<Notification>(message)) {
                        Logger.log(loggerLocality, 'IANP sent a new message', message);
                        setNotifications(old => [message, ...old.filter(x => x.notificationID != message.notificationID)]);
                    }
                });

                hc.onreconnecting(() => {
                    if (!Session.instance.isUserLoggedIn) {
                        hc.stop();
                    }
                });

                try {
                    await hc.start();
                } catch (err) {
                    console.error(err);
                }
                setHubConnection(hc);

                if (hubConnection != null) {
                    Logger.log(loggerLocality, 'IANP connected');
                    setIsConnected(true);
                }
            }
            async function loadInitNotifications() {
                Logger.log(loggerLocality, 'Loading Init Notifications');
                const notifications = await InAppNotificationService.instance.getNotifications();
                setNotifications(notifications);
            }

            function initService() {
                const ianpBaseurl: string = globalConfig.inAppNotificationProperties.serviceConnection;
                InAppNotificationService.instance.init(ianpBaseurl);
            }
            if (Session.instance.jwtToken != null && Session.instance.isUserLoggedIn) {
                Logger.log(loggerLocality, 'Connecting to IANP');
                connectSignalR();
                initService();
                loadInitNotifications();
            }
    }, []);

    // Timeout for the OnBlur Method
    let timeoutId: number;

    function onNotificationPanelBlur() {
        timeoutId = setTimeout(() => {
            setIsNotificaitonListOpen(false)
        })
    }

    function onFocusHandler() {
        clearTimeout(timeoutId);
    }

    return (
        <React.Fragment>
            <div className="menu__icon ianotifications__menu-button"
                onFocus={() => onFocusHandler()}
                onBlur={() => onNotificationPanelBlur()}>
                <button
                    aria-label={Session.instance.storage.translation.GetString('Navigation:Notifications')}
                    data-tip={Session.instance.storage.translation.GetString('Navigation:Notifications')}
                    data-for="menuIconNotificationsTooltip"
                    onClick={() => setIsNotificaitonListOpen(!isNotificaitonListOpen)}>
                    {notifications.filter(n => n.readDate == null).length > 0 ? (
                        <div className={"ianotifications__unread-notifications--count"}> {notifications.filter(n => n.readDate == null).length}</div>
                    ) : ''}
                    <InlineSVG src={IconNotificationBell} />
                    <Tooltip id="menuIconNotificationsTooltip" place="bottom" />
                </button>

                {isNotificaitonListOpen ? (<IANotificationList onClick={() => setIsNotificaitonListOpen(false)} notifications={notifications} />) : ''}
            </div>
        </React.Fragment>

    );
}