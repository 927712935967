import RequestDetail from '$components/myEmployees/RequestDetail';
import { ItemBreadCrumb } from '$src/components/breadCrumb/ItemBreadCrumb';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import { EItemDetailCallerContextType, EItemDetailCallerType, EItemSubType } from '$src/storage/models/enums';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { RouteComponentProps } from 'react-router';

interface IMatchParams {
    registrationId: string;
}
type IProps = RouteComponentProps<IMatchParams>

interface IURLParamState {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    itemSubType: EItemSubType;
    bossRelationCode: string;
}

interface IState extends IURLParamState {
    errorMessage: string;
    externalCourseRegistration: ExternalCourseRegistration | undefined;
    isDataLoaded: boolean;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export default class RequestDetailRoutePage extends React.Component<IProps, IState> {
    private registrationId: number;
    protected defaultTeamMatch = 'default';

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            externalCourseRegistration: undefined,
            isDataLoaded: false,
            itemDetailCallerContextType: EItemDetailCallerContextType.Undefined,
            itemSubType: EItemSubType.Undefined,
            bossRelationCode: '',
        }
    }

    public async componentDidMount() {
        // Get URL Parameters
        const partialStateUrlParamState = this.getURLParameters();

        // Set State
        this.setState({ ...partialStateUrlParamState });

        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;

        const { registrationId } = this.props.match.params;
        this.registrationId = registrationId != null && !isNaN(Number(registrationId)) ? Number(registrationId) : 0;

        if (!this.state.isDataLoaded) {
            await this.getExternalCourseRegistration(partialStateUrlParamState.itemSubType);
        }
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return (<ProgressSpinner />)
        } else {
            let itemTitle = '';
            if (this.state.externalCourseRegistration && this.state.externalCourseRegistration.externalCourse) {
                itemTitle = this.state.externalCourseRegistration.externalCourse.title;
            }
            return (
                <div>
                    <div className="l-container">
                        <ItemBreadCrumb
                            itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                            parentAssignmentId={0}
                            parentCatalogFolderId={0}
                            parentTrainingPlanId={0}
                            itemDetailType={EItemDetailCallerType.ExternalCourse}
                            itemSubType={this.state.itemSubType}
                            itemTitle={itemTitle}
                            registraionId={this.registrationId}
                            bossRelationCode={this.state.bossRelationCode}
                            {...this.props} />
                        {this.state.errorMessage.length > 0 ?
                            (<span className={'input-message error'}>
                                <Translate>{this.state.errorMessage}</Translate>
                            </span>)
                            :
                            (<RequestDetail 
                                registrationId={this.registrationId}
                                parentHeadingLevel={1}
                                courseType={this.state.itemSubType}
                                itemContext={this.state.itemDetailCallerContextType}
                                {...this.props} />)
                        }


                    </div>
                </div>
            )
        }

    }

    private getURLParameters(): IURLParamState {
        const parameters = new URLSearchParams(window.location.search);

        const urlParamContext = parameters.get('context');
        const urlParamSubType = parameters.get('itemSubType');
        const urlParamBossRelation = parameters.get('bossRelationCode');

        let paramContext: EItemDetailCallerContextType = EItemDetailCallerContextType.Undefined;
        let paramSubType: EItemSubType = EItemSubType.Undefined;
        let paramBossRelation: string = '';

        paramContext = urlParamContext != null && !isNaN(Number(urlParamContext)) ?
            Number(urlParamContext) : EItemDetailCallerContextType.Undefined;

        paramSubType = urlParamSubType != null && !isNaN(Number(urlParamSubType)) ?
            Number(urlParamSubType) : EItemSubType.Undefined;

        paramBossRelation = urlParamBossRelation != null ? urlParamBossRelation : this.defaultTeamMatch;

        return { itemDetailCallerContextType: paramContext, itemSubType: paramSubType, bossRelationCode: paramBossRelation }
    }

    private async getExternalCourseRegistration(itemSubType: EItemSubType) {
        const registration = await ExternalCourseService.instance.getExternalCourseRegistration(this.registrationId, itemSubType);
        if (isSuccess<ExternalCourseRegistration>(registration)) {
            this.setState({ externalCourseRegistration: registration, isDataLoaded: true })
        } else {
            if (registration.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(registration.detailedObject.subStatusCode), isDataLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesRegistrationFailed', isDataLoaded: true });
            }
        }
    }
}