import React from "react"


/**
 * **GTHorizontalTable Props**
 *
 * @export
 * @interface IGTHorizontalTableProps
 */
export interface IGTHorizontalTableProps  {
    /**
     * The GTHorizontalTableHeaderRow element -> see Atoms/GTHorizontalTableRowHeader
     */
    headerElement: JSX.Element;
    /**
     * A List of GTHorizontalTableRow -> see Atoms/GTHorizontalTableRow
     */
    rows: JSX.Element[];
    /**
     * **Optional** Table class name, default is 'div-table__horizontal-table'
     */
    tableClassName?: string;
}



/**
 * Global Teach Table Horizontal Table Component
 */
export default function GTHorizontalTable(props: IGTHorizontalTableProps) {
    return (
        <div role="table" className={props.tableClassName}>
            <div role="rowgroup">
                {props.headerElement}
            </div>
            <div role="rowgroup">
                {props.rows}
            </div>
        </div>
    )
}

GTHorizontalTable.defaultProps = {
    tableClassName: 'div-table__horizontal-table'
}
