import ServiceClient from '$core/ServiceClient';
import { PaymentClassSearchFilter } from '$src/storage/models/Payment/PaymentClassSearchFilter';
import { PaymentClassSearchResponse } from '$src/storage/models/Payment/PaymentClassSearchResponse';
import { PaymentUserSearchFilter } from '$src/storage/models/Payment/PaymentUserSearchFilter';
import { PaymentUserSearchResponse } from '$src/storage/models/Payment/PaymentUserSearchResponse';
import { ValidateUserRegistrationOnClassResponse } from '$src/storage/models/Payment/ValidateUserRegistrationOnClassResponse';
import GtError from '$src/util/GtError';
import Session from '$core/Session';
import { BookingJournalFilterRequest } from '$src/storage/models/Payment/BookingJournalFilterRequest';
import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import { PaymentCheckoutItemHistoryResponse } from '$src/storage/models/Payment/PaymentCheckoutItemHistoryResponse';

/**
 * PaymentAdminService provides helper services that are used in the context of creating payments as admin/boss
 */
export default class PaymentAdminService extends ServiceClient {
    protected static _instance: PaymentAdminService | null = null;

    protected className = 'PaymentAdminService';
    protected loggerLocality = 'PaymentAdminService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PaymentAdminService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Search for class
     * @param filter search text and restrictions
     * @param bossRelationCode bossRelationCode optionally param is used for the myTeam
     * @returns List of class infos
     */
    public async getClassSearch(filter: PaymentClassSearchFilter, bossRelationCode: string = ""): Promise<PaymentClassSearchResponse[] | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        return await this.post<PaymentClassSearchResponse[]>(
            'paymentAdmin/classSearch',
            filter,
            PaymentClassSearchResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Search for user
     * @param filter search text
     * @param bossRelationCode bossRelationCode optionally param is used for the myTeam
     * @returns List of user infos
     */
    public async getUserSearch(filter: PaymentUserSearchFilter, bossRelationCode: string = ""): Promise<PaymentUserSearchResponse[] | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        return await this.post<PaymentUserSearchResponse[]>(
            'paymentAdmin/userSearch',
            filter,
            PaymentUserSearchResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Check if user can be registered on class
     * @param userId 
     * @param classId 
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation
     */
    public async validateUserForClass(userId: number, classId: number, bossRelationCode: string = ""): Promise<ValidateUserRegistrationOnClassResponse | GtError> {
        return await this.post<ValidateUserRegistrationOnClassResponse>(
            'paymentAdmin/validateUserRegistrationOnClass',
            {},
            ValidateUserRegistrationOnClassResponse,
            undefined,
            [
                { name: 'userId', value: userId.toString() },
                { name: 'classId', value: classId.toString() },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Get payment booking journal with given filter parameters
     * @param filter 
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation
     * @returns 
     */
    public async getPaymentBookingJournal(filter: BookingJournalFilterRequest | undefined, bossRelationCode: string = ""): Promise<PaymentBookingJournalResponse[] | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        if (!filter) {
            filter = new BookingJournalFilterRequest();
        }

        return await this.post<PaymentBookingJournalResponse[]>(
            'paymentAdmin/bookingJournal',
            filter,
            PaymentBookingJournalResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Change the class of a purchased booking and return new booking journal entry with updated status
     * @param oicdId
     * @param userId
     * @param itemId
     * @param newClassId 
     * @param comment
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation
     * @returns 
     */
    public async changeClass(pcoiId: number, userId: number, itemId: number, newClassId: number, comment: string | undefined, bossRelationCode: string = ""): Promise<PaymentBookingJournalResponse | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        return await this.post<PaymentBookingJournalResponse>(
            'paymentAdmin/changeClass',
            { pcoiId, userId, itemId, newClassId, comment },
            PaymentBookingJournalResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Change the user of a purchased booking and return new booking journal entry with updated status
     * @param pcoiId 
     * @param newUserId 
     * @param comment
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation 
     * @returns 
     */
    public async changeUser(pcoiId: number, newUserId: number, comment: string | undefined, bossRelationCode: string = ""): Promise<PaymentBookingJournalResponse | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        return await this.post<PaymentBookingJournalResponse>(
            'paymentAdmin/changeUser',
            { pcoiId, newUserId, comment },
            PaymentBookingJournalResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Cancel the user of a purchased booking and return new booking journal entry with updated status
     * @param pcoiId 
     * @param comment 
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation
     * @returns 
     */
    public async cancelUser(pcoiId: number, comment: string | undefined, bossRelationCode: string = ""): Promise<PaymentBookingJournalResponse | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        return await this.post<PaymentBookingJournalResponse>(
            'paymentAdmin/cancelUser',
            { pcoiId, comment },
            PaymentBookingJournalResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Returns history of a payment checkout item
     * @param checkoutId 
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation
     * @returns 
     */
    public async getCheckoutItemHistory(checkoutId: number, bossRelationCode: string = ""): Promise<PaymentCheckoutItemHistoryResponse[] | GtError> {
        return await this.get<PaymentCheckoutItemHistoryResponse[]>(
            'paymentAdmin/checkoutItemHistory',
            PaymentCheckoutItemHistoryResponse,
            undefined,
            [
                { name: 'checkoutItemId', value: checkoutId.toString() },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }

    /**
     * Saves the updated comment to checkout item
     * @param pcoiId 
     * @param comment 
     * @param bossRelationCode this is used for the myTeam when this param are null the user must have the paymentadmin role for get the data, when the param are not null 
     * the api check if the boss is boss of the given relation and get data only for his employees in the given relation
     * @returns 
     */
    public async saveComment(pcoiId: number, comment: string | undefined, bossRelationCode: string = ""): Promise<PaymentBookingJournalResponse | GtError> {
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        return await this.post<PaymentBookingJournalResponse>(
            'paymentAdmin/saveComment',
            { pcoiId, comment },
            PaymentBookingJournalResponse,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'bossRelationCode', value: bossRelationCode }
            ]
        );
    }
}