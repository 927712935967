import React from 'react';
import { NavigationPointContainer } from '$src/components/layout/menu/NavigationPointContainer';
import { NavigationPoint } from '$src/components/layout/menu/NavigationPoint';
import Session from '$src/core/Session';

interface IProps {
    bossRelation: string;
}

export default function FunctionsList(props: IProps) {
    const defaultTeamMatch = 'default';

    function getSectionConfigurationByBossRelationCode(bossRelationCode: string) {
        const defaultBossRelation = Session.instance.getLoggedInUserBossrelations().find(r => r.isDefault);
        if (bossRelationCode === defaultTeamMatch && defaultBossRelation != null) {
            bossRelationCode = defaultBossRelation.bossTypeCode;
        }
        let sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
            item => item.id.toLocaleLowerCase() === bossRelationCode.toLocaleLowerCase());
        if (sectionsConfig == null) {
            sectionsConfig = globalConfig.myTeamProperties.relationConfiguration.find(
                item => item.id.toLocaleLowerCase() === defaultTeamMatch);
        }
        sectionsConfig?.sectionsOrderAndDisplay.sort((n1, n2) => n1.order - n2.order);
        return sectionsConfig;
    }

    function renderFunctionsPanel(): JSX.Element[] | null {
        const elements: JSX.Element[] = [];
        const conf = getSectionConfigurationByBossRelationCode(props.bossRelation);

        if (conf !== undefined) {
            conf.functionPanels.map(panel => {
                if (panel.show) {
                    elements.push(<NavigationPoint
                        link={`${panel.link}/${props.bossRelation}`}
                        text={panel.text}
                        key={panel.id}
                        id={panel.id}
                        openNewWindow={false} />)
                }

            })
        }
        return elements;
    }

    return (
        <NavigationPointContainer>
            {renderFunctionsPanel()}
        </NavigationPointContainer>
    )
}