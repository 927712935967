import { JsonObject, JsonProperty } from 'json2typescript';
import { ECheckoutStatus, EPayLinkUsage } from '$storage/models/enums';

/**
 * Response object for PurchaseService.checkout 
 */
@JsonObject
export class CheckoutResponse {
    /** current status of the checkout */
    @JsonProperty('status', Number, false)
    public status: ECheckoutStatus = ECheckoutStatus.Pending;

    /** payment provider URL to be used when payment is required or '' if no payment needed */
    @JsonProperty('paymentProviderUrl', String, false)
    public paymentProviderUrl: string = '';

    /** defines how the payment link should be used */
    @JsonProperty('payLinkUsage', Number, false)
    public payLinkUsage: EPayLinkUsage = EPayLinkUsage.SameTab;

    /** ReferenceCode of this purchase */
    @JsonProperty('referenceCode', String, false)
    public referenceCode: string = '';
}