import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class PerformanceReviewCommentResponse {

    /** Boolean value  */
    @JsonProperty('status', Boolean, false)
    public status: boolean = false;

    /** Boolean value  */
    @JsonProperty('editWarning', Boolean, false)
    public editWarning: boolean = false;
   
}
