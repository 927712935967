import { FileDataRequest } from "$src/storage/models/RequestObjects/FileDataRequest";
import React from "react";
import GTFile, { UploadedFileTypes } from "$src/components/shared/Atoms/GTFile";
import { EFileType } from "$src/storage/models/enums";
import Session from "$src/core/Session";
import GTHorizontalTableHeaderRow, { GTHeaderContent } from "$components/shared/Atoms/GTHorizontalTableHeaderRow";
import GTHorizontalTable from "$components/shared/Molecules/GTHorizontalTable";

/**
 * GT FileList Props
 */
export interface IGTFileListProps {
    /**
     * List of Files
     */
    files: File[];
    /**
     * List of the files for api requests which also contains the file type {@see EFileType}
     */
    fileDataRequests: FileDataRequest[];
    /**
     * The default file type {@see EFileType}
     */
    defaultFileType: EFileType;
    /**
     * Callback if the File list change (e.g. a file was deleted)
     */
    onFileChange: (files: File[]) => void;
    /**
     * Callback if the File list change (e.g. a file was deleted or the file type was changed)
     */
    onFileDataRequestChange: (fileDataRequests: FileDataRequest[]) => void;
    /**
     * **Optional** Flag if the type selection should be displayed
     */
    showFileTypeSelection?: boolean;
    /**
     * **Optional** Array of supported file types, if void -> Default File Types will be displayed
     */
     supportedFiletypesWithTranslation?: UploadedFileTypes[];
}

/**
 * Global Teach file list
 * Render a file list as table rows
 */
export default function GTFileList(props: IGTFileListProps) {

    function onDeleteFileDataRequest(fileName: string) {
        const fileDataRequest = props.fileDataRequests.filter(f => f.fileName !== fileName);
        props.onFileDataRequestChange(fileDataRequest);
        props.onFileChange(props.files.filter(file => file.name !== fileName));
    }

    function onFileTypeChange(fileName: string, fileType: EFileType) {
        const fileDataRequest = props.fileDataRequests.find(f => f.fileName === fileName);
        if (fileDataRequest) {
            fileDataRequest.fileType = fileType;
            props.onFileDataRequestChange(props.fileDataRequests)
        }
    }

    function renderTableHeader(showFileTypeSelection?: boolean) {
        const headerElements: GTHeaderContent[] = [];
        headerElements.push({
            cellId: 'fileName',
            cellElement: Session.instance.storage.translation.GetString('FileUpload:Filename'),
            cellClassName: 'fullsize'
        });

        if (showFileTypeSelection) {
            headerElements.push({
                cellId: 'fileType',
                cellElement: Session.instance.storage.translation.GetString('FileUpload:Filetype')
            });
        }

        headerElements.push({
            cellId: 'deleteFile',
            cellElement: Session.instance.storage.translation.GetString('FileUpload:DeleteFile'),
            cellClassName: 'autoSize'
        });
        return <GTHorizontalTableHeaderRow
            headerElements={headerElements}
            tableRowClassName="screen-reader-only" />
    }

    function renderTableRows() {
        const tableRows: JSX.Element[] = [];
        props.files.map((file, index) => {
            const fileData = props.fileDataRequests.find(f => f.fileName === file.name)
            if (fileData) {
                tableRows.push(<GTFile
                    key={`file${index}_${file.name}`}
                    id={`file${index}_${file.name}`}
                    file={file}
                    showFileTypeSelection={props.showFileTypeSelection}
                    supportedFiletypesWithTranslation={props.supportedFiletypesWithTranslation}
                    defaultFileType={props.defaultFileType}
                    selectedFileType={fileData.fileType}
                    onDeleteFile={() => onDeleteFileDataRequest(fileData.fileName)}
                    onFileTypeChange={(fileType) => onFileTypeChange(fileData.fileName, fileType)}
                />)
            }
        });
        return tableRows;
    }

    return (
        <GTHorizontalTable
            headerElement={renderTableHeader(props.showFileTypeSelection)}
            rows={renderTableRows()}
        />
    )
}