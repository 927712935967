import React, { useEffect, useState } from 'react';

import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import PrivacyService from '$src/core/Services/PrivacyService';
import { ETCApprovalMethod, ETCApprovalStatus } from '$src/storage/models/enums';
import { UserTCApprovalStatus } from '$src/storage/models/Privacy/UserTCApprovalStatus';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';


/** Props for UseRowProps hook */
interface IUseRowProps {
    tc: UserTCApprovalStatus;
}

/** Props for MyPrivacyTable hook */
interface IProps {
    isDisabledByGroup: (value: boolean) => void;
}

/**
* Renders my declaration of consent / terms and conditions / privacy statement / dsgvo
*/
export default function MyPrivacyTable(props: IProps) {
    const [tcHistory, setTcHistory] = useState<UserTCApprovalStatus[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        PrivacyService.instance.getUserTermsAndConditionsApprovalStatuses().then((tc) => {
            if (isSuccess(tc)) {
                setErrorMessage(undefined);
                setTcHistory(tc as UserTCApprovalStatus[]);
                if ((tc as UserTCApprovalStatus[]).some((p) => p.approvalMethod === ETCApprovalMethod.DisabledByGroup && p.approvalStatus === ETCApprovalStatus.DisabledByGroup)) {
                    props.isDisabledByGroup(true);
                }
            } else {
                setTcHistory([]);
                setErrorMessage('MyPrivacy:Error_Loading');
            }
        });
    }, []);

    /**
     * File data is already available. Just create an download blob anchor and click it
     * @param tc UserTCApprovalStatus
     */
    const onDownloadClick = (tc: UserTCApprovalStatus) => {
        if (tc.document) {   
            PrivacyService.instance.downloadTermsAndConditions(tc.document);
        }
    }

    /**
     * Table header
     */
    const UseTableHeader = () => {
        return (
            <div role="rowgroup">
                <div role="row" className="div-table__horizontal-table-row zebra">
                    <div role="columnheader" className="notMobile my-privacy__table-filenameColumn">
                        <Translate>MyPrivacy:Document</Translate>
                    </div>
                    <div role="columnheader" className="notMobile">
                        <Translate>MyPrivacy:ApprovalMethod</Translate>
                    </div>
                    <div role="columnheader" className="notMobile">
                        <Translate>MyPrivacy:Language</Translate>
                    </div>
                    <div role="columnheader" className="notMobile">
                        <Translate>MyPrivacy:ApprovalStatus</Translate>
                    </div>
                    <div role="columnheader" className="notMobile">
                        <Translate>MyPrivacy:Version</Translate>
                    </div>
                    <div role="columnheader" className="notMobile">
                        <Translate>MyPrivacy:DateOfAgreement</Translate>
                    </div>
                </div>
            </div>
        )
    };

    /**
    * Renders a single terms and conditions version row
    * @param param0 Users terms and conditions
    */
    const UseRow = ({ tc }: IUseRowProps): JSX.Element => {
        return (
            <div role="row" className="div-table__horizontal-table-row zebra">
                <div role="columnheader" className="mobileOnly my-privacy__table-filenameColumn">
                    <Translate>MyPrivacy:Document</Translate>
                </div>
                <div role="cell" className="my-privacy__table-filenameColumn">
                    <button className="link-button" onClick={() => onDownloadClick(tc)}>{tc.document?.filename}</button>                
                </div>

                <div role="columnheader" className="mobileOnly">
                    <Translate>MyPrivacy:ApprovalMethod</Translate>
                </div>
                <div role="cell">
                    <Translate>{'MyPrivacy:ApprovalMethod_' + ETCApprovalMethod[tc.approvalMethod]}</Translate>
                </div>

                <div role="columnheader" className="mobileOnly">
                    <Translate>MyPrivacy:Language</Translate>
                </div>
                <div role="cell">
                    {tc.document?.language}
                </div>

                <div role="columnheader" className="mobileOnly">
                    <Translate>MyPrivacy:ApprovalStatus</Translate>
                </div>
                <div role="cell">
                    <Translate>{'MyPrivacy:ApprovalStatus_' + ETCApprovalStatus[tc.approvalStatus]}</Translate>
                </div>

                <div role="columnheader" className="mobileOnly">
                    <Translate>MyPrivacy:Version</Translate>
                </div>
                <div role="cell">
                    {tc.document?.version}
                </div>

                <div role="columnheader" className="mobileOnly">
                    <Translate>MyPrivacy:DateOfAgreement</Translate>
                </div>
                <div role="cell">
                    {tc.approvalStatus !== ETCApprovalStatus.None && tc.entryDate ? (StringHelper.dateTimeString(new Date(tc.entryDate))) : <span>-</span>}
                </div>
            </div>
        )
    }

    return (
        <>
            {   
                !errorMessage &&
                <div role="table" className="div-table__horizontal-table my-privacy__table">
                    <UseTableHeader />
                    {tcHistory === undefined && <ProgressSpinner />}
                    {tcHistory && tcHistory.map((tc) => <UseRow tc={tc} key={tc.id} />)}
                </div>
            }
            {errorMessage && <Alert alertAppereance="box" alertType="error" message={errorMessage} />}                   
        </>
    )
}