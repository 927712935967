import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Request Object send to Server
 * Used to send all information needed in order to add a new Voucher Campaign
 * @@ Jessica - TODO : Restricted to Course 
 */
@JsonObject
export class PaymentVoucherCampaignRequest {

    @JsonProperty('name', String, false)
    public name: string;

    @JsonProperty('numberOfVouchers', Number, false)
    public numberOfVouchers: number;

    // @JsonProperty('isDiscountAbsolute', Boolean, false)
    // public isDiscountAbsolute: boolean;  Coming soon - leave comment here

    @JsonProperty('discount', Number, false)
    public discount: number;

    @JsonProperty('expiryDate', String, false)
    public expiryDate: string = '';

    @JsonProperty('restrictedItemIds', [String], false)
    public restrictedItemIds: string[] = [];

    @JsonProperty('itemAttributeName', String, false)
    public itemAttributeName: string = '';

    @JsonProperty('itemLifecycleInDays', Number, false)
    public itemLifecycleInDays: number = 0;

    @JsonProperty('metaData', [String], false)
    public metaData: { [key: string]: string } = {};
}


