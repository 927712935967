import { filterBy } from '@progress/kendo-data-query';
import { ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';

export interface IGenericFilterItem{
    id: number;
    text: string;
    helperItem: any; // can be used for 3rd party dependencies
}

interface IProps  {
    fetchedItemList: IGenericFilterItem[];
    onSelectedItemChange: (item: IGenericFilterItem | undefined) => void;
    filterable: boolean;
    suggest: boolean;
    className: string;
    label?: string;
    required?: boolean;
    initValue?: IGenericFilterItem;
    clearButton?: boolean;
    disabled?: boolean;
    id?: string;
}

interface IState{
    currentFilter: string | undefined;
    fetchedItemList: IGenericFilterItem[];
    filteredList?: IGenericFilterItem[];
    selectedValue?: IGenericFilterItem;
}

/*
    This class encapsulates search logic (onChange, filterChange, filterData) for a kendo combobox
    with a IGenericFilterItem Interface
*/
export class GenericFilter extends React.Component<IProps, IState> {

    constructor(props: IProps){
        super(props);

        this.state = {
            currentFilter: undefined,
            fetchedItemList: this.props.fetchedItemList,
            filteredList: this.props.fetchedItemList,
            selectedValue: this.props.initValue
        }
    }

    public componentDidUpdate() {
        if ((this.props.fetchedItemList !== this.state.fetchedItemList)) {
            this.setState({
                currentFilter: undefined,
                fetchedItemList: this.props.fetchedItemList,
                filteredList: this.props.fetchedItemList,
                selectedValue: this.props.initValue
            });
        }
    }

    public render(){
        return <ComboBox
            id={this.props.id}
            clearButton={this.props.clearButton}
            data={this.state.filteredList}
            disabled={this.props.disabled}
            textField="text"
            dataItemKey="id"
            value={this.state.selectedValue ? this.state.selectedValue : ''}
            filterable={this.props.filterable}
            suggest={this.props.suggest}
            onChange={(e) => this.onChange(e)}
            onFilterChange={(e) => this.filterChange(e)}
            className={this.props.className}
            label={this.props.label}
            required={this.props.required}/>
    }

    private onChange = (event: ComboBoxChangeEvent) => {
        const item = event.target.value;
        const itemList = item ? this.filterData(item.displayText) : this.props.fetchedItemList;
        event.target.setState({ value: item ? item : this.state.currentFilter })

        this.setState({
            currentFilter: item ? item.displayText : this.state.currentFilter,
            selectedValue: item,
            
            filteredList: itemList
        }, () => this.props.onSelectedItemChange(item));
    }

    private filterChange = (event: ComboBoxFilterChangeEvent) => {
        const filteredList = this.filterData(event.filter.value);
        const currentFilter = event.filter.value;

        this.setState({
            currentFilter,
            filteredList
        });
    }

    private filterData(val: string) {
        const filter = {
            field: 'text',
            ignoreCase: true,
            operator: 'contains',
            value: val
        };
        return val ? filterBy(this.state.fetchedItemList, filter) : this.state.fetchedItemList;
    }

}