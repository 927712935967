import React from "react";
import IconInfo from '$resources/svgs/misc/info.svg';
import InlineSVG from "react-inlinesvg";

/**
 * The GT Button Props
 */
export interface IGTInfoMessage {
    /**
     * The message to show
     */
    message: JSX.Element | string;
    /**
     * **Optional**: Component unique id
     */
    id?: string;
    /**
     * **Optional**: Show Info Icon, default is true
     */
    showInfoIcon?: boolean;
    /**
     * **Optional**: higlight border coloer
     */
    higlightBorder?: boolean;
    /**
     * **Optional**: additional css class names
     */
    additionalClassNames?: string;
}

/**
 * Global Teach Info Message Component
 */
export default function GTInfoMessage(props: IGTInfoMessage) {
    let cssClasses = 'info-message ';
    if (props.higlightBorder) {
        cssClasses += ' info-message__higlight-border ';
    }
    cssClasses += props.additionalClassNames;
    return (
        <div className={cssClasses} id={props.id}>
            {props.showInfoIcon &&
                <div className="inlineFlex">
                    <InlineSVG src={IconInfo} />
                </div>
            }
            {props.message}
        </div>
    )
}

GTInfoMessage.defaultProps = {
    id: '',
    showInfoIcon: true,
    additionalClassNames: '',
    message: ''
}