import React from 'react';
import { GridDetailRowProps } from '@progress/kendo-react-grid';

import { DetailRowContext } from '$components/PaymentBookingJournal/DetailRowContext';
import ActionChangeClass from '$components/PaymentBookingJournal/ActionChangeClass';
import ActionChangeUser from '$components/PaymentBookingJournal/ActionChangeUser';
import ActionCancel from '$components/PaymentBookingJournal/ActionCancel';
import BookingJournalResultDetails from '$components/PaymentBookingJournal/BookingJournalResultDetails';

/**
 * Kendo Grid BookingJournalResults details row with all data details or an action
 * @param props 
 * @returns 
 */
export default function BookingJournalDetailsRow(props: GridDetailRowProps) {
    if (!props || !props.dataItem) {
        return null;
    }

    const context = React.useContext(DetailRowContext);

    return (
        <>
            <section className="payment-booking-journal--detail-row margin-top">
                {context.showDetails && <BookingJournalResultDetails myTeamConf={context.myTeamConf} data={props.dataItem} onUpdated={(e) => context.onEntryUpdated && context.onEntryUpdated(e)} />}
                {context.showChangeClass && <ActionChangeClass bossRelationCode={context.myTeamConf?.bossRelationCode} data={props.dataItem} onUpdated={(e) => context.onEntryUpdated && context.onEntryUpdated(e)} />}
                {context.showChangeUser && <ActionChangeUser bossRelationCode={context.myTeamConf?.bossRelationCode} data={props.dataItem} onUpdated={(e) => context.onEntryUpdated && context.onEntryUpdated(e)} />}
                {context.showCancel && <ActionCancel bossRelationCode={context.myTeamConf?.bossRelationCode} data={props.dataItem} onUpdated={(e) => context.onEntryUpdated && context.onEntryUpdated(e)} />}
            </section>
        </>
    );
}