import { EValidationStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class ValidationStatusConverter implements JsonCustomConvert<EValidationStatus> {
    // from WebApi UserPropertiesValidation
    public serialize(status: EValidationStatus): number {
        switch(status) {
            case EValidationStatus.Success: return 0;
            case EValidationStatus.Warning: return 1;
            case EValidationStatus.Error: return 2;
        }
    }

    public deserialize(jsonValue: number): EValidationStatus {
        switch(jsonValue) {
            case 0: return EValidationStatus.Success;
            case 1: return EValidationStatus.Warning;
            case 2: return EValidationStatus.Error;
            default: return EValidationStatus.Success;
        }
    }
}