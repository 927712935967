import { InputAttributes } from '$src/components/shared/InputAttributes';
import React from 'react';

interface IProps {
    attributes: InputAttributes;
}

interface IState {
    dummy: string;
}

// tslint:disable:object-literal-sort-keys
export class InputPlaceholder extends React.Component<IProps, IState> {
    protected className = 'InputPlaceholder';
    protected loggerLocality = 'Components\shared.InputPlaceholder';       

    constructor(props: IProps) {
        super(props);
        this.state = {
            dummy: '',
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {        
        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'

        // Build final component element (Empty)     
        const inputElement: JSX.Element[] = [];
       
        return (
            <div key={'InputPleaseHolder_Div_' + this.props.attributes.id} className={boxClass}>
                {inputElement}
            </div >
        )
    }
}