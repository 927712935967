import React, { useEffect, useState } from "react";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import { Translate } from "$src/components/shared/Translate";
import { BreadCrumbElement } from "$src/storage/models/BreadCrumbElement";
import Session from "$src/core/Session";
import { useParams } from "react-router";
import { HeadingCollapsible } from "$src/components/shared/HeadingCollapsible";
import { UnmountClosed } from "react-collapse";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { Tooltip } from "$src/components/shared/Tooltip";
import { EItemSubType, EItemType, ELessonStatus } from "$src/storage/models/enums";
import { DatePicker } from "$src/components/shared/DatePicker";
import { ItemSubTypeConverter } from "$src/storage/models/converters/ItemSubTypeConverter";
import AssignmentService from "$src/core/Services/AssignmentService";
import DateHelper from "$src/util/DateHelper";
import { isSuccess } from "$src/util/Result";
import Logger from "$src/core/Logger";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import RequirementsOverviewTable from "$src/components/myEmployees/RequirementsOverviewTable";
import { RequirementTableItem } from "$src/components/myEmployees/RequirementsOverviewItem";
import { NoDataFound } from "$src/components/shared/WarningsAndErrors/NoDataFound";
import { IPanelState } from "$src/storage/models/UserPreferences/IPanelState";
import { RequirementsOverviewData } from "$src/storage/models/RequirementsOverviewData";
import { StringHelper } from "$src/util/StringHelper";
import { isString } from "lodash";

export default function RequirementsOverview() {
    const [filetPanelCollapsed, setFilterPanelCollapsed] = useState<boolean>(false);
    const [filterUser, setFilterUser] = useState<string>("");
    const [filterTitle, setFilterTitle] = useState<string>("");
    const [filterItemType, setFilterItemType] = useState<number>(0);
    const [filterItemSubType, setFilterItemSubType] = useState<number>(0);
    const [filterLessonStatus, setFilterLessonStatus] = useState<number>(0);
    const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null);
    const [filterDateTo, setFilterDateTo] = useState<Date | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [dataNoneGrouped, setDataNoneGrouped] = useState<RequirementTableItem[]>([]);
    const [dataUserGrouped, setDataUserGrouped] = useState<RequirementsOverviewData[]>([]);
    const [groupedBy, setGroupedBy] = useState<"none" | "user" | "requirement">("none");
    const bossRelationCodeParam = useParams<{ bossRelationCode: string }>();
    const [panelUser, setPanelUser] = useState<IPanelState[]>([]);
    const [panelRequirement, setPanelRequirement] = useState<IPanelState[]>([]);
    const [itemIds, setItemIds] = useState<number[]>([]);
    const bossRelationCode: string = bossRelationCodeParam != null && bossRelationCodeParam.bossRelationCode ? bossRelationCodeParam.bossRelationCode : "";
    const tr = Session.instance.storage.translation;
    const idLoadSearchText = Session.instance.storage.translation.GetString('RequirementsOverview:LoadFilter');
    const loggerLocality = 'Components.RequirementsOverview';
    const className = 'RequirementsOverview';

    useEffect(() => {
        const methodName = `${className}:fetchData()`;
        if (!isLoaded) {
            AssignmentService.instance.getRequirementsOverview(Session.instance.getUserLanguageCodeOrFallBack, filterUser, filterTitle,
                filterItemType, filterItemSubType, filterLessonStatus,
                DateHelper.toSqlDateString(filterDateFrom), DateHelper.toSqlDateString(filterDateTo), bossRelationCode).then((result) => {

                    if (isSuccess<RequirementsOverviewData[]>(result)) {
                        const elements: RequirementTableItem[] = [];
                        const userPanelList: IPanelState[] = [];
                        const requirementPanelList: IPanelState[] = [];
                        const temp = result.filter(data => data.requiredTrainings.length > 0);
                        setDataUserGrouped(temp);
                        if (temp.length > 0) {
                            temp.map((data, index) => {
                                const userPanel = new IPanelState();
                                userPanel.isOpened = false;
                                userPanel.panelId = temp[index].userId.toString();
                                userPanelList.push(userPanel);
                                data.requiredTrainings.map((requiredTraining) => {
                                    if (!itemIds.includes(requiredTraining.itemId)) {
                                        itemIds.push(requiredTraining.itemId);
                                    }
                                    const requirementPanel = new IPanelState();
                                    requirementPanel.isOpened = false;
                                    requirementPanel.panelId = requiredTraining.itemId.toString();
                                    requirementPanelList.push(requirementPanel);
                                    elements.push({
                                        userId: temp[index].userId,
                                        assignmentId: requiredTraining.assignmentId,
                                        userFirstAndLastName: temp[index].userFirstAndLastName,
                                        lessonStatus: requiredTraining.lessonStatus,
                                        date: tr.GetString('RequirementsOverview:RequirementDate').Format(requiredTraining.learningPeriodBegin != null ? StringHelper.dateString(requiredTraining.learningPeriodBegin) : tr.GetString('RequirementsOverview:Instantly'),
                                            requiredTraining.learningPeriodTarget ? StringHelper.dateString(requiredTraining.learningPeriodTarget) : tr.GetString('RequirementsOverview:Infinite'),
                                            requiredTraining.learningPeriodEnd ? StringHelper.dateString(requiredTraining.learningPeriodEnd) : tr.GetString('RequirementsOverview:Infinite')),
                                        lessonTitle: requiredTraining.title,
                                        score: requiredTraining.score == null ? 0 : requiredTraining.score,
                                        itemId: requiredTraining.itemId,
                                        itemType: requiredTraining.itemType,
                                        registrationStatus: requiredTraining.registrationStatus,
                                        registrationStatusEnabled: requiredTraining.registrationRequiredEnabled
                                    })
                                })
                            })
                        }
                        setDataNoneGrouped(elements);
                        setPanelUser(userPanelList);
                        setPanelRequirement(requirementPanelList);

                    } else {
                        const errorMessage = `${methodName} failed to get overview`;
                        Logger.log(loggerLocality, `${methodName} called`);
                        console.log(errorMessage);
                    }
                    setIsLoaded(true);
                })
        }
    }, [isLoaded])

    function reloadData() {
        setPanelRequirement([]);
        setPanelUser([]);
        setDataUserGrouped([]);
        setItemIds([]);
        setDataNoneGrouped([]);
        setIsLoaded(false);
    }

    function getBcElements() {
        const bcElements: BreadCrumbElement[] = [];
        bcElements.push(
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/myTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:MyTeam')
            },
            {
                navigationPath: `/myTeam/requirementsOverview/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:RequirementsOverview')
            }
        );

        return bcElements;
    }

    function filterItemTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        if (Number(target.value) !== EItemType.F2FCourse) {
            setFilterItemSubType(0);
            setFilterItemType(Number(target.value));
            setFilterTitle("");
        } else {
            setFilterDateFrom(null);
            setFilterDateTo(null);
            setFilterItemSubType(0);
            setFilterItemType(Number(target.value));
            setFilterLessonStatus(0);
            setFilterTitle("");
        }
    }

    function createitemTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const eItemTypes = Object.keys(EItemType);
        const filters: number[] = globalConfig.requirementsOverviewProperties.filter_itemTypes;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = eItemTypes.map(eItemType => EItemType[eItemType as any]).map(v => v as any as EItemType);
        values = values.filter(val => !Number(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const type in values) {
            if (filters.includes(Number.parseInt(type, 10)) || type === '0') {
                elements.push(
                    <option key={'itemTypeFilterOption_' + type} value={type}>
                        {
                            type === '0' ?
                                Session.instance.storage.translation.GetString('RequirementsOverview:All') :
                                Session.instance.storage.translation.GetString('ItemType:' + EItemType[type].toString())
                        }
                    </option>
                );
            }
        }
        return elements;
    }

    function filterItemSubTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        setFilterItemSubType(Number(target.value));
        setFilterTitle("");
    }

    function createItemSubTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        let filters: number[] = [];
        if (filterItemType === EItemType.F2FCourse) {
            filters = globalConfig.requirementsOverviewProperties.filter_itemSubTypes_Course;
        } else if (filterItemType === EItemType.Questionnaire) {
            filters = globalConfig.requirementsOverviewProperties.filter_itemSubTypes_Questionnaire;
        }

        const itemSubTypeConverter = new ItemSubTypeConverter();
        // tslint:disable-next-line:forin
        for (const type in EItemSubType) {
            const itemSubTypeNumber = itemSubTypeConverter.stringToInt(type);
            if (filters.includes(itemSubTypeNumber) || itemSubTypeNumber === 0) {
                elements.push(
                    <option key={'itemSubTypeFilterOption_' + itemSubTypeNumber} value={itemSubTypeNumber}>
                        {
                            itemSubTypeNumber === 0 ?
                                Session.instance.storage.translation.GetString('RequirementsOverview:All') :
                                Session.instance.storage.translation.GetString('ItemSubType:' + type)
                        }
                    </option>
                );
            }
        }
        return elements;
    }

    function filterDateToChanged(value: Date | null) {
        setFilterDateTo(value);
        setFilterTitle("");
    }

    function filterDateFromChanged(value: Date | null) {
        setFilterDateFrom(value);
        setFilterTitle("");
    }

    function filterLessonStatusChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        setFilterLessonStatus(Number(target.value));
    }

    function createLessonStatusFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const abc = Object.keys(ELessonStatus);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = abc.map(k => ELessonStatus[k as any]).map(v => v as any as ELessonStatus);
        values = values.filter(val => !isString(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const status in values) {
            elements.push(
                <option key={'lessonStatusFilterOption_' + status}
                    value={status}>
                    {status === '0' ?
                        Session.instance.storage.translation.GetString('RequirementsOverview:All')
                        : Session.instance.storage.translation.GetString('LessonStatus:' + ELessonStatus[status].toString())}</option>
            );
        }
        return elements;
    }

    function renderFilter() {
        return (
            <div role="table" className="requirements-overview__filter-table">
                <div role="rowgroup">
                    {/* Filter User */}
                    <div role="row" className="requirements-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>RequirementsOverview:SearchByUser</Translate>
                        </div>
                        <div role="cell">
                            <input
                                type="text"
                                aria-label={tr.GetString('RequirementsOverview:SearchByUser')}
                                placeholder={tr.GetString('RequirementsOverview:SearchByUser')}
                                value={filterUser}
                                className="input-field"
                                onChange={(e) => { setFilterUser(e.target.value) }}
                            />
                        </div>
                    </div>
                    {/* Filter Title */}
                    <div role="row" className="requirements-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>RequirementsOverview:SearchByTitle</Translate>
                        </div>
                        <div role="cell">
                            <input
                                type="text"
                                aria-label={tr.GetString('RequirementsOverview:SearchByTitle')}
                                placeholder={tr.GetString('RequirementsOverview:SearchByTitle')}
                                value={filterTitle}
                                className="input-field"
                                onChange={(e) => { setFilterTitle(e.target.value) }}
                            />
                        </div>
                    </div>
                    {/* Filter Type */}
                    <div role="row" className="requirements-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>RequirementsOverview:LessonTypeFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlItemTypeFilter"
                                data-tip={tr.GetString('RequirementsOverview:LessonTypeFilter')}
                                aria-label={tr.GetString('RequirementsOverview:LessonTypeFilter')}
                                data-for="ddlItemTypeFilter_Tooltip"
                                className="input-field"
                                onChange={(e) => filterItemTypeChanged(e)}
                                defaultValue={filterItemType.toString()}>
                                {createitemTypeFilter()}
                            </select>
                            <Tooltip id="ddlItemTypeFilter_Tooltip" />
                        </div>
                    </div>
                    <div role="row" className="requirements-overview__filter-table-row">
                        {/* Filter Subtype */}
                        <div role="columnheader">
                            <Translate>RequirementsOverview:LessonSubTypeFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlItemSubTypeFilter"
                                data-tip={tr.GetString('RequirementsOverview:LessonSubTypeFilter')}
                                data-for="ddlItemSubTypeFilter_Tooltip"
                                aria-label={tr.GetString('RequirementsOverview:LessonSubTypeFilter')}
                                className="input-field"
                                onChange={(e) => filterItemSubTypeChanged(e)}
                                defaultValue={filterItemSubType.toString()}>
                                {createItemSubTypeFilter()}
                            </select>
                            <Tooltip id="ddlItemSubTypeFilter_Tooltip" />
                        </div>
                    </div>
                    {/* Filter Date */}
                    <div role="row" className={'requirements-overview__filter-table-row'}>
                        <div role="columnheader" className="requirements-Overview__columnheader-autocomplete">
                            {filterItemType !== EItemType.F2FCourse ?
                                (<Translate>RequirementsOverview:LastUseBetween</Translate>) :
                                (<Translate>RequirementsOverview:TakesPlaceBetween</Translate>)
                            }
                        </div>
                        <div role="cell" className="datePicker" >
                            <DatePicker
                                key={'txt_Filter_DateFrom'}
                                onChange={(v) => filterDateFromChanged(v)}
                                value={filterDateFrom}
                            />
                        </div>
                        <div role="columnheader" className="connectorText requirements-Overview__columnheader-autocomplete">
                            {filterItemType !== EItemType.F2FCourse ?
                                (<Translate>RequirementsOverview:LastUseBetweenAnd</Translate>) :
                                (<Translate>RequirementsOverview:TakesPlaceBetweenAnd</Translate>)
                            }
                        </div>
                        <div role="cell" className="datePicker" >
                            <DatePicker
                                key={'txt_Filter_DateTo'}
                                onChange={(v) => filterDateToChanged(v)}
                                value={filterDateTo} />
                        </div>
                    </div>
                    {/* Filter Status */}
                    <div role="row" className="requirements-overview__filter-table-row">
                        <div role="columnheader">
                            <Translate>RequirementsOverview:LessonStatusFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlLessonStatusFilter"
                                data-tip={tr.GetString('RequirementsOverview:LessonStatusFilter')}
                                data-for="ddlLessonStatusFilter_Tooltip"
                                aria-label={tr.GetString('RequirementsOverview:LessonStatusFilter')}
                                className="input-field"
                                onChange={(e) => filterLessonStatusChanged(e)}
                                defaultValue={filterLessonStatus.toString()}>
                                {createLessonStatusFilter()}
                            </select>
                            <Tooltip id="ddlLessonStatusFilter_Tooltip" />
                        </div>
                    </div>
                </div>
            </div>)
    }

    function toggleUserOpenendState(panel: IPanelState | undefined) {
        const panels = panelUser;
        if (panel) {
            panels.map((panelList) => {
                if (panelList.panelId == panel.panelId) {
                    panelList.isOpened = !panelList.isOpened;
                }
            })
            setPanelUser([]);
            setPanelUser([...panels]);
        }

    }

    function toggleRequirementsOpenendState(panel: IPanelState | undefined) {
        const panels = panelRequirement;
        if (panel) {
            panels.map((panelList) => {
                if (panelList.panelId == panel.panelId) {
                    panelList.isOpened = !panelList.isOpened;
                }
            })
            setPanelRequirement([]);
            setPanelRequirement([...panels]);
        }

    }

    function getUserPanel(emplyoeeId: number) {
        return panelUser.find((panel) => panel.panelId === emplyoeeId.toString());
    }

    function getRequirementPanel(itemId: number) {
        return panelRequirement.find((panel) => panel.panelId === itemId.toString());
    }

    function renderGroupedByEmployee() {
        const elements: JSX.Element[] = [];
        dataUserGrouped.map((employee, index) => {
            const tableItems: RequirementTableItem[] = [];
            const panel = getUserPanel(employee.userId);
            if (panel) {
                employee.requiredTrainings.map((requiredTraining) => {
                    tableItems.push({
                        userId: dataUserGrouped[index].userId,
                        assignmentId: requiredTraining.assignmentId,
                        userFirstAndLastName: dataUserGrouped[index].userFirstAndLastName,
                        lessonStatus: requiredTraining.lessonStatus,
                        date: tr.GetString('RequirementsOverview:RequirementDate').Format(requiredTraining.learningPeriodBegin ? StringHelper.dateString(requiredTraining.learningPeriodBegin) : tr.GetString('RequirementsOverview:Instantly'),
                            requiredTraining.learningPeriodTarget ? StringHelper.dateString(requiredTraining.learningPeriodTarget) : tr.GetString('RequirementsOverview:Infinite'),
                            requiredTraining.learningPeriodEnd ? StringHelper.dateString(requiredTraining.learningPeriodEnd) : tr.GetString('RequirementsOverview:Infinite')),
                        lessonTitle: requiredTraining.title,
                        score: requiredTraining.score == null ? 0 : requiredTraining.score,
                        itemId: requiredTraining.itemId,
                        itemType: requiredTraining.itemType,
                        registrationStatus: requiredTraining.registrationStatus,
                        registrationStatusEnabled: requiredTraining.registrationRequiredEnabled
                    })
                })
                elements.push(
                    <div key={employee.userId}>
                        <div>
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                onToggleOpenedState={() => toggleUserOpenendState(panel)}
                                isOpened={panel.isOpened}>
                                {employee.userFirstAndLastName}
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={panel.isOpened}>
                                <RequirementsOverviewTable groupedBy={groupedBy} reloadData={() => reloadData()} bossRelationCode={bossRelationCode} overviewData={tableItems} />
                            </UnmountClosed>
                        </div>
                    </div>
                )
            }

        })
        return elements;
    }

    function groupedByValue(value: string) {
        switch (value) {
            case "none":
                setGroupedBy("none");
                break;
            case "requirement":
                setGroupedBy("requirement");
                break;
            case "user":
                setGroupedBy("user");
                break;
        }
    }

    function renderGroupedByRequirement() {
        const elements: JSX.Element[] = [];
        itemIds.map((itemId) => {
            const data = dataNoneGrouped.filter(data => data.itemId === itemId);
            const itemTitle = data[0].lessonTitle;
            const tableItems: RequirementTableItem[] = [];
            const panel = getRequirementPanel(itemId);
            if (panel) {
                data.map((requiredTraining) => {
                    tableItems.push({
                        userId: requiredTraining.userId,
                        assignmentId: requiredTraining.assignmentId,
                        userFirstAndLastName: requiredTraining.userFirstAndLastName,
                        lessonStatus: requiredTraining.lessonStatus,
                        date: requiredTraining.date,
                        lessonTitle: requiredTraining.lessonTitle,
                        score: requiredTraining.score == null ? 0 : requiredTraining.score,
                        itemId: requiredTraining.itemId,
                        itemType: requiredTraining.itemType,
                        registrationStatus: requiredTraining.registrationStatus,
                        registrationStatusEnabled: requiredTraining.registrationStatusEnabled
                    })
                })
                elements.push(
                    <div key={itemId}>
                        <div>
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                onToggleOpenedState={() => toggleRequirementsOpenendState(panel)}
                                isOpened={panel.isOpened}>
                                {itemTitle}
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={panel.isOpened}>
                                <RequirementsOverviewTable groupedBy={groupedBy} reloadData={() => reloadData()} bossRelationCode={bossRelationCode} overviewData={tableItems} />
                            </UnmountClosed>
                        </div>
                    </div>
                )
            }

        })
        return elements;
    }

    return (
        <div>
            <div className="l-container">
                <MenuBreadCrumb breadCrumbElementsToAppend={getBcElements()} />
                <div className="heading__Title">
                    <Translate>RequirementsOverview:Title</Translate>
                </div>
                <div className="l-box--wide"><Translate>RequirementsOverview:Info</Translate></div>
            </div>
            <div className="requirements-overview__container l-element--striped-reverse requirements-overview__container--padding">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass="requirements-overview__container--no-margin "
                        headingCssClass="heading__Level2"
                        isOpened={filetPanelCollapsed}
                        onToggleOpenedState={() => setFilterPanelCollapsed(!filetPanelCollapsed)}>
                        <Translate>RequirementsOverview:Filter</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={filetPanelCollapsed} className="l-container">
                        {renderFilter()}
                    </UnmountClosed>
                    <div className="input-field__container">
                        <GTButton
                            additionalClassNames="btn--md requirements-overview__filter-btn"
                            onClick={() => reloadData()}>
                            {idLoadSearchText}
                        </GTButton>
                    </div>
                </div>
            </div>
            <div className="l-container">
                <select className="skill__filter--date"
                    onChange={(e) => {
                        groupedByValue(e.target.value)
                    }}>
                    <option value="none">{Session.instance.storage.translation.GetString('RequirementsOverview:None')}</option>
                    <option value="requirement">{Session.instance.storage.translation.GetString('RequirementsOverview:GroupedByRequirement')}</option>
                    <option value="user">{Session.instance.storage.translation.GetString('RequirementsOverview:GroupedByUser')}</option>
                </select>
                {isLoaded ?
                    dataNoneGrouped.length > 0 ?


                        groupedBy === "none" ?
                            <RequirementsOverviewTable groupedBy={groupedBy} reloadData={() => reloadData()} bossRelationCode={bossRelationCode} overviewData={dataNoneGrouped} />
                            : groupedBy === "user" ? renderGroupedByEmployee() :
                                groupedBy === "requirement" ? renderGroupedByRequirement() : <></>
                        :
                        <NoDataFound message={Session.instance.storage.translation.GetString("RequirementsOverview:NoDataFound")} />
                    :
                    <ProgressSpinner />}
            </div>
        </div>
    )
}