import React, { useState } from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import InlineSVG from 'react-inlinesvg';
import { toast } from 'react-toastify';

import GTIconButton from '$components/shared/Atoms/GTIconButton';
import { Translate } from '$components/shared/Translate';
import GTSearchSelectBox from '$components/shared/Molecules/GTSearchSelectBox';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';

import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import { isSuccess } from '$src/util/Result';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import SaveIcon from '$resources/svgs/PaymentBookingJournal/pay_save.svg';
import { EItemTypeAttributeValue } from '$src/storage/models/enums';

interface IActionChangeUserProps {
	data: PaymentBookingJournalResponse;
	onUpdated: (newData: PaymentBookingJournalResponse) => void;
	/** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
	bossRelationCode?: string;
}

/**
 * Changes the booked user of one class
 * @param props 
 * @returns 
 */
export default function ActionChangeUser(props: IActionChangeUserProps) {
	const [newUser, setNewUser] = useState<number>();
	const [comment, setComment] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isOk, setIsOk] = useState<boolean>();
	const [errorMsg, setErrorMsg] = useState<string>();
	const [bossRelationCode] = useState<string>(props.bossRelationCode ? props.bossRelationCode : "");

	function commit() {
		// button shall not be visible - check nevertheless
		if (props.data.itemType !== EItemTypeAttributeValue.F2FCourse) {
			toast.error('Not supportet');
			return;
		}

		setIsLoading(true);
		PaymentAdminService.instance.changeUser(props.data.id, newUser!, comment, props.bossRelationCode).then((res) => {
			setIsLoading(false);
			if (isSuccess<PaymentBookingJournalResponse>(res)) {
				setIsOk(true);
				props.onUpdated(res);
			} else {
				setIsOk(false);
				setErrorMsg(res.message);
			}
		});
	}

	function renderAlerts() {
		if (isLoading) return;
		if (isOk === true) {
			return <Alert alertAppereance='single-line' alertType='success' message='PaymentBookingJournal:ChangeUserOk' />
		} else if (isOk === false) {
			return <Alert alertAppereance='box' alertType='error' message='PaymentBookingJournal:ChangeUserFailed'>{errorMsg && <p>{errorMsg}</p>}</Alert>
		}
		return;
	}

	function renderForm() {
		if (isLoading || isOk === true) return;

		return (
			<div className='payment-booking-journal-action__grid'>
				<div>
					<Translate>PaymentBookingJournal:ChangeUserCurrentUser</Translate>
				</div>
				<div className='payment-booking-journal-action__current'>
					{props?.data.participant}
				</div>
				<div>&nbsp;</div>

				<div>
					<Translate>PaymentBookingJournal:User</Translate>
				</div>
				<div>
					<GTSearchSelectBox apiController='paymentAdmin' apiMethod='bookingJournalParticipantOfSameCompanySearch' apiQueryStringParams={[
						{ name: 'checkoutItemId', value: props?.data.id.toString() },
						{ name: 'bossRelationCode', value: bossRelationCode }
					]} onSelected={(e) => setNewUser(e?.id)} />
				</div>
				<div>&nbsp;</div>

				<div>
					<Translate>PaymentBookingJournal:Comment</Translate>
				</div>
				<div>
					<TextArea className="input-field" autoSize={true} onChange={(e) => setComment(e.value.toString())} />
				</div>
				<div>
					<GTIconButton id='save' ariaLabel='save' onClick={() => commit()} defaultButtonProps={{ disabled: !newUser || newUser === props?.data.participantId }}>
						<InlineSVG src={SaveIcon} />
					</GTIconButton>
				</div>
			</div>
		)
	}

	return (
		<div>
			<p>
				<Translate>PaymentBookingJournal:ChangeUserText</Translate>
			</p>

			{isLoading && <ProgressSpinner />}
			{renderAlerts()}
			{renderForm()}
		</div>
	);
}