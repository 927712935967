import { CancellationReasonModel } from '$src/storage/models/Workflow/CancellationReasonModel';
import { action, computed, observable } from 'mobx';

 
 export class TodoGlobalState {
 
    /**
     * Static property implements Singleton pattern for GlobalState.
     */
    public static get instance(): TodoGlobalState {
        return this._instance || (this._instance = new this());
    }
    protected static _instance: TodoGlobalState | null = null;

    @observable 
    private _cancellationReasons: CancellationReasonModel[] | undefined= undefined;


    @computed
    public get cancellationReasons(): CancellationReasonModel[] {
        if(this._cancellationReasons === undefined) {
            return [];
        }
        return this._cancellationReasons;
    }
    
    @action
    public setCancellationReasons(param: CancellationReasonModel[]): void {
        this._cancellationReasons =Object.assign([], param);
    }

 }