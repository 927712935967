import * as React from 'react';

import { Translate } from '$components/shared/Translate';
import IconOpenInNewTab from '$resources/svgs/misc/open-in-new-tab.svg';
import IconInfo from '$resources/svgs/misc/info.svg';
import Logger from '$src/core/Logger';
import Session from '$src/core/Session';
import { LocationDocument } from '$src/storage/models/F2F/LocationDocument';
import { LocationDocumentList } from '$src/storage/models/F2F/LocationDocumentList';
import { Room } from '$src/storage/models/F2F/Room';
import { isSuccess } from '$src/util/Result';
import InlineSVG from 'react-inlinesvg';
import { Heading } from '$components/shared/Heading';
import { ModalPopup } from '$components/shared/ModalPopup';
import { Tooltip } from '$components/shared/Tooltip';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';



interface IProps {
    uniqueId: string;
    courseItemId: number;
    itemContext: EItemDetailCallerContextType;
    parentTraningPlanId: number;
    room: Room;
    parentHeadingLevel: number;
    showRoomTitleAsLink?: boolean;
    setErrorMessage: (errorMessage: string) => void;
}

interface IState {
    shouldShowModal: boolean;
    locationDocuments: LocationDocumentList | null;
}
/**
 * Shows the room details, if showRoomTitleAsLink is true the RommTitle will be shown as a link 
 * Onclick open a Pop Up with the Room details
 * If no room title available also no link available
 * if no image01 is available a default picture is displayed
 */
export class RoomDetails extends React.Component<IProps, IState> {

    protected className = 'RoomDetails';
    protected loggerLocality = 'Components.RoomDetails';

    constructor(props: IProps) {
        super(props);
        this.state = {
            locationDocuments: null,
            shouldShowModal: false,
        }

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getLocationDocuments = this.getLocationDocuments.bind(this);
        this.renderSingleDocument = this.renderSingleDocument.bind(this);
        this.renderImage = this.renderImage.bind(this);
    }

    public componentDidMount() {
        this.getLocationDocuments();
    }

    public render() {
        const classProps = globalConfig.classProperties;
        const roomProps = globalConfig.roomProperties;
        const room = this.props.room;
        let checkedLink = null;

        if (room.venueLink.includes('https://') || room.venueLink.includes('http://')) {
            checkedLink = room.venueLink;
        }
        else if (room.venueLink) {

            checkedLink = 'https://' + room.venueLink;
        }
        return (
            <React.Fragment>
                {classProps.classDisplayVenueBuilding && <p>{room.venueBuilding}</p>}
                {classProps.classDisplayVenueTitle && <p>{room.venueTitle}</p>}
                {classProps.classDisplayRoomId && <p>{room.roomSid}</p>}
                {classProps.classDisplayRoomNr && <p>{room.roomNr}</p>}
                {classProps.classDisplayRoomTitle &&
                    <p>
                        {this.props.showRoomTitleAsLink ?
                            <button
                                onClick={() => this.openModal()}
                                className="button-link button-link--colorized button-link--no-margin"
                                data-tip={Session.instance.storage.translation.GetString('Room:RoomDetailsInfoTip')}
                                data-for={`${this.props.uniqueId}_roomInfoTip`} >
                                {room.roomTitle}
                                <InlineSVG src={IconInfo} />
                                <Tooltip id={`${this.props.uniqueId}_roomInfoTip`} />
                            </button> : room.roomTitle
                        }
                    </p>}
                {classProps.classDisplayAddress && <p>{room.venueAddress}, {room.venueZipCode} {room.venueCity}</p>}
                {classProps.classDisplayCountry && <p>{room.venueCountry}</p>}

                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={this.closeModal}
                    contentCssClass="modal__roomDetails">

                    <Heading headingLevel={this.props.parentHeadingLevel + 1} cssClass="modal__content heading__Level1" >
                        <Translate>Room:RoomDetails</Translate>
                    </Heading>

                    <div className="f2f__roomdetails-details">
                        {this.state.locationDocuments !== null && this.renderImage(this.state.locationDocuments.roomDocuments.find((doc) =>
                            doc.filename.toLowerCase().substring(0, 7).includes('image01')))}
                        <div role="table" className="f2f__roomdetails-details-table">
                            <div role="rowgroup" />
                            <div role="row" className="f2f__roomdetails-details-table-row">
                                <div role="columnheader">
                                    <Translate>Room:RoomId</Translate>
                                </div>
                                <div role="cell">
                                    {roomProps.roomDisplayRoomId && <p>{room.roomSid}</p>}
                                </div>
                            </div>
                            <div role="row" className="f2f__roomdetails-details-table-row">
                                <div role="columnheader">
                                    <Translate>Room:RoomName</Translate>
                                </div>
                                <div role="cell">
                                    {roomProps.roomDisplayRoomTitle && <p>{room.roomTitle}</p>}
                                </div>
                            </div>
                            <div role="row" className="f2f__roomdetails-details-table-row">
                                <div role="columnheader">
                                    <Translate>Room:RoomNumber</Translate>
                                </div>
                                <div role="cell">
                                    {roomProps.roomDisplayRoomNr && <p>{room.roomNr}</p>}
                                </div>
                            </div>
                            <div role="row" className="f2f__roomdetails-details-table-row">
                                <div role="columnheader">
                                    <Translate>Room:Location</Translate>
                                </div>
                                <div role="cell">
                                    {roomProps.roomDisplayAddress && <p>{room.venueAddress}</p>}
                                    {roomProps.roomDisplayAddress && <p>{room.venueZipCode} {room.venueCity}</p>}
                                    {roomProps.roomDisplayAddress && <p>{room.venueCountry}</p>}
                                </div>
                            </div>
                            <div role="row" className="f2f__roomdetails-details-table-row">
                                <div role="columnheader">
                                    <Translate>Room:Contact</Translate>
                                </div>
                                <div role="cell">
                                    {roomProps.roomDisplayContact && <p>{room.venueContact}</p>}
                                    {roomProps.roomDisplayEmail && <p>{room.venueEmail}</p>}
                                    {roomProps.roomDisplayPhone && <p>{room.venuePhone}</p>}
                                </div>
                            </div>
                            {checkedLink && <div role="row" className="f2f__roomdetails-details-table-row">
                                <div role="columnheader">
                                    <Translate>Room:Link</Translate>
                                </div>
                                <div role="cell">
                                    {roomProps.roomDisplayLink && <a target="_blank" href={checkedLink}><Translate>Room:LinkForInformation</Translate></a>}
                                </div>
                            </div>}

                        </div>

                    </div>

                    <Heading headingLevel={this.props.parentHeadingLevel + 1} cssClass="modal__content heading__Level1">
                        <Translate>Room:Documents</Translate>
                    </Heading>
                    <div>
                        <ol className="f2f__roomdetails-documents__list">
                            {this.state.locationDocuments !== null && this.state.locationDocuments.roomDocuments.map((doc, index) => this.renderSingleDocument(doc, index))}
                            {this.state.locationDocuments !== null && this.state.locationDocuments.venueDocuments.map((doc, index) => this.renderSingleDocument(doc, index))}
                        </ol>
                    </div>


                </ModalPopup>
            </React.Fragment>
        )
    }

    protected renderImage(doc: LocationDocument | undefined): JSX.Element {
        let urlSrc: string | undefined;
        if (doc) {
            urlSrc = 'url(' + doc.url + ')';
        }
        return (
            <div className="f2f__roomdetails-image" style={{ backgroundImage: urlSrc }} />
        );
    }

    protected renderSingleDocument(doc: LocationDocument, index: number): JSX.Element | null {
        if (doc.filename.toLowerCase().substring(0, 7).includes('image01')) {
            return null;
        }
        return (
            <li key={index}>
                <div className="inlineFlex">
                    <InlineSVG src={IconOpenInNewTab} />
                </div>
                <div>
                    <a href={doc.url} target="blank">{doc.filename}</a>
                </div>
            </li>

        );
    }

    private async getLocationDocuments() {
        let locationDocuments = null;
        const methodName = `${this.className}:getLocationDocuments()`;
        const response = await Session.instance.storage.locationDocuments.getLocationDocuments(this.props.courseItemId, this.props.itemContext,
            this.props.parentTraningPlanId, null, this.props.room.roomId, this.props.room.venueId);
        if (isSuccess<LocationDocumentList | null>(response)) {
            locationDocuments = response;
        }
        else {
            const errorMessage = `${methodName} failed to load locationdocuments for room id ${this.props.room.roomId} and venue id ${this.props.room.venueId}, ` +
                `exception: ${response.message}`
            Logger.log(this.loggerLocality, errorMessage);
            this.props.setErrorMessage('ErrorMessage:GetLocationDocumentsFailed');
            console.error(errorMessage);
        }

        this.setState({ locationDocuments });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });

    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

} 