import React, { useState, useEffect } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';

import { Translate } from '$components/shared/Translate';
import { isSuccess } from '$src/util/Result';
import { SearchSelectBoxResponse } from '$src/storage/models/SearchSelectBoxResponse';
import ServiceClient, { IQueryStringParam } from '$src/core/ServiceClient';

interface IGTSearchSelectBoxProps {
	/**
	 * Entry was selcted - you can choose wether using the id or sid for your purpose
	 */
	onSelected: (selected: SearchSelectBoxResponse | undefined) => void;
	/**
	 * Name of the controller where the search action is placed in
	 */
	apiController: string;
	/**
	 * Name of the api action to search
	 */
	apiMethod: string;
	/**
	 * Additional query string parameters like filter data
	 */
	apiQueryStringParams?: IQueryStringParam[];
}

const MININPUTLENGH = 2;

/**
 * Generic text input with api search and value selection 
 * @param props api search method and callback function for selected result
 * @returns 
 */
export default function GTSearchSelectBox(props: IGTSearchSelectBoxProps) {
	const [loading, setLoading] = useState(false);
	const [searchResults, setSearchResults] = useState<SearchSelectBoxResponse[]>([]);
	const [searchText, setSearchText] = useState<string>('');
	const [selected, setSelected] = useState<SearchSelectBoxResponse>();

	/** reload combobox */
	useEffect(() => {
		if (searchText && searchText.length >= MININPUTLENGH) {
			setLoading(true);
			ServiceClient.instance.getSearch(props.apiController, props.apiMethod, searchText, props.apiQueryStringParams).then((res) => {
				setLoading(false);
				if (isSuccess(res)) {
					setSearchResults(res as SearchSelectBoxResponse[]);
				}
			});
		}
		else if (!searchText || searchText.length < MININPUTLENGH) {
			setSearchResults([]);
		}
	}, [searchText]);

	/** callback on selected changed */
	useEffect(() => {
		props.onSelected(selected);
	}, [selected]);

	/** No data found or input length to short */
	const searchComboBoxNoData = (element: React.ReactElement<HTMLDivElement>) => {
		const length = searchText?.length || 0;

		const noData = (
			<span>
				{length < MININPUTLENGH && <Translate>SearchSelectBox:MinimumInputLengthValidator</Translate>}
				{length >= MININPUTLENGH && <Translate>SearchSelectBox:NoData</Translate>}
			</span>
		);

		return React.cloneElement(element, { ...element.props }, noData);
	};

	return (
		<>
			<ComboBox
				loading={loading}
				style={{ width: '100%' }}
				filterable={true}
				onFilterChange={(e) => setSearchText(e.filter.value)}
				textField='display'
				listNoDataRender={searchComboBoxNoData}
				onChange={(e) => setSelected(e.value)}
				value={selected}
				data={searchResults}
			/>
		</>
	);
}