import React from "react";
import { Heading } from "$components/shared/Heading";
import { Translate } from "$components/shared/Translate";
import { useHistory } from "react-router";
import GTButton from "$components/shared/Atoms/GTButton";

export interface ILicenseRegPanelProps {
    parentHeadingLevel: number;
}

export default function LicenseRegPanel(props: ILicenseRegPanelProps) {
    const history = useHistory();
    if (!globalConfig.loginProperties.enableLicenseRegSelfreg && !globalConfig.loginProperties.enableLicenseRegLogin) {
        return null;
    } else {
        return (
            <div className="l-container login__licenseReg">
                <div className="licenseReg__panel">
                {globalConfig.loginProperties.enableLicenseRegSelfreg &&
                    <div>
                        <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level4">
                            <Translate>LicenseRegLogin:SelfRegTitle</Translate>
                        </Heading>
                        <p><Translate>LicenseRegLogin:SelfRegHint</Translate></p>
                        <GTButton onClick={() => history.push('/licenseSelfRegCheck')} additionalClassNames="licenseReg__button">
                            <Translate>LicenseRegLogin:CreateAndActivateLicense</Translate>
                        </GTButton>
                    </div>
                }

                {globalConfig.loginProperties.enableLicenseRegLogin &&
                    <div>
                        <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level4">
                            <Translate>LicenseRegLogin:LoginTitle</Translate>
                        </Heading>
                        <p><Translate>LicenseRegLogin:LoginHint</Translate></p>
                        <GTButton onClick={() => history.push('/licenseRegLogin')} additionalClassNames="licenseReg__button">
                            <Translate>LicenseRegLogin:ActivateAdditionalLicense</Translate>
                        </GTButton>
                    </div>
                }
                </div>
            </div>
        )
    }
}
