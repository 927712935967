import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import SharedService from '$src/core/Services/SharedService';
import { CallbackUrl } from '$src/storage/models/CallbackUrl'
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { Redirect } from 'react-router';


interface IState {
    redirectPage: string;
    redirectToRoot: boolean;
}
export class CallbackUrlPage extends React.Component<{}, IState>  {

    constructor(props: {}) {
        super(props);
        this.state = {
            redirectPage: '',
            redirectToRoot: false,
        }
    }

    public render() {
        if (this.state.redirectPage !== '') {
            return window.location.href = this.state.redirectPage;
        }
        else if (this.state.redirectToRoot) {
            const redirectLink = '/';
            return <Redirect push={true} to={redirectLink} />;
        }
        else {
            return <ProgressSpinner />;
        }
    }
    public async componentDidMount() {
        const parameters = new URLSearchParams(window.location.search);
        const guid = parameters.get('guid');
        let redirectURL: string = '';
        if (guid != null) {
            const cburl = await SharedService.instance.getCallbackURL(guid);
            if(cburl !== null && isSuccess<CallbackUrl>(cburl)){
                    redirectURL = cburl.url;
            }
        }
        this.setState({ redirectPage: redirectURL, 
                        redirectToRoot: redirectURL === '' });
    }
}
export default CallbackUrlPage;