import React from 'react';

import { FavoriteRibbon } from '$src/components/favorites/FavoriteRibbon';
import { WatchlistRibbon } from '$src/components/watchlist/WatchlistRibbon';
import { EFavoriteType } from '$src/storage/models/enums';

interface IProps {
    itemId: number;
    itemTitle?: string;
    favoriteType: EFavoriteType;
    tpElementId: number;
}

interface IState {
    itemId: number;
    refreshKey: number;
}
export class TopAnchorToolbar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            itemId: this.props.itemId,
            refreshKey: 0,
        }

        this.onFavoriteAdd = this.onFavoriteAdd.bind(this);
    }


    public render(): JSX.Element[] | JSX.Element {
        return (
            <div className="top-anchor__toolbar">
                <WatchlistRibbon
                    itemId={this.state.itemId}
                    refreshKey={this.state.refreshKey}
                    itemTitle={this.props.itemTitle} />
                {globalConfig.favoritesProperties.enableFavorites ?
                    (<FavoriteRibbon 
                        onFavoriteAdd={this.onFavoriteAdd} 
                        itemTitle={this.props.itemTitle} 
                        itemId={this.props.tpElementId === 0 ? this.state.itemId : this.props.tpElementId} 
                        type={this.props.favoriteType} />) :
                    ('')}
            </div>
        );
    }

    public onFavoriteAdd() {
        this.setState({ refreshKey: (this.state.refreshKey + 1) });
    }
}