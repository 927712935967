import { JsonObject, JsonProperty } from 'json2typescript';
import { FileTypeConverter } from '../converters/FileTypeConverter';
import { EFileType } from '../enums';

@JsonObject
export class FileDataRequest {

    @JsonProperty('fileName', String, false)
    public fileName: string = '';

    @JsonProperty('base64String', String, false)
    public base64String: string = '';
    
    @JsonProperty('contentType', String, false)
    public contentType: string = '';

    @JsonProperty('fileType', FileTypeConverter, false)
    public fileType: EFileType = EFileType.Undefined;

    @JsonProperty('url', String, false)
    public url: string = '';

    public file: File | undefined = undefined;
}
