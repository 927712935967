import ServiceClient from '$core/ServiceClient';
import GtError from '$util/GtError';
import { TrainerBookingsResponse } from '$src/storage/models/Trainer/TrainerBookingsResponse';
import { TrainerBookingsRequest } from '$src/storage/models/RequestObjects/TrainerBookingsRequest';


/**
 * Provides all Service Methods needed for the Trainer
 */

export default class TrainerService extends ServiceClient {

    protected static _instance: TrainerService | null = null;
    protected className = 'TrainerService';
    protected loggerLocality = 'TrainerService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): TrainerService {
        return this._instance || (this._instance = new this());
    }

    public async getBookings(
        language: string,
        pageSize: number,
        pageNumber: number,
        filterYear: number,
        filterMonth: number,
        filterCourse: string,
        filterClass: string
    ): Promise<TrainerBookingsResponse | GtError> {

        const requestObject: TrainerBookingsRequest = {
            language,
            pageSize,
            pageNumber,
            filterYear,
            filterMonth,
            filterCourse,
            filterClass
        }
        const response = await this.post<TrainerBookingsResponse>(
            'trainer/getBookings',
            requestObject,
            TrainerBookingsResponse);

        return response;
    }

    public async getMailLink(language: string, classId: number): Promise<string | GtError> {
        const response: string | GtError = await this.get<string>('trainer/getMailLink', String,undefined,  [{ name: 'language', value: language },
        { name: 'classId', value: classId.toString()}]);
        return response;
    }

}