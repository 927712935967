import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class TrainingPlanRecalculateRequest {

    @JsonProperty('langCode', String, false)
    public langCode: string = '';

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;
}