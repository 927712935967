import { Preferences } from '$src/storage/models/UserPreferences/Preferences';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

// tslint:disable-next-line:max-classes-per-file
@JsonConverter
export class PreferencesConverter implements JsonCustomConvert<Preferences> {
    public serialize(userPref: Preferences): string {
        return JSON.stringify(userPref);
    }
    public deserialize(userPref: string): Preferences {
        if(userPref != null && userPref !== '') {
            return JSON.parse(userPref)
        }
        else {
            return new Preferences();
        }
    }
}