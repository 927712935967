import React, { useEffect } from 'react';

import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NavigationUtil } from '$util/NavigationUtil';

export const Aui: React.FC = () => {

    useEffect(() => {
        NavigationUtil.loginAui(window)
    }, []);

    return (
        <div className="l-container">
            <Heading headingLevel={1} cssClass="heading__Title">
                <Translate>GtApplicationSwitch:Aui</Translate>
            </Heading>
            <ProgressSpinner />
        </div>);
}
export default Aui;