import Session from '$core/Session';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { EAttributeType } from '$src/storage/models/enums';
import { FieldValidators } from '$src/util/Fieldvalidators';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { KeyboardEvent } from 'react';

interface IProps {
    attributes: InputAttributes;
    onChange?: (id: string, value: string, errMsg: string, code: string) => void;
    onCrEntered?: (id: string) => void;
}

interface IState {
    value: string;
    isValid: boolean;
    errorMsg: string;
    attributeValue: AttributeValue[] | null;
}
// tslint:disable:object-literal-sort-keys
export class InputSelect extends React.Component<IProps, IState> {
    protected controlSelect: HTMLSelectElement | null;
    protected className = 'InputSelect';
    protected loggerLocality = 'Components\shared.InputSelect';

    constructor(props: IProps) {
        super(props);

        const sourceValue: string = (this.props.attributes.value === '0') ? '' : this.props.attributes.value;
        const initialValidation: boolean = (this.props.attributes.initialValidation === undefined) ? false : this.props.attributes.initialValidation;

        // Validate input value.
        const errorMessage = this.validateField(initialValidation, sourceValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        // Initialize state based on validation check.
        this.state = {
            value: sourceValue,
            isValid: controlValid,
            errorMsg: errorMessage,
            attributeValue: null,
        }

        // If the control is invalid, the event will be triggered to inform parent component.
        if (!controlValid) {
            if (this.props.onChange) {
                this.props.onChange(this.props.attributes.id, sourceValue, errorMessage, '0');
            }
        }
    }

    public componentDidMount(): void {
        if (this.props.attributes.editMode && this.props.attributes.hasFocus) {
            this.setFocus();
        }
    }

    public UNSAFE_componentWillMount() {
        if (this.props.attributes.attributeValueType !== undefined) {
            this.getAttributeValues(this.props.attributes.attributeValueType);
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {
        const lblClass: string = 'input-label';
        let txtClass: string = 'input-field';
        txtClass = txtClass + this.errorClass(this.state.errorMsg)

        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'
        const placeholder: string = (this.props.attributes.placeholder === undefined || this.props.attributes.placeholder === null) ? '' : this.props.attributes.placeholder;

        // Build final component element
        const inputElement: JSX.Element[] = [];

        inputElement.push(
            <label key={'InputSelect_Label_' + this.props.attributes.id} htmlFor={this.props.attributes.id} className={lblClass}>
                <Translate>{this.props.attributes.label}</Translate>
                {(this.props.attributes.isRequired && this.props.attributes.editMode) ? ' *' : ''}
            </label>
        )

        inputElement.push(
            <select
                key={'InputSelect_Input_' + this.props.attributes.id}
                id={this.props.attributes.id}
                name={this.props.attributes.id}
                className={txtClass}
                value={this.state.value}
                style={this.props.attributes.isReadOnly === true ? { color: '#a4a4a4', background: 'none' } : undefined}
                disabled={this.props.attributes.isReadOnly}
                required={this.props.attributes.isRequired}
                placeholder={placeholder}
                onChange={(e) => this.onChange(e)} ref={(e) => this.controlSelect = e}>
                {this.createSelectItems()}
            </select>
        )

        inputElement.push(
            <span key={'InputSelect_ErrorMsg_' + this.props.attributes.id} className={'input-message error'}>
                <Translate>{this.state.errorMsg}</Translate>
            </span>
        )

        return (
            <div key={'InputSelect_Div_' + this.props.attributes.id} className={boxClass}>
                {inputElement}
            </div >
        )
    }

    /**
     * Create select items
     */
    protected createSelectItems() {
        const items = [];
        const keyId = this.props.attributes.attributeValueType === undefined ?
            'zz_' : this.props.attributes.attributeValueType.toString() + '_'

        // Add empty entry
        items.push(<option key={keyId + 'Empty'}
            value={''}>{Session.instance.storage.translation.GetString('AttributeValue:NoSelection')}</option>);

        // Load all values available           
        if (this.state.attributeValue !== null) {
            for (let i = 0; i <= this.state.attributeValue.length - 1; i++) {
                items.push(<option key={keyId + this.state.attributeValue[i].id.toString()}
                    value={this.state.attributeValue[i].id}>{this.state.attributeValue[i].text}</option>);
            }
        }
        return items;
    }

    /**
     * Retrieve error class.
     */
    protected errorClass(errorMsg: string) {
        return (errorMsg.length === 0 ? '' : ' has-error');
    }

    /**
     * Validate input value.
     */
    protected validateField(validateValue: boolean, value: string): string {
        let message: string = '';

        if (validateValue) {
            if (message.length === 0) {
                message = FieldValidators.Required(value, this.props.attributes.isRequired);
            }

            if (message.length === 0) {
                message = FieldValidators.RegExpression(value, this.props.attributes.regExpression)
            }
        }
        return message;
    }

    protected setFocus() {
        if (this.controlSelect !== null) {
            const htmlInput = this.controlSelect;
            htmlInput.focus();
        }
    }

    /**
     * Callback for text input: value has changed.
     */
    protected onChange = (event: React.FormEvent<HTMLSelectElement>) => {
        const target = event.currentTarget as HTMLSelectElement;
        const targetValue: string = target.value;

        // Validate input value
        const errorMessage = this.validateField(true, targetValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        let code = this.state.attributeValue?.find(f => f.id.toString() == targetValue.toString())?.code;

        if (code === undefined) {
            code = '0'
        }

        this.setState({
            value: targetValue,
            isValid: controlValid,
            errorMsg: errorMessage,
        })

        // Callback for control: Value has changed.
        if (this.props.onChange) {
            this.props.onChange(this.props.attributes.id, targetValue, errorMessage, code);
        }
    }

    /**
     * Callback called each time a key is released (key up event).
     */
    protected onKeyUp = (event: KeyboardEvent<HTMLSelectElement>) => {
        if (event.keyCode === 13) {
            if (this.props.onCrEntered) {
                this.props.onCrEntered(this.props.attributes.id);
                event.preventDefault();
            }
        }
    }

    /**
     * Get attribute values for select control.
     */
    protected async getAttributeValues(typeId: number): Promise<void> {
        let attributeValues: AttributeValue[] | null;
        attributeValues = await Session.instance.storage.attributeValue.getAttributeValuesAsync(typeId);
        if (typeId == EAttributeType.UILanguage &&
            attributeValues != null && globalConfig.languageProperties?.languages.length > 0) { 
                // restrict UILanguage choice to configured languages
                attributeValues = attributeValues.filter(avLang =>
                    globalConfig.languageProperties.languages.find(lang => lang.Code == avLang.code) != undefined);            
        }

        if (isSuccess<AttributeValue[] | null>(attributeValues)) {
            this.setState({ attributeValue: attributeValues });
        }
    }
}