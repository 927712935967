import UserInterestsService from "$src/core/Services/UserInterestsService";
import { isSuccess } from "$src/util/Result";
import React, { useEffect, useState } from "react";
import { UserInterests } from "$src/storage/models/UserInterests/UserInterests"
import UserInterestsAdministrationSubjects from '$components/userInterestsAdministration/UserInterestsAdministrationSubjects';
import UserInterestsAdministrationGeneric from "$components/userInterestsAdministration/UserInterestsAdministrationGeneric";
import { Heading } from "$components/shared/Heading";
import { Translate } from "$components/shared/Translate";
import { ItemTypeConverter } from "$src/storage/models/converters/ItemTypeConverter";
import { EItemTypeAttributeValue } from "$src/storage/models/enums";
import Session from "$src/core/Session";

interface IProps {
    headingLevel: number;
}

export default function UserInterestsAdministration(props: IProps) {
    const [isLoadedUserInterests, setIsLoadedUserInterests] = useState<boolean>(false);
    const [userInterestsData, setUserInterestsData] = useState<Array<UserInterests>>([])

    useEffect(() => {
        UserInterestsService.instance.getUserInterests().then(userinterests => {
            if (!isSuccess(userinterests)) {
                setIsLoadedUserInterests(true);
            }
            else {
                const ui = userinterests as Array<UserInterests>;
                setUserInterestsData([...ui])
                setIsLoadedUserInterests(true);
            }
        });
    }, [])

    const saveNewUserInterest = async (idAttributeValue: number, orderNumber: number): Promise<void> => {
        const userInterest = await UserInterestsService.instance.saveUserInterests(idAttributeValue, orderNumber);
        if (isSuccess(userInterest)) {
            const ui = userInterest as UserInterests;
            setUserInterestsData([...userInterestsData, ui])
        }
    }

    const deleteUserInterests = async (userInterest: UserInterests[]): Promise<void> => {
        let userinterests = [...userInterestsData];
        const deleteRequests = userInterest.map(async (ui) => {
            const request = await UserInterestsService.instance.deleteUserInterests(ui.id)
            if (isSuccess(request)) {
                // userinterests.splice(userinterests.findIndex(ui2 => ui2.id === ui.id), 1);
                userinterests = userinterests.filter(ui2 => ui2.id !== ui.id);
                console.log(userinterests);
            }
        });
        await Promise.all(deleteRequests);
        setUserInterestsData([...userinterests]);
    }

    return <>

        { isLoadedUserInterests &&
            <div className="user-interests">
                <Heading headingLevel={props.headingLevel + 1} cssClass="heading__Level4" ><Translate>UserInterests:DefineSubjects</Translate></Heading>
                <UserInterestsAdministrationSubjects
                    userInterests={userInterestsData}
                    onItemsDelete={async (userInterests) => { await deleteUserInterests(userInterests) }}
                    onItemInsert={async (idAttributeValue: number, orderNumber: number) => { await saveNewUserInterest(idAttributeValue, orderNumber) }} />

                <Heading headingLevel={props.headingLevel + 1} cssClass="heading__Level4"><Translate>UserInterests:DefineOtherFilters</Translate></Heading>
                <UserInterestsAdministrationGeneric
                    idAttribute={12}
                    title={"UserInterests:ItemTypeFilter"}
                    userInterests={userInterestsData}
                    onItemsDelete={async (userInterests) => { await deleteUserInterests(userInterests) }}
                    onItemInsert={async (idAttributeValue: number) => { await saveNewUserInterest(idAttributeValue, 1) }}
                    attributeFilter={ItemTypeConverter.asItemTypeAttribueValues(globalConfig.userInterestsProperties.itemTypesFilter)}
                    attributeConverter={(av) => {
                        av.text = Session.instance.storage.translation.GetString('ItemType:' + EItemTypeAttributeValue[av.id]);
                        return av;
                    }
                    } />

                {globalConfig.userInterestsProperties.additionalInterests.map(ui => {
                    return <UserInterestsAdministrationGeneric
                        key={`UserInterestsFilter_${ui.AttributeID}`}
                        userInterests={userInterestsData}
                        idAttribute={ui.AttributeID}
                        title={ui.title}
                        onItemsDelete={async (userInterests) => { await deleteUserInterests(userInterests) }}
                        onItemInsert={async (idAttributeValue: number) => { await saveNewUserInterest(idAttributeValue, 1) }} />
                })}
            </div>
        }
    </>
        ;

}