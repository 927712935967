import GamificationService from '$core/Services/GamificationService';
import { GamificationPointHistoryDetail as GamificationPointHistoryDetailModel } from '$src/storage/models/Gamification/GamificationPointHistoryDetail';
import TranslationStorage from '$storage/TranslationStorage';
import { isSuccess } from '$util/Result';
import { StringHelper } from '$util/StringHelper';
import React, { useEffect, useState } from 'react';
import GTButton from '$components/shared/Atoms/GTButton';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { GamificationPointHistoryIcon } from '$components/gamification/GamificationPointHistoryDetailIcon';
import { GamificationPointHistoryTitle } from '$components/gamification/GamificationPointHistoryDetailTitle';
import Logger from '$src/core/Logger';
import { ErrorMessage } from '../shared/WarningsAndErrors/ErrorMessage';

interface IGamificationPointHistoryDetailProps {
    translationStorage: TranslationStorage;
}

export const GamificationPointHistoryDetail: React.FC<IGamificationPointHistoryDetailProps> = (props: IGamificationPointHistoryDetailProps) => {
    const [historyDetail, setHistoryDetail] = useState<GamificationPointHistoryDetailModel[]>();
    const [historyDetailDataLoading, setHistoryDetailDataLoading] = useState<boolean>(false);
    const [historyDetailDataLoaded, setHistoryDetailDataLoaded] = useState<boolean>(false);
    const [pageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [hasMoreData, setHasMoreData] = useState<boolean>(false);
    const [errorLoadingData, setErrorLoadingData] = useState<boolean>(false);

    const loggerLocality = 'GamificationPointHistoryDetail';

    useEffect(() => {
        async function fetchData() {
            setHistoryDetailDataLoading(true);
            await GamificationService.instance.getGamificationPointHistoryDetail(pageSize, pageNumber)
                .then((result) => {
                    if (isSuccess<GamificationPointHistoryDetailModel[]>(result)) {
                        if(result.length > pageSize) {
                            result.pop();
                            setHasMoreData(true);
                        }
                        else {
                            setHasMoreData(false);
                        }
                        setHistoryDetail([...historyDetail || [], ...result]);
                        setHistoryDetailDataLoading(false);
                        setErrorLoadingData(false);
                    }
                    else {
                        setErrorLoadingData(true);
                        Logger.log(loggerLocality, `failed to get data`);
                        console.error(`${loggerLocality} failed to get data`);
                    }
                    setHistoryDetailDataLoaded(true);
                });
        }
        fetchData();
    }, [pageNumber]);

    return (
        <div className='gamification-history__detail'>
            {errorLoadingData && <ErrorMessage appearance='box' errorMessage='Common:ErrorLoadingData' />}
            {historyDetail != null && <ol> {
                historyDetail.map(hd => <li key={hd.idGamificationPointHistory}>
                    <div className='gamification-history__detail__received'>
                        {hd.received != null && StringHelper.dateString(hd.received)}
                    </div>
                    <div className='gamification-history__detail__icon'>
                        <GamificationPointHistoryIcon detail={hd} translationStorage={props.translationStorage} />
                    </div>
                    <div className='gamification-history__detail__title'>
                    <GamificationPointHistoryTitle detail={hd} translationStorage={props.translationStorage} />
                    </div>
                    <div className='gamification-history__detail__points'>
                        +
                        {hd.points}
                    </div>
                </li>)
            }</ol>}
            {historyDetailDataLoading && !historyDetailDataLoaded && <ProgressSpinner />}
            {hasMoreData && <GTButton onClick={() => setPageNumber(pageNumber + 1)}>
                <Translate>GamificationPointHistory:DisplayMore</Translate>
            </GTButton>}
        </div>
    )
}