import React from 'react';
import Logger from '$src/core/Logger';
import { observer } from 'mobx-react';
import { HeadingCollapsible } from '../shared/HeadingCollapsible';
import { UnmountClosed } from 'react-collapse';
import { Translate } from '../shared/Translate';
import { ProgressSpinner } from '../shared/ProgressSpinner';

interface IProps {
    panelId: string;
    panelIsFixed: boolean;
    panelExpanded: boolean;
    panelHeadingStyle?: number;
    panelIsTitleVisible: boolean;
    panelTranslationString: string;
    startIndex: number;
    noBackground: boolean;
    isOrganizerOpen: boolean;
    component: JSX.Element;
    togglePanel: (panelId: string) => void;

}

interface IState {
    panelExpanded: boolean;
}

@observer
export class HomeCollapsablePanel extends React.Component<IProps, IState> {

    protected className = 'HomePanel';
    protected loggerLocality = 'Components.HomePanel';

    constructor(props: IProps) {
        super(props);

        this.state = {
            panelExpanded: props.panelExpanded
        }
    }

    public UNSAFE_componentWillReceiveProps() {
        const methodName = `${this.className}:UNSAFE_componentWillReceiveProps()`;
        Logger.log(this.loggerLocality, `${methodName} loading required data.`);
    }

    public componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        Logger.log(this.loggerLocality, `${methodName} loading required data.`);
    }

    public render() {
        if (this.props == null) return <ProgressSpinner />
        const cssClasss = globalConfig.homeProperties.strippedLayout ?
            !this.props.noBackground ? !this.props.isOrganizerOpen ? 'l-element--striped-reverse' : 'l-element--striped' : '' : ''
        return (
            <div key={'homePanel-Element_' + this.props.panelId} id={this.props.panelId} className={cssClasss}>
                <div className="l-container">
                    <div className="home__panel">
                        <HeadingCollapsible
                            headingLevel={1}
                            containerCssClass=""
                            headingCssClass={this.props.panelHeadingStyle == null || this.props.panelHeadingStyle === 1 ?
                                'heading__Title' :
                                'heading__Title heading--secondary'}
                            onToggleOpenedState={() => this.togglePanel(this.props.panelId)}
                            isOpened={this.props.panelIsFixed ? true : (this.state.panelExpanded)}>
                            {this.props.panelIsTitleVisible ? <Translate>{this.props.panelTranslationString}</Translate> : undefined}
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={this.props.panelIsFixed ? true : (this.state.panelExpanded)}>
                            {this.props.component}
                        </UnmountClosed>
                    </div >
                </div>
            </div>
        );
    }

    private togglePanel(panelId: string): void {
        const panelExpanded = !this.state.panelExpanded;
        this.setState({ panelExpanded }, () => this.props.togglePanel(panelId));
    }
}
