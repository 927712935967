import { JsonObject, JsonProperty } from 'json2typescript';

/** This is an Element used in the Catalog Breadcrumb. */
@JsonObject
export class CatalogPathElement {

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('parentItemId', Number, false)
    public parentItemId: number = 0;

    @JsonProperty('treeLevel', Number, false)
    public treeLevel: number = 0;

    @JsonProperty('title', String, false)
    public title: string = '';
}
