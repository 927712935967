import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class Skill {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    /** String Id of the skill */
    @JsonProperty('skillSId', String, false)
    public skillSId: string = '';

    /** Skill title */
    @JsonProperty('skillTitle', String, false)
    public skillTitle: string = '';

    /**level set id */
    @JsonProperty('levelSetId', Number, false)
    public levelSetId: number = 0;
}