import { JsonObject, JsonProperty } from 'json2typescript';

import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { LessonStatusConverter } from '$src/storage/models/converters/LessonStatusConverter';
import { ELessonStatus } from '$storage/models/enums';

/** 
 * This class holds information about restrictions applied to an item (lesson or training plan) 
 * due to its assignment to the current user or one of his groups (without information about the item).
 */
@JsonObject
export class Assignment {
    
    /** Id of registration. */
    @JsonProperty('assignmentId', Number, false)
    public assignmentId: number = 0;

    /** Id of the user or group the assignment is associated to. */
    @JsonProperty('userGroupId', Number, false)
    public userGroupID: number = 0;
 
    /** Id of the assigned lesson or training plan. */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;
 
    /** Indicates if this is a required (true) or optional (false) assignment. */
    @JsonProperty('isRequired', Boolean, false)
    public isRequired: boolean = false;

    /** Lesson status required to pass the assignment. */
    @JsonProperty('requiredLessonStatus', LessonStatusConverter, false)
    public requiredLessonStatus: ELessonStatus = ELessonStatus.Undefined;

    /** Score required to pass the assignment. */
    @JsonProperty('requiredScore', Number, false)
    public requiredScore: number = 0;

    /** Optional date/time after that the learner may start with the associated lesson. */
    @JsonProperty('learningPeriodBegin', DateTimeConverter, false)
    public learningPeriodBegin?: Date = undefined;

    /** Optional target time, the learner should have completed the associated lesson at that time. */
    @JsonProperty('learningPeriodTarget', DateTimeConverter, false)
    public learningPeriodTarget? : Date = undefined;

    /** Optional deadline, after this time the learner is no longer allowed to use the associated lesson. */
    @JsonProperty('learningPeriodEnd', DateTimeConverter, false)
    public learningPeriodEnd?: Date = undefined;

    /** A number used to order/sort the assignments. */
    @JsonProperty('rank', Number, false)
    public rank: number = 0;
 
    /** Gets true if current time is between LearningPeriodBegin and LearningPeriodEnd. */
    @JsonProperty('isInLearningPeriod', Boolean, false)
    public isInLearningPeriod: boolean = false;
}
