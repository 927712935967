import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class DeputyDeleteRequest {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('bossRelationCode', String, false)
    public bossRelationCode: string = '';    

}