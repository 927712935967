
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { TrainingPlanCancelRequest } from '$src/storage/models/RequestObjects/TrainingPlanCancelRequest';
import { TrainingPlanRegisterRequest } from '$src/storage/models/RequestObjects/TrainingPlanRegisterRequest';
import { TrainingPlan } from '$src/storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import { TrainingPlanSchedule } from '$src/storage/models/TrainingPlan/TrainingPlanSchedule';
import GtError from '$src/util/GtError';
import ServiceClient from '$src/core/ServiceClient';
import Logger from '$src/core/Logger';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { ItemHelper } from '$src/util/ItemHelper';
import Session from '$src/core/Session';
import { TrainingPlanRecalculateRequest } from '$src/storage/models/RequestObjects/TrainingPlanRecalculateRequest';

/**
 * This Service provides all funcitons for the trainin plan
 */
export default class TrainingPlanService extends ServiceClient {
    
    protected static _instance: TrainingPlanService | null = null;

    protected className = 'TrainingPlanService';
    protected loggerLocality = 'TrainingPlanService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): TrainingPlanService {
        return this._instance || (this._instance = new this());
    }


    public async getMyTrainingPlans(language: string): Promise<TrainingPlan[] | GtError> {
        let response: TrainingPlan[] | GtError;
        response = await this.get<TrainingPlan[]>('trainingplan/getMyTrainingPlans', TrainingPlan, undefined, [{ name: 'language', value: language }]);
        return response;
    }

    public async getTrainingPlan(itemId: number, itemContext: EItemDetailCallerContextType): Promise<TrainingPlan | GtError> {
        let response: TrainingPlan | GtError;
        const language = Session.instance.getUserLanguageCodeOrFallBack;
        response = await this.get<TrainingPlan>(
            'trainingplan/getTrainingPlan',
            TrainingPlan,
            undefined,
            [
                { name: 'language', value: language },
            ],
            itemId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getTrainingPlanElement(tpeId: number, language: string, itemContext: EItemDetailCallerContextType): Promise<TrainingPlanElement | GtError> {
        let response: TrainingPlanElement | GtError;
        response = await this.get<TrainingPlanElement>('trainingplan/getTrainingPlanElement',
            TrainingPlanElement,
            undefined,
            [
                { name: 'language', value: language },
            ],
            tpeId.toString(),
            globalConfig.itemProperties.learningTimeAttributeName,
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }
    
    public async registerToTrainingPlan(itemId: number, 
                                        scheduleId: number, 
                                        f2fClassIds: number[] | null, 
                                        language: string,
                                        selectedBossId: number,
                                        registerAsStatusRequested: boolean): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;

        const obj = new TrainingPlanRegisterRequest();
        obj.itemId = itemId;
        obj.langCode = language;
        obj.scheduleId = scheduleId;
        obj.f2fClassIds = f2fClassIds;
        obj.selectedBossId = selectedBossId;
        obj.registerAsStatusRequested = registerAsStatusRequested;

        response = await this.post<BooleanResponse>('trainingplan/registerToTrainingPlan', obj, BooleanResponse);
        return response;
    }

    public async cancelRegistration(itemId: number,
        scheduleId: number,
        language: string,
        cancellationReasonId: number,
        cancellationReasonText: string,
        userIdToCancel: number): Promise<BooleanResponse | GtError> {

        let response: BooleanResponse | GtError;
        const obj = new TrainingPlanCancelRequest();
        obj.itemId = itemId;
        obj.langCode = language;
        obj.scheduleId = scheduleId;
        obj.cancellationReasonText = cancellationReasonText;
        obj.cancellationReasonId = cancellationReasonId;
        obj.userIdToCancel = userIdToCancel;

        response = await this.post<BooleanResponse>('trainingplan/cancelRegistration', obj, BooleanResponse);
        return response;
    }


    public async getTrainingPlanElements(language: string, tplanId: number, itemContext: EItemDetailCallerContextType): Promise<TrainingPlanElement[] | GtError> {
        let response: TrainingPlanElement[] | GtError;
        response = await this.get<TrainingPlanElement[]>('trainingplan/getTrainingPlanElements',
            TrainingPlanElement,
            undefined,
            [
                { name: 'language', value: language },
            ],
            tplanId.toString(),
            globalConfig.itemProperties.learningTimeAttributeName,
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getTrainingPlanSchedules(language: string, tplanId: number, itemContext: EItemDetailCallerContextType, ignoreRegisterRight?: boolean): Promise<TrainingPlanSchedule[] | GtError> {
        let response: TrainingPlanSchedule[] | GtError;
        const queryStringParams = [];
        queryStringParams.push({ name: 'language', value: language });
        if(ignoreRegisterRight) {
            queryStringParams.push({name: 'ignoreRegisterRight', value: ignoreRegisterRight.toString()})
        }
        response = await this.get<TrainingPlanSchedule[]>('trainingplan/getSchedulesOfTrainingPlan',
            TrainingPlanSchedule,
            undefined,
            queryStringParams,
            tplanId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getMyTrainingPlanSchedule(language: string, tplanId: number): Promise<TrainingPlanSchedule | GtError> {
        let response: TrainingPlanSchedule | GtError;
        response = await this.get<TrainingPlanSchedule>('trainingplan/getTrainingPlanScheduleByUser',
            TrainingPlanSchedule,
            undefined,
            [{ name: 'language', value: language }],
            tplanId.toString());
        return response;
    }

    public async getTrainingPlanSchedulesForEmployees(language: string, tplanId: number, itemContext: EItemDetailCallerContextType): Promise<TrainingPlanSchedule[] | GtError> {
        let response: TrainingPlanSchedule[] | GtError;
        response = await this.get<TrainingPlanSchedule[]>('trainingplan/getTrainingPlanSchedulesForEmployees',
            TrainingPlanSchedule,
            undefined,
            [
                { name: 'language', value: language },
            ],
            tplanId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async recalculateTrainingPlan(language: string, tplanId: number): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        const obj = new TrainingPlanRecalculateRequest();
        obj.itemId = tplanId;
        obj.langCode = language;
        response = await this.post<BooleanResponse>('trainingplan/recalculateTrainingPlanStatus', obj, BooleanResponse);
        return response;
    }

    public async getIsSimpleRegisterPossible(itemId: number, itemContext: EItemDetailCallerContextType):  Promise<number | GtError> {
        const methodName = this.className + ":getIsSimpleRegisterPossible"
        let response: number | GtError = 0;
        const responseTplan = await this.getTrainingPlan(itemId, itemContext);
        if (responseTplan instanceof TrainingPlan) {
            if (responseTplan.isSimpleRegisterToTplanWithOnlyOneClass) {
                const responseSce = await this.getTrainingPlanSchedules(Session.instance.getUserLanguageCodeOrFallBack, itemId, itemContext);
                if (!(responseSce instanceof GtError)) {

                    const availableClasses = responseSce.filter((f) => {
                        return f.isAutoRegister && f.isRegistrationAllowed && responseTplan.InputSkillsFulfilled && responseTplan.isRegistered == false;
                    })
                    if (availableClasses.length == 1) {
                        response = availableClasses[0].sceId;
                        return response;
                    }
                    else {
                        return response;
                    }
                }
                else if (responseSce instanceof GtError) {
                    Logger.log(this.loggerLocality, `${methodName} failed to get training plan schedules: ${itemId}, ${responseSce.message}`);
                    return response;
                }
                else {
                    Logger.log(this.loggerLocality, `${methodName} failed to get training plan schedules: ${itemId}: Unknown error}`);
                    return response;
                }
            }
            else {
                return response;
            }
        }
        else if (responseTplan instanceof GtError) {
            Logger.log(this.loggerLocality, `${methodName} failed to get training plan: ${itemId}, ${responseTplan.message}`);
            return response;
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} failed to get training plan: ${itemId}: Unknown error}`);
            return response;
        }
    }
} 