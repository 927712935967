import React from "react"


/**
 * **GTHorizontalTableHeaderRow Props**
 *
 * @export
 * @interface IGTHorizontalTableHeaderRowProps
 */
export interface IGTHorizontalTableHeaderRowProps {

    /**
     * List of header elements
     */
    headerElements: GTHeaderContent[];
    /**
     * **Optional** The optional row class, default is 'div-table__horizontal-table-row zebra'
     */
    tableRowClassName?: string;
}



/**
 * Global Teach Table Horizontal Header Row Component
 */
export default function GTHorizontalTableHeaderRow(props: IGTHorizontalTableHeaderRowProps) {
    return (
        <div role="row" className={`${props.tableRowClassName}`} >
            {props.headerElements.map(element => {
                return (
                    <div key={`header__${element.cellId}`} role="columnheader" className={`notMobile ${element.cellClassName ? element.cellClassName : ''}`}>
                        {element.cellElement}
                    </div>
                )
            })}

        </div>
    )
}

GTHorizontalTableHeaderRow.defaultProps = {
    tableRowClassName: 'div-table__horizontal-table-row zebra'
}

export class GTHeaderContent {
    public cellId: string;
    public cellElement: JSX.Element | string;
    public cellClassName?: string;
}