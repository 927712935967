import React from "react";
import GTButton from "$src/components/shared/Atoms/GTButton";

/**
 * The GT Icon Button Props
 */
export interface GTIconButtonProps {
    /**
     * The Button children
     */
    children: JSX.Element | string;
    /**
     * OnClick callback
     */
    onClick(event: React.FormEvent<HTMLButtonElement>): void;
    /**
     * Component unique id, needed for Tooltip
     */
    id: string;
    /**
     * Aria label for GT Button
     */
    ariaLabel: string;
    /**
     * **Optional** Aria role description is e more precise definition for the role of the button than the default value "button". Is multilingual.
     * E.g. "Lesson start button", "Edit button". **DO NOT lose the information that this html element is a button!**
     */
    ariaRoleDescription?: string;
    /**
     * **Optional** Tool tip text. If undefined, the tool tip won't show.
     */
    tooltipText?: string;
    /**
     * **Optional** Default react button attributes
     */
    defaultButtonProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
    /**
     * **Optional** Additional css class
     */
    additionalClassNames?: string;
    /**
     * **Optional** disable button
     */
    disabled?: boolean;
}


/**
 * Global Teach Icon Button Component
 * **Important** Use only this component for Icon Buttons, beceause it includes A11y (Accessibility)
 * @param props 
 */
export default function GTIconButton(props: GTIconButtonProps) {
    return (
        <GTButton
            onClick={(event) => props.onClick(event)}
            defaultButtonProps={
                {
                    "aria-roledescription": props.ariaRoleDescription,
                    ...props.defaultButtonProps
                }
            }
            id={props.id}
            ariaLabel={props.ariaLabel}
            isIconButton={true}
            tooltipText={props.tooltipText}
            additionalClassNames={props.additionalClassNames}
            disabled={props.disabled}>
            {props.children}
        </GTButton>
    )
}