import React from 'react';
import { UnmountClosed } from 'react-collapse';
import InlineSVG from 'react-inlinesvg';

import IconattachFile from '$resources/svgs/misc/attach-file.svg';
import InfoIcon from '$resources/svgs/misc/info.svg';

import { ToolbarItemRating } from '$src/components/item/Toolbar/ToolbarItemRating';
import { BossSelection } from '$components/shared/BossSelection';
import { Heading } from '$components/shared/Heading';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ParticipantList } from '$components/shared/ParticipantList';
import { Translate } from '$components/shared/Translate';
import ItemService from '$core/Services/ItemService';
import Session from '$core/Session';
import { EAttendanceStatus, ERegistrationStatus, EItemType, EItemSubType, EItemDetailCallerContextType } from '$storage/models/enums';
import { ExternalCourseRegistration } from '$storage/models/ExternalCourse/ExternalCourseRegistration';
import { Item } from '$storage/models/Item';
import FileHelper from '$util/FileHelper';
import { isSuccess } from '$util/Result';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';
import { ApplyRating } from '$components/itemRating/ApplyRating';

interface IProps {
    externalCourseRegistration: ExternalCourseRegistration;
    parentHeadingLevel: number;
    onBossSelectionChanged?: (selectedBoss: BossSearchResult | undefined) => void;
    isBossView?: boolean;
}

interface IState {
    applicantRequestCollapsed: boolean;
    bossCommentCollapsed: boolean;
    errorMessage: string;
    shouldShowModal: boolean;
    item: Item | undefined;
}

export class ExternalCourseRequestData extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            applicantRequestCollapsed: this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted,
            bossCommentCollapsed: this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted,
            errorMessage: '',
            item: undefined,
            shouldShowModal: false,
        }

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.onBossSelectionChanged = this.onBossSelectionChanged.bind(this);
    }

    public async componentDidMount() {
        if (this.props.externalCourseRegistration.attendedStatus !== EAttendanceStatus.Undefined) {
            const item = await ItemService.instance.getLesson(this.props.externalCourseRegistration.itemId, EItemDetailCallerContextType.ExternalCourse);
            if (isSuccess<Item>(item)) {
                this.setState({ item });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetLessonFailed' });
            }

        }
    }

    public render() {
        let requestReason = '-';
        let registrationBossComment = '-';
        const registrationDocs = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_RegistrationDocument');

        if (this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_RegistrationReason')[0]) {
            requestReason = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_RegistrationReason')[0].value;
        }

        if (this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_RegistrationBossComment')[0]) {
            registrationBossComment = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_RegistrationBossComment')[0].value;
        }
        
        const showParticipantListForBoss = this.props.isBossView && globalConfig.requestDetail.showParticipantList;
        const showParticipantListForRequestUser = !this.props.isBossView && globalConfig.externalCourse.showParticipantList 
                                                    && this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted;
                                                    return (
            <React.Fragment>

                <span className={'input-message error'}>
                    <Translate>{this.state.errorMessage}</Translate>
                </span>
                {(this.state.item && !this.props.isBossView) &&
                    <div className="step-content__block">
                        <Translate>ExternalCourse:RatingHintText</Translate>
                        {this.state.item.ratingMine > 0 && <ToolbarItemRating item={this.state.item} starsCount={6} /> }
                        {this.state.item.ratingMine === 0 && 
                         this.props.externalCourseRegistration.attendedStatus === EAttendanceStatus.Attended &&
                                            <ApplyRating
                        item={this.state.item}
                        allowCancel={false} /> }
                    </div>
                }
                <div className="step-content__block">

                    <HeadingCollapsible
                        headingLevel={this.props.parentHeadingLevel}
                        containerCssClass=""
                        headingCssClass="heading__Level5"
                        isOpened={!this.state.applicantRequestCollapsed}
                        onToggleOpenedState={() => this.onToggleApplicantRequest()}>
                        <Translate>ExternalCourse:RequestReasonLabel</Translate>
                    </HeadingCollapsible>

                    <UnmountClosed isOpened={!this.state.applicantRequestCollapsed}>
                        <div className="step-content__block">
                            <span>{requestReason}</span>
                        </div>
                        <div className="step-content__block">
                            <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                <label id="lblDocuments"><Translate>ExternalCourse:RequestDocuments</Translate></label>
                            </Heading>
                            <ul className="step-content__block--documentList">
                                {registrationDocs.map(doc => {
                                    const currentFileName = doc.value;// FileHelper.getFileNameFromBase64(doc.value);
                                    const arriaLabel = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                        .Format(currentFileName);
                                    return (
                                        <li key={doc.id} className="inlineFlex">
                                            <InlineSVG src={IconattachFile} />
                                            <button
                                                className="button-link button-link--colorized-dark"
                                                aria-label={arriaLabel}
                                                onClick={() => FileHelper.openMediaFile(doc, null)}>
                                                {currentFileName}
                                            </button>
                                        </li>
                                    )
                                }

                                )}
                            </ul>
                        </div>
                    </UnmountClosed>
                </div>

                {(globalConfig.bossSelection.selectionIsVisible_ExternalCourse && !this.props.isBossView) &&
                <div className="step-content__block">
                    <BossSelection
                        isReadOnly={this.props.externalCourseRegistration.registrationStatus >= ERegistrationStatus.Requested}
                        isConfigReadOnly={globalConfig.bossSelection.bossSelectionIsReadOnly_ExternalCourse}
                        parentHeadingLevel={this.props.parentHeadingLevel + 1}
                        onBossSelectionChanged={this.onBossSelectionChanged} />
                </div>}

                {this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Accepted &&
                    <div className="step-content__block">
                        <HeadingCollapsible
                            headingLevel={this.props.parentHeadingLevel}
                            containerCssClass=""
                            headingCssClass="heading__Level5"
                            isOpened={!this.state.bossCommentCollapsed}
                            onToggleOpenedState={() => this.onToggleBossComment()}>
                            <Translate>ExternalCourse:RegistrationBossComment</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={!this.state.bossCommentCollapsed}>
                            <span>{registrationBossComment}</span>
                        </UnmountClosed>
                    </div>
                }

                {(showParticipantListForBoss || showParticipantListForRequestUser) &&
                    <div className="step-content__block">
                        <button className="button-link button-link--colorized-dark"
                            onClick={() => this.openModal()}
                            aria-label={Session.instance.storage.translation.GetString('Course:OpenParticipantList')}>
                            <Translate>Course:OpenParticipantList</Translate>
                        </button>
                    </div>
                }

                {(this.props.externalCourseRegistration.registrationStatus === ERegistrationStatus.Requested && !this.props.isBossView) &&
                    <div className="step-content__block center red">
                        <p>
                            <div className="inlineFlex">
                                <InlineSVG src={InfoIcon} />
                                <span>
                                    <Translate>ExternalCourse:RequestBossHintMessage</Translate>
                                </span>
                            </div>
                        </p>
                    </div>
                }

                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={this.closeModal}
                    contentCssClass="modal__participantList">
                    <div>
                        <ParticipantList 
                            parentHeadingLevel={this.props.parentHeadingLevel} 
                            scheduleId={this.props.externalCourseRegistration.scheduleId}
                            itemType={EItemType.F2FCourse}
                            itemSubType={EItemSubType.External} />
                    </div>
                </ModalPopup>
            </React.Fragment>
        )
    }

    private onToggleApplicantRequest() {
        const applicantRequestCollapsed = !this.state.applicantRequestCollapsed;
        this.setState({ applicantRequestCollapsed });
    }

    private onToggleBossComment() {
        const bossCommentCollapsed = !this.state.bossCommentCollapsed;
        this.setState({ bossCommentCollapsed });
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private onBossSelectionChanged(selectedBoss: BossSearchResult | undefined) {
        if (this.props.onBossSelectionChanged) {
            this.props.onBossSelectionChanged(selectedBoss);
        }
    }
}