import React from 'react';

import ItemsFilter from '$components/myEmployees/ActionModals/ItemsFilter';
import { TimeSettingFormElement } from '$src/components/myEmployees/ActionModals/TimeSettingFormElement';
import { Heading } from '$src/components/shared/Heading';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Translate } from '$src/components/shared/Translate';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import Logger from '$src/core/Logger';
import TeamService from '$src/core/Services/TeamService';
import Session from '$src/core/Session';
import { AssignLessonToUserResponse, AssignLessonToUserResponseContainer } from '$src/storage/models/Assignments/AssignLessonToUserResponse';
import { Item } from '$src/storage/models/Item';
import { AssignLessonToUserRequest } from '$src/storage/models/RequestObjects/AssignLessonToUserRequest';
import { User } from '$src/storage/models/User';
import { isSuccess } from '$src/util/Result';
import { ERelativeTimeSettingMode, ERelativeTimeSettingsDayMode } from '$storage/models/enums';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { StringHelper } from '$src/util/StringHelper';


interface IProps {
    isOpen: boolean;
    employees: User[] | undefined;
    preSelectedItem?: Item;
    onClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    bossRelationCode: string;
}

interface IDateSettings {
    id: string;
    selectedTimeSettingMode: ERelativeTimeSettingMode;
    selectedDayMode: ERelativeTimeSettingsDayMode;
    ticks: number;
    days: number;
}
interface IErrorStrings {
    endDate: string;
    targetDate: string;
    reminderDate: string;
}
interface IForm {
    isLessonRequired: boolean;
    requiredScore: number | null;
    notifyOnStart: boolean;
    notifyOnEnd: boolean;
    remindUser: boolean;
    selectedReminderFrequencyOption: string;
    startDateSettings: IDateSettings;
    targetDateSettings: IDateSettings;
    endDateSettings: IDateSettings;
    reminderDateSettings: IDateSettings;
    submitDisabled: boolean;
}
interface IState extends IForm {
    errors: IErrorStrings;
    showAssignLessonsModal: boolean;
    isDisabled: boolean;
    selectedItem: Item | undefined;
    selectedTimeSettingMode: ERelativeTimeSettingMode;
    hasServerResponse: boolean;
    showOnServerError: boolean;
    serverError: string;
    serverResponse: undefined | AssignLessonToUserResponse[];
}

export class AssignLessonsModal extends React.Component<IProps, IState>{

    protected className = 'AssignLessonsModal';
    protected loggerLocality = 'Components.AssignLessonsModal';
    private TRANSLATION_PREFIX = 'AssignLessonsModal';
    private publishRight: number = 2;

    private timeSettingsDayModes = [
        {
            id: ERelativeTimeSettingsDayMode.DaysLater,
            text: Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:DaysLater`)
        },
        {
            id: ERelativeTimeSettingsDayMode.WorkingDaysLater,
            text: Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:WorkingDaysLater`)
        },
    ];

    private reminderFrequencyOptions: string[] = [
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:Never`),
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:Daily`),
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:Weekly`),
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:Monthly`)];
    private timeSettingModes: string[] = [
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:AbsoluteTimeMode`),
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:RelativeTimeMode`)
    ];

    constructor(props: IProps) {
        super(props);

        this.state = this.initializeState();
    }

    public render() {
        const heading = <Heading headingLevel={2} cssClass={'heading__Level2'}><Translate>{`${this.TRANSLATION_PREFIX}:Header`}</Translate></Heading>;
        const assignmentDate = StringHelper.dateString(new Date());

        return (
            <ModalPopup
                isOpen={this.props.isOpen}
                onRequestClose={(e) => { this.setState(this.initializeState(), () => this.props.onClose(e)) }}
                contentCssClass="my-team__assign-lesson__modal">
                {this.state.showOnServerError ?
                    <Alert alertType={'error'} alertAppereance={'box'} message={this.state.serverError} /> :
                    this.state.hasServerResponse ?
                        this.renderResponseResults() :
                        <div className="assign-lesson-modal__content">
                            {heading}
                            {this.renderItemsFilter()}
                            {this.state.selectedItem ?
                                <legend className="my-team-assign-lesson__assignment-date">
                                    {`${this.getTranslation('AssignmentDate')} ${assignmentDate}`}
                                </legend> : null
                            }
                            {this.state.selectedItem ? this.renderSetLessonParameterForm() : null}
                            {this.renderButtons()}
                        </div>
                }
            </ModalPopup>
        );
    }


    private async onAssignLessonSubmit() {
        const methodName = `${this.className}:onAssignLessonSubmit()`;

        if (this.props.employees !== null && this.state.selectedItem) {
            if (this.props.employees !== undefined) {
                const employeeIds = this.props.employees.map(e => e.id);

                if (employeeIds !== null) {
                    const response = await TeamService.instance.assignLessonsToEmployees(this.createRequestObject(employeeIds));
                    if (isSuccess<AssignLessonToUserResponseContainer>(response)) {
                        Logger.log(this.loggerLocality, `${methodName} lessons assigned to selected users`);
                        this.setState({ serverResponse: response.assignLessonToUserResponse, hasServerResponse: true });
                    } else {
                        console.error(`onAssignLessonSubmit on Assign Lesson to users failed, itemId=${this.state.selectedItem.itemId}, ${response.message}`);
                        Logger.log(this.loggerLocality, `onAssignLessonSubmit failed to assign lesson to users, lessonID=${this.state.selectedItem.itemId}, ${response.message}`);
                        this.setState({ showOnServerError: true, serverError: response.message });
                    }
                }
            }
        }
    }

    // Render Helpers 

    private renderItemsFilter = (): JSX.Element => {
        return <ItemsFilter
            id='AssignLessonsModalItemId'
            dataItemKey="itemId"
            aclRight={this.publishRight} itemType={0} isCheckRegisterClass={false}
            onSelectedItemChange={(item) => item ?
                this.setState({ isDisabled: false, selectedItem: item }) :
                this.setState({ isDisabled: true, selectedItem: undefined })}
            label={Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:SearchLesson`)}
            preSelectedItem={this.props.preSelectedItem}
            bossRelationCode={this.props.bossRelationCode} />
    }

    private renderButtons = (): JSX.Element => {
        return <div className="my-Team_RegisterLessonModal modal__spread-buttons">
            <button
                className="btn--sm btn--default"
                disabled={this.state.submitDisabled}
                onClick={() => this.onAssignLessonSubmit()}>
                <Translate>{`${this.TRANSLATION_PREFIX}:SubmitButton`}</Translate>
            </button>
            <button onClick={e => { this.initializeState(); this.props.onClose(e) }} className="btn--sm btn--default">
                <Translate>{`${this.TRANSLATION_PREFIX}:Close`}</Translate>
            </button>
        </div>
    }

    private renderSetLessonParameterForm = (): JSX.Element => {
        const { isLessonRequired, notifyOnStart, remindUser, notifyOnEnd, errors } = this.state;
        const absolute = ERelativeTimeSettingMode.Absolute;
        const relative = ERelativeTimeSettingMode.Relative;
        const mainElementClassName = 'assign-lesson-modal__form div-table__horizontal-table';
        const subElementsClassName = 'div-table__horizontal-table-row zebra assign-lesson-modal__unset-display';

        return <div className={mainElementClassName}>
            {/* Required Lesson && Required Lesson Score*/}
            <div className={subElementsClassName}>
                {this.createFormForCheckboxes('Required', 'isLessonRequired', () => this.setState({ isLessonRequired: !isLessonRequired }))}
                {isLessonRequired ? this.renderSetLessonScore() : null}
            </div>

            {/* Set start, end and target date: Relative or absolute */}
            <div className={subElementsClassName + ' assign-lesson-modal__inner-element__wide'}>
                <DropDownList
                    data={this.timeSettingModes}
                    defaultValue={this.timeSettingModes[0]}
                    label={this.getTranslation('SelectAssignmentType')}
                    onChange={(e) =>
                        this.setState({ selectedTimeSettingMode: e.target.value === this.timeSettingModes[1] ? relative : absolute })} />
            </div>

            {this.renderDateSettings(subElementsClassName + ' assign-lesson-modal__time-setting-form-element')}

            {/* Notify on Start */}
            <div className={subElementsClassName}>
                {
                    this.createFormForCheckboxes('NotifyOnStart', 'notifyOnStartOfLesson',
                        () => this.setState({ notifyOnStart: !notifyOnStart }))
                }
            </div>

            {/* Erinnerung einstellen? */}
            <div className={subElementsClassName}>
                {this.createFormForCheckboxes('RemindEmployee', 'remindUser', () => this.setState({ remindUser: !remindUser }))}
                {remindUser ? this.renderSetReminder() : null}
                {errors.reminderDate.length > 0 ?
                    <div className="spacing">
                        {
                            errors.reminderDate.split('\n').map((error, i) => {
                                return <p className="error" key={i}>{error}</p>
                            })
                        }
                    </div>
                    : null
                }
            </div>

            {/* Notify on End */}
            <div className={subElementsClassName}>
                {
                    this.createFormForCheckboxes('NotifyOnEndCheckBox', 'notifyOnEndOfLesson',
                        () => this.setState({ notifyOnEnd: !notifyOnEnd }))
                }
            </div>
        </div>
    }

    private renderDateSettings = (className: string): JSX.Element => {
        const isDisabled = this.state.isDisabled;
        const date = new Date();
        const assignmentDate = StringHelper.dateString(date);

        return <React.Fragment>
            <div className={className}>
                <TimeSettingFormElement
                    id={this.state.startDateSettings.id}
                    title={`${this.TRANSLATION_PREFIX}:StartDate`}
                    isDisabled={isDisabled}
                    setNewDateSettings={this.setNewDateSettings}
                    showOnlyAbsoluteDate={true}
                    selectedTimeSettingMode={this.state.selectedTimeSettingMode}
                    assignmentDate={assignmentDate}
                    initialDate={new Date(this.state.startDateSettings.ticks)} />
            </div>
            <div className={className}>
                <TimeSettingFormElement
                    id={this.state.targetDateSettings.id}
                    title={`${this.TRANSLATION_PREFIX}:TargetDate`}
                    isDisabled={isDisabled}
                    setNewDateSettings={this.setNewDateSettings}
                    showOnlyAbsoluteDate={false}
                    selectedTimeSettingMode={this.state.selectedTimeSettingMode}
                    assignmentDate={assignmentDate}
                    initialDate={new Date(this.state.targetDateSettings.ticks)} />
                {this.state.errors.targetDate.length > 0 ?
                    <div className="spacing">
                        {
                            this.state.errors.targetDate.split('\n').map((error, i) => {
                                return <p className="error" key={i}>{error}</p>
                            })
                        }
                    </div>
                    : null
                }
            </div>
            <div className={className}>
                <TimeSettingFormElement
                    id={this.state.endDateSettings.id}
                    title={`${this.TRANSLATION_PREFIX}:EndDate`}
                    isDisabled={isDisabled}
                    setNewDateSettings={this.setNewDateSettings}
                    showOnlyAbsoluteDate={false}
                    selectedTimeSettingMode={this.state.selectedTimeSettingMode}
                    assignmentDate={assignmentDate}
                    initialDate={new Date(this.state.endDateSettings.ticks)} />
                {this.state.errors.endDate.length > 0 ?
                    <div className="spacing">
                        {
                            this.state.errors.endDate.split('\n').map((error, i) => {
                                return <p className="error" key={i}>{error}</p>
                            })
                        }
                    </div>
                    : null
                }
            </div>
        </React.Fragment>
    }


    private renderSetReminder = (): JSX.Element => {
        const never = this.reminderFrequencyOptions[0];
        const daysAfter = this.timeSettingsDayModes[0];

        return (
            <div className="spacing">
                <legend><Translate>{`${this.TRANSLATION_PREFIX}:ReminderBeginning`}</Translate></legend>
                <div className="spacing assign-lesson-modal__set-reminder">
                    <NumericTextBox format="n" defaultValue={0} min={0}
                        disabled={this.state.isDisabled}
                        id="setReminderDays"
                        onChange={(e) => {
                            // TODO: THIS IS NOT ALLOWED PLEASE FIX
                            // eslint-disable-next-line react/no-direct-mutation-state
                            this.state.reminderDateSettings.days = e.value && e.value > -1 ? e.value : 0;
                            this.validateDateSettings();
                        }}
                        label={this.getTranslation('Days')} />
                    <DropDownList
                        data={this.timeSettingsDayModes}
                        onChange={(e) => this.dayModeChangeEvent(e)}
                        defaultValue={daysAfter} disabled={this.state.isDisabled}
                        textField="text"
                        dataItemKey="id"
                        label={this.getTranslation('TimeSettingsDayModes')} />
                </div>
                <legend><Translate>{`${this.TRANSLATION_PREFIX}:ReiterateReminder`}</Translate></legend>
                <div className="spacing">
                    <DropDownList
                        data={this.reminderFrequencyOptions}
                        onChange={(e) => this.setState({ selectedReminderFrequencyOption: e.target.value })}
                        defaultValue={never} disabled={this.state.isDisabled}
                        label={this.getTranslation('ReminderFrequencyOptions')} />
                </div>
            </div>
        );
    }

    private renderSetLessonScore = (): JSX.Element => {
        return <div
            className="spacing assign-lesson__set-score">
            <NumericTextBox
                className=""
                format="n"
                defaultValue={0}
                min={0} max={100}
                id="MyTeam__AssignLessons__requiredScore"
                disabled={!this.state.isLessonRequired}
                onChange={(e) => this.setState({ requiredScore: e ? e.value : null })}
                label={this.getTranslation('RequiredScore')}
            />
        </div>

    }

    private createFormForCheckboxes = (title: string, id: string, eventHandler: (e: React.ChangeEvent<HTMLInputElement>) => void): JSX.Element => {
        return <React.Fragment>
            <input
                id={id}
                type="checkbox"
                className="k-checkbox"
                onChange={eventHandler}
                disabled={this.state.isDisabled} />
            <label className="k-checkbox-label" htmlFor={id}>
                <Translate>{`${this.TRANSLATION_PREFIX}:${title}`}</Translate>
            </label>
        </React.Fragment>
    }

    private renderResponseResults = (): JSX.Element => {
        const successMsgs = new Array<string>();
        const alreadyAssignedMsgs = new Array<string>();
        const errorOnUsersMsgs = new Array<string>();

        const itemTitle = this.state.selectedItem !== undefined ? `${this.state.selectedItem.title} ( ${this.state.selectedItem.sId} )` : '';
        const success = `${this.getTranslation('UserSuccessfullyAdded')}: ${itemTitle}`;
        const alreadyAssignedMsg = this.getTranslation('ErrorLessonWasAlreadyAssigned');
        const errorOccured = this.getTranslation('ErrorOnTryingToAssignLesson');
        const modEmployees = this.props.employees;
        const response = this.state.serverResponse;

        if (response !== undefined && modEmployees !== undefined) {
            response.map(data => {
                const emp = modEmployees.find(e => e.id === data.userId);
                if (emp) {
                    if (data.assigned) {
                        successMsgs.push(emp.fullName);
                    }
                    if (data.isAlreadyAssigned) {
                        alreadyAssignedMsgs.push(emp.fullName);
                    }
                    if (!data.assigned && !data.isAlreadyAssigned) {
                        errorOnUsersMsgs.push(emp.fullName);
                    }
                }
            });
        }

        return (
            <React.Fragment>
                {
                    successMsgs.length > 0 ?
                        <React.Fragment>
                            <legend>{success}</legend>
                            <Alert
                                alertType={'success'}
                                alertAppereance={'box'}
                                messages={this.prepareAlertMessage(successMsgs, 'success')} />
                        </React.Fragment>
                        : null
                }
                {
                    alreadyAssignedMsgs.length > 0 ?
                        <React.Fragment>
                            <legend>{alreadyAssignedMsg}</legend>
                            <Alert
                                alertType={'info'}
                                alertAppereance={'box'}
                                messages={this.prepareAlertMessage(alreadyAssignedMsgs, 'info')} />
                        </React.Fragment>
                        : null
                }
                {
                    errorOnUsersMsgs.length > 0 ?
                        <React.Fragment>
                            <legend>{errorOccured}</legend>
                            <Alert
                                alertType={'error'}
                                alertAppereance={'box'}
                                messages={this.prepareAlertMessage(errorOnUsersMsgs, 'error')} />
                        </React.Fragment>
                        : null
                }
                <button onClick={e => { this.initializeState(); this.props.onClose(e) }} className="btn--sm btn--default">
                    <Translate>{`${this.TRANSLATION_PREFIX}:Close`}</Translate>
                </button>
            </React.Fragment>
        );
    }

    private prepareAlertMessage(msgs: string[], alertType: string): Array<{ message: string; alertType: 'info' | 'warning' | 'error' | 'success' }> {
        return msgs.map(msg => {
            return { message: msg, alertType: alertType as ('info' | 'warning' | 'error' | 'success') };
        });
    }

    // DateSettings Handling + Validation

    private setNewDateSettings = (id: string, selectedTimeSettingMode: ERelativeTimeSettingMode, selectedDayMode: ERelativeTimeSettingsDayMode, ticks: number, days: number) => {
        switch (id) {
            case 'start':
                this.setState({ startDateSettings: { days, id, selectedDayMode, selectedTimeSettingMode, ticks } }, () => this.validateDateSettings());
                break;
            case 'target':
                this.setState({ targetDateSettings: { days, id, selectedDayMode, selectedTimeSettingMode, ticks } }, () => this.validateDateSettings());
                break;
            case 'end':
                this.setState({ endDateSettings: { days, id, selectedDayMode, selectedTimeSettingMode, ticks } }, () => this.validateDateSettings());
        }
    }

    private validateDateSettings = () => {
        const targetValidationMssg = this.validateTargetDateSettings();
        const endValidationMssg = this.validateEndDateSettings();
        const reminderValidationMssg = this.validateReminderDateSettings();
        this.setState({
            errors: { targetDate: targetValidationMssg, endDate: endValidationMssg, reminderDate: reminderValidationMssg },
            submitDisabled: targetValidationMssg === '' && endValidationMssg === '' && reminderValidationMssg === '' ? false : true
        });
    }

    private validateTargetDateSettings = () => {
        const todayMidnight = new Date().setHours(0, 0, 0, 0);
        const timeMode = this.state.selectedTimeSettingMode;
        const dayMode = this.state.targetDateSettings.selectedDayMode;
        const targetDays = this.state.targetDateSettings.days;
        // In order to compare the date ticks (in ms) easier, they must have the same time set.
        const targetTicks = new Date(this.state.targetDateSettings.ticks).setHours(0, 0, 0, 0);
        const startTicks = new Date(this.state.startDateSettings.ticks).setHours(0, 0, 0, 0);
        const endTicks = this.state.endDateSettings.ticks === 0 ? 0 : new Date(this.state.endDateSettings.ticks).setHours(0, 0, 0, 0);
        let errorMssg = '';

        if (timeMode === ERelativeTimeSettingMode.Absolute) {
            if (targetTicks <= startTicks) {
                errorMssg += this.getTranslation('ErrorTargetBeforeStartDate') + '\n';
            }
            if (targetTicks < todayMidnight) {
                errorMssg += this.getTranslation('ErrorTargetBeforeAssignmentDate') + '\n';
            }
            if (this.state.endDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {
                if (targetTicks >= endTicks && endTicks != 0) {
                    errorMssg += this.getTranslation('ErrorTargetAfterEndDate') + '\n';
                }
            }
        }
        // Relative to assignment Date, but validated as relative to start date
        if (timeMode === ERelativeTimeSettingMode.Relative) {
            // Relative to Start Date: Only if start date is today or in the future, because today would be the assignment date, relative to which target Date will be saved to DB
            const relativeTargetDateToStartDateTicks = this.getRelativeTimeSetting(startTicks, targetDays, dayMode);
            if (relativeTargetDateToStartDateTicks <= startTicks) {
                errorMssg += this.getTranslation('ErrorRelativeTargetBeforeStartDate') + '\n';
            }
            // Relative to absolute End Date
            if (this.state.endDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {
                if (relativeTargetDateToStartDateTicks >= endTicks) {
                    errorMssg += this.getTranslation('ErrorRelativeTargetAfterEndDate') + '\n';
                }
            }
            // Relative to an End Date which itself has to be checked when relative to the Start Date
            if (this.state.endDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Relative) {
                const relativeEndDate = this.getRelativeTimeSetting(startTicks, this.state.endDateSettings.days, this.state.endDateSettings.selectedDayMode);
                if (relativeTargetDateToStartDateTicks >= relativeEndDate) {
                    errorMssg += this.getTranslation('ErrorRelativeTargetAfterRelativeEndDate') + '\n';
                }
            }
        }

        return errorMssg;
    }

    private validateEndDateSettings = () => {
        const todayMidnight = new Date().setHours(0, 0, 0, 0);
        const timeMode = this.state.selectedTimeSettingMode;
        const dayMode = this.state.endDateSettings.selectedDayMode;
        const endDays = this.state.endDateSettings.days;
        // In order to compare the date ticks (in ms) easier, they must have the same time set.
        const targetTicks = new Date(this.state.targetDateSettings.ticks).setHours(0, 0, 0, 0);
        const startTicks = new Date(this.state.startDateSettings.ticks).setHours(0, 0, 0, 0);
        const endTicks = this.state.endDateSettings.ticks === 0 ? 0 : new Date(this.state.endDateSettings.ticks).setHours(0, 0, 0, 0);
        let errorMssg = '';

        if (timeMode === ERelativeTimeSettingMode.Absolute) {
            if (endTicks <= startTicks && endTicks != 0) {
                errorMssg += this.getTranslation('ErrorEndBeforeStartDate') + '\n';
            }
            if (endTicks < todayMidnight && endTicks != 0) {
                errorMssg += this.getTranslation('ErrorEndBeforeAssignmentDate') + '\n';
            }
            if (this.state.targetDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {
                if (endTicks <= targetTicks && endTicks != 0) {
                    errorMssg += this.getTranslation('ErrorEndBeforeTargetDate') + '\n';
                }
            }

        }

        // Relative to assignment Date, but validated as relative to start date
        if (timeMode === ERelativeTimeSettingMode.Relative) {
            // Relative to Start Date: Only if start date is today or in the future, because today would be the assignment date, relative to which target Date will be saved to DB
            if (startTicks >= todayMidnight) {
                const relativeEndDateToStartDateTicks = this.getRelativeTimeSetting(startTicks, endDays, dayMode);
                if (relativeEndDateToStartDateTicks <= startTicks) {
                    errorMssg += this.getTranslation('ErrorRelativeEndBeforeStartDate') + '\n';
                }
                // Relative to absolute target Date
                if (this.state.targetDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {
                    if (relativeEndDateToStartDateTicks <= targetTicks) {
                        errorMssg += this.getTranslation('ErrorRelativeEndBeforeTargetDate') + '\n';
                    }
                }
                // Relative to a Target Date which itself has to be checked when relative to the Start Date
                if (this.state.targetDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Relative) {
                    const realtiveTargetDate = this.getRelativeTimeSetting(startTicks, this.state.targetDateSettings.days, this.state.targetDateSettings.selectedDayMode);
                    if (relativeEndDateToStartDateTicks <= realtiveTargetDate) {
                        errorMssg += this.getTranslation('ErrorRelativeEndBeforeRelativeTargetDate') + '\n';
                    }
                }
            } else {
                // @@not relative to midnight but assignmentDate? Nicht sicher...
                const relativeEndDateToToday = this.getRelativeTimeSetting(todayMidnight, endDays, dayMode);
                // Relative to absolute Target Date
                if (this.state.targetDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {
                    if (relativeEndDateToToday <= targetTicks) {
                        errorMssg += this.getTranslation('ErrorRelativeEndBeforeTargetDate') + '\n';
                    }
                }
                // Relative to a Target Date which itself has to be checked when relative to the Start Date
                if (this.state.targetDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Relative) {
                    const relativeTargetDateToToday = this.getRelativeTimeSetting(todayMidnight, this.state.targetDateSettings.days, this.state.targetDateSettings.selectedDayMode);
                    if (relativeEndDateToToday <= relativeTargetDateToToday) {
                        errorMssg += this.getTranslation('ErrorRelativeEndBeforeRelativeTargetDate') + '\n';
                    }
                }
            }
        }

        return errorMssg;
    }

    private validateReminderDateSettings = () => {
        const todayMidnight = new Date().setHours(0, 0, 0, 0);
        const dayMode = this.state.reminderDateSettings.selectedDayMode;
        const reminderDays = this.state.reminderDateSettings.days;
        // In order to compare the date ticks (in ms) easier, they must have the same time set.
        const startTicks = new Date(this.state.startDateSettings.ticks).setHours(0, 0, 0, 0);
        const endTicks = this.state.endDateSettings.ticks === 0 ? 0 : new Date(this.state.endDateSettings.ticks).setHours(0, 0, 0, 0);
        let errorMssg = '';

        if (this.state.remindUser) {
            const relativeReminderDateToStartDate = this.getRelativeTimeSetting(startTicks, reminderDays, dayMode);
            if (relativeReminderDateToStartDate < startTicks) {
                errorMssg += this.getTranslation('ErrorReminderBeforeStart') + '\n';
            }
            // Relative to absolute target Date
            if (this.state.endDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {
                if (relativeReminderDateToStartDate >= endTicks && endTicks != 0) {
                    errorMssg += this.getTranslation('ErrorReminderAfterEqualEndDate') + '\n';
                }
            }
            if (this.state.endDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Relative) {
                const relativeEndDateToStartDate = this.getRelativeTimeSetting(todayMidnight, this.state.endDateSettings.days, this.state.endDateSettings.selectedDayMode);
                if (relativeReminderDateToStartDate >= relativeEndDateToStartDate) {
                    errorMssg += this.getTranslation('ErrorReminderAfterEqualEndDate') + '\n';
                }
            }
        }

        return errorMssg;
    }

    // Event Handlers

    private dayModeChangeEvent(e: DropDownListChangeEvent) {
        const reminderDateSettingsCopy = this.state.reminderDateSettings;
        reminderDateSettingsCopy.selectedDayMode = e.target.value.id as ERelativeTimeSettingsDayMode;
        this.setState({ reminderDateSettings: reminderDateSettingsCopy }, () => this.validateDateSettings());
    }

    // Other helper methods

    /**
     * Calculates by time settings Day Mode (ERelativeTimeSettingsDayMode) the relative ticks
     * @param tickToBeRelativeTo number of ticks respective to which you wish to calculate the relative ticks to
     * @param amountOfDays amount of days its relative to the ticks given
     * @param timeSettingDayMode day mode (ERelativeTimeSettingsDayMode)
     * @returns relative ticks to the assignment Date (today at Midnight)
     */
    private getRelativeTimeSetting = (tickToBeRelativeTo: number, amountOfDays: number | null, timeSettingDayMode: ERelativeTimeSettingsDayMode): number => {
        if (amountOfDays !== null && timeSettingDayMode) {
            const dateToBeRelativeTo = new Date(tickToBeRelativeTo);
            let days = amountOfDays;

            if (timeSettingDayMode === ERelativeTimeSettingsDayMode.DaysLater) {
                dateToBeRelativeTo.setDate(dateToBeRelativeTo.getDate() + days);
                return dateToBeRelativeTo.getTime();
            } else {
                const increment = 1;
                while (Math.abs(days) > 0) {
                    // If not sunday or saturday
                    if (dateToBeRelativeTo.getDay() !== 6 && dateToBeRelativeTo.getDay() !== 7) {
                        days -= increment;
                    }
                    dateToBeRelativeTo.setDate(dateToBeRelativeTo.getDate() + increment);
                }
                return dateToBeRelativeTo.getTime();
            }
        } else {
            return 0;
        }
    }

    private initializeState = () => {
        return {
            errors: {
                endDate: '',
                reminderDate: '',
                targetDate: '',
            },
            hasServerResponse: false,
            serverError: '',
            serverResponse: undefined,
            showOnServerError: false,

            selectedReminderFrequencyOption: this.getTranslation('Never'),
            selectedTimeSettingMode: ERelativeTimeSettingMode.Absolute,

            isDisabled: true,
            isLessonRequired: false,
            notifyOnEnd: false,
            notifyOnStart: false,
            remindUser: false,
            requiredScore: 0,
            selectedItem: undefined,
            showAssignLessonsModal: false,
            submitDisabled: true,


            reminderDateSettings: {
                days: 0,
                id: 'reminder',
                selectedDayMode: ERelativeTimeSettingsDayMode.DaysLater,
                selectedTimeSettingMode: ERelativeTimeSettingMode.Relative,
                ticks: new Date().setHours(8, 0, 0, 0),
            },
            startDateSettings: {
                days: 0,
                id: 'start',
                selectedDayMode: ERelativeTimeSettingsDayMode.DaysLater,
                selectedTimeSettingMode: ERelativeTimeSettingMode.Absolute,
                ticks: new Date().setHours(0, 0, 0, 0),
            },
            targetDateSettings: {
                days: 0,
                id: 'target',
                selectedDayMode: ERelativeTimeSettingsDayMode.DaysLater,
                selectedTimeSettingMode: ERelativeTimeSettingMode.Absolute,
                ticks: new Date(new Date().setDate(new Date().getDate() + 7)).setHours(23, 59, 0, 0),
            },
            endDateSettings: {
                days: 0,
                id: 'end',
                selectedDayMode: ERelativeTimeSettingsDayMode.DaysLater,
                selectedTimeSettingMode: ERelativeTimeSettingMode.Absolute,
                ticks: new Date(new Date().setDate(new Date().getDate() + 14)).setHours(23, 59, 0, 0),
            },
        }
    }

    private createRequestObject = (employeeIds: number[]) => {
        const {
            selectedItem, isLessonRequired, requiredScore,
            notifyOnStart, notifyOnEnd, startDateSettings,
            targetDateSettings, endDateSettings, reminderDateSettings,
            remindUser, selectedReminderFrequencyOption, selectedTimeSettingMode
        } = this.state;
        const request = new AssignLessonToUserRequest();

        request.employeeIds = employeeIds;
        request.itemId = selectedItem !== undefined ? selectedItem.itemId : 0;
        request.isLessonRequired = isLessonRequired;
        request.requiredScore = requiredScore ? requiredScore : 0;
        request.notifyOnStart = notifyOnStart;
        request.notifyOnEnd = notifyOnEnd;

        request.selectedTimeSettingMode = selectedTimeSettingMode;

        request.assRunningPeriodStart = startDateSettings.ticks;
        request.startPeriodMode = ERelativeTimeSettingMode.Absolute;

        if (selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute) {

            request.targetPeriodMode = ERelativeTimeSettingMode.Absolute;
            request.assRunningPeriodTarget = targetDateSettings.ticks;

            request.endPeriodMode = ERelativeTimeSettingMode.Absolute;
            request.assRunningPeriodEnd = endDateSettings.ticks;
        }
        if (selectedTimeSettingMode === ERelativeTimeSettingMode.Relative) {
            request.targetPeriodMode = ERelativeTimeSettingMode.Relative;
            request.targetOffsetDayMode = targetDateSettings.selectedDayMode;
            request.targetOffsetDays = targetDateSettings.days;

            request.endPeriodMode = ERelativeTimeSettingMode.Relative;
            request.endOffsetDayMode = endDateSettings.selectedDayMode;
            request.endOffsetDays = endDateSettings.days;
        }

        // Check Reminder Date Settings
        if (reminderDateSettings.selectedTimeSettingMode === ERelativeTimeSettingMode.Relative) {
            request.reminderOffsetDayMode = reminderDateSettings.selectedDayMode;
            request.reminderOffsetDays = reminderDateSettings.days;
        }

        if (remindUser) {
            request.remindUser = remindUser;
            request.reminderOffsetDayMode = reminderDateSettings.selectedDayMode;
            request.reminderOffsetDays = reminderDateSettings.days;
            request.reminderInterval = this.reminderFrequencyOptions.indexOf(selectedReminderFrequencyOption);
        }

        return request;
    }

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }

}

export default AssignLessonsModal;