import React from 'react';

import { Heading } from '$components/shared/Heading';
import { Attribute } from '$storage/models/Attribute';
import { EItemDetailCallerContextType, EItemType } from '$storage/models/enums';
import { Item } from '$storage/models/Item';
import { ItemHelper } from '$util/ItemHelper';
import '$util/stringExtensions';
import { NavLink } from 'react-router-dom';

interface IState{
    itemLinkStyle?: React.CSSProperties;
}

interface IProps {
    item: Item;
    itemLinkStyle?: React.CSSProperties;
    parentHeadingLevel: number;
    showItemBackground: boolean;
    itemDetailCallerContextType: EItemDetailCallerContextType; // The type of the caller
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number | null;

}

export class ItemSummaryBasic extends React.Component<IProps,IState> {
    protected className = 'ItemSummaryBasic';
    protected loggerLocality = 'Components.ItemSummaryBasic';
    constructor(props: IProps) {
        super(props);
        this.state= {
            itemLinkStyle:{}
        }
    }

    public componentDidMount() {

        let itemLinkStyleDef: React.CSSProperties | undefined = this.props.itemLinkStyle;

        if (this.props.showItemBackground) {
            const itemImageAttr: Attribute | undefined = this.props.item.attributes.find(f => f.name === 'ItemImage');
            if (itemImageAttr !== undefined) {
                    const itemImage = 'url(' + itemImageAttr.getMediaStreamUrl() + ')';
                    if (itemLinkStyleDef != null) {
                        itemLinkStyleDef.backgroundImage = itemImage;
                    }
                    else {
                        itemLinkStyleDef = { backgroundImage: itemImage }
                    }
                    this.setState({
                        itemLinkStyle: itemLinkStyleDef
                    })
            }
        }
    }
    
    public render() {
        return (
            <NavLink
                to={this.getLink()}
                style={this.state.itemLinkStyle}>
                <div className={'title' + (this.props.showItemBackground ? ' title--with-background' : '')}>
                    <Heading
                        headingLevel={this.props.parentHeadingLevel + 1}
                        cssClass={'heading__Level4' + (this.props.showItemBackground ? ' heading--secondary' : '')}>
                        {this.props.item.title}
                    </Heading>
                </div>
            </NavLink>
        );
    }

    protected getLink(): string {
        const id = this.props.item.itemId;
        if (this.props.item.itemType === EItemType.TrainingPlan) {
            return ItemHelper.getTrainingPlanDetailLink(id,
                                                        this.props.itemDetailCallerContextType,
                                                        this.props.parentCatalogFolderId,
                                                        this.props.parentAssignmentId)
        } else {
            return ItemHelper.getItemDetailLink(id,
                                                this.props.itemDetailCallerContextType,
                                                this.props.parentCatalogFolderId,
                                                this.props.parentAssignmentId,
                                                this.props.parentTrainingPlanId,
                                                0)
        }
    }
}