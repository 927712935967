import { JsonObject, JsonProperty } from "json2typescript";
import { TCDocument } from "./TCDocument";
import { ETCApprovalStatus, ETCApprovalMethod } from "../enums";

/**
 * Termns and condition approval status
 */
@JsonObject
export class UserTCApprovalStatus {
    /** Primary key */
    @JsonProperty('id', Number, false)
    public id: number = 0;

    /** Status: Accepted, Refused, None */
    @JsonProperty('approvalStatus', ETCApprovalStatus, false)
    public approvalStatus: ETCApprovalStatus = ETCApprovalStatus.Any;

    /** Method: Digital, Paper, Email, Phone */
    @JsonProperty('approvalMethod', ETCApprovalMethod, false)
    public approvalMethod: ETCApprovalMethod = ETCApprovalMethod.Any;

    /** Date of the document was approved/declined */
    @JsonProperty('entryDate', Date, false)
    public entryDate: Date = new Date();

    /** Document reference id */
    @JsonProperty('documentId', Number, false)
    public documentId?: number = undefined;

    /** Document reference */
    @JsonProperty('document', TCDocument, false)
    public document?: TCDocument = undefined;
}