import React from 'react';

import IconStarOff from '$resources/svgs/favorites/favorite-off.svg';
import IconStarOn from '$resources/svgs/favorites/favorite-on.svg';

import Session from '$src/core/Session';
import { EFavoriteType } from '$src/storage/models/enums';
import { Favorite } from '$src/storage/models/Favorite';
import GtError from '$src/util/GtError';
import { KeyPressHelper } from '$src/util/KeyPressHelper';
import { isSuccess } from '$src/util/Result';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';


interface IProps {
    itemId: number;
    type: EFavoriteType;
    onFavoriteAdd?: () => void;
    itemTitle?: string;
}

interface IState {
    favorite: Favorite | null;
    dataLoaded: boolean;
}
export class FavoriteRibbon extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dataLoaded: false,
            favorite: null,
        }
    }

    public componentDidMount() {
        const response: null | Favorite | GtError = Session.instance.storage.favorites.getFavoriteStatus(this.props.itemId, this.props.type);
        if (isSuccess<Favorite | null>(response)) {
            this.setState({ favorite: response, dataLoaded: true });
        } else {
            this.setState({ favorite: null, dataLoaded: true });
        }
    }

    public render(): JSX.Element {
        if (this.state.dataLoaded) {
            const hoverText = this.state.favorite == null ?
                Session.instance.storage.translation.GetString('Favorite:Add') + ': ' + this.props.itemTitle :
                Session.instance.storage.translation.GetString('Favorite:Remove') + ': ' + this.state.favorite.title;
            return (
                <div className="favorites__ribbon"
                    onKeyUp={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => e.stopPropagation()) }} >
                    <button className="mylist__toolbar-icon"
                        aria-label={hoverText}
                        data-tip={hoverText}
                        data-for={`${this.props.itemId}_favoriteToggleTooltip`}
                        onClick={(e) => this.onFavoriteToggle(e)}>
                        <InlineSVG src={this.state.favorite == null ? IconStarOff : IconStarOn} />
                        <Tooltip id={`${this.props.itemId}_favoriteToggleTooltip`} place="bottom" delayShow={500} />
                    </button>
                </div>);
        } else {
            return <React.Fragment />;
        }
    }

    public async onFavoriteToggle(event: React.FormEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();
        if (this.state.favorite != null) {
            await Session.instance.storage.favorites.removeFavorite(this.state.favorite.id);
            this.setState({ favorite: null });
        } else {
            const response = await Session.instance.storage.favorites.addFavorite(this.props.itemId, this.props.type,
                Session.instance.getUserLanguageCodeOrFallBack);
            if (isSuccess<Favorite | null>(response)) {
                if (response.type === EFavoriteType.Item) {

                    await Session.instance.storage.watchList.AddSubscriber(this.props.itemId);
                    if (this.props.onFavoriteAdd != null) {
                        this.props.onFavoriteAdd();
                    }
                }
                this.setState({ favorite: response });
            } else {
                this.setState({ favorite: null });
            }
        }
    }
}