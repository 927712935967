
import ServiceClient from '$core/ServiceClient';
import GtError from '$util/GtError';
import { EvaluationSkill } from '$src/storage/models/EvaluationSkill';
import { EvaluationGroup } from '$src/storage/models/EvaluationGroup';
import { EvaluationSkillRequest } from '$src/storage/models/RequestObjects/EvaluationSkillRequest';
import { EvaluationSkillData } from '$src/storage/models/EvaluationSkillData';
import { EvaluationOfTplan } from '$src/storage/models/EvaluationOfTplan';
import { EvaluationOfTplanData } from '$src/storage/models/EvaluationOfTplanData';

/**
 * UserService provides all Service Methods for the Reporting 
 */
export default class ReportingService extends ServiceClient {
    protected static _instance: ReportingService | null = null;

    protected className = 'ReportingService';
    protected loggerLocality = 'ReportingService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ReportingService {
        return this._instance || (this._instance = new this());
    }

    /**
     * 
     * @returns all skill which the user has the show or assign right or both
     */
    public async getSkillsForSkillEvaluation(language: string): Promise<EvaluationSkill[] | GtError> {
        const response: EvaluationSkill[] | GtError = await this.get<EvaluationSkill[]>('reporting/getSkillsForSkillEvaluation', EvaluationSkill, undefined,  [{ name: 'language', value: language}]);
        return response;
    }


    /**
     * 
     * @returns all groups which the user has the tutorize right
     */
    public async getGroupsForSkillEvaluation(bossRelationCode: string = ""): Promise<EvaluationGroup[] | GtError> {
        const response: EvaluationGroup[] | GtError = await this.get<EvaluationGroup[]>('reporting/getGroupsForSkillEvaluation', EvaluationGroup, undefined, [{ name: 'bossRelationCode', value: bossRelationCode}]);
        return response;
    }

    /**
     * 
     * @param language 
     * @param request holds the groupIds, and the skillIds
     * @returns all user in the groups with the userskill 
     */
    public async getDataForSkillEvaluation(language: string, request: EvaluationSkillRequest): Promise<EvaluationSkillData[] | GtError> {
        const response: EvaluationSkillData[] | GtError = await this.post<EvaluationSkillData[]>('reporting/getDataForSkillEvaluation', request , EvaluationSkillData, undefined, [{ name: 'language', value: language}])
        return response;
    }

    /**
     * 
     * @param language 
     * @param bossRelationCode 
     * @returns get all Tplans with schedules with tutorize right for a boss for selection
     */
    public async getEvaluationOfTplanSelection(language: string, bossRelationCode: string): Promise<EvaluationOfTplan[] | GtError> {
        const response: EvaluationOfTplan[] | GtError = await this.get<EvaluationOfTplan[]>('reporting/getEvaluationOfTplanSelection', EvaluationOfTplan, undefined,
            [{ name: 'language', value: language },
            { name: 'bossRelationCode', value: bossRelationCode }]);
        return response;
    }

    /**
     * 
     * @param language 
     * @param bossRelationCode 
     * @param tplanId 
     * @param scheduleId 
     * @returns  Get Tplan, schedule, tplan element and uls data for reporting
     */
    public async getEvaluationOfTplanData(language: string, bossRelationCode: string, tplanId: number, scheduleId: number, bossEmployeeACLCheck: boolean, tpeType: number): Promise<EvaluationOfTplanData[] | GtError> {
        const response: EvaluationOfTplanData[] | GtError = await this.get<EvaluationOfTplanData[]>('reporting/getEvaluationOfTplanData', EvaluationOfTplanData, undefined,
            [{ name: 'language', value: language },
            { name: 'bossRelationCode', value: bossRelationCode },
            { name: 'tplanId', value: tplanId.toString() },
            { name: 'scheduleId', value: scheduleId.toString() },
            { name: 'bossEmployeeACLCheck', value: bossEmployeeACLCheck.toString() },
            { name: 'tpeType', value: tpeType.toString() },
            ]);
        return response;
    }
} 