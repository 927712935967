import { UserResponse } from "$src/storage/models/MultiFactorAuthentication/UserResponse";
import React from "react";
import { PasswordReset } from "$components/login/PasswordReset";
import GTConfirm from "$components/shared/Atoms/GTConfirm";
import { ModalPopup } from "$components/shared/ModalPopup";
import { Alert } from "$components/shared/WarningsAndErrors/Alert";
import CheckDataForPasswordRecovery from "./CheckDataForPasswordRecovery";
import { useHistory } from "react-router";
import Session from "$src/core/Session";

interface IProps {
    userData: UserResponse;
    initialCode: string;
}

export default function ThirdStep(props: IProps) {
    const history = useHistory();

    const [showPasswordReset, setShowPasswordReset] = React.useState(false);

    if (props.userData.isFirstLogin) {
        return (
            <CheckDataForPasswordRecovery userData={props.userData} initialCode={props.initialCode}/>
        );
    } else {
        return (
            <>
                <div className="l-box__input">
                    <Alert alertType="info" alertAppereance="single-line" message="MFA:UserAlreadyActivated" />
                </div>
                <GTConfirm
                    onConfirm={() => setShowPasswordReset(true)}
                    onCancel={() => history.push('/')}
                    cancelText={Session.instance.storage.translation.GetString('MFA:GoToLogin')}
                    confirmText={Session.instance.storage.translation.GetString('MFA:PasswordReset')} />
                <ModalPopup isOpen={showPasswordReset}
                    onRequestClose={() => setShowPasswordReset(false)}>
                    <PasswordReset onResetClick={() => setShowPasswordReset(false)} />
                </ModalPopup>
            </>
        )
    }
}