import React from 'react';
import { KeyPressHelper } from '$src/util/KeyPressHelper';

interface IProps {
    id: string;
    name?: string;
    containerClassName?: string;
    className?: string;
    disabled?: boolean;
    required?: boolean;
    value?: string;
    defaultChecked?: boolean;
    onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    onClick?: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    labelId?: string;
    standardCheckbox?: boolean;
    ariaLabel?: string;
}

interface IState {
    value?: string;
}

// tslint:disable:object-literal-sort-keys
export class CheckBox extends React.Component<IProps, IState> {
    public inputCheckBox: HTMLInputElement | null;
    protected className = 'CheckBox';
    protected loggerLocality = 'Components\shared.CheckBox';

    constructor(props: IProps) {
        super(props);

        let val: string;
        if (this.props.value !== null && this.props.value !== undefined) {
            val = this.props.value
        }
        else if (this.props.defaultChecked !== null && this.props.defaultChecked !== undefined) {
            val = this.props.defaultChecked.toString()
        }
        else {
            val = (false).toString()
        }

        this.state = {
            value: val
        };

        this.onLabelKeyUp = this.onLabelKeyUp.bind(this);
    }

    public render() {
        return (
            <div className={this.props.containerClassName === null || this.props.containerClassName === undefined ? 'checkbox-container' :
                this.props.containerClassName + ' checkbox-container'}>
                <input
                    key={'CheckBox_' + this.props.id}
                    id={this.props.id}
                    name={this.props.name}
                    className={(this.props.className != null ? this.props.className : '') + ' ' +
                        this.props.standardCheckbox == null || !this.props.standardCheckbox ? 'k-checkbox' : ''}
                    type="checkbox"
                    disabled={this.props.disabled}
                    required={this.props.required}
                    value={this.state.value}
                    defaultChecked={this.props.defaultChecked || false}
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    onClick={this.onClick}
                    ref={ref => (this.inputCheckBox = ref)}
                    aria-labelledby={this.props.labelId}
                    aria-label={this.props.ariaLabel} />
                {this.props.children && <label
                    htmlFor={this.props.id}
                    key={this.props.id + '_cboverride'}
                    className={this.props.standardCheckbox == null || !this.props.standardCheckbox ? 'k-checkbox-label' : ''}
                >
                    {this.props.children}
                </label>}
            </div>
        );
    }

    protected onChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.currentTarget as HTMLInputElement;
        const value: boolean = target.checked;
        this.setState({
            value: value.toString(),
        })

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    protected onClick = (event: React.FormEvent<HTMLInputElement>) => {
        if (this.props.onClick) {
            this.props.onClick(event);
        }
    }

    protected onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(event);
        }
    }

    protected onLabelKeyUp = (event: React.KeyboardEvent<HTMLLabelElement>) => {
        if (KeyPressHelper.isSpaceOrEnter(event)) {
            let isChecked = false;
            if (this.inputCheckBox !== null && this.inputCheckBox !== undefined) {
                isChecked = this.inputCheckBox.checked;
                this.inputCheckBox.checked = !this.inputCheckBox.checked;
            }
            this.setState({
                value: isChecked.toString()
            })
        }
    }

}