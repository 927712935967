import React from "react"
import GTButton from "$components/shared/Atoms/GTButton";
import { Translate } from "$components/shared/Translate"

interface ICollaborationProviderClassLinkProps {
    link: string;
    title: string;
}


export const CollaborationProviderLink: React.FC<ICollaborationProviderClassLinkProps> = (props: ICollaborationProviderClassLinkProps) => {
    return <GTButton onClick={() => window.open(props.link, '_blank')}>
            <Translate>{props.title}</Translate>
            </GTButton>
}