import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseAttendanceRequest {

    @JsonProperty('registrationId', Number, false)
    public registrationId: number;

    @JsonProperty('hasAttended', Boolean, false)
    public hasAttended: boolean;
    
    @JsonProperty('cancellationId', Number, false)
    public cancellationId: number;
    
    @JsonProperty('cancellationReason', String, false)
    public cancellationReason: string;
}