import ServiceClient from '$src/core/ServiceClient';
import { ECancellationType } from '$src/storage/models/enums';
import { CancellationReasonModel } from '$src/storage/models/Workflow/CancellationReasonModel';
import { WorkflowActivity } from '$src/storage/models/Workflow/WorkflowActivity';
import GtError from '$util/GtError';

export interface INameValuePair{
    Name: string;
    Value: string;
}


export default class WorkflowActivityService extends ServiceClient {
    protected static _instance: WorkflowActivityService | null = null;

    protected className = 'WorkflowActivity';
    protected loggerLocality = 'WorkflowActivityService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): WorkflowActivityService {
        return this._instance || (this._instance = new this());
    }

     /**
      * Get lesson detail of a selected item
      * @param itemId Id of lesson
      * @param language language
      */
    public async getTodoList(): Promise<WorkflowActivity[] | GtError> {
        const response: WorkflowActivity[] | GtError = await this.get<WorkflowActivity[] >('GetTodoList', WorkflowActivity , undefined, undefined);        
        return response;
    }

    public async getCancellationReasons(): Promise<CancellationReasonModel[] | GtError> {
        const response: CancellationReasonModel[] | GtError = await this.get<CancellationReasonModel[] >
            ('GetCancellationReasons', CancellationReasonModel , undefined,undefined, ECancellationType.Enrolment.toString());
        return response;
    }
    
    public async getCancellationReasonsByType(cancellationType: ECancellationType, itemID: number): Promise<CancellationReasonModel[] | GtError> {
        const response: CancellationReasonModel[] | GtError = await this.get<CancellationReasonModel[] >
            ('GetCancellationReasons', CancellationReasonModel , undefined,undefined, cancellationType.toString(), itemID.toString());
        return response;
    }

    public async setWorkflowActivity(arr: INameValuePair[][]): Promise<void | GtError> {
        try
        {
            await this.postVoid('UpdateWorkflowActivities', arr);
            return
        }
        catch(error) {
            return new GtError().initFromHttpResponseError(error).writeToSession();
        }
    }


}