import * as React from 'react';

import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import DateHelper from '$src/util/DateHelper';
import { DatePicker } from '$components/shared/DatePicker';

interface IProps {
    skillTitle: string;
    parentHeadingLevel: number;
    onConfirmedClick?: (expirationDate: Date | null) => void;
    onExitClicked?: () => void;
    actualSkillLevel: string;
    targetSkillLevel: string;
    isExpirationDateRequired: boolean;
    isSkillLevelExpired: boolean;
}

interface IState {
    disableConfirmButton: boolean;
    responseWait: boolean;
    errorMessage: string;
    expirationDate: Date | null;
}

/**
 * Confirmation component to change skill to a new skill level
 */
export class ChangeSkillLevelConfirmation extends React.Component<IProps, IState> {
    protected className = 'ChangeSkillLevelConfirmation';
    protected loggerLocality = 'Components.ChangeSkillLevelConfirmation';

    constructor(props: IProps) {
        super(props);

        const today = new Date();

        this.state = {
            disableConfirmButton: false,
            errorMessage: '',
            expirationDate: this.props.isExpirationDateRequired ? new Date(today.getFullYear(),
                today.getMonth() + globalConfig.performanceReviewProperties.addMonthForSkillExpiration,
                today.getDate()) : null,
            responseWait: false,
        }
    }

    public render() {

        if (this.state.responseWait) {
            return <ProgressSpinner />;
        } else {
            const tr = Session.instance.storage.translation;
            const confirmationMsg = tr.GetString('PerformanceReview:ChangeSkillLevel').Format(this.props.actualSkillLevel, this.props.targetSkillLevel);
            const ariaLabelCancel = tr.GetString('PerformanceReview:CancelSkillLevelChange')
                + ' '
                + this.props.skillTitle
            const ariaLabelConfirm = tr.GetString('PerformanceReview:ConfirmSkillLevelChange')
                + ' '
                + this.props.skillTitle

            return (

                <div>
                    <Heading cssClass="heading__Level2" headingLevel={this.props.parentHeadingLevel + 1}>
                        <Translate>{confirmationMsg}</Translate>
                    </Heading>
                    {this.props.isExpirationDateRequired &&
                        <div className="columnFlex">
                            <label className="k-form-field" htmlFor="dtExpirationDate">
                                <Translate>PerformanceReview:lblExpirationDate</Translate>
                            </label>
                            <DatePicker
                                onChange={(d) => this.onDateChanged(d)}
                                value={this.state.expirationDate}
                                minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1)}
                            />
                            <span className={'input-message error'}>
                                <Translate>PerformanceReview:EvaluationsAreFinalized</Translate>
                            </span>
                        </div>
                    }
                    {this.state.errorMessage.length > 0 &&
                        <span className={'input-message error'}>
                            {this.state.errorMessage}
                        </span>}
                    {this.props.isSkillLevelExpired &&
                        <span className={'input-message error'}>
                            <Translate>PerformanceReview:LevelExpiredInfo</Translate>
                        </span>}
                    <div className="modal__spread-buttons">
                        <button type="button"
                            aria-label={ariaLabelConfirm}
                            className="btn btn--sm marginRight-5"
                            disabled={this.state.disableConfirmButton}
                            onClick={() => this.onButtonClickConfirm()}>
                            <Translate>PerformanceReview:ConfirmSkillLevelChange</Translate>
                        </button>
                        <button type="button"
                            aria-label={ariaLabelCancel}
                            className="btn btn--sm"
                            onClick={() => this.onButtonExitClicked()}>
                            <Translate>PerformanceReview:CancelSkillLevelChange</Translate>
                        </button>
                    </div>
                </div>

            )
        }
    }

    private onDateChanged(selectedDate: Date | null) {
        // Expiration Date must be in the future
        if (this.props.isExpirationDateRequired && DateHelper.CompareDate(new Date(), selectedDate) === 1) {
            this.setState({
                disableConfirmButton: true,
                errorMessage: Session.instance.storage.translation.GetString('PerformanceReview:ExpirationDateMustBeInFuture'),
                expirationDate: selectedDate
            });
        } else {
            this.setState({ disableConfirmButton: false, expirationDate: selectedDate, errorMessage: '' });
        }
    }

    private onButtonExitClicked() {
        if (this.props.onExitClicked) {
            this.props.onExitClicked();
        }
    }

    private onButtonClickConfirm() {
        if (this.props.onConfirmedClick && this.state.errorMessage.length === 0) {
            this.setState({ responseWait: true });
            this.props.onConfirmedClick(this.state.expirationDate)
        }
    }

}