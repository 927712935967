import ServiceClient from '$core/ServiceClient';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Deputy } from '$src/storage/models/Deputy';
import { DeputyDeleteRequest } from '$src/storage/models/RequestObjects/DeputyDeleteRequest';
import { DeputyInsertRequest } from '$src/storage/models/RequestObjects/DeputyInsertRequest';
import { DeputyUpdateRequest } from '$src/storage/models/RequestObjects/DeputyUpdateRequest';
import { AssignLessonToUserResponseContainer } from '$storage/models/Assignments/AssignLessonToUserResponse';
import { AssignUserSkillResponse } from '$storage/models/AssignUserSkillResponse';
import { RegisterUserToItemResponse } from '$storage/models/RegisterUserToItemResponse';
import { AssignLessonToUserRequest } from '$storage/models/RequestObjects/AssignLessonToUserRequest';
import { AssignSkillProfileRequest } from '$storage/models/RequestObjects/AssignSkillProfileRequest';
import { AssignUserSkillRequest } from '$storage/models/RequestObjects/AssignUserSkillRequest';
import { RegisterUserToItemRequest } from '$storage/models/RequestObjects/RegisterUserToItemRequest';
import { AssignSkillProfileResponseContainer } from '$storage/models/SkillProfile/AssignSkillProfileResponse';
import GtError from '$util/GtError';
import { EmployeePasswordResetRequest } from '$src/storage/models/RequestObjects/EmployeePasswordResetRequest';
import { RemoveSkillProfileResponseContainer } from '$src/storage/models/SkillProfile/RemoveSkillProfileResponse';
import { RemoveSkillProfileRequest } from '$src/storage/models/RequestObjects/RemoveSkillProfileRequest';
import { RemoveSkillsRequest } from '$src/storage/models/RequestObjects/RemoveSkillsRequest';
import { RemoveSkillResponseContainer } from '$src/storage/models/Skill/RemoveSkillResponse';
import { SkillStatusUpdateRequest } from '$src/storage/models/RequestObjects/SkillStatusUpdateRequest';

export default class TeamService extends ServiceClient {
    protected static _instance: TeamService | null = null;

    protected className = 'TeamService';
    protected loggerLocality = 'TeamService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): TeamService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Assing Skill Profile to Users
     */
    public async assignSkillProfile(assignSkillProfile: AssignSkillProfileRequest): Promise<AssignSkillProfileResponseContainer | GtError> {
        return await this.post<AssignSkillProfileResponseContainer>('team/assignskillprofile', assignSkillProfile, AssignSkillProfileResponseContainer, undefined);
    }

    public async RemoveSkillProfilesFromEmployees(removeSkillProfilesRequest: RemoveSkillProfileRequest): Promise<RemoveSkillProfileResponseContainer | GtError> {
        return await this.post<RemoveSkillProfileResponseContainer>('team/removeSkillProfilesFromEmployees', removeSkillProfilesRequest, RemoveSkillProfileResponseContainer, undefined);
    }

    public async assignLessonsToEmployees(request: AssignLessonToUserRequest): Promise<AssignLessonToUserResponseContainer | GtError> {
        return await this.post<AssignLessonToUserResponseContainer>('team/assignLessonsToEmployees', request, AssignLessonToUserResponseContainer, undefined);
    }

    public async registerUserToItem(request: RegisterUserToItemRequest): Promise<RegisterUserToItemResponse | GtError> {
        return await this.post<RegisterUserToItemResponse>('team/registerUserToItem', request, RegisterUserToItemResponse, undefined);
    }

    public async assingSkillToEmployees(request: AssignUserSkillRequest): Promise<AssignUserSkillResponse | GtError> {
        const formData = new FormData();
        let key: keyof AssignUserSkillRequest;
        for (key in request) {
            if (key == "fileCollection") {
                const aF = request[key];
                aF.forEach(f => {
                    formData.append("[fileName]:" + f.fileName, f.file as File);
                    formData.append("[fileType]:" + f.fileName, f.fileType.toString());
                })
            }
            if(key == "employeeIds"){
                formData.append(key, request.employeeIds.join(","));
            }
            else {
                formData.append(key, request[key] === undefined ? "" : request[key]!.toString());
            }
        }
        return await this.postFormData<AssignUserSkillResponse>('team/assingSkillToEmployees', formData, AssignUserSkillResponse);
    }

    public async RemoveSkillsFromEmployees(request: RemoveSkillsRequest): Promise<RemoveSkillResponseContainer | GtError> {
        return await this.post<RemoveSkillResponseContainer>('team/removeSkillsFromEmployees', request, RemoveSkillResponseContainer, undefined);
    }

    public async employeePasswordReset(request: EmployeePasswordResetRequest): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('team/employeePasswordReset', request, BooleanResponse, undefined);
    }

    // Deputy

    public async getDeputiesByBossRelation(bossRelationCode: string): Promise<Deputy[] | GtError> {
        return await this.get<Deputy[]>('team/deputy/getDeputiesByBossRelation', Deputy, undefined, undefined, bossRelationCode);
    }

    public async insertDeputy(request: DeputyInsertRequest): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('team/deputy/insert', request, BooleanResponse, undefined);
    }

    public async deleteDeputy(request: DeputyDeleteRequest): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('team/deputy/delete', request, BooleanResponse, undefined);
    }

    public async updateDeputy(request: DeputyUpdateRequest): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('team/deputy/update', request, BooleanResponse, undefined);
    }

    public async isDeputyOfRelation(bossRelationCode: string): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('team/deputy/isDeputyOfRelation', BooleanResponse, undefined, undefined, bossRelationCode);
    }
    // End Deputy

    public async updateSkillStatus(request: SkillStatusUpdateRequest): Promise<BooleanResponse | GtError> {
        const formData = new FormData();
        let key: keyof SkillStatusUpdateRequest;
        for (key in request) {
            if (key == "fileCollection") {
                const aF = request[key];
                aF.forEach(f => {
                    formData.append("[fileName]:" + f.fileName, f.file as File);
                    formData.append("[fileType]:" + f.fileName, f.fileType.toString());
                })
            }
            else {
                formData.append(key, request[key].toString());
            }
        }
        return await this.postFormData<BooleanResponse>('team/updateSkillStatus', formData, BooleanResponse);
    }

} 