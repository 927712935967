import React from "react";

export default function Snowfall() {

    return (
        <div className="snowfall">
            <div className="snow layer1 a"></div>
            <div className="snow layer1"></div>
            <div className="snow layer2 a"></div>
            <div className="snow layer2"></div>
            <div className="snow layer3 a"></div>
            <div className="snow layer3"></div>
        </div>
    )
}