import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ItemSkillDisplay {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('sid', String, false)
    public sId: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('skillLevelSetId', Number, false)
    public slsId: number = 0;

    @JsonProperty('skillTypeId', Number, false)
    public skillTypeId: number = 0;

    @JsonProperty('showInPerformanceReview', Boolean, false)
    public showInPerformanceReview: boolean = false;
    
    public get displayName(): string {
        return `${this.title}` + (this.sId.length > 0 ? ` [${this.sId}]` : '');
    }
}