import React from "react";

import { EColor } from "$src/storage/models/enums";
import { Badge } from "@progress/kendo-react-indicators";


/**
 * The GT Item Box Tile Props
 */
export interface IItemBoxTileProps {
    /**
     * The content of the box
     */
    children: JSX.Element | string;

    /**
     * Component unique id for the key of the dom element
     */
    id: number;

    /**
     * **Optional** Color of the border. If the color is not set, the border is hidden
     */
    borderColor?: EColor;

    /**
     * **Optional** To postion elements absolute in reference to the box, the position of the box has to be relative. 
     */
    isPositionRelative?: boolean;

    /**
     * **Optional** Flag if the item should be displayed as an <li>. Requirement: if this flag is set to true, then the calling component must provide the list <ol> or <ul>
     */
    isListItem?: boolean;

    /**
     * **Optional** Indicates if the Item should be highligted
     */
    highlight?: boolean;
}



/**
 * Global Teach item box tile component
 * Box for detailed design of the item summary with many information, used for the catalog, course requests and recommendations
 * Design: Rectangular box, higher than wide
 * List: Three items will be displayed side by side, more items use new rows
 * **Hint** Directions for parent component: <ul className="item-box-tile__parent"> {renderItems} </ul>
 */
export default function ItemBoxTile(props: IItemBoxTileProps) {

    let cssClass = 'item-box-tile';

    if (props.isPositionRelative) {
        cssClass += ' position--relative';
    }

    switch (props.borderColor) {
        case EColor.BaseColor:
            cssClass += ' border'
            break;
        case EColor.Highlight:
            cssClass += ' border border--highlight'
            break;
        case EColor.HighlightDark:
            cssClass += ' border border--highlight-dark'
            break;
        case EColor.Error:
            cssClass += ' border border--error'
            break;
    }

    if(props.highlight === true) {
        cssClass += ' item-box-tile--highlight'
    }

    if (props.isListItem) {
        cssClass += ' no-bullets';
        return (
            <li
                key={'ItemBoxTile_' + props.id}
                className={cssClass}
            >
                {props.highlight && <Badge align={{ vertical: 'top', horizontal: 'end' }} themeColor="secondary"></Badge> }
                {props.children}
            </li>
        );
    }

    return (
        <div
            key={'ItemBoxTile_' + props.id}
            className={cssClass}
        >
            {props.children}
        </div>
    );

}

