import React, { useState } from "react";
import GTButton, { IGTButtonProps } from "./GTButton";
import GTConfirm from "./GTConfirm";
/**
 * The GT Confirm Props
 */

/**
 * Global Teach Confirm Component
 * @param props 
 */
export default function GtConfirmButton(props: IGTButtonProps) {
    const [currentState, setCurrentState] = useState<'ACTION' | 'CONFIRMATION'>('ACTION');

    return <>
        {currentState === 'ACTION' && 
        <GTButton {...props} onClick={() => setCurrentState('CONFIRMATION')} />}

        {currentState === 'CONFIRMATION' && 
        <GTConfirm
            onConfirm={(event) => { props.onClick(event); setCurrentState('ACTION') }}
            onCancel={() => setCurrentState('ACTION')} />}
    </>
}