import { JsonObject, JsonProperty } from 'json2typescript';
import { EvaluationSkillDataUser } from '$src/storage/models/EvaluationSkillDataUser';

/**
 *This Model is holding the data for the skill report
 */
@JsonObject
export class EvaluationSkillData {

    /** skillId  */
    @JsonProperty('skillId', Number, false)
    public skillId: number = 0;

    /** skillName*/
    @JsonProperty('skillName', String, false)
    public skillName: string = "";

    /** Data for the report with userskilll status and user data*/
    @JsonProperty('usersWithAchievedSkill', [EvaluationSkillDataUser], false)
    public usersWithAchievedSkill: EvaluationSkillDataUser[] = [];
}
