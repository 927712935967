import React, { useState } from 'react';

import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { InputTextArea } from '$components/shared/InputTextArea';
import { InputAttributes } from '$components/shared/InputAttributes';

interface ICommentsProps {
    onTextEntered?: (comment: string) => void;
}

/**
 * Component for payment admins to enter comments for the booking
 * @param props ICommentsProps
 * @returns Hook - complete component
 */
export default function Comments(props: ICommentsProps) {
    const [text, setText] = useState('');

    function onTextEntered(value: string) {
        setText(value);
        if (props.onTextEntered) {
            props.onTextEntered(value);
        }
    }

    const textareaAttributes: InputAttributes =
    {
        id: 'paymentCommentsInput',
        attributeValueType: undefined,
        class: '',
        editMode: true,
        label: '',
        rows: 6,
        value: text        
    };

    return (
        <>
            <div className="">
                <Heading headingLevel={2} cssClass="heading__Title">
                    <Translate>PaymentBooking:CommentsTitle</Translate>
                </Heading>

                <div>
                    <Translate>PaymentBooking:CommentsSubTitle</Translate>
                </div>

                <div role="table" className="div-table__vertical-table margin-top">
                    <div role="rowgroup">
                        <div role="row" className="div-table__vertical-table-row">
                            <div role="columnheader" className='payment-comments__comment_header'>
                                <Translate>PaymentBooking:Comments</Translate>
                            </div>
                            <div role="cell" className='hide-input-label'>
                                <InputTextArea onChange={(id, value) => onTextEntered(value)} attributes={textareaAttributes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}