import React, { useEffect, useState } from 'react';

import { ProgressSpinner } from '$components/shared/ProgressSpinner';

import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { isSuccess } from '$src/util/Result';
import { PaymentCheckoutItemHistoryResponse } from '$src/storage/models/Payment/PaymentCheckoutItemHistoryResponse';
import { EPaymentCheckoutItemHistoryType } from '$src/storage/models/enums';
import Session from '$src/core/Session';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`PaymentBookingJournal:${str}`);
}

interface IPaymentCheckoutHistoryProps {
	checkoutId: number;
	/** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
	bossRelationCode?: string;
}

/**
 * Component to view change history of payment checkout item
 * @param props 
 * @returns 
 */
export default function PaymentCheckoutHistory(props: IPaymentCheckoutHistoryProps) {
	const [history, setHistory] = useState<PaymentCheckoutItemHistoryResponse[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		PaymentAdminService.instance.getCheckoutItemHistory(props.checkoutId, props.bossRelationCode).then((res) => {
			if (isSuccess<PaymentCheckoutItemHistoryResponse[]>(res)) {
				setHistory(res);
				setIsLoading(false);
			}
		});
	}, [props.checkoutId]);

	return (
		<div>
			{isLoading && <ProgressSpinner size={20} />}
			{
				history.map((entry) => {
					return (
						<div key={entry.id}>
							{ entry.replacePlaceholders(getTranslation(`History${EPaymentCheckoutItemHistoryType[entry.historyType]}Text`)) }
						</div>
					)
				})
			}
		</div>
	);
}