import React from 'react';

import { ItemSummary } from '$components/item/itemSummaries/ItemSummary';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { AssignedItem } from '$storage/models/AssignedItem';
import { EItemDetailCallerContextType, ESortDirection } from '$storage/models/enums';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import IconSort from '$resources/svgs/misc/sort.svg';
import InlineSVG from 'react-inlinesvg';
import GTButton from '$components/shared/Atoms/GTButton';
import GTInfoMessage from '$components/shared/Atoms/GTInfoMessage';

interface IProps {
    maxItemsToDisplay: number;
    allItemsLink?: string;
    allItemsLinkText?: string;
    expandItemsText?: string;
    expandItemsAriaText?: string;
    parentHeadingLevel: number;
    expandInline: boolean;
    filterExpression: (a: AssignedItem) => boolean;
    AssignmentItems?: AssignedItem[] | null;
    showSortOrderButton?: boolean;
}

interface IState {
    maxItemsToDisplay: number;
    sortOrder: ESortDirection;
}

@observer // observing Session.instance.storage.registeredItem.isInitialized
export class Assignments extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            maxItemsToDisplay: this.props.maxItemsToDisplay,
            sortOrder: globalConfig.assignmentProperties.orderByDateASC ? ESortDirection.Up : ESortDirection.Down,
        }
    }

    public async componentDidMount() {
        const assignmentStorage = Session.instance.storage.assignment;
        const assignmentsLoading = assignmentStorage.isLoading;
        const assignmentsInitialized = assignmentStorage.isInitialized;

        let assignments: AssignedItem[] | null = [];
        if (!assignmentsLoading && !assignmentsInitialized) {
            assignments = await assignmentStorage.getObjects();
            if (assignments != null) {
                assignments = assignments.filter(this.props.filterExpression)
                assignmentStorage.sort(assignments);
            }
        }
    }

    public render() {
        const assignmentStorage = Session.instance.storage.assignment;
        if (!assignmentStorage.isInitialized) {
            return <ProgressSpinner />;
        }
        const assignedItems = assignmentStorage.getObjectsFromCache().filter(this.props.filterExpression);
        if (assignedItems != null && assignedItems.length > 0) {
            assignmentStorage.sortByRemainingHours(assignedItems, this.state.sortOrder);
        }
        else {
            return '';
        }
        const itemCountExpiredInTheNextFewDays = assignedItems.filter(ai => ai.expiresInTheNextFewDays).length;
        const numberOfDays = globalConfig.assignmentProperties.daysToLookAheadForExpirations;
        let infoMessage = '';
        if (numberOfDays > 1) {
            infoMessage = itemCountExpiredInTheNextFewDays === 1 ?
                Session.instance.storage.translation.GetString('Assignments:ItemExpiresInTheNextFewDays').Format(numberOfDays.toLocaleString(), itemCountExpiredInTheNextFewDays.toLocaleString()) :
                Session.instance.storage.translation.GetString('Assignments:ItemsExpiresInTheNextFewDays').Format(numberOfDays.toLocaleString(), itemCountExpiredInTheNextFewDays.toLocaleString());
        } else {
            infoMessage = itemCountExpiredInTheNextFewDays === 1 ?
                Session.instance.storage.translation.GetString('Assignments:ItemExpiresTomorrow').Format(itemCountExpiredInTheNextFewDays.toLocaleString()) :
                Session.instance.storage.translation.GetString('Assignments:ItemsExpiresTomorrow').Format(itemCountExpiredInTheNextFewDays.toLocaleString());
        }
        return (
            <>
                {itemCountExpiredInTheNextFewDays > 0 || this.props.showSortOrderButton &&
                    <div className="assignments__header">
                        {itemCountExpiredInTheNextFewDays > 0 &&
                            <GTInfoMessage
                                message={infoMessage}
                            />
                        }
                        {this.props.showSortOrderButton &&
                            <GTButton onClick={() => this.sortOrderChange()} additionalClassNames="assignments__changeOrder--button">
                                <>
                                    {this.state.sortOrder === ESortDirection.Up &&
                                        <Translate>Assignments:SortDown</Translate>
                                    }
                                    {this.state.sortOrder === ESortDirection.Down &&
                                        <Translate>Assignments:SortUp</Translate>
                                    }
                                    <InlineSVG src={IconSort} />
                                </>
                            </GTButton>
                        }
                    </div>
                }
                <div className="l-box-container l-box-container--column-flex">
                    {this.renderAssingments(assignedItems)}
                    {!this.props.expandInline ?
                        this.renderAllItemsLink(assignedItems != null &&
                            assignedItems.length > 0 &&
                            this.state.maxItemsToDisplay > 0) :
                        this.renderShowAllItems(assignedItems != null &&
                            assignedItems.length > this.state.maxItemsToDisplay &&
                            this.state.maxItemsToDisplay > 0)}
                </div>
            </>
        );
    }

    protected renderAssingments(elements: AssignedItem[] | null): JSX.Element | JSX.Element[] {
        if (elements == null || elements.length === 0) {
            return <NoDataFound message={Session.instance.storage.translation.GetString('Assignments:NoData')} />;
        }
        else {
            return (
                <ul className="item-box-tile__parent">
                    {this.renderAssingments2(elements)}
                </ul>
            );
        }
    }
    protected renderAssingments2(elements: AssignedItem[] | null): JSX.Element[] | null {
        if (elements != null) {
            const itemsToDisplay = this.state.maxItemsToDisplay > 0 &&
                elements.length > this.state.maxItemsToDisplay ? this.state.maxItemsToDisplay : elements.length;
            return elements.slice(0, itemsToDisplay).map(assignment =>
                <ItemSummary
                    key={assignment.sId}
                    item={assignment}
                    itemCallerContext={EItemDetailCallerContextType.Assignment}
                    parentAssignmentId={assignment.assignmentId}
                    parentTrainingPlanId={0}
                    parentCatalogFolderId={0}
                    {...this.props} />
            );
        } else {
            return null;
        }
    }
    protected renderAllItemsLink(hasElements: boolean): JSX.Element | null {
        if (hasElements && this.props.allItemsLink != null && this.props.allItemsLinkText != null) {
            return <div className="assignments__all-items-link">
                <NavLink to={this.props.allItemsLink} className="btn btn--md btn--primary">
                    <Translate>{this.props.allItemsLinkText}</Translate>
                </NavLink>
            </div>
        }
        else { return null }
    }

    protected renderShowAllItems(hasElements: boolean): JSX.Element | null {
        if (hasElements && this.props.expandItemsText != null) {
            return <div className="assignments__more">
                <button
                    aria-label={Session.instance.storage.translation.GetString(this.props.expandItemsAriaText != null ? this.props.expandItemsAriaText : '')}
                    className="btn btn--md btn--primary"
                    onClick={() => this.showAllItems()}>
                    <Translate>{this.props.expandItemsText}</Translate>
                </button>
            </div>
        }
        else { return null }
    }

    protected showAllItems() {
        this.setState({ maxItemsToDisplay: 0 })
    }

    private sortOrderChange() {
        switch (this.state.sortOrder) {
            case ESortDirection.Down:
                this.setState({ sortOrder: ESortDirection.Up })
                break;
            case ESortDirection.Up:
                this.setState({ sortOrder: ESortDirection.Down })
                break;
        }
    }
}