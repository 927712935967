import Logger from '$core/Logger';
import Session from '$core/Session';
import FavoriteService from '$src/core/Services/FavoriteService';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import SessionCache from '$storage/cache/SessionCache';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { EFavoriteType } from '$storage/models/enums';
import { Favorite } from '$storage/models/Favorite';
import GtError from '$util/GtError';

const CACHING_DURATION = 3600;

export default class FavoriteStorage extends SessionCache<number, Favorite> {
    protected className = 'FavoriteStorage';
    protected loggerLocality = 'Storage.FavoriteStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, false, true);
    }

    public async loadFavoritesFromServer() {
        const methodName = `${this.className}:loadFavoritesFromServer()`;
        this.clear();
        Logger.log(this.loggerLocality, `${methodName} loading favorites into cache`);
        const response  = await FavoriteService.instance.getFavorites(Session.instance.getUserLanguageCodeOrFallBack)
        if(isSuccess<Favorite[]>(response)) {
            Session.instance.setHasFavorites(response.length > 0);
            response.map( x => this.saveObjectToCache(x.id, x))
            Logger.log(this.loggerLocality, `${methodName} succeeded.`);        
        }
        else {
            if (response.detailedObject !== undefined) {
                Logger.log(this.loggerLocality, CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) )
            } else {
                Logger.log(this.loggerLocality, 'ErrorMessage:GetFavoritesFailed' );
            }

        } 

    }

    public getFavoriteStatus(itemId: number, context: EFavoriteType): Favorite | null {
        const cachedFavorite = this.getObjectsFromCache().find(x => x.objectId === itemId && x.type === context  )
        if (cachedFavorite === undefined ) {
            return null;
        } else {
            return cachedFavorite;
        }
    }

    public async addFavorite(objectId: number,  context: EFavoriteType, language: string): Promise<Favorite | GtError> {
        const methodName = `${this.className}:addFavorite()`;
        const response = await FavoriteService.instance.addFavorite(objectId, context, language)
        if(isSuccess<Favorite>(response))
        {
            this.saveObjectToCache(response.id, response);
            Session.instance.setHasFavorites(this.getObjectsFromCache().length > 0);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} failed:  Id=${objectId}, context=${context}, language=${language}`);

        }
        return response;
    }

    public async removeFavorite(id: number): Promise<BooleanResponse | GtError> {
        const methodName = `${this.className}:removeFavorite()`;
        const response = await FavoriteService.instance.removeFavorite(id)
        if(isSuccess<BooleanResponse>(response))
        {   
            if(response.status === false ) {
                Logger.log(this.loggerLocality, `${methodName} removeFavorite item already deleted:  Id=${id}`);
            }
            this.removeObjectFromCache(id)
            Session.instance.setHasFavorites(this.getObjectsFromCache().length > 0);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} failed:  Id=${id}`);

        }
        return response;
    }

    public clear(): void {
        Session.instance.setHasFavorites(false);
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}
