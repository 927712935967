import { UserCertificate } from '$src/storage/models/Certificates/UserCertificate';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserCertificateViewModel {
    // [key: string]: string | number | Date | DateTimeConverter | boolean;

    @JsonProperty('certificate', UserCertificate, false)
    public certificate: UserCertificate | undefined = undefined;

    @JsonProperty('url', String, false)
    public url: string = '';

    @JsonProperty('fileSizeInBytes', Number, false)
    public fileSizeInBytes: number = 0;

    @JsonProperty('fileExtension', String, false)
    public fileExtension: string = '';



}



