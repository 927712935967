import Logger from '$src/core/Logger';
import { computed, observable } from 'mobx';

import AttributeValueStorage from '$src/storage/AttributeValueStorage';
import F2FClassDetailStorage from '$src/storage/F2FClassDetailStorage';
import F2FClassListStorage from '$src/storage/F2FClassListStorage';
import F2FDocumentListStorage from '$src/storage/F2FDocumentListStorage';
import ItemStorage from '$src/storage/ItemStorage';
import AssignmentStorage from '$storage//AssignmentStorage';
import ExternalCourseProviderStorage from '$storage//ExternalCourseProviderStorage';
import RegisteredItemStorage from '$storage//RegisteredItemStorage';
import TrainingPlanElementStorage from '$storage//TrainingPlanElementStorage';
import TrainingPlanScheduleStorage from '$storage//TrainingPlanScheduleStorage';
import TrainingPlanStorage from '$storage//TrainingPlanStorage';
import CatalogStorage from '$storage/CatalogStorage';
import FavoriteStorage from '$storage/FavoriteStorage';
import SearchStorage from '$storage/SearchStorage';
import TranslationStorage from '$storage/TranslationStorage';
import UserSkillProfileStorage from '$storage/UserSkillProfileStorage';
import UserSkillStorage from '$storage/UserSkillStorage';
import UsersStorage from '$storage/UsersStorage';
import UserTargetSkillStorage from '$storage/UserTargetSkillStorage';
import WatchlistStorage from '$storage/WatchlistStorage';
import LocationDocumentListStorage from '$storage/LocationDocumentListStorage';
import ValidBossesStorage from '$storage/ValidBossesStorage';
import SkillStorage from '$storage/SkillStorage';
import IPanelStateStorage from '$storage/IPanelStateStorage';
import AssessmentStorage from '$storage/AssessmentStorage'
import PerformanceSupportStorage from '$storage/PerformanceSupportStorage';

/**
 * This class implements a container for all storage classes. The storage classes are used to
 * get and save objects (e.g. users, lessons, etc.) from/to the server. To improve performance 
 * the storage classes may use local caching.
 */
export default class Storage {
    protected static _instance: Storage | null = null;

    protected className = 'Storage';
    protected loggerLocality = 'Storage.Storage';

    @observable private _users: UsersStorage;
    @observable private _catalog: CatalogStorage;
    @observable private _translation: TranslationStorage;
    @observable private _attributeValue: AttributeValueStorage;
    @observable private _assignment: AssignmentStorage;
    @observable private _item: ItemStorage;
    @observable private _classList: F2FClassListStorage;
    @observable private _classDetails: F2FClassDetailStorage;
    @observable private _f2fDocuments: F2FDocumentListStorage;
    @observable private _locationDocuments: LocationDocumentListStorage;
    @observable private _registeredItems: RegisteredItemStorage;
    @observable private _searchStorage: SearchStorage;
    @observable private _trainingPlans: TrainingPlanStorage;
    @observable private _trainingPlanElements: TrainingPlanElementStorage;
    @observable private _trainingPlanSchedules: TrainingPlanScheduleStorage;
    @observable private _externalCourseProviders: ExternalCourseProviderStorage;
    @observable private _favoriteStorage: FavoriteStorage;
    @observable private _watchlistStorage: WatchlistStorage;
    @observable private _userSkillProfileStorage: UserSkillProfileStorage;
    @observable private _userTargetSkillStorage: UserTargetSkillStorage;
    @observable private _userSkillStorage: UserSkillStorage;
    @observable private _validBossesStorage: ValidBossesStorage;
    @observable private _skillStorage: SkillStorage;
    @observable private _ipanelStateStorage: IPanelStateStorage;
    @observable private _assessmentStorage: AssessmentStorage;
    @observable private _performanceSupportStorage: PerformanceSupportStorage;

    constructor() {
        this._users = new UsersStorage(3600);
        this._catalog = new CatalogStorage(3600);
        this._translation = new TranslationStorage(30); // Time to Log in
        this._attributeValue = new AttributeValueStorage(3600);
        this._assignment = new AssignmentStorage(3600);
        this._item = new ItemStorage(3600);
        this._classList = new F2FClassListStorage(3600);
        this._classDetails = new F2FClassDetailStorage(3600);
        this._f2fDocuments = new F2FDocumentListStorage(3600);
        this._locationDocuments = new LocationDocumentListStorage(3600);
        this._registeredItems = new RegisteredItemStorage(3600);
        this._searchStorage = new SearchStorage(3600);
        this._trainingPlans = new TrainingPlanStorage(3600);
        this._trainingPlanElements = new TrainingPlanElementStorage(3600);
        this._trainingPlanSchedules = new TrainingPlanScheduleStorage(3600);
        this._externalCourseProviders = new ExternalCourseProviderStorage(3600);
        this._favoriteStorage = new FavoriteStorage(3600);
        this._watchlistStorage = new WatchlistStorage(3600);
        this._userSkillProfileStorage = new UserSkillProfileStorage(3600);
        this._userTargetSkillStorage = new UserTargetSkillStorage(3600);
        this._userSkillStorage = new UserSkillStorage(3600);
        this._validBossesStorage = new ValidBossesStorage(3600);
        this._skillStorage = new SkillStorage(3600);
        this._ipanelStateStorage = new IPanelStateStorage(3600);
        this._assessmentStorage = new AssessmentStorage(3600);
        this._performanceSupportStorage = new PerformanceSupportStorage(3600);
        Logger.log(this.loggerLocality, 'creating new Storage');
    }
    
    /**
     * get user storage (used to get and save users from/to server).
     */
    @computed
    public get user(): UsersStorage { return this._users; }

    /**
     * Get catalog storage (used to get catalog elements from server).
     */
    @computed
    public get catalog(): CatalogStorage { return this._catalog; }

    @computed
    public get translation(): TranslationStorage{
        return this._translation;
    }

    @computed
    public get attributeValue(): AttributeValueStorage{return this._attributeValue; }    

    @computed.struct
    public get assignment(): AssignmentStorage { return this._assignment; }

    @computed
    public get item(): ItemStorage { return this._item; }

    @computed
    public get classList(): F2FClassListStorage { return this._classList; }

    @computed
    public get classDetails(): F2FClassDetailStorage { return this._classDetails; }

    @computed
    public get f2fDocuments(): F2FDocumentListStorage { return this._f2fDocuments; }

    @computed
    public get locationDocuments(): LocationDocumentListStorage { return this._locationDocuments; }

    @computed.struct
    public get registeredItem(): RegisteredItemStorage { return this._registeredItems; }

    @computed.struct
    public get searchReponses(): SearchStorage { return this._searchStorage }

    @computed.struct
    public get trainingPlans(): TrainingPlanStorage { return this._trainingPlans; }

    @computed.struct
    public get trainingPlanElements(): TrainingPlanElementStorage { return this._trainingPlanElements; }

    @computed.struct
    public get trainingPlanSchedules(): TrainingPlanScheduleStorage { return this._trainingPlanSchedules; }

    @computed.struct
    public get externalCourseProviders(): ExternalCourseProviderStorage { return this._externalCourseProviders; }

    @computed.struct
    public get favorites(): FavoriteStorage { return this._favoriteStorage; }

    @computed.struct
    public get watchList(): WatchlistStorage { return this._watchlistStorage; }

    @computed.struct
    public get userSkillProfile(): UserSkillProfileStorage { return this._userSkillProfileStorage; }

    @computed.struct
    public get userTargetSkill(): UserTargetSkillStorage { return this._userTargetSkillStorage; }

    @computed.struct
    public get userSkill(): UserSkillStorage { return this._userSkillStorage; }

    @computed
    public get performanceSupport(): PerformanceSupportStorage { return this._performanceSupportStorage; }

    @computed
    public get validBosses(): ValidBossesStorage { return this._validBossesStorage; }

    @computed
    public get skill(): SkillStorage { return this._skillStorage; }

    @computed
    public get ipanelState(): IPanelStateStorage { return this._ipanelStateStorage; }
    
    @computed
    public get assessment(): AssessmentStorage { return this._assessmentStorage; }

    /**
     * Clear all caches.
     */
    public clearAll(): void {
        const methodName = `${this.className}:clearAll()`;
        this._users.clear();
        this._catalog.clear();
        this._attributeValue.clear();
        this._assignment.clear();
        this._item.clear();
        this._classList.clear();
        this._classDetails.clear();
        this._f2fDocuments.clear();
        this._locationDocuments.clear();
        this._registeredItems.clear();
        this._searchStorage.clear();
        this._trainingPlans.clear();
        this._trainingPlanElements.clear();
        this._trainingPlanSchedules.clear();
        this._externalCourseProviders.clear();
        this._favoriteStorage.clear();
        this._watchlistStorage.clear();
        this._userSkillProfileStorage.clear();
        this._userTargetSkillStorage.clear();
        this._userSkillStorage.clear();
        this._validBossesStorage.clear();
        this._skillStorage.clear();
        this._ipanelStateStorage.clear();
        this._assessmentStorage.clear();
        this._performanceSupportStorage.clear();
        Logger.log(this.loggerLocality, `${methodName}`);
    }
    /**
     * Clear all caches.
     */
    public clearLanguageDependantCache(): void {
        const methodName = `${this.className}:clearLanguageDependantCache()`;
        if (this._users.isLanguageDependant) { this._users.clear();}
        if (this._catalog.isLanguageDependant) { this._catalog.clear(); }
        if (this._attributeValue.isLanguageDependant) { this._attributeValue.clear(); }
        if (this._assignment.isLanguageDependant) { this._assignment.clear(); }
        if (this._item.isLanguageDependant) { this._item.clear(); }
        if (this._classList.isLanguageDependant) { this._classList.clear(); }
        if (this._classDetails.isLanguageDependant) { this._classDetails.clear(); }
        if (this._registeredItems.isLanguageDependant) { this._registeredItems.clear(); }
        if (this._searchStorage.isLanguageDependant) { this._searchStorage.clear() }
        if (this._trainingPlans.isLanguageDependant) { this._trainingPlans.clear(); }
        if (this._trainingPlanElements.isLanguageDependant) { this._trainingPlanElements.clear(); }
        if (this._trainingPlanSchedules.isLanguageDependant) { this._trainingPlanSchedules.clear(); }
        if (this._externalCourseProviders.isLanguageDependant) { this._externalCourseProviders.clear(); }
        if (this._favoriteStorage.isLanguageDependant) { this._favoriteStorage.clear(); }
        if (this._watchlistStorage.isLanguageDependant) { this._watchlistStorage.clear(); }
        if (this._userSkillProfileStorage.isLanguageDependant) { this._userSkillProfileStorage.clear(); }
        if (this._userTargetSkillStorage.isLanguageDependant) { this._userTargetSkillStorage.clear(); }
        if (this._userSkillStorage.isLanguageDependant) { this._userSkillStorage.clear(); }
        if (this._validBossesStorage.isLanguageDependant) { this._validBossesStorage.clear(); }
        if (this._skillStorage.isLanguageDependant) { this._skillStorage.clear(); }
        if (this._ipanelStateStorage.isLanguageDependant) { this._ipanelStateStorage.clear(); }
        if (this._assessmentStorage.isLanguageDependant) { this._assessmentStorage.clear(); }
        Logger.log(this.loggerLocality, `${methodName}`);
    }

    /**
     * Clear all caches containing rating information.
     */
    public clearCachesWithRating(): void {
        const methodName = `${this.className}:clearCachesWithRating()`;
        this._catalog.clear();
        this._assignment.clear();
        this._item.clear();
        this._registeredItems.clear();
        this._trainingPlans.clear();
        this._trainingPlanElements.clear();
        Logger.log(this.loggerLocality, `${methodName}`);
    }
}