import React from "react";
import { Heading } from "$components/shared/Heading";
import { FavoriteRibbon } from "$src/components/favorites/FavoriteRibbon";
import { EFavoriteType, EItemDetailCallerContextType, EItemSubType, EItemType, ELessonStatus, ERegistrationStatus } from "$src/storage/models/enums";
import StarRating, { getRatingToolTipText } from "$components/shared/Atoms/StarRating";
import Session from "$src/core/Session";
import { SummaryAttribute } from "$components/shared/Attributes/SummaryAttribute";
import ItemFooter from "$src/components/shared/itemBox/ItemFooter";
import { GlossaryRibbon } from "$src/components/glossary/GlossaryRibbon";
import ItemIconAndStatus from "$src/components/shared/itemBox/ItemIconAndStatus";
import ItemSummaryHelper from "$src/util/ItemSummaryHelper";
import { CourseCosts } from "$src/components/item/Toolbar/CourseCosts";
import { Attribute } from "$src/storage/models/Attribute";
import { NavLink } from "react-router-dom";

/**
 * The GT Item Summary for Detail Props
 */
export interface IItemSummaryDetailProps {

    /**
     * Component unique id for the key of the dom element
     */
    id: string;

    /**
     * Heading Level of parent component
     */
    parentHeadingLevel: number;

    /**
     *  Details of the item like title, description
     */
    itemDetails: ItemDetails;

    /**
     *  Context of which the item summary was called
     */
    itemCallerContext: EItemDetailCallerContextType;

    /** 
     * Set true if the user is looking at the items of his employees
    */
    isBoss?: boolean;

    /**
     *  Function for button of item summary detail footer
     */
    onButtonClicked?: () => void;
}

/**
 * Global Teach Item Summary Detail Component
 */
export default function ItemSummaryDetail(props: IItemSummaryDetailProps) {

    // Rendering for the detailed item summary display
    const item = props.itemDetails;
    const itemFooterInformation = ItemSummaryHelper.calculateItemDetailFooterInformation(item, props.itemCallerContext);
    const footerButtonType = ItemSummaryHelper.calculateItemDetailFooterButtonType(item.isLocked, item.itemType, item.itemTitle, props.itemCallerContext,
        item.registrationRequiredEnabled, item.registrationStatus, item.itemStatus, item.assignmentEndDate, props.isBoss, item.hasPublishRight);
    const ratingToolTip = getRatingToolTipText(item.myRating, item.ratingCount, item.averageRating);

    return (
        <>
            <div className="item-summary__detail__header" />
            <div className="item-summary__detail__type-icon">
                <ItemIconAndStatus
                    id={props.id}
                    itemType={item.itemType}
                    itemSubType={item.itemSubType}
                    itemStatus={item.itemStatus}
                    itemTitle={item.itemTitle}
                    itemAttributes={item.itemAttributes}
                    itemIcon={item.itemIcon} />
            </div>

            <div className="item-summary__detail__ribbons">
                <GlossaryRibbon itemId={props.itemDetails.itemId} {...props} />
                {props.itemDetails.showFavoriteRibbon && <FavoriteRibbon
                    itemId={props.itemDetails.itemId}
                    itemTitle={props.itemDetails.itemTitle}
                    type={EFavoriteType.Item} />}
            </div>

            <div className="item-summary__detail__rating">
                {globalConfig.lessonToolbarProperties.displayRating && item.isRatingEnabled &&
                    <StarRating id={props.id} ratingCount={item.ratingCount} averageRating={item.averageRating} myRating={item.myRating}
                        tooltipText={ratingToolTip} ariaLabel={ratingToolTip} />}
            </div>
            <NavLink to={item.navUrl}
                aria-label={Session.instance.storage.translation.GetString("ItemSummary:LinkAriaLabel").replace("{title}", item.itemTitle)}>
                <div className="item-summary__detail__title item-summary__title--overflow">
                    <Heading cssClass="heading__Level4" headingLevel={props.parentHeadingLevel + 1} aria-label="">
                        <p className="summary__detail__clickable">{item.itemTitle}</p>
                    </Heading>
                </div>
                {props.itemCallerContext === EItemDetailCallerContextType.CourseRequests ?
                    <div className="item-summary__detail__description">
                        <div className="employee-name text-overflow--ellipsis">{props.itemDetails.userNameAndMail}</div>
                        <CourseCosts classCosts={props.itemDetails.classCosts} classCurrency={props.itemDetails.classCurrency} />
                    </div>
                    : <SummaryAttribute summary={item.itemDescription} className="item-summary__detail__description overflow" />
                }
            </NavLink>
            <div className="item-summary__detail__footer" >
                <ItemFooter
                    id={props.id}
                    itemFooterIconAndText={itemFooterInformation}
                    isItemLocked={props.itemDetails.isLocked}
                    itemId={props.itemDetails.itemId}
                    itemTitle={props.itemDetails.itemTitle}
                    itemContext={props.itemCallerContext}
                    parentAssignmentId={props.itemDetails.parentAssignmentId}
                    footerButtonType={footerButtonType}
                    onButtonClicked={props.onButtonClicked} />
            </div>
        </>
    );
}


/**
 * This class defines the data the item summary catalog component needs for dispayling a item.
 */
export class ItemDetails {
    public itemId: number = 0;
    public itemTitle: string = '';
    public itemDescription: string = '';
    public navUrl: string = '';
    public itemType: EItemType = EItemType.Undefined;
    public itemSubType: EItemSubType = EItemSubType.Undefined;
    public itemStatus: ELessonStatus = ELessonStatus.NotAttempted;
    public showFavoriteRibbon: boolean = false;
    public isRatingEnabled: boolean = false;
    public ratingCount: number = 0;
    public myRating: number = 0;
    public averageRating: number = 0;
    public registrationRequiredEnabled: boolean = false;
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;
    public isLocked: boolean = false;
    public isLockedDueToAssignments: boolean = false;
    public isLockedDueToRequiredSkills: boolean = false;
    public isIgnoreStartCondition: boolean = false;
    public isRegistered: boolean = false;
    public score: number = 0;
    public learningPeriodEnd?: Date = undefined;
    public learningPeriodStart?: Date = undefined;
    public duration?: string = undefined;
    public dueDate?: Date = undefined;
    public parentAssignmentId: number = 0;
    public remainingHoursToStart?: number = 0;
    public remainingHoursToEnd?: number = 0;
    public assignmentEndDate?: Date = undefined;
    public classCosts?: number = undefined;
    public classCurrency?: string = undefined;
    public userNameAndMail?: string = undefined;
    public itemAttributes: Attribute[] = [];
    public itemIcon: string;
    public lessonLockedByAttemptsUntil?: Date = undefined;
    public hasPublishRight: boolean = false;
}