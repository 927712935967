import { RecoverLoginModeConverter } from '$src/storage/models/converters/RecoverLoginModeConverter';

import { ERecoverLoginMode } from '$src/storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

/** Model to get the Response of the RecoverPassword request.
 * If the process was successfully started it returns true.
 */
@JsonObject
export class RecoverPasswordResponse {

    /** Was the Request a success or not */
    @JsonProperty('isSuccess', Boolean, false)
    public isSuccess: boolean = false;

    @JsonProperty('timestamp', String, false)
    public timestamp: string = '';

    /** This is the currently configured Password recovery mode */
    @JsonProperty('mode', RecoverLoginModeConverter, false)
    public mode: ERecoverLoginMode = ERecoverLoginMode.UNKNOWN;

}




