import Session from '$src/core/Session';
import { ECancellationPermission, EItemDetailCallerContextType, EItemSecurityContext, EItemType } from '$storage/models/enums';
import { StringHelper } from '$src/util/StringHelper';

export class ItemHelper {

    public static getItemDetailLink(itemId: number | null,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        parentCatalogFolderId: number,
        parentAssignmentId: number,
        parentTrainingPlanId: number | null,
        parentTrainingPlanElementId: number | null,
        voucherCode?: string | null | undefined
    ): string {


        let link = `/itemDetail/${itemId != null ? itemId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `catId=${parentCatalogFolderId}&` +
            `asId=${parentAssignmentId}&` +
            `tpId=${parentTrainingPlanId != null ? parentTrainingPlanId : 0}&` +
            `tpeId=${parentTrainingPlanElementId != null ? parentTrainingPlanElementId : 0}`
            ;

        if (voucherCode) {
            link = `${link}&vouchercode=${voucherCode}`
        }

        return link;
    }

    public static getPerformanceReviewItemDetailLink(itemId: number | undefined,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        itemType: EItemType,
        skillId?: number,
        userId?: number,
        profileId?: number,
        bossRelationCode?: string
    ): string {

        const routePath = itemType == EItemType.TrainingPlan ? 'trainingPlanDetail' : 'itemDetail';

        let link = `/${routePath}/${itemId ? itemId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `userId=${userId ? userId : 0}&` +
            `skillId=${skillId ? skillId : 0}&` +
            `profileId=${profileId ? profileId : 0}`
            ;
        if (bossRelationCode) {
            link += `&bossRelationCode=${bossRelationCode}`;
        }
        return link
    }

    public static getPerformanceSupportItemDetailLink(itemId: number | undefined,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        itemType: EItemType,
        skillId?: number,
        userId?: number,
        profileId?: number,
        bossRelationCode?: string,
    ): string {

        const routePath = itemType == EItemType.TrainingPlan ? 'trainingPlanDetail' : 'itemDetail';

        let link = `/${routePath}/${itemId ? itemId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `userId=${userId ? userId : 0}&` +
            `skillId=${skillId ? skillId : 0}&` +
            `profileId=${profileId ? profileId : 0}`
            ;
        if (bossRelationCode) {
            link += `&bossRelationCode=${bossRelationCode}`;
        }
        return link
    }


    public static getF2fRegistrationLink(
        itemId: number | null,
        classId: number | null,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        parentCatalogFolderId: number,
        parentAssignmentId: number,
        parentTrainingPlanId: number | null,
        voucherCode: string | null | undefined = undefined): string {


        let link = `/f2fregistration/${itemId != null ? itemId : 0}/` +
            `${classId != null ? classId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `catId=${parentCatalogFolderId}&` +
            `asId=${parentAssignmentId}&` +
            `tpId=${parentTrainingPlanId != null ? parentTrainingPlanId : 0}`;
        if (voucherCode) {
            link = `${link}&vouchercode=${voucherCode}`
        }

        return link;
    }

    public static getTrainingPlanDetailLink(trainingPlanId: number | null,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        parentCatalogFolderId: number,
        parentAssignmentId: number,
        itemId?: number,
        voucherCode?: string | null | undefined): string {

        return `/trainingPlanDetail/${trainingPlanId != null ? trainingPlanId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `catId=${parentCatalogFolderId}&` +
            `asId=${parentAssignmentId}` +
            `${itemId != null ? '&returnItemId=' + itemId : ''}` +
            `${voucherCode != null ? '&vouchercode=' + voucherCode : ''}`;
    }

    public static getTrainingPlanScheduleRegistrationLink(
        trainingPlanId: number | null,
        scheduleId: number | null,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        parentCatalogFolderId: number,
        parentAssignmentId: number,
        voucherCode: string | null = null): string {

        let link = `/trainingPlanRegistration/${trainingPlanId != null ? trainingPlanId : 0}/` +
            `${scheduleId != null ? scheduleId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `catId=${parentCatalogFolderId}&` +
            `asId=${parentAssignmentId}`;

        if (voucherCode !== null) {
            link = `${link}&vouchercode=${voucherCode}`
        }
        return link;
    }

    public static getRequestWizardDetailLink(registrationId: number,
        itemDetailCallerContextType: EItemDetailCallerContextType): string {
        return `/requestWizard/${registrationId}?` +
            `context=${itemDetailCallerContextType}`;
    }

    public static getMySkillsDetailLink(itemId: number | undefined,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        itemType: EItemType,
        skillId?: number,
        userId?: number,
    ): string {

        const routePath = itemType == EItemType.TrainingPlan ? 'trainingPlanDetail' : 'itemDetail';

        const link = `/${routePath}/${itemId ? itemId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `userId=${userId ? userId : 0}&` +
            `skillId=${skillId ? skillId : 0}`;
        return link
    }

    public static getMyPerformanceSupportLink(itemId: number | undefined,
        itemDetailCallerContextType: EItemDetailCallerContextType,
        itemType: EItemType,
        skillId?: number,
        userId?: number,
    ): string {
        const routePath = itemType == EItemType.TrainingPlan ? 'trainingPlanDetail' : 'itemDetail';

        const link = `/${routePath}/${itemId ? itemId : 0}?` +
            `context=${itemDetailCallerContextType}&` +
            `userId=${userId ? userId : 0}&` +
            `skillId=${skillId ? skillId : 0}`;
        return link
    }

    public static isDefaultIcon(itemIcon: string, itemType: EItemType) {
        switch (itemType) {
            case EItemType.Folder: return true;
            case EItemType.Agenda: return true;
            case EItemType.Block: return true;
            case EItemType.Assessment: return itemIcon == "" || itemIcon.toLowerCase() === "assessment.gif";
            case EItemType.CBT: return itemIcon == "" ||  itemIcon.toLowerCase() === "cbt.gif";
            case EItemType.Document: return itemIcon == "" ||  itemIcon.toLowerCase() === "document.gif";
            case EItemType.F2FCourse: return itemIcon == "" ||  itemIcon.toLowerCase() === "course.gif";
            case EItemType.FileSharing: return itemIcon == "" ||  itemIcon.toLowerCase() === "filesharing.gif";
            case EItemType.Questionnaire: return itemIcon == "" ||  itemIcon.toLowerCase() === "test.gif";
            case EItemType.Recording: return itemIcon == "" ||  itemIcon.toLowerCase() === "recording.gif";
            case EItemType.TrainingPlan:  return itemIcon == "" ||  itemIcon.toLowerCase() === "trainingplan.gif";
            case EItemType.WBT: return itemIcon == "" ||  itemIcon.toLowerCase() === "wbt.gif";
            default: return true;
        }
    }

    /**
     * Returns a translated text for the locked reason due to failed attempts (caused by AUI workflow)
     * @param lessonLockedByAttemptsUntil Date until the lesson is locked. If the date is greater or queal to 31.12.9999, it counts as permanently locked
     */
    public static isLockedDueToFailedAttemptsText(lessonLockedByAttemptsUntil: Date): string {
        let text: string = '';
        if (lessonLockedByAttemptsUntil >= new Date(9999, 11, 31)) { // Use 11 for month to get december, new Date(9999, 12, 31) will return 31.01.10000
            text = Session.instance.storage.translation.GetString('ItemDetail:LessonLockedFinally');
        } else {
            text = Session.instance.storage.translation.GetString('ItemDetail:LessonLockedUntil');
            text = text.Format(StringHelper.dateTimeString(lessonLockedByAttemptsUntil));
        }
        return text;
    }

    /**
     * Converts the item detail caller context to the context relevant for the item security check
     * @param callerContext 
     * @returns Context for item security
     */
    public static convertCallerContextToSecurityContext(callerContext: EItemDetailCallerContextType, isBoss?: boolean) {
        switch (callerContext) {
            case EItemDetailCallerContextType.Catalog:
            case EItemDetailCallerContextType.Recommendations:
            case EItemDetailCallerContextType.MyRecommendationsByInterests:
            case EItemDetailCallerContextType.MySubjectSubscriptionsEditView:
            case EItemDetailCallerContextType.VoucherValidation:
                return EItemSecurityContext.CatalogAccess;
            case EItemDetailCallerContextType.RecommendedItems:
            case EItemDetailCallerContextType.Assignment:
                return EItemSecurityContext.Assignment;
            case EItemDetailCallerContextType.RequirementsOverview:
            case EItemDetailCallerContextType.BookingOverview:
                return EItemSecurityContext.BossReporting;
            case EItemDetailCallerContextType.MyLesson:
                return EItemSecurityContext.MyLesson;
            case EItemDetailCallerContextType.MyFavorites:
                return EItemSecurityContext.Undefined;
            case EItemDetailCallerContextType.MyWatchlist:
                return EItemSecurityContext.Undefined;
            case EItemDetailCallerContextType.RegisteredItem:
            case EItemDetailCallerContextType.MyCourses:
                return EItemSecurityContext.RegisteredItem;
            case EItemDetailCallerContextType.Search:
                return EItemSecurityContext.CatalogAccess;
            case EItemDetailCallerContextType.MyFileSharing:
                return EItemSecurityContext.MyFileSharing;
            case EItemDetailCallerContextType.PerformanceReview:
            case EItemDetailCallerContextType.PerformanceCheck:
            case EItemDetailCallerContextType.MySkills:
                return isBoss ? EItemSecurityContext.PerformanceReportsBoss : EItemSecurityContext.PerformanceReports;
            case EItemDetailCallerContextType.TutoringReport:
                return EItemSecurityContext.TutoringReport;
            case EItemDetailCallerContextType.TrainerBookingView:
                return EItemSecurityContext.TrainerBookingView;
            case EItemDetailCallerContextType.MyPerformanceSupport:
                return EItemSecurityContext.PerformanceReports;
            case EItemDetailCallerContextType.PerformanceSupport:
                return EItemSecurityContext.PerformanceReportsBoss;
            case EItemDetailCallerContextType.PurchasedItemsList:
                return EItemSecurityContext.PurchasedItems;
            case EItemDetailCallerContextType.MyGlossary:
            case EItemDetailCallerContextType.PaymentCampaignOverview:
            case EItemDetailCallerContextType.Privacy:
            case EItemDetailCallerContextType.ExternalCourseCostReport:
            case EItemDetailCallerContextType.MyEmployees:
            case EItemDetailCallerContextType.PowerBiReports:
            default:
                return EItemSecurityContext.Undefined;
        }
    }

    
    /**
     * Returns true if today is before cancellationPeriod1 Date
     */
     public static isInCancellationPeriod1(cancellationDate1?: Date): boolean {
        const today = new Date()
        return cancellationDate1 !== undefined && cancellationDate1 >= today;
    }

    /**
     * Returns true if today is before the cancellation Period 2 Date
     */
    public static isInCancellationPeriod2(cancellationDate2?: Date): boolean {
        const today = new Date()
        if (cancellationDate2 === undefined) {
            return false;
        } else if (today > cancellationDate2) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Returns true if the item has no cancellation period specified
     */
     public static hasNoCancellationPeriod(cancellationDate1?: Date, cancellationDate2?: Date): boolean {
        return (cancellationDate1 == null && cancellationDate2 == null)
    }

    /**
     * Returns an ENUM ECancellationType depending on if the cancellation is still allowed and if it should display a warning
     *
     * @readonly
     * @type {ECancellationType}
     * @memberof Item
     */
     public static isCancellationAllowed(cancellationDate1?: Date, cancellationDate2?: Date): ECancellationPermission {
        if (this.isInCancellationPeriod1(cancellationDate1)) {
            return ECancellationPermission.Allowed;
        } else if (this.isInCancellationPeriod2(cancellationDate2)) {
            return ECancellationPermission.WithWarning;
        } else if (this.hasNoCancellationPeriod(cancellationDate1, cancellationDate2)) {
            return ECancellationPermission.Allowed;
        }
        return ECancellationPermission.NotAllowed;
    }
}

