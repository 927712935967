
import { JsonObject, JsonProperty } from 'json2typescript';

/** This Model is used mainly in the search result list. 
 * It is needed to display the parent Folders of the search results.
 */
@JsonObject('CatalogParent')
export class CatalogParent {
    @JsonProperty('parentItemId', Number, false)
    public parentItemId: number = 0;

    @JsonProperty('usageCount', Number, false)
    public usageCount: number = 0;

    @JsonProperty('catalogPath', String, false)
    public catalogPath: string = '';


    public constructor(parentItemId: number, usageCount: number, catalogPath: string) {     
        this.parentItemId = parentItemId;
        this.usageCount = usageCount;
        this.catalogPath = catalogPath;
    }
}