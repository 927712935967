import { EGamificationPointOrigin } from "$storage/models/enums"
import { Item } from "$storage/models/Item"
import React from "react"
import InlineSVG from "react-inlinesvg"
import ItemIconAndStatus from "$components/shared/itemBox/ItemIconAndStatus"
import skill from '$resources/svgs/gamification/skill.svg';
import rating from '$resources/svgs/gamification/rating.svg';
import { GamificationPointHistoryDetail as GamificationPointHistoryDetailModel } from '$src/storage/models/Gamification/GamificationPointHistoryDetail';
import TranslationStorage from "$storage/TranslationStorage"

interface IGamificationPointHistoryIconProps {
    detail: GamificationPointHistoryDetailModel;
    translationStorage: TranslationStorage;
}

export const GamificationPointHistoryIcon: React.FC<IGamificationPointHistoryIconProps> = (props: IGamificationPointHistoryIconProps) => {
    return <>
        {props.detail.triggerItem != null && props.detail.PointOrigin == EGamificationPointOrigin.LessonStatusChanged &&
            <GamificationPointHistoryIconLesson item={props.detail.triggerItem} />}
        {props.detail.triggerItem == null && props.detail.PointOrigin == EGamificationPointOrigin.LessonStatusChanged &&
            <></>}
        {(props.detail.PointOrigin == EGamificationPointOrigin.RatingSubmitted || props.detail.PointOrigin === EGamificationPointOrigin.SkillAquired) &&
            <GamificationPointHistoryIconBase {...props} />}
    </>
}

const GamificationPointHistoryIconLesson: React.FC<{ item: Item }> = (props: { item: Item }) => {
    return <ItemIconAndStatus
        id={props.item.sId}
        itemType={props.item.itemType}
        itemSubType={props.item.itemSubType}
        itemStatus={props.item.lessonStatus}
        itemTitle={props.item.title}
        itemAttributes={props.item.attributes}
        itemIcon={props.item.icon}
        hideItemTypeText={true} />
}

const GamificationPointHistoryIconBase: React.FC<IGamificationPointHistoryIconProps> = (props: IGamificationPointHistoryIconProps) => {
    let text = "";
    if(props.detail.PointOrigin == EGamificationPointOrigin.RatingSubmitted) {
        text = props.translationStorage.GetString('GamificationPointHistory:RatingSubmitted')
    }
    if(props.detail.PointOrigin == EGamificationPointOrigin.SkillAquired) {
        text = props.translationStorage.GetString('GamificationPointHistory:SkillAquired')
    }
    return <div
        className="item-icon"
    >
        <div
            className="item-icon__icon"
            aria-label={text}
            role={"img"}
        >
            {props.detail.PointOrigin == EGamificationPointOrigin.RatingSubmitted && <InlineSVG src={rating} />}
            {props.detail.PointOrigin == EGamificationPointOrigin.SkillAquired && <InlineSVG src={skill} />}
        </div>
    </div>
}