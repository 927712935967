import PubSub from '$src/util/EventListener/PubSub';

export default class ResizeEventListener {

    protected static _resizeEventListener: ResizeEventListener | null = null;
    
    private _subscriberHandler: PubSub;
    public get subscriberHandler(): PubSub {
        return this._subscriberHandler;
    }
    public set subscriberHandler(v: PubSub) {
        this._subscriberHandler = v;
    }

    private constructor() {
        this._subscriberHandler = new PubSub();
    }

    public static get instance(): ResizeEventListener {
        return this._resizeEventListener || (this._resizeEventListener = new this());
    }

}
