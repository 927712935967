import GTButton, { EButtonSize } from '$src/components/shared/Atoms/GTButton';
import { Translate } from '$src/components/shared/Translate';
import AttributeService from '$src/core/Services/AttributeService';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';

interface ISearchByKeywordsProps {
    onSearch?: (
        searchText: string,
    ) => void;
}

export default function SearchByKeywords(props: ISearchByKeywordsProps) {
    const [subjects, setSubjects] = useState<AttributeValue[]>();
    const [searchTextList, setSearchTextList] = useState<AttributeValue[]>();

    const configuredSubjects: number[] | undefined = globalConfig.searchProperties.oneClickSubjects;
    const isSearchBySearchTextEnabled: boolean | undefined = globalConfig.searchProperties.isSearchBySearchTextEnabled;

    useEffect(() => {
        if (configuredSubjects != null) {
            AttributeService.instance.getAttributeValues(16).then(sub => { //Read subjects
                if (isSuccess<Array<AttributeValue>>(sub)) {
                    setSubjects(sub.filter(f => configuredSubjects.includes(f.id)));
                }
            });
        }
        if (isSearchBySearchTextEnabled) {
            AttributeService.instance.getAttributeValues(globalConfig.searchProperties.searchTextAttributTypeId).then(attValues => {
                if (isSuccess<Array<AttributeValue>>(attValues)) {
                    setSearchTextList(attValues);
                }
            })
        }
    }, []);

    function onSearch(searchValue: string) {
        if (props.onSearch) {
            props.onSearch(searchValue);
        }
    }


    function renderSubjectSearch(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        subjects?.map((sub, index) => {
            elements.push(
                <li key={`subjectSearch-${index}`}>
                    <GTButton
                        buttonSize={EButtonSize.small}
                        onClick={() => (onSearch(sub.text))
                        }>
                        <Translate key={1}>{sub.text}</Translate>
                    </GTButton >
                </li>
            );
        });

        return elements;
    }

    function renderQuickSearch(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        searchTextList?.map((searchText, index) => {
            elements.push(
                <li key={`quickSearch-${index}`}>
                    <GTButton
                        buttonSize={EButtonSize.small}
                        onClick={() => (onSearch(searchText.text))
                        }
                    >
                        <Translate key={1}>{searchText.text}</Translate>
                    </GTButton >
                </li>
            );
        });

        return elements;
    }

    if ((subjects && subjects.length > 0) || (searchTextList && searchTextList.length > 0)) {
        return (
            <div className="search__keywords">
                <ul className="search__keywords-list">
                    {renderSubjectSearch()}
                    {renderQuickSearch()}
                </ul>
            </div>
        )
    } else {
        return null;
    }
}