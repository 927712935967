import { JsonObject, JsonProperty } from 'json2typescript';
import { ECheckoutStatus } from '$src/storage/models/enums';
import { CheckoutEventMessage } from '$storage/models/Purchase/CheckoutEventMessage';

/** Result of the checkout status polling */
@JsonObject
export class CheckoutStatusResponse {   
    /** status of the PaymentCheckout */
    @JsonProperty('checkoutStatus', Number, false)
    public checkoutStatus: ECheckoutStatus = ECheckoutStatus.Pending;

    @JsonProperty('eventMessages', [CheckoutEventMessage], false)
    public eventMessages: CheckoutEventMessage[] = [];    
}
