import ServiceClient from '$core/ServiceClient';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { CollaborationProviderLinkResponse } from '$storage/models/CollaborationProvider/CollaborationProviderLinkResponse';

/**
 * CollaborationProviderService provides all Service Methods for the Collaboration Provider Feature needed in sui
 */
export default class CollaborationProviderService extends ServiceClient {
    protected static _instance: CollaborationProviderService | null = null;

    protected className = 'CollaborationProviderService';
    protected loggerLocality = 'CollaborationProviderService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): CollaborationProviderService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Returns the collaboration provider team link for a item
     * @param itemId id of item to set the status
     */
         public async getItemLink(itemId: number) {
            return await this.get<CollaborationProviderLinkResponse>(`collaborationprovider/link`, CollaborationProviderLinkResponse, undefined, undefined, itemId.toString());
        }

    /**
     * Returns the collaboration provider team or channel link for a class
     * @param itemId id of item to set the status
     * @param classId id of item to set the status
     */
    public async getClassLink(itemId: number, classId: number) {
        return await this.get<CollaborationProviderLinkResponse>(`collaborationprovider/link/class`, CollaborationProviderLinkResponse, undefined, undefined, itemId.toString(), classId.toString());
    }

    /**
     * Returns true if the item has a collaboration team
     * @param itemId id of item to set the status
     */
    public async hasItemLink(itemId: number) {
        return await this.get<BooleanResponse>(`collaborationprovider/link/exists`, BooleanResponse, undefined, undefined, itemId.toString());
    }

    /**
     * Returns true if the class has a collaboration team or channel
     * @param itemId id of item to set the status
     * @param classId id of item to set the status
     */
     public async hasClassLink(itemId: number, classId: number) {
        return await this.get<BooleanResponse>(`collaborationprovider/link/class/exists`, BooleanResponse, undefined, undefined, itemId.toString(), classId.toString());
    }

} 