import * as React from 'react';

import { Translate } from '$src/components/shared/Translate';
import ItemRatingService from '$src/core/Services/ItemRatingService';
import { Item } from '$src/storage/models/Item';
import { Input, Rating} from '@progress/kendo-react-inputs';
import Session from '$src/core/Session';
import { FieldWrapper } from '@progress/kendo-react-form/dist/npm/FieldWrapper';
import GTButton from '$src/components/shared/Atoms/GTButton';
import { useState } from 'react';
import { InputTextArea } from '$components/shared/InputTextArea';
import { InputAttributes } from '$components/shared/InputAttributes';


interface IProps {
    item: Item;
    onCancle?: (event: React.FormEvent<HTMLButtonElement>) => void;
    onSave?: (event: React.FormEvent<HTMLButtonElement>) => void;
}

export default function ApplyRatingModal(props: IProps) {
    const [ratingTitle, setRatingTitle] = useState<string>('');
    const [commentRating, setCommentRating] = useState<string>('');
    const [ratingStars, setRatingStars] = useState<number>(0);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const [ratingCommentLength, setRatingCommentLength] = useState<number>(0);
    const [ratingTitleLength, setRatingTitleLength] = useState<number>(0);
    const [item] = useState<Item>(props.item);
    const [maxCommentLength] = useState<number>(500);
    const [maxTitleLength] = useState<number>(50);

    async function saveMyRating(e: React.FormEvent<HTMLButtonElement>) {
        if (props.onSave) {
            setDisableSaveButton(true);
            props.onSave(e);
        }
        await ItemRatingService.instance.saveItemRating({
            title: ratingTitle,
            comment: commentRating,
            itemId: props.item.itemId,
            rating: ratingStars * 2,
        });
        window.location.reload();
    }

    function onTitleChange(value: string) {
        setRatingTitle(value);
        setRatingTitleLength(value.length);
    }

    function onCommentChange(value: string, maxLength: number) {
        setCommentRating(value.substring(0, maxLength));
        setRatingCommentLength(value.substring(0, maxLength).length);
    }

    const ratingCommentAttribut: InputAttributes =
    {
        attributeValueType: undefined,
        class: 'appplyRatingModal_textArea',
        editMode: true,
        hasFocus: false,
        id: 'ratingComment',
        initialValidation: false,
        isReadOnly: false,
        isRequired: false,
        label: Session.instance.storage.translation.GetString('Rating:Comment'),
        labelClassName: 'heading__Level5',
        regExpression: undefined,
        rows: 6,
        url: undefined,
        value: "",
        disableErrorMessage: true
    };

    function ratingComment(readonly: boolean): JSX.Element {
        return <>
            <Input
                onChange={(e) => { onTitleChange(e.value) }}
                height="100"
                maxLength={maxTitleLength}
                readOnly={readonly}
                label={Session.instance.storage.translation.GetString('Rating:Title')}
            />{ratingTitleLength.toString() + '/' + maxTitleLength}
            <FieldWrapper>
                <InputTextArea 
                attributes={ratingCommentAttribut}
                onChange={(id: string, value: string) => onCommentChange(value, maxCommentLength)}
               />
                {ratingCommentLength.toString() + '/' + maxCommentLength}
            </FieldWrapper>
        </>
    }

    function cancleMyRating(event: React.FormEvent<HTMLButtonElement>) {
        setCommentRating('');
        setRatingStars(0);
        if (props.onCancle) {
            props.onCancle(event);
        }
    }

    function renderApplyRating(): JSX.Element {
        return (<>
            <div className="applyRatingModal__RatingItems">
                {
                    globalConfig.ratingProperties.commentsEnabled && <>
                        <Translate>{'Rating:RateNow'}</Translate>:
                    </>
                }
                <>
                    <Rating
                        value={item.ratingMine}
                        disabled={item.ratingMine > 0}
                        onChange={(e) => setRatingStars(e.value)} />
                </>
            </div>
            <div>
                {
                    globalConfig.ratingProperties.commentsEnabled &&
                    <div>
                        {ratingComment(item.ratingMine > 0 || !globalConfig.ratingProperties.commentsEnabled)}
                    </div>
                }
            </div>
            <div className="modal__spread-buttons">
                <GTButton onClick={(e) => saveMyRating(e)}
                    defaultButtonProps={{ disabled: disableSaveButton || (ratingStars === 0) }}
                    ariaLabel={Session.instance.storage.translation.GetString('Button:Save')}
                    id="btnRatingModalSave">
                    <Translate>{'Button:Save'}</Translate>
                </GTButton>

                <GTButton onClick={(event) => cancleMyRating(event)}
                    ariaLabel={Session.instance.storage.translation.GetString('Button:Cancel')}
                    id="btnRatingModalCancel">
                    <Translate>{'Button:Cancel'}</Translate>
                </GTButton>
            </div>
        </>)
    }

    return (
        <div>
            {renderApplyRating()}
        </div>
    )
}