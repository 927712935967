import { ExternalCourseRequestRegistration } from '$components/externalCourse/ExternalCourseRequestRegistration';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';
import { CheckBox } from '$src/components/shared/CheckBox';
import { Heading } from '$src/components/shared/Heading';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import UserService from '$src/core/Services/UserService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Boss } from '$src/storage/models/Boss';
import { ELessonStatus } from '$src/storage/models/enums';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

interface IProps {
    parentHeadingLevel: number;
    externalCourseRegistration: ExternalCourseRegistration;
    onStepClicked?: (stepNumber: number) => void;
    onSaveSuccess?: () => void;
}

interface IState {
    errorMessage: string;
    externalRegistrationChecked: boolean;
    currentUserBoss: Boss | undefined;
    dataLoaded: boolean;
}

export class StepRegistration extends React.Component<IProps, IState> {
    protected requestStepNumber = 4; // step number of registration step defined in RequestWizard.tsx
    protected attendanceStepNumber = 6 // step number of attendance step defined in RequestWizard.tsx

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentUserBoss: undefined,
            dataLoaded: false,
            errorMessage: '',
            externalRegistrationChecked: this.props.externalCourseRegistration.externalRegistrationDone,
        }

        this.goToRequest = this.goToRequest.bind(this);
        this.goToAttendance = this.goToAttendance.bind(this);
        this.onExternalCourseRegistrationSave = this.onExternalCourseRegistrationSave.bind(this);
    }

    public componentDidMount() {
        this.loadBoss();
    }

    public render() {
        if (!this.state.dataLoaded) {
            return (
                <ProgressSpinner />
            )
        } else {

            const ariaLabelPreviousStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToRequest');
            const ariaLabelNextStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToAttendance');
            let saveDisabled = true;
            if (this.props.externalCourseRegistration.externalRegistrationDone) {
                // external registration is already done
                saveDisabled = true;
            } else if (this.state.externalRegistrationChecked) {
                // external registration isn't done and the user has selected the checkbox
                saveDisabled = false;
            }
            const currentBoss = this.state.currentUserBoss ? this.state.currentUserBoss.fullNameAndEmail : '';
            return (
                <div className="step-content">
                    <ExternalCourseRequestRegistration
                        externalCourseRegistration={this.props.externalCourseRegistration}
                        parentHeadingLevel={this.props.parentHeadingLevel}
                        isBossView={false}
                    />
                    {currentBoss.length > 0 &&
                        <div className="step-content__block">
                            <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                {Session.instance.storage.translation.GetString('ExternalCourse:RegistrationHint').Format(currentBoss)}
                            </Heading>
                        </div>
                    }
                    <div className="step-content__block flex">
                        <CheckBox id="cbExternalRegistration"
                            onClick={() => this.onExternalRegistrationChanged()}
                            value={this.state.externalRegistrationChecked.toString()}
                            defaultChecked={this.state.externalRegistrationChecked}
                            labelId="lblExternalRegistration"
                            disabled={this.props.externalCourseRegistration.externalRegistrationDone} />
                        <label id="lblExternalRegistration" htmlFor="cbExternalRegistration"><Translate>ExternalCourse:ExternalRegistration</Translate></label>
                    </div>
                    {this.props.externalCourseRegistration.lessonStatus !== ELessonStatus.Completed &&
                        <div className="step-content__block">
                            <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                <Translate>ExternalCourse:CancelRequestOnPreviousStepHint</Translate>
                            </Heading>
                        </div>
                    }
                    <div className="step-content__block center">
                        <button
                            className="btn--sm btn--primary"
                            disabled={saveDisabled}
                            onClick={this.onExternalCourseRegistrationSave}
                            id="btnRegistrationSave">
                            <Translate>Button:Save</Translate>
                        </button>
                    </div>

                    {this.state.errorMessage.length > 0 &&
                        <div className="step-content__block center">
                            <span className={'input-message error--dark'}>
                                <Translate>{this.state.errorMessage}</Translate>
                            </span>
                        </div>
                    }
                    <div className="step-content__block">
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:RegistrationNextStepHint</Translate>
                        </Heading>
                    </div>

                    <div className="step-navigation">
                        <button className="button-link button-link--colorized-dark"
                            onClick={this.goToRequest}
                            aria-label={ariaLabelPreviousStep}>
                            <div className="inlineFlex">
                                <InlineSVG src={ArrowLeft} />
                                <span className="notMobile"><Translate>ExternalCourse:Request</Translate></span>
                            </div>
                        </button>
                        <span><Translate>ExternalCourse:Registration</Translate></span>

                        <button className="button-link button-link--colorized-dark"
                            onClick={this.goToAttendance}
                            aria-label={ariaLabelNextStep}
                            disabled={!this.props.externalCourseRegistration.externalRegistrationDone}>
                                { 
                                    this.props.externalCourseRegistration.externalRegistrationDone ? 
                                        <div className="inlineFlex">
                                            <span className="notMobile">
                                                <Translate>ExternalCourse:Attendance</Translate>
                                            </span>
                                            <InlineSVG src={ArrowRight} />
                                        </div> 
                                    :
                                        <div className="inlineFlex">
                                            <span className="notMobile external-courses__link__disabled">
                                                <Translate>ExternalCourse:Attendance</Translate>
                                            </span>
                                        </div>
                                }
                        </button>
                    </div>
                </div>
            )
        }
    }

    private goToRequest() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.requestStepNumber);
        }
    }

    private goToAttendance() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.attendanceStepNumber);
        }
    }

    private onExternalRegistrationChanged() {
        const externalRegistrationChecked = this.state.externalRegistrationChecked;
        this.setState({ externalRegistrationChecked: !externalRegistrationChecked });
    }

    private async onExternalCourseRegistrationSave() {
        const response = await ExternalCourseService.instance.updateExternalCourseRegistrationDone(this.props.externalCourseRegistration.registrationId,
            this.state.externalRegistrationChecked);

        if (isSuccess<BooleanResponse>(response)) {
            if (this.props.onSaveSuccess && response.status) {
                this.props.onSaveSuccess();
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:UpdateExternalCourseRegistrationFailed' });
        }

    }

    private async loadBoss() {
        const userBosses = await UserService.instance.getBosses(Session.instance.getUserLanguageCodeOrFallBack, true);
        if (isSuccess<Boss[]>(userBosses)) {
            this.setState({ currentUserBoss: userBosses[0], dataLoaded: true });
        } else {
            this.setState({ errorMessage: 'ErrorMessage:FailedToLoadBoss' });
        }
    }
}