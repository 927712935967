/**
 * this model is for the glossary breadcrumb 
 * because the bread crumb need all this ids for set the correct url
 */
export class GlossarIdForBredCrumb {
    // itemid of the parent
    public itemId: number;

    // catalog folde id
    public catId: number;

    // assignment id
    public asId: number;

    // trainingplan id
    public tpId: number;

    // trainingplan element id
    public tpeId: number;
}