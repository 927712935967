import { JsonObject, JsonProperty } from 'json2typescript';

import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { LessonStatusConverter } from '$src/storage/models/converters/LessonStatusConverter';
import { ELessonStatus } from '$src/storage/models/enums';
import { Item } from '$src/storage/models/Item';

/**
 * This class holds information about an assigned item (lesson or training plan)
 * (with information about item and assignment).
 */
@JsonObject
export class AssignedItem extends Item {

    /** Id of the assignment. */
    @JsonProperty('assignmentId', Number, false)
    public assignmentId: number = 0;

    /** Id of the user or group the assignment is associated to. */
    @JsonProperty('userGroupId', Number, false)
    public userGroupID: number = 0;
    
    /** Name of the user or group the assignment is associated to. */
    @JsonProperty('userGroupName', String, false)
    public userGroupName: string = '';
    
    /** Indicates if assignment is associated to a group (true) or user (false). */
    @JsonProperty('isGroup', Boolean, false)
    public isGroup: boolean = false;

    /** Indicates if this is a required (true) or optional (false) assignment. */
    @JsonProperty('isRequired', Boolean, false)
    public isRequired: boolean = false;

    /** Lesson status required to pass the assignment. */
    @JsonProperty('requiredLessonStatus', LessonStatusConverter, false)
    public requiredLessonStatus: ELessonStatus = ELessonStatus.Undefined;

    /** Score required to pass the assignment. */
    @JsonProperty('requiredScore', Number, false)
    public requiredScore: number = 0;

    /** Optional date/time after that the learner may start with the associated lesson. */
    @JsonProperty('learningPeriodBegin', DateTimeConverter, false)
    public learningPeriodBegin? : Date = undefined;

    /** Optional target time, the learner should have completed the associated lesson at that time. */
    @JsonProperty('learningPeriodTarget', DateTimeConverter, false)
    public learningPeriodTarget? : Date = undefined;

    /** Optional deadline, after this time the learner is no longer allowed to use the associated lesson. */
    @JsonProperty('learningPeriodEnd', DateTimeConverter, false)
    public learningPeriodEnd? : Date = undefined;

    /** A number used to order/sort the assignments. */
    @JsonProperty('rank', Number, false)
    public rank: number = 0;

}
