import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * This Model is used for the lesson Type "Document". 
 * tslint:disable-next-line:jsdoc-format
 * The attached files in this lesson are saved in models like this one.  
 */
@JsonObject
export class File {

    @JsonProperty('fileName', String, false)
    public fileName: string = '';
    @JsonProperty('url', String, false)
    public url: string = '';
    @JsonProperty('fileExtension', String, false)
    public fileExtension: string = '';
    @JsonProperty('fileSizeInBytes', Number, false)
    public fileSizeInBytes: number = 0;
}
