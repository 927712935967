import { JsonObject, JsonProperty } from 'json2typescript';

import Session from '$src/core/Session';
import { ClassStatusConverter } from '$src/storage/models/converters/ClassStatusConverter';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { RegistrationStatusConverter } from '$src/storage/models/converters/RegistrationStatusConverter';
import { EClassStatus, ERegistrationStatus } from '$src/storage/models/enums';
import { Item } from '$src/storage/models/Item';

/**
 * This class holds information about a user's registration for a F2F class, training plan class or WBT lesson
 * (with information about the F2F course/class training plan/class or WBT lesson and registration).
 */
@JsonObject
export class RegisteredItem extends Item {

    /** Id of the assignment. */
    @JsonProperty('registrationId', Number, true)
    public registrationId: number = 0;

    /** Registration status. */
    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;

    /** Date/time when user has/was registered. */
    @JsonProperty('registrationDate', DateTimeConverter, false)
    public registrationDate?: Date | undefined = undefined;

    /** Class Id (F2F class or training plan class). */
    @JsonProperty('classId', Number, true)
    public classId: number = 0;

    /** Class code (F2F class or training plan class). */
    @JsonProperty('classCode', String, true)
    public classCode: string = '';
  
    /** Class status (F2F class or training plan class). */
    @JsonProperty('classStatus', ClassStatusConverter, true)
    public classStatus: EClassStatus = EClassStatus.Undefined;

    /** Begin of learning period (F2F class or training plan class, for F2F class this is the beginning of the first appointment). */
    @JsonProperty('learningPeriodBegin', DateTimeConverter, false)
    public learningPeriodBegin?: Date = undefined;
  
    /** End of learning period (F2F class or training plan class, for F2F class this is the end of the last appointment). */
    @JsonProperty('learningPeriodEnd', DateTimeConverter, false)
    public learningPeriodEnd?: Date = undefined;
    
    public get registrationStatusAsString(): string {
        const tr = Session.instance.storage.translation;
        let registrationStatus = '';
        switch (this.registrationStatus) {
            case ERegistrationStatus.Requested:
                registrationStatus = tr.GetString('RegistrationStatus:Requested');
                break;
            case ERegistrationStatus.Accepted:
                registrationStatus = tr.GetString('RegistrationStatus:Accepted');
                break;
            case ERegistrationStatus.Rejected:
                registrationStatus = tr.GetString('RegistrationStatus:Rejected');
                break;
            case ERegistrationStatus.Cancelled:
                registrationStatus = tr.GetString('RegistrationStatus:Cancelled');
                break;
            case ERegistrationStatus.ScheduleCancelled:
                registrationStatus = tr.GetString('RegistrationStatus:ScheduleCancelled');
                break;
            case ERegistrationStatus.InWaitingList:
                registrationStatus = tr.GetString('RegistrationStatus:InWaitingList');
                break;
            default:
                registrationStatus = tr.GetString('RegistrationStatus:NotRegistered');
                break;
        }
        return registrationStatus;
    }
}
