import GTButton from "$src/components/shared/Atoms/GTButton";
import { Heading } from "$src/components/shared/Heading";
import { Translate } from "$src/components/shared/Translate";
import TeamService from "$src/core/Services/TeamService";
import Session from "$src/core/Session";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import { EFileType, EResponseStatus, ESkillType } from "$src/storage/models/enums";
import { SkillStatusUpdateRequest } from "$src/storage/models/RequestObjects/SkillStatusUpdateRequest";
import { isSuccess } from "$src/util/Result";
import { TextArea } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { FileDataRequest } from "$src/storage/models/RequestObjects/FileDataRequest";
import GTFileUpload from "$src/components/shared/Molecules/GTFileUpload";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";

interface IProps {
    reloadItems: () => void;
    skillRequest: SkillRequest;
    parentHeadingLevel: number;
    onExit: () => void;
}

export default function SkillRequestResponse(props: IProps) {
    const [errorMessage, setErrormessage] = useState<string>('');
    const [responseReason, setResponseReason] = useState<string>('');
    const [requestFiles, setRequestFiles] = useState<FileDataRequest[]>([]);
    const [sendRequestPending, setSendRequestPending] = useState<boolean>(false);
    const [disableDeclinedButton, setDisableDeclinedButton] = useState<boolean>(false);
    const [disabledDeclinedTooltip, setDisabledDeclinedTooltip] = useState<string>('');
    const [disableAcceptButton, setDisableAcceptButton] = useState<boolean>(false);
    const [disableAcceptButtonDependingOnFileUploadChecks, setDisableAcceptButtonDependingOnFileUploadChecks] = useState<boolean>(false);
    const [disabledAcceptTooltip, setDisabledAcceptTooltip] = useState<string>('');

    useEffect(() => {
        if (globalConfig.performanceCheck.isFileUploadRequiredForActualSkillForBoss) {
            if (requestFiles.length <= 0) {
                setDisableAcceptButton(true);
                setDisabledAcceptTooltip(Session.instance.storage.translation.GetString('PerformanceCheck:FileUploadRequired'));
            } else {
                setDisableAcceptButton(false);
                setDisabledAcceptTooltip('');
            }
        } else {
            setDisableAcceptButton(false);
            setDisabledAcceptTooltip('');
        }
    }, [requestFiles]);

    useEffect(() => {
        if (responseReason.length === 0) {
            setDisableDeclinedButton(true);
            setDisabledDeclinedTooltip(Session.instance.storage.translation.GetString('PerformanceCheck:ResponseReasonError'));
        } else {
            setDisableDeclinedButton(false);
            setDisabledDeclinedTooltip('');
        }
    }, [responseReason])

    const supportedFiletypesWithTranslation = [
        { id: EFileType.Undefined, text: Session.instance.storage.translation.GetString('FileUpload:ChooseFileType') },
        { id: EFileType.Certificate, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeCertificate') },
        { id: EFileType.Miscellaneous, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeMiscellaneous') },
    ]

    async function updateStatus(responseStatus: EResponseStatus) {
        setSendRequestPending(true);
        const requestObj: SkillStatusUpdateRequest = {
            userId: props.skillRequest.userId,
            skillId: props.skillRequest.skillId,
            skillLevelId: props.skillRequest.skillLevelId,
            type: props.skillRequest.skillType,
            responseReason: responseReason,
            responseStatus: responseStatus,
            fileCollection: requestFiles,
        };

        const response = await TeamService.instance.updateSkillStatus(requestObj);
        if (isSuccess<BooleanResponse>(response) && response.status) {
            setRequestFiles([])
            setSendRequestPending(false);
            props.reloadItems();
        } else {
            console.error(`Failed to send response for skill request, ${response.message}`);
            setErrormessage('PerformanceCheck:ErrorUpdateRequest');
            setSendRequestPending(false);
        }
    }

    function onClickAccepted() {
        if (globalConfig.performanceCheck.isFileUploadRequiredForActualSkillForBoss) {
            if (requestFiles.length <= 0) {
                setErrormessage(Session.instance.storage.translation.GetString('PerformanceCheck:FileUploadRequired'));

            } else {
                setErrormessage('');
                updateStatus(EResponseStatus.Accepted);
            }
        } else {
            updateStatus(EResponseStatus.Accepted);
        }
    }

    function onClickDeclined() {
        if (responseReason.length === 0) {
            setErrormessage(Session.instance.storage.translation.GetString('PerformanceCheck:ResponseReasonError'));
        } else {
            updateStatus(EResponseStatus.Declined);
        }
    }

    function onFileChange(files: FileDataRequest[]) {
        setRequestFiles([]);
        setRequestFiles(files);
    }

    const fileUploadLabel = globalConfig.performanceCheck.isFileUploadRequiredForActualSkillForBoss ?
        `${Session.instance.storage.translation.GetString('PerformanceCheck:UploadFilesBossLabel')}*` : 
        Session.instance.storage.translation.GetString('PerformanceCheck:UploadFilesBossLabel')

    return (
        <div className="performanceCheck--requestResponse">
            <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level3">
                <Translate>PerformanceCheck:ResponseTitle</Translate>
            </Heading>
            <div>
                {Session.instance.storage.translation.GetString('PerformanceCheck:ResponseDescription').Format(props.skillRequest.skillTitle)}
            </div>
            <Heading headingLevel={props.parentHeadingLevel + 1} cssClass="heading__Level5">
                <label id="lblSkillComment"><Translate>PerformanceCheck:ResponseLabel</Translate></label>
            </Heading>
            <TextArea
                id="SkillComment"
                ariaLabelledBy="lblSkillComment"
                placeholder={Session.instance.storage.translation.GetString('PerformanceCheck:ResponseLabelPlaceholder')}
                onChange={(e) => { setResponseReason(e.value.toString()) }}
                className="input-field"
                autoSize={true}
            />
            {props.skillRequest.skillType === ESkillType.ActualSkill &&
                <div className="performanceCheck--fileUpload">
                    <GTFileUpload
                        parentHeadingLevel={props.parentHeadingLevel}
                        label={fileUploadLabel}
                        allowedFileExtensions={globalConfig.performanceCheck.allowedFileExtensions}
                        multipleUploadAllowed={globalConfig.performanceCheck.multipleFileUploadAllowed}
                        maxFileSizeInByte={globalConfig.performanceCheck.maxFileSizeInByte}
                        onUploadFiles={(files) => { onFileChange(files); }}
                        defaultFileType={EFileType.Undefined}
                        supportedFiletypesWithTranslation={supportedFiletypesWithTranslation}
                        showFileTypeSelection={true}
                        multipleCertificateUploadAllowed ={globalConfig.performanceCheck.multipleCertificateUploadAllowed }
                        multipleMiscFileUploadAllowed={globalConfig.performanceCheck.multipleMiscFileUploadAllowed}
                        filetypeRequired={globalConfig.performanceCheck.filetypeRequired}
                        onDisableFurtherAction={(disabled) => setDisableAcceptButtonDependingOnFileUploadChecks(disabled)}
                        duplicateErrorMessage='PerformanceCheck:DocumentAlreadyUploaded'
                        multipleCertificateNotAllowedErrorMessage='PerformanceCheck:MultipleCertificateNotAllowedErrorMessage'
                        multipleMiscFileUploadAllowedErrorMessage='PerformanceCheck:MultipleMiscFileUploadAllowedErrorMessage'
                    />
                </div>
            }
            <div className="performanceCheck--requestResponse--buttonContainer">
                <GTButton
                    id={`OnExit_${props.skillRequest.skillId}`}
                    onClick={() => props.onExit()}>
                    <Translate>PerformanceCheck:CancelButton</Translate>
                </GTButton>
                <div className="performanceCheck--requestResponse--responseButtons">
                    {sendRequestPending ? <ProgressSpinner size={24} /> :
                        <>
                            <GTButton
                                additionalClassNames="marginRight-5"
                                isPrimary={false}
                                id={"DeclineButton" + props.skillRequest.skillId}
                                onClick={() => onClickDeclined()}
                                tooltipText={disabledDeclinedTooltip}
                                defaultButtonProps={{ disabled: disableDeclinedButton }}>
                                <Translate>PerformanceCheck:DeclineButton</Translate>
                            </GTButton>
                            <GTButton
                                id={"AcceptButton" + props.skillRequest.skillId}
                                onClick={() => onClickAccepted()}
                                tooltipText={disabledAcceptTooltip}
                                defaultButtonProps={{ disabled: disableAcceptButton || disableAcceptButtonDependingOnFileUploadChecks}}>
                                <Translate>PerformanceCheck:AcceptButton</Translate>
                            </GTButton>
                        </>
                    }
                </div>
            </div>

            <span className={'input-message error'}>
                <Translate>{errorMessage}</Translate>
            </span>
        </div>
    )
}

export class SkillRequest {
    public userId: number;
    public skillId: number;
    public skillLevelId: number;
    public skillType: ESkillType;
    public skillTitle: string;
}