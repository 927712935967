import { ETargetSkillStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class TargetSkillStatusConverter implements JsonCustomConvert<ETargetSkillStatus> {  
    public serialize(targetSkill: ETargetSkillStatus): number {
        switch (targetSkill) {         
            case ETargetSkillStatus.Undefined: return 0;
            case ETargetSkillStatus.Requested: return 1;
            case ETargetSkillStatus.Accepted: return 2;
            case ETargetSkillStatus.Declined: return 3;            
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): ETargetSkillStatus {
        switch (jsonValue) {
         
            case 0: return ETargetSkillStatus.Undefined;
            case 1: return ETargetSkillStatus.Requested;
            case 2: return ETargetSkillStatus.Accepted;
            case 3: return ETargetSkillStatus.Declined;         
            default: return ETargetSkillStatus.Undefined;
        }
    }
}
