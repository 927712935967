import React from 'react';

import {  ELessonStatus } from '$storage/models/enums';
import { Item } from '$storage/models/Item';
import ItemSummaryHelper from "$src/util/ItemSummaryHelper";

interface IProps {
    item: Item;
}

export class ToolbarRemainingTime extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        let isOverdue = false;

        if (this.props.item.remainingHoursToEnd != null && this.props.item.remainingHoursToEnd <= 0) {
            isOverdue = true;
        }

        const targetDateOnTheseLessonStatus = globalConfig.lessonToolbarProperties.displayTargetDateOnTheseLessonStatus;
        const item = this.props.item;

        if( !targetDateOnTheseLessonStatus.Completed && item.lessonStatus === ELessonStatus.Completed ||
            !targetDateOnTheseLessonStatus.Failed && item.lessonStatus === ELessonStatus.Failed  ||
            !targetDateOnTheseLessonStatus.Incomplete && item.lessonStatus === ELessonStatus.Incomplete ||
            !targetDateOnTheseLessonStatus.NotAttempted && item.lessonStatus === ELessonStatus.NotAttempted ||
            !targetDateOnTheseLessonStatus.Passed && item.lessonStatus === ELessonStatus.Passed ||
            !targetDateOnTheseLessonStatus.Undefined && item.lessonStatus === ELessonStatus.Undefined){
            return null;
        }   else {
            const dueDateText = this.getDateText();
            return (
                <div aria-label={dueDateText}
                    className={'l-box__hint box__remaining-time ' + (isOverdue ? 'box__remaining-time--overdue ' : ' ')}>
                    {dueDateText}
                </div>
            );
        }
    }

    private getDateText(): string {
        const item = this.props.item;
        const dueDate = ItemSummaryHelper.calculateDueDate(item.itemType, item.currentRegistration,item.nextRegistration, item.assignmentsEarliestLearningPeriodBegin,
            item.assignmentsLatestLearningPeriodTarget, item.assignmentsLatestLearningPeriodEnd);
        const inADecade = new Date();
        inADecade.setFullYear(inADecade.getFullYear() + 100);
        const dateText = dueDate && ItemSummaryHelper.calculateRemainingTimeText(dueDate, inADecade, item.remainingHoursToStart, item.remainingHoursToEnd);
        return dateText || '';
    }
}