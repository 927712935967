import React, { useState } from "react";

import { Heading } from "$src/components/shared/Heading";
import { Translate } from "$src/components/shared/Translate";
import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import MyRecommendationsByInterests from "$components/myRecommendations/MyRecommendationsByInterests";
import { HeadingCollapsible } from "$components/shared/HeadingCollapsible";
import { UnmountClosed } from "react-collapse";
import UserInterestsAdministration from "$components/userInterestsAdministration/UserInterestsAdministration";

/**
 * Component that renders one Power BI report
 * @param name The name of the report 
 */
export default function MyRecommendationsByInterestsPage() {
    const [userInterestsPanelOpen, setUserInterestsPanelOpen] = useState<boolean>(false);


    return (
        <div className="my-recommendations">
            <div className="l-container">
                <MenuBreadCrumb />
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>MyRecommendations:Title</Translate>
                </Heading>
                <div>
                    <Translate>MyRecommendations:SubTitle</Translate>
                </div>
            </div>

            <div className="l-element--striped-reverse my-recommendations__userInterestsAdministration">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        headingCssClass='heading__Level2 my-recommendations__heading__Level2'
                        containerCssClass="my-recommendations__heading "
                        isOpened={userInterestsPanelOpen}
                        onToggleOpenedState={() => setUserInterestsPanelOpen(!userInterestsPanelOpen)}>
                        <Translate>{'MyRecommendations:Settings'}</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={userInterestsPanelOpen}>
                        <UserInterestsAdministration headingLevel={2} />
                    </UnmountClosed>
                </div>
            </div>
            <div className="l-container">
                <MyRecommendationsByInterests appereance="Page" />
            </div>
        </div>
    )
}