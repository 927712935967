export const skillTitle = 'skillTitle';
export const skillRequiredLevel = 'skillRequiredLevel';
export const userSkillTargetStatus = 'userSkillTargetStatus';
export const approvalDate = 'approvalDate';
export const requestDate = 'requestDate';
export const skillType = 'skillType'


export class PerformanceCheckModel {
    public columnDictionary: { [key: number]: string } = {};

    constructor() {
        this.columnDictionary[0] = skillTitle;
        this.columnDictionary[1] = skillRequiredLevel;
        this.columnDictionary[2] = requestDate;
        this.columnDictionary[3] = approvalDate;
        this.columnDictionary[4] = userSkillTargetStatus;
        this.columnDictionary[5] = skillType;
    }
}