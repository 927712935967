import Session from '$core/Session';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

import Icondown from '$resources/svgs/misc/sort-ASC.svg'
import Iconup from '$resources/svgs/misc/sort-DESC.svg'

import { Translate } from '$components/shared/Translate';
import { KeyPressHelper } from '$src/util/KeyPressHelper';
import { ESortDirection } from '$storage/models/enums';




// id: string, which must be unique in a group of headers
// header: any content to be displayed
// initialSortDirection: Sortdirection of the control, when its rendered the first time.
//                         Should be None for all controls, except the one, which is sorted initially
// isActive: true for the control, which currently is responsible for the sorting
//           the parent control should implement a method like:
//
//              private IsActiveHeader(id:string ){
//                   return id ===  this.state.activeHeaderId;
//               }
// className = name of the css class.
// OnSortDirectionChanged: Function to implement the Sorting handler

// example:
// <SortableHeaderElement
//    id='MyID'
//    isActive={this.IsActiveHeader('MyID')}
//    header={<Translate>MyCertificates:AquiredDate</Translate>}
//    className="heading__Level4"
//    initialSortDirection={ESortDirection.None}
//    OnSortDirectionChanged={this.OnSort}
//  />

interface IProps {
    id: string;
    header: string;
    initialSortDirection: ESortDirection;
    isActive: boolean;
    className?: string;
    OnSortDirectionChanged: (id: string, sortDirection: ESortDirection) => void;
}

interface IState {
    currentSortDirection: ESortDirection;
}

export class SortableHeaderElement extends React.Component<IProps, IState>  {


    constructor(props: IProps) {
        super(props)
        this.state  ={ currentSortDirection:   props.initialSortDirection}
        this.OnClickHandler = this.OnClickHandler.bind(this)
        this.RaiseSrollDirecionChanged = this.RaiseSrollDirecionChanged.bind(this)
        this.RenderHeader = this.RenderHeader.bind(this)
    }
    

    public render() {

        return (
            <div id={this.props.id}>
            {this.RenderHeader()}
            </div>
        )
    }

    protected OnClickHandler() {
        switch (this.state.currentSortDirection){
            case ESortDirection.None:
                this.setState({currentSortDirection: ESortDirection.Down}); 
                break;
            case ESortDirection.Up:
                this.setState({currentSortDirection: ESortDirection.Down});
                break;
            case ESortDirection.Down:
                this.setState({currentSortDirection: ESortDirection.Up});
                break;
        }
        this.RaiseSrollDirecionChanged();
    }


    private RenderHeader()
    {
        let className = 'button-link--nowrap '
        if( this.props.className !== null) {
            className = className + this.props.className 
        }

        return <div role="button" className={className}
                       tabIndex={0}
                       aria-label={Session.instance.storage.translation.GetString(this.props.header)}
                       onClick={this.OnClickHandler}
                       onKeyUp={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => this.OnClickHandler()) }}

                    >
                    <Translate>{this.props.header}</Translate>{ this.renderIcon()}
                </div>

    }

    private RaiseSrollDirecionChanged() {

        if(this.props.OnSortDirectionChanged != null) {
            this.props.OnSortDirectionChanged(this.props.id, this.state.currentSortDirection)
        }
    }

    private renderIcon(){
        const icon =    this.state.currentSortDirection === ESortDirection.Up ? <InlineSVG src={Iconup} /> :
                        this.state.currentSortDirection === ESortDirection.Down ?  <InlineSVG src={Icondown} /> : null; 
        if (this.props.isActive === true) {
            return  icon;            
        }
        else
        {
            return '';
        }    
    } 
}

