import React, { useState } from 'react';

import { BreadCrumb } from '$components/breadCrumb/BreadCrumb';
import { BreadCrumbElement } from '$storage/models/BreadCrumbElement';
import { useLocation } from 'react-router';
interface IProps {
    rootElement?: BreadCrumbElement;
    splitterElement?: JSX.Element;
    autoLookUpForMoreMenuEntry?: boolean; // Default true
    navigationItemMoreRouteName?: string;  // Default defaultMoreRouteName
    routePathName?: string; // Use this to overwrite the default behaviour to take the current route
    breadCrumbElementsToAppend?: BreadCrumbElement[];
}

export const MenuBreadCrumb: React.FC<IProps> = (props: IProps) => {
    const defaultMoreRouteName = '/more';

    const [autoLookUpForMoreMenuEntry] = useState<boolean>(props.autoLookUpForMoreMenuEntry == null ? true : props.autoLookUpForMoreMenuEntry);
    const [navigationItemMoreRouteName] = useState<string>(props.navigationItemMoreRouteName || defaultMoreRouteName);
    const location = useLocation();
    const [routePathName] = useState<string>(props.routePathName || location.pathname);

    const bcElements: BreadCrumbElement[] = [];
    const itemStyle = globalConfig.breadCrumbProperties.itemStyle;
    let mainNavItem;


    const getInMainNavigation = (currentRouteName: string) => {
        const navItems = globalConfig.navigationProperties.navigationItems.filter(ni => ni.link.toUpperCase() === currentRouteName.toUpperCase());
        if (navItems != null && navItems.length > 0) {
            return navItems[0];
        }
        else { return null }
    }
    
    const getExtendedNavigation = (currentRouteName: string) => {
        const navItems = globalConfig.navigationProperties.extendedNavigationItems.filter(
            ni => {
                let navItemRouteName = ni.link;
                const navItemRouteNameUrlParameterIndex: number = navItemRouteName.indexOf('?');
                navItemRouteName = navItemRouteNameUrlParameterIndex > -1 ?
                    navItemRouteName.substring(0, navItemRouteNameUrlParameterIndex) :
                    navItemRouteName;
                return navItemRouteName.toUpperCase() === currentRouteName.toUpperCase()
            }
        );
        if (navItems != null && navItems.length > 0) {
            return navItems[0];
        }
        return null;
    }
    
    // Check if extended navigation
    const extendedNavItem = getExtendedNavigation(routePathName)
    if (extendedNavItem) {
        // Auto Look Up More Main Navigation Item
        if (autoLookUpForMoreMenuEntry) {
            mainNavItem = getInMainNavigation(navigationItemMoreRouteName);
            if (mainNavItem != null) {
                bcElements.push({
                    navigationPath: mainNavItem.link,
                    style: itemStyle,
                    title: mainNavItem.text,
                    translateTitle: true
                });
            }
        }
        bcElements.push({
            navigationPath: extendedNavItem.link,
            style: itemStyle,
            title: extendedNavItem.text,
            translateTitle: true
        });
    }

    // Check if main navigation
    else {
        mainNavItem = getInMainNavigation(routePathName);
        while (mainNavItem != null) {
            bcElements.push({
                navigationPath: mainNavItem.link,
                style: itemStyle,
                title: mainNavItem.text,
                translateTitle: true
            });
            if (mainNavItem.parent != null && mainNavItem.parent !== '') {
                mainNavItem = getInMainNavigation(mainNavItem.parent);
            }
            else { mainNavItem = null }
        }
    }

    // Push append items if defined
    if (props.breadCrumbElementsToAppend != null) {
        props.breadCrumbElementsToAppend.map(bc => bcElements.push(bc));
    }

    return <BreadCrumb rootElement={props.rootElement} breadCrumbElements={bcElements} splitterElement={props.splitterElement} />
}