import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { FileSharingTag } from '$src/storage/models/FileSharing/FileSharingTag';

/**
 * Persistend object extended with calculated properties for access rights
 * @export
 * @class FileSharingDocument
 */
@JsonObject
export class FileSharingDocument {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('tPlanId', Number, false)
    public tPlanId: number = 0;
    
    @JsonProperty('assignmentId', Number, false)
    public assignmentId: number = 0;

    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('name', String, false)
    public name: string = '';

    @JsonProperty('modifiedAt', DateTimeConverter, false)
    public modifiedAt: Date = new Date();

    @JsonProperty('modifiedBy', Number, false)
    public modifiedBy: number = 0;

    @JsonProperty('size', Number, false)
    public size: number = 0;

    @JsonProperty('allowDownload', Boolean, false)
    public allowDownload: boolean = false;

    @JsonProperty('allowDelete', Boolean, false)
    public allowDelete: boolean = false;
    
    @JsonProperty('fileSharingTags', [FileSharingTag])
    public fileSharingTags: FileSharingTag[] = [];

    @JsonProperty('sortOrder', Number, false)
    public sortOrder: number = 0;

    public isCbxClicked: boolean = false;
}