import { JsonObject, JsonProperty } from "json2typescript";

/**
 * Terms and conditions document
 */
@JsonObject
export class TCDocument {
    /** Name of file */
    @JsonProperty('id', Number, false)
    public id: number = 0;

    /** Name of file */
    @JsonProperty('filename', String, false)
    public filename: string = '';

    /** Language */
    @JsonProperty('language', String, false)
    public language: string = '';

    /** Base64 string of file content */
    @JsonProperty('document', Uint8Array, false)
    public document: Uint8Array = new Uint8Array();

    /** Primary key */
    @JsonProperty('approvalStatusId', Number, false)
    public approvalStatusId: number = 0;

    /** Version number */
    @JsonProperty('version', Number, false)
    public version: number = 0;

    /** Is valid since */
    @JsonProperty('validSince', Date, false)
    public validSince: Date = new Date();

    /** Domain */
    @JsonProperty('domainId', Number, false)
    public domainId: number = 0;

    /** Is default */
    @JsonProperty('isDefault', Boolean, false)
    public isDefault: boolean = false;
}