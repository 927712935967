import { JsonObject, JsonProperty } from 'json2typescript';
import ShoppingBasket from '$storage/models/ShoppingBasket/ShoppingBasket';

/**
 * Request object for PurchaseService.checkout 
 */
@JsonObject
export class CheckoutRequest {
    constructor(shoppingBasket: ShoppingBasket) {
        this.shoppingBasket = shoppingBasket;
    }
    
    /** shopping basket with the items to purchase */
    @JsonProperty('shoppingBasket', ShoppingBasket, false)
    public shoppingBasket: ShoppingBasket;
}
