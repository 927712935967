import { observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { LegacySearchBar } from '$src/components/layout/LegacySearchBar';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import Session from '$core/Session';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import ItemTypeModel from '$storage/models/ItemTypeModel';
import { LegacySearchedItemCompact } from '$src/storage/models/search/legacy/LegacySearchedItemCompact';
import { LegacySearchResponse } from '$src/storage/models/search/legacy/LegacySearchResponse';
import CustomErrorMessage from '$util/CustomErrorMessage';
import DateHelper from '$util/DateHelper';
import GtError from '$util/GtError';
import JsonHelper from '$util/JsonHelper';
import { isSuccess } from '$util/Result';
import { SearchResult } from '$src/storage/models/search/elk/SearchResult';
import SearchResults from '$components/search/elk/SearchResults';


interface IStateSearchParams {
    currentSearchText: string;
    currentItemTypeArray?: ItemTypeModel[];
    currentFrom: Date | null;
    currentTo: Date | null;
}

interface IState extends IStateSearchParams {
    errorMessage: string;
    searchResults: SearchResult[];
    isSearching: boolean;
    doInitialSearch: boolean;
    itemCount: number;
    isNextDisabled: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
@observer
export class LegacySearch extends React.Component<RouteComponentProps, IState> {
    protected className = 'ExternalPage';
    protected logger = 'Components.Search';
    protected itemsPerPage: number = globalConfig.searchProperties.itemsPerPage;

    constructor(props: RouteComponentProps) {
        super(props);

        const urlParams = this.getUrlSearchValues();
        this.state = ({
            currentFrom: urlParams != null ? urlParams.currentFrom : null,
            currentItemTypeArray: urlParams != null ? urlParams.currentItemTypeArray : undefined,
            currentSearchText: urlParams != null ? urlParams.currentSearchText : '',
            currentTo: urlParams != null ? urlParams.currentTo : null,
            doInitialSearch: urlParams.currentSearchText != null && urlParams.currentSearchText !== '',
            errorMessage: '',
            isNextDisabled: false,
            isSearching: false,
            itemCount: this.itemsPerPage,
            searchResults: [],
        });
    }

    public async componentDidMount() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
        if (this.state.doInitialSearch) {
            const urlParams = this.getUrlSearchValues();
            await this.search(urlParams.currentSearchText, urlParams.currentItemTypeArray, urlParams.currentFrom, urlParams.currentTo);
        }
    }

    public componentDidUpdate() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
    }

    public async UNSAFE_componentWillReceiveProps() {
        const urlParams = this.getUrlSearchValues();
        await this.search(urlParams.currentSearchText, urlParams.currentItemTypeArray, urlParams.currentFrom, urlParams.currentTo);
    }

    public render() {
        return (
            <React.Fragment>
                <div className="search__page">
                    <div className="search__page__background">
                        <div className="l-container search__header-container" >
                            <MenuBreadCrumb
                                breadCrumbElementsToAppend={[{ title: 'Search:Title', navigationPath: this.props.location.pathname, translateTitle: true }]}
                                {...this.props} />
                            <h1 className="heading__Title heading--secondary"><Translate>Search:Title</Translate></h1>
                            <div className="search__textbox-container">
                                <LegacySearchBar isFlyoutAlwaysVisible={true}
                                    onSearch={(text, from, to, itemTypeArray) => this.onSearch(text, from, to, itemTypeArray)}
                                    initialTo={this.state.currentTo}
                                    initialFrom={this.state.currentFrom}
                                    initialSearchText={this.state.currentSearchText}
                                    initialItemTypeArray={this.state.currentItemTypeArray}
                                    isInHeader={false} />
                                <span className={'input-message error'}>
                                    <Translate>{this.state.errorMessage}</Translate>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="l-container">
                        {this.state.isSearching ? (
                            <ProgressSpinner />
                        ) : (
                            <SearchResults {...this.props} searchResults={this.state.searchResults} onSearch={() => console.log()} searchValue="" />
                        )
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private async onSearch(text: string, from: Date | null, to: Date | null, itemTypeArray?: ItemTypeModel[] | undefined) {
        if (text != null && text !== '') {
            let itemsAsString: string = ''
            if (itemTypeArray != null) {
                itemTypeArray.map((item) => {
                    if (itemsAsString !== '') {
                        itemsAsString += ','
                    }
                    itemsAsString += ItemTypeConverter.asGtServiceItemString(item.itemType)
                })
            }
            // eslint-disable-next-line @typescript-eslint/await-thenable
            await this.setState({
                currentFrom: from,
                currentItemTypeArray: itemTypeArray,
                currentSearchText: text,
                currentTo: to
            });

            this.props.history.push('/legacy/search?text=' + text
                + '&types=' + ItemTypeModel.itemTypeArrayToString(itemTypeArray)
                + '&from=' + encodeURI(DateHelper.toDateString(from))
                + '&to=' + encodeURI(DateHelper.toDateString(to))
            );
        }
    }

    private async search(searchValue: string, itemTypes: ItemTypeModel[] | undefined, tempFrom: Date | null, tempTo: Date | null) {
        // This gets all search results to search string

        let itemsAsString: string = ''
        if (itemTypes != null) {
            itemTypes.map((item) => {
                if (itemsAsString !== '') {
                    itemsAsString += ','
                }
                itemsAsString += ItemTypeConverter.asGtServiceItemString(item.itemType)
            });
        }

        this.setState({
            currentFrom: tempFrom,
            currentItemTypeArray: itemTypes,
            currentSearchText: searchValue,
            currentTo: tempTo,
            doInitialSearch: false,
            isNextDisabled: false,
            isSearching: true,
            itemCount: this.itemsPerPage,
            searchResults: [],
        });
        Logger.log(this.logger, 'IsSearching = ' + true);
        const searchText = searchValue != null && searchValue !== '' ? searchValue : '*';

        const searchResultList = await Session.instance.storage.searchReponses.getSearchItems(
            Session.instance.getUserLanguageCodeOrFallBack,
            searchText,
            itemsAsString,
            tempFrom,
            tempTo,
            false
        );
        if (searchResultList instanceof LegacySearchResponse && isSuccess<LegacySearchResponse>(searchResultList)) {
            Logger.log(this.logger, 'Search function returned ' + searchResultList.searchResults.length.toString() + ' results.');
            const resultsCompact = LegacySearchedItemCompact.GetCompactList(searchResultList.searchResults);
            this.setState({ searchResults: resultsCompact, errorMessage: '', isNextDisabled: resultsCompact.length - 1 < this.itemsPerPage });
        } else if (searchResultList === null || searchResultList instanceof GtError) {
            let errMsg = 'ErrorMessage:SearchFailed';
            if (searchResultList !== null && searchResultList.detailedObject !== undefined) {
                errMsg = CustomErrorMessage.getErrorCodeMessageString(searchResultList.detailedObject.subStatusCode);
            }
            Logger.log(this.logger, errMsg);
            this.setState({ errorMessage: errMsg })
            Session.instance.storage.classList.clear();
        }

        this.setState({ isSearching: false });
        Logger.log(this.logger, 'IsSearching = ' + false);
    }

    private getUrlSearchValues(): IStateSearchParams {
        const parameters = new URLSearchParams(window.location.search);
        const tempText = this.getParam(parameters, 'text');
        const tempFrom = DateHelper.toDate(this.getParam(parameters, 'from'));
        const tempTo = DateHelper.toDate(this.getParam(parameters, 'to'));
        const tempItemTypeArray = this.getParam(parameters, 'types');

        const items: ItemTypeModel[] = JsonHelper.toObject(tempItemTypeArray);
        return {
            currentFrom: tempFrom,
            currentItemTypeArray: items,
            currentSearchText: tempText || '',
            currentTo: tempTo,
        }
    }

    private getParam(params: URLSearchParams, p: string) {
        const temp = params.get(p)
        if (temp != null) {
            return decodeURI(temp);
        }
        return undefined;
    }

}
export default LegacySearch;