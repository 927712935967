
import { SubStatusCodeConverter } from '$src/storage/models/converters/SubStatusCodeConverter';
import { ESubStatusCodes } from '$src/storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';


@JsonObject
export default class CustomErrorMessage {

    @JsonProperty('subStatusCode', SubStatusCodeConverter, false)
    public subStatusCode: ESubStatusCodes = ESubStatusCodes.Unknown;

    @JsonProperty('message', String, false)
    public message: string;

    /**
     * Determine which error message should be shown for following SubStatusCode.
     * Implement case for each custom error message, default is "Unknown Error"
     * retVal is the keyID of the translation. It is returned with leading "ErrorMessage:" as category.
     * @param code SubStatusCode of error
     */
    public static getErrorCodeMessageString(code: ESubStatusCodes): string {
        let retVal: string = '';
        switch (code) {
            case ESubStatusCodes.MethodNotAllowed_NewPinNotAllowed:
                retVal = 'PasswordRecoveryFailed_NewPinNotAllowed';
                break;
            case ESubStatusCodes.MethodNotAllowed_PWResetNotAllowed:
                retVal = 'PasswordRecoveryFailed_PWResetNotAllowed';
                break;
            case ESubStatusCodes.MethodNotAllowed_UpdateRegistration:
                retVal = 'LockedRegistration';
                break;
            case ESubStatusCodes.MethodNotAllowed_RequestAuthToken:
                retVal = 'RequestAuthTokens_NotAllowed';
                break;
            case ESubStatusCodes.MethodNotAllowed_ExternalCourseRegistration:
                retVal = 'ExternalCourseRegistration_NotAllowed';
                break;
            case ESubStatusCodes.MethodNotAllowed_PWRecoveryCooldown:
                retVal = 'PasswordRecoveryFailed_PasswordRecoveryCooldown';
                break;
            case ESubStatusCodes.MethodNotAllowed_EmptyDocumentUpload:
                retVal = 'EmptyDocumentUpload_NotAllowed';
                break;
            case ESubStatusCodes.MethodNotAllowed_MFA_Cooldown:
                retVal = 'MultiFactorAuthenticationFailed_Cooldown';
                break;
            case ESubStatusCodes.NotFound_SetNewPassword_UserNotFound:
                retVal = 'PasswordRecoveryFailed_UserNotFound';
                break;
            case ESubStatusCodes.NotFound_MFA_UserNotFound:
                retVal = 'MultiFactorAuthenticationFailed_UserNotFound';
                break;
            case ESubStatusCodes.NotFound_MFA_NotUnique:
                retVal = 'MultiFactorAuthenticationFailed_NotUnique';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_LinkInvalid:
                retVal = 'PasswordRecoveryFailed_LinkInvalid';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PINExpired:
                retVal = 'PasswordRecoveryFailed_PINExpired';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PINInvalid:
                retVal = 'PasswordRecoveryFailed_PINInvalid';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_LinkExpired:
                retVal = 'PasswordRecoveryFailed_LinkExpired';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_EmailAddressNotUnique:
                retVal = 'PasswordRecoveryFailed_EmaiLAddressNotUnique';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_MobileNrNotFound:
                retVal = 'PasswordRecoveryFailed_MobileNrNotFound';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_InvalidPassword:
                retVal = 'PasswordRecoveryFailed_InvalidPassword';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_CannotChangePublicPassword:
                retVal = 'PasswordRecoveryFailed_CannotChangePublicPassword';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsToShort:
                retVal = 'PasswordIsToShort';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordHasWrongSyntax:
                retVal = 'PasswordHasWrongSyntax';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsInvalid:
                retVal = 'PasswordIsInvalid';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsInHistory:
                retVal = 'PasswordIsInHistory';
                break;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsToNew:
                retVal = 'PasswordIsToNew';
                break;
            case ESubStatusCodes.InternalServerError_UserCertificates_GeneralError:
                retVal = 'GetCertificatesForUserFailed';
                break;
            case ESubStatusCodes.InternalServerError_DocumentFiles_GeneralError:
                retVal = 'FilesForDocumentFailed';
                break;
            case ESubStatusCodes.InternalServerError_RequestAuthTokens_UserIsInvalidOrLocked:
                retVal = 'UserIsInvalidOrLocked';
                break;
            case ESubStatusCodes.InternalServerError_ExternalCourseProviderSidAlreadyExists:
                retVal = 'ExternalCourseProviderAlreadyExists';
                break;
            case ESubStatusCodes.InternalServerError_ExternalCourseSidAlreadyExists:
                retVal = 'ExternalCourseAlreadyExists';
                break;
            case ESubStatusCodes.InternalServerError_ContactSystemAdmin:
                retVal = 'ContatSystemAdmin';
                break;
            case ESubStatusCodes.NotSupported_UpdateRegistration:
                retVal = 'NotSupportedForThisLesson';
                break;
            case ESubStatusCodes.InternalServerError_Login_UserIsLocked:
                retVal = 'Login_UserIsLocked';
                break;
            case ESubStatusCodes.InternalServerError_Login_UserIsInvalid:
                retVal = 'Login_UserIsInvalid';
                break;
            case ESubStatusCodes.InternalServerError_Login_UserIsInactive:
                retVal = 'Login_UserIsInactive';
                break;
            case ESubStatusCodes.InternalServerError_Login_LoginNotPermitted:
                retVal = 'Login_LoginIsNotPermitted';
                break;
            case ESubStatusCodes.InternalServerError_Login_UserNotFound:
                retVal = 'Login_UserNotFound';
                break;
            case ESubStatusCodes.InternalServerError_Login_InvalidPassword:
                retVal = 'Login_InvalidPassword';
                break;
            case ESubStatusCodes.InternalServerError_SelfReg_MaxActiveUsersExceeded:
                retVal = 'SelfReg_MaxActiveUsersExceeded';
                break;
            case ESubStatusCodes.InternalServerError_SelfReg_ValidationError:
                retVal = 'SelfReg_ValidationError';
                break;
            case ESubStatusCodes.InternalServerError_LicenseRegRunOutError:
                retVal = 'LicenseRegRunOutError';
                break;
            case ESubStatusCodes.InternalServerError_LicenseRegUsernameError:
                retVal = 'LicenseRegUsernameError';
                break;
            case ESubStatusCodes.InternalServerError_LicenseRegEmailError:
                retVal = 'LicenseRegEmailError';
                break;
            case ESubStatusCodes.InternalServerError_LicenseRegWrongContractNumberError:
                retVal = 'LicenseRegWrongContractNumber';
                break;
            case ESubStatusCodes.MethodNotAllowed_UserIsNotATrainer:
                retVal = 'UserIsNotATrainer';
                break;

            case ESubStatusCodes.RegisterNewUser_CaptchaVerificationFailed:
            case ESubStatusCodes.RegisterNewUser_UsernameNotValid:
            case ESubStatusCodes.RegisterNewUser_EmailNotValid:
            case ESubStatusCodes.RegisterNewUser_MaxUserCountForDomainExceeded:
            case ESubStatusCodes.RegisterNewUser_TokenForSecondStepExpired:
            case ESubStatusCodes.RegisterNewUser_InvalidTokenForSecondStep:
            case ESubStatusCodes.RegisterNewUser_SecondStepSeemsToBeCompleted:
            case ESubStatusCodes.RegisterNewUser_UsernameNotUnique:
            case ESubStatusCodes.RegisterNewUser_EmailNotUnique:
            case ESubStatusCodes.RegisterNewUser_PasswordComplexityFailed:
            case ESubStatusCodes.RegisterNewUser_PasswordIsInHistory:
            case ESubStatusCodes.RegisterNewUser_PasswordIsTooNew:
            case ESubStatusCodes.RegisterNewUser_PasswordIsTooShort:
            case ESubStatusCodes.RegisterNewUser_InvalidPassword:
            case ESubStatusCodes.RegisterNewUser_UnknownError:
                retVal = ESubStatusCodes[code];
                break;

            case ESubStatusCodes.Unknown:
                retVal = 'General'
        }

        return 'ErrorMessage:' + retVal;
    }
}





