import { JsonObject, JsonProperty } from 'json2typescript';
import { User } from './User';

@JsonObject('SelfRegistrationResponse')
export class RegisterNewUserResponse {

    @JsonProperty('user', User, false)
    public user: User | undefined = undefined;

    // @JsonProperty('dynamicGroupRefreshActivated', Boolean, false)
    // public dynamicGroupRefreshActivated: boolean = false;
 }


