import React, { useEffect, useState } from 'react';
import { MultiSelect, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

import Logger from '$src/core/Logger';
import PaymentCampaignService from '$src/core/Services/PaymentCampaignService';
import GTButton from '$components/shared/Atoms/GTButton';
import { GenericInput } from '$components/shared/GenericInput';
import { Translate } from '$components/shared/Translate';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { PaymentVoucherCampaignRequest } from '$src/storage/models/Campaign/PaymentVoucherCampaignRequest';
import { isSuccess } from '$src/util/Result';
import Session from '$src/core/Session';
import { PaymentVoucherCampaignResponse, RestrictionItems } from '$src/storage/models/Campaign/PaymentVoucherCampaignResponse';
import { PaymentVoucherCampaignItemsToRestrictResponse } from '$src/storage/models/Campaign/PaymentVoucherCampaignResponse';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { MultiSelectCheckboxItemRender } from '$components/shared/Atoms/MultiSelectCheckboxItemRender';

const loggerLocality = 'Components.AddPaymentCampaignForm';

interface IProps {
    addNewCampaignToArchive: (newCampaigns: PaymentVoucherCampaignResponse[]) => void;
}


/**
 * Form to Add new Voucher Campaigns
 * @param props 
 * @returns 
 */
export default function AddPaymentCampaignForm(props: IProps) {

    const [nameOfCampaign, setNameOfCampaign] = useState<string>('');
    const [numberOfVouchers, setNumberOfVouchers] = useState<string>('');
    const [discount, setDiscount] = useState<string>('');
    const [campaignExpiryDate, setCampaignExpiryDate] = useState<Date | null>(null);
    const [messages, setMessages] = useState<Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> | null>(null);
    const [alertType, setAlertType] = useState<'warning' | 'error' | 'info' | 'success' | 'mixed'>('info');
    const [hasFocus, setHasFocus] = useState<string>();
    const [restrictionItems, setRestrictionItems] = useState<RestrictionItems[]>([]);
    const [filteredRestrictionItems, setFilteredRestrictionItems] = useState<RestrictionItems[]>([]);
    const [itemAttributeName, setItemAttributeName] = useState<string>('');
    const [metaData, setMetaData] = useState<string>('');
    const [itemLifecycleInDays, setItemLifecycleInDays] = useState<string>('');
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [selectedItemRestrictions, setSelectedItemRestrictions] = useState<Array<RestrictionItems>>([]);
    const [isCreateButtonEnabled, setIsCreateButtonEnabled] = useState<boolean>(false);
    const [isCreatingCampaign, setIsCreatingCampaign] = useState<boolean>(false);
    const [enableValidation, setEnableValidation] = useState<boolean>(false);
    const maxDate = new Date(2079, 6, 6);
    const minDate = new Date(1900, 1, 1);
    // const [isDiscountAbsolute, setIsDiscountAbsolute] = useState<boolean>(false); Coming soon - leave comment here

    const TRANSLATION_PREFIX = 'AddPaymentCampaignForm';
    const getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${TRANSLATION_PREFIX}:${str}`);
    }

    useEffect(() => {
        if (!isLoaded) {
            PaymentCampaignService.instance.getRestrictionItems().then(response => {
                if (isSuccess(response)) {
                    const res = response as PaymentVoucherCampaignItemsToRestrictResponse;
                    const items = res.restrictionItems as Array<RestrictionItems>;
                    setRestrictionItems([...items]);
                    setFilteredRestrictionItems([...items]);
                    setIsLoaded(true);
                    setMessages(null);
                } else {
                    const mssgs = Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }>();
                    Logger.log(loggerLocality, `An error ocurred while trying to get Voucher Campaigns from Server`);
                    mssgs.push({ message: getTranslation('ErrorLoadingPaymentCampaigns'), alertType: 'error' });
                    setMessages(mssgs);
                    setAlertType('error');
                }
            });
        }
    }, []);


    useEffect(() => {
        const mssgs = Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }>();
        let hasFormErrors = false;

        if (nameOfCampaign != '') {
            if (nameOfCampaign.length > 50) {
                mssgs.push({ message: getTranslation('InputErrorCampaignTitle'), alertType: 'error' });
            }
        }
        if (numberOfVouchers != '') {
            if (Number(numberOfVouchers) < 1 || Number(numberOfVouchers) > 1000) {
                mssgs.push({ message: getTranslation('InputErrorNumberOfVoucher'), alertType: 'error' });
            }
            const isOnlyNum = /^[0-9]*$/.test(numberOfVouchers);
            if (isOnlyNum == false) {
                mssgs.push({ message: getTranslation('InputErrorNumberOfVoucherFormat'), alertType: 'error' });
            }
        }
        if (discount != '') {
            if (Number(discount) < 0 || Number(discount) == 0 || Number(discount) > 100) {
                mssgs.push({ message: getTranslation('InputErrorDiscount'), alertType: 'error' });
            }
        }
        if (campaignExpiryDate != null) {
            const today = new Date();
            const yesterday = new Date(today.setDate(today.getDate() - 1));
            if (campaignExpiryDate < yesterday) {
                mssgs.push({ message: getTranslation('InputErrorDate'), alertType: 'error' });
            } else if (campaignExpiryDate > maxDate) {
                mssgs.push({ message: getTranslation('InputErrorMaxDate'), alertType: 'error' });
            }
        }
        if (nameOfCampaign == '' || numberOfVouchers == '' || discount == '' || campaignExpiryDate == null) {
            setIsCreateButtonEnabled(false);
            hasFormErrors = true;
        }
        if (mssgs.length > 0) {
            setMessages(mssgs);
            setAlertType('error');
            setIsCreateButtonEnabled(false);
            hasFormErrors = true;
        }
        if (mssgs.length == 0) {
            setMessages(null);
        }
        if (!hasFormErrors) {
            setIsCreateButtonEnabled(true);
        }

    }, [nameOfCampaign, numberOfVouchers, discount, campaignExpiryDate]);

    const onCreateClicked = () => {
        setIsCreatingCampaign(true);

        const req = new PaymentVoucherCampaignRequest();
        req.name = nameOfCampaign;
        req.numberOfVouchers = +numberOfVouchers;
        req.discount = +discount;
        req.expiryDate = campaignExpiryDate ? (`${campaignExpiryDate.getFullYear()}.${campaignExpiryDate.getMonth() + 1}.${campaignExpiryDate.getDate()}`) : '';
        req.restrictedItemIds = selectedItemRestrictions.map(selectedItem => selectedItem.itemId.toString());
        req.itemAttributeName = itemAttributeName;
        req.itemLifecycleInDays = +itemLifecycleInDays;

        if (metaData != null && metaData.length > 0) {
            try {
                const jMetaData = JSON.parse(metaData); //must be in this format {"key1" : "value1", "key2" : "value2",... , "key_n" : "value_n"}
                req.metaData = jMetaData;
            }
            catch (e) {
                const mssgs = Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }>();
                mssgs.push({ message: getTranslation('UnableToParseMetaData'), alertType: 'error' });
                setMessages(mssgs);
                setAlertType('error');

                setIsCreatingCampaign(false);

                return;
            }
        }
        // req.isDiscountAbsolute = isDiscountAbsolute; Coming soon - leave comment here

        PaymentCampaignService.instance.addVoucherCampaign(req).then(response => {
            const mssgs = Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }>();
            if (isSuccess(response)) {
                setHasFocus('');
                setNameOfCampaign('');
                setNumberOfVouchers('');
                setDiscount('');
                setCampaignExpiryDate(null);
                setEnableValidation(false);
                setSelectedItemRestrictions([]);
                // setIsDiscountAbsolute(false); Coming soon - leave comment here
                props.addNewCampaignToArchive(response as Array<PaymentVoucherCampaignResponse>);
                setIsCreatingCampaign(false);

                mssgs.push({ message: getTranslation('SuccessfullyAddedCampaign'), alertType: 'success' });
                setMessages(mssgs);
                setAlertType('success');

                // Quick & Dirty fix because GenericInput fields don't cleanup values
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.href;
            } else {
                mssgs.push({ message: getTranslation('UnsuccessfullyAddedCampaign'), alertType: 'error' });
                setMessages(mssgs);
                setAlertType('error');
                Logger.log(loggerLocality, `An error ocurred while trying to add a new Voucher Campaign`);
                setIsCreatingCampaign(false);
            }
        });
    }

    const onSelectionChanged = (selectedValues: Array<RestrictionItems>): void => {
        setSelectedItemRestrictions(selectedValues);
    }

    const filterData = (e: MultiSelectFilterChangeEvent): void => {
        const i = restrictionItems;
        setFilteredRestrictionItems(filterBy(i, e.filter));
    }

    return (
        <>
            <div className="campaign-overview__container">
                <div className="overview__container--col-left">
                    {/* Name of the Campaign */}
                    <div role="cell" className="l-box__input l-box__input--edit">
                        <GenericInput
                            key={'CampaignEditForm_NameCampaign'}
                            type={'text'}
                            id={'CampaignEditForm_NameCampaign'}
                            label={getTranslation('CampaignTitle')}
                            value={nameOfCampaign}
                            // placeholder={nameOfCampaign}
                            isReadOnly={false}
                            isRequired={true}
                            hasFocus={hasFocus === 'CampaignEditForm_NameCampaign'}
                            editMode={true}
                            initialValidation={enableValidation}
                            fieldIndex={1}
                            onTextChange={(id, value) => {
                                nameOfCampaign != value && setHasFocus('CampaignEditForm_NameCampaign');
                                setEnableValidation(true);
                                setNameOfCampaign(value);
                            }}
                        />
                    </div>
                    {/* Number of Vouchers */}
                    <div role="cell" className="l-box__input l-box__input--edit">
                        <GenericInput
                            key={'CampaignEditForm_NumberOfVouchers'}
                            type={'number'}
                            id={'CampaignEditForm_NumberOfVouchers'}
                            label={getTranslation('NumberOfVouchers')}
                            value={numberOfVouchers}
                            isReadOnly={false}
                            isRequired={true}
                            hasFocus={hasFocus === 'CampaignEditForm_NumberOfVouchers'}
                            editMode={true}
                            initialValidation={enableValidation}
                            fieldIndex={2}
                            onTextChange={(id, value) => {
                                numberOfVouchers != value && setHasFocus('CampaignEditForm_NumberOfVouchers');
                                setEnableValidation(true);
                                setNumberOfVouchers(value);
                            }}
                            regExpression={/^[1-9]\d*$/}
                        />
                    </div>
                    {/* Discount % */}
                    <div role="cell" className="l-box__input l-box__input--edit">
                        <GenericInput
                            key={'CampaignEditForm_Discount'}
                            type={'number'}
                            id={'CampaignEditForm_Discount'}
                            label={getTranslation('Discount') + ' (%)'}
                            value={discount}
                            isReadOnly={false}
                            isRequired={true}
                            hasFocus={hasFocus === 'CampaignEditForm_Discount'}
                            editMode={true}
                            initialValidation={enableValidation}
                            fieldIndex={3}
                            onTextChange={(id, value) => {
                                discount != value && setHasFocus('CampaignEditForm_Discount');
                                setEnableValidation(true);
                                setDiscount(value);
                            }}
                            regExpression={/^[1-9]\d*$/}
                        />
                        {/* Coming soon */}
                        {/* <Checkbox 
                            id={'CampaignIsDiscountAbsolute'}
                            value={isDiscountAbsolute} 
                            onChange={() => setIsDiscountAbsolute(!isDiscountAbsolute)}
                            label={getTranslation('CampaignIsDiscountAbsolute')}
                            /> */}
                    </div>
                    <LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
                        <IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
                            {/* Expiry Date field */}
                            <div role="cell" className="l-box__input l-box__input--edit">
                                <GenericInput
                                    key={'CampaignEditForm_ExpireDate'}
                                    type={'date'}
                                    id={'CampaignEditForm_ExpireDate'}
                                    class={'expireDate_booking'}
                                    label={getTranslation('ExpiryDate')}
                                    value={campaignExpiryDate?.toDateString() || ''}
                                    isReadOnly={false}
                                    isRequired={true}
                                    hasFocus={false}
                                    editMode={true}
                                    initialValidation={enableValidation}
                                    fieldIndex={3}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    onTextChange={(id, value) => {
                                        if (value) {
                                            discount != value && setHasFocus('CampaignEditForm_ExpireDate');
                                            setEnableValidation(true);
                                            setCampaignExpiryDate(new Date(value));
                                        } else {
                                            setCampaignExpiryDate(null);
                                        }
                                    }}
                                />
                            </div>
                        </IntlProvider>
                    </LocalizationProvider>
                </div>
                <div className="overview__container--col-right">
                    <LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
                        {/* Restrict to course */}
                        <div role="cell" className="l-box__input l-box__input--edit">
                            <div className="l-box--short l-box__input l-box__input--edit">
                                <label id="CampaignEditForm_SetExpiryDate" className="input-label">
                                    <Translate>{`${TRANSLATION_PREFIX}:RestrictToCourseLabel`}</Translate>
                                </label>
                                <MultiSelect
                                    className="user-subscriptions__settings__value__multi-select"
                                    itemRender={MultiSelectCheckboxItemRender}
                                    data={filteredRestrictionItems}
                                    onChange={(e) => {
                                        onSelectionChanged(e.target.value as RestrictionItems[]);
                                        setHasFocus('');
                                    }}
                                    filterable={true}
                                    onFilterChange={(e) => { filterData(e) }}
                                    value={selectedItemRestrictions}
                                    textField="itemTitle"
                                    dataItemKey="itemId"
                                />
                                <span className="input-message error"></span> {/*as it is with genericinput, too*/}
                            </div>
                        </div>
                    </LocalizationProvider>
                    <div role="cell" className="l-box__input l-box__input--edit">
                        <GenericInput
                            key={'CampaignEditForm_ItemLifecycleInDays'}
                            type={'number'}
                            id={'CampaignEditForm_ItemLifecycleInDays'}
                            label={getTranslation("ItemLifecycleInDays")}
                            value={numberOfVouchers}
                            isReadOnly={false}
                            isRequired={false}
                            hasFocus={hasFocus === 'CampaignEditForm_ItemLifecycleInDays'}
                            editMode={true}
                            initialValidation={enableValidation}
                            fieldIndex={4}
                            onTextChange={(id, value) => {
                                itemLifecycleInDays != value && setHasFocus('CampaignEditForm_ItemLifecycleInDays');
                                setEnableValidation(true);
                                setItemLifecycleInDays(value);
                            }}
                            regExpression={/^[1-9]\d*$/}
                        />
                    </div>
                    <div role="cell" className="l-box__input l-box__input--edit">
                        <GenericInput
                            key={'CampaignEditForm_ItemAttributeName'}
                            type={'text'}
                            id={'CampaignEditForm_ItemAttributeName'}
                            label={getTranslation('ItemAttributeName')}
                            value={itemAttributeName}
                            isReadOnly={false}
                            isRequired={false}
                            hasFocus={hasFocus === 'CampaignEditForm_ItemAttributeName'}
                            editMode={true}
                            initialValidation={enableValidation}
                            fieldIndex={5}
                            onTextChange={(id, value) => {
                                itemAttributeName != value && setHasFocus('CampaignEditForm_ItemAttributeName');
                                setEnableValidation(true);
                                setItemAttributeName(value);
                            }}
                        />
                    </div>
                    <div role="cell" className="l-box__input l-box__input--edit">
                        <GenericInput
                            key={'CampaignEditForm_MetaData'}
                            type={'textarea'}
                            id={'CampaignEditForm_MetaData'}
                            label={getTranslation('MetaData')}
                            placeholder={"{\"key1\": \"value1\", \"key2\": \"value2\", \"key_n\": \"value_n\"}"}
                            value={metaData}
                            isReadOnly={false}
                            isRequired={false}
                            hasFocus={hasFocus === 'CampaignEditForm_MetaData'}
                            editMode={true}
                            initialValidation={enableValidation}
                            fieldIndex={6}
                            onTextChange={(id, value) => {
                                metaData != value && setHasFocus('CampaignEditForm_MetaData');
                                setEnableValidation(true);
                                setMetaData(value);
                            }}
                        />
                    </div>
                </div>
            </div>

            {
                !isCreatingCampaign &&
                <GTButton
                    onClick={() => onCreateClicked()}
                    defaultButtonProps={{ disabled: !isCreateButtonEnabled }} >
                    <Translate>{`${TRANSLATION_PREFIX}:Create`}</Translate>
                </GTButton>
            }
            {
                isCreatingCampaign && <ProgressSpinner />
            }
            {messages &&
                <Alert alertType={alertType} alertAppereance={'box'} messages={messages} />
            }

        </>
    )
}