import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import Logger from '$src/core/Logger';
import { Attribute } from '$storage/models/Attribute';
import { EListingType, EValueType } from '$storage/models/enums';
import { StringHelper } from '$util/StringHelper';
import React from 'react';
import loadClipster, { playVideo } from '$src/util/Cliplister';
import ResizeEventListener from '$src/util/EventListener/ResizeEventListener';
import { EBreakPoint } from '$src/storage/models/enums';
import { BrowserHelper } from '$util/BrowserHelper';

interface IState {
    doUpdate: number;
    cliplisterLoaded: boolean;
    currentBreakPoint: EBreakPoint;
}

interface IProps {
    listingType: EListingType;
    attribut: Attribute[];
    titleIsVisible: boolean;
    parentHeadingLevel: number;
    headingCssClass: string;
    enableHTMLFormatter: boolean;
    attributeCssClassName: string;
    /*Warning: SingleAttribute: `key` is not a prop. 
    Trying to access it will result in `undefined` being returned. 
    If you need to access the same value within the child component,
    you should pass it as a different prop. (https://fb.me/react-special-props)*/
    key: string;
}

export class SingleAttribute extends React.Component<IProps, IState> {
    protected className = 'SingleAttribute';
    protected loggerLocality = 'Components.SingleAttribute';

    private _isMounted = false;

    constructor(props: IProps) {
        super(props);
        this.state = { doUpdate: 0, cliplisterLoaded: false, currentBreakPoint: EBreakPoint.Undefined }
        this.onResize = this.onResize.bind(this);
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;

        this._isMounted = true;
        const attributImagesOrVideos = this.props.attribut.filter(a => a.valueType === EValueType.Image);

        Logger.log(this.loggerLocality, `${methodName}`);
        if (attributImagesOrVideos != null && attributImagesOrVideos.length > 0) {
            await Promise.all(attributImagesOrVideos.map((attr) => {
                Logger.log(this.loggerLocality, `${methodName}: this.state.doUpdate + 1`);
                attr.url = attr.getMediaStreamUrl();
                if (this._isMounted) {
                    this.setState({ doUpdate: this.state.doUpdate + 1 });
                }

            }));

        }
        if (this.props.attribut[0].name === 'MyCliplisterVideo') {
            loadClipster(() => { this.setState({ cliplisterLoaded: true }) });
            ResizeEventListener.instance.subscriberHandler.subscribe(this.onResize, this);
        }
    }

    public componentWillUnmount() {
        this._isMounted = false;
        if (this.state.cliplisterLoaded) {
            ResizeEventListener.instance.subscriberHandler.unsubscribe(this.onResize, this);
        }
    }

    public onResize() {
        if (this.state.cliplisterLoaded && this.state.currentBreakPoint != BrowserHelper.getCurrentBreakPoint()) {
            // Rerender if it is a cliplister Attribute and the breakpoint size has changed
            this.setState({ currentBreakPoint: BrowserHelper.getCurrentBreakPoint() });
        }
    }

    public render() {
        const attribut = this.props.attribut;

        if (attribut.length >= 1) {
            let heading: JSX.Element | null = null;
            let attributeData: JSX.Element | JSX.Element[] | null = null;

            const attributImagesOrVideos = attribut.filter(a => a.valueType === EValueType.Image);
            const externalVideoLink = attribut.filter(a => a.valueType == EValueType.ExternalVideoLink);

            heading = this.props.titleIsVisible && attributImagesOrVideos.length === 0
                ? this.renderHeading(attribut[0].labelKey,
                    this.props.headingCssClass,
                    this.props.parentHeadingLevel) : null;

            if (attributImagesOrVideos != null && attributImagesOrVideos.length > 0) {
                attributeData = this.renderImageOrVideo(attributImagesOrVideos);
            }
            else if (externalVideoLink != null && externalVideoLink.length > 0) {
                attributeData = this.renderExternalVideo(attribut);
            }
            else {
                attributeData = this.renderAttributeData(attribut, this.props.enableHTMLFormatter, this.props.listingType);
            }

            return (
                <div className={`${this.props.attributeCssClassName}`}>
                    {heading}
                    {attributeData}

                </div>);

        } else {
            return null;
        }
    }

    protected renderHeading(attributeKey: string, cssClass: string, parentHeadingLevel: number): JSX.Element | null {
        let titleLabel = '';
        titleLabel = 'attributeMetadata:' + attributeKey;
        return <Heading cssClass={cssClass} headingLevel={parentHeadingLevel + 1}>
            <Translate>{titleLabel}</Translate>
        </Heading>;
    }

    // Render embedded external videos
    protected renderExternalVideo(attributes: Attribute[]): JSX.Element | JSX.Element[] {
        const elements: JSX.Element[] = [];
        attributes.map((attr, index) => {
            switch (attr.name) {
                case ("YoutubeLink"): //Youtube
                    elements.push(
                        <div key={index}>
                            <iframe
                                className="attributes-embedded-video_frame"
                                src={attr.value.replace("watch?v=", "embed/")}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>
                            </iframe>
                        </div>
                    )
                    break;
                case ("MyCliplisterVideo"): //MyCliplister            
                    this.state.cliplisterLoaded && elements.push(
                        <>
                            <div className="attributes-embedded-video_frame">
                                <div id="mycliplister">
                                </div >
                            </div>
                            {this.state.cliplisterLoaded && playVideo('mycliplister', () => { null }, attr.value)}
                        </>
                    );
                    break;
            }
        });
        return elements;
    }

    protected renderAttributeData(attributes: Attribute[], htmlFormatterEnabled: boolean, listingType: EListingType): JSX.Element | JSX.Element[] {
        switch (listingType) {
            case EListingType.NumberedList:
                return (
                    <ol>
                        {attributes.map((attr, index) => {
                            return this.renderListItem(attr, htmlFormatterEnabled, index);
                        })}
                    </ol>
                )
            case EListingType.PointList:
                return (
                    <ul>
                        {attributes.map((attr, index) => {
                            return this.renderListItem(attr, htmlFormatterEnabled, index);
                        })}
                    </ul>
                )
            default:
                return (
                    attributes.map((attr, index) => {
                        return this.renderItems(attr, htmlFormatterEnabled, index)
                    }));
        }
    }

    protected renderListItem(attr: Attribute, htmlFormatterEnabled: boolean, attributeIndex: number) {
        return <li key={`${attr.id}-${attributeIndex}`}><span dangerouslySetInnerHTML={
            htmlFormatterEnabled ?
                { __html: StringHelper.htmlTextFormat(attr.value) } :
                { __html: attr.value }
        } /></li>;
    }

    protected renderItems(attr: Attribute, htmlFormatterEnabled: boolean, attributeIndex: number) {
        return <div key={`${attr.id}-${attributeIndex}`}>
            {htmlFormatterEnabled ?
                <span dangerouslySetInnerHTML={{ __html: StringHelper.htmlFormat(attr.value) }} /> :
                <span dangerouslySetInnerHTML={{ __html: attr.value }} />
            }
        </div>
    }

    protected renderImageOrVideo(attr: Attribute[]): JSX.Element[] {
        const imgVidDisplay = attr.map(a => {
            return (
                <div key={a.id} className={`${this.props.attributeCssClassName} ${this.props.attributeCssClassName}--image`}>
                    {this.renderImageOrVid(a)}
                </div>
            );
        });
        return imgVidDisplay;
    }

    protected renderImageOrVid(attr: Attribute): JSX.Element {
        if (globalConfig.itemProperties.maxAttributeImageWidth > 0) {
            return attr.value.startsWith('data:video') ? <div
                key={attr.id}
                className="description-content-image__container">
                <video
                    src={attr.url}
                    controls={true}
                    className="description-content-image description-content-image--fixed"
                    style={{
                        maxWidth: `${globalConfig.itemProperties.maxAttributeImageWidth}px`
                    }} />
            </div>
                : <div
                    key={attr.id}
                    className="description-content-image__container">
                    <img
                        src={attr.url == null ? attr.value : attr.url} // backward compatibility
                        alt={attr.name}
                        className="description-content-image description-content-image--fixed"
                        style={{
                            maxWidth: `${globalConfig.itemProperties.maxAttributeImageWidth}px`
                        }} />
                </div>
        }
        else {
            return attr.value.startsWith('data:video') ? <video
                src={attr.url}
                controls={true}
                className="description-content-image"
                key={attr.id}
            /> : <img
                src={attr.url == null ? attr.value : attr.url} // backward compatibility
                alt={attr.name}
                className="description-content-image"
                key={attr.id}
            />
        }
    }
}