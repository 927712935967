import React from "react";
import { Translate } from "$components/shared/Translate";

interface IProps {
    withOr?: boolean;
}

export default function ThematicBreak(props: IProps) {

    return (
        <>
            {props.withOr ?
                <div className="thematic-break" >
                    <hr />
                    {props.withOr && <div className="thematic-break--or"><Translate>ThematicBreak:Or</Translate></div>}
                    <hr />
                </div> :
                <div className="thematic-break" >
                    <hr />
                </div>
            }
        </>
    );

}