import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserInterests {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('idUser', Number, false)
    public idUser: number = 0;

    @JsonProperty('idAttributeValue', Number, false)
    public idAttributeValue: number = 0;

    @JsonProperty('orderNumber', Number, false)
    public orderNumber: number = 0;

    @JsonProperty('idAttribute', Number, false)
    public idAttribute: number = 0;

    @JsonProperty('attributeValueText', String, false)
    public attributeValueText: string = '';
}