import React from 'react';
import { Route } from 'react-router-dom';

import { AllAssignments, IAllAssignmentsProps } from '$src/components/assignment/AllAssignments';
import { Catalog } from '$src/components/catalog/Catalog';
import { ColorSchemeSelection } from '$src/components/colorSchemeSelection/ColorSchemeSelection';
import { Contact } from '$src/components/contact/Contact';
import { RequestWizard } from '$src/components/externalCourse/RequestWizard';
import { ExternalNavigationPage } from '$src/components/externalPage/ExternalNavigationPage';
import { Favorites } from '$src/components/favorites/Favorites';
import { MyFileSharings } from '$src/components/fileSharing/MyFileSharings';
import { GlossaryList } from '$src/components/Glossary/GlossaryList';
import { Aui } from '$src/components/gtApplicationSwitch/Aui';
import { LmsSui } from '$src/components/gtApplicationSwitch/LmsSui';
import { HelpPage } from '$src/components/Help/Help';
import { Home } from '$src/components/Home';
import { Info } from '$src/components/info/Info';
import { F2FRegistration } from '$src/components/item/F2F/F2FRegistration';
import { ItemDetail } from '$src/components/item/ItemDetail';
import App from '$src/components/layout/App';
import { ExtendedMenu } from '$src/components/layout/menu/ExtendedMenu';
import { LicenseSelfReg } from '$src/components/licenseReg/LicenseSelfReg';
import { LicenseSelfRegCheck } from '$src/components/licenseReg/LicenseSelfRegCheck';
import { LicenseRegLogin } from '$src/components/login/LicenseRegLogin';
import { PasswordChange } from '$src/components/login/PasswordChange';
import { Logout } from '$src/components/logout/logout';
import { CourseRequests } from '$src/components/myEmployees/CourseRequests';
import { EmployeeDetail } from '$src/components/myEmployees/employee/EmployeeDetail';
import { MyTeam } from '$src/components/myEmployees/MyTeam';
import RequestDetailRoutePage from '$src/components/myEmployees/RequestDetailRoutePage';
import LessonStatusImportOffline from '$src/components/offlineAdmin/LessonStatusImportOffline';
import { PerformanceReviewDetail } from '$src/components/performanceReview/PerformanceReviewDetail';
import { AllRegisteredItems } from '$src/components/registeredItem/AllRegisteredItems';
import { ReportExternalCourseRegistration } from '$src/components/reporting/ReportExternalCourseRegistration';
import { ReportingPage } from '$src/components/reporting/ReportingPage';
import { ReportTutoring } from '$src/components/reporting/tutor/ReportTutoring';
import { TrainerBookingView } from '$src/components/Trainer/TrainerBookingView';
import { LegacySearch } from '$src/components/search/legacy/LegacySearch';
import { SelfRegistration } from '$src/components/selfRegistration/SelfRegistration';
import { CallbackUrlPage } from '$src/components/shared/CallbackUrl';
import { MySkills } from '$src/components/userProfile/MySkills';
import { TodoList } from '$src/components/TodoList/TodoList';
import { VirtualMeetingLinks } from '$src/components/shared/VirtualMeetings';
import { TrainingPlanDetail } from '$src/components/trainingPlan/TrainingPlanDetail';
import { TrainingPlanRegistration } from '$src/components/trainingPlan/TrainingPlanRegistration';
import { MyCertificates } from '$src/components/userProfile/MyCertificates';
import { MyLessons } from '$src/components/userProfile/MyLessons';
import { MyPerformanceReview } from '$src/components/userProfile/MyPerformanceReview';
import { UserProfile } from '$src/components/userProfile/UserProfile';
import { WatchedItems } from '$src/components/Watchlist/WatchedItems';
import UserPreferencesService from '$src/core/Services/UserPreferencesService';
import Session from '$src/core/Session';
import Trainings from '$src/components/shared/Trainings';
import { MyPerformanceSupport } from '$src/components/userProfile/MyPerformanceSupport';
import PowerBiReports from '$src/components/powerBiReports/PowerBiReports';
import MyPrivacy from '$src/components/privacy/MyPrivacy';
import ForbiddenPage from '$src/components/layout/ForbiddenPage';
import MyRecommendationsByInterestsPage from '$src/components/myRecommendations/MyRecommendationsByInterestsPage';
import UserSubjectSubscriptionItems from '$src/components/userSubjectSubscriptions/UserSubjectSubscriptionItems';
import MySubjectSubscriptionsEditView from '$src/components/userSubjectSubscriptions/MySubjectSubscriptionsEditView';
import ShoppingBasketContent from '$src/components/shoppingBasket/ShoppingBasketContent';
import SearchPage from '$src/components/search/elk/SearchPage';
import { BookingOverview } from '$src/components/myEmployees/BookingOverview';
import RequirementsOverview from '$src/components/myEmployees/RequirementsOverview';
import MyLessonSurveys from '$src/components/lessonsurveys/LessonSurveys';
import VoucherValidationPage from '$src/components/shoppingBasket/VoucherValidationPage';
import UserRegistration from '$src/components/selfRegistration/UserRegistration';
import PaymentBookingOverview from '$src/components/paymentBookingOverview/PaymentBookingOverview';
import AwaitCheckoutCompletion from '$src/components/shoppingBasket/AwaitCheckoutCompletion';
import PurchasedItemsList from '$src/components/shoppingBasket/PurchasedItemsList';
import MyPerformanceCheck from '$src/components/userProfile/MyPerformanceCheck';
import PaymentCampaignOverview from '$src/components/voucherAdministration/PaymentCampaignOverview';
import F2FParticipantListTutor from '$src/components/item/F2F/F2FParticipantListTutor';
import MyPerformanceCheckSkillRequest from '$src/components/userProfile/MyPerformanceCheckSkillRequest';
import PerformanceCheckRequest from '$src/components/myEmployees/PerformanceCheckRequest';
import CloseCurrentTab from '$src/components/shoppingBasket/CloseCurrentTab';
import Glossar from '$src/components/glossary/GlossarDetail';
import GlossaryTermShow from '$src/components/glossary/GlossaryTermShow';
import { GamificationPointHistoryPage } from '$src/components/gamification/GamificationPointHistoryPage';
import PaymentBooking from '$src/components/paymentBooking/PaymentBooking';
import { ValidateUserCertificatePage } from '$src/components/validateUserCertificate/ValidateUserCertificatePage';
import PaymentBookingJournal from '$src/components/paymentBookingJournal/PaymentBookingJournal';
import CheckoutItemsStatus from '$src/components/shoppingBasket/CheckoutItemsStatus';
import ConfigurationPage from '$src/components/configuration/ConfigurationPage';
import MailTo from '$src/components/userProfile/MailTo';
import InitialCodeCheck from '$src/components/multiFactorAuth/InitialCodeCheck';
import EvaluationOfSkills from '$src/components/reporting/EvaluationOfSkills';
import EvaluationOfTplans from '$src/components/reporting/EvaluationOfTplans';

// Important, if you add a new component to routes, in this component add className="contentContainer" to the div 
// for public pages, set IsExternal to true, to display sticky container
export const routes = <App>
    <Route path="/pwchange" component={PasswordChange} IsPublic={true} />
    <Route path="/CallbackUrl" component={CallbackUrlPage} IsPublic={true} />
    <Route path="/licenseRegLogin" component={LicenseRegLogin} IsPublic={true} IsExternal={true} />
    <Route path="/selfRegistration" component={SelfRegistration} IsPublic={true} IsExternal={true} />
    <Route path="/userRegistration/:bossRelationCode" component={UserRegistration} IsPublic={false} />
    <Route path="/licenseSelfRegCheck" component={LicenseSelfRegCheck} IsPublic={true} IsExternal={true} />
    <Route path="/licenseSelfReg" component={LicenseSelfReg} IsPublic={true} IsExternal={true} />
    <Route path="/initialCodeCheck" component={InitialCodeCheck} IsPublic={true} IsExternal={true} />

    <Route exact={true} path="/" component={Home} IsPublic={false} />
    <Route path="/userProfile" component={UserProfile} IsPublic={false} />
    <Route path="/catalog/:folderId" component={(props: any) => <Catalog {...props} />} IsPublic={false} />
    <Route path="/contact" component={Contact} IsPublic={false} />
    <Route path="/myPrivacy" component={MyPrivacy} IsPublic={false} />
    <Route path="/legacy/search" component={LegacySearch} IsPublic={false} />
    <Route path="/search" component={SearchPage} IsPublic={false}></Route>
    <Route path="/help" component={HelpPage} IsPublic={true} IsExternal={true} />
    <Route path="/favorites" component={Favorites} IsPublic={false} />
    <Route path="/watcheditems" component={WatchedItems} IsPublic={false} />
    <Route path="/logout" component={Logout} IsPublic={false} />
    <Route path="/assignments"
        component={(props: IAllAssignmentsProps) => <AllAssignments
            {...props}
            maxItemsToDisplay={0}
            title={'Assignments:Title'}
            filterExpression={(a) => Session.instance.storage.assignment.filterRequiredAssignments(a)}
            showSortOrderButton={globalConfig.assignmentProperties.showSortButton}
        />}
        IsPublic={false} />
    <Route path="/recommendedTrainings"
        component={(props: IAllAssignmentsProps) => <AllAssignments
            {...props}
            maxItemsToDisplay={0}
            title={'RecommendedTrainings:Title'}
            filterExpression={(a) => Session.instance.storage.assignment.filterRecommendedAssignments(a)}
        />}
        IsPublic={false} />
    <Route path="/mytrainings" component={AllRegisteredItems} IsPublic={false} />
    <Route path="/myregistrations" component={AllRegisteredItems} IsPublic={false} />
    <Route path="/itemDetail/:lessonId" component={(props: any) => <ItemDetail {...props} />} IsPublic={false} />
    <Route path="/info" component={Info} IsPublic={false} />
    <Route path="/externalPage/:pageId" component={(props: any) => <ExternalNavigationPage {...props} />} IsPublic={false} />
    <Route path="/myCertificates" component={MyCertificates} IsPublic={false} />

    <Route path="/myteam/:bossRelationCode" component={MyTeam} IsPublic={false} />
    <Route path="/employee/:bossRelationCode/:employeeId" component={EmployeeDetail} IsPublic={false} />
    <Route path="/courseRequests" component={CourseRequests} IsPublic={false} />
    <Route path="/performanceCheckRequest/:bossRelationCode" component={PerformanceCheckRequest} IsPublic={false} />
    <Route path="/bookingOverview/:bossRelationCode" component={BookingOverview} IsPublic={false} />
    <Route path="/requirementsOverview/:bossRelationCode" component={RequirementsOverview} IsPublic={false} />
    <Route path="/myLessons" component={(props: any) => <MyLessons {...props} componentId="myLessons" />} IsPublic={false} />
    <Route path="/myTplans" component={(props: any) => <MyLessons {...props} componentId="myTplans" />} IsPublic={false} />
    <Route path="/myCourses" component={(props: any) => <MyLessons {...props} componentId="myCourses" courseFilter={true} />} IsPublic={false} />
    <Route path="/todoList" component={TodoList} IsPublic={false} />
    <Route path="/myskills" component={MySkills} IsPublic={false} />
    <Route path="/more" component={ExtendedMenu} IsPublic={false} />
    <Route path="/colorschemeselection" component={(props: any) => <ColorSchemeSelection
        colorSchemes={globalConfig.colorSchemes.colorSchemeDefinitions}
        currentScheme={Session.instance.getLoginUserPreferencesColorScheme()}
        userPreferencesService={UserPreferencesService.instance}
        isPopup={false}
        parentHeadingLevel={0}
        headingCssClassName="heading__Title"
        schemeHeadingCssName="heading__Level2"
        {...props} />} IsPublic={false} />
    <Route path="/trainingPlanDetail/:tplanId" component={(props: any) => <TrainingPlanDetail {...props} />} IsPublic={false} />
    <Route path="/trainingPlanRegistration/:tplanId/:sceId" component={(props: any) => <TrainingPlanRegistration {...props} />} IsPublic={false} />
    <Route path="/f2fRegistration/:itmId/:clsId" component={(props: any) => <F2FRegistration {...props} />} IsPublic={false} />
    <Route path="/requestWizard/:itemRegistrationId" component={(props: any) => <RequestWizard {...props} />} IsPublic={false} />
    <Route path="/requestDetail/:registrationId" component={(props: any) => <RequestDetailRoutePage {...props} />} IsPublic={false} />
    <Route path="/reporting" component={(props: any) => <ReportingPage {...props} />} IsPublic={false} exact={true} />
    <Route path="/reporting/reportExternalCourseRegistration" component={(props: any) => <ReportExternalCourseRegistration {...props} />} IsPublic={false} />
    <Route path="/reporting/reportTutoring" component={(props: any) => <ReportTutoring {...props} />} IsPublic={false} />
    <Route path="/myGlossaries" component={GlossaryList} IsPublic={false} />
    <Route path="/myFileSharing" component={(props: any) => <MyFileSharings {...props} />} IsPublic={false} />
    <Route path="/myVirtualMeetings" component={(props: any) => <VirtualMeetingLinks {...props} />} IsPublic={false} />
    <Route path="/myPerformanceReview" component={MyPerformanceReview} IsPublic={false} />
    <Route path="/myPerformanceSupport" component={MyPerformanceSupport} IsPublic={false} />
    <Route path="/myPerformanceCheck" component={MyPerformanceCheck} IsPublic={false} />
    <Route path="/skillRequest" component={MyPerformanceCheckSkillRequest} IsPublic={false} />
    <Route path="/myrecommendations" component={MyRecommendationsByInterestsPage} IsPublic={false} />
    <Route path="/performanceReviewDetail/:skillId" component={PerformanceReviewDetail} IsPublic={false} />
    <Route path="/Trainer/trainerBookingView" component={(props: any) => <TrainerBookingView {...props} />} IsPublic={false} />
    <Route path="/userSubjectSubscriptions/MySubscriptions/:subjectId/:subjName" component={(props: any) => <UserSubjectSubscriptionItems {...props} />} IsPublic={false} />
    <Route path="/EditMySubjectSubscriptions" component={(props: any) => <MySubjectSubscriptionsEditView {...props} />} IsPublic={false} />

    <Route path="/aui" component={(props: any) => <Aui {...props} />} IsPublic={false} />
    <Route path="/lmssui" component={(props: any) => <LmsSui {...props} />} IsPublic={false} />
    <Route path="/lmssuiembedded" component={(props: any) => <LmsSui {...props} />} IsPublic={false} renderComponentOnly={true} />
    <Route path="/lessonStatusImportOffline" component={(props: any) => <LessonStatusImportOffline {...props} />} IsPublic={false} />
    <Route path="/trainings/:skillIdParam/:userIdParam/:catIdParam/:itemIdParam/:tpIdParam/:asIdParam/:tpeIdParam" component={(props: any) => <Trainings {...props} />} IsPublic={false} />
    <Route path="/PowerBiReports" component={(props: any) => <PowerBiReports {...props} />} IsPublic={false} />
    <Route path="/forbidden" component={ForbiddenPage} />

    <Route path="/lessonsurveys" component={MyLessonSurveys} IsPublic={false}></Route>
    <Route path="/closeCurrentTab" component={CloseCurrentTab} IsPublic={true} /> 
    <Route path="/closeCurrentTab" component={CloseCurrentTab} IsPublic={true} />
    <Route path="/participantListTutor/:classIdParam" component={(props: any) => <F2FParticipantListTutor {...props} />} IsPublic={false} />
    <Route path="/glossar/:glossarIdParam/:context/:itemId/:catId/:asId/:tpId/:tpeId" component={(props: any) => <Glossar {...props} />} IsPublic={false} />
    <Route path="/glossaryTerm/:glossarTermId" component={(props: any) => <GlossaryTermShow {...props} />} IsPublic={false} />
    <Route path="/gamification/" component={(props: any) => <GamificationPointHistoryPage {...props} />} IsPublic={false} />
    <Route path="/certificates/:userId/:userCertificateId/:checksum" component={(props: any) => <ValidateUserCertificatePage {...props}/>} IsPublic={false} />

    <Route path="/shoppingBasketContent" component={(props: any) => <ShoppingBasketContent {...props} />} IsPublic={false} />
    <Route path="/voucherValidation" component={(props: any) => <VoucherValidationPage {...props} />} IsPublic={false} />
    <Route path="/awaitCheckoutCompletion/:referenceCode" component={(props: any) => <AwaitCheckoutCompletion {...props} />} IsPublic={false} />
    <Route path="/purchasedItems/:referenceCode" component={(props: any) => <PurchasedItemsList {...props} />} IsPublic={false} />
    <Route path="/checkoutItemsStatus/:referenceCode" component={(props: any) => <CheckoutItemsStatus {...props} />} IsPublic={false} />
    <Route path="/paymentBookingOverview" component={(props: any) => <PaymentBookingOverview {...props} />} IsPublic={false} />
    <Route path="/paymentBookingJournal" component={(props: any) => <PaymentBookingJournal {...props} />} IsPublic={false} />
    <Route path="/paymentBooking" component={(props: any) => <PaymentBooking {...props} />} IsPublic={false} />
    <Route path="/paymentCampaignOverview" component={(props: any) => <PaymentCampaignOverview {...props} />} IsPublic={false} />
    <Route path="/reloadstrings" component={(props: any) => <ConfigurationPage {...props} />} IsPublic={false} />
    <Route path="/configuration" component={(props: any) => <ConfigurationPage {...props} />} IsPublic={false} />
    <Route path="/mailTo" component={(props: any) => <MailTo {...props} />} IsPublic={false} />
    <Route path="/paymentBookingJournalMyTeam/:bossRelationCode" component={(props: any) => <PaymentBookingJournal {...props} />} IsPublic={false} />
    <Route path="/paymentBookingMyTeam/:bossRelationCode" component={(props: any) => <PaymentBooking {...props} />} IsPublic={false} />
    <Route path="/reportEvaluationOfSkills" component={(props: any) => <EvaluationOfSkills {...props} />} IsPublic={false} />
    <Route path="/reportEvaluationOfSkillsMyteam/:bossRelationCode" component={(props: any) => <EvaluationOfSkills {...props} />} IsPublic={false} />
    <Route path="/reportEvaluationOfTplans" component={(props: any) => <EvaluationOfTplans {...props} />} IsPublic={false} />
    <Route path="/reportEvaluationOfTplansMyTeam/:bossRelationCode" component={(props: any) => <EvaluationOfTplans {...props} />} IsPublic={false} />
</App>;
