import { ExternalCourseRequestRegistration } from '$components/externalCourse/ExternalCourseRequestRegistration';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';
import { CancellationReasonSelection } from '$src/components/shared/CancellationReasonSelection';
import { Heading } from '$src/components/shared/Heading';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { EAttendanceStatus, ECancellationType } from '$src/storage/models/enums';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { NavigationUtil } from '$src/util/NavigationUtil';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';
import DateHelper from '$src/util/DateHelper';

interface IProps extends RouteComponentProps<{}> {
    parentHeadingLevel: number;
    externalCourseRegistration: ExternalCourseRegistration;
    onStepClicked?: (stepNumber: number) => void;
    onSaveSuccess?: () => void;
}

interface IState {
    errorMessage: string;
    hasAttended: EAttendanceStatus;
    cancellationReasonText: string;
    selectedCancellationReasonId: number;
    shouldShowModal: boolean;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class StepAttendance extends React.Component<IProps, IState> {
    protected registrationStep = 5 // step number of registration step defined in RequestWizard.tsx
    protected conclusionStep = 7 // step number of conclusion step defined in RequestWizard.tsx

    constructor(props: IProps) {
        super(props);

        this.state = {
            cancellationReasonText: this.props.externalCourseRegistration.cancellationReason,
            errorMessage: '',
            hasAttended: this.props.externalCourseRegistration.attendedStatus,
            selectedCancellationReasonId: this.props.externalCourseRegistration.cancellationReasonId,
            shouldShowModal: this.props.externalCourseRegistration.attendedStatus === EAttendanceStatus.NotAttended,
        }
    }

    public render() {
        const ariaLabelPreviousStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToRegistration');
        const ariaLabelNextStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToConclusion');
        let saveDisabled = true;
        if (this.state.hasAttended === EAttendanceStatus.Attended
            || (this.state.hasAttended === EAttendanceStatus.NotAttended && this.state.selectedCancellationReasonId > 0 && this.state.cancellationReasonText.length > 0)) {
            saveDisabled = false;
        }
        const editDisabled = this.props.externalCourseRegistration.attendedStatus !== EAttendanceStatus.Undefined;
        const stringMyCourses = Session.instance.storage.translation.GetString(NavigationUtil.getNavigationItemAttributeById('MyCourses', 'text'));
        const setAttendanceAllowed = this.props.externalCourseRegistration.externalCourseClass && DateHelper.CompareDate(new Date(), this.props.externalCourseRegistration.externalCourseClass.lastAppointment) === 1;

        return (
            <div className="step-content">
                <ExternalCourseRequestRegistration
                    externalCourseRegistration={this.props.externalCourseRegistration}
                    parentHeadingLevel={this.props.parentHeadingLevel}
                    isBossView={false}
                />
                <div className="step-content__block">
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                        <Translate>ExternalCourse:ConfirmAttendanceHint</Translate>
                    </Heading>
                </div>

                {setAttendanceAllowed &&
                    <React.Fragment>
                        <div className="step-content__block">
                            <input
                                type="radio"
                                name="attendance"
                                id="optHasAttended"
                                value="hasAttended"
                                onChange={(event) => this.handleOptionChange(event)}
                                checked={this.state.hasAttended === EAttendanceStatus.Attended}
                                disabled={editDisabled} />
                            <label htmlFor="optHasAttended"><Translate>ExternalCourse:HasAttended</Translate></label>
                        </div>
                        <div className="step-content__block">
                            <input
                                type="radio"
                                name="attendance"
                                id="optHasNotAttended"
                                value="hasNotAttended"
                                onChange={(event) => this.handleOptionChange(event)}
                                checked={this.state.hasAttended === EAttendanceStatus.NotAttended}
                                disabled={editDisabled} />
                            <label htmlFor="optHasNotAttended"><Translate>ExternalCourse:HasNotAttended</Translate></label>
                            {this.state.hasAttended === EAttendanceStatus.NotAttended &&
                                <CancellationReasonSelection
                                    itemID={this.props.externalCourseRegistration.itemId}
                                    cancellationReasonTextValue={this.state.cancellationReasonText}
                                    cancellationReasonId={this.state.selectedCancellationReasonId}
                                    onChange={(cancellationReasonId, reason) => this.onCancellationReasonChanged(cancellationReasonId, reason)}
                                    readonly={editDisabled}
                                    cancellationReasonType={ECancellationType.Attendance} />
                            }
                        </div>

                        <div className="step-content__block center">
                            <button
                                className="btn--sm btn--primary"
                                onClick={() => this.onSaveAttendance()}
                                disabled={saveDisabled || editDisabled}
                                id="btnAttendanceSave">
                                <Translate>Button:Save</Translate>
                            </button>
                        </div>
                    </React.Fragment>}

                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={() => this.closeModal()}>
                    <div>
                        <div><Translate>ExternalCourse:NotAttendedHint</Translate></div>
                        <div className="modal__single-button">
                            <button type="button"
                                aria-label={stringMyCourses}
                                className="btn btn--sm"
                                onClick={() => this.onButtonClickToMyCourses()}>
                                {stringMyCourses}
                            </button>
                        </div>
                    </div>
                </ModalPopup>

                {this.state.errorMessage.length > 0 &&
                    <div className="step-content__block center">
                        <span className={'input-message error--dark'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                }

                <div className="step-navigation">
                    <button className="button-link button-link--colorized-dark"
                        onClick={() => this.goToRegistration()}
                        aria-label={ariaLabelPreviousStep}>
                        <div className="inlineFlex">
                            <InlineSVG src={ArrowLeft} />
                            <span className="notMobile"><Translate>ExternalCourse:Registration</Translate></span>
                        </div>
                    </button>
                    <span><Translate>ExternalCourse:Attendance</Translate></span>
                    <button className="button-link button-link--colorized-dark"
                        onClick={() => this.goToConclusion()}
                        aria-label={ariaLabelNextStep}
                        disabled={this.props.externalCourseRegistration.attendedStatus !== EAttendanceStatus.Attended}>
                        {
                            (this.props.externalCourseRegistration.attendedStatus !== EAttendanceStatus.Attended) ? 
                                <div className="inlineFlex external-courses__link__disabled">
                                    <span className="notMobile"><Translate>ExternalCourse:Conclusion</Translate></span>
                                </div>
                             :
                                <div className="inlineFlex">
                                    <span className="notMobile"><Translate>ExternalCourse:Conclusion</Translate></span>
                                    <InlineSVG src={ArrowRight} />
                                </div>
                        }
                    </button>
                </div>
            </div>
        )
    }

    private async onSaveAttendance() {
        const hasAttended = this.state.hasAttended === EAttendanceStatus.Attended ? true : false;
        const response = await ExternalCourseService.instance.saveExternalCourseAttendance(
            this.props.externalCourseRegistration.registrationId,
            hasAttended,
            this.state.selectedCancellationReasonId,
            this.state.cancellationReasonText
        );

        if (isSuccess<BooleanResponse>(response)) {
            if (this.props.onSaveSuccess && response.status) {
                this.props.onSaveSuccess()
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:SaveExternalCourseAttendanceFailed' });
        }
    }

    private goToRegistration() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.registrationStep);
        }
    }

    private goToConclusion() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.conclusionStep);
        }
    }

    private handleOptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        switch (value) {
            case 'hasAttended':
                this.setState({ hasAttended: EAttendanceStatus.Attended, selectedCancellationReasonId: 0, cancellationReasonText: '' });
                break;
            case 'hasNotAttended':
                this.setState({ hasAttended: EAttendanceStatus.NotAttended });
                break;
            default:
                break;
        }
    }

    private onCancellationReasonChanged(cancellationReasonId: number, reason: string) {
        this.setState({ selectedCancellationReasonId: cancellationReasonId, cancellationReasonText: reason });
    }

    private closeModal() {
        this.setState({ shouldShowModal: false });
    }

    private onButtonClickToMyCourses() {
        this.props.history.push('/myCourses');
    }
}