import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject
export class GamificationGroupHighscore {

    @JsonProperty('id', Number, false)
    public groupId: number = 0;

    @JsonProperty('rank', Number, false)
    public rank: number = 0;

    @JsonProperty('points', Number, false)
    public points: number = 0;

    @JsonProperty('groupname', String, false)
    public groupname: string = "";

    @JsonProperty('groupInfo', String, false)
    public groupInfo: string = "";

    @JsonProperty('isMyGroup', Boolean, false)
    public isMyGroup: boolean = false;

}