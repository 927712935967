export const skillTitle = 'SkillTitle';
export const skillLevel = 'SkillLevel';
export const skillRequiredLevel = 'SkillRequiredLevel';
export const skillAquired = 'SkillAquired';
export const skillExpired = 'SkillExpired';
export const skillStatus = 'SkillStatus';

export class SkillModel {
    public columnDictionary: { [key: number]: string } = {};

    constructor() {
        this.columnDictionary[0] = skillTitle
        this.columnDictionary[1] = skillRequiredLevel
        this.columnDictionary[2] = skillLevel
        this.columnDictionary[3] = skillAquired
        this.columnDictionary[4] = skillExpired
        this.columnDictionary[5] = skillStatus
    }

} 
