import Session from "$src/core/Session";
import React from "react";
import { useHistory } from "react-router";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { Heading } from "$src/components/shared/Heading";
import { ModalPopup } from "$src/components/shared/ModalPopup";
import { Translate } from "$src/components/shared/Translate";

interface IProps {
    showModal: boolean;
    closeModal: () => void;
}
/**
 * Shows the pop up when user added item to shopping basket
 */
export default function ShoppingBasketPopUp(props: IProps) {
    const history = useHistory();
    
    function openShoppingBasket() {
        history.push('/shoppingBasketContent');
    }

    return (
        <>
            {
                globalConfig.shoppingProperties.showPopUp && <ModalPopup isOpen={props.showModal} onRequestClose={() => props.closeModal()}>
                    <div>
                        <div className="title">
                            <Heading headingLevel={1} cssClass="heading__Title"><Translate>ShoppingBasketPopUp:Title</Translate></Heading>
                            <div>
                                <Translate>ShoppingBasketPopUp:SubTitle</Translate>
                            </div>
                        </div>
                        <div className="modal__spread-buttons">
                            <GTButton onClick={() => props.closeModal()}
                                ariaLabel={Session.instance.storage.translation.GetString('ShoppingBasketPopUp:ContinueShopping')}>
                                <Translate>ShoppingBasketPopUp:ContinueShopping</Translate>
                            </GTButton>
                            <GTButton onClick={() => openShoppingBasket()}
                                ariaLabel={Session.instance.storage.translation.GetString('ShoppingBasketPopUp:ToShoppingBasket')}
                                isPrimary={false}>
                                <Translate>ShoppingBasketPopUp:ToShoppingBasket</Translate>
                            </GTButton>
                        </div>
                    </div>
                </ModalPopup>
            }
        </>
    )
}

