import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { GridDetailRowProps } from '@progress/kendo-react-grid';

import { PaymentBookingOverviewEntry, PaymentBookingOverviewResponseTuple } from '$src/storage/models/Purchase/PaymentBookingOverviewResponse';
import { StringHelper } from '$src/util/StringHelper';
import CheckedSvg from '$src/resources/svgs/misc/success.svg';
import FailedSvg from '$src/resources/svgs/misc/fail.svg';

import { Translate } from '$components/shared/Translate';

/**
 * Kendo grid details row containing any booking information
 * @param props GridDetailRowProps from kendo. props.dataItem must be of type PaymentBookingOverviewEntry
 * @returns payment-booking-overview--detail-row
 */
export default function PaymentBookingOverviewDetailsRow(props: GridDetailRowProps) {
    if (!props || !props.dataItem) {
        return null;
    }

    const dataItem = props.dataItem as PaymentBookingOverviewEntry;
    return (
        <section className="payment-booking-overview--detail-row">
            <table>
                <tbody>
                    <tr>
                        <td><Translate>PaymentBookingOverview:BookingDate</Translate></td>
                        <td>{props.dataItem.bookingDate ? StringHelper.dateTimeString(props.dataItem.bookingDate) : ''}</td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:PaymentStatus</Translate></td>
                        <td><Translate>{props.dataItem.paymentStatus}</Translate></td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:PaymentCompleted</Translate></td>
                        <td>
                            {props.dataItem.paymentCompleted ?
                                <InlineSVG src={CheckedSvg} /> :
                                <InlineSVG src={FailedSvg} />}
                        </td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:BookingReference</Translate></td>
                        <td>{dataItem.bookingReference}</td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:PaymentReference</Translate></td>
                        <td>{dataItem.paymentReference}</td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:TotalPriceAndCurrency</Translate></td>
                        <td>{dataItem.totalPrice} {dataItem.currency}</td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:PaymentTypes</Translate></td>
                        <td><Translate>{dataItem.paymentTypes}</Translate> {dataItem.paymentTypes.includes("Voucher") && <>({dataItem.voucherCode} / {dataItem.campaignTitle})</>}</td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:Lessons</Translate></td>
                        <td>
                            {
                                props.dataItem.bookedLessons &&
                                props.dataItem.bookedLessons.map((lesson: PaymentBookingOverviewResponseTuple) => {
                                    return <div key={dataItem.bookingReference + lesson.id}>{lesson.name} ({lesson.id})</div>
                                })
                            }
                        </td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:VoucherRefund</Translate></td>
                        <td>{dataItem.voucherRefund}</td>
                    </tr>
                    <tr>
                        <td><Translate>PaymentBookingOverview:Purchaser</Translate></td>
                        <td>{dataItem.purchaser.id} ({dataItem.purchaser.name})</td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}