import React from 'react';
import { UnmountClosed } from 'react-collapse';
import { RouteComponentProps } from 'react-router';

import { ItemBreadCrumb } from '$components/breadCrumb/ItemBreadCrumb';
import  RecommendationContainer  from '$components/digitalCoach/RecommendationContainer';
import { Toolbar } from '$components/item/Toolbar/Toolbar';
import { ToolbarRemainingTime } from '$components/item/Toolbar/ToolbarRemainingTime';
import { TopAnchorToolbar } from '$components/item/Toolbar/TopAnchorToolbar';
import AttributeList from '$components/shared/Attributes/AttributeList';
import { SummaryAttribute } from '$components/shared/Attributes/SummaryAttribute';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { TrainingPlanElementsContainer } from '$components/trainingPlan//trainingPlanElements/TrainingPlanElementsContainer';
import { TrainingPlanOutputSkills } from '$components/trainingPlan//TrainingPlanOutputSkills';
import TrainingPlanPrerequisites from '$components/trainingPlan//TrainingPlanPrerequisites';
import { TrainingPlanScheduleList } from '$components/trainingPlan/trainingPlanSchedules/TrainingPlanScheduleList';
import Logger from '$core/Logger';
import GlobalDescriptionService from '$core/Services/GlobalDescriptionService';
import ItemService from '$core/Services/ItemService';
import TrainingPlanService from '$core/Services/TrainingPlanService';
import Session from '$core/Session';
import { Attribute } from '$src/storage/models/Attribute';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { EFavoriteType, EItemDetailCallerContextType, EItemDetailCallerType, ERegistrationStatus } from '$storage/models/enums';
import { GlobalDescription } from '$storage/models/GlobalDescription';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import { TrainingPlanSchedule } from '$storage/models/TrainingPlan/TrainingPlanSchedule';
import { isSuccess } from '$util/Result';
import { StringHelper } from '$util/StringHelper';
import GlossaryList from '$components/Glossary/GlossaryList';
import TrainingPlanCompletitionRequirements, { ICompletitionRequirements } from '$components/trainingPlan/TrainingPlanCompletitionRequirements';
import { ItemRatingDetails } from '$components/itemRating/ItemRatingDetails';
import { Item } from '$src/storage/models/Item';
import { ItemMentorList } from '$components/item/ItemMentorList';
import { TrainingPlanElementsContentsV2 } from '$components/trainingPlan//TrainingPlanElementsV2/TrainingPlanElementsContentsV2';
import { GlossarIdForBredCrumb } from '$src/storage/models/Glossary/GlossarIdForBreadCrumb';
import { getEstimateDurationText } from '$src/util/TrainingPlanHelper';
import { CollaborationProviderItemPanel } from '$components/collaborationProvider/item/CollaborationProviderItemPanel';


interface IMatchParams {
    tplanId: string;
}

type IProps = RouteComponentProps<IMatchParams>

interface IURLParamState {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    returnFromItemId: number;
    voucherCode: string | null;
}

interface IURLPerformanceState {
    userId?: number;
    skillId?: number;
    profileId?: number;
    bossRelationCode?: string;
}

interface ITPRecaltulateState {
    trainingPlanRecalculated: boolean;
}

interface IStateTPlanDetails {
    globalDescription: GlobalDescription | null;
    trainingPlan: TrainingPlan | null;
    tplanIdLoaded: number;
}

interface IPartialStateTPlanElements {
    trainingPlanElements: TrainingPlanElement[] | null;
}

interface ICollabsibleState {
    customAttributesCollapsed: boolean;
    elementsCollapsed: boolean;
    schedulesCollapsed: boolean;
    prerequisitsCollapsed: boolean;
    outputSkillsCollapsed: boolean;
    completitionRequirementsCollapsed: boolean;
    glossaryCollapsed: boolean;
    ratingCollapsed: boolean;
    mentorsCollapsed: boolean;
}
interface IState extends IURLParamState, 
                         IURLPerformanceState, 
                         ITPRecaltulateState, 
                         IStateTPlanDetails, 
                         IPartialStateTPlanElements,
                         ICollabsibleState {
    trainingPlanSchedules: TrainingPlanSchedule[] | null;
    errorMessage: string;
    shouldShowModal: boolean;
    contextLessonId: number;
    isSimpleRegisterCheckLoaded: boolean;
}

export class TrainingPlanDetail extends React.Component<IProps, IState> {
    private className = 'TrainingPlanDetail';
    private loggerLocality = 'Components.TrainingPlanDetail';
    private _itemConfig = globalConfig.itemProperties;

    constructor(props: IProps) {
        super(props);

        const params = this.props.match.params;
        const paramTPlanId: number = params.tplanId != null && !isNaN(Number(params.tplanId)) ? Number(params.tplanId) : 0;

        this.state = {
            completitionRequirementsCollapsed: false,
            contextLessonId: paramTPlanId,
            customAttributesCollapsed: false,
            elementsCollapsed: false,
            errorMessage: '',
            globalDescription: null,
            itemDetailCallerContextType: EItemDetailCallerContextType.Undefined,
            outputSkillsCollapsed: false,
            parentAssignmentId: 0,
            parentCatalogFolderId: 0,
            prerequisitsCollapsed: false,
            returnFromItemId: 0,
            schedulesCollapsed: false,
            shouldShowModal: false,
            tplanIdLoaded: 0,
            trainingPlan: null,
            trainingPlanElements: null,
            trainingPlanRecalculated: false,
            trainingPlanSchedules: null,
            isSimpleRegisterCheckLoaded: false,
            glossaryCollapsed: false,
            ratingCollapsed: false,
            mentorsCollapsed: false,
            voucherCode: null,
        }
    }

    public async componentDidMount() {
        // Load Data
        const itemId = this.state.contextLessonId;

        let partialStateRecalculateTPlan: ITPRecaltulateState = { trainingPlanRecalculated: false };

        // Get URL Parameters
        const partialStateUrlParameters = this.getURLParameters();
        const partialStateUrlPerformanceParamState = this.getURLPerformanceParameters();


        if (!Session.instance.storage.trainingPlans.isTPCached(itemId)) {
            partialStateRecalculateTPlan = await this.recalculateTplan(itemId);
        }

        const errorMessage = 'ErrorMessage:SimpleRegistrationFailed';
        const tPlanDetails = await this.getTPlanDetails(itemId, partialStateUrlParameters.itemDetailCallerContextType);
        this.setState({
            ...partialStateRecalculateTPlan,
            ...partialStateUrlParameters,
            ...tPlanDetails,
            ...partialStateUrlParameters,
            ...partialStateUrlPerformanceParamState,
        });

        const sceIdSimpleRegister = await TrainingPlanService.instance.getIsSimpleRegisterPossible(this.state.contextLessonId, partialStateUrlParameters.itemDetailCallerContextType);
        if (isSuccess<number>(sceIdSimpleRegister)) {
            if (sceIdSimpleRegister != 0 && this.state.trainingPlan?.registrationStatus != ERegistrationStatus.Cancelled) {
                const response = await TrainingPlanService.instance.registerToTrainingPlan(this.state.contextLessonId,
                    sceIdSimpleRegister,
                    null,
                    Session.instance.getUserLanguageCodeOrFallBack,
                    0,
                    false);
                this.setState({ isSimpleRegisterCheckLoaded: true })
                if (!isSuccess<BooleanResponse>(response) || !response.status) {
                    this.setState({ isSimpleRegisterCheckLoaded: true })
                    Logger.log(errorMessage);
                } else {
                    // reload page to clear cache
                    window.location.reload();
                }
            } else {
                this.setState({ isSimpleRegisterCheckLoaded: true })
            }
        }
        else {
            this.setState({ isSimpleRegisterCheckLoaded: true })
        }


        const tPlanElements = await this.getTPlanElements(itemId, partialStateUrlParameters.itemDetailCallerContextType);
        this.setState({ ...tPlanElements });

        if (tPlanDetails.trainingPlan != null) {
            if (this.state.trainingPlanSchedules == null) {
                this.getTrainingPlanSchedules(tPlanDetails.trainingPlan, partialStateUrlParameters.itemDetailCallerContextType); // TODO: Do not set state on its own
            }
        }

        // Execute Global Description
        await this.executeGlobalDescription();
    }

    public render() {
        const { tplanId } = this.props.match.params;
        const itemId = tplanId != null && !isNaN(Number(tplanId)) ? Number(tplanId) : 0;

        if (itemId !== this.state.tplanIdLoaded && this.state.tplanIdLoaded === 0 || this.state.isSimpleRegisterCheckLoaded === false) {
            return <ProgressSpinner />;
        }
        else if ((itemId !== this.state.tplanIdLoaded && this.state.tplanIdLoaded > 0)) {
            // On Recommendation Click
            this.setState({ tplanIdLoaded: 0, trainingPlan: null, trainingPlanSchedules: null, trainingPlanElements: null });
            this.getTPlanDetails(itemId, this.state.itemDetailCallerContextType).then(value => {
                this.setState({ ...value });
                if (value.trainingPlan != null) {
                    this.getTrainingPlanSchedules(value.trainingPlan, this.state.itemDetailCallerContextType);
                }
            });
            this.getTPlanElements(itemId, this.state.itemDetailCallerContextType).then(value => this.setState({ ...value }));

        }
        if (this.state.trainingPlan === null) {
            return (
                <div className='l-container'>
                    <NoDataFound message="No Tplan found" />
                </div>
            )
        }

        return (
            <React.Fragment>
                {this.renderDetails()}
                <RecommendationContainer parentHeadingLevel={0} parentItemId={this.state.tplanIdLoaded} {...this.props} />
            </React.Fragment>
        )
    }

    public async componentDidUpdate() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;

        await this.executeGlobalDescription();
    }

    private getURLParameters(): IURLParamState {
        const methodName = `${this.className}:readURLParameters()`;
        const parameters = new URLSearchParams(window.location.search);

        const urlParamContext = parameters.get('context');
        const urlParamAssingmentId = parameters.get('asId');
        const urlParamCatalogFolerId = parameters.get('catId');
        const urlParamReturnFromItemId = parameters.get('returnItemId');
        const urlParamVoucherCode = parameters.get('vouchercode');

        let paramContext: EItemDetailCallerContextType = EItemDetailCallerContextType.Undefined;

        paramContext = urlParamContext != null && !isNaN(Number(urlParamContext)) ?
            Number(urlParamContext) : EItemDetailCallerContextType.Undefined;
        const paramAssingmentId = urlParamAssingmentId != null && !isNaN(Number(urlParamAssingmentId)) ?
            Number(urlParamAssingmentId) : 0;
        const paramCatalogFolerId = urlParamCatalogFolerId != null && !isNaN(Number(urlParamCatalogFolerId)) ?
            Number(urlParamCatalogFolerId) : 0;
        const paramReturnFromItemId = urlParamReturnFromItemId != null && !isNaN(Number(urlParamReturnFromItemId)) ?
            Number(urlParamReturnFromItemId) : 0;

        Logger.log(this.loggerLocality, `${methodName} got context: ${paramContext}, assignmentid: ${paramAssingmentId} ` +
            `catalogFolderId: ${paramCatalogFolerId}`);

        return {
            itemDetailCallerContextType: paramContext,
            parentAssignmentId: paramAssingmentId,
            parentCatalogFolderId: paramCatalogFolerId,
            returnFromItemId: paramReturnFromItemId,
            voucherCode: urlParamVoucherCode
        };
    }

    protected getURLPerformanceParameters(): IURLPerformanceState {
        const methodName = `${this.className}:getURLPerformanceParameters()`;
        const parameters = new URLSearchParams(window.location.search);

        const urlParamSkillId = parameters.get('skillId');
        const urlParamUserId = parameters.get('userId');
        const urlParamProfileId = parameters.get('profileId');
        const urlParamBossRelationCode = parameters.get('bossRelationCode');

        let paramSkillId: number | undefined;
        let paramUserId: number | undefined;
        let paramProfileId: number | undefined;
        let paramBossRelationCode: string | undefined;

        paramSkillId = urlParamSkillId != null && !isNaN(Number(urlParamSkillId)) ? Number(urlParamSkillId) : undefined;
        paramUserId = urlParamUserId != null && !isNaN(Number(urlParamUserId)) ? Number(urlParamUserId) : undefined;
        paramProfileId = urlParamProfileId != null && !isNaN(Number(urlParamProfileId)) ? Number(urlParamProfileId) : undefined;
        paramBossRelationCode = urlParamBossRelationCode != null ?
            urlParamBossRelationCode : undefined;

        Logger.log(this.loggerLocality, `${methodName} got skillId: ${paramSkillId}, userId: ${paramUserId}, profileId: ${paramProfileId}`);

        return {
            bossRelationCode: paramBossRelationCode,
            profileId: paramProfileId,
            skillId: paramSkillId,
            userId: paramUserId,
        };
    }

    private renderDetails(): JSX.Element | JSX.Element[] | null {
        const tplan = this.state.trainingPlan;
        if (tplan === null) {
            return null;
        }
        const title = tplan.title;
        const summary = (globalConfig.trainingPlanProperties.enableHTMLFormatterForSummary) ? StringHelper.htmlFormat(tplan.summary) : tplan.summary;


        const hasGlobalDescriptionTemplate = this.state.trainingPlan != null &&
            this.state.trainingPlan.globalTemplate != null &&
            this.state.globalDescription != null &&
            this.state.globalDescription.globalDescriptionFile != null;

        const currentSchedule = this.getCurrentTPlanSchedule();

        return (
            <React.Fragment>
                <div>
                    <div className="">
                        <div className="l-container ">
                            <div className='item-detail__header'>
                                <div>
                                    <ItemBreadCrumb
                                        itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                                        parentAssignmentId={this.state.parentAssignmentId}
                                        parentCatalogFolderId={this.state.parentCatalogFolderId}
                                        parentTrainingPlanId={0}
                                        itemDetailType={EItemDetailCallerType.TPlan}
                                        itemTitle={this.state.trainingPlan != null ? this.state.trainingPlan.title : ''}
                                        itemId={this.state.trainingPlan != null ? this.state.trainingPlan.itemId : undefined}
                                        skillId={this.state.skillId}
                                        profileId={this.state.profileId}
                                        userId={this.state.userId}
                                        bossRelationCode={this.state.bossRelationCode}
                                        voucherCode={this.state.voucherCode}
                                        {...this.props} />
                                </div>
                                <div>
                                    <TopAnchorToolbar  {...this.props}
                                        itemId={this.state.tplanIdLoaded}
                                        tpElementId={0}
                                        itemTitle={this.state.trainingPlan != null ? this.state.trainingPlan.title : ''}
                                        favoriteType={EFavoriteType.Item} />
                                </div>
                            </div>

                            <div className="item-detail item-detail__header">
                                <div className="title">
                                    <h1 className="heading__Title"><span dangerouslySetInnerHTML={{ __html: title }} /></h1>
                                </div>
                                <ToolbarRemainingTime item={tplan} />
                                <Toolbar item={tplan} showTPlanScore={true} />
                            </div>
                        </div>
                    </div>

                    <div className="l-element--striped">
                        <div className="l-container">
                            {summary != null && summary.length > 0 && globalConfig.trainingPlanProperties.displayDescriptionPanel &&
                                <>
                                    <HeadingCollapsible
                                        headingLevel={2}
                                        containerCssClass=""
                                        headingCssClass="heading__Level2"
                                        isOpened={!this.state.customAttributesCollapsed}
                                        onToggleOpenedState={() => this.setState({ customAttributesCollapsed: !this.state.customAttributesCollapsed })}>
                                        <Translate>ItemDetail:Description</Translate>
                                    </HeadingCollapsible>
                                    <UnmountClosed
                                        isOpened={!this.state.customAttributesCollapsed}>
                                        <SummaryAttribute summary={summary} className="item-detail" />
                                    </UnmountClosed>
                                </>
                            }
                            {!hasGlobalDescriptionTemplate ? this.renderCustomAttributes(tplan.attributes) : null}
                            {!hasGlobalDescriptionTemplate &&
                                this.state.trainingPlan != null &&
                                this.state.trainingPlan.allRegistrations != null &&
                                this.state.trainingPlan.allRegistrations.length > 0 ?
                                this.renderCustomScheduleAttributes(currentSchedule) : null}
                            {hasGlobalDescriptionTemplate ? this.renderGlobalTemplatePlaceHolder() : null}
                        </div>
                    </div>

                    <div className="l-element--striped">
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.elementsCollapsed}
                                onToggleOpenedState={() => this.setState({ elementsCollapsed: !this.state.elementsCollapsed })}>
                                <>
                                    <Translate>TrainingPlan:Elements</Translate>
                                    {this.state.trainingPlanElements && 
                                    Session.instance.storage.translation.GetString("TrainingPlan:TrainingPlanDuration").Format(
                                        getEstimateDurationText(this.state.trainingPlanElements.filter(x => x.parentItemId === "root")
                                        .map(y => y.estimateDuration).reduce((a, b) => a + b, 0)))}
                                </>
                            </HeadingCollapsible>
                            <UnmountClosed
                                isOpened={!this.state.elementsCollapsed}>
                                {this.state.trainingPlanElements == null ? (
                                    <ProgressSpinner />
                                ) :
                                    this.renderElements()
                                }
                            </UnmountClosed>
                        </div>
                    </div>
                    
                    {// Render CollaborationProviderPanel
                     this.state.trainingPlan != null &&
                     currentSchedule != null &&
                     currentSchedule.allRegistrations != null &&
                     currentSchedule.allRegistrations.filter(x => x.registrationStatus === ERegistrationStatus.Accepted).length > 0 &&
                     <CollaborationProviderItemPanel
                        itemId={this.state.trainingPlan.itemId}
                        classId={currentSchedule.sceId}
                        panelTitle="Item:CollaborationProviderLinks" />
                    }

                    {   // Prerequisites Panel | Display if the displayPrerequisitePanel config entry says true and there is at least 1 prerequisite
                        globalConfig.itemProperties.displayPrerequisitePanel
                            && (this.state.trainingPlan != null && // Only show prerequisites if there are any inputSkills
                                this.state.trainingPlan.userInputSkills != null &&
                                this.state.trainingPlan.userInputSkills.length > 0) ?
                            (
                                <div className="l-element--striped">
                                    <div className="l-container">
                                        <HeadingCollapsible
                                            headingLevel={2}
                                            containerCssClass=""
                                            headingCssClass="heading__Level2"
                                            isOpened={!this.state.prerequisitsCollapsed}
                                            onToggleOpenedState={() => this.setState({ prerequisitsCollapsed: !this.state.prerequisitsCollapsed })}>
                                            <Translate>TrainingPlan:Prerequisites</Translate>
                                        </HeadingCollapsible>
                                        <UnmountClosed
                                            isOpened={!this.state.prerequisitsCollapsed}>
                                            {this.state.trainingPlan == null ? (
                                                <ProgressSpinner />
                                            ) :
                                                <TrainingPlanPrerequisites trainingPlan={this.state.trainingPlan} catalogId={this.state.parentCatalogFolderId} />
                                            }
                                        </UnmountClosed>
                                    </div>
                                </div>
                            ) : ''}

                    {this.renderCompletitionRequirements()}

                    {   // OutputSkills Panel | Display if the displayOutputSkillsPanel config entry says true and there is at least 1 outputSkill
                        globalConfig.itemProperties.displayOutputSkillsPanel
                            && (this.state.trainingPlan != null && // Only show prerequisites if there are any inputSkills
                                this.state.trainingPlan.outputSkills != null &&
                                this.state.trainingPlan.outputSkills.length > 0) ?
                            (
                                <div className="l-element--striped">
                                    <div className="l-container">
                                        <HeadingCollapsible
                                            headingLevel={2}
                                            containerCssClass=""
                                            headingCssClass="heading__Level2"
                                            isOpened={!this.state.outputSkillsCollapsed}
                                            onToggleOpenedState={() => this.setState({ outputSkillsCollapsed: !this.state.outputSkillsCollapsed })}>
                                            <Translate>TrainingPlan:OutputSkills</Translate>
                                        </HeadingCollapsible>
                                        <UnmountClosed
                                            isOpened={!this.state.outputSkillsCollapsed}>
                                            {this.state.trainingPlan == null ? (
                                                <ProgressSpinner />
                                            ) :
                                                <TrainingPlanOutputSkills trainingPlan={this.state.trainingPlan} />
                                            }
                                        </UnmountClosed>
                                    </div>
                                </div>
                            ) : ''}

                    {
                        this.state.trainingPlan != null && this.state.trainingPlanSchedules != null ?
                            (<div className="l-element--striped">
                                <div className="l-container">
                                    <HeadingCollapsible headingLevel={2}
                                        containerCssClass=""
                                        headingCssClass="heading__Level2"
                                        isOpened={!this.state.schedulesCollapsed}
                                        onToggleOpenedState={() => this.setState({ schedulesCollapsed: !this.state.schedulesCollapsed })}>
                                        <Translate>
                                            TrainingPlan:Classes
                                        </Translate>
                                    </HeadingCollapsible>
                                    <UnmountClosed
                                        isOpened={!this.state.schedulesCollapsed}>
                                        {this.state.trainingPlanSchedules == null ? (
                                            <ProgressSpinner />
                                        ) :
                                            <TrainingPlanScheduleList
                                                trainingPlan={this.state.trainingPlan}
                                                trainingPlanSchedules={this.state.trainingPlanSchedules}
                                                itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                                                parentAssignmentId={this.state.parentAssignmentId}
                                                parentCatalogFolderId={this.state.parentCatalogFolderId}
                                                voucherCode={this.state.voucherCode}
                                                {...this.props} />
                                        }
                                    </UnmountClosed>
                                </div>
                            </div>) : ''
                    }
                    {this.renderMentors()}
                    {this.state.trainingPlan?.isRatingEnabled && globalConfig.trainingPlanProperties.displayRatings && this.renderCommonRating(this.state.trainingPlan)}

                    <GlossaryList itemId={this.state.contextLessonId} {...this.props} callerContext={EItemDetailCallerContextType.Catalog} idsForGlossary={this.getIdsForGlossary()} />
                </div>
            </React.Fragment>
        );
    }
    private getIdsForGlossary() {
        const tmpIds: GlossarIdForBredCrumb = {
            itemId: this.state.contextLessonId,
            asId: this.state.parentAssignmentId,
            catId: this.state.parentCatalogFolderId,
            tpId: this.state.tplanIdLoaded,
            tpeId: 0
        };
        return tmpIds;
    }

    protected renderMentors(): JSX.Element | JSX.Element[] | null {
        if (this.state.trainingPlan != null) {
            if (this.state.trainingPlan.mentors.length > 0) {
                return (
                    <div className="l-element--striped">
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.mentorsCollapsed}
                                onToggleOpenedState={() => this.setState({ mentorsCollapsed: !this.state.mentorsCollapsed })}>
                                <Translate>ItemDetail:MentorsTitle</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={!this.state.mentorsCollapsed}>
                                <ItemMentorList mentors={this.state.trainingPlan.mentors} parentHeadingLevel={2} />
                            </UnmountClosed>
                        </div>
                    </div>
                )
            }
            else {
                return null;
            }
        } else {
            return null;
        }
    }

    private renderGlobalTemplatePlaceHolder() {
        return <div id="globalDescription">&nbsp;</div>
    }

    private renderElements(): JSX.Element[] | JSX.Element {
        if (this.state.trainingPlanElements != null && this.state.trainingPlanElements.length > 0) {
            let activeElement = undefined;
            if (this.state.returnFromItemId != 0) {
                const retrunItemParentSid = this.state.trainingPlanElements.find(tpElement => tpElement.itemId === this.state.returnFromItemId)?.parentItemId;
                activeElement = this.state.trainingPlanElements.find(tpElement => tpElement.tPElementSid === retrunItemParentSid);
            }
            return <>
                {globalConfig.trainingPlanProperties.renderOldLayout && <TrainingPlanElementsContainer
                    trainingPlan={this.state.trainingPlan}
                    trainingPlanElements={this.state.trainingPlanElements}
                    itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                    parentAssignmentId={this.state.parentAssignmentId}
                    parentCatalogFolderId={this.state.parentCatalogFolderId}
                    activeElement={activeElement}
                    {...this.props} />}
                {this.state.trainingPlan != null && !globalConfig.trainingPlanProperties.renderOldLayout && <TrainingPlanElementsContentsV2
                    trainingPlan={this.state.trainingPlan}
                    trainingPlanElements={this.state.trainingPlanElements}
                    itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                    parentAssignmentId={this.state.parentAssignmentId}
                    parentCatalogFolderId={this.state.parentCatalogFolderId}
                    parentHeadingLevel={1}
                    translationStorage={Session.instance.storage.translation}
                    {...this.props} />}
            </>
        } else {
            return <React.Fragment />
        }
    }

    protected renderCommonRating(lesson: Item) {
        return <div className="l-element--striped">
            <div className="l-container">
                <HeadingCollapsible
                    headingLevel={2}
                    containerCssClass=""
                    headingCssClass="heading__Level2"
                    isOpened={!this.state.ratingCollapsed}
                    onToggleOpenedState={() => this.setState({ ratingCollapsed: !this.state.ratingCollapsed })}>
                    <Translate>{'Rating:Rating'}</Translate> {lesson.title}
                </HeadingCollapsible>
                <UnmountClosed
                    isOpened={!this.state.ratingCollapsed}>
                    <ItemRatingDetails
                        headingLevel={2}
                        item={lesson}
                        parentTrainingPlanId={0} />
                </UnmountClosed>
            </div>
        </div>
    }

    private renderCompletitionRequirements(): JSX.Element | null {
        // CompletitionRequirements Panel | Display if the displayCompletitionRequirementsPanel config entry says true and there is at least 1 compReq
        if (globalConfig.trainingPlanProperties.displayCompletitionRequirementsPanel
            && (this.state.trainingPlan != null && // Only show compReqs panel if there are any compReqs
                this.state.trainingPlan.completitionRequirementsStatus != null &&
                this.state.trainingPlan.completitionRequirementsStatus.length > 0)) {

            const completitionRequirementsStatus: ICompletitionRequirements[] = [];
            this.state.trainingPlan.completitionRequirementsStatus.map(compReq => {
                completitionRequirementsStatus.push({
                    requiredLessonStatus: compReq.completitionRequirement!.requiredLessonStatus,
                    requiredScore: compReq.completitionRequirement!.requiredScore,
                    requiredItemSID: compReq.completitionRequirement!.requiredItemSID,
                    requiredItemTitle: compReq.requiredItemTitle,
                    currentStatus: compReq.status,
                    currentScore: compReq.score,
                    fulfilled: compReq.fulfilled
                })
            });

            return (
                <div className="l-element--striped">
                    <div className="l-container">
                        <HeadingCollapsible headingLevel={2}
                            containerCssClass=""
                            headingCssClass="heading__Level2"
                            isOpened={!this.state.completitionRequirementsCollapsed}
                            onToggleOpenedState={() => this.setState({ completitionRequirementsCollapsed: !this.state.completitionRequirementsCollapsed })}>
                            <Translate>TrainingPlan:CompletionRequirements</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed
                            isOpened={!this.state.completitionRequirementsCollapsed}>
                            {this.state.trainingPlan == null ? (
                                <ProgressSpinner />
                            ) :
                                <TrainingPlanCompletitionRequirements completitionRequirementsStatus={completitionRequirementsStatus} />
                            }
                        </UnmountClosed>
                    </div>
                </div>
            )

        }
        else {
            return null;
        }
    }

    private renderCustomAttributes(traiingPlanAttributes: Attribute[]): JSX.Element | null {
        const customAttributeConfig = this._itemConfig.customAttributesTrainingPlan != null ?
            this._itemConfig.customAttributesTrainingPlan :
            this._itemConfig.customAttributes;
        if (traiingPlanAttributes == null || traiingPlanAttributes.length <= 0 || customAttributeConfig.length <= 0) {
            return null;
        }
        return <AttributeList
            customAttributeConfig={customAttributeConfig}
            attributes={traiingPlanAttributes}
            headingCssClass="heading__Level2"
            attributeCssClassName="item-detail"
            parentHeadingLevel={1}
        />
    }

    private renderCustomScheduleAttributes(tpScheduleDetail: TrainingPlanSchedule | null): JSX.Element | null {
        const customAttributeConfig = globalConfig.trainingPlanProperties.customAttributesScheduleOnTpDetail;
        if (customAttributeConfig == null ||
            (customAttributeConfig != null && customAttributeConfig.length <= 0) ||
            tpScheduleDetail == null) {
            return null;
        }
        return <AttributeList
            customAttributeConfig={customAttributeConfig}
            attributes={tpScheduleDetail.attributes}
            headingCssClass="heading__Level2"
            attributeCssClassName="item-detail"
            parentHeadingLevel={1}
        />
    }

    private async getTPlanDetails(itemId: number, itemContext: EItemDetailCallerContextType): Promise<IStateTPlanDetails> {
        const response = await Session.instance.storage.trainingPlans.getTrainingPlan(itemId, itemContext, false);
        if (isSuccess<TrainingPlan | null>(response)) {
            let globDescResponse: GlobalDescription | null = null;
            if (response != null && response.globalTemplate != null && response.globalTemplate !== '') {
                // Load Global Description before adding lesson to state to know what to render.
                globDescResponse = await this.getGlobalDescription(response.globalTemplate);
            }

            return { globalDescription: globDescResponse, trainingPlan: response, tplanIdLoaded: itemId };
        } else {
            this.setState({ errorMessage: 'ErrorMessage:UnknownError' });
            return { globalDescription: null, trainingPlan: null, tplanIdLoaded: 0 };
        }
    }

    private async getTrainingPlanSchedules(tPlan: TrainingPlan | null, itemContext: EItemDetailCallerContextType): Promise<void> {
        if (tPlan != null) {
            const ignoreRegisterRight = globalConfig.trainingPlanProperties.ignoreClassRegisterRight
                || globalConfig.trainingPlanProperties.ignoreClassRegistrationPhaseEnd
                || globalConfig.trainingPlanProperties.ignoreClassRegistrationPhaseStart;
            const response = await Session.instance.storage.trainingPlanSchedules.getTrainingPlanSchedules(tPlan.itemId, itemContext, ignoreRegisterRight, false);
            if (isSuccess<TrainingPlanSchedule[] | null>(response)) {
                this.setState({ trainingPlanSchedules: response, errorMessage: '' });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:UnknownError' });
            }
        }
    }

    private async recalculateTplan(tplanId: number): Promise<ITPRecaltulateState> {
        const methodName = 'recalculateTplan';
        const isSuccessfull = await TrainingPlanService.instance.recalculateTrainingPlan(Session.instance.getUserLanguageCodeOrFallBack, tplanId);
        if (isSuccess<BooleanResponse>(isSuccessfull)) {
            return { trainingPlanRecalculated: isSuccessfull.status };
        }
        else {
            console.error(`${methodName} failed to recalculate training Plan with id=${tplanId}, ${isSuccessfull.message}`);
            Logger.log(this.loggerLocality, `${methodName}  failed to recalculate training Plan with id=${tplanId}, ${isSuccessfull.message}`);
            return { trainingPlanRecalculated: false };
        }
    }
    private async getTPlanElements(tplanId: number, itemContext: EItemDetailCallerContextType): Promise<IPartialStateTPlanElements> {
        const response = await Session.instance.storage.trainingPlanElements.getTrainingPlanElements(tplanId, itemContext, false);
        if (isSuccess<TrainingPlanElement[] | null>(response)) {
            return { trainingPlanElements: response };
        } else {
            this.setState({ errorMessage: 'ErrorMessage:UnknownError' });
            return { trainingPlanElements: null }
        }
    }

    private getCurrentTPlanSchedule(): TrainingPlanSchedule | null {
        if (this.state.trainingPlanSchedules != null &&
            this.state.trainingPlan != null &&
            this.state.trainingPlan.allRegistrations != null &&
            this.state.trainingPlan.allRegistrations.length > 0) {
            const activeRegistrations = this.state.trainingPlan.allRegistrations.filter(r =>
                r.registrationStatus === ERegistrationStatus.Accepted ||
                r.registrationStatus === ERegistrationStatus.Requested ||
                r.registrationStatus === ERegistrationStatus.InWaitingList);
            if (activeRegistrations.length > 0) {
                const currentSchedule = this.state.trainingPlanSchedules.filter(s => s.sceId === activeRegistrations[0].classId);
                if (currentSchedule != null && currentSchedule.length > 0) {
                    return currentSchedule[0];
                }
            }
        }
        return null;
    }

    // Load Global Description
    private async getGlobalDescription(globalTemplate: string): Promise<GlobalDescription | null> {
        const globalDescriptionResponse = await GlobalDescriptionService.instance.getGlobalDescription(globalTemplate, Session.instance.getUserLanguageCodeOrFallBack);
        if (isSuccess<GlobalDescription>(globalDescriptionResponse)) {
            return globalDescriptionResponse;
        }
        else {
            if (globalDescriptionResponse.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(globalDescriptionResponse.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GlobalDescriptionLoadingFailed' });
            }
        }
        return null;
    }

    private async executeGlobalDescription() {
        const methodName = `${this.className}:executeGlobalDescription()`;
        if (this.state.trainingPlan != null &&
            this.state.trainingPlan.globalTemplate != null &&
            this.state.globalDescription != null &&
            this.state.globalDescription.globalDescriptionFile != null) {

            // Data for Global Description
            const user = Session.instance.loginUser;
            const lesson: TrainingPlan = this.state.trainingPlan;
            const globalDescription: GlobalDescription = this.state.globalDescription;
            const lessonFiles = await ItemService.instance.getFilesForItem(this.state.trainingPlan.itemId, this.state.itemDetailCallerContextType);
            const scheduleDetail = this.getCurrentTPlanSchedule();

            Logger.log(this.loggerLocality, `${methodName} user Object for Global Description: `, user);
            Logger.log(this.loggerLocality, `${methodName} trainingPlan Object for Global Description: `, lesson);
            Logger.log(this.loggerLocality, `${methodName} globalDescription Object for Global Description:`, globalDescription);
            Logger.log(this.loggerLocality, `${methodName} lessonFiles Object for Global Description:`, lessonFiles);
            Logger.log(this.loggerLocality, `${methodName} class detail Object for Global Description:`, scheduleDetail);

            // tslint:disable-next-line:no-eval
            eval(this.state.globalDescription.globalDescriptionFile.fileContent);
            Logger.log(this.loggerLocality, `${methodName} executed global description`);
        }
    }
}
export default TrainingPlanDetail;