import { StringHelper } from '$src/util/StringHelper';
import { DateTimeConverter } from '$storage/models/converters/DateTimeConverter';
import { RegistrationStatusConverter } from '$storage/models/converters/RegistrationStatusConverter';
import { ERegistrationStatus } from '$storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseEmployeeRegistration {

    @JsonProperty('id', Number, false)
    public registrationId: number = 0;

    @JsonProperty('registrationDate', DateTimeConverter, false)
    public registrationDate: Date | undefined = undefined;
    
    @JsonProperty('registrationYear', Number, false)
    public registrationYear: number = 0;

    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;

    @JsonProperty('registeredUserId', Number, false)
    public registeredUserId: number = 0;

    @JsonProperty('registeredUserFirstName', String, false)
    public registeredUserFirstName: string = '';
    
    @JsonProperty('registeredUserLastName', String, false)
    public registeredUserLastName: string = '';
    
    @JsonProperty('registeredUserEmail', String, false)
    public registeredUserEmail: string = '';

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('itemTitle', String, false)
    public itemTitle: string = '';

    @JsonProperty('itemSid', String, false)
    public itemSid: string = '';

    @JsonProperty('scheduleId', Number, false)
    public scheduleId: number = 0;

    @JsonProperty('classTitle', String, false)
    public classTitle: string = '';

    @JsonProperty('classDescription', String, false)
    public classDescription: string = '';
    
    @JsonProperty('firstAppointment', DateTimeConverter, false)
    public firstAppointment: Date | undefined = undefined;

    @JsonProperty('lastAppointment', DateTimeConverter, false)
    public lastAppointment: Date | undefined = undefined;

    @JsonProperty('classPrice', Number, false)
    public classPrice: number = 0;
    
    @JsonProperty('classCurrency', String, false)
    public classCurrency: string = '';

    public get formatedFirstAppointment(): string {
        if(this.firstAppointment) {
            return StringHelper.dateString(this.firstAppointment);
        } else {
            return '';
        }
    }

    public get formatedLastAppointment(): string  {
        if(this.lastAppointment) {
            return StringHelper.dateString(this.lastAppointment);
        } else {
            return '';
        }
    }

    public get scheduleSpan(): string {
        return `${this.formatedFirstAppointment} - ${this.formatedLastAppointment}`;
    }

    public get registeredUserFullNameAndEmail(): string {
        return `${this.registeredUserFirstName} ${this.registeredUserLastName} (${this.registeredUserEmail})`
    }
    
}
