import React, { useEffect, useState } from 'react';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { UnmountClosed } from 'react-collapse';
import { DatePicker } from '$src/components/shared/DatePicker';
import { HeadingCollapsible } from '$src/components/shared/HeadingCollapsible';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { InputSelect } from '$src/components/shared/InputSelect';
import { Translate } from '$src/components/shared/Translate';
import AttributeService from '$src/core/Services/AttributeService';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import ItemTypeFactory from '$src/storage/models/itemTypeFactory';
import ItemTypeModel from '$src/storage/models/ItemTypeModel';
import { SearchFilter } from '$src/storage/models/search/elk/SearchFilter';
import { isSuccess } from '$src/util/Result';

interface IProps {
    onFiltersChanged?: (
        filters: SearchFilter,
    ) => void;
}

export default function SearchFilters(props: IProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [filters, setFilters] = useState<SearchFilter>(new SearchFilter());
    const [subjectsData, setSubjectsData] = useState<Array<AttributeValue>>();

    useEffect(() => {
        AttributeService.instance.getAttributeValues(16).then(subjects => { //Read subjects
            if (isSuccess<Array<AttributeValue>>(subjects)) {
                setSubjectsData(subjects);
            }
        });
    }, [])

    useEffect(() => {
        if (props.onFiltersChanged != null) {
            props.onFiltersChanged(filters);
        }
    }, [filters])

    const itemTypesData = ItemTypeFactory.instance.GetItemTypes(true);

    const ldFromAttributes: InputAttributes = {
        attributeValueType: 67,
        class: 'search-filters_duration',
        editMode: true,
        hasFocus: false,
        id: 'ldFromSelect',
        initialValidation: false,
        isReadOnly: false,
        isRequired: false,
        label: '',
        labelClassName: 'screen-reader-only',
        maxLength: 100,
        value: '0',
        placeholder: ''
    }

    const ldToAttributes: InputAttributes = {
        attributeValueType: 67,
        class: 'search-filters_duration',
        editMode: true,
        hasFocus: false,
        id: 'ldToSelect',
        initialValidation: false,
        isReadOnly: false,
        isRequired: false,
        label: '',
        labelClassName: 'screen-reader-only',
        maxLength: 100,
        value: '0',
        placeholder: ''
    }

    function onSubjectFilterChanged(values: Array<AttributeValue>): void {
        setFilters(prev => ({ ...prev, subjects: values }));
    }

    function onLessonTypeFilterChanged(values: Array<ItemTypeModel>): void {
        setFilters(prev => ({ ...prev, itemTypes: values }));
    }

    function onDateFromFilterChanged(date: Date | null): void {
        setFilters(prev => ({ ...prev, dateFrom: date }));
    }

    function onDateToFilterChanged(date: Date | null): void {
        setFilters(prev => ({ ...prev, dateTo: date }));
    }

    function onMinDurationChanged(id: string, code: string): void {
        setFilters(prev => ({ ...prev, durationMin: Number.parseInt(code) }));
    }

    function onMaxDurationChanged(id: string, code: string): void {
        setFilters(prev => ({ ...prev, durationMax: Number.parseInt(code) }));
    }

    return (
        <div className="search-filter__container">
            <HeadingCollapsible
                headingLevel={1}
                headingCssClass='heading__Level3'
                containerCssClass=""
                isOpened={isOpen}
                onToggleOpenedState={() => setIsOpen(!isOpen)}>
                <Translate>Search:Filters</Translate>
            </HeadingCollapsible>
            <UnmountClosed isOpened={isOpen}>
                <div className="search-filter__filter-row">
                    <div className="search-filter__filter-row-title">
                        <Translate>Search:SubjectFilter</Translate>
                    </div>
                    <div className="search-filter__filter-row-filter">
                        <MultiSelect
                            className="user-interests__settings__value__multi-select"
                            data={subjectsData}
                            onChange={(e) => { onSubjectFilterChanged(e.target.value as AttributeValue[]) }}
                            value={filters.subjects}
                            textField="text"
                        />
                    </div>
                </div>
                <div className="search-filter__filter-row">
                    <div className="search-filter__filter-row-title">
                        <Translate>Search:LessonTypeFilter</Translate>
                    </div>
                    <div className="search-filter__filter-row-filter">
                        <MultiSelect
                            className="user-interests__settings__value__multi-select"
                            data={itemTypesData}
                            onChange={(e) => { onLessonTypeFilterChanged(e.target.value as ItemTypeModel[]) }}
                            value={filters.itemTypes}
                            textField="text"
                        />
                    </div>
                </div>
                <div className="search-filter__filter-row">
                    <div className="search-filter__filter-row-title">
                        <Translate>Search:DateRangeFilter</Translate>
                    </div>
                    <div className="search-filter__filter-row-filter">
                        <DatePicker
                            className="DatePicker"
                            key="DateFrom"
                            onChange={(e) => onDateFromFilterChanged(e)}
                            value={filters.dateFrom}
                        />
                        <div><Translate>Search:DateFilterSeperator</Translate></div>
                        <DatePicker
                            className="DatePicker"
                            key="DateTo"
                            onChange={(e) => onDateToFilterChanged(e)}
                            value={filters.dateTo}
                        />
                    </div>
                </div>
                <div className="search-filter__filter-row">
                    <div className="search-filter__filter-row-title">
                        <Translate>Search:LearningDurationFilter</Translate>
                    </div>
                    <div className="search-filter__filter-row-filter">
                        <InputSelect attributes={ldFromAttributes} onChange={(id, val, errMessage, code) => onMinDurationChanged(id, code)} />
                        <div><Translate>Search:DateFilterSeperator</Translate></div>
                        <InputSelect attributes={ldToAttributes} onChange={(id, value, errMessage, code) => onMaxDurationChanged(id, code)} />
                    </div>
                </div>
            </UnmountClosed>
        </div>
    );
}