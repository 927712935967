import Logger from "$src/core/Logger";
import MultiFactorAuthenticationService from "$src/core/Services/MultiFactorAuthenticationService";
import Session from "$src/core/Session";
import { MoreDetailsCheckRequest, PropertyToCheck } from "$src/storage/models/RequestObjects/MultiFactorAuthentication/MoreDetailsCheckRequest";
import CustomErrorMessage from "$src/util/CustomErrorMessage";
import { isSuccess } from "$src/util/Result";
import React, { useEffect } from "react";
import { GenericInput } from "$components/shared/GenericInput";
import { Heading } from "$components/shared/Heading";
import { ProgressSpinner } from "$components/shared/ProgressSpinner";
import { Translate } from "$components/shared/Translate";
import { ErrorMessage } from "$components/shared/WarningsAndErrors/ErrorMessage";
import GTConfirm from "$components/shared/Atoms/GTConfirm";
import { UserResponse } from "$src/storage/models/MultiFactorAuthentication/UserResponse";
import ThirdStep from "./ThirdStep";

interface IProps {
    initialCode: string;
    onGoBack?: () => void;
}

export default function SecondStep(props: IProps) {
    const loggerLocality = 'InitialCodeCheck';

    const [errorMessage, setErrorMessage] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [checkMoreDetails, setCheckMoreDetails] = React.useState(false);
    const [propertiesToCheck, setPropertiesToCheck] = React.useState<PropertyToCheck[]>([]);
    const [userData, setUserData] = React.useState<UserResponse>();

    useEffect(() => {
        const listOfPropertiesToCheck: PropertyToCheck[] = [];
        globalConfig.initialCodeCheckSecondStepProperties.fields.map(field => {
            listOfPropertiesToCheck.push({
                property: field.name,
                value: '',
                attributeMetaDataName: field.attributeMetaDataName != null ? field.attributeMetaDataName : '',
                isCustomAttribut: field.attributeMetaDataName != null ? true : false,
            })
        });
        setPropertiesToCheck([...listOfPropertiesToCheck]);
    }, [])

    useEffect(() => {
        async function fetchData() {
            setErrorMessage('');
            const moreDetailsCheckRequest: MoreDetailsCheckRequest = {
                initialCode: encodeURIComponent(props.initialCode),
                domainName: globalConfig.loginProperties.defaultDomain,
                listOfProperties: propertiesToCheck,
            }
            await MultiFactorAuthenticationService.instance.checkMoreDetails(moreDetailsCheckRequest)
                .then((result) => {
                    if (isSuccess<UserResponse>(result)) {
                        setUserData(result);
                    }
                    else {
                        if (result.detailedObject) {
                            setErrorMessage(CustomErrorMessage.getErrorCodeMessageString(result.detailedObject.subStatusCode));
                        } else {
                            setErrorMessage('ErrorMessage:CheckMoreDetailsFailed');
                        }
                        Logger.log(loggerLocality, `failed to check more details`);
                        console.error(`${loggerLocality} failed to check more details`);
                    }
                });
            setIsLoading(false);
            setCheckMoreDetails(false);
        }

        if (checkMoreDetails) {
            setIsLoading(true);
            fetchData();
        }
    }, [checkMoreDetails]);

    const handleSecondStepSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setCheckMoreDetails(true);
    }

    const onTextChange = (id: string, value: string) => {
        propertiesToCheck.find(p => p.property === id)!.value = value;
        setPropertiesToCheck([...propertiesToCheck]);
    }

    return (
        <>
            {userData ? <ThirdStep userData={userData} initialCode={props.initialCode} />:
                <>
                    {
                        isLoading ?
                            <div className="l-container--center">
                                < ProgressSpinner />
                            </div> :
                            <div className="l-container--center">
                                <div className="l-box__input">
                                    <Heading headingLevel={1} cssClass="heading__Level4">
                                        <Translate>MFA:MoreDetailsCheckTitle</Translate>
                                    </Heading>
                                    <div className="text">
                                        <Translate>MFA:MoreDetailsCheckHint</Translate>
                                    </div>
                                </div>
                                <form id="formInitialCodeCheckSecondStep" onSubmit={(e) => handleSecondStepSubmit(e)}>
                                    {
                                        globalConfig.initialCodeCheckSecondStepProperties.fields.map(f =>
                                            <GenericInput
                                                key={f.name}
                                                type='text'
                                                id={f.name}
                                                label={Session.instance.storage.translation.GetString(f.translation)}
                                                value={""}
                                                isReadOnly={false}
                                                isRequired={true}
                                                hasFocus={f.index <= 1}
                                                editMode={true}
                                                initialValidation={false}
                                                fieldIndex={f.index}
                                                onTextChange={(id, value) => onTextChange(id, value)}
                                                class=''
                                            />
                                        )
                                    }
                                    <ErrorMessage errorMessage={errorMessage} />
                                    <GTConfirm
                                        onConfirm={() => setCheckMoreDetails(true)}
                                        onCancel={() => props.onGoBack ? props.onGoBack() : null}
                                        cancelText={Session.instance.storage.translation.GetString('MFA:BackToInitialCodeCheck')}
                                        confirmText={Session.instance.storage.translation.GetString('MFA:MoreDetailsCheck')} />
                                </form>
                            </div>
                    }
                </>
            }
        </>);
}