import React from 'react';

import { Alert } from '$components/shared/WarningsAndErrors/Alert';

interface IProps {
    message?: string;
}

export class NoDataFound extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <Alert message={this.getMessage()} alertType="info" alertAppereance="box" />
        );
    }

    private getMessage(): string {
        if (this.props.message != null) {
            return this.props.message
        }
        else {
            return 'NoDataFound:DefaultMessage';
        }
    }
}