import ServiceClient from '$core/ServiceClient';
import { ErrorLogEntryResponse } from '$src/storage/models/offlineAdmin/ErrorLogEntryResponse';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import Session from '$src/core/Session';

export default class OfflineAdminService extends ServiceClient {
    protected static _instance: OfflineAdminService | null = null;

    protected className = 'OfflineAdminService';
    protected loggerLocality = 'OfflineAdminService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): OfflineAdminService {
        return this._instance || (this._instance = new this());
    }

    public async saveLessonStatusFromOfflineXmlImport(request: FileDataRequest) {
        const formData = new FormData();
        formData.append(request.fileName, request.file as File);
        formData.append("language", Session.instance.getUserLanguageCodeOrFallBack);
        const response = await this.postFormData<ErrorLogEntryResponse>('lesson/saveLessonStatusFromOfflineXmlImport', formData, ErrorLogEntryResponse);
        return response;
    }
} 