import React from 'react';
import { GridDetailRowProps } from '@progress/kendo-react-grid';


import { Translate } from '$components/shared/Translate';

/**
 * Kendo grid details row containing any booking information
 * @param props GridDetailRowProps from kendo. props.dataItem must be of type PaymentVoucherCampaignResponse
 * @returns payment-campaign-archive--detail-row
 */
export default function PaymentCampaignOverviewDetailRow(props: GridDetailRowProps) {
    if (!props || !props.dataItem) {
        return null;
    }

    return (
        <section className="payment-campaign-archive--detail-row">
            <table>
                <tbody>
                    <tr>
                        <th scope='row'><Translate>PaymentCampaignArchive:ExpiryDate</Translate></th>
                        <td>{props.dataItem.expireTime}</td>
                    </tr>
                    <tr>
                        <th scope='row'><Translate>PaymentCampaignArchive:ItemLifecycleInDays</Translate></th>
                        <td>{props.dataItem.itemLifecycleInDays}</td>
                    </tr>
                    <tr>
                        <th scope='row'><Translate>PaymentCampaignArchive:Creator</Translate></th>
                        <td>{props.dataItem.createdBy}</td>
                    </tr>
                    <tr>
                        <th scope='row'><Translate>PaymentCampaignArchive:ItemAttributeName</Translate></th>
                        <td>{props.dataItem.itemAttributeName}</td>
                    </tr>
                    <tr>
                        <th scope='row'><Translate>PaymentCampaignArchive:RestrictedToCourse</Translate></th>
                        <td>{props.dataItem.paymentVoucherCampaignRestrictions}</td>
                    </tr>
                    <tr>
                        <th scope='row'><Translate>PaymentCampaignArchive:MetaData</Translate></th>
                        <td>{props.dataItem.metaData}</td>
                    </tr>
                </tbody>
            </table>
        </section>
    );
}