import Logger from '$src/core/Logger';
import AuthService from '$src/core/Services/AuthService';
import SsoServiceProviderService from '$src/core/Services/SsoServiceProviderService';
import Session from '$src/core/Session';
import { StringResponse } from '$src/storage/models/StringResponse';
import { isSuccess } from '$src/util/Result';
import React, { useEffect } from 'react';

/**
 * Renders an invisible iframe that connects the current user to the external authenticated user
 * Iframe will only be rendered, if user is authenticated and sso id is null
 */
export const BackgroundSsoIdMigration: React.FC = () => {
    const loggerLocality: string = 'SsoServiceProviderService';

    const [url, setUrl] = React.useState('');

    // Try to merge in backgroun means - call the merge endpoint and if user is not authenticated it will just return an ok 200
    const tryMergeAsync = async () => {
        const accessToken = await AuthService.instance.GetExternalAccessToken();
        if (isSuccess(accessToken)) {
            setUrl(SsoServiceProviderService.instance.getTryMergeUserUrl((accessToken as StringResponse).value));
        } else {
            Logger.log(loggerLocality, 'Retrieving accessToken failed');
        }
    }

    useEffect(() => {        
        if (SsoServiceProviderService.instance.isAnySsoEnabled() && !Session.instance.loginUser?.hasSsoId) {
            tryMergeAsync().catch(() => {
                Logger.log(loggerLocality, 'Failed merging user to sso id');
            });
        }
    }, []);

    return (
        <>
            {url && <iframe className="backgroundSsoSPCalls__iframe" src={url} role="none"></iframe>}
        </>
    );
}