import Logger from '$core/Logger';
import Session from '$core/Session';
import SkillService from '$src/core/Services/SkillService';
import GtError from '$src/util/GtError';
import SessionCache from '$storage/cache/SessionCache';
import { UserSkillProfileSkill } from '$storage/models/SkillProfile/UserSkillProfileSkill';
import { UserSkillProfileSkillContainer } from '$src/storage/models/SkillProfile/UserSkillProfileSkillContainer';
import { isSuccess } from '$util/Result';

const CACHING_DURATION = 300;

export default class UserTargetSkillStorage extends SessionCache<number, UserSkillProfileSkillContainer> {
    protected className = 'UserTargetSkillStorage';
    protected loggerLocality = 'Storage.UserTargetSkillStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getUserTargetSkills(requestedUserId?: number, forceReload = false, bossRelationCode?: string): Promise<UserSkillProfileSkillContainer | null> {
        const methodName = `${this.className}:getUserTargetSkills()`;
        const userId = requestedUserId ? requestedUserId : Session.instance.loginUser!.id;
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        if (this.isObjectInCache(userId) && !forceReload) {
            Logger.log(this.loggerLocality, `${methodName} getting user target skills for userId=${userId}.`);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} getting user target skills from server, userId=${userId}.`);
            const response = await SkillService.instance.getUserTargetSkills(language, userId, bossRelationCode);

            if (isSuccess<UserSkillProfileSkillContainer>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality, `${methodName} got user target skills from server, userid=${userId}, number of profiles=${elementCount}.`);
                this.saveObjectToCache(userId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get user target skills from server, userId=${userId}.`);
                this.saveObjectToCache(userId, new UserSkillProfileSkillContainer());
            }
        }
        return this.getObjectFromCache(userId);
    }

    public async getPerformanceReviewTargetSkills(requestedUserId?: number, bossRelationCode?: string): Promise<UserSkillProfileSkillContainer | null> {
        const response = await this.getUserTargetSkills(requestedUserId, false, bossRelationCode);
        if (response != null) {
            const responseFiltered = {hasAllShowRights: response.hasAllShowRights, userSkillProfileSkills: response.userSkillProfileSkills};
            responseFiltered.userSkillProfileSkills = responseFiltered.userSkillProfileSkills.filter(utsk => utsk.showSkillInPerformanceReview); 
            return responseFiltered;
        } else {
            return null;
        }
    }

    public async getTargetSkill(skillId: number, requestedUserId?: number, bossRelationCode?: string): Promise<UserSkillProfileSkill | undefined> {
        const targetSkillsContainer = await this.getUserTargetSkills(requestedUserId, false, bossRelationCode);
        let response: UserSkillProfileSkill | undefined ;
        if (targetSkillsContainer != null) {
            response = targetSkillsContainer.userSkillProfileSkills.find(utsk => utsk.skillId === skillId);
        } 
        return response;
    }

    //This Method gets all Skills for a user with status requested, acccepted or declined
    public async getTargetSkillsForPerformanceCheck(requestedUserId: number, language: string, bossRelationCode: string): Promise<UserSkillProfileSkill[] | GtError> {
        const response = await SkillService.instance.getUserTargetSkillsForPerformanceCheck(language, requestedUserId, bossRelationCode);
        return response;
    }

    public clear(): void {
        super.clear();
    } 

    public isCacheExpired(): boolean {
        return false;
    }
}