import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from '$storage/models/converters/DateConverter';

/** Generic id, name class for some PaymentBookingOverviewResponse properties */
@JsonObject
export class PaymentBookingOverviewResponseTuple {
    @JsonProperty("item1", String)
    public id: number = 0;
    @JsonProperty("item2", String)
    public name: string = '';
}

/** Single response entry from the payment bookingOverview api call to fill paymentBookingOverview table */
@JsonObject
export class PaymentBookingOverviewEntry {

    @JsonProperty('paymentReference', String, false)
    public paymentReference: string = '';

    @JsonProperty('bookingReference', String, false)
    public bookingReference: string = '';

    @JsonProperty('paymentTypes', [String], false)
    public paymentTypes: string[] = [];

    @JsonProperty('voucherRefund', Number, false)
    public voucherRefund: number = 0;

    @JsonProperty('bookedLessons', [PaymentBookingOverviewResponseTuple], false)
    public bookedLessons: PaymentBookingOverviewResponseTuple[] = [];

    @JsonProperty('totalPrice', Number, false)
    public totalPrice: number = 0;

    @JsonProperty('currency', String, false)
    public currency: string = '';

    @JsonProperty('bookingDate', DateConverter, true)
    public bookingDate: Date | undefined = undefined;

    @JsonProperty('purchaser', PaymentBookingOverviewResponseTuple, false)
    public purchaser: PaymentBookingOverviewResponseTuple = new PaymentBookingOverviewResponseTuple();

    @JsonProperty('paymentStatus', String, false)
    public paymentStatus: string = '';

    @JsonProperty('paymentCompleted', Boolean, false)
    public paymentCompleted: boolean = false;

    @JsonProperty('voucherCode', String, false)
    public voucherCode: string = '';

    @JsonProperty('campaignTitle', String, false)
    public campaignTitle: string = '';

    /* Calculated properties */

    public get bookingDateDate(): Date | undefined {
        if (this.bookingDate) {
            return new Date(this.bookingDate.getFullYear(), this.bookingDate.getMonth(), this.bookingDate.getDate());
        }
        return this.bookingDate; // fallback
    }

    /**
     * Flag for kendo grid component to show the detail row component
     * @type {boolean}
     * @memberof PaymentBookingOverviewResponse
     */
    public isGridDetailExpanded: boolean;
}

/** Response from the payment bookingOverview api call to fill paymentBookingOverview table */
@JsonObject
export class PaymentBookingOverviewResponse {

    @JsonProperty('items', [PaymentBookingOverviewEntry], false)
    public items: PaymentBookingOverviewEntry[] = [];

    @JsonProperty('totalCount', Number, false)
    public totalCount: number = 0;
}