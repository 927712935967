import React from 'react';

import { InputText } from '$components/shared/InputText';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import ConfigService from '$src/core/Services/ConfigService';
import PasswordService from '$src/core/Services/PasswordService';
import Session from '$src/core/Session';
import { ERecoverLoginMode, ESubStatusCodes } from '$src/storage/models/enums';
import { NumberResponse } from '$src/storage/models/NumberRepsonse';
import { RecoverPasswordResponse } from '$src/storage/models/RecoverPasswordResponse';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { Redirect } from 'react-router';


interface IState {
    email: string;
    username: string;
    mobileOrEmail: string;
    redirectPIN: boolean;
    ErrorMessage: string;
    focusTo: '' | 'username' | 'email' | 'mobileOrEmail';
    showSuccessfullMessage: boolean;
    recoverMode: number;
}

interface IProps {
    onResetClick?: () => void;
}
// tslint:disable:object-literal-sort-keys
export class PasswordReset extends React.Component<IProps, IState> {
    constructor(props: {}) {
        super(props);
        this.state = { email: '', username: '', redirectPIN: false, ErrorMessage: '', focusTo: 'username', showSuccessfullMessage: false, mobileOrEmail: '', recoverMode: 2 };
    }

    public async UNSAFE_componentWillMount() {
        const recoverModeResponse = await ConfigService.instance.getPasswordRecoveryMode(Session.instance.getUserLanguageCodeOrFallBack);
        if (isSuccess<NumberResponse>(recoverModeResponse)) {
            this.setState({ recoverMode: recoverModeResponse.value });
        }
    }

    public render() {

        const usernameAttributes: InputAttributes =
        {
            id: 'txtUsername',
            label: 'PasswordReset:Username',
            value: this.state.username,
            class: 'box__login',
            isReadOnly: false,
            isRequired: false,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'username' || (this.state.ErrorMessage != null && this.state.ErrorMessage.length > 0),
            placeholder: '',
            editMode: true,
            autoComplete: 'username'
        };

        const emailAttributes: InputAttributes =
        {
            id: 'txtEmail',
            label: 'PasswordReset:Email',
            value: this.state.email,
            class: '',
            isReadOnly: false,
            isRequired: false,
            regExpression: globalConfig.loginProperties.mailAdressValidationExpression,
            hasFocus: this.state.focusTo === 'email',
            placeholder: '',
            editMode: true,
        };

        const mobileOrEmailAttributes: InputAttributes =
        {
            id: 'txtMobileOrEmail',
            label: 'PasswordReset:MobileOrEmail',
            value: this.state.mobileOrEmail,
            class: '',
            isReadOnly: false,
            isRequired: true,
            regExpression: globalConfig.loginProperties.mailAndPhoneNumberValidationExpression,
            hasFocus: this.state.focusTo === 'mobileOrEmail',
            placeholder: '',
            editMode: true,
        };

        let popUpContent;
        if (this.state.redirectPIN) {
            let domain = '';
            let username = '';

            const posSlash = this.state.username.indexOf('\\');
            if (posSlash >= 0) {
                domain = this.state.username.substr(0, posSlash);
                username = this.state.username.substr(posSlash + 1);
            } else {
                username = this.state.username;
                domain = globalConfig.loginProperties.defaultDomain;
            }

            popUpContent = (
                <Redirect push={true} to={'/pwchange?mode=pin&username=' + username + '&domain=' + domain} />
            );
        } else if (this.state.showSuccessfullMessage) {
            const successStyle: React.CSSProperties = {
                width: '80%',
            };

            popUpContent = (
                <div>
                    <div>
                        <span className={'input-message success'} style={successStyle}>
                            <Translate>PasswordReset:SuccessMessage</Translate>
                        </span>
                    </div>
                    <div>
                        <button type="button" className="btn--md" onClick={() => this.onButtonClickClose()}>
                            <Translate>PasswordReset:Close</Translate>
                        </button><br />
                    </div>
                </div>

            );
        } else {
            popUpContent = (
                <div className="password-reset__container">
                    <h1 className="heading__Level2"><Translate>PasswordReset:Credentials</Translate></h1>
                    <p><Translate>{this.state.recoverMode === 2 ? 'PasswordReset:Hint' : 'PasswordReset:HintPIN'}</Translate></p>
                    <br />
                    {this.state.ErrorMessage != null && this.state.ErrorMessage.length > 0 && 
                    <div className="box__reset__error">
                        <span className={'input-message error'}>
                            <Translate>{this.state.ErrorMessage}</Translate>
                        </span>
                    </div>
                    }
                    {this.state.recoverMode === 2 ?
                        this.renderEmailModeFields(usernameAttributes, emailAttributes) : this.renderPINModeFields(usernameAttributes, mobileOrEmailAttributes)}

                    <div className="modal__spread-buttons">
                        <button type="button" className="btn btn--sm" onClick={() => this.onButtonClickRecover()} >
                            <Translate>PasswordReset:ResetPW</Translate>
                        </button>
                        <button type="button" className="btn btn--sm marginRight-5" onClick={() => this.onButtonClickClose()} >
                            <Translate>PasswordReset:Cancel</Translate>
                        </button>
                    </div>
                </div>
            );
        }

        return popUpContent;
    }

    private renderEmailModeFields(usernameAttributes: InputAttributes, emailAttributes: InputAttributes) {
        return (
            <React.Fragment>
                <InputText
                    attributes={usernameAttributes}
                    onChange={(id, value) => this.onTextChangedUsername(id, value)}
                    hasError={this.state.ErrorMessage != null && this.state.ErrorMessage.length > 0}
                    errorMsg={this.state.ErrorMessage}
                />
                <InputText
                    attributes={emailAttributes}
                    onChange={(id, value) => this.onTextChangedEmail(id, value)}
                    type="email"
                />
            </React.Fragment>);
    }

    private renderPINModeFields(usernameAttributes: InputAttributes, emailOrMobile: InputAttributes) {
        return (
            <React.Fragment>
                <InputText
                    attributes={usernameAttributes}
                    onChange={(id, value) => this.onTextChangedUsername(id, value)}
                    hasError={this.state.ErrorMessage != null && this.state.ErrorMessage.length > 0}
                    errorMsg={this.state.ErrorMessage}
                />
                <InputText
                    attributes={emailOrMobile}
                    onChange={(id, value) => this.onTextChangedMobileOrEmail(id, value)}
                />
            </React.Fragment>);
    }

    private onTextChangedUsername(id: string, value: string) {
        const em = (value === '' && this.state.email === '') ? 'ErrorMessage:UserOrEmail' : '';
        this.setState({ username: value, ErrorMessage: em, focusTo: '' });
    }

    private onTextChangedEmail(id: string, value: string) {
        const em = (value === '' && this.state.username === '') ? 'ErrorMessage:UserOrEmail' : '';
        this.setState({ email: value, ErrorMessage: em, focusTo: '' });
    }

    private onTextChangedMobileOrEmail(id: string, value: string) {
        const em = (value === '' && this.state.username === '') ? 'ErrorMessage:EmailOrMobile' : '';
        this.setState({ mobileOrEmail: value, ErrorMessage: em, focusTo: '' });
    }

    private onButtonClickClose() {
        if (this.props.onResetClick !== undefined) {
            this.props.onResetClick(); // Pass event to parent
            this.setState({ showSuccessfullMessage: false });
        }
    }
    private async onButtonClickRecover() {
        if (this.checkRequiredValidity() === false) {
            this.setState({ ErrorMessage: 'ErrorMessage:UserOrEmail' });
        } else {
            const lang: string | null = Session.instance.languageCode;
            if (lang != null) {
                let domain = '';
                let username = this.state.username;
                let email = this.state.email;
                if (username !== '') {
                    const posSlash = username.indexOf('\\');
                    if (posSlash >= 0) {
                        domain = username.substr(0, posSlash);
                        username = username.substr(posSlash + 1);
                    }
                    if (posSlash === -1 && globalConfig.loginProperties && globalConfig.loginProperties.defaultDomain && domain === '') {
                        domain = globalConfig.loginProperties.defaultDomain;
                    }
                } else if (email !== '') { // If pw reset is with email only, pass the default domain                                    
                    const posSlash = email.indexOf('\\');
                    if (posSlash >= 0) {
                        domain = email.substr(0, posSlash);
                        email = email.substr(posSlash + 1);
                    } else if (posSlash === -1 && globalConfig.loginProperties && globalConfig.loginProperties.defaultDomain && domain === '') {
                        domain = globalConfig.loginProperties.defaultDomain;
                    } else {
                        domain = globalConfig.loginProperties.defaultDomain;
                    }
                }
                const response = await PasswordService.instance.recoverPassword(
                    lang,
                    username,
                    email,
                    this.state.mobileOrEmail,
                    domain);
                if (isSuccess<RecoverPasswordResponse>(response)) {
                    Session.instance.passwordRecoverTimestamp = response.timestamp;
                    if (response.mode === ERecoverLoginMode.SendPin) {
                        this.setState({ redirectPIN: true }); // Redirect to PW Change page 
                    } else {
                        this.setState({ showSuccessfullMessage: true });
                    }
                } else { // IF there was an error or an exception
                    if (response instanceof GtError) {
                        let errMsg: string = 'ErrorMessage:PasswordRecoveryFailed';
                        if (response.detailedObject !== undefined) {
                            errMsg = CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode);
                            if (response.detailedObject.subStatusCode === ESubStatusCodes.MethodNotAllowed_NewPinNotAllowed ||
                                response.detailedObject.subStatusCode === ESubStatusCodes.MethodNotAllowed_PWRecoveryCooldown) {
                                errMsg = Session.instance.storage.translation.GetString(errMsg).replace('{0}', response.detailedObject.message);
                            }
                        }
                        this.setState({ ErrorMessage: errMsg });
                    } else {
                        this.setState({ ErrorMessage: 'ErrorMessage:PasswordRecoveryFailed' });
                    }
                }

            }
        }
    }

    private checkRequiredValidity(): boolean {
        return (this.state.email !== '' || this.state.username !== '')
    }
}
