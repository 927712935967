import Session from '$core/Session';
import { StringHelper } from '$src/util/StringHelper';
import React from 'react';

interface IProps {
    args?: string[]; 
}

export class Translate extends React.Component<IProps> {

    constructor(props: IProps) {
        super(props)
    }

    public render() {
        let uniqueKey: string;
        let translatedValue: string;
        if (this.props.children === null || this.props.children === undefined) {
            uniqueKey = '';
        } else {
            uniqueKey = this.props.children.toString();
        }

        if (uniqueKey === '') {
            translatedValue = '';
        } else {

            translatedValue = this.props.args === undefined ?
                Session.instance.storage.translation.GetString(uniqueKey) :
                StringHelper.stringFormat( Session.instance.storage.translation.GetString(uniqueKey), this.props.args) 
        }

        if (this.containsHTMLTags(translatedValue)) {
            return (<React.Fragment>
                <span dangerouslySetInnerHTML={{ __html: translatedValue }} />
            </React.Fragment>
            );
        } else {
            return (<React.Fragment>
                {translatedValue}
            </React.Fragment>
            );
        }       
    }

    private containsHTMLTags(translation: string): boolean {
        let retVal = false;
        // tslint:disable-next-line:prefer-const 
        // let regex = new RegExp('(\<\w*)((\s\/\>)|(.*\<\/\w*\>))'); // TODO: Regex check doesn't work, if someone finds a solution, please implement      
        if (translation.includes('<') && translation.includes('>')) {
            retVal = true;
        } else {
            retVal = false;
        }
        return retVal;
    }

}
