import React from 'react';
import { Translate } from '$components/shared/Translate';
import { TrainingPlanLessonStatusIcon } from '$components/trainingPlan/TrainingPlanLessonStatusIcon';
import Session from '$core/Session';
import InlineSVG from 'react-inlinesvg';
import Iconfulfilled from '$resources/svgs/requirements/fulfilled.svg';
import IconnotFulfilled from '$resources/svgs/requirements/not-fulfilled.svg';
import { Tooltip } from '$components/shared/Tooltip';
import { ELessonStatus } from '$src/storage/models/enums';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';


interface IProps {
    completitionRequirementsStatus: ICompletitionRequirements[];
}

export interface ICompletitionRequirements {
    requiredLessonStatus: ELessonStatus;
    requiredScore: number;
    requiredItemSID?: string;
    requiredItemTitle: string;
    currentStatus: ELessonStatus;
    currentScore: number;
    fulfilled: boolean;
}


/**
 * Returns the language dependent info whether the given completion requirement is fulfilled
 * @param compReq 
 */
function getFulfillmentText(compReqFulfilled: boolean): string {
    const tr = Session.instance.storage.translation;
    return compReqFulfilled ? (tr.GetString('TrainingPlan:FulfilledYes')) : (tr.GetString('TrainingPlan:FulfilledNo'));
}


/**
 * Renders a row of the table containing one completion requirement and its status
 * @param compReq 
 * @param index 
 */
function renderRow(compReq: ICompletitionRequirements, index: number): JSX.Element[] | JSX.Element {
    const tr = Session.instance.storage.translation;
    const requiredLessonStatus = compReq.requiredLessonStatus;
    const requiredScore = compReq.requiredScore.toString() + '%';
    const tooltipId = `${compReq.requiredItemSID}_${index}_statusTooltip`;
    return (
        <tr key={index}>
            <td>{compReq.requiredItemTitle} </td>
            <td>
                <div className="inlineFlexAboveSm">
                    <TrainingPlanLessonStatusIcon
                        itemTitle={compReq.requiredItemTitle}
                        context={'completitionRequirements'}
                        lessonStatus={compReq.currentStatus}
                        includeText={false} /> 
                    {requiredScore === "0%" ? "" : compReq.currentScore + '%'}
                </div>
            </td>
            <td>
                <div className="inlineFlexAboveSm">
                    <TrainingPlanLessonStatusIcon
                        itemTitle={compReq.requiredItemTitle}
                        context={'targetCompletitionRequirements'}
                        lessonStatus={requiredLessonStatus}
                        includeText={false} />
                    {requiredScore === "0%" ? "" : requiredScore}
                </div>
            </td>
            <td aria-label={tr.GetString('TrainingPlan:Fulfilled') + ': ' + getFulfillmentText(compReq.fulfilled)}
                data-tip={tr.GetString('TrainingPlan:Fulfilled') + ': ' + getFulfillmentText(compReq.fulfilled)}
                data-for={tooltipId}>
                <div className="inlineFlexAboveSm">
                    {compReq.fulfilled === true ? (<InlineSVG src={Iconfulfilled} />) : (<InlineSVG src={IconnotFulfilled} />)}
                    <span className="marginLeft5AboveSm">{getFulfillmentText(compReq.fulfilled)}</span>
                </div>
                <Tooltip id={tooltipId} />
            </td>
        </tr>
    )
}


/**
 * Renders a table with the requirements of training plan or the active block (aka module).
 * @param props 
 */
export default function TrainingPlanCompletitionRequirements(props: IProps) {
    if (props.completitionRequirementsStatus.length > 0) {
        return (
            <>
                <table className="tp-table">
                    <thead>
                        <tr>
                            <th><Translate>TrainingPlan:Lesson</Translate></th>
                            <th><Translate>TrainingPlan:Status</Translate></th>
                            <th><Translate>TrainingPlan:minStatus</Translate></th>
                            <th><Translate>TrainingPlan:Fulfilled</Translate></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.completitionRequirementsStatus.map(
                            (compReq, index) => renderRow(compReq, index))}
                    </tbody>
                </table>
            </>
        );
    } else {
        return (
            <tr>
                <td colSpan={3}>
                    <NoDataFound message={Session.instance.storage.translation.GetString('TrainingPlan:NoCompletitionRequirements')} />
                </td>
            </tr>
        );
    }
}
