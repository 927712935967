import Logger from '$core/Logger';
import Session from '$core/Session';
import { PerformanceCheckTableItem } from '$src/components/Skills/PerformanceCheckItem';
import SkillService from '$src/core/Services/SkillService';
import GtError from '$src/util/GtError';
import SessionCache from '$storage/cache/SessionCache';
import { isSuccess } from '$util/Result';
import { EPerformanceCheckStatus, ESkillStatus, ESkillType, ETargetSkillStatus } from '$storage/models/enums';
import { Skill } from '$storage/models/Skill/Skill';
import { UserSkill } from '$storage/models/Skill/UserSkill';
import { UserSkillProfileSkill } from '$storage/models/SkillProfile/UserSkillProfileSkill';
import { StringHelper } from '$src/util/StringHelper';

const CACHING_DURATION = 300;

export default class SkillStorage extends SessionCache<number, Skill> {
    protected className = 'UserSkillStorage';
    protected loggerLocality = 'Storage.UserSkillStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getSkill(skillId: number): Promise<Skill | null> {
        const methodName = `${this.className}:getSkill()`;
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        if (this.isObjectInCache(skillId)) {
            Logger.log(this.loggerLocality, `${methodName} getting skill with skillId=${skillId}.`);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} getting skill from server, skillId=${skillId}.`);
            const response = await SkillService.instance.getSkill(skillId, language);

            if (isSuccess<Skill>(response)) {
                Logger.log(this.loggerLocality, `${methodName} got skill from server, skillId=${skillId}.`);
                this.saveObjectToCache(skillId, response);
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get skill from server, skillId=${skillId}.`);
                this.saveObjectToCache(skillId, new Skill());
            }
        }
        return this.getObjectFromCache(skillId);
    }

    //This Method gets all Skills for a user with status requested, acccepted or declined
    public async getSkillsForPerformanceCheck(requestedUserId: number, language: string, bossRelationCode?: string): Promise<PerformanceCheckTableItem[] | GtError> {
        const targetSkills = await SkillService.instance.getUserTargetSkillsForPerformanceCheck(language, requestedUserId, bossRelationCode);
        const userSkills = await SkillService.instance.getUserSkillsForPerformanceCheck(language, requestedUserId, bossRelationCode);
        const assignableSkills = await SkillService.instance.getAssignableSkills(language, bossRelationCode);
        const response: PerformanceCheckTableItem[] = [];

        if (isSuccess<UserSkillProfileSkill[]>(targetSkills)) {
            targetSkills.map(skill => {
                let allowedToEdit = false;
                if(isSuccess<Skill[]>(assignableSkills)) {
                    allowedToEdit = assignableSkills.find(sk => sk.id === skill.skillId) ? true : false;
                }
                response.push({
                    userId: skill.userId,
                    skillId: skill.skillId,
                    skillTitle: skill.skillTitle,
                    userSkillOrTargetSkillId: skill.userSkillTargetOrProfileSkillId,
                    requestDate: skill.requestDate,
                    approvalDate: skill.approvalDate ? skill.approvalDate : undefined,
                    status: this.convertTargetStatus(skill.targetSkillStatus),
                    skillRequiredLevel: skill.skillProfileSkillLevelTitle,
                    skillRequiredLevelId: skill.skillProfileSkillLevelId,
                    employeeComment: skill.requestReason,
                    bossComment: skill.responseReason,
                    skillUniqueKey: skill.skillId + "_" + ESkillType.TargetSkill,
                    skillType: ESkillType.TargetSkill,
                    bossName: skill.bossFirstName + " " + skill.bossLastName,
                    allowedToEdit,
                    attributes: []
                })
            }
            )
        }

        if (isSuccess<UserSkill[]>(userSkills)) {
            
            userSkills.map(skill => {
                let allowedToEdit = false;
                if(isSuccess<Skill[]>(assignableSkills)) {
                    allowedToEdit = assignableSkills.find(sk => sk.id === skill.skillId) ? true : false;
                }
                response.push({
                    userId: skill.userId,
                    skillId: skill.skillId,
                    skillTitle: skill.skillTitle,
                    userSkillOrTargetSkillId: skill.userSkillId,
                    status: this.convertStatus(skill.skillStatus),
                    skillRequiredLevel: skill.skillLevelTitle,
                    skillRequiredLevelId: skill.skillLevelId,
                    requestDate: skill.requestDate,
                    approvalDate: this.checkDate(skill.requestDate, skill.dateAcquired),
                    employeeComment: skill.requestReason,
                    bossComment: skill.responseReason,
                    skillUniqueKey: skill.skillId + "_" + ESkillType.ActualSkill,
                    skillCertificates: skill.userSkillCertificates,
                    skillType: ESkillType.ActualSkill,
                    bossName: skill.bossFirstName + " " +skill.bossLastName,
                    allowedToEdit,
                    attributes: skill.attributes
                })
            }
            )
        }
        return response;
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }

    private checkDate(requestedDate: Date, dateAcquired: Date) {
        const request = StringHelper.dateTimeString(requestedDate);
        const approval = StringHelper.dateTimeString(dateAcquired);
        if (request === approval) {
            return undefined;
        } else {
            return dateAcquired;
        }
    }

    private convertTargetStatus(oldStatus: ETargetSkillStatus): EPerformanceCheckStatus {
        switch (oldStatus) {
            case ETargetSkillStatus.Requested:
                return EPerformanceCheckStatus.Requested;
            case ETargetSkillStatus.Accepted:
                return EPerformanceCheckStatus.Accepted;
            case ETargetSkillStatus.Declined:
                return EPerformanceCheckStatus.Declined;

            default:
                return EPerformanceCheckStatus.Requested;
        }
    }

    private convertStatus(oldStatus: ESkillStatus): EPerformanceCheckStatus {
        switch (oldStatus) {
            case ESkillStatus.Requested:
                return EPerformanceCheckStatus.Requested;
            case ESkillStatus.Valid:
            case ESkillStatus.Expired:
            case ESkillStatus.ExpiredButValid:
                return EPerformanceCheckStatus.Accepted;
            case ESkillStatus.Declined:
                return EPerformanceCheckStatus.Declined;

            default:
                return EPerformanceCheckStatus.Requested;
        }
    }
}