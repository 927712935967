import React from 'react';

import Iconoff from '$resources/svgs/favorites/watchlist-off.svg';
import Iconon from '$resources/svgs/favorites/watchlist-on.svg';

import Session from '$core/Session';
import { WatchedItemModel } from '$storage/models/WatchedItemModel';
import { isSuccess } from '$util/Result';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    itemId: number;
    refreshKey: number;
    itemTitle?: string;
}

interface IState {
    dataLoaded: boolean;
    isWatched: boolean;
    refreshKey: number;
}
export class WatchlistRibbon extends React.Component<IProps, IState> {

    protected loggerLocality = 'components.Watchlist.WatchlistRibbon';

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataLoaded: false,
            isWatched: false,
            refreshKey: this.props.refreshKey
        }

        this.onToggle = this.onToggle.bind(this);
    }

    public  UNSAFE_componentWillMount() {
        this.loadData();
    }

    public render(): JSX.Element {
        if (this.state.refreshKey !== this.props.refreshKey) {
            this.loadData();
        }
        if (this.state.dataLoaded) {
            const hoverText = this.state.isWatched ?
            Session.instance.storage.translation.GetString('Watchlist:Remove') + ': ' + this.props.itemTitle :
            Session.instance.storage.translation.GetString('Watchlist:Add') + ': ' + this.props.itemTitle;
            return (
                <div className="watchlist__ribbon">
                    <button className="watchlist__toolbar-icon"
                        aria-label={hoverText}
                        data-tip={hoverText}
                        data-for={`${this.props.itemId}_watchListIconTooltip`}
                        onClick={(e) => this.onToggle(e)}>
                        <InlineSVG src={this.state.isWatched ? Iconon : Iconoff } />
                        <Tooltip id={`${this.props.itemId}_watchListIconTooltip`} place="bottom" />
                    </button>
                </div>);
        } else {
            return <React.Fragment />;
        }
    }

    public async onToggle(event: React.FormEvent<HTMLButtonElement>) {
        event.stopPropagation();
        event.preventDefault();
        if (this.state.isWatched) {
            await Session.instance.storage.watchList.RemoveSubscriber(this.props.itemId);
            this.setState({ isWatched: false });
        } else {
            const response = await Session.instance.storage.watchList.AddSubscriber(this.props.itemId);

            if (isSuccess<WatchedItemModel >(response)) {
                this.setState({ isWatched: true });
            } else {
                this.setState({ isWatched: false });
            }
        }
    }

    private loadData() {
        const response:  WatchedItemModel  | null = Session.instance.storage.watchList.GetSubscriptionStatus(this.props.itemId);
        if (response != null) {
            this.setState({ isWatched: true, dataLoaded: true, refreshKey: this.props.refreshKey  });
        } else {
            this.setState({ isWatched: false, dataLoaded: true, refreshKey: this.props.refreshKey });
        }

    }
}