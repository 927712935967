import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseRegistrationDoneRequest {

    @JsonProperty('registrationId', Number, false)
    public registrationId: number;

    @JsonProperty('externalCourseRegistrationDone', Boolean, false)
    public externalCourseRegistrationDone: boolean;
}