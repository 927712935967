import Logger from '$src/core/Logger';
import requestPromise from 'request-promise';
import Session from '../Session';
import { Notification } from '$storage/models/InAppNotifications/Notification'

export default class InAppNotificationService {
    protected static _instance: InAppNotificationService | null = null;

    protected _serviceUrl: string;

    protected className = 'InAppNotificationService';
    protected loggerLocality = 'InAppNotificationService';


    /**
     * Implement Singleton pattern.
     */
    public static get instance(): InAppNotificationService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Initialize service client.
     * @param protocol http or https
     * @param hostname of the web API server
     * @param port PortSuffix to use (e.g. ':2020' or '' if none)
     * @param baseUrl Base URL to use, i.e. the part of the URL between hostname and API name
     */
    public init(serviceUrl: string): void {
        const methodName = `${this.className}:init()`;
        this._serviceUrl = serviceUrl + "/api/";
        Logger.log(this.loggerLocality, `${methodName} service url is ${this._serviceUrl}`);
    }

    /**
     * Returns the base url of the service application
     * @memberof SsoServiceProviderService
     */
    public getUrl(): string {
        const url: string = `${this._serviceUrl}`;
        return url;
    }


    public async getNotifications(): Promise<Notification[]> {
        let response: Notification[];

        try {
            const options = {
                uri: `${this._serviceUrl}` + 'notifications',
                headers: {
                    Authorization: 'Bearer ' + Session.instance.jwtToken
                }
            };
            const res = await requestPromise.get(options);
            response = JSON.parse(res);
        } catch (error) {
            Logger.log(this.loggerLocality, error);
            response = [];
        }

        return response;
    }

    public async setNotificationAsRead(notificationId: number): Promise<boolean> {
        let response: boolean = false;
        try {
            const options = {
                uri: `${this._serviceUrl}` + 'notifications/' + notificationId,
                headers: {
                    Authorization: 'Bearer ' + Session.instance.jwtToken
                }
            };
            const res = await requestPromise.put(options);
            if (res == 'true') {
                response = true;
            }
        } catch (error) {
            response = false;
        }
        return response;
    }
}