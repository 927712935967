import { JsonObject, JsonProperty } from 'json2typescript';

/** Request filter data for payment booking journal */
@JsonObject
export class BookingJournalFilterRequest {
	@JsonProperty
	bookingReference?: string;

	@JsonProperty
	paymentType?: number;	

	@JsonProperty
	purchaserId?: number;
	
	@JsonProperty
	bookingDateFrom?: Date;
	
	@JsonProperty
	bookingDateTo?: Date;
	
	@JsonProperty
	lessonId?: number;
	
	@JsonProperty
	classDateFrom?: Date;
	
	@JsonProperty
	classDateTo?: Date;
	
	@JsonProperty
	registrationStatus?: number;
	
	@JsonProperty
	company?: string;
	
	@JsonProperty
	userId?: number;
}