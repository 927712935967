import { JsonObject, JsonProperty } from 'json2typescript';

/**
 *This Model is for showing the Users for MailTo
 */
@JsonObject
export class UserMailLink {

    /** UserId  */
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** FirstName of the user  */
    @JsonProperty('userFirstName', String, false)
    public userFirstName: string = "";

    /** LastName of the user*/
    @JsonProperty('userLastName', String, false)
    public userLastName: string = "";

    /** User E-Mail */
    @JsonProperty('userMail', String, false)
    public userMail: string = "";

    /** Username */
    @JsonProperty('username', String, false)
    public username: string = "";


    /** this field is for showing the user in the link */
    public forMailLink: boolean = true;

}
