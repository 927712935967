import { JsonObject, JsonProperty } from 'json2typescript';
import { FileSharingContext } from '$src/storage/models/FileSharing/FileSharingContext';
import { FileSharingTag } from '$src/storage/models/FileSharing/FileSharingTag';
import { FileSharingUser } from '$src/storage/models/FileSharing/FileSharingUser';

/**
 * Persistent object containing the settings of an FileSharing lesson and the documents grouped by users
 * @export
 * @class FileSharingLesson
 */
@JsonObject
export class FileSharingLesson {

    @JsonProperty('studentMayDownloadStudentFiles', Boolean, false)
    public studentMayDownloadStudentFiles: boolean = false;

    @JsonProperty('studentMayDownloadTutorFiles', Boolean, false)
    public studentMayDownloadTutorFiles: boolean = false;

    @JsonProperty('studentMayModifyStudentFiles', Boolean, false)
    public studentMayModifyStudentFiles: boolean = false;

    @JsonProperty('studentMayModifyTutorFiles', Boolean, false)
    public studentMayModifyTutorFiles: boolean = false;

    @JsonProperty('tutorMayDownloadStudentFiles', Boolean, false)
    public tutorMayDownloadStudentFiles: boolean = false;

    @JsonProperty('tutorMayModifyStudentFiles', Boolean, false)
    public tutorMayModifyStudentFiles: boolean = false;

    @JsonProperty('documementsAtTPlanlevel', Boolean, false)
    public documementsAtTPlanlevel: boolean = false;

    @JsonProperty('allowedFileExtensions', [String], false)
    public allowedFileExtensions: string[] = [];

    @JsonProperty('allowedFileExtensionsString', String, false)
    public allowedFileExtensionsString: string = '';

    @JsonProperty('users', [FileSharingUser], false)
    public users: FileSharingUser[] = [];

    @JsonProperty('context', FileSharingContext, false)
    public context: FileSharingContext = new FileSharingContext();

    @JsonProperty('studentSpaceQuota', Number, false)
    public studentSpaceQuota: number = 0;

    @JsonProperty('availableFileSharingTags', [FileSharingTag])
    public availableFileSharingTags: FileSharingTag[] = [];
}