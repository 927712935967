import { JsonObject, JsonProperty } from 'json2typescript';
import { LessonStatusConverter } from './converters/LessonStatusConverter';
import { ELessonStatus } from './enums';

/**
 *This Model is holding the data for the groups
 */
@JsonObject
export class EvaluationOfTplanData {

    /** itmId  */
    @JsonProperty('itmId', Number, false)
    public itmId: number = 0;

    /** itmGuid*/
    @JsonProperty('itmGuid', String, false)
    public itmGuid: string = "";

    /** tplanTitle*/
    @JsonProperty('tplanTitle', String, false)
    public tplanTitle: string = "";

    /** sceId*/
    @JsonProperty('sceId', Number, false)
    public sceId: number = 0;

    /** sceCode*/
    @JsonProperty('sceCode', String, false)
    public sceCode: string = "";

    /** sceTitle*/
    @JsonProperty('sceTitle', String, false)
    public sceTitle: string = "";

    /** sceGuid*/
    @JsonProperty('sceGuid', String, false)
    public sceGuid: string = "";

    @JsonProperty('tpeId', Number, false)
    public tpeId: number = 0;

    @JsonProperty('tpeType', Number, false)
    public tpeType: number = 0;

    @JsonProperty('isTpeModule', Boolean, false)
    public isTpeModule: boolean = false;

    /** tPElementTitle*/
    @JsonProperty('tPElementTitle', String, false)
    public tPElementTitle: string = "";

    /** UserId*/
    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    /** userName*/
    @JsonProperty('userName', String, false)
    public userName: string = "";

    /** userDisplayName (firstName and lastName combined)*/
    @JsonProperty('userDisplayName', String, false)
    public userDisplayName: string = "";

    /** firstName*/
    @JsonProperty('firstName', String, false)
    public firstName: string = "";

    /** lastName*/
    @JsonProperty('lastName', String, false)
    public lastName: string = "";

    /** email*/
    @JsonProperty('email', String, false)
    public email: string = "";

    /** Current user's lesson status. */
    @JsonProperty('lessonStatus', LessonStatusConverter, false)
    public lessonStatus: ELessonStatus = ELessonStatus.Undefined;

    /** Current user's module status. */
    @JsonProperty('eTPBlockLessonStatus', LessonStatusConverter, false)
    public eTPBlockLessonStatus: ELessonStatus = ELessonStatus.Undefined;

    public lessonStatusTranslated: string = ''

    public eTPBlockLessonStatusTranslated: string = ''


}
