import Logger from '$src/core/Logger';
import AttributeService from '$src/core/Services/AttributeService';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import SessionCache from '$storage/cache/SessionCache';
import { isSuccess } from '$util/Result';

const CACHING_DURATION = 300;

export default class AttributeValueStorage extends SessionCache<number, AttributeValue[]> {
    protected className = 'AttributeValueStorage';
    protected loggerLocality = 'Storage.AttributeValueStorage';        

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    } 

    /**
     * Get attribute values of an attribute type from cache or server.
     * @param typeId of the attribute type.
     */
    public async getAttributeValuesAsync(typeid: number): Promise<AttributeValue[] | null> {

        const methodName = `${this.className}:getAttributeValuesAsync()`;

        if (this.isObjectInCache(typeid)) {
            Logger.log(this.loggerLocality, `${methodName} getting attribute values from cache, typeid=${typeid}.`);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} getting attribute values from server, typeid=${typeid}.`);
            const response = await AttributeService.instance.getAttributeValues(typeid);

            if (isSuccess<AttributeValue[]>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality, `${methodName} got attribute values from server, typeid=${typeid}, number of attributes=${elementCount}.`);
                this.saveObjectToCache(typeid, response);
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get attribute values from server, typeid=${typeid}.`);
                this.saveObjectToCache(typeid, new Array<AttributeValue>());
            }
        }
        return this.getObjectFromCache(typeid);
    }

    public clear(): void {
        super.clear();
    }    

    /**
     * Get a specific attribute value of an attribute type from cache.
     * @param typeId of the attribute type.
     * @param id of the attribute value. 
     */
    public getAttributeValue(typeid: number, id: number): AttributeValue | null {

        let attributeValues: AttributeValue[] | null;
        let attributeValue: AttributeValue | undefined;

        attributeValues = this.getObjectFromCache(typeid);

        if (attributeValues !== null) {
            attributeValue = attributeValues.find(f => f.id === id);
        }

        if (attributeValue === undefined) {
            attributeValue = new AttributeValue()
        }

        return attributeValue
    }

    public isCacheExpired(): boolean {
        return false;
    }
}
