import PowerBiReport from '$src/components/powerBiReports/PowerBiReport';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import PowerBiService from '$src/core/Services/PowerBiService';
import React, { useEffect, useState } from 'react';
import { Translate } from '$src/components/shared/Translate';

interface IProps {
    parentHeadingLevel: number;
}

/**
 * Component that renders all available Power Bi reports
 */
export default function PowerBiReportList(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [reports, setReports] = useState<string[]>([]);

    useEffect(() => {
        if (!isLoaded) {
            PowerBiService.instance.getAvailableReports().then((apiValue) => {
                setReports(apiValue as Array<string>);
                setIsLoaded(true);
            });            
        }
    }, []);

    // return ProgressSpinner, if not loaded yet
    if (!isLoaded) {
        return <ProgressSpinner />
    }
    // return info message, if no reports available
    if (!reports.length) {
        return (
            <div className="l-container">
                <Translate>{"Report:PowerBiNoReports"}</Translate>
            </div>
        )
    }
    // default return reports
    return (        
        <>
            {
                reports.map((name) => <PowerBiReport key={name} name={name} parentHeadingLevel={props.parentHeadingLevel} />)
            }
        </>
    )
}