import React, { useState } from 'react';

import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import Session from '$src/core/Session';
import { NumberHelper } from '$src/util/NumberHelper';
import ShoppingBasketItemUser from '$src/storage/models/ShoppingBasket/ShoppingBasketItemUser';

import { Translate } from '$components/shared/Translate';
import { ModalPopup } from '$components/shared/ModalPopup';
import GTButton from '$components/shared/Atoms/GTButton';
import GTTooltipIcon from '$components/shared/Atoms/GTTooltipIcon';
import ShoppingBasketItemParticipantVoucher from '$components/shoppingBasket/ShoppingBasketItemParticipantVoucher';

import IconDelete from '$resources/svgs/misc/delete.svg';
import IconGroupDiscount from '$resources/svgs/shoppingBasket/discount_group.svg';
import IconVoucherDiscount from '$resources/svgs/shoppingBasket/discount_voucher.svg';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`${str}`);
}
interface IShoppingBasketItemParticipantsProps {
	item: ShoppingBasketItem;
	onDeleteClicked: (userId: number) => void;
	onParticipantsUpdated: (updatedItem: ShoppingBasketItem) => void;
	isRecalculating: boolean;
}

/**
 * Renders the booked users or nothing if booked users are empty
 * @param props 
 * @returns 
 */
export default function ShoppingBasketItemParticipants(props: IShoppingBasketItemParticipantsProps) {
	const [openVoucherModal, setOpenVoucherModal] = useState<undefined | ShoppingBasketItemUser>(); // user to enter voucher code

	function assignVoucher(voucher: string) {
		setOpenVoucherModal(undefined);
		const users = props.item.bookUsers?.map(user => {
			if (user.userId === openVoucherModal?.userId) {
				user.price.voucherCode = voucher;
			}
			return user;
		});
		props.onParticipantsUpdated({ ...props.item, bookUsers: users });
	}

	const deleteTooltipText = getTranslation('ShoppingBasketContent:DeleteParticipant');

	const GroupDiscountIcon = (props: {discount: number; currency: string}) => {
		const css = props.discount > 0 ? '' : 'shopping-basket-content__participant-grid-inactive-icon'; 		
		return <GTTooltipIcon src={IconGroupDiscount} className={css} text={getTranslation('ShoppingBasketContent:ParticipantGroupDiscount') + ' ' + NumberHelper.getFormattedPrice(props.discount, props.currency)} size={23} />
	}
	const VoucherDiscountIcon = (props: {discount: number; currency: string}) => {
		const css = props.discount > 0 ? '' : 'shopping-basket-content__participant-grid-inactive-icon'; 		
		return <GTTooltipIcon src={IconVoucherDiscount} className={css} text={getTranslation('ShoppingBasketContent:ParticipantVoucherDiscount') + ' ' + NumberHelper.getFormattedPrice(props.discount, props.currency)} size={23} />
	}

	return props.item.bookUsers && props.item.bookUsers.length > 0 ? (
		<>
			<div className='shopping-basket-content__participant-grid-header'>
				<div><Translate>ShoppingBasketContent:Participants</Translate></div>
				<div></div>
				<div><Translate>ShoppingBasketContent:ParticipantVoucher</Translate></div>
				<div className='shopping-basket-content__participant-grid-price-cell'>
					<Translate>ShoppingBasketContent:ParticipantOriginalPrice</Translate>
				</div>
				<div className='shopping-basket-content__participant-grid-price-cell'>
					<Translate>ShoppingBasketContent:ParticipantPrice</Translate>
				</div>
			</div>
			<div className='shopping-basket-content__participant-grid'>
				{
					props.item.bookUsers.map((user, index) => {
						return (
							<React.Fragment key={index}>
								<div>
									{user.display}									
								</div>
								<div>									
									<GTTooltipIcon src={IconDelete} text={deleteTooltipText} size={23} className='shopping-basket-content__participant-grid-delete-icon-button' onClick={() => props.onDeleteClicked(user.userId)} />
									<GroupDiscountIcon discount={user.price?.groupDiscountSum} currency={props.item.currency}/>
									<VoucherDiscountIcon discount={user.price?.voucherDiscountSum} currency={props.item.currency}/>
								</div>
								<div>
									<GTButton id='enterCode' isIconButton={true} onClick={() => setOpenVoucherModal(user)} tooltipText={user.price?.voucherCode && NumberHelper.getFormattedPrice(user.price.voucherDiscountSum, props.item.currency)} additionalClassNames={user.price?.voucherCode && 'shopping-basket-content__participant-grid-active-voucher'}>
										{user.price?.voucherCode ? user.price.voucherCode : getTranslation('ShoppingBasketContent:EnterCodeButton')}
									</GTButton>
								</div>
								<div className='shopping-basket-content__participant-grid-price-cell'>
									{NumberHelper.getFormattedPrice(user.price?.originalPrice, props.item.currency)}
								</div>
								<div className='shopping-basket-content__participant-grid-price-cell'>
									{props.isRecalculating ? '...' : NumberHelper.getFormattedPrice(user.price?.price, props.item.currency)}
								</div>
							</React.Fragment>
						);
					})
				}
			</div>

			{
				openVoucherModal &&
				<ModalPopup isOpen={true} onRequestClose={() => setOpenVoucherModal(undefined)}>
					<ShoppingBasketItemParticipantVoucher user={openVoucherModal} itemId={props.item.itemId} onVoucherVerified={(e) => assignVoucher(e)} onCancel={() => setOpenVoucherModal(undefined)} />
				</ModalPopup>
			}
		</>
	) : <></>;
}