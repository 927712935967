import { JsonObject, JsonProperty } from 'json2typescript';

/** Response of any SearchSelectBox search action */
@JsonObject
export class SearchSelectBoxResponse {
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('sid', String, false)
    public sId: string = '';

	@JsonProperty('display', String, false)
    public display: string = '';
}