import React, { useEffect, useState } from 'react';
import Iconglossary from '$resources/svgs/glossary/glossary.svg';
import GlossaryService from '$src/core/Services/GlossaryService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { isSuccess } from '$src/util/Result';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    itemId: number;
}

export function GlossaryRibbon(props: IProps) {
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {
        GlossaryService.instance.isGlossaryAssignedToItem(props.itemId).then(response => {
            if (isSuccess<BooleanResponse>(response)) {
                setIsVisible(response.status);
            }
        })
    }, [props.itemId]);


    if (isVisible) {
        return (
            <div
                className="glossary__ribbon"
                data-tip={Session.instance.storage.translation.GetString('Glossary:HasGlossaries')}
                data-for={`${props.itemId}_glossaryIconTooltip`}
                aria-label={Session.instance.storage.translation.GetString('Glossary:HasGlossaries')}>
                <InlineSVG src={Iconglossary} />
                <Tooltip id={`${props.itemId}_glossaryIconTooltip`} place="bottom" delayShow={500} />
            </div>);
    } else {
        return <React.Fragment />;
    }

}