import { Translate } from '$src/components/shared/Translate';
import F2FService from '$src/core/Services/F2FService';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import React from 'react';

interface IF2FVirtualMeetingSingleLink {
  virtualMeetingLink: string;
  participationAutoConfirmationForVCG: boolean;
  registrationId: number;
  linkText: string;
  linkClass: string;
  linkTitle: string | undefined;
  itemContext: EItemDetailCallerContextType;
  parentTrainingPlanId?: number;
}

/**
 * 
 * @param Props 
 * @returns A clickable Link to join the Virtual Meeting
 */
export const VirtualMeetingSingleLink: React.FC<IF2FVirtualMeetingSingleLink> = (Props) => {

  /**
   * Auto Confirms the Attendance of the Participant if set
   * @param registrationId Corresponding Registration ID
   * @param autoConfirmAttendance Auto Confirm Attendance
   * @returns true
   * 
   */
  const onJoinLinkClick = async (registrationId: number, autoConfirmAttendance: boolean) => {
    if (autoConfirmAttendance && registrationId > 0) {
      await F2FService.instance.setLessonStatusCompletedAndHasAttendedForVirtualMeeting(registrationId, Props.itemContext, Props.parentTrainingPlanId);
    }
    return true; // Return true to follow the link
  }

  return (
    <a
      onClick={() =>
        onJoinLinkClick(Props.registrationId, Props.participationAutoConfirmationForVCG)}
      href={Props.virtualMeetingLink}
      className={Props.linkClass}
      target="_blank"
      rel="noopener noreferrer"
      title={Props.linkTitle}>
      <Translate>{Props.linkText}</Translate>
    </a>
  );

}


