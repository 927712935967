import React, { useEffect, useState } from "react";
import PerformanceCheckTable from "$src/components/Skills/PerformanceCheckTable";
import Session from "$src/core/Session";
import SkillService from "$src/core/Services/SkillService";
import { Skill } from "$src/storage/models/Skill/Skill";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { Translate } from "$src/components/shared/Translate";
import { isSuccess } from "$src/util/Result";
import { NoDataFound } from "$src/components/shared/WarningsAndErrors/NoDataFound";
import { AddUserTargetSkillRequest } from "$src/storage/models/RequestObjects/AddUserTargetSkillRequest";
import { ItemSkillLevelTranslatedDisplay } from "$src/storage/models/Skill/ItemSkillLevelTranslatedDisplay";
import GtError from "$src/util/GtError";
import { ErrorMessage } from "$src/components/shared/WarningsAndErrors/ErrorMessage";
import { Heading } from '$src/components/shared/Heading';
import { TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { EFileType, ESkillType } from "$src/storage/models/enums";
import { UserSkillRequest } from "$src/storage/models/RequestObjects/UserSkillRequest";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import { BreadCrumbElement } from "$src/storage/models/BreadCrumbElement";
import { PerformanceCheckTableItem } from "$components/Skills/PerformanceCheckItem";
import { FileDataRequest } from "$src/storage/models/RequestObjects/FileDataRequest";
import GTFileUpload from "$components/shared/Molecules/GTFileUpload";
import { ProgressSpinner } from "$components/shared/ProgressSpinner";

/**
 * Component that renders the skill request dialog
 */
export default function MyPerformanceCheckSkillRequest() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [skills, setSkills] = useState<Skill[]>([]);
    const [filteredSkills, setFilteredSkills] = useState<Skill[]>([]);
    const [selectedSkill, setSelectedSkill] = useState<Skill | undefined>();
    const [skillLevels, setSkillLevels] = useState<ItemSkillLevelTranslatedDisplay[]>([]);
    const [selectedSkillLevel, setSelcetedSkillLevel] = useState<ItemSkillLevelTranslatedDisplay | null>();
    const [isError, setIsError] = useState<boolean>(false);
    const [skillComment, setSkillComment] = useState<string>("");
    const [selectedSkillTyp, setSelectedSkillTyp] = useState<ESkillType>();
    const [selectedSkillTypId, setSelectedSkillTypId] = useState<ESkillType>();
    const [commentString, setCommentString] = useState<string>(Session.instance.storage.translation.GetString('PerformanceCheck:CommentField'));
    const [skillsForPerformance, setSkillsForPerformance] = useState<PerformanceCheckTableItem[]>([]);
    const [isCommentForTargetRequired] = useState<boolean | undefined>(globalConfig.performanceCheck.isRequestReasonRequiredForTargetSkill);
    const [isCommentForActualRequired] = useState<boolean | undefined>(globalConfig.performanceCheck.isRequestReasonRequiredForActualSkill);
    const [isFileUploadRequiredForActualSkill] = useState<boolean | undefined>(globalConfig.performanceCheck.isFileUploadRequiredForActualSkill);
    const [isFileUploadRequiredForTargetSkill] = useState<boolean | undefined>(globalConfig.performanceCheck.isFileUploadRequiredForTargetSkill);
    const [requestFiles, setRequestFiles] = useState<FileDataRequest[]>([]);
    const [requestFileLabel, setRequestFileLabel] = useState<string>(Session.instance.storage.translation.GetString('PerformanceCheck:FileUploadCertMiscFile'));
    const [showFileUpload, setShowFileUpload] = useState<boolean>(true);
    const [sendRequestPending, setSendRequestPending] = useState<boolean>(false);
    const [isButtonDependingOnFilesDisabled, setIsButtonDependingOnFilesDisabled] = useState<boolean>(false);

    const supportedFiletypesWithTranslation = [
        { id: EFileType.Undefined, text: Session.instance.storage.translation.GetString('FileUpload:ChooseFileType') },
        { id: EFileType.Certificate, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeCertificate') },
        { id: EFileType.Miscellaneous, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeMiscellaneous') },
    ]

    useEffect(() => {
        document.title = globalConfig.appProperties.title + ' - ' + Session.instance.storage.translation.GetString("PerformanceCheck:RequestTitle")
    });

    // Load data on first rendering and after request a skill
    useEffect(() => {
        if (!isLoaded) {
            (async function loadSkills() {
                const assignableSkills = await SkillService.instance.getAssignableSkills(Session.instance.getUserLanguageCodeOrFallBack);
                if (isSuccess<Skill[]>(assignableSkills)) {
                    setSkills(assignableSkills);
                    setFilteredSkills(assignableSkills);
                }
                const skillsForTable = await Session.instance.storage.skill.getSkillsForPerformanceCheck(Session.instance.loginUser!.id, Session.instance.getUserLanguageCodeOrFallBack);
                if (isSuccess<PerformanceCheckTableItem[]>(skillsForTable)) {
                    setSkillsForPerformance(skillsForTable);
                }
                setIsLoaded(true);
            })();
        }
    }, [isLoaded]);

    async function onAssignClick() {
        setSendRequestPending(true);
        if (selectedSkillTypId === ESkillType.ActualSkill) {
            const requestObj: UserSkillRequest = {
                skillLevelId: selectedSkillLevel!.id,
                skillId: selectedSkill!.id,
                requestReason: skillComment,
                fileCollection: requestFiles,
            }
            const response = await SkillService.instance.requestUserSkill(requestObj);
            if (!response) {
                setIsError(true)
            }
        } else {
            const requestObj: AddUserTargetSkillRequest = {
                skillLevelId: selectedSkillLevel!.id,
                skillId: selectedSkill!.id,
                requestReason: skillComment
            }
            const response = await SkillService.instance.addUserTargetSkill(requestObj);
            if (!response) {
                setIsError(true);
            }
        }
        setSelectedSkill(undefined);
        setSelcetedSkillLevel(null);
        setSelectedSkillTyp(undefined);
        setSelectedSkillTypId(undefined);
        setSkillComment("");
        setSkillLevels([]);
        setSkills([]);
        setFilteredSkills([]);
        setRequestFiles([]);
        setIsLoaded(false);
        setSendRequestPending(false);
    }

    async function loadSkillLevels(skillLevelSetId: number) {
        const response: ItemSkillLevelTranslatedDisplay[] | GtError = await SkillService.instance.getSkillLevelsTranslated(Session.instance.getUserLanguageCodeOrFallBack,
            skillLevelSetId)

        if (isSuccess<ItemSkillLevelTranslatedDisplay[]>(response)) {
            setSkillLevels(response);
        }
    }

    function onChange(e: DropDownListChangeEvent) {
        const response = skills.find(sk => sk.id === e.target.value.id);
        setSelcetedSkillLevel(null);
        setSkillLevels([]);
        setSelectedSkill(response);
        loadSkillLevels(response!.levelSetId);
    }

    function onChangeLevel(e: DropDownListChangeEvent) {
        setSelcetedSkillLevel(e.target.value);
    }


    function isButtonDisabled() {
        if (selectedSkillTypId === ESkillType.ActualSkill) {
            if ((isCommentForActualRequired && skillComment.length === 0) ||
                (isFileUploadRequiredForActualSkill && requestFiles.length === 0)) {
                return true;
            } else {
                return (selectedSkill == null || selectedSkillLevel == null);
            }
        }
        else if (selectedSkillTypId === ESkillType.TargetSkill) {
            if ((isCommentForTargetRequired && skillComment.length === 0) ||
                isFileUploadRequiredForTargetSkill && requestFiles.length === 0) {
                return true;
            } else {
                return (selectedSkill == null || selectedSkillLevel == null);
            }
        } else {
            return true;
        }
    }
    function filterData(filter: string) {

        if (filter !== '') {
            setFilteredSkills(skills?.filter((e) => e.skillTitle.toLowerCase().includes(filter.toLowerCase())))
        } else {
            setFilteredSkills(skills);
        }
    }

    function onCommentChange(e: TextAreaChangeEvent) {
        setSkillComment(e.value.toString());
    }

    function onChangeTyp(e: DropDownListChangeEvent) {
        setSelectedSkillTyp(e.target.value);
        setSelectedSkillTypId(e.target.value.id);
        setShowFileUpload(true);
        if (e.target.value.id === ESkillType.ActualSkill && isCommentForActualRequired || e.target.value.id === ESkillType.TargetSkill && isCommentForTargetRequired) {
            setCommentString(Session.instance.storage.translation.GetString('PerformanceCheck:CommentField') + "*");
        } else {
            setCommentString(Session.instance.storage.translation.GetString('PerformanceCheck:CommentField'));
        }
        if (e.target.value.id === ESkillType.ActualSkill && isFileUploadRequiredForActualSkill) {
            setRequestFileLabel(Session.instance.storage.translation.GetString('PerformanceCheck:FileUploadCertMiscFile') + "*");
        } else if (e.target.value.id === ESkillType.TargetSkill) {
            setShowFileUpload(false);
        }
    }

    function onFileChange(files: FileDataRequest[]) {
        setRequestFiles([]);
        setRequestFiles(files);
    }

    const bcElements: BreadCrumbElement[] = [];
    bcElements.push(
        {
            navigationPath: "/more",
            title: Session.instance.storage.translation.GetString('Navigation:More')
        },
        {
            navigationPath: "/myPerformanceCheck",
            title: Session.instance.storage.translation.GetString('Navigation:MyPerformanceCheck')
        },
        {
            navigationPath: `/myPerformanceCheck/SkillRequest`,
            title: Session.instance.storage.translation.GetString('Navigation:SkillRequest')
        }

    )

    return (
        <div className="l-container">
            <MenuBreadCrumb
                routePathName={"/myPerformanceCheck/skillRequest"}
                breadCrumbElementsToAppend={bcElements} />
            <div className="l-box--wide">
                <Heading headingLevel={1} cssClass="l-box-container heading__Title"><Translate>PerformanceCheck:RequestTitle</Translate></Heading>
                <Translate>PerformanceCheck:RequestSubTitle</Translate>
            </div>
            {skills && skills.length !== 0 ? <>

                <DropDownList
                    data={
                        [
                            { id: ESkillType.ActualSkill, text: Session.instance.storage.translation.GetString('PerformanceCheck:ActualSkill') },
                            { id: ESkillType.TargetSkill, text: Session.instance.storage.translation.GetString('PerformanceCheck:TargetSkill') }
                        ]}
                    label={Session.instance.storage.translation.GetString('PerformanceCheck:SkillTypDropdown') + "*"}
                    dataItemKey="id"
                    textField="text"
                    onChange={(e) => onChangeTyp(e)}
                    value={selectedSkillTyp}
                    required={true}
                />

                <DropDownList
                    data={filteredSkills}
                    label={Session.instance.storage.translation.GetString('PerformanceCheck:SkillDropdown') + "*"}
                    dataItemKey="id"
                    textField="skillTitle"
                    onChange={(e) => onChange(e)}
                    value={selectedSkill}
                    required={true}
                    filterable={true}
                    onFilterChange={(e) => filterData(e.filter.value)}
                />
                <DropDownList
                    data={skillLevels}
                    label={Session.instance.storage.translation.GetString('PerformanceCheck:LevelDropdown') + "*"}
                    dataItemKey="id"
                    textField="translatedText"
                    onChange={(e) => onChangeLevel(e)}
                    value={selectedSkillLevel}
                    required={true}
                    defaultValue={null}
                />
                <Heading headingLevel={2} cssClass="heading__Level5">
                    <label id="lblSkillComment">{commentString}</label>
                </Heading>
                <TextArea
                    id="SkillComment"
                    ariaLabelledBy="lblSkillComment"
                    placeholder={commentString}
                    onChange={(e) => { onCommentChange(e) }}
                    className="input-field"
                    autoSize={true}
                />
                {showFileUpload ? <div className="performanceCheck--fileUpload">
                    <GTFileUpload
                        parentHeadingLevel={1}
                        label={requestFileLabel}
                        allowedFileExtensions={globalConfig.performanceCheck.allowedFileExtensions}
                        multipleUploadAllowed={globalConfig.performanceCheck.multipleFileUploadAllowed}
                        maxFileSizeInByte={globalConfig.performanceCheck.maxFileSizeInByte}
                        onUploadFiles={(files) => { onFileChange(files); }}
                        defaultFileType={EFileType.Undefined}
                        showFileTypeSelection={true}
                        supportedFiletypesWithTranslation={supportedFiletypesWithTranslation}
                        multipleCertificateUploadAllowed={globalConfig.performanceCheck.multipleCertificateUploadAllowed}
                        multipleMiscFileUploadAllowed={globalConfig.performanceCheck.multipleMiscFileUploadAllowed}
                        filetypeRequired={globalConfig.performanceCheck.filetypeRequired}
                        onDisableFurtherAction={(disabled) => setIsButtonDependingOnFilesDisabled(disabled)}
                        duplicateErrorMessage='PerformanceCheck:DocumentAlreadyUploaded'
                        multipleCertificateNotAllowedErrorMessage='PerformanceCheck:MultipleCertificateNotAllowedErrorMessage'
                        multipleMiscFileUploadAllowedErrorMessage='PerformanceCheck:MultipleMiscFileUploadAllowedErrorMessage'
                    />
                </div> : null}
            </>
                : <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceCheck:NoSkillsForRequest')} />}
            {isError && <ErrorMessage errorMessage={Session.instance.storage.translation.GetString('ErrorMessage:General')} />}

            {sendRequestPending ? <ProgressSpinner size={24} /> :

                skills && skills.length !== 0 ?
                    <GTButton
                        defaultButtonProps={{ disabled: isButtonDependingOnFilesDisabled || isButtonDisabled() }}
                        onClick={() => onAssignClick()}
                    ><Translate>PerformanceCheck:AssignButton</Translate></GTButton> : null}
            {!isLoaded ? <ProgressSpinner /> :
                <PerformanceCheckTable
                    isBoss={false}
                    skills={skillsForPerformance}
                    parentHeadingLevel={1}
                />
            }
        </div>
    )
}


