import { JsonObject, JsonProperty } from 'json2typescript';

/** This class holds information needed for starting a CBT lesson. */
@JsonObject
export class CbtStartParameters {

    /** 
     * URL to be opened by SUI to start the CBT lesson. 
     * Normally the lesson should be opened in a new browser window using the WindowParams. 
     * IMPORTANT: The StartUrl is valid only for one usage and only for a short period, e.g. 30 seconds!
     */
    @JsonProperty('startUrl', String, false)
    public startUrl: string = '';

     /** 
      * Indicates if the ContentHost asks if user have completed when finishing a lesson. Valid only for non-compatible lessons.
      * Property just for information. 
      */
    @JsonProperty('askForCompletion', Boolean, false)
    public askForCompletion: boolean = false;

}