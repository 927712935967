
import { ItemTypeConverter } from '$src/storage/models/converters/ItemTypeConverter';
import { LegacySearchRequest } from '$src/storage/models/RequestObjects/LegacySearchRequest';
import { SearchRequest } from '$src/storage/models/RequestObjects/SearchRequest';
import { LazyFetchSearchResult } from '$src/storage/models/search/elk/LazyFetchSearchResult';
import { SearchFilter } from '$src/storage/models/search/elk/SearchFilter';
import { SearchResponse } from '$src/storage/models/search/elk/SearchResponse';
import { LegacySearchResponse } from '$src/storage/models/search/legacy/LegacySearchResponse';
import DateHelper from '$src/util/DateHelper';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * SearchService provides all Service Methods for the Search Functionality
 */
export default class SearchService extends ServiceClient {
    protected static _instance: SearchService | null = null;

    protected className = 'SearchService';
    protected loggerLocality = 'SearchService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): SearchService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get all Search Results for a keyword / text search.
     *
     * @param {string} language
     * @param {string} searchText
     * @returns {(Promise<SearchResponse | GtError>)}
     * @memberof SearchService
     */
    public async getSearchItems(
        language: string,
        searchText: string,
        itemsAsString: string,
        dateFrom: Date | null,
        dateTo: Date | null,
    ): Promise<LegacySearchResponse | GtError> {
        let response: LegacySearchResponse | GtError;
        const obj = new LegacySearchRequest();
        if (searchText !== '') {
            obj.searchValue = '*' + searchText + '*';
        } else {
            obj.searchValue = searchText;
        }
        obj.language = language;
        obj.itemTypeArrayAsString = itemsAsString
        obj.from = dateFrom != null ? DateHelper.toSqlDateString(dateFrom) : '0',
            obj.to = dateTo != null ? DateHelper.toSqlDateString(dateTo) : '0',
            response = await this.post<LegacySearchResponse>('search/catalogSearch', obj, LegacySearchResponse, undefined);
        return response;
    }

    /**
     * Get all Search Results for a keyword / text search.
     *
     * @param {string} language
     * @param {string} searchText
     * @returns {(Promise<SearchResponse | GtError>)}
     * @memberof SearchService
     */
    public async getSearchItemsv2(
        language: string,
        searchText: string,
        filters: SearchFilter,
        sortOrder: string
    ): Promise<SearchResponse | GtError> {
        let response: SearchResponse | GtError;
        const obj = new SearchRequest();

        obj.searchValue = searchText;
        obj.language = language;
        obj.durationMax = filters.durationMax;
        obj.durationMin = filters.durationMin;
        obj.from = filters.dateFrom != null ? DateHelper.toSqlDateString(filters.dateFrom) : '0';
        obj.to = filters.dateTo != null ? DateHelper.toSqlDateString(filters.dateTo) : '0';
        obj.sortOrder = sortOrder;

        filters.subjects.forEach(s => {
            obj.subjects.push(s.text.trim());
        });

        let itemsAsString: string = ''
        if (filters.itemTypes != null) {
            filters.itemTypes.map((item) => {
                if (itemsAsString !== '') {
                    itemsAsString += ','
                }
                itemsAsString += ItemTypeConverter.asGtServiceItemString(item.itemType);
            });
        }

        obj.itemTypeArray = itemsAsString;

        response = await this.post<SearchResponse>('search', obj, SearchResponse, undefined);
        return response;
    }

    /**
     * 
     * @param itemId 
     */
    public async lazyFetchSearchResultData(itemId: number): Promise<LazyFetchSearchResult | GtError> {
        let response: LazyFetchSearchResult | GtError;      
        response = await this.get<LazyFetchSearchResult>('search/lazyFetch/', LazyFetchSearchResult, undefined, undefined,  itemId.toString());
        return response;
    }
} 