import { JsonObject, JsonProperty } from 'json2typescript';

import { ClassStatusConverter } from '$src/storage/models/converters/ClassStatusConverter';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { RegistrationStatusConverter } from '$src/storage/models/converters/RegistrationStatusConverter';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter'
import { EClassStatus, EItemType, ERegistrationStatus } from '$storage/models/enums';


/** 
 * This class holds all information about a registraion (of  the current user) for a F2F class, 
 * training plan class or WBT lesson (without information about the item/class).
 */
@JsonObject
export class Registration {
    
    /** Id of registration. */
    @JsonProperty('registrationId', Number, false)
    public registrationId: number = 0;

    /** Registration status. */
    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;

    /** Date/time when user has/was registered. */
    @JsonProperty('registrationDate', DateTimeConverter, false)
    public registrationDate: Date | undefined = undefined;
  
    /** Id of the registered F2F course, training plan, or WBT. */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** Type of the registered item. */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType = EItemType.Undefined;

    /** Class Id (F2F class or training plan class). */
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    /** Class code (F2F class or training plan class). */
    @JsonProperty('classCode', String, false)
    public classCode: string = '';

    /** Class description (F2F, external class or training plan class). */
    @JsonProperty('classDescription', String, false)
    public classDescription: string = '';

    /** Begin of learning period (F2F class or training plan class, for F2F class this is the beginning of the first appointment). */
    @JsonProperty('learningPeriodBegin', DateTimeConverter, false)
    public learningPeriodBegin?: Date = undefined;
  
    /** End of learning period (F2F class or training plan class, for F2F class this is the end of the last appointment). */
    @JsonProperty('learningPeriodEnd', DateTimeConverter, false)
    public learningPeriodEnd?: Date = undefined;
  
    /** Class status (F2F class or training plan class). */
    @JsonProperty('classStatus', ClassStatusConverter, false)
    public classStatus: EClassStatus = EClassStatus.Undefined;

    /** Has Attended Flag (F2F class or training plan class). */
    @JsonProperty('hasAttended', Boolean, false)
    public hasAttended?: boolean = undefined;
}
