import { JsonObject, JsonProperty } from 'json2typescript';
import { LessonStatusConverter } from '$storage/models/converters/LessonStatusConverter';
import { ELessonStatus } from '$storage/models/enums';
import { TrainingPlanBlockCompletitionRequirement } from '$storage/models/TrainingPlan/TrainingPlanBlockCompletitionRequirement'

@JsonObject
export class TrainingPlanBlockCompletitionRequirementStatus {
    @JsonProperty('completitionRequirement', TrainingPlanBlockCompletitionRequirement, true)
    public completitionRequirement: TrainingPlanBlockCompletitionRequirement | undefined = undefined;

    @JsonProperty('requiredItemTitle', String, false)
    public requiredItemTitle: string = '';

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('score', Number, false)
    public score: number = 0;

    @JsonProperty('status', LessonStatusConverter, false)
    public status: ELessonStatus = ELessonStatus.Undefined;

    @JsonProperty('fulfilled', Boolean, false)
    public fulfilled: boolean = false;
}
