import { JsonObject, JsonProperty } from 'json2typescript';

// tslint:disable-next-line:max-classes-per-file

/** Model to get the Credentials of the server side configured guest user */
@JsonObject
export class IOSkill {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('isInputSkill', Boolean, false)
    public isInputSkill: boolean = false;

    @JsonProperty('isOutputSkill', Boolean, false)
    public isOutputSkill: boolean = false;

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('tplanElementId', Number, false)
    public tplanElementId: number = 0;

    @JsonProperty('skillId', Number, false)
    public skillId: number = 0;

    @JsonProperty('skillGuid', String, false)
    public skillGuid: string = '';

    @JsonProperty('skillSid', String, false)
    public skillSid: string = '';

    @JsonProperty('skillTitle', String, false)
    public skillTitle: string = '';

    @JsonProperty('skillLevelTitle', String, false)
    public skillLevelTitle: string = '';

    @JsonProperty('skillLevelValueAndText', String, false)
    public skillLevelValueAndText: string = '';

    @JsonProperty('skillLevelId', Number, false)
    public skillLevelId: number = 0;

    @JsonProperty('skillLevelValue', Number, false)
    public skillLevelValue: number = 0;

    @JsonProperty('numberOfRecommendedLessons', Number, false)
    public skillNumberOfRecommendaedLessons: number = 0;
}
