
import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { VirtualMeetingLink } from '$src/storage/models/F2F/VirtualMeetingLink';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$util/Result';
import DateHelper from '$src/util/DateHelper';
import { EDisplayVcgLeadTime, EItemDetailCallerContextType, EVirtualMeetingsViewType } from '$src/storage/models/enums';
import UserService from '$src/core/Services/UserService';
import { HeadingCollapsible } from './HeadingCollapsible';
import { UnmountClosed } from 'react-collapse';
import { Tooltip } from '$components/shared/Tooltip';
import { isNumber } from 'util';
import { VirtualMeetingSingleLink } from '../item/F2F/F2FVirtualMeetingSingleLink';

interface IState {
    dataLoaded: boolean;
    virtualMeetingLinks: VirtualMeetingLink[] | null;
    errorMessage: string | undefined;
    viewType: EVirtualMeetingsViewType;
    filterPanelCollapsed: boolean;
}

export class VirtualMeetingLinks extends React.Component<{}, IState> {
    protected className = 'TrainerVirtualMeetings';
    protected loggerLocality = 'Components.TrainerVirtualMeetings';
    protected leadTimeConfig = EDisplayVcgLeadTime[globalConfig.virtualMeetingsProperties.showLeadTimeAs] ? globalConfig.virtualMeetingsProperties.showLeadTimeAs : EDisplayVcgLeadTime.Integrated;

    constructor(props: {}) {
        super(props);
        const parameters = new URLSearchParams(window.location.search);
        const viewType = parameters.get('viewType');
        this.state = {
            dataLoaded: false,
            virtualMeetingLinks: null,
            errorMessage: undefined,
            viewType: viewType == null ? EVirtualMeetingsViewType.All : Number(viewType),
            filterPanelCollapsed: false,
        }
    }

    public componentDidMount() {
        this.getLinks(this.state.viewType);
    }

    public render() {
        return (
            <div className="l-container" >
                <MenuBreadCrumb {...this.props} />
                <h1 className="heading__Title">
                    <Translate>
                        VirtualMeetings:VirtualMeetings
                    </Translate>
                </h1>
                <HeadingCollapsible
                    headingLevel={2}
                    containerCssClass=" "
                    headingCssClass="heading__Level2"
                    isOpened={!this.state.filterPanelCollapsed}
                    onToggleOpenedState={() => this.setState({ filterPanelCollapsed: !this.state.filterPanelCollapsed })}>
                    <Translate>MyLessons:Filter</Translate>
                </HeadingCollapsible>
                <UnmountClosed
                    isOpened={!this.state.filterPanelCollapsed}>
                    {this.renderFilterPanel()}
                </UnmountClosed>
                {this.renderContentPart()}
            </div>
        );
    }

    /**
     *Render the main content part
     *
     * @private
     * @returns {JSX.Element}
     * @memberof VirtualMeetingLinks
     */
    private renderContentPart(): JSX.Element {
        if (this.state.dataLoaded && this.state.virtualMeetingLinks != null && this.state.virtualMeetingLinks.length > 0) {
            const minutesOpenedBeforeStartTime = this.state.virtualMeetingLinks != null && this.state.virtualMeetingLinks.length !== 0 && this.state.virtualMeetingLinks[0].minutesOpenedBeforeStartTime;
            return (
                <React.Fragment>
                    {globalConfig.virtualMeetingsProperties.showLeadTimeHint &&
                        <div className={globalConfig.virtualMeetingsProperties.leadTimeHintCss != null ?
                            ('virtualMeetings__lead-time-hint ' + globalConfig.virtualMeetingsProperties.leadTimeHintCss) : ''}>
                            {Session.instance.storage.translation.GetString("F2FVirtualMeetingLinks:LeadTimeHint").Format(minutesOpenedBeforeStartTime.toString())}
                        </div>
                    }
                    <div role="table" className="div-table__horizontal-table">
                        <div role="rowgroup" className="notMobile">
                            <div role="row" className="div-table__horizontal-table-row">
                                <div role="columnheader" >
                                    <Translate>F2FVirtualMeetingLinks:Title</Translate>
                                </div>
                                <div role="columnheader" >
                                    <Translate>F2FVirtualMeetingLinks:Schedule</Translate>
                                </div>
                                {this.leadTimeConfig === EDisplayVcgLeadTime.Column &&
                                    <div role="columnheader" >
                                        <Translate>F2FVirtualMeetingLinks:LeadTime</Translate>
                                    </div>
                                }
                                <div role="columnheader">
                                    <Translate>VirtualMeetings:ViewType</Translate>
                                </div>
                                <div role="columnheader">
                                    <Translate>F2FVirtualMeetingLinks:URL</Translate>
                                </div>
                            </div>
                        </div>
                        <div role="rowgroup">
                            {this.renderLinkList()}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.dataLoaded === false) {
            return <ProgressSpinner />;
        } else if (this.state.errorMessage !== undefined) {
            return (
                <Alert alertAppereance={'box'} alertType={'error'} message={Session.instance.storage.translation.GetString(this.state.errorMessage)} />
            );
        } else {
            return <NoDataFound message={Session.instance.storage.translation.GetString('VirtualMeetings:NoVirtualMeetings')} />
        }
    }

    /**
     *Render the panel for the meeting filter
     *
     * @protected
     * @returns {JSX.Element}
     * @memberof VirtualMeetingLinks
     */
    protected renderFilterPanel(): JSX.Element {
        const tr = Session.instance.storage.translation;
        return (
            <React.Fragment>
                <div role="table" className="virtualMeetings__filterTable">
                    <div role="row">
                        <div role="columnheader" >
                            <Translate>VirtualMeetings:MyMeetingsAs</Translate>
                        </div>
                        <div role="cell" className="datePicker" >
                            <select
                                id="ddlMyMeetingsAsFilter"
                                data-tip={tr.GetString('VirtualMeetings:MyMeetingsAs')}
                                aria-label={tr.GetString('VirtualMeetings:MyMeetingsAs')}
                                data-for="ddlMyMeetingsAsFilter_Tooltip"
                                className="input-field"
                                onChange={(e) => this.filterViewTypeChanged(e)}
                                defaultValue={this.state.viewType}>
                                {this.createVirtualMeetingTypeFilter()}
                            </select>
                            <Tooltip id="ddlMyMeetingsAsFilter_Tooltip" />
                        </div>
                    </div>
                </div>
                <br />
            </React.Fragment >
        );
    }

    /**
     * Create dropdown options from EVirtualMeetingsViewType ENUM
     */
    private createVirtualMeetingTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const val = Object.keys(EVirtualMeetingsViewType);
        let values = val.map(k => EVirtualMeetingsViewType[k as any]).map(v => v as any as EVirtualMeetingsViewType);
        const isCurrentUserTrainer = Session.instance.isCurrentUserTrainer();
        values = values.filter(val => isNumber(val));
        if (isCurrentUserTrainer === false) {
            values = values.filter(val => val === EVirtualMeetingsViewType.Student)
        }
        values.map(val => {
            elements.push(
                <option key={'ddlMyMeetingsAsFilterOption_' + val} value={val}   >
                    {Session.instance.storage.translation.GetString('VirtualMeetings:' + EVirtualMeetingsViewType[val].toString())}
                </option>
            );
        })
        return elements;
    }

    /**
     * Return a list of JSX Elements containing the join links and the timeSpan of this meeting
     */
    protected renderLinkList(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const tr = Session.instance.storage.translation;
        if (this.state.virtualMeetingLinks != null) {
            this.state.virtualMeetingLinks.map((vm, index) => {
                let startDateTime = vm.startDateTime != null ? DateHelper.toDateTimeString(vm.startDateTime) : ''
                if (this.leadTimeConfig !== EDisplayVcgLeadTime.Integrated && vm.minutesOpenedBeforeStartTime !== 0 && vm.startDateTime != null) {
                    const newStartDateTime = new Date(vm.startDateTime.getTime() + vm.minutesOpenedBeforeStartTime * 60000);
                    startDateTime = DateHelper.toDateTimeString(newStartDateTime);
                }
                const el: JSX.Element = (
                    <div role="row" key={index} className="div-table__horizontal-table-row">
                        <div role="columnheader" className="mobileOnly">
                            <Translate>F2FVirtualMeetingLinks:Title</Translate>
                        </div>
                        <div role="cell" className="inlineFlex">
                            {vm.title}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>F2FVirtualMeetingLinks:Schedule</Translate>
                        </div>
                        <div role="cell" className="inlineFlex">
                            {startDateTime}
                            -<br />
                            {vm.endDateTime != null ? DateHelper.toDateTimeString(vm.endDateTime) : ''}
                        </div>
                        {this.leadTimeConfig === EDisplayVcgLeadTime.Column &&
                            <React.Fragment>
                                <div role="columnheader" className="mobileOnly">
                                    <Translate>F2FVirtualMeetingLinks:LeadTime</Translate>
                                </div>
                                <div role="cell" className="inlineFlex">
                                    {vm.minutesOpenedBeforeStartTime}
                                </div>
                            </React.Fragment>
                        }
                        <div role="columnheader" className="mobileOnly">
                            <Translate>VirtualMeetings:ViewType</Translate>
                        </div>
                        <div role="cell" className="inlineFlex">
                            <Translate>{tr.GetString('VirtualMeetings:ViewType_' + EVirtualMeetingsViewType[vm.type].toString())}</Translate>
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>F2FVirtualMeetingLinks:URL</Translate>
                        </div>
                        <div role="cell" className="inlineFlex">
                            {vm.url === '' || vm.url == null ? Session.instance.storage.translation.GetString('F2FVirtualMeetingLinks:MeetingNotActive') :
                                <VirtualMeetingSingleLink
                                    virtualMeetingLink={vm.url}
                                    participationAutoConfirmationForVCG={vm.participationAutoConfirmationForVCG}
                                    registrationId={vm.registrationId}
                                    linkText="F2FVirtualMeetingLinks:JoinVirtualMeeting"
                                    linkClass="button-link button-link--colorized-dark"
                                    linkTitle={undefined}
                                    itemContext={EItemDetailCallerContextType.RegisteredItem} />
                            }
                        </div>
                    </div>
                );
                elements.push(el);
            });
        }
        return elements;
    }

    /**
     * Load all virtualMeetings from this user into the state
     */
    protected getLinks(type: EVirtualMeetingsViewType): void {
        UserService.instance.getVirtualMeetingLinksByViewType(type).then(res => {
            if (isSuccess<VirtualMeetingLink[]>(res)) {
                this.setState({ dataLoaded: true, virtualMeetingLinks: res, errorMessage: undefined });
            } else if (res.detailedObject !== undefined) {
                this.setState({ dataLoaded: true, errorMessage: CustomErrorMessage.getErrorCodeMessageString(res.detailedObject.subStatusCode) });
            } else {
                this.setState({ dataLoaded: true });
            }
        });
    }

    /**
     * DropDown ViewType changed, reload data!
     * @param event 
     */
    private filterViewTypeChanged(event: React.FormEvent<HTMLSelectElement>): void {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ viewType: Number(target.value), dataLoaded: false });
        this.getLinks(Number(target.value));
    }

}