import Logger from '$src/core/Logger';
import Session from '$src/core/Session';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { observer } from 'mobx-react';
import React from 'react';
import { Tooltip } from '$components/shared/Tooltip';


interface IProps {
    onLanguageSwitched?: () => void;
    isLanguageSwitcherinHeader?: boolean;
}

interface IState {
    attributeValue: AttributeValue[] | null;
    isLanguageSwitcherinHeader: boolean
}

@observer
export class LanguageSwitcher extends React.Component<IProps, IState> {
    protected className = 'LanguageSwitcher';
    protected loggerLocality = 'Components.LanguageSwitcher';

    public constructor(props: IProps) {
        super(props);
        this.state = {
            attributeValue: null,
            isLanguageSwitcherinHeader: this.props.isLanguageSwitcherinHeader ? true : false,
        }

        this.SwitchLanguage = this.SwitchLanguage.bind(this);
    }

    /* 
    public UNSAFE_componentWillMount() {
        // Retrieve attribute values for UI Languages
        this.getAttributeValues(globalConfig.languageProperties.attributeValueType);
    }
    */

    public render() {
        const tr = Session.instance.storage.translation;
        let translation;
        this.props.isLanguageSwitcherinHeader ? translation = tr.GetString('Header:Language') : translation = tr.GetString('Navigation:Language');

        return (
            <React.Fragment>
                <label htmlFor="ddlLanguage" id="lbl_ddlLanguage" className="screen-reader-only">
                    {translation}
                </label>
                <select
                    id="ddlLanguage"
                    data-tip={translation}
                    data-for="languageTooltip"
                    aria-label={translation}
                    onChange={e => this.SwitchLanguage(e)}
                    value={Session.instance.getUserLanguageCodeOrFallBack}>
                    {this.createSelectItems()}
                </select>
                <Tooltip id="languageTooltip" place="bottom" />
            </React.Fragment>
        );
    }

    /*
    protected async getAttributeValues(typeId: number): Promise<void> {
        let attributeValues: AttributeValue[] | null;
        attributeValues = await Session.instance.storage.attributeValue.getAttributeValuesAsync(typeId);
        if (isSuccess<AttributeValue[] | null>(attributeValues)) {

            // Sort by Code
            if (attributeValues !== null) {
                attributeValues.sort((a, b) => {
                    const codeA = a.code.toLowerCase();
                    const codeB = b.code.toLowerCase();
                    if (codeA < codeB) { return -1 };
                    if (codeA > codeB) { return 1 };
                    return 0;
                });
            }
            this.setState({ attributeValue: attributeValues });
        }
    }
    */

    /**
     * Initialize the LanguageDropDown with data from given Array.
     *
     * @private
     * @returns
     * @memberof LanguageSwitcher
     */
    private createSelectItems(): Array<React.ReactElement<{}>> {
        const items: Array<React.ReactElement<{}>> = [];
        const langProps = globalConfig.languageProperties;

        // The languages are always presented in the original order from the config file (The former alphabetical sorting was removed, SWTGTRUAGCR-81)
        langProps.languages.map(l => {
            items.push(<option key={l.Code} value={l.Code}>{l.DisplayName}</option>);
        })
        return items
    }

    /**
     * Switch the current Language and initialize the translation store (if not initialized).
     * Save the language in sessionStorage and pass the event to parent.
     * @private
     * @param {React.FormEvent} event
     * @memberof LanguageSwitcher
     */
    private async SwitchLanguage(event: React.FormEvent) {
        const methodName = `${this.className}:SwitchLanguage()`;
        const langCode = ((event.target) as any).value;

        Logger.log(this.loggerLocality, `${methodName} to ${event.target}.`);

        // Clear Language Dependant Storage
        Session.instance.storage.clearLanguageDependantCache();

        Logger.log(this.loggerLocality, `${methodName} Switch Language: Old langCode = ${Session.instance.languageCode}.`);
        Session.instance.setLanguageCode(langCode);
        Logger.log(this.loggerLocality, `${methodName} Switch Language: New langCode = ${Session.instance.languageCode}.`);

        // Reload Translation Storage
        if (!Session.instance.storage.translation.isObjectInCacheAndValid(langCode)) {
            await Session.instance.storage.translation.InitializeTranslationStore(langCode);
        }

        // Reload available Languages
        // this.getAttributeValues(globalConfig.languageProperties.attributeValueType);

        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;

    }

}
