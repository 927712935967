import React from 'react';

import { BossSelection } from '$components/shared/BossSelection';
import { CancellationReasonSelection } from '$components/shared/CancellationReasonSelection';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import { ECancellationPermission, ECancellationType } from '$storage/models/enums';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';

interface IProps {
    classTitle: string;
    parentHeadingLevel: number;

    register: boolean;

    bossSelectionVisible?: boolean;
    bossSelectionRequired?: boolean;
    bossSelectionReadonly?: boolean;

    cancellationType?: ECancellationPermission;
    cancellationReasonType?: ECancellationType;

    onRegistrationConfirmedClick?: (boss: BossSearchResult | undefined) => void;
    onCancelRegistrationConfirmedClick?: (cancellationReasonId: number | undefined, cancellationReasonText: string) => void;
    onExitClicked?: () => void;

    itemID: number;

}

interface IStateRegistration {
    selectedBoss?: BossSearchResult;
}

interface IStateCancelRegistration {
    cancellationReasonText: string;
    selectedCancellationReasonId?: number;
}

interface IState extends IStateRegistration, IStateCancelRegistration {
    responseWait: boolean;
    errorMessage: string;
}

export class ItemRegistrationActionConfirmation extends React.Component<IProps, IState> {
    protected className = 'ItemRegistrationActionConfirmation';
    protected loggerLocality = 'Components.ItemRegistrationActionConfirmation';

    constructor(props: IProps) {
        super(props);
        this.state = {
            cancellationReasonText: '',
            errorMessage: '',
            responseWait: false,
            selectedBoss: undefined,
            selectedCancellationReasonId: undefined,
        }
    }

    public render() {

        if (this.state.responseWait) {
            return <ProgressSpinner />;
        } else {
            const tr = Session.instance.storage.translation;
            let confirmationMsg = '';
            let cancelationHint = '';
            if (this.props.register) {
                // Registration
                confirmationMsg = tr.GetString('Class:ConfirmRegistrationHint').Format(this.props.classTitle);
            } else {
                // Cancel Registration
                confirmationMsg = tr.GetString('Class:CancelRegistrationHint');
                if (this.props.cancellationType === ECancellationPermission.WithWarning) {
                    cancelationHint = tr.GetString('Class:CancellationHint');
                }
            }
            const ariaLabelCancel = Session.instance.storage.translation.GetString('Class:CancelConfirmation')
                + ' '
                + this.props.classTitle
            const ariaLabelConfirm = Session.instance.storage.translation.GetString('Class:Confirm')
                + ' '
                + this.props.classTitle

            return (

                <div>
                    <Heading cssClass="heading__Level2" headingLevel={this.props.parentHeadingLevel + 1}>
                        <Translate>{confirmationMsg}</Translate>
                    </Heading>
                    <span className={'input-message error'}>
                        <Translate>{cancelationHint}</Translate>
                    </span>


                    {this.props.bossSelectionVisible && this.props.register &&
                        // Registration
                        <div>
                            <BossSelection
                                isReadOnly={false}
                                isConfigReadOnly={this.props.bossSelectionReadonly != null ? this.props.bossSelectionReadonly : true}
                                parentHeadingLevel={this.props.parentHeadingLevel + 1}
                                onBossSelectionChanged={(selectedBoss) => this.onBossSelectionChanged(selectedBoss)} />
                        </div>
                    }

                    {globalConfig.registrationCancellation.cancellationReasonSectionVisible && !this.props.register &&
                        // Cancel Registration
                        <div>
                            <CancellationReasonSelection
                                itemID={this.props.itemID}
                                onChange={(cancellationReasonId, reason) => this.onCancellationReasonChanged(cancellationReasonId, reason)}
                                cancellationReasonType={this.props.cancellationReasonType != null ? this.props.cancellationReasonType : ECancellationType.Enrolment} />
                        </div>
                    }
                    <div className="modal__spread-buttons">
                        <button type="button"
                            aria-label={ariaLabelConfirm}
                            className="btn btn--sm marginRight-5"
                            onClick={() => this.onButtonClickConfirm()}
                            disabled={!this.allowConfirmation()}
                            id="btnItemRegistrationActionConfirmation">
                            <Translate>Class:Confirm</Translate>
                        </button>
                        <button type="button"
                            aria-label={ariaLabelCancel}
                            className="btn btn--sm"
                            onClick={() => this.onButtonExitClicked()}
                            disabled={false}
                            id="btnItemRegistrationActionCancel">
                            <Translate>Class:CancelConfirmation</Translate>
                        </button>
                    </div>
                </div>

            )
        }
    }

    private allowConfirmation(): boolean {
        if (this.props.register) {
            return !this.props.bossSelectionVisible || // Not visible
                (this.props.bossSelectionVisible && ( // Visible and
                    (this.state.selectedBoss != null && this.props.bossSelectionRequired) || // selected and required
                    !this.props.bossSelectionRequired) // not required
                );
        }
        else {
            return !globalConfig.registrationCancellation.cancellationReasonSectionVisible ||
                (
                    globalConfig.registrationCancellation.cancellationReasonSectionVisible &&
                    (
                        !globalConfig.registrationCancellation.isCancellationReasonMandatory ||
                        (globalConfig.registrationCancellation.isCancellationReasonMandatory &&
                            this.state.selectedCancellationReasonId != null &&
                            this.state.selectedCancellationReasonId > 0
                        )
                    ) &&
                    (
                        !globalConfig.registrationCancellation.isCancellationReasonTextMandatory ||
                        (globalConfig.registrationCancellation.isCancellationReasonTextMandatory && this.state.cancellationReasonText !== '')
                    )
                );
        }
    }

    private onButtonExitClicked() {
        if (this.props.onExitClicked != null) {
            this.props.onExitClicked();
        }
    }

    private onButtonClickConfirm() {
        this.setState({ responseWait: true });
        if (this.props.register) {
            // Registration Confirmed
            if (this.props.onRegistrationConfirmedClick != null) {
                this.props.onRegistrationConfirmedClick(this.state.selectedBoss)
            }
        } else
        {
            // Cancel Registration Confirmed
            if (this.props.onCancelRegistrationConfirmedClick != null) {
                this.props.onCancelRegistrationConfirmedClick(this.state.selectedCancellationReasonId,
                    this.state.cancellationReasonText)
            }
        }
    }

    private onCancellationReasonChanged(cancellationReasonId: number, reason: string) {
        this.setState({ selectedCancellationReasonId: cancellationReasonId, cancellationReasonText: reason });
    }

    private onBossSelectionChanged(selectedBoss: BossSearchResult | undefined) {
        this.setState({ selectedBoss });
    }

}