import { ValidationStatusConverter } from '$storage/models/converters/ValidationStatusConverter';
import { EValidationStatus } from '$storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ValidationResult {
    @JsonProperty('status', ValidationStatusConverter, false)
    public status: EValidationStatus = EValidationStatus.Success;

    @JsonProperty('propertyName', String, false)
    public propertyName: string = '';

    @JsonProperty('code', String, false)
    public code: string = '';

    @JsonProperty('message', String, false)
    public message: string = '';
}