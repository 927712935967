import Session from '$src/core/Session';

export class StringHelper {
    /** 
     * Formats a file size into KB and MB string
     * @param bytes size of the file
     */
    public static formatFileSize = (bytes: number) => {
        const prefix: string = bytes < 0 ? '-' : '';
        bytes = Math.abs(bytes);

        if (bytes < 1000) {
            return prefix + '1 KB';
        }

        let size = bytes / 1024.0;
        if (size < 1000) {
            return prefix + size.toFixed(0) + ' KB';
        }

        size = size / 1024;
        return prefix + size.toFixed(1) + ' MB';
    }

    public static stringFormat = (str: string, args: string[]) =>
        str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

    /**
     * Converts the Date to a localized date string
     */
    public static dateString = (date?: Date): string => {
        return date ? date.toLocaleDateString(Session.instance.getUserLanguageCodeOrFallBack, {
            year: 'numeric',

            month: '2-digit',
            day: '2-digit'
        })
        : '';
    }

    /**
     * Converts the Date to a localized date and time string
     */
    public static dateTimeString = (dateTime: Date): string => {
        return dateTime.toLocaleDateString(Session.instance.getUserLanguageCodeOrFallBack, {
            year: 'numeric',            
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    /**
     * Converts the Date to a localized date string without year
     */
         public static dayMonthString = (dateTime: Date): string => {
            return dateTime.toLocaleDateString(Session.instance.getUserLanguageCodeOrFallBack, {        
                month: '2-digit',
                day: '2-digit'
            });
        }

    /**
     * Converts the Iso Date Strin (yyyy-MM-ddThh:mm:ss) to a localized date and time string
     */
     public static dateTimeIsoString = (dateTimeString: string): string => {
        const dateTime = new Date(dateTimeString);
        return dateTime.toLocaleDateString(Session.instance.getUserLanguageCodeOrFallBack, {
            year: 'numeric',            
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    /**
     * Converts the Date to a localized time string
     */
    public static timeString = (dateTime: Date): string => {
        return dateTime.toLocaleTimeString(Session.instance.getUserLanguageCodeOrFallBack, {
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    /**
     * Converts the number to a localized number string in the following format
     * 30,000.65 en-US
     * 30.000,65 de-DE
     * 30'000.65 de-CH
     * 30 000,65 fr-CH
     */
    public static numberString = (number: number): string => {
        return number.toLocaleString(Session.instance.getUserLanguageCodeOrFallBack, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }

    /**
     * Formats text like:
     * '- ' is converted into a bullet point
     * '\n' is converted into break lines 
     */
    public static htmlFormat = (str: string) => {
        let formatArray: string[];
        let finalHTML = '';
        let bulletPointsStarted = false;

        if (str != null && str.length > 0) {
            formatArray = StringHelper.htmlTextFormat(str).split('\n').slice(0, -1);
            formatArray.map((line, index) => {
                if (line[0] + line[1] !== '- ' && !bulletPointsStarted) {
                    finalHTML += line + '<br/>';
                }
                else if (line[0] + line[1] === '- ' && !bulletPointsStarted) {
                    finalHTML += '<ul>';
                    finalHTML += '<li>' + line.substring(2) + '</li>';
                    bulletPointsStarted = true;
                }
                else if (line[0] + line[1] === '- ' && bulletPointsStarted && index < formatArray.length) {
                    if (index < formatArray.length - 1 && formatArray[index + 1].startsWith('- ')) {
                        finalHTML += '<li>' + line.substring(2) + '</li>';
                    }
                    else if (index < formatArray.length - 1 && !formatArray[index + 1].startsWith('- ')) {
                        finalHTML += '<li>' + line.substring(2) + '</li>';
                        finalHTML += '</ul>';
                        bulletPointsStarted = false;
                    } else if (index === formatArray.length - 1) {
                        finalHTML += '<li>' + line.substring(2) + '</li>';
                        finalHTML += '</ul>';
                        bulletPointsStarted = false;
                    }
                }else if(bulletPointsStarted){
                    bulletPointsStarted = false;
                }
            });

            return finalHTML;
        }
        return str;
    }

    /**
     * Formats Text like:
     * **text** -> Bold
     * __text__ -> Italic
     * ~~text~~ -> Underlined
     */
    public static htmlTextFormat = (str: string) => {
        if (str != null && str.length > 0) {
            const arr = StringHelper.replacePlaceholders(str).split('\n');
            let resultStr = '';
            arr.map(line => {
                let newLine = line;
                if (line.includes('**')) {
                    const foundWords = newLine.match(/\*\*([\s\S]*?)\*\*/g);
                    if (foundWords != null) {
                        foundWords.map(elm => {
                            let boldWord = elm.replace(/\**/g, '');
                            boldWord = '<b>' + boldWord + '</b>';
                            newLine = newLine.replace(elm, boldWord);
                        });
                    }
                } if (line.includes('~~')) {
                    const foundWords = newLine.match(/\~\~([\s\S]*?)\~\~/g);
                    if (foundWords != null) {
                        foundWords.map(elm => {
                            let italicWord = elm.replace(/\~\~/g, '');
                            italicWord = '<i>' + italicWord + '</i>';
                            newLine = newLine.replace(elm, italicWord);
                        });
                    }
                } if (line.includes('__')) {
                    const foundWords = newLine.match(/\_\_([\s\S]*?)\_\_/g);
                    if (foundWords != null) {
                        foundWords.map(elm => {
                            let underlinedWord = elm.replace(/\_\_/g, '');
                            underlinedWord = '<span style="text-decoration: underline;">' + underlinedWord + '</span>';
                            newLine = newLine.replace(elm, underlinedWord);
                        });
                    }
                }
                resultStr += newLine + '\n';
            });
            return resultStr;
        }
        return str;
    }

    /**
     * Replaces the placeholder with the object values
     * The supported placeholder formats are {User.firstName}
     * The property is case sensitive
     */
    private static replacePlaceholders = (str: string) => {
        const placeholders = StringHelper.getPlaceholders(str);
        if(placeholders) {
            placeholders.map(placeholder => {
                const parts = placeholder.slice(1, -1).split('.');
                if(parts.length === 2) {
                    const object = parts[0]
                    const property = parts[1]
                    if(object.length > 0 && property.length > 0) {
                        const o = StringHelper.getObject(object.toLowerCase());
                        if(o) {
                            const value = o[property];
                            if(value != undefined) {
                                str = str.replace(placeholder, value.toString());
                            }
                        }
                    }
                }
            });
        }

        return str;
    }

    /**
     * Returns the placeholders as a list (with the curly brackets)
     * To remove the curly brackets, use slice(1, -1)
     * The supported placeholder formats are: {User.firstName}
     */
    private static getPlaceholders = (str: string) => {
        const regexMatchArray = str.match(/{([^}]+)}/g);
        if(regexMatchArray) {
            return regexMatchArray;
        } else {
            return null;
        }
    }

    /**
     * This function returns an object by its name
     * For the LoginUser use the param 'user'
     */
    private static getObject = (objectName: string) => {
        if(objectName === 'user' && Session.instance.loginUser) {
            return Session.instance.loginUser;
        } else {
            return null;
        }
    }

}