import DateHelper from '$src/util/DateHelper';
import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '../converters/DateTimeConverter';

/** Response of a class search */
@JsonObject
export class PaymentClassSearchResponse {
    @JsonProperty('course', String, false)
    public course: string = '';

    @JsonProperty('class', String, false)
    public class: string = '';

    @JsonProperty('itmItemId', String, false)
    public itmItemId: string = '';

    @JsonProperty('itmId', Number, false)
    public itmId: number = 0;

    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    @JsonProperty('clsCode', String, false)
    public clsCode: string = '';

    @JsonProperty('freeSeats', Number, false)
    public freeSeats: number = 0;

    @JsonProperty('city', String, false)
    public city: string = '';

    @JsonProperty('price', Number, false)
    public price: number = 0;

    @JsonProperty('currency', String, false)
    public currency: string = '';

    @JsonProperty('attribute', String, false)
    public attribute: string = '';

    @JsonProperty('startDate', DateTimeConverter, false)
    public startDate: Date = new Date();

    /** Function to replace the placeholders in given string */
    replacePlaceholders(text: string): string {
        return text
            .replace('{Course}', this.course || '-')
            .replace('{Class}', this.class || '-')
            .replace('{StartDate}', DateHelper.toDateString(this.startDate))
            .replace('{Start}', DateHelper.toDateTimeString(this.startDate))
            .replace('{FreeSeats}', this.freeSeats.toString())
            .replace('{City}', this.city || '-');
    }
}