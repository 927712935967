
import React from 'react';
import { UnmountClosed } from 'react-collapse';
import InlineSVG from 'react-inlinesvg';

import Icondown from '$resources/svgs/navi/arrow-down.svg'
import Iconup from '$resources/svgs/navi/arrow-up.svg'

import Session from '$src/core/Session';

interface IState {
    isOpened: boolean;
    contentStyle: string;
}

interface IProps {
    content: JSX.Element;
    header: JSX.Element;
    mykey: string; 
    OnStateChanged?: (isOpened: boolean ) => void;  
}


export class CollapsibleButton extends React.Component<IProps, IState> {
    protected content: any;
    protected header: any;

    constructor(props: IProps) {
        super(props);
        this.content = this.props.content;
        this.header  = this.props.header;
        this.state ={isOpened : false, contentStyle : 'contentCollapsed'};    
        this.ToggleItem = this.ToggleItem.bind(this);

    }
    
    public render() {
        const icon = this.state.isOpened ? Iconup : Icondown;
        // this.state.isOpened ? 'Details angezeigt' : 'Details ausgeblendet'
        return (<div className="l-box-container"  >
                    
                    <button id={this.props.mykey}    
                            role="region"                     
                            aria-atomic={true}
                            aria-label={this.state.isOpened ?
                                Session.instance.storage.translation.GetString('Todo:DetailsDisplayed') :
                                Session.instance.storage.translation.GetString('Todo:DetailsHidden')}
                            aria-live="assertive" 
                            className="collapsible l-form-container__image" onClick={this.ToggleItem}>
                            {this.header}
                            <InlineSVG className="collapsible-padding" aria-controls={this.props.mykey} src={icon}/>

                            
                    </button>
                    <UnmountClosed className="todo-100percent" 
                                   isOpened={this.state.isOpened}>
                                   <div className={this.state.contentStyle}> {this.content}</div>
                    </UnmountClosed> 
                </div>
        );              
    } 
    
    protected ToggleItem()
    {

        this.setState({isOpened : !this.state.isOpened, contentStyle: !this.state.isOpened ? 'contentVisible' : 'contentCollapsed' })
        if(this.props.OnStateChanged != null)
        {
            this.props.OnStateChanged(!this.state.isOpened)
        }
    }
}