
import { AssignedItem } from '$src/storage/models/AssignedItem';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { RequirementsDeleteRequest } from '$src/storage/models/RequestObjects/RequirementsDeleteRequest';
import { RequirementsOverviewData } from '$src/storage/models/RequirementsOverviewData';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * AssignmentService provides all Service Methods for Assignments 
 */
export default class AssignmentService extends ServiceClient {
    protected static _instance: AssignmentService | null = null;

    protected className = 'AssignmentService';
    protected loggerLocality = 'AssignmentService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): AssignmentService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get assignments of current user from web API. 
     * @param language language
     */
    public async getAssignments(): Promise<AssignedItem[] | GtError> {
        const response: AssignedItem[] | GtError = await this.get<AssignedItem[]>('assignment', AssignedItem, undefined, undefined);
        return response;
    }

    public async deleteAssignmentFromUser(request: RequirementsDeleteRequest) {
        return await this.post<BooleanResponse>('deleteAssignmentFromEmployee', request, BooleanResponse, undefined);
    }

    public async getRequirementsOverview(language: string, user: string, title: string, lessonType: number, lessonSubType: number,
        lessonStatus: number, assignedDateFrom: string, assignedDateTo: string, bossRelationCode: string): Promise<RequirementsOverviewData[] | GtError> {
        const response: RequirementsOverviewData[] | GtError = await this.get<RequirementsOverviewData[]>('requierdAssignmentForUsers', RequirementsOverviewData, undefined, [
            { name: 'language', value: language },
            { name: 'user', value: user },
            { name: 'title', value: title },
            { name: 'lessonType', value: lessonType.toString() }, //1185 for tplan
            { name: 'lessonSubType', value: lessonSubType.toString() },
            { name: 'lessonStatus', value: lessonStatus.toString() },
            { name: 'assignedDateFrom', value: assignedDateFrom },
            { name: 'assignedDateTo', value: assignedDateTo },
            { name: 'bossRelationCode', value: bossRelationCode },
        ])
        return response;
    }
} 