import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class Group {

    @JsonProperty('groupName', String, false)
    public groupName: string = '';

    @JsonProperty('groupTypeCode', String, false)
    public groupTypeCode: string = '';

    @JsonProperty('isBossEmployeeGroup', Boolean, false)
    public isBossEmployeeGroup: boolean = false;

    @JsonProperty('isDynamic', Boolean, false)
    public isDynamic: boolean = false;

    @JsonProperty('groupTypeId', Number, false)
    public groupTypeId: number = 0;
}