import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class AssignLessonToUserRequest {

    @JsonProperty('bossId', Number, false)
    public bossId: number ;

    @JsonProperty('employeeIds', [Number], false)
    public employeeIds: number[] ;

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('isLessonRequired', Boolean, false)
    public isLessonRequired: boolean = false;

	@JsonProperty('requiredScore', Number, false)
	public requiredScore: number = 0;

	@JsonProperty('notifyOnStart', Boolean, false)
	public notifyOnStart: boolean = false;

	@JsonProperty('notifyOnEnd', Boolean, false)
    public notifyOnEnd: boolean = false;

	@JsonProperty('assRunningPeriodStart', Number, false)
	public assRunningPeriodStart: number = 0;

	@JsonProperty('assRunningPeriodTarget', Number, false)
	public assRunningPeriodTarget: number = 0;

	@JsonProperty('assRunningPeriodEnd', Number, false)
    public assRunningPeriodEnd: number = 0;
    
    @JsonProperty('selectedTimeSettingMode', Number, false)
    public selectedTimeSettingMode: number = 0;


	// For absolute mode (1) and realtive (2) 

	@JsonProperty('startPeriodMode', Number, false) 
	public startPeriodMode: number = 0;

	@JsonProperty('startOffsetDays', Number, false) 
	public startOffsetDays: number = 0;

	@JsonProperty('startOffsetDayMode', Number, false) 
	public startOffsetDayMode: number = 2;


	@JsonProperty('endPeriodMode', Number, false) 
	public endPeriodMode: number = 0;

	@JsonProperty('endOffsetDays', Number, false) 
	public endOffsetDays: number = 0;

	@JsonProperty('endOffsetDayMode', Number, false) 
	public endOffsetDayMode: number = 2;


	@JsonProperty('targetPeriodMode', Number, false) 
	public targetPeriodMode: number = 0;

	@JsonProperty('targetOffsetDays', Number, false) 
	public targetOffsetDays: number = 0;

	@JsonProperty('targetOffsetDayMode', Number, false) 
	public targetOffsetDayMode: number = 2; 



	@JsonProperty('remindUser', Boolean, false) 
	public remindUser: boolean = false;

	@JsonProperty('reminderOffsetDayMode', Number, false) 
	public reminderOffsetDayMode: number = 2;

	@JsonProperty('reminderOffsetDays', Number, false) 
	public reminderOffsetDays: number = 0;

	@JsonProperty('reminderInterval', Number, false) 
	public reminderInterval: number = 0;
     
}