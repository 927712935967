import React from 'react';
import { Notification } from '$storage/models/InAppNotifications/Notification';
import IANotification from './IANotification';
import { NoDataFound } from '../shared/WarningsAndErrors/NoDataFound';
import Session from '$src/core/Session';

interface IProps {
    notifications: Notification[];
    onClick?: () => void;
}
export default function IANotificationList(props: IProps) {

    function onClick() {
        if (props.onClick != null) {
            props.onClick();
        }
    }

    function renderNotifications(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        if(props.notifications.length >0 ){
            props.notifications.map((value, index) => {
                elements.push(
                    <IANotification onClick={() => onClick()} {...props} key={"Notification_" + index} notification={value} />
                );
            });
        }else{
            elements.push(
             <NoDataFound message={Session.instance.storage.translation.GetString("IAN:NoData")}/>
            );
        }
        // eslint-disable-next-line react/prop-types
        
        return elements;
    }

    return (
        <div className={"ianotifications__popout-panel"}>
            <ul>
                {
                    renderNotifications()
                }
            </ul>
        </div>
    );

}