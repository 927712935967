import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import { PaymentMyTeamJournal } from '$src/storage/models/Payment/PaymentMyTeamJournal';
import React from 'react';

/**
 * Decide which actions shall be shown in details row
 */
class DetailRowContextData {
	showDetails?: boolean;
	showChangeClass?: boolean;
	showChangeUser?: boolean;
	showCancel?: boolean;
	myTeamConf?: PaymentMyTeamJournal;
	onEntryUpdated?: (entry: PaymentBookingJournalResponse) => void;
}

/**
 * Share commands from Grid to GridDetailsRow within this context
 */
const DetailRowContext = React.createContext(new DetailRowContextData());

export { DetailRowContext, DetailRowContextData };