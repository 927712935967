import { DatePicker } from '$components/shared/DatePicker';
import { GenericFilter, IGenericFilterItem } from '$src/components/shared/GenericFilter';
import { Heading } from '$src/components/shared/Heading';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { InputTextArea } from '$src/components/shared/InputTextArea';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Translate } from '$src/components/shared/Translate';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import Logger from '$src/core/Logger';
import AttributeService from '$src/core/Services/AttributeService';
import SkillService from '$src/core/Services/SkillService';
import TeamService from '$src/core/Services/TeamService';
import Session from '$src/core/Session';
import { AssignUserSkillResponse } from '$src/storage/models/AssignUserSkillResponse';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { EAttributeType, EFileType, ESkillType } from '$src/storage/models/enums';
import { AssignUserSkillRequest } from '$src/storage/models/RequestObjects/AssignUserSkillRequest';
import { ItemSkillDisplay } from '$src/storage/models/Skill/ItemSkillDisplay';
import { ItemSkillLevelTranslatedDisplay } from '$src/storage/models/Skill/ItemSkillLevelTranslatedDisplay';
import { User } from '$src/storage/models/User';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import GTFileUpload from '$src/components/shared/Molecules/GTFileUpload';

interface IProps {
    isOpen: boolean;
    onAssignSuccess?: (assignments: AssignUserSkillResponse, type: ESkillType) => void;
    onClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    headingClassName: string | undefined;
    headingLevel: number;
    selectedEmployees: User[] | undefined;
    skillTypeValue?: ESkillType;
    preselectedSkillId?: number;
    preselectedSkillLevelId?: number;
    preselectedSkillLevelSetId?: number;
    bossRelationCode: string;
}

interface IState {
    isDataLoaded: boolean;

    displayMessages: string[];
    errorMessages: string[];
    skillTypeValue?: IGenericFilterItem;

    competenceTypeData: IGenericFilterItem[];
    competenceTypeValue: IGenericFilterItem;

    competenceSearchData: ItemSkillDisplay[];
    filteredCompetenceSearchData: IGenericFilterItem[];
    competenceSearchValue?: IGenericFilterItem;

    competenceGradeData: IGenericFilterItem[];
    competenceGradeValue?: IGenericFilterItem;

    assignmentDate: Date | null;
    expirationDate: Date | null;
    reason: string;
    isVisibleAfterAssignClick: boolean;
    disableAssignButton: boolean;

    reasonRequired: boolean;
    expirationDateVisible: boolean;
    competenceChanged: boolean;

    requestFileLabel: string;
    secondPageActivated: boolean;
    supportedFiletypesWithTranslation: any[];
    defaultFileType: EFileType;
    fileCollection: FileDataRequest[];

}

export class AssignSkillsModal extends React.Component<IProps, IState>{

    private TRANSLATION_PREFIX = 'AssignSkills'// does not work on production `${AssignSkillsModal.name.replace('Modal', '')}`;

    private COMPONENT_CLASS_PREFIX = 'Components:AssignSkills';

    private preselectedSkill?: IGenericFilterItem;
    private preselectedLevel?: IGenericFilterItem;

    private reasonTextAttributes: InputAttributes = {
        attributeValueType: undefined,
        class: '',
        editMode: true,
        hasFocus: false,
        id: 'txtReason',
        initialValidation: true,
        isReadOnly: false,
        isRequired: true,
        label: Session.instance.storage.translation.GetString('AssignSkills:lblReason'),
        labelClassName: 'heading__Level5',
        placeholder: '',
        regExpression: undefined,
        rows: 5,
        url: undefined,
        value: '',
    }

    private allSkillProfileTypes =
        {
            id: 0,
            text: Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:AllSkillTypes`) // does not work with this.getTranslation(), because of init.
        } as IGenericFilterItem;

    constructor(props: IProps) {
        super(props);

        this.state = {
            isDataLoaded: false,
            competenceChanged: false,
            displayMessages: new Array<string>(),
            errorMessages: new Array<string>(),
            skillTypeValue: {
                id: this.props.skillTypeValue ? this.props.skillTypeValue : ESkillType.ActualSkill,
                text: this.getTranslation(this.props.skillTypeValue ? ESkillType[this.props.skillTypeValue].toString() : ESkillType[ESkillType.ActualSkill].toString())
            } as IGenericFilterItem,
            competenceTypeData: new Array<IGenericFilterItem>(),
            competenceTypeValue: this.allSkillProfileTypes,
            competenceSearchData: [],
            filteredCompetenceSearchData: new Array<IGenericFilterItem>(),
            competenceSearchValue: undefined,
            competenceGradeData: new Array<IGenericFilterItem>(),
            competenceGradeValue: undefined,
            assignmentDate: new Date(new Date().toDateString()),
            expirationDate: null,
            reason: '',
            isVisibleAfterAssignClick: true,
            disableAssignButton: true,
            reasonRequired: true,
            expirationDateVisible: true,
            requestFileLabel: props.selectedEmployees !== undefined && props.selectedEmployees.length === 1 ?
                Session.instance.storage.translation.GetString('PerformanceCheck:FileUploadCertMiscFile')
                : Session.instance.storage.translation.GetString('PerformanceCheck:FileUploadMiscFile'),
            secondPageActivated: false, //only certificate upload if one employee is selected
            supportedFiletypesWithTranslation: props.selectedEmployees === undefined ? [] : props.selectedEmployees.length === 1 ? [
                { id: EFileType.Undefined, text: Session.instance.storage.translation.GetString('FileUpload:ChooseFileType') },
                { id: EFileType.Certificate, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeCertificate') },
                { id: EFileType.Miscellaneous, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeMiscellaneous') },
            ] : [{ id: EFileType.Miscellaneous, text: Session.instance.storage.translation.GetString('FileUpload:FileTypeMiscellaneous') },],
            defaultFileType: props.selectedEmployees === undefined ? EFileType.Undefined : props.selectedEmployees.length === 1 ? EFileType.Undefined : EFileType.Miscellaneous,
            fileCollection: []
        }
    }

    public async componentDidMount() {
        Logger.log(`Components.${AssignSkillsModal.name}`, `${this.componentDidMount.name} called`);
        const allPromises = new Array<Promise<unknown>>();
        if (this.props.preselectedSkillId == null) {
            allPromises.push(this.loadSkillProfileTypes());
            allPromises.push(this.loadSkillsWithAccessRight());
        } else {
            allPromises.push(this.loadSkillsWithAccessRight());
            if (this.props.preselectedSkillLevelSetId != null) {
                allPromises.push(this.loadSkillLevels(this.props.preselectedSkillLevelSetId));
            }
        }

        await Promise.all(allPromises);

        this.preselectedSkill = this.state.filteredCompetenceSearchData.find(item => item.id === this.props.preselectedSkillId);
        this.preselectedLevel = this.state.competenceGradeData.find(item => item.id === this.props.preselectedSkillLevelId);

        this.setState({ isDataLoaded: true, competenceSearchValue: this.preselectedSkill, competenceGradeValue: this.preselectedLevel });

        if (this.preselectedSkill !== undefined) {
            this.validateInputValues();
        }
    }

    public render() {
        const header = <Heading
            headingLevel={this.props.headingLevel}
            cssClass={this.props.headingClassName != null ? this.props.headingClassName : 'heading__Level2'}>
            <Translate>{`${this.TRANSLATION_PREFIX}:Header`}
            </Translate>
        </Heading>;

        let assigmentDateText = this.getTranslation(`lblAssignmentDate`);
        if (this.state.skillTypeValue != null && this.state.skillTypeValue.id === ESkillType.TargetSkill) {
            assigmentDateText = assigmentDateText.replace('*', '');
        }

        const inputTextArea =
            <InputTextArea
                attributes={this.reasonTextAttributes}
                isRequired={this.state.reasonRequired}
                onChange={(id: string, value: string) => (this.onTxtReasonChange(value))} />;

        let mainContent =
            <React.Fragment>
                <GenericFilter
                    id='AssignSkillsModalSkillTyp'
                    clearButton={false}
                    fetchedItemList={
                        [
                            { id: ESkillType.ActualSkill, text: this.getTranslation('ActualSkill') } as IGenericFilterItem,
                            { id: ESkillType.TargetSkill, text: this.getTranslation('TargetSkill') } as IGenericFilterItem,
                            { id: ESkillType.PerformanceReviewSkill, text: this.getTranslation('PerformanceReviewSkill') } as IGenericFilterItem,
                        ]}
                    initValue={this.state.skillTypeValue}
                    label={this.getTranslation('lblSkillType')}
                    filterable={true}
                    suggest={false}
                    onSelectedItemChange={(itm) => this.onChangeType(itm)}
                    className="my-team__item-filter"
                    required={true}
                    disabled={this.props.skillTypeValue != null} />
                <GenericFilter
                    id='AssignSkillsModalCompetenceType'
                    fetchedItemList={this.state.competenceTypeData}
                    initValue={this.allSkillProfileTypes}
                    label={this.getTranslation('lblCompetenceType')}
                    filterable={true}
                    suggest={false}
                    onSelectedItemChange={(itm) => { this.onChangeCompetenceType(itm), this.validateInputValues() }}
                    className="my-team__item-filter"
                    required={true}
                    disabled={this.preselectedSkill != null}
                />
                <GenericFilter
                    id='AssignSkillsModalCompetenceSearch'
                    fetchedItemList={this.state.filteredCompetenceSearchData}
                    initValue={this.state.competenceSearchValue}
                    label={this.getTranslation('lblCompetenceSearch')}
                    filterable={true}
                    suggest={false}
                    onSelectedItemChange={(itm) => { this.onChangeSearchCompetence(itm), this.validateInputValues() }}
                    className="my-team__item-filter"
                    required={true}
                    disabled={this.preselectedSkill != null}
                />
                {this.props.preselectedSkillId != null && this.preselectedSkill == null && !this.state.competenceChanged &&
                    <span className={'input-message error'}>
                        <Translate>AssignSkills:MissingAssignRightHint</Translate>
                    </span>
                }
                <GenericFilter
                    id='AssignSkillsModalCompetenceGrade'
                    fetchedItemList={this.state.competenceGradeData}
                    initValue={this.state.competenceGradeValue}
                    label={this.getTranslation('lblCompetenceGrade')}
                    filterable={true}
                    suggest={false}
                    onSelectedItemChange={(itm) => { this.onChangeCompetenceGrade(itm), this.validateInputValues() }}
                    className="my-team__item-filter"
                    required={true} />
                <div className="assign-skill-profile__label-datepicker">
                    <label className="k-form-field" htmlFor="dtAssignmentDate">
                        {assigmentDateText}
                    </label>
                    <DatePicker id="dtAssignmentDate" name="dtAssignmentDate" width="100%"
                        value={this.state.assignmentDate} onChange={(e) => { this.setState({ assignmentDate: e }, () => this.validateInputValues()) }}
                        required={this.state.skillTypeValue != null && this.state.skillTypeValue.id === ESkillType.ActualSkill} />
                </div>
                {
                    this.state.expirationDateVisible ?
                        <div className="assign-skill-profile__label-datepicker">
                            <label className="k-form-field" htmlFor="dtExpirationDate">
                                <Translate>{`${this.TRANSLATION_PREFIX}:lblExpirationDate`}
                                </Translate>
                            </label>
                            <DatePicker id="dtExpirationDate" name="dtExpirationDate" width="100%"
                                value={this.state.expirationDate} onChange={(e) => { this.setState({ expirationDate: e }, () => this.validateInputValues()) }} required={false} />
                        </div> : null}

            </React.Fragment>;

        const secondPageContent = <React.Fragment>
            {
                this.state.reasonRequired ?
                    inputTextArea : null
            }
            {
                this.state.skillTypeValue?.id == ESkillType.ActualSkill ?
                    <GTFileUpload
                        parentHeadingLevel={1}
                        label={this.state.requestFileLabel}
                        allowedFileExtensions={globalConfig.performanceCheck.allowedFileExtensions}
                        multipleUploadAllowed={globalConfig.performanceCheck.multipleFileUploadAllowed}
                        maxFileSizeInByte={globalConfig.performanceCheck.maxFileSizeInByte}
                        onUploadFiles={(files) => this.onFileChange(files)}
                        defaultFileType={this.state.defaultFileType}
                        showFileTypeSelection={true}
                        supportedFiletypesWithTranslation={this.state.supportedFiletypesWithTranslation}
                        multipleCertificateUploadAllowed={globalConfig.performanceCheck.multipleCertificateUploadAllowed}
                        multipleMiscFileUploadAllowed={globalConfig.performanceCheck.multipleMiscFileUploadAllowed}
                        filetypeRequired={globalConfig.performanceCheck.filetypeRequired}
                        onDisableFurtherAction={(disabled) => this.setState({ disableAssignButton: disabled })}
                        duplicateErrorMessage='PerformanceCheck:DocumentAlreadyUploaded'
                        multipleCertificateNotAllowedErrorMessage='PerformanceCheck:MultipleCertificateNotAllowedErrorMessage'
                        multipleMiscFileUploadAllowedErrorMessage='PerformanceCheck:MultipleMiscFileUploadAllowedErrorMessage'
                    /> : null
            }
        </React.Fragment>

        const assignButton = <button type="button" className="btn--sm btn--primary" disabled={this.state.disableAssignButton} onClick={() => this.onAssignClick()}>
            <Translate>{`${this.TRANSLATION_PREFIX}:Assign`}</Translate></button>;

        const nextButton = <button type="button" className="btn--sm btn--primary" disabled={this.state.disableAssignButton} onClick={() => this.setState({ secondPageActivated: true })}>
            <Translate>{`${this.TRANSLATION_PREFIX}:Next`}</Translate></button>;

        const closeButton = <button type="button" onClick={e => this.onRequestClose(e)} className="btn--sm btn--default"><Translate>{`${this.TRANSLATION_PREFIX}:Close`}</Translate></button>;

        let buttons: React.ReactFragment = <React.Fragment></React.Fragment>;

        if (this.state.isVisibleAfterAssignClick === false) {
            buttons = <div className={'assign-skill-profile__button-container modal__spread-buttons'}>
                {closeButton}
            </div>;
            mainContent = <React.Fragment></React.Fragment>;
        }
        else if (this.state.skillTypeValue?.id != ESkillType.ActualSkill) {
            buttons =
                <div className={'assign-skill-profile__button-container modal__spread-buttons'}>
                    {assignButton}
                    {closeButton}
                </div>;
        }
        else if (this.state.skillTypeValue?.id == ESkillType.ActualSkill) {
            if (this.state.secondPageActivated) {
                buttons =
                    <div className={'assign-skill-profile__button-container modal__spread-buttons'}>
                        {assignButton}
                        {closeButton}
                    </div>;
                mainContent = secondPageContent;
            }
            else {
                buttons =
                    <div className={'assign-skill-profile__button-container modal__spread-buttons'}>
                        {nextButton}
                        {closeButton}
                    </div>;
            }
        }

        return (
            <ModalPopup contentCssClass={this.state.secondPageActivated ? "assign-skills-modal-2ndpage" : "assign-skills-modal"}
                isOpen={this.props.isOpen}
                onRequestClose={(e) => this.onRequestClose(e)}>
                {this.state.isDataLoaded ?
                    <form className="assign-skill-profile__form">
                        {header}
                        {mainContent}
                        {this.prepareAlertBoxes()}
                        {buttons}
                    </form> :
                    <ProgressSpinner />
                }
            </ModalPopup>);
    }

    private async loadSkillProfileTypes() {
        const data: AttributeValue[] | GtError = await AttributeService.instance.getAttributeValues(EAttributeType.SkillType);

        if (isSuccess<AttributeValue[]>(data)) {
            Logger.log(`Components.${AssignSkillsModal.name}`, `${this.loadSkillProfileTypes.name} called`);
            const competenceTypeData = [];
            competenceTypeData.push(this.allSkillProfileTypes);
            data.map((item) => {
                competenceTypeData.push({ id: item.id, text: item.text, helperItem: undefined } as IGenericFilterItem);
            });
            this.setState({ competenceTypeData })
        } else {
            const errorMessage = `${this.loadSkillProfileTypes.name} failed to load skill profile types from AttributeService. ${data.message}`;
            Logger.log(`Components.${AssignSkillsModal.name}`, errorMessage);
            console.error(errorMessage);
        }
    }

    private async loadSkillsWithAccessRight() {
        const data: ItemSkillDisplay[] | GtError = await SkillService.instance.getSkillsWithAssignRight(Session.instance.getUserLanguageCodeOrFallBack, this.props.bossRelationCode)

        if (isSuccess<ItemSkillDisplay[]>(data)) {
            Logger.log(`Components.${AssignSkillsModal.name}`, `${SkillService.instance.getSkillsWithAssignRight.name} called`);
            const filteredCompetenceSearchData: Array<IGenericFilterItem> = [];
            const showPerformanceSkills = this.state.skillTypeValue?.id === ESkillType.PerformanceReviewSkill;
            data.map((skill) => {
                // The skill selection should either contain the performance skills (showInPerformanceReveiw = true) or the others (showInPerformanceReveiw = false), never both
                if (showPerformanceSkills === skill.showInPerformanceReview) {
                    filteredCompetenceSearchData.push({ id: skill.id, text: skill.displayName, helperItem: skill.slsId } as IGenericFilterItem);
                }
            });

            this.setState({ competenceSearchData: data, filteredCompetenceSearchData });
        } else {
            const errorMessage = `getSkillsWithAccessRight: failed to load skills for assigning from SkillService. ${data.message}`;
            Logger.log(`Components.${AssignSkillsModal.name}`, errorMessage);
            this.setState({ errorMessages: new Array<string>(errorMessage) });
        }
    }

    private async loadSkillLevels(skillLevelSetId: number) {
        const data: ItemSkillLevelTranslatedDisplay[] | GtError = await SkillService.instance.getSkillLevelsTranslated(Session.instance.getUserLanguageCodeOrFallBack,
            skillLevelSetId)

        if (isSuccess<ItemSkillLevelTranslatedDisplay[]>(data)) {
            Logger.log(`Components.${AssignSkillsModal.name}`, `getSkillLevelsTranslated called`);
            const competenceGradeData: Array<IGenericFilterItem> = [];
            data.map((iSkill) => {
                competenceGradeData.push({ id: iSkill.id, text: iSkill.translatedText } as IGenericFilterItem);
            });

            this.setState({ competenceGradeData });
        } else {
            const errorMessage = `getSkillLevelsTranslated: failed to load skill levels from SkillService. ${data.message}`;
            Logger.log(`Components.${AssignSkillsModal.name}`, errorMessage);
            console.error(errorMessage);

            this.setState({ errorMessages: new Array<string>(errorMessage) });
        }
    }

    private setDefaultState(): void {
        this.setState({
            displayMessages: new Array<string>(),
            errorMessages: new Array<string>(),
            skillTypeValue: {
                id: this.props.skillTypeValue ? this.props.skillTypeValue : ESkillType.ActualSkill,
                text: this.getTranslation(this.props.skillTypeValue ? ESkillType[this.props.skillTypeValue].toString() : ESkillType[ESkillType.ActualSkill].toString())
            } as IGenericFilterItem,
            competenceTypeValue: this.allSkillProfileTypes,
            filteredCompetenceSearchData: new Array<IGenericFilterItem>(),
            competenceSearchValue: undefined,
            competenceGradeData: new Array<IGenericFilterItem>(),
            competenceGradeValue: undefined,
            assignmentDate: new Date(new Date().toDateString()),
            expirationDate: null,
            reason: '',
            isVisibleAfterAssignClick: true,
            disableAssignButton: true,
            reasonRequired: true,
            expirationDateVisible: true,
            secondPageActivated: false
        });
    }

    private prepareAlertBoxes(): React.ReactNode {
        return <React.Fragment>
            {this.state.displayMessages.length > 0 ?
                <Alert alertType={'success'} alertAppereance={'box'}
                    messages={this.prepareMessages(this.state.displayMessages, 'success')} /> : null
            }
            {this.state.errorMessages.length > 0 ?
                <Alert alertType={'error'} alertAppereance={'box'}
                    messages={this.prepareMessages(this.state.errorMessages, 'error')} /> : null
            }
        </React.Fragment>
    }

    private prepareMessages(msgs: string[], alertType: string): Array<{ message: string; alertType: 'info' | 'warning' | 'error' | 'success' }> {

        return msgs.map(msg => {
            return { message: msg, alertType: alertType as ('info' | 'warning' | 'error' | 'success') };
        });
    }

    private onChangeType(itm: IGenericFilterItem | undefined) {
        const filteredCompetenceSearchData: Array<IGenericFilterItem> = [];

        if (itm) {
            const showPerformanceSkills = itm.id === ESkillType.PerformanceReviewSkill;
            this.state.competenceSearchData.map((skill) => {
                // The skill selection should either contain the performance skills (showInPerformanceReveiw = true) or the others (showInPerformanceReveiw = false), never both
                if (showPerformanceSkills === skill.showInPerformanceReview) {
                    filteredCompetenceSearchData.push({ id: skill.id, text: skill.displayName, helperItem: skill.slsId } as IGenericFilterItem);
                }
            });

            this.setState({
                skillTypeValue: itm,
                filteredCompetenceSearchData,
            }, () => this.validateInputValues());
        }
    }

    private onChangeCompetenceType(itm: IGenericFilterItem | undefined) {
        const filteredCompetenceSearchData: Array<IGenericFilterItem> = [];

        if (itm) {
            this.state.competenceSearchData.map((skill) => {
                if (skill.skillTypeId === itm?.id) {
                    filteredCompetenceSearchData.push({ id: skill.id, text: skill.displayName, helperItem: skill.slsId } as IGenericFilterItem);
                }
            });

            this.setState({
                competenceTypeValue: itm,
                filteredCompetenceSearchData,
                competenceSearchValue: undefined,
                competenceGradeData: new Array<IGenericFilterItem>(),
                competenceGradeValue: undefined,
            });
        }
    }

    private onChangeSearchCompetence(item: IGenericFilterItem | undefined): void {
        this.setState({
            competenceChanged: true,
            competenceSearchValue: item,
            competenceGradeData: new Array<IGenericFilterItem>(),
            competenceGradeValue: undefined,
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
        }, () => {
            if (this.state.competenceSearchValue != null) {
                this.loadSkillLevels(this.state.competenceSearchValue.helperItem);
            }
            else {
                this.setState({ competenceGradeData: new Array<IGenericFilterItem>(), });
            }
        });
    }

    private onChangeCompetenceGrade(item: IGenericFilterItem | undefined): void {
        this.setState({
            competenceGradeValue: item,
        }, () => this.validateInputValues());
    }

    private validateInputValues(): boolean {
        let valid = true;
        switch (this.state.skillTypeValue!.id) {
            case ESkillType.ActualSkill:
                this.setState({
                    reasonRequired: true,
                    expirationDateVisible: true,
                    errorMessages: []
                });
                if (this.state.skillTypeValue !== undefined && this.state.skillTypeValue.id === ESkillType.ActualSkill
                    &&
                    (
                        (this.state.reason == null || this.state.reason.length === 0)
                        && this.state.secondPageActivated === true
                    )
                ) {
                    valid = false;
                }
                if (this.state.skillTypeValue != null && this.state.assignmentDate == null && this.state.skillTypeValue.id === ESkillType.ActualSkill) {
                    const errMsg = this.getTranslation('AssignmentDateRequired');
                    this.setState({ errorMessages: new Array<string>(errMsg) });
                    valid = false;
                }
                if (this.state.assignmentDate != null && this.state.expirationDate != null
                    && this.state.assignmentDate >= this.state.expirationDate) {
                    const errMsg = this.getTranslation('AssignmentDateThenExpirationDate');
                    this.setState({ errorMessages: new Array<string>(errMsg) });
                    valid = false;
                }
                break;
            case ESkillType.PerformanceReviewSkill:
            case ESkillType.TargetSkill:
                this.setState({
                    reasonRequired: false,
                    expirationDateVisible: false,
                    errorMessages: []
                });
                break;
        }

        valid = valid
            && this.state.competenceTypeValue != null
            && this.state.competenceSearchValue != null
            && this.state.competenceGradeValue != null;

        this.setState({ disableAssignButton: !valid });

        return valid;
    }

    private onTxtReasonChange(value: string): void {
        this.setState({ reason: value }, () => this.validateInputValues());
    }

    private async onAssignClick() {

        if (this.validateInputValues()) {
            const methodName = `${AssignSkillsModal.name}:${this.onAssignClick.name}`;

            const successMsg = this.getTranslation('AssignSuccessfull');
            const errorMsg = this.getTranslation('FailedToAssign');
            const arrMsgs = new Array<string>();
            const arrErrMsg = new Array<string>();

            const requestObj: AssignUserSkillRequest = {
                employeeIds: this.props.selectedEmployees!.map((emp) => emp.id),
                skillTypeId: this.state.skillTypeValue!.id === ESkillType.PerformanceReviewSkill ? ESkillType.TargetSkill : this.state.skillTypeValue!.id,

                skillId: this.state.competenceSearchValue!.id,
                skillLevelId: this.state.competenceGradeValue!.id,
                reason: this.state.reason,
                assignDateTicks: this.state.assignmentDate == null ? undefined : this.state.assignmentDate.valueOf(),
                expDateTicks: this.state.expirationDate == null ? undefined : this.state.expirationDate.valueOf(),
                fileCollection: this.state.fileCollection

            }

            const response = await TeamService.instance.assingSkillToEmployees(requestObj);
            if (isSuccess<AssignUserSkillResponse>(response)) {
                if (this.props.onAssignSuccess) {
                    this.props.onAssignSuccess(response, this.state.skillTypeValue ? this.state.skillTypeValue.id : ESkillType.ActualSkill);
                }
                this.props.selectedEmployees!.map((emp) => {
                    if (response.employeeIdsWithSuccess.filter((rEmpId) => rEmpId === emp.id) != []) {
                        arrMsgs.push(`${successMsg} ${emp.firstName} ${emp.lastName}`);
                    }
                    else {
                        arrErrMsg.push(`${errorMsg} ${emp.firstName} ${emp.lastName}`);
                        Logger.log(this.COMPONENT_CLASS_PREFIX, `${methodName}: ${errorMsg}`);
                    }
                });

            }
            else if (response == null || response instanceof GtError) {
                this.props.selectedEmployees!.map((emp) => {
                    arrErrMsg.push(`${errorMsg} ${emp.firstName} ${emp.lastName}`);
                }
                );
                if (response instanceof GtError) {
                    arrErrMsg.unshift(response.message);
                    Logger.log(this.COMPONENT_CLASS_PREFIX, `${methodName}: ${response.message}`);
                }
            }

            this.setState({ displayMessages: arrMsgs, errorMessages: arrErrMsg, isVisibleAfterAssignClick: false });
        }
    }

    private onRequestClose(e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>): void {
        this.setDefaultState();
        this.props.onClose(e);
    }

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }

    private onFileChange(files: FileDataRequest[]) {
        this.setState({ fileCollection: files });
    }
}