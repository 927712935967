import { JsonObject, JsonProperty } from 'json2typescript';

/** All data of a "Glossar-Karte" */
@JsonObject
export class GlossaryTermImages {

    @JsonProperty('position', Number, false)
    public position: number = 0;

    @JsonProperty('caption', String, false)
    public caption: string = '';

    @JsonProperty('path', String, false)
    public path: string = '';      
}