import React, { useState } from 'react';

import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { CheckBox } from '$components/shared/CheckBox';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import ClassSearchSearchBox, { DefaultFilter } from '$components/paymentBooking/ClassSearchSearchBox';

import { PaymentClassSearchFilter } from '$src/storage/models/Payment/PaymentClassSearchFilter';
import { PaymentClassSearchResponse } from '$src/storage/models/Payment/PaymentClassSearchResponse';
import Session from '$src/core/Session';
import GTDateRangeSelect from '../shared/Atoms/GTDateRangeSelect';
import { useEffect } from 'react';

interface IClassSearchProps {
    onClassSelected?: (selectedClass: PaymentClassSearchResponse) => void;
    /** For the MyTeam Boss View. Restricts the class selection to courses where the current boss user has the publish right */
    bossRelationCode?: string;
}

/**
 * Component for payment admins search for a class to book
 * @param props RouteComponentProps
 * @returns Hook - complete component
 */
export default function ClassSearch(props: IClassSearchProps) {
    const [filter, setFilter] = useState<PaymentClassSearchFilter>(DefaultFilter);
    const [selectedResult, setSelectedResult] = useState<PaymentClassSearchResponse>();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        if (Session.instance.loginUser?.roles.includes("PaymentBookingOverviewAdmin")) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [isAdmin]);

    const selectedclassString = Session.instance.storage.translation.GetString('PaymentBooking:ClassSearchSelectedClassname');

    const attributeHeader = Session.instance.storage.translation.GetString('PaymentBooking:ClassSearchAttributeHeader').replace('{Course}', selectedResult?.course || '');

    return (
        <>
            <div className="">
                <Heading headingLevel={2} cssClass="heading__Title">
                    <Translate>PaymentBooking:ClassSearchTitle</Translate>
                </Heading>

                <div>
                    <Translate>PaymentBooking:ClassSearchSubTitle</Translate>
                </div>

                <div role="table" className="div-table__vertical-table">
                    <div role="rowgroup">
                        <div role="row" className="div-table__vertical-table-row">
                            <div role="columnheader">
                                <Translate>PaymentBooking:ClassSearchDateBetween</Translate>
                            </div>
                            <div role="cell">
                                <GTDateRangeSelect onMinSelected={e => setFilter({ ...filter, dateFrom: e || null })} onMaxSelected={e => setFilter({ ...filter, dateTo: e || null })} />
                            </div>
                        </div>
                        <div role="row" className="div-table__vertical-table-row">
                            <div role="columnheader">
                                <Translate>PaymentBooking:ClassSearchOptions</Translate>
                            </div>
                            <div role="cell">
                                <div className="class-search__flexOneline">
                                    <CheckBox id="clsOnlyWithFreeSeats" defaultChecked={true} value={filter.onlyWithFreeSeats ? 'on' : ''} onChange={() => setFilter({ ...filter, onlyWithFreeSeats: !filter.onlyWithFreeSeats })}>
                                        <Translate>PaymentBooking:OnlyFreeSeats</Translate>
                                    </CheckBox>
                                    {isAdmin && <CheckBox id='clsOnlyFuture' defaultChecked={true} value={filter.onlyFuture ? 'on' : ''} onChange={() => setFilter({ ...filter, onlyFuture: !filter.onlyFuture })}>
                                        <Translate>PaymentBooking:OnlyFuture</Translate>
                                    </CheckBox>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <Translate>PaymentBooking:ClassSearchSubTitle2</Translate>
                </div>

                <div role="table" className="div-table__vertical-table">
                    <div role="rowgroup">
                        <div role="row" className="div-table__vertical-table-row">
                            <div role="columnheader">
                                <Translate>PaymentBooking:ClassSearchClass</Translate>
                            </div>
                            <div role="cell">
                                <ClassSearchSearchBox bossRelationCode={props.bossRelationCode} filter={filter} onClassSelected={(e) => {
                                    setSelectedResult(e);
                                    if (props.onClassSelected) {
                                        props.onClassSelected(e);
                                    }
                                }} />
                            </div>
                        </div>
                        <div role="row" className="div-table__vertical-table-row">
                            <div role="columnheader">
                                <Translate>PaymentBooking:ClassSearchSelectedClass</Translate>
                            </div>
                            <div role="cell" className='class-search__selected-class'>
                                {
                                    selectedResult && <span>{selectedResult.replacePlaceholders(selectedclassString)}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    {
                        selectedResult && selectedResult.attribute &&
                        <Alert alertType='error' alertAppereance='box'>
                            <b>{attributeHeader}</b><br />
                            {selectedResult.attribute}
                        </Alert>
                    }
                </div>
            </div>
        </>
    );
}