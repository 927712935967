import React from 'react';
import InlineSVG from 'react-inlinesvg';

import GTIconButton from '$components/shared/Atoms/GTIconButton';

import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import ChangeUserIcon from '$resources/svgs/PaymentBookingJournal/pay_change_user.svg';
import ChangeClassIcon from '$resources/svgs/PaymentBookingJournal/pay_change_class.svg';
import CancelIcon from '$resources/svgs/PaymentBookingJournal/pay_cancel_booking.svg';
import { ECheckoutPaymentStatus, ECheckoutRegistrationStatus, EItemTypeAttributeValue } from '$src/storage/models/enums';
import Session from '$src/core/Session';
import { PaymentMyTeamJournal } from '$src/storage/models/Payment/PaymentMyTeamJournal';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`PaymentBookingJournal:${str}`);
}

interface IChangeBookingButtonsProps {
	data: PaymentBookingJournalResponse;
	myTeamConf?: PaymentMyTeamJournal; // is only used in the context of myTeam
	onChangeUserClicked: () => void;
	onChangeClassClicked: () => void;
	onCancelClicked: () => void;
}

/**
 * Change buttons for each booking journal result line
 * @param props 
 * @returns 
 */
export default function ChangeBookingButtons(props: IChangeBookingButtonsProps) {
	if (props.data.paymentStatus !== ECheckoutPaymentStatus.Completed && props.data.paymentStatus !== ECheckoutPaymentStatus.NoPayment) {
		return <></>
	}

	let adminView = false;
	if (props.myTeamConf === undefined) {
		adminView = true;
	}
	let classesChangeUser = props.data.HasAnyUserChanged ? 'change-booking-buttons__marked' : '';
	if (props.data.HasAnyCancelled) classesChangeUser = ' hide-change-booking-button';
	if (props.data.itemType !== EItemTypeAttributeValue.F2FCourse) classesChangeUser = 'hide-change-booking-button';

	let classesChangeClass = props.data.HasAnyClassChanged ? 'change-booking-buttons__marked' : '';
	if (props.data.HasAnyCancelled) classesChangeClass = ' hide-change-booking-button';
	if (props.data.itemType !== EItemTypeAttributeValue.F2FCourse) classesChangeClass = 'hide-change-booking-button';

	const classesCancel = props.data.itemType !== EItemTypeAttributeValue.F2FCourse && props.data.itemType !== EItemTypeAttributeValue.TrainingPlan ? 'hide-change-booking-button' : '';

	return (
		<>
		{ (props.data.registrationStatus == ECheckoutRegistrationStatus.Succeeded 
			|| props.data.registrationStatus == ECheckoutRegistrationStatus.Canceled)
			&& <div className='payment-booking-journal__change-booking-buttons'>
			{adminView || props.myTeamConf?.changeParticipant ? <GTIconButton id='btnChangeUser' ariaLabel='changeUser' additionalClassNames={classesChangeUser} onClick={() => props.onChangeUserClicked()} tooltipText={getTranslation('ButtonChangeUserTooltip')}>
				<InlineSVG src={ChangeUserIcon} />
			</GTIconButton> : null}
			{adminView || props.myTeamConf?.changeClass ? <GTIconButton id='btnChangeClass' ariaLabel='changeClass' additionalClassNames={classesChangeClass} onClick={() => props.onChangeClassClicked()} tooltipText={getTranslation('ButtonChangeClassTooltip')}>
				<InlineSVG src={ChangeClassIcon} />
			</GTIconButton> : null}
			{
				adminView || props.myTeamConf?.cancel ?
					props.data.HasAnyCancelled ?
					<span className='change-booking-buttons__marked'><InlineSVG src={CancelIcon} /></span>
					:
					<GTIconButton id='btnCancel' ariaLabel='cancel' additionalClassNames={classesCancel} onClick={() => props.onCancelClicked()} tooltipText={getTranslation('ButtonCancelTooltip')}><InlineSVG src={CancelIcon} /></GTIconButton>
			: null}

		</div>}
		</>
	);
}