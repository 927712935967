import React, { useState } from "react";
import { ModalPopup } from "../ModalPopup";
import { Translate } from "../Translate";
import GTButton, { IGTButtonProps } from "./GTButton";
import GTConfirm, { IGtConfirmTextProps } from "./GTConfirm";
/**
 * The GT Confirm Props
 */
export interface IGTConfirmButtonWithModal extends IGtConfirmTextProps, IGTButtonProps {
    modalTitle: string;
    modalText: string;
}

/**
 * Global Teach Confirm Component
 * @param props 
 */
export default function GtConfirmButtonWithModal(props: IGTConfirmButtonWithModal) {
    const [currentState, setCurrentState] = useState<'ACTION' | 'CONFIRMATION'>('ACTION');

    return <>
        {currentState === 'ACTION' &&
            <GTButton {...props} onClick={() => setCurrentState('CONFIRMATION')} />}

        {currentState === 'CONFIRMATION' &&
            <ModalPopup
                isOpen={true}
                disableClosing={true}
                onRequestClose={() => { console.log('closing disabled'); }}
                contentCssClass="confirmation"
                title={props.modalTitle} >
                <p>
                    <Translate>{props.modalText}</Translate>
                </p>
                <p>
                    <GTConfirm
                        onConfirm={(event) => { props.onClick(event); setCurrentState('ACTION') }}
                        onCancel={() => setCurrentState('ACTION')}
                        cancelText={props.cancelText}
                        confirmText={props.confirmText} />
                </p>
            </ModalPopup>
        }
    </>
}