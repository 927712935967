import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { NavigationPoint } from '$components/layout/menu/NavigationPoint';
import { NavigationPointContainer } from '$components/layout/menu/NavigationPointContainer';
import ReportList from '$components/myEmployees/reporting/ReportList';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import Session from '$src/core/Session';
import React from 'react';

export class ReportingPage extends React.Component<{}, {}> {
    private pageTitle = 'Navigation:Reporting';

    constructor(props: {}) {
        super(props);
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString(this.pageTitle);
    }

    public render() {
        return (
            <div>
                <div className="l-container">
                    <MenuBreadCrumb {...this.props} />
                    <div>
                        <Heading headingLevel={1} cssClass="heading__Title">
                            <Translate>{this.pageTitle}</Translate>
                        </Heading>
                    </div>
                    <div>
                        {this.renderMenuPoints()}
                        {this.renderReports()}
                    </div>
                </div>
            </div>
        )
    }

    public renderMenuPoints(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const menuPoints = globalConfig.navigationProperties.extendedNavigationItems.filter(mp => mp.parent != null && mp.parent === 'Reporting');
        const groups = [...new Set(menuPoints.map(item => item.groupId))];

        groups.map((g, index) => {
            elements.push(
                <NavigationPointContainer key={index}>
                    {this.renderMenupointsForGroup(g)}
                </NavigationPointContainer>
            );
        });

        return elements;
    }

    private renderReports() {
        const reports = globalConfig.tutoring.reportings;
        const userGroups = Session.instance.loginUser ? Session.instance.loginUser.groups.map(g => g.groupName.toLocaleLowerCase()) : [];
        const renderElements = [];

        for (const report of reports) {
            if(report.allowedGroups.includes("*")) {
                renderElements.push(report);
            }
            else {
                for(const reportGroup of report.allowedGroups) {
                    if(userGroups.includes(reportGroup.toLocaleLowerCase())) {
                        renderElements.push(report);
                        break;
                    }
                }
            }
        }

        return <ReportList reports={renderElements} />
    }

    private renderMenupointsForGroup(groupId: string): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const menuPoints = globalConfig.navigationProperties.extendedNavigationItems;
        const isUserBossOfDefaultRelation = Session.instance.isLoggedInUserBossOfDefaultRelation;
        menuPoints.filter(mp => mp.groupId === groupId
            && !(mp.mustBeBossOfDefaultRelation && !isUserBossOfDefaultRelation)
            && (mp.allowedRoles === undefined || Session.instance.hasCurrentUserAtLeastOneOfRoles(mp.allowedRoles))
            && (mp.parent != null && mp.parent === 'Reporting')).map((mp, index) => {
                elements.push(
                    <NavigationPoint
                        key={index}
                        link={mp.link}
                        text={mp.text}
                        id={mp.id}
                        backgroundColor={mp.backgroundColor}
                        iconColor={mp.iconColor}
                        openNewWindow={mp.openNewWindow} />
                );
            });
        return elements;
    }
}