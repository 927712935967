import React from "react";
import { Tooltip } from "$components/shared/Tooltip";
import { Badge } from "@progress/kendo-react-indicators/dist/npm/badge/Badge";

/**
 * The GT Button Props
 */
export interface IGTButtonProps {
    /**
     * The Button children
     */
    children: JSX.Element | string;
    /**
     * OnClick callback
     */
    onClick(event: React.FormEvent<HTMLButtonElement>): void;
    /**
     * Aria label for GT Button. Necessary if The button does not provide an informative text, e.g. an icon. 
     */
    ariaLabel?: string;
    /**
     * **Optional** Component unique id
     */
    id?: string;
    /**
     * **Optional** Flag to set if button is primary or not, default is true
     */
    isPrimary?: boolean;
    /**
     * **Optional** Enum to set the button size, default is middle
     */
    buttonSize?: EButtonSize;
    /**
     * Tool tip text. If undefined, the tool tip won't show.
     */
    tooltipText?: string;
    /**
     * **Optional** Additional css class
     */
    additionalClassNames?: string;
    /**
     * **Optional** Enum to set the button type, default type is button
     */
    type?: EButtonType;
    /**
     * **Optional** Flag if button is an icon button
     */
    isIconButton?: boolean;
    /**
     * **Optional** Default react button attributes
     */
    defaultButtonProps?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
    /**
     * **Optional** Activates a Badge Element next to the button. For CSS-> Badge has Position Absolute, so the button should be relative in order for the badge to show right
     */
    activateBadge?: boolean;
    /**
     * **Optional** Sets a text to be set inside the badge
     */
    badgeText?: string;
    /**
     * **Optional** Sets a css class to the badge Element
     */
    badgeCssClass?: string;
    /**
     * **Optional** disable button
     */
    disabled?: boolean;
}


/**
 * Enum for button size
 */
export enum EButtonSize {
    small = 'small',
    middle = 'middle',
    large = 'large'
}

/**
 * Enum for button type
 */
export enum EButtonType {
    button = 'button',
    submit = 'submit',
    reset = 'reset'
}

/**
 * Global Teach Button Component
 * **Important** for Icon Buttons please use {@see GTIconButton} this component contains A11y (Accessibility)
 */
export default function GTButton(props: IGTButtonProps) {
    let cssClasses = '';
    if (props.isIconButton) {
        cssClasses = `button-link ${props.additionalClassNames}`;
    } else {
        cssClasses = `button--${props.buttonSize} ${props.isPrimary ? 'btn--primary' : 'btn--secondary'} ${props.additionalClassNames}`
    }
    return (
        // div: display flex // btn ->badge // badge: position relative, right 20px
        // span is necessar to avoid bug that disabled buttons do not show tooltip. See: https://github.com/wwayne/react-tooltip/issues/304
        <span
            data-tip={props.tooltipText}
            data-for={props.id ? `GTButton_${props.id}`: ''}>
            <button
                className={cssClasses + (props.activateBadge ? ' gt-Button_relative' : '')} 
                type={props.type}
                onClick={(event) => props.onClick(event)}
                aria-label={props.ariaLabel}
                id={props.id} 
                disabled={props.disabled}
                {...props.defaultButtonProps}>

                {props.children}
                {props.id &&
                    <Tooltip id={`GTButton_${props.id}`} />
                }
                {
                    props.activateBadge ? 
                        <Badge align={{ vertical: 'top', horizontal: 'end' }} themeColor="secondary" className={props.badgeCssClass}>{props.badgeText}</Badge> :
                    ''
                }
            </button>
        </span>
    )
}

GTButton.defaultProps = {
    id: '',
    isPrimary: true,
    buttonSize: EButtonSize.middle,
    additionalClassNames: '',
    type: EButtonType.button,
    isIconButton: false,
}