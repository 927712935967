import Logger from '$src/core/Logger';
import F2FService from '$src/core/Services/F2FService';
import SessionCache from '$src/storage/cache/SessionCache';
import { isSuccess } from '$src/util/Result';
import GtError from '$util/GtError';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { LocationDocumentList } from '$src/storage/models/F2F/LocationDocumentList';

const CACHING_DURATION = 300;

export default class LocationDocumentListStorage extends SessionCache<string, LocationDocumentList> {
    protected className = 'LocationDocumentListStorage';
    protected loggerLocality = 'Storage.LocationDocumentListStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getLocationDocuments(courseItemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0, classId: number | null, roomId: number| null, venueId: number| null): Promise<LocationDocumentList | null | GtError> {
        const methodName = `${this.className}:getLocationDocuments`;
        const classIdNumber= classId ? classId : 0;
        const roomIdNumber= roomId ? roomId : 0;
        const venueIdNumber= venueId ? venueId : 0;
        if(classIdNumber < 0) { classId = 0}
        if(roomIdNumber < 0) { roomId = 0}
        if(venueIdNumber < 0) { venueId = 0}
        const key = `${classId}-${roomId}-${venueId}`;
        if(this.isObjectInCache(key)) {
            Logger.log(this.loggerLocality, `${methodName} getting LocationDocument from cache, key=${key}`);
            return this.getObjectFromCache(key);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting LocationDocument from server, key=${key}`);
            const response = await F2FService.instance.getLocationDocuments(courseItemId, itemContext, parentTrainingPlanId, classId, roomId, venueId);
            if(isSuccess<LocationDocumentList>(response))
            {
                this.saveObjectToCache(key, response);
                return this.getObjectFromCache(key);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get LocationDocument from server, key=${key}. Exception: ${response.message}`);
                return response;
            }
        }
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}