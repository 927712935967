import { JsonObject, JsonProperty } from 'json2typescript';
import { LessonStatusConverter } from '../converters/LessonStatusConverter';
import { ELessonStatus } from '../enums';


@JsonObject
export class TrainingPlanBlockCompletitionRequirement {

    @JsonProperty('blockId', Number, false)
    public blockId: number = 0;

    @JsonProperty('requiredItemSID', String, false)
    public requiredItemSID: string | undefined = undefined;

    @JsonProperty('requiredLessonStatusString', String, false)
    public requiredLessonStatusString: string | undefined = undefined;

    @JsonProperty('requiredScore', Number, false)
    public requiredScore: number = 0;
    
    @JsonProperty('fulfillmentRule', Number, false)
    public fulfillmentRule: number = 0;

    @JsonProperty('requiredLessonStatus', LessonStatusConverter, false)
    public requiredLessonStatus: ELessonStatus = ELessonStatus.NotAttempted;
}
