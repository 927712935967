import { Translate } from '$src/components/shared/Translate';
import React from 'react';

import Session from '$src/core/Session';



interface IState {
    message: string;
 
    
}
export class Contact extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = { 
            message: ''
    }
   
    }
    public render() {
         return (
            <div>
                <h1 className="heading__Level2"><Translate>Contact:Title</Translate></h1>
                <p><Translate>Contact:SubTitle</Translate></p>
                <br />
                <p dangerouslySetInnerHTML={ { __html: Session.instance.storage.translation.GetString('Contact:AddressBar1') }} />
                <p dangerouslySetInnerHTML={ { __html: Session.instance.storage.translation.GetString('Contact:AddressBar2') }} />
                <p dangerouslySetInnerHTML={ { __html: Session.instance.storage.translation.GetString('Contact:AddressBar3') }} />
                <p dangerouslySetInnerHTML={ { __html: Session.instance.storage.translation.GetString('Contact:AddressBar4') }} />
                <br />
            </div >
        );
    }
}

export default Contact;