import Session from '$core/Session';
import Logger from '$src/core/Logger';
import { SearchSelectBoxResponse } from '$src/storage/models/SearchSelectBoxResponse';
import { RenewTokensRequest } from '$src/storage/models/RequestObjects/RenewTokensRequest';
import { isSuccess } from '$src/util/Result';
import { Auth } from '$storage/models/User';
import GtError, { GtErrorType } from '$util/GtError';
import { JsonConvert, OperationMode, ValueCheckingMode } from 'json2typescript';
import Request from 'request';
import RequestPromise from 'request-promise';

interface ICtor0<T> {
    new(): T;
}

export interface IQueryStringParam {
    name: string;
    value: string;
}

/**
 * ServiceClient is used to provide the get / post methods for the Service classes.
 * If you don't know where you are here, you're propably wrong. 
 * You can find the service classes under core/Services/...Service.ts
 */
export default class ServiceClient {
    protected static _instance: ServiceClient | null = null;

    protected className = 'ServiceClient';
    protected loggerLocality = 'ServiceClient';

    protected _serviceUrl: string;
    protected _applicationName: string = 'Sui';

    protected _isInRefreshTokenDelay: number = 500; // Delay in ms when the service is refreshing the jwt tokens
    protected _maxRefreshTokenDelayCycles: number = 50;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected constructor() {
    }

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ServiceClient {
        return this._instance || (this._instance = new this());
    }

    /**
     * Initialize service client.
     * @param protocol http or https
     * @param hostname of the web API server
     * @param port PortSuffix to use (e.g. ':2020' or '' if none)
     * @param baseUrl Base URL to use, i.e. the part of the URL between hostname and API name
     */
    public init(protocol: string, hostname: string, portSuffix: string, baseUrl: string): void {
        this._serviceUrl = `${protocol}://${hostname}${portSuffix}/${baseUrl}`;
    }

    // #region "HTTP Methods"

    /**
     * Send GET request to web API and receive response.
     * @param api API to call (part of the URL)
     * @param responseType Type of response expected, may be an Error object in case of failure.
     * @param requestHeaders request headers to include with request.
     * @param params Parameters to be passed in URL
     */
    public async get<TResponse>(api: string, 
                                responseType: ICtor0<any>, 
                                requestHeaders?: Request.Headers, 
                                queryStringParams?: IQueryStringParam[],
                                ...params: string[]): Promise<TResponse | GtError> {
        const methodName = `${this.className}:get()`;

        let response: TResponse | GtError;
        let url: string = `${this._serviceUrl}${api}`;
        params.forEach(value => url += '/' + value);
        url += this.buildQueryString(queryStringParams);

        const options: Request.CoreOptions = {
            json: false,
            withCredentials: true, // Send cookies
        };

        await this.waitIfTokenIsRenewing(methodName);

        if (Session.instance.jwtToken !== '') {

            let isTokenUsable = await this.IsTokenUsable()
            if(!isTokenUsable) {
                // Lets give him another chance to wait for the renew token
                await this.waitIfTokenIsRenewing(methodName);
                isTokenUsable = await this.IsTokenUsable()
            }

            if (isTokenUsable) {
                Logger.log(this.loggerLocality, `${methodName} token is valid`);
                options.headers = { Authorization: 'Bearer ' + Session.instance.jwtToken };
            } else {
                Logger.log(this.loggerLocality, `${methodName} error = Tokens Invalid.`);
                console.error(`${this.loggerLocality} ${methodName} error = Tokens Invalid.`)
                return new GtError(1, '404', 'Tokens invalid');
            }
        }

        if (requestHeaders !== undefined) {
            options.headers = { ...options.headers, ...requestHeaders };
        }

        try {
            Logger.log(this.loggerLocality, `${methodName} loading data from server, url = ${url}.`);
            const body = await RequestPromise.get(url, options);
            if (responseType !== undefined) {
                response = this.typesafeJsonParser(body, responseType);
            } else {
                response = body;
            }
        } catch (error) {
            Logger.log(this.loggerLocality, `${methodName} error = ${error as Error}.`);
            console.error(`${this.loggerLocality} ${methodName} error = ${error as Error}.`)
            response = new GtError().initFromHttpResponseError(error).writeToSession();
        }
        return response;
    }

    /**
     * Send POST request to web API and receive response.
     * @param api API to call (part of the URL)
     * @param request Object to be serialized into the body of the request.
     * @param responseType Type of response expected, may be an Error object in case of failure.
     * @param requestHeaders Parameters to be passed in URL.
     */
    public async post<TResponse>(api: string, 
                                 request: {}, 
                                 responseType?: ICtor0<any>, 
                                 requestHeaders?: Request.Headers, 
                                 queryStringParams?: IQueryStringParam[]): Promise<TResponse | GtError> {
        const methodName = `${this.className}:post()`;
        let response: TResponse | GtError;
        const options: Request.CoreOptions = {
            body: JSON.stringify(request),
            json: false,
            withCredentials: true,  // Send cookies
        };
        options.headers = { 'content-type': 'application/json' };
        await this.waitIfTokenIsRenewing(methodName);
        if (Session.instance.jwtToken !== '') {

            let isTokenUsable = await this.IsTokenUsable()
            if(!isTokenUsable) {
                // Lets give him another chance to wait for the renew token
                await this.waitIfTokenIsRenewing(methodName);
                isTokenUsable = await this.IsTokenUsable()
            }

            if (isTokenUsable) {
                const authHeader = { Authorization: 'Bearer ' + Session.instance.jwtToken };
                options.headers = { ...options.headers, ...authHeader };
            } else {
                Logger.log(this.loggerLocality, `${methodName} error = Tokens Invalid.`);
                console.error(`${this.loggerLocality}  ${methodName} error = Tokens Invalid.`)
                return new GtError(1, '404', 'Tokens invalid');
            }
        }
        if (requestHeaders !== undefined) {
            options.headers = { ...options.headers, ...requestHeaders };
        }

        let url: string = (api.startsWith('http://') || api.startsWith('https://')) ? api : `${this._serviceUrl}${api}`;
        url += this.buildQueryString(queryStringParams);

        try {
            const body = await RequestPromise.post(url, options);
            // DK: prevent error 'JsonConvert.deserialize() is not in valid JSON format' when Boolean | String
            if (responseType !== undefined && (responseType.name === 'Boolean' || responseType.name === 'String')) {
                return JSON.parse(body);
            }
            else if (responseType !== undefined && responseType.name !== 'Boolean' && responseType.name !== 'String') {
                response = this.typesafeJsonParser(body, responseType);
            } else {
                response = body;
            }
        } catch (error) {
            Logger.log(this.loggerLocality, `${methodName} error = ${error as Error}.`);
            console.error(`${this.loggerLocality} ${methodName} error = ${error as Error}.`)
            response = new GtError().initFromHttpResponseError(error).writeToSession();
        }
        return response;
    }

    /**
     * Send POST request to web API and receive response.
     * @param api API to call (part of the URL)
     * @param formData
     * @param responseType
     */
    public async postFormData<TResponse>(api: string, formData: FormData, responseType?: ICtor0<any>): Promise<TResponse | GtError> {
        const methodName = `${this.className}:postFormData()`;
        let response: TResponse | GtError = new GtError(GtErrorType.UnknownError);

        await this.waitIfTokenIsRenewing(methodName);
        if (Session.instance.jwtToken !== '') {

            let isTokenUsable = await this.IsTokenUsable()
            if (!isTokenUsable) {
                // Lets give him another chance to wait for the renew token
                await this.waitIfTokenIsRenewing(methodName);
                isTokenUsable = await this.IsTokenUsable()
            }

            if (isTokenUsable) {
                const authHeader = { Authorization: 'Bearer ' + Session.instance.jwtToken };
                let url: string = `${this._serviceUrl}${api}`;

                try {
                    const fResponse = await fetch(url, {
                        method: 'POST',
                        headers: authHeader,
                        body: formData,
                    });
                    const body = await fResponse.text();

                    if (responseType !== undefined && (responseType.name === 'Boolean' || responseType.name === 'String')) {
                        response = JSON.parse(body);
                    }
                    else if (responseType !== undefined && responseType.name !== 'Boolean' && responseType.name !== 'String') {
                        response = this.typesafeJsonParser(body, responseType);
                    } else {
                        response = await fResponse.json();
                    }

                    return response;

                } catch (error) {
                    Logger.log(this.loggerLocality, `${methodName} error = ${error as Error}.`);
                    console.error(`${this.loggerLocality} ${methodName} error = ${error as Error}.`)
                    response = new GtError().initFromHttpResponseError(error).writeToSession();

                    return response;
                }

            } else {
                Logger.log(this.loggerLocality, `${methodName} error = Tokens Invalid.`);
                console.error(`${this.loggerLocality}  ${methodName} error = Tokens Invalid.`)
                response = new GtError(1, '404', 'Tokens invalid');

                return response;
            }
        }

        return response;
    }

    /**
     * Send POST request to web API and receive response.
     * @param api API to call (part of the URL)
     * @param request Object to be serialized into the body of the request.
     * @param requestHeaders Parameters to be passed in URL.
     */
    public async postVoid(api: string, 
                          request: {}, 
                          requestHeaders?: Request.Headers): Promise<undefined | GtError> {
        const methodName = `${this.className}:postVoid()`;       
        let response: undefined | GtError;
        const options: Request.CoreOptions = {
            body: JSON.stringify(request),
            json: false,
            withCredentials: true,  // Send cookies
        };
        options.headers = { 'content-type': 'application/json' };
        await this.waitIfTokenIsRenewing(methodName);
        if (Session.instance.jwtToken !== '') {

            let isTokenUsable = await this.IsTokenUsable()
            if(!isTokenUsable) {
                // Lets give him another chance to wait for the renew token
                await this.waitIfTokenIsRenewing(methodName);
                isTokenUsable = await this.IsTokenUsable()
            }

            if (isTokenUsable) {               
                const authHeader = { Authorization: 'Bearer ' + Session.instance.jwtToken };
                options.headers = { ...options.headers, ...authHeader };
            } else {
                Logger.log(this.loggerLocality, `${methodName} error = Tokens Invalid.`);
                console.error(`${this.loggerLocality}  ${methodName} error = Tokens Invalid.`)
                return new GtError(1, '404', 'Tokens invalid');
            }
        }
        if (requestHeaders !== undefined) {
            options.headers = { ...options.headers, ...requestHeaders };
        }
        const url: string = `${this._serviceUrl}${api}`;

        try {
            await RequestPromise.post(url, options);
            response = undefined;
        } catch (error) {
            Logger.log(this.loggerLocality, `${methodName} error = ${error as Error}.`);
            console.error(`${this.loggerLocality} ${methodName} error = ${error as Error}.`)
            response = new GtError().initFromHttpResponseError(error).writeToSession();
        }
        return response;
    }

    /*
        Returns the url of the service
    */
    public getUrl(): string {
        const url: string = `${this._serviceUrl}`;
        return url;
    }


    //#endregion "HTTP Methods"

    /**
     * Build query string form list with name/value pairs. 
     * If list doesn't contain a name/value for 'language' add one with current language from session.
     * @param {IQueryStringParam[]} list of name-value-pairs to be added as query string parameters (?p1=x1&p2=x2&...)
     * @returns {string} query string
     */
    protected buildQueryString(queryStringParams?: IQueryStringParam[]): string {
        let queryString = '';
        let queryStringContainsLanguage = false;
        if (queryStringParams && queryStringParams.length > 0) {
            queryStringParams.forEach(qsp => {
                queryString += '&' + qsp.name + '=' + qsp.value;
                if (qsp.name.toLowerCase() === 'language') {
                    queryStringContainsLanguage = true;
                }
            });
        }
        if (queryStringContainsLanguage) {
            queryString = queryString.replace('&', '?');
        } else {
            queryString = '?language=' + Session.instance.languageCode + queryString;
        }
        return queryString;
    }

    /**
     * Parse JSON string (e.g. response from web API) to object. This is a type-safe parser!
     * @param jsonString JSON string to be parsed.
     * @param classConstructor Type (class) of the object to be build from JSON string.
     */
    protected typesafeJsonParser<T>(jsonString: string, classConstructor: ICtor0<T>): T {
        const methodName = `${this.className}:typesafeJsonParser()`;
        const jsonConvert: JsonConvert = new JsonConvert();
        let typesafeDTO: T = new classConstructor();
        try {
            // TODO set operationMode to OperationMode.ENABLE for production, see https://www.npmjs.com/package/json2typescript
            jsonConvert.operationMode = OperationMode.ENABLE; // THIS MUST BE ENABLED! Don't change (PS). Other settings disable type checking or end in performance problems.
            jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
            jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL; // TODO: set to ALLOW_OBJECT_NULL -> object properties may be null, but not primitve types
            // TODO: The following statements may throw an exception!
            const object: {} = JSON.parse(jsonString);

            // DK: prevent error 'JsonConvert.deserialize() is not in valid JSON format' when Boolean | String
            if (classConstructor.name === 'Boolean' || classConstructor.name === 'String') {
                typesafeDTO = object as T;
            } else {
                typesafeDTO = jsonConvert.deserialize(object, classConstructor);
            }
        }
        catch (exception) {
            console.error(`${this.loggerLocality} ${methodName} error = ${exception}, type = ${classConstructor.name}.`)
            Logger.log(this.loggerLocality, `${methodName} error = ${exception}, type = ${classConstructor.name}.`);
        }
        return typesafeDTO;
    }

    /**
     * Check if token is expired, if yes try to generate new tokens with refreshToken and save it into session
     */
    protected async IsTokenUsable(): Promise<boolean> {
        const methodName = `${this.className}:IsTokenUsable()`;
        let retVal: boolean = true;
        Logger.log(this.loggerLocality, `${methodName}: start verifying`);
        if (Session.instance.isJWTTokenValid()) {
            Logger.log(this.loggerLocality, `${methodName}: Token is valid`);
            retVal = true;
        } else {
            Logger.log(this.loggerLocality, `${methodName}: Token is invalid`);
            if (!Session.instance.isRenewingTokens) {
                Session.instance.isRenewingTokens = true; // Lock method for only one run --> Prevent multiple executions
                Logger.log(this.loggerLocality, `${methodName}: resetting token`);

                const obj = new RenewTokensRequest();
                obj.jwtToken = Session.instance.jwtToken;
                obj.refreshToken = Session.instance.refreshToken;
                const isStoredLocal = Session.instance.isJWTStoredLocal;
                const auth = await this.getNewTokens<Auth>('auth/renewTokens', obj, Auth, undefined);
                if (isSuccess<Auth>(auth)) {
                    if(auth == null){
                        Logger.log(this.loggerLocality, `${methodName}: failed to get new token`);                      
                        Session.instance.logout();
                        // eslint-disable-next-line no-self-assign
                        window.location.href = window.location.href;
                        retVal = false;
                    }else{
                        Logger.log(this.loggerLocality, `${methodName}: successfully got new token`);
                        Session.instance.setJwtToken(auth.jwtSecurityToken, auth.refreshToken, isStoredLocal);
                        retVal = true;
                    }
                    
                } else {
                    Logger.log(this.loggerLocality, `${methodName}: failed to get new token : ${auth.message}, ${auth.detailedObject}`);
                    Session.instance.logout();
                    // eslint-disable-next-line no-self-assign
                    window.location.href = window.location.href;
                    retVal = false;
                }

                Session.instance.isRenewingTokens = false;
            }
            else {
                Logger.log(this.loggerLocality, `${methodName}: Token is invalid but already renewing`);
                retVal = false;
            }
        }
        return retVal;
    }

    // tslint:disable-next-line:member-ordering
    public async getNewTokens<TResponse>(api: string, request: {}, responseType: ICtor0<TResponse>, requestHeaders?: Request.Headers): Promise<TResponse | GtError> {
        const methodName = `${this.className}:getNewTokens()`;
        let response: TResponse | GtError;
        const options: Request.CoreOptions = {
            body: JSON.stringify(request),
            json: false,
            withCredentials: true,  // Send cookies
        };
        options.headers = { 'content-type': 'application/json' };
        if (Session.instance.jwtToken !== '') {
            Logger.log(this.loggerLocality, `${methodName}: jwt Token is not empty, using bearer authorization.`);
            const authHeader = { Authorization: 'Bearer ' + Session.instance.jwtToken };
            options.headers = { ...options.headers, ...authHeader };
        }
        if (requestHeaders !== undefined) {
            options.headers = { ...options.headers, ...requestHeaders };
        }
        const url: string = `${this._serviceUrl}${api}`;

        try {
            const body = await RequestPromise.post(url, options);
            if (responseType !== undefined) {
                response = this.typesafeJsonParser(body, responseType);
            } else {
                response = body;
            }
        } catch (error) {
            Logger.log(this.loggerLocality, `${methodName} error = ${error as Error}.`);
            response = new GtError().initFromHttpResponseError(error).writeToSession();
        }
        return response;
    }

    private async waitIfTokenIsRenewing(methodName: string) {
        let waitCounter = 0;
        Logger.log(this.loggerLocality, `${methodName} waitIfTokenIsRenewing, is token renewing`);
        while(Session.instance.isRenewingTokens && waitCounter < this._maxRefreshTokenDelayCycles) {
            Logger.log(this.loggerLocality, `${methodName} waitIfTokenIsRenewing Delay Cycle ${waitCounter + 1}`);
            await new Promise(resolve => setTimeout(resolve, this._isInRefreshTokenDelay));
            Logger.log(this.loggerLocality, `${methodName} waitIfTokenIsRenewing - Delay Cycle finished`);
            waitCounter++;
        }
        if(waitCounter >= this._maxRefreshTokenDelayCycles) {
            const errorMessage = `${methodName} waitIfTokenIsRenewing - exeeded max refresh token delay cycles`
            Logger.log(this.loggerLocality, errorMessage);
            console.error(this.loggerLocality, errorMessage);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} waitIfTokenIsRenewing - token is ready, waited ${waitCounter * this._isInRefreshTokenDelay}ms`);
        }
    }

    /**
     * Generic method to call a controller/search api action for the GTSearchSelectBox control
     * @param searchController Name of the controller where the searchMethod is placed in
     * @param searchMethod Name of the paymentAdmin api action
     * @param searchText Text to search for
     * @param parameters Additional query string parameters to give the search method some extra information like filtering data
     * @returns id, sid and display for general use
     */
         public async getSearch(searchController: string, searchMethod: string, searchText: string, parameters: IQueryStringParam[] = []): Promise<SearchSelectBoxResponse[] | GtError> {
            const language = Session.instance.getUserLanguageCodeOrFallBack;
    
            const params: IQueryStringParam[] = [];
            params.push({ name: 'language', value: language });
            params.push({ name: 'searchText', value: searchText });
            if (parameters && parameters.length > 0) {
                parameters.forEach(p => params.push(p));
            }
    
            return await this.post<SearchSelectBoxResponse[]>(`${searchController}/${searchMethod}`, {}, SearchSelectBoxResponse, undefined, params);
        }
}