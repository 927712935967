import React, { useState, useEffect } from "react";
import { Translate } from "$src/components/shared/Translate";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import { Heading } from "$src/components/shared/Heading";
import Session from "$src/core/Session";
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { ComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router";
import ReportingService from "$src/core/Services/ReportingService";
import { EvaluationOfTplan } from "$src/storage/models/EvaluationOfTplan";
import { isSuccess } from "$src/util/Result";
import Logger from "$src/core/Logger";
import GTButton from "../shared/Atoms/GTButton";
import { EvaluationOfTplanData } from "$src/storage/models/EvaluationOfTplanData";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, SeriesClickEvent } from "@progress/kendo-react-charts";
import { ELessonStatus, ESortDirection } from "$src/storage/models/enums";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import GTHorizontalTable from "../shared/Molecules/GTHorizontalTable";
import GTHorizontalTableHeaderRow, { GTHeaderContent } from "../shared/Atoms/GTHorizontalTableHeaderRow";
import { SortableHeaderElement } from "../shared/SortableHeaderElement";
import EvaluationOfTplansItem from "./EvaluationOfTplansItem";
import SortingAlgorithm from "$src/util/SortingAlgorithm";
import { EvaluationOfTplansItemModel } from "./EvaluationOfTplansItemModel";
// report on click / touch event
import "hammerjs";
import { filterBy } from "@progress/kendo-data-query";
import { BreadCrumb } from "../breadCrumb/BreadCrumb";

interface ISort {
    id: string;
    direction: ESortDirection;
}

export default function EvaluationOfTplans() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [filteredTplanItems, setFilteredTplanItems] = useState<EvaluationOfTplan[] | undefined>([]);
    const [allTplanItems, setAllTplanItems] = useState<EvaluationOfTplan[] | undefined>([]);
    const [selectedTplan, setSelectedTplan] = useState<EvaluationOfTplan | undefined>();

    const [fullData, setFullData] = useState<EvaluationOfTplanData[] | undefined>([]);
    const [moduleNames, setModuleNames] = useState<string[]>([]);
    const [reportData, setReportData] = useState<{ name: string; data: number[]; color: string }[]>([]);
    const [showReport, setShowReport] = useState<boolean>(false);

    type ScheduleData = {
        sceId: number;
        sceTitleOrCode: string;
    }

    const [filteredSchedule, setFilteredSchedule] = useState<ScheduleData[] | undefined>([]);
    const [selectedSchedule, setSelectedSchedule] = useState<ScheduleData | undefined>();

    const evaluationOfTplansItemModel = new EvaluationOfTplansItemModel();
    const [activeHeader, setActiveHeader] = useState<string>('name');
    const [sort, setSort] = useState<ISort>({ id: 'name', direction: ESortDirection.Up });
    const [selectedModuleStatus, setSelectedModuleStatus] = useState<ELessonStatus>(ELessonStatus.Undefined);
    const [selectedModule, setSelectedModule] = useState<string>("");
    const [selectedTableData, setSelectedTableData] = useState<EvaluationOfTplanData[] | undefined>([]);
    const [showDataTable, setShowDataTable] = useState<boolean>(false);

    const tr = Session.instance.storage.translation;
    const className = 'EvaluationOfTplans';
    const loggerLocality = 'Components.EvaluationOfTplans';
    const bossRelationCodeParam = useParams<{ bossRelationCode: string }>();
    const bossRelationCode: string = bossRelationCodeParam != null && bossRelationCodeParam.bossRelationCode ? bossRelationCodeParam.bossRelationCode : "";

    const exporter = React.createRef<ExcelExport>();
    
    useEffect(() => {
        const methodName = `${className}:useEffect(), [isLoaded]`;
        if (!isLoaded && filteredTplanItems?.length === 0) {

            ReportingService.instance.getEvaluationOfTplanSelection(Session.instance.getUserLanguageCodeOrFallBack, bossRelationCode).then((result) => {
                if (isSuccess<EvaluationOfTplan[]>(result)) {
                    setAllTplanItems(result.slice());
                    setFilteredTplanItems(result.slice());
                    setFilteredSchedule(undefined);

                    setIsLoaded(true);

                } else {
                    const errorMessage = `${methodName} failed getEvaluationOfTplanSelection`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);

                    setIsLoaded(true);
                }
            });
        }

    }, [isLoaded])

    useEffect(() => {
        if (selectedTplan) {
            const schedules = new Array<ScheduleData>();
            for (const [key, value] of Object.entries(selectedTplan.scheduleDict)) {
                schedules.push({ sceId: key as unknown as number, sceTitleOrCode: value })
            }

            setFilteredSchedule(schedules);
            setSelectedSchedule(undefined);
        }

    }, [selectedTplan])

    function renderTplanFilters() {
        return (
            <>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>EvaluationOfTplans:TplanFilterTitle</Translate></Heading>
                    <div className="subTitle__spacer">
                        <Translate>EvaluationOfTplans:TplanFilterSubTitle</Translate>
                    </div>
                </div>

                <ComboBox
                    data={filteredTplanItems}
                    label={tr.GetString('EvaluationOfTplans:FilterTplans')}
                    dataItemKey="itmId"
                    textField="title"
                    filterable={true}
                    onFilterChange={(e: ComboBoxFilterChangeEvent) => { allTplanItems && setFilteredTplanItems(filterBy(allTplanItems.slice(), e.filter)) }}
                    onChange={(e) => setSelectedTplan(e.target.value)}
                    value={selectedTplan}
                    allowCustom={false}
                    disabled={false}
                />
            </>
        )
    }

    function getModuleStatus(index: number): ELessonStatus {
        switch (index) {
            case 0:
                return ELessonStatus.Undefined;
            case 1:
                return ELessonStatus.NotAttempted;
            case 2:
                return ELessonStatus.Incomplete;
            case 3:
                return ELessonStatus.Failed;
            case 4:
                return ELessonStatus.Completed;
            case 5:
                return ELessonStatus.Passed;
        }

        return ELessonStatus.Undefined;
    }

    function getLessonStatusTranslation(index: number | ELessonStatus): string {
        switch (index) {
            case ELessonStatus.Incomplete:
                return Session.instance.storage.translation.GetString('LessonStatus:Incomplete');
            case ELessonStatus.Completed:
                return Session.instance.storage.translation.GetString('LessonStatus:Completed');
            case ELessonStatus.Failed:
                return Session.instance.storage.translation.GetString('LessonStatus:Failed');
            case ELessonStatus.Passed:
                return Session.instance.storage.translation.GetString('LessonStatus:Passed');
            case ELessonStatus.NotAttempted:
                return Session.instance.storage.translation.GetString('LessonStatus:NotAttempted');
            default:
            case ELessonStatus.Undefined:
                return Session.instance.storage.translation.GetString('LessonStatus:Undefined');
        }
    }

    function showExportAndTable(event: SeriesClickEvent) {
        const statusLevelIndex = event.series.index;

        // there was a split for categories in chart below (renderReport)
        const regexNewLine = /(?:\r\n|\r|\n)/g;
        const category = event.category.replace(regexNewLine, "");

        const moduleStatus = getModuleStatus(statusLevelIndex);

        if (fullData) {
            const tmp = fullData.filter(d => d.tPElementTitle.replace(regexNewLine, "") == category && d.eTPBlockLessonStatus === moduleStatus);
            if (tmp) {
                setSelectedModuleStatus(moduleStatus);
                setSelectedModule(category);
                setSelectedTableData(tmp);
                setShowDataTable(true);
            }
        }
    }

    function renderReport() {

        const showModuleNames = [...moduleNames];
        for (let i = 0; i < showModuleNames.length; i++) {
            if (showModuleNames[i].length > 25) {
                showModuleNames[i] = showModuleNames[i].ChunkSubstr(25).join("\n");
            }
        }

        return (
            <div>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>EvaluationOfSkills:CharTitle</Translate></Heading>
                    <div className="subTitle__spacer">
                        <Translate>EvaluationOfSkills:CharTitleSubTitle</Translate>
                    </div>
                </div>
                {
                    reportData && reportData.length > 0 ?
                        <Chart onSeriesClick={(event) => showExportAndTable(event)}>
                            <ChartTitle text={undefined} />
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={showModuleNames} />
                            </ChartCategoryAxis>
                            <ChartSeries>
                                {reportData.map((item, idx) => (
                                    <ChartSeriesItem
                                        key={idx}
                                        type="bar"
                                        tooltip={{
                                            visible: true,
                                        }}
                                        markers={{ visible: true }}
                                        stack={true}
                                        data={item.data}
                                        name={item.name}
                                        color={item.color}
                                    />
                                ))}
                            </ChartSeries>
                        </Chart>
                        : <></>
                }
            </div>
        );
    }

    function prepareDataPart(data: EvaluationOfTplanData[], filter: ELessonStatus): number[] {
        const counts = new Map<string, number>();

        data.forEach(f => {
            if (!counts.has(f.tPElementTitle)) {
                counts.set(f.tPElementTitle, data.filter(d => d.eTPBlockLessonStatus == filter && d.tPElementTitle == f.tPElementTitle).length);
            }
        })

        return [...counts.values()];
    }

    function loadReport(): void {
        const methodName = `${className}:loadReport()`;

        setIsLoaded(false);

        ReportingService.instance.getEvaluationOfTplanData(Session.instance.getUserLanguageCodeOrFallBack, bossRelationCode, selectedTplan!.itmId,
            selectedSchedule === undefined ? 0 : selectedSchedule.sceId,
            globalConfig.reporting.evaluationOfTplans.bossEmployeeACLCheck,
            // TP Block
            10000003).then((result) => {
                if (isSuccess<EvaluationOfTplanData[]>(result)) {

                    const tmp: string[] = [];
                    result?.map(item => {
                        if (!tmp.includes(item.tPElementTitle)) {
                            tmp.push(item.tPElementTitle)
                        }

                        item.lessonStatus = item.lessonStatus == ELessonStatus.Undefined ? ELessonStatus.NotAttempted : item.lessonStatus;
                        item.eTPBlockLessonStatus = item.eTPBlockLessonStatus == ELessonStatus.Undefined ? ELessonStatus.NotAttempted : item.eTPBlockLessonStatus;

                        item.lessonStatusTranslated = getLessonStatusTranslation(item.lessonStatus);
                        item.eTPBlockLessonStatusTranslated = getLessonStatusTranslation(item.eTPBlockLessonStatus);
                    })

                    setModuleNames(tmp);

                    const tmpReportData = [
                        //  ELessonStatus is mapped from Undefinied to NotAttempted above, same data (dimensions) for report and table
                        // - > set to empty legend here
                        {
                            name: '',
                            data: prepareDataPart(result, ELessonStatus.Undefined),
                            color: ""
                        },
                        {
                            name: getLessonStatusTranslation(ELessonStatus.NotAttempted),
                            data: prepareDataPart(result, ELessonStatus.NotAttempted),
                            color: "grey"
                        },
                        {
                            name: getLessonStatusTranslation(ELessonStatus.Incomplete),
                            data: prepareDataPart(result, ELessonStatus.Incomplete),
                            color: "#d7191c"
                        },
                        {
                            name: getLessonStatusTranslation(ELessonStatus.Failed),
                            data: prepareDataPart(result, ELessonStatus.Failed),
                            color: "#fdae61"
                        },
                        {
                            name: getLessonStatusTranslation(ELessonStatus.Completed),
                            data: prepareDataPart(result, ELessonStatus.Completed),
                            color: "#abd9e9"
                        },
                        {
                            name: getLessonStatusTranslation(ELessonStatus.Passed),
                            data: prepareDataPart(result, ELessonStatus.Passed),
                            color: "#2c7bb6"
                        },
                    ]

                    setFullData(result);
                    setReportData(tmpReportData);

                    setIsLoaded(true);
                    setShowReport(true);

                } else {
                    const errorMessage = `${methodName} failed getEvaluationOfTplanSelection`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);

                    setFullData([]);
                    setReportData([]);

                    setIsLoaded(true);
                    setShowReport(false);
                }
            });
    }

    function renderScheduleFilter() {
        return (
            <>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>EvaluationOfTplans:ScheduleSearchTitle</Translate></Heading>
                    <div className="subTitle__spacer">
                        <Translate>EvaluationOfTplans:ScheduleSearchSubTitle</Translate>
                    </div>
                </div>

                <ComboBox
                    data={filteredSchedule ? filteredSchedule : []}
                    label={tr.GetString('EvaluationOfTplans:SearchSchedule')}
                    dataItemKey="sceId"
                    textField="sceTitleOrCode"
                    filterable={false}
                    onChange={(e) => setSelectedSchedule(e.target.value)}
                    value={selectedSchedule}
                    allowCustom={false}
                    disabled={selectedTplan == undefined || selectedTplan.scheduleDict == undefined}
                />

                <div className="button-position">
                    <GTButton
                        onClick={() => loadReport()}
                        additionalClassNames=""
                        aria-label={tr.GetString("EvaluationOfTplans:LoadReport")}
                        defaultButtonProps={{ disabled: (selectedTplan == undefined) }}>
                        <Translate>EvaluationOfTplans:LoadReport</Translate>
                    </GTButton>
                </div>
            </>
        )

    }

    function onSortDirectionChanged(id: string, direction: ESortDirection) {
        setActiveHeader(id);
        setSort({
            id, direction
        });
    }

    function isActiveHeader(id: string) {
        return id === activeHeader;
    }

    function renderTableHeader() {
        const headerElements: GTHeaderContent[] = [];

        globalConfig.reportingShowHeaderTableProperties.tplanReport.map((field) => {
            const element = {
                cellId: field.id,
                cellElement: <SortableHeaderElement
                    id={field.id}
                    isActive={isActiveHeader(field.id)}
                    header={tr.GetString(`EvaluationOfTplans:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                />
            }

            headerElements.push(element);
        });

        return <GTHorizontalTableHeaderRow
            headerElements={headerElements}
            tableRowClassName="evaluationOfReport__table__row not mobile row__header" />
    }

    function getRows() {

        const rowContent: JSX.Element[] = [];
        selectedTableData!.map((data, index) => {
            rowContent.push(
                <EvaluationOfTplansItem
                    columnSettings={globalConfig.reportingShowHeaderTableProperties.tplanReport}
                    data={data}
                    isActiveHeader={isActiveHeader(evaluationOfTplansItemModel.columnDictionary[index])}
                    onActiveHeaderChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                    key={data.userId.toString() + index.toString()}
                />
            )
        });

        return rowContent;
    }
    
    function getRowsExport() {
        const columns: JSX.Element[] = [];
        globalConfig.reportingExportProperties.tplanReport.map((exportField) => {
            const element = <ExcelExportColumn field={exportField.id} title={tr.GetString(`EvaluationOfTplans:${exportField.id.charAt(0).toUpperCase() + exportField.id.slice(1)}`)} />;
            columns.push(element);
        });

        return columns;
    }

    const excelExport = () => {
        if (exporter.current) {
            exporter.current.save(selectedTableData, getRowsExport());
        }
    };

    function renderDataTable() {
        const lessonStatusTranslated = getLessonStatusTranslation(selectedModuleStatus);
        return (
            <div>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"> {tr.GetString('EvaluationOfTplans:TableData')
                        .Format(selectedModule, lessonStatusTranslated)} </Heading>
                    <div className="subTitle__spacer">
                        {tr.GetString('EvaluationOfTplans:CountOfUsers')
                            .Format(selectedTableData !== undefined ? selectedTableData.length.toString(): "0")}
                    </div>
                </div>

                <GTHorizontalTable
                    headerElement={renderTableHeader()}
                    rows={getRows()}
                    tableClassName="evaluationOfReport__table"
                />

                <div className="button-position">
                    <GTButton
                        onClick={() => excelExport()}
                        additionalClassNames=""
                        aria-label={tr.GetString("EvaluationOfTplans:ExportToList")}
                        defaultButtonProps={{ disabled: false }}>
                        <Translate>EvaluationOfSkills:ExportToList</Translate>
                    </GTButton>
                </div>
            </div>
        )
    }

    function sortUserWithAchievedSkill(id: string, direction: ESortDirection) {
        selectedTableData!.sort((a: EvaluationOfTplanData, b: EvaluationOfTplanData): number => {
            return SortingAlgorithm.sortByColumnAndDirection(a, b, id, direction);
        })
    }

    function getBcElemets() {

        return [
            {
                navigationPath: "/more",
                title: Session.instance.storage.translation.GetString('Navigation:More')
            },
            {
                navigationPath: `/myTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:MyTeam')
            },
            {
                navigationPath: `/reportEvaluationOfTplansMyTeam/${bossRelationCode}`,
                title: Session.instance.storage.translation.GetString('Navigation:EvaluationOfTplans')
            }
        ]
    }
    
    return (
        <>
            {selectedTableData && sortUserWithAchievedSkill(sort.id, sort.direction)}
            <div className="l-container">
            {bossRelationCode != undefined && bossRelationCode != "" && <BreadCrumb breadCrumbElements={getBcElemets()} /> }
            {(bossRelationCode == undefined || bossRelationCode == "") && <MenuBreadCrumb /> }
                <Heading headingLevel={1} cssClass="heading__Title"><Translate>EvaluationOfTplans:Title</Translate></Heading>
                <div>
                    <Translate>EvaluationOfTplans:SubTitle</Translate>
                </div>
                {isLoaded ?
                    <>
                        {renderTplanFilters()}
                        {renderScheduleFilter()}
                        {showReport && renderReport()}
                        {showDataTable && renderDataTable()}
                    </>
                    : <ProgressSpinner />}

                <ExcelExport
                    data={selectedTableData ? selectedTableData : []}
                    ref={exporter}
                    fileName={'TplanExport'} />

            </div>
        </>
    )
}