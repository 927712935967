import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "$src/components/shared/ProgressSpinner";
import { Translate } from "$src/components/shared/Translate";
import { isSuccess } from "$src/util/Result";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { UserSubjectSubscriptions } from "$src/storage/models/UserSubjectSubscriptions/UserSubjectSubscriptions";
import { SubjectsSubscribedTo } from '$src/storage/models/UserSubjectSubscriptions/UserSubjectSubscriptions';
import UserSubjectSubscriptionService from '$src/core/Services/UserSubjectSubscriptionService';
import { useHistory } from "react-router";

/**
 * Component which renders all subjects as buttons on which the user is subscribed to, but only if he has subjects.
 */
export default function UserSubjectSubscriptionsPanel() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [userSubjects, setUserSubjects] = useState<SubjectsSubscribedTo[]>([]);
    const history = useHistory();

    useEffect(() => {
        UserSubjectSubscriptionService.instance.getSubjectSubscriptionsFromUser().then(subscriptions => {
            if (isSuccess(subscriptions)) {
                const response = subscriptions as UserSubjectSubscriptions;
                if (response != null && response.subscribedSubjects != null) {
                    setUserSubjects(response.subscribedSubjects)
                }
            }
            setIsLoaded(true);
        });
    }, [])

    const Settings = () => {
        return <div className="my-subscriptions__settings">
            <GTButton onClick={() => { history.push('/EditMySubjectSubscriptions') }}>
                <Translate>UserSubjectSubscriptions:Settings</Translate>
            </GTButton>
        </div>
    };

    const SubscribedSubjects = () => {
        const elements = userSubjects.map(
            subj => {
                return (
                    <GTButton key={'userSubjectSubscription_' + subj.subjectId}
                        onClick={() => {
                            history.push({
                                pathname: `/userSubjectSubscriptions/MySubscriptions/${subj.subjectId}/${subj.subjectName}`,
                            })
                        }}
                        additionalClassNames={'subject-subscription__button'}
                        activateBadge={subj.numberOfNewItemsInSubject > 0}
                        badgeText={subj.numberOfNewItemsInSubject.toString()}
                    >
                        <Translate>{subj.subjectName}</Translate>
                    </GTButton>
                )
            }
        );
        return (
            <>
                {elements}
            </>
        );
    }


    return (
        <>
            {!isLoaded && <ProgressSpinner />}
            {isLoaded && userSubjects != null && userSubjects.length >= 0}
            <div className="my-subscriptions__page">
                <SubscribedSubjects />
            </div>
            <Settings />
        </>
    );
}