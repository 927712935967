import React from 'react';

import IconexternalClassDuration from '$resources/svgs/toolbar/location-city.svg';

import Session from '$core/Session';
import { Item } from '$storage/models/Item';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    item: Item;
    textVisible: boolean;
}

export class ToolbarExternalClassDuration extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        const icon = IconexternalClassDuration;
        let text = '';
        let hoverText = tr.GetString('ItemDetail:ExternalCourseDuration');
        if (this.props.item.allRegistrations && this.props.item.allRegistrations.length > 0) {
            const registration = this.props.item.allRegistrations.filter(regs => regs.registrationId === this.props.item.registrationId);
            text = registration[0].classDescription === '' ? tr.GetString('ItemDetail:NoExternalCoresDurationFound') :
            registration[0].classDescription;
        }

        hoverText = hoverText + ': ' + text;
        return (
            <div className="l-box__toolbar__icon lesson-tools lesson-tools--type" 
                aria-label={hoverText} 
                data-tip={hoverText}
                data-for={`${this.props.item.itemId}_extCourseDurationTooltip`}>
                <InlineSVG src={icon} />
                {this.props.textVisible ? (text) : ''}
                <Tooltip id={`${this.props.item.itemId}_extCourseDurationTooltip`} />
            </div>
        )
    }
}
