import { isSuccess } from "$src/util/Result";
import { MultiSelect, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { Translate } from "$components/shared/Translate";
import { UserInterests } from "$src/storage/models/UserInterests/UserInterests"
import AttributeService from "$src/core/Services/AttributeService";
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { filterBy } from "@progress/kendo-data-query";
import { MultiSelectCheckboxItemRender } from "$components/shared/Atoms/MultiSelectCheckboxItemRender";

interface IProps {
    userInterests: Array<UserInterests>;
    idAttribute: number;
    onItemsDelete: (userInterest: UserInterests[]) => Promise<void>;
    onItemInsert: (idAttributeValue: number) => Promise<void>;
    title: string;
    attributeFilter?: Array<number>;
    attributeConverter?: (attributeValue: AttributeValue) => AttributeValue;
}

export default function UserInterestsAdministrationGeneric(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [data, setData] = useState<Array<AttributeValue>>([]);
    const [filteredData, setFilteredData] = useState<Array<AttributeValue>>([]);

    useEffect(() => {
        AttributeService.instance.getAttributeValues(props.idAttribute).then(attributeValues => {
            if (!isSuccess(attributeValues)) {
                setIsLoaded(true);
            }
            else {
                let av = attributeValues as Array<AttributeValue>;
                if(props.attributeFilter != null) {
                    av = av.filter(a => props.attributeFilter != null && props.attributeFilter.includes(a.id))
                }
                if(props.attributeConverter != null) {
                    av.forEach(av => props.attributeConverter != null ? props.attributeConverter(av) : av);
                }
                setData([...av]);
                setFilteredData([...av]);
                setIsLoaded(true);
            }
        });
    }, [])

    const onSelectionChanged = (values: Array<AttributeValue>): void => {
        const oldItemTypes = data.filter(s => props.userInterests.findIndex(u => u.idAttributeValue === s.id) !== -1);
        const itemsToDelete = oldItemTypes.filter(oldItemTypes => values.findIndex(newSubjects => newSubjects.id === oldItemTypes.id) === -1);
        const itemsToAdd = values.filter(newSubject => oldItemTypes.findIndex(oldItemType => oldItemType.id === newSubject.id) === -1);
        const deleteUserInterestsItems: UserInterests[] = [];
        itemsToDelete.map((deleteItem) => {
            const deleteUserInterestsItem = props.userInterests.find(s => s.idAttributeValue === deleteItem.id);
            if(deleteUserInterestsItem != null) deleteUserInterestsItems.push(deleteUserInterestsItem);
        });
        if(deleteUserInterestsItems.length > 0) {
            props.onItemsDelete(deleteUserInterestsItems);
        }
        itemsToAdd.map((addItem) => {
            props.onItemInsert(addItem.id);
        });
    }

    const filterData = (e: MultiSelectFilterChangeEvent): void => {
        const i = data;
        setFilteredData(filterBy(i, e.filter));
    }
    
    return <>

        { isLoaded && <>

            <div className="user-interests__setting">
                <div className="user-interests__settings__label">
                    <label id={`lbuserinterests_${props.idAttribute}`}>
                        <Translate>{props.title}</Translate>
                    </label>
                </div>
                <div className="user-interests__settings__value">
                    <MultiSelect
                        className="user-interests__settings__value__multi-select"
                        itemRender={MultiSelectCheckboxItemRender}
                        data={filteredData}
                        onChange={(e) => { onSelectionChanged(e.target.value as AttributeValue[]) }}
                        value={data.filter(s => props.userInterests.findIndex(u => u.idAttributeValue === s.id) !== -1)}
                        textField="text"
                        filterable={true}
                        onFilterChange={(e) => { filterData(e) }}
                    />
                </div>
            </div>
        </>
        }
    </>;
}