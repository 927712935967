import { EWorkflowActivityStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class WorkflowActivityStatusConverter implements JsonCustomConvert<EWorkflowActivityStatus> {
    public serialize(lessonStatus: EWorkflowActivityStatus): number {
        switch (lessonStatus) {
            case EWorkflowActivityStatus.Locked: return 0;
            case EWorkflowActivityStatus.Due: return 1;
            case EWorkflowActivityStatus.Completed: return 2;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EWorkflowActivityStatus {
        switch (jsonValue) {
            case 0: return EWorkflowActivityStatus.Locked;
            case 1: return EWorkflowActivityStatus.Due;
            case 2: return EWorkflowActivityStatus.Completed;
            default: return EWorkflowActivityStatus.Locked;
        }
    }
}
