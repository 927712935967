import React from 'react';

import { BreadCrumb } from '$components/breadCrumb/BreadCrumb';
import Logger from '$core/Logger';
import CatalogService from '$core/Services/CatalogService';
import { BreadCrumbElement } from '$storage/models/BreadCrumbElement';
import { CatalogPathElement } from '$storage/models/CatalogPathElement';
import { isSuccess } from '$util/Result';

interface IProps {
    folderId: number;
    breadCrumbElementsToAppend?: BreadCrumbElement[];
    itemStyle?: React.CSSProperties;
}

interface IState {
    isDataLoaded: boolean;
    catalogPathElements?: CatalogPathElement[];
}

export class CatalogBreadCrumb extends React.Component<IProps, IState> {
    protected className = 'CatalogBreadCrumb';
    protected loggerLocality = 'Components.CatalogBreadCrumb';

    private catalogRootPath = '/catalog';
    private catalogRootElementId = 1;
    private catalogRootTitle = 'Catalog:Title';

    constructor(props: IProps) {
        super(props);
        this.state = {
            catalogPathElements: undefined,
            isDataLoaded: false
        };
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        if(this.state.isDataLoaded === false && this.props.folderId === this.catalogRootElementId) {
            this.setState({isDataLoaded: true});
        }
        else if(this.state.isDataLoaded === false && this.props.folderId !== this.catalogRootElementId) {
            Logger.log(this.loggerLocality, `${methodName} loading data for item: `, this.props.folderId);
            await this.loadData();
        }
    }

    public render() {
        const catalogRoot: BreadCrumbElement = {navigationPath: this.catalogRootPath + '/' + this.catalogRootElementId,
                                                 style: this.props.itemStyle,
                                                 title: this.catalogRootTitle,
                                                 translateTitle: true}
        if(this.state.isDataLoaded) {
            const bcElements: BreadCrumbElement[] = [];
            if(this.state.catalogPathElements != null) {
                this.state.catalogPathElements.map((value) => {
                    bcElements.push({navigationPath: `${this.catalogRootPath}/${value.itemId}`,
                                     style: this.props.itemStyle,
                                     title: value.title});
                });
            }
            if(this.props.breadCrumbElementsToAppend != null) {
                this.props.breadCrumbElementsToAppend.map(bc => bcElements.push(bc));
            }

            return <BreadCrumb
                        breadCrumbElements={bcElements}
                        rootElement={catalogRoot} />
        }
        else{
            return <BreadCrumb
                        breadCrumbElements={ [] } /> // Render empty item to reserver space
        }
    }

    protected async loadData() {
        const methodName = `${this.className}:componentDidMount()`;
        const response = await CatalogService.instance.getCatalogFolderPath(this.props.folderId);
        if (isSuccess<CatalogPathElement[]>(response)) {
            this.setState({
                catalogPathElements: response,
                isDataLoaded: true
            });
        } else {
            Logger.log(this.loggerLocality, `${methodName} failed to get data: ${this.props.folderId}, ${response.message}`);
            console.error(`${this.loggerLocality} ${methodName} failed to get data: ${this.props.folderId}, ${response.message}`);
        }
    }

}
