import React, { useEffect, useRef, useState } from 'react';
import { SearchResult } from '$storage/models/search/elk/SearchResult'
import SearchResultSummary from '$src/components/search/elk/SearchResultSummary';
import SearchPager from '$components/search/elk/SearchPager';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import Session from '$src/core/Session';

interface IProps {
    searchResults: SearchResult[];
    onSearch: (
        searchText: string,
    ) => void;
    searchValue: string;
}

export default function SearchResults(props: IProps) {
    const ulRef = useRef<HTMLDivElement>(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setCurrentPage(1);
    }, [props.searchResults]);


    const resultsPerPage = globalConfig.searchProperties.itemsPerPage;

    function renderSearchResults(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        if (props.searchResults != null) {
            const indexOfLastResult = currentPage * resultsPerPage;
            const indexOfFirstResult = indexOfLastResult - resultsPerPage;

            props.searchResults.slice(indexOfFirstResult, indexOfLastResult).map((res, index) => {
                elements.push(
                    <li key={index + "_" + res.itemId}>
                        <SearchResultSummary {...props} searchResult={res} onSearch={(e) => props.onSearch(e)} searchValue={props.searchValue} />
                    </li>
                );
            })
        }
        return elements;
    }

    function onPageSelected(page: number) {
        setCurrentPage(page);
        window.scrollTo(0, 0);
    }

    if (props.searchResults != null && props.searchResults.length > 0) {
        return (
            <div ref={ulRef} className="search-result__container">
                <div>
                    <ul>
                        {renderSearchResults()}
                    </ul>
                </div>
                <SearchPager resultsPerPage={resultsPerPage} onPageSelected={(e) => onPageSelected(e)} totalResults={props.searchResults.length} />
            </div>
        );
    } else  {
        return <NoDataFound message={Session.instance.storage.translation.GetString("Search:NoData")} />;
    }
}