import { ELessonStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class LessonStatusConverter implements JsonCustomConvert<ELessonStatus> {
    // from WebApi Models\Enums.cs: Undefined=0, NotAttempted=1, Incomplete=2, Completed=3, Failed=4, Passed=5
    public serialize(lessonStatus: ELessonStatus): number {
        switch (lessonStatus) {
            case ELessonStatus.Undefined: return 0;
            case ELessonStatus.NotAttempted: return 1;
            case ELessonStatus.Incomplete: return 2;
            case ELessonStatus.Completed: return 3;
            case ELessonStatus.Failed: return 4;
            case ELessonStatus.Passed: return 5;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): ELessonStatus {
        switch (jsonValue) {
            case 0: return ELessonStatus.Undefined;
            case 1: return ELessonStatus.NotAttempted;
            case 2: return ELessonStatus.Incomplete;
            case 3: return ELessonStatus.Completed;
            case 4: return ELessonStatus.Failed;
            case 5: return ELessonStatus.Passed;
            default: return ELessonStatus.Undefined;
        }
    }
}
