import { ERegistrationRequestType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class RegistrationRequestTypeConverter implements JsonCustomConvert<ERegistrationRequestType> {
    
    public serialize(value: ERegistrationRequestType): number {
        switch(value) {
            case ERegistrationRequestType.Undefined: return 0;
            case ERegistrationRequestType.SingleStepSelfRegistration: return 1;
            case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration: return 2;
            case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration: return 3;
            case ERegistrationRequestType.RegistrationByBoss : return 4;
            default: return 0;
        }
    }

    public deserialize(value: number): ERegistrationRequestType {
        switch (value) {
            case 0: return ERegistrationRequestType.Undefined;
            case 1: return ERegistrationRequestType.SingleStepSelfRegistration;
            case 2: return ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration;
            case 3: return ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration;
            case 4: return ERegistrationRequestType.RegistrationByBoss;
            default: return ERegistrationRequestType.Undefined;
        }
    }
}
