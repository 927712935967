import React from 'react';
import InputFile from '$src/components/shared/InputFile';
import IconDelete from '$resources/svgs/misc/delete.svg';
import IconOpenInNewTab from '$resources/svgs/misc/open-in-new-tab.svg';
import FileHelper from '$src/util/FileHelper';
import { DropDownListChangeEvent, DropDownList } from '@progress/kendo-react-dropdowns';
import { Translate } from '$src/components/shared/Translate';
import { EDocumentType, EFileType } from '$src/storage/models/enums';
import Session from '$src/core/Session';
import InlineSVG from 'react-inlinesvg';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { InputText } from '$src/components/shared/InputText';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { Tooltip } from '$src/components/shared/Tooltip';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { isSuccess } from '$src/util/Result';
import { ClassFileDataRequest } from '$src/storage/models/RequestObjects/ClassFileDataRequest';
import F2FService from '$src/core/Services/F2FService';
import GtError from '$src/util/GtError';
import CustomErrorMessage from '$src/util/CustomErrorMessage';

interface IProps {
    classId: number;
    onDocumentsUploaded?: () => void;
}

interface IState {
    uploadDocuments: File[];
    fileDataRequest: ClassFileDataRequest[];
    errorMessage?: string;
    isUploading: boolean;
}

export class F2FDocumentsUpload extends React.Component<IProps, IState> {
    protected documentTypes = [
        { id: EDocumentType.WithoutRestriction, text: Session.instance.storage.translation.GetString('Course:DocumentTypeWithoutRestriction') },
        { id: EDocumentType.RegistrationRequestedOrAccepted, text: Session.instance.storage.translation.GetString('Course:DocumentTypeRegistrationRequestedOrAccepted') },
        { id: EDocumentType.RegistrationAccepted, text: Session.instance.storage.translation.GetString('Course:DocumentTypeRegistrationAccepted') },
        { id: EDocumentType.ClassTakesPlace, text: Session.instance.storage.translation.GetString('Course:DocumentTypeClassTakesPlace') },
        { id: EDocumentType.ClassAfterRunningPhase, text: Session.instance.storage.translation.GetString('Course:DocumentTypeClassAfterRunningPhase') },
        { id: EDocumentType.GeneralTermsAndConditions, text: Session.instance.storage.translation.GetString('Course:DocumentTypeGeneralTermsAndConditions') },
    ];

    constructor(props: IProps) {
        super(props);
        this.state = {
            uploadDocuments: [],
            fileDataRequest: [],
            isUploading: false
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderUploadedDocuments()}
                <label id="lblClassDocuemntsFileUpload"><Translate>Course:DocumentsUpload</Translate></label>
                <InputFile
                    acceptFilter={globalConfig.f2fProperties.f2fFileUpload.acceptFilter}
                    isMultiple={globalConfig.f2fProperties.f2fFileUpload.multipleAllowed}
                    labeledBy="lblClassDocuemntsFileUpload"
                    onUploadChange={(files) => this.onUploadChange(files)}
                    maxFileSizeInByte={globalConfig.f2fProperties.f2fFileUpload.maxFileSize}
                    linkCssClass="button-link button-link--colorized-dark"
                />
                {this.state.errorMessage && <Alert message={this.state.errorMessage} alertAppereance="single-line" alertType="error" />}
            </React.Fragment>
        )
    }

    private renderUploadedDocuments(): JSX.Element {
        return (
            <div>
                <div role="table" className="f2f-document-upload__table">
                    {this.state.uploadDocuments.length > 0 &&
                        <div role="rowgroup">
                            <div role="row" className="f2f-document-upload__table-row">
                                <div role="columnheader" className="notMobile">
                                    <Translate>Course:Filename</Translate>
                                </div>
                                <div role="columnheader" className="notMobile">
                                    <Translate>Course:FileCustomName</Translate>
                                </div>
                                <div role="columnheader" className="notMobile">
                                    <Translate>Course:DocumentType</Translate>
                                </div>
                            </div>
                        </div>}
                    <div role="rowgroup">
                        {this.state.uploadDocuments.map(doc => {
                            const attributes: InputAttributes = {
                                id: doc.name,
                                label: '',
                                value: '',
                                isReadOnly: false,
                                isRequired: false,
                                hasFocus: false,
                                placeholder: Session.instance.storage.translation.GetString('Course:EnterFileName'),
                                editMode: true,
                                class: ''
                            }
                            const fileData = this.state.fileDataRequest.filter(f => f.fileName === doc.name)[0];
                            const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('Course:OpenDocument')
                                .Format(doc.name);
                            return (
                                <div key={doc.name} role="row" className="f2f-document-upload__table-row">
                                    <div role="columnheader" className="mobileOnly">
                                        <Translate>Course:Filename</Translate>
                                    </div>
                                    <div role="cell">
                                        <InlineSVG src={IconOpenInNewTab} />
                                        <button
                                            className="button-link button-link--colorized-dark"
                                            aria-label={arriaLabelOpenDoc}
                                            onClick={() => FileHelper.openFile(doc)}>
                                            {doc.name}
                                        </button>
                                    </div>
                                    <div role="columnheader" className="mobileOnly">
                                        <Translate>Course:FileCustomName</Translate>
                                    </div>
                                    <div role="cell">
                                        <InputText attributes={attributes} onChange={(id, value, errorMessage) => this.onUploadDocumentNameChanged(id, value, errorMessage)} />
                                    </div>
                                    <div role="columnheader" className="mobileOnly ">
                                        <Translate>Course:DocumentType</Translate>
                                    </div>
                                    <div role="cell">
                                        <DropDownList
                                            data={this.documentTypes}
                                            textField="text"
                                            dataItemKey="id"
                                            defaultValue={this.documentTypes[0]}
                                            value={fileData ? this.documentTypes.filter(ft => ft.id === fileData.documentType)[0] : undefined}
                                            onChange={(e) => this.onDocumentTypeChanged(e, doc.name)} />
                                        <button className="button-link inlineFlex"
                                            onClick={() => this.removeDocumentForUpload(doc.name)}
                                            aria-label={Session.instance.storage.translation.GetString('Course:IconDeleteFileName').Format(doc.name)}
                                            data-for={doc.name}
                                            data-tip={Session.instance.storage.translation.GetString('Course:IconDeleteFileName').Format(doc.name)}>
                                            <InlineSVG src={IconDelete} />
                                        </button>
                                        <Tooltip id={doc.name} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {this.state.uploadDocuments.length > 0 &&
                    <button type="button"
                        onClick={() => this.onUploadDocuments()}
                        disabled={this.state.isUploading}
                        className="btn--md btn--primary">
                        <Translate>Course:UploadDocuments</Translate>
                    </button>}
            </div>
        )
    }

    private removeDocumentForUpload(fileName: string) {
        const uploadDocuments = this.state.uploadDocuments;
        const fileDataRequest = this.state.fileDataRequest
        this.setState({
            fileDataRequest: fileDataRequest.filter(file => file.fileName !== fileName),
            uploadDocuments: uploadDocuments.filter(doc => doc.name !== fileName)
        });
    }

    private onDocumentTypeChanged(event: DropDownListChangeEvent, fileName: string) {
        const index = this.state.fileDataRequest.findIndex(doc => doc.fileName === fileName);
        if (index != -1) {
            const fileDataRequest = this.state.fileDataRequest;
            fileDataRequest[index].documentType = event.value.id;
            this.setState({ fileDataRequest });
        }
    }

    private onUploadDocumentNameChanged(fileName: string, value: string, errorMessage: string) {
        const index = this.state.fileDataRequest.findIndex(doc => doc.fileName === fileName);
        if (index != -1 && errorMessage === '') {
            const fileDataRequest = this.state.fileDataRequest;
            fileDataRequest[index].name = value;
            this.setState({ fileDataRequest });
        } else {
            errorMessage = errorMessage !== '' ? errorMessage : Session.instance.storage.translation.GetString('Course:DocumentNotFound');
            this.setState({ errorMessage });
        }
    }

    /**
     * Callback for InputFile component: input has changed
     * Returns a list of files
     * @param files Selected files in input file
     */
    private onUploadChange(files: File[]) {
        const uploadDocuments = this.state.uploadDocuments;
        const fileDataRequest = this.state.fileDataRequest;
        files.map(f => {
            if (!this.state.uploadDocuments.find(doc => doc.name === f.name)) {
                fileDataRequest.push({
                    base64String: '',
                    file: f,
                    contentType: f.type,
                    fileName: f.name,
                    fileType: EFileType.Undefined,
                    name: '',
                    documentType: EDocumentType.WithoutRestriction,
                    url: '',
                    linkedDocument: ''
                });
                uploadDocuments.push(f)
            }
        });
        this.setState({ uploadDocuments: Array.from(new Set(uploadDocuments)) });
    }

    private async onUploadDocuments() {
        this.setState({isUploading: true});
        const response = await F2FService.instance.uploadF2FDocuments(this.props.classId, this.state.fileDataRequest);

        if (isSuccess<BooleanResponse>(response) && this.props.onDocumentsUploaded && response.status) {
            this.props.onDocumentsUploaded();
            this.setState({ uploadDocuments: [], fileDataRequest: [], errorMessage: undefined, isUploading: false });
        } else {
            if (response instanceof GtError && response.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode), isUploading: false})
            } else {
                this.setState({ errorMessage: 'ErrorMessage:FailedToUploadDocuments', isUploading: false });
            }
        }
    }
}