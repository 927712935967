import React from "react";

import { EColor } from "$src/storage/models/enums";


/**
 * The GT Item Box simple Props
 */
export interface IItemBoxSimpleProps {
    /**
     * The content of the box
     */
    children: JSX.Element | string;

    /**
     * Component unique id for the key of the dom element
     */
    id: number;

    /**
     * **Optional** Color of the border. If the color is not set, the border is hidden
     */
    borderColor?: EColor;

    /**
     * **Optional** To postion elements absolute in reference to the box, the position of the box has to be relative. 
     */
    isPositionRelative?: boolean;

    /**
     * **Optional** Flag if the item should be displayed as an <li>. Requirement: if this flag is set to true, then the calling component must provide the list <ol> or <ul>
     */
    isListItem?: boolean;

    /**
     * **Optional** Add additional css classes here
     */
    additionalCss?: string;

}



/**
 * Global Teach item box simple component
 * Box for simple design of the item summary with minimal information, used for favorites and watched items
 * Design: One line and wide box
 * List: The items will be displayed one below the other
 */
export default function ItemBoxSimple(props: IItemBoxSimpleProps) {

    let cssClass = 'l-box--wide l-box--shadow';

    if (props.isPositionRelative) {
        cssClass += ' position--relative';
    }

    if (props.additionalCss) {
        cssClass += ' ' + props.additionalCss;
    }

    switch (props.borderColor) {
        case EColor.BaseColor:
            cssClass += ' border'
            break;
        case EColor.Highlight:
            cssClass += ' border border--highlight'
            break;
        case EColor.HighlightDark:
            cssClass += ' border border--highlight-dark'
            break;
        case EColor.Error:
            cssClass += ' border border--error'
            break;
    }

    if (props.isListItem) {
        cssClass += ' no-bullets';
        return (
            <li
                key={'ItemBoxSimple_' + props.id}
                className={cssClass}
            >
                {props.children}
            </li>
        );
    }
    return (
        <div
            key={'ItemBoxSimple_' + props.id}
            className={cssClass}
        >
            {props.children}
        </div>
    );

}

