import { Item } from "$src/storage/models/Item";
import { Translate } from "$components/shared/Translate";
import React from "react";

interface IProps {
    item: Item;
    hasRegisterRight: boolean;
    canSelfRegister: boolean;
    isInRegistrationPeriod: boolean;
}

/**
 * This component render the locke reason in a ul-li list
 */
export class ItemLockedReasons extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        if (!this.props.item.isRegistered &&
            this.props.item.isLocked
            || (!this.props.hasRegisterRight)
            || (!this.props.isInRegistrationPeriod)
            || (!this.props.canSelfRegister)) {
            return (
                <React.Fragment>
                    <div role="columnheader">
                        <Translate>LessonStatus:LockedReasons</Translate>
                    </div>
                    <div role="cell">
                        <ul className="f2f__registration-details-locked-reason__list">
                            {this.renderLockedReasonList()}
                        </ul>
                    </div>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    private renderLockedReasonList(): JSX.Element[] {
        const elements: JSX.Element[] = [];

        if (!this.props.hasRegisterRight || !this.props.canSelfRegister) {
            elements.push(
                <li key="locked_permissionMissing">
                    <Translate>LessonStatus:LockedReasonPermissionMissing</Translate>
                </li>
            )
        }
        if (this.props.item.isLockedDueToRequiredSkills) {
            elements.push(
                <li key="locked_skillMising">
                    <Translate>LessonStatus:LockedReasonRequiredSkillsMissing</Translate>
                </li>
            )
        }
        if (this.props.item.isLockedDueToTrainingPlan) {
            elements.push(
                <li key="locked_skillMising">
                    <Translate>LessonStatus:LockedReasonTrainingPlanLocked</Translate>
                </li>
            )
        }
        if (this.props.item.isLockedDueToAssignments) {
            elements.push(
                <li key="locked_assignmentNotInLearningPeriod">
                    <Translate>LessonStatus:LockedReasonAssignmentNotInLearningPeriod</Translate>
                </li>
            )
        }
        if (!this.props.isInRegistrationPeriod) {
            elements.push(
                <li key="locked_notInRegistrationPeriod">
                    <Translate>LessonStatus:LockedReasonNotInRegistrastionPeriod</Translate>
                </li>
            )
        }
        return elements;
    }
}