import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Lazy<boolean>
 * @export
 * @class FileSharingUser
 */
@JsonObject
export class LazyBoolean {

    @JsonProperty('isValueCreated', Boolean, false)
    public isValueCreated: boolean = false;

    @JsonProperty('value', Boolean, false)
    public value: boolean = false;

}