import React from 'react';

import { BreadCrumb } from '$components/breadCrumb/BreadCrumb';
import { InputPassword } from '$components/shared/InputPassword';
import { InputText } from '$components/shared/InputText';
import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import Logger from '$src/core/Logger';
import ServiceClient from '$src/core/ServiceClient';
import PasswordService from '$src/core/Services/PasswordService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { BreadCrumbElement } from '$src/storage/models/BreadCrumbElement';
import { RenewTokensRequest } from '$src/storage/models/RequestObjects/RenewTokensRequest';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { FieldValidators } from '$src/util/Fieldvalidators';
import GtError from '$src/util/GtError';
import { Auth, User } from '$storage/models/User';
import { isSuccess } from '$util/Result';
import { StickyContainer } from 'react-sticky';
import { Redirect } from 'react-router';

interface IState {
    OldPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
    Username: string;
    Domain: string;
    PIN: string;
    PwChanged: boolean;
    FormValid: boolean;
    ErrorMessage: string;
    FocusTo: 'pin' | 'OldPW' | 'ConfirmPW' | 'NewPW' | 'username' | 'Domain' | '';
}

interface IProps {
    hint: string;
}

export class PasswordChange extends React.Component<IProps, IState> {
    protected className = 'PasswordChange';
    protected loggerLocality = 'Components.PasswordChange';
    constructor(props: IProps) {
        super(props);
        // tslint:disable:object-literal-sort-keys
        this.state = {
            OldPassword: '',
            NewPassword: '',
            ConfirmPassword: '',
            Domain: '',
            Username: '',
            PIN: '',
            PwChanged: false,
            FormValid: false,
            ErrorMessage: '',
            FocusTo: ''
        };
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('PasswordChange:Title');

        const parameters = new URLSearchParams(window.location.search);
        const changeMode = parameters.get('mode');
        if (changeMode === 'pin') {
            const username = parameters.get('username');
            const domain = parameters.get('domain');

            if (username != null && domain != null) {
                this.setState({ Username: username, Domain: domain });
            }
        }
    }

    public render() {
        const parameters = new URLSearchParams(window.location.search);
        let changeMode = parameters.get('mode');
        let focus: string = '';

        if (Session.instance.getIsSSOSession) {
            changeMode = 'sso';
        }

        if (this.state.FocusTo === '') {
            switch (changeMode) {
                case 'pin':
                    focus = 'pin'
                    break;
                case 'deep':
                    focus = 'NewPW'
                    break;
                case 'default':
                    focus = 'OldPW'
                    break;
                case 'sso':
                    focus = 'NewPW'
                    break;
                default:
                    focus = 'OldPW'
                    break;
            }
        }

        const oldPwdAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'txtOldPassword',
            label: 'PasswordChange:OldPassword',
            value: this.state.OldPassword,
            class: '',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: focus === 'OldPW',
            placeholder: '',
            editMode: true,
            autoComplete: 'current-password'
        };

        const pinAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'txtPIN',
            label: 'PasswordChange:PIN',
            value: this.state.OldPassword,
            class: '',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: focus === 'pin',
            placeholder: '',
            editMode: true,
        };

        const newPwdAttributes: InputAttributes =
        {
            id: 'txtNewPassword',
            label: 'PasswordChange:NewPassword',
            value: this.state.NewPassword,
            class: '',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: focus === 'NewPW',
            placeholder: '',
            editMode: true,
            autoComplete: 'new-password'
        };

        const confirmPwdAttributes: InputAttributes =
        {
            id: 'txtNewPasswordConfirm',
            label: 'PasswordChange:ConfirmPassword',
            value: this.state.ConfirmPassword,
            class: '',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: focus === 'ConfirmPW',
            placeholder: '',
            editMode: true,
            autoComplete: 'new-password'
        };

        const usernameAttributes: InputAttributes =
        {
            id: 'txtUsername',
            label: 'PasswordChange:Username',
            value: this.state.ConfirmPassword,
            class: '',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: focus === 'username',
            placeholder: '',
            editMode: true,
            autoComplete: 'username'
        };

        const domainAttributes: InputAttributes =
        {
            id: 'txtDomain',
            label: 'PasswordChange:Domain',
            value: this.state.ConfirmPassword,
            class: '',
            isReadOnly: false,
            isRequired: false,
            regExpression: undefined,
            hasFocus: focus === 'Domain',
            placeholder: '',
            editMode: true,
        };

        let inputForm;
        // If the password was successfully changed
        //other user is logged in (selfregistration paste link in same tab with on other user)       
        if (this.state.PwChanged) {
            if (changeMode === null || changeMode === 'default') {
                Session.instance.logout();
            } else {
                window.location.href = window.location.origin + globalConfig.appProperties.appPathOverride;
            }
        } else {// If the password still has to be changed
            // Switch to the mode on which this site is called, the mode is set in the QueryString
            switch (changeMode) {
                case 'pin':
                    {
                        const username = parameters.get('username');
                        const domain = parameters.get('domain');

                        if (username != null) {
                            usernameAttributes.value = username;
                        }
                        if (domain != null) {
                            domainAttributes.value = domain;
                        }

                        inputForm = (
                            <div id="divPinPWChange">
                                <InputText
                                    attributes={pinAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedPIN(id, value, errorMsg)}
                                />
                                <InputText
                                    attributes={domainAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedDomain(id, value, errorMsg)}
                                />
                                <InputText
                                    attributes={usernameAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedUsername(id, value, errorMsg)}
                                />
                                <InputPassword
                                    attributes={newPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedNewPassword(id, value, errorMsg)}
                                />
                                <InputPassword
                                    attributes={confirmPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedConfirmPassword(id, value, errorMsg)}
                                />
                            </div>
                        );
                        break;
                    }
                case 'deep':
                    this.ProcessDeepLinkProperties(parameters);
                    inputForm = (
                        <div id="divDefaultPWChange">
                            <InputPassword
                                attributes={newPwdAttributes}
                                onChange={(id, value, errorMsg) => this.onTextChangedNewPassword(id, value, errorMsg)}
                            />
                            <InputPassword
                                attributes={confirmPwdAttributes}
                                onChange={(id, value, errorMsg) => this.onTextChangedConfirmPassword(id, value, errorMsg)}
                            />
                        </div>
                    );
                    break;
                case 'sso':
                    if (Session.instance.getIsSSOSession) {
                        inputForm = (
                            <div id="divDefaultPWChange">
                                <InputPassword
                                    attributes={newPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedNewPassword(id, value, errorMsg)}
                                />
                                <InputPassword
                                    attributes={confirmPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedConfirmPassword(id, value, errorMsg)}
                                />
                            </div>
                        );
                    }
                    break;
                default:
                    if (Session.instance.isUserLoggedIn) {
                        inputForm = (
                            <div id="divDefaultPWChange">
                                <div className="password-change__info">
                                    <Translate>PasswordChange:LogoutHint</Translate>
                                </div>
                                <InputPassword
                                    attributes={oldPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedOldPassword(id, value, errorMsg)}
                                />
                                <InputPassword
                                    attributes={newPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedNewPassword(id, value, errorMsg)}
                                />
                                <InputPassword
                                    attributes={confirmPwdAttributes}
                                    onChange={(id, value, errorMsg) => this.onTextChangedConfirmPassword(id, value, errorMsg)}
                                />
                            </div>
                        );
                    } else {
                        inputForm = (
                            <div>
                                <Redirect push={true} to="/" />
                            </div>
                        );
                    }
            }
        }

        let hintControls;
        if (this.props.hint != null && this.props.hint !== '') {
            hintControls = (
                <h2 className="heading__Level2"><Translate>{this.props.hint}</Translate></h2>
            );
        }
        const breadCrumbElements: BreadCrumbElement[] = [];
        const bceRoot = new BreadCrumbElement();
        const bceCurrent = new BreadCrumbElement();
        bceRoot.title = Session.instance.storage.translation.GetString('Navigation:UserProfile');
        bceRoot.navigationPath = '/userProfile';

        bceCurrent.title = Session.instance.storage.translation.GetString('PasswordChange:Title');
        bceCurrent.navigationPath = '/pwchange?mode=default';

        breadCrumbElements.push(bceCurrent);

        return (
            <StickyContainer className="app__sticky-container">
                <div>
                    <div className="l-container">
                        {changeMode === 'default' || changeMode === '' ? (
                            <BreadCrumb rootElement={bceRoot} breadCrumbElements={breadCrumbElements} {...this.props} />) :
                            ('')}
                        <h1 className="heading__Title"><Translate>PasswordChange:Title</Translate></h1>
                        {hintControls}
                        <div className="l-box__input password-change__form">
                            {inputForm}
                            <span className={'input-message error'}>
                                <Translate>{this.state.ErrorMessage}</Translate>
                            </span>

                            <button type="button" className="btn--md" onClick={() => this.onButtonSaveClick()}
                                disabled={(!this.state.FormValid) || this.checkRequiredValidity() === false} id="btnSavePasswordChange">
                                <Translate>PasswordChange:Save</Translate>
                            </button><br />
                        </div>
                    </div>
                </div>
            </StickyContainer>
        );
    }

    private onTextChangedDomain(id: string, value: string, errorMsg: string) {
        this.setState({
            Domain: value,
            FocusTo: 'Domain'
        }, () => { // checkRequiredValidity reads from state.Domain, therefore we need to wait for the state update
            const isValid: boolean = (errorMsg.length === 0 ? true : false) && this.checkRequiredValidity();
            if (this.state.FormValid !== isValid) {
                this.setState({ FormValid: isValid });
            }
        });
    }

    private onTextChangedPIN(id: string, value: string, errorMsg: string) {
        this.setState({
            PIN: value,
            FocusTo: 'pin'
        }, () => { // checkRequiredValidity reads from state.PIN, therefore we need to wait for the state update
            const isValid: boolean = (errorMsg.length === 0 ? true : false) && this.checkRequiredValidity();
            if (this.state.FormValid !== isValid) {
                this.setState({ FormValid: isValid });
            }
        });
    }

    private onTextChangedUsername(id: string, value: string, errorMsg: string) {
        this.setState({
            Username: value,
            FocusTo: 'username'
        }, () => { // checkRequiredValidity reads from state.Username, therefore we need to wait for the state update
            const isValid: boolean = (errorMsg.length === 0 ? true : false) && this.checkRequiredValidity();
            if (this.state.FormValid !== isValid) {
                this.setState({ FormValid: isValid });
            }
        });
    }

    private onTextChangedOldPassword(id: string, value: string, errorMsg: string) {
        this.setState({
            OldPassword: value,
            FocusTo: 'OldPW'
        }, () => {  // checkRequiredValidity reads from state.OldPassword, therefore we need to wait for the state update
            const isValid: boolean = (errorMsg.length === 0 ? true : false) && this.checkRequiredValidity();
            if (this.state.FormValid !== isValid) {
                this.setState({ FormValid: isValid });
            }
        });
    }

    private onTextChangedNewPassword(id: string, value: string, errorMsg: string) {
        const customError: string = FieldValidators.ConfirmPasswordMatching(value.toString(), this.state.ConfirmPassword.toString());

        this.setState({
            NewPassword: value,
            ErrorMessage: customError,
            FocusTo: 'NewPW'
        }, () => { // checkRequiredValidity reads from state.NewPassword, therefore we need to wait for the state update

            const isValid: boolean = ((customError.length === 0 ? errorMsg : customError).length === 0 ? true : false) && this.checkRequiredValidity();
            if (this.state.FormValid !== isValid) {
                this.setState({ FormValid: isValid });
            }
        });
    }

    private onTextChangedConfirmPassword(id: string, value: string, errorMsg: string) {
        const customError: string = FieldValidators.ConfirmPasswordMatching(this.state.NewPassword.toString(), value.toString());

        this.setState({
            ConfirmPassword: value,
            ErrorMessage: customError,
            FocusTo: 'ConfirmPW'
        }, () => { // checkRequiredValidity reads from state.ConfirmedPassword, therefore we need to wait for the state update
            const isValid: boolean = ((customError.length === 0 ? errorMsg : customError).length === 0 ? true : false) && this.checkRequiredValidity();
            if (this.state.FormValid !== isValid) {
                this.setState({ FormValid: isValid });
            }
        });

    }

    /**
     * Read the properties passed from DeepLink
     * @param params 
     */
    private ProcessDeepLinkProperties(params: URLSearchParams) {
        if (Session.instance.isUserLoggedIn) { //other user is logged in (selfregistration paste link in same tab with on other user)
            Session.instance.logout();
        }

        const usrName: string = params.get('u') || '';
        const domain: string = params.get('d') || '';

        if (this.state.Username !== usrName) {
            this.setState({ Username: usrName });
        }
        if (this.state.Domain !== domain) {
            this.setState({ Domain: domain });
        }
    }

    /**
     * Validate URL Token and save the new Password
     *
     */
    private async onButtonSaveClick() {
        const methodName = `${this.className}:onButtonSaveClick()`;
        const parameters = new URLSearchParams(window.location.search);
        let mode = parameters.get('mode');
        let usrName: string = '';
        let domain = this.state.Domain;
        // tslint:disable-next-line:ban-types
        let changedSuccessfully: BooleanResponse | GtError = new BooleanResponse();

        if (Session.instance.getIsSSOSession) {
            mode = 'sso';
        }

        // Execute an specific Save() action depending on current PWChange mode(QueryString)
        switch (mode) {
            case 'pin':
                changedSuccessfully = await PasswordService.instance.savePasswordWithRecoveryPIN(Session.instance.getUserLanguageCodeOrFallBack,
                    this.state.Username,
                    this.state.PIN,
                    this.state.ConfirmPassword,
                    this.state.Domain,
                    Session.instance.passwordRecoverTimestamp)
                break;
            case 'deep':
                {
                    const timestamp = parameters.get('t') || '';
                    const username = parameters.get('u') || '';
                    const domain = parameters.get('d') || '';
                    const hash = parameters.get('h') || '';
                    const pw = this.state.ConfirmPassword;

                    // Call webService SetNewPassword() to check hash and change pw
                    const deepResponse = await PasswordService.instance.savePasswordWithDeepLink(Session.instance.getUserLanguageCodeOrFallBack, username, domain, pw, timestamp, hash);
                    if (isSuccess<User>(deepResponse)) {
                        changedSuccessfully.status = true;
                        if (!Session.instance.isUserLoggedIn) { //other user was logged in (selfregistration paste link in same tab with on other user)
                            const res = await Session.instance.login(domain, username, pw, false);
                            if (isSuccess<boolean>(res)) {
                                Logger.log(this.loggerLocality, `Login after PW Deep Link reset: ${res}`);
                            }
                            else {
                                Logger.log(this.loggerLocality, `Login after PW Deep Link reset: ${(res).message}`);
                            }
                        }

                    } else if (deepResponse instanceof GtError) {
                        changedSuccessfully = deepResponse;
                    }
                    break;
                }
            case 'default':
                if (Session.instance.loginUser != null) {
                    usrName = Session.instance.loginUser.username || '';
                }

                if (Session.instance.loginUser != null) {
                    domain = Session.instance.loginUser.domainName;
                }

                // tslint:disable-next-line:max-line-length
                changedSuccessfully = await PasswordService.instance.savePassword(Session.instance.getUserLanguageCodeOrFallBack, usrName, this.state.OldPassword, this.state.NewPassword, domain);
                break;
            case 'sso':
                if (Session.instance.loginUser != null) {
                    const ssoResponse = await PasswordService.instance.savePasswordSSO(Session.instance.getUserLanguageCodeOrFallBack,
                        Session.instance.loginUser.username,
                        '',
                        this.state.NewPassword,
                        Session.instance.loginUser.domainName);
                    changedSuccessfully = ssoResponse;
                }
                break;
            default:
                if (Session.instance.loginUser != null) {
                    usrName = Session.instance.loginUser.username || '';
                }

                if (Session.instance.loginUser != null) {
                    domain = Session.instance.loginUser.domainName;
                }

                // tslint:disable-next-line:max-line-length
                changedSuccessfully = await PasswordService.instance.savePassword(Session.instance.getUserLanguageCodeOrFallBack, usrName, this.state.OldPassword, this.state.NewPassword, domain);
                break;
        }

        if (isSuccess<BooleanResponse>(changedSuccessfully)) {  // After successfull changing --> Redirect to login            

            Logger.log(this.loggerLocality, `${methodName} Password changed, sucessfully=${changedSuccessfully.status}.`);

            const obj = new RenewTokensRequest();
            obj.jwtToken = Session.instance.jwtToken;
            obj.refreshToken = Session.instance.refreshToken;
            const isStoredLocal = Session.instance.isJWTStoredLocal;

            Session.instance.isRenewingTokens = true;
            const auth = await ServiceClient.instance.getNewTokens<Auth>('auth/renewTokens', obj, Auth, undefined);
            if (isSuccess<Auth>(auth) && auth !== null) {
                Session.instance.setJwtToken(auth.jwtSecurityToken, auth.refreshToken, isStoredLocal);
            }
            Session.instance.isRenewingTokens = false;

            this.setState({ PwChanged: changedSuccessfully.status });

        } else if (changedSuccessfully instanceof GtError) {        // If there occured an error whils saving, display error message          
            // let errMsg: string = 'ErrorMessage:PasswordChangeFailed';
            let errMsg: string = changedSuccessfully.message;
            if (changedSuccessfully.detailedObject !== undefined) {
                errMsg = CustomErrorMessage.getErrorCodeMessageString(changedSuccessfully.detailedObject.subStatusCode);
            }
            this.setState({ ErrorMessage: errMsg });
        }
    }

    private checkRequiredValidity(): boolean {
        const parameters = new URLSearchParams(window.location.search);
        let mode = parameters.get('mode');
        if (Session.instance.getIsSSOSession) {
            mode = 'sso';
        }
        let isValid: boolean = true;
        switch (mode) {
            case 'pin':
                if (this.state.Username.toString() === '' || this.state.NewPassword.toString() === '' || this.state.ConfirmPassword === '' || this.state.PIN === '') {
                    isValid = false;
                }
                break;

            case 'default':
                if (this.state.OldPassword.toString() === '' || this.state.NewPassword.toString() === '' || this.state.ConfirmPassword.toString() === '') {
                    isValid = false;
                }
                break;
            case 'deep':
                if (this.state.NewPassword.toString() === '' || this.state.ConfirmPassword.toString() === '') {
                    isValid = false;
                }
                break;
            case 'sso':
                if (this.state.NewPassword.toString() === '' || this.state.ConfirmPassword.toString() === '') {
                    isValid = false;
                }
                break;
            default:
                if (this.state.OldPassword.toString() === '' || this.state.NewPassword.toString() === '' || this.state.ConfirmPassword.toString() === '') {
                    isValid = false;
                }
                break;
        }
        return isValid;
    }
}
export default PasswordChange;