import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class OldSuiUrlResponse {

    /** LSM SUI URL  */
    @JsonProperty('url', String, false)
    public url: string = '';

    /** LSM SUI URL  */
    @JsonProperty('accessToken', String, false)
    public accessToken: string = '';
   
}
