import React from 'react';

import InlineSVG from 'react-inlinesvg';

interface IProps {
    ariaLabel: string;
    buttonText: string;
    cssClass?: string;
    icon?: string;
    Iconcolor?: string;
    id: string;
    onButtonClicked: () => void;
}

export class ContainerButton extends React.Component<IProps, {}>  {

    constructor(props: IProps) {
        super(props);
        this.state = {
            redirectPage: ''
        }

        this.onButtonClicked = this.onButtonClicked.bind(this);
    }

    public async componentDidMount() {
        const iconColor = this.props.Iconcolor ? this.props.Iconcolor : '#fff';
        await this.delay(0); // TODO: Make nice ?? No clue why it works
        const iconElements = document.querySelectorAll('#' + this.props.id + '  .svg-color');
        if (iconElements != null && iconElements.length > 0) {
            for(const iconElement of Array.from(iconElements)) {
                iconElement.setAttribute('style', 'fill:' + iconColor);
            }
        }
    }
    
    public render() {
        return (
            <div className={`container-button__container ${this.props.cssClass && this.props.cssClass}`}>
                <button type="button"
                    aria-label={this.props.ariaLabel}
                    className="btn--md btn--primary container-button__button"
                    onClick={this.onButtonClicked}
                    id={`btn_${this.props.id}`} >
                    {this.props.icon && <div id={this.props.id}><InlineSVG src={this.props.icon} /></div>}
                    <div className="container-button__text">{this.props.buttonText}</div>
                </button>
            </div>);
    }

    private onButtonClicked() {
        if (this.props.onButtonClicked) {
            this.props.onButtonClicked();
        }
    }

    private async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


}
export default ContainerButton;