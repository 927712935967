import React from "react";
import { Heading } from "$components/shared/Heading";
import { TrainingPlanElement } from "$src/storage/models/TrainingPlan/TrainingPlanElement";
import { TrainingPlan } from "$src/storage/models/TrainingPlan/TrainingPlan";
import ToolbarTrainingPlanElementDurationV2 from "../Toolbar/ToolbarTrainingPlanElementDurationV2";
import { ToolbarStatus } from "$components/item/Toolbar/ToolbarStatus";
import TranslationStorage from "$src/storage/TranslationStorage";
import ToolbarTrainingPlanModulChildCount from "$components/item/Toolbar/ToolbarTrainingPlanModulChildCount";

export interface IItemSummaryTrainingPlanModuleV2Props {
    id: string;
    parentHeadingLevel: number;
    trainingPlanElement: TrainingPlanElement;
    trainingPlan: TrainingPlan;
    translationStorage: TranslationStorage;
}

export default function ItemSummaryTrainingPlanModuleV2(props: IItemSummaryTrainingPlanModuleV2Props) {

    return <>
        <Heading cssClass="module-title heading__Level4" headingLevel={props.parentHeadingLevel + 1} aria-label="">
            {props.trainingPlanElement.title}
        </Heading>
        <ToolbarStatus
            reason={(props.trainingPlanElement.lockedReason != null && props.trainingPlanElement.lockedReason !== '' ? 'TrainingPlan:' + props.trainingPlanElement.lockedReason : '')}
            reasonDetail={(props.trainingPlanElement.lockedReasonDetail != null && props.trainingPlanElement.lockedReasonDetail !== '' ? props.trainingPlanElement.lockedReasonDetail : '')}
            isLocked={props.trainingPlanElement.isLocked || !props.trainingPlanElement.allowSelection(props.trainingPlan)}
            item={props.trainingPlanElement}
            textVisible={false}
            id={props.trainingPlanElement.tpeId}
            renderAsIconAndText={true} />
        <ToolbarTrainingPlanModulChildCount
            trainingPlanElement={props.trainingPlanElement}
            translationStorage={props.translationStorage}
        />
        <ToolbarTrainingPlanElementDurationV2
            trainingPlanElement={props.trainingPlanElement}
            translationStorage={props.translationStorage} />
        <div className="module-description">
            {props.trainingPlanElement.description}
        </div>
    </>
}