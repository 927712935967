import { ESkillStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class SkillStatusConverter implements JsonCustomConvert<ESkillStatus> {  
    // Mapping to ESkillStatus in enum.ts
    public serialize(lessonStatus: ESkillStatus): number {
        switch (lessonStatus) {         
            case ESkillStatus.Undefined: return 0;
            case ESkillStatus.Valid: return 1;
            case ESkillStatus.Expired: return 2;   
            case ESkillStatus.Requested: return 6;
            case ESkillStatus.Declined: return 7;         
            default: return 0;
        }
    }

    // Mapping to ESkillStatus in GTServices
    public deserialize(jsonValue: number): ESkillStatus {
        switch (jsonValue) {
         
            case 0: return ESkillStatus.Undefined;
            case 1: return ESkillStatus.Valid;
            case 2: return ESkillStatus.Expired;
            case 3: return ESkillStatus.Requested; 
            case 4: return ESkillStatus.Declined;         
            default: return ESkillStatus.Undefined;
        }
    }
}
