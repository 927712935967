import { JsonObject, JsonProperty } from 'json2typescript';
import { CatalogParent } from '$storage/models/CatalogParent';
import { Item } from '$storage/models/Item';
import { LegacySearchedItem } from '$storage/models/search/legacy/LegacySearchedItem';
import { SearchResult } from '$storage/models/search/elk/SearchResult';
import { EItemType } from '$storage/models/enums';
import { SearchResultCatalogPath } from '$storage/models/search/elk/SearchResultCatalogPath';

/** This Model is used to display the searchResults in a compact way. */
@JsonObject('SearchedItem')
export class LegacySearchedItemCompact {

    @JsonProperty('catalogParents', [CatalogParent], false)
    public catalogParents: CatalogParent[] = [];

    @JsonProperty('iten', Item, false)
    public item: Item;


    /** Turns a list of normal SearchedItems in to a compact list. 
     *  That means if 1 Item is in 2 Folders, this method makes 1 Item with 2 Catalog parents.
     *  @returns results as SearchedItemCompact[]
     */
    public static GetCompactList(searchedItems: LegacySearchedItem[]): SearchResult[] {
        const results: LegacySearchedItemCompact[] = [];

        for (const tmpItem of searchedItems) {
            const catalogParents: CatalogParent[] = [];
            const searchedItem: LegacySearchedItemCompact = new LegacySearchedItemCompact();

            if (!results.find(fItem => fItem.item.sId === tmpItem.sId)) {
                for (const sub of searchedItems) {
                    if (sub.sId === tmpItem.sId) {
                        const tmpCatalogParent: CatalogParent = new CatalogParent(sub.parentItemId, sub.usageCount, sub.catalogPath);
                        catalogParents.push(tmpCatalogParent);
                    }
                }
                searchedItem.item = tmpItem;
                searchedItem.catalogParents = catalogParents;

                results.push(searchedItem);
            }
        }
        return this.GetSearchResult(results);
    }

    private static GetSearchResult(searchedItems: LegacySearchedItemCompact[]): SearchResult[] {
        const results: SearchResult[] = [];

        searchedItems.map(lesson => {
            if(lesson.item.itemType !== EItemType.Folder) {
                results.push({
                    itemId: lesson.item.itemId,
                    title: lesson.item.title,
                    summary: lesson.item.summary === null ? '' : lesson.item.summary,
                    subjects: [],
                    itemType: lesson.item.itemType,
                    itemSubType: lesson.item.itemSubType,
                    from: undefined,
                    to: undefined,
                    catalogParents: this.convertCatalogParents(lesson.catalogParents),
                    itmTPId: 0,
                    itmTPTitle: ''
                });
            }
        });
        return results;
    }

    private static convertCatalogParents(catalogParents: CatalogParent[]): SearchResultCatalogPath[] {
        const convertedCatalogParents: SearchResultCatalogPath[] = [];

        catalogParents.map(catalog => {
            convertedCatalogParents.push({
                parentId: catalog.parentItemId,
                catalogPath: catalog.catalogPath
            })
        });

        return convertedCatalogParents;
    }
}