import React from 'react';

import Session from '$core/Session';
import { SortableHeaderElement } from '$src/components/shared/SortableHeaderElement';
import { Translate } from '$src/components/shared/Translate';
import { EGlossaryAssociationType, EItemDetailCallerContextType, ESortDirection } from '$src/storage/models/enums';
import { Glossary } from '$src/storage/models/Glossary/Glossary';
import { Tooltip } from '$src/components/shared/Tooltip';
import { NavLink } from 'react-router-dom';
import { GlossarIdForBredCrumb } from '$src/storage/models/Glossary/GlossarIdForBreadCrumb';

interface IProps {
    glossaries: Glossary[];
    callerContext: EItemDetailCallerContextType;
    idsForGlossary: GlossarIdForBredCrumb;
}

interface IState {
    sortedGlossaries: Glossary[];
    titleHeader: string;
    activeHeaderId: string;
    numberOfTermsHeader: string;
    numberOfViewedTermsHeader: string;
}

export class GlossaryTable extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = ({
            activeHeaderId: '',
            numberOfTermsHeader: 'NumTerms',
            numberOfViewedTermsHeader: 'NumVTerms',
            sortedGlossaries: this.props.glossaries,
            titleHeader: 'Title',
        });

        this.OnSort = this.OnSort.bind(this);
    }

    public render() {

        return (
            <React.Fragment>
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" >
                                <SortableHeaderElement
                                    id={this.state.titleHeader}
                                    isActive={this.IsActiveHeader(this.state.titleHeader)}
                                    header="Glossary:Name"
                                    className="heading__Level4"
                                    initialSortDirection={ESortDirection.Down}
                                    OnSortDirectionChanged={this.OnSort} />
                            </div>
                            <div role="columnheader" >
                                <SortableHeaderElement
                                    id={this.state.numberOfTermsHeader}
                                    isActive={this.IsActiveHeader(this.state.numberOfTermsHeader)}
                                    header="Glossary:NumberOfTerms"
                                    className="heading__Level4"
                                    initialSortDirection={ESortDirection.None}
                                    OnSortDirectionChanged={this.OnSort} />

                            </div>
                            <div role="columnheader" >
                                <SortableHeaderElement
                                    id={this.state.numberOfViewedTermsHeader}
                                    isActive={this.IsActiveHeader(this.state.numberOfViewedTermsHeader)}
                                    header="Glossary:NumberOfViewedTerms"
                                    className="heading__Level4"
                                    initialSortDirection={ESortDirection.None}
                                    OnSortDirectionChanged={this.OnSort} />
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        {this.renderTableRows()}
                    </div>
                </div>
            </React.Fragment >
        );
    }

    private renderTableRows(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        this.state.sortedGlossaries.map((glo, index) => {
            elements.push(this.renderTableRow(glo, index));
        });
        return elements;
    }

    private renderTableRow(glossary: Glossary, index: number): JSX.Element {
        return (
            <div key={index} role="row" className="div-table__horizontal-table-row">
                <div role="columnheader" className="heading__Level5 mobileOnly">
                    <Translate>Glossary:Name</Translate>
                </div>
                <div role="cell">
                    <NavLink
                        className="glossary__table-button"
                        to={`/glossar/${glossary.id}/${this.props.callerContext}/${this.props.idsForGlossary.itemId}/${this.props.idsForGlossary.catId}/${this.props.idsForGlossary.asId}/${this.props.idsForGlossary.tpId}/${this.props.idsForGlossary.tpeId}`}
                        data-tip={Session.instance.storage.translation.GetString('Glossary:OpenGlossary') +" "+ glossary.title}
                        data-for={`${index}_openGlossaryTooltip`}
                        aria-label={Session.instance.storage.translation.GetString('Glossary:OpenGlossary') +" "+ glossary.title} >
                        {glossary.associationType === EGlossaryAssociationType.UserPinned ?
                            Session.instance.storage.translation.GetString('Glossary:MyGlossariesTitle') : glossary.title}
                    </NavLink>
                    <Tooltip id={`${index}_openGlossaryTooltip`} />
                </div>
                <div role="columnheader" className="heading__Level5 mobileOnly">
                    <Translate>Glossary:NumberOfTerms</Translate>
                </div>
                <div role="cell">
                    {glossary.numberOfTerms}
                </div>
                <div role="columnheader" className="heading__Level5 mobileOnly">
                    <Translate>Glossary:NumberOfViewedTerms</Translate>
                </div>
                <div role="cell">
                    {glossary.numberOfViewedTerms}
                </div>
            </div>
        );
    }


    private OnSort(id: string, direction: ESortDirection) {
        if (this.state.sortedGlossaries != null) {
            if (id === this.state.titleHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.titleHeader,
                        sortedGlossaries: this.state.sortedGlossaries.sort(this.sortGlossariesByTitle)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.titleHeader,
                        sortedGlossaries: this.state.sortedGlossaries.sort(this.sortGlossariesByTitleDown)
                    });
                }
            }
            if (id === this.state.numberOfTermsHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.numberOfTermsHeader,
                        sortedGlossaries: this.state.sortedGlossaries.sort(this.sortGlossariesByNumberOfTerms)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.numberOfTermsHeader,
                        sortedGlossaries: this.state.sortedGlossaries.sort(this.sortGlossariesByNumberOfTermsDown)
                    });
                }
            }
            if (id === this.state.numberOfViewedTermsHeader) {
                if (direction === ESortDirection.Down) {
                    this.setState({
                        activeHeaderId: this.state.numberOfViewedTermsHeader,
                        sortedGlossaries: this.state.sortedGlossaries.sort(this.sortGlossariesByNumberOfViewedTerms)
                    });
                }
                else {
                    this.setState({
                        activeHeaderId: this.state.numberOfViewedTermsHeader,
                        sortedGlossaries: this.state.sortedGlossaries.sort(this.sortGlossariesByNumberOfViewedTermsDown)
                    });
                }
            }
        }
    }

    private sortGlossariesByTitle(a: Glossary, b: Glossary) {
        return b.title.toLowerCase().localeCompare(a.title.toLowerCase());
    }

    private sortGlossariesByTitleDown(a: Glossary, b: Glossary) {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
    }

    private sortGlossariesByNumberOfTerms(a: Glossary, b: Glossary) {
        if (a.numberOfTerms > b.numberOfTerms) {
            return 1;
        }
        if (a.numberOfTerms < b.numberOfTerms) {
            return -1;
        }
        return 0;
    }

    private sortGlossariesByNumberOfTermsDown(a: Glossary, b: Glossary) {
        if (a.numberOfTerms > b.numberOfTerms) {
            return -1;
        }
        if (a.numberOfTerms < b.numberOfTerms) {
            return 1;
        }
        return 0;
    }

    private sortGlossariesByNumberOfViewedTerms(a: Glossary, b: Glossary) {
        if (a.numberOfViewedTerms > b.numberOfViewedTerms) {
            return 1;
        }
        if (a.numberOfViewedTerms < b.numberOfViewedTerms) {
            return -1;
        }
        return 0;
    }

    private sortGlossariesByNumberOfViewedTermsDown(a: Glossary, b: Glossary) {
        if (a.numberOfViewedTerms > b.numberOfViewedTerms) {
            return -1;
        }
        if (a.numberOfViewedTerms < b.numberOfViewedTerms) {
            return 1;
        }
        return 0;
    }


    private IsActiveHeader(id: string) {
        return id === this.state.activeHeaderId;
    }

}
export default GlossaryTable;