import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import IconskillExpired from '$resources/svgs/skills/skill-expired.svg';
import IconskillMet from '$resources/svgs/skills/skill-met.svg';
import IconskillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import { ItemOutputSkill } from '$src/storage/models/ItemDetails/ItemOutputSkill';
import { ItemSkills } from '$storage/models/ItemDetails/ItemSkills';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    itemSkills: ItemSkills | null;
}

interface IState {
    itemSkills: ItemSkills | null;
    errorMessage: string;
}

export class ItemOutputSkills extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessage: '',
            itemSkills: this.props.itemSkills,
        }
    }

    public render() {
        return (
            <React.Fragment>
                <table className="tp-table">
                    <thead>
                        <tr>
                            <th><Translate>TrainingPlan:Skills</Translate></th>
                            <th colSpan={2} className="tp-table__column--double"><Translate>TrainingPlan:SkillLevel</Translate></th>
                            <th ><Translate>TrainingPlan:Fulfilled</Translate></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderItems()}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    protected renderItems(): JSX.Element[] | JSX.Element {
        if (this.state.itemSkills != null && this.state.itemSkills.userOutputSkills != null && this.state.itemSkills.userOutputSkills.length > 0) {
            const tr = Session.instance.storage.translation;
            const elements: JSX.Element[] = [];
            this.state.itemSkills.userOutputSkills.map(
                (oskl, index) => elements.push(
                    <tr key={index}>
                        <td>{oskl.outputSkill != null ? oskl.outputSkill.skillTitle : '??'}</td>
                        <td colSpan={2}>{oskl.outputSkill != null ? oskl.outputSkill.skillLevelTitle : ''}</td>
                        <td 
                            data-tip={tr.GetString('TrainingPlan:OSkillOwned') + ': ' + this.renderOutputSkillTitles(oskl)}
                            data-for={`${index}_oSkillStatusTooltip`}
                            aria-label={tr.GetString('TrainingPlan:OSkillOwned') + ': ' + this.renderOutputSkillTitles(oskl)}>
                            <div className="inlineFlexAboveSm">{this.renderOutputSkillIcons(oskl)}
                                <span className="marginLeft5AboveSm">{this.renderOutputSkillTitles(oskl)}</span>
                            </div>
                            <Tooltip id={`${index}_oSkillStatusTooltip`} />
                        </td>
                    </tr>));
            return elements;
        }
        else {
            return (
                <tr>
                    <td colSpan={3}>
                        <NoDataFound message={Session.instance.storage.translation.GetString('TrainingPlan:NoPrerequisites')} />
                    </td>
                </tr>
            );
        }
    }

    private renderOutputSkillIcons(oskl: ItemOutputSkill): JSX.Element {
        let iconName = '';
        if (oskl.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            iconName = IconskillMet;
        }
        else if (oskl.isExpired) {
            iconName = IconskillExpired;
        }
        else {
            iconName = IconskillNotMet;
        }

        return <InlineSVG src={iconName} />
    }

    private renderOutputSkillTitles(oskl: ItemOutputSkill): string {
        const tr = Session.instance.storage.translation;
        let title = '';
        if (oskl.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            title = tr.GetString('TrainingPlan:FulfilledYes');
        }
        else if (oskl.isExpired) {
            title = tr.GetString('TrainingPlan:SkillExpired');
        }
        else {
            title = tr.GetString('TrainingPlan:FulfilledNo');
        }

        return title;
    }


}