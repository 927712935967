import React from "react";

import ItemFooterButton, { EItemButtonType } from "$src/components/shared/itemBox/ItemFooterButton";
import { startWBT } from "$src/components/shared/itemBox/LessonStart";
import IconAndText from "$src/components/shared/Atoms/IconAndText";
import { EColor, EItemDetailCallerContextType } from "$src/storage/models/enums";

/**
 * The GT Item Footer Props
 */
export interface IItemFooterProps {

    /**
     * Component unique id
     */
    id: string;

    /**
     * **Optional** Item footer information, containing a icon, text and tooltip.
     * **To determine the correct information use ItemSummaryHelper.calculateItemDetailFooterInformation()**
     */
    itemFooterIconAndText?: { icon?: string; ariaLabel: string; text?: string; textColor?: EColor; tooltip?: string };

    /**
     * Information about to footer button. If footerButtonType.buttonType == EItemButtonTye.Undefined no button is displayed.
     * **To determin what button should be displayed use ItemSummaryHelper.calculateItemDetailFooterButtonType**
     */
    footerButtonType: { buttonType: EItemButtonType; ariaLabel: string; tooltipText?: string; disabled?: boolean };

    /**
     * Flag if item is locked
     */
    isItemLocked: boolean;

    /**
     * Id of item
     */
    itemId: number;

    /**
     * Title of item
     */
    itemTitle: string;

    /**
     * Context the item was opened in
     */
    itemContext: EItemDetailCallerContextType;

    /**
     * Id of assignment
     */
    parentAssignmentId: number;

    /**
     * **Optional** Text to display in the tool tip. If empty no tool tip will be displayed
     */
    tooltipText?: string;

    /**
     * **Optional** Function for button of item summary detail footer
     */
    onButtonClicked?: () => void;

}

function onPlayButtonClicked(event: React.FormEvent<HTMLButtonElement>, isItemLocked: boolean, itemId: number, itemTitle: string,
    itemContext: EItemDetailCallerContextType, parentAssignmentId: number) {
    event.preventDefault();
    event.stopPropagation();
    const methodName = 'ItemFooter:onButtonClicked()';
    const loggerLocality = 'Components.ItemFooter';
    startWBT(methodName, isItemLocked, itemId, itemTitle, itemContext, parentAssignmentId, 0, 0, loggerLocality);
}


/**
 * Global Teach Item Footer Component
 */
export default function ItemFooter(props: IItemFooterProps) {

    return (
        <div
            key={props.id}
            className="detail-footer">
            <div className="detail-footer__grid__information">
                {props.itemFooterIconAndText && props.itemFooterIconAndText.icon &&
                    <IconAndText
                        id={props.id}
                        icon={props.itemFooterIconAndText.icon}
                        ariaLabel={props.itemFooterIconAndText.ariaLabel}
                        iconText={props.itemFooterIconAndText.text}
                        textColor={props.itemFooterIconAndText.textColor}
                        tooltipText={props.itemFooterIconAndText.tooltip} />
                }
            </div>

            <div className="detail-footer__grid__button">
                <ItemFooterButton
                    id={props.id}
                    buttonType={props.footerButtonType.buttonType}
                    ariaLabel={props.footerButtonType.ariaLabel}
                    disabled={props.footerButtonType.disabled}
                    tooltipText={props.footerButtonType.tooltipText}
                    onPlayButtonClicked={(event) => 
                        onPlayButtonClicked(event, props.isItemLocked, props.itemId, props.itemTitle, props.itemContext, props.parentAssignmentId)}
                    onButtonClicked={props.onButtonClicked} />
            </div>
        </div>
    );
}