import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourse {
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('sid', String, false)
    public sid: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('courseId', Number, false)
    public courseId: number = 0;

    @JsonProperty('description', String, false)
    public description: string = '';
    
    @JsonProperty('courseUrl', String, false)
    public courseUrl: string = '';
}