import React from 'react';

import { RegisteredItems } from '$src/components/registeredItem/RegisteredItems';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';

export class AllRegisteredItems extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
    }

    public render() {
        return <div className="all-registered-items">
            <div className="l-container">
                <h1 className="heading__Title">
                    <Translate>MyTrainings:Title</Translate>
                </h1>
                <RegisteredItems maxItems={0} {...this.props} parentHeadingLevel={1} />
            </div>
        </div >;
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('MyTrainings:Title');
    }
}
export default AllRegisteredItems;