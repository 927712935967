import Session from '$src/core/Session';
import React, { useEffect, useState } from 'react';
import GTButton, { EButtonSize } from '$components/shared/Atoms/GTButton';
import { ModalPopup } from '$components/shared/ModalPopup';
import { Translate } from '$components/shared/Translate';
import { useHistory } from 'react-router';

export default function UserProfileMissingDataWarning() {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const history = useHistory();

    function checkUserProfileData(): void {
        let profileIsComplete = true;
        const loginUser = Session.instance.loginUser;
        if (loginUser != null) {
            const getExtendedConfiguration = () => {
                if(globalConfig.userProfileExtendedProperties != null && globalConfig.userProfileExtendedProperties.length > 0) {
                    return globalConfig.userProfileExtendedProperties.find(up => {
                        up.allowedGroups = up.allowedGroups.map(ag => ag.toLocaleLowerCase());
                        return (loginUser.groups.filter(userGroup => up.allowedGroups.includes(userGroup.groupName.toLocaleLowerCase())).length > 0);
                    })
                }
                return null;
            };
            const extendedConfiguration = getExtendedConfiguration();
    
            const userProfileConfig = extendedConfiguration != null ? extendedConfiguration.fieldGroups : globalConfig.userProfileProperties.fieldGroups;
            userProfileConfig.map(config => {
                config.fields.filter(field => field.required && field.type.toLocaleLowerCase() !== "redirectlink").map(field => {
                    if(loginUser[field.property] == '' || loginUser[field.property] == null || !loginUser[field.property]) {
                        profileIsComplete = false;
                    }
                });
            });
        }

        if (!profileIsComplete) {
            setOpenModal(!Session.instance.getIgnoreRequiredUserProfileData);
        } else {
            setOpenModal(false);
        }
    }

    useEffect(() => {
        if(globalConfig.loginProperties.checkUserProfileData) {
            checkUserProfileData()
        } 
    }, []);

    const ignore = () => {
        Session.instance.setIgnoreRequiredUserProfileData();
        setOpenModal(false);
    }

    const goToProfile = () => {
        Session.instance.setIgnoreRequiredUserProfileData();
        setOpenModal(false);
        history.push('/userProfile')
    }

    return (
        <ModalPopup
            isOpen={openModal}
            onRequestClose={() => ignore()}>
            <div>
                <Translate>UserProfile:MissingMandatoryData</Translate>
                <div className="modal__spread-buttons">
                    <GTButton isPrimary={true} buttonSize={EButtonSize.small} onClick={() => goToProfile()} id="btnGoToProfile">
                        <Translate>UserProfile:GoToProfile</Translate>
                    </GTButton>
                    <GTButton isPrimary={false} buttonSize={EButtonSize.small} onClick={() => ignore()} id="btnCancelProfileCheck">
                        <Translate>UserProfile:Ignore</Translate>
                    </GTButton>
                </div>
            </div>
        </ModalPopup>
    )
}