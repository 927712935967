import { EItemType, EItemTypeAttributeValue } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class ItemTypeConverter implements JsonCustomConvert<EItemType> {
    // from WebApi Models\Enums.cs: Folder=1, Document=2, Wbt=3, Questionnaire=4, F2FCourse=5, Recording=6
    // Agenda=7, FileSharing=8, TrainingPlan=9, Assessement=10, CBT = 11
    public serialize(itemType: EItemType): any {
        switch (itemType) {
            case EItemType.Folder: return 1;
            case EItemType.Document: return 2;
            case EItemType.WBT: return 3;
            case EItemType.Questionnaire: return 4;
            case EItemType.F2FCourse: return 5;
            case EItemType.Recording: return 6;
            case EItemType.Agenda: return 7;
            case EItemType.FileSharing: return 8;
            case EItemType.TrainingPlan: return 9;
            case EItemType.Assessment: return 10;
            case EItemType.CBT: return 11;
            case EItemType.Block: return 10000003;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EItemType {
        switch (jsonValue) {
            case 1: return EItemType.Folder;
            case 2: return EItemType.Document;
            case 3: return EItemType.WBT;
            case 4: return EItemType.Questionnaire;
            case 5: return EItemType.F2FCourse;
            case 6: return EItemType.Recording;
            case 7: return EItemType.Agenda;
            case 8: return EItemType.FileSharing;
            case 9: return EItemType.TrainingPlan;
            case 10:return EItemType.Assessment;
            case 11:return EItemType.CBT;
            case 10000003: return EItemType.Block;
            default: return EItemType.Undefined;
        }
    }

    public static asGtServiceItemString(itemType: EItemType): string {
        switch (itemType) {
            case EItemType.Folder: return '1164';
            case EItemType.Document: return '1051';
            case EItemType.WBT: return '1252';
            case EItemType.Questionnaire: return '1222';
            case EItemType.F2FCourse: return '1050';
            case EItemType.Recording: return '1278';
            case EItemType.Agenda: return '1277';
            case EItemType.FileSharing: return '1492';
            case EItemType.TrainingPlan: return '1185';
            case EItemType.Assessment: return '1310';
            case EItemType.CBT: return '1048';
            case EItemType.Block: return '10000003';
            default: return '0'
        }
    }

    public static asItemTypeAttributeValue(itemType: EItemType): EItemTypeAttributeValue {
        switch (itemType) {
            case EItemType.Folder: return EItemTypeAttributeValue.Folder;
            case EItemType.Document: return EItemTypeAttributeValue.Document;
            case EItemType.WBT: return EItemTypeAttributeValue.WBT;
            case EItemType.Questionnaire: return EItemTypeAttributeValue.Questionnaire;
            case EItemType.F2FCourse: return EItemTypeAttributeValue.F2FCourse;
            case EItemType.Recording: return EItemTypeAttributeValue.Recording;
            case EItemType.FileSharing: return EItemTypeAttributeValue.FileSharing;
            case EItemType.TrainingPlan: return EItemTypeAttributeValue.TrainingPlan;
            case EItemType.Assessment: return EItemTypeAttributeValue.Assessment;
            case EItemType.CBT: return EItemTypeAttributeValue.CBT;
            case EItemType.Block: return EItemTypeAttributeValue.Block;
            default: return EItemTypeAttributeValue.Undefined
        }
    }

    public static asItemTypeAttribueValues(itemTypes: Array<EItemType>): Array<EItemTypeAttributeValue> {
        return itemTypes.map(it => this.asItemTypeAttributeValue(it));
    }
}
