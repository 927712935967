import { InputAttributes } from '$src/components/shared/InputAttributes';
import { InputText } from '$src/components/shared/InputText';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import { isSuccess } from '$src/util/Result';
import React from 'react';

interface IProps {
    onSaveExternalCourseProvider?: (confirmed: boolean, externalCourseProvider: ExternalCourseProvider | undefined) => void;
    parentHeadingLevel: number;
}

interface IState {
    errorMessage: string;
    externalCourseProvider: ExternalCourseProvider;
}

export class CreateExternalCourseProvider extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            externalCourseProvider: new ExternalCourseProvider(),
        }
    }

    public render() {
        const ariaLabelCancel = Session.instance.storage.translation.GetString('Button:Cancel');
        const ariaLabelSave = Session.instance.storage.translation.GetString('Button:Save');

        // disabled button if required field isn't filled
        const saveDisabled = this.state.externalCourseProvider.name.length === 0;

        const providerNameAttributes: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: true,
            id: 'providerName',
            initialValidation: false,
            isReadOnly: false,
            isRequired: true,
            label: Session.instance.storage.translation.GetString('ExternalCourse:CourseProviderName'),
            labelClassName: 'heading__Level5',
            maxLength: 50,
            regExpression: undefined,
            rows: 5,
            url: undefined,
            value: this.state.externalCourseProvider.name,
        };

        const providerUrlAttributes: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: true,
            hasFocus: false,
            id: 'providerUrl',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: Session.instance.storage.translation.GetString('ExternalCourse:OptionalCourseProviderUrl'),
            labelClassName: 'heading__Level5',
            maxLength: 255,
            regExpression: undefined,
            rows: 5,
            url: undefined,
            value: this.state.externalCourseProvider.name,
        };
        return (
            <div>
                <span className={'input-message error'}>
                    <Translate>{this.state.errorMessage}</Translate>
                </span>
                <InputText attributes={providerNameAttributes} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                <InputText attributes={providerUrlAttributes} onChange={(id: string, value: string) => this.onInputChange(id, value)} />
                <div className="modal__spread-buttons">
                    <button type="button"
                        aria-label={ariaLabelSave}
                        className="btn btn--sm marginRight-5"
                        onClick={() => this.onButtonClickSave()}
                        disabled={saveDisabled}
                        id="btnSave">
                        <Translate>Button:Save</Translate>
                    </button>
                    <button type="button"
                        aria-label={ariaLabelCancel}
                        className="btn btn--sm"
                        onClick={() => this.onButtonClickClose()}
                        id="btnClose">
                        <Translate>Button:Cancel</Translate>
                    </button>
                </div>
            </div>
        )
    }

    private onInputChange(id: string, value: string) {
        const externalCourseProvider = this.state.externalCourseProvider;
        switch (id) {
            case 'providerName':
                externalCourseProvider.name = value;
                break;
            case 'providerUrl':
                externalCourseProvider.url = value;
                break;
        }
        this.setState({ externalCourseProvider });
    }

    private onButtonClickClose() {
        if (this.props.onSaveExternalCourseProvider) {
            this.props.onSaveExternalCourseProvider(false, undefined);
        }
    }

    private async onButtonClickSave() {
        const sidAlreadyExists = await ExternalCourseService.instance.externalCourseProviderExists(this.state.externalCourseProvider.name);
        if (isSuccess<BooleanResponse>(sidAlreadyExists)) {
            if (!sidAlreadyExists.status) {
                if (this.props.onSaveExternalCourseProvider) {
                    this.props.onSaveExternalCourseProvider(true, this.state.externalCourseProvider);
                }
            } else {
                this.setState({ errorMessage: 'ErrorMessage:ExternalCourseProviderAlreadyExists' });
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:FailedToCheckIfExist' });
        }
    }

}