import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject
export class GamificationUserHighscore {

    @JsonProperty('id', Number, false)
    public userId: number = 0;

    @JsonProperty('rank', Number, false)
    public rank: number = 0;

    @JsonProperty('points', Number, false)
    public points: number = 0;

    @JsonProperty('userFirstAndLastname', String, false)
    public userFirstAndLastname: string = "";

    @JsonProperty('reachedLevelName', String, false)
    public reachedLevelName: string = "";

    @JsonProperty('reachedLevelAvId', Number, false)
    public reachedLevelAvId: number = 0;

}