import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class F2FRegistrationRequest {

    @JsonProperty('courseId', Number, false)
    public courseId: number = 0;

    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    @JsonProperty('selectedBossId', Number, false)
    public selectedBossId: number = 0;

    @JsonProperty('registerAsStatusRequested', Number, false)
    public registerAsStatusRequested: boolean = false;
}