import 'whatwg-fetch'

/*
* Recursively merge properties of two objects 
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const MergeRecursive = (obj1: any, obj2: any) => {

    // tslint:disable-next-line: forin
    for (const p in obj2) {
        try {
            // Property in destination object set; update its value.
            if (obj2[p].constructor === Object) {
                obj1[p] = MergeRecursive(obj1[p], obj2[p]);
            } else {
                obj1[p] = obj2[p];
            }
        } catch (e) {
            // Property in destination object not set; create it and set its value.
            obj1[p] = obj2[p];
        }
    }
    return obj1;
}

/**
 * Injects the configuration and customization files
 */

export const InjectPreLoginConfiguration = (callback: () => Promise<void>) => {
    console.log(`CONFIG: Injecting Global Config File configuration for service url, looking at ${__webpack_public_path__ + "config/globalConfig.js"}`);
    // Inject Global Description
    const globConfigFile = document.createElement('script');
    globConfigFile.src = __webpack_public_path__ + "config/globalConfig.js";
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    globConfigFile.onload = function AfterGlobalConfigFileLoad() { InjectPreLoginConfigMapFile(callback) }
    document.head.appendChild(globConfigFile);
}

const InjectPreLoginConfigMapFile = (callback: () => Promise<void>) => {
    console.log(`CONFIG: Global Config File found and loaded, injecting configuration map, looking at ${__webpack_public_path__ + "config/configMap.js"}`);
    const configMap = document.createElement('script');
    configMap.src = __webpack_public_path__ + "config/configMap.js";
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    configMap.onload = function AfterConfigMapLoad() { InjectPreLoginCustomizationConfigFiles(callback) };
    document.head.appendChild(configMap);
}

const InjectPreLoginCustomizationConfigFiles = (callback: () => Promise<void>) => {
    console.log('CONFIG: Config Map found and loaded, injecting custom style file and customization config file');

    if (configMap.configs == null || configMap.configs.length === 0) {
        console.error("CONFIG: Invalid configmap configuration, could not load configurations");
        return;
    }

    let configToUse = configMap.configs.filter(c => window.location.href.toLowerCase().includes(c.url.toLowerCase()));
    if (configToUse == null || configToUse.length === 0) {
        configToUse = configMap.configs.filter(c => c.url === "*");
    }

    if (configToUse.length > 1) {
        console.warn(`CONFIG: Found multiple configurations for ${window.location.href}, using ${JSON.stringify(configToUse[0])}`)
    }
    else if (configToUse == null) {
        console.error(`CONFIG: Could not find valid configmap configuration for ${window.location.href}, could not load configurations`);
        return
    }
    else {
        console.log(`CONFIG: Found configurations for ${window.location.href}, using ${JSON.stringify(configToUse[0])}`)
    }

    console.log(`CONFIG: Config Map: ${JSON.stringify(configMap.configs)}`);


    // Inject Customization Config
    const custConfig = document.createElement('script');
    // custConfig.src = "./config/customizationConfig.js";
    custConfig.src = __webpack_public_path__ + configToUse[0].customizationConfigPath;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    custConfig.onload = async function AfterCustomizationLoad() { await ReadConfiguration(callback) };
    document.head.appendChild(custConfig);

    // Inject Customization Styles
    const custStyle = document.createElement('link');
    custStyle.type = "text/css";
    custStyle.rel = "stylesheet";
    // custStyle.href = "./config/customization.css";
    custStyle.href = __webpack_public_path__ + configToUse[0].customizationStylePath;
    document.head.appendChild(custStyle);
}

const ReadConfiguration = async (callback: () => Promise<void>) => {
    console.log('CONFIG: Global Config File found and loaded, injecting custom configuration file into global configuration file');

    try {
        console.debug('CONFIG: Merging Global Config File with Customization Config File');
        console.debug(`CONFIG: GlobalConfig File: ${JSON.stringify(globalConfig)}`);
        console.debug(`CONFIG: CustomizationConfig File: ${JSON.stringify(customizationConfig)}`);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        MergeRecursive(globalConfig, customizationConfig);
        console.debug(`CONFIG: Merge Result GlobalConfig: ${JSON.stringify(globalConfig)}`);
    }
    catch (ex) {
        console.warn('CustomizationConfig not found');
    }

    console.debug(`CONFIG: Injecting Global Config DB configuration`);
    // Get Service Reference from GlobalConfig File
    const svcProtocolFromGlobalConfigFile: string = globalConfig.serviceProperties.overrideSvcProtocol || process.env.REACT_APP_API_PROTOCOL || window.location.protocol.replace(':', '');
    const svcHostnameFromGlobalConfigFile: string = globalConfig.serviceProperties.overrideSvcHostName || process.env.REACT_APP_API_HOSTNAME || window.location.hostname;
    const svcPortSuffixFromGlobalConfigFile: string = globalConfig.serviceProperties.overrideSvcPortSuffix || process.env.REACT_APP_API_PORT_SUFFIX || '';
    const svcBaseUrlFromGlobalConfigFile: string = globalConfig.serviceProperties.overrideSvcBaseUrl || process.env.REACT_APP_API_BASE_URL || 'gtservices/suisvc/v1/'; // must end with '/'
    const svcUrl = `${svcProtocolFromGlobalConfigFile}://${svcHostnameFromGlobalConfigFile}${svcPortSuffixFromGlobalConfigFile}/${svcBaseUrlFromGlobalConfigFile}`;
    console.debug(`CONFIG: Searching for GTService at URL ${svcUrl}`);
    // use fetch since this is the very first which is loading and session and services are not yet initialized
    const response = await fetch(`${svcUrl}/config/nsui`);
    const data = await response.json();
    const globalConfigDB = eval('(' + data.globalConfig + ')');
    const customizationConfigDB = eval('(' + data.customizationConfig + ')');
    console.log(`CONFIG: Merging Global Config File with Global Config from Database`);
    console.debug(`CONFIG: GlobalConfig from DB: ${JSON.stringify(globalConfigDB)}`);
    MergeRecursive(globalConfig, globalConfigDB);
    console.debug(`CONFIG: Merge Result: ${JSON.stringify(globalConfig)}`);

    console.log(`CONFIG: Merging Global Config with Customization Config from Database`);
    console.debug(`CONFIG: CustomizationConfig from DB: ${JSON.stringify(customizationConfigDB)}`);
    MergeRecursive(globalConfig, customizationConfigDB);
    console.debug(`CONFIG: Merge Result: ${JSON.stringify(globalConfig)}`);

    if (globalConfig.appProperties.logConfigToConsole) {
        console.log(globalConfig);
    }
    callback();
}