import { JsonObject, JsonProperty } from 'json2typescript';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import { EItemType } from '$storage/models/enums';


/** Request to be sent to the validateBeforePurchase api */
@JsonObject
export class ItemPurchaseValidationRequest {

    constructor(itemType: EItemType, itemTitle: string, itemId: number, classId: number, f2fClassIdsForTP: number[], isPurchaseForOthers: boolean, voucherCodeForCurrentUserHimSelf: string){
        this.itemType = itemType;
        this.itemTitle = itemTitle;
        this.itemId = itemId;
        this.classId = classId;
        this.f2fClassIdsForTP = f2fClassIdsForTP;
        this.isPurchaseForOthers = isPurchaseForOthers;
        this.voucherCodeForCurrentUserHimSelf = voucherCodeForCurrentUserHimSelf;
    }

    /** Type of the item (WBT, F2F, TP) */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType;

    /** Tiltle of the item, will be used in reason messages */
    @JsonProperty('itemTitle', String, false)
    public itemTitle: string;

    /** ID of item to validate */
    @JsonProperty('itemId', Number, false)
    public itemId: number;

    /** Class ID of item to validate (for F2F/TP) */
    @JsonProperty('classId', Number, false)
    public classId: number;

    /** List of F2F class IDs selected with the training plan (for TPs containing F2F courses) */
    @JsonProperty('f2fClassIdsForTP', [Number], false)
    public f2fClassIdsForTP: number[] = [];

    /** True if booking admin or boss is purchasing items for others by payment booking page
        Important for validation if more than one class of the same course is purchased */
    @JsonProperty('isPurchaseForOthers', Boolean, false)
    public isPurchaseForOthers: boolean = false;

    /** VoucherCode for CurrentUser himself (ShoppingBasket voucher) */
    @JsonProperty('voucherCodeForCurrentUserHimSelf', String, false)
    public voucherCodeForCurrentUserHimSelf: string = '';
}