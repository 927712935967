export class CookieHelper {


    public static setCookie(name: string, value: string) {
        const date = new Date();
        const val = value;

        // Set it expire in 7 days
        date.setTime(date.getTime() + (globalConfig.cookieProperties.cookieLifetimeInDays * 24 * 60 * 60 * 1000));

        // Set it
        document.cookie = name + '=' + val + '; expires=' + date.toUTCString() + '; path=/';
    }

    public static getCookie(name: string): string | null {
        const nameLenPlus = (name.length + 1);
        return document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => {
                // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map(cookie => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
    }

    public static deleteCookie(name: string) {
        const date = new Date();
        // Set it expire in -1 days
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

        // Set it
        document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
    }
}