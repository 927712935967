import { EValueType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class ValueTypeConverter implements JsonCustomConvert<EValueType> {
    // from WebApi Models\Attribute.cs: Undefined=0, String=1, Integer=2, Double=3, Boolean=4, DateTime=5, Enum=6, Image=7
    public serialize(valueType: EValueType): any {
        switch (valueType) {
            case EValueType.String: return 1;
            case EValueType.Integer: return 2;
            case EValueType.Double: return 3;
            case EValueType.Boolean: return 4;
            case EValueType.DateTime: return 5;
            case EValueType.Enum: return 6;
            case EValueType.Image: return 7;
            case EValueType.Date : return 8;
            case EValueType.ExternalVideoLink : return 9;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EValueType {
        switch (jsonValue) {
            case 1: return EValueType.String;
            case 2: return EValueType.Integer;
            case 3: return EValueType.Double;
            case 4: return EValueType.Boolean;
            case 5: return EValueType.DateTime;
            case 6: return EValueType.Enum;
            case 7: return EValueType.Image;
            case 8: return EValueType.Date;
            case 9: return EValueType.ExternalVideoLink;
            default: return EValueType.Undefined;
        }
    }
}
