import CollaborationProviderService from '$core/Services/CollaborationProviderService';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { isSuccess } from '$util/Result';
import { useState, useEffect } from 'react';

type IUseHasCollaborationClassLinkProps = {
  itemId: number;
  classId: number;
}

export const useHasCollaborationClassLink = (props: IUseHasCollaborationClassLinkProps) => {
  const [hasClassLink, setHasClassLink] = useState<boolean>(false);
  useEffect(() => {
    const linkExists = async (itemId: number, classId: number) => {
      const result2 = await CollaborationProviderService.instance.hasClassLink(itemId, classId);
      if (isSuccess<BooleanResponse>(result2)) {
        setHasClassLink(result2.status);
      }
    }
    linkExists(props.itemId, props.classId);
  }, []);

  return [hasClassLink];
}