import React, { useState } from 'react';

import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';
import MyPrivacyTable from '$src/components/privacy/MyPrivacyTable';
import RevokeMyPrivacy from '$src/components/privacy/RevokeMyPrivacy';

/**
 * Main container component for users declaration of consent / terms and conditions / privacy statement / dsgvo
 * @param props 
 */
export default function MyPrivacy() {
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <div className="my-privacy__container">
            <div className="l-container">
                <MenuBreadCrumb />
                
                <Heading headingLevel={1} cssClass="heading__Title">
                    <Translate>Navigation:MyPrivacy</Translate>
                </Heading>               
            </div>
            
            <div className="l-container">
                {isDisabled === false && <MyPrivacyTable isDisabledByGroup={(e: boolean) => setIsDisabled(e)} />}
                {isDisabled === false && <RevokeMyPrivacy />}
                {isDisabled === true && <UseIsDisabled />}
            </div>
        </div>
    )
}

const UseIsDisabled = () => {
    return (
        <span>
            <Translate>MyPrivacy:DisabledByGroup</Translate>
        </span>
    )
}