import { ErrorSummary } from '$components/shared/ErrorSummary';
import { GenericInput, GenericInputTypes } from '$components/shared/GenericInput';
import { Heading } from '$components/shared/Heading';
import { ISetFocus } from '$components/shared/InputAttributes';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import UserService from '$src/core/Services/UserService';
import Session from '$src/core/Session';
import { ETCApprovalStatus, ERegistrationRequestType, EAttributeType } from '$src/storage/models/enums';
import Logger from '$src/core/Logger';
import { User } from '$src/storage/models/User';
import { Guid } from '$src/util/Guid';
import { isSuccess } from '$src/util/Result';
import React, { RefObject } from 'react';
import { Redirect } from 'react-router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import GtError from '$src/util/GtError';
import { UserTCApprovalStatus } from '$src/storage/models/Privacy/UserTCApprovalStatus';
import PrivacyService from '$src/core/Services/PrivacyService';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import * as _ from "lodash";

import { RegisterNewUserRequest } from '$src/storage/models/RequestObjects/RegisterNewUserRequest';
import { RegisterNewUserResponse } from '$src/storage/models/RegisterNewUserResponse';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { Group } from '$src/storage/models/Group';

interface IProps {
    parentHeadingLevel: number;
    onSaveUser?: (userSave: boolean) => void;
    bossRelationCode?: string | null;
    boss?: User | null;
    licenseKey?: string;
}

interface IState {
    registrationRequestType: ERegistrationRequestType;
    uiState: EUiState;
    showErrorSummary: boolean;
    errorMessage: string;
    tokenForSecondStep: string; // used for self-registration with 2 steps: token returned by 1st step is used to protect 2nd call to registerNewUser
    user: User;
    isFormValid: boolean;
    formErrorMsg: string;
    statusOfInputControls: IStatusOfInputControl[];
    password: string;
    confirmPassword: string;
    isSavingUser: boolean;
    isUserCreated: boolean;
    captchaValidation: boolean;
    isTCchecked: boolean;
    tcDocument: UserTCApprovalStatus | undefined;
    isGdprEnabled: boolean;
    isUserAnonymous: boolean;
    saving: boolean;
    selectedGroups: Group[] | undefined;
}

interface IStatusOfInputControl {
    id: string;
    label: string;
    isValid: boolean;
    errorMsg: string;
}

/** Used to track the current state of the user interface: 
 *    SingleStep_*   States used during self-registration with one step.
 *    MultisStep1_*  States used during 1st step of self-registration with two steps.
 *    MultisStep2_*  States used during 2nd step of self-registration with two steps.
 *    Error          Show error message.
*/
enum EUiState {
    Initializing,
    RegisterByBossShowForm, RegisterByBossSaving, RegisterByBossRedirecting,
    SingleStepShowForm, SingleStepSaving, SingleStepLogin, SingleStepRedirecting,
    MultiStep1ShowForm, MultiStep1Saving, MultiStep1Finished,
    MultiStep2LoadingUser, MultiStep2ShowForm, MultiStep2Saving, MultiStep2Redirecting,
    Error
}

export class SelfRegistration extends React.Component<IProps, IState> {
    private className = 'SelfRegistration';
    private loggerLocality = 'Components.SelfRegistration';
    private readonly localStorageKeyForSavedUsername = 'savedUsername';

    private _lastIndex: number = 99999;
    private _pageIsEmbedded: boolean = false; // flag if this component is called by another component or by routing
    grcpRef: string | ((instance: GoogleReCaptchaProvider | null) => void) | RefObject<GoogleReCaptchaProvider> | null | undefined;

    constructor(props: IProps) {
        super(props);
        this._pageIsEmbedded = this.props.parentHeadingLevel != null;

        this.state = {
            registrationRequestType: this.getRegistrationRequestType(),
            uiState: EUiState.Initializing,
            showErrorSummary: globalConfig.selfRegProperties.showErrorySummaryBeforeOnClick,
            errorMessage: '',
            tokenForSecondStep: this.getTokenFromUrl(),
            user: new User(),
            isFormValid: true,
            formErrorMsg: '',
            statusOfInputControls: [],
            password: '',
            confirmPassword: '',
            isSavingUser: false,
            isUserCreated: false,
            captchaValidation: globalConfig.captchaProperties.enableCaptcha,
            isTCchecked: false,
            tcDocument: undefined,
            isGdprEnabled: false,
            isUserAnonymous: false,
            saving: false,
            selectedGroups: Session.instance.loginUser?.groups.filter(g => !g.isDynamic && g.groupTypeId === 0) // No Dynamic Groups and no BossEmployee Groups should be able to select.
        };

        this.grcpRef = React.createRef();
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        this.getFormfields();
        if (!this._pageIsEmbedded) {
            document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('SelfReg:SelfRegistration');
        }

        if (this.state.registrationRequestType !== ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration) {
            const isGdprEnabled = await this.isGdprEnabled();
            this.setState({ isGdprEnabled });
            if (isGdprEnabled) {
                await this.loadTCDocument();
            }
        }

        let user: User = new User();
        let nextUiState: EUiState = EUiState.Error;

        switch (this.state.registrationRequestType) {

            case ERegistrationRequestType.RegistrationByBoss:
                nextUiState = EUiState.RegisterByBossShowForm;
                break;

            case ERegistrationRequestType.SingleStepSelfRegistration:
                nextUiState = EUiState.SingleStepShowForm;
                break;

            case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration:
                nextUiState = EUiState.MultiStep1ShowForm;
                break;

            case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration: {
                // Does the URL include a token? yes -> this is the 2nd step of two-step-self-registration -> load user information from 1st step
                const token = this.getTokenFromUrl();
                const userFromStep1 = await this.getUserFromFistStep(token);
                if (userFromStep1 != null) {
                    user = userFromStep1;
                } else {
                    return; // this.status.UiStatus and this.status.statusOfInputControls are already set
                }
                nextUiState = EUiState.MultiStep2ShowForm;
                break;
            }
        }

        user = this.loadUrlParameter(user);
        if (user.notificationLanguageId === 0) {
            user.notificationLanguageId = await this.getUiLanguageId();
        }

        Logger.log(this.loggerLocality, `${methodName} uiState=${nextUiState}`);
        this.setState({ user: user, uiState: nextUiState });
    }

    private isGdprEnabled = async () => {
        const isGdprEnabled: BooleanResponse | GtError =
            await PrivacyService.instance.isGdprEnabledNoAuthorization(this.state.isUserAnonymous);

        if (isSuccess<BooleanResponse>(isGdprEnabled)) {
            return isGdprEnabled.status;
        }
        return false;
    }

    private loadTCDocument = async () => {
        const tcDocument: UserTCApprovalStatus | GtError =
            await PrivacyService.instance.getSelfregistrationTermsAndConditionsApprovalStatus(Session.instance.getUserLanguageCodeOrFallBack, this.state.isUserAnonymous);

        if (isSuccess<UserTCApprovalStatus>(tcDocument)) {
            const isTCchecked = this.state.isTCchecked;
            this.setState({ tcDocument: tcDocument, isTCchecked })
        }
    }

    private async getUiLanguageId(): Promise<number> {
        const currentUiLanguageCode = Session.instance.languageCode;
        const avLanguages = await Session.instance.storage.attributeValue.getAttributeValuesAsync(EAttributeType.UILanguage)
        if (avLanguages != null) {
            const avLanguage = avLanguages.find(av => av.code === currentUiLanguageCode);
            if (avLanguage != null) {
                return avLanguage.id;
            }
        }
        return 0;
    }

    public render() {
        const element = sessionStorage.getItem('fromSso');
        if (globalConfig.loginProperties.isSelfRegistrationEnabled || (globalConfig.loginProperties.isSelfRegistrationEnabledSso && element === 'true') || globalConfig.loginProperties.enableLicenseRegSelfreg) {
            return (

                <form className={'l-form-container user-profile'}>
                    <div key="Title">
                        <Heading headingLevel={1} cssClass="l-box--wide heading__Title" key={'UserProfile_MainTitle'}>
                            {this.state.registrationRequestType !== ERegistrationRequestType.RegistrationByBoss
                                ? <Translate>{'SelfReg:PageTitle'}</Translate>
                                : <Translate>{'UserReg:PageTitle'}</Translate>}
                        </Heading>
                    </div>

                    {this.renderSelfReg()}

                </form>
            )
        } else {
            return (
                <></>
            )
        }

    }

    public renderSelfReg() {
        const methodName = `${this.className}:renderSelfReg()`;
        Logger.log(this.loggerLocality, `${methodName} uiState=${EUiState[this.state.uiState]}`);
        const tr = Session.instance.storage.translation;

        switch (this.state.uiState) {

            case EUiState.Initializing:
                return <></>;

            case EUiState.SingleStepShowForm:
            case EUiState.MultiStep1ShowForm:
            case EUiState.MultiStep2ShowForm:
            case EUiState.RegisterByBossShowForm:
                return <>
                    {globalConfig.captchaProperties.enableCaptcha &&
                        <GoogleReCaptchaProvider
                            ref={this.grcpRef}
                            reCaptchaKey={globalConfig.captchaProperties.siteKey}
                            language={Session.instance.getUserLanguageCodeOrFallBack}>
                            {this.renderSelfRegForm()}
                        </GoogleReCaptchaProvider>
                    }
                    {!globalConfig.captchaProperties.enableCaptcha && this.renderSelfRegForm()}
                </>

            case EUiState.SingleStepSaving:
            case EUiState.MultiStep1Saving:
            case EUiState.RegisterByBossSaving:
                return <div className='selfRegistration__ProgressSpinner'><ProgressSpinner displayMessage={true} message={tr.GetString('SelfReg:CreatingUser')} /></div>

            case EUiState.MultiStep2Saving:
                return <div className='selfRegistration__ProgressSpinner'><ProgressSpinner displayMessage={true} message={tr.GetString('SelfReg:UpdatingUser')} /></div>

            case EUiState.MultiStep1Finished:
                return <Translate>{'SelfReg:Step1Finished'}</Translate>

            case EUiState.MultiStep2LoadingUser:
                return <div className='selfRegistration__ProgressSpinner'><ProgressSpinner displayMessage={true} message={tr.GetString('SelfReg:LoadingUser')} /></div>

            case EUiState.SingleStepLogin:
                return <div className='selfRegistration__ProgressSpinner'><ProgressSpinner displayMessage={true} message={tr.GetString('SelfReg:LoggingInUser')} /></div>

            case EUiState.SingleStepRedirecting:
                if (Session.instance.getReturnUrlAfterLogin && Session.instance.getReturnUrlAfterLogin.length > 0) {
                    const returnUrl = Session.instance.getReturnUrlAfterLogin;
                    Session.instance.setReturnUrlAfterLogin('') // clear return url to prevent a loop
                    return <Redirect push={true} to={returnUrl} />
                } else if (this.props.licenseKey) {
                    return <Redirect push={true} to={globalConfig.licenseReg.returnUrl} />
                } else {
                    return <Redirect push={true} to={globalConfig.selfRegProperties.returnUrl} />
                }

            case EUiState.MultiStep2Redirecting:
                return <Redirect push={false} to="/" />

            case EUiState.RegisterByBossRedirecting:
                return <Redirect push={true} to={`/myTeam/${this.props.bossRelationCode}`} />

            case EUiState.Error: {
                const errorSummary = this.state.statusOfInputControls.filter(c => !c.isValid).map((c, index) =>
                    <React.Fragment key={index}><Translate>{c.label}</Translate>: <Translate>{c.errorMsg}</Translate></React.Fragment>
                );
                return <ErrorSummary key="UserProfile_ErrorSummary" listOfErrors={errorSummary} />
            }
            default:
                return <></>
        }
    }

    private getText(property: string, user: User) {
        const boss = this.props.boss;
        let text: string = "";
        if (boss) {
            switch (property) {
                case "address1":
                    text = boss.address1;
                    user[property] = text;
                    break;
                case "address2":
                    text = boss.address2;
                    user[property] = text;
                    break;
                case "zipCode":
                    text = boss.zipCode;
                    user[property] = text;
                    break;
                case "city":
                    text = boss.city;
                    user[property] = text;
                    break;
                case "company":
                    text = boss.company;
                    user[property] = text;
                    break;
                default:
                    text = user[property] != null ? this.GetAttributeValue(user, property) : '';
                    break;
            }
        }

        return text;
    }

    private renderSelfRegForm() {
        this._lastIndex = 99999
        const user = this.state.user;
        const userGroupedFields: JSX.Element[] = [];

        if (!this.props.bossRelationCode && this.state.registrationRequestType === ERegistrationRequestType.RegistrationByBoss) {
            return <div className="l-box-wide"  ><Alert alertAppereance="box" alertType="error" message="MyTeam:NoAccess" /></div>
        }
        else {

            // Introduction
            switch (this.state.registrationRequestType) {
                case ERegistrationRequestType.RegistrationByBoss:
                    userGroupedFields.push(<React.Fragment key="selfReg_comment"><Translate>{'SelfReg:IntroRegistrationByBoss'}</Translate></React.Fragment>);
                    break;
                case ERegistrationRequestType.SingleStepSelfRegistration:
                    userGroupedFields.push(<React.Fragment key="selfReg_comment"><Translate>{'SelfReg:IntroSingleStep'}</Translate></React.Fragment>);
                    break;
                case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration:
                    userGroupedFields.push(<React.Fragment key="selfReg_comment"><Translate>{'SelfReg:IntroMultiStep1'}</Translate></React.Fragment>);
                    break;
                case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration:
                    userGroupedFields.push(<React.Fragment key="selfReg_comment"><Translate>{'SelfReg:IntroMultiStep2'}</Translate></React.Fragment>);
                    break;
            }

            // Save button and optional error summary
            userGroupedFields.push(<React.Fragment key="userProfile_split"><div className="l-box--wide">&nbsp;</div><div>&nbsp;</div></React.Fragment>)
            this.renderSaveButton(userGroupedFields, true);
            if (this.state.showErrorSummary) {
                const errorsForSummary = this.state.statusOfInputControls.filter(c => !c.isValid).map((c, index) =>
                    <React.Fragment key={index}><Translate>{c.label}</Translate>: <Translate>{c.errorMsg}</Translate></React.Fragment>
                );
                userGroupedFields.push(<ErrorSummary key="UserProfile_ErrorSummary" listOfErrors={errorsForSummary} />);
            }

            // Read configration from globalConfig.userProperties and create fields grouped by titles
            let selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroups;
            switch (this.state.registrationRequestType) {
                case ERegistrationRequestType.RegistrationByBoss:
                    selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroupsBossRegistration;
                    break;
                case ERegistrationRequestType.SingleStepSelfRegistration:
                    selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroups;
                    break;
                case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration:
                    selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroupsStep1;
                    break;
                case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration:
                    selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroupsStep2;
                    break;
            }
            selfRegFieldGroups.map((group) => {
                userGroupedFields.push(
                    <div key={'UserProfile_SubTitle_' + group.title} className="l-box--wide">
                        <h2 className="l-form-container__section-title heading__Level2"><Translate>{group.title}</Translate></h2>
                    </div>)
                group.fields.map((field, index) => {
                    let inputValue: string = '';
                    if (field.type.toLocaleLowerCase() !== 'placeholder' && field.type.toLocaleLowerCase() !== 'redirectlink') {
                        inputValue = this.props.boss != null ? this.getText(field.property, user) : user[field.property] != null ? this.GetAttributeValue(user, field.property) : '';
                    }
                    if (field.type.toLocaleLowerCase() === 'date') {
                        const date = user[field.property];
                        if (date < new Date('1900-01-01')) {
                            inputValue = '';
                        }
                    }
                    const hasFocus: boolean = (field.hasFocus !== undefined && this.state.isFormValid) ? field.hasFocus : false;
                    const key = 'UserProfile_Property' + field.property;

                    // If password confirmation fails -> set errorMessage property of InputPassword control. This will overwrite any
                    // input validation error detected by the control itself.
                    let errorMessage: string | undefined;
                    if ((field.property.toLocaleLowerCase() === 'password') || (field.property.toLocaleLowerCase() === 'confirmpassword')) {
                        const passwordInputIndex = this.state.statusOfInputControls.findIndex(i => i.id === 'password');
                        const errMsg = this.state.statusOfInputControls[passwordInputIndex].errorMsg;
                        if (errMsg != '') {
                            errorMessage = errMsg;
                        }
                    }

                    userGroupedFields.push(
                        <GenericInput
                            key={key}
                            type={field.type as GenericInputTypes}
                            id={field.property}
                            label={field.label}
                            value={inputValue}
                            class={field.class}
                            attributeValueType={field.attributeValueType}
                            isReadOnly={field.readonly}
                            isRequired={field.required}
                            onTextChange={(id, value, errorMsg, xy) => this.onTextChange(id, value, errorMsg, xy)}
                            onCheckboxChange={(id, value, errorMsg) => this.onCheckboxChange(id, value, errorMsg)}
                            regExpression={field.regExpression}
                            url={field.url}
                            hasFocus={hasFocus}
                            editMode={!field.readonly}
                            initialValidation={true}
                            fieldIndex={index}
                            validationOptions={field.validationOptions}
                            errorMessage={errorMessage}
                        />
                    )
                })
            });

            // Group Selection if registration by boss
            this.state.registrationRequestType === ERegistrationRequestType.RegistrationByBoss && userGroupedFields.push(this.renderGroupSelection());

            // Optional terms&conditions approval and save button
            userGroupedFields.push(<React.Fragment key="userProfile_split2"><div className="l-box--wide">&nbsp;</div><div>&nbsp;</div></React.Fragment>);
            if (this.state.uiState === EUiState.SingleStepShowForm || this.state.uiState === EUiState.MultiStep1ShowForm || this.state.uiState === EUiState.RegisterByBossShowForm) {
                userGroupedFields.push(this.renderTCApprovalSection());
            }
            this.renderSaveButton(userGroupedFields, false);

            return userGroupedFields;
        }
    }

    // adds the group to the user
    private renderGroupSelection() {
        const checkBoxList: JSX.Element[] = [];
        const loginUserGroups = Session.instance.loginUser?.groups.filter(g => !g.isDynamic && g.groupTypeId === 0);
        loginUserGroups?.forEach(g => {
            checkBoxList.push(
                <div className="selfRegistratuion__checkbox-container">
                    <Checkbox
                        defaultChecked={true}
                        onChange={e => this.setGroups(e, g)}
                    />
                    <span className="selfRegistratuion__checkbox-element">{g.groupName}</span>
                </div>)
        });
        return (<>
            <div className="l-box-wide">
                <div className="selfRef-dsgvo__title">
                    <Heading headingLevel={4} cssClass="l-box--wide" >
                        <Translate>{Session.instance.storage.translation.GetString('SelfReg:GroupSelection')}</Translate>
                    </Heading>
                </div>
                <div className="selfRef-dsgvo__description-box">
                    {Session.instance.storage.translation.GetString('SelfReg:GroupSelectionDescription')}
                </div>
                {checkBoxList}
            </div>
        </>);
    }

    private setGroups(e: CheckboxChangeEvent, group: Group) {
        if (e.value === true) {
            !this.state.selectedGroups?.includes(group) && this.state.selectedGroups?.push(group);
        }
        else {
            this.state.selectedGroups?.includes(group) && this.setState({ selectedGroups: this.state.selectedGroups.filter(g => g !== group) });
        }
    }

    private renderTCApprovalSection() {
        if (this.state.isGdprEnabled) {
            return (
                <div className="selfReg-dsgvo__container" key="TCApprovalSection">
                    <div className="selfRef-dsgvo__title">
                        <Heading headingLevel={4} cssClass="l-box--wide" >
                            <Translate>{Session.instance.storage.translation.GetString('SelfReg:DSGVO_Title')}</Translate>
                        </Heading>
                    </div>
                    <div className="selfRef-dsgvo__description-box">
                        {Session.instance.storage.translation.GetString('SelfReg:DSGVO_Description')}
                        <button
                            className="button button-small button-secondary"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.downloadTCDocument(e)}>
                            {' ' + Session.instance.storage.translation.GetString('SelfReg:DSGVO_URL')}
                        </button>
                    </div>
                    <div>
                        <Checkbox
                            defaultChecked={this.state.isTCchecked}
                            onChange={(e) => this.acceptTermsAndConditions(e)} />
                        <span className="selfRef-dsgvo__checkbox-text" dangerouslySetInnerHTML={{__html: Session.instance.storage.translation.GetString('SelfReg:DSGVO_UserAccept')}}></span>
                    </div>
                </div>
            )
        } else {
            return (<></>)
        }
    }

    /**
     * Loads parameter form url and prefills the configured input fields (selfRegProperties) if:
     * 1. configured input field is not read only
     * 2. the field value is not already set by the first step of registration
     *
     * @private
     * @param {User} user user form the first step of registration (or empty user)
     * @return {*}  Returns updated user by url parameters
     * @memberof SelfRegistration
     */
    private loadUrlParameter(user: User): User {
        const parameters = new URLSearchParams(window.location.search);

        // Read configration from globalConfig.userProperties
        let selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroups;
        switch (this.state.registrationRequestType) {
            case ERegistrationRequestType.RegistrationByBoss:
                selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroupsBossRegistration;
                break;
            case ERegistrationRequestType.SingleStepSelfRegistration:
                selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroups;
                break;
            case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration:
                selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroupsStep1;
                break;
            case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration:
                selfRegFieldGroups = globalConfig.selfRegProperties.fieldGroupsStep2;
                break;
        }

        selfRegFieldGroups.map((group) => {
            group.fields.map((field) => {
                if (!field.readonly) {
                    let parameter = parameters.get(field.property);

                    switch (field.type.toLocaleLowerCase()) {
                        case 'placeholder':
                        case 'redirectLink':
                        case 'password':
                            break;
                        case 'date': {
                            const date = user[field.property];
                            if (date < new Date('1900-01-01') && parameter != null) {
                                // Allows dates following these rules: - days may have leading zeros. 1-31. max 2 digits - months may have leading zeros. 1-12. max 2 digits - years 1900-2099 4 digits
                                const dateRegExpPoint = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g;
                                if (parameter != null && dateRegExpPoint.test(parameter)) {
                                    const dateArray = parameter.split('.');
                                    parameter = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
                                }
                                // Allows dates following these rules: - days may have leading zeros. 1-31/ max 2 digits - months may have leading zeros. 1-12/ max 2 digits - years 1900-2099 4 digits
                                const dateRegExpSlash = /^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/g;
                                if (parameter != null && dateRegExpSlash.test(parameter)) {
                                    const dateArray = parameter.split('/');
                                    parameter = dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1];
                                }
                                user[field.property] = (parameter != null && !isNaN(Date.parse(parameter))) && new Date(parameter);
                            }
                            break;
                        }
                        case 'select': {
                            if ((user[field.property] == null || user[field.property] === 0) && parameter != null && !isNaN(Number.parseInt(parameter))) {
                                user[field.property] = Number.parseInt(parameter);
                            }
                            break;
                        }
                        default: {
                            if ((user[field.property] == null || user[field.property] === '') && parameter != null) {
                                user[field.property] = parameter;
                            }
                        }
                    }
                }
            })
        });

        return user;
    }

    private acceptTermsAndConditions(e: CheckboxChangeEvent) {
        if (e.value && this.state.tcDocument !== undefined) {
            const termsAndConditions = this.state.tcDocument;
            termsAndConditions.approvalStatus = ETCApprovalStatus.Accepted;
            this.setState({
                isTCchecked: e.value,
                tcDocument: termsAndConditions
            })
        } else {
            this.setState({ isTCchecked: e.value });
        }
    }

    private downloadTCDocument(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        const tc = this.state.tcDocument;
        if (tc !== undefined && tc.document) {
            PrivacyService.instance.downloadTermsAndConditions(tc.document);
        }
    }

    private renderSaveButton(userGroupedFields: JSX.Element[], isBeginningOfForm: boolean) {
        const uid = Guid.newGuid();
        const disabled = (this.state.isGdprEnabled && !this.state.isTCchecked) || !this.state.isFormValid;
        userGroupedFields.push(
            <div
                key={`UserProfile_SaveButton_${uid}${isBeginningOfForm ? '_Top' : '_Bottom'}`}
                id={'btnContainer_' + uid}
                className={'user-detail__button-container ' +
                    (isBeginningOfForm ? 'user-detail__button-container--start' : 'user-detail__button-container--end')}>
                <button
                    disabled={disabled}
                    className="button button-small marginRight-5"
                    onClick={(e) => { e.preventDefault(); this.save(); this.setState({ saving: true }); }} >
                    <Translate>{'Button:Save'}</Translate>
                </button>
            </div>
        )
    }

    private async save() {
        this.state.selectedGroups?.forEach(g => !this.state.user.groups.includes(g) && this.state.user.groups.push(g));  // No Dynamic Groups and no BossEmployee Groups should be able to select.
        const methodName = `${this.className}:save()`;
        let captchaToken = ''; // this.state.captchaToken;
        if (globalConfig.captchaProperties.enableCaptcha) {
            captchaToken = (await (this.grcpRef as RefObject<GoogleReCaptchaProvider>).current?.executeRecaptcha()) as string;
        }
        this.setState({ showErrorSummary: true });
        if (this.state.user !== null && this.state.isFormValid) {
            let nextUIState = EUiState.SingleStepSaving;
            if (this.state.registrationRequestType === ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration) {
                nextUIState = EUiState.MultiStep1Saving;
            }
            else if (this.state.registrationRequestType === ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration) {
                nextUIState = EUiState.MultiStep2Saving;
            }
            else if (this.state.registrationRequestType === ERegistrationRequestType.RegistrationByBoss) {
                nextUIState = EUiState.RegisterByBossSaving;
            }
            const prevUiStatus = this.state.uiState;
            const status = _.cloneDeep(this.state.statusOfInputControls);
            this.setState({ uiState: nextUIState });

            const defaultBossRelation = Session.instance.getLoggedInUserBossrelations().find(r => r.isDefault);
            let bossRelationCode = this.props.bossRelationCode;
            if (bossRelationCode === 'default' && defaultBossRelation != null) {
                bossRelationCode = defaultBossRelation.bossTypeCode;
            }

            if ((this.state.isGdprEnabled && this.state.tcDocument !== undefined && this.state.isTCchecked) || (!this.state.isGdprEnabled)) {
                const regNewUser = new RegisterNewUserRequest()
                regNewUser.registrationRequestType = this.state.registrationRequestType;
                regNewUser.termsAndConditionApprovalLanguage = Session.instance.getUserLanguageCodeOrFallBack;
                regNewUser.password = this.state.password;
                regNewUser.bossEmployeeRelationCode = bossRelationCode ? bossRelationCode : '';
                regNewUser.isAnonymousUser = this.state.isUserAnonymous;
                regNewUser.user = this.state.user;
                regNewUser.userTCApprovalStatus = this.state.tcDocument;
                regNewUser.tokenForSecondStep = this.state.registrationRequestType === ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration ? this.state.tokenForSecondStep : "";
                regNewUser.licenseKey = this.props.licenseKey ?? '';
                Logger.log(this.loggerLocality, `${methodName} calling userRegistration/registerNewUser with registrationRequestType=${regNewUser.registrationRequestType}.`);
                const regNewUserResponse = await UserService.instance.registerNewUser(regNewUser, captchaToken);
                if (isSuccess<RegisterNewUserResponse>(regNewUserResponse)) {
                    switch (this.state.registrationRequestType) {

                        case ERegistrationRequestType.RegistrationByBoss:
                            Logger.log(this.loggerLocality, `${methodName} RegistrationByBoss self-registration for user ${regNewUserResponse.user?.domainName}\\${regNewUserResponse.user?.username} was successful.`);
                            this.setState({ uiState: EUiState.RegisterByBossRedirecting });
                            break;

                        case ERegistrationRequestType.SingleStepSelfRegistration:
                            Logger.log(this.loggerLocality, `${methodName} single-step self-registration for user ${regNewUserResponse.user?.domainName}\\${regNewUserResponse.user?.username} was successful.`);
                            this.setState({ uiState: EUiState.SingleStepLogin });
                            if (regNewUserResponse.user != null) {
                                await this.doLogin(regNewUserResponse.user.domainName, regNewUserResponse.user.username, this.state.password);
                            }
                            break;

                        case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration:
                            Logger.log(this.loggerLocality, `${methodName} 1st step of self-registration for user ${regNewUserResponse.user?.domainName}\\${regNewUserResponse.user?.username} was successful.`);
                            this.setState({ uiState: EUiState.MultiStep1Finished });
                            break;

                        case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration:
                            Logger.log(this.loggerLocality, `${methodName} 2nd step of self-registration for user ${regNewUserResponse.user?.domainName}\\${regNewUserResponse.user?.username} was successful.`);
                            // Save username in local storage so it will be used as default for login.
                            localStorage.setItem(this.localStorageKeyForSavedUsername, this.getUsernameForLogin(regNewUserResponse.user));
                            this.setState({ uiState: EUiState.MultiStep2Redirecting });
                            break;
                    }
                    return;
                } else {
                    if (regNewUserResponse.detailedObject != undefined || regNewUserResponse.detailedObjects != undefined) {
                        if (regNewUserResponse.detailedObject != undefined && !Array.isArray(regNewUserResponse.detailedObject)) {
                            const errorStatus: IStatusOfInputControl[] = [];
                            const stringId = CustomErrorMessage.getErrorCodeMessageString(regNewUserResponse.detailedObject.subStatusCode);
                            errorStatus.push(this.createErrorStatus(stringId));
                            status.push(...errorStatus);
                            this.setState({ uiState: prevUiStatus, statusOfInputControls: status });
                        }
                        if (regNewUserResponse.detailedObjects != undefined) {
                            const errorStatus = this.processErrorsFromServer(regNewUserResponse.detailedObjects);
                            status.push(...errorStatus);
                            this.setState({ uiState: prevUiStatus, statusOfInputControls: status });
                        }
                    } else {
                        status.push(this.createErrorStatus('ErrorMessage:SelfRegistrationFailed'));
                    }
                }
            } else {
                status.push(this.createErrorStatus('ErrorMessage:SelfRegistrationTCCheckFailed'));
                if (!this.state.tcDocument) {
                    Logger.log(this.loggerLocality, `${methodName} TCDocument is undefined, check if a GDPR document exists.`);
                }
                this.setState({ uiState: EUiState.Error, statusOfInputControls: status });
            }
        }
    }

    private processErrorsFromServer(errors: CustomErrorMessage[]): IStatusOfInputControl[] {
        const errorStatus: IStatusOfInputControl[] = [];
        if (errors !== undefined) {
            for (const error of errors) {
                const stringId = CustomErrorMessage.getErrorCodeMessageString(error.subStatusCode);
                errorStatus.push(this.createErrorStatus(stringId));
            }
        }
        return errorStatus;
    }

    private async doLogin(domain: string, username: string, password: string) {
        if (this.state.user != null) {
            const status = _.cloneDeep(this.state.statusOfInputControls);

            const loginOk = await Session.instance.login(domain, username, password, false);
            if (isSuccess<boolean>(loginOk)) {
                if (loginOk) {
                    if (this.props.onSaveUser) {
                        this.props.onSaveUser(true);
                    } else {
                        this.setState({ uiState: EUiState.SingleStepRedirecting });
                    }
                    return;
                } else {
                    status.push(this.createErrorStatus('ErrorMessage:LoginAfterSelfRegFailed'));
                }
            } else {
                status.push(this.createErrorStatus('ErrorMessage:LoginAfterSelfRegFailed'));
            }

            this.setState({ uiState: EUiState.Error, statusOfInputControls: status });
        }
    }

    private getUsernameForLogin(user: User | undefined): string {
        let username = '';
        if (user != null) {
            const defaultDomain = globalConfig.loginProperties ? globalConfig.loginProperties.defaultDomain ? globalConfig.loginProperties.defaultDomain : "" : "";
            if (user.domainName.toLocaleLowerCase() !== defaultDomain.toLocaleLowerCase()) {
                username = `${user.domainName}\\${user.username}`;
            } else {
                username = user.username;
            }
        }
        return username;
    }

    // tslint:disable:jsdoc-format
    /**
      * Callback for input controls: value has changed.
      * @param id: Id of the input control triggering this callback.
      * @param value: Current value from input control.
      * @param errorMsg: Any error messages detected by input control (e.g. input validation error).
      */
    private onTextChange(id: string, value: string, errorMsg: string, setFocus?: ISetFocus) {

        if (this.state.user !== null) {

            const user = _.cloneDeep(this.state.user);
            // Do not clone array, otherwise SetState() will not change the state - for whatever reason.
            const statusOfInputControls = this.state.statusOfInputControls.filter(s => s.id !== '');

            // Update specific user property with new value from input control.
            user[id] = value;

            // Update input control's status, i.e. update status properties .isValid and .errorMsg for the 
            // control whose input has been changed.
            // Remark: The validation of the input (check for empty values and/or Regex validation) has already 
            // been performed by the input control itself. In case of an error, the callback parameter errorMsg
            // contains the corresponding error string ID.
            let password = this.state.password;
            let confirmPassword = this.state.confirmPassword;
            if (id === 'password') {
                password = value;
                if (password.length > 0 || confirmPassword.length > 0) {
                    errorMsg = (password != confirmPassword) ? 'ErrorMessage:ConfirmPasswordNotMatching' : '';
                }
                this.updateStatusOfInputControl(statusOfInputControls, 'password', errorMsg);
                this.updateStatusOfInputControl(statusOfInputControls, 'confirmPassword', errorMsg);
            } else if (id === 'confirmPassword') {
                confirmPassword = value
                if (confirmPassword.length > 0 || password.length > 0) {
                    errorMsg = (password != confirmPassword) ? 'ErrorMessage:ConfirmPasswordNotMatching' : '';
                }
                this.updateStatusOfInputControl(statusOfInputControls, 'password', errorMsg);
                this.updateStatusOfInputControl(statusOfInputControls, 'confirmPassword', errorMsg);
            } else {
                // Other input control, i.e. not password and not confirmPassword.
                this.updateStatusOfInputControl(statusOfInputControls, id, errorMsg);
            }

            // Check, whether any input control is invalid and try to set focus on it.
            let isFormValid = true;
            if (statusOfInputControls.some(i => i.isValid === false)) {
                // Mark form as invalid, because one or more controls are invalid                    
                isFormValid = false;
                if (setFocus !== undefined && setFocus.idx < this._lastIndex) {
                    if (setFocus.innerRef !== undefined && setFocus.innerRef.current !== null) {
                        this._lastIndex = setFocus.idx
                        setFocus.innerRef.current.focus()
                    }
                }
            }

            // Update state.
            this.setState(() => {
                return {
                    user: user,
                    statusOfInputControls: statusOfInputControls,
                    isFormValid: isFormValid,
                    password,
                    confirmPassword,
                }
            });
        }
    }

    private updateStatusOfInputControl(status: IStatusOfInputControl[], controlId: string, errorMsg: string): void {
        const index = status.findIndex(i => i.id === controlId);
        status[index].isValid = errorMsg.length === 0 ? true : false;
        status[index].errorMsg = errorMsg;
    }

    /**
      * Callback for input(boolean) component: value has changed.
      * @param id: Id of the Input triggering this callback.
      * @param value: Current value from Input control.
      * @param errorMsg: Any error messages (Validation). 
     */
    private onCheckboxChange(id: string, value: boolean, errorMsg: string) {

        if (this.state.user !== null) {

            const user = _.cloneDeep(this.state.user);
            const status = _.cloneDeep(this.state.statusOfInputControls.filter(s => s.id !== ''));

            // Update specific user attribute (new value)
            user[id] = value;

            // Get affected control reference (current input control [user attribute])
            const inputIndex = status.findIndex(i => i.id === id);
            const statusInput: IStatusOfInputControl = status[inputIndex];

            // Update affected control values
            statusInput.isValid = errorMsg.length === 0 ? true : false;
            statusInput.errorMsg = errorMsg;

            // Update state of status panel                
            status[inputIndex] = statusInput;

            // Check, whether any input controlis invalid
            let isFormValid = true;
            if (status.some(i => i.isValid === false)) {
                isFormValid = false;
            }

            // Refresh state
            user[id] = value;
            this.setState(() => {
                return {
                    user: user,
                    statusOfInputControls: status,
                    isFormValid: isFormValid,
                }
            });
        }
    }

    private createErrorStatus(errorMessageStringId: string): IStatusOfInputControl {
        return {
            errorMsg: errorMessageStringId,
            id: '',
            isValid: false,
            label: 'ErrorMessage:Error'
        };
    }

    private GetAttributeValue(user: User, propertyName: string) {
        const temp = user[propertyName]
        if (temp instanceof Date) {
            return temp.toISOString()
        }
        return temp.toString()
    }

    private getRegistrationRequestType(): ERegistrationRequestType {
        let mode = Session.instance.isBossAllowedToRegisterNewUsers() ? ERegistrationRequestType.RegistrationByBoss : ERegistrationRequestType.SingleStepSelfRegistration;
        if (globalConfig.selfRegProperties.registrationMode.toLowerCase() === 'twosteps' && mode !== ERegistrationRequestType.RegistrationByBoss) {
            if (this.getTokenFromUrl() != '') {
                mode = ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration;
            } else {
                mode = ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration;
            }
        }
        return mode;
    }

    private getFormfields() {
        // Build arry with 
        const formfields: IStatusOfInputControl[] = [];
        switch (this.state.registrationRequestType) {
            case ERegistrationRequestType.FirstStepOfTwoStepSelfRegistration:
                globalConfig.selfRegProperties.fieldGroupsStep1.map(c => {
                    c.fields.map(f =>
                        formfields.push({ id: f.property, label: f.label, isValid: true, errorMsg: '' }),
                    )
                });
                break;
            case ERegistrationRequestType.SecondStepOfTwoStepSelfRegistration:
                globalConfig.selfRegProperties.fieldGroupsStep2.map(c => {
                    c.fields.map(f =>
                        formfields.push({ id: f.property, label: f.label, isValid: true, errorMsg: '' }),
                    )
                });
                break;
            case ERegistrationRequestType.SingleStepSelfRegistration:
                globalConfig.selfRegProperties.fieldGroups.map(c => {
                    c.fields.map(f =>
                        formfields.push({ id: f.property, label: f.label, isValid: true, errorMsg: '' }),
                    )
                });
                break;
            case ERegistrationRequestType.RegistrationByBoss:
                globalConfig.selfRegProperties.fieldGroupsBossRegistration.map(c => {
                    c.fields.map(f =>
                        formfields.push({ id: f.property, label: f.label, isValid: true, errorMsg: '' }),
                    )
                });
                break;
        }
        this.setState({ statusOfInputControls: formfields });
    }

    private getTokenFromUrl(): string {
        const parameters = new URLSearchParams(window.location.search);
        const token = parameters.get('p');
        return token != null && token.length > 0 ? token : '';
    }

    private async getUserFromFistStep(token: string) {
        const user = await UserService.instance.getUserFromFistStep(token);
        if (isSuccess<User>(user)) {
            return user;
        } else {
            const status = _.cloneDeep(this.state.statusOfInputControls);
            status.push(this.createErrorStatus('ErrorMessage:RegisterNewUser_InvalidTokenForSecondStep'))
            this.setState({ uiState: EUiState.Error, statusOfInputControls: status });
            return null;
        }
    }
}
