import { EClassStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class ClassStatusConverter implements JsonCustomConvert<EClassStatus> {
    // from WebApi Models\Enums.cs: Undefined=0, Open=1, Reserved=2, TakePlace=3, Closed=4, Cancelled=5
    public serialize(lessonStatus: EClassStatus): number {
        switch (lessonStatus) {
            case EClassStatus.Undefined: return 0;
            case EClassStatus.Open: return 1;
            case EClassStatus.Reserved: return 2;
            case EClassStatus.TakesPlace: return 3;
            case EClassStatus.Closed: return 4;
            case EClassStatus.Cancelled: return 5;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EClassStatus {
        switch (jsonValue) {
            case 0: return EClassStatus.Undefined;
            case 1: return EClassStatus.Open;
            case 2: return EClassStatus.Reserved;
            case 3: return EClassStatus.TakesPlace;
            case 4: return EClassStatus.Closed;
            case 5: return EClassStatus.Cancelled;
            default: return EClassStatus.Undefined;
        }
    }
}
