import { ELessonFilterContext } from '$storage/models/enums';
import DateHelper from '$util/DateHelper';

export default class MyLessonFilter  {
    public maxDisplayItems: number;
    public itemType: number;
    public lessonStatus: number;
    public excludeCompleted: boolean;
    public registrationStatus: number;
    public dateFrom: Date | null;
    public dateTo: Date | null;
    public itemSubType: number;
    public title: string;

    constructor(lessonFilterContext: ELessonFilterContext) {
        const configResultCountSteps = globalConfig.myLessonsProperties.filter_numberOfResultSteps;
        this.maxDisplayItems = configResultCountSteps.length > 0 ? configResultCountSteps[0] : 10;
        this.itemType = 0
        this.lessonStatus = 0
        this.excludeCompleted = lessonFilterContext === ELessonFilterContext.MyTrainingPlans ? true : false
        this.registrationStatus = 0
        this.dateFrom =  DateHelper.addDays( new Date(), -globalConfig.myLessonsProperties.filter_lastUsedDays)
        this.dateTo = null
        this.itemSubType = 0
    }

}