import React from 'react';
import GTWhitePanel from '$components/shared/Atoms/GTWhitePanel';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

export default function InitialCodeCheck() {

    const [code, setCode] = React.useState('');
    const [isValid, setIsValid] = React.useState(false);

    const handleInitValidation = (code: string) => {
        setCode(code);
        setIsValid(true);
    }

    return (
        <div className="login__background">
            <div className="l-container">
                <GTWhitePanel>
                    {isValid ?
                        <SecondStep initialCode={code} onGoBack={() => setIsValid(false)} /> :
                        <FirstStep onValidateInitialCode={(code) => handleInitValidation(code)} />}
                </GTWhitePanel>
            </div>
        </div>
    );
}