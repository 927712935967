import { Attribute } from '$storage/models/Attribute';
import { ClassStatusConverter } from '$storage/models/converters/ClassStatusConverter';
import { DateTimeConverter } from '$storage/models/converters/DateTimeConverter';
import { ECancellationPermission, EClassStatus, ERegistrationStatus } from '$storage/models/enums';
import { F2FClassDetail } from '$storage/models/F2F/F2FClassDetail';
import { JsonObject, JsonProperty } from 'json2typescript';
import { computed } from 'mobx';
import { Registration } from '$storage/models/Registration';

@JsonObject
export class TrainingPlanSchedule {

    @JsonProperty('sceId', Number, false)
    public sceId: number = 0;

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('guid', String, false)
    public guid: string = '';

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('code', String, false)
    public code: string = '';

    @JsonProperty('description', String, false)
    public description: string = '';

    @JsonProperty('registrationBegin', DateTimeConverter, false)
    public registrationBegin: Date | undefined = undefined;

    @JsonProperty('registrationEnd', DateTimeConverter, false)
    public registrationEnd: Date | undefined = undefined;

    @JsonProperty('runningBegin', DateTimeConverter, false)
    public runningBegin: Date | undefined = undefined;

    @JsonProperty('runningEnd', DateTimeConverter, false)
    public runningEnd: Date | undefined = undefined;

    @JsonProperty('isAutoRegister', Boolean, false)
    public isAutoRegister: boolean = false;

    @JsonProperty('registrationGroup', Number, false)
    public registrationGroup: number = 0;

    @JsonProperty('status', ClassStatusConverter, true)
    public status: EClassStatus = EClassStatus.Undefined;

    @JsonProperty('cancellationDate1', DateTimeConverter, false)
    public cancellationDate1: Date | undefined = undefined;

    @JsonProperty('cancellationDate2', DateTimeConverter, false)
    public cancellationDate2: Date | undefined = undefined;

    /** checkingDateOfStartCondition of the corresponding class */
    @JsonProperty('checkingDateOfStartCondition', DateTimeConverter, false)
    public checkingDateOfStartCondition: Date | undefined = undefined;

    @JsonProperty('closingDate', DateTimeConverter, false)
    public closingDate: Date | undefined = undefined;

    @JsonProperty('takesPlaceDate', DateTimeConverter, false)
    public takesPlaceDate: Date | undefined = undefined;

    @JsonProperty('reservedDate', DateTimeConverter, false)
    public reservedDate: Date | undefined = undefined;

    @JsonProperty('minRegistrations', Number, false)
    public minRegistrations: number = 0;

    @JsonProperty('maxRegistrations', Number, false)
    public maxRegistrations: number = 0;

    /** All registrations for current user in the past, present and future. */
    @JsonProperty('allRegistrations', [Registration], false)
    public allRegistrations: Registration[] | null = null;

    @JsonProperty('waitingListMode', Number, false)
    public waitingListMode: number = 0;

    @JsonProperty('price', Number, false)
    public price: number = 0;

    @JsonProperty('currency', String, false)
    public currency: string = '';

    @JsonProperty('priceInfo', String, false)
    public priceInfo: string = '';

    @JsonProperty('costCenter', String, false)
    public costCenter: string = '';

    @JsonProperty('autoRegistrationActive', Boolean, false)
    public autoRegistrationActive: boolean = false;

    @JsonProperty('autoRegistrationClassDetails', [F2FClassDetail], false)
    public autoRegistrationClassDetails: F2FClassDetail[] | undefined = undefined;

    /** List of all (custom) attributes in selected language or in default language. */
    @JsonProperty('attributes', [Attribute], false)
    public attributes: Attribute[] = [];

    @JsonProperty('hasRegistrationRight', Boolean, false)
    public hasRegistrationRight: boolean = false;

    @JsonProperty('freePlaces', Number, false)
    public freePlaces: number = 0;

    @computed
    public get availablePlaces(): number | undefined {
        if (this.maxRegistrations === 0) {
            return undefined;
        } else {            
            return this.freePlaces;
        }
    }

    public get isRegistrationAllowedAndStatusIsNotRequested(): boolean {
        if (((this.availablePlaces !== undefined && this.availablePlaces > 0)|| this.availablePlaces === undefined || this.waitingListMode > 0) 
                && (this.registrationBegin != null && new Date() >= this.registrationBegin)
                && (this.registrationEnd != null && new Date() <= this.registrationEnd)
                && (this.status !== EClassStatus.Cancelled) 
                && (this.status !== EClassStatus.Closed)
                && !this.isUserAlreadyRegisteredAndStatusIsNotRequested()) 
                {
            return true;
        }
        return false;
    }

    public get isRegistrationAllowed(): boolean {
        if (((this.availablePlaces !== undefined && this.availablePlaces > 0)|| this.availablePlaces === undefined || this.waitingListMode > 0) 
                && (this.registrationBegin != null && new Date() >= this.registrationBegin)
                && (this.registrationEnd != null && new Date() <= this.registrationEnd)
                && (this.status !== EClassStatus.Cancelled) 
                && (this.status !== EClassStatus.Closed)
                && !this.isUserAlreadyRegistered()) 
                {
            return true;
        }
        return false;
    }

    public get isInRegistrationPeriod(): boolean {
        return this.registrationBegin !== undefined && this.registrationBegin <= new Date()
            && this.registrationEnd !== undefined && this.registrationEnd >= new Date();
    }

    public isUserAlreadyRegistered() {
        if (this.allRegistrations != null && this.allRegistrations.filter(reg => 
            (reg.registrationStatus !== ERegistrationStatus.Cancelled &&
                reg.registrationStatus !== ERegistrationStatus.ScheduleCancelled &&
                reg.registrationStatus !== ERegistrationStatus.NotRegistered)).length > 0) {
            return true;
        }
        return false;
    }

    public isUserAlreadyRegisteredAndStatusIsNotRequested() {
        if (this.allRegistrations != null && this.allRegistrations.filter(reg => 
            (reg.registrationStatus !== ERegistrationStatus.Cancelled &&
                reg.registrationStatus !== ERegistrationStatus.ScheduleCancelled &&
                reg.registrationStatus !== ERegistrationStatus.Requested &&
                reg.registrationStatus !== ERegistrationStatus.NotRegistered)).length > 0) {
            return true;
        }
        return false;
    }

    public get isCancellationAllowed(): ECancellationPermission{
        if(this.isInCancellationPeriod1){
            return ECancellationPermission.Allowed;
        }else if(this.isInCancellationPeriod2){
            return ECancellationPermission.WithWarning;
        }else if(this.hasNoCancellationPeriod){
            return this.status === EClassStatus.Closed ? ECancellationPermission.NotAllowed : ECancellationPermission.Allowed
        }
        return ECancellationPermission.NotAllowed;
    }


    /**
     * Returns true if today is before cancellationPeriod1 Date
     */
    private get isInCancellationPeriod1(): boolean {
        return this.cancellationDate1 !== undefined && this.cancellationDate1 > new Date();
    }

    /**
     * Returns true if today is before the cancellation Period 2 Date
     */
    private get isInCancellationPeriod2(): boolean {
        if (this.cancellationDate2 === undefined) {
            return false;
        } else if (new Date() > this.cancellationDate2) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Returns true if the item has no cancellation period specified
     */
    private get hasNoCancellationPeriod(): boolean {
        return (this.cancellationDate1 == null && this.cancellationDate2 == null) 
    }

    /**
     * Returns true if today is before the checking date of start conditions
     */
    public get cheatStartcondition(): boolean {
        return this.checkingDateOfStartCondition != undefined && this.checkingDateOfStartCondition >= new Date();
    }
}