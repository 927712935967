import Logger from "$src/core/Logger";
import MultiFactorAuthenticationService from "$src/core/Services/MultiFactorAuthenticationService";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import CustomErrorMessage from "$src/util/CustomErrorMessage";
import { isSuccess } from "$src/util/Result";
import React, { useEffect } from "react";
import GTButton from "$components/shared/Atoms/GTButton";
import { GenericInput } from "$components/shared/GenericInput";
import { Heading } from "$components/shared/Heading";
import { ProgressSpinner } from "$components/shared/ProgressSpinner";
import { Translate } from "$components/shared/Translate";
import { ErrorMessage } from "$components/shared/WarningsAndErrors/ErrorMessage";
import { SsoButtonContainer } from "$components/login/SsoButtonContainer";
import { Redirect, useHistory, useLocation, useRouteMatch } from "react-router";
import ThematicBreak from "$components/shared/Atoms/ThematicBreak";
import SsoServiceProviderService from "$src/core/Services/SsoServiceProviderService";

interface IProps {
    onValidateInitialCode?: (code: string) => void;
}

export default function FirstStep(props: IProps) {
    const loggerLocality = 'InitialCodeCheck';

    const [code, setCode] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [checkCode, setCheckCode] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSSOEnabled, setIsSSOEnabled] = React.useState(globalConfig.initicalCodeCheck.isSSOEnabled && SsoServiceProviderService.instance.isAnySsoEnabled());
    const [isSSOLoginSuccessfull, setIsSSOLoginSuccessfull] = React.useState(false);

    useEffect(() => {
        async function fetchData() {
            await MultiFactorAuthenticationService.instance.checkInitialCode(code, globalConfig.loginProperties.defaultDomain)
                .then((result) => {
                    setIsLoading(false);
                    setCheckCode(false);
                    if (isSuccess<BooleanResponse>(result)) {
                        if (result.status) {
                            setErrorMessage('');
                            if (props.onValidateInitialCode) {
                                props.onValidateInitialCode(code);
                            }
                        } else {
                            setErrorMessage('ErrorMessage:InitialCodeCheckFailed');
                            Logger.log(loggerLocality, `failed to check code`);
                            console.error(`${loggerLocality} failed to check code`);
                        }
                    }
                    else {
                        if (result.detailedObject) {
                            setErrorMessage(CustomErrorMessage.getErrorCodeMessageString(result.detailedObject.subStatusCode));
                        } else {
                            setErrorMessage('ErrorMessage:InitialCodeCheckFailed');
                        }
                        Logger.log(loggerLocality, `failed to check code`);
                        console.error(`${loggerLocality} failed to check code`);
                    }
                });
        }

        if (checkCode && code.length > 0) {
            setIsLoading(true);
            setErrorMessage('');
            fetchData();
        }
    }, [checkCode]);


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setCheckCode(true);
    }

    function renderSsoOptions(): JSX.Element | null {
        const props = {
            history: useHistory(),
            match: useRouteMatch(),
            location: useLocation()
        };

        return (
            <div className="l-container--center">
                <div className="l-box__input">
                    <Heading headingLevel={1} cssClass="heading__Level4">
                        <Translate>MFA:InitialCodeCheckTitleSSO</Translate>
                    </Heading>
                    <div className="text">
                        <Translate>MFA:InitialCodeCheckSSOHint</Translate>
                    </div>
                </div>
                <SsoButtonContainer
                    {...props}
                    stopAutoSso={true}
                    onDoShowLoginForSso={() => setIsSSOEnabled(true)}
                    onLoggedIn={() => setIsSSOLoginSuccessfull(true)} />
            </div>
        );
    }

    return (
        <>
            {isSSOLoginSuccessfull ? <Redirect to="/" /> :
                <>
                    {isLoading ?
                        <div className="l-container--center">
                            <ProgressSpinner />
                        </div> :
                        <div className="l-container--center">
                            <div className="l-box__input">
                                <Heading headingLevel={1} cssClass="heading__Level4">
                                    <Translate>MFA:InitialCodeCheckTitle</Translate>
                                </Heading>
                                <div className="text">
                                    <Translate>MFA:InitialCodeCheckHint</Translate>
                                </div>
                            </div>
                            <form onSubmit={(event) => handleSubmit(event)}>
                                <GenericInput
                                    type='text'
                                    id='initialCode'
                                    label='MFA:InitialCodeCheckLabel'
                                    value={code}
                                    isReadOnly={false}
                                    isRequired={true}
                                    hasFocus={true}
                                    editMode={true}
                                    initialValidation={false}
                                    fieldIndex={1}
                                    onTextChange={(id, value) => setCode(value)}
                                    class=''
                                />
                            </form>
                            <ErrorMessage errorMessage={errorMessage} />

                            <div className="l-container--center">
                                <GTButton id="InitialCodeCheckButton"
                                    onClick={() => setCheckCode(true)}>
                                    <Translate>MFA:InitialCodeCheck</Translate>
                                </GTButton>
                            </div>
                        </div>
                    }
                    {isSSOEnabled && 
                    <>
                    <ThematicBreak withOr={true} />
                    {renderSsoOptions()}
                    </>
                    }
                </>
            }
        </>
    );

}