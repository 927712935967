import { JsonObject } from 'json2typescript';

import { AssignedItem } from '$storage/models//AssignedItem';

/**
 * This class holds information about an assigned item (lesson or training plan)
 * (with information about item and assignment).
 */
@JsonObject
export class FakeAssignedItem extends AssignedItem {

    public isFakeAssignedItem: boolean = true;
    
}
