import React from 'react';
import ShoppingBasketStorage from '$storage/ShoppingBasketStorage'
import IconShoppingBasket from '$resources/svgs/navi/shopping-basket.svg';
import InlineSVG from 'react-inlinesvg';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '$components/shared/Tooltip';
import Session from '$core/Session';

/**
 * Shopping basket icon with a badge that shows the number of Items in the shopping basket.
 * Acts as NavLink to navigate to the shopping basket display page.
 */
@observer
export class ShoppingBasketIcon extends React.Component<{}, {}> {

    constructor(props: {}) {
        super(props);
    }

    public render() {
       let ariaLabelText = Session.instance.storage.translation.GetString('Navigation:ShoppingBasket');
       let dataTipText = Session.instance.storage.translation.GetString('Navigation:ShoppingBasket');
       return (
            ShoppingBasketStorage.instance.getItemCount() == 0 ? '' :
            <>
                <div className="menu__icon shopping-basket-icon"> 
                    <NavLink
                        to={`/shoppingBasketContent`} 
                        aria-label={ariaLabelText}
                        data-tip={dataTipText}
                        data-for="shoppingBasketTooltip" >
                        <InlineSVG src={IconShoppingBasket} />
                        <div className="shopping-basket-icon__badge" >{ShoppingBasketStorage.instance.getItemCount()}</div>
                        <Tooltip id="shoppingBasketTooltip" place="bottom" />
                    </NavLink>
                </div>       
            </>
       );
    }
}
