import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';

/**
 *This Model is for showing the class for MailTo
 */
@JsonObject
export class ClassMailLink {

    /** GroupId  */
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    /** ClassName*/
    @JsonProperty('className', String, false)
    public className: string = "";

    /** ClassStartDate*/
    @JsonProperty('classStartDate', DateTimeConverter, false)
    public classStarteDate: Date | undefined = undefined;

    /** ClassEndDate*/
    @JsonProperty('classEndDate', DateTimeConverter, false)
    public classEndDate: Date | undefined = undefined;

    public classString: string = "";
}
