import React, { useEffect, useState } from "react";
import { Translate } from "$src/components/shared/Translate";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import { Heading } from "$src/components/shared/Heading";

import Session from "$src/core/Session";
import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { isSuccess } from "$src/util/Result";
import { EvaluationGroup } from "$src/storage/models/EvaluationGroup";
import Logger from "$src/core/Logger";
import { EvaluationSkill } from "$src/storage/models/EvaluationSkill";
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import GTButton from "$src/components/shared/Atoms/GTButton";
import { EvaluationSkillRequest } from "$src/storage/models/RequestObjects/EvaluationSkillRequest";
import { EvaluationSkillData } from "$src/storage/models/EvaluationSkillData";
import { ESkillStatus, ESortDirection } from "$src/storage/models/enums";
import ReportingService from "$src/core/Services/ReportingService";
import GTHorizontalTable from "$src/components/shared/Molecules/GTHorizontalTable";
import GTHorizontalTableHeaderRow, { GTHeaderContent } from "$src/components/shared/Atoms/GTHorizontalTableHeaderRow";
import EvaluationOfSkillsItem from "$src/components/reporting/EvaluationOfSkillsItem";
import { EvaluationOfSkillsModel } from "$src/components/reporting/EvaluationOfSkillsModel";
import SortingAlgorithm from "$src/util/SortingAlgorithm";
import { EvaluationSkillDataUser } from "$src/storage/models/EvaluationSkillDataUser";
import { SortableHeaderElement } from "$src/components/shared/SortableHeaderElement";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { useParams } from "react-router";
import { BreadCrumbElement } from "$src/storage/models/BreadCrumbElement";
import { filterBy } from "@progress/kendo-data-query";
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, SeriesClickEvent } from "@progress/kendo-react-charts";
// report on click / touch event
import "hammerjs";

interface ISort {
    id: string;
    direction: ESortDirection;
}

export default function EvaluationOfSkills() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [groups, setGroups] = useState<EvaluationGroup[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<EvaluationGroup[] | undefined>([]);
    const [filteredGroups, setFilteredGroups] = useState<EvaluationGroup[]>([]);
    const [myTeamGroupId, setMyTeamGroupId] = useState<number>(0);
    const [skills, setSkills] = useState<EvaluationSkill[]>([]);
    const [filteredSkills, setFilteredSkils] = useState<EvaluationSkill[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<EvaluationSkill[] | undefined>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [showReport, setShowReport] = useState<boolean>(false);
    const [reportData, setReportData] = useState<EvaluationSkillData[]>([]);
    const [showData, setShowData] = useState<{ name: string; data: number[]; color: string }[]>([]);
    const [showSkillDataTable, setShowSkillDataTable] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<EvaluationSkillData>();
    const [activeHeader, setActiveHeader] = useState<string>('name');
    const [sort, setSort] = useState<ISort>({ id: 'name', direction: ESortDirection.Up });
    const [selectedSkillLevelId, setSelectedSkillLevelId] = useState<number>(0);
    const evaluationOfSkillsModel = new EvaluationOfSkillsModel;
    const tr = Session.instance.storage.translation;
    const className = 'EvaluationOfSkills';
    const loggerLocality = 'Components.EvaluationOfSkills';
    const { bossRelationCode } = useParams<{ bossRelationCode: string }>();
    const exporter = React.createRef<ExcelExport>();

    useEffect(() => {
        const methodName = `${className}:useEffect()`;
        if (!isLoaded) {
            ReportingService.instance.getGroupsForSkillEvaluation(bossRelationCode).then((result) => {
                if (isSuccess<EvaluationGroup[]>(result)) {
                    result.map((group) => {
                        if (group.groupName.includes("sEmployees")) {
                            group.groupName = tr.GetString("EvaluationOfSkills:MyTeamGroup");

                            setMyTeamGroupId(group.groupId);
                        }
                    });
                    setGroups(result);
                } else {
                    const errorMessage = `${methodName} failed to get groups`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            });

            ReportingService.instance.getSkillsForSkillEvaluation(Session.instance.getUserLanguageCodeOrFallBack).then((result) => {
                if (isSuccess<EvaluationSkill[]>(result)) {
                    setSkills(result.slice());
                } else {
                    const errorMessage = `${methodName} failed to get skills`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            });

            setIsLoaded(true)
        }

    }, [isLoaded])

    function sortUserWithAchievedSkill(id: string, direction: ESortDirection) {
        selectedData?.usersWithAchievedSkill.sort((a: EvaluationSkillDataUser, b: EvaluationSkillDataUser): number => {
            return SortingAlgorithm.sortByColumnAndDirection(a, b, id, direction);
        })
    }

    function handleGroupSelectionChanged(e: MultiSelectChangeEvent) {

        if (e.target.value.length == 0) {
            setSelectedGroups([]);
        }
        else {
            setSelectedGroups([...e.target.value]);
        }
    }

    function handleSkillSelectionChanged(e: MultiSelectChangeEvent) {

        if (e.target.value.length == 0) {
            setSelectedSkills([]);
        }
        else {
            setSelectedSkills([...e.target.value]);
        }
    }

    function renderSkillsFilter() {
        return (
            <>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>EvaluationOfSkills:SkillFilterTitle</Translate></Heading>
                    <div className="subTitle__spacer">
                        <Translate>EvaluationOfSkills:SkillFilterSubTitle</Translate>
                    </div>

                    <MultiSelect
                        data={filteredSkills}
                        label={tr.GetString('EvaluationOfSkills:FilterSkills')}
                        dataItemKey="skillId"
                        textField="skillDisplayName"
                        filterable={true}
                        onChange={(e) => handleSkillSelectionChanged(e)}
                        onFilterChange={(e: MultiSelectFilterChangeEvent) => {setFilteredSkils(filterBy(skills.slice(), e.filter))}}
                        value={selectedSkills}
                        allowCustom={false}
                        disabled={undefined}
                        
                    />
                </div>
            </>
        )

    }

    function loadReport() {
        const methodName = `${className}:loadReport()`;

        const tmp: string[] = [];
        selectedSkills?.map(skill => {
            tmp.push(skill.skillName);
        })
        setCategories(tmp);

        const skillIds: number[] = [];
        selectedSkills?.map(skill => {
            skillIds.push(skill.skillId);
        });

        const groupIds: number[] = [];
        selectedGroups?.map(group => {
            groupIds.push(group.groupId);
        })
        if(groupIds.length === 0 && bossRelationCode && bossRelationCode.length > 0){
            groupIds.push(myTeamGroupId);
        }

        const request: EvaluationSkillRequest = { skillIds: skillIds, groupIds: groupIds, bossRelationCode: bossRelationCode === undefined ? '' : bossRelationCode }
        ReportingService.instance.getDataForSkillEvaluation(Session.instance.getUserLanguageCodeOrFallBack, request).then((result) => {
            if (isSuccess<EvaluationSkillData[]>(result)) {
                setReportData(result);
                const valid: number[] = [];
                const expired: number[] = [];
                const notAchived: number[] = [];
                result.map(data => {

                    valid.push(data.usersWithAchievedSkill.filter(skill => skill.skillStatus == ESkillStatus.Valid).length);
                    expired.push(data.usersWithAchievedSkill.filter(skill => skill.skillStatus == ESkillStatus.Expired).length);
                    notAchived.push(data.usersWithAchievedSkill.filter(skill => skill.skillStatus == ESkillStatus.Undefined).length);
                });

                const dataReport = [
                    {
                        name: tr.GetString("Skill:NotAcquired"),
                        data: notAchived,
                        color: "grey",
                    },
                    {
                        name: tr.GetString("Skill:Expired"),
                        data: expired,
                        color: "orange",
                    },
                    {
                        name: tr.GetString("Skill:Valid"),
                        data: valid,
                        color: "green",
                    },
                ];
                setShowData(dataReport);
                setShowReport(true);

            } else {
                const errorMessage = `${methodName} failed to get report Data`;
                Logger.log(loggerLocality, `${methodName} called`);
                console.log(errorMessage);
            }
        });
    }

    function disabledButton() {
        if (((bossRelationCode && bossRelationCode.length > 0) || selectedGroups && selectedGroups.length > 0)
            && selectedSkills && selectedSkills.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    function renderUserFilter() {
        return (
            <>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>EvaluationOfSkills:GroupSearch</Translate></Heading>
                    <div className="subTitle__spacer">
                        <Translate>EvaluationOfSkills:GroupSubTitle</Translate>
                    </div>
                </div>

                <MultiSelect
                    data={filteredGroups}
                    label={tr.GetString('EvaluationOfSkills:SearchGroup')}
                    dataItemKey="groupId"
                    textField="groupName"
                    filterable={true}
                    onChange={(e) => handleGroupSelectionChanged(e)}
                    onFilterChange={(e: MultiSelectFilterChangeEvent) => { setFilteredGroups(filterBy(groups.slice(), e.filter)) }}
                    value={selectedGroups}
                    allowCustom={false}
                    disabled={false}
                />

                <div className="button-position">
                    <GTButton
                        onClick={() => loadReport()}
                        additionalClassNames=""
                        aria-label={tr.GetString("EvaluationOfSkills:LoadReport")}
                        defaultButtonProps={{ disabled: disabledButton() }}>
                        <Translate>EvaluationOfSkills:LoadReport</Translate>
                    </GTButton>
                </div>
            </>
        )
    }

    function getSkillLevel(index: number) {
        switch (index) {
            case 0:
                return ESkillStatus.Undefined;
            case 1:
                return ESkillStatus.Expired;
            case 2:
                return ESkillStatus.Valid;
            default:
                return ESkillStatus.Undefined;
        }
    }

    function getSkillLevelText(index: number) {
        switch (index) {
            case 0:
                return tr.GetString("Skill:NotAcquired");
            case 1:
                return tr.GetString("Skill:Expired");
            case 2:
                return tr.GetString("Skill:Valid");
            default:
                return tr.GetString("Skill:NotAcquired");
        }
    }

    function showExportAndTable(event: SeriesClickEvent) {
        
        const skillLevelIndex = event.series.index;

        // there was a split for categories in chart below (renderReport)
        const regexNewLine = /(?:\r\n|\r|\n)/g;
        const category = event.category.replace(regexNewLine, "");

        const tmp = reportData.find(data => data.skillName.replace(regexNewLine, "") == category);
        if (tmp) {
            const skillLevel = getSkillLevel(skillLevelIndex);
            const tmpReportDat: EvaluationSkillData = { skillId: tmp.skillId, skillName: tmp.skillName, usersWithAchievedSkill: tmp.usersWithAchievedSkill.filter(usk => usk.skillStatus == skillLevel) };
            setSelectedData(tmpReportDat);
            setSelectedSkillLevelId(skillLevelIndex);
            setShowSkillDataTable(true);
        }
    }

    function renderReport() {

        const showCategories = [...categories];
        for (let i = 0; i < showCategories.length; i++) {
            if (showCategories[i].length > 25) {
                showCategories[i] = showCategories[i].ChunkSubstr(25).join("\n");
            }
        }

        return (
            <div>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"><Translate>EvaluationOfSkills:CharTitle</Translate></Heading>
                    <div className="subTitle__spacer">
                        <Translate>EvaluationOfSkills:CharTitleSubTitle</Translate>
                    </div>
                </div>
                <Chart
                    onSeriesClick={(event) => showExportAndTable(event)}
                >
                    <ChartTitle text={undefined} />
                    <ChartLegend position="top" orientation="horizontal" />
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem categories={showCategories} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        {showData.map((item, idx) => (
                            <ChartSeriesItem
                                key={idx}
                                type="bar"
                                tooltip={{
                                    visible: true,
                                }}
                                markers={{ visible: true }}
                                stack={true}
                                data={item.data}
                                name={item.name}
                                color={item.color}
                            />
                        ))}
                    </ChartSeries>
                </Chart>
            </div>
        )
    }

    function getCountOfUsers() {
        return selectedData!.usersWithAchievedSkill.length.toString();
    }

    function onSortDirectionChanged(id: string, direction: ESortDirection) {
        setActiveHeader(id);
        setSort({
            id, direction
        });
    }

    function isActiveHeader(id: string) {
        return id === activeHeader;
    }

    function RenderTableHeader() {

        const headerElements: GTHeaderContent[] = [];

        globalConfig.reportingShowHeaderTableProperties.skillReport.map((field) => {
            const element = {
                cellId: field.id,
                cellElement: <SortableHeaderElement
                    id={field.id}
                    isActive={isActiveHeader(field.id)}
                    header={tr.GetString(`EvaluationOfSkills:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                />
            }

            headerElements.push(element);
        });

        return <GTHorizontalTableHeaderRow
            headerElements={headerElements}
            tableRowClassName="evaluationOfReport__table__row not mobile row__header" />
    }

    function getRows() {

        const rowContent: JSX.Element[] = [];
        selectedData!.usersWithAchievedSkill.map((data, index) => {

            rowContent.push(
                <EvaluationOfSkillsItem
                    columnSettings={globalConfig.reportingShowHeaderTableProperties.skillReport}
                    data={data}
                    isActiveHeader={isActiveHeader(evaluationOfSkillsModel.columnDictionary[index])}
                    onActiveHeaderChanged={(id, direction) => onSortDirectionChanged(id, direction)}
                    key={data.userId.toString() + index.toString()}
                />
            )
        });

        return rowContent;
    }

    function getRowsExport() {
        const columns: JSX.Element[] = [];
        globalConfig.reportingExportProperties.skillReport.map((exportField) => {
            const element = <ExcelExportColumn field={exportField.id} title={tr.GetString(`EvaluationOfSkills:${exportField.id.charAt(0).toUpperCase() + exportField.id.slice(1)}`)} />;
            columns.push(element);
        });

        return columns;
    }

    const excelExport = () => {
        if (exporter.current) {
            exporter.current.save(selectedData?.usersWithAchievedSkill, getRowsExport());
        }
    };

    function showSkillData() {
        return (
            <div>
                <div className="title__margin">
                    <Heading headingLevel={2} cssClass="heading__Title2"> {tr.GetString('EvaluationOfSkills:SkillData')
                        .Format(selectedData!.skillName, getSkillLevelText(selectedSkillLevelId))} </Heading>
                    <div className="subTitle__spacer">
                        {tr.GetString('EvaluationOfSkills:CountOfUsers')
                            .Format(getCountOfUsers())}
                    </div>
                </div>

                <GTHorizontalTable
                    headerElement={RenderTableHeader()}
                    rows={getRows()}
                    tableClassName="evaluationOfReport__table"
                />

                <div className="button-position">
                    <GTButton
                        onClick={() => excelExport()}
                        additionalClassNames=""
                        aria-label={tr.GetString("EvaluationOfSkills:ExportToList")}
                        defaultButtonProps={{ disabled: false }}>
                        <Translate>EvaluationOfSkills:ExportToList</Translate>
                    </GTButton>
                </div>
            </div>
        )
    }


    function getBcElemets() {
        const bcElements: BreadCrumbElement[] = [];

        if (bossRelationCode != undefined) {
            bcElements.push(
                {
                    navigationPath: "/more",
                    title: Session.instance.storage.translation.GetString('Navigation:More')
                },
                {
                    navigationPath: `/myTeam/${bossRelationCode}`,
                    title: Session.instance.storage.translation.GetString('Navigation:MyTeam')
                },
                {
                    navigationPath: `/reportEvaluationOfSkillsMyteam/${bossRelationCode}`,
                    title: Session.instance.storage.translation.GetString('Navigation:EvaluationOfSkills')
                }
            )
        }
        return bcElements;
    }

    return (
        <>
            {selectedData && sortUserWithAchievedSkill(sort.id, sort.direction)}
            <div className="l-container">
                <MenuBreadCrumb breadCrumbElementsToAppend={getBcElemets()} />
                <Heading headingLevel={1} cssClass="heading__Title"><Translate>EvaluationOfSkills:Title</Translate></Heading>
                <div>
                    <Translate>EvaluationOfSkills:SubTitle</Translate>
                </div>

                {isLoaded ?
                    <>
                        {renderSkillsFilter()}

                        {renderUserFilter()}

                        {showReport && renderReport()}

                        {showSkillDataTable && showSkillData()}
                    </>
                    : <ProgressSpinner />}

                <ExcelExport
                    data={selectedData?.usersWithAchievedSkill ? selectedData?.usersWithAchievedSkill : []}
                    ref={exporter}
                    fileName={'SkillExport'} />
            </div>
        </>
    )
}