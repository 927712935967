import React from 'react';

import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';

interface IProps {
    activeElement: TrainingPlanElement | null;
}
/**
 * @deprecated The method should not be used
 */
export class TrainingPlanElementsModulSummary extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return <div className="tp__elements__modul-summary">{this.props.activeElement != null ? this.props.activeElement.description : '' }</div>
    }
}