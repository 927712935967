import React from "react";
import { ModalPopup } from "$src/components/shared/ModalPopup";
import Logger from "$src/core/Logger";
import { AttributeValue } from '$storage/models/AttributeValue';
import { Heading } from '$components/shared/Heading';
import { Translate } from "$src/components/shared/Translate";
import Session from "$src/core/Session";
import { DropDownList, DropDownListChangeEvent, MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { UserSkillProfile } from "$src/storage/models/SkillProfile/UserSkillProfile";
import { isSuccess } from "$src/util/Result";
import GtError from "$src/util/GtError";
import SkillProfileService from "$src/core/Services/SkillProfileService";
import CustomErrorMessage from "$src/util/CustomErrorMessage";
import { filterBy } from "@progress/kendo-data-query";
import { RemoveSkillProfileResponse, RemoveSkillProfileResponseContainer } from "$src/storage/models/SkillProfile/RemoveSkillProfileResponse";
import { RemoveSkillProfileRequest } from "$src/storage/models/RequestObjects/RemoveSkillProfileRequest";
import TeamService from "$src/core/Services/TeamService";
import { User } from "$src/storage/models/User";
import { Alert } from "$src/components/shared/WarningsAndErrors/Alert";
import { SkillProfile } from "$src/storage/models/SkillProfile/SkillProfile";

interface IProps {
    isOpen: boolean;
    onClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    onProfileRemovalSuccess: (removals: RemoveSkillProfileResponse[]) => void;
    headingClassName?: string;
    employees: User[];
    headingLevel: number;
    userTargetProfiles: UserSkillProfile[];
    bossRelationCode: string;
}

interface IStateData {
    allSkillProfileTypes: AttributeValue[];
    skillProfileTypesFiltered: AttributeValue[];
    selectedProfileType: AttributeValue | undefined;

    storedServerResponse: undefined | RemoveSkillProfileResponse[];

    allTargetProfiles: SkillProfile[];
    allRemovableSkillProfiles: UserSkillProfile[] | undefined;
    skillProfilesFiltered: UserSkillProfile[];
    selectedSkillProfiles: UserSkillProfile[] | undefined;
    skillProfileFilter: string;
}

interface IState extends IStateData {
    errorMessage: string;
}

export class RemoveTargetProfilesModal extends React.Component<IProps, IState>{
    protected className = 'RemoveTargetProfilesModal';
    protected loggerLocality = 'Components.RemoveTargetProfilesModal';
    private TRANSLATION_PREFIX = 'RemoveTargetProfiles';

    constructor(props: IProps) {
        super(props);

        this.state = {
            allSkillProfileTypes: [],
            skillProfileTypesFiltered: [],
            selectedProfileType: undefined,

            allTargetProfiles: [],
            skillProfileFilter: '',
            skillProfilesFiltered: [],
            allRemovableSkillProfiles: undefined,
            selectedSkillProfiles: undefined,
            storedServerResponse: undefined,

            errorMessage: ''
        }
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const allPromises = new Array<Promise<unknown>>();
        allPromises.push(this.loadSkillProfiles());
        allPromises.push(this.loadSkillProfileTypes());
        await Promise.all(allPromises);

        //Filter out all target profiles where user not has ASSIGN(ZUWEISEN) right
        //userTargetProfiles contains all assigned target profiles and allTargetProfiles contains all profiles with ASSIGN right
        const assignedTargetProfiles: (UserSkillProfile[] | undefined) = [];
        this.state.allTargetProfiles.map((profile) => {
            const targetProfile = this.props.userTargetProfiles.find(assignedProfile => assignedProfile.skillProfileId == profile.skillProfileID)
            if (targetProfile) {
                assignedTargetProfiles.push(targetProfile);
            }
        })

        //Filter out types that dont have any removable target profile
        const filterableProfilesTypes: AttributeValue[] = [];
        this.state.allSkillProfileTypes.map((profileType) => {
            if (assignedTargetProfiles?.some(skillProfile => skillProfile.skillProfileTypeId === profileType.id)) {
                filterableProfilesTypes.push(profileType);
            }
        })

        this.setState({
            skillProfileTypesFiltered: filterableProfilesTypes,
            allRemovableSkillProfiles: assignedTargetProfiles,
            skillProfilesFiltered: assignedTargetProfiles
        });
    }

    public componentDidUpdate(prevProps: IProps) {
        const methodName = `${this.className}:componentDidUpdate()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
            Logger.log(this.loggerLocality, `${methodName} open state changed, setting state`);
            this.setState({ selectedSkillProfiles: undefined, selectedProfileType: undefined, skillProfileFilter: '' })
        }
    }

    public render() {
        const methodName = `${this.className}:render()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        return (
            <ModalPopup
                isOpen={this.props.isOpen}
                onRequestClose={(e) => this.closeModal(e)}
                contentCssClass="assign-skill-profile">
                <form className="assign-skill-profile__form">
                    <Heading
                        headingLevel={this.props.headingLevel}
                        cssClass={this.props.headingClassName != null ? this.props.headingClassName : 'heading__Level2'}>
                        <Translate>{`${this.TRANSLATION_PREFIX}:Title`}</Translate>
                    </Heading>
                    {this.renderContent()}
                    {this.renderButtons()}
                </form>
            </ModalPopup>
        )
    }

    private renderServerResults(): JSX.Element {
        if (this.state.storedServerResponse != null) {
            return <Alert
                alertType="mixed"
                alertAppereance="box"
                className="no-background no-padding no-margin"
                messages={this.getResultMessages()}
            />
        }
        return <React.Fragment />;
    }

    private getResultMessages(): Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> {
        const methodName = `${this.className}:getResultMessage()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const messages: Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> = [];
        const serverResponses = this.state.storedServerResponse;

        this.props.employees.map(employee => {
            if (serverResponses == null) {
                const errorMessage = `${methodName} got no results`;
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);
                //Server did not respond
                messages.push({
                    alertType: 'error',
                    message: `${employee.fullName}: ${Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultNoAnswer`)}`
                })
            } else {
                if (serverResponses.length === 0) {
                    const errorMessageInvalidResult = `${methodName} invalid result for user ${employee.username}`;
                    Logger.log(this.loggerLocality, errorMessageInvalidResult);
                    console.error(errorMessageInvalidResult);
                    // Got an empty Result
                    messages.push(
                        {
                            alertType: 'error',
                            message: `${employee.fullName}: ${Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultNoResult`)}`
                        }
                    );
                } else {
                    serverResponses.map(response => {
                        if (this.state.selectedSkillProfiles) {
                            //Profile not found, perfect
                            const profile = this.state.selectedSkillProfiles.find(sp => sp.skillProfileId === response.skillProfileId);
                            if (profile) {
                                messages.push({
                                    alertType: response.removed ? 'success' : 'error',
                                    message: `${employee.fullName}: ${response.removed ?
                                        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultSuccessfull`).Format(profile?.skillProfileTitle) :
                                        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultError`)}`
                                });
                            }
                        }
                    })
                }
            }
        })
        return messages;
    }

    public renderContent(): JSX.Element {
        const methodName = `${this.className}:renderContent()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        //User tried to remove skill profile(s) => render answer instead of selection
        if (this.state.storedServerResponse != null) {
            return this.renderServerResults();
        }

        //Add option to select all skills to list
        const allSkillProfiles = Object.assign(new UserSkillProfile(),
            {
                skillProfileID: 0,
                skillProfileTitle: Session.instance.storage.translation.GetString(`AssignSkillProfile:AllSkillProfiles`)
            })
        const skillProfilesFiltered = [allSkillProfiles, ...this.state.skillProfilesFiltered];

        //Add option to select all skill types to list
        const allSkillProfileTypes = Object.assign(new AttributeValue(),
            {
                id: 0,
                text: Session.instance.storage.translation.GetString(`AssignSkillProfile:AllSkillProfileTypes`)
            });
        const skillProfileTypeData = [allSkillProfileTypes, ...this.state.skillProfileTypesFiltered];
        const selectedProfileType = this.state.selectedProfileType || allSkillProfileTypes;

        return (<React.Fragment>
            <DropDownList
                data={skillProfileTypeData}
                label={Session.instance.storage.translation.GetString(`AssignSkillProfile:SkillProfileType`)}
                dataItemKey="id"
                textField="text"
                defaultValue={allSkillProfileTypes}
                onChange={(e) => this.handleSkillProfileTypeSelectionChanged(e)}
                value={selectedProfileType}
            />
            <MultiSelect
                data={skillProfilesFiltered}
                label={Session.instance.storage.translation.GetString(`AssignSkillProfile:SkillProfile`)}
                dataItemKey="userSkillProfileId"
                textField="skillProfileTitle"
                filterable={true}
                onChange={(e) => this.handleSkillProfileSelectionChanged(e)}
                onFilterChange={(e) => this.skillProfileFilterChanged(e)}
                value={this.state.selectedSkillProfiles}
                allowCustom={false}
            />
        </React.Fragment>)
    }

    private handleSkillProfileTypeSelectionChanged(e: DropDownListChangeEvent): void {
        if (e.target.value instanceof AttributeValue) {
            this.setState({ selectedProfileType: e.target.value, selectedSkillProfiles: undefined, skillProfileFilter: '' });
            this.filterSkillProfiles(e.target.value.id, this.state.skillProfileFilter);
        }
    }

    private filterSkillProfiles(selectedProfileTypeID: number | null, skillProfileFilter: string): void {
        const methodName = `${this.className}:filterSkillProfiles()`;
        Logger.log(this.loggerLocality, `${methodName} called with ${selectedProfileTypeID} ${skillProfileFilter}`);

        let skillProfilesFiltered = this.state.allRemovableSkillProfiles;
        if (skillProfilesFiltered === undefined) {
            return
        }

        // Filter out by selected profile type
        if (selectedProfileTypeID != null && selectedProfileTypeID !== 0) {
            Logger.log(this.loggerLocality, `${methodName} filter by id ${selectedProfileTypeID}`);
            const skillProfileTypeFilter = {
                field: 'skillProfileTypeId',
                ignoreCase: true,
                operator: 'eq',
                value: selectedProfileTypeID,
            };
            skillProfilesFiltered = filterBy(skillProfilesFiltered, skillProfileTypeFilter);
        }

        // Filter out by entered text
        if (skillProfileFilter != null && skillProfileFilter !== '') {
            Logger.log(this.loggerLocality, `${methodName} filter by string ${skillProfileFilter}`);
            const skillProfileInputTextFilter = {
                field: 'skillProfileTitle',
                ignoreCase: true,
                operator: 'contains',
                value: skillProfileFilter,
            };
            skillProfilesFiltered = filterBy(skillProfilesFiltered, skillProfileInputTextFilter);
        }

        this.setState({ skillProfilesFiltered })
    }

    private async loadSkillProfileTypes() {
        const methodName = `${this.className}:loadSkillProfileTypes()`;
        const data: AttributeValue[] | GtError = await SkillProfileService.instance.getSkillProfileTypes(Session.instance.getUserLanguageCodeOrFallBack);

        if (isSuccess<AttributeValue[]>(data)) {
            Logger.log(this.loggerLocality, `${methodName} got skill profile types`);

            this.setState({
                allSkillProfileTypes: data
            });
        } else {
            const errorMessage = `${methodName} failed to load skill profile types. ${data.message}`;
            console.error(errorMessage);

            if (data.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(data.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetSkillProfileTypesFailed' });
            }
        }
    }

    private skillProfileFilterChanged(e: MultiSelectFilterChangeEvent) {
        const methodName = `${this.className}:skillProfileFilterChanged()`;
        Logger.log(this.loggerLocality, `${methodName} called`);
        const skillProfileFilter = e.filter.value;
        if (skillProfileFilter !== '') {
            Logger.log(this.loggerLocality, `${methodName} filter is ${e.filter.value}`);
            this.setState({
                skillProfileFilter
            });
            if (skillProfileFilter !== (this.state.selectedSkillProfiles != null)) {
                this.setState({
                    selectedSkillProfiles: undefined
                });
            }
        } else {
            Logger.log(this.loggerLocality, `${methodName} filter is empty`);
            this.setState({
                selectedSkillProfiles: undefined,
                skillProfileFilter: ''
            });
        }
        this.filterSkillProfiles(this.state.selectedProfileType != null ? this.state.selectedProfileType.id : 0, skillProfileFilter);
    }

    private handleSkillProfileSelectionChanged(e: MultiSelectChangeEvent) {
        const methodName = `${this.className}:handleSkillProfileSelectionChanged()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const values = e.target.value
        const lastItem = values[values.length - 1]
        if (lastItem != null && lastItem.skillProfileID == 0) {
            Logger.log(this.loggerLocality, `Got id == 0, add all`)
            this.setState({ selectedSkillProfiles: this.state.skillProfilesFiltered })
        }
        else if (e.target.value.length == 0) {
            Logger.log(this.loggerLocality, `No elements, empty the list`)
            this.setState({ selectedSkillProfiles: [] })
        }
        else {
            Logger.log(this.loggerLocality, `Add selected values to list ${e.target.value}`)
            this.setState({ selectedSkillProfiles: [...e.target.value] })
        }
    }

    private renderButtons(): JSX.Element {
        const methodName = `${this.className}:renderButtons()`;
        Logger.log(this.loggerLocality, `${methodName} rendering buttons`);
        return <div
            key={`assign-skill-profile__button__container`}
            className={'assign-skill-profile__button-container modal__spread-buttons'}>
            {!this.state.storedServerResponse &&
                <button
                    type="button"
                    className="btn btn--sm"
                    disabled={!this.state.selectedSkillProfiles || this.state.selectedSkillProfiles.length === 0}
                    onClick={() => this.removeSkillProfiles()}>
                    <Translate>{`${this.TRANSLATION_PREFIX}:Save`}</Translate>
                </button>
            }
            <button
                type="button"
                className="btn btn--sm"
                onClick={(e) => this.closeModal(e)}>
                {this.state.storedServerResponse != null ?
                    <Translate>{`${this.TRANSLATION_PREFIX}:Close`}</Translate> :
                    <Translate>{`${this.TRANSLATION_PREFIX}:Cancel`}</Translate>
                }

            </button>
        </div>
    }

    private async loadSkillProfiles() {
        const methodName = `${this.className}:loadSkillProfiles()`;
        const allTargetProfiles: SkillProfile[] | GtError = await SkillProfileService.instance.getSkillProfiles(0, Session.instance.getUserLanguageCodeOrFallBack, this.props.bossRelationCode);

        if (isSuccess<SkillProfile[]>(allTargetProfiles)) {
            Logger.log(this.loggerLocality, `${methodName} got skill profiles`);
            this.setState({
                allTargetProfiles: allTargetProfiles
            });
        } else {
            const errorMessage = `${methodName} failed to load skill profiles. ${allTargetProfiles.message}`;
            console.error(errorMessage);

            if (allTargetProfiles.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(allTargetProfiles.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetSkillProfilesFailed' });
            }
        }
    }

    private async removeSkillProfiles() {
        const methodName = `${this.className}:deleteSkillProfiles()`;
        Logger.log(this.loggerLocality, `${methodName} called`);
        const removeRequest = new RemoveSkillProfileRequest();

        if (this.state.selectedSkillProfiles != null) {
            this.state.selectedSkillProfiles.forEach(profile => {
                Logger.log(this.loggerLocality, `${methodName} removing target profile ${profile.skillProfileId} from user ${profile.userId}`);
                removeRequest.skillProfileIds.push(profile.skillProfileId)
            });
            removeRequest.userIds = this.props.employees.map(e => e.id);

            const response = await TeamService.instance.RemoveSkillProfilesFromEmployees(removeRequest);
            if (isSuccess<RemoveSkillProfileResponseContainer>(response)) {
                if (this.props.onProfileRemovalSuccess) {
                    this.props.onProfileRemovalSuccess(response.removeSkillProfileResponses);
                }
                this.setState({ storedServerResponse: response.removeSkillProfileResponses });
            } else {
                const errorMessage = `${methodName} failed to remove skill profile from users. ${response.message}`;
                console.error(errorMessage);

                this.setState({ errorMessage: this.TRANSLATION_PREFIX + ':Error' });
            }
        }

    }

    private closeModal(e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>): void {
        const methodName = `${this.className}:closeModal()`;
        Logger.log(this.loggerLocality, `${methodName} closing modal and resetting state`);
        this.setState({
            errorMessage: '',
            selectedSkillProfiles: undefined,
            selectedProfileType: undefined,
            storedServerResponse: undefined,
        });
        if (this.props.onClose != null) {
            this.props.onClose(e);
        }
    }
}