import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Translate } from '$components/shared/Translate';
import { InputText } from '$components/shared/InputText';
import GTButton from '$components/shared/Atoms/GTButton';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';

import PurchaseService from '$src/core/Services/PurchaseService';
import { isSuccess } from '$src/util/Result';
import { VoucherValidity } from '$src/storage/models/Purchase/VoucherValidity';
import Session from '$src/core/Session';
import ShoppingBasketItemUser from '$src/storage/models/ShoppingBasket/ShoppingBasketItemUser';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`ShoppingBasketContent:${str}`);
}
interface IShoppingBasketItemParticipantVoucherProps {
	user: ShoppingBasketItemUser;
	itemId: number;
	onVoucherVerified: (voucher: string) => void;
	onCancel?: () => void;
}

/**
 * Checks voucher for user and item
 * @param props 
 * @returns 
 */
export default function ShoppingBasketItemParticipantVoucher(props: IShoppingBasketItemParticipantVoucherProps) {
	const [voucher, setVoucher] = useState(props.user.price?.voucherCode || '');
	const [isVerifying, setVerifying] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	useEffect(() => {
		if (!isVerifying) return;

		if (!voucher) {
			props.onVoucherVerified('');
			return;
		}

		const prevalidation = ShoppingBasketStorage.instance.prevalidateVoucher(voucher);
		if (!prevalidation.isValid) {
			setVerifying(false);
			setErrorMessage(prevalidation.error);
			return;
		}

		PurchaseService.instance.checkVoucher(voucher).then(validationResult => {
			if (isSuccess<VoucherValidity>(validationResult)) {
				if (validationResult.isValid) {
					if (validationResult.itemIds.length === 0 || validationResult.itemIds.some(p => p === props.itemId)) {
						if (validationResult.percentDiscount > 0) {
							toast.success(getTranslation('VoucherValidationSucceeded').replace('{discount}', validationResult.percentDiscount.toString() + '%'));
						} else {
							toast.success(getTranslation('VoucherValidationSucceeded').replace('{discount}', validationResult.absDiscount.toString() + ' ' + validationResult.absDiscountCurrency));
						}
						
						props.onVoucherVerified(voucher);
					} else {
						setErrorMessage('ShoppingBasketContent:VoucherValidationFailed_ItemId')
					}
				} else {
					setErrorMessage('ShoppingBasketContent:VoucherValidationFailed_Invalid')
				}
			} else {
				if (props.onCancel) {
					props.onCancel(); // hide modal to see toast 
				}
				toast.error(getTranslation('VoucherValidationFailed'));
			}
			setVerifying(false);
		});
	}, [isVerifying]);

	function remove() {
		setVoucher('');
		setVerifying(true);
	}

	return isVerifying ? <ProgressSpinner /> : (
		<>
			<p className='margin-top'>
				<Translate>ShoppingBasketContent:EnterVoucherDescription</Translate>
			</p>

			<div className='margin-top'>
				<InputText attributes={{ id: 'inputVoucher', value: voucher, editMode: true, label: 'ShoppingBasketContent:InputVoucherLabel' }} onChange={(id, value) => setVoucher(value) } />
			</div>

			<GTButton onClick={() => setVerifying(true)} defaultButtonProps={{disabled: voucher.length < 1}} additionalClassNames='marginRight-5'>
				<Translate>ShoppingBasketContent:VerifyParticipantVoucher</Translate>
			</GTButton>

			{
				props.user.price?.voucherCode &&
				<GTButton onClick={() => remove()} isPrimary={false}>
					<Translate>ShoppingBasketContent:RemoveParticipantVoucher</Translate>
				</GTButton>
			}

			{
				errorMessage &&
				<Alert alertType='error' alertAppereance='box' message={errorMessage} />
			}
		</>
	);
}