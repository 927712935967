import { ListItemProps } from "@progress/kendo-react-dropdowns";
import React from "react";
import { ReactElement } from "react";

export const MultiSelectCheckboxItemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const itemChildren = (
        <span>
            <input type="checkbox" defaultChecked={itemProps.selected} />
            &nbsp;{li.props.children}
        </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
};