import { ErrorSummary } from '$components/shared/ErrorSummary';
import {GenericInput, GenericInputTypes} from '$components/shared/GenericInput';
import { Heading } from '$components/shared/Heading';
import { ISetFocus } from '$components/shared/InputAttributes';
import { ModalPopup } from '$components/shared/ModalPopup';
import { Translate } from '$components/shared/Translate';
import UserService from '$core/Services/UserService';
import Session from '$core/Session';
import Logger from '$src/core/Logger';
import { NumberResponse } from '$src/storage/models/NumberRepsonse';
import { User } from '$src/storage/models/User';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { EEmailCheckMode } from '$storage/models/enums';
import GtError from '$util/GtError';
import { Guid } from '$util/Guid';
import { isSuccess } from '$util/Result';
import React from 'react';

interface IProps {
    user: User;
    formClassName: string;
    headingClassName: string;
    headingLevel: number;
    extendedConfigurations?: Array<{  // Extended Role Based Configurations
        allowedGroups: string[];
        fieldGroups: IFieldGroup[];
    }>;
    defaultConfiguration: IFieldGroup[]; // Default FieldGroup Configurations, will be rendered if no extended configuration is given or no extended configurations is found based on the user groups
}

interface IState {
    savedUser: User;
    modifyUser: User; // Object which will get modified
    editMode: boolean;
    formValid: boolean;
    formErrorMsg: string;
    statusInputControls: IStatusInputControl[];
    shouldShowModal: boolean;
}

interface IStatusInputControl {
    id: string;
    label: string;
    isValid: boolean;
    errorMsg: string;
}

interface IFieldGroup {
        title: string;
        fields: {
            label: string;
            type: string;
            property: string;
            attributeValueType: number;
            readonly: boolean;
            required: boolean;
            class: string;
            regExpression?: RegExp | undefined;
            url?: string | undefined;
            hasFocus?: boolean | undefined;
            validationOptions?: {
                isTodayCheckEnabled: boolean;
            };
        }[];
    }

export class UserDetails extends React.Component<IProps, IState> {

    protected _formConfig: IFieldGroup[];

    protected _lastIndex: number = 99999;

    protected className = 'UserDetail';
    protected loggerLocality = 'Components.UserDetail';

    constructor(props: IProps) {
        super(props);

        // Build status panel for configured input controls
        const formfields: IStatusInputControl[] = [];

        const getExtendedConfiguration = () => {
            if(this.props.extendedConfigurations != null && this.props.extendedConfigurations.length > 0) {
                return this.props.extendedConfigurations.find(up => {
                    up.allowedGroups = up.allowedGroups.map(ag => ag.toLocaleLowerCase());
                    return (this.props.user.groups.filter(userGroup => up.allowedGroups.includes(userGroup.groupName.toLocaleLowerCase())).length > 0);
                })
            }
            return null;
        };
        const extendedConfiguration = getExtendedConfiguration();

        this._formConfig = extendedConfiguration != null ? extendedConfiguration.fieldGroups : 
                           this.props.defaultConfiguration;

        this._formConfig.map(c => {
            c.fields.map(f =>
                formfields.push({ id: f.property, label: f.label, isValid: true, errorMsg: '' }),
            )
        });

        // Initializes status
        this.state = {
            editMode: false,
            formErrorMsg: '',
            formValid: true,
            modifyUser: JSON.parse(JSON.stringify(this.props.user)), // creates a copy of the user to compare the current to the old value 
            savedUser: JSON.parse(JSON.stringify(this.props.user)),
            shouldShowModal: false,
            statusInputControls: formfields
        };

        this.handleEditClick = this.handleEditClick.bind(this);
        this.emailAllreadyExists = this.emailAllreadyExists.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.addButtons = this.addButtons.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    public render() {
        this._lastIndex = 99999
        const userGroupedFields: JSX.Element[] = [];

        this.addButtons(userGroupedFields, true);
        this.addUserDetails(userGroupedFields);
        this.addButtons(userGroupedFields, false);

        return <form
            key="userdetail_form"
            className={`l-form-container ${this.props.formClassName != null ? this.props.formClassName : ''}`}>
            {userGroupedFields}
        </form>

    }

    protected async handleCancelClick(event: React.FormEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({ formErrorMsg: '' });
        // Reset state of status panel
        // Get status panel reference
        const statusInputs = this.state.statusInputControls;

        // Reset all as invalid marked objects                    
        statusInputs.filter(c => !c.isValid).map(c => {
            c.isValid = true;
            c.errorMsg = '';
        })

        // Reload login user if login user got modified
        if (Session.instance.loginUser != null &&
            this.state.modifyUser.id === Session.instance.loginUser.id) {
            await Session.instance.reloadLoginUser();
        }

        this.setState({
            editMode: false,
            formValid: true,
            modifyUser: JSON.parse(JSON.stringify(this.props.user)), // Reset User
            savedUser: JSON.parse(JSON.stringify(this.props.user)), // Reset User
            statusInputControls: statusInputs,
        });
    }

    protected handleEditClick(event: React.FormEvent<HTMLButtonElement>) {
        event.preventDefault();
        this.setState({
            editMode: true,
            formValid: true
        });
    }

    protected async saveUser(event: React.FormEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (this.state.modifyUser) {
            const configurationValue = await UserService.instance.getDomainEmailCheckMode(this.state.modifyUser.domainId.toString());
            if (isSuccess<NumberResponse>(configurationValue)) {
                if (await this.emailAllreadyExists() && configurationValue.value === EEmailCheckMode.ErrorIfNotUnique.valueOf()) {
                    this.setState({ formErrorMsg: 'UserProfile:MailExists' });
                }
                else {
                    if (await this.emailAllreadyExists() && configurationValue.value === EEmailCheckMode.WarnIfNotUnique.valueOf()) {
                        this.openModal();
                    }
                    else {
                        this.handleSaving();
                    }
                }
            }
        }
    }

    protected async handleSaving() {
        let response: User | GtError;
        if (this.state.shouldShowModal) {
            this.closeModal();
        }
        if (this.state.modifyUser) {
            // Save changes persistent
            response = await Session.instance.storage.user.saveUser(this.state.modifyUser);

            // Reload login user
            await Session.instance.reloadLoginUser();

            if (isSuccess<User>(response)) {
                // reset formErrorMsg to hide it for next modification
                this.setState({ editMode: false, formErrorMsg: '', savedUser: response, modifyUser: response });
            }
            else {
                this.setState({ formErrorMsg: 'UserProfile:SaveHasFailed' });
            }
        }
    }

    protected async emailAllreadyExists(): Promise<boolean> {
        const methodName = `${this.className}:emailAllreadyExists()`;

        if (this.state.modifyUser != null && this.state.modifyUser.email !== this.state.savedUser.email) {
            const responseEmailValidation = await UserService.instance.userEmailExists(this.state.modifyUser.email, this.state.modifyUser.domainId);
            if (isSuccess<BooleanResponse>(responseEmailValidation)) {
                Logger.log(this.loggerLocality, `${methodName} - e-mail validation result: Already exists: ${responseEmailValidation.status}`);
                return responseEmailValidation.status; // Return value got from service
            }
            else {
                this.setState({ formErrorMsg: 'Failed to check if e-mail already exists' });
                const message = `${methodName} - Failed to check if e-mail already exists, error: ${responseEmailValidation.message}`;
                Logger.log(this.loggerLocality, message);
                return true; // Could not check, return already exists
            }
        }
        return false; // do not need to check, return does not exist
    }

    // tslint:disable:jsdoc-format
    /**
      * Callback for input(text) component: value has changed.
      * @param id: Id of the Input triggering this callback.
      * @param value: Current value from Input control.
      * @param errorMsg: Any error messages (Validation). 
      */
    protected onTextChange(id: string, value: string, errorMsg: string, setFocus?: ISetFocus) {

        if (this.state.modifyUser !== null) {

            const editedUser = this.state.modifyUser;

            // Update specific user attribute (new value)
            editedUser[id] = value;

            // Get state of status panel.
            const statusInputs = this.state.statusInputControls;

            // Get affected control reference (current input control [user attribute])
            const inputIndex = statusInputs.findIndex(i => i.id === id);
            const statusInput: IStatusInputControl = statusInputs[inputIndex];

            // Update affected control values
            statusInput.isValid = errorMsg.length === 0 ? true : false;
            statusInput.errorMsg = errorMsg;

            // Update state of status panel                
            statusInputs[inputIndex] = statusInput;

            // Check, whether any input controlis invalid
            let isFormValid = true;
            if (statusInputs.some(i => i.isValid === false)) {
                // Mark form as invalid, because one or more controls are invalid                    
                isFormValid = false;
                if (setFocus !== undefined && setFocus.idx < this._lastIndex) {
                    if (setFocus.innerRef !== undefined && setFocus.innerRef.current !== null) {
                        this._lastIndex = setFocus.idx
                        setFocus.innerRef.current.focus()
                    }
                }
            }

            // Refresh state            
            this.setState(() => {
                return {
                    formValid: isFormValid,
                    modifyUser: editedUser,
                    statusInputControls: statusInputs,
                }
            });
        }
    }

    /**
      * Callback for input(boolean) component: value has changed.
      * @param id: Id of the Input triggering this callback.
      * @param value: Current value from Input control.
      * @param errorMsg: Any error messages (Validation). 
     */
    protected onCheckboxChange(id: string, value: boolean, errorMsg: string) {

        if (this.state.modifyUser !== null) {

            const editedUser = this.state.modifyUser;

            // Update specific user attribute (new value)
            editedUser[id] = value;

            // Get state of status panel.
            const statusInputs = this.state.statusInputControls;

            // Get affected control reference (current input control [user attribute])
            const inputIndex = statusInputs.findIndex(i => i.id === id);
            const statusInput: IStatusInputControl = statusInputs[inputIndex];

            // Update affected control values
            statusInput.isValid = errorMsg.length === 0 ? true : false;
            statusInput.errorMsg = errorMsg;

            // Update state of status panel                
            statusInputs[inputIndex] = statusInput;

            // Check, whether any input controlis invalid
            let isFormValid = true;
            if (statusInputs.some(i => i.isValid === false)) {
                isFormValid = false;
            }

            // Refresh state
            editedUser[id] = value;
            this.setState(() => {
                return {
                    formValid: isFormValid,
                    modifyUser: editedUser,
                    statusInputControls: statusInputs,
                }
            });
        }
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private GetAttributeValue(user: User, type: string) {
        const temp = user[type]
        if (temp instanceof Date) {
            return temp.toISOString()
        }
        return temp.toString()
    }

    private addUserDetails(userGroupedFields: JSX.Element[]) {

        const user = this.state.modifyUser;

        if (this.state.editMode) {
            // Error Summary for Input Validation Errors
            const errorsForSummary = this.state.statusInputControls.filter(c => !c.isValid).map((c, index) =>
                <React.Fragment key={index}><Translate>{c.label}</Translate>:&nbsp;
                <Translate>{c.errorMsg}</Translate>
                </React.Fragment>
            );
            userGroupedFields.push(<ErrorSummary key="UserDetail_ErrorSummary" listOfErrors={errorsForSummary} />);
            // Error Summary for Form Errors
            if (this.state.formErrorMsg !== '') {
                userGroupedFields.push(<ErrorSummary key="UserDetail_ErrorSummaryForm"
                    listOfErrors={[<Translate key="UserDetail_ErrorSummaryForm_Error">{this.state.formErrorMsg}</Translate>]} />);
            }

        }

        this._formConfig.map((c) => {
            if (c.title != null && c.title !== '') {
                userGroupedFields.push(
                    <div key={'UserDetail_SubTitle_' + c.title} className="l-box--wide">
                        <Heading headingLevel={this.props.headingLevel} cssClass={`l-form-container__section-title ${this.props.headingClassName}`}>
                            <Translate>{c.title}</Translate>
                        </Heading>
                    </div>)
            }
            c.fields.map((f, index) => {

                let inputValue: string = '';
                if (f.type.toLocaleLowerCase() !== 'placeholder' && f.type.toLocaleLowerCase() !== 'redirectlink') {
                    inputValue = (user[f.property] !== null && user[f.property] !== undefined) ? this.GetAttributeValue(user, f.property) : ''
                }

                const hasFocus: boolean = (f.hasFocus !== undefined && this.state.formValid) ? f.hasFocus : false;

                // Render all but no redirect links vor public user profiles (e.g. change password)
                if ((user.isPublic && f.type.toLowerCase() !== 'redirectlink') || !user.isPublic) {
                    userGroupedFields.push(
                        <GenericInput
                            key={'UserDetail_Property' + f.property}
                            type={f.type as GenericInputTypes}
                            id={f.property}
                            label={f.label}
                            value={inputValue}
                            class={f.class}
                            attributeValueType={f.attributeValueType}
                            isReadOnly={f.readonly}
                            isRequired={f.required}
                            onTextChange={(id: string, value: string, errorMsg: string, xy: ISetFocus) => this.onTextChange(id, value, errorMsg, xy)}
                            onCheckboxChange={(id: string, value: boolean, errorMsg: string) => this.onCheckboxChange(id, value, errorMsg)}
                            regExpression={f.regExpression}
                            url={f.url}
                            hasFocus={hasFocus}
                            editMode={this.state.editMode}
                            initialValidation={true}
                            fieldIndex={index}
                            validationOptions={f.validationOptions}
                        />
                    )
                }
                // Instead render place holders for public profiles to keep ordering
                else {
                    userGroupedFields.push(
                        <GenericInput
                            key={'UUserDetail_Property' + f.property}
                            type={'placeholder'}
                            id={f.property}
                            label={f.label}
                            value={inputValue}
                            class={f.class}
                            attributeValueType={f.attributeValueType}
                            isReadOnly={true}
                            isRequired={false}
                            hasFocus={hasFocus}
                            editMode={this.state.editMode}
                            initialValidation={true}
                            fieldIndex={index}
                        />
                    )
                }
            })
        }
        );

        userGroupedFields.push(<ModalPopup
            isOpen={this.state.shouldShowModal}
            onRequestClose={this.closeModal}>
            <div>
                <Translate>Warning:EmailExists</Translate>
            </div>
            <div className="modal__spread-buttons">
                <button type="button" className="btn btn--sm marginRight-5" id="btnSave2" onClick={() => this.handleSaving()}><Translate>Button:Save</Translate></button>
                <button type="button" className="button button-small" id="btnCancel2" onClick={() => this.closeModal()}><Translate>Button:Cancel</Translate></button>
            </div>
        </ModalPopup>)
        userGroupedFields.push(<React.Fragment key="UserDetail_split2"><div className="l-box--wide">&nbsp;</div></React.Fragment>)
    }

    private addButtons(userGroupedFields: JSX.Element[], isBeginningOfForm: boolean) {
        const uid = Guid.newGuid();
        if (this._formConfig != null &&
            this._formConfig.find(fc => fc.fields.find(f => f.readonly === false) != null) != null) {
            if (this.state.editMode) {
                // Page edit mode:     
                // Add save and cancel button.
                userGroupedFields.push(
                    <React.Fragment>
                        <div
                            key={`UserDetail_Button_${uid}${isBeginningOfForm ? '_true' : '_false'}`}
                            id={'btnContainer_' + uid}
                            className={'user-detail__button-container ' +
                                (isBeginningOfForm ? 'user-detail__button-container--start ' : 'user-detail__button-container--end ') +
                                (globalConfig.layoutProperties.modalButtonReverse ? 'reverted' : '')}>
                            <button type="button" className="button button-small button--secondary marginRight-5" id="btnSave"
                                disabled={!this.state.formValid} onClick={(e) => this.saveUser(e)}>

                                <Translate>{'Button:Save'}</Translate>
                            </button>
                            <button className="button button-small button--secondary marginRight-5" id="btnCancel" onClick={(e) => this.handleCancelClick(e)}>
                                {<Translate>{'Button:Cancel'}</Translate>}
                            </button>
                        </div>
                    </React.Fragment>
                )
            }
            else {
                // Page view mode:
                // Add edit button.

                if (this.state.modifyUser != null) {
                    if (this.state.modifyUser.isPublic) {
                        userGroupedFields.push(
                            <div
                                key={`UserDetial_Button_${uid}${isBeginningOfForm ? '_true' : '_false'}`}
                                id={'btnContainer_' + uid}
                                className={'user-detail__button-container ' +
                                    (isBeginningOfForm ? 'user-detail__button-container--start' : 'user-detail__button-container--end')}>
                                <Translate>UserProfile:IsPublicHint</Translate>
                            </div>
                        )
                    }
                    else {
                        userGroupedFields.push(
                            <div
                                key={`UserDetail_Button_${uid}${isBeginningOfForm ? '_true' : '_false'}`}
                                id={'btnContainer_' + uid}
                                className={'user-detail__button-container ' +
                                    (isBeginningOfForm ? 'user-detail__button-container--start' : 'user-detail__button-container--end')}>
                                <button className="button button-small button--secondary" id="btnEdit" onClick={this.handleEditClick}>
                                    <Translate>{'Button:Edit'}</Translate>
                                </button>
                            </div>
                        )
                    }
                }
            }
            userGroupedFields.push(<div style={{ width: '100%' }}>&nbsp;</div>);
        }
    }
}

export default UserDetails;
