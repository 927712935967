import Session from '$src/core/Session';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    value: string;
    onChange: (event: {
        value: string;
        operator: string | Function;
        syntheticEvent: React.SyntheticEvent<any>;
    }) => void;
}

export default function DropDownFilterCell(data: string[], defaultItem: string) {
    return class DropDownFilterCellCalss extends React.Component<IProps, {}> {
        constructor(props: IProps) {
            super(props);
        }
        public hasValue(value: string) {
            return value && value !== defaultItem;
        }

        public render() {
            return (
                <div className="k-filtercell">
                    <DropDownList
                        data={data}
                        onChange={(event) => {
                            const hasValue = this.hasValue(event.target.value);
                            this.props.onChange({
                                operator: hasValue ? 'eq' : '',
                                syntheticEvent: event.syntheticEvent,
                                value: hasValue ? event.target.value : '',
                            });
                        }}
                        value={this.props.value || defaultItem}
                        defaultItem={defaultItem}
                    />
                    <button
                        className="k-button k-button-icon k-clear-button-visible"
                        aria-label={Session.instance.storage.translation.GetString('Reporting:Clear')}
                        data-tip={Session.instance.storage.translation.GetString('Reporting:Clear')}
                        data-for="kFilterCellClearTooltip"
                        disabled={!this.hasValue(this.props.value)}
                        onClick={(event) => {
                            event.preventDefault();
                            this.props.onChange({
                                operator: '',
                                syntheticEvent: event,
                                value: '',
                            });
                        }}
                    >
                        <Tooltip id="kFilterCellClearTooltip" />
                        <span className="k-icon k-i-filter-clear" />
                    </button>
                </div>
            );
        }
    };
}