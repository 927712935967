import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import DropDownFilterCell from '$components/reporting/DropDownFilterCell';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { ExternalCourseEmployeeRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseEmployeeRegistration';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import { FilterDescriptor, process } from '@progress/kendo-data-query';
import { AggregateDescriptor, DataResult, State } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridExpandChangeEvent,
    GridRowClickEvent,
    GridToolbar
} from '@progress/kendo-react-grid';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { StringHelper } from '$src/util/StringHelper';

interface IState {
    dataResult: DataResult | undefined;
    dataState: State;
    isDataLoaded: boolean;
    errorMessage: string;
    externalCourseEmployeeRegistrations: ExternalCourseEmployeeRegistration[];
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class ReportExternalCourseRegistration extends React.Component<RouteComponentProps, IState> {
    private pageTitle = 'Navigation:ReportingExternalCourseRegistration';
    private aggregates: AggregateDescriptor[] = [
        { field: 'classPrice', aggregate: 'sum' },
    ];
    private yearFilterCell: any;
    private _export: ExcelExport | null;
    private userIsAllowed: boolean;

    constructor(props: RouteComponentProps) {
        super(props);


        const dataState: State = {
            group: [
                { field: 'registeredUserFullNameAndEmail', dir: 'asc', aggregates: this.aggregates }
            ],
            skip: 0,
            take: 10
        };

        this.state = {
            dataResult: undefined,
            dataState,
            errorMessage: '',
            externalCourseEmployeeRegistrations: [],
            isDataLoaded: false,
        }

        const navConfiguration = globalConfig.navigationProperties.extendedNavigationItems.filter(mp => mp.link === this.props.location.pathname)[0];
        const isUserBossOfDefaultRelation = Session.instance.isLoggedInUserBossOfDefaultRelation;
        this.userIsAllowed = (navConfiguration
            && !(navConfiguration.mustBeBossOfDefaultRelation && !isUserBossOfDefaultRelation)
            && (navConfiguration.allowedRoles === undefined || Session.instance.hasCurrentUserAtLeastOneOfRoles(navConfiguration.allowedRoles)));
    }

    public componentDidMount() {
        if (this.userIsAllowed) {
            this.getExternalCourseRegistrations();
        } else {
            this.setState({ isDataLoaded: true });
        }

        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString(this.pageTitle);
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return (<ProgressSpinner />)
        } else {
            return (
                <div>
                    <div className="l-container">
                        <MenuBreadCrumb
                            breadCrumbElementsToAppend={[{
                                navigationPath: this.props.location.pathname,
                                title: 'Navigation:ReportingExternalCourseRegistration',
                                translateTitle: true
                            }]}
                            {...this.props} />
                        <div>
                            <Heading headingLevel={1} cssClass="heading__Title">
                                <Translate>{this.pageTitle}</Translate>
                            </Heading>
                        </div>
                        {!this.userIsAllowed ?
                            (<span className={'input-message error'}>
                                <Translate>Reporting:UserNotAllowed</Translate>
                            </span>) :
                            (this.renderReport())}

                    </div>
                </div>
            )
        }
    }

    private renderReport(): JSX.Element {
        const years = Array.from(new Set(this.state.externalCourseEmployeeRegistrations.map(e => e.registrationYear.toString())));
        this.yearFilterCell = DropDownFilterCell(years, Session.instance.storage.translation.GetString('Reporting:SelectAll'));
        return (
            <React.Fragment>
                {this.renderExcelExport()}
                <Grid
                    data={this.state.dataResult}
                    {...this.state.dataState}
                    onDataStateChange={(e) => this.dataStateChange(e)}
                    pageable={{ pageSizes: globalConfig.reporting.pageSizes }}
                    sortable={true}
                    filterable={true}
                    onExpandChange={(e) => this.expandChange(e)}
                    expandField="expanded"
                    cellRender={(tdElement, cellProps) => this.cellRender(tdElement, cellProps)}
                    groupable={{ enabled: false, footer: 'visible' }}
                    onRowClick={(e) => this.onRowClick(e)}
                >
                    <GridToolbar>
                        <button
                            className="btn--sm btn--primary"
                            onClick={() => this.export()}
                        >
                            <Translate>Reporting:ExportToExcel</Translate>
                        </button>
                    </GridToolbar>
                    <GridColumn field="itemTitle" title={Session.instance.storage.translation.GetString('Reporting:ItemTitle')} filterable={false} />
                    <GridColumn field="scheduleSpan" title={Session.instance.storage.translation.GetString('Reporting:ScheduleSpan')} filterable={false} />
                    <GridColumn className="k-grid-currency-column" field="classPrice" title={Session.instance.storage.translation.GetString('Reporting:Price')} filterable={false}
                        footerCell={() => this.totalFooterRender()} />
                    <GridColumn field="registrationYear" title={Session.instance.storage.translation.GetString('Reporting:RegistrationYear')} filterCell={this.yearFilterCell} />
                </Grid>
            </React.Fragment>
        )
    }

    private cellRender(tdElement: React.ReactElement<HTMLTableCellElement> | null, cellProps: GridCellProps): React.ReactElement<HTMLTableCellElement> | null {
        if (cellProps.rowType === 'groupFooter' && cellProps.field === 'classPrice') {
            const sum: number = cellProps.dataItem.aggregates.classPrice.sum;
            return (
                <td className="k-grid-currency-column">
                    {Session.instance.storage.translation.GetString('Reporting:Sum').Format(StringHelper.numberString(sum))}
                </td>
            );
        } else if (cellProps.field === 'classPrice' && cellProps.dataItem['classPrice'] != undefined) {
            let price = 0.00;
            if (cellProps.dataItem['classPrice']) {
                price = cellProps.dataItem['classPrice'];
            }
            return (
                <td className="k-grid-currency-column">
                    {StringHelper.numberString(price)}
                </td>
            );
        }
        return tdElement;
    }

    private totalFooterRender(): JSX.Element {
        let registrations = this.state.externalCourseEmployeeRegistrations;
        if (this.state.dataState.filter != null) {
            this.state.dataState.filter.filters.map(f => {
                const filter = f as FilterDescriptor
                if (filter.field === 'registrationYear' && !isNaN(filter.value)) {
                    registrations = registrations.filter(reg => reg.registrationYear === Number(filter.value))
                }
            });

        }
        const total = registrations.reduce((acc, current) => acc + current.classPrice, 0);
        return (
            <td className="k-grid-currency-column">
                {Session.instance.storage.translation.GetString('Reporting:Total').Format(StringHelper.numberString(total))}
            </td>
        )
    }

    private dataStateChange(event: GridDataStateChangeEvent) {
        this.setState({
            dataResult: process(this.state.externalCourseEmployeeRegistrations, event.data),
            dataState: event.data
        });
    }

    private renderExcelExport(): JSX.Element {
        return (
            <ExcelExport
                data={this.state.externalCourseEmployeeRegistrations}
                ref={(exporter) => this._export = exporter}
                fileName="ExternalCourseRegistraionCosts.xlsx"
            >
                <ExcelExportColumn field="registeredUserFullNameAndEmail" title={Session.instance.storage.translation.GetString('Reporting:RequestUser')} />
                <ExcelExportColumn field="itemTitle" title={Session.instance.storage.translation.GetString('Reporting:ItemTitle')} />
                <ExcelExportColumn field="scheduleSpan" title={Session.instance.storage.translation.GetString('Reporting:ScheduleSpan')} />
                <ExcelExportColumn field="registrationYear" title={Session.instance.storage.translation.GetString('Reporting:RegistrationYear')} />
                <ExcelExportColumn field="classPrice" title={Session.instance.storage.translation.GetString('Reporting:Price')} />
            </ExcelExport>
        )
    }

    private async getExternalCourseRegistrations() {
        const response = await ExternalCourseService.instance.getExternalCourseEmployeeRegistrations();
        if (isSuccess<ExternalCourseEmployeeRegistration[]>(response)) {
            this.setState({
                dataResult: process(response, this.state.dataState),
                externalCourseEmployeeRegistrations: response,
                isDataLoaded: true
            })
        } else {
            if (response.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode), isDataLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesEmployeeRegistrationFailed', isDataLoaded: true });
            }
        }
    }

    private expandChange(event: GridExpandChangeEvent) {
        const expandedField = event.target.props.expandField;
        if (expandedField) {
            event.dataItem[expandedField] = event.value;
            this.setState({
                dataResult: Object.assign({}, this.state.dataResult)
            });
        }
    }

    private export() {
        if (this._export != null) {
            this._export.save();
        }
    }

    private onRowClick(event: GridRowClickEvent) {
        if (event.dataItem.registrationId) {
            this.props.history.push(this.getLink(event.dataItem.registrationId));
        }
    }

    private getLink(registrationId: number): string {
        return `/requestDetail/${registrationId}?context=${EItemDetailCallerContextType.ExternalCourseCostReport}&itemSubType=4`;
    }
}