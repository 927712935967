import { JsonObject, JsonProperty } from 'json2typescript';
import { ELessonStatus } from '$storage/models/enums';
import { LessonStatusConverter } from '$src/storage/models/converters/LessonStatusConverter';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';

/** Model Class for the search function lazy fetch result .
 */
@JsonObject('LazyFetchSearchResult')
export class LazyFetchSearchResult {

    /** lessonstatus of the item. */
    @JsonProperty('lessonStatus', LessonStatusConverter, false)
    public lessonStatus: ELessonStatus = ELessonStatus.NotAttempted;


    @JsonProperty('isAssignment', Boolean, false)
    public isAssignment: boolean = false;

    @JsonProperty('assignmentId', Number, false)
    public assignmentId: number = 0;

    @JsonProperty('areInputSkillsFulfilled', Boolean, false)
    public areInputSkillsFulfilled: boolean = false;

    @JsonProperty('isRatingEnabled', Boolean, false)
    public isRatingEnabled: boolean = false;

    @JsonProperty('ratingAverage', Number, false)
    public ratingAverage: number = 0;

    @JsonProperty('ratingCount', Number, false)
    public ratingCount: number = 0;

    @JsonProperty('myRating', Number, false)
    public myRating: number = 0;

    @JsonProperty('isLockedDueToAssignments', Boolean, false)
    public isLockedDueToAssignment: boolean = false;
    
    /** Valid Date indicates the lesson is locked due to failed attempts */
    @JsonProperty('lessonLockedByAttemptsUntil', DateTimeConverter, false)
    public lessonLockedByAttemptsUntil: Date | undefined = undefined;

    @JsonProperty('itemIcon', String, '')
    public itemIcon: string = '';
}