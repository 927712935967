import ServiceClient from "$core/ServiceClient";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";
import { UserResponse } from "$src/storage/models/MultiFactorAuthentication/UserResponse";
import { MoreDetailsCheckRequest } from "$src/storage/models/RequestObjects/MultiFactorAuthentication/MoreDetailsCheckRequest";
import { UpdateData } from "$src/storage/models/RequestObjects/MultiFactorAuthentication/UpdateData";
import GtError from "$src/util/GtError";

export default class MultiFactorAuthenticationService extends ServiceClient {
    protected static _instance: MultiFactorAuthenticationService | null = null;

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): MultiFactorAuthenticationService {
        return this._instance || (this._instance = new this());
    }

    public async checkInitialCode(initialCode: string, domainName: string): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('mfa/checkInitialCode',
            BooleanResponse,
            undefined,
            [{ name: 'code', value: encodeURIComponent(initialCode) }, { name: 'domainName', value: domainName }]);
    }

    public async checkMoreDetails(moreDetailsCheckRequest: MoreDetailsCheckRequest): Promise<UserResponse | GtError> {
        return await this.post<UserResponse>('mfa/checkMoreDetails',
            moreDetailsCheckRequest,
            UserResponse);
    }

    public async updateData(updateData: UpdateData): Promise<UserResponse | GtError> {
        return await this.post<UserResponse>('mfa/updateData',
            updateData,
            UserResponse);
    }
}