import { Translate } from '$src/components/shared/Translate';
import React from 'react';

interface IProps {
    acceptFilter: string;
    isDisabled?: boolean;
    isMultiple?: boolean;
    onUploadChange?: (files: File[]) => void;
    labeledBy: string;
    maxFileSizeInByte?: number;
    linkCssClass: string;
    allowAllFileTypes?: boolean;
    id?: string;
}

interface IState {
    isDraggedOver: boolean;
    modalIsOpen: boolean;
    errorTitle: string;
    errorMessage: string;
}

export default class InputFile extends React.Component<IProps, IState> {
    protected fileInput: React.RefObject<HTMLInputElement>;
    private files: File[];
    private acceptedTypes = new Array<string>();

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            errorTitle: '',
            isDraggedOver: false,
            modalIsOpen: false
        }

        if (this.props.acceptFilter.length > 0) {
            this.acceptedTypes = this.props.acceptFilter.toLocaleLowerCase().split(',');
        }

        // Allow some File Types if allowAllFileTypes is null or false and no acceptFilter was defined
        if ((this.props.allowAllFileTypes == null ||
            !this.props.allowAllFileTypes) &&
            this.props.acceptFilter.length === 0) {
            this.acceptedTypes = ['.txt', '.pdf', '.pps', '.jpeg', '.rtf', '.csv', '.pptx', '.png', '.doc', '.xls', '.gif', '.zip',
                '.odt', '.xlsx', '.jpg', '.rar', '.docx', '.ppt'];
        }

        this.fileInput = React.createRef();
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.dragLeaveHandler = this.dragLeaveHandler.bind(this);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.dragOverHandler = this.dragOverHandler.bind(this);
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.dropHandler = this.dropHandler.bind(this);
    }

    public render() {
        return (
            <div className={`input-field--file ${this.state.isDraggedOver && !this.props.isDisabled ? 'isDraggedOver' : ''}`} key="FileInput_Wrapper"
                // eslint-disable-next-line @typescript-eslint/unbound-method
                onDragOver={this.dragOverHandler}
                // eslint-disable-next-line @typescript-eslint/unbound-method
                onDragLeave={this.dragLeaveHandler}
                // eslint-disable-next-line @typescript-eslint/unbound-method
                onDrop={this.dropHandler}
            >
                {this.state.isDraggedOver === false ? this.renderDragInfo() : this.renderDropInfo()}
            </div >
        )
    }

    protected renderDragInfo() {
        return (
            <React.Fragment>
                <p><Translate>InputFile:DragAndDropFile</Translate></p>
                <p><Translate>InputFile:Or</Translate></p>
                <button
                    className={this.props.linkCssClass}
                    onClick={(event) => this.onButtonClick(event)}>
                    <Translate>InputFile:SelectDocument</Translate>
                </button>
                <input
                    id={this.props.id}
                    type="file"
                    multiple={this.props.isMultiple}
                    accept={this.props.acceptFilter}
                    disabled={this.props.isDisabled}
                    hidden={true}
                    ref={this.fileInput}
                    onChange={(e) => this.fileInputChanged(e)}
                    aria-labelledby={this.props.labeledBy}
                    onClick={(event) => this.onInputClick(event)} />
                {this.state.errorMessage.length > 0 &&
                    <span className={'input-message error'}>
                        <Translate>{this.state.errorMessage}</Translate>
                    </span>}
            </React.Fragment>
        );
    }

    protected renderDropInfo() {
        return (
            <React.Fragment>
                <p>&nbsp;</p>
                <p><Translate>InputFile:DropFile</Translate></p>
                <input type="file" style={{ 'visibility': 'hidden' }} />
            </React.Fragment>
        );
    }

    protected fileInputChanged(event: React.FormEvent<HTMLInputElement>) {
        if (this.props.isDisabled) {
            return;
        }

        if (event.currentTarget.files != null) {
            this.addFiles(Array.from(event.currentTarget.files));
            this.fileUploadChanged();
        }
    }

    protected onInputClick(event: React.FormEvent<HTMLInputElement>) {
        const element = event.target as HTMLInputElement;
        element.value = '';
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dropHandler(event: any) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();

        if (this.props.isDisabled) {
            return;
        }
        const inputFiles = event.dataTransfer.files ||
            event.dataTransfer.items.filter((file: { kind: string }) => file.kind === 'file').map((file: { getAsFile: () => void }) => file.getAsFile());
        this.addFiles(Array.from(inputFiles));

        this.fileUploadChanged();

        // Pass event to removeDragData for cleanup
        this.removeDragData(event);

        this.setState({ isDraggedOver: false });
    }

    private addFiles(inputFiles: File[]) {
        const validFiles = inputFiles.filter(file => this.isTypeAccepted(file));
        if (validFiles.length < inputFiles.length) {
            this.setState({ errorMessage: 'InputFile:InvalidFileType' });
        }

        const sizedValidFiles = validFiles.filter(file => this.isSizeAccepted(file));

        if (sizedValidFiles.length < validFiles.length) {
            this.setState({ errorMessage: 'InputFile:InvalidFileSize' });
        }
        this.files = this.props.isMultiple ? sizedValidFiles :
            sizedValidFiles.length > 1 ? sizedValidFiles.splice(0, sizedValidFiles.length - 1) : sizedValidFiles;
    }

    private dragOverHandler(event: React.DragEvent<HTMLDivElement>) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();

        if (this.props.isDisabled) {
            return;
        }

        this.setState({ isDraggedOver: true });
    }

    private dragLeaveHandler(event: React.DragEvent<HTMLDivElement>) {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();

        if (this.props.isDisabled) {
            return;
        }

        this.setState({ isDraggedOver: false });
    }

    private removeDragData(event: React.DragEvent<HTMLDivElement>) {
        if (event.dataTransfer.items) {
            // Use DataTransferItemList interface to remove the drag data
            event.dataTransfer.items.clear();
        } else {
            // Use DataTransfer interface to remove the drag data
            event.dataTransfer.clearData();
        }
    }

    private isTypeAccepted(file: File): boolean {
        if (this.acceptedTypes.length <= 0 || this.acceptedTypes.includes('*')) {
            return true;
        }

        const extension = this.getExtension(file.name.toLocaleLowerCase());
        if (this.acceptedTypes.includes(extension)) {
            return true;
        } else {
            return false;
        }
    }

    private isSizeAccepted(file: File): boolean {
        if (this.props.maxFileSizeInByte && this.props.maxFileSizeInByte > 0 && file.size > this.props.maxFileSizeInByte) {
            return false;
        } else {
            return true;
        }
    }

    private getExtension(filename: string): string {
        return '.' + filename.split('.').pop();
    }

    private fileUploadChanged() {
        if (this.files.length > 0) {
            if (this.props.onUploadChange) {
                this.props.onUploadChange(this.files);
            }
            this.files = new Array<File>();
            this.setState({ errorMessage: '' })
        }
    }

    private onButtonClick(event: React.MouseEvent) {
        event.preventDefault();
        if (this.fileInput !== null && this.fileInput.current !== null) {
            this.fileInput.current.click();
        }
    }
}