import { JsonObject, JsonProperty } from 'json2typescript';

// tslint:disable-next-line:max-classes-per-file

/** Model to get the Credentials of the server side configured guest user */
@JsonObject
export class GuestUserCredentials {

    @JsonProperty('username', String, false)
    public username: string = '';
    @JsonProperty('password', String, false)
    public password: string = '';
    @JsonProperty('domain', String, false)
    public domain: string = '';

}
