import { JsonObject, JsonProperty } from 'json2typescript';

// tslint:disable-next-line:max-classes-per-file

/** Model to get the Credentials of the server side configured guest user */
@JsonObject
export class MorePanel {

    @JsonProperty('id', String, false)
    public id: string = '';
    @JsonProperty('expanded', Boolean, false)
    public expanded: boolean = true;

    constructor(id: string,  expanded: boolean){
        this.id = id;
        this.expanded = expanded;
    }
}