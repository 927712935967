import { JsonObject, JsonProperty } from 'json2typescript';

/**
 *This Model is for showing the groups for MailTo
 */
@JsonObject
export class GroupMailLink {

    /** GroupId  */
    @JsonProperty('groupId', Number, false)
    public groupId: number = 0;

    /** GroupName*/
    @JsonProperty('groupName', String, false)
    public groupName: string = "";

}
