import React from 'react';

import { DropDownList, DropDownListChangeEvent, MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';

import { Heading } from '$components/shared/Heading';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import Logger from '$core/Logger';
import SkillProfileService from '$core/Services/SkillProfileService';
import TeamService from '$core/Services/TeamService';
import Session from '$core/Session';
import { AttributeValue } from '$storage/models/AttributeValue';
import { AssignSkillProfileRequest } from '$storage/models/RequestObjects/AssignSkillProfileRequest';
import { AssignSkillProfileResponse, AssignSkillProfileResponseContainer } from '$storage/models/SkillProfile/AssignSkillProfileResponse';
import { SkillProfile } from '$storage/models/SkillProfile/SkillProfile';
import { User } from '$storage/models/User';
import CustomErrorMessage from '$util/CustomErrorMessage';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import { filterBy } from '@progress/kendo-data-query';

interface IProps {
    isOpen: boolean;
    onAssignSuccess?: (assignments: AssignSkillProfileResponse[]) => void;
    onClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    employees: User[];
    headingClassName?: string;
    headingLevel: number;
    bossRelationCode: string;
}

interface IStateData {
    skillProfileTypes: AttributeValue[] | undefined;
    skillProfileTypesLoaded: boolean;

    skillProfiles: SkillProfile[] | undefined;
    skillProfilesLoaded: boolean;
    skillProfileFilter: string;

    skillProfilesFiltered: SkillProfile[] | undefined;

    selectedProfileType: AttributeValue | undefined;
    selectedProfiles: SkillProfile[] | undefined;

    storedData: undefined | AssignSkillProfileResponse[];
}

interface IState extends IStateData {
    errorMessage: string;
}

export class AssignSkillProfileModal extends React.Component<IProps, IState>{

    protected className = 'AssignSkillProfileModal';
    protected loggerLocality = 'Components.AssignSkillProfileModal';
    private TRANSLATION_PREFIX = 'AssignSkillProfile'

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            selectedProfiles: undefined,
            selectedProfileType: undefined,
            skillProfileFilter: '',
            skillProfileTypes: undefined,
            skillProfileTypesLoaded: false,
            skillProfiles: undefined,
            skillProfilesFiltered: undefined,
            skillProfilesLoaded: false,

            storedData: undefined
        }


    }

    public componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        Logger.log(this.loggerLocality, `${methodName} called`);
        this.loadSkillProfileTypes();
        this.loadSkillProfiles();
    }

    public componentDidUpdate(prevProps: IProps) {
        const methodName = `${this.className}:componentDidUpdate()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
            Logger.log(this.loggerLocality, `${methodName} open state changed, setting state`);
            this.setState({ selectedProfiles: undefined, selectedProfileType: undefined, skillProfileFilter: '' })
        }
    }

    public render() {
        const heading = <Heading
            headingLevel={this.props.headingLevel}
            cssClass={this.props.headingClassName != null ? this.props.headingClassName : 'heading__Level2'}>
            <Translate>{`${this.TRANSLATION_PREFIX}:Title`}</Translate>
        </Heading>;

        return (
            <ModalPopup
                isOpen={this.props.isOpen}
                onRequestClose={(e) => this.closeModal(e)}
                contentCssClass="assign-skill-profile">
                <form className="assign-skill-profile__form">
                    {heading}
                    {this.renderContent()}
                    {this.renderButtons()}
                </form>
            </ModalPopup>
        );
    }

    private renderContent(): JSX.Element {

        if (this.state.skillProfilesLoaded && this.state.skillProfileTypesLoaded && this.state.storedData == null) {
            return this.renderSkillProfileContent();
        }
        else if (this.state.storedData != null) {
            return this.renderResults();
        }
        else {
            return <ProgressSpinner />;
        }
    }

    private renderButtons(): JSX.Element {
        return <div
            key={`assign-skill-profile__button__container`}
            className={'assign-skill-profile__button-container modal__spread-buttons'}>
            {!this.state.storedData &&
                <button
                    type="button"
                    className="btn btn--sm"
                    disabled={!this.state.selectedProfiles || this.state.selectedProfiles.length === 0}
                    onClick={() => this.assignSkillProfile()}
                >
                    <Translate>{`${this.TRANSLATION_PREFIX}:Save`}</Translate>
                </button>
            }
            <button
                type="button"
                className="btn btn--sm"
                onClick={(e) => this.closeModal(e)}
            >
                {
                    this.state.storedData != null ?
                        <Translate>{`${this.TRANSLATION_PREFIX}:Close`}</Translate> :
                        <Translate>{`${this.TRANSLATION_PREFIX}:Cancel`}</Translate>
                }

            </button>
        </div>
    }

    private renderSkillProfileContent(): JSX.Element {
        const allSkillProfiles = Object.assign(new SkillProfile(),
            {
                skillProfileID: 0,
                skillProfileTitle: Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:AllSkillProfiles`)
            })
        const skillProfilesFiltered = [allSkillProfiles, ...this.state.skillProfilesFiltered!];

        const allSkillProfileTypes = Object.assign(new AttributeValue(),
            {
                id: 0,
                text: Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:AllSkillProfileTypes`)
            });
        const selectedProfileType = this.state.selectedProfileType || allSkillProfileTypes;
        const skillProfileTypeData = [allSkillProfileTypes, ...this.state.skillProfileTypes!];

        return <React.Fragment>
            <DropDownList
                data={skillProfileTypeData}
                label={Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:SkillProfileType`)}
                dataItemKey="id"
                textField="text"
                defaultValue={allSkillProfileTypes}
                onChange={(e) => this.handleSkillProfileTypeSelectionChanged(e)}
                value={selectedProfileType}
            />
            <MultiSelect
                data={skillProfilesFiltered}
                label={Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:SkillProfile`)}
                dataItemKey="skillProfileID"
                textField="displayName"
                filterable={true}
                onChange={(e) => this.handleSkillProfileSelectionChanged(e)}
                onFilterChange={(e) => this.skillProfileFilterChanged(e)}
                value={this.state.selectedProfiles}
                allowCustom={false}
            />
        </React.Fragment>
    }

    private skillProfileFilterChanged(event: MultiSelectFilterChangeEvent) {
        const methodName = `${this.className}:skillProfileFilterChanged()`;

        const skillProfileFilter = event.filter.value;
        if (skillProfileFilter !== '') {
            Logger.log(this.loggerLocality, `${methodName} filter is ${event.filter.value}`);
            this.setState({
                skillProfileFilter
            });
            if (skillProfileFilter !== (this.state.selectedProfiles != null)) {
                this.setState({
                    selectedProfiles: undefined
                });
            }
        } else {
            Logger.log(this.loggerLocality, `${methodName} filter is empty`);
            this.setState({
                selectedProfiles: undefined,
                skillProfileFilter: ''
            });
        }
        this.filterSkillProfiles(this.state.selectedProfileType != null ? this.state.selectedProfileType.id : 0, skillProfileFilter);
    }

    private renderResults(): JSX.Element {
        if (this.state.storedData != null) {
            return <Alert
                alertType="mixed"
                alertAppereance="box"
                className="no-background no-padding no-margin"
                messages={this.getResultMessages()}
            />
        }
        return <React.Fragment />;
    }

    private handleSkillProfileTypeSelectionChanged(e: DropDownListChangeEvent) {
        if (e.target.value instanceof AttributeValue) {
            this.setState({ selectedProfileType: e.target.value, selectedProfiles: undefined, skillProfileFilter: '' });
            this.filterSkillProfiles(e.target.value.id, this.state.skillProfileFilter);
        }
    }
    private handleSkillProfileSelectionChanged(e: MultiSelectChangeEvent) {
        const methodName = `${this.className}:handleSkillProfileSelectionChanged()`;
        Logger.log(this.loggerLocality, `${methodName} called`);

        const values = e.target.value
        const lastItem = values[values.length - 1]
        if (lastItem != null && lastItem.skillProfileID == 0) {
            Logger.log(this.loggerLocality, `Got id == 0, add all`)
            this.setState({ selectedProfiles: this.state.skillProfilesFiltered })
        }
        else if (e.target.value.length == 0) {
            Logger.log(this.loggerLocality, `No elements, empty the list`)
            this.setState({ selectedProfiles: [] })
        }
        else {
            Logger.log(this.loggerLocality, `Add selected values to list`)
            this.setState({ selectedProfiles: [...e.target.value] })
        }
    }
    private filterSkillProfiles(selectedProfileTypeID: number | null, skillProfileFilter: string): void {

        let skillProfilesFiltered = this.state.skillProfiles;

        if (skillProfilesFiltered === undefined) { return skillProfilesFiltered }

        // Filter out by selected profile type
        if (selectedProfileTypeID != null && selectedProfileTypeID !== 0) {

            const skillProfileTypeFilter = {
                field: 'skillProfileTypeID',
                ignoreCase: true,
                operator: 'eq',
                value: selectedProfileTypeID,
            };

            skillProfilesFiltered = filterBy(skillProfilesFiltered, skillProfileTypeFilter);
        }


        // Filter out by entered text
        if (skillProfileFilter != null && skillProfileFilter !== '') {
            const skillProfileInputTextFilter = {
                field: 'displayName',
                ignoreCase: true,
                operator: 'contains',
                value: skillProfileFilter,
            };
            skillProfilesFiltered = filterBy(skillProfilesFiltered, skillProfileInputTextFilter);
        }

        this.setState({ skillProfilesFiltered })
    }

    private async assignSkillProfile() {
        const methodName = `${this.className}:assignSkillProfile()`;
        Logger.log(this.loggerLocality, `${methodName} assigning skill profile to selected users`);

        const saveRequest = new AssignSkillProfileRequest();

        if (this.state.selectedProfiles != null) {
            this.state.selectedProfiles.forEach(profile => {
                saveRequest.skillProfileIds.push(profile.skillProfileID)
            });
            saveRequest.userIds = this.props.employees.map(e => e.id);
            const response = await TeamService.instance.assignSkillProfile(saveRequest);
            if (isSuccess<AssignSkillProfileResponseContainer>(response)) {
                Logger.log(this.loggerLocality, `${methodName} skill profile assigned to selected users`);
                if (this.props.onAssignSuccess) {
                    this.props.onAssignSuccess(response.assignSkillProfileResponse);
                }
                this.setState({ storedData: response.assignSkillProfileResponse });
            } else {
                const errorMessage = `${methodName} failed to assign skill profile to users. ${response.message}`;
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);

                this.setState({ errorMessage: this.TRANSLATION_PREFIX + ':Error' });
            }
        }
    }

    private async loadSkillProfileTypes() {
        const methodName = `${this.className}:loadSkillProfileTypes()`;
        const data: AttributeValue[] | GtError = await SkillProfileService.instance.getSkillProfileTypes(Session.instance.getUserLanguageCodeOrFallBack);

        if (isSuccess<AttributeValue[]>(data)) {
            Logger.log(this.loggerLocality, `${methodName} got skill profile types`);
            this.setState({
                skillProfileTypes: data,
                skillProfileTypesLoaded: true,
            });
        } else {
            const errorMessage = `${methodName} failed to load skill profile types. ${data.message}`;
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);

            if (data.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(data.detailedObject.subStatusCode), skillProfileTypesLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetSkillProfileTypesFailed', skillProfileTypesLoaded: true });
            }
        }
    }

    private async loadSkillProfiles() {
        const methodName = `${this.className}:loadSkillProfiles()`;
        const data: SkillProfile[] | GtError = await SkillProfileService.instance.getSkillProfiles(0, Session.instance.getUserLanguageCodeOrFallBack, this.props.bossRelationCode);

        if (isSuccess<SkillProfile[]>(data)) {
            Logger.log(this.loggerLocality, `${methodName} got skill profiles`);
            this.setState({
                skillProfiles: data,
                skillProfilesFiltered: data,
                skillProfilesLoaded: true,
            });
        } else {
            const errorMessage = `${methodName} failed to load skill profiles. ${data.message}`;
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);

            if (data.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(data.detailedObject.subStatusCode), skillProfilesLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetSkillProfilesFailed', skillProfilesLoaded: true });
            }
        }
    }

    private closeModal(e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) {
        const methodName = `${this.className}:closeModal()`;
        Logger.log(this.loggerLocality, `${methodName} closing modal and resetting state`);
        this.setState({
            errorMessage: '',
            selectedProfiles: undefined,
            selectedProfileType: undefined,
            storedData: undefined,
        });
        if (this.props.onClose != null) {
            this.props.onClose(e);
        }
    }


    private getResultMessages(): Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> {
        const methodName = `${this.className}:getResultMessages()`;
        const messages: Array<{ message: string; alertType: 'warning' | 'error' | 'info' | 'success' }> = [];

        this.props.employees.map(e => {
            if (this.state.storedData != null) {
                const storeResult = this.state.storedData.filter(s => s.userId === e.id);
                if (storeResult.length === 0) {
                    const errorMessageInvalidResult = `${methodName} invalid result for user ${e.username}`;
                    Logger.log(this.loggerLocality, errorMessageInvalidResult);
                    console.error(errorMessageInvalidResult);
                    // Got an empty Result
                    messages.push(
                        {
                            alertType: 'error',
                            message: `${e.fullName}: ${Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultNoResult`)}`
                        }
                    );
                } else {
                    storeResult.map(response => {
                        if (this.state.skillProfiles) {
                            const profile = this.state.skillProfiles.find(sp => sp.skillProfileID === response.skillProfileId);
                            if (profile) {
                                messages.push({
                                    alertType: response.assigned ? 'success' : response.isAlreadyAssigned ? 'warning' : 'error',
                                    message: `${e.fullName}: ${response.assigned ?
                                        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultSuccessfull`).Format(profile.displayName) :
                                        response.isAlreadyAssigned ?
                                            Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultAlredyAssigned`).Format(profile.displayName) :
                                            Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultError`).Format(profile.displayName)}`
                                });
                            } else {
                                // Profile not found
                                messages.push(
                                    {
                                        alertType: 'error',
                                        message: `${e.fullName}: ${Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultNoResult`)}`
                                    }
                                );
                            }
                        }
                    });
                }
            } else {
                const errorMessage = `${methodName} got no results`;
                Logger.log(this.loggerLocality, errorMessage);
                console.error(errorMessage);

                messages.push({
                    alertType: 'error',
                    message: `${e.fullName}: ${Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:ResultNoAnswer`)}`
                })
            }

        })

        return messages;
    }
}