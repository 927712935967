import { JsonObject, JsonProperty } from 'json2typescript';
import { GlossaryTermSummary } from '$src/storage/models/Glossary/GlossaryTermSummary';

@JsonObject
export class Glossar {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('sid', String, false)
    public sid: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';
 
    @JsonProperty('glossaryTermSummaries', [GlossaryTermSummary], false)
    public glossaryTermSummaries: GlossaryTermSummary[] = [];
}