import React from 'react';
import { UnmountClosed } from 'react-collapse';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';

import Icondoc from '$resources/svgs/misc/open-in-new-tab.svg';

import { ItemBreadCrumb } from '$components/breadCrumb/ItemBreadCrumb';
import RecommendationContainer from '$components/digitalCoach/RecommendationContainer';
import { F2FCourseDetails } from '$components/item/F2F/F2FCourseDetails';
import ItemInputSkills from '$components/Item/ItemInputSkills';
import { ItemMentorList } from '$components/item/ItemMentorList';
import { ItemOutputSkills } from '$components/Item/ItemOutputSkills';
import { ItemRegistrationActionConfirmation } from '$components/item/ItemRegistrationActionConfirmation';
import { Toolbar } from '$components/item/Toolbar/Toolbar';
import { ToolbarRemainingTime } from '$components/item/Toolbar/ToolbarRemainingTime';
import { TopAnchorToolbar } from '$components/item/Toolbar/TopAnchorToolbar';
import AttributeList from '$components/shared/Attributes/AttributeList';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import GlobalDescriptionService from '$core/Services/GlobalDescriptionService';
import ItemService from '$core/Services/ItemService';
import LessonService from '$core/Services/ItemService';
import RegistrationService from '$core/Services/RegistrationService';
import TrainingPlanService from '$core/Services/TrainingPlanService';
import Session from '$core/Session';
import { __gt_startCbt, __gt_startQuestionnaire, __gt_startWbt } from '$src/boot';
import FileSharing from '$src/components/fileSharing/FileSharing';
import { SummaryAttribute } from '$src/components/shared/Attributes/SummaryAttribute';
import { Registration } from '$src/storage/models/Registration';
import { WbtStartParameters } from '$src/storage/models/WbtStartParameters';
import { CbtCommandButton } from '$storage/models/CbtCommandButton';
import { ECancellationType, EFavoriteType, EItemDetailCallerContextType, EItemDetailCallerType, EItemSubType, EItemType, ELessonStatus, ERegistrationStatus }
    from '$storage/models/enums';
import { F2FClassDetail } from '$storage/models/F2F/F2FClassDetail';
import { File } from '$storage/models/File';
import { GlobalDescription } from '$storage/models/GlobalDescription';
import { Item } from '$storage/models/Item';
import { ItemSkills } from '$storage/models/ItemDetails/ItemSkills';
import { TrainingPlanSchedule } from '$storage/models/TrainingPlan/TrainingPlanSchedule';
import CustomErrorMessage from '$util/CustomErrorMessage';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import { StringHelper } from '$util/StringHelper';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import GlossaryList from '../Glossary/GlossaryList';
import { Assessment } from '$src/storage/models/Assessment';
import AssessmentService from '$src/core/Services/AssessmentService';
import { ItemRatingDetails } from '$components/itemRating/ItemRatingDetails';
import AuthService from '$src/core/Services/AuthService';
import { VirtualMeetingLink } from '$src/storage/models/F2F/VirtualMeetingLink';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import { DateTimeSpan } from '$src/storage/models/F2F/DateTimeSpan';
import { NumberHelper } from '$src/util/NumberHelper';
import { ItemHelper } from '$src/util/ItemHelper';
import { BrowserHelper } from '$src/util/BrowserHelper';
import { GlossarIdForBredCrumb } from '$src/storage/models/Glossary/GlossarIdForBreadCrumb';
import GTButton from '$src/components/shared/Atoms/GTButton';
import { Tooltip } from '$components/shared/Tooltip';
import ApplyRatingModal from '$src/components/itemRating/ApplyRatingModal';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import { VirtualMeetingSingleLink } from '$src/components/item/F2F/F2FVirtualMeetingSingleLink';
import ShoppingBasketPopUp from '$src/components/shoppingBasket/ShoppingBasketPopUp';

interface IMatchParams {
    lessonId: string;
}

type IProps = RouteComponentProps<IMatchParams>

interface IURLParamState {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number;
    parentTrainingPlanElementId: number;
    voucherCode: string | null;
}

interface IURLPerformanceState {
    userId?: number;
    skillId?: number;
    profileId?: number;
    bossRelationCode?: string;
}

interface ILessonState {
    globalDescription: GlobalDescription | null;
    lesson: Item | null;
    lessonIdLoaded: number;
}
interface ITPSchueduleState {
    trainingPlanScheduleId?: number;
}
interface IItemSkillState {
    itemSkills: ItemSkills | null;
}
interface ICbtButtonState {
    cbtCommandButtons?: CbtCommandButton[];
}
interface IFilesForItemState {
    filesForItem?: File[];
}
interface IF2FClassDetail {
    f2fClassDetail: F2FClassDetail[] | null;
}
interface ICollapseState {
    customAttributesCollapsed: boolean;
    mentorsCollapsed: boolean;
    documentFilesCollapsed: boolean;
    inputSkillsCollapsed: boolean;
    ratingCollapsed: boolean;
    outputSkillsCollapsed: boolean;
    glossaryCollapsed: boolean;
}

interface IState extends IURLParamState,
    IURLPerformanceState,
    ILessonState,
    ITPSchueduleState,
    IItemSkillState,
    ICbtButtonState,
    IFilesForItemState,
    ICollapseState,
    IF2FClassDetail {
    errorMessage: string;
    shouldShowModal: boolean;
    contextLessonId: number;
    assessmentDetails: Assessment | null;
    scheduleList: DateTimeSpan[] | null;
    shouldShowRatingModal: boolean;
    wbtParameter?: WbtStartParameters;
    showShoppingModal: boolean;
}

export class ItemDetail extends React.Component<IProps, IState> {
    protected className = 'ItemDetail';
    protected loggerLocality = 'Components.ItemDetail';

    protected _itemConfig = globalConfig.itemProperties;

    constructor(props: IProps) {
        super(props);
        this.state = {
            assessmentDetails: null,
            contextLessonId: 0,
            customAttributesCollapsed: false,
            documentFilesCollapsed: false,
            errorMessage: '',
            f2fClassDetail: null,
            filesForItem: undefined,
            globalDescription: null,
            inputSkillsCollapsed: false,
            itemDetailCallerContextType: EItemDetailCallerContextType.Undefined,
            itemSkills: null,
            lesson: null,
            lessonIdLoaded: 0,
            mentorsCollapsed: false,
            outputSkillsCollapsed: false,
            parentAssignmentId: 0,
            parentCatalogFolderId: 0,
            parentTrainingPlanElementId: 0,
            parentTrainingPlanId: 0,
            ratingCollapsed: false,
            shouldShowModal: false,
            trainingPlanScheduleId: 0,
            glossaryCollapsed: false,
            scheduleList: null,
            shouldShowRatingModal: false,
            wbtParameter: undefined,
            voucherCode: null,
            showShoppingModal: false,
        }
    }

    public async componentDidMount() {
        await this.loadData();
        // Execute Global Description
        await this.executeGlobalDescription();
    }

    public async componentDidUpdate() {
        // Set document.title, as long as App.tsx componentDidUpdate doesn't work
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;

        const params = this.props.match.params;
        const itemId: number = params.lessonId != null && !isNaN(Number(params.lessonId)) ? Number(params.lessonId) : 0;
        if (itemId !== this.state.lessonIdLoaded && itemId !== 0) {
            // On Recommendation Click
            await this.loadData();
            await this.executeGlobalDescription();
        }
    }

    public componentWillUnmount() {
        document.title = globalConfig.appProperties.title;
    }

    public render() {
        const methodName = `${this.className}:render()`;
        const { lessonId } = this.props.match.params;
        const itemId = lessonId != null && !isNaN(Number(lessonId)) ? Number(lessonId) : 0;
        if (itemId !== this.state.lessonIdLoaded) {
            // Not yet loaded
            return <ProgressSpinner />;
        }
        if (this.state.lesson === null) {
            // Not found
            return (
                <div className='l-container'>
                    <NoDataFound />
                </div>
            )
        }
        else {
            // Loaded
            let confirmation: JSX.Element;
            if (this.state.lesson.registrationRequiredEnabled && this.state.lesson.registrationStatus !== ERegistrationStatus.Accepted
                && this.state.lesson.registrationStatus !== ERegistrationStatus.Requested) {

                // This is for Items != F2F and TP with registration for registration
                Logger.log(this.loggerLocality, `${methodName} adding confirm registration message modal.`);

                // Other types not needed, since tp and course do not get registered here
                const bossSelectionReadonly = globalConfig.bossSelection.bossSelectionIsReadOnly_OtherTypes;
                const bossSelectionVisible = globalConfig.bossSelection.selectionIsVisible_OtherTypes;
                const bossSelectionRequired = globalConfig.bossSelection.bossSelectionIsRequired_OtherTypes;

                confirmation = <ItemRegistrationActionConfirmation
                    classTitle={this.state.lesson.title}
                    parentHeadingLevel={2}
                    register={true}
                    onExitClicked={() => this.closeModal()}
                    onRegistrationConfirmedClick={() => this.onRegistrationConfirmed(this.state.lesson != null ?
                        this.state.lesson.itemId : 0)}
                    cancellationReasonType={ECancellationType.Enrolment}
                    bossSelectionReadonly={bossSelectionReadonly}
                    bossSelectionVisible={bossSelectionVisible}
                    bossSelectionRequired={bossSelectionRequired}
                    itemID={itemId} />;
            }
            else {
                // This is for Cancellation
                Logger.log(this.loggerLocality, `${methodName} adding item registrtion action confirmation modal.`);
                confirmation = <ItemRegistrationActionConfirmation
                    itemID={itemId}
                    classTitle={this.state.lesson.title}
                    parentHeadingLevel={2}
                    register={false}
                    onExitClicked={() => this.closeModal()}
                    onCancelRegistrationConfirmedClick={
                        (cancellationReasonId: number | undefined, cancellationReasonText: string) =>
                            this.onCancelRegistrationConfirmed(this.state.lesson != null ?
                                this.state.lesson.registrationId : 0,
                                cancellationReasonId,
                                cancellationReasonText)}
                    cancellationType={this.state.lesson.isCancellationAllowed}
                    cancellationReasonType={ECancellationType.Enrolment} />;
            }
            // Render Details
            return (
                <React.Fragment>
                    {this.renderDetails()}
                    <ModalPopup
                        isOpen={this.state.shouldShowModal}
                        onRequestClose={() => this.closeModal()}>
                        <div className="modal__spread buttons">
                            {confirmation}
                        </div>
                    </ModalPopup>
                    <RecommendationContainer parentHeadingLevel={0} parentItemId={this.state.lessonIdLoaded} {...this.props} />
                    <ShoppingBasketPopUp closeModal={() => this.closeShoppingModal()} showModal={this.state.showShoppingModal} />
                </React.Fragment>
            )
        }
    }

    public closeShoppingModal() {
        this.setState({showShoppingModal: false});
    }


    protected async loadData() {
        const methodName = `${this.className}:loadData()`;
        const params = this.props.match.params;
        const itemId: number = params.lessonId != null && !isNaN(Number(params.lessonId)) ? Number(params.lessonId) : 0;
        // Load Lesson if param Item ID != loaded lesson id
        if (itemId !== this.state.lessonIdLoaded) {
            Logger.log(this.loggerLocality, `${methodName} URL Parameter changed, loading data.`);
            let activeRegistrations: Registration[] | null = null;
            let partialStateTpSchedule: ITPSchueduleState | null = null;
            let partialStateCbtButtons: ICbtButtonState | null = null;
            let partialStateFilesForDocument: IFilesForItemState | null = null;
            let partialF2FClassDetails: F2FClassDetail[] | null = null;
            let assessmentDetails: Assessment | null = null;
            let scheduleList: DateTimeSpan[] | null = null;
            // Get URL Parameters
            const partialStateUrlParamState = this.getURLParameters();
            const partialStateUrlPerformanceParamState = this.getURLPerformanceParameters();
            // Get Item Basic Data
            const itemDetail = this.getItemDetail(itemId, partialStateUrlParamState.itemDetailCallerContextType, partialStateUrlParamState.parentTrainingPlanId);
            const itemSkill = this.getItemSkills(itemId);
            const partialCombindedState = await Promise.all([itemDetail, itemSkill]); // Wait to load basic data
            if (globalConfig.ratingProperties.feedbackToolBaseUrl != null && partialCombindedState[0].lesson != null) {
                const wbtParameter = await LessonService.instance.getWbtStartParameters(partialCombindedState[0].lesson.itemId,
                    partialStateUrlParamState.itemDetailCallerContextType,
                    this.state.parentAssignmentId,
                    this.state.parentTrainingPlanId,
                    this.state.trainingPlanScheduleId);
                if (isSuccess<WbtStartParameters>(wbtParameter)) {
                    this.setState({ wbtParameter: wbtParameter });
                }
            }

            if (partialCombindedState[0].lesson != null && partialCombindedState[0].lesson.itemType == EItemType.WBT
                && partialCombindedState[0].lesson.isRatingEnabled && globalConfig.itemProperties.displayRatingModal
                && partialCombindedState[0].lesson.ratingMine == 0 && (partialCombindedState[0].lesson.lessonStatus == ELessonStatus.Completed || partialCombindedState[0].lesson.lessonStatus == ELessonStatus.Passed)) {
                this.setState({ shouldShowRatingModal: true });
            }
            // Get TP Schedule if needed
            if (partialStateUrlParamState.parentTrainingPlanId != null && partialStateUrlParamState.parentTrainingPlanId > 0) {
                partialStateTpSchedule = await this.getTrainingPlanSchedule(partialStateUrlParamState.parentTrainingPlanId);
            }

            // Get CbtCommandButtons if needed (EItemType.CBT)
            if (partialCombindedState[0].lesson != null && partialCombindedState[0].lesson.itemType === EItemType.CBT) {
                partialStateCbtButtons = await this.getCbtCommandButtons(itemId, partialStateUrlParamState.itemDetailCallerContextType, partialStateUrlParamState.parentTrainingPlanId);
            }

            if (partialCombindedState[0].lesson != null && partialCombindedState[0].lesson.itemType === EItemType.Assessment) {
                assessmentDetails = await Session.instance.storage.assessment.getAssessmentDetail(itemId);
            }

            // Get Item Documents if needed (EItemType.Document and EItemType.Assessment)
            if (partialCombindedState[0].lesson != null && (partialCombindedState[0].lesson.itemType === EItemType.Document ||
                partialCombindedState[0].lesson.itemType === EItemType.Assessment)) {
                partialStateFilesForDocument = await this.getFilesForItem(itemId, partialStateUrlParamState.itemDetailCallerContextType, partialStateUrlParamState.parentTrainingPlanId)
            }

            if (partialCombindedState[0].lesson != null &&
                partialCombindedState[0].lesson.allRegistrations != null &&
                partialCombindedState[0].lesson.allRegistrations.length > 0) {
                activeRegistrations = partialCombindedState[0].lesson.allRegistrations.filter(r =>
                    r.registrationStatus === ERegistrationStatus.Accepted ||
                    r.registrationStatus === ERegistrationStatus.Requested ||
                    r.registrationStatus === ERegistrationStatus.InWaitingList);
            }

            const isF2F = partialCombindedState[0].lesson != null && partialCombindedState[0].lesson.itemType === EItemType.F2FCourse;
            const isMultipleRegistrationAllowed = partialCombindedState[0].lesson != null && partialCombindedState[0].lesson.isMultipleRegistrationAllowed;
            const hasGlobalTemplate = partialCombindedState[0].lesson != null && partialCombindedState[0].lesson.globalTemplate != null;
            // Get Current Registered Class Detail if needed
            if (isF2F
                && // Is F2F
                // (!partialCombindedState[0].lesson.isMultipleRegistrationAllowed ||
                //     partialCombindedState[0].lesson.globalTemplate != null) && // Multi Registration is disabled or Global Description
                activeRegistrations != null && // There are active Registrations
                activeRegistrations.length > 0 //&& // User is Registered (Approved, Requested, InWaitingList)
                // (
                //     globalConfig.f2fProperties.customAttributesClassOnItemDetail != null ||
                //     partialCombindedState[0].lesson.globalTemplate != null
                // )
            ) { // Custom Class Attributes or Global Description should be shown
                await Promise.all(activeRegistrations.map(async (activeRegistration) => {
                    const classDetail = await Session.instance.storage.classDetails.getF2FClassDetails(activeRegistration.classId, partialStateUrlParamState.itemDetailCallerContextType,
                        undefined, partialStateUrlParamState.parentTrainingPlanId);
                    if (hasGlobalTemplate || !isMultipleRegistrationAllowed) {
                        if (hasGlobalTemplate || globalConfig.f2fProperties.customAttributesClassOnItemDetail != null) {
                            if (partialF2FClassDetails == null) {
                                partialF2FClassDetails = [];
                            }
                            if (classDetail != null) {
                                partialF2FClassDetails.push(classDetail);
                            }
                        }
                    }

                    if (scheduleList == null) {
                        scheduleList = [];
                    }
                    if (classDetail != null && classDetail?.schedulesList != null) {
                        scheduleList.push(...classDetail.schedulesList);
                    }
                }));
            }

            // Set State
            this.setState({
                // ...lessonState, // DK: wird in partialCombindedState[0] geladen
                ...partialCombindedState[0],
                ...partialCombindedState[1],
                ...partialStateUrlParamState,
                ...partialStateCbtButtons,
                ...partialStateFilesForDocument,
                ...partialStateTpSchedule,
                ...partialStateUrlPerformanceParamState,
                assessmentDetails,
                f2fClassDetail: partialF2FClassDetails,
                parentTrainingPlanElementId: partialStateUrlParamState.parentTrainingPlanElementId,
                scheduleList: scheduleList,
            });
        }
    }

    protected getURLParameters(): IURLParamState {
        const methodName = `${this.className}:getURLParameters()`;
        const parameters = new URLSearchParams(window.location.search);

        const urlParamContext = parameters.get('context');
        const urlParamAssingmentId = parameters.get('asId');
        const urlParamCatalogFolerId = parameters.get('catId');
        const urlParamTrainingPlanId = parameters.get('tpId');
        const urlParamTrainingPlanElementId = parameters.get('tpeId');
        const urlParamVoucherCode = parameters.get('vouchercode');

        let paramContext: EItemDetailCallerContextType = EItemDetailCallerContextType.Undefined;

        paramContext = urlParamContext != null && !isNaN(Number(urlParamContext)) ?
            Number(urlParamContext) : EItemDetailCallerContextType.Undefined;
        const paramAssingmentId = urlParamAssingmentId != null && !isNaN(Number(urlParamAssingmentId)) ?
            Number(urlParamAssingmentId) : 0;
        const paramCatalogFolerId = urlParamCatalogFolerId != null && !isNaN(Number(urlParamCatalogFolerId)) ?
            Number(urlParamCatalogFolerId) : 0;
        const paramTrainingPlanId = urlParamTrainingPlanId != null && !isNaN(Number(urlParamTrainingPlanId)) ?
            Number(urlParamTrainingPlanId) : 0;
        const paramTrainingPlanElementId = urlParamTrainingPlanElementId != null && !isNaN(Number(urlParamTrainingPlanElementId)) ?
            Number(urlParamTrainingPlanElementId) : 0;

        Logger.log(this.loggerLocality, `${methodName} got context: ${paramContext}, assignmentid: ${paramAssingmentId} ` +
            `catalogFolderId: ${paramCatalogFolerId}, trainingPlanId: ${paramTrainingPlanId}, trainingPlanElementId: ${paramTrainingPlanElementId}`);

        return {
            itemDetailCallerContextType: paramContext,
            parentAssignmentId: paramAssingmentId,
            parentCatalogFolderId: paramCatalogFolerId,
            parentTrainingPlanElementId: paramTrainingPlanElementId,
            parentTrainingPlanId: paramTrainingPlanId,
            voucherCode: urlParamVoucherCode
        };
    }

    protected getURLPerformanceParameters(): IURLPerformanceState {
        const methodName = `${this.className}:getURLPerformanceParameters()`;
        const parameters = new URLSearchParams(window.location.search);

        const urlParamSkillId = parameters.get('skillId');
        const urlParamUserId = parameters.get('userId');
        const urlParamProfileId = parameters.get('profileId');
        const urlParamBossRelationCode = parameters.get('bossRelationCode');

        const paramSkillId = urlParamSkillId != null && !isNaN(Number(urlParamSkillId)) ? Number(urlParamSkillId) : undefined;
        const paramUserId = urlParamUserId != null && !isNaN(Number(urlParamUserId)) ? Number(urlParamUserId) : undefined;
        const paramProfileId = urlParamProfileId != null && !isNaN(Number(urlParamProfileId)) ? Number(urlParamProfileId) : undefined;
        const paramBossRelationCode = urlParamBossRelationCode != null ?
            urlParamBossRelationCode : undefined;

        Logger.log(this.loggerLocality, `${methodName} got skillId: ${paramSkillId}, userId: ${paramUserId}, profileId: ${paramProfileId}`);

        return {
            bossRelationCode: paramBossRelationCode,
            profileId: paramProfileId,
            skillId: paramSkillId,
            userId: paramUserId,
        };
    }

    protected renderDetails(): JSX.Element | JSX.Element[] | null {
        const methodName = `${this.className}:renderDetails()`;
        const lesson = this.state.lesson;

        if (lesson === null) {
            Logger.log(this.loggerLocality, `${methodName} lesson is null, returning null`);
            return null;
        }

        const title = lesson.title;
        const summary = lesson.summary;
        const hasGlobalDescriptionTemplate = lesson.globalTemplate != null &&
            this.state.globalDescription != null &&
            this.state.globalDescription.globalDescriptionFile != null;

        return (
            <React.Fragment>
                <div>
                    {this.renderCommonHeader(title, lesson)}
                    {this.renderCommonDetails(summary, hasGlobalDescriptionTemplate, lesson)}
                    {this.renderItemTypeBasedDetails(lesson)}
                    {this.renderMentors(lesson)}
                    {this.renderCommonPrerequisits(lesson)}
                    {this.renderModalForRating(lesson)}
                    {this.renderCommonIOSkills(lesson)}
                    {this.renderGlossary(lesson.itemId)}

                    {this.state.lesson?.isRatingEnabled && globalConfig.itemProperties.displayRatings && this.renderCommonRating(lesson)}
                </div>
            </React.Fragment>
        )
    }

    protected renderCommonHeader(title: string, lesson: Item): JSX.Element {
        return <div className="">
            <div className="l-container">
                <div className="item-detail__header">
                    <div>
                        <ItemBreadCrumb
                            itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                            parentAssignmentId={this.state.parentAssignmentId}
                            parentCatalogFolderId={this.state.parentCatalogFolderId}
                            parentTrainingPlanId={this.state.parentTrainingPlanId}
                            itemDetailType={EItemDetailCallerType.Item}
                            itemTitle={this.state.lesson != null ? this.state.lesson.title : ''}
                            itemId={this.state.lesson != null ? this.state.lesson.itemId : undefined}
                            skillId={this.state.skillId}
                            profileId={this.state.profileId}
                            userId={this.state.userId}
                            bossRelationCode={this.state.bossRelationCode}
                            voucherCode={this.state.voucherCode}
                            {...this.props} />
                    </div>
                    <div>
                        <TopAnchorToolbar {...this.props}
                            itemId={this.state.lessonIdLoaded}
                            tpElementId={this.state.parentTrainingPlanElementId}
                            itemTitle={this.state.lesson != null ? this.state.lesson.title : ''}
                            favoriteType={this.state.parentTrainingPlanElementId === 0 ? EFavoriteType.Item : EFavoriteType.TPlanElement}
                        />
                    </div>

                </div>
                <div className="item-detail item-detail__header">
                    <div className="title">
                        <h1 className="heading__Title"><span dangerouslySetInnerHTML={{ __html: title }} /></h1>
                    </div>
                    <ToolbarRemainingTime item={lesson} />
                    <Toolbar item={lesson} />
                </div>
                {globalConfig.itemProperties.displayMainButtonBeforeCustomAttributes ? (
                    <div className="item-detail item-detail__button">
                        {lesson.itemType === EItemType.CBT ? this.renderCbtButtons(lesson) : this.renderButton(lesson)}
                    </div>) : ''}
            </div>
            {lesson.itemType === EItemType.F2FCourse &&
                this.state.lesson != null &&
                this.state.f2fClassDetail != null &&
                this.state.f2fClassDetail.length > 0 ? this.renderCommonHeaderF2FLink(this.state.lesson.registrationId,
                    this.state.f2fClassDetail[0].participationAutoConfirmationForVCG) : null}
        </div>
    }

    protected renderCommonHeaderF2FLink(registrationId: number, participationAutoConfirmationForVCG: boolean): JSX.Element | null {
        if (this.state.scheduleList != null) {
            const schedulelist = this.state.scheduleList;
            if (schedulelist != null && schedulelist.length > 0) {
                let allVirtualMeetingLinks: VirtualMeetingLink[] = [];
                schedulelist.forEach(dt => {
                    const virtualMeetingLinks = dt.rooms.filter(s => s.virtualMeetingLink != null && s.virtualMeetingLink.url != "").map(r => r.virtualMeetingLink);
                    if (virtualMeetingLinks != null && virtualMeetingLinks.length > 0) {
                        allVirtualMeetingLinks.push(...virtualMeetingLinks as VirtualMeetingLink[]);
                    }
                });
                if (allVirtualMeetingLinks == null || allVirtualMeetingLinks.length == 0) {
                    return null;
                }
                allVirtualMeetingLinks = allVirtualMeetingLinks.sort((a, b) => {
                    if (a.startDateTime == null || b.startDateTime == null) {
                        return 0;
                    }
                    if (a.startDateTime <= b.startDateTime) {
                        return 1;
                    }
                    return -1;
                });

                return <div className="l-container">
                    <div className="item-detail item-detail__button">
                        <VirtualMeetingSingleLink
                            virtualMeetingLink={allVirtualMeetingLinks[0].url}
                            participationAutoConfirmationForVCG={participationAutoConfirmationForVCG}
                            registrationId={registrationId}
                            linkText="F2FVirtualMeetingLinks:JoinVirtualMeeting"
                            linkClass="btn--md btn--primary"
                            linkTitle={undefined}
                            itemContext={this.state.itemDetailCallerContextType}
                            parentTrainingPlanId={this.state.parentTrainingPlanId} />
                    </div>
                </div>
            }
        }

        return null;
    }

    protected renderCommonDetails(summary: string, hasGlobalDescriptionTemplate: boolean, lesson: Item): JSX.Element {
        if (globalConfig.itemProperties.enableHTMLFormatterForSummary) {
            summary = StringHelper.htmlFormat(summary);
        }
        return <div className="l-element--striped">
            <div className={lesson.isFullWidthDescription ? "l-container item-detail_fullWidthDescription" : "l-container"}>
                {summary != null && summary.length > 0 && globalConfig.itemProperties.showSummary &&
                    <>
                        <HeadingCollapsible
                            headingLevel={2}
                            containerCssClass=""
                            headingCssClass="heading__Level2"
                            isOpened={!this.state.customAttributesCollapsed}
                            onToggleOpenedState={() => this.setState({ customAttributesCollapsed: !this.state.customAttributesCollapsed })}>
                            <Translate>ItemDetail:Description</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={!this.state.customAttributesCollapsed}>
                            <SummaryAttribute summary={summary} className="item-detail" />
                        </UnmountClosed>
                    </>
                }
                {lesson.lessonLockedByAttemptsUntil ? this.renderLockedUntilInfo() : null}
                {!hasGlobalDescriptionTemplate ? this.renderCommonDetailsCustomAttributes(true, lesson) : null}
                {!hasGlobalDescriptionTemplate && this.state.f2fClassDetail != null ?
                    this.renderCommonDetailsCustomClassAttributes(true, this.state.f2fClassDetail[0]) : null}
                {!globalConfig.itemProperties.displayMainButtonBeforeCustomAttributes ?
                    (lesson.itemType === EItemType.CBT ? this.renderCbtButtons(lesson) : this.renderButton(lesson))
                    : ''}
                {hasGlobalDescriptionTemplate ? this.renderGlobalTemplatePlaceHolder() : this.renderCommonDetailsCustomAttributes(false, lesson)}
                {!hasGlobalDescriptionTemplate && this.state.f2fClassDetail != null ?
                    this.renderCommonDetailsCustomClassAttributes(false, this.state.f2fClassDetail[0]) : null}
            </div>
        </div>
    }

    protected renderCommonDetailsCustomAttributes(beforeButton: boolean, lesson: Item): JSX.Element | null {
        const customAttributeConfig = this.getCustomAttributeConfiguration(lesson.itemType, beforeButton);
        if (lesson === null || customAttributeConfig.length <= 0 || lesson.attributes === null) {
            return null;
        }
        return <AttributeList
            customAttributeConfig={customAttributeConfig}
            attributes={lesson.attributes}
            headingCssClass="heading__Level2"
            attributeCssClassName="item-detail"
            parentHeadingLevel={1}
        />
    }

    protected renderCommonDetailsCustomClassAttributes(beforeButton: boolean, f2fClassDetail: F2FClassDetail): JSX.Element | null {
        const customAttributeConfig = globalConfig.f2fProperties.customAttributesClassOnItemDetail;
        if (customAttributeConfig == null || (customAttributeConfig != null && customAttributeConfig.length <= 0)) {
            return null;
        }
        return <AttributeList
            customAttributeConfig={customAttributeConfig.filter(attr => attr.beforeButton === beforeButton)}
            attributes={f2fClassDetail.attributes}
            headingCssClass="heading__Level2"
            attributeCssClassName="item-detail"
            parentHeadingLevel={1}
        />
    }

    protected getCustomAttributeConfiguration(itemType: EItemType,
        beforeButton: boolean): ICustomAttributeGrouped[] {
        let customAttributes: ICustomAttributeGrouped[];
        const itemConfig = this._itemConfig.customAttributes.filter(attr => attr.beforeButton === beforeButton);
        switch (itemType) {
            case EItemType.Assessment:
                customAttributes = this._itemConfig.customAttributesAssessment != null ?
                    this._itemConfig.customAttributesAssessment.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.Document:
                customAttributes = this._itemConfig.customAttributesDocument != null ?
                    this._itemConfig.customAttributesDocument.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.WBT:
                customAttributes = this._itemConfig.customAttributesWBT != null ?
                    this._itemConfig.customAttributesWBT.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.Questionnaire:
                customAttributes = this._itemConfig.customAttributesQuestionnaire != null ?
                    this._itemConfig.customAttributesQuestionnaire.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.F2FCourse:
                customAttributes = this._itemConfig.customAttributesF2F != null ?
                    this._itemConfig.customAttributesF2F.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.Recording:
                customAttributes = this._itemConfig.customAttributesRecording != null ?
                    this._itemConfig.customAttributesRecording.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.Agenda:
                customAttributes = this._itemConfig.customAttributesAgenda != null ?
                    this._itemConfig.customAttributesAgenda.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.FileSharing:
                customAttributes = this._itemConfig.customAttributesFileSharing != null ?
                    this._itemConfig.customAttributesFileSharing.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            case EItemType.CBT:
                customAttributes = this._itemConfig.customAttributesCBT != null ?
                    this._itemConfig.customAttributesCBT.filter(attr => attr.beforeButton === beforeButton) : itemConfig;
                break;
            default:
                customAttributes = itemConfig;
                break;
        }
        return customAttributes;
    }

    protected renderLockedUntilInfo() {
        if (!this.state.lesson || !this.state.lesson.lessonLockedByAttemptsUntil) {
            return null;
        }
        const text = ItemHelper.isLockedDueToFailedAttemptsText(this.state.lesson.lessonLockedByAttemptsUntil)

        return (
            <div className="item-detail">
                {text}
            </div>
        );
    }

    protected renderCommonPrerequisits(lesson: Item): JSX.Element | null {
        // Prerequisites Panel | Display if the displayPrerequisitePanel config entry says true and there is at least 1 prerequisite
        if (globalConfig.itemProperties.displayPrerequisitePanel
            && lesson.itemType !== EItemType.Document
            && (this.state.itemSkills != null && // Only show prerequisites if there are any inputSkills
                this.state.itemSkills.userInputSkills != null &&
                this.state.itemSkills.userInputSkills.length > 0)) {
            return <div className="l-element--striped">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        isOpened={!this.state.inputSkillsCollapsed}
                        onToggleOpenedState={() => this.setState({ inputSkillsCollapsed: !this.state.inputSkillsCollapsed })}>
                        <Translate>TrainingPlan:Prerequisites</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed
                        isOpened={!this.state.inputSkillsCollapsed}>
                        {this.state.itemSkills == null ? (
                            <ProgressSpinner />
                        ) :
                            <ItemInputSkills itemSkills={this.state.itemSkills}
                                asIdParam={this.state.parentAssignmentId}
                                catIdParam={this.state.parentCatalogFolderId}
                                itemIdParam={this.state.lesson?.itemId ? this.state.lesson?.itemId : 0}
                                tpIdParam={this.state.parentTrainingPlanId}
                                tpeIdParam={this.state.parentTrainingPlanElementId} />
                        }
                    </UnmountClosed>
                </div>
            </div>
        }
        else { return null }
    }

    protected renderModalForRating(lesson: Item) {
        if (!this.state.shouldShowRatingModal) {
            return null;
        }

        let evalTemplate: string = '';
        const baseUrl = globalConfig.ratingProperties.feedbackToolBaseUrl;
        let urlForDtFeedbackTool: string = '';
        if (baseUrl != null && this.state.wbtParameter) {
            if (this.state.wbtParameter.lessonAttributes != null) {
                const evalTemplateAtt = this.state.wbtParameter.lessonAttributes.find(att => att.name === 'DT_EvaluationTool_Template');
                evalTemplate = evalTemplateAtt != null ? evalTemplateAtt.value : '';
                if (evalTemplate !== '') {
                    const wbtSid = this.state.wbtParameter.lessonSId;
                    const wbtTitle = encodeURIComponent(this.state.wbtParameter.lessonTitle);
                    const userGuid = Session.instance.loginUser != null ? Session.instance.loginUser.guid : 0;
                    const domain = Session.instance.loginUser != null ? Session.instance.loginUser.domainName : '';
                    urlForDtFeedbackTool = `${baseUrl}?TId=${evalTemplate}&EId=${wbtSid}&Title=${wbtTitle}&Domain=${domain}&GUID=${userGuid}`;
                } else {
                    urlForDtFeedbackTool = `${baseUrl}`;
                }
            }
        }

        return (
            <ModalPopup isOpen={true} onRequestClose={() => this.closeRatingModal()}>
                <>
                    <p>
                        <Translate>ItemDetail:RatingTextModal</Translate>
                    </p>
                    <>
                        {baseUrl != null ? <div className="modal__spread-buttons">

                            <a href={urlForDtFeedbackTool} target="_blank" rel="noopener noreferrer" className='btn btn--md btn--primary' id='openDTfeedBack'>
                                <Translate>ItemDetail:DTFeedBack</Translate>
                            </a>
                            <Tooltip id={`openDTfeedBack`} />

                            <GTButton onClick={() => this.closeRatingModal()}
                                ariaLabel={Session.instance.storage.translation.GetString('Button:Cancel')}
                                id="btnCancelRatingModal">
                                <Translate>Button:Cancel</Translate>
                            </GTButton>
                        </div> : <ApplyRatingModal
                            item={lesson} onSave={() => this.closeRatingModal()}
                            onCancle={() => this.closeRatingModal()} />
                        }
                    </>
                </>
            </ModalPopup>
        );
    }

    protected renderCommonRating(lesson: Item) {
        return <div className="l-element--striped">
            <div className="l-container">
                <HeadingCollapsible
                    headingLevel={2}
                    containerCssClass=""
                    headingCssClass="heading__Level2"
                    isOpened={!this.state.ratingCollapsed}
                    onToggleOpenedState={() => this.setState({ ratingCollapsed: !this.state.ratingCollapsed })}>
                    <Translate>{'Rating:Rating'}</Translate> {lesson.title}
                </HeadingCollapsible>
                <UnmountClosed
                    isOpened={!this.state.ratingCollapsed}>
                    <ItemRatingDetails
                        headingLevel={2}
                        item={lesson}
                        parentTrainingPlanId={this.state.parentTrainingPlanId} />
                </UnmountClosed>
            </div>
        </div>
    }
    protected renderCommonIOSkills(lesson: Item): JSX.Element | null {
        // OutputSkills Panel | Display if the displayOutputSkillsPanel config entry says true and there is at least 1 outputSkill
        if (globalConfig.itemProperties.displayOutputSkillsPanel
            && lesson.itemType !== EItemType.Document
            && (this.state.itemSkills != null && // Only show prerequisites if there are any inputSkills
                this.state.itemSkills.userOutputSkills != null &&
                this.state.itemSkills.userOutputSkills.length > 0)) {
            return <div className="l-element--striped">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        isOpened={!this.state.outputSkillsCollapsed}
                        onToggleOpenedState={() => this.setState({ outputSkillsCollapsed: !this.state.outputSkillsCollapsed })}>
                        <Translate>TrainingPlan:OutputSkills</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed
                        isOpened={!this.state.outputSkillsCollapsed}>
                        {this.state.itemSkills == null ? (
                            <ProgressSpinner />
                        ) :
                            <ItemOutputSkills itemSkills={this.state.itemSkills} />
                        }
                    </UnmountClosed>
                </div>
            </div>
        }
        else { return null }
    }

    protected renderGlossary(itemId: number): JSX.Element {
        return (
            <GlossaryList itemId={itemId} {...this.props} callerContext={this.state.itemDetailCallerContextType} idsForGlossary={this.getIdsForGlossary(itemId)} />
        )
    }

    private getIdsForGlossary(itemId: number) {
        const tmpIds: GlossarIdForBredCrumb = {
            itemId: itemId,
            asId: this.state.parentAssignmentId,
            catId: this.state.parentCatalogFolderId,
            tpId: this.state.parentTrainingPlanId,
            tpeId: this.state.parentTrainingPlanElementId
        };
        return tmpIds;
    }

    protected renderGlobalTemplatePlaceHolder() {
        return <div id="globalDescription">&nbsp;</div>
    }

    protected renderItemTypeBasedDetails(lesson: Item): JSX.Element | JSX.Element[] | null {
        switch (lesson.itemType) {
            case EItemType.F2FCourse:
                return (this.renderF2FDetails(lesson));
            case EItemType.Document:
                return (this.renderDocumentDetails());
            case EItemType.FileSharing:
                return (this.renderFileSharingDetails(lesson));
            case EItemType.Assessment:
                return (this.renderAssessmentDetails(lesson));
            default:
                return null;
        }
    }

    protected renderDocumentDetails(): JSX.Element {
        // Rendering documents documents
        let documentTable: JSX.Element;
        const documentContent: JSX.Element[] = [];
        if (this.state.filesForItem != null) {
            for (const item of this.state.filesForItem) {
                const element = (
                    <tr key={item.fileName} className="col__Icon">
                        <td>
                            <InlineSVG src={Icondoc} />
                        </td>
                        <td className="col__FileName">
                            <a href={item.url} target="_blank" rel="noopener noreferrer" onClick={async () => { await AuthService.instance.GetPing(); return true }}>{item.fileName}</a>
                        </td>
                        <td className="col__FileSize">
                            {(item.fileSizeInBytes / 1024).toString().split('.')[0] + ' kB'}
                        </td>
                    </tr>
                );
                documentContent.push(element);
            }
        }

        if (this.state.filesForItem != null && this.state.filesForItem.length > 0) {
            documentTable = (
                <table className="item-detail__document-files-table">
                    <thead>
                        <tr>
                            <th className="col__icon">
                                <span className="screen-reader-only"><Translate>ItemDetail:ItemType</Translate></span>
                            </th>
                            <th className="col__file-name">
                                <Translate>ItemDetail:Filename</Translate>
                            </th>
                            <th className="col__file-size">
                                <Translate>ItemDetail:FileSize</Translate>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentContent}
                    </tbody>
                </table>
            );
        } else { documentTable = (<div />) }

        return (
            <div className="l-element--striped">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass=""
                        headingCssClass="heading__Level2"
                        isOpened={!this.state.documentFilesCollapsed}
                        onToggleOpenedState={() => this.setState({ documentFilesCollapsed: !this.state.documentFilesCollapsed })}>
                        <Translate>ItemDetail:Files</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={!this.state.documentFilesCollapsed}>
                        {documentTable}
                    </UnmountClosed>
                </div>
            </div>
        )
    }

    protected renderMentors(lesson: Item): JSX.Element | JSX.Element[] | null {
        if (lesson.mentors.length > 0) {
            return (
                <div className="l-element--striped">
                    <div className="l-container">
                        <HeadingCollapsible
                            headingLevel={2}
                            containerCssClass=""
                            headingCssClass="heading__Level2"
                            isOpened={!this.state.mentorsCollapsed}
                            onToggleOpenedState={() => this.setState({ mentorsCollapsed: !this.state.mentorsCollapsed })}>
                            <Translate>ItemDetail:MentorsTitle</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={!this.state.mentorsCollapsed}>
                            <ItemMentorList mentors={lesson.mentors} parentHeadingLevel={2} />
                        </UnmountClosed>
                    </div>
                </div>
            )
        }
        else {
            return null;
        }
    }

    protected renderF2FDetails(lesson: Item): JSX.Element | null {
        return <F2FCourseDetails
            parentHeadingLevel={1}
            item={lesson}
            itemDetailCallerContextType={this.state.itemDetailCallerContextType}
            parentAssignmentId={this.state.parentAssignmentId}
            parentCatalogFolderId={this.state.parentCatalogFolderId}
            parentTrainingPlanId={this.state.parentTrainingPlanId}
            voucherCode={this.state.voucherCode}
            {...this.props} />
    }

    protected renderFileSharingDetails(lesson: Item): JSX.Element | null {
        return (
            <FileSharing
                lesson={lesson}
                parentAssignmentId={this.state.parentAssignmentId}
                parentCatalogFolderId={this.state.parentCatalogFolderId}
                parentTrainingPlanId={this.state.parentTrainingPlanId}
                parentScheduleId={this.state.trainingPlanScheduleId} />
        );
    }

    protected renderAssessmentDetails(lesson: Item): JSX.Element | null {
        return (
            <>
                {this.renderDocumentDetails()}

                <div className="item-detail">
                    <div className="item-detail item-detail__button">
                        {this.state.assessmentDetails?.userCanSetStatusEnabled &&
                            <button
                                className="btn--md btn--primary"
                                disabled={this.state.assessmentDetails.setToLessonStatus === lesson.lessonStatus}
                                onClick={() => this.acknowledgeContentButtonClicked()} >
                                {this.state.assessmentDetails.setToLessonStatus === lesson.lessonStatus ?
                                    <Translate>ItemDetail:AssessmentAcknowledgeContentButtonDisabled</Translate> :
                                    <Translate>ItemDetail:AssessmentAcknowledgeContentButton</Translate>
                                }
                            </button>
                        }

                        <div className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    protected renderCbtButtons(lesson: Item): JSX.Element | null {
        if (this.state.cbtCommandButtons != null) {
            return (
                <div className="item-detail">
                    <div className="cbt-button-container">
                        {this.state.cbtCommandButtons.map((itm, index) => this.renderCbtButton(itm, index + 1, lesson))}
                    </div>
                    <div className={'input-message error'}>
                        <Translate>{this.state.errorMessage}</Translate>
                    </div>
                </div>
            )
        }
        else {
            return <React.Fragment />
        }
    }

    protected renderCbtButton(cbtCommandButton: CbtCommandButton, nr: number, lesson: Item): JSX.Element | null {
        const disabled = false;
        let buttonText = cbtCommandButton.startButton != null && cbtCommandButton.startButton !== '' ? cbtCommandButton.startButton : '';
        if (buttonText == null || buttonText === '') {
            if (lesson.lessonStatus === ELessonStatus.Undefined || lesson.lessonStatus === ELessonStatus.NotAttempted) {
                buttonText = Session.instance.storage.translation.GetString('ItemDetail:Start');
            } else {
                buttonText = Session.instance.storage.translation.GetString('ItemDetail:Continue');
            }
        }

        return (
            <div className="item-detail item-detail__button cbt-button" key={'cbtbutton_' + nr.toString()}>
                <button type="button"
                    aria-label={buttonText}
                    className="btn--md btn--primary"
                    disabled={disabled}
                    onClick={(ev) => { ev.preventDefault(); this.handleCbtButtonClicked(nr) }}>{buttonText}
                </button>
            </div>
        )
    }



    protected renderButton(lesson: Item): JSX.Element | null {
        let disabled = lesson.isLocked;
        let buttonText: JSX.Element | null;
        let buttonIsVisible: boolean = false;
        let ariaLabel = ''
        let priceInfo: JSX.Element | null = null;

        buttonText = null;
        if (lesson.itemType === EItemType.WBT || lesson.itemType === EItemType.Questionnaire) {
            buttonIsVisible = true;
            if (!lesson.registrationRequiredEnabled || lesson.registrationStatus === ERegistrationStatus.Accepted) {
                if (lesson.lessonStatus === ELessonStatus.Undefined || lesson.lessonStatus === ELessonStatus.NotAttempted) {
                    buttonText = <Translate>ItemDetail:Start</Translate>;
                    ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:Start')
                        + ' '
                        + lesson.title
                } else {
                    buttonText = <Translate>ItemDetail:Continue</Translate>
                    ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:Continue')
                        + ' '
                        + lesson.title
                }
            } else {
                if (lesson.registrationStatus === ERegistrationStatus.Requested) {
                    buttonText = <Translate>ItemDetail:CancelRegistration</Translate>;
                    ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:CancelRegistration')
                        + ' '
                        + lesson.title
                } else {
                    if (lesson.isPurchasableForCurrentUser) {
                        // WBT's that require payment                        
                        if (lesson.price === null || lesson.currency.length === 0) {
                            // misconfiguration: item is attributed as purchasable but no price or currency is defined
                            buttonText = <Translate>ItemDetail:PurchaseImpossible</Translate>;
                            ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:PurchaseImpossible')
                            priceInfo = (<span><Translate>ItemDetail:PriceIsInvalid</Translate></span>);
                            disabled = true;
                        } else {
                            if (ShoppingBasketStorage.instance.isItemInBasket(lesson.itemId, 0)) {
                                // Item is already in shopping basket
                                buttonText = <Translate>ItemDetail:ItemAlreadyInShoppingBasket</Translate>;
                                ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:ItemAlreadyInShoppingBasket')
                            } else {
                                // Item can be added to shopping basket
                                buttonText = <Translate>ItemDetail:AddToShoppingBasket</Translate>;
                                ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:AddToShoppingBasket')
                            }
                            priceInfo = (<span><Translate>ItemDetail:Price</Translate>:&nbsp;
                                {NumberHelper.getFormattedPrice(lesson.price, lesson.currency)}</span>);
                        }
                    } else {
                        // Normal registration (without payment)
                        buttonText = <Translate>ItemDetail:Register</Translate>;
                        ariaLabel = Session.instance.storage.translation.GetString('ItemDetail:Register')
                            + ' '
                            + lesson.title
                    }
                }
            }
        }

        if (buttonIsVisible) {
            return (
                <>
                    {priceInfo != null ?
                        <div className="item-detail item-detail__price-info">
                            {priceInfo}
                        </div>
                        : ''}
                    <div className="item-detail item-detail__button">
                        <button type="button"
                            aria-label={ariaLabel}
                            className="btn--md btn--primary"
                            disabled={disabled}
                            onClick={(e) => this.onButtonClicked(e)}
                            id="btnItemDetail">{buttonText}
                        </button>
                        <span className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                </>
            )
        }

        return null;
    }

    protected async onButtonClicked(event: React.FormEvent<HTMLButtonElement>) {
        const methodName = 'onButtonClicked';
        event.preventDefault();
        if (this.state.lesson !== null) {
            if (this.state.lesson.itemType === EItemType.WBT) {
                if (!this.state.lesson.registrationRequiredEnabled
                    || this.state.lesson.registrationStatus === ERegistrationStatus.Accepted) {

                    const wbtWindow = window.open('', 'nsui_wbt');
                    //hotfix always ask to close active wbt window on firefox
                    if (wbtWindow?.frames && wbtWindow.frames.length > 0 && BrowserHelper.isFirefox()) {

                        alert(Session.instance.storage.translation.GetString("ItemDetail:CloseActiveWbtWindowFirst"));
                        return;
                    }

                    // TODO - should we not check for itemislocked??? see itemMainButton

                    // WINDOW.OPEN MUST BE CALLED DIRECTLY IN THIS FUNCTION OTHERWISE POPUPBLOCKER WILL BLOCK
                    // WE need to get the wbt start Paraemters to open the window correctly
                    const response: WbtStartParameters | GtError = await LessonService.instance.getWbtStartParameters(this.state.lesson.itemId,
                        this.state.itemDetailCallerContextType,
                        this.state.parentAssignmentId,
                        this.state.parentTrainingPlanId,
                        this.state.trainingPlanScheduleId);
                    if (isSuccess<WbtStartParameters>(response)) {
                        const wbtStartParameters = response;
                        const startUrl = wbtStartParameters.startUrl;
                        // TODO: We have to get the params on load of item detail and pass it here since we cannot make a request
                        // const windowParams = wbtStartParameters.windowParameters;
                        Logger.log(this.loggerLocality, `${methodName} starting wbt ${this.state.lesson != null ? this.state.lesson.itemId : ''} 
                                                        - ${this.state.lesson != null ? this.state.lesson.title : ''}
                                                        assignmentId: ${this.state.parentAssignmentId}, tpid: ${this.state.parentTrainingPlanId}, 
                                                        tpScheduleId: ${this.state.trainingPlanScheduleId}`);
                        Logger.log(this.loggerLocality, `opening WBT lesson ${wbtStartParameters.lessonSId} using URL ${startUrl}`);

                        if (wbtWindow != null) {
                            wbtWindow.location.href = wbtStartParameters.startUrl;
                            __gt_startWbt(wbtWindow);
                        } else {
                            const errorMessage = `${methodName} could not open window for wbt ${this.state.lesson != null ? this.state.lesson.itemId : ''} 
                            - ${this.state.lesson != null ? this.state.lesson.title : ''}
                            assignmentId: ${this.state.parentAssignmentId}, tpid: ${this.state.parentTrainingPlanId}, 
                            tpScheduleId: ${this.state.trainingPlanScheduleId}`;

                            Logger.log(this.loggerLocality, errorMessage);
                            console.error(errorMessage)
                            // TODO - We should show an error
                            this.setState({ errorMessage: 'Could not open WBT' });
                        }
                    }
                }
                if (this.state.lesson.registrationRequiredEnabled
                    && this.state.lesson.registrationStatus !== ERegistrationStatus.Accepted) {
                    if (this.state.lesson.isPurchasableForCurrentUser) {
                        // this WBT is marked as purchasable (and the current user is not entiteled to use it for free)
                        if (!ShoppingBasketStorage.instance.isItemInBasket(this.state.lesson.itemId, 0)) {
                            if (this.state.lesson.price != null && this.state.lesson.currency.length > 0) {
                                // the price is defined
                                ShoppingBasketStorage.instance.addItemToBasket(new ShoppingBasketItem(
                                    this.state.lesson.itemId,
                                    0,
                                    this.state.lesson.itemType,
                                    this.state.lesson.sId,
                                    '',
                                    this.state.lesson.title,
                                    this.state.lesson.learningDuration,
                                    this.state.lesson.price,
                                    this.state.lesson.currency,
                                    [],
                                    []
                                ));
                                    this.setState({showShoppingModal: true});
                            } else {
                                // misconfiguration: if item is purchasable then the price must not be null (should not accur since button is diabled in this case)
                                console.error(`Item misconfiguration: WBT ${this.state.lesson.sId} is purchasable but price or currency is unspecified.`)
                            }
                        } else {
                            // Item is already in the shopping basket
                            // remove it again
                            ShoppingBasketStorage.instance.removeItemFromBasket(this.state.lesson.itemId, 0);
                        }
                        // since the shopping basket ist not part of the state, we need to force-update to change the button's text accordingly
                        this.forceUpdate();
                    } else {
                        this.openModal();
                    }
                }
            }
            if (this.state.lesson.itemType === EItemType.F2FCourse) {
                this.openModal();
            }
            if (this.state.lesson.itemType === EItemType.Questionnaire) {
                if (!this.state.lesson.registrationRequiredEnabled || this.state.lesson.registrationStatus === ERegistrationStatus.Accepted) {

                    const qtrWindow = window.open('', 'nsui_questionnaire');
                    // TODO - should we not check for itemislocked??? see itemMainButton

                    // WINDOW.OPEN MUST BE CALLED DIRECTLY IN THIS FUNCTION OTHERWISE POPUPBLOCKER WILL BLOCK
                    // WE need to get the wbt start Paraemters to open the window correctly
                    const response: WbtStartParameters | GtError = await LessonService.instance.getWbtStartParameters(this.state.lesson.itemId,
                        this.state.itemDetailCallerContextType,
                        this.state.parentAssignmentId,
                        this.state.parentTrainingPlanId,
                        this.state.trainingPlanScheduleId);
                    if (isSuccess<WbtStartParameters>(response)) {
                        const qtrStartParameters = response;
                        const startUrl = qtrStartParameters.startUrl;
                        // TODO: We have to get the params on load of item detail and pass it here since we cannot make a request
                        // const windowParams = qtrStartParameters.windowParameters;
                        Logger.log(this.loggerLocality, `${methodName} starting Questionnaire ${this.state.lesson != null ? this.state.lesson.itemId : ''} 
                        - ${this.state.lesson != null ? this.state.lesson.title : ''}
                        assignmentId: ${this.state.parentAssignmentId}, tpid: ${this.state.parentTrainingPlanId}, 
                        tpScheduleId: ${this.state.trainingPlanScheduleId}`);
                        Logger.log(this.loggerLocality, `opening Questionnaire lesson ${qtrStartParameters.lessonSId} using URL ${startUrl}`);



                        if (qtrWindow != null) {
                            qtrWindow.location.href = qtrStartParameters.startUrl;
                            __gt_startQuestionnaire(qtrWindow);
                        } else {
                            const errorMessage = `${methodName} could not open window for Questionnaire ${this.state.lesson != null ? this.state.lesson.itemId : ''} 
                                                - ${this.state.lesson != null ? this.state.lesson.title : ''}
                                                assignmentId: ${this.state.parentAssignmentId}, tpid: ${this.state.parentTrainingPlanId}, 
                                                tpScheduleId: ${this.state.trainingPlanScheduleId}`;

                            Logger.log(this.loggerLocality, errorMessage);
                            console.error(errorMessage)
                            // TODO - We should show an error
                            this.setState({ errorMessage: 'Could not open Questionnaire' });
                        }
                    }



                }
                if (this.state.lesson.registrationRequiredEnabled && this.state.lesson.registrationStatus !== ERegistrationStatus.Accepted) {
                    // Open registration modal
                    this.openModal();
                }
            }
        }
    }

    protected async acknowledgeContentButtonClicked() {

        let response;
        if (this.state.lesson) {
            response = await AssessmentService.instance.setAssessmentLessonStatus(this.state.lesson.itemId);
        } else {
            const params = this.props.match.params;
            const itemId: number = params.lessonId != null && !isNaN(Number(params.lessonId)) ? Number(params.lessonId) : 0;
            response = await AssessmentService.instance.setAssessmentLessonStatus(itemId);
        }
        if (isSuccess<BooleanResponse>(response) && response.status) {
            const lesson = this.state.lesson;
            if (lesson != null && this.state.assessmentDetails != null) {
                const newStatus = this.state.assessmentDetails.setToLessonStatus;
                lesson.lessonStatus = newStatus;
                this.setState({ lesson });
            }
        }
        else {
            this.setState({ errorMessage: 'ErrorMessage:LessonStatusSaveFailed' });
        }
    }

    protected async handleCbtButtonClicked(nr: number) {
        // TODO - We should fix the popup blocker stuff for cbts too
        const methodName = 'handleCbtButtonClicked';
        if (this.state.lesson !== null) {
            if (this.state.lesson.itemType === EItemType.CBT) {

                Logger.log(this.loggerLocality, `${methodName} starting cbt ${this.state.lesson.itemId} - ${this.state.lesson != null ? this.state.lesson.title : ''}
                assignmentId: ${this.state.parentAssignmentId}, tpid: ${this.state.parentTrainingPlanId}, tpScheduleId: ${this.state.trainingPlanScheduleId}`);

                const result = await __gt_startCbt(this.state.lesson.itemId,
                    nr,
                    this.state.itemDetailCallerContextType,
                    this.state.parentAssignmentId,
                    this.state.parentTrainingPlanId,
                    this.state.trainingPlanScheduleId);
                if (result instanceof GtError) {
                    this.setState({ errorMessage: result.message })
                }
            }
        }
    }

    protected async getItemSkills(itemId: number): Promise<IItemSkillState> {
        const methodName = 'getItemSkills';
        const response = await ItemService.instance.getItemSkills(itemId);
        if (isSuccess<ItemSkills | null>(response)) {
            return {
                itemSkills: response,
            };
        }
        else {
            console.error(`${methodName}  failed to get skils from server, lessonID=${itemId}, ${response.message}`);
            Logger.log(this.loggerLocality, `${methodName}  failed to get skils from server, lessonID=${itemId}, ${response.message}`);
            return { itemSkills: null };
        }
    }

    protected async getTrainingPlanSchedule(tpId: number): Promise<ITPSchueduleState> {
        const methodName = 'getTrainingPlanSchedule';
        Logger.log(this.loggerLocality, `${methodName} getting Training Plan Schedule of tpId=${tpId}`);
        const response = await TrainingPlanService.instance.getMyTrainingPlanSchedule(Session.instance.getUserLanguageCodeOrFallBack, tpId);
        if (response == null) {
            Logger.log(this.loggerLocality, `${methodName} found no Training Plan Schedule for user`);
            return { trainingPlanScheduleId: 0 };
        }
        else if (response != null && isSuccess<TrainingPlanSchedule>(response)) {
            Logger.log(this.loggerLocality, `${methodName} found Training Plan Schedule ${response.sceId}`);
            return { trainingPlanScheduleId: response.sceId };
        }
        else {
            console.error(`${methodName} failed to get traing plan schedule from server, tpId=${tpId}, ${response.message}`);
            Logger.log(this.loggerLocality, `${methodName} failed to get traing plan schedule from server, tpId=${tpId}, ${response.message}`);
            return { trainingPlanScheduleId: 0 }
        }
    }

    protected async getFilesForItem(itemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId?: number): Promise<IFilesForItemState> {
        const files = await ItemService.instance.getFilesForItem(itemId, itemContext, parentTrainingPlanId);
        if (isSuccess<File[]>(files)) {
            return { filesForItem: files };
        } else {
            if (files.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(files.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:FilesForDocumentFailed' });
            }
        }
        return { filesForItem: undefined }
    }

    protected async getCbtCommandButtons(itemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId?: number): Promise<ICbtButtonState> {

        const response = await ItemService.instance.getCbtCommandButtons(itemId, itemContext, parentTrainingPlanId);
        if (isSuccess<CbtCommandButton[] | null>(response)) {
            if (response.length === 0) {
                this.setState({ errorMessage: 'ErrorMessage:CbtConfigError' });
            }
            return {
                cbtCommandButtons: response,
            };
        }
        else {
            console.error(`getCbtCommandButtons failed to get skils from server, lessonID=${itemId}, ${response.message}`);
            Logger.log(this.loggerLocality, `getCbtCommandButtons failed to get skils from server, lessonID=${itemId}, ${response.message}`);
            this.setState({ errorMessage: response.message })
            return { cbtCommandButtons: undefined }
        }
    }
    protected async getItemDetail(itemId: number, itemContext: EItemDetailCallerContextType, trainingPlanId?: number): Promise<ILessonState> {
        const response = await Session.instance.storage.item.getItemDetail(itemId, itemContext, trainingPlanId);
        if (isSuccess<Item | null>(response)) {
            let globDescResponse: GlobalDescription | null = null;
            if (response != null && response.globalTemplate != null && response.globalTemplate !== '') {
                // Load Global Description before adding lesson to state to know what to render.
                globDescResponse = await this.getGlobalDescription(response.globalTemplate);
            }
            return {
                globalDescription: globDescResponse,
                lesson: response,
                lessonIdLoaded: itemId
            };
        }
        return {
            globalDescription: null,
            lesson: null,
            lessonIdLoaded: 0
        };
    }

    // Load Global Description
    protected async getGlobalDescription(globalTemplate: string): Promise<GlobalDescription | null> {
        const globalDescriptionResponse = await GlobalDescriptionService.instance.getGlobalDescription(globalTemplate, Session.instance.getUserLanguageCodeOrFallBack);
        if (isSuccess<GlobalDescription>(globalDescriptionResponse)) {
            return globalDescriptionResponse;
        }
        else {
            if (globalDescriptionResponse.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(globalDescriptionResponse.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GlobalDescriptionLoadingFailed' });
            }
        }
        return null;
    }

    private async executeGlobalDescription() {
        const methodName = `${this.className}:executeGlobalDescription()`;
        if (this.state.lesson != null &&
            this.state.lesson.globalTemplate != null &&
            this.state.globalDescription != null &&
            this.state.globalDescription.globalDescriptionFile != null) {

            // Data for Global Description
            const user = Session.instance.loginUser;
            const lesson: Item = this.state.lesson;
            const globalDescription: GlobalDescription = this.state.globalDescription;
            const lessonFiles = await ItemService.instance.getFilesForItem(this.state.lesson.itemId,
                this.state.itemDetailCallerContextType, this.state.parentTrainingPlanId);
            const classDetail = this.state.f2fClassDetail;

            Logger.log(this.loggerLocality, `${methodName} user Object for Global Description: `, user);
            Logger.log(this.loggerLocality, `${methodName} lesson Object for Global Description: `, lesson);
            Logger.log(this.loggerLocality, `${methodName} globalDescription Object for Global Description:`, globalDescription);
            Logger.log(this.loggerLocality, `${methodName} lessonFiles Object for Global Description:`, lessonFiles);
            Logger.log(this.loggerLocality, `${methodName} class detail Object for Global Description:`, classDetail);

            // tslint:disable-next-line:no-eval
            eval(this.state.globalDescription.globalDescriptionFile.fileContent);
            Logger.log(this.loggerLocality, `${methodName} executed global description`);
        }
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false }); // Replace with actual Titel translation
    }

    private closeRatingModal(): void {
        this.setState({ shouldShowRatingModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true }); // Replace with actual Titel translation
    }

    private async onCancelRegistrationConfirmed(registrationId: number, cancellationReasonId: number | undefined, cancellationReasonText: string) {
        let onCancelRegister: BooleanResponse | GtError = new BooleanResponse;
        if (registrationId > 0 && this.state.lesson != null) {
            if (this.state.lesson.itemType === EItemType.F2FCourse &&
                this.state.lesson.itemSubType === EItemSubType.FaceToFace &&
                Session.instance.loginUser != null &&
                !Session.instance.loginUser.canSelfUnregisterOfCourse) {
                this.setState({ errorMessage: 'ErrorMessage:CanNotSelfUnregisterOfCourse' });
            } else {
                onCancelRegister = await RegistrationService.instance.cancelRegistration(registrationId,
                    cancellationReasonId != null ?
                        cancellationReasonId : 0,
                    cancellationReasonText);
            }
            // TODO: SN - 2020-03-24 - This should be fixed, the returned value is never checked
            if (isSuccess<BooleanResponse>(onCancelRegister)) {
                window.location.reload();
            } else {
                this.setState({ errorMessage: 'ErrorMessage:CancelRegistrationFailed' })
                this.closeModal();
            }
        }
    }

    private async onRegistrationConfirmed(lessonId: number) {
        const onRegister: BooleanResponse | GtError = await RegistrationService.instance.saveItemRegistration(lessonId);
        if (isSuccess<BooleanResponse>(onRegister) && onRegister.status !== false) {
            window.location.reload();
        } else if (onRegister instanceof GtError) {
            let errMsg = 'ErrorMessage:RegistrationFailed';
            if (onRegister.detailedObject !== undefined) {
                errMsg = CustomErrorMessage.getErrorCodeMessageString(onRegister.detailedObject.subStatusCode);
            }
            this.setState({ errorMessage: errMsg })
            this.closeModal();
        } else if (onRegister.status === false) {
            const errMsg = 'ErrorMessage:RegistrationFailed';
            this.setState({ errorMessage: errMsg })
            this.closeModal();
        }
    }

}
export default ItemDetail;