import { Item } from '$src/storage/models/Item';
import { JsonObject, JsonProperty } from 'json2typescript';
/*import { observable } from 'mobx';*/

/** This is an Element used in the Catalog. */
@JsonObject
export class ItemWithSubscription extends Item {

    @JsonProperty('isNew', Boolean, false)
    public isNew: boolean = false;

}
