import React from 'react';

import { InputAttributes, ISetFocus, IValidationOptions } from '$components/shared/InputAttributes';
import { InputCheckbox } from '$components/shared/InputCheckbox';
import { InputDate } from '$components/shared/InputDate';
import { InputPassword } from '$components/shared/InputPassword';
import { InputPlaceholder } from '$components/shared/InputPlaceholder';
import { InputReadOnly } from '$components/shared/InputReadOnly';
import { InputSelect } from '$components/shared/InputSelect';
import { InputText } from '$components/shared/InputText';
import { InputTextArea } from '$components/shared/InputTextArea';
import { RedirectLink } from '$src/components/shared/RedirectLink';
import { InputTimeZoneSelect } from './InputTimeZoneSelect';

export type GenericInputTypes = 'checkbox' | 'date' | 'password' | 'select' | 'textarea' | 'text' | 'placeholder' | 'redirectlink' | 'selectTimeZone' | 'number';

interface IProps {
    type: GenericInputTypes;
    id: string;
    label: string;
    value: string;
    class?: string;
    attributeValueType?: number;
    isReadOnly: boolean;
    isRequired: boolean;
    hasFocus: boolean;
    regExpression?: RegExp | undefined;
    url?: string;
    placeholder?: string;
    rows?: number;
    editMode: boolean;
    initialValidation: boolean;
    onTextChange?: (id: string, value: string, errorMsg: string, setFocus?: ISetFocus) => void;
    onCheckboxChange?: (id: string, value: boolean, errorMsg: string, setFocus?: ISetFocus) => void;
    onCrEntered?: (id: string) => void;
    fieldIndex: number;
    validationOptions?: IValidationOptions;
    minDate?: Date;
    maxDate?: Date;
    errorMessage?: string;
}

export const GenericInputDefaultProps = {
    label: '',
    isReadOnly: false,
    isRequired: false,
    hasFocus: false,
    editMode: true,
    initialValidation: false
};

export class GenericInput extends React.Component<IProps, {}> {
    protected controlInput: HTMLInputElement;
    protected className = 'GenericInput';
    protected loggerLocality = 'Components\shared.GenericInput';

    constructor(props: IProps) {
        super(props);
    }

    // tslint:disable:no-shadowed-variable      
    public inputSelector = (param: IProps) => {
        const isetFocus: ISetFocus = {
            idx: param.fieldIndex,
            innerRef: undefined
        }

        const attributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: param.id,
            label: param.label,
            value: param.value,
            class: param.class,
            attributeValueType: param.attributeValueType,
            isReadOnly: param.isReadOnly,
            isRequired: param.isRequired,
            hasFocus: param.hasFocus,
            regExpression: param.regExpression,
            url: param.url,
            placeholder: param.placeholder,
            rows: param.rows,
            editMode: param.editMode,
            initialValidation: param.initialValidation,
            setFocus: isetFocus,
            validationOptions: param.validationOptions,
            minDate: param.minDate,
            maxDate: param.maxDate,
            errorMessage: param.errorMessage
        };

        if (param.editMode) {
            switch (param.type) {
                case 'checkbox':
                    return ([
                        // tslint:disable-next-line:max-line-length
                        <InputCheckbox key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg) => this.onCheckboxChange(id, value, errorMsg)} />
                    ]);
                case 'date':
                    return ([
                        <InputDate key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg) => this.onTextChange(id, value, errorMsg)} />
                    ]);
                case 'password':
                    return ([
                        <InputPassword key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg) => this.onTextChange(id, value, errorMsg)} />
                    ]);
                case 'select':
                    return ([
                        <InputSelect key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg) => this.onTextChange(id, value, errorMsg)} />
                    ]);
                case 'textarea':
                    return ([
                        <InputTextArea key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg) => this.onTextChange(id, value, errorMsg)} />
                    ]);
                case 'text':
                    return ([
                        <InputText key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg, ref) => this.onTextChange(id, value, errorMsg, ref)} />
                    ]);
                case 'placeholder':
                    return ([
                        <InputPlaceholder key={'GenericInput_' + attributes.id} attributes={attributes} />
                    ]);
                case 'redirectlink':
                    return ([
                        <RedirectLink key={'GenericInput_' + attributes.id} attributes={attributes} />
                    ]);
                case 'selectTimeZone':
                    return ([
                        <InputTimeZoneSelect key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg) => this.onTextChange(id, value, errorMsg)} />
                    ])
                default:
                    return ([
                        <InputText key={'GenericInput_' + attributes.id} attributes={attributes}
                            onChange={(id, value, errorMsg, ref) => this.onTextChange(id, value, errorMsg, ref)}
                        />
                    ]);
            }
        }
        else {
            switch (param.type) {
                case 'placeholder':
                    return ([
                        <InputPlaceholder key={'GenericInput_' + attributes.id} attributes={attributes} />
                    ]);
                case 'redirectlink':
                    return ([
                        <RedirectLink key={'GenericInput_' + attributes.id} attributes={attributes} />
                    ]);
                default:
                    return ([
                        <InputReadOnly key={'GenericInput_' + attributes.id} attributes={attributes} type={param.type} />
                    ]);
            }
        }
    }

    public render(): JSX.Element {
        return (
            <div key={'GenericInput_Div_' + this.props.id}>
                {this.inputSelector(this.props)}
            </div >
        )
    }

    /**
     * Callback for TextInput component: text has changed.
     * @param id: Id of the TextInput triggering this callback.
     * @param value: Current text from TextInput.
     * @param errorMsg: Current error message.
     */
    protected onTextChange(id: string, value: string, errorMsg: string, setFocus?: ISetFocus) {
        if (this.props.onTextChange) {
            this.props.onTextChange(this.props.id, value, errorMsg, setFocus);
        }
    }

    /**
     * Callback for CheckboxInput component: text has changed.
     * @param id: Id of the TextInput triggering this callback.
     * @param value: Current text from TextInput.
     * @param errorMsg: Current error message.
     */
    protected onCheckboxChange(id: string, value: boolean, errorMsg: string, setFocus?: ISetFocus) {
        if (this.props.onCheckboxChange) {
            this.props.onCheckboxChange(this.props.id, value, errorMsg, setFocus);
        }
    }

}