import ServiceClient from '$core/ServiceClient';

/**
 * PaymentProviderService provides helper services that are 
 * used in the context of interacting with the PaymentProvider.
 */
export default class PaymentProviderService extends ServiceClient {
    protected static _instance: PaymentProviderService | null = null;

    protected className = 'PaymentProviderService';
    protected loggerLocality = 'PaymentProviderService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PaymentProviderService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Calls the given webhook link and sends reference code as json
     * @param webhookLink 
     * @param referenceCode 
     */
    public async callWebhook(webhookLink: string, referenceCode: string) 
    {
        const data = { referenceCode };

        const resp = await this.post<undefined>(webhookLink, data, undefined, undefined);
        return resp;
    }
}