import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import UserService from '$core/Services/UserService';
import Session from '$core/Session';
import { Boss } from '$storage/models/Boss';
import { isSuccess } from '$util/Result';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCloseEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';

interface IProps {
    parentHeadingLevel: number;
    isReadOnly: boolean;
    isConfigReadOnly: boolean;
    onBossSelectionChanged?: (selectedBoss: BossSearchResult | undefined) => void;
}

interface IState {
    dataLoaded: boolean;
    errorMessage: string;
    bossList: BossSearchResult[];
    selectedBoss: BossSearchResult | undefined;
    isSearching: boolean;
}

export class BossSelection extends React.Component<IProps, IState> {

    private timer: any; // use any beceause, NodeJS.Timer or number makes build error

    constructor(props: IProps) {
        super(props);

        this.state = {
            bossList: [],
            dataLoaded: false,
            errorMessage: '',
            isSearching: false,
            selectedBoss: undefined,
        }
    }

    public async componentDidMount() {
        await this.loadCurrentBoss();
    }

    public componentWillUnmount() {
        this.internalBossSelectionChanged(this.state.selectedBoss);
    }

    public render() {
        if (!this.state.dataLoaded) {
            return (
                <ProgressSpinner isSmall={true} />
            )
        } else {
            return (
                <React.Fragment>
                    {this.props.isReadOnly || this.props.isConfigReadOnly ?
                        (<div className="columnFlex">
                            <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                                <Translate>BossSelection:Boss</Translate>
                            </Heading>
                            {this.state.selectedBoss ? this.state.selectedBoss.fullNameAndEmail : Session.instance.storage.translation.GetString('BossSelection:BossMissed')}
                        </div>) :
                        (
                            <React.Fragment>
                                <Translate>BossSelection:BossSelectionHint</Translate>

                                {this.state.bossList.length > globalConfig.bossSelection.maxSearchResults ?
                                    <Alert alertType="warning" alertAppereance="input-message" message="BossSelection:TooMayResults" /> : null}

                                <AutoComplete
                                    data={this.state.bossList}
                                    onChange={(e: AutoCompleteChangeEvent) => this.onChange(e)}
                                    label={Session.instance.storage.translation.GetString('BossSelection:Boss')}
                                    suggest={false}
                                    textField="fullNameAndEmail"
                                    className="flexForIcon"
                                    onClose={(e) => this.onClose(e)}
                                    value={this.state.selectedBoss ? this.state.selectedBoss.fullNameAndEmail : undefined}
                                    loading={this.state.isSearching}
                                />
                            </React.Fragment>)}
                    <span className="input-message error">
                        <Translate>{this.state.errorMessage}</Translate>
                    </span>
                </React.Fragment>
            )
        }

    }

    private async onChange(event: AutoCompleteChangeEvent) {
        const value = event.target.value;
        if (this.state.selectedBoss != null && value === this.state.selectedBoss.fullNameAndEmail) {
            return;
        }
        else {
            this.internalBossSelectionChanged(undefined);
        }
        // Get Bosses from service, only when the user enters minimum the configured number of characters if timeout search aren't configured
        if (!globalConfig.bossSelection.useTimeOutSearch) {
            if (value.length >= globalConfig.bossSelection.NbrOfCharsToStartSearch) {
                await this.searchBosses(value);
            }
        }
        else { // Get Bosses from service, every entered characters after a minimum number of characters when timeout search are configured
            if (value.length >= globalConfig.bossSelection.NbrOfCharsToStartSearch) {
                clearTimeout(this.timer);
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                this.timer = setTimeout(async () => {
                    await this.searchBosses(value);
                }, globalConfig.bossSelection.timeOutInMS);
            }
        }

        if (value.length < 2) {
            this.setState({
                bossList: [],
            })
        }
    }

    private onClose(event: AutoCompleteCloseEvent) {
        const value = event.target.value;
        const selectedBoss = this.state.bossList.filter(b => b.fullNameAndEmail === value)[0];
        this.internalBossSelectionChanged(selectedBoss);
    }

    private async loadCurrentBoss() {
        let selectedBoss: BossSearchResult | undefined;
        this.setState({ isSearching: true });
        const userBosses = await UserService.instance.getBosses(Session.instance.getUserLanguageCodeOrFallBack, true);
        if (isSuccess<Boss[]>(userBosses)) {
            selectedBoss = BossSearchResult.Convert(userBosses[0]);
        }

        this.setState({ dataLoaded: true, isSearching: false });
        this.internalBossSelectionChanged(selectedBoss);
    }

    private async searchBosses(searchText: string) {
        let validBosses: BossSearchResult[] | null = [];
        if (!this.props.isReadOnly) {
            this.setState({ isSearching: true });
            validBosses = await Session.instance.storage.validBosses.getBosses(searchText);
            if (validBosses == null) { validBosses = [] }
            this.setState({ bossList: validBosses, isSearching: false });
        }
    }

    private internalBossSelectionChanged(selectedBoss: BossSearchResult | undefined) {
        this.setState({ selectedBoss });
        if (this.props.onBossSelectionChanged) {
            this.props.onBossSelectionChanged(selectedBoss);
        }
    }
}