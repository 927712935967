import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class BossSelection {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('firstName', String, false)
    public firstName: string = '';

    @JsonProperty('lastName', String, false)
    public lastName: string = '';

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`
    }
}