import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * Identity provider configuration for nsui
 *
 * @export
 * @class SsoSuiConfigurationIdentityProvider
 */
@JsonObject
export class SsoSuiConfigurationIdentityProvider {

    @JsonProperty('authEndpoint', String, false)
    authEndpoint: string = '';

    @JsonProperty('identityProvider', String, false)
    identityProvider: 'GoogleOpenIdConnect' | 'Saml2' | 'OpenIdConnect' | 'Windows' | 'OAuth2' | string;

    @JsonProperty('isClientIPEnabled', Boolean, false)
    public isClientIPEnabled: boolean = false;

    @JsonProperty('clientIP', String, false)
    clientIP: string = '';

    @JsonProperty('isBackgroundUserCreationEnabled', Boolean, false)
    isBackgroundUserCreationEnabled: boolean = false;

}