import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { CertificateTypeConverter } from '$storage/models/converters/CertificateTypeConverter';
import { ECertificateType } from '$storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserCertificate {
    [key: string]: string | number | Date | DateTimeConverter | boolean;

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('certificateType', CertificateTypeConverter, false)
    public certificateType: ECertificateType = ECertificateType.Undefined;

    @JsonProperty('owningObjectId', Number, false)
    public owningObjectId: number = 0;

    @JsonProperty('acquiredDate', DateTimeConverter, false)
    public acquiredDate: Date = new Date();

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('fileName', String, false)
    public fileName: string = '';

    public constructor(id: number = 0,
        certificateType: ECertificateType = ECertificateType.Undefined,
        owningObjectId: number = 0,
        acquiredDate: Date = new Date(),
        userId: number = 0,
        fileName: string = '') {

        this.id = id;
        this.certificateType = certificateType;
        this.owningObjectId = owningObjectId;
        this.acquiredDate = acquiredDate;
        this.userId = userId;
        this.fileName = fileName;
    }
}



