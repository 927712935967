import { JsonObject, JsonProperty } from 'json2typescript';
import { SsoSuiConfigurationIdentityProvider } from '$src/storage/models/SsoSuiConfigurationIdentityProvider';

/**
 * List of configured identity providers with their configuration informationf or nsui
 *
 * @export
 * @class SsoSuiConfiguration
 */
@JsonObject
export class SsoSuiConfiguration {

    @JsonProperty('identityProviders', SsoSuiConfigurationIdentityProvider, true)
    public identityProviders: SsoSuiConfigurationIdentityProvider[] = [];

}