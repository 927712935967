import { EVirtualMeetingsViewType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class VirtualMeetingsViewTypeConverter implements JsonCustomConvert<EVirtualMeetingsViewType> {

    public serialize(lessonStatus: EVirtualMeetingsViewType): number {
        switch (lessonStatus) {
            case EVirtualMeetingsViewType.All: return 0;
            case EVirtualMeetingsViewType.Trainer: return 1;
            case EVirtualMeetingsViewType.Student: return 2;           

            default: return 0;
        }
    }
    public deserialize(jsonValue: number): EVirtualMeetingsViewType {
        switch (jsonValue) {
            case 0: return EVirtualMeetingsViewType.All;            
            case 1: return EVirtualMeetingsViewType.Trainer;
            case 2: return EVirtualMeetingsViewType.Student;
            
            default: return EVirtualMeetingsViewType.All;
        }
    }
}
