import { ExternalCourseRequestRegistration } from '$components/externalCourse/ExternalCourseRequestRegistration';
import AttachFile from '$resources/svgs/misc/attach-file.svg';
import Delete from '$resources/svgs/misc/delete.svg';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import { Heading } from '$src/components/shared/Heading';
import InputFile from '$src/components/shared/InputFile';
import { Translate } from '$src/components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ECertificateType, EFileType, ELessonStatus } from '$src/storage/models/enums';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import { UserCertificate } from '$src/storage/models/Certificates/UserCertificate';
import { UserCertificateViewModel } from '$src/storage/models/Certificates/UserCertificateViewModel';
import FileHelper from '$src/util/FileHelper';
import { isSuccess } from '$src/util/Result';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { UpdateDocumentRequest } from '$src/storage/models/RequestObjects/ExternalCourse/UpdateDocumentRequest';

interface IProps {
    parentHeadingLevel: number;
    externalCourseRegistration: ExternalCourseRegistration;
    onStepClicked?: (stepNumber: number) => void;
    onSaveSuccess?: () => void;
}

interface IState {
    errorMessage: string;
    uploadedFiles: Blob[];
    uploadErrorMessage: string;
    filesToUpload: File[];
    fileDataRequest: FileDataRequest[];
    updateDocuments: UpdateDocumentRequest[];
}

export class StepConclusion extends React.Component<IProps, IState> {
    protected attendanceStep = 6 // step number of attendance step defined in RequestWizard.tsx
    protected uploadedFileTypes = [
        { id: EFileType.Certificate, text: Session.instance.storage.translation.GetString('ExternalCourse:FileTypeCertificate') },
        { id: EFileType.Confirmation, text: Session.instance.storage.translation.GetString('ExternalCourse:FileTypeExpenseReport') }
    ]

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            fileDataRequest: [],
            filesToUpload: [],
            uploadedFiles: [],
            updateDocuments: [],
            uploadErrorMessage: '',
        }
    }

    public render() {
        const ariaLabelPreviousStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToAttendance');
        const readonly = this.props.externalCourseRegistration.lessonStatus >= ELessonStatus.Completed;

        return (
            <div className="step-content">
                <ExternalCourseRequestRegistration
                    externalCourseRegistration={this.props.externalCourseRegistration}
                    parentHeadingLevel={this.props.parentHeadingLevel}
                    isBossView={false}
                />
                <div className="step-content__block">
                    <Translate>ExternalCourse:AttendanceSaveHint</Translate>
                </div>

                <div className="step-content__block">
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                        <Translate>ExternalCourse:AttendanceDocumentsTitle</Translate>
                    </Heading>
                    {!readonly &&
                        <Translate>ExternalCourse:AttendanceDocumentsText</Translate>
                    }
                </div>

                {readonly ?
                    (this.renderReadonlyDocuments()) :
                    (<div className="step-content__block">
                        {this.renderDocuments()}
                        <InputFile
                            id='StepConclusionFileUpload'
                            acceptFilter={globalConfig.externalCourse.requestFileUpload.acceptFilter}
                            isMultiple={globalConfig.externalCourse.requestFileUpload.multipleAllowed}
                            labeledBy="lblDocuments"
                            onUploadChange={(files) => this.onUploadChange(files)}
                            maxFileSizeInByte={globalConfig.externalCourse.requestFileUpload.maxFileSize}
                            linkCssClass="button-link button-link--colorized-dark"
                        />
                        <span className={'input-message error'}>
                            <Translate>{this.state.uploadErrorMessage}</Translate>
                        </span>
                    </div>)
                }

                <div className="step-content__block">
                    {!readonly &&
                        <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level5">
                            <Translate>ExternalCourse:ConclusionTitle</Translate>
                        </Heading>
                    }
                    <Translate>{readonly ? 'ExternalCourse:ConcludedText' : 'ExternalCourse:ConclusionText'}</Translate>
                </div>

                <div className="step-content__block center">
                    {
                        (readonly || this.isDocumentTypeMissing()) ? '' :
                            <button
                                className="btn--sm btn--primary"
                                onClick={() => this.onSaveLessonStatus()}
                                disabled={readonly || this.isDocumentTypeMissing()}
                                id="btnConclusion">
                                <Translate>Button:Conclusion</Translate>
                            </button>
                    }

                </div>

                {this.state.errorMessage.length > 0 &&
                    <div className="step-content__block center">
                        <span className={'input-message error--dark'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                }
                <div className="step-navigation step-navigation__conclusion">
                    <button className="button-link button-link--colorized-dark"
                        onClick={() => this.goToAttendance()}
                        aria-label={ariaLabelPreviousStep}>
                        <div className="inlineFlex">
                            <InlineSVG src={ArrowLeft} />
                            <span className="notMobile"><Translate>ExternalCourse:Attendance</Translate></span>
                        </div>
                    </button>
                    <span><Translate>ExternalCourse:Conclusion</Translate></span>
                    <div></div>
                </div>
            </div>
        )
    }

    private renderDocuments(): JSX.Element {
        const expenseReportDocs = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_ExpenseReport');
        const defaultFileTypeValue = { id: EFileType.Undefined, text: Session.instance.storage.translation.GetString('ExternalCourse:ChooseFileType') }
        return (
            <div className="step-content__block">
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup" className="div-table__horizontal-table-row screen-reader-only">
                        <div role="row">
                            <div role="columnheader">
                                <Translate>ExternalCourse:Filename</Translate>
                            </div>
                            <div role="columnheader">
                                <Translate>ExternalCourse:Filetype</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        {this.state.filesToUpload.map(doc => {
                            const fileData = this.state.fileDataRequest.filter(f => f.fileName === doc.name)[0];
                            const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                .Format(doc.name);
                            return (
                                <div key={doc.name} role="row" className="div-table__horizontal-table-row">
                                    <div role="cell" className="inlineFlex">
                                        <InlineSVG src={AttachFile} />
                                        <button
                                            className="button-link button-link--colorized-dark"
                                            aria-label={arriaLabelOpenDoc}
                                            onClick={() => FileHelper.openFile(doc)}>
                                            {doc.name}
                                        </button>
                                        <button className="button-link"
                                            onClick={() => this.onDeleteFile(doc.name)}
                                            aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName').Format(doc.name)}
                                            disabled={this.props.externalCourseRegistration.lessonStatus > 2}>
                                            <InlineSVG src={Delete} />
                                        </button>
                                    </div>
                                    <div role="cell">
                                        <DropDownList
                                            id='StepConclusionUploadedFileTyp'
                                            data={this.uploadedFileTypes}
                                            textField="text"
                                            dataItemKey="id"
                                            defaultValue={defaultFileTypeValue}
                                            value={fileData ? this.uploadedFileTypes.filter(ft => ft.id === fileData.fileType)[0] : undefined}
                                            onChange={(e) => this.fileTypeChange(e, doc.name)} />

                                        {(fileData === undefined || fileData.fileType === EFileType.Undefined) &&
                                            <div className="step-content__block dropdown-error">
                                                <span className={'input-message error--dark'}>
                                                    <Translate>ErrorMessage:MissingFileType</Translate>
                                                </span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {this.state.fileDataRequest.length > 0 && this.state.fileDataRequest.map((doc, index) => {
                            const fileName = doc.fileName; // FileHelper.getFileNameFromBase64(doc.base64String, doc.fileName);
                            const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                .Format(fileName);
                            if (this.state.filesToUpload.some(f => f.name === fileName)) {
                                return null;
                            } else {
                                return (
                                    <div key={`doc_${index}`} role="row" className="div-table__horizontal-table-row">
                                        <div role="cell" className="inlineFlex">
                                            <InlineSVG src={AttachFile} />
                                            <button
                                                className="button-link button-link--colorized-dark"
                                                aria-label={arriaLabelOpenDoc}
                                                onClick={() => FileHelper.openMediaFile(null, doc.url)}>
                                                {fileName}
                                            </button>
                                            <button className="button-link"
                                                onClick={() => this.onDeleteFile(fileName)}
                                                aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName').Format(fileName)}
                                                disabled={this.props.externalCourseRegistration.lessonStatus > 2}>
                                                <InlineSVG src={Delete} />
                                            </button>
                                        </div>
                                        <div role="cell">
                                            <DropDownList
                                                data={this.uploadedFileTypes}
                                                textField="text"
                                                dataItemKey="id"
                                                defaultValue={defaultFileTypeValue}
                                                value={this.uploadedFileTypes.filter(ft => ft.id === doc.fileType)[0]}
                                                onChange={(e) => this.fileTypeChange(e, fileName)} />

                                            {doc.fileType === EFileType.Undefined &&
                                                <div className="step-content__block dropdown-error">
                                                    <span className={'input-message error--dark'}>
                                                        <Translate>ErrorMessage:MissingFileType</Translate>
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        {expenseReportDocs.length > 0 && expenseReportDocs.map((doc, index) => {
                            const fileName = doc.value; // FileHelper.getFileNameFromBase64(doc.value);
                            const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                .Format(fileName);
                            const updatedDoc = this.state.updateDocuments.filter(uDoc => uDoc.attributeId === doc.id);
                            if (updatedDoc && updatedDoc.length > 0 && updatedDoc[0].isDeleted) {
                                return null;
                            } else {
                                const fileType = updatedDoc && updatedDoc.length > 0 ? updatedDoc[0].fileType : EFileType.Confirmation;
                                return (
                                    <div key={`doc_${index}`} role="row" className="div-table__horizontal-table-row">
                                        <div role="cell" className="inlineFlex">
                                            <InlineSVG src={AttachFile} />
                                            <button
                                                className="button-link button-link--colorized-dark"
                                                aria-label={arriaLabelOpenDoc}
                                                onClick={() => FileHelper.openMediaFile(doc, null)}>
                                                {fileName}
                                            </button>
                                            <button className="button-link"
                                                onClick={() => this.onDeleteAttribute(doc.id)}
                                                aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName').Format(fileName)}
                                                disabled={this.props.externalCourseRegistration.lessonStatus > 2}>
                                                <InlineSVG src={Delete} />
                                            </button>
                                        </div>
                                        <div role="cell">
                                            <DropDownList
                                                data={this.uploadedFileTypes}
                                                textField="text"
                                                dataItemKey="id"
                                                defaultValue={defaultFileTypeValue}
                                                value={this.uploadedFileTypes.filter(ft => ft.id === fileType)[0]}
                                                onChange={(e) => this.updateFileTypeChange(e, doc.id)} />
                                        </div>
                                    </div>
                                )
                            }
                        })}
                        {this.lessonCertificates().map(cert => {
                            if (cert.certificate) {
                                const certificate = cert.certificate;
                                if (this.state.updateDocuments.some(uDoc => uDoc.certificateId === certificate.id && uDoc.isDeleted)) {
                                    return null;
                                } else {
                                    return (
                                        <div key={certificate.id} role="row" className="div-table__horizontal-table-row">
                                            <div role="cell" className="inlineFlex">
                                                <InlineSVG src={AttachFile} />
                                                <a href={cert.url} target={'_blank'} rel="noopener noreferrer" className="button-link button-link--colorized-dark">
                                                    {`${FileHelper.getFileNameFromUrl(cert.url)}${cert.fileExtension}`}
                                                </a>
                                                <button className="button-link"
                                                    onClick={() => this.onDeleteCertificateFile(certificate)}
                                                    aria-label={Session.instance.storage.translation.GetString('ExternalCourse:DeleteFileName')
                                                        .Format(`${FileHelper.getFileNameFromUrl(cert.url)}${cert.fileExtension}`)}
                                                    disabled={this.props.externalCourseRegistration.lessonStatus > 2}>
                                                    <InlineSVG src={Delete} />
                                                </button>
                                            </div>
                                            <div role="cell">
                                                <DropDownList
                                                    data={this.uploadedFileTypes}
                                                    textField="text"
                                                    dataItemKey="id"
                                                    defaultValue={defaultFileTypeValue}
                                                    value={this.uploadedFileTypes.filter(ft => ft.id === EFileType.Certificate)[0]}
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    )
                                }
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }

    private renderReadonlyDocuments(): JSX.Element {
        const expenseReportDocs = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_ExpenseReport');
        return (
            <div className="step-content__block">
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup" className="div-table__horizontal-table-row screen-reader-only">
                        <div role="row">
                            <div role="columnheader">
                                <Translate>ExternalCourse:Filename</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        {expenseReportDocs.map(doc => {
                            const fileName = doc.value; // FileHelper.getFileNameFromBase64(doc.value);
                            const arriaLabelOpenDoc = Session.instance.storage.translation.GetString('ExternalCourse:OpenDocument')
                                .Format(fileName);
                            return (
                                <div key={doc.name} role="row" className="div-table__horizontal-table-row">
                                    <div role="cell" className="inlineFlex">
                                        <InlineSVG src={AttachFile} />
                                        <button
                                            className="button-link button-link--colorized-dark"
                                            aria-label={arriaLabelOpenDoc}
                                            onClick={() => FileHelper.openMediaFile(doc, null)}>
                                            {fileName}
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                        {this.lessonCertificates().map(cert => {
                            if (cert.certificate) {
                                return (
                                    <div key={cert.certificate.id} role="row" className="div-table__horizontal-table-row">
                                        <div role="cell" className="inlineFlex">
                                            <InlineSVG src={AttachFile} />
                                            <a href={cert.url} target={'_blank'} rel="noopener noreferrer" className="button-link button-link--colorized-dark">
                                                {`${FileHelper.getFileNameFromUrl(cert.url)}${cert.fileExtension}`}
                                            </a>
                                        </div>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    }

    private isDocumentTypeMissing(): boolean {
        if (this.state.fileDataRequest.length === 0 && this.state.filesToUpload.length === 0) {
            return false;
        }
        if (this.state.fileDataRequest.findIndex(file => file.fileType === EFileType.Undefined) !== -1) {
            return true;
        } else {
            // Uploaded documents do not have an EDocumentType and are stored only in this.state.filesToUpload
            // The moment the document type is set (or a new file is uploaded), the document will get a copy in this.state.fileDataRequest with an EDocumentType
            // To know if a document in filesToUpload is missing the EDocumentType, the following code will check for their copies in fileDataRequest
            return this.state.filesToUpload.findIndex(doc => {
                return this.state.fileDataRequest.findIndex(f => f.fileName === doc.name) !== -1
            }) === -1;
        }
    }

    private async onSaveLessonStatus() {
        const response = await ExternalCourseService.instance.saveExternalCourseConclusion({
            fileCollection: this.state.fileDataRequest,
            itemId: this.props.externalCourseRegistration.itemId,
            registrationId: this.props.externalCourseRegistration.registrationId,
            updateDocumentList: this.state.updateDocuments,
        });
        if (isSuccess<BooleanResponse>(response)) {
            if (this.props.onSaveSuccess && response.status) {
                this.props.onSaveSuccess();
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:SaveExternalCourseAttendanceFailed' });
        }
    }

    private goToAttendance() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.attendanceStep)
        }
    }

    //#region File Functions

    /**
     * Callback for InputFile component: input has changed
     * Returns a list of files
     * @param files Selected files in input file
     */
    private onUploadChange(files: File[]) {
        const filesToUpload = this.state.filesToUpload;
        const fileDataRequest = this.state.fileDataRequest;
        let uploadErrorMessage = '';
        files.map(f => {
            if (this.documentAlreadyExists(f.name)) {
                uploadErrorMessage = 'ExternalCourse:DocumentAlreadyUploaded';
            } else {
                fileDataRequest.push({
                    base64String: '',
                    file: f,
                    contentType: f.type,
                    fileName: f.name,
                    fileType: EFileType.Undefined,
                    url: ''
                })
            }
            filesToUpload.push(f)
        });
        this.setState({ filesToUpload: Array.from(new Set(filesToUpload)), uploadErrorMessage });
    }

    /**
     * Checks if the document already exists or if a document with the same name is deleted
     */
    private documentAlreadyExists(filename: string): boolean {
        const filesToUpload = this.state.filesToUpload;
        const expenseReportDocs = this.props.externalCourseRegistration.attributes.filter(a => a.name === 'ExternalCourse_ExpenseReport');
        const existentExpenseReportDoc = expenseReportDocs.find(doc => doc.value == filename);
        const certificates = this.lessonCertificates();
        const existentCertificate = certificates.find(doc => `${FileHelper.getFileNameFromUrl(doc.url)}${doc.fileExtension}` == filename);

        const documentsToDelete = this.state.updateDocuments.filter(doc => doc.isDeleted)

        if (filesToUpload.find(uploadedFile => uploadedFile.name == filename) ||
            (existentExpenseReportDoc && !documentsToDelete.find(docToDelete => docToDelete.attributeId == existentExpenseReportDoc.id)) ||
            (existentCertificate && !documentsToDelete.find(docToDelete => docToDelete.certificateId == existentCertificate.certificate!.id))) {
            return true;
        } else {
            return false;
        }
    }

    private onDeleteFile(docName: string) {
        const filesToUpload = this.state.filesToUpload;
        const fileDataRequest = this.state.fileDataRequest
        this.setState({
            fileDataRequest: fileDataRequest.filter(file => file.fileName !== docName),
            filesToUpload: filesToUpload.filter(doc => doc.name !== docName),
            uploadErrorMessage: ''
        });
    }

    private onDeleteAttribute(attId: number) {
        const updateDocuments = this.state.updateDocuments;
        updateDocuments.map(doc => {
            if (doc.attributeId == attId) {
                doc.isDeleted = true;
                updateDocuments.push(doc);
            } else {
                updateDocuments.push({
                    attributeId: attId,
                    isDeleted: true,
                    fileType: EFileType.Undefined,
                    certificateId: 0
                });
            }
        })
        if (updateDocuments.length === 0) {
            updateDocuments.push({
                attributeId: attId,
                isDeleted: true,
                fileType: EFileType.Undefined,
                certificateId: 0
            });
        }
        this.setState({ updateDocuments, uploadErrorMessage: '' });
    }

    private onDeleteCertificateFile(certificate: UserCertificate | undefined) {
        if (certificate) {
            const updateDocuments = this.state.updateDocuments;
            updateDocuments.map(doc => {
                if (doc.attributeId == certificate.id) {
                    doc.isDeleted = true;
                    updateDocuments.push(doc);
                } else {
                    updateDocuments.push({
                        attributeId: 0,
                        isDeleted: true,
                        fileType: EFileType.Undefined,
                        certificateId: certificate.id
                    });
                }
            })
            if (updateDocuments.length === 0) {
                updateDocuments.push({
                    attributeId: 0,
                    isDeleted: true,
                    fileType: EFileType.Undefined,
                    certificateId: certificate.id
                });
            }
            this.setState({ updateDocuments, uploadErrorMessage: '' });
        }
    }

    private fileTypeChange(event: DropDownListChangeEvent, docName: string) {
        const fileDataRequest = this.state.fileDataRequest;
        const fileType: number = event.target.value.id;
        fileDataRequest.map(file => {
            if (file.fileName === docName) {
                switch (fileType) {
                    case 1:
                        file.fileType = EFileType.Certificate
                        break;
                    case 2:
                        file.fileType = EFileType.Confirmation
                        break;
                    default:
                        file.fileType = EFileType.Undefined
                        break;
                }
            }
        });
        this.setState({ fileDataRequest });
    }

    private updateFileTypeChange(event: DropDownListChangeEvent, attId: number) {
        const updateDocuments = this.state.updateDocuments;
        const fileType: number = event.target.value.id;
        let docFileType = EFileType.Undefined;
        switch (fileType) {
            case 1:
                docFileType = EFileType.Certificate
                break;
            case 2:
                docFileType = EFileType.Confirmation
                break;
        }
        updateDocuments.map(doc => {
            if (doc.attributeId === attId) {
                doc.fileType = docFileType;
            } else {
                updateDocuments.push({
                    attributeId: attId,
                    isDeleted: false,
                    fileType: docFileType,
                    certificateId: 0
                });
            }
        });
        if (updateDocuments.length === 0) {
            updateDocuments.push({
                attributeId: attId,
                isDeleted: false,
                fileType: docFileType,
                certificateId: 0
            });
        }

        this.setState({ updateDocuments });
    }

    private lessonCertificates(): UserCertificateViewModel[] {
        const lessonCertificates: UserCertificateViewModel[] = [];
        for (const cert of this.props.externalCourseRegistration.certificates) {
            if (cert.certificate && cert.certificate.certificateType === ECertificateType.Lesson
                && cert.certificate.owningObjectId === this.props.externalCourseRegistration.itemId) {
                lessonCertificates.push(cert);
            }
        }
        return lessonCertificates;
    }

    //#endregion
}