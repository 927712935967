import { PaymentClassSearchResponse } from "$src/storage/models/Payment/PaymentClassSearchResponse";
import { PaymentUserSearchResponse } from "$src/storage/models/Payment/PaymentUserSearchResponse";
import ShoppingBasketStorage from "$src/storage/ShoppingBasketStorage";
import { useState } from "react";

/** Payment booking validation hook */
export function usePaymentBookingValidation(): [(cls: PaymentClassSearchResponse | undefined, users: PaymentUserSearchResponse[], adminView: boolean) => void, string | undefined, boolean] {
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    /** Validate free seats & one company restriction */
    function validate(cls: PaymentClassSearchResponse | undefined, users: PaymentUserSearchResponse[], adminView: boolean) {
        setHasErrors(false);
        setErrorMessage(undefined);

        // validate free seats
        let fromBasket: number = 0;
        ShoppingBasketStorage.instance.shoppingBasketContent
            .shoppingBasketItems
            .filter(p => p.classId == cls?.classId)
            .forEach(p => fromBasket += p.bookUsers?.length || 0);

        if (cls && (users.length + fromBasket) > cls.freeSeats && !adminView) {
            setErrorMessage('PaymentBooking:Error_ToManySelectedUsers');
            setHasErrors(true);
        }

        // validate company
        const basketCompany: string | null = ShoppingBasketStorage.instance.shoppingBasketContent
            .shoppingBasketItems
            .reduce((f, current) => current, null)?.bookUsers?.reduce((f, current) => current, null)?.company || null;
        if (basketCompany && users.some(p => p.company !== basketCompany)) {
            setErrorMessage('PaymentBooking:Error_OnlyOneCompanyInBasket');
            setHasErrors(true);
        }
    }

    return [validate, errorMessage, hasErrors];
}