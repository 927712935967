
// Package Import
import { observer } from 'mobx-react';
import React from 'react';
import { CSSProperties } from 'react';
import InlineSVG from 'react-inlinesvg';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';

// Icon Import
import Iconfavorites from '$resources/svgs/navi/favorites.svg'
import Iconhelp from '$resources/svgs/navi/help.svg';
import Iconhome from '$resources/svgs/navi/home.svg';
import Iconlogout from '$resources/svgs/navi/logout.svg';
import Iconsearch from '$resources/svgs/navi/search.svg';
import IconuserProfile from '$resources/svgs/navi/userProfile.svg'

// Self development imports
import { LanguageSwitcher } from '$components/layout/LanguageSwitcher';
import NavBurger from '$components/layout/menu/NavBurger';
import Navigation from '$components/layout/menu/Navigation';
import { LegacySearchBar } from '$src/components/layout/LegacySearchBar';
import Logger from '$core/Logger';
import Session from '$core/Session';
import ItemTypeModel from '$storage/models/ItemTypeModel';
import DateHelper from '$util/DateHelper';
import ResizeEventListener from '$util/EventListener/ResizeEventListener';
import { Tooltip } from '$components/shared/Tooltip';
import SystemRoles from '$src/core/SystemRoles';
import { ShoppingBasketIcon } from '$src/components/shoppingBasket/ShoppingBasketIcon';
import QuickSearch from '$src/components/search/elk/QuickSearch';
import { KeyPressHelper } from '$src/util/KeyPressHelper';
import IANotificationMain from '$src/components/inAppNotifications/IANotificationMain';

interface IMenuProps extends RouteComponentProps<{}> {
    style: CSSProperties;
    isSticky?: boolean;
    wasSticky?: boolean;
    distanceFromTop?: number;
    distanceFromBottom?: number;
    calculatedHeight?: number;
    isSmallScreenMenuVisible?: boolean;
    smallMenuMaxWidthFallBack: number;
    onLanguageSwitched?: () => void;
}

interface IState {
    searchBarActive: boolean;
}

@observer
export class Menu extends React.Component<IMenuProps, IState> {
    protected className = 'Menu';
    protected loggerLocality = 'Components.Menu';

    protected menuContainer: React.RefObject<HTMLDivElement> | undefined
    protected menuContainerInner: React.RefObject<HTMLDivElement> | undefined

    constructor(props: IMenuProps) {
        super(props);

        this.state = ({
            searchBarActive: false
        });

        this.menuContainer = React.createRef();
        this.menuContainerInner = React.createRef();

        this.onNavigate = this.onNavigate.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onLanguageSwitched = this.onLanguageSwitched.bind(this);
        this.toggleMenuHandler = this.toggleMenuHandler.bind(this);
        this.onSearchBarToggle = this.onSearchBarToggle.bind(this);
        this.toggleSearchBar = this.toggleSearchBar.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }


    public componentDidMount() {
        this.CalculateMenuAppereance(); // First call to determine the Menu Display Settings
        ResizeEventListener.instance.subscriberHandler.subscribe(this.onWindowResize, this);
    }

    public componentDidUpdate(prevProps: IMenuProps) {
        if (prevProps.style.width !== this.props.style.width && this.props.isSticky === true) {
            this.CalculateMenuAppereance(); // Call for Orientation Change without resize. Sticky is setting the container width after the orientation changed event
        }
    }

    public componentWillUnmount() {
        ResizeEventListener.instance.subscriberHandler.unsubscribe(this.onWindowResize, this);
    }

    public render() {
        const smallScreenCSS = Session.instance.isSmallScreen ? ' small-screen' : '';
        const hasFavorites = Session.instance.getHasFavorites;
        let faqURL = globalConfig.navigationProperties.helpFaqUrl || '';
        faqURL = faqURL.replace('@language', Session.instance.getUserLanguageCodeOrFallBack);
        return (
            <div className={'menu' + smallScreenCSS} style={this.props.style} ref={this.menuContainer}>
                <div className={'l-container menu__container' + smallScreenCSS} ref={this.menuContainerInner}>

                    {this.state.searchBarActive &&
                        globalConfig.searchProperties.useLegacySearch &&
                        (<>
                            <div id="menu__search-wrapper"
                                className={'menu__search-wrapper ' + smallScreenCSS}>
                                <LegacySearchBar
                                    onCancel={() => this.toggleSearchBar()}
                                    onSearch={(t, i, f, to) => this.onSearch(t, i, f, to)}
                                    isFlyoutAlwaysVisible={false}
                                    isInHeader={true}
                                    initialFrom={null}
                                    initialTo={null}
                                    initialSearchText={''} />
                            </div>
                        </>
                        )
                    }

                    {this.state.searchBarActive &&
                        !globalConfig.searchProperties.useLegacySearch &&
                        globalConfig.searchProperties.searchPosition === 'menu' &&
                        (<>
                            <div id="menu__search-wrapper"
                                className={'menu__search-wrapper ' + smallScreenCSS}
                                onKeyDown={(e) => KeyPressHelper.isESC(e) && this.toggleSearchBar()}>
                                <QuickSearch {...this.props} onSearch={() => this.toggleSearchBar()} onClose={(e) => { e.preventDefault(); e.stopPropagation(); this.toggleSearchBar() }} isMenuQuickSearch={true} />
                            </div>
                        </>
                        )
                    }

                    {!this.state.searchBarActive &&
                        <>
                            <NavBurger onBurgerClicked={() => this.toggleMenuHandler()} />
                            <div className="navigation__wrapper">
                                <Navigation
                                    onNavigate={() => this.onNavigate()} />
                            </div>

                            {(
                                (globalConfig.navigationProperties.searchBar_Visible &&
                                    globalConfig.searchProperties.useLegacySearch) ||
                                    (globalConfig.searchProperties.searchPosition === 'menu' &&
                                        !globalConfig.searchProperties.useLegacySearch) ?
                                    <button
                                        data-tip={Session.instance.storage.translation.GetString('Search:Title')}
                                        data-for="menuIconSearchTooltip"
                                        aria-label={Session.instance.storage.translation.GetString('Search:Title')}
                                        aria-expanded={this.state.searchBarActive ? 'true' : 'false'}
                                        onClick={!this.props.location.pathname.startsWith('/search') ? this.onSearchBarToggle : undefined}
                                        tabIndex={0}
                                        aria-haspopup="true"
                                        className="menu__icon menu__search-container">
                                        <InlineSVG src={Iconsearch} />
                                        <Tooltip id="menuIconSearchTooltip" place="bottom" />
                                    </button> :
                                    <div className="menu__icon--flex"></div>)}

                            {globalConfig.navigationProperties.homeIconButton_Visible &&
                                this.renderHomeIcon()
                            }

                            <ShoppingBasketIcon />

                            {globalConfig.navigationProperties.favoritesIconButton_Visible && hasFavorites &&
                                this.renderFavoritesIcon()
                            }

                            {globalConfig.navigationProperties.helpIconButton_Visible && globalConfig.navigationProperties.helpFaqInIframe &&
                                this.renderFaqUrlInFrame()}

                            {
                                globalConfig.navigationProperties.helpIconButton_Visible && !globalConfig.navigationProperties.helpFaqInIframe &&
                                this.renderFaqUrl(faqURL)
                            }

                            {globalConfig.navigationProperties.notificationIcon_Visible &&
                                this.renderNotificationIcon()}

                            {globalConfig.navigationProperties.userProfileIcon_Visible && !Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest) &&
                                this.renderUserProfileIcon()
                            }

                            {globalConfig.navigationProperties.logoutIconButton_Visible &&
                                this.renderLogoutButton()
                            }

                            {globalConfig.navigationProperties.languageSwitcher_Visible &&
                                this.renderLanguageSwitcher()}
                        </>
                    }
                </div>
            </div>
        );
    }

    private onSearchBarToggle(): void {
        Logger.log(this.loggerLocality, 'SearchBarToggle()');
        Session.instance.setSmallScreenMenuVisibility(false);
        this.toggleSearchBar();
    }
    private onClickBarToggle(): void {
        Session.instance.setSmallScreenMenuVisibility(false);
    }

    private onSearch(
        text: string,
        from: Date | null,
        to: Date | null,
        itemTypeArray?: ItemTypeModel[]
    ) {

        this.props.history.push({
            pathname: '/legacy/search',
            search: '?text=' + encodeURI(text)
                + '&types=' + ItemTypeModel.itemTypeArrayToString(itemTypeArray)
                + '&from=' + encodeURI(DateHelper.toDateString(from))
                + '&to=' + encodeURI(DateHelper.toDateString(to))

        });
    }

    private toggleSearchBar() {
        this.setState({ searchBarActive: !this.state.searchBarActive });
    }

    private onLanguageSwitched() {
        const methodName = `${this.className}:onLanguageSwitched()`;
        Logger.log(this.loggerLocality, `${methodName} event executed on component Menu`);
        if (this.props.onLanguageSwitched !== undefined) {
            this.props.onLanguageSwitched();
        }
    }
    private onNavigate() {
        const methodName = `${this.className}:onNavigate()`;
        Logger.log(this.loggerLocality, `${methodName}`);
        if (Session.instance.isSmallScreenMenuVisible) {
            Logger.log(this.loggerLocality, `${methodName} toggle small screen menu`);
            Session.instance.toggleSmallScreenMenu();
        }
    }

    private toggleMenuHandler() {
        const methodName = `${this.className}:toggleMenuHandler()`;
        Logger.log(this.loggerLocality, `${methodName}`);
        Session.instance.toggleSmallScreenMenu();
    }

    private onWindowResize() {
        this.CalculateMenuAppereance();
    }

    private CalculateMenuAppereance() {
        const newIsSmallScreen = this.isSmallScreen(this.props.smallMenuMaxWidthFallBack);
        if (newIsSmallScreen !== Session.instance.isSmallScreen) {
            Session.instance.setSmallScreenMenuVisibility(false); // Close Menu when reszing
            Session.instance.setIsSmallScreen(newIsSmallScreen);
        }
    }

    private getScreenWidth(): number {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];

        if (e != null) {
            return w.innerWidth || e.clientWidth || g.clientWidth;
        }
        else {
            return w.innerWidth || g.clientWidth;
        }
    }

    private isSmallScreen(smallMenuMaxWidthFallBack: number): boolean {
        const screenWidth = this.getScreenWidth();
        return screenWidth < smallMenuMaxWidthFallBack;
    }

    private renderFaqUrl(faqURL: string) {
        return <a
            href={faqURL}
            target={'_blank'}
            rel="noopener noreferrer"
            className="menu__icon"
            aria-label={Session.instance.storage.translation.GetString('Navigation:Help')}
            data-tip={Session.instance.storage.translation.GetString('Navigation:Help')}
            data-for="menuIconHelpTooltip"
            onClick={() => this.onClickBarToggle()}>
            <InlineSVG src={Iconhelp} />
            <Tooltip id="menuIconHelpTooltip" place="bottom" />
        </a>
    }

    private renderFaqUrlInFrame() {
        return <NavLink
            to={'/help'}
            className="menu__icon"
            aria-label={Session.instance.storage.translation.GetString('Navigation:Help')}
            data-tip={Session.instance.storage.translation.GetString('Navigation:Help')}
            data-for="menuIconHelpTooltip"
            onClick={() => this.onClickBarToggle()}>
            <InlineSVG src={Iconhelp} />
            <Tooltip id="menuIconHelpTooltip" place="bottom" />
        </NavLink>
    }
    private renderUserProfileIcon() {
        return <NavLink
            to={'/userProfile'}
            className="menu__icon"
            aria-label={Session.instance.storage.translation.GetString('Navigation:UserProfile')}
            data-tip={Session.instance.storage.translation.GetString('Navigation:UserProfile')}
            data-for="menuIconUserProfileTooltip"
            onClick={() => this.onClickBarToggle()}>
            <InlineSVG src={IconuserProfile} />
            <Tooltip id="menuIconUserProfileTooltip" place="bottom" />
        </NavLink>
    }

    private renderNotificationIcon(): JSX.Element {
        return (
            <IANotificationMain />
        );
    }

    private renderFavoritesIcon() {
        return <NavLink
            to={'/favorites'}
            className="menu__icon"
            aria-label={Session.instance.storage.translation.GetString('Navigation:Favorites')}
            data-tip={Session.instance.storage.translation.GetString('Navigation:Favorites')}
            data-for="menuIconFavoritesTooltip"
            onClick={() => this.onClickBarToggle()}>
            <InlineSVG src={Iconfavorites} />
            <Tooltip id="menuIconFavoritesTooltip" place="bottom" />
        </NavLink>
    }

    private renderLanguageSwitcher() {
        return <div className="menu__language-container">
            <LanguageSwitcher
                onLanguageSwitched={() => this.onLanguageSwitched()} />
        </div>
    }

    private renderLogoutButton() {
        return <NavLink
            to={'/logout'}
            className="menu__icon"
            aria-label={Session.instance.storage.translation.GetString('Navigation:Logout')}
            data-tip={Session.instance.storage.translation.GetString('Navigation:Logout')}
            data-for="menuIconLogoutTooltip"
            onClick={() => this.onClickBarToggle()}>
            <InlineSVG src={Iconlogout} />
            <Tooltip id="menuIconLogoutTooltip" place="bottom" />
        </NavLink>
    }

    private renderHomeIcon() {
        return <NavLink
            to={'/'}
            className="menu__icon"
            aria-label={Session.instance.storage.translation.GetString('Navigation:Home')}
            data-tip={Session.instance.storage.translation.GetString('Navigation:Home')}
            data-for="menuIconHomeTooltip"
            onClick={() => this.onClickBarToggle()}>
            <InlineSVG src={Iconhome} />
            <Tooltip id="menuIconHomeTooltip" place="bottom" />
        </NavLink>
    }
}
