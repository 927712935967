import { JsonObject, JsonProperty } from 'json2typescript';
import { Attribute } from './Attribute';
import { UtcDateTimeConverter } from './converters/UtcDateTimeConverter';
import { Trainer } from './F2F/Trainer';
import { User } from './User';

@JsonObject
export class ParticipantListForTutor {

    @JsonProperty('participants', [User], false)
    public users: User[] = [];

    @JsonProperty('trainers', [Trainer], false)
    public trainers: Trainer[] = [];

    @JsonProperty('startDate', UtcDateTimeConverter, false)
    public startDate: Date = new Date;

    @JsonProperty('endDate', UtcDateTimeConverter, false)
    public endDate: Date = new Date;

    @JsonProperty('venues', [String], false)
    public venues: string[] = [];

    @JsonProperty('attributes', [Attribute], false)
    public attributes: Attribute[] = [];

    @JsonProperty('courseTitle', String, false)
    public courseTitle: string = '';

    @JsonProperty('allowedUserFields', [String], false)
    public allowedUserFields: string[] = [];
}