import React, { useState } from 'react';
import InlineSVG from 'react-inlinesvg';

import CollapsedIcon from '$resources/svgs/navi/arrow-down.svg';
import ExpandedIcon from '$resources/svgs/navi/arrow-up.svg';
import Session from '$src/core/Session';

interface IProps extends WithChildren {
    containerCssClass: string;
    isOpened?: boolean;
    onToggleOpenedState?: (expanded: boolean) => void;
    disallowTogglingOpenState?: boolean;
}

export const Collapsible: React.FC<IProps> = (props: IProps) => {

    const [isOpened, setIsOpenend] = useState<boolean>(props.isOpened || false);

    const onToggleOpenedStateInternal = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        const newOpenedState = !isOpened;
        setIsOpenend(newOpenedState);
        if (props.onToggleOpenedState != null) {
            props.onToggleOpenedState(newOpenedState);
        }
    }

    const tr = Session.instance.storage.translation;

    return (
        <div 
        className={props.containerCssClass + ' l-box l-box--flex l-element--clickable collapsible'} 
        onClick={e => ((props.disallowTogglingOpenState == null || !props.disallowTogglingOpenState) || (!props.disallowTogglingOpenState)) && onToggleOpenedStateInternal(e)}>
            {props.children}
            <button
                className="button-image" 
                onClick={e => onToggleOpenedStateInternal(e)} 
                aria-expanded={props.isOpened} 
                aria-label={props.isOpened ? tr.GetString("Common:ShowDetails") : tr.GetString("Common:HideDetails")}
                disabled={props.disallowTogglingOpenState}>
                <InlineSVG src={isOpened ? ExpandedIcon : CollapsedIcon} />
            </button>
        </div>
    );
}
