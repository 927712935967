import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import SsoServiceProviderService from '$src/core/Services/SsoServiceProviderService';
import Session from '$src/core/Session';
import { UrlHelper } from '$src/util/UrlHelper';
import Logger from '$core/Logger';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import SsoButton from '$components/login/SsoButton';
import { SsoSuiConfiguration } from '$src/storage/models/SsoSuiConfiguration';
import { SsoSuiConfigurationIdentityProvider } from '$src/storage/models/SsoSuiConfigurationIdentityProvider';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { SsoSelfregistrationModal } from '$components/selfRegistration/SsoSelfregistrationModal';
import { LogoutSso } from '$components/logout/LogoutSso';

interface IState {
    errorMsg: string;
    renderLoggedOutMsg: boolean;
    invalidAutoSso: boolean;
    suiConfiguration?: SsoSuiConfiguration;
    isLoading: boolean;
    showSelfregistrationModal: boolean;
    backgroundLogout: boolean;
    providerId: string;
    usedIdp: SsoSuiConfigurationIdentityProvider | null;
}

interface IProps extends RouteComponentProps {
    onLoggedIn?: () => void;
    stopAutoSso: boolean;
    onDoShowLoginForSso: () => void;
}

/**
 * This component is the container for all GTSsoServiceProvider compatible sso buttons
 * It handles the returned sso token when the sso process comes back to the nsui login page and manages the login
 */
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class SsoButtonContainer extends React.Component<IProps, IState> {
    protected loggerLocality = 'Component.SsoButtonContainer';

    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMsg: '',
            renderLoggedOutMsg: false,
            invalidAutoSso: false,
            isLoading: false,
            showSelfregistrationModal: false,
            backgroundLogout: false,
            providerId: '',
            usedIdp: null
        };
    }

    public async componentDidMount() {
        if (SsoServiceProviderService.instance.isAnySsoEnabled()) {
            this.setState({ isLoading: true });

            // completeSso = login callback from SSO ServiceProvider
            const completeSso = await this.checkSsoToken();

            // if sso was not completed, load the sso config etc.
            if (!completeSso) {
                const config = await SsoServiceProviderService.instance.getConfiguration();
                this.validateAutoSso(config);

                this.setState({
                    suiConfiguration: config,
                    isLoading: false
                });
            }
        }

        window.onbeforeunload = function () {
            Session.instance.setHasLoggedOut(false); // reset has logged out info
        }
    }

    public render() {
        if (this.state.isLoading) {
            return (
                <ProgressSpinner />
            )
        }

        return (
            <div className="ssoButtonContainer">
                {this.props.children}

                {globalConfig.loginProperties.enableSamlSSO && this.renderSsoButton('Saml2')}
                {globalConfig.loginProperties.enableGoogleSSO && this.renderSsoButton('GoogleOpenIdConnect')}
                {globalConfig.loginProperties.enableOpenIdConnectSSO && this.renderSsoButton('OpenIdConnect')}
                {globalConfig.loginProperties.enableOAuthSSO && this.renderSsoButton('OAuth2')}
                {globalConfig.loginProperties.enableWindowsSSO && this.renderSsoButton('Windows')}
                {globalConfig.loginProperties.clientSSOProviderId && this.renderSsoButton(globalConfig.loginProperties.clientSSOProviderId)}

                {this.renderSelfregistrationModal()}
                {this.renderBackgroundLogout()}

                {this.renderLoggedOutInfo()}
                {this.renderError()}
            </div>
        );
    }

    /**
     * Renders a SsoButton with the corresponding config fetched from SSOSP
     * @param {('GoogleOpenIdConnect' | 'Saml2' | 'OpenIdConnect' | 'Windows' | 'OAuth2' | globalConfig.loginProperties.clientSSOProviderId)} identityProvider
     */
    private renderSsoButton(identityProvider: 'GoogleOpenIdConnect' | 'Saml2' | 'OpenIdConnect' | 'Windows' | 'OAuth2' | string) {
        let config: SsoSuiConfigurationIdentityProvider | undefined = undefined;
        if (this.state.suiConfiguration && this.state.suiConfiguration?.identityProviders.length > 0) {
            this.state.suiConfiguration?.identityProviders.forEach((idp) => {
                if (idp.identityProvider === identityProvider) {
                    config = idp;
                }
            });
        }

        if (!config) {
            Logger.log(this.loggerLocality, 'Tried to render SsoButton, but the given identity provider was not found in the configuration fetched from SSOSP', config);
            return null;
        }

        return (
            <SsoButton
                autoSsoDelay={this.getAutoSsoDelay()}
                stopAutoSso={this.props.stopAutoSso || this.state.invalidAutoSso || this.state.renderLoggedOutMsg || this.state.errorMsg.length > 0}
                onClick={() => SsoServiceProviderService.instance.startSso(config!)}
                onTriggerAutoSso={() => !this.props.stopAutoSso && !SsoServiceProviderService.instance.getStopAutoSso && SsoServiceProviderService.instance.startSso(config!)}
                {...config}
            />
        )
    }

    private renderError(): JSX.Element | null {
        if (!this.state.errorMsg) {
            return null;
        }

        return (
            <Alert alertAppereance="box" alertType="error" message={this.state.errorMsg}></Alert>
        );
    }

    private renderLoggedOutInfo(): JSX.Element | null {
        if (!this.state.renderLoggedOutMsg) {
            return null;
        }

        return (
            <Alert alertAppereance="single-line" alertType="success" message="Login:SSO_LoggedOutInfo"></Alert>
        );
    }

    private renderSelfregistrationModal(): JSX.Element | null {
        if (!this.state.showSelfregistrationModal) {
            return null;
        }

        return (
            <SsoSelfregistrationModal
                onCloseClicked={() => this.setState({ showSelfregistrationModal: false, backgroundLogout: true })}
                onLoginClicked={() => this.loginClicked()}
                provider={this.state.usedIdp!}>
            </SsoSelfregistrationModal>
        );
    }

    private renderBackgroundLogout(): JSX.Element | null {
        if (!this.state.backgroundLogout) {
            return null;
        }

        return (
            <LogoutSso onDone={() => this.setState({ backgroundLogout: false })} />
        );
    }

    /**
     * Checks if url has a ssoToken
     * Fetches the authentication status and perform a login, if authentication was successfull
     * Checks for automatic sso login
     * 
     * @returns true, if an ssoToken was found AND props.onLoggedIn() was called
     */
    private async checkSsoToken(): Promise<boolean> {
        const parameters = new URLSearchParams(this.props.location.search);
        const urlToken = parameters.get('ssoToken');
        let res = false;

        if (urlToken) {
            parameters.delete('ssoToken');
            this.props.history.replace(this.props.location.pathname + UrlHelper.searchToString(parameters)); // remove query param first

            const status = await SsoServiceProviderService.instance.getSsoStatus(urlToken);
            this.setState({ providerId: status.providerId });
            if (status.statusString === 'LoggedIn') {
                await Session.instance.initSsoSession(status);
                if (this.props.onLoggedIn) {
                    this.props.onLoggedIn();
                    res = true;
                }
                sessionStorage.setItem('fromSso', 'true');
            } else if (status.isIdPAuthenticated === true && (status.statusString === 'UserNotFoundError' || status.statusString === 'ToManyUsersFoundError')) {
                const config = await SsoServiceProviderService.instance.getConfiguration();
                const idp: SsoSuiConfigurationIdentityProvider = config.identityProviders.filter(p => p.identityProvider == status.providerId)[0];
                sessionStorage.setItem('fromSso', 'true');
                this.setState({ showSelfregistrationModal: true, usedIdp: idp });
                sessionStorage.setItem('fromSso', 'true');
            } else {
                this.setState({
                    errorMsg: 'ErrorMessage:SSO_' + status.statusString
                });
                sessionStorage.setItem('fromSso', 'false');
                Logger.log(this.loggerLocality, `getSsoStatus() returned ${status.lastErrorMessage}`);
            }
        }

        return res;
    }

    /**
     * Returns the configured sso delay if there is no reason to stop auto sso
     * @private
     * @returns {(number | undefined)} undefined, if sso should not start automatically, or the number of milliseconds for delay
     */
    private getAutoSsoDelay(): number | undefined {
        if (globalConfig.loginProperties.autoStartSsoDelay === null || this.state.renderLoggedOutMsg || this.state.errorMsg || this.props.stopAutoSso || this.state.invalidAutoSso) {
            return undefined;
        }

        return globalConfig.loginProperties.autoStartSsoDelay;
    }

    /**
     * Checks if globalConfig.loginProperties.autoStartSsoDelay is set
     * Checks if only one idp is configured so it is clear what sso should start
     * Checks for any reasons auto sso has to be paused
     * @returns True or false and sets state stopAutoSso value
     */
    private validateAutoSso(config: SsoSuiConfiguration): boolean {
        let bValid: boolean = true;

        // is auto sso not configured or are there any error messages
        if (globalConfig.loginProperties.autoStartSsoDelay === null || this.state.errorMsg) {
            bValid = false;
        }

        // has the user logged out recently
        if (Session.instance.getHasLoggedOut) {
            this.setState({
                renderLoggedOutMsg: true,
                backgroundLogout: true
            });
            bValid = false;
        }

        // is there no fetched sui configureation from SSOSP
        if (config === undefined) {
            bValid = false;
        }

        // is no identity provider configured
        if (bValid && SsoServiceProviderService.instance.getEnabledIdentityProviders().length < 1) {
            bValid = false;
            Logger.log(this.loggerLocality, 'Auto sso is configured but no configured identity provider found');
        }

        // are more then one identity provider configured that is not blocked by ip
        if (bValid && SsoServiceProviderService.instance.getEnabledIdentityProviders().length > 1 && config.identityProviders.filter(p => p.isClientIPEnabled).length > 1) {
            bValid = false;
            Logger.log(this.loggerLocality, 'Auto sso is configured but more then one configured identity providers found. Identity provider must be clear');
        }

        this.setState({ invalidAutoSso: !bValid });

        return bValid;
    }

    /**
     * User merge modal - login clicked
     */
    private loginClicked() {
        this.props.onDoShowLoginForSso();
        this.setState({ showSelfregistrationModal: false });
    }
}
