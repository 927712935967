import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import PurchaseService from '$src/core/Services/PurchaseService';
import { isSuccess } from '$src/util/Result';
import { Item } from '$src/storage/models/Item';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import ItemSummaryMinimalDetail from '$src/components/item/itemSummaries/ItemSummaryMinimalDetail';
import { ItemMinimalDetails } from "$src/components/item/itemSummaries/ItemSummaryMinimalDetail";
import { EItemType, } from '$src/storage/models/enums';
import { PurchasedItem } from '$src/storage/models/Purchase/PurchasedItem';
import { useHistory } from "react-router";
import GTButton from '$components/shared/Atoms/GTButton';


/**
 * Shows the list of purchased items after the checkout
 */
export default function PurchasedItemsList() {       
    const { referenceCode } = useParams<{ referenceCode: string }>(); // identifies the checkout
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [loadPurchasedItems, setLoadPurchasedItems] = useState<boolean>(true);
    const [items, setItems] = useState<PurchasedItem[] | null | undefined>(undefined);
    const history = useHistory();

    useEffect(() => {
        if (loadPurchasedItems) {
            setLoadPurchasedItems(false);
            PurchaseService.instance.getPurchasedItems(referenceCode)
            .then(response => {
                if (isSuccess<PurchasedItem>(response)) {
                    setItems(response as Array<PurchasedItem>);
                    setIsLoaded(true);
                }        
            });    
        }
    });

    function getItemMinimalDetailsForItem(item: Item, classInfo: string, detailLink: string): ItemMinimalDetails {
        const itemMinimalDetails: ItemMinimalDetails = {
            itemId: item.itemId,
            itemTitle: item.title != null ? item.title.Ellipsis(globalConfig.itemProperties.maxTitleLengthInItemList) : '',
            itemDescription: classInfo != null ? classInfo.Ellipsis(globalConfig.itemProperties.maxSummaryLengthInItemList) : '',
            itemType: item.itemType,
            itemSubType: item.itemSubType,
            itemStatus: item.lessonStatus,
            navUrl: detailLink,
            isRatingEnabled: item.isRatingEnabled && globalConfig.lessonToolbarProperties.displayRating,
            ratingCount: item.ratingCount,
            myRating: item.ratingMine / 2,
            averageRating: item.ratingAverage / 2,
            isLocked: (item.isLockedDueToRequiredSkills  
                || item.isLockedDueToAssignments
                || item.lessonLockedByAttemptsUntil != null),
            isLockedDueToAssignments: item.isLockedDueToAssignments,
            isLockedDueToRequiredSkills: item.isLockedDueToRequiredSkills,
            itemIcon: item.icon,
            lessonLockedByAttemptsUntil: item.lessonLockedByAttemptsUntil
        }
        return itemMinimalDetails;
    }
    
    function getDetailLink(item: Item): string {
        let link = '';
        if (item.itemType == EItemType.TrainingPlan) {
            link = '/trainingPlanDetail/' + item.itemId;
        } else {
            link = '/itemDetail/' + item.itemId;
        }
        return link;
    }


    return (
        <div className="l-container">
            <Heading headingLevel={1} cssClass="heading__Title">
                <Translate>Purchase:ConfirmationTitleUser</Translate>
            </Heading>
            <div>
                <Translate>Purchase:ConfirmationPhraseUser</Translate>
            </div>
            <div className="purchased-items__container">
                {isLoaded && items && <ul>
                    {
                        items.map(i => <li key={`Item_${i.item.itemId}`}><div className="purchased-items__item">
                            <ItemSummaryMinimalDetail
                                id={`Item_${i.item.itemId}`}
                                itemDetails={getItemMinimalDetailsForItem(i.item, i.additionalInfo, getDetailLink(i.item))}
                                parentHeadingLevel={1}
                                isAssignment={false}
                            />
                        </div></li>)
                    }
                </ul>
                }
                {!isLoaded && <ProgressSpinner />}
            </div>
            <div className="purchased-items-list__buttons">
                <GTButton onClick={() => history.push('/catalog/1')} >
                    <Translate>PaymentBooking:GoToCatalog</Translate>
                </GTButton>
                <GTButton onClick={() => history.push('/myLessons')} >
                    <Translate>PaymentBooking:GoToMyLessons</Translate>
                </GTButton>
                <GTButton onClick={() => history.push('/more')} >
                    <Translate>PaymentBooking:GoToMore</Translate>
                </GTButton>
            </div>

        </div>
    )
}
