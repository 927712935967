import { Translate } from '$components/shared/Translate';
import Logger from '$src/core/Logger';
import AuthService from '$src/core/Services/AuthService';
import Session from '$src/core/Session';
import { StringResponse } from '$src/storage/models/StringResponse';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { RefObject } from 'react';

interface IProps  {
    url: string | undefined;
    noUrl: string;
    pageTitle?: string;
}

interface IState {
    accessToken?: string;
    lastRefresh?: number;
    index: number;
}

const iframeStyle = {
    background: 'transparent',
    boder: 'none',
    marginTop: '20px',
    outline: 'none',
}

/** 
 * This class is used to integrate external applications or pages as control in the SUI.
 * for theses pages the route property "IsExternal" must be set to true
 */
export class ExternalPageControl extends React.Component<IProps, IState> {
    protected className = 'ExternalPageControl';
    protected loggerLocality = 'Components.ExternalPageControl';
    protected iframe: RefObject<HTMLIFrameElement>;
    constructor(props: IProps) {
        super(props);
        this.iframe = React.createRef();
        this.state = {
            index: 1,
            lastRefresh: Date.now()
        };
    }

    public async componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        if (Session.instance.isUserLoggedIn) {
            await AuthService.instance.GetExternalAccessToken().then(resp => {
                if (isSuccess<StringResponse>(resp)) {
                    this.setState({ accessToken: resp.value });
                } else {
                    this.setState({ accessToken: 'failed' });
                }
            });
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.resize.bind(this));
    }

    private resize() {
        const height = this.calculateIframeHeight();
        if (this.iframe != null && this.iframe.current != null) {
            this.iframe.current.height = `${height}px`;
        }
    }

    private calculateIframeHeight(): number {
        const methodName = `${this.className}:calculateIframeHeight()`;
        if (document == null || document.documentElement == null) {
            return 0;
        }
        let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        height -= 340; // space for header & footer
        Logger.log(this.loggerLocality, `${methodName} changing height of iframe to ${height}px.`);
        return height;
    }

    public render() {
        return (
            <div className="l-container">
                {this.renderPageTitle(this.props.pageTitle)}
                {this.renderIFrame()}
                {this.renderNoUrl()}
            </div>
        )
    }

    protected renderNoUrl() {
        if(this.props.url === undefined){
            return (    
                <React.Fragment>
                    <div><Translate>{this.props.noUrl}</Translate></div>
                </React.Fragment> 
            )
        }    
        return (<React.Fragment/>)
    }                    

    protected renderIFrame(){
        if(this.props.url !== undefined && (this.state.accessToken !== undefined || !Session.instance.isUserLoggedIn)) {
            const iframeId = 'frameContentExtern';
            const height = this.calculateIframeHeight();
            
            const url = new URL(this.props.url);
            if (Session.instance.isUserLoggedIn) {
                url.searchParams.set('at', this.state.accessToken as string);
            }

            return (
                <iframe
                    id={iframeId}
                    key={this.state.index}
                    style={iframeStyle}
                    src={url.toString()}
                    width="100%"
                    height={`${height}px`}
                    frameBorder="0"
                    allowFullScreen={true}
                    ref={this.iframe}        
                />
            )
        }    
        
        return (<React.Fragment/>)    
    }

    protected renderPageTitle(pageTitle: string | undefined): JSX.Element | null {
        return pageTitle != null ? <div className="l-box-container"><h1 className="heading__Title"><Translate>{pageTitle}</Translate></h1></div> : null;
    }
}
