import { ERecoverLoginMode } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class RecoverLoginModeConverter implements JsonCustomConvert<ERecoverLoginMode> {

    public serialize(loginMode: ERecoverLoginMode): any {
        switch (loginMode) {
            case ERecoverLoginMode.GenerateRandomPassword: return 1;
            case ERecoverLoginMode.SetNewPassword: return 2;
            case ERecoverLoginMode.SendPin: return 3;
            case ERecoverLoginMode.StartWorkflow: return 4;

            default: return 0;
        }
    }
    public deserialize(jsonValue: number): ERecoverLoginMode {
        switch (jsonValue) {
            case 1: return ERecoverLoginMode.GenerateRandomPassword;
            case 2: return ERecoverLoginMode.SetNewPassword;
            case 3: return ERecoverLoginMode.SendPin;
            case 4: return ERecoverLoginMode.StartWorkflow;
            default: return ERecoverLoginMode.UNKNOWN;
        }
    }
}
