import React from 'react';
import InlineSVG from 'react-inlinesvg';
import SkillMet from '$resources/svgs/skills/skill-met.svg';
import SkillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import SkillExpired from '$resources/svgs/skills/skill-expired.svg';
import ExpirationIcon from '$resources/svgs/skills/skill-expiration.svg';
import SkillLevelIcon from '$resources/svgs/skills/skill-level.svg';
import { Tooltip } from '$components/shared/Tooltip';
import { StringHelper } from '$src/util/StringHelper';
import Session from '$src/core/Session';
import { NavLink } from 'react-router-dom';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

interface IProps {
    skill: PerformanceSupportPanelBoxModel;
    skillType: EPerformanceSupportPanelType;
    userId: number;
}

export enum EPerformanceSupportPanelType {
    Expired = 1,
    Expiring = 2,
    MissingLevel = 3,
}

export default function PerformanceSupportPanelBox(props: IProps) {
    const currentUserSkillLevelText = props.skill.UserSkillLevelTitle || '---';
    const cssClassIconFaded = 'status__missing-level';
    const expirationDate: string = props.skill.ExpirationDate && StringHelper.dateString(props.skill.ExpirationDate) || '';

    const skillCurrentLevelIconText = Session.instance.storage.translation.GetString('PerformanceSupport:CurrentUserLevel').Format(currentUserSkillLevelText);
    const skillTargetLevelIconText = Session.instance.storage.translation.GetString('PerformanceSupport:UserTargetLevel').Format(props.skill.TargetSkillLevelTitle);
    const expirationIconText = Session.instance.storage.translation.GetString('PerformanceSupport:ValidUntil').Format(expirationDate);

    let tooltipText = '';
    let iconPanel = SkillNotMet;
    let cssClass = 'status__not-received';
    let htmlOutput: JSX.Element | undefined;

    switch (props.skillType) {
        case EPerformanceSupportPanelType.Expired:
            iconPanel = SkillExpired;
            cssClass = 'status__achieved--expired';
            tooltipText = Session.instance.storage.translation.GetString('PerformanceSupport:ExpiredStatus');
            htmlOutput = (
                <>
                    <div className="performanceSupportPanel__skill-box__text inline-svg--text">
                        <InlineSVG src={SkillLevelIcon} aria-label={skillCurrentLevelIconText} />
                        <p className="performanceSupportPanel__skill-box__text">{currentUserSkillLevelText}</p>
                    </div>
                    <div className="performanceSupportPanel__skill-box__text inline-svg--text">
                        <InlineSVG src={ExpirationIcon} aria-label={expirationIconText} />
                        <p className="performanceSupportPanel__skill-box__text">{expirationDate}</p>
                    </div>
                </>
            );
            break;
        case EPerformanceSupportPanelType.Expiring:
            iconPanel = SkillMet;
            cssClass = 'status__achieved';
            tooltipText = Session.instance.storage.translation.GetString('PerformanceSupport:ValidStatus');
            htmlOutput = (
                <>
                    <div className="performanceSupportPanel__skill-box__text inline-svg--text">
                        <InlineSVG src={SkillLevelIcon} aria-label={skillCurrentLevelIconText} />
                        <p className="performanceSupportPanel__skill-box__text">{currentUserSkillLevelText}</p>
                    </div>
                    <div className="performanceSupportPanel__skill-box__text inline-svg--text">
                        <InlineSVG src={ExpirationIcon} aria-label={expirationIconText} />
                        <p className="performanceSupportPanel__skill-box__text">{expirationDate}</p>
                    </div>
                </>
            );
            break;
        case EPerformanceSupportPanelType.MissingLevel:
            cssClass = 'status__not-received';
            iconPanel = SkillNotMet;
            if (props.skill.UserSkillLevelTitle) {
                tooltipText = Session.instance.storage.translation.GetString('PerformanceSupport:MissingLevelStatus');
            } else {
                tooltipText = Session.instance.storage.translation.GetString('PerformanceSupport:NotRecievedStatus');
            }
            htmlOutput = (
                <>
                    <div className="performanceSupportPanel__skill-box__text inline-svg--text" >
                        <InlineSVG src={SkillLevelIcon} className={cssClassIconFaded} aria-label={skillCurrentLevelIconText} />
                        <p className="performanceSupportPanel__skill-box__text">{currentUserSkillLevelText}</p>
                    </div>
                    <div className="performanceSupportPanel__skill-box__text inline-svg--text" >
                        <InlineSVG src={SkillLevelIcon} className={cssClass} aria-label={skillTargetLevelIconText} />
                        <p className="performanceSupportPanel__skill-box__text">{props.skill.TargetSkillLevelTitle}</p>
                    </div>
                </>
            );
            break;
        default:
            iconPanel = SkillNotMet;
            cssClass = 'status__not-received';
            tooltipText = Session.instance.storage.translation.GetString('PerformanceSupport:NotRecievedStatus');
    }

    return (
        <li key={props.skill.SkillId} className={`performanceSupportPanel__skill-box ${cssClass}`} >
            <NavLink
                to={`/trainings/${props.skill.SkillId}/${props.userId}/0/0/0/0/0/?context=${EItemDetailCallerContextType.MyPerformanceSupport}${props.skill.skillProfileId ? '&skillProfileId=' + props.skill.skillProfileId : ''}`}>
                <div className="performanceReview-title__icon__small">
                    <div className="performanceReview-title__icon__small__tooltip"
                        aria-label={tooltipText}
                        data-tip={tooltipText}
                        data-for={`skillStatusIconTooltip_${props.skill.SkillId}`}
                        role="img">
                        <InlineSVG src={iconPanel} className={cssClass} />
                        <Tooltip id={`skillStatusIconTooltip_${props.skill.SkillId}`} />
                    </div>
                </div>
                <div className="performanceSupportPanel__skill-box__text title">
                    <span className="overflow---ellipsis">{props.skill.SkillTitle}</span>
                </div>
                {htmlOutput}
            </NavLink>
        </li>
    );
}

export class PerformanceSupportPanelBoxModel {
    public SkillId: number = 0;
    public SkillTitle: string = '';
    public UserSkillLevelTitle?: string;
    public TargetSkillLevelTitle: string = '';
    public ExpirationDate?: Date;
    public MayBeExpired: boolean;
    public skillProfileId?: number;
}