import JsonHelper from '$src/util/JsonHelper';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import { EItemType } from '$storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export default class ItemTypeModel  {

    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType;


    @JsonProperty('text', String, false)
    public text: string;
    

    constructor(itemType: EItemType, icon: string, text: string){
        this.itemType = itemType;
        this.text=text;
    }

    public static itemTypeArrayToString(currentItemTypeArray?: ItemTypeModel[]) {
        const s = JsonHelper.toString(currentItemTypeArray)
        if(s != null)
        {            
            return encodeURI(s)
        }
        return '';
    }


}