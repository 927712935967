import React from 'react';

import { FavoriteRibbon } from '$components/favorites/FavoriteRibbon';
import ToolbarTrainingPlanElement from '$components/item/Toolbar/ToolbarTrainingPlanElement';
import { Heading } from '$components/shared/Heading';
import Logger from '$core/Logger';
import { SummaryAttribute } from '$src/components/shared/Attributes/SummaryAttribute';
import { EFavoriteType, EItemType } from '$storage/models/enums';
import { TrainingPlanElement } from '$storage/models/TrainingPlan/TrainingPlanElement';
import { KeyPressHelper } from '$util/KeyPressHelper';
import '$util/stringExtensions';

interface IProps {
    trainingPlanElement: TrainingPlanElement;
    parentHeadingLevel: number;
    allowSelection: boolean;
    onItemClicked?: () => void;
}

/**
 * @deprecated The method should not be used
 */
export class ItemSummaryTrainingPlanElement extends React.Component<IProps, {}> {
    protected className = 'ItemSummaryTrainingPlanElement';
    protected logger = 'Components.ItemSummaryTrainingPlanElement';
    constructor(props: IProps) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    public render() {
        const tpElement = this.props.trainingPlanElement;
        const title = tpElement.title != null ? tpElement.title.Ellipsis(globalConfig.itemProperties.maxTitleLengthInItemList) : '';
        let summary: string;
        let cssClass: string = '';
        if (this.props.trainingPlanElement.type === EItemType.Block) {
            summary = tpElement.description != null ? tpElement.description.Ellipsis(globalConfig.itemProperties.maxSummaryLengthInItemList) : '';
            cssClass = 'item-summary__modul';
        }
        else {
            summary = tpElement.summary != null ? tpElement.summary.Ellipsis(globalConfig.itemProperties.maxSummaryLengthInItemList) : '';
            cssClass = 'item-summary__lesson';
        }
        if (tpElement.isLocked || !this.props.allowSelection) {
            cssClass = cssClass + ' item-summary__locked';
        }
        Logger.log(this.logger, `Rendering Training Plan Element with id: ${this.props.trainingPlanElement.sId}, 
        title: ${this.props.trainingPlanElement.title}, type: ${this.props.trainingPlanElement.type.toString()}, clicking allowed: ${this.props.allowSelection}`);

        const itemContent = (
            <React.Fragment>
                <div className="title">
                    <Heading headingLevel={this.props.parentHeadingLevel + 1} cssClass="heading__Level4">
                        <span dangerouslySetInnerHTML={{ __html: title.toString() }} />
                    </Heading>
                </div>
                <div className="favorite">
                    {globalConfig.favoritesProperties.enableFavorites &&
                        globalConfig.favoritesProperties.enableFavoritesOnTPlanElements &&
                        this.props.trainingPlanElement.type !== EItemType.Block &&
                        this.props.allowSelection ?
                        (<FavoriteRibbon
                            itemTitle={this.props.trainingPlanElement.title}
                            itemId={this.props.trainingPlanElement.tpeId}                            
                            type={EFavoriteType.TPlanElement} />) :
                        (<div />)}
                </div>
                <SummaryAttribute summary={summary} className="text"/>
                <ToolbarTrainingPlanElement allowSelection={this.props.allowSelection} trainingPlanElement={this.props.trainingPlanElement} />
            </React.Fragment>
        );

        if (this.props.allowSelection) {
            return <li key={this.props.trainingPlanElement.sId}
                className={'l-box--short l-box--shadow item-summary item-summary--link ' + cssClass}
                role="link"
                tabIndex={0}
                onClick={() => this.props.allowSelection ? this.onClick() : null}
                onKeyUp={(e) => this.props.allowSelection ? KeyPressHelper.executeWhenSpaceOrEnter(e, () => this.onClick()) : null}>
                {itemContent}
            </li>
        }
        else {
            return <li key={this.props.trainingPlanElement.sId}
                className={'l-box--short l-box--shadow item-summary ' + cssClass}>
                {itemContent}
            </li>
        }
    }

    protected onClick() {
        if (this.props.onItemClicked != null) {
            this.props.onItemClicked();
        }
    }
}