import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class AssignLessonToUserResponse {

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('assigned', Boolean, false)
    public assigned: boolean = false;

    @JsonProperty('isAlreadyAssigned', Boolean, false)
    public isAlreadyAssigned: boolean = false;

}

// This container is needed to be able to json convert the array
// tslint:disable-next-line: max-classes-per-file
@JsonObject
export class AssignLessonToUserResponseContainer {

    @JsonProperty('assignLessonToUserResponse', [AssignLessonToUserResponse], false)
    public assignLessonToUserResponse: AssignLessonToUserResponse[] = [];

}
