import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * General information about the file sharings a user has access to
 * @export
 * @class MyFileSharing
 */
@JsonObject
export class MyFileSharing {

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('userId', Number, false)
    public userId: number = 0;

    @JsonProperty('courseTitle', String, false)
    public courseTitle: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('assignmentId', Number, true)
    public assignmentId?: number = 0;

    @JsonProperty('tPlanId', Number, true)
    public tPlanId?: number = 0;

    @JsonProperty('scheduleId', Number, true)
    public scheduleId?: number = 0;
}