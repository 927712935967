
import { JsonObject, JsonProperty } from 'json2typescript';
import { LegacySearchedItem } from '$src/storage/models/search/legacy/LegacySearchedItem';

/** This is the Model Class for the ServerResponse for all SearchResults */
@JsonObject
export class LegacySearchResponse {

    @JsonProperty('searchResults', [LegacySearchedItem], false)
    public searchResults: LegacySearchedItem[] = [];
}




