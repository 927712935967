import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class CancelRegistrationRequest {

    @JsonProperty('registrationId', Number, false)
    public registrationId: number = 0;

    @JsonProperty('cancellationReasonId', Number, false)
    public cancellationReasonId: number = 0;

    @JsonProperty('cancellationReasonText', String, false)
    public cancellationReasonText: string = '';
    
}