

import ServiceClient from '$src/core/ServiceClient';
import { EmployeeRegisteredItem } from '$src/storage/models/EmployeeRegisteredItem';
import { ParticipantListForTutor } from '$src/storage/models/ParticipantListForTutor';
import { RegisteredItem } from '$src/storage/models/RegisteredItem';
import { CancelRegistrationRequest } from '$src/storage/models/RequestObjects/CancelRegistrationRequest';
import { F2FRegistrationRequest } from '$src/storage/models/RequestObjects/F2FRegistrationRequest';
import { ItemRegistrationRequest } from '$src/storage/models/RequestObjects/ItemRegistrationRequest';
import { User } from '$src/storage/models/User';
import GtError from '$util/GtError';
import { BooleanResponse } from './../../storage/models/BooleanResponse';

/**
 * RegistrationService provides all Service Methods for all the registrations 
 */
export default class RegistrationService extends ServiceClient {
    protected static _instance: RegistrationService | null = null;

    protected className = 'RegistrationService';
    protected loggerLocality = 'RegistrationService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): RegistrationService {
        return this._instance || (this._instance = new this());
    }

    public async saveF2FRegistration(courseId: number, classId: number, selectedBossId: number, registerAsStatusRequested: boolean): Promise<BooleanResponse | GtError> {
        const obj = new F2FRegistrationRequest();
        obj.courseId = courseId;
        obj.classId = classId;
        obj.selectedBossId = selectedBossId;
        obj.registerAsStatusRequested = registerAsStatusRequested;
        return await this.post<BooleanResponse>('lesson/f2fRegistration', obj, BooleanResponse, undefined);
    }

    public async saveItemRegistration(lessonId: number): Promise<BooleanResponse | GtError> {
        const obj = new ItemRegistrationRequest();
        obj.lessonId = lessonId;      
        return await this.post<BooleanResponse>('lesson/lessonRegistration', obj, BooleanResponse, undefined);
    }

    public async cancelRegistration(registrationId: number, cancellationReasonId: number, cancellationReasonText: string): Promise<BooleanResponse | GtError> {        
        const obj = new CancelRegistrationRequest();
        obj.registrationId = registrationId;      
        obj.cancellationReasonId = cancellationReasonId;
        obj.cancellationReasonText = cancellationReasonText;
        return await this.post<BooleanResponse>('lesson/f2fRegistration/cancel', obj, BooleanResponse, undefined);
    }

    /**
     * Get registered items of current user from web API. 
     * @param language language
     */
    public async getRegisteredItems(language: string, force: boolean): Promise<RegisteredItem[] | GtError> {
        const response: RegisteredItem[] | GtError = await this.get<RegisteredItem[]>('registeredItem',
         RegisteredItem,
         undefined,
         [{name:'force', value:force ? 'true' : 'false' },
          {name:'language', value:language }
         ]);
        return response;
    }

    /**
     * Get registered items of current user from web API. 
     * @param language language
     */
    public async getMyEmployeesRegisteredCourseItems(): Promise<EmployeeRegisteredItem[] | GtError> {
        const response: EmployeeRegisteredItem[] | GtError = await this.get<EmployeeRegisteredItem[]>('getMyEmployeesRegisteredCourseItems', EmployeeRegisteredItem, undefined, undefined);
        return response;
    }

    public async getRegisteredUsers(scheduleId: number, itemType: number, itemSubType: number): Promise<User[] | GtError> {
        const response: User[] | GtError = await this.get<User[]>('registeredUsers', User, undefined, undefined, scheduleId.toString(), itemType.toString(), itemSubType.toString());
        return response;
    }

    /**
     * Get information about f2f class and all registered participants for participant list for tutors and admins
     * @param scheduleId Id of class to get participant details
     */
    public async getParticipantListForTutor(scheduleId: number): Promise<ParticipantListForTutor | GtError> {
        const response: ParticipantListForTutor | GtError = await this.get<ParticipantListForTutor>('participantListTutor',
            ParticipantListForTutor,
            undefined, undefined,
             scheduleId.toString());
        return response;
    }
} 