import Logger from "$src/core/Logger";
import ConfigService from "$src/core/Services/ConfigService";
import MultiFactorAuthenticationService from "$src/core/Services/MultiFactorAuthenticationService";
import PasswordService from "$src/core/Services/PasswordService";
import Session from "$src/core/Session";
import { ERecoverLoginMode, ESubStatusCodes } from "$src/storage/models/enums";
import { UserResponse } from "$src/storage/models/MultiFactorAuthentication/UserResponse";
import { NumberResponse } from "$src/storage/models/NumberRepsonse";
import { RecoverPasswordResponse } from "$src/storage/models/RecoverPasswordResponse";
import { UpdateData } from "$src/storage/models/RequestObjects/MultiFactorAuthentication/UpdateData";
import CustomErrorMessage from "$src/util/CustomErrorMessage";
import GtError from "$src/util/GtError";
import { isSuccess } from "$src/util/Result";
import React, { useEffect } from "react";
import { Redirect } from "react-router";
import GTButton from "$components/shared/Atoms/GTButton";
import { Heading } from "$components/shared/Heading";
import { InputAttributes } from "$components/shared/InputAttributes";
import { InputText } from "$components/shared/InputText";
import { ProgressSpinner } from "$components/shared/ProgressSpinner";
import { Translate } from "$components/shared/Translate";
import { Alert } from "$components/shared/WarningsAndErrors/Alert";
import { ErrorMessage } from "$components/shared/WarningsAndErrors/ErrorMessage";

interface IProps {
    userData: UserResponse;
    initialCode: string;
}

export default function CheckDataForPasswordRecovery(props: IProps) {
    const loggerLocality = 'InitialCodeCheck';

    const [recoverMode, setRecoverMode] = React.useState<number>();
    const [email, setEmail] = React.useState<string>(props.userData.email);
    const [mobile, setMobile] = React.useState<string>(props.userData.mobileNumber);
    const [updateData, setUpdateData] = React.useState<boolean>(false);
    const [updatedData, setUpdatedData] = React.useState<UserResponse | undefined>();
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [redirectPIN, setRedirectPIN] = React.useState<boolean>(false);
    const [showSuccessfullMessage, setShowSuccessfullMessage] = React.useState<boolean>(false);
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            await ConfigService.instance.getPasswordRecoveryMode(Session.instance.getUserLanguageCodeOrFallBack)
                .then((result) => {
                    if (isSuccess<NumberResponse>(result)) {
                        setRecoverMode(result.value);
                    }
                });
        }

        if (!recoverMode) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            setShowSpinner(true);
            const updateData = new UpdateData();
            updateData.initialCode = encodeURIComponent(props.initialCode);
            updateData.username = props.userData.username;
            updateData.domainName = props.userData.domainName;
            updateData.email = email;
            updateData.mobileNumber = mobile;
            await MultiFactorAuthenticationService.instance.updateData(updateData)
                .then((result) => {
                    if (isSuccess<UserResponse>(result)) {
                        setUpdatedData(result);
                    } else {
                        setUpdatedData(undefined);
                        if (result.detailedObject) {
                            setErrorMessage(CustomErrorMessage.getErrorCodeMessageString(result.detailedObject.subStatusCode));
                        } else {
                            setErrorMessage("ErrorMessage:UpdateDataFailed");
                        }
                        Logger.log(loggerLocality, `failed to update data`);
                        console.error(`${loggerLocality} failed to update data`);
                    }
                    setShowSpinner(false);
                });
        }
        if (updateData) {
            fetchData();
        }
    }, [updateData]);

    useEffect(() => {
        async function fetchData() {
            setShowSpinner(true);
            const lang: string | null = Session.instance.languageCode;
            if (lang != null && updatedData) {
                await PasswordService.instance.recoverPassword(
                    lang,
                    updatedData.username,
                    updatedData.email,
                    updatedData.mobileNumber,
                    updatedData.domainName
                ).then((response) => {
                    if (isSuccess<RecoverPasswordResponse>(response)) {
                        Session.instance.passwordRecoverTimestamp = response.timestamp;
                        if (response.mode === ERecoverLoginMode.SendPin) {
                            setRedirectPIN(true) // Redirect to PW Change page 
                        } else {
                            setShowSuccessfullMessage(true)
                        }
                    } else { // IF there was an error or an exception
                        if (response instanceof GtError) {
                            let errMsg: string = 'ErrorMessage:PasswordRecoveryFailed';
                            if (response.detailedObject !== undefined) {
                                errMsg = CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode);
                                if (response.detailedObject.subStatusCode === ESubStatusCodes.MethodNotAllowed_NewPinNotAllowed ||
                                    response.detailedObject.subStatusCode === ESubStatusCodes.MethodNotAllowed_PWRecoveryCooldown) {
                                    errMsg = Session.instance.storage.translation.GetString(errMsg).replace('{0}', response.detailedObject.message);
                                }
                            }
                            setErrorMessage(errMsg)
                        } else {
                            setErrorMessage('ErrorMessage:PasswordRecoveryFailed');
                        }
                    }
                    setShowSpinner(false);
                });
            }
        }

        if (updatedData) {
            fetchData();
        }
    }, [updatedData])

    const emailAttributes: InputAttributes =
    {
        id: 'txtEmail',
        label: 'PasswordReset:Email',
        value: email,
        class: '',
        isReadOnly: false,
        isRequired: true,
        regExpression: globalConfig.loginProperties.mailAdressValidationExpression,
        placeholder: '',
        editMode: true,
    };

    const mobileAttributes: InputAttributes =
    {
        id: 'txtMobile',
        label: 'PasswordReset:MobileNumber',
        value: mobile,
        class: '',
        isReadOnly: false,
        isRequired: true,
        regExpression: globalConfig.loginProperties.mailAndPhoneNumberValidationExpression,
        placeholder: '',
        editMode: true,
    };

    const handleCheckDataSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setUpdateData(true);
    }

    const renderPINModeFields = () => {
        return (
            <form id="formCheckData" onSubmit={(e) => handleCheckDataSubmit(e)}>
                <InputText
                    attributes={emailAttributes}
                    onChange={(id, value) => setEmail(value)}
                    type="email"
                />
                <InputText
                    attributes={mobileAttributes}
                    onChange={(id, value) => setMobile(value)}
                />
                {showSuccessfullMessage ?
                    <Alert alertType='success' alertAppereance='single-line' message="PasswordReset:SuccessMessage" /> :
                    <GTButton id="UpdateData"
                        onClick={() => setUpdateData(true)}>
                        <Translate>MFA:UpdateData</Translate>
                    </GTButton>
                }
            </form>
        )
    }

    const renderEmailModeFields = () => {
        return (
            <form id="formCheckData" onSubmit={(e) => handleCheckDataSubmit(e)}>
                <InputText
                    attributes={emailAttributes}
                    onChange={(id, value) => setEmail(value)}
                    type="email"
                />
                {showSuccessfullMessage ?
                    <Alert alertType='success' alertAppereance='single-line' message="PasswordReset:SuccessMessage" /> :
                    <GTButton id="UpdateData"
                        onClick={() => setUpdateData(true)}>
                        <Translate>MFA:UpdateData</Translate>
                    </GTButton>
                }
            </form>
        )
    }

    return (
        <div className="l-container--center">
            {showSpinner ?
                <ProgressSpinner />
                : redirectPIN && updatedData ?
                    <Redirect push={true} to={'/pwchange?mode=pin&username=' + updatedData.username + '&domain=' + updatedData.domainName} /> :
                    <>
                        <div className="l-box__input">
                            <Heading headingLevel={1} cssClass="heading__Level4">
                                <Translate>MFA:CheckDataToSendPin</Translate>
                            </Heading>
                            <div className="text">
                                <Translate>MFA:CheckDataToSendPinHint</Translate>
                            </div>
                        </div>
                        {recoverMode === 3 ? renderPINModeFields() : renderEmailModeFields()}
                        <ErrorMessage errorMessage={errorMessage} />
                    </>
            }
        </div>
    )
}