import React from 'react';

import Session from '$core/Session';
import Iconscore from '$resources/svgs/toolbar/score.svg';
import { Item } from '$storage/models/Item';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    item: Item;
}

export class ToolbarScore extends React.Component<IProps, {}> {
    constructor (props: IProps) {
        super(props)
    }

    public render(): JSX.Element {
        const tr = Session.instance.storage.translation;
        let hoverText = tr.GetString('ItemDetail:Score');
        const itemScore = this.props.item.score ? this.props.item.score : 0;
        let score: JSX.Element = <span/>;
        if (itemScore > 0) {
            score = <React.Fragment><InlineSVG src={Iconscore} />{ `${itemScore} %` } </React.Fragment>
        }
        hoverText = hoverText+ ': ' + itemScore+ '%';
        return (
            <div className="l-box__toolbar__icon lesson-tools lesson-tools--score" 
                aria-label={hoverText} 
                data-tip={hoverText}
                data-for={`${this.props.item.itemId}_scoreTooltip`}>
                 {score}   
                 <Tooltip id={`${this.props.item.itemId}_scoreTooltip`} />
            </div>
        )
    }
}
