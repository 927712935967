import Logger from '$src/core/Logger';
import LessonService from '$src/core/Services/ItemService';
import SessionCache from '$src/storage/cache/SessionCache';
import { Item } from '$src/storage/models/Item';
import { isSuccess } from '$src/util/Result';
import Session from '$src/core/Session';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

const CACHING_DURATION = 300;

export default class ItemStorage extends SessionCache<number, Item> {
    protected className = 'ItemStorage';
    protected loggerLocality = 'Storage.ItemStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }


    public async getItemDetail(itemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId?: number, forceLoadingFromServer = true): Promise<Item | null> {
        const methodName = `${this.className}:getLesson()`;

        if (this.isObjectInCache(itemId) && !forceLoadingFromServer) {
            Logger.log(this.loggerLocality, `${methodName} getting lessonDetail from cache, lessonId=${itemId}.`);
        } else {
            // because is force loading from server, clear cache to reload item properly
            if(this.isObjectInCache(itemId)) {
                this.removeObjectFromCache(itemId);
            }
            Logger.log(this.loggerLocality, `${methodName} getting lessonDetail from server, lessonId=${itemId}`);
            const response = await LessonService.instance.getLesson(itemId, itemContext, parentTrainingPlanId);
            if (isSuccess<Item>(response)) {
                this.saveObjectToCache(itemId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get lessonDetail from server, lessonID=${itemId}.`);
            }
        }
        return this.getObjectFromCache(itemId);
    }

    public async getOutputLessonsForSkill(skillId: number, userId: number, bossRelationCode: string): Promise<Item[] | null> {
        const methodName = `${this.className}:getOutputLessonForSkill()`;

        Logger.log(this.loggerLocality, `${methodName} getting output lessons for skill from server, skillId=${skillId}`);
        const response = await LessonService.instance.getOutputLessonsForSkill(skillId, userId, bossRelationCode);
        if (isSuccess<Item[]>(response)) {
            response.forEach(item => {
                this.saveObjectToCache(item.itemId, item);
            })
            return response;
        } else {
            Session.instance.setLastErrorMessage(response.message);
            Logger.log(this.loggerLocality, `${methodName} failed to get lessons for skill from server, lessonID=${skillId}.`);
            return null;
        }
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}