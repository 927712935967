import Session from '$src/core/Session';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router';
import { ProgressSpinner } from '../shared/ProgressSpinner';
import SsoServiceProviderService from '$src/core/Services/SsoServiceProviderService';


interface IState {
    isLogoutFinished: boolean;
    redirectUrl: string;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class Logout extends React.Component<RouteComponentProps<{}>, IState> {

    private _isMounted: boolean = false;
    constructor(props: RouteComponentProps<{}>) {
        super(props);   
        this.state = {
            isLogoutFinished: false,
            redirectUrl: '/'
        };
    }

    public componentDidMount() {
        this._isMounted = true;
    }

    public render() {       
        if (this.state.isLogoutFinished) {
            return <Redirect push={true} to={this.state.redirectUrl} />
        } else {
            this.logout();
            return <ProgressSpinner />
        }    
    }

    public componentWillUnmount() {
        this._isMounted = false;
    }

    protected async logout() {
        Session.instance.setHasLoggedOut(true);
        if (Session.instance.isUserLoggedIn) {
            await Session.instance.logout();              
            
            if (SsoServiceProviderService.instance.isAnySsoEnabled()) {
                SsoServiceProviderService.instance.sessionSignOut();
            }

            // must be at the and because of possible auto sso login logic
            this.props.history.push('/');    
        }
       

        if(this._isMounted) {
            // try sso sign out - returns nothing and just continues, if no sso sign in is present
            SsoServiceProviderService.instance.sessionSignOut();

            this.setState({
                isLogoutFinished: true,
                redirectUrl: '/'
            });
        }
    }

}
export default Logout;