import { EGlossaryAssociationType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class GlossaryAssociationTypeConverter implements JsonCustomConvert<EGlossaryAssociationType> {
    public serialize(lessonStatus: EGlossaryAssociationType): number {
        switch (lessonStatus) {
            case EGlossaryAssociationType.UserPinned: return 1;
            case EGlossaryAssociationType.Config: return 2;
            case EGlossaryAssociationType.Groups: return 3;
            case EGlossaryAssociationType.Item: return 4;
            default: return 1;
        }
    }
    public deserialize(jsonValue: number): EGlossaryAssociationType {
        switch (jsonValue) {
            case 1: return EGlossaryAssociationType.UserPinned;
            case 2: return EGlossaryAssociationType.Config;
            case 3: return EGlossaryAssociationType.Groups;
            case 4: return EGlossaryAssociationType.Item;

            default: return EGlossaryAssociationType.UserPinned;
        }
    }
}
