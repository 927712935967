import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
 export default class TranslationObject {
    [key: string]: string;

    @JsonProperty('key', String, false)
    public key: string = '';
    @JsonProperty('text', String, false)
    public text: string ='';

   
   public constructor(key: string='', text: string=''){
        this.key = key;
        this.text = text;
       
    }
    
}



