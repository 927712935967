import React from 'react';
import InlineSVG from 'react-inlinesvg';
import Session from '$core/Session';

import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import { EItemType } from '$src/storage/models/enums';
import { NumberHelper } from '$src/util/NumberHelper';
import { Tooltip } from '$components/shared/Tooltip';
import GTIconButton from '$components/shared/Atoms/GTIconButton';
import ShoppingBasketItemParticipants from '$components/shoppingBasket/ShoppingBasketItemParticipants';
import { Translate } from '$components/shared/Translate';

import IconDelete from '$resources/svgs/misc/delete.svg';
import IconF2F from '$resources/svgs/lessonTypes/internal-lesson.svg';
import IconTrainingPlan from '$resources/svgs/lessonTypes/training-plan.svg';
import IconWbt from '$resources/svgs/lessonTypes/online-lesson.svg';
import IconGroupDiscount from '$resources/svgs/shoppingBasket/discount_group.svg';
import IconVoucherDiscount from '$resources/svgs/shoppingBasket/discount_voucher.svg';
import GTTooltipIcon from '../shared/Atoms/GTTooltipIcon';
import DateHelper from '$src/util/DateHelper';
import { ProgressSpinner } from '../shared/ProgressSpinner';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`${str}`);
}
interface IShoppingBasketItemComponentProps {
	item: ShoppingBasketItem;
	onDeleteClicked: () => void;
	onUserDeleteClicked: (userId: number) => void;
	onItemUpdated: (item: ShoppingBasketItem) => void;
	isRecalculating: boolean;
}

/**
 * Renders one item/class of the shopping basket and optionally the booked users
 * @param props 
 * @returns 
 */
export default function ShoppingBasketItemComponent(props: IShoppingBasketItemComponentProps) {

	const icon = () => {
		switch (props.item.itemType) {
			case EItemType.WBT:
				return IconWbt;
			case EItemType.F2FCourse:
				return IconF2F;
			case EItemType.TrainingPlan:
				return IconTrainingPlan;
			default:
				return '';
		}
	}

	const itemTypeName = `${getTranslation('ItemDetail:ItemType')}: ${getTranslation('ItemType:' + EItemType[props.item.itemType])}`;
	const deleteTooltipText = getTranslation('ShoppingBasketContent:DeleteItem');
	
	const itm = props.item;
	const classString = itm.classId > 0 ? `${itm.classStart ? DateHelper.toDateString(itm.classStart) : ''}${itm.classEnd ? ' - ' + DateHelper.toDateString(itm.classEnd) : ''}${itm.classLocation ? ', ' + itm.classLocation : ''}` : '';

	return (
		<>
			<div className='shopping-basket-content__item-title-container'>
				<div role='cell' className='shopping-basket-content__item-icon' aria-label={itemTypeName} data-tip={itemTypeName} data-for={'sbitt' + props.item.itemId + props.item.classId}>
					<InlineSVG src={icon()} />
					<Tooltip id={'sbitt' + props.item.itemId + props.item.classId} place="bottom" delayShow={250} />
				</div>
				<div role='cell' className='shopping-basket-content__item-title'>
					<div className='inlineFlex'>
						{props.item.title}
						<GTIconButton id='deleteItem' ariaLabel={deleteTooltipText} tooltipText={deleteTooltipText} onClick={() => props.onDeleteClicked()}>
							<InlineSVG src={IconDelete} />
						</GTIconButton>
					</div>

					<div className='shopping-basket-content__item-subtext'>
						{props.item.additionalInfo !== classString && classString}
					</div>

					<div className='shopping-basket-content__item-subtext'>
						{props.item.additionalInfo}
					</div>
				</div>
				<div role='cell'>
					<table>
						<tr>
							<td colSpan={3} className='shopping-basket-content__item-price'>
								{NumberHelper.getFormattedPrice(props.item.finalPrice.originalPrice, props.item.currency)}
							</td>
						</tr>
						{
							props.item.finalPrice.groupDiscountSum > 0 &&
							<tr>
								<td>
									<GTTooltipIcon src={IconGroupDiscount} size={21} text="ShoppingBasketContent:ParticipantGroupDiscount" />
								</td>
								<td>
									<Translate>ShoppingBasketContent:ParticipantGroupDiscount</Translate>
								</td>
								<td className='shopping-basket-content__item-price-discount'>
									-{NumberHelper.getFormattedPrice(props.item.finalPrice.groupDiscountSum, props.item.currency)}
								</td>
							</tr>
						}
						{
							props.item.finalPrice.voucherDiscountSum > 0 &&
							<tr>
								<td>
									<GTTooltipIcon src={IconVoucherDiscount} size={21} text="ShoppingBasketContent:ParticipantVoucherDiscount" />
								</td>
								<td>
									<Translate>ShoppingBasketContent:ParticipantVoucherDiscount</Translate>
								</td>
								<td className='shopping-basket-content__item-price-discount'>
									-{NumberHelper.getFormattedPrice(props.item.finalPrice.voucherDiscountSum, props.item.currency)}
								</td>
							</tr>
						}
					</table>
				</div>
			</div>

			<ShoppingBasketItemParticipants item={props.item} isRecalculating={props.isRecalculating} onDeleteClicked={(id) => props.onUserDeleteClicked(id)} onParticipantsUpdated={(e) => props.onItemUpdated(e)} />

			{
				((props.item.bookUsers ? props.item.bookUsers.length : 0) > 0 || props.item.finalPrice.price !== props.item.finalPrice.originalPrice) &&
				<div className='shopping-basket-content__item-footer'>
					<span>
						<Translate>ShoppingBasketContent:ItemFinalPrice</Translate>
					</span>
					<span>
						{props.isRecalculating ? <ProgressSpinner size={16} /> : NumberHelper.getFormattedPrice(props.item.finalPrice.price, props.item.currency)}
					</span>
				</div>
			}
		</>
	);
}