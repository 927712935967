import Release from '$src/core/Release';
import React from 'react';

export class Info extends React.Component<{}, {}> {
    public render() {      
        return <div className="l-container">
            <h1 className="heading__Title">Info</h1>
            Version: { ' [' + Release.version + ']'}
        </div>;
    }
}
export default Info;