import React, { useState } from "react";
import { EPerformanceCheckStatus, ESkillType, ESortDirection } from "$src/storage/models/enums";
import GTIconButton from "$src/components/shared/Atoms/GTIconButton";
import InlineSVG from "react-inlinesvg";
import PendingIcon from "$src/resources/svgs/skills/skill_pending.svg";
import DeclinedIcon from "$src/resources/svgs/skills/skill_declined.svg";
import AcceptedIcon from "$src/resources/svgs/skills/skill_accepted.svg";
import ActualSkill from "$src/resources/svgs/skills/actualSkill.svg";
import TargetSkill from "$src/resources/svgs/skills/targetSkill.svg";
import EditIcon from '$resources/svgs/skills/skill-edit.svg';
import Session from "$src/core/Session";
import { Tooltip } from '$components/shared/Tooltip';
import { StringHelper } from '$src/util/StringHelper';
import { UnmountClosed } from 'react-collapse';
import { Translate } from '$components/shared/Translate';
import { SortableHeaderElement } from "$src/components/shared/SortableHeaderElement";
import { approvalDate, requestDate, skillRequiredLevel, skillTitle, skillType, userSkillTargetStatus } from "$src/components/Skills/PerformanceCheckModel";
import SkillRequestResponse from "$src/components/myEmployees/ActionModals/SkillRequestResponse";
import { UserCertificateViewModel } from "$src/storage/models/Certificates/UserCertificateViewModel";
import Iconcertificate from '$resources/svgs/objects/certificate.svg';
import AuthService from '$src/core/Services/AuthService';
import { Attribute } from "$src/storage/models/Attribute";
import FileHelper from "$src/util/FileHelper";


interface IProps {
    emplyoeeId?: number;
    skill: PerformanceCheckTableItem;
    isActiveHeader: boolean;
    onActiveHeaderChanged: (activeHeaderId: string, sortDirection: ESortDirection) => void;
    key: string;
    isBoss: boolean;
    reloadPerformanceCheckTargetSkills?: () => void;
    parentHeadingLevel: number;
    inEditMode: boolean;
    onEditModeChanged: (inEditMode: boolean) => void;
}
/*
    This function is rendering the Rows for the PerformanceCheckTable
    Author: Bryan Gerber
*/
export default function PerformanceCheckItem(props: IProps) {
    const [skill] = useState<PerformanceCheckTableItem>(props.skill);
    const [showComment, setShowComment] = useState<boolean>(false);


    function renderStatus(status: EPerformanceCheckStatus) {
        switch (status) {
            case EPerformanceCheckStatus.Requested:
                return <InlineSVG src={PendingIcon} />
            case EPerformanceCheckStatus.Accepted:
                return <InlineSVG src={AcceptedIcon} />
            case EPerformanceCheckStatus.Declined:
                return <InlineSVG src={DeclinedIcon} />
            default:
                return <InlineSVG src={AcceptedIcon} />;
        }
    }

    function renderType(type: ESkillType) {
        switch (type) {
            case ESkillType.ActualSkill:
                return <InlineSVG src={ActualSkill} />
            case ESkillType.TargetSkill:
                return <InlineSVG src={TargetSkill} />
            default:
                return <InlineSVG src={ActualSkill} />
        }
    }

    function getToolTipSkillTypeText(type: ESkillType) {
        switch (type) {
            case ESkillType.ActualSkill:
                return Session.instance.storage.translation.GetString('PerformanceCheck:ActualSkill');
            case ESkillType.TargetSkill:
                return Session.instance.storage.translation.GetString('PerformanceCheck:TargetSkill');
            default:
                return Session.instance.storage.translation.GetString('PerformanceCheck:ActualSkill');
        }
    }

    function getDateString(x: Date | undefined) {
        if (x === undefined) {
            return '---';
        }
        else {
            return StringHelper.dateString(x);
        }
    }

    function getToolTipText(status: EPerformanceCheckStatus) {
        switch (status) {
            case EPerformanceCheckStatus.Requested:
                return skill.skillTitle + ': ' + Session.instance.storage.translation.GetString('Skill:Requested');
            case EPerformanceCheckStatus.Accepted:
                return skill.skillTitle + ': ' + Session.instance.storage.translation.GetString('Skill:Accepted');
            case EPerformanceCheckStatus.Declined:
                return skill.skillTitle + ': ' + Session.instance.storage.translation.GetString('Skill:Declined');
            default:
                return skill.skillTitle + ': ' + Session.instance.storage.translation.GetString('Skill:Accepted');
        }
    }

    function onClickTitle() {
        setShowComment(!showComment);
    }


    function reloadItems() {
        if (props.reloadPerformanceCheckTargetSkills) {
            props.reloadPerformanceCheckTargetSkills();
        }
    }


    function renderUserskillCertificates() {
        if (skill.skillCertificates && skill.skillCertificates.length > 0) {
            return (
                <fieldset className="skill__details__box notMobile" >
                    <legend className="skill__details__box__legend" ><Translate>Skill:Certificates</Translate></legend>
                    <ul className="no-bullets">
                        {skill.skillCertificates.map(cert => {
                            return (
                                <li key={cert.certificate?.id} >
                                    <div className="inlineFlex">
                                        <InlineSVG src={Iconcertificate} />
                                        <a href={cert.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={async () => { await AuthService.instance.GetPing(); return true }}
                                            className="marginLeft5">
                                            {cert.certificate?.fileName}
                                        </a>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </fieldset>
            )
        } else {
            return <></>
        }
    }

    function renderMiscDocuments() {
        if (skill.attributes && skill.attributes.length > 0) {
            const miscDocuments = skill.attributes.filter(a => a.name === 'PerformanceCheck_MiscFiles');
            console.log(miscDocuments);

            return (
                <fieldset className="skill__details__box notMobile" >
                    <legend className="skill__details__box__legend" ><Translate>Skill:MiscFiles</Translate></legend>
                    <ul className="no-bullets">
                        {miscDocuments.map(misc => {
                            return (
                                <li key={misc.id} >
                                    <div className="inlineFlex">
                                        <a href="#"
                                            onClick={() => { FileHelper.openMediaFile(misc, null); return false; }}
                                            className="marginLeft5">
                                            {misc.value}
                                        </a>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </fieldset>
            )
        }

        return <></>;
    }

    const toolTipText = getToolTipText(skill.status);
    const toolTipForComment = Session.instance.storage.translation.GetString('PerformanceCheck:ToolTipBossReason')
    const editButtonTooltip = props.skill.skillType == ESkillType.ActualSkill ?
        Session.instance.storage.translation.GetString('PerformanceCheck:ActualResponseButtonForSkill').Format(skill.skillTitle) :
        Session.instance.storage.translation.GetString('PerformanceCheck:TargetResponseButtonForSkill').Format(skill.skillTitle);
    return (
        <div role="row">
            <div className="skill__table__row row__content" >
            <div role="columnheader" className="mobileOnly small-cell">
                <SortableHeaderElement
                    id={skillType}
                    isActive={props.isActiveHeader}
                    header="PerformanceCheck:SkillType"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                />
            </div>
            <div role="cell"
                data-tip={getToolTipSkillTypeText(skill.skillType)}
                data-for={`${skill.skillId}_skillTypeToolTip`} className="small-cell">
                {renderType(skill.skillType)}
                <Tooltip id={`${skill.skillId}_skillTypeToolTip`} />
            </div>

            <div role="columnheader" className="mobileOnly">
                <SortableHeaderElement
                    id={skillTitle}
                    isActive={props.isActiveHeader}
                    header="Skill:SkillName"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                />
            </div>
            <div role="cell"
                data-tip={toolTipForComment}
                data-for={`${skill.skillId}_commentToolTip`}
                onClick={() => onClickTitle()}>
                {skill.skillTitle}
                <Tooltip id={`${skill.skillId}_commentToolTip`} />
            </div>

            <div role="columnheader" className="mobileOnly">
                <SortableHeaderElement
                    id={skillRequiredLevel}
                    isActive={props.isActiveHeader}
                    header="Skill:SkillRequiredLevel"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                />
            </div>
            <div role="cell">{skill.skillRequiredLevel} </div>

            <div role="columnheader" className="mobileOnly">
                <SortableHeaderElement
                    id={requestDate}
                    isActive={props.isActiveHeader}
                    header="PerformanceCheck:RequestDate"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                />
            </div>
            <div role="cell">{getDateString(skill.requestDate)} </div>

            <div role="columnheader" className="mobileOnly">
                <SortableHeaderElement
                    id={approvalDate}
                    isActive={props.isActiveHeader}
                    header="PerformanceCheck:ApprovalDate"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                />
            </div>
            <div role="cell">{getDateString(skill.approvalDate)} </div>

            <div role="columnheader" className="mobileOnly small-cell">
                <SortableHeaderElement
                    id={userSkillTargetStatus}
                    isActive={props.isActiveHeader}
                    header="Skill:SkillValid"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                />
            </div>
            <div role="cell"
                data-tip={toolTipText}
                data-for={`${skill.skillId}_skillStatusToolTip`}
                className="small-cell">
                {renderStatus(skill.status)}
                <Tooltip id={`${skill.skillId}_skillStatusToolTip`} />
            </div>
            {props.isBoss ?
                <div role="cell"
                    className="small-cell">
                    {
                        props.emplyoeeId &&
                        skill.status == EPerformanceCheckStatus.Requested &&
                        skill.allowedToEdit &&
                        <GTIconButton
                            additionalClassNames="performanceCheck--responseButton"
                            id={`${skill.skillId}_responseButton`}
                            ariaLabel={editButtonTooltip}
                            onClick={() => props.onEditModeChanged(true)}
                            tooltipText={editButtonTooltip}>
                            <InlineSVG src={EditIcon} width={24} height={24} />
                        </GTIconButton>
                    }
                </div> :
                null
            }
            </div>
            <UnmountClosed
                title={skill.skillTitle}
                isOpened={showComment || props.inEditMode}
            >
                {skill.employeeComment != null && skill.employeeComment.length > 0 &&
                    <div key={'OverlayDescriptionEmployee' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                        <fieldset className="skill__details__box notMobile" >
                            <legend className="skill__details__box__legend" ><Translate>PerformanceCheck:EmployeeReason</Translate></legend>
                            {skill.employeeComment}
                        </fieldset>
                    </div>
                }
                {skill.bossComment != null && skill.bossComment.length > 0 &&
                    <div key={'OverlayDescriptionBoss' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                        <fieldset className="skill__details__box notMobile" >
                            <legend className="skill__details__box__legend" ><Translate>PerformanceCheck:BossReason</Translate> {skill.bossName} </legend>
                            {skill.bossComment}
                        </fieldset>
                    </div>
                }
                <div key={'OverlayCertificates' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                    {renderUserskillCertificates()}
                </div>
                <div key={'OverlayMiscFiles' + skill.skillId} className="skill__table____row--collapsable notMobile" >
                    {renderMiscDocuments()}
                </div>
            </UnmountClosed>
            <UnmountClosed
                title={`${skill.skillTitle}_response`}
                isOpened={props.inEditMode}
            >
                {props.isBoss && props.emplyoeeId &&
                    <div key={'OverlayResponse' + skill.skillId} className="skill__table__row" >
                        <SkillRequestResponse
                            skillRequest={{
                                userId: props.emplyoeeId,
                                skillId: skill.skillId,
                                skillLevelId: skill.skillRequiredLevelId,
                                skillType: skill.skillType,
                                skillTitle: skill.skillTitle
                            }}
                            reloadItems={() => reloadItems()}
                            parentHeadingLevel={props.parentHeadingLevel}
                            onExit={() => props.onEditModeChanged(false)}
                        />
                    </div>
                }
            </UnmountClosed>
        </div >
    )



}
/**
 * This class defines the data structure for PerformanceCheckTableItem.
 * Author: Bryan Gerber
 */
export class PerformanceCheckTableItem {
    public userId: number = 0;
    public skillId: number = 0;
    public skillTitle: string = '';
    public skillRequiredLevel: string = "";
    public skillRequiredLevelId: number = 0;
    public requestDate: Date;
    public approvalDate: Date | undefined;
    public status: EPerformanceCheckStatus;
    public userSkillOrTargetSkillId: number = 0;
    public employeeComment: string | null = '';
    public bossComment: string | null = '';
    public skillUniqueKey: string;
    public skillType: ESkillType;
    public skillCertificates?: UserCertificateViewModel[] = [];
    public bossName: string | null = '';
    public allowedToEdit: boolean = false;
    public attributes: Attribute[] = []
}