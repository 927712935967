import { ERegistrationStatus } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class RegistrationStatusConverter implements JsonCustomConvert<ERegistrationStatus> {
    // from WebApi Models\Enums.cs: Undefined=0, NotRegistered=1, Requested=2, Accepted=3, InWaitingList=4, 
    // Rejected=5, Cancelled=6, ScheduleCancelled=7
    public serialize(lessonStatus: ERegistrationStatus): number {
        switch (lessonStatus) {
            case ERegistrationStatus.NotRegistered: return 1;
            case ERegistrationStatus.Requested: return 2;
            case ERegistrationStatus.Accepted: return 3;
            case ERegistrationStatus.InWaitingList: return 4;
            case ERegistrationStatus.Rejected: return 5;
            case ERegistrationStatus.Cancelled: return 6;
            case ERegistrationStatus.ScheduleCancelled: return 7;
            default: return 0;
        }
    }
    public deserialize(jsonValue: number): ERegistrationStatus {
        switch (jsonValue) {
            case 1: return ERegistrationStatus.NotRegistered;
            case 2: return ERegistrationStatus.Requested;
            case 3: return ERegistrationStatus.Accepted;
            case 4: return ERegistrationStatus.InWaitingList;
            case 5: return ERegistrationStatus.Rejected;
            case 6: return ERegistrationStatus.Cancelled;
            case 7: return ERegistrationStatus.ScheduleCancelled;
            default: return ERegistrationStatus.NotRegistered;
        }
    }
}
