import React from 'react';
import InlineSVG from 'react-inlinesvg';

import Iconup from '$resources/svgs/navi/scroll-up.svg';

import { Translate } from '$components/shared/Translate';

interface IProps {
    direction?: string;
}

interface IState {
    showButton: boolean;
}

export class Scroller extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            showButton: false
        }

        this.OnScrollClick = this.OnScrollClick.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    public render() {
        if (globalConfig.layoutProperties.scrollToTop_Enable) {
            if (this.state.showButton) {
                return (
                    <div className={globalConfig.layoutProperties.scrollToTop_Enable_Mobile ? '' : 'notMobile'}>
                        <button
                            className={' scroller__container scroller__container-' + (globalConfig.layoutProperties.scrollToTop_Anchor)}
                            onClick={this.OnScrollClick}>
                            {
                                globalConfig.layoutProperties.scrollToTop_DisplayText ?
                                    <span 
                                        className={globalConfig.layoutProperties.scrollToTop_DisplayText_Mobile ? '' : 'notMobile'}
                                    >
                                        <Translate>Scroller:Text</Translate>
                                    </span> :
                                    null
                            }
                            <InlineSVG src={Iconup} />
                            <span className="screen-reader-only"><Translate>Scroller:ScrollToTop</Translate></span>
                        </button>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    protected handleScroll() {
        if (window.scrollY > 0 || document.documentElement.scrollTop > 0) {
            this.setState({ showButton: true })
        } else {
            this.setState({ showButton: false })
        }
    }

    protected OnScrollClick() {
        window.scrollTo(0, 0);
    }
}