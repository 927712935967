import Logger from '$core/Logger';
import { EBreakPoint } from '$src/storage/models/enums';

export class BrowserHelper {

    private static className = 'BrowserHelper';
    private static loggerLocality = 'Util';

    public static applyBrowserSpecificFixes() {
        const methodName = `${this.className}:applyBrowserSpecificFixes()`;
        Logger.log(this.loggerLocality, `${methodName} : appliying browser specific fixes`);

        // Firefox
        if (BrowserHelper.isFirefox()) {
            // Disable Firefox History Caching behavior
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            window.addEventListener('unload', () => { });
        }
    }

    public static isFirefox(): boolean {
        return (navigator.userAgent.includes('Firefox'));
    }

    public static isEdge(): boolean {
        return (navigator.userAgent.includes('Edge'));
    }

    public static isIOSDevice(): boolean {
        return (navigator.userAgent.match(/(iPad)/) != null /* iOS pre 13 */ ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */);
    }

    public static isMobile(): boolean {
        return (navigator.userAgent.match(/iPhone|iPad|iPod|Android/) != null)
    }

    public static getCurrentBreakPoint(): EBreakPoint {
        if (window.matchMedia("(min-width: 0) and (max-width: 337px)").matches) {
            return EBreakPoint.Tiny;
        }
        if (window.matchMedia("(min-width: 338px) and (max-width: 697px)").matches) {
            return EBreakPoint.Small;
        }
        if (window.matchMedia("(min-width: 698px) and (max-width: 1041px)").matches) {
            return EBreakPoint.Medium;
        }
        if (window.matchMedia("(min-width: 1042px) and (max-width: 1457px)").matches) {
            return EBreakPoint.Large;
        }
        if (window.matchMedia("(min-width: 1458px)").matches) {
            return EBreakPoint.ExtraLarge;
        }
        console.debug('Current BreakPoint ist Undefined');
        return EBreakPoint.Undefined;
    }
}