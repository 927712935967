import { ELifeCycle } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class LifeCycleContextConverter implements JsonCustomConvert<ELifeCycle> {
    public serialize(lifeCycle: ELifeCycle): number {
        switch (lifeCycle) {
            case ELifeCycle.Development: return 1;
            case ELifeCycle.Production: return 2;
            case ELifeCycle.Obsolete: return 3;
            default: return 1;
        }
    }
    public deserialize(jsonValue: number): ELifeCycle {
        switch (jsonValue) {
            case 1: return ELifeCycle.Development;
            case 2: return ELifeCycle.Production;
            case 3: return ELifeCycle.Obsolete;
            default: return ELifeCycle.Development;
        }
    }
}
