import Logger from '$core/Logger';
import Session from '$core/Session';
import SkillService from '$src/core/Services/SkillService';
import GtError from '$src/util/GtError';
import SessionCache from '$storage/cache/SessionCache';
import { UserSkill } from '$storage/models/Skill/UserSkill';
import { isSuccess } from '$util/Result';

const CACHING_DURATION = 300;

export default class UserSkillStorage extends SessionCache<number, UserSkill[]> {
    protected className = 'UserSkillStorage';
    protected loggerLocality = 'Storage.UserSkillStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getUserSkills(requestedUserId?: number, forceReload?: boolean, bossRelationCode?: string): Promise<UserSkill[] | null> {
        const methodName = `${this.className}:getUserSkills()`;
        const userId = requestedUserId ? requestedUserId : Session.instance.loginUser!.id;
        const language = Session.instance.getUserLanguageCodeOrFallBack;

        if (this.isObjectInCache(userId) && !forceReload) {
            Logger.log(this.loggerLocality, `${methodName} getting user skills for userId=${userId}.`);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} getting user skills from server, userId=${userId}.`);
            const response = await SkillService.instance.getUserSkills(language, userId, bossRelationCode);

            if (isSuccess<UserSkill[]>(response)) {
                const elementCount = (Array.isArray(response)) ? response.length : 0;
                Logger.log(this.loggerLocality, `${methodName} got user skills from server, userid=${userId}, number of skills=${elementCount}.`);
                this.saveObjectToCache(userId, response);
            }
            else {
                Logger.log(this.loggerLocality, `${methodName} failed to get user skills from server, userId=${userId}.`);
                this.saveObjectToCache(userId, new Array<UserSkill>());
            }
        }
        return this.getObjectFromCache(userId);
    }

    public async getUserSkill(requestedUserId: number, skillId: number, bossRelationCode?: string): Promise<UserSkill | undefined> {
        const response = await this.getUserSkills(requestedUserId, undefined, bossRelationCode);
        if (response != null) {
            return response.find(usk => usk.skillId === skillId);
        } else {
            return undefined;
        }
    }

    //This Method gets all actuals Skills for a user with status requested, acccepted or declined
    public async getActualSkillsForPerformanceCheck(requestedUserId: number, language: string, bossRelationCode?: string): Promise<UserSkill[] | GtError> {
        const response = await SkillService.instance.getUserSkillsForPerformanceCheck(language, requestedUserId, bossRelationCode);
        return response;
    }
    
    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}