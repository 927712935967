import Logger from '$src/core/Logger';
import SsoServiceProviderService from '$src/core/Services/SsoServiceProviderService';
import React from 'react';

interface ILogoutSsoProps {
    onDone(): void;
}

/**
 * Renders an invisible iframe that connects the current user to the external authenticated user and will try to logout
 */
export const LogoutSso: React.FC<ILogoutSsoProps> = (_props: ILogoutSsoProps) => {
    const loggerLocality: string = 'SsoServiceProviderService';
    const url = SsoServiceProviderService.instance.getSsoBackgroundSignOutUrl();

    function onDone() {
        Logger.log(loggerLocality, 'Sso signout called');
        _props.onDone();
    }

    return (
        <>
            <iframe className="backgroundSsoSPCalls__iframe" src={url} onLoad={onDone}></iframe>
        </>
    );
}