import { JsonObject, JsonProperty } from 'json2typescript';
import { UtcDateTimeConverter } from '../converters/UtcDateTimeConverter';
import { Item } from '$src/storage/models/Item';
import { GamificationPointOriginConverter } from '../converters/GamificationPointOriginConverter';
import { EGamificationPointOrigin } from '../enums';

@JsonObject
export class GamificationPointHistoryDetail {

    @JsonProperty('idGamificationPointHistory', Number, false)
    public idGamificationPointHistory: number = 0;

    @JsonProperty('points', Number, false)
    public points: number = 0;

    @JsonProperty('received', UtcDateTimeConverter, false)
    public received: Date | undefined = undefined;

    @JsonProperty('fK_TriggerObject', Number, false)
    public fK_TriggerObject: number = 0;

    @JsonProperty('triggerObjectTitle', String, false)
    public triggerObjectTitle: string = ''

    @JsonProperty('pointOrigin', GamificationPointOriginConverter, false)
    public PointOrigin: EGamificationPointOrigin = EGamificationPointOrigin.Unknown;

    @JsonProperty('triggerItem', Item, false)
    public triggerItem: Item | undefined = undefined;

}