import ServiceClient from '$src/core/ServiceClient';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { ActivateLicenseRequest } from '$src/storage/models/RequestObjects/ActivateLicenseRequest';
import GtError from '$src/util/GtError';

export default class LicenseRegistrationService extends ServiceClient {
    protected static _instance: LicenseRegistrationService | null = null;

    protected className = 'LicenseRegistrationService';
    protected loggerLocality = 'LicenseRegistrationService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): LicenseRegistrationService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Check if user can register for license
     */
    public async canRegisterForLicense(langCode: string, username: string, email: string, contractNumber: string): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        // The contract number may end with an url breaking sign (eg .). Therefore an extra character is added to make the url valid. The character will be removed in the backend.
        contractNumber = contractNumber + 'a';
        response = await this.get<BooleanResponse>(
            'licenseRegistration/canRegisterForLicense', 
            BooleanResponse, 
            undefined,
            undefined, 
            username,
            email,
            contractNumber);
        return response;
    }

    public async activateLicense(langCode: string, contractNumber: string): Promise<BooleanResponse | GtError> {
        let response: BooleanResponse | GtError;
        const obj = new ActivateLicenseRequest();
        obj.contractNumber = contractNumber;
        response = await this.post<BooleanResponse>(
            'licenseRegistration/activateLicense',
            obj,
            BooleanResponse,
            undefined,
            [{ name: 'language', value: langCode }]
            );
        return response
    }
}