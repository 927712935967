import { observer } from 'mobx-react';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import { StudentSpaceQuota } from '$src/components/fileSharing/StudentSpaceQuota';
import InputFile from '$src/components/shared/InputFile';
import { Translate } from '$src/components/shared/Translate';
import FileSharingService from '$src/core/Services/FileSharingService';
import { FileSharingDocument } from '$src/storage/models/FileSharing/FileSharingDocument';
import { FileSharingLesson } from '$src/storage/models/FileSharing/FileSharingLesson';
import { FileSharingUser } from '$src/storage/models/FileSharing/FileSharingUser';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { StringHelper } from '$src/util/StringHelper';
import { ModalPopup } from '$components/shared/ModalPopup';
import { BooleanResponse } from '$storage/models/BooleanResponse';

import Iconattach from '$resources/svgs/filesharing/attach.svg';
import Iconabort from '$resources/svgs/filesharing/remove.svg';
import Iconuploaded from '$resources/svgs/filesharing/success.svg';


interface IProps {
    fileSharingUser: FileSharingUser;
    fileSharing: FileSharingLesson;
    allowedFileExtensions: string;
    onDocumentUploaded?: (fileSharingDocument: FileSharingDocument) => void;
}

interface IStateFileOverwriteCheck {
    overwriteFiles: string[];
    allowOverwriteFiles: boolean;
    showOverwriteWarningModal: boolean;
}

interface IState extends IStateFileOverwriteCheck {
    files: File[];
    showUploadInput: boolean;
    uploadingFiles: string[];
    uploadedFiles: string[];
    uploadingSize: number;
    errorMessage: string | null;
    isCheckingFilesOverwrite: boolean;
}

@observer // observing Session.instance.storage.registeredItem.isInitialized
export class FileSharingUploadPanel extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: null,
            files: [],
            showUploadInput: false,
            uploadedFiles: [],
            uploadingFiles: [],
            uploadingSize: 0,
            overwriteFiles: [],
            allowOverwriteFiles: false,
            showOverwriteWarningModal: false,
            isCheckingFilesOverwrite: false
        };
    }

    public UNSAFE_componentWillReceiveProps() {
        this.setState({ uploadingSize: this.getUploadSize(this.state.files) });
    }

    public render() {
        return (
            <React.Fragment>
                <div className="item-detail__file-sharing__upload-panel" onDragEnter={() => this.setState({ showUploadInput: true })}>
                    <label id={'fileSharingUpload_' + this.props.fileSharingUser.userId.toString()}>
                        <button className="link-button" onClick={() => this.setState({ showUploadInput: !this.state.showUploadInput })}>
                            <InlineSVG src={Iconattach} className="icon-attach" />&nbsp;<Translate>FileSharing:ShowUploadPanel</Translate>
                        </button>
                    </label>
                    {this.state.showUploadInput ? this.renderUploadInput() : null}
                </div>
            </React.Fragment >
        );
    }

    protected renderUploadInput() {
        return (
            <React.Fragment>
                <InputFile
                    isMultiple={true}
                    acceptFilter={this.props.allowedFileExtensions}
                    labeledBy={'fileSharingUpload_' + this.props.fileSharingUser.userId.toString()}
                    linkCssClass="button-link button-link--colorized-dark"
                    onUploadChange={this.uploadChanged} />

                <div>
                    <Translate>FileSharing:AllowedFileExtensions</Translate>
                    <span>{this.props.allowedFileExtensions}</span>
                </div>

                {this.renderFiles()}

                <StudentSpaceQuota
                    studentSpaceQuota={this.props.fileSharing.studentSpaceQuota.valueOf()}
                    usedSpace={this.props.fileSharingUser.usedSpace.valueOf()}
                    requestedSpace={this.state.uploadingSize} />

                {this.renderUploadButton()}
                {this.renderOverwriteWarningModal()}

                <ErrorMessage errorMessage={this.state.errorMessage} />
            </React.Fragment>
        );
    }

    protected renderOverwriteWarningModal() {
        return (
            <ModalPopup
                isOpen={this.state.showOverwriteWarningModal}
                onRequestClose={() => this.closeModal()}>
                    <div className="file-sharing-upload file-sharing-upload__overwrite-modal">
                    <Translate>FileSharing:ConfirmOverwriteFiles</Translate>
                    <ul>{ 
                    this.state.overwriteFiles.map((value, index) => {
                    return <li key={`overwritefile_${index}`}>{value}</li>})
                    }</ul>
                    </div>
                    <div className="modal__spread-buttons">
                    <button type="button"
                        className="btn btn--sm marginRight-5"
                        onClick={this.onAllowOverwriteFilesClicked}>
                        <Translate>FileSharing:ConfirmOverwrite</Translate>
                    </button>
                    <button type="button"
                        className="btn btn--sm"
                        onClick={this.onDisallowOverwriteFilesClicked}>
                        <Translate>FileSharing:CancelOverwrite</Translate>
                    </button>
                </div>
            </ModalPopup>
        )
    }

    private closeModal = (): void => {
        this.setState({ showOverwriteWarningModal: false });
    }

    private onAllowOverwriteFilesClicked = () => {
        this.setState({ allowOverwriteFiles: true, showOverwriteWarningModal: false }, () => { this.onUploadClicked() });
    }

    private onDisallowOverwriteFilesClicked = () => {
        this.setState({ allowOverwriteFiles: false, showOverwriteWarningModal: false });
    }

    protected renderFiles() {
        if (this.state.files.length <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="document-table" role="table">
                    <div role="rowgroup">
                        {
                            this.state.files.map((file: File) => {
                                return this.renderFile(file);
                            })
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    protected renderFile(file: File) {
        return (
            <div key={file.name} className="tr hover" role="row">
                <div className="td file-name" role="cell">
                    {file.name}
                </div>
                <div className="td min" role="cell">
                    {StringHelper.formatFileSize(file.size)}
                </div>
                <div className="td min file-status" role="cell">
                    {this.renderFileStatus(file)}
                </div>
            </div>
        );
    }

    protected renderFileStatus(file: File) {
        const isUploading = this.state.uploadingFiles.includes(file.name);
        const isUploaded = this.state.uploadedFiles.includes(file.name);

        if (isUploaded) {
            return <InlineSVG src={Iconuploaded} />
        } else if (isUploading) {
            return (
                <div className="upload-spinner">
                    <ProgressSpinner size={18} />
                </div>
            );
        } else if (!isUploading) {
            return (
                <button type="button" className="action-button" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.removeFile(e, file)}>
                    <InlineSVG src={Iconabort} />
                </button>
            );
        }

        return null;
    }

    protected renderUploadButton() {
        if (this.countFilesToUpload() <= 0) {
            return null;
        }

        if (this.isUploadSizeExceeded()) {
            return (
                <div className="upload-size-exceeded">
                    <Translate>FileSharing:UploadRequestSizeExceeded</Translate>
                </div>
            );
        }

        return (
            <div>
                <button type="button" className="btn--md btn--primary" onClick={this.onUploadClicked} disabled={this.state.uploadingFiles.length > 0 || this.state.isCheckingFilesOverwrite}>
                    <Translate>FileSharing:SubmitFiles</Translate>
                </button>
                {this.state.isCheckingFilesOverwrite ? <ProgressSpinner size={18} /> : ''}
            </div>
        );
    }

    /**
     * Posts the file as base64 data string to the server and triggers the onDocumentUploaded event
     * @param file Value of the file input
     */
    protected uploadFile = async (file: File) => {
        // already uploaded? then skip
        if (this.state.uploadedFiles.includes(file.name)) {
            return;
        }

        const uploadingFiles: string[] = this.state.uploadingFiles;
        uploadingFiles.push(file.name);
        this.setState({ uploadingFiles });

        let formData = new FormData();
        formData.append("userId", this.props.fileSharingUser.userId.toString());
        formData.append("itemId", this.props.fileSharing.context.itemId.toString());
        
        if (this.props.fileSharing.context.assignmentId) {
            formData.append("assignmentId", this.props.fileSharing.context.assignmentId.toString());
        }
        if (this.props.fileSharing.context.scheduleId) {
            formData.append("scheduleId", this.props.fileSharing.context.scheduleId.toString());
        }
        if (this.props.fileSharing.context.tPlanId) {
            formData.append("tPlanId", this.props.fileSharing.context.tPlanId.toString());
        }
        
        formData.append("file", file);
        formData.append("fileName", file.name); //IE posts whole path and file

        const res: FileSharingDocument | GtError = await FileSharingService.instance.postFile(formData);
        if (isSuccess<FileSharingDocument>(res)) {
            const stateUploadedFiles = this.state.uploadedFiles;
            stateUploadedFiles.push(file.name);
            const stateUploadingFiles = this.state.uploadingFiles;
            stateUploadingFiles.splice(stateUploadingFiles.indexOf(file.name), 1);
            this.setState({
                uploadedFiles: stateUploadedFiles,
                uploadingFiles: stateUploadingFiles,
                uploadingSize: this.getUploadSize(this.state.files)
            });

            if (this.props.onDocumentUploaded) {
                this.props.onDocumentUploaded(res);
            }
        } else {
            const stateUploadingFiles = this.state.uploadingFiles;
            stateUploadingFiles.splice(stateUploadingFiles.indexOf(file.name), 1);
            this.setState({
                errorMessage: 'FileSharing:Error_UploadingFile',
                uploadingFiles: stateUploadingFiles
            });
        }
    }

    /**
     * Returns the number of files that will be uploaded
     */
    protected countFilesToUpload = (): number => {
        let res: number = 0;
        for (const file of this.state.files) {
            res += !this.state.uploadedFiles.includes(file.name) ? 1 : 0;
        }
        return res;
    }

    /**
     * Returns the size of the files that will be uploaded
     * @param files Array of the file input values
     */
    protected getUploadSize = (files: File[]): number => {
        let res: number = 0;
        files.filter(file => !this.state.uploadedFiles.includes(file.name)).forEach(file => {
            if (!this.props.fileSharingUser.documents.some(doublet => doublet.name === file.name)) {
                res += file.size
            }
        });
        return res;
    }

    /**
     * Returns true, if the size, that will be uploaded, exceeds the student space quota
     */
    protected isUploadSizeExceeded = () => {
        const usedMB: number = this.props.fileSharingUser.usedSpace.valueOf() / 1024.0 / 1024.0;
        const requestedMB: number = this.state.uploadingSize / 1024.0 / 1024.0;

        return requestedMB + usedMB >= this.props.fileSharing.studentSpaceQuota.valueOf();
    }

    /* EVENTS */

    /**
     * Files were selected via the InputFile component and will be listed in the user interface
     * @param files The files the user has selected to upload
     */
    protected uploadChanged = (files: File[]) => {
        // first remove already uploaded files from the lists
        const stateFiles: File[] = this.state.files;
        const stateUploadedFiles: string[] = this.state.uploadedFiles;
        stateFiles.forEach((file, index) => {
            const iFound: number = stateUploadedFiles.indexOf(file.name);
            if (iFound >= 0) {
                stateUploadedFiles.splice(iFound, 1);
                stateFiles.splice(index, 1);
            }
        });

        // remove also doublets
        files = files.filter((file) => {
            return !stateFiles.some((stateFile) => {
                return file.name === stateFile.name;
            })
        });

        const newFiles = stateFiles.concat(files);
        this.setState({
            files: newFiles,
            uploadedFiles: stateUploadedFiles,
            uploadingSize: this.getUploadSize(newFiles),
            allowOverwriteFiles: false
        });
    }

    /**
     * Removes a file from the upload list
     * @param event onclick event
     * @param removeFile The value of the file input that will be removed from the upload list
     */
    protected removeFile = (event: React.MouseEvent<HTMLButtonElement>, removeFile: File) => {
        event.preventDefault();
        const newFiles = this.state.files.filter((file: File) => {
            return removeFile.name !== file.name;
        })
        this.setState({ files: newFiles, uploadingSize: this.getUploadSize(newFiles) });
    }

    /**
     * Upload the selected files in succession
     */
    protected onUploadClicked = async () => {
        const overwriteFiles: string[] = [];
        // Overwrite Check if not already allowed overwriting
        if (this.state.allowOverwriteFiles === false) {
            this.setState({isCheckingFilesOverwrite: true});
            for (const file of this.state.files) {
                const exists = await this.doesFileExist(file.name);
                if (isSuccess<BooleanResponse>(exists)) {
                    if (exists.status) { overwriteFiles.push(file.name) }
                }
            }
            this.setState({isCheckingFilesOverwrite: false});
        }

        // Upload files if we either have no conflicts or conflict got approved
        if (overwriteFiles.length === 0 || this.state.allowOverwriteFiles === true) {
            // Upload
            for (const file of this.state.files) {
                await this.uploadFile(file);
            }
            // Reset Conflict Approvment
            this.setState({ allowOverwriteFiles: false });
        }
        else {
            // Set Overwrite Files
            this.setState({ overwriteFiles, showOverwriteWarningModal: true });
        }
    }

    private doesFileExist = async (fileName: string) => {
        const fileExist = await FileSharingService.instance.existsDocument(
            this.props.fileSharing.context.itemId,
            this.props.fileSharingUser.userId,
            fileName,
            this.props.fileSharing.context.assignmentId,
            this.props.fileSharing.context.tPlanId,
            this.props.fileSharing.context.scheduleId);
        return fileExist;
    }
}