import React from "react";
import { Heading } from "$components/shared/Heading";
import { EItemSubType, EItemType, ELessonStatus } from "$src/storage/models/enums";
import StarRating, { getRatingToolTipText } from "$components/shared/Atoms/StarRating";
import Session from "$src/core/Session";
import ItemIconAndStatus from "$src/components/shared/itemBox/ItemIconAndStatus";
import { NavLink } from "react-router-dom";
import IconAndText from "$src/components/shared/Atoms/IconAndText";
import { StringHelper } from "$src/util/StringHelper";
import IconLocked from '$resources/svgs/status/locked.svg';
import IconAssignment from '$resources/svgs/misc/assignment.svg';
import { ItemHelper } from "$src/util/ItemHelper";

/**
 * The GT Item Summary for Detail Props
 */
export interface IItemSummaryMinimalDetailProps {

    /**
     * Component unique id for the key of the dom element
     */
    id: string;

    /**
     * Heading Level of parent component
     */
    parentHeadingLevel: number;

    /**
     *  Details of the item like title, description
     */
    itemDetails: ItemMinimalDetails;

    isAssignment: boolean;
}

/**
 * Global Teach Item Summary Detail Component
 */
export default function ItemSummaryMinimalDetail(props: IItemSummaryMinimalDetailProps) {

    // Rendering for the detailed item summary display
    const item = props.itemDetails;
    const ratingToolTip = getRatingToolTipText(item.myRating, item.ratingCount, item.averageRating);

    let lockedReasonText = Session.instance.storage.translation.GetString('LessonStatus:Locked') + ' ' + Session.instance.storage.translation.GetString('LessonStatus:LockedReason') + ':\r\n';
    if (item.isLockedDueToAssignments) {
        lockedReasonText += '- ' + Session.instance.storage.translation.GetString('LessonStatus:LockedReasonAssignmentNotInLearningPeriod') + '\r\n';
    }
    if (item.isLockedDueToRequiredSkills) {
        lockedReasonText += '- ' + Session.instance.storage.translation.GetString('LessonStatus:LockedReasonRequiredSkillsMissing') + '\r\n';
    }
    if (item.lessonLockedByAttemptsUntil != null) {
        lockedReasonText += '- ' + ItemHelper.isLockedDueToFailedAttemptsText(item.lessonLockedByAttemptsUntil);
    }

    return (
        <>
            <div className="item-summary__mini-detail__summary-icon">
                <ItemIconAndStatus
                    id={props.id}
                    itemType={item.itemType}
                    itemSubType={item.itemSubType}
                    itemStatus={item.itemStatus}
                    itemTitle={item.itemTitle}
                    itemAttributes={[]}
                    itemIcon={item.itemIcon} 
                    hideItemTypeText={true}/>
            </div>
            <div className="item-summary__mini-detail__detail-container">
                <div className="item-summary__mini-detail__title-container">
                    <NavLink to={item.navUrl}
                        aria-label={Session.instance.storage.translation.GetString("ItemSummary:LinkAriaLabel").replace("{title}", item.itemTitle)}>
                        <Heading cssClass="heading__Level4" headingLevel={props.parentHeadingLevel + 1} aria-label="">
                            <p>{item.itemTitle}</p>
                        </Heading>
                    </NavLink>
                    <div className="item-summary__mini-detail__iconToolbar">
                        {props.isAssignment ? (
                            <IconAndText
                                id={props.id}
                                icon={IconAssignment}
                                ariaLabel={Session.instance.storage.translation.GetString("ItemSummary:IsAssignment")}
                                tooltipText={Session.instance.storage.translation.GetString("ItemSummary:IsAssignment")} />
                        ) : ''}
                        {globalConfig.lessonToolbarProperties.displayRating && item.isRatingEnabled &&
                            <StarRating id={props.id} ratingCount={item.ratingCount} averageRating={item.averageRating} myRating={item.myRating}
                                tooltipText={ratingToolTip} ariaLabel={ratingToolTip} />}
                        {item.isLocked ? (
                            <IconAndText
                                id={props.id}
                                icon={IconLocked}
                                ariaLabel={lockedReasonText}
                                tooltipText={StringHelper.htmlFormat(lockedReasonText)} />
                        ) : ''}
                    </div>
                </div>
                <div>
                    <span dangerouslySetInnerHTML={{ __html: item.itemDescription.substr(0, 100) + (item.itemDescription.length > 100 ? "..." : '') }} />
                </div>
            </div>

        </>
    );
}


/**
 * This class defines the data the item summary minimal detail component needs for dispayling a item.
 */
export class ItemMinimalDetails {
    public itemId: number = 0;
    public itemTitle: string = '';
    public itemDescription: string = '';
    public navUrl: string = '';
    public itemType: EItemType = EItemType.Undefined;
    public itemSubType: EItemSubType = EItemSubType.Undefined;
    public itemStatus: ELessonStatus = ELessonStatus.NotAttempted;
    public isRatingEnabled: boolean = false;
    public ratingCount: number = 0;
    public myRating: number = 0;
    public averageRating: number = 0;
    public isLocked: boolean = false;
    public isLockedDueToAssignments: boolean = false;
    public isLockedDueToRequiredSkills: boolean = false;
    public itemIcon: string;
    public lessonLockedByAttemptsUntil?: Date = undefined;
}