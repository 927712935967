import ExternalCourseRequest from '$components/myEmployees/ExternalCourseRequest';
import F2FRequest from '$components/myEmployees/F2FRequest';
import { Heading } from '$components/shared/Heading';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputTextArea } from '$components/shared/InputTextArea';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import ExternalCourseService from '$src/core/Services/ExternalCourseService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { EItemDetailCallerContextType, EItemSubType, ERegistrationStatus } from '$src/storage/models/enums';
import { ExternalCourseRegistration } from '$src/storage/models/ExternalCourse/ExternalCourseRegistration';
import { ExternalCourseUpdateRegistrationStatus } from '$src/storage/models/RequestObjects/ExternalCourse/ExternalCourseUpdateRegistrationStatus';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { RouteComponentProps } from 'react-router';

interface IProps extends RouteComponentProps<{}> {
    registrationId: number;
    parentHeadingLevel: number;
    courseType: EItemSubType;
    itemContext: EItemDetailCallerContextType;
}

interface IState {
    errorMessage: string;
    externalCourseRegistration: ExternalCourseRegistration | undefined;
    isDataLoaded: boolean;
    requestBossComment: string;
    isReadOnly: boolean;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export default class RequestDetail extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            externalCourseRegistration: undefined,
            isDataLoaded: false,
            isReadOnly: false,
            requestBossComment: '',
        }

        this.onButtonClickAccept = this.onButtonClickAccept.bind(this);
        this.onButtonClickReject = this.onButtonClickReject.bind(this);
        this.onInputChanged = this.onInputChanged.bind(this);
    }

    public async componentDidMount() {
        if (!this.state.isDataLoaded) {
            await this.getExternalCourseRegistration();
        }
        document.title = this.state.externalCourseRegistration ?
            Session.instance.storage.translation.GetString('CourseRequest:RequestDetail').Format(this.state.externalCourseRegistration.registeredUserFullName)
            : '';
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return (<ProgressSpinner />)
        } else if (!Session.instance.isLoggedInUserBossOfDefaultRelation) {
            return (
                <div>
                    <span className={'input-message error'}>
                        <Translate>Reporting:UserNotAllowed</Translate>
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    {this.state.errorMessage.length > 0 ?
                        (<span className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>)
                        :
                        (this.renderDetails())
                    }
                </div>
            )
        }
    }


    private renderDetails(): JSX.Element {
        const commentAttributes: InputAttributes =
        {
            attributeValueType: undefined,
            class: '',
            editMode: false,
            hasFocus: true,
            id: 'bossComment',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: 'ExternalCourse:RegistrationBossComment',
            labelClassName: 'heading__Level5',
            placeholder: Session.instance.storage.translation.GetString('ExternalCourse:RegistrationBossCommentPlaceholder'),
            regExpression: undefined,
            rows: 5,
            url: undefined,
            value: '',
        };
        if (this.state.externalCourseRegistration) {
            const courseTitle = this.state.externalCourseRegistration.externalCourse ? this.state.externalCourseRegistration.externalCourse.title : '';
            const courseDescription = this.state.externalCourseRegistration.externalCourse ? this.state.externalCourseRegistration.externalCourse.description : '';
            const registeredUser = this.state.externalCourseRegistration.registeredUserFullName;
            const ariaLabelAccept = Session.instance.storage.translation.GetString('CourseRequest:AcceptRegistration').Format(registeredUser, courseTitle);
            const ariaLabelReject = Session.instance.storage.translation.GetString('CourseRequest:RejectRegistration').Format(registeredUser, courseTitle);
            return (
                <React.Fragment>
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level2">
                        {Session.instance.storage.translation.GetString('CourseRequest:RequestDetail').Format(this.state.externalCourseRegistration.registeredUserFullName)}
                    </Heading>
                    <div className="step-content no-background">
                        {this.props.courseType === EItemSubType.FaceToFace ?
                            (<F2FRequest
                                classId={this.state.externalCourseRegistration.scheduleId}
                                courseTitle={courseTitle}
                                courseDescription={courseDescription}
                                parentHeadingLevel={this.props.parentHeadingLevel + 1}
                                registrationStatus={this.state.externalCourseRegistration.registrationStatus} 
                                itemContext={this.props.itemContext} />)
                            :
                            (<ExternalCourseRequest
                                {...this.props}
                                externalCourseRegistration={this.state.externalCourseRegistration}
                                parentHeadingLevel={this.props.parentHeadingLevel + 1} 
                                 />)}
                        {!this.state.isReadOnly &&
                            <React.Fragment>
                                <div className="step-content__block">
                                    <InputTextArea attributes={commentAttributes} onChange={this.onInputChanged} />
                                </div>

                                <div className="btn-group--row-flex">
                                    <button type="button"
                                        aria-label={ariaLabelReject}
                                        className="btn btn--sm marginRight-5 button-error"
                                        onClick={this.onButtonClickReject}
                                        id="btnRejectRequest">
                                        <Translate>Button:Reject</Translate>
                                    </button>
                                    <button type="button"
                                        aria-label={ariaLabelAccept}
                                        className="btn btn--sm button-success"
                                        onClick={this.onButtonClickAccept}
                                        id="btnAcceptRequest">
                                        <Translate>Button:Accept</Translate>
                                    </button>
                                </div>
                            </React.Fragment>}

                    </div>
                </React.Fragment >

            )
        } else {
            return (
                <div>
                    <Translate>CourseRequest:NoRegistrationFound</Translate>
                </div>
            )
        }
    }
    private onInputChanged(id: string, value: string) {
        this.setState({ requestBossComment: value });
    }

    private onButtonClickAccept() {
        this.onUpdateRegistrationStatus(true);
    }

    private onButtonClickReject() {
        this.onUpdateRegistrationStatus(false);

    }

    private async getExternalCourseRegistration() {
        const registration = await ExternalCourseService.instance.getExternalCourseRegistration(this.props.registrationId, this.props.courseType);
        if (isSuccess<ExternalCourseRegistration>(registration)) {
            if (!registration) {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesRegistrationFailed', isDataLoaded: true });
            } else {
                this.setState({ externalCourseRegistration: registration, isDataLoaded: true, isReadOnly: registration.registrationStatus > ERegistrationStatus.Requested })
            }
        } else {
            if (registration.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(registration.detailedObject.subStatusCode), isDataLoaded: true })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:GetExternalCoursesRegistrationFailed', isDataLoaded: true });
            }
        }
    }

    private async onUpdateRegistrationStatus(accepted: boolean) {
        if (this.state.externalCourseRegistration) {
            const request = new ExternalCourseUpdateRegistrationStatus();
            request.registrationId = this.state.externalCourseRegistration.registrationId;
            request.userId = this.state.externalCourseRegistration.registeredUserId;
            request.registrationStatus = accepted ? ERegistrationStatus.Accepted : ERegistrationStatus.Rejected;
            request.bossComment = this.state.requestBossComment;
            request.externalCourseClassId = this.state.externalCourseRegistration.scheduleId;
            const response = await ExternalCourseService.instance.updateRegistrationStatus(request);
            if (isSuccess<BooleanResponse>(response)) {
                if (!response.status) {
                    this.setState({ errorMessage: 'ErrorMessage:UpdateRegistrationFailed ' });
                } else {
                    this.getExternalCourseRegistration();
                }
            } else {
                if (response.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
                } else {
                    this.setState({ errorMessage: 'ErrorMessage:UpdateRegistrationFailed' });
                }
            }
        }
    }

}