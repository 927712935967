import { CheckBox } from '$components/shared/CheckBox';
import { Translate } from '$components/shared/Translate';
import UserPreferencesService from '$core/Services/UserPreferencesService';
import Session from '$core/Session';
import { HomePanel } from '$storage/models/HomePanel';
import { Preferences } from '$storage/models/UserPreferences/Preferences';
import { Sortable, SortableOnDragEndEvent } from '@progress/kendo-react-sortable';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

import IconClose from '$resources/svgs/misc/close.svg';
import IconDragVertical from '$resources/svgs/misc/drag-vertical.svg';
import { BrowserHelper } from '$src/util/BrowserHelper';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    onClose?: () => void;
    onChange?: () => void;
}
interface IState {
    panels: any[] | null;
}
export class HomePanelOrganizer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { panels: Preferences.getUserConfigPanels() }
    }

    public render() {
        return (
            <div className="home__organizer-panel">
                <div className="home__organizer-panel-header">
                    <button
                        data-tip={Session.instance.storage.translation.GetString('HomeOrganizer:Close')}
                        data-for="homePanelOrganizer_Tooltip"
                        aria-label={Session.instance.storage.translation.GetString('HomeOrganizer:Close')}
                        onClick={() => this.onClose()}>
                        <InlineSVG src={IconClose} />
                    </button>
                    <Tooltip id="homePanelOrganizer_Tooltip" />
                </div>
                <Sortable
                    idField={'defaultOrder'}
                    disabledField={'isFixed'}
                    data={this.state.panels != null ? this.state.panels : []}
                    itemUI={this.sortableItemUI}
                    navigation={true}
                    style={{ position: 'fixed', marginTop: '48px' }}
                    onDragOver={(event) => this.onDragOver(event)}
                />
            </div>
        );
    }

    private async onDragOver(event: SortableOnDragEndEvent) {
        if (this.state.panels != null) {
            const id = this.state.panels.find(pnl => pnl.defaultOrder === event.prevIndex + 1).id;
            const preferences = Session.instance.loginUserPreferences;

            if (preferences != null) {
                if (preferences.preferences != null && preferences.preferences.homePanels != null) {
                    const homePanel = preferences.preferences.homePanels.find(pnl => pnl.id === id)
                    if (homePanel != null) {
                        event.newState.forEach(npanel => {
                            const abc: any = npanel;
                            if (preferences.preferences.homePanels != null) {
                                const prefPanel = preferences.preferences.homePanels.find(pnl => pnl.id === abc.id);
                                if (prefPanel != null) {
                                    prefPanel.order = event.newState.indexOf(abc) + 1;
                                }
                            }
                        });

                        await UserPreferencesService.instance.saveUserPreferences(preferences);
                        this.setState({ panels: event.newState });
                        if (this.props.onChange != null) {
                            this.props.onChange();
                        }
                    }
                }
            }
        }
    }

    private getBaseItemStyle = (isActive: boolean) => (
        isActive ? { backgroundColor: 'var(--t-base-color__highlight--light)', color: 'white' } : {}
    );

    private sortableItemUI = (props: any) => {
        const { isDisabled, isActive, style, attributes, dataItem, forwardRef } = props;
        const classNames = ['home__organizer-panel-list__item-container'];

        if (isDisabled) {
            classNames.push('k-state-disabled');
        }

        const userPreferences = Session.instance.loginUserPreferences;
        let isPanelPreferedOpen: boolean = true;
        if (userPreferences != null && userPreferences.preferences != null && userPreferences.preferences.homePanels != null) {
            const currPanel = userPreferences.preferences.homePanels.find(lpnl => lpnl.id === dataItem.id);
            if (currPanel != null) { // If there is a config entry for this exact panel
                isPanelPreferedOpen = currPanel.visible;
            }
        }
        return (

            <div ref={forwardRef}
                {...attributes}
                style={{
                    ...this.getBaseItemStyle(isActive),
                    ...style
                }}
                className={classNames.join(' ')}>
                <InlineSVG className="home__organizer-panel-list__drag-icon" src={IconDragVertical} />

                <label id="lblPanelVisible" htmlFor="cbPanelVisible"> <Translate>{dataItem.translationString}</Translate></label>
                <CheckBox
                    id={'cbPanelVisible_' + dataItem.id}
                    onChange={(event) => this.onToggleVisibility(event, dataItem, dataItem.order)}
                    defaultChecked={dataItem.isFixed ? true : isPanelPreferedOpen}
                    disabled={dataItem.isFixed}
                    standardCheckbox={BrowserHelper.isIOSDevice()} />
            </div>

        );
    };


    private onClose() {
        if (this.props.onClose != null) {
            this.props.onClose();
        }
    }

    private async onToggleVisibility(event: React.FormEvent<HTMLInputElement>, panel: any, index: number) {

        const preferences = Session.instance.loginUserPreferences;
        if (preferences != null) {
            if (preferences.preferences.homePanels != null) { // If there are already configured panels
                const currPanel = preferences.preferences.homePanels.find(lpnl => lpnl.id === panel.id);
                if (currPanel != null) { // If there is a config entry for this exact panel
                    currPanel.visible = event.currentTarget.checked;
                } else {
                    const newPanel = new HomePanel(event.currentTarget.id.replace('cbPanelVisible_', ''), index, event.currentTarget.checked, false);
                    preferences.preferences.homePanels.push(newPanel);
                }

            } else { // If there are no configured panels
                const newPanel = new HomePanel(event.currentTarget.id.replace('cbPanelVisible_', ''), index, event.currentTarget.checked, false);
                preferences.preferences.homePanels = [];
                preferences.preferences.homePanels.push(newPanel);
            }
            await UserPreferencesService.instance.saveUserPreferences(preferences);
        }

        if (this.props.onChange != null) {
            this.props.onChange();
        }
    }
}