
import ServiceClient from '$core/ServiceClient';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { Boss } from '$storage/models/Boss';
import { GuestUserCredentials } from '$storage/models/GuestUserCredentials';
import { NumberResponse } from '$storage/models/NumberRepsonse';
import { SelfRegistrationRequest } from '$src/storage/models/RequestObjects/SelfRegistrationRequest';
import { User } from '$storage/models/User';
import { UserBossRelation } from '$storage/models/UserBossRelation';
import { UserPropertiesValidation } from '$storage/models/UserPropertiesValidation';
import GtError from '$util/GtError';
import { VirtualMeetingLink } from '$storage/models/F2F/VirtualMeetingLink';
import { EVirtualMeetingsViewType } from '$storage/models/enums';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';
import { SelfRegistrationResponse } from '$src/storage/models/SelfRegistrationResponse';
import { UserSubjectSubscriptions } from '$src/storage/models/UserSubjectSubscriptions/UserSubjectSubscriptions';
import { BossSelection } from '$src/storage/models/BossSelection';
import Logger from '$src/core/Logger';
import { RegisterNewUserRequest } from '$src/storage/models/RequestObjects/RegisterNewUserRequest';
import { RegisterNewUserResponse } from '$src/storage/models/RegisterNewUserResponse';
import { UserMailLink } from '$src/storage/models/UserMailLink';
import { GroupMailLink } from '$src/storage/models/GroupMailLink';
import { ClassMailLink } from '$src/storage/models/ClassMailLink';
import { ItemMailLink } from '$src/storage/models/ItemMailLink';
import { MailToRequest } from '$src/storage/models/RequestObjects/MailToRequest';

/**
 * UserService provides all Service Methods for the User 
 */
export default class UserService extends ServiceClient {
    protected static _instance: UserService | null = null;

    protected className = 'UserService';
    protected loggerLocality = 'UserService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): UserService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get user from web API.
     * @param userId Id of user to get.
     */
    public async getUser(userId: number): Promise<User | GtError> {
        const response: User | GtError = await this.get<User>('user', User, undefined, undefined, userId.toString());
        return response;
    }

    /**
     * Save curent user
     * @param user 
     */
    public async saveUser(user: User): Promise<User | GtError> {
        const response: User | GtError = await this.post<User>('user', user, User, undefined);
        return response;
    }

    /**
     * Get the guest user credentials from the server config
     *
     */
    public async getGuestUserCredentials(langCode: string): Promise<GuestUserCredentials | GtError> {
        return await this.get<GuestUserCredentials>('config/GetGuestUserCredentials', GuestUserCredentials, undefined, undefined, langCode);
    }

    /**
     * Get a list of Bosses of the user
     * @param langCode 
     */
    public async getBosses(langCode: string, relationFromConfig: boolean): Promise<Boss[] | GtError> {
        return await this.get<Boss[]>('user/getBosses', Boss, undefined, [{ name: 'language', value: langCode }], String(relationFromConfig));
    }

    /**
     * gets valid bosses
     * @param langCode 
     * @param searchText 
     */
    public async getValidBosses(langCode: string, searchText: string): Promise<BossSearchResult[] | GtError> {
        return await this.post<BossSearchResult[]>('user/getValidBosses', searchText, BossSearchResult, undefined,
            [{ name: 'maxEntries', value: globalConfig.bossSelection.maxSearchResults.toString() }]);
    }

    /**
     * Saves a new user as boss for the login User
     * @param langCode 
     * @param id 
     */
    public async saveBoss(id: NumberResponse): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>('user/saveBoss', id, BooleanResponse, undefined);
    }

    /**
     * Gets a List of Bosses to select from in userprofile, regaring to globalConfig.js userProfileProperties
     * @param langCode 
     */
    public async getBossSelection(): Promise<BossSelection[] | GtError> {
        return await this.get<BossSelection[]>('user/getSelectedBosses', BossSelection, undefined);
    }

    /**
     * Gets the configurated permission for the BossSelection in UserProfiles
     */
    public async IsChangeBossAllowed(): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('user/IsChangeBossAllowed', BooleanResponse, undefined);
    }

    /**
     * Get a list of Employees of the user (from the boss relation defined in GTServices)
     */
    public async getEmployees(): Promise<User[] | GtError> {
        return await this.get<User[]>('user/getEmployees', User, undefined);
    }

    /**
     * Get a list of Employees of the user from the given bossRelationCode
     */
    public async getEmployeesOfRelation(bossRelationCode: string): Promise<User[] | GtError> {
        return await this.get<User[]>('user/getEmployees', User, undefined, undefined, bossRelationCode);
    }

    /**
     * Get a list of Boss Relation Codes of the user where he is a boss
     */
    public async getBossRelationCodes(langCode: string): Promise<UserBossRelation[] | GtError> {
        return await this.get<UserBossRelation[]>('user/bossRelations', UserBossRelation, undefined, [{ name: 'language', value: langCode }]);
    }

    /**
     * Validate username and email
     */
    public async validateUserProperties(langCode: string, email: string, username: string, isAnonymousUser: boolean): Promise<UserPropertiesValidation | GtError> {
        return await this.get<UserPropertiesValidation>(
            'user/validateUserProperties',
            UserPropertiesValidation,
            undefined,
            [{ name: 'language', value: langCode }],
            email,
            username,
            String(isAnonymousUser));
    }

    /**
     * Checks if mail exists in Database or not boolean
     */
    public async userEmailExists(email: string, domainId: number): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('user/emailExists', BooleanResponse, undefined, undefined, email, domainId ? domainId.toString() : '0');
    }

    /**
     *  Checks wether mail must be unique or not.  
     */
    public async getDomainEmailCheckMode(domainId: string): Promise<NumberResponse | GtError> {
        return await this.get<NumberResponse>('user/getDomainEmailCheckMode', NumberResponse, undefined, undefined, domainId);
    }

    /**
     * Selfregister user in system
     */
    public async selfRegisterUser(selfRegUser: SelfRegistrationRequest, captchaToken: string): Promise<SelfRegistrationResponse | GtError> {
        const response: SelfRegistrationResponse | GtError = await this.post<SelfRegistrationResponse>('user/selfRegistration', selfRegUser, SelfRegistrationResponse, undefined,
            [{ name: 'captchaToken', value: captchaToken }]);
        return response;
    }

    /**
     * Register a new user, either from self-registration (with 1 or 2 steps) or boss is registering a new user.
     * @param request 
     * @param captchaToken 
     */
    public async registerNewUser(request: RegisterNewUserRequest, captchaToken: string): Promise<RegisterNewUserResponse | GtError> {
        const methodName = `${this.className}:registerNewUser()`;
        Logger.log(this.loggerLocality, `${methodName} calling userRegistration/registerNewUser, username=${request.user.domainId}\\${request.user.username}.`);
        const response: RegisterNewUserResponse | GtError =
            await this.post<RegisterNewUserResponse>('userRegistration/registerNewUser', request, RegisterNewUserResponse, undefined,
                [{ name: 'captchaToken', value: captchaToken }]);
        return response;
    }

    /**
     * Get user created by 1st step of self-registration with 2 steps. This method is used during 2nd step of self-registration.
     * @param token Token returned by registerNewUser() during 1st step
     */
    public async getUserFromFistStep(token: string): Promise<User | GtError> {
        const response: User | GtError = await this.get<User>('userRegistration/getUserFromFistStep', User, undefined, undefined, token);
        return response;
    }

    /*
    * Offline lesson status update via xml: Check Access
    */
    public async hasAccessToLessonStatusFromOfflineXmlImport(): Promise<BooleanResponse | GtError> {
        return await this.get<BooleanResponse>('user/hasAccessToLessonStatusFromOfflineXmlImport', BooleanResponse, undefined, undefined);
    }

    public async getVirtualMeetingLinks(): Promise<VirtualMeetingLink[] | GtError> {
        const response: VirtualMeetingLink[] | GtError = await this.get<VirtualMeetingLink[]>('user/student/getVirtualMeetingLinks', VirtualMeetingLink, undefined, undefined);
        return response;
    }

    public async getVirtualMeetingLinksByViewType(viewType: EVirtualMeetingsViewType): Promise<VirtualMeetingLink[] | GtError> {
        const response: VirtualMeetingLink[] | GtError = await this.get<VirtualMeetingLink[]>('user/student/getVirtualMeetingLinks', VirtualMeetingLink, undefined, undefined, viewType.toString());
        return response;
    }

    // Issue with controller, cant use own controller :/
    public async getSubjectSubscriptionsFromUser(): Promise<UserSubjectSubscriptions | GtError> {
        const response: UserSubjectSubscriptions | GtError = await this.get<UserSubjectSubscriptions>('user/getSubjectSubscriptionsFromUser', UserSubjectSubscriptions, undefined, undefined);
        return response;
    }

    public async getUserForMailLink(request: MailToRequest): Promise<UserMailLink[] | GtError> {
        const response: UserMailLink[] | GtError = await this.post<UserMailLink[]>('user/getUserForMailToLink', request, UserMailLink, undefined, undefined)
        return response;
    }

    public async getGroups(): Promise<GroupMailLink[] | GtError> {
        const response: GroupMailLink[] | GtError = await this.get<GroupMailLink[]>('user/getGroups', GroupMailLink, undefined, undefined);
        return response;
    }

    public async getClasses(itemId: number, typeId: number): Promise<ClassMailLink[] | GtError> {
        const response: ClassMailLink[] | GtError = await this.get<ClassMailLink[]>('user/getClasses', ClassMailLink, undefined, [{ name: 'itemId', value: itemId.toString() }, { name: 'typeId', value: typeId.toString() }]);
        return response;
    }

    public async getItems(language: string): Promise<ItemMailLink[] | GtError> {
        const response: ItemMailLink[] | GtError = await this.get<ItemMailLink[]>('user/getItemsForMailLink', ItemMailLink, undefined, [{ name: 'language', value: language }]);
        return response;
    }
} 