
import React, { useEffect, useState } from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridExpandChangeEvent } from '@progress/kendo-react-grid';
import { PaymentVoucherCampaignResponse, Voucher } from '$src/storage/models/Campaign/PaymentVoucherCampaignResponse';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import GTIconButton from '$components/shared/Atoms/GTIconButton';
import InlineSVG from 'react-inlinesvg';
import Download from '$resources/svgs/voucherCampaign/download.svg';
import { ModalPopup } from '$components/shared/ModalPopup';
import PaymentCampaignVoucherCodesPopup from '$components/voucherAdministration/PaymentCampaignVoucherCodesPopup';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Alert } from '$components/shared/WarningsAndErrors/Alert';
import Session from '$src/core/Session';
import DeleteIcon from '$src/resources/svgs/misc/delete.svg'
import PaymentCampaignOverviewDetailRow from '$components/voucherAdministration/PaymentCampaignOverviewDetailRow';
import { GenericInput } from '../shared/GenericInput';
import { PaymentVoucherCode } from '$src/storage/models/Payment/PaymentVoucherCode';

// const loggerLocality = 'Components.PaymentCampaignArchive ';
interface IProps {
    message: string | null;
    campaignArchive: PaymentVoucherCampaignResponse[] | null;
    archiveMessageType: 'warning' | 'error' | 'info' | 'success' | 'mixed';
    deleteCampaign: (campaignId: number) => void;
    deleteVoucher: (ClickedCampaignId: number) => void;
    isDeletingCampaign: boolean;
    clickedCampaingId: number | null;
}


/**
 * Used to show already created Voucher Campaigns in form of a table/grid
 * Includes functionality to export Vouchers of the Campaigsn in Form of Excel Sheets
 * @param props 
 * @returns Element for the Overview of Campaigns as an Archive
 */
export default function PaymentCampaignArchive(props: IProps) {
    const [data, setData] = useState<PaymentVoucherCampaignResponse[] | null>(props.campaignArchive);
    const [_export, setExporter] = useState<ExcelExport | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [messageType, setMessageType] = useState<'warning' | 'error' | 'info' | 'success' | 'mixed'>('info');
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
    const [clickedCampaign, setClickedCampaign] = useState<PaymentVoucherCampaignResponse | null>(null);
    const [clickedCampaignId, setClickedCampaignId] = useState<number | null>(null);

    const TRANSLATION_PREFIX = 'PaymentCampaignArchive';

    useEffect(() => {
        if (props.clickedCampaingId != null && clickedCampaignId != props.clickedCampaingId) {
            const tmpCampaign = props.campaignArchive?.find(campaign => campaign.id == props.clickedCampaingId);
            if (tmpCampaign) {
                setClickedCampaign(tmpCampaign);
                setIsPopupOpen(true);
                setClickedCampaignId(props.clickedCampaingId);
            } else {
                setClickedCampaign(null);
                setIsPopupOpen(false);
            }

        }
    });

    const getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${TRANSLATION_PREFIX}:${str}`);
    }

    function getVoucherStatus(voucher: PaymentVoucherCode) {
        if (voucher.isRevoked) {
            return getTranslation("Revoked");
        } else if (voucher.usedAt.length > 0 && voucher.usedBy.length > 0) {
            return getTranslation("Used");
        } else {
            return getTranslation("UnUsed");
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exportVouchers = (field: any) => {
        if (_export && field) {
            const list = Array<Voucher>();
            const data = (field as PaymentVoucherCampaignResponse);
            data.vouchers.map((value) => {
                const temp = {
                    pvcrId: data?.id,
                    voucherCode: value.voucherCode,
                    campaignName: data?.title,
                    createdAt: data?.createdAt,
                    expireTime: data?.expireTime,
                    paymentVoucherCampaignRestrictions: data?.paymentVoucherCampaignRestrictions,
                    discount: data?.discount,
                    usedAt: value.usedAt,
                    usedBy: value.usedBy,
                    voucherStatus: getVoucherStatus(value),
                    voucherCodeId: value.voucherCodeId,
                    isRevoked: value.isRevoked
                } as Voucher;
                list.push(temp);
            });
            _export?.save(list);

            setMessage(null);
        } else {
            setMessage(getTranslation('ErrorExportingVouchersToExcel'));
            setMessageType('error');
        }
    };

    const deleteCampaign = (campaignId: number) => {
        props.deleteCampaign(campaignId);
    }

    const expandDetailRow = (event: GridExpandChangeEvent) => {
        const newData = data?.map((campaign) => {
            if (campaign.title === event.dataItem.title) {
                campaign.isGridDetailExpanded = !event.dataItem.isGridDetailExpanded;
            }

            return campaign;
        });
        if (newData) {
            setData(newData);
        }
    };

    let index = 1;

    return (
        <div className="payment-campaign-archive">
            <Heading headingLevel={1} cssClass={'heading__Title '}>
                <Translate>{`${TRANSLATION_PREFIX}:Archive`}</Translate>
            </Heading>
            <GenericInput
                key={`${TRANSLATION_PREFIX}_SearchBox_key`}
                type={'text'}
                id={`${TRANSLATION_PREFIX}_SearchBox_id`}
                label={getTranslation('SearchBox')}
                placeholder={getTranslation('SearchBox_placeholder')}
                value=""
                hasFocus={false}
                isReadOnly={false}
                isRequired={false}
                editMode={true}
                initialValidation={false}
                fieldIndex={1}
                onTextChange={(id, value) => {
                    value = value.toLowerCase();
                    const newData = props.campaignArchive?.filter(
                        f => f.title.toLowerCase().includes(value)
                            || f.numberOfVouchers != null && f.numberOfVouchers.toString().includes(value)
                            || f.discount != null && f.discount.toString().includes(value)
                            || f.createdAt != null && f.createdAt.toLowerCase().includes(value)
                            || f.expireTime != null && f.expireTime.toLowerCase().includes(value)
                            || f.itemLifecycleInDays != null && f.itemLifecycleInDays.toString().includes(value)
                            || f.itemAttributeName != null && f.itemAttributeName.toLowerCase().includes(value)
                            || f.paymentVoucherCampaignRestrictions != null && f.paymentVoucherCampaignRestrictions.toLowerCase().includes(value)
                            || f.metaData != null && f.metaData.toLowerCase().includes(value))
                    if (newData) {
                        setData(newData);
                    }
                }}
            />
            <ExcelExport
                data={data ? data : []}
                ref={exporter => { setExporter(exporter) }}
                fileName={clickedCampaign ? clickedCampaign.title + ".xlsx" : 'ExcelExport.xlsx'}
                columns={[
                    { field: 'voucherCode', title: getTranslation('VoucherCode') },
                    { field: 'campaignName', title: getTranslation('Title') },
                    { field: 'createdAt', title: getTranslation('Date') },
                    { field: 'expireTime', title: getTranslation('ExpiryDate') },
                    { field: 'paymentVoucherCampaignRestrictions', title: getTranslation('RestrictedToCourse') },
                    { field: 'discount', title: getTranslation('Discount') },
                    { field: 'usedAt', title: getTranslation('UsedAt') },
                    { field: 'usedBy', title: getTranslation('UsedBy') },
                    { field: 'voucherStatus', title: getTranslation('VoucherStatus') },
                ]}
            >
                <ExcelExportColumn field="voucherCode" title={getTranslation('VoucherCode')} />
                <ExcelExportColumn field="campaignName" title={getTranslation('Title')} />
                <ExcelExportColumn field="createdAt" title={getTranslation('Date')} />
                <ExcelExportColumn field="expireTime" title={getTranslation('ExpiryDate')} />
                <ExcelExportColumn field="paymentVoucherCampaignRestrictions" title={getTranslation('RestrictedToCourse')} />
                <ExcelExportColumn field="discount" title={getTranslation('Discount')} />
                <ExcelExportColumn field="usedAt" title={getTranslation('UsedAt')} />
                <ExcelExportColumn field="usedBy" title={getTranslation('UsedBy')} />
                <ExcelExportColumn field="voucherStatus" title={getTranslation('VoucherStatus')} />


            </ExcelExport>

            <Grid
                style={{ height: '400px' }}
                data={data}
                detail={PaymentCampaignOverviewDetailRow}
                expandField="isGridDetailExpanded"
                onExpandChange={expandDetailRow}
            >
                <Column
                    field="title"
                    title={getTranslation('Title')}
                    cell={
                        (props) => (
                            <td>
                                <a onClick={() => { setIsPopupOpen(true); setClickedCampaign(props.dataItem) }} >
                                    {props.dataItem?.title}
                                </a>
                            </td>
                        )
                    }
                />
                <Column field="numberOfVouchers" title={getTranslation('NumberOfVouchers')} />
                <Column field="discount" title={getTranslation('Discount')} />
                <Column field="createdAt" title={getTranslation('Date')} />
                <Column
                    field="Actions" title={getTranslation('Actions')}
                    cell={(props: GridCellProps) => (
                        <>
                            <td>
                                <GTIconButton
                                    id={`excel_export_${index++}`}
                                    ariaLabel={getTranslation('ExportVouchersToExcel')}
                                    tooltipText={getTranslation('ExportVouchersToExcel')}
                                    onClick={() => { setClickedCampaign(props.dataItem); exportVouchers(props.dataItem ? props.dataItem : ''); }}>
                                    <InlineSVG src={Download} />
                                </GTIconButton>

                                <GTIconButton
                                    id={`delete_${index++}`}
                                    ariaLabel={getTranslation('Delete')}
                                    tooltipText={getTranslation('Delete')}
                                    onClick={() => deleteCampaign((props.dataItem as PaymentVoucherCampaignResponse).id)}>
                                    <InlineSVG src={DeleteIcon} />
                                </GTIconButton>
                            </td>
                        </>
                    )}
                />
            </Grid>

            <ModalPopup
                isOpen={isPopupOpen}
                onRequestClose={() => setIsPopupOpen(false)}
                contentCssClass={'payment-campaign-archive__voucher-modal'}>
                <PaymentCampaignVoucherCodesPopup campaign={clickedCampaign} exportHandler={() => exportVouchers(clickedCampaign)} {...props} deletedVoucher={() => props.deleteVoucher(clickedCampaign?.id!)} />
            </ModalPopup>

            {
                props.isDeletingCampaign && <ProgressSpinner />
            }
            {props.message &&
                <Alert alertType={props.archiveMessageType} alertAppereance={'box'} message={props.message} />
            }
            {message &&
                <Alert alertType={messageType} alertAppereance={'box'} message={message} />
            }

        </div>
    );
}