import { __gt_startWbt } from '$src/boot';
import ItemService from '$src/core/Services/ItemService';
import { LessonSurvey } from '$src/storage/models/LessonSurvey';
import { WbtStartParameters } from '$src/storage/models/WbtStartParameters';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { UnmountClosed } from 'react-collapse';
import { EItemDetailCallerContextType, EItemSubType, EItemType } from '$src/storage/models/enums';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import ItemTypeModel from '$src/storage/models/ItemTypeModel';
import ItemTypeFactory from '$src/storage/models/itemTypeFactory';
import DateHelper from '$src/util/DateHelper';
import Session from '$src/core/Session';
import { Tooltip } from '$components/shared/Tooltip';
import { DatePicker } from '$components/shared/DatePicker';


import IconPlay from '$resources/svgs/toolbar/lesson-play.svg'
import InlineSVG from 'react-inlinesvg';

//Item Types
import Iconassessment from '$resources/svgs/lessonTypes/assessment.svg';
import Iconfolder from '$resources/svgs/lessonTypes/catalog-folder.svg';
import Iconcbt from '$resources/svgs/lessonTypes/cbt-lesson.svg';
import Icondocument from '$resources/svgs/lessonTypes/document.svg';
import Iconexternal from '$resources/svgs/lessonTypes/external-lesson.svg';
import Iconf2f from '$resources/svgs/lessonTypes/internal-lesson.svg';
import Iconwbt from '$resources/svgs/lessonTypes/online-lesson.svg';
import Iconquestionnaire from '$resources/svgs/lessonTypes/questionnaire.svg';
import Iconfilesharing from '$resources/svgs/lessonTypes/share.svg';
import Iconsurvey from '$resources/svgs/lessonTypes/survey.svg';
import Icontrainingplan from '$resources/svgs/lessonTypes/training-plan.svg';
import { NoDataFound } from '../shared/WarningsAndErrors/NoDataFound';
import { MenuBreadCrumb } from '../breadCrumb/MenuBreadCrumb';

export default function LessonSurveys() {
    const [lessonSurveys, setLessonSurveys] = useState<LessonSurvey[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [filterOpen, setFiltersOpen] = useState<boolean>(false);

    const [itemTypesFilter, setItemTypesFilter] = useState<ItemTypeModel[]>([]);
    const [startDateFilter, setStartDateFilter] = useState<Date | null >();
    const [endDateFilter, setEndDateFilter] = useState<Date | null >();

    useEffect(() => {
        ItemService.instance.getMyLessonSurveys().then(res => {
            if (isSuccess<LessonSurvey[]>(res)) {
                setLessonSurveys(res);
                setDataLoaded(true);
            } else {
                setDataLoaded(true);
            }
        });
    }, [])

    const itemTypesData = ItemTypeFactory.instance.GetItemTypes(true);

    async function openSurveyPlayer(surveyItemId: number) {
        const wbtWindow = window.open('', 'nsui_wbt');

        const response: WbtStartParameters | GtError = await ItemService.instance.getWbtStartParameters(surveyItemId,
            EItemDetailCallerContextType.Undefined,
            0,
            undefined,
            undefined);
        if (isSuccess<WbtStartParameters>(response)) {
            const wbtStartParameters = response;
            const startUrl = wbtStartParameters.startUrl;
            if (wbtWindow != null) {
                wbtWindow.location.href = startUrl;
                __gt_startWbt(wbtWindow);
            }
        }
    }

    function onLessonTypeFilterChanged(values: Array<ItemTypeModel>): void {
        setItemTypesFilter(values);
    }

    function renderFilters(): JSX.Element {
        return (
            <React.Fragment>
                <HeadingCollapsible
                    headingLevel={2}
                    headingCssClass='heading__Level2'
                    containerCssClass=""
                    isOpened={filterOpen}
                    onToggleOpenedState={() => setFiltersOpen(!filterOpen)}>
                    <Translate>Search:Filters</Translate>
                </HeadingCollapsible>
                <UnmountClosed isOpened={filterOpen}>
                    <div className="lesson-surveys_filter">
                        <div className="lesson-surveys_filter-row">
                            <div className="lesson-surveys_filter-row-title">
                                <Translate>LessonSurveys:LessonTypeFilter</Translate>
                            </div>
                            <div className="lesson-surveys_filter-row-filter">
                                <MultiSelect
                                    className="user-interests__settings__value__multi-select"
                                    data={itemTypesData}
                                    onChange={(e) => { onLessonTypeFilterChanged(e.target.value as ItemTypeModel[]) }}
                                    value={itemTypesFilter}
                                    textField="text"
                                />
                            </div>
                        </div>
                        <div className="lesson-surveys_filter-row">
                            <div className="lesson-surveys_filter-row-title">
                                <Translate>LessonSurveys:DateFilter</Translate>
                            </div>
                            <div className="lesson-surveys_filter-row-filter">
                                <DatePicker
                                    className="DatePicker"
                                    key="DateFrom"
                                    onChange={(date) => setStartDateFilter(date)}
                                    value={startDateFilter || null}
                                />
                                <div className="seperator"><Translate>Search:DateFilterSeperator</Translate></div>
                                <DatePicker
                                    className="DatePicker"
                                    key="DateTo"
                                    onChange={(date) => setEndDateFilter(date)}
                                    value={endDateFilter || null}
                                />
                            </div>
                        </div>
                    </div>
                </UnmountClosed >
            </React.Fragment>
        );
    }
    function renderLessonTypeIcon(itemType: EItemType, itemSubType: EItemSubType): string {
        let itemTypeIcon = '';
        switch (itemType) {
            case EItemType.Document:
                itemTypeIcon = Icondocument;
                break;
            case EItemType.F2FCourse:
                if (itemSubType === EItemSubType.External) {
                    itemTypeIcon = Iconexternal;
                } else if (itemSubType === EItemSubType.FaceToFace) {
                    itemTypeIcon = Iconf2f;
                } else {
                    itemTypeIcon = Iconf2f;
                }
                break;
            case EItemType.WBT:
                itemTypeIcon = Iconwbt;
                break;
            case EItemType.CBT:
                itemTypeIcon = Iconcbt;
                break;
            case EItemType.Questionnaire:
                itemTypeIcon = Iconquestionnaire;
                if (itemSubType === EItemSubType.Survey) {
                    itemTypeIcon = Iconsurvey;
                }
                break;
            case EItemType.TrainingPlan:
                itemTypeIcon = Icontrainingplan;
                break;
            case EItemType.Assessment:
                itemTypeIcon = Iconassessment;
                break;
            case EItemType.FileSharing:
                itemTypeIcon = Iconfilesharing;
                break;
            case EItemType.Folder:
                itemTypeIcon = Iconfolder;
                break;

        }
        return itemTypeIcon;
    }

    function renderLessonSurveys(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        let list = itemTypesFilter.length > 0 ? lessonSurveys.filter(f => itemTypesFilter.find(itf => itf.itemType == f.itemType) != undefined) : lessonSurveys;

        if (startDateFilter != null) {
            list = list.filter(f => f.startDate != null && f.startDate >= startDateFilter);
        }

        if (endDateFilter != null) {
            list = list.filter(f => f.endDate != null && f.endDate <= endDateFilter);
        }

        if (list != null) {
            if (list.length > 0) {
                list.map((ls, index) => {
                    elements.push(
                        <li key={index}>
                            <div className="lesson-surveys_item-type">
                                <InlineSVG src={renderLessonTypeIcon(ls.itemType, ls.itemSubType)} />
                            </div>
                            <div className="lesson-surveys_titles">
                                <div>
                                    {ls.surveyTitle}
                                </div>
                                <div>
                                    {ls.itemTitle + (ls.startDate != null && ls.endDate != null ? (" (" + DateHelper.getDatePeriod(ls.startDate == null ? undefined : ls.startDate, ls.endDate == null ? undefined : ls.endDate) + ")") : '')}
                                </div>
                            </div>
                            <div className="lesson-surveys_buttons">
                                <button
                                    data-tip={Session.instance.storage.translation.GetString('LessonSurveys:OpenSurvey') + ": " + ls.surveyTitle}
                                    data-for="openSurvey_Tooltip"
                                    aria-label={Session.instance.storage.translation.GetString("LessonSurveys:OpenSurvey") + ": " + ls.surveyTitle}
                                    className="lesson-surveys_button"
                                    onClick={() => openSurveyPlayer(ls.surveyItemId)}>
                                    <InlineSVG src={IconPlay} />
                                    <Tooltip id="openSurvey_Tooltip" />
                                </button>
                            </div>
                        </li>
                    );
                });
            } else {
                elements.push(
                    <NoDataFound message={Session.instance.storage.translation.GetString('LessonSurveys:NoSurveysFound')} />
                )
            }
        }
        return elements;
    }

    return (
        <div>
            <div className="l-box-container lesson-surveys_container" >
                <MenuBreadCrumb />
                <div>
                    <h1 className="heading__Title" >
                        <Translate>
                            LessonSurveys:Title
                        </Translate>
                    </h1>
                </div >
                <div>
                    <span><Translate>LessonSurveys:Description</Translate></span>
                </div>
                {renderFilters()}
                {!dataLoaded ? (<div className="spinner"><ProgressSpinner /></div>) : (
                    <ul className="lesson-surveys_list">
                        {renderLessonSurveys()}
                    </ul>
                )}
            </div>
        </div>
    );
}