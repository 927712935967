import { UtcDateTimeConverter } from '$storage/models/converters/UtcDateTimeConverter';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class DeputyUpdateRequest {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('bossRelationCode', String, false)
    public bossRelationCode: string = '';

    @JsonProperty('validFrom', UtcDateTimeConverter, false)
    public validFrom: Date | undefined = undefined;

    @JsonProperty('validTo', UtcDateTimeConverter, false)
    public validTo: Date | undefined = undefined;

    @JsonProperty('isNotificationOn', Boolean, false)
    public isNotificationOn: boolean = false;

}