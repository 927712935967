import MediaService from '$src/core/Services/MediaService';
import { Attribute } from '$src/storage/models/Attribute';
import { BrowserHelper } from '$util/BrowserHelper';
import { isSuccess } from './Result';

export default class FileHelper {

    public static async openMediaFile(attr: Attribute | null, url: string | null) {
        let preparedUrl = url == null ? attr!.url : url;
        if(preparedUrl == null) {
            preparedUrl = attr!.getPreparedDocumentUrl();
        }
        if (preparedUrl != null) {
            const documentUrl = await MediaService.instance.getDocumentUrl(preparedUrl);
            if (isSuccess<string>(documentUrl)) {
                window.open(documentUrl, '_blank')
            }
        }
    }

    public static openBlobFile(base64String: string, fallbackFileName?: string) {
        const blob = this.convertBase64ToBlob(base64String);
        const fileName = this.getFileNameFromBase64(base64String, fallbackFileName ? fallbackFileName : '');
        if (BrowserHelper.isEdge()) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        }
    }

    public static openFile(file: File) {
        const blob = file as Blob;
        const fileName = file.name;
        if (BrowserHelper.isEdge()) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const objectUrl = URL.createObjectURL(blob);
            window.open(objectUrl);
        }
    }
    
    public static getFileNameFromUrl(url: string): string {
        const urlFileNameElement = url.split('\\').pop(); // get the las element
        let fileName = '';
        if (urlFileNameElement) {
            fileName = urlFileNameElement.slice(0, urlFileNameElement.indexOf('.'));
        }
        return fileName;
    }

    private static getFileNameFromBase64(base64String: string, fallbackFileName?: string): string {
        if (!base64String.includes('base64')) {
            return base64String;
        }
        const arr = base64String.split(',');
        let fileName = fallbackFileName ? fallbackFileName : '';
        if(arr.length > 2) {
            fileName = arr[2];
        }

        return fileName;
    }

    private static convertBase64ToBlob(base64String: string): Blob {
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        const blob = new Blob([u8arr], { type: mime });
        return blob;
    }

}