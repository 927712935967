import { DatePicker } from '$components/shared/DatePicker';
import React from 'react';
import { UnmountClosed } from 'react-collapse';
import { Redirect, RouteComponentProps } from 'react-router';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { ItemSummary } from '$components/item/itemSummaries/ItemSummary';
import { CheckBox } from '$components/shared/CheckBox';
import ContainerButton from '$components/shared/ContainerButton';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import LessonService from '$core/Services/ItemService';
import Session from '$core/Session';
import IconnewExternalCourseRequest from '$resources/svgs/extendedMenu/student/external-lesson-request.svg';
import { ItemSubTypeConverter } from '$storage/models/converters/ItemSubTypeConverter';
import { EItemDetailCallerContextType, EItemSubType, EItemType, ELessonFilterContext, ELessonStatus, ERegistrationStatus } from '$storage/models/enums';
import { Item } from '$storage/models/Item';
import MyLessonFilter from '$storage/models/MyLessonFilter';
import CustomErrorMessage from '$util/CustomErrorMessage';
import DateHelper from '$util/DateHelper';
import { ItemHelper } from '$util/ItemHelper';
import { NavigationUtil } from '$util/NavigationUtil';
import { isSuccess } from '$util/Result';
import { AutoComplete, AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { isNumber } from 'util';
import { Tooltip } from '$components/shared/Tooltip';

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IProps extends RouteComponentProps<{}> {
    userId: number | undefined;
    courseFilter?: boolean;
    componentId: string;
}

interface IState {
    userId: number;
    errorMessage: string;
    autoCompleteTitlesFiltered: string[] | undefined;
    lessons: Item[] | undefined;
    filterMaxDisplayItems: number;
    filterPanelCollapsed: boolean;
    filterItemType: number;
    filterLessonStatus: number;
    filterExcludeCompleted: boolean;
    filterRegistrationStatus: number;
    filterDateFrom: Date | null;
    filterDateTo: Date | null;
    filterItemSubType: number;
    filterOnlyAssignments: boolean;
    filterTitle: string;
    isAutoCompleteLoading: boolean;
    isDataSearching: boolean;
    itemDetailCallerContextType: EItemDetailCallerContextType;
    title: string;
}

/**
 * Displays a List of all Lessons with an ULS.
 * Grouped BY ULS and Sorted by LastUsed
 */
export class MyLessons extends React.Component<IProps, IState> {

    protected className = 'MyLessons';
    protected loggerLocality = 'Components.MyLessons';

    private timeout: NodeJS.Timeout;

    constructor(props: IProps) {
        super(props);

        // Use Session UserId if not empty
        let tempUsrId: number = 0;
        if (Session.instance.loginUser !== null) {
            tempUsrId = Session.instance.loginUser.id;
        } else if (this.props.userId !== undefined) {
            tempUsrId = this.props.userId;
        }

        const parameters = new URLSearchParams(window.location.search);
        const itemType = parameters.get('itemType');
        const itemSubType = parameters.get('itemSubType');
        const maxResultCount = parameters.get('maxResults');
        const lessonStatus = parameters.get('lessonStatus');
        const excludeCompleted = parameters.get('excludeCompleted');
        const registrationStatus = parameters.get('registrationStatus');
        const lessonFilter = Session.instance.getLessonFilter(itemType === EItemType.TrainingPlan.toString() ? 'myTrainings' : this.props.componentId,
            itemType === EItemType.TrainingPlan.toString() ? ELessonFilterContext.MyTrainingPlans : ELessonFilterContext.Generic);
        const titleId = itemType === EItemType.TrainingPlan.toString() ? 'MyTrainingPlans:Title' : 'MyLessons:Title'
        const maxDisplayItems = maxResultCount != null ? Number.parseInt(maxResultCount, 10) : lessonFilter.maxDisplayItems;
        this.state = {
            errorMessage: '',
            filterDateFrom: itemType === EItemType.TrainingPlan.toString() ? new Date(new Date().getFullYear(), 0, 1) : lessonFilter.dateFrom,
            filterDateTo: itemType === EItemType.TrainingPlan.toString() ? new Date(new Date().getFullYear(), 11, 31) : lessonFilter.dateTo,
            filterExcludeCompleted: excludeCompleted != null ? Boolean(JSON.parse(excludeCompleted)) : lessonFilter.excludeCompleted,
            filterItemSubType: itemSubType != null ? Number.parseInt(itemSubType, 10) : lessonFilter.itemSubType,
            filterItemType: itemType != null ? Number.parseInt(itemType, 10) : lessonFilter.itemType,
            filterLessonStatus: lessonStatus != null ? Number.parseInt(lessonStatus, 10) : lessonFilter.lessonStatus,
            filterMaxDisplayItems: itemType === EItemType.TrainingPlan.toString() ? -1 : maxDisplayItems,
            filterPanelCollapsed: !globalConfig.myLessonsProperties.isFilterPanelExpanded,
            filterRegistrationStatus: registrationStatus != null ? Number.parseInt(registrationStatus, 10) : lessonFilter.registrationStatus,
            filterOnlyAssignments: false,
            filterTitle: '',
            isDataSearching: false,
            itemDetailCallerContextType: EItemDetailCallerContextType.MyLesson,
            lessons: undefined,

            isAutoCompleteLoading: false,
            autoCompleteTitlesFiltered: undefined,

            title: Session.instance.storage.translation.GetString(titleId),
            userId: tempUsrId,
        }
    }

    public UNSAFE_componentWillMount() {
        if (this.props.courseFilter) {
            this.setCourseFilter();
        }
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + this.state.title;
    }

    public componentWillUnmount() {
        const lessonFilter = new MyLessonFilter(this.state.filterItemType === EItemType.TrainingPlan ? ELessonFilterContext.MyTrainingPlans : ELessonFilterContext.Generic)
        lessonFilter.maxDisplayItems = this.state.filterMaxDisplayItems
        lessonFilter.itemType = this.state.filterItemType
        lessonFilter.lessonStatus = this.state.filterLessonStatus
        lessonFilter.registrationStatus = this.state.filterRegistrationStatus
        lessonFilter.dateFrom = this.state.filterDateFrom
        lessonFilter.dateTo = this.state.filterDateTo
        lessonFilter.itemSubType = this.state.filterItemSubType
        lessonFilter.excludeCompleted = this.state.filterExcludeCompleted

        Session.instance.setLessonFilter(this.state.filterItemType === EItemType.TrainingPlan ? 'myTplans' : this.props.componentId, lessonFilter)
    }

    public render() {
        if (this.state.userId === 0) {
            return <Redirect push={true} to="/" />;
        } else {
            const iconColor = NavigationUtil.getNavigationItemAttributeById('MyCourses', 'iconColor');
            const idLoadSearchText = this.state.filterPanelCollapsed ?
                Session.instance.storage.translation.GetString("MyLessons:LoadWithFilterCollapsed")
                : Session.instance.storage.translation.GetString("MyLessons:LoadWithFilter");
            return (
                <div>
                    <div className="l-container" >
                        <div>
                            <MenuBreadCrumb {...this.props} />
                            <div className="l-box--wide my-lessons__header">
                                <h1 className="heading__Title" dangerouslySetInnerHTML={{ __html: this.state.title }} />
                                {this.renderSubtitle()}
                                {this.props.courseFilter &&
                                    <React.Fragment>
                                        {globalConfig.myCoursesProperties.showNewExternalCourseRequestButton &&
                                            <ContainerButton
                                                ariaLabel={Session.instance.storage.translation.GetString('MyCourses:LblNewExternalCourse')}
                                                buttonText={Session.instance.storage.translation.GetString('Navigation:NewExternalCourseRequest')}
                                                icon={IconnewExternalCourseRequest}
                                                Iconcolor={iconColor}
                                                id="newExternalCourseRequestLink"
                                                onButtonClicked={() => this.onButtonClicked()}
                                            />}
                                    </React.Fragment>}
                            </div>
                        </div>
                    </div>
                    <div className="my-lessons__container l-element--striped-reverse my-lessons__container--padding">
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={2}
                                containerCssClass="my-lessons__container--no-margin "
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.filterPanelCollapsed}
                                onToggleOpenedState={() => this.setState({ filterPanelCollapsed: !this.state.filterPanelCollapsed })}>
                                <Translate>MyLessons:Filter</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed
                                isOpened={!this.state.filterPanelCollapsed}>
                                {this.renderFilterPanel()}
                            </UnmountClosed>
                        <ContainerButton
                            ariaLabel={idLoadSearchText}
                            buttonText={idLoadSearchText}
                            id="idLoadSearch"
                            Iconcolor={iconColor}
                            onButtonClicked={() => this.setState({ isDataSearching: true }, () => { this.LoadMyLessons() })}
                        />
                        </div>
                    </div>
                    <div className="my-lessons__container l-box-container">
                        {this.state.isDataSearching ?
                            <ProgressSpinner />
                            : <ul className="item-box-tile__parent">
                                {this.renderLessons()}
                            </ul>
                        }
                        {this.state.errorMessage !== '' ? (
                            <NoDataFound message={Session.instance.storage.translation.GetString(this.state.errorMessage)} />) : ('')
                        }
                    </div>
                </div>);
        }
    }

    protected renderLessons(): JSX.Element[] {
        const myLessons: JSX.Element[] = [];
        if (this.state.lessons != null && this.state.lessons.length > 0) {
            const lessonsToRender = [...this.state.lessons];
            // sorts the lessions in relation to the title in alphabetic order
            lessonsToRender.sort((a, b) => a.title.localeCompare(b.title));

            lessonsToRender.map(lesson => {
                let assignmentId = 0;
                if (lesson.allAssignments && lesson.allAssignments.length > 0) {
                    assignmentId = lesson.allAssignments[0].assignmentId;
                }

                myLessons.push(<ItemSummary
                    key={'sum_' + lesson.sId}
                    item={lesson}
                    parentHeadingLevel={1}
                    itemCallerContext={this.state.itemDetailCallerContextType}
                    parentAssignmentId={assignmentId}
                    parentCatalogFolderId={0}
                    parentTrainingPlanId={0}
                    {...this.props} />)
            });
        }
        else if (this.state.lessons != null && this.state.lessons.length > 0) {
            myLessons.push(<NoDataFound message={Session.instance.storage.translation.GetString('MyLessons:NoDataWithFilter')} />);
        }
        return myLessons;
    }

    private renderSubtitle(): JSX.Element {
        if (this.props.componentId == 'myLessons') {
            return (<div>
                <Translate>MyLessons:SubTitle</Translate>
            </div>);
        } else if (this.props.componentId == 'myTplans') {
            return (<div>
                <Translate>MyTrainingPlans:SubTitle</Translate>
            </div>);
        } else if (this.props.componentId == 'myCourses') {
            return (<div>
                <Translate>MyCourses:SubTitle</Translate>
            </div>);
        }
        return (<div />);
    }


    private renderFilterPanel(): JSX.Element {
        switch (this.props.componentId) {
            case 'myTplans':
                return this.renderFilterPanelTplan();
            default:
                return this.renderFilterPanelDefault();
        }
    }

    private renderFilterPanelTplan(): JSX.Element {
        const tr = Session.instance.storage.translation;
        return (<>
            <div className="l-box--wide"><Translate>MyLessons:Info</Translate></div>
            <div role="table" className="my-Lessons__filter-table l-box--wide my-lessons__container--no-margin">
                <div role="rowgroup">
                    <div role="row" className="my-Lessons__filter-table-row">
                        <div role="columnheader" className="my-Lessons__columnheader-autocomplete"><Translate>MyLessons:SearchByTitle</Translate>
                        </div>
                        <div role="cell">
                            <AutoComplete
                                aria-label={tr.GetString('MyLessons:SearchByTitle')}
                                data={this.state.autoCompleteTitlesFiltered}
                                placeholder={tr.GetString('MyLessons:SearchByTitle')}
                                value={this.state.filterTitle}
                                className="input-field flexForIcon"
                                suggest={false}
                                loading={this.state.isAutoCompleteLoading}
                                onChange={(value: AutoCompleteChangeEvent) => this.filterTitleTextChanged(value)}
                            />
                        </div>
                    </div>
                    <div role="row" className="my-Lessons__filter-table-row">
                        <div role="cell" className="cell-flex autoSize">
                            <CheckBox
                                id="cbLessonStatus"
                                className="input-field"
                                onClick={(e) => this.filterExcludeLessonStatusCompleted(!e.currentTarget.checked)}
                                defaultChecked={!this.state.filterExcludeCompleted} />
                            <div className="my-Lessons__checkbox-text"><Translate>MyLessons:chkLessonStatus</Translate></div>
                        </div>
                        <div role="cell" className="cell-small">
                            <select
                                className="input-field"
                                id="cbYear"
                                value={this.state.filterDateFrom != null ? this.state.filterDateFrom.getFullYear().toString() : tr.GetString('MyLessons:TPlanFilterAll')}
                                onChange={(e) => this.filterOnYearChange(e)} >
                                {this.createYearItems()}
                            </select>
                        </div>
                    </div>
                    <div role="row" className="my-Lessons__filter-table-row">
                        <div role="cell" className="cell-flex autoSize">
                            <CheckBox
                                id="cbWithExpiredAssignments"
                                className="input-field"
                                onClick={(e) => this.filterOnlyAssignmentsClick(e.currentTarget.checked)}
                                defaultChecked={this.state.filterOnlyAssignments} />
                            <div className="my-Lessons__checkbox-text"><Translate>MyLessons:cbOnlyAssignments</Translate></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        );
    }

    private createYearItems() {
        const today = new Date()
        const elements: JSX.Element[] = []
        elements.push(<option key="all">{Session.instance.storage.translation.GetString('MyLessons:TPlanFilterAll')}</option>)
        let firstYear = today.getFullYear() + globalConfig.myLessonsProperties.filter_TPlan_NumberOfYearsInFuture;
        for (let counter: number = 0; counter < globalConfig.myLessonsProperties.filter_TPlan_NumberOfYearsForSelection; counter++) {
            const element = (<option key={counter}> {firstYear.toString()} </option>)
            elements.push(element)
            firstYear--;
        }
        return (
            <React.Fragment>
                {elements}

            </React.Fragment>
        )

    }

    private renderFilterPanelDefault(): JSX.Element {
        const tr = Session.instance.storage.translation;
        return (
            <div role="table" className="my-Lessons__filter-table">
                <div role="row" className={'my-Lessons__filter-table-row'}>
                    <div role="columnheader" className="my-Lessons__columnheader-autocomplete">
                        {this.state.filterItemType !== EItemType.F2FCourse ?
                            (<Translate>MyLessons:LastUseBetween</Translate>) :
                            (<Translate>MyLessons:TakesPlaceBetween</Translate>)
                        }
                    </div>
                    <div role="cell" className="datePicker" >
                        <DatePicker
                            key={'txt_Filter_DateFrom'}
                            onChange={(v) => this.filterDateFromChanged(v)}
                            value={this.state.filterDateFrom}
                        />
                    </div>
                    <div role="columnheader" className="connectorText my-Lessons__columnheader-autocomplete">
                        {this.state.filterItemType !== EItemType.F2FCourse ?
                            (<Translate>MyLessons:LastUseBetweenAnd</Translate>) :
                            (<Translate>MyLessons:TakesPlaceBetweenAnd</Translate>)
                        }
                    </div>
                    <div role="cell" className="datePicker" >
                        <DatePicker
                            key={'txt_Filter_DateTo'}
                            onChange={(v) => this.filterDateToChanged(v)}
                            value={this.state.filterDateTo} />
                    </div>
                </div>
                <div role="row" className="my-Lessons__filter-table-row">
                    <div role="columnheader" className="my-Lessons__columnheader-autocomplete"><Translate>MyLessons:SearchByTitle</Translate>
                    </div>
                    <div role="cell">
                        <AutoComplete
                            aria-label={tr.GetString('MyLessons:SearchByTitle')}
                            data={this.state.autoCompleteTitlesFiltered}
                            placeholder={tr.GetString('MyLessons:SearchByTitle')}
                            value={this.state.filterTitle}
                            className="input-field flexForIcon"
                            suggest={false}
                            loading={this.state.isAutoCompleteLoading}
                            onChange={(value: AutoCompleteChangeEvent) => this.filterTitleTextChanged(value)}
                        />
                    </div>
                </div>
                <div role="row" className={this.props.courseFilter === true ? 'hidden' : 'my-Lessons__filter-table-row'}>
                    <div role="columnheader">
                        <Translate>MyLessons:LessonTypeFilter</Translate>
                    </div>
                    <div role="cell">
                        <select
                            id="ddlItemTypeFilter"
                            data-tip={tr.GetString('MyLessons:LessonTypeFilter')}
                            aria-label={tr.GetString('MyLessons:LessonTypeFilter')}
                            data-for="ddlItemTypeFilter_Tooltip"
                            className="input-field"
                            onChange={(e) => this.filterItemTypeChanged(e)}
                            defaultValue={this.state.filterItemType.toString()}>
                            {this.createitemTypeFilter()}
                        </select>
                        <Tooltip id="ddlItemTypeFilter_Tooltip" />
                    </div>
                </div>
                {(this.state.filterItemType === EItemType.F2FCourse || this.state.filterItemType === EItemType.Questionnaire) &&
                    <div role="row" className="my-Lessons__filter-table-row">
                        <div role="columnheader">
                            <Translate>MyLessons:LessonSubTypeFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlItemSubTypeFilter"
                                data-tip={tr.GetString('MyLessons:LessonSubTypeFilter')}
                                data-for="ddlItemSubTypeFilter_Tooltip"
                                aria-label={tr.GetString('MyLessons:LessonSubTypeFilter')}
                                className="input-field"
                                onChange={(e) => this.filterItemSubTypeChanged(e)}
                                defaultValue={this.state.filterItemSubType.toString()}>
                                {this.createItemSubTypeFilter()}
                            </select>
                            <Tooltip id="ddlItemSubTypeFilter_Tooltip" />
                        </div>
                    </div>}
                {this.state.filterItemType !== EItemType.F2FCourse &&
                    <div role="row" className="my-Lessons__filter-table-row">
                        <div role="columnheader">
                            <Translate>MyLessons:LessonStatusFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlLessonStatusFilter"
                                data-tip={tr.GetString('MyLessons:LessonStatusFilter')}
                                data-for="ddlLessonStatusFilter_Tooltip"
                                aria-label={tr.GetString('MyLessons:LessonStatusFilter')}
                                className="input-field"
                                onChange={(e) => this.filterLessonStatusChanged(e)}
                                defaultValue={this.state.filterLessonStatus.toString()}>
                                {this.createLessonStatusFilter()}
                            </select>
                            <Tooltip id="ddlLessonStatusFilter_Tooltip" />
                        </div>
                    </div>
                }
                {this.state.filterItemType === EItemType.F2FCourse &&
                    <div role="row" className="my-Lessons__filter-table-row">
                        <div role="columnheader">
                            <Translate>MyLessons:RegistrationStatusFilter</Translate>
                        </div>
                        <div role="cell">
                            <select
                                id="ddlRegistrationStatusFilter"
                                data-tip={tr.GetString('MyLessons:RegistrationStatusFilter')}
                                data-for="ddlRegistrationStatusFilter_Tooltip"
                                aria-label={tr.GetString('MyLessons:RegistrationStatusFilter')}
                                className="input-field"
                                onChange={(e) => this.filterRegistrationStatusChanged(e)}
                                defaultValue={this.state.filterRegistrationStatus.toString()}>
                                {this.createRegistrationStatusFilter()}
                            </select>
                            <Tooltip id="ddlRegistrationStatusFilter_Tooltip" />
                        </div>
                    </div>
                }
                <div role="row" className="my-Lessons__filter-table-row">
                    <div role="columnheader">
                        <Translate>MyLessons:FilterResultCount</Translate>
                    </div>
                    <div role="cell">
                        <select
                            id="ddlResultCountFilter"
                            data-tip={tr.GetString('MyLessons:FilterResultCount')}
                            data-for="ddlResultCountFilter_Tooltip"
                            aria-label={tr.GetString('MyLessons:FilterResultCount')}
                            className="input-field"
                            onChange={(e) => this.filerMaxResultCountChanged(e)}
                            defaultValue={this.state.filterMaxDisplayItems.toString()}>
                            {this.createResultCountFilter()}
                        </select>
                        <Tooltip id="ddlResultCountFilter_Tooltip" />
                    </div>
                </div>
                <div role="row" className="my-Lessons__filter-table-row">
                    <div role="cell" className="cell-flex autoSize">
                        <CheckBox
                            id="cbWithExpiredAssignments"
                            className="input-field"
                            onClick={(e) => this.filterOnlyAssignmentsClick(e.currentTarget.checked)}
                            defaultChecked={this.state.filterOnlyAssignments} />
                        <div className="my-Lessons__checkbox-text"><Translate>MyLessons:cbOnlyAssignments</Translate></div>
                    </div>
                </div>
            </div>
        );
    }

    private filterTitleTextChanged(event: AutoCompleteChangeEvent) {
        const value = event.target.value;
        this.setState({
            filterTitle: value
        });
        global.clearTimeout(this.timeout);
        if (value != this.state.filterTitle && !this.state.autoCompleteTitlesFiltered?.includes(value) && (value.length > 2 || value == "*")) {
            this.timeout = global.setTimeout(() => {
                this.LoadMyLessonsTitles();
            }, 300);
        }
    }

    private onButtonClicked() {
        this.props.history.push(ItemHelper.getRequestWizardDetailLink(0, EItemDetailCallerContextType.MyCourses));
    }

    private setCourseFilter() {
        const thisYear = new Date().getFullYear();

        this.setState({
            filterDateFrom: new Date(thisYear, 0, 1),
            filterDateTo: new Date(thisYear, 11, 31),
            filterItemType: EItemType.F2FCourse,
            filterMaxDisplayItems: -1,
            itemDetailCallerContextType: EItemDetailCallerContextType.MyCourses,
            title: Session.instance.storage.translation.GetString('MyCourses:Title')
        });
    }

    private filterDateToChanged(value: Date | null) {
        this.setState({ filterDateTo: value, filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterDateFromChanged(value: Date | null) {
        this.setState({ filterDateFrom: value, filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterLessonStatusChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterLessonStatus: Number(target.value), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterRegistrationStatusChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget.value;
        this.setState({ filterRegistrationStatus: Number(target), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterItemTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget.value;
        if (Number(target) !== EItemType.F2FCourse) {
            this.setState({
                filterItemSubType: 0,
                filterItemType: Number(target),
                filterRegistrationStatus: 0,
                filterTitle: '',
                autoCompleteTitlesFiltered: undefined
            });
        } else {
            this.setState({
                filterDateFrom: null,
                filterDateTo: null,
                filterItemSubType: 0,
                filterItemType: Number(target),
                filterLessonStatus: 0,
                filterRegistrationStatus: 0, filterTitle: '',
                autoCompleteTitlesFiltered: undefined
            });
        }
    }

    private filterItemSubTypeChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterItemSubType: Number(target.value), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filerMaxResultCountChanged(event: React.FormEvent<HTMLSelectElement>) {
        const target = event.currentTarget as HTMLSelectElement;
        this.setState({ filterMaxDisplayItems: Number(target.value), filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterOnYearChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const thisYear = Number(e.target.value)
        if (isNaN(thisYear)) {
            this.setState({ filterDateFrom: null, filterDateTo: null, filterTitle: '', autoCompleteTitlesFiltered: undefined });
        } else {
            this.setState({ filterDateFrom: new Date(thisYear, 0, 1), filterDateTo: new Date(thisYear, 11, 31), filterTitle: '', autoCompleteTitlesFiltered: undefined });
        }
    }

    private filterExcludeLessonStatusCompleted(exclude: boolean) {
        this.setState({ filterExcludeCompleted: exclude, filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private filterOnlyAssignmentsClick(checked: boolean): void {
        this.setState({ filterOnlyAssignments: checked, filterTitle: '', autoCompleteTitlesFiltered: undefined });
    }

    private createResultCountFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const filters = globalConfig.myLessonsProperties.filter_numberOfResultSteps;
        filters.map(num => {
            elements.push(
                <option key={'resultCountFilter_' + num} value={num}>{num}</option>
            );
        });
        elements.push(
            <option
                key={'itemTypeFilterOption_ShowAll'}
                value={-1}>{Session.instance.storage.translation.GetString('MyLessons:ShowAll')}</option>
        );
        return elements;
    }

    private createitemTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const abc = Object.keys(EItemType);
        let filters: number[] = globalConfig.myLessonsProperties.filter_itemTypes;
        if(this.props.courseFilter) {
            filters = [EItemType.F2FCourse]
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = abc.map(k => EItemType[k as any]).map(v => v as any as EItemType);
        values = values.filter(val => !isNumber(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const type in values) {
            if (filters.includes(Number.parseInt(type, 10)) || type === '0') {
                elements.push(
                    <option key={'itemTypeFilterOption_' + type}
                        value={type}>{type === '0' ?
                            Session.instance.storage.translation.GetString('MyLessons:All') :
                            Session.instance.storage.translation.GetString('ItemType:' + EItemType[type].toString())}</option>
                );
            }
        }
        return elements;
    }

    // the creation of the sub type filter differs form the others, because the enum numbers are not continuous
    private createItemSubTypeFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        let filters: number[] = [];
        if (this.state.filterItemType === EItemType.F2FCourse) {
            filters = globalConfig.myLessonsProperties.filter_itemSubTypes_Course;
        } else if (this.state.filterItemType === EItemType.Questionnaire) {
            filters = globalConfig.myLessonsProperties.filter_itemSubTypes_Questionnaire;
        }

        const itemSubTypeConverter = new ItemSubTypeConverter();
        // tslint:disable-next-line:forin
        for (const type in EItemSubType) {
            const itemSubTypeNumber = itemSubTypeConverter.stringToInt(type);
            if (filters.includes(itemSubTypeNumber) || itemSubTypeNumber === 0) {
                elements.push(
                    <option key={'itemSubTypeFilterOption_' + itemSubTypeNumber}
                        value={itemSubTypeNumber}>{itemSubTypeNumber === 0 ?
                            Session.instance.storage.translation.GetString('MyLessons:All') :
                            Session.instance.storage.translation.GetString('ItemSubType:' + type)}</option>
                );
            }
        }
        return elements;
    }

    private createLessonStatusFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const abc = Object.keys(ELessonStatus);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = abc.map(k => ELessonStatus[k as any]).map(v => v as any as ELessonStatus);
        values = values.filter(val => !isNumber(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const status in values) {
            elements.push(
                <option key={'lessonStatusFilterOption_' + status}
                    value={status}>
                    {status === '0' ?
                        Session.instance.storage.translation.GetString('MyLessons:All')
                        : Session.instance.storage.translation.GetString('LessonStatus:' + ELessonStatus[status].toString())}</option>
            );
        }
        return elements;
    }

    private createRegistrationStatusFilter(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const abc = Object.keys(ERegistrationStatus);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let values = abc.map(k => ERegistrationStatus[k as any]).map(v => v as any as ERegistrationStatus);
        values = values.filter(val => !isNumber(val));
        // eslint-disable-next-line @typescript-eslint/no-for-in-array
        for (const status in values) {
            elements.push(
                <option key={'registrationStatusFilterOption_' + status}
                    value={status}>
                    {status === '0' ?
                        Session.instance.storage.translation.GetString('MyLessons:All')
                        : Session.instance.storage.translation.GetString('RegistrationStatus:' + ERegistrationStatus[status].toString())}</option>
            );
        }
        return elements;
    }

    private async LoadMyLessons() {

        const loadRegisteredItems = (globalConfig.myLessonsProperties.loadRegisteredItems || this.props.courseFilter) ? 'true' : 'false';

        const tmp = await LessonService.instance.getLessons(Session.instance.getUserLanguageCodeOrFallBack,
            false,
            loadRegisteredItems,
            this.state.filterItemType,
            this.state.filterItemSubType,
            this.state.filterLessonStatus,
            this.state.filterRegistrationStatus,
            this.state.filterExcludeCompleted,
            this.state.filterTitle,
            DateHelper.toSqlDateString(this.state.filterDateFrom),
            DateHelper.toSqlDateString(this.state.filterDateTo),
            this.state.filterMaxDisplayItems,
            this.state.filterOnlyAssignments);
        if (isSuccess<Item[]>(tmp)) {
            this.setState({ lessons: tmp, errorMessage: tmp.length > 0 ? '' : 'MyLessons:NoLessonsFound', isDataSearching: false });
        } else {
            if (tmp.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(tmp.detailedObject.subStatusCode), isDataSearching: false })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:MyLessonsFailed', isDataSearching: false });
            }
        }
    }

    private async LoadMyLessonsTitles() {
        const loadRegisteredItems = (globalConfig.myLessonsProperties.loadRegisteredItems || this.props.courseFilter) ? 'true' : 'false';

        this.setState({ isAutoCompleteLoading: true });

        const tmp = await LessonService.instance.getMyLessonsTitles(Session.instance.getUserLanguageCodeOrFallBack,
            loadRegisteredItems,
            this.state.filterItemType,
            this.state.filterItemSubType,
            this.state.filterLessonStatus,
            this.state.filterExcludeCompleted,
            this.state.filterTitle,
            DateHelper.toSqlDateString(this.state.filterDateFrom),
            DateHelper.toSqlDateString(this.state.filterDateTo),
            this.state.filterMaxDisplayItems,
            this.state.filterOnlyAssignments);
        if (isSuccess<string[]>(tmp)) {
            this.setState({
                autoCompleteTitlesFiltered: tmp,
                errorMessage: tmp.length > 0 ? '' : 'MyLessons:NoLessonsFound', isAutoCompleteLoading: false
            });
        } else {
            if (tmp.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(tmp.detailedObject.subStatusCode), isAutoCompleteLoading: false })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:MyLessonsFailed', isAutoCompleteLoading: false });
            }
        }
    }
}
export default MyLessons;