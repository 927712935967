import React from "react";
import { ESkillStatus, ESortDirection } from "$src/storage/models/enums";
import { EvaluationSkillDataUser } from "$src/storage/models/EvaluationSkillDataUser";
import { SortableHeaderElement } from "../shared/SortableHeaderElement";
import { StringHelper } from "$src/util/StringHelper";
import Session from "$src/core/Session";

interface IProps {
    data: EvaluationSkillDataUser;
    isActiveHeader: boolean;
    onActiveHeaderChanged: (activeHeaderId: string, sortDirection: ESortDirection) => void;
    key: string;
    columnSettings: { id: string }[];
}

const tr = Session.instance.storage.translation;

function ShowExpirationDate(item: EvaluationSkillDataUser) {
    if (item.skillLevel == null || item.skillStatus === ESkillStatus.Undefined) {
        return tr.GetString("EvaluationOfSkills:NotAchieved");
    }
    if (item.expirationDate !== undefined && item.expirationDate?.getFullYear() !== 1) {
        return StringHelper.dateString(item.expirationDate);
    }
    return "";
}

export default function EvaluationOfSkillsItem(props: IProps) {
    return (
        <div role="row">
            <div className="evaluationOfReport__table__row row__content" >
                {
                    props.columnSettings.map((field, idx) => {
                        console.log(field.id);
                        console.log(eval(`props.data.${field.id}`));
                        switch (field.id) {
                            case "allocationDate":
                                return (<>
                                    <div role="columnheader" className="mobileOnly small-cell" key={idx}>
                                        <SortableHeaderElement
                                            key={idx}
                                            id={field.id}
                                            isActive={props.isActiveHeader}
                                            header={tr.GetString(`EvaluationOfSkills:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                                            initialSortDirection={ESortDirection.Down}
                                            OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                                        />
                                    </div>
                                    <div role="cell">{props.data.skillLevel != null && props.data.skillStatus != ESkillStatus.Undefined ? StringHelper.dateString(props.data.allocationDate) : tr.GetString("EvaluationOfSkills:NotAchieved")}</div>
                                </>);
                            case "expirationDate":
                                return (<>
                                    <div role="columnheader" className="mobileOnly small-cell" key={idx}>
                                        <SortableHeaderElement
                                            key={idx}
                                            id={field.id}
                                            isActive={props.isActiveHeader}
                                            header={tr.GetString(`EvaluationOfSkills:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                                            initialSortDirection={ESortDirection.Down}
                                            OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                                        />
                                    </div>
                                    <div role="cell">{ShowExpirationDate(props.data)}</div>
                                </>);
                            case "skillLevel":
                                return (<> <div role="columnheader" className="mobileOnly small-cell" key={idx}>
                                    <SortableHeaderElement
                                        key={idx}
                                        id={field.id}
                                        isActive={props.isActiveHeader}
                                        header={tr.GetString(`EvaluationOfSkills:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                                        initialSortDirection={ESortDirection.Down}
                                        OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                                    />
                                </div>
                                    <div role="cell">{props.data.skillLevel != null && props.data.skillStatus != ESkillStatus.Undefined ? props.data.skillLevel : tr.GetString("EvaluationOfSkills:NotAchieved")}</div>
                                </>);
                            default:
                                return (<>
                                    <div role="columnheader" className="mobileOnly small-cell" key={idx}>
                                        <SortableHeaderElement
                                            key={idx}
                                            id={field.id}
                                            isActive={props.isActiveHeader}
                                            header={tr.GetString(`EvaluationOfSkills:${field.id.charAt(0).toUpperCase() + field.id.slice(1)}`)}
                                            initialSortDirection={ESortDirection.Down}
                                            OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                                        />
                                    </div>
                                    <div role="cell">{eval(`props.data.${field.id}`)}</div>
                                </>);
                        }
                    })
                }
            </div>
        </div>
    )
}