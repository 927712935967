import Logger from '$src/core/Logger';
import ConfigService from '$src/core/Services/ConfigService';
import Session from '$src/core/Session';
import { ServiceInfo } from '$src/storage/models/ServiceInfo';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import ServiceInfoIcon from '$src/resources/svgs/ServiceInfoIcon.svg'
import GTButton from '$src/components/shared/Atoms/GTButton';
import { Translate } from '$src/components/shared/Translate';
import DateHelper from '$src/util/DateHelper';

export default function ServiceInfoBar() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [serviceInfo, setServiceInfo] = useState<ServiceInfo>();
    const [display, setDisplay] = useState<boolean>(true);
    const loggerLocality = 'Components.ServiceInfoBar';
    const className = 'ServiceInfoBar';

    useEffect(() => {
        const methodName = `${className}:fetchData()`;

        if (!isLoaded) {
            ConfigService.instance.getServiceInfo().then(result => {
                if (isSuccess<ServiceInfo>(result)) {
                    setServiceInfo(result);
                } else {
                    const errorMessage = `${methodName} failed to get Service Info`;
                    Logger.log(loggerLocality, `${methodName} called`);
                    console.log(errorMessage);
                }
            })
            setIsLoaded(true);
        }
    });


    function storeServiceInfo() {
        setDisplay(false);
    }

    return (
        <>

            {isLoaded && serviceInfo?.shouldBeDisplay && display ? <div className="serviceInfoBar__container">

                <div>
                    <InlineSVG src={ServiceInfoIcon} />
                </div>
                <div>
                    {Session.instance.storage.translation.GetString('ServiceInfo:AlertUpdate')
                        .Format(DateHelper.toDateTimeString(serviceInfo.systemOffFrom), DateHelper.toDateTimeString(serviceInfo.systemOffTo))}
                </div>
                <div>
                    <GTButton onClick={() => storeServiceInfo()}
                        ariaLabel={Session.instance.storage.translation.GetString('ServiceInfo:Close')}
                        id="btnServiceInfoClose">
                        <Translate>ServiceInfo:Close</Translate>
                    </GTButton>
                </div>
            </div >
                : null
            }
        </>


    )
}
