import ServiceClient from '$src/core/ServiceClient';
import { CbtCommandButton } from '$src/storage/models/CbtCommandButton';
import { CbtStartParameters } from '$src/storage/models/CbtStartParameters';
import { Item } from '$src/storage/models/Item';
import { ItemSkills } from '$src/storage/models/ItemDetails/ItemSkills';
import { ItemId } from '$src/storage/models/ItemId';
import { WbtStartParameters } from '$src/storage/models/WbtStartParameters';
import { Attribute } from '$storage/models/Attribute';
import { File } from '$storage/models/File';
import GtError from '$util/GtError';

import { VirtualMeetingLink } from '$src/storage/models/F2F/VirtualMeetingLink';
import Session from '$src/core/Session';
import { EItemDetailCallerContextType, ERecommendedItemsByUserInterestsOrdering } from '$src/storage/models/enums';
import { LessonUser } from '$src/storage/models/LessonUser';
import { LessonSurvey } from '$src/storage/models/LessonSurvey';
import { ItemHelper } from '$src/util/ItemHelper';

/**
 * ItemService provides all Service Methods for the Lessons 
 */
export default class ItemService extends ServiceClient {
    protected static _instance: ItemService | null = null;

    protected className = 'ItemService';
    protected loggerLocality = 'ItemService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ItemService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Get lesson detail of a selected item
     * 
     * 
     * @param itemId Id of lesson
     * @param language language
     */
    public async getItemSkills(itemId: number): Promise<ItemSkills | GtError> {
        const response: ItemSkills | GtError = await this.get<ItemSkills>('lessonioskills', ItemSkills, undefined, undefined, itemId.toString());
        return response;
    }

    /**
     * Get lesson detail of a selected item
     * @param itemId Id of lesson
     * @param itemContext  Context of item detail call for checking access right to item
     * @param parentTrainingPlanId Optional id of parent training plan (item was opened in a trainig plan)
     */
    public async getLesson(itemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId: number = 0): Promise<Item | GtError> {
        const response: Item | GtError = await this.get<Item>('lesson',
            Item,
            undefined,
            [
                { name: 'tpId', value: parentTrainingPlanId.toString() }
            ],
            itemId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    /**
     * Get itemId by item SId
     * @param itemSId SId of lesson
     */
    public async getItemID(itemSId: string): Promise<ItemId | GtError> {
        const response: ItemId | GtError = await this.get<ItemId>('lesson/getItemId', ItemId, undefined, undefined, itemSId);
        return response;
    }

    /**
     * Get all lessons with a LessonStatus from current Authorized User.
     *
     * @param {string} language
     * @param {number} maxItemCount
     * @returns {(Promise<Lesson[] | GtError>)}
     * @memberof LessonService
     */
    public async getMyLessons(language: string, loadRegisteredItems: string, lessonType: number, lessonSubType: number,
        lessonStatus: number, lessonStatusExcludeCompleted: boolean, title: string, filterDateFrom: string, filterDateTo: string, maxItemCount: number, isOnlyAssignments: boolean): Promise<Item[] | GtError> {

        const response: Item[] | GtError = await this.get<Item[]>('lesson/getMyLessons',
            Item,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'loadRegisteredItems', value: loadRegisteredItems },
                { name: 'lessonType', value: lessonType.toString() }, //1185 for tplan
                { name: 'lessonSubType', value: lessonSubType.toString() },
                { name: 'lessonStatus', value: lessonStatus.toString() },
                { name: 'lessonStatusExcludeCompleted', value: lessonStatusExcludeCompleted.toString() },
                { name: 'title', value: title.toString() },
                { name: 'filterDateFrom', value: filterDateFrom },
                { name: 'filterDateTo', value: filterDateTo },
                { name: 'maxItemCount', value: maxItemCount.toString() },
                { name: 'isOnlyAssignments', value: isOnlyAssignments.toString() },
            ]
        );
        return response;
    }

    /**
     * Get all lessons with a LessonStatus from current Authorized User or his (boss) team employees.
     *
     * @param {string} language
     * @param {number} maxItemCount
     * @returns {(Promise<Lesson[] | GtError>)}
     * @memberof LessonService
     */
    public async getLessons(language: string, requestLessonsOfEmployees: boolean, loadRegisteredItems: string, lessonType: number, lessonSubType: number,
        lessonStatus: number, registrationStatus: number, lessonStatusExcludeCompleted: boolean, title: string, filterDateFrom: string, filterDateTo: string, maxItemCount: number, isOnlyAssignments: boolean): Promise<Item[] | GtError> {

        const response: Item[] | GtError = await this.get<Item[]>('lesson/getLessons',
            Item,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'requestLessonsOfEmployees', value: requestLessonsOfEmployees.toString() },
                { name: 'loadRegisteredItems', value: loadRegisteredItems },
                { name: 'lessonType', value: lessonType.toString() }, //1185 for tplan
                { name: 'lessonSubType', value: lessonSubType.toString() },
                { name: 'lessonStatus', value: lessonStatus.toString() },
                { name: 'registrationStatus', value: registrationStatus.toString() },
                { name: 'lessonStatusExcludeCompleted', value: lessonStatusExcludeCompleted.toString() },
                { name: 'title', value: title.toString() },
                { name: 'filterDateFrom', value: filterDateFrom },
                { name: 'filterDateTo', value: filterDateTo },
                { name: 'maxItemCount', value: maxItemCount.toString() },
                { name: 'isOnlyAssignments', value: isOnlyAssignments.toString() },
            ]
        );
        return response;
    }

    /**
     * Get all lessons with a LessonStatus from current Authorized User or his (boss) team employees.
     *
     * @param {string} language
     * @param {number} maxItemCount
     * @returns {(Promise<Lesson[] | GtError>)}
     * @memberof LessonService
     */
    public async getLessonWithUsers(language: string, userNameInput: string, loadRegisteredItems: string, lessonType: number, lessonSubType: number,
        lessonStatus: number, lessonStatusExcludeCompleted: boolean, title: string, filterDateFrom: string, filterDateTo: string, maxItemCount: number, bossRelationCode: string): Promise<LessonUser[] | GtError> {

        const response: LessonUser[] | GtError = await this.get<LessonUser[]>('lesson/getEmployeeLessonsWithUser',
            LessonUser,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'userName', value: userNameInput },
                { name: 'loadRegisteredItems', value: loadRegisteredItems },
                { name: 'lessonType', value: lessonType.toString() }, //1185 for tplan
                { name: 'lessonSubType', value: lessonSubType.toString() },
                { name: 'lessonStatus', value: lessonStatus.toString() },
                { name: 'lessonStatusExcludeCompleted', value: lessonStatusExcludeCompleted.toString() },
                { name: 'title', value: title.toString() },
                { name: 'filterDateFrom', value: filterDateFrom },
                { name: 'filterDateTo', value: filterDateTo },
                { name: 'maxItemCount', value: maxItemCount.toString() },
                { name: 'bossRelationCode', value: bossRelationCode },
            ]
        );
        return response;
    }

    /**
     * Get all lessons titles with a LessonStatus from current Authorized User.
     *
     * @param {string} language
     * @param {number} maxItemCount
     * @returns {(Promise<Lesson[] | GtError>)}
     * @memberof LessonService
     */
    public async getMyLessonsTitles(language: string, loadRegisteredItems: string, lessonType: number, lessonSubType: number,
        lessonStatus: number, lessonStatusExcludeCompleted: boolean, title: string, filterDateFrom: string, filterDateTo: string, maxItemCount: number, isOnlyAssignments: boolean): Promise<string[] | GtError> {

        const response: string[] | GtError = await this.get<string[]>('lesson/getMyLessonsTitles',
            String,
            undefined,
            [
                { name: 'language', value: language },
                { name: 'loadRegisteredItems', value: loadRegisteredItems },
                { name: 'lessonType', value: lessonType.toString() }, //1185 for tplan
                { name: 'lessonSubType', value: lessonSubType.toString() },
                { name: 'lessonStatus', value: lessonStatus.toString() },
                { name: 'lessonStatusExcludeCompleted', value: lessonStatusExcludeCompleted.toString() },
                { name: 'title', value: title.toString() },
                { name: 'filterDateFrom', value: filterDateFrom },
                { name: 'filterDateTo', value: filterDateTo },
                { name: 'maxItemCount', value: maxItemCount.toString() },
                { name: 'isOnlyAssignments', value: isOnlyAssignments.toString() },
            ]
        );
        return response;
    }

    /**
     * Get all files for an item (with multilanguage support when using language folders)
     * @param itemId Id og the corresponding item.
     * @param itemContext Security context enum for checking access right to item
     * @param parentTrainingPlanId Optional id of parent training plan (item was opened in a trainig plan)
     * Returns an array of files
     */
    public async getFilesForItem(itemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId: number = 0): Promise<File[] | GtError> {
        const response: File[] | GtError = await this.get<File[]>('lesson/getFilesForLesson',
            File,
            undefined,
            [
                { name: 'tpId', value: parentTrainingPlanId.toString() }
            ],
            itemId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    /**
     * Get the info.html file for the NSUI:LoginInfoDocumentSID document
     * Returns the info.html if existing
     */
    public async getLoginInfoDocument(): Promise<File | GtError> {
        const response: File | GtError = await this.get<File>('lesson/getLoginInfoDocument',
            File,
            undefined,
            undefined);
        return response;
    }

    /**
     * Gets the recommended Lessons for this ItemId. Searches items similar to this item (Theme, etc...)
     *
     * @param {string} language
     * @param {number} itemId
     * @param {number} maxItemCount
     * @returns {(Promise<Lesson[] | GtError>)}
     * @memberof LessonService
     */
    public async getRecommendedLessonByItemID(language: string, itemId: number, maxItemCount: number): Promise<Item[] | GtError> {
        const response: Item[] | GtError = await this.get<Item[]>('lesson/getRecommendedLessonsByLessonID',
            Item,
            undefined,
            [{ name: 'language', value: language }],
            itemId.toString(),
            maxItemCount.toString());
        return response;
    }

    /**
     * Gets the recommended Lessons the user by his interests.
     *
     * @param {string} language
     * @param {EERecommendedItemsByUserInterestsOrdering} orderby
     * @param {number} pageNumber
     * @param {number} pageSize
     * @returns {(Promise<Lesson[] | GtError>)}
     * @memberof LessonService
     */
    public async getRecommendedLessonByUserInterests(orderby: ERecommendedItemsByUserInterestsOrdering,
        pageNumber: number,
        pageSize: number): Promise<Item[] | GtError> {
        const response = await this.get<Item[]>('lesson/getRecommendedLessonsByUserInterests',
            Item,
            undefined,
            [{ name: 'language', value: Session.instance.getUserLanguageCodeOrFallBack }],
            orderby.toString(),
            pageNumber.toString(),
            pageSize.toString());
        return response;
    }

    /**
     * Get a Attribute by ID
     * @param attributeId Id of the corresponding attribute.
     * @param itemContext  Context of item attribute call for checking access right to item of attribute
     * @param parentTrainingPlanId Optional id of parent training plan (item was opened in a trainig plan)
     * Returns the Attribute
     */
    public async getAttribute(attributeId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<Attribute | GtError> {
        const response: Attribute | GtError = await this.get<Attribute>('lesson/attribute',
            Attribute,
            undefined,
            [
                { name: 'tpId', value: parentTrainingPlanId.toString() }
            ],
            attributeId.toString(),
            ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    /**
     * Get parameters used to start a WBT lesson.
     * IMPORTANT: The StartUrl returned is valid only for one usage and only for a short period, e.g. 30 seconds!
     * @param itemId Id of the lesson to be started
     * @param itemContext Context of item detail call
     * @param assignmentId Assignement id, if lesson is to be started in the context of an assignment - (optional)
     * @param tplanId Training plan id, if lesson is to be started in the context of a training plan - (optional)
     * @param tplanClassId Training plan class (schedule) id, if lesson is to be started in the context of a training plan - (optional)
     * @param skillId Skill id, if lesson is to be starte in context of a skill training - (optional)
     */
    public async getWbtStartParameters(itemId: number, itemContext: EItemDetailCallerContextType, assignmentId = 0, tplanId = 0, tplanClassId = 0): Promise<WbtStartParameters | GtError> {
        const queryStringParams = [
            { name: 'startButton', value: '1' },
            { name: 'assignmentId', value: assignmentId.toString() },
            { name: 'tplanId', value: tplanId.toString() },
            { name: 'tplanClassId', value: tplanClassId.toString() },
        ];
        const response: WbtStartParameters | GtError = await this.get<WbtStartParameters>('lesson/wbtStartParameters', WbtStartParameters, undefined,
            queryStringParams, itemId.toString(), ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    /**
     * Get parameters used to start a CBT lesson.
     * IMPORTANT: The StartUrl returned is valid only for one usage and only for a short period, e.g. 30 seconds!
     * @param itemId Id of the lesson to be started
     * @param startButton Nr of the Button (1,2,3)
     * @param itemContext Context of item detail call
     * @param assignmentId Assignement id, if lesson is to be started in the context of an assignment - (optional)
     * @param tplanId Training plan id, if lesson is to be started in the context of a training plan - (optional)
     * @param tplanClassId Training plan class (schedule) id, if lesson is to be started in the context of a training plan - (optional)
     * @param skillId Skill id, if lesson is to be starte in context of a skill training - (optional)
     */
    public async getCbtStartParameters(itemId: number, startButton: number, itemContext: EItemDetailCallerContextType, assignmentId = 0, tplanId = 0, tplanClassId = 0): Promise<CbtStartParameters | GtError> {
        const queryStringParams = [
            { name: 'startButton', value: startButton.toString() },
            { name: 'assignmentId', value: assignmentId.toString() },
            { name: 'tplanId', value: tplanId.toString() },
            { name: 'tplanClassId', value: tplanClassId.toString() },
        ];
        const response: CbtStartParameters | GtError = await this.get<CbtStartParameters>('lesson/cbtStartParameters', CbtStartParameters, undefined,
        queryStringParams, itemId.toString(), ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }
    /**
     * Get the properties to display the cbt start buttons
     */
    public async getCbtCommandButtons(itemId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId: number = 0): Promise<CbtCommandButton[] | GtError> {
        const queryStringParams = [
            { name: 'tplanId', value: parentTrainingPlanId.toString() },
        ];
        const response: CbtCommandButton[] | GtError = await this.get<CbtCommandButton[]>('lesson/cbtCommandButtons', CbtCommandButton, undefined,
        queryStringParams, itemId.toString(), ItemHelper.convertCallerContextToSecurityContext(itemContext).toString());
        return response;
    }

    public async getLessonsForBossToEmployeesByRightsAndItemType(itemType: number, aclRight: number, isCheckRegisterClass: boolean, bossRelationCode: string, searchText: string): Promise<Item[] | GtError> {
        const response: Item[] | GtError = await this.get<Item[]>('lesson/GetLessonsForBossToEmployeesRegister',
            Item,
            undefined,
            [{ name: 'bossRelationCode', value: bossRelationCode},
             { name: 'searchText', value: searchText}],
            itemType.toString(),
            aclRight.toString(),
            isCheckRegisterClass.toString());
        return response;
    }

    /**
     * Return all virtual meetings from this class.
     */
    public async getVirtualMeetingJoinLinks(classId: number): Promise<VirtualMeetingLink[] | GtError> {
        const response: VirtualMeetingLink[] | GtError = await this.get<VirtualMeetingLink[]>('lesson/getVirtualMeetingJoinLinks', VirtualMeetingLink, undefined, undefined, classId.toString());
        return response;
    }

    /**
     * Returns all lessons which has the requested skill as output
     */
    public async getOutputLessonsForSkill(skillId: number, userId: number, bossRelationCode?: string): Promise<Item[] | GtError> {
        const queryStringParams = [
            { name: 'language', value: Session.instance.getUserLanguageCodeOrFallBack },
            { name: 'userId', value: userId.toString() },
            { name: 'skillId', value: skillId.toString() },
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}
        ]
        const response: Item[] | GtError = await this.get<Item[]>('lesson/getOutputLessonsForSkill', Item, undefined,
            queryStringParams);
        return response
    }


    public async getMyLessonSurveys(): Promise<LessonSurvey[] | GtError> {
        const response: LessonSurvey[] | GtError = await this.get<LessonSurvey[]>('lesson/getmylessonssurveys', LessonSurvey, undefined, undefined);
        return response
    }

}