import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { ItemSummaryWatchedItem } from '$components/item/itemSummaries/ItemSummaryWatchedItem';
import { Heading } from '$components/shared/Heading';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { WatchedItemModel } from '$storage/models/WatchedItemModel';
import { RouteComponentProps } from 'react-router';

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IProps extends RouteComponentProps<{}> {
    parentHeadingLevel?: number;
}

interface IState {
    watchedItems: WatchedItemModel[] | null;
    isDataLoaded: boolean;
    errorMessage: string;
}

export class WatchedItems extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            isDataLoaded: false,
            watchedItems: null,
        };
    }

    public UNSAFE_componentWillMount() {
        this.loadData();
    }

    public render() {
        return (
            <div>
                <div className="l-box-container mylist-container">
                    <div>
                        <MenuBreadCrumb {...this.props} />
                        <div className="l-box--wide">
                            <Heading headingLevel={this.props.parentHeadingLevel != null ? (this.props.parentHeadingLevel + 1) : 1} cssClass="heading__Title">
                                <Translate>WatchedItems:Title</Translate>
                            </Heading>
                            <div>
                                <Translate>WatchedItems:SubTitle</Translate>
                            </div>
                        </div>
                        <div>
                            <ul>
                                {this.renderItems()}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public componentDidMount() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;
    }

    public renderItems(): JSX.Element | JSX.Element[] {
        const elements: JSX.Element[] = [];
        if (this.state.watchedItems != null && this.state.isDataLoaded) {
            if (this.state.watchedItems.length > 0) {
                this.state.watchedItems.map((item, index) => {
                    elements.push(
                        <ItemSummaryWatchedItem {...this.props} onRemove={() => this.onRemove()} key={index} watchedItem={item} />
                    );
                });
            } else {
                elements.push(<NoDataFound message={Session.instance.storage.translation.GetString('Watchlist:NoData')} />);
            }

        } else if (!this.state.isDataLoaded) {
            elements.push(<ProgressSpinner key={1} />);
        }
        return elements;
    }

    public onRemove() {
        this.setState({ isDataLoaded: false })
        this.loadData();
    }

    private loadData() {
        const response = Session.instance.storage.watchList.getObjectsFromCache();
        this.setState({
            errorMessage: '',
            isDataLoaded: true,
            watchedItems: response,
        })
    }
}
export default WatchedItems;