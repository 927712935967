import { ESubStatusCodes } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class SubStatusCodeConverter implements JsonCustomConvert<ESubStatusCodes> {

    public serialize(subStatusCode: ESubStatusCodes): any {
        switch (subStatusCode) {
            case ESubStatusCodes.MethodNotAllowed_NewPinNotAllowed: return 4051;
            case ESubStatusCodes.MethodNotAllowed_PWResetNotAllowed: return 4052;
            case ESubStatusCodes.MethodNotAllowed_UpdateRegistration: return 4053;
            case ESubStatusCodes.MethodNotAllowed_RequestAuthToken: return 4054;
            case ESubStatusCodes.MethodNotAllowed_ExternalCourseRegistration: return 4055;
            case ESubStatusCodes.MethodNotAllowed_UserIsNotATrainer: return 4056;
            case ESubStatusCodes.MethodNotAllowed_PWRecoveryCooldown: return 4057;
            case ESubStatusCodes.MethodNotAllowed_EmptyDocumentUpload: return 4058;
            case ESubStatusCodes.MethodNotAllowed_MFA_Cooldown: return 4059;
            case ESubStatusCodes.NotFound_SetNewPassword_UserNotFound: return 4041;
            case ESubStatusCodes.NotFound_MFA_UserNotFound: return 4042;
            case ESubStatusCodes.NotFound_MFA_NotUnique: return 4043;
            case ESubStatusCodes.InternalServerError_SetNewPassword_LinkInvalid: return 5001;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PINExpired: return 5002;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PINInvalid: return 5003;
            case ESubStatusCodes.InternalServerError_SetNewPassword_LinkExpired: return 5004;
            case ESubStatusCodes.InternalServerError_SetNewPassword_MobileNrNotFound: return 5005;
            case ESubStatusCodes.InternalServerError_SetNewPassword_EmailAddressNotUnique: return 5006;
            case ESubStatusCodes.InternalServerError_SetNewPassword_CannotChangePublicPassword: return 5007;
            case ESubStatusCodes.InternalServerError_SetNewPassword_InvalidPassword: return 5008;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsToShort: return 5009;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordHasWrongSyntax: return 50010;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsInvalid: return 50011;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsInHistory: return 50012;
            case ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsToNew: return 50013;
            case ESubStatusCodes.InternalServerError_UserCertificates_GeneralError: return 50014;
            case ESubStatusCodes.InternalServerError_DocumentFiles_GeneralError: return 50015;
            case ESubStatusCodes.InternalServerError_GlobalDescription_GeneralError: return 50016;
            case ESubStatusCodes.InternalServerError_LessonFiles_GeneralError: return 50017;
            case ESubStatusCodes.InternalServerError_RequestAuthTokens_UserIsInvalidOrLocked: return 50018;
            case ESubStatusCodes.InternalServerError_ExternalCourseProviderSidAlreadyExists: return 50019;
            case ESubStatusCodes.InternalServerError_ExternalCourseSidAlreadyExists: return 50020;
            case ESubStatusCodes.InternalServerError_ContactSystemAdmin: return 50021;
            case ESubStatusCodes.InternalServerError_Login_UserIsLocked: return 50022;
            case ESubStatusCodes.InternalServerError_Login_UserIsInvalid: return 50023;
            case ESubStatusCodes.InternalServerError_Login_UserIsInactive: return 50024;
            case ESubStatusCodes.InternalServerError_Login_LoginNotPermitted: return 50025;
            case ESubStatusCodes.InternalServerError_Login_UserNotFound: return 50026;
            case ESubStatusCodes.InternalServerError_Login_InvalidPassword: return 50027;
            case ESubStatusCodes.InternalServerError_SelfReg_MaxActiveUsersExceeded: return 50028;
            case ESubStatusCodes.InternalServerError_SelfReg_ValidationError: return 50029;
            case ESubStatusCodes.InternalServerError_LicenseRegRunOutError: return 50030;
            case ESubStatusCodes.InternalServerError_LicenseRegUsernameError: return 50031;
            case ESubStatusCodes.InternalServerError_LicenseRegEmailError: return 50032;
            case ESubStatusCodes.InternalServerError_LicenseRegWrongContractNumberError: return 50033;
            case ESubStatusCodes.NotSupported_UpdateRegistration: return 5011;

            default: return 0;
        }
    }
    public deserialize(jsonValue: number): ESubStatusCodes {
        switch (jsonValue) {
            case 4051: return ESubStatusCodes.MethodNotAllowed_NewPinNotAllowed;
            case 4052: return ESubStatusCodes.MethodNotAllowed_PWResetNotAllowed;
            case 4053: return ESubStatusCodes.MethodNotAllowed_UpdateRegistration;
            case 4054: return ESubStatusCodes.MethodNotAllowed_RequestAuthToken;
            case 4055: return ESubStatusCodes.MethodNotAllowed_ExternalCourseRegistration;
            case 4056: return ESubStatusCodes.MethodNotAllowed_UserIsNotATrainer;
            case 4057: return ESubStatusCodes.MethodNotAllowed_PWRecoveryCooldown;
            case 4058: return ESubStatusCodes.MethodNotAllowed_EmptyDocumentUpload;
            case 4059: return ESubStatusCodes.MethodNotAllowed_MFA_Cooldown;
            case 4041: return ESubStatusCodes.NotFound_SetNewPassword_UserNotFound;
            case 4042: return ESubStatusCodes.NotFound_MFA_UserNotFound;
            case 4043: return ESubStatusCodes.NotFound_MFA_NotUnique;
            case 5001: return ESubStatusCodes.InternalServerError_SetNewPassword_LinkInvalid;
            case 5002: return ESubStatusCodes.InternalServerError_SetNewPassword_PINExpired;
            case 5003: return ESubStatusCodes.InternalServerError_SetNewPassword_PINInvalid;
            case 5004: return ESubStatusCodes.InternalServerError_SetNewPassword_LinkExpired;
            case 5005: return ESubStatusCodes.InternalServerError_SetNewPassword_MobileNrNotFound;
            case 5006: return ESubStatusCodes.InternalServerError_SetNewPassword_EmailAddressNotUnique;
            case 5007: return ESubStatusCodes.InternalServerError_SetNewPassword_CannotChangePublicPassword;
            case 5008: return ESubStatusCodes.InternalServerError_SetNewPassword_InvalidPassword;
            case 5009: return ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsToShort;
            case 50010: return ESubStatusCodes.InternalServerError_SetNewPassword_PasswordHasWrongSyntax;
            case 50011: return ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsInvalid;
            case 50012: return ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsInHistory;
            case 50013: return ESubStatusCodes.InternalServerError_SetNewPassword_PasswordIsToNew;
            case 50014: return ESubStatusCodes.InternalServerError_UserCertificates_GeneralError;
            case 50015: return ESubStatusCodes.InternalServerError_DocumentFiles_GeneralError;
            case 50016: return ESubStatusCodes.InternalServerError_GlobalDescription_GeneralError;
            case 50017: return ESubStatusCodes.InternalServerError_LessonFiles_GeneralError;
            case 50018: return ESubStatusCodes.InternalServerError_RequestAuthTokens_UserIsInvalidOrLocked;
            case 50019: return ESubStatusCodes.InternalServerError_ExternalCourseProviderSidAlreadyExists;
            case 50020: return ESubStatusCodes.InternalServerError_ExternalCourseSidAlreadyExists;
            case 50021: return ESubStatusCodes.InternalServerError_ContactSystemAdmin;
            case 50022: return ESubStatusCodes.InternalServerError_Login_UserIsLocked; 
            case 50023: return ESubStatusCodes.InternalServerError_Login_UserIsInvalid;
            case 50024: return ESubStatusCodes.InternalServerError_Login_UserIsInactive;
            case 50025: return ESubStatusCodes.InternalServerError_Login_LoginNotPermitted ;
            case 50026: return ESubStatusCodes.InternalServerError_Login_UserNotFound;
            case 50027: return ESubStatusCodes.InternalServerError_Login_InvalidPassword;
            case 50028: return ESubStatusCodes.InternalServerError_SelfReg_MaxActiveUsersExceeded;
            case 50029: return ESubStatusCodes.InternalServerError_SelfReg_ValidationError;
            case 50030: return ESubStatusCodes.InternalServerError_LicenseRegRunOutError;
            case 50031: return ESubStatusCodes.InternalServerError_LicenseRegUsernameError;
            case 50032: return ESubStatusCodes.InternalServerError_LicenseRegEmailError;
            case 50033: return ESubStatusCodes.InternalServerError_LicenseRegWrongContractNumberError;
            case 5011: return ESubStatusCodes.NotSupported_UpdateRegistration;


            default: return ESubStatusCodes.Unknown;
        }
    }
}
