import { EDocumentType } from '$storage/models/enums';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export class DocumentTypeConverter implements JsonCustomConvert<EDocumentType> {
    public serialize(documentType: EDocumentType): number {
        switch(documentType) {
            case EDocumentType.WithoutRestriction: return 0;
            case EDocumentType.RegistrationRequestedOrAccepted: return 1;
            case EDocumentType.RegistrationAccepted: return 2;
            case EDocumentType.ClassTakesPlace : return 3;
            case EDocumentType.ClassAfterRunningPhase: return 4;
            case EDocumentType.GeneralTermsAndConditions: return 5;
            case EDocumentType.OnlyForAdministrators: return 6;
            case EDocumentType.Custom: return 100;
            default: return 0;
        }
    }

    public deserialize(jsonValue: number): EDocumentType {
        switch (jsonValue) {
            case 0: return EDocumentType.WithoutRestriction;
            case 1: return EDocumentType.RegistrationRequestedOrAccepted;
            case 2: return EDocumentType.RegistrationAccepted;
            case 3: return EDocumentType.ClassTakesPlace;
            case 4: return EDocumentType.ClassAfterRunningPhase;
            case 5: return EDocumentType.GeneralTermsAndConditions;
            case 6: return EDocumentType.OnlyForAdministrators;
            case 100: return EDocumentType.Custom;
            default: return EDocumentType.WithoutRestriction;
        }
    }
}
