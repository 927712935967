import { MultiSelect, MultiSelectFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import { Translate } from "$components/shared/Translate";
import { UserInterests } from "$src/storage/models/UserInterests/UserInterests"
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { filterBy } from "@progress/kendo-data-query";
import { MultiSelectCheckboxItemRender } from "$components/shared/Atoms/MultiSelectCheckboxItemRender";

interface IProps {
    userInterests: Array<UserInterests>;
    orderNumber: number;
    subjectsData: Array<AttributeValue>;
    onItemsDelete: (userInterest: UserInterests[]) => Promise<void>;
    onItemInsert: (idAttributeValue: number, orderNumber: number) => Promise<void>;
}

export default function UserInterestsAdministrationSubject(props: IProps) {

    const [filteredSubjectData, setFilteredSubjectData] = useState<Array<AttributeValue>>(props.subjectsData);

    const filterData = (e: MultiSelectFilterChangeEvent): void => {
        const i = props.subjectsData;
        setFilteredSubjectData(filterBy(i, e.filter));
    }

    const onSelectionChanged = (values: Array<AttributeValue>, orderNumber: number): void => {
        const oldSubjects = props.subjectsData.filter(s => props.userInterests.findIndex(u => u.idAttributeValue === s.id && u.orderNumber === orderNumber) !== -1);
        const itemsToDelete = oldSubjects.filter(oldSubject => values.findIndex(newSubjects => newSubjects.id === oldSubject.id) === -1);
        const itemsToAdd = values.filter(newSubject => oldSubjects.findIndex(oldSubject => oldSubject.id === newSubject.id) === -1);
        const deleteUserInterestsItems: UserInterests[] = [];
        itemsToDelete.map((deleteItem) => {
            const deleteUserInterestsItem = props.userInterests.find(s => s.idAttributeValue === deleteItem.id);
            if (deleteUserInterestsItem != null) deleteUserInterestsItems.push(deleteUserInterestsItem);
        });
        if (deleteUserInterestsItems.length > 0) {
            props.onItemsDelete(deleteUserInterestsItems);
        }
        itemsToAdd.map(async (addSubject) => {
            await props.onItemInsert(addSubject.id, orderNumber);
        });
    }

    return <div className="user-interests__setting">
        <div className="user-interests__settings__label">
            <label id={`lbuserinterests_subjects${props.orderNumber}`}>
                <Translate>{`UserInterests:SubjectFilter${props.orderNumber}`}</Translate>
            </label>
        </div>
        <div className="user-interests__settings__value">
            <MultiSelect
                className="user-interests__settings__value__multi-select"
                itemRender={MultiSelectCheckboxItemRender}
                data={filteredSubjectData}
                // data={subjectsData}
                onChange={(e) => { onSelectionChanged(e.target.value as AttributeValue[], props.orderNumber) }}
                value={
                    props.subjectsData.filter(
                        s => props.userInterests != null &&
                            props.userInterests.length > 0 &&
                            props.userInterests.findIndex(u => u.idAttributeValue === s.id && u.orderNumber === props.orderNumber) !== -1)}
                textField="text"
                dataItemKey="id"

                filterable={true}
                onFilterChange={(e) => { filterData(e) }}
            />
        </div>
    </div>

}