import React from 'react';
import { ECancellationPermission, EClassStatus, EItemDetailCallerContextType, EItemSubType, EItemType, ELessonStatus, ELifeCycle, ERegistrationStatus } from '$storage/models/enums';
import '$util/stringExtensions';
import { RouteComponentProps } from 'react-router';
import { LessonUser } from '$src/storage/models/LessonUser';
import { ItemHelper } from '$src/util/ItemHelper';
import { StringHelper } from '$src/util/StringHelper';
import Session from '$src/core/Session';
import InlineSVG from 'react-inlinesvg';
import InfoIcon from '$resources/svgs/misc/info.svg';
import CancelIcon from '$resources/svgs/misc/cancel.svg';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';
import { Translate } from '$src/components/shared/Translate';

interface IProps extends RouteComponentProps<{}> {
    lessons: LessonUser[];
    itemDetailCallerContextType: EItemDetailCallerContextType; // The type of the caller
    parentCatalogFolderId: number;
    parentTrainingPlanId: number;
    bossRelationCode: string;

    openAnswerModal: (registrationId: number, lessonId: number, lessonTitle: string, cancellationType: ECancellationPermission, itemType: EItemType, classId: number, selectedEmployeeId: number) => void;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class BookingOverviewTable extends React.Component<IProps, {}> {
    protected className = 'BookingOverviewTable';
    private TRANSLATION_PREFIX = 'BookingOverviewTable';
    protected loggerLocality = 'Components.BookingOverviewTable';


    constructor(props: IProps) {
        super(props);
    }

    public render() {
        return (
            <div className="l-container booking-overview__container" >
                {this.renderBookings()}
            </div>
        )
    }

    private renderBookings = (): JSX.Element | null => {
        if (this.props.lessons !== undefined) {
            return (
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup">
                        {this.renderTableHeader()}
                    </div>
                    <div role="rowgroup">
                        {this.renderBookingRows(this.props.lessons)}
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

    private renderTableHeader() {
        const tableHeader: JSX.Element = (
            <div role="row" className="div-table__horizontal-table-row zebra">
                <div role="columnheader" className="notMobile">
                    <Translate>BookingOverviewTable:NameHeader</Translate>
                </div>
                <div role="columnheader" className="notMobile">
                    <Translate>BookingOverviewTable:LessonHeader</Translate>
                </div>
                <div role="columnheader" className="notMobile">
                    <Translate>BookingOverviewTable:BookingDates</Translate>
                </div>
                <div role="columnheader" className="notMobile">
                    <Translate>BookingOverviewTable:Status</Translate>
                </div>
                <div role="columnheader" className="notMobile small-cell">
                    {''}
                </div>
                <div role="columnheader" className="notMobile small-cell">
                    {''}
                </div>
            </div>
        );
        return tableHeader
    }

    private renderBookingRows(lessons: LessonUser[]): JSX.Element[] {
        const tableRows: JSX.Element[] = [];
        if (lessons != null) {
            lessons.map((lesson, index) => {
                tableRows.push(this.renderTableRow(index, lesson));
            });
        }

        return tableRows;
    }

    private renderTableRow(index: number, lesson: LessonUser): JSX.Element {
        const title = lesson.title != null ? lesson.title.Ellipsis(globalConfig.itemProperties.maxTitleLengthInItemList) : '';

        let bookingDates = '';
        const startDate = lesson.displayStartDate;
        const endDate = lesson.displayEndDate;
        if (startDate && endDate) {
            bookingDates = `${StringHelper.dateString(startDate)} - ${StringHelper.dateString(endDate)}`;
        }

        const lessonStatus = this.GetStatusAsString(lesson);
        const navLink = `${this.getLink(lesson)}&bossRelationCode=${this.props.bossRelationCode}`;

        const GtInfoButtonId = `GTInfoButton_${index}`;
        const GtCancelButtonId = `GTCancelButton_${index}`;

        let tooltipText = this.getTranslation("Info").replace("{title}", title);
        if(lesson.lifeCycle != ELifeCycle.Production) {
            tooltipText = this.getTranslation("Info_NotProduction");
        }

        return (
            <div key={index} role="row" className="div-table__horizontal-table-row zebra">
                <div role="columnheader" className="mobileOnly">
                    <Translate>BookingOverviewTable:NameHeader</Translate>
                </div>
                <div role="cell">
                    {lesson.userName}
                </div>
                <div role="columnheader" className="mobileOnly">
                    <Translate>BookingOverviewTable:LessonHeader</Translate>
                </div>
                <div role="cell">
                    {title}
                </div> 
                <div role="columnheader" className="mobileOnly">
                    <Translate>BookingOverviewTable:BookingDates</Translate>
                </div>
                <div role="cell">
                    {bookingDates}
                </div>
                <div role="columnheader" className="mobileOnly">
                    <Translate>BookingOverviewTable:Status</Translate>
                </div>
                <div role="cell">
                    {lessonStatus}
                </div>
                <div role="columnheader" className="mobileOnly">
                    {''}
                </div>
                <div role="cell" className="small-cell">
                    {this.allowCancellation(lesson) ?
                        <GTIconButton
                            id={GtCancelButtonId}
                            ariaLabel={this.getTranslation("CancelRegistration")}
                            tooltipText={this.getTranslation("CancelRegistration")}
                            onClick={() => this.onAnswerClick(lesson)}>
                            <InlineSVG src={CancelIcon} />
                        </GTIconButton>
                        : null
                    }
                </div>
                <div role="columnheader" className="mobileOnly">
                    {''}
                </div>
                <div role="cell" className="small-cell">
                    <GTIconButton
                        id={GtInfoButtonId}
                        ariaLabel={tooltipText}
                        tooltipText={tooltipText}
                        onClick={() => this.props.history.push(navLink)}
                        disabled={lesson.lifeCycle != ELifeCycle.Production}>
                        <InlineSVG src={InfoIcon} />
                    </GTIconButton>
                </div>
            </div>
        );
    }

    private allowCancellation(lesson: LessonUser) {
        // Check if a registration exists
        if (lesson != null &&
            lesson.registrationId != null &&
            lesson.registrationStatus != null &&
            ItemHelper.isCancellationAllowed(lesson.cancellationDate1, lesson.cancellationDate2) != ECancellationPermission.NotAllowed &&
            lesson.classStatus !== EClassStatus.Closed) {
            // Has registration for this class
            if (lesson.registrationStatus !== ERegistrationStatus.Cancelled &&
                lesson.registrationStatus !== ERegistrationStatus.Rejected &&
                lesson.registrationStatus !== ERegistrationStatus.ScheduleCancelled &&
                lesson.registrationStatus !== ERegistrationStatus.NotRegistered) {
                return true;
            }
        }
        return false
    }

    private GetStatusAsString(item: LessonUser): string {
        let statusText = '';
        if ((item.itemType === EItemType.F2FCourse || item.itemType === EItemType.TrainingPlan || item.registrationRequiredEnabled) &&
            (item.lessonStatus === ELessonStatus.Undefined || item.lessonStatus === ELessonStatus.NotAttempted)) {

            switch (item.registrationStatus) {
                case ERegistrationStatus.Requested:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Requested');
                    break;
                case ERegistrationStatus.Accepted:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Accepted');
                    break;
                case ERegistrationStatus.Rejected:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Rejected');
                    break;
                case ERegistrationStatus.Cancelled:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Cancelled');
                    break;
                case ERegistrationStatus.ScheduleCancelled:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:ScheduleCancelled');
                    break;
                case ERegistrationStatus.InWaitingList:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:InWaitingList');
                    break;
                default:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:NotRegistered');
                    break;
            }
        } else {
            switch (item.lessonStatus) {
                case ELessonStatus.Incomplete:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Incomplete');
                    break;
                case ELessonStatus.Completed:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Completed');
                    break;
                case ELessonStatus.Failed:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Failed');
                    break;
                case ELessonStatus.Passed:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Passed');
                    break;                
                case ELessonStatus.NotAttempted:
                default:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:NotAttempted');
                    break;
            }
        }
        return statusText;
    }

    protected getLink(lesson: LessonUser): string {
        const id = lesson.itemId;
        const parentAssignmentId = 0;

        if (lesson.itemType === EItemType.TrainingPlan) {
            return ItemHelper.getTrainingPlanDetailLink(id,
                this.props.itemDetailCallerContextType,
                this.props.parentCatalogFolderId,
                parentAssignmentId)
        }
        if (lesson.itemType === EItemType.F2FCourse
            && lesson.itemSubType === EItemSubType.External
            && this.props.itemDetailCallerContextType !== EItemDetailCallerContextType.Catalog) {
            return `/requestDetail/${lesson.registrationId}?context=${this.props.itemDetailCallerContextType}&itemSubType=${lesson.itemSubType}`;
        }
        return ItemHelper.getItemDetailLink(id,
            this.props.itemDetailCallerContextType,
            this.props.parentCatalogFolderId,
            parentAssignmentId,
            this.props.parentTrainingPlanId,
            0)
    }

    private onAnswerClick(lesson: LessonUser) {
        if (this.props.openAnswerModal) {
            this.props.openAnswerModal(lesson.registrationId, 
                lesson.itemId, 
                lesson.title, 
                ItemHelper.isCancellationAllowed(lesson.cancellationDate1, lesson.cancellationDate2), 
                lesson.itemType,
                lesson.classId,
                lesson.userId);
        }
    }

    private getTranslation = (str: string) => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }
}