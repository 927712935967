import React from 'react';
import { RouteComponentProps } from 'react-router';

import { ToolbarRegistrationStatus } from '$components/item/Toolbar/ToolbarRegistrationStatus';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { EAvailablePlaces, EClassStatus, EItemDetailCallerContextType, ERegistrationStatus } from '$storage/models/enums';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanSchedule } from '$storage/models/TrainingPlan/TrainingPlanSchedule';
import DateHelper from '$util/DateHelper';
import { ItemHelper } from '$util/ItemHelper';
import { StringHelper } from '$src/util/StringHelper';

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
interface IProps extends RouteComponentProps<{}> {
    trainingPlanSchedules: TrainingPlanSchedule[];
    trainingPlan: TrainingPlan;
    itemDetailCallerContextType: EItemDetailCallerContextType; // The type of the caller
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    voucherCode: string | null;
}

interface IState {
    trainingPlanSchedules: TrainingPlanSchedule[];
    errorMessage: string;
}

export class TrainingPlanScheduleList extends React.Component<IProps, IState> {
    protected _TPlanProperties = globalConfig.trainingPlanProperties;

    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessage: '',
            trainingPlanSchedules: this.props.trainingPlanSchedules,
        }
    }

    public render() {
        let warning = '';
        if (this.props.trainingPlan != null) {
            if (!this.props.trainingPlan.InputSkillsFulfilled) {
                warning = 'TrainingPlan:InputSkillsNotFulfilled';
            } else if (this.props.trainingPlan.isLockedDueToAssignments) {
                warning = 'TrainingPlan:LockedDueToAssignments';
            } else if (this.props.trainingPlan.isLocked) {
                warning = 'TrainingPlan:RegistrationNotAllowed';
            }
        }

        if (this.state.trainingPlanSchedules != null && this.state.trainingPlanSchedules.length > 0) {
            return <React.Fragment>
                <span className={'input-message error'}>
                    <Translate>{warning}</Translate>
                </span>
                <div role="table" className="tp__registration-scheduleList">
                    <div role="rowgroup">
                        <div role="row" className="tp__registration-scheduleList-row">
                            {this._TPlanProperties.tplanClassColumns?.slice(0, 5).map((c, index) => {
                                return (
                                    <div key={`labelKey_${c.label}-${index}`}
                                        role="columnheader"
                                        className={`notMobile ${c.cssClass ? c.cssClass : ''}`}>
                                        <Translate>{c.label}</Translate>
                                    </div>
                                )
                            })}
                            <div role="columnheader" className="notMobile">
                                <Translate>TrainingPlan:ScheduleRegistrationStatus</Translate>
                            </div>
                            <div role="columnheader" className="notMobile">
                                &nbsp;
                            </div>
                        </div>

                    </div>
                    <div role="rowgroup">
                        {this.renderSchedules()}
                    </div>
                </div>
            </React.Fragment>
        }
        else {
            return <React.Fragment>
                <NoDataFound message={Session.instance.storage.translation.GetString('TrainingPlan:NoSchedules')} />
            </React.Fragment>
        }
    }


    protected getDateInRow(date: Date | undefined): JSX.Element {
        if (date) {
            return (
                <React.Fragment>
                    {StringHelper.dateString(date)}
                </React.Fragment>
            )
        } else {
            return <React.Fragment />
        }
    }

    protected renderRow(property: string, schedule: TrainingPlanSchedule, available: EAvailablePlaces) {
        switch (property.toLocaleLowerCase()) {
            case 'scheduletitle':
                return schedule.title;
            case 'schedulecode':
                return schedule.code;
            case 'learningperiod':
                return schedule.runningBegin != null && schedule.runningEnd != null ?
                    (DateHelper.getDatePeriod(schedule.runningBegin, schedule.runningEnd))
                    : ('');
            case 'availableplaces':
                return this.renderAvailablePlaces(available, schedule.availablePlaces);
            case 'description':
                return schedule.description;
            case 'cancellationdate1':
                return this.getDateInRow(schedule.cancellationDate1);
            case 'cancellationdate2':
                return this.getDateInRow(schedule.cancellationDate2);
            case 'price':
                return (<React.Fragment>{schedule.price + ' ' + schedule.currency}</React.Fragment>);
            case 'registrationbegin':
                return this.getDateInRow(schedule.registrationBegin);
            case 'registrationend':
                return this.getDateInRow(schedule.registrationEnd);
            case 'registrationperiod':
                return schedule.registrationBegin != null && schedule.registrationEnd != null ?
                (DateHelper.getDatePeriod(schedule.registrationBegin, schedule.registrationEnd))
                : ('');
            default:
                return (<React.Fragment>{property}</React.Fragment>);
        }
    }

    protected renderSchedules(): JSX.Element[] | JSX.Element {
        const elements: JSX.Element[] = [];
        this.getFilteredClasses().map(
            (schedule, index) => {
                const available: EAvailablePlaces = this.getAvailabilityOfClassStatus(schedule.maxRegistrations, schedule.availablePlaces || 0, schedule.waitingListMode);
                elements.push(
                    <div key={index} role="row" className="tp__registration-scheduleList-row">
                        {this._TPlanProperties.tplanClassColumns?.slice(0, 5).map((c, index) =>
                            <React.Fragment key={`data_${c.property}_${index}`}>
                                <div role="columnheader" className="mobileOnly">
                                    <Translate>{c.label}</Translate>
                                </div>
                                <div role="cell" className={c.cssClass}>
                                    {this.renderRow(c.property, schedule, available)}
                                </div>
                            </React.Fragment>

                        )}

                        <div role="columnheader" className="mobileOnly">
                            <Translate>TrainingPlan:ScheduleRegistrationStatus</Translate>
                        </div>
                        <div role="cell">
                            <ToolbarRegistrationStatus
                                item={this.props.trainingPlan}
                                classId={schedule.sceId}
                                textVisible={true}
                                classTitle={schedule.code}
                                hasRegisterRight={schedule.hasRegistrationRight}
                                canSelfRegister={Session.instance.loginUser != null && Session.instance.loginUser.canSelfRegisterToTP}
                                isInRegistrationPeriod={schedule.isInRegistrationPeriod}
                                checkingDateOfStartCondition={schedule.checkingDateOfStartCondition} />
                        </div>

                        <div role="cell">
                            <button type="button"
                                className="btn--sm btn--primary "
                                onClick={() => this.onScheduleDetailClick(schedule.sceId, schedule.itemId)}>
                                {(this.getClassRegistrationStatus(schedule, available))}
                            </button>
                        </div>
                    </div>
                )
            });
        return elements;
    }

    // Returns only the classes the user can register for or the user already registered
    protected getFilteredClasses(): TrainingPlanSchedule[] {
        const filteredClasses: TrainingPlanSchedule[] = [];
        this.state.trainingPlanSchedules.map((sce) => {
            if (sce.isUserAlreadyRegistered() ||
                // Check if user can register 
                (sce.isInRegistrationPeriod
                    // If the flags are set, show classes which are not in their registration period
                    || (this._TPlanProperties.ignoreClassRegistrationPhaseEnd && sce.registrationEnd && sce.registrationEnd.getTime() < Date.now() && sce.runningEnd && Date.now() < sce.runningEnd.getTime())
                    || (this._TPlanProperties.ignoreClassRegistrationPhaseStart && sce.registrationBegin && sce.registrationBegin.getTime() < Date.now() && sce.runningEnd && Date.now() < sce.runningEnd.getTime())
                    // If the flag ignoreClassRegisterRight is set in the config, ignore if the user has registration right or not
                    && (this._TPlanProperties.ignoreClassRegisterRight || sce.hasRegistrationRight))
            ) {
                filteredClasses.push(sce);
            }
        });
        return filteredClasses;
    }


    protected getClassRegistrationStatus(schedule: TrainingPlanSchedule, available: EAvailablePlaces): JSX.Element {
        const regStatus = this.props.trainingPlan.registrationStatus;

        const registeredClassId = this.props.trainingPlan.registeredClassId;
        // Bezeichnet den Button mit Details, wenn entweder der Klassenstatus eine Anmeldung nicht ermöglicht oder der User bereits für eine Klasse angemeldet ist.
        if (available === EAvailablePlaces.NoPlaces
            || schedule.status === EClassStatus.Cancelled
            || schedule.status === EClassStatus.Closed
            || this.props.trainingPlan.isLockedDueToRequiredSkills &&
            !(registeredClassId === schedule.sceId && (regStatus === ERegistrationStatus.Accepted
                || regStatus === ERegistrationStatus.Requested || regStatus === ERegistrationStatus.InWaitingList))
            || (registeredClassId !== schedule.sceId && regStatus === ERegistrationStatus.Accepted)
            || (registeredClassId !== schedule.sceId && regStatus === ERegistrationStatus.Requested)
            || (registeredClassId !== schedule.sceId && regStatus === ERegistrationStatus.InWaitingList)
            || (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfUnregisterFromTP)
            || !schedule.isInRegistrationPeriod
            || !schedule.hasRegistrationRight) {
            return <Translate>TrainingPlan:ShowDetails</Translate>
        }
        // Wenn der User für eine Klasse angemeldet ist kann er die Anmeldung stornieren
        else if ((registeredClassId === schedule.sceId && regStatus === ERegistrationStatus.Accepted)
            || (registeredClassId === schedule.sceId && regStatus === ERegistrationStatus.Requested)
            || (registeredClassId === schedule.sceId && regStatus === ERegistrationStatus.InWaitingList)) {
            return <Translate>TrainingPlan:ShowDetailsCancellation</Translate>
        }
        // Wenn der User noch nicht für eine Klasse angemeldet ist und es den Klassenstatus erlaubt, kann er sich anmelden.
        else {
            if ((Session.instance.loginUser != null && !Session.instance.loginUser.canSelfRegisterToTP)) {
                return <Translate>TrainingPlan:ShowDetails</Translate>
            } else {
                return <Translate>TrainingPlan:ShowScheduleDetails</Translate>
            }
        }
    }

    protected getAvailabilityOfClassStatus(maxParticipants: number, freePlaces: number, waitingListMode: number): EAvailablePlaces {
        let availability: EAvailablePlaces;
        if (maxParticipants === 0) {
            availability = EAvailablePlaces.NoParticpantsLimit
        } else if (freePlaces <= 0 && waitingListMode <= 0) {
            availability = EAvailablePlaces.NoPlaces
        } else if (freePlaces <= 0 && waitingListMode > 0) {
            availability = EAvailablePlaces.WaitingList
        } else {
            if (freePlaces === 1) {
                availability = EAvailablePlaces.OneFreePlace
            }
            else {
                availability = EAvailablePlaces.FreePlaces
            }
        }
        return availability;
    }

    protected renderAvailablePlaces(available: EAvailablePlaces, freePlaces?: number): JSX.Element {
        let text;
        const tr = Session.instance.storage.translation;
        if (available === EAvailablePlaces.FreePlaces && freePlaces !== undefined) {
            text = tr.GetString('Course:FreePlaces').Format(freePlaces.toString());
        }
        else if (available === EAvailablePlaces.OneFreePlace && freePlaces !== undefined) {
            text = tr.GetString('Course:OneFreePlace').Format(freePlaces.toString());
        }
        else {
            text = Session.instance.storage.translation.GetString('Course:' + EAvailablePlaces[available].toString());
        }
        return <p>{text}</p>;
    }

    protected onScheduleDetailClick(sceId: number, tplanId: number): void {
        this.props.history.push(
            ItemHelper.getTrainingPlanScheduleRegistrationLink(tplanId,
                sceId,
                this.props.itemDetailCallerContextType,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId,
                this.props.voucherCode)
        );
    }
}
