import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from './converters/DateTimeConverter';
import { ItemSubTypeConverter } from './converters/ItemSubTypeConverter';
import { ItemTypeConverter } from './converters/ItemTypeConverter';
import { LessonStatusConverter } from './converters/LessonStatusConverter';
import { EItemSubType, EItemType, ELessonStatus } from './enums';

/**
 * This Model is used for Lessonsurveys.
 */
@JsonObject
export class LessonSurvey {

    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('itemTitle', String, false)
    public itemTitle: string = '';

    @JsonProperty('itemTypeId', Number, false)
    public itemTypeId: number = 0;

    @JsonProperty('itemSubTypeId', Number, false)
    public itemSubTypeid: number = 0;

    /** Type of the item. */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType = EItemType.Undefined;

    /** Sub type of the item. */
    @JsonProperty('itemSubType', ItemSubTypeConverter, false)
    public itemSubType: EItemSubType = EItemSubType.Undefined;

      /** Current user's lesson status. */
      @JsonProperty('lessonStatus', LessonStatusConverter, false)
      public lessonStatus: ELessonStatus = ELessonStatus.NotAttempted;

    @JsonProperty('registrationStatus', String, false)
    public registrationStatus: string = '';

    @JsonProperty('lessonStatusID', String, false)
    public lessonStatusID: string = '';

    @JsonProperty('surveyItemId', Number, false)
    public surveyItemId: number = 0;

    @JsonProperty('surveyTypeId', Number, false)
    public surveyTypeId: number = 0;

    @JsonProperty('surveyTypeCode', String, false)
    public surveyTypeCode: string = '';

    @JsonProperty('surveyTitle', String, false)
    public surveyTitle: string = '';

    @JsonProperty('startDate', DateTimeConverter, false)
    public startDate: Date | null = new Date();

    @JsonProperty('endDate', DateTimeConverter, false)
    public endDate: Date | null = new Date();
}
