import React from 'react';
import { UnmountClosed } from 'react-collapse';
import { RouteComponentProps } from 'react-router';

import { F2FDocuments } from '$components/item/F2F/F2FDocuments';
import { ToolbarRegistrationStatus } from '$components/item/Toolbar/ToolbarRegistrationStatus';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Logger from '$core/Logger';
import Session from '$core/Session';
import { EAvailablePlaces, EClassStatus, EItemDetailCallerContextType, ERegistrationStatus } from '$storage/models/enums';
import { F2FClassDetail } from '$storage/models/F2F/F2FClassDetail';
import { F2FDocumentList } from '$storage/models/F2F/F2FDocumentList';
import { Room } from '$storage/models/F2F/Room';
import { Item } from '$storage/models/Item';
import { Registration } from '$storage/models/Registration';
import { ItemHelper } from '$util/ItemHelper';
import { isSuccess } from '$util/Result';
import { StringHelper } from '$util/StringHelper';
import { Trainer } from '$storage/models/F2F/Trainer';
import { CollaborationProviderItemPanel } from '$components/collaborationProvider/item/CollaborationProviderItemPanel';

interface IProps extends RouteComponentProps<{}> {
    item: Item;
    parentHeadingLevel: number;
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number;
    voucherCode?: string | null;
}

interface IState extends ICollabsibleState {
    classDetailList: F2FClassDetail[] | null;
    f2fDocuments: F2FDocumentList | null;
}

interface ICollabsibleState {
    documentsCollapsed: boolean;
    schedulesCollapsed: boolean;
    virtualMeetingsCollapsed: boolean;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export class F2FCourseDetails extends React.Component<IProps, IState> {
    protected className = 'F2FCourseDetails';
    protected loggerLocality = 'Components.F2FCourseDetails';

    protected _itemConfig = globalConfig.itemProperties;
    protected _f2fProperties = globalConfig.f2fProperties;

    constructor(props: IProps) {
        super(props);
        this.state = {
            classDetailList: null,
            documentsCollapsed: false,
            f2fDocuments: null,
            schedulesCollapsed: false,
            virtualMeetingsCollapsed: false
        }
    }

    public async componentDidMount() {
        const methodName = `${this.className}:componentDidMount()`;
        const f2fDocuments = await Session.instance.storage.f2fDocuments.getF2FDocuments(0, this.props.item.itemId, 0, this.props.itemDetailCallerContextType, this.props.parentTrainingPlanId);
        if (isSuccess<F2FDocumentList | null>(f2fDocuments)) {
            this.setState({ f2fDocuments });
        }
        else {
            const errorMessage = `${methodName} failed to load f2fdocuments for course item id ${this.props.item.itemId}. Exception: ${f2fDocuments.message} `
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);
        }
    }

    public render() {
        const currentReg = this.getAcceptedRegistration(this.props.item);
        if (this.state.classDetailList === null) {
            this.getClassDetails(this.props.item.itemId, this.props.itemDetailCallerContextType, this.props.parentTrainingPlanId);
            return <ProgressSpinner />
        } else {
            return (
                <React.Fragment>
                    <div className="l-element--striped">
                        <div className="l-container">
                            <HeadingCollapsible
                                headingLevel={this.props.parentHeadingLevel + 1}
                                containerCssClass=""
                                headingCssClass="heading__Level2"
                                isOpened={!this.state.schedulesCollapsed}
                                onToggleOpenedState={() => this.setState({ schedulesCollapsed: !this.state.schedulesCollapsed })}>
                                <Translate>Course:Schedules</Translate>
                            </HeadingCollapsible>
                            <UnmountClosed isOpened={!this.state.schedulesCollapsed}>
                                {this.renderSchedulesTable()}
                            </UnmountClosed>
                        </div>
                    </div>

                    {// Render CollaborationProviderPanel (Taking first registered class)
                     this.props.item != null &&
                     this.state.classDetailList != null &&
                     currentReg != null &&
                     <CollaborationProviderItemPanel
                     itemId={this.props.item.itemId}
                     classId={currentReg.classId}
                     panelTitle="Item:CollaborationProviderLinks" />
                     }

                    {this.state.f2fDocuments != null &&
                        this.state.f2fDocuments.allowedDocuments != null &&
                        this.state.f2fDocuments.allowedDocuments.length > 0 ?
                        (<div className="l-element--striped">
                            <div className="l-container">
                                <HeadingCollapsible
                                    headingLevel={this.props.parentHeadingLevel + 1}
                                    containerCssClass=""
                                    headingCssClass="heading__Level2"
                                    isOpened={!this.state.documentsCollapsed}
                                    onToggleOpenedState={() => this.setState({ documentsCollapsed: !this.state.documentsCollapsed })}>
                                    <Translate>Course:F2FDocuments</Translate>
                                </HeadingCollapsible>
                                <UnmountClosed isOpened={!this.state.documentsCollapsed}>
                                    <F2FDocuments
                                        f2fDocuments={this.state.f2fDocuments} />
                                </UnmountClosed>
                            </div>
                        </div>) : <React.Fragment />
                    }

                </React.Fragment >
            )
        }
    }

    protected renderNoSchedules() {
        return (
            <React.Fragment>
                <NoDataFound message={Session.instance.storage.translation.GetString('Course:NoSchedules')} />
            </React.Fragment>
        );
    }

    protected renderSchedulesTable() {
        if (this.state.classDetailList === null || this.state.classDetailList.length <= 0) {
            return this.renderNoSchedules();
        } else {
            return this.renderSchedulesTab();
        }
    }

    protected renderSchedulesTab() {
        if (!this.state.classDetailList) {
            return null;
        }

        // check if there are any classes do display
        if (this.getFilteredClasses().length <= 0) {
            return this.renderNoSchedules();
        } else {
            return (
                <React.Fragment>
                    <div role="table" className="f2f__courseDetail-table">
                        <div role="rowgroup">
                            <div role="row" className="f2f__courseDetail-table-row">
                                {this._f2fProperties.f2fClassColumns.slice(0, 5).map((c, index) => {
                                    return (
                                        <div key={`labelKey_${c.label}-${index}`}
                                            role="columnheader"
                                            className={`notMobile ${c.cssClass ? c.cssClass : ''}`}>
                                            <Translate>{c.label}</Translate>
                                        </div>
                                    )
                                })}

                                <div role="columnheader"
                                    className="notMobile f2f__courseDetail-table-doublecell">
                                    <Translate>Course:ClassRegistrationStatus</Translate>
                                </div>
                            </div>
                        </div>
                        <div role="rowgroup">
                            {this.renderClasses()}
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }

    protected renderClasses() {
        const elements: JSX.Element[] = [];
        const classes: F2FClassDetail[] = this.getFilteredClasses();
        if (this.state.classDetailList != null) {
            classes.map((cls, index) => {
                {
                    const available: EAvailablePlaces = this.getAvailabilityOfClassStatus(cls.maxParticipants, cls.freePlaces || 0, cls.hasWaitingList);
                    const ariaLabel = Session.instance.storage.translation.GetString('TrainingPlan:ShowScheduleDetails')
                        + ' '
                        + cls.classTitle
                    elements.push(
                        <div key={index} role="row" className="f2f__courseDetail-table-row">
                            {this._f2fProperties.f2fClassColumns.slice(0, 5).map((c, index) => {
                                return (
                                    <React.Fragment key={`data_${c.property}_${index}`}>
                                        <div role="columnheader" className="mobileOnly">
                                            <Translate>{c.label}</Translate>
                                        </div>
                                        <div role="cell" className={c.cssClass}>
                                            {this.renderRow(c.property, cls)}
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                            <div role="columnheader" className="mobileOnly">
                                <Translate>Course:ClassRegistrationStatus</Translate>
                            </div>
                            <div role="cell" className="f2f__courseDetail-table-doublecell f2f__courseDetail-table-registration-cell">
                                <div className="f2f__toolbar--center">
                                    <ToolbarRegistrationStatus
                                        item={this.props.item}
                                        classId={cls.classId}
                                        classTitle={cls.classCode}
                                        textVisible={true}
                                        hasRegisterRight={cls.hasRegistrationRight}
                                        canSelfRegister={Session.instance.loginUser != null && Session.instance.loginUser.canSelfRegisterToCourse}
                                        isInRegistrationPeriod={cls.isInRegistrationPeriod}
                                        hasOverlappingRegistration={cls.overlappingRegistrations.length > 0}
                                        checkingDateOfStartCondition={cls.checkingDateOfStartCondition} />
                                </div>
                                <div>
                                    <button type="button"
                                        aria-label={ariaLabel}
                                        className="btn--sm btn--primary f2f__button--size"
                                        onClick={() => this.onClassDetailClick(cls.classId)}
                                        id={`btnRegistration_${index}`}>
                                        {this.getClassRegistrationStatus(cls, available, this.props.item)}
                                    </button>
                                </div>
                            </div>

                        </div>
                    );
                }
            })
        }
        return elements;
    }

    protected renderRow(property: string, cls: F2FClassDetail): JSX.Element {
        switch (property.toLocaleLowerCase()) {
            case 'registrationperiod':
                return this.getDatePeriodInRows(cls.registrationStart, cls.registrationEnd)
            case 'learningperiod':
                return this.getDatePeriodInRowsForLearningPeriod(cls.learningPeriodStart, cls.learningPeriodEnd)
            case 'location':
                return (<ul>
                    {this.renderCityLocations(cls.rooms, '', cls.classId)}
                </ul>)
            case 'trainers':
                return (<ul>
                    {this.renderTrainers(cls.trainers, cls.classId)}
                </ul>)
            case 'availableplaces':
                return this.renderAvailability(cls.maxParticipants, cls.freePlaces, cls.hasWaitingList)
            case 'price':
                return (<React.Fragment>{cls.price + ' ' + cls.currency}</React.Fragment>)
            case 'cancellationdate1':
                return this.getDateInRow(cls.cancellationDate1)
            case 'cancellationdate2':
                return this.getDateInRow(cls.cancellationDate2)
            default:
                return (<React.Fragment>{cls[property]}</React.Fragment>)
        }
    }

    // Returns only the classes the user can register for
    protected getFilteredClasses(): F2FClassDetail[] {
        const filteredClasses: F2FClassDetail[] = [];
        if (this.state.classDetailList) {
            this.state.classDetailList.map((cls) => {
                const classRegistration = this.props.item.allRegistrations != null ? this.props.item.allRegistrations.find(regCls => regCls.classId === cls.classId) : null;
                if (((cls.isInRegistrationPeriod
                    // If the flags are set, show classes which are not in their registration period
                    || (this._f2fProperties.ignoreClassRegistrationPhaseEnd && cls.registrationEnd && cls.registrationEnd.getTime() < Date.now() && cls.learningPeriodEnd && Date.now() < cls.learningPeriodEnd.getTime())
                    || (this._f2fProperties.ignoreClassRegistrationPhaseStart && cls.registrationStart && Date.now() < cls.registrationStart.getTime()))
                    // If the flag ignoreClassRegisterRight is set in the config, ignore if the user has registration right or not
                    && (this._f2fProperties.ignoreClassRegisterRight || cls.hasRegistrationRight)
                    && this._f2fProperties.classStatusToShow.includes(cls.classStatus)) ||
                    // Shows classes even if they do not have the configured status only if there is a registration and no multiple registration is possible
                    (!this._f2fProperties.checkClassStatusToShowEvenRegistrationExists && classRegistration != null && classRegistration.registrationStatus === ERegistrationStatus.Accepted)) {
                    filteredClasses.push(cls);
                }
            });
        }
        return filteredClasses;
    }

    protected onClassDetailClick(clsId: number) {
        this.props.history.push(
            ItemHelper.getF2fRegistrationLink(this.props.item.itemId,
                clsId,
                this.props.itemDetailCallerContextType,
                this.props.parentCatalogFolderId,
                this.props.parentAssignmentId,
                this.props.parentTrainingPlanId,
                this.props.voucherCode)
        );
    }

    protected async getClassDetails(lessonId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0): Promise<void> {
        const response = await Session.instance.storage.classList.getF2FClassList(lessonId, itemContext, undefined, parentTrainingPlanId);
        if (isSuccess<F2FClassDetail[] | null>(response)) {
            this.setState({ classDetailList: response });
        }
    }


    protected getDatePeriodInRows(periodStart: Date | undefined, periodEnd: Date | undefined): JSX.Element {
        const start = periodStart;
        const end = periodEnd;
        if (start !== undefined && end !== undefined) {
            return (
                <React.Fragment>
                    {StringHelper.dateString(start) + ' - '}
                    {StringHelper.dateString(end)}
                </React.Fragment>
            );
        } else {
            return <React.Fragment />
        }
    }

    protected getDatePeriodInRowsForLearningPeriod(periodStart: Date | undefined, periodEnd: Date | undefined): JSX.Element {
        const start = periodStart;
        const end = periodEnd;
        if (start !== undefined && end !== undefined) {

            if (StringHelper.dateString(start) == StringHelper.dateString(end)) {
                return (
                    <React.Fragment>
                        {StringHelper.dateString(start)}<br/>
                        {globalConfig.f2fProperties.showLearningPeriodTime && StringHelper.timeString(start) + ' - ' + StringHelper.timeString(end)}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {StringHelper.dateString(start) + ' - '}
                        {StringHelper.dateString(end)}<br/>
                        {globalConfig.f2fProperties.showLearningPeriodTime && StringHelper.timeString(start) + ' - ' + StringHelper.timeString(end)}
                    </React.Fragment>
                );
            }
        } else {
            return <React.Fragment />
        }
    }

    protected getDateInRow(date: Date | undefined): JSX.Element {
        if (date) {
            return (
                <React.Fragment>
                    {StringHelper.dateString(date)}
                </React.Fragment>
            )
        } else {
            return <React.Fragment />
        }
    }

    protected getClassRegistrationStatus(schedule: F2FClassDetail, available: EAvailablePlaces, item: Item): JSX.Element {
        if (this.props.item.isMultipleRegistrationAllowed) {

            const currentRegistration = this.getActiveClassRegistration(schedule, item);

            if ((available === EAvailablePlaces.NoPlaces
                || schedule.classStatus === EClassStatus.Cancelled
                || schedule.classStatus === EClassStatus.Closed
                || item.isLockedDueToRequiredSkills
                || (!schedule.hasRegistrationRight && this._f2fProperties.ignoreClassRegisterRight))
                || !schedule.isInRegistrationPeriod
                || (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfUnregisterOfCourse)
                || !schedule.isInCancellationPeriod2 && // Check cancellationPeriod only when the user has one of the registration status bellow
                (currentRegistration != null && (
                    currentRegistration.registrationStatus === ERegistrationStatus.Accepted ||
                    currentRegistration.registrationStatus === ERegistrationStatus.Requested ||
                    currentRegistration.registrationStatus === ERegistrationStatus.InWaitingList
                ))
            ) {
                return <Translate>TrainingPlan:ShowDetails</Translate>
            }
            // Wenn der User für eine Klasse angemeldet ist kann er die Anmeldung stornieren
            else if (currentRegistration != null && (
                currentRegistration.registrationStatus === ERegistrationStatus.Accepted ||
                currentRegistration.registrationStatus === ERegistrationStatus.Requested ||
                currentRegistration.registrationStatus === ERegistrationStatus.InWaitingList
            )) {
                return <Translate>TrainingPlan:ShowDetailsCancellation</Translate>
            }
            // Wenn der User noch nicht für eine Klasse angemeldet ist und es den Klassenstatus erlaubt, kann er sich anmelden.
            else {
                return <Translate>TrainingPlan:ShowScheduleDetails</Translate>
            }
        }
        // Bezeichnet den Button mit Details, wenn entweder der Klassenstatus eine Anmeldung nicht ermöglicht oder der User bereits für eine Klasse angemeldet ist
        // oder der Benutzer das Registrieren Recht nicht besitzt und es konfiguriert ist, dass er diese Klassen sehen soll.
        else if (available === EAvailablePlaces.NoPlaces
            || schedule.classStatus === EClassStatus.Cancelled
            || schedule.classStatus === EClassStatus.Closed
            || (item.isLockedDueToRequiredSkills &&
                ((item.registeredClassId === schedule.classId && item.registrationStatus !== ERegistrationStatus.Accepted)
                    && (item.registeredClassId === schedule.classId && item.registrationStatus !== ERegistrationStatus.Requested)
                    && (item.registeredClassId === schedule.classId && item.registrationStatus !== ERegistrationStatus.InWaitingList)))
            || (item.registeredClassId !== schedule.classId && item.registrationStatus === ERegistrationStatus.Accepted)
            || (item.registeredClassId !== schedule.classId && item.registrationStatus === ERegistrationStatus.Requested)
            || (item.registeredClassId !== schedule.classId && item.registrationStatus === ERegistrationStatus.InWaitingList)
            || (!schedule.hasRegistrationRight && this._f2fProperties.ignoreClassRegisterRight)
            || !schedule.isInRegistrationPeriod
            || (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfUnregisterOfCourse)
            || !schedule.isInCancellationPeriod2 && // Check cancellationPeriod only when the user has one of the registration status bellow
            ((item.registeredClassId === schedule.classId && item.registrationStatus !== ERegistrationStatus.Accepted)
                && (item.registeredClassId === schedule.classId && item.registrationStatus !== ERegistrationStatus.Requested)
                && (item.registeredClassId === schedule.classId && item.registrationStatus !== ERegistrationStatus.InWaitingList))
        ) {
            return <Translate>TrainingPlan:ShowDetails</Translate>
        }
        // Wenn der User für eine Klasse angemeldet ist kann er die Anmeldung stornieren
        else if ((item.registeredClassId === schedule.classId && item.registrationStatus === ERegistrationStatus.Accepted)
            || (item.registeredClassId === schedule.classId && item.registrationStatus === ERegistrationStatus.Requested)
            || (item.registeredClassId === schedule.classId && item.registrationStatus === ERegistrationStatus.InWaitingList)) {
            return <Translate>TrainingPlan:ShowDetailsCancellation</Translate>
        }
        // Wenn der User noch nicht für eine Klasse angemeldet ist und es den Klassenstatus erlaubt, kann er sich anmelden.
        else {
            if ((Session.instance.loginUser != null && !Session.instance.loginUser.canSelfRegisterToCourse)) {
                return <Translate>TrainingPlan:ShowDetails</Translate>
            } else {
                return <Translate>TrainingPlan:ShowScheduleDetails</Translate>
            }
        }
    }


    protected getActiveClassRegistration(classDetails: F2FClassDetail, item: Item): Registration | null {
        let currentRegistration: Registration[];
        if (item != null &&
            item.allRegistrations != null &&
            item.allRegistrations.length > 0 &&
            classDetails != null) {
            currentRegistration = item.allRegistrations.filter(r => r.classId === classDetails.classId);
            // Has registration for this class
            if (currentRegistration.length > 0 &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.Cancelled &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.Rejected &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.ScheduleCancelled &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.NotRegistered) {
                return currentRegistration[0];
            }
        }
        return null;
    }

    protected getAcceptedRegistration(item: Item): Registration | null {
        let currentRegistration: Registration[];
        if (item != null &&
            item.allRegistrations != null &&
            item.allRegistrations.length > 0) {
            currentRegistration = item.allRegistrations.filter(r => r.registrationStatus === ERegistrationStatus.Accepted);
            // Has registration for this class
            if (currentRegistration.length > 0) {
                return currentRegistration[0];
            }
        }
        return null;
    }

    protected getAvailabilityOfClassStatus(maxParticipants: number, freePlaces: number, hasWaitingList: boolean): EAvailablePlaces {
        let availability: EAvailablePlaces;
        if (maxParticipants === 0) {
            availability = EAvailablePlaces.NoParticpantsLimit;
        } else if (freePlaces <= 0 && !hasWaitingList) {
            availability = EAvailablePlaces.NoPlaces;
        } else if (freePlaces <= 0 && hasWaitingList) {
            availability = EAvailablePlaces.WaitingList
        } else {
            if (freePlaces === 1) {
                availability = EAvailablePlaces.OneFreePlace
            }
            else {
                availability = EAvailablePlaces.FreePlaces
            }
        }
        return availability;
    }

    protected renderAvailability(maxParticipants: number, freePlaces: number, hasWaitingList: boolean): JSX.Element {
        let availability: JSX.Element;
        if (maxParticipants === 0) {
            availability = <Translate>Course:NoParticpantsLimit</Translate>
        } else if (freePlaces <= 0 && !hasWaitingList) {
            availability = <Translate>Course:NoPlaces</Translate>
        } else if (freePlaces <= 0 && hasWaitingList) {
            availability = <Translate>Course:WaitingList</Translate>
        } else {
            const tr = Session.instance.storage.translation;
            const text = tr.GetString('Course:FreePlaces').Format(freePlaces.toString());
            availability = <p>{text}</p>
        }
        return availability;
    }

    protected renderCityLocations(rooms: Room[] | null, disabledClass: string, classId: number): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const venueIds: string[] = []
        if (rooms !== null && rooms.length > 0) {
            rooms.map(room => {
                if (!venueIds.includes(room.venueCity)) {
                    elements.push(
                        <li key={classId + room.roomId}>
                            <label className={disabledClass}>{room.venueCity}</label>
                        </li>
                    )
                }
                venueIds.push(room.venueCity)
            })
        } else {
            elements.push(
                <div key={classId}>
                    -
                </div>
            )
        }
        return elements;
    }

    protected renderTrainers(trainers: Trainer[] | null, classId: number) {
        const elements: JSX.Element[] = [];
        if (trainers !== null && trainers.length > 0) {
            trainers.map((trainer, index) => {
                elements.push(
                    <li key={index}>
                        {trainer.trainerFirstName + ' ' + trainer.trainerLastName}
                    </li>
                );
            })
        } else {
            elements.push(
                <div key={classId}>
                    -
                </div>
            )
        }
        return elements;
    }
}