import { DatePicker } from '$components/shared/DatePicker';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { observer } from 'mobx-react';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

import closeIcon from '$resources/svgs/misc/close.svg';
import searchIcon from '$resources/svgs/navi/search.svg';

import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import Session from '$core/Session';
import ItemTypeFactory from '$storage/models/itemTypeFactory';
import ItemTypeModel from '$storage/models/ItemTypeModel';
import { KeyPressHelper } from '$util/KeyPressHelper';
import { MultiSelectCheckboxItemRender } from '$components/shared/Atoms/MultiSelectCheckboxItemRender';

interface IProps {
    onCancel?: () => void;
    onSearch?: (
        text: string,
        from: Date | null,
        to: Date | null,
        itemTypeArray?: ItemTypeModel[]
    ) => void;
    isFlyoutAlwaysVisible: boolean;
    isInHeader: boolean;
    initialItemTypeArray?: ItemTypeModel[];
    initialFrom: Date | null;
    initialTo: Date | null;
    initialSearchText: string;
    isAnimationOff?: boolean;
    isFlyoutDisabled?: boolean;
    isCloseIconDisabled?: boolean
}

interface IState {
    focusTo: '' | 'searchBox';
    isFlyoutVisible: boolean;
    itemTypeArray?: ItemTypeModel[];
    from: Date | null;
    to: Date | null;
    searchText: string;
    isAnimationOff: boolean;
    isFlyoutDisabled: boolean;
    isCloseIconDisabled: boolean;
}

@observer
export class LegacySearchBar extends React.Component<IProps, IState> {
    protected className = 'SearchBar';
    protected loggerLocality = 'Components.SearchBar';
    protected inputControl: HTMLInputElement | null;

    constructor(props: IProps) {
        super(props);
        this.state = ({
            focusTo: 'searchBox',
            from: this.props.initialFrom,
            isFlyoutVisible: this.props.isFlyoutAlwaysVisible ? true : false,
            itemTypeArray: this.props.initialItemTypeArray,
            searchText: this.props.initialSearchText,
            to: this.props.initialTo,
            isAnimationOff: this.props.isAnimationOff ? false : true,
            isFlyoutDisabled: this.props.isFlyoutDisabled ? false : true,
            isCloseIconDisabled: this.props.isCloseIconDisabled ? true : false,
        });

        this.setFocus = this.setFocus.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    public componentDidMount(): void {
        this.setFocus();
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        if (this.props.initialItemTypeArray !== nextProps.initialItemTypeArray ||
            this.props.initialFrom !== nextProps.initialFrom ||
            this.props.initialTo !== nextProps.initialTo ||
            this.props.initialSearchText !== nextProps.initialSearchText) {
            this.setState({
                itemTypeArray: nextProps.initialItemTypeArray,
                // tslint:disable-next-line:object-literal-sort-keys
                from: nextProps.initialFrom,
                to: nextProps.initialTo,
                searchText: nextProps.initialSearchText
            });
        }
    }

    public render() {
        const items = ItemTypeFactory.instance.GetItemTypes(false);
        const searchbar: JSX.Element[] = [];



        searchbar.push(
            <div
                id="divSearchBox"
                key="divSearchBox"
                className={`${!this.props.isAnimationOff ? 'searchbar__container__search--animationOn ' : ''}searchbar__container__search-box ${this.props.isInHeader ? ' searchbar__container__search-box--header ' : ''}`}
                onKeyUp={(e) => {
                    KeyPressHelper.executeWhenESC(e, () => this.onCancel())
                }} >
                <div className="search-box__column">
                    <div className="search-box__row">
                        <div className="search-box__row__input search-box__row__input--large">
                            <input
                                id="txtSearchBox"
                                name="txtSearchBox"
                                type="text"
                                autoComplete={'on'}
                                aria-label={Session.instance.storage.translation.GetString('Search:SearchFieldLabel')}
                                ref={(e) => this.inputControl = e}
                                placeholder={Session.instance.storage.translation.GetString('Search:TextboxPlaceholder')}
                                onChange={(e) => {
                                    this.setState({
                                        isFlyoutVisible: this.props.isFlyoutAlwaysVisible ? true : e.target.value != null && e.target.value.length > 0,
                                        searchText: e.target.value
                                    })
                                }
                                }
                                value={this.state.searchText}
                                // onBlur={this.state.isFlyoutVisible || Session.instance.isSmallScreen ? undefined : this.onCancel}
                                onKeyDown={(e) => {
                                    KeyPressHelper.executeWhenEnter(e, () => this.onSearch())
                                }}
                            />
                        </div>
                    </div>
                    {this.state.isFlyoutVisible && this.state.isFlyoutDisabled ?
                        <React.Fragment>
                            <div className="search-box__row">
                                <div className="search-box__row__label">
                                    <label><Translate>Search:LessonType</Translate> </label>
                                </div>
                                <div className="search-box__row__input search-box__row__input--large">
                                    <MultiSelect
                                        className="Multiselect"
                                        itemRender={MultiSelectCheckboxItemRender}
                                        data={items}
                                        onChange={(event) => { this.setState({ itemTypeArray: [...event.target.value] }) }}
                                        value={this.state.itemTypeArray}
                                        textField="text"
                                    />
                                </div>
                            </div>

                            <div className="search-box__row">
                                <div className="search-box__row__label">
                                    <label ><Translate>Search:DateFrom</Translate> </label>
                                </div>
                                <div className="search-box__row__input search-box__row__input--datepicker">
                                    <DatePicker
                                        className="DatePicker"
                                        key="DateFrom"
                                        onChange={(e) => this.setState({ from: e })}
                                        value={this.state.from}
                                    />
                                </div>
                                <div className="search-box__row__label search-box__row__label--between">
                                    <label><Translate>Search:DateTo</Translate> </label>
                                </div>
                                <div className="search-box__row__input search-box__row__input--datepicker">
                                    <DatePicker
                                        className="DatePicker searchBar--margin"
                                        key="DateTo"
                                        onChange={(e) => this.setState({ to: e })}
                                        value={this.state.to}
                                    />
                                </div>
                            </div>
                        </React.Fragment> : ''}

                </div>
                <div className="search-box__column search-box__column--button">
                    <button
                        onClick={this.onSearch}
                        aria-label={Session.instance.storage.translation.GetString('Search:Title')}
                        className="search__button ">
                        <InlineSVG src={searchIcon} />
                    </button>
                    {!this.props.isFlyoutAlwaysVisible && !this.props.isCloseIconDisabled ?
                        <button
                            onClick={this.onClose}
                            aria-label={Session.instance.storage.translation.GetString('Search:Close')}
                            className="search__button">
                            <InlineSVG src={closeIcon} />
                        </button> : ''
                    }
                </div>
            </div>
        );

        return (
            <div className="searchbar__container"
                // onBlur={(e) => this.onBlur(e)}
                aria-label={Session.instance.storage.translation.GetString('Search:TextboxPlaceholder')}>
                {searchbar}
            </div>
        );
    }

    /**
     * Cancel search and hide the searchbar. Pass Event to parent
     */
    private onCancel() {
        if (this.props.onCancel != null) {
            Logger.log(this.loggerLocality, 'SearchBarToggle()');
            this.props.onCancel();
        }
    }

    private onBlur(event: React.FocusEvent<HTMLDivElement>) {
        if (event.relatedTarget == null && !event.target.className.includes('DatePicker') && !Session.instance.isSmallScreen) {
            this.onClose();
        }
    }

    private onClose() {
        if (this.props.onCancel != null) {
            Logger.log(this.loggerLocality, 'SearchBarToggle()');
            this.props.onCancel();
        }
    }

    private setFocus() {
        if (this.inputControl !== null) {
            const htmlInput = this.inputControl;
            htmlInput.focus();
        }
    }

    private onSearch() {
        Logger.log(this.loggerLocality, 'Search function called');
        if (this.props.onSearch != null) {
            if (this.inputControl !== null) {
                this.props.onSearch(
                    this.inputControl.value,
                    this.state.from,
                    this.state.to,
                    this.state.itemTypeArray,
                );
                this.setState({ isFlyoutVisible: this.props.isFlyoutAlwaysVisible ? true : false })
                this.onCancel();
            }
        }

    }
}
