import { CreateDeputy } from '$components/myEmployees/DeputyManagement/CreateDeputy';
import { DeputyList } from '$components/myEmployees/DeputyManagement/DeputyList';
import * as React from 'react';

interface IProps {
    parentHeadingLevel: number;
    bossRelationCode: string;
}
interface IState {
    refreshDeputies: boolean;
}
/**
 * Displays a list of all the employees of this user
 */
export class DeputyManagement extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            refreshDeputies: false,
        };
    }

    public render() {
        return (
            <div>
                <CreateDeputy onDeputyCreated={() => this.onDeputyCreated()} bossRelationCode={this.props.bossRelationCode} parentHeadingLevel={this.props.parentHeadingLevel} />
                <DeputyList refreshDeputies={this.state.refreshDeputies} bossRelationCode={this.props.bossRelationCode} parentHeadingLevel={this.props.parentHeadingLevel} />
            </div>
        );
    }

    private onDeputyCreated(): void {
        this.setState({ refreshDeputies: !this.state.refreshDeputies });
    }
}