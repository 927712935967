import React from "react";
import { EItemDetailCallerContextType, EItemType, ELessonStatus, ERegistrationStatus, ESortDirection } from "$src/storage/models/enums";
import { SortableHeaderElement } from "$src/components/shared/SortableHeaderElement";
import GTIconButton from "$src/components/shared/Atoms/GTIconButton";
import InfoIcon from '$resources/svgs/misc/info.svg';
import InlineSVG from "react-inlinesvg";
import CancelIcon from '$resources/svgs/misc/cancel.svg';
import Session from "$src/core/Session";
import { useHistory } from "react-router";
import { userFirstAndLastName, date, icons, lessonStatus, score, lessonTitle } from "$src/components/myEmployees/RequirementsOverviewModel";
import { ItemHelper } from "$src/util/ItemHelper";

interface IProps {
    requirement: RequirementTableItem;
    isActiveHeader: boolean;
    bossRelationCode: string;
    groupedBy: "none" | "user" | "requirement";
    onActiveHeaderChanged: (activeHeaderId: string, sortDirection: ESortDirection) => void;
    openModal: (userId: number, assignmentId: number) => void;
}

export default function RequirementsOverviewItem(props: IProps) {
    const groupedBy = props.groupedBy;
    const history = useHistory();

    function getLink() {
        const id = props.requirement.itemId;

        if (props.requirement.itemType === EItemType.TrainingPlan) {
            return ItemHelper.getTrainingPlanDetailLink(id,
                EItemDetailCallerContextType.RequirementsOverview,
                0,
                0)
        }
        return ItemHelper.getItemDetailLink(id,
            EItemDetailCallerContextType.RequirementsOverview,
            0,
            0,
            0,
            0)
    }

    function GetStatusAsString(item: RequirementTableItem): string {
        let statusText = '';
        if ((item.itemType === EItemType.F2FCourse || item.itemType === EItemType.TrainingPlan || item.registrationStatusEnabled) &&
            (item.lessonStatus === ELessonStatus.Undefined || item.lessonStatus === ELessonStatus.NotAttempted)) {

            switch (item.registrationStatus) {
                case ERegistrationStatus.Requested:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Requested');
                    break;
                case ERegistrationStatus.Accepted:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Accepted');
                    break;
                case ERegistrationStatus.Rejected:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Rejected');
                    break;
                case ERegistrationStatus.Cancelled:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:Cancelled');
                    break;
                case ERegistrationStatus.ScheduleCancelled:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:ScheduleCancelled');
                    break;
                case ERegistrationStatus.InWaitingList:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:InWaitingList');
                    break;
                default:
                    statusText = Session.instance.storage.translation.GetString('RegistrationStatus:NotRegistered');
                    break;
            }
        } else {
            switch (item.lessonStatus) {
                case ELessonStatus.Incomplete:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Incomplete');
                    break;
                case ELessonStatus.Completed:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Completed');
                    break;
                case ELessonStatus.Failed:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Failed');
                    break;
                case ELessonStatus.Passed:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:Passed');
                    break;
                case ELessonStatus.NotAttempted:
                default:
                    statusText = Session.instance.storage.translation.GetString('LessonStatus:NotAttempted');
                    break;
            }
        }
        return statusText;
    }

    const navLink = `${getLink()}&bossRelationCode=${props.bossRelationCode}`;
    return (
        <div role="row">
            <div className="requirementsOverview__row row__content" >
                {groupedBy != "user" && <>
                    <div role="columnheader" className="mobileOnly">
                        <SortableHeaderElement
                            id={userFirstAndLastName}
                            isActive={props.isActiveHeader}
                            header="RequirementsOverview:UserName"
                            initialSortDirection={ESortDirection.Down}
                            OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                        />
                    </div>
                    <div role="cell">
                        {props.requirement.userFirstAndLastName}
                    </div>
                </>}


                {groupedBy != "requirement" && <>
                    <div role="columnheader" className="mobileOnly">
                        <SortableHeaderElement
                            id={lessonTitle}
                            isActive={props.isActiveHeader}
                            header="RequirementsOverview:LessonTitle"
                            initialSortDirection={ESortDirection.Down}
                            OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                        />
                    </div>


                    <div role="cell">
                        {props.requirement.lessonTitle}
                    </div>
                </>}



                <div role="columnheader" className="mobileOnly">
                    <SortableHeaderElement
                        id={date}
                        isActive={props.isActiveHeader}
                        header="RequirementsOverview:Date"
                        initialSortDirection={ESortDirection.Down}
                        OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                    />
                </div>


                <div role="cell">
                    <p dangerouslySetInnerHTML={{ __html: props.requirement.date }} />
                </div>


                <div role="columnheader" className="mobileOnly">
                    <SortableHeaderElement
                        id={lessonStatus}
                        isActive={props.isActiveHeader}
                        header="RequirementsOverview:LessonStatus"
                        initialSortDirection={ESortDirection.Down}
                        OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                    />
                </div>


                <div role="cell">
                    {GetStatusAsString(props.requirement)}
                </div>


                <div role="columnheader" className="mobileOnly">
                    <SortableHeaderElement
                        id={score}
                        isActive={props.isActiveHeader}
                        header="RequirementsOverview:Score"
                        initialSortDirection={ESortDirection.Down}
                        OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                    />
                </div>


                <div role="cell">
                    {props.requirement.score}%
                </div>


                <div role="columnheader" className="mobileOnly small-cell">
                    <SortableHeaderElement
                        id={icons}
                        isActive={props.isActiveHeader}
                        header="RequirementsOverview:Icons"
                        initialSortDirection={ESortDirection.Down}
                        OnSortDirectionChanged={(id, direction) => props.onActiveHeaderChanged(id, direction)}
                    />
                </div>


                <div role="cell" className="small-cell">
                    <GTIconButton
                        id={`RequirmentsCancel${props.requirement.lessonTitle}`}
                        ariaLabel={Session.instance.storage.translation.GetString('RequirementsOverview:Remove')}
                        tooltipText={Session.instance.storage.translation.GetString('RequirementsOverview:Remove')}
                        onClick={() => props.openModal(props.requirement.userId, props.requirement.assignmentId)}>
                        <InlineSVG src={CancelIcon} />
                    </GTIconButton>
                    <GTIconButton
                        id={`RequirmentsInfo${props.requirement.lessonStatus}`}
                        ariaLabel={Session.instance.storage.translation.GetString('RequirementsOverview:InfoLesson') + " " + props.requirement.lessonTitle}
                        tooltipText={Session.instance.storage.translation.GetString('RequirementsOverview:InfoLesson') + " " + props.requirement.lessonTitle}
                        onClick={() => history.push(navLink)}>
                        <InlineSVG src={InfoIcon} />
                    </GTIconButton>
                </div>
            </div>

        </div >
    )
}
export class RequirementTableItem {
    public userId: number;
    public assignmentId: number;
    public userFirstAndLastName: string;
    public lessonTitle: string;
    public date: string;
    public lessonStatus: ELessonStatus;
    public score: number;
    public itemId: number;
    public itemType: number;
    public registrationStatus: ERegistrationStatus;
    public registrationStatusEnabled: boolean;
}