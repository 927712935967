import React from 'react';
import ReactModal from 'react-modal';
import IconAlert from '$resources/svgs/misc/alert.svg';
import InlineSVG from "react-inlinesvg";

interface IProps {
    isOpen: boolean;
    onRequestClose: (event: React.MouseEvent<Element> | React.KeyboardEvent<Element>) => void;
    containerCssClass?: string;
    contentCssClass?: string;
    title?: string;
    disableClosing?: boolean;
    isAlert?: boolean;
}

export class ModalPopup extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            modalIsOpen: false,
            shouldShowModal: false
        }
    }

    onRequestCloseHandler = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        if (!this.props.disableClosing) {
            this.props.onRequestClose(e);
        }
    }

    public render() {
        // This is used for Screen Readers and will set Aria-Hidden on the root element
        const elementToHide = document.getElementById('root');

        return (
            <ReactModal
                appElement={elementToHide != null ? elementToHide : undefined}
                isOpen={this.props.isOpen}
                onRequestClose={(e) => this.onRequestCloseHandler(e)}
                shouldFocusAfterRender={true}
                shouldCloseOnOverlayClick={this.props.disableClosing != true}
                shouldCloseOnEsc={this.props.disableClosing != true}
                shouldReturnFocusAfterClose={true}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0,0,0,0.45)',
                        borderWidth: '0',
                        zIndex: 10000,
                        backdropFilter: 'blur(3px)'
                    }
                }}
                className={'modal__container ' + (globalConfig.layoutProperties.modalButtonReverse ? ' reverted ' : '') 
                + (this.props.containerCssClass != null ? this.props.containerCssClass : '')
                }>
                <div className="modal__container__background">
                    <div className={'modal__content  ' + (this.props.contentCssClass != null ? this.props.contentCssClass : '')}>
                        <div className="modal__content__item__header">
                            {this.props.isAlert &&  <InlineSVG src={IconAlert} />}
                            <span>{this.props.title}</span>
                            {!this.props.disableClosing && <button type="button" className="btn--sm" onClick={(e) => this.props.onRequestClose(e)} id="btnCloseModal">X</button>}
                        </div>
                        <div className="modal__content__item">
                            {this.props.children}  
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}    
