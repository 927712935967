import AttributeService from '$src/core/Services/AttributeService';
import { AttributeValue } from '$src/storage/models/AttributeValue';
import { isSuccess } from '$src/util/Result';
import {
    GridCellProps,
} from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { ProgressSpinner } from '../ProgressSpinner';
import { KendoGridCellHelper } from './KendoGridCellHelper';

type IProps = GridCellProps

export const KendoAttributeGridCell: React.FC<IProps> = (props: IProps) => {
    const [attributeValue, setAttributeValue] = useState<AttributeValue | undefined>();
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [dataLoading, setDataLoading] = useState<boolean>(false);

    const value = KendoGridCellHelper.getValue(props);

    useEffect(() => {
        if (!dataLoaded) {
            setDataLoading(true);
            AttributeService.instance.getAttributeValue(value).then((response) => {
                if (isSuccess<AttributeValue>(response)) {
                    setDataLoaded(true);
                    setAttributeValue(response);
                }
                setDataLoading(false);
                setDataLoaded(true)
            });
        }
    }, [])
    
    return <td>
        { dataLoading && <ProgressSpinner isSmall={true} /> }
        { dataLoaded && attributeValue?.text }
    </td>
}