import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * This Model is used for pure string responses from the WebAPI. 
 * If the value is not packed inside this model, the Jscon2TypescriptConverter has problems
 * reading the value.
 */
@JsonObject
export class MediaTokenResponse {

    /** String value  */
    @JsonProperty('token', String, false)
    public token: string = '';

    /** String value  (ticks) */
    @JsonProperty('validUntilTicks', Number, false)
    public validUntilTicks: number = 0;

}
