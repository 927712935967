export default class PubSubHandler {

    private _handler: () => void;
    private _self: any;
    private _once: boolean;

    constructor(handler: () => void, self: any, once = false) {
        this._handler = handler;
        this._self = self;
        this._once = once;
    }

    public apply() {
        this._handler.apply(this._self, {});
    }

    public equals(handler: () => void, self: any): boolean {
        return this._handler === handler && this._self === self;
    }

    public once(): boolean {
        return this._once;
    }
}