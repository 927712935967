import React from "react";
import InlineSVG from "react-inlinesvg";
import Play from '$resources/svgs/toolbar/lesson-play.svg';
import Edit from '$resources/svgs/toolbar/lesson-edit.svg';
import AddAssignment from '$resources/svgs/toolbar/lesson-add-assignment.svg';
import GTIconButton from "$components/shared/Atoms/GTIconButton";

/**
 * The GT Item Footer Button Props
 */
export interface IItemFooterButtonProps {

    /**
     * Component unique id
     */
    id: string;

    /**
     * Icon to display in the button
     */
    buttonType: EItemButtonType;

    /**
     * Aria label for button
     */
    ariaLabel: string;

    /**
     * **Optional** Function to call if the play button is clicked
     */
    onPlayButtonClicked?: (event: React.FormEvent<HTMLButtonElement>) => void;


    /**
     *  **Optional** Function for button of item summary detail footer
     */
    onButtonClicked?: () => void;

    /**
     * **Optional** Text to display in the tool tip. If empty no tool tip will be displayed
     */
    tooltipText?: string;

    /**
     * If button is disabled (changes color)
     */
    disabled?: boolean;

}

/**
 * Type of item button
 */
export enum EItemButtonType {
    Undefined = 0,
    Play = 1,
    Edit = 2,
    AddAssingment = 3.
}

/**
 * Global Teach Item Footer Button Component
 */
export default function ItemFooterButton(props: IItemFooterButtonProps) {
    let icon = Edit;

    switch (props.buttonType) {
        case EItemButtonType.Play:
            icon = Play;
            break;
        case EItemButtonType.Edit:
            icon = Edit;
            break;
        case EItemButtonType.AddAssingment:
            icon = AddAssignment;
            break;
    }

    if (props.buttonType === EItemButtonType.Undefined) {
        return null;
    }

    function onButtonClicked(event: React.FormEvent<HTMLButtonElement>) {
        switch (props.buttonType) {
            case EItemButtonType.Play:
                if (props.onPlayButtonClicked) {
                    props.onPlayButtonClicked(event);
                }
                break;
            default:
                if (props.onButtonClicked) {
                    props.onButtonClicked();
                }
        }
    }

    return (
        <GTIconButton
            id={props.id}
            ariaLabel={props.ariaLabel}
            onClick={(event) => onButtonClicked(event)}
            additionalClassNames={`item-footer-button${props.disabled ? " disabled-button" : ""}`}
            defaultButtonProps={{disabled:props.disabled}}
            tooltipText={props.tooltipText}>
                <InlineSVG src={icon} width={30} height={30} />
        </GTIconButton>
    );
}

