import { JsonObject, JsonProperty } from "json2typescript";
import { DateTimeConverter } from "$storage/models/converters/DateTimeConverter";
import { ItemSubTypeConverter } from "$storage/models/converters/ItemSubTypeConverter";
import { ItemTypeConverter } from "$storage/models/converters/ItemTypeConverter";
import { LessonStatusConverter } from "$storage/models/converters/LessonStatusConverter";
import { LifeCycleContextConverter } from "$storage/models/converters/LifeCycleContextConverter";
import { RegistrationStatusConverter } from "$storage/models/converters/RegistrationStatusConverter";
import { ELessonStatus, EItemType, EItemSubType, ERegistrationStatus, ELifeCycle, EClassStatus } from "$storage/models/enums";
import { ClassStatusConverter } from "./converters/ClassStatusConverter";


@JsonObject('LessonUser')
export class LessonUser {
    @JsonProperty('id', Number, false)
    public userId: number = 0;

    @JsonProperty('userName', String, false)
    public userName: string = '';

    /** Id of the corresponding item. */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** Title (in selected language). */
    @JsonProperty('title', String, false)
    public title: string = '';

    /** Current user's lesson status. */
    @JsonProperty('lessonStatus', LessonStatusConverter, false)
    public lessonStatus: ELessonStatus = ELessonStatus.NotAttempted;

    /** Type of the item. */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType = EItemType.Undefined;

    /** Sub type of the item. */
    @JsonProperty('itemSubType', ItemSubTypeConverter, false)
    public itemSubType: EItemSubType = EItemSubType.Undefined;

    /** Current user's lesson status. */
    @JsonProperty('lifeCycle', LifeCycleContextConverter, false)
    public lifeCycle: ELifeCycle = ELifeCycle.Development;

    /** Date & time of last usage of lesson (last change of lesson status). */
    @JsonProperty('lastUsed', DateTimeConverter, false)
    public lastUsed: Date | undefined = undefined;

    /** Title (in selected language). */
    @JsonProperty('registrationRequiredEnabled', Boolean, false)
    public registrationRequiredEnabled: boolean = false;

    @JsonProperty('registrationId', Number, false)
    public registrationId: number = 0;

    /** Registration status, taken in this priority order from 1: CurrentRegistration 2: NextRegistration 3: last one from AllRegistrations. */
    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;

    /** Begin of learning period (F2F class or training plan class, for F2F class this is the beginning of the first appointment). */
    @JsonProperty('displayStartDate', DateTimeConverter, false)
    public displayStartDate?: Date = undefined;
  
    /** End of learning period (F2F class or training plan class, for F2F class this is the end of the last appointment). */
    @JsonProperty('displayEndDate', DateTimeConverter, false)
    public displayEndDate?: Date = undefined;

    /** cancellationDate1 of the corresponding class */
    @JsonProperty('cancellationDate1', DateTimeConverter, false)
    public cancellationDate1: Date | undefined = undefined;

    /** cancellationDate2 of the corresponding class */
    @JsonProperty('cancellationDate2', DateTimeConverter, false)
    public cancellationDate2: Date | undefined = undefined;

    @JsonProperty('classStatus', ClassStatusConverter, false)
    public classStatus: EClassStatus = EClassStatus.Undefined;

    @JsonProperty('classId', Number, false)
    public classId: number = 0;
}