import React from "react";
import { Heading } from "$components/shared/Heading";
import LessonsIncluded from '$resources/svgs/toolbar/lessons-included.svg';
import IconAndText from "$components/shared/Atoms/IconAndText";
import { FavoriteRibbon } from "$src/components/favorites/FavoriteRibbon";
import { EFavoriteType } from "$src/storage/models/enums";
import { NavLink } from "react-router-dom";

/**
 * The GT Item Summary for Catalog Folder Props
 */
export interface IItemSummaryCatalogFolderProps {

    // Component unique id for the key of the dom element
    id: string;

    // Heading Level of parent component
    parentHeadingLevel: number;
    
    // Details of the folder like title, info
    folderDetails: FolderDetails;
}

/**
 * Global Teach Item Summary Catalog Folder Component
 */
export default function ItemSummaryCatalogFolder(props: IItemSummaryCatalogFolderProps) {

    const footerAriaLabel = props.folderDetails.folderTitle + ': ' + props.folderDetails.folderInfo;
    // Rendering for the folder box, used mostly in catalog
    return (
        <>
            {props.folderDetails.showFavoriteRibbon &&
                <div className="item-summary__folder__favorite-ribbon">
                    <FavoriteRibbon
                        itemId={props.folderDetails.folderId}
                        itemTitle={props.folderDetails.folderTitle}
                        type={EFavoriteType.CatalogFolder} />
                </div>
            }
            <NavLink to={props.folderDetails.navUrl}>
                <div className="item-summary__folder__image" style={{ backgroundImage: props.folderDetails.imageUrl || '' }}></div>
                <div className="item-summary__folder__title item-summary__title--overflow" >
                    <Heading cssClass="heading__Level4" headingLevel={props.parentHeadingLevel + 1}>
                        <p className="summary__folder__clickable">
                            {props.folderDetails.folderTitle}
                        </p>
                    </Heading>
                </div>
                <div className="item-summary__folder__footer" >
                    <IconAndText
                        id={props.id}
                        icon={LessonsIncluded}
                        ariaLabel={footerAriaLabel}
                        iconText={props.folderDetails.folderInfo}
                    />
                </div>
            </NavLink>
        </>
    );

}

/**
 * This class defines the data the ItemSummaryCatalogFolder components needs for dispayling a folder.
 */
export class FolderDetails {
    public folderId: number = 0;
    public folderTitle: string = '';
    public folderInfo: string = '';
    public navUrl: string = '';
    public imageUrl: string | null = null;
    public showFavoriteRibbon: boolean = false;
}