import { Translate } from '$src/components/shared/Translate';
import { Mentor } from '$src/storage/models/Mentor';
import React from 'react';

interface IProps {
    mentors: Mentor[];
    parentHeadingLevel: number;
}

export class ItemMentorList extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const mentors = this.props.mentors;
     
        return (
            <div className="item-detail item-detail__mentors ">
                <div className="hint">
                    <Translate>ItemDetail:MentorsHint</Translate>
                </div>
                {mentors.map(mentor => {
                    return (
                        <div key={mentor.id} className="mentorBox">
                            <div className="mentorsHeader">{mentor.firstName} {mentor.lastName}</div>
                            <div>
                                <a href={`mailto:${mentor.email}`}>
                                    {mentor.email}</a>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}