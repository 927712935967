
import { UserCertificateValidated } from '$src/storage/models/Certificates/UserCertificateValidated';
import { UserCertificateViewModel } from '$src/storage/models/Certificates/UserCertificateViewModel';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';

/**
 * CertificateService provides all Service Methods for the Certificates 
 */
export default class CertificateService extends ServiceClient {
    protected static _instance: CertificateService | null = null;

    protected className = 'CertificateService';
    protected loggerLocality = 'CertificateService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): CertificateService {
        return this._instance || (this._instance = new this());
    }
  
    /**
     * Get all my certificates based on parameters.
     * @param guid guid to map the url in the database
     */
    public async getCertificatesForUser(userId: number, language: string): Promise<UserCertificateViewModel[] | GtError> {
        const response = await this.get<UserCertificateViewModel[]>('certificate/getCertificatesForUser',
            UserCertificateViewModel,
            undefined,
            [{ name: 'language', value: language }]);
        return response;
    } 

    /**
     * Validate Certificate
     */
     public async getValidatedUserCertificate(userId: number, userCertificateId: number, checksum: string, language: string): Promise<UserCertificateValidated | GtError> {
        const response = await this.get<UserCertificateValidated>(
            'certificate/validate',
            UserCertificateValidated,
            undefined,
            [{ name: 'checksum', value: checksum },
            { name: 'language', value: language }],
            userId.toString(),
            userCertificateId.toString());
        return response;
    } 
} 