import React, { useState } from 'react'
import { ComboBox } from '@progress/kendo-react-dropdowns';

import { Translate } from '$components/shared/Translate'
import { GenericInput, GenericInputDefaultProps } from '$components/shared/GenericInput'
import GTButton from '$components/shared/Atoms/GTButton';
import GTDateRangeSelect from '$components/shared/Atoms/GTDateRangeSelect';
import GTSearchSelectBox from '$components/shared/Molecules/GTSearchSelectBox';

import Session from '$src/core/Session';
import { BookingJournalFilterRequest } from '$src/storage/models/Payment/BookingJournalFilterRequest';

interface IBookingJournalFilterProps {
	onFilterSet: (filter: BookingJournalFilterRequest | undefined) => void;
	/** Only for bookings in context of MyTeam. If the code is default it means the default relation of Myteam. If the code is null the admin view are show (check)*/
	bossRelationCode?: string;
}

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`PaymentBookingJournal:${str}`);
}

/**
 * Filter components to search for payment bookings
 * @param props callback function to start bookings search
 * @returns 
 */
export default function BookingJournalFilterComponent(props: IBookingJournalFilterProps) {
	const [filter, setFilter] = useState<BookingJournalFilterRequest>({});
	const [bossRelationCode] = useState<string>(props.bossRelationCode != undefined ? props.bossRelationCode : "");

	const registrationStatus = [
		{ id: 0, text: getTranslation("RegistrationStatusNotProcessed") },
		{ id: 1, text: getTranslation("RegistrationStatusSucceeded") },
		{ id: 2, text: getTranslation("RegistrationStatusFailed") },
		{ id: 3, text: getTranslation("RegistrationStatusCanceled") },
	];
	const paymentTypes = [
		{ id: 1, text: Session.instance.storage.translation.GetString('PaymentBookingJournal:PaymentTypeVoucher') },
		{ id: 2, text: Session.instance.storage.translation.GetString('PaymentBookingJournal:PaymentTypePaymentProvider') }
	]

	return (
		<div className='payment-booking-journal-filter'>
			<div role="grid" className="div-grid">
				<div role="gridcell">
					<Translate>PaymentBookingJournal:BookingReference</Translate>
				</div>
				<div role="gridcell">
					<GenericInput type='text' id='inBookingReference' fieldIndex={1} {...GenericInputDefaultProps} value={filter?.bookingReference || ''} onTextChange={(id, value) => setFilter({ ...filter, bookingReference: value })} />
				</div>
				<div role="gridcell">
					<Translate>PaymentBookingJournal:PaymentType</Translate>
				</div>
				<div role="gridcell">
					<ComboBox
						data={paymentTypes}
						dataItemKey='id'
						textField='text'
						onChange={(e) => setFilter({ ...filter, paymentType: e.value?.id })}
					/>
				</div>

				<div role="gridcell">
					<Translate>PaymentBookingJournal:Purchaser</Translate>
				</div>
				<div role="gridcell">
					<GTSearchSelectBox apiController='paymentAdmin' apiMethod='bookingJournalParticipantSearch' apiQueryStringParams={[{ name: 'bossRelationCode', value: bossRelationCode }]} onSelected={(e) => setFilter({ ...filter, purchaserId: e?.id })} />
				</div>
				<div role="gridcell">
					<Translate>PaymentBookingJournal:BookingDate</Translate>
				</div>
				<div role="gridcell">
					<GTDateRangeSelect onMinSelected={(e) => setFilter({ ...filter, bookingDateFrom: e })} onMaxSelected={(e) => setFilter({ ...filter, bookingDateTo: e })} />
				</div>

				<div role="gridcell">
					<Translate>PaymentBookingJournal:Lesson</Translate>
				</div>
				<div role="gridcell">
					<GTSearchSelectBox apiController='paymentAdmin' apiMethod='bookingJournalLessonSearch' apiQueryStringParams={[{ name: 'bossRelationCode', value: bossRelationCode }]} onSelected={(e) => setFilter({ ...filter, lessonId: e?.id })} />
				</div>
				<div role="gridcell">
					<Translate>PaymentBookingJournal:ClassDate</Translate>
				</div>
				<div role="gridcell">
					<GTDateRangeSelect onMinSelected={(e) => setFilter({ ...filter, classDateFrom: e })} onMaxSelected={(e) => setFilter({ ...filter, classDateTo: e })} />
				</div>

				<div role="gridcell">
					<Translate>PaymentBookingJournal:RegistrationStatus</Translate>
				</div>
				<div role="gridcell">
					<ComboBox
						data={registrationStatus}
						dataItemKey='id'
						textField='text'
						onChange={(e) => setFilter({ ...filter, registrationStatus: e.value?.id })}
					/>
				</div>
				<div role="gridcell"></div>
				<div role="gridcell"></div>

				<div role="gridcell">
					<Translate>PaymentBookingJournal:Company</Translate>
				</div>
				<div role="gridcell">
					<GTSearchSelectBox apiController='paymentAdmin' apiMethod='bookingJournalCompanySearch' apiQueryStringParams={[{ name: 'bossRelationCode', value: bossRelationCode }]} onSelected={(e) => setFilter({ ...filter, company: e?.display })} />
				</div>
				<div role="gridcell">
					<Translate>PaymentBookingJournal:Participant</Translate>
				</div>
				<div role="gridcell">
					<GTSearchSelectBox apiController='paymentAdmin' apiMethod='bookingJournalParticipantSearch' apiQueryStringParams={[
						{ name: 'bossRelationCode', value: bossRelationCode },
						{ name: 'forParticipant', value: bossRelationCode.length > 0 ? "true" : "false" }
					]} onSelected={(e) => setFilter({ ...filter, userId: e?.id })} />
				</div>
			</div>

			<div className='action-buttons margin-top'>
				{
					Object.values(filter).some(p => p !== undefined) &&
					<GTButton onClick={() => props.onFilterSet(filter)}><Translate>PaymentBookingJournal:ShowResultsButton</Translate></GTButton>
				}
				<GTButton onClick={() => props.onFilterSet(undefined)}><Translate>PaymentBookingJournal:ShowAllButton</Translate></GTButton>
			</div>
		</div>
	)
}
