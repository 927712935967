
import { Heading } from '$src/components/shared/Heading';
import React from 'react';
import { Translate } from '$components/shared/Translate';
import { CollaborationProviderClassLink } from '$components/collaborationProvider/CollaborationProviderClassLink';
import { useHasCollaborationClassLink } from '$components/collaborationProvider/Hooks/UseHaseCollaborationClassLink';


interface ICollaborationProviderClassPanelProps {
    itemId: number;
    classId: number;
    panelTitle: string;
}

export const CollaborationProviderClassPanel: React.FC<ICollaborationProviderClassPanelProps> = (props: ICollaborationProviderClassPanelProps) => {
    const [hasClassLink] = useHasCollaborationClassLink({ itemId: props.itemId, classId: props.classId });

    return <>
        {hasClassLink && <>
            <Heading headingLevel={2} cssClass="heading__Level2">
                <Translate>{props.panelTitle}</Translate>
            </Heading>
            <CollaborationProviderClassLink itemId={props.itemId} classId={props.classId} hasLink={hasClassLink} />
        </>}
    </>
}