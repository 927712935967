import ServiceClient from '$core/ServiceClient';
import { UserSkillProfile } from '$src/storage/models/SkillProfile/UserSkillProfile';
import { AttributeValue } from '$storage/models/AttributeValue';
import { SkillProfile } from '$storage/models/SkillProfile/SkillProfile';
import GtError from '$util/GtError';

export default class SkillProfileService extends ServiceClient {
    protected static _instance: SkillProfileService | null = null;

    protected className = 'SkillProfileService';
    protected loggerLocality = 'SkillProfileService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): SkillProfileService {
        return this._instance || (this._instance = new this());
    }

    public async getSkillProfileTypes(language: string): Promise<AttributeValue[] | GtError> {
        const response: AttributeValue[] | GtError = await this.get<AttributeValue[]>('skillprofiletype', AttributeValue, undefined, [{ name: 'language', value: language }]);
        return response;
    }

    public async getSkillProfiles(skillProfileTypeId: number, language: string, bossRelationCode: string): Promise<SkillProfile[] | GtError> {
        const response: SkillProfile[] | GtError = await this.get<SkillProfile[]>('skillprofile', SkillProfile, undefined, 
            [{ name: 'language', value: language }, {name: 'skillProfileTypeId', value: skillProfileTypeId.toString()},
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}]);
        return response;
    }

    public async getUserSkillProfiles(language: string, userId: number, bossRelationCode?: string): Promise<UserSkillProfile[] | GtError> {
        const response: UserSkillProfile[] | GtError = await this.get<UserSkillProfile[]>('userSkillProfiles', UserSkillProfile, undefined,
            [{ name: 'language', value: language }, {name: 'userId', value: userId.toString()},
            { name: 'bossRelationCode', value: bossRelationCode ?? ''}]);
        return response;
    }
} 