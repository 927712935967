import React, { useEffect, useState } from 'react';
import Session from '$core/Session';
import { Translate } from '$components/shared/Translate';
import { Heading } from '$components/shared/Heading';

/**
 * Control to select available payment provider for shopping basket
 * When NSUI:globalConfig:shoppingProperties:availablePaymentProviders is undefined this control doesn't render and the GTServices:Payment:PaymentProviderId will be used (as fallback for the first version of payment)
 * @returns Component and selected payment provider id - undefined, when default payment provider should be selected from gtservices
 */
export default function useSelectPaymentProvider() {
    const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<string | undefined>(undefined);

    /**
     * Returns available payment provider ids
     */
    const paymentProviderIds = (): string[] => {
        if (globalConfig.shoppingProperties.availablePaymentProviders === undefined) {
            return [''];
        } else {
            const res: string[] = [];
            globalConfig.shoppingProperties.availablePaymentProviders.forEach((p) => {
                if (!p.allowedRole || Session.instance.hasCurrentUserRole(p.allowedRole)) {
                    res.push(p.providerId);
                }
            });
            return res;
        }
    }

    useEffect(() => {
        if (selectedPaymentProvider === undefined && paymentProviderIds().length > 0) {
            setSelectedPaymentProvider(paymentProviderIds()[0]);
        }
    }, []);

    function renderRadioButton(paymentProvider?: string): JSX.Element {
        const ctrlId = 'radio-' + paymentProvider;
        const lblLabel = 'ShoppingBasketContent:' + (paymentProvider ? `PaymentProvider-${paymentProvider}-Label` : 'PaymentProvider-Default-Label');
        const lblText = 'ShoppingBasketContent:' + (paymentProvider ? `PaymentProvider-${paymentProvider}-Description` : 'PaymentProvider-Default-Description');
        const cssChecked = selectedPaymentProvider == paymentProvider ? 'checked' : '';

        return (
            <label key={ctrlId} className={'shopping-basket-selectPaymentProvider__radio ' + cssChecked} htmlFor={ctrlId}>
                <input type='radio' id={ctrlId} value={paymentProvider} checked={selectedPaymentProvider == paymentProvider} onChange={() => setSelectedPaymentProvider(paymentProvider)} radioGroup='paymentprovider'></input>
                <div>
                    <b><Translate>{ lblLabel }</Translate></b>
                    <p><Translate>{ lblText }</Translate></p> 
                </div>               
            </label>
        )
    }

    const SelectPaymentProvider = (): JSX.Element => {
        // this feature is only used when multiple payment provider are configured
        if (globalConfig.shoppingProperties.availablePaymentProviders === undefined) {
            return <></>;
        }

        return (
            <div className='shopping-basket-selectPaymentProvider'>
                <Heading headingLevel={2} cssClass="heading__Level2">
                    <Translate>ShoppingBasketContent:PaymentProviderSelection</Translate>
                </Heading>
                <div className="shopping-basket-selectPaymentProvider__container">
                    {paymentProviderIds().map(p => renderRadioButton(p))}
                </div>
            </div>
        )
    }

    return { SelectPaymentProvider, selectedPaymentProvider };
}