import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';

import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import Session from '$src/core/Session';

import BookingJournalDetailsRow from '$components/PaymentBookingJournal/BookingJournalResultsDetailsRow';
import ChangeBookingButtons from '$components/PaymentBookingJournal/ChangeBookingButtons';
import { DetailRowContext, DetailRowContextData } from '$components/PaymentBookingJournal/DetailRowContext';
import BookingJournalExcelExport from '$src/components/paymentBookingJournal/BookingJournalExcelExport';
import { PaymentMyTeamJournal } from '$src/storage/models/Payment/PaymentMyTeamJournal';
import DateHelper from '$src/util/DateHelper';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`PaymentBookingJournal:${str}`);
}
const initialDataState = {
	skip: 0,
	take: 20,
};

interface IBookingJournalResults {
	data: PaymentBookingJournalResponse[];
	myTeamConf?: PaymentMyTeamJournal; // only for the myTeam context to check the config
}

/**
 * Data grid with loaded booking journal entries
 * Each entry has functions: details view, change class, change user, cancel
 * @param props 
 * @returns 
 */
export default function BookingJournalResults(props: IBookingJournalResults) {
	const [data, setData] = useState<PaymentBookingJournalResponse[]>(props.data);
	const [expanded, setExpanded] = useState<PaymentBookingJournalResponse | null>(null);
	const [page, setPage] = React.useState(initialDataState);
	const [detailRowFunction, setDetailRowFunction] = useState<DetailRowContextData>(new DetailRowContextData());
	const [myTeamConf] = useState<PaymentMyTeamJournal | undefined>(props.myTeamConf);

	/** set expanded field to show detail row of selected entry */
	useEffect(() => {
		if (expanded !== undefined) {
			const expandedData = [...data];
			for (let i = page.skip; i < Math.min(data.length, page.skip + page.take); i++) {
				expandedData[i].expanded = data[i]?.id == expanded?.id;
			}
			setData(expandedData);
		}
	}, [expanded])

	/**
	 * One of the change components updated the entry
	 * @param entry Updated entry which will replace the appropriate one in the data array
	 */
	function updateEntry(entry: PaymentBookingJournalResponse) {
		const index = data.findIndex(p => p.id === entry.id);
		const newData = [...data];
		newData[index] = entry;
		setData(newData);
	}

	/** + icon or one of the action buttons of one row was clicked */
	function openDetailsRowAction(dataItem: PaymentBookingJournalResponse, action: DetailRowContextData) {
		action.myTeamConf = myTeamConf;
		action.onEntryUpdated = updateEntry;
		setExpanded(dataItem == expanded && JSON.stringify(action) === JSON.stringify(detailRowFunction) ? null : dataItem); // open/close
		setDetailRowFunction(action);
	}

	return (
		<div className='payment-booking-journal-results'>
			<DetailRowContext.Provider value={detailRowFunction}>
				<LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
					<IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
						<Grid
							data={data.slice(page.skip, page.take + page.skip)}
							detail={BookingJournalDetailsRow}
							expandField='expanded'
							selectedField='expanded'
							onExpandChange={(e) => openDetailsRowAction(e.dataItem, { showDetails: true })}
							pageable={true}
							skip={page.skip}
							take={page.take}
							total={data.length}
							onPageChange={(e) => setPage(e.page)}
						>
							<GridColumn field='bookingReference' sortable={true} title={getTranslation('BookingReference')} />
							<GridColumn field='participant' sortable={true} title={getTranslation('Participant')} />
							<GridColumn field='classDate' sortable={true} title={getTranslation('ClassDate')} cell={props => {
								if (props.dataItem.classDate instanceof Date && props.dataItem.classDate.getUTCFullYear() > 1) {
									if (DateHelper.CompareDate(props.dataItem.classDate, props.dataItem.classEnd) !== 0) {
										return <td>
											{DateHelper.toDayMonthString(props.dataItem.classDate) + ' - ' + DateHelper.toDateString(props.dataItem.classEnd)}
										</td>
									} else {
										return <td>
											{DateHelper.toDateString(props.dataItem.classDate)}
										</td>
									}
								} else {
									return <td></td>
								}
							}} />
							<GridColumn field='lesson' sortable={true} title={getTranslation('Lesson')} />
							<GridColumn cell={props => {
								if (!props.dataItem && props.dataItem instanceof PaymentBookingJournalResponse === false) return <td></td>
								return (
									<td>
										<ChangeBookingButtons
											data={props.dataItem}
											myTeamConf={myTeamConf}
											onChangeUserClicked={() => openDetailsRowAction(props.dataItem, { showChangeUser: true, onEntryUpdated: updateEntry })}
											onChangeClassClicked={() => openDetailsRowAction(props.dataItem, { showChangeClass: true, onEntryUpdated: updateEntry })}
											onCancelClicked={() => openDetailsRowAction(props.dataItem, { showCancel: true, onEntryUpdated: updateEntry })}
										/>
									</td>
								)
							}}>
							</GridColumn>
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</DetailRowContext.Provider>

			<BookingJournalExcelExport data={data} />
		</div>
	);
}