import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class LocationDocument {
    
    /** RoomId of the corresponding document */
    @JsonProperty('roomId', Number, false)
    public roomId: number = 0;

    /** VenueId of the corresponding document */
    @JsonProperty('venueId', Number, false)
    public venueId: number = 0;

    /** Filename of the corresponding document */
    @JsonProperty('filename', String, false)
    public filename: string = '';

    /** Url of the corresponding document */
    @JsonProperty('url', String, false)
    public url: string = '';
}