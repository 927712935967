import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class SkillProfile {

    @JsonProperty('skillProfileID', Number, false)
    public skillProfileID: number = 0;

    @JsonProperty('skillProfileSID', String, false)
    public skillProfileSID: string = '';

    @JsonProperty('domainID', Number, false)
    public domainID: number = 0;

    @JsonProperty('skillProfileTypeID', Number, true)
    public skillProfileTypeID: number | null = null;

    @JsonProperty('skillProfileTitle', String, false)
    public skillProfileTitle: string = '';

    public get displayName(): string {
        return `${this.skillProfileTitle}` + (this.skillProfileSID.length > 0 ? ` [${this.skillProfileSID}]` : '');
    }
}