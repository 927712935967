import React from 'react';
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";

import { Translate } from '$components/shared/Translate';
import GTButton from '$components/shared/Atoms/GTButton';

import { PaymentBookingJournalResponse } from '$src/storage/models/Payment/PaymentBookingJournalResponse';
import Session from '$src/core/Session';

const getTranslation = (str: string) => {
	return Session.instance.storage.translation.GetString(`PaymentBookingJournal:${str}`);
}
interface IBookingJournalExcelExportProps {
	data: PaymentBookingJournalResponse[];
}

/**
 * Renders an excel export button that exports the PaymentBookingJournalResponse array as excel
 * @param props 
 * @returns 
 */
export default function BookingJournalExcelExport(props: IBookingJournalExcelExportProps) {
	const exporter = React.createRef<ExcelExport>();

	/** Excel export */
	const excelExport = () => {
		if (exporter.current) {
			exporter.current.save();
		}
	};

	return (
		<div>
			<GTButton onClick={() => excelExport()}>
				<Translate>PaymentBookingJournal:ExportExcel</Translate>
			</GTButton>

			<ExcelExport ref={exporter} data={props.data} fileName='BookingJournal'>
				<ExcelExportColumn field='bookingReference' title={getTranslation(`BookingReference`)} />
				<ExcelExportColumn field='participant' title={getTranslation(`Participant`)} />
				<ExcelExportColumn field='classDate' title={getTranslation(`ClassDate`)} />
				<ExcelExportColumn field='lesson' title={getTranslation(`Lesson`)} />
				<ExcelExportColumn field='bookingDate' title={getTranslation(`BookingDate`)} />
				<ExcelExportColumn field='paymentStatusString' title={getTranslation(`PaymentStatus`)} />
				<ExcelExportColumn field='paymentReference' title={getTranslation(`PaymentReference`)} />
				<ExcelExportColumn field='paymentTypeString' title={getTranslation(`PaymentType`)} />
				<ExcelExportColumn field='paymentProviderId' title={getTranslation(`PaymentProviderId`)} />
				<ExcelExportColumn field='classCode' title={getTranslation(`ClassCode`)} />
				<ExcelExportColumn field='classTitle' title={getTranslation(`Class`)} />
				<ExcelExportColumn field='registrationStatusString' title={getTranslation(`RegistrationStatus`)} />
				<ExcelExportColumn field='voucherRefund' title={getTranslation(`VoucherRefund`)} />
				<ExcelExportColumn field='voucherCampaign' title={getTranslation(`VoucherCampaign`)} />
				<ExcelExportColumn field='voucherCode' title={getTranslation(`VoucherCode`)} />
				<ExcelExportColumn field='groupDiscount' title={getTranslation(`GroupDiscount`)} />
				<ExcelExportColumn field='price' title={getTranslation(`Price`)} />
				<ExcelExportColumn field='currency' title={getTranslation(`Currency`)} />
				<ExcelExportColumn field='purchaser' title={getTranslation(`Purchaser`)} />
			</ExcelExport>
		</div>
	);
}