import React from 'react';

import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { InputAttributes } from '$components/shared/InputAttributes';
import { InputCheckbox } from '$components/shared/InputCheckbox';
import { InputText } from '$components/shared/InputText';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import { TodoGlobalState } from '$components/TodoList/TodoGlobalState';
import { TodoItem } from '$components/TodoList/TodoItem';
import WorkflowActivityService from '$core/Services/WorkflowActivityService';
import Session from '$core/Session';
import { EClassStatus, EWorkflowActivityStatus } from '$storage/models/enums';
import { CancellationReasonModel } from '$storage/models/Workflow/CancellationReasonModel';
import { WorkflowActivity } from '$storage/models/Workflow/WorkflowActivity';
import { isSuccess } from '$util/Result';
import { observer } from 'mobx-react';

interface IProps {
    userId: number | undefined;
}

interface IState {
    userId: number;
    todos: WorkflowActivity[] | undefined;
    showDone: boolean;
    showClassCancelled: boolean;
    searchString: string;
}

@observer
export class TodoList extends React.Component<IProps, IState> {

    protected userName: string;
    protected tempUsrId: number = 0;
    protected fetchedTodos: WorkflowActivity[] | undefined;

    private stateShowTrue: string = 'TRUE';
    private stateShowFalse: string = 'FALSE';

    constructor(props: IProps) {
        super(props);

        this.fetchedTodos = undefined;
        // Use Session UserId if not empty
        if (Session.instance.loginUser !== null) {
            this.userName = Session.instance.loginUser.username;
            this.tempUsrId = Session.instance.loginUser.id;
        } else if (this.props.userId !== undefined) {
            this.tempUsrId = this.props.userId;
        }

        this.state = {
            searchString: '',
            showDone: true,
            showClassCancelled: false,
            todos: undefined,
            userId: this.tempUsrId,

        }
        this.Refresh = this.Refresh.bind(this)
        this.getWorkflowActivities = this.getWorkflowActivities.bind(this)
        this.getCancellationReasons = this.getCancellationReasons.bind(this)
        this.OnSearchChanged = this.OnSearchChanged.bind(this)
        this.onChangeShowDone = this.onChangeShowDone.bind(this)
        this.onChangeShowClassCancelled = this.onChangeShowClassCancelled.bind(this)
        this.UpdateTodos = this.UpdateTodos.bind(this)
        this.onReload = this.onReload.bind(this)
        this.onUpdateInProgress = this.onUpdateInProgress.bind(this)


    }

    public async UNSAFE_componentWillMount() {
        await this.getWorkflowActivities();
        await this.getCancellationReasons();
    }

    public render() {
        const mytodos: JSX.Element[] = [];
        const searchboxAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            attributeValueType: undefined,
            class: 'todo-Input',
            editMode: false,
            hasFocus: false,
            id: 'searchText',
            initialValidation: false,
            isReadOnly: false,
            isRequired: false,
            label: '',
            placeholder: Session.instance.storage.translation.GetString('Todo:Search'),
            regExpression: undefined,
            rows: undefined,
            url: undefined,
            value: this.state.searchString,
        };
        if (this.state.todos != null) {
            if (this.state.todos.length > 0) {
                this.state.todos.map(todo => {
                    mytodos.push(<TodoItem onUpdateInProgress={this.onUpdateInProgress} onReload={this.onReload} workflowActivity={todo} key={'Todo_' + todo.id} />)
                }
                )
            }

        } else if (Session.instance.lastErrorMessage === '') {
            mytodos.push(
                <ProgressSpinner key={'progSpinner'} />
            );
        }

        const attributesShowDone: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'Todolist_ShowDone',
            label: 'Todo:ShowAll',
            value: this.state.showDone ? this.stateShowTrue : this.stateShowFalse,
            class: 'todo-container-flex',
            attributeValueType: undefined,
            isReadOnly: false,
            isRequired: false,
            hasFocus: false,
            regExpression: undefined,
            url: undefined,
            placeholder: undefined,
            rows: undefined,
            editMode: true,
            initialValidation: undefined
        };

        const attributesShowClassCancelled: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'Todolist_ShowClassCancelled',
            label: 'Todo:ShowClassCancelled',
            value: this.state.showClassCancelled ? this.stateShowTrue : this.stateShowFalse,
            class: 'todo-container-flex',
            attributeValueType: undefined,
            isReadOnly: false,
            isRequired: false,
            hasFocus: false,
            regExpression: undefined,
            url: undefined,
            placeholder: undefined,
            rows: undefined,
            editMode: true,
            initialValidation: undefined
        };

        return (
            <div className="l-container">
                <MenuBreadCrumb {...this.props} />
                <div className="l-box-container todo-container-row--header" >
                    <InputText attributes={searchboxAttributes} onChange={this.OnSearchChanged} />
                    <InputCheckbox attributes={attributesShowDone} onChange={(id, value) => this.onChangeShowDone(id, value)} />
                    <InputCheckbox attributes={attributesShowClassCancelled} onChange={(id, value) => this.onChangeShowClassCancelled(id, value)} />
                </div>
                {this.RenderTodos(mytodos)}
            </div>
        );
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('Navigation:TodoList');
    }

    public async getWorkflowActivities(): Promise<void> {
        const response = await WorkflowActivityService.instance.getTodoList();
        if (isSuccess<WorkflowActivity[]>(response)) {

            response.forEach(x => x.BuildSearchString())
            this.fetchedTodos = response
            this.UpdateTodos(this.state.showDone, this.state.showClassCancelled, this.state.searchString);
        }
        else {
            Session.instance.setLastErrorMessage(response.message);
        }
    }

    public async getCancellationReasons(): Promise<void> {
        const response = await WorkflowActivityService.instance.getCancellationReasons()
        if (isSuccess<CancellationReasonModel[]>(response)) {
            TodoGlobalState.instance.setCancellationReasons(response);
        }
        else {
            Session.instance.setLastErrorMessage(response.message);
        }
    }

    protected async Refresh() {
        this.fetchedTodos = undefined;
        await this.delay(3000)
        await this.getWorkflowActivities();
    }

    protected onReload() {
        this.Refresh()
    }

    protected onUpdateInProgress() {
        this.setState({ todos: undefined });
    }

    private onChangeShowDone(id: string, value: boolean) {
        this.UpdateTodos(value, this.state.showClassCancelled, this.state.searchString)
    }

    private onChangeShowClassCancelled(id: string, value: boolean) {
        this.UpdateTodos(this.state.showDone, value, this.state.searchString)
    }

    private OnSearchChanged(id: string, value: string) {
        const text = value;
        this.UpdateTodos(this.state.showDone, this.state.showClassCancelled, text);
    }

    private UpdateTodos(showDone: boolean, showClassCancelled: boolean, search: string) {
        this.setState({
            searchString: search,
            showDone,
            showClassCancelled,
            todos: this.fetchedTodos!.filter(
                x => x.searchString.toUpperCase().includes(search.toUpperCase())
                    && (showDone || x.status === EWorkflowActivityStatus.Due)
                    && (showClassCancelled || x.classStatus !== EClassStatus.Cancelled)
            )
        });
    }

    private RenderTodos(mytodos: JSX.Element[]) {
        if (mytodos.length > 0) {
            return <ul>{mytodos}</ul>
        }
        else if (this.fetchedTodos == null || this.fetchedTodos.length === 0) {
            return <NoDataFound message={Session.instance.storage.translation.GetString('Todo:NoData')} />;
        }
        else {
            return <NoDataFound message={Session.instance.storage.translation.GetString('Todo:NothingFound')} />;
        }
    }

    private async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
export default TodoList;