
import InfoIcon from '$resources/svgs/misc/info.svg';
import LinkIcon from '$resources/svgs/misc/open-in-new-tab.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';

import { CreateExternalCourseProvider } from '$src/components/externalCourse/CreateDialogs/CreateExternalCourseProvider';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import { ExternalCourseProvider } from '$src/storage/models/ExternalCourse/ExternalCourseProvider';
import { isSuccess } from '$src/util/Result';
import { UrlHelper } from '$src/util/UrlHelper';
import { filterBy } from '@progress/kendo-data-query';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCloseEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';
import InlineSVG from 'react-inlinesvg';

interface IProps {
    selectedCourseProvider: ExternalCourseProvider | undefined;
    parentHeadingLevel: number;
    onStepClicked?: (stepNumber: number) => void;
    onCourseProviderChanged?: (selctedCourseProvider: ExternalCourseProvider) => void;
    isNew: boolean;
}

interface IState {
    errorMessage: string;
    filteredProviderList: ExternalCourseProvider[];
    selectedProvider: ExternalCourseProvider | undefined;
    isDataLoaded: boolean;
    providerList: ExternalCourseProvider[];
    shouldShowModal: boolean;
}

export class StepCourseProvider extends React.Component<IProps, IState> {
    protected courseStepNumber = 2 // step number of course step defined in RequestWizard.tsx

    constructor(props: IProps) {
        super(props);

        this.state = {
            errorMessage: '',
            filteredProviderList: [],
            isDataLoaded: false,
            providerList: [],
            selectedProvider: this.props.selectedCourseProvider,
            shouldShowModal: false,
        }
    }

    public async componentDidMount() {
        if (this.props.isNew) {
            await this.getCourseProviders();
        } else {
            this.setState({ isDataLoaded: true });
        }
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return <ProgressSpinner />
        } else {
            const ariaLabelNextStep = Session.instance.storage.translation.GetString('ExternalCourse:NavigateToCourse')
            let providerUrl: string = '';
            if (this.state.selectedProvider && this.state.selectedProvider.url.length > 0) {
                providerUrl = UrlHelper.getHttpsUrl(this.state.selectedProvider.url);
            }
            return (
                <div className="step-content">
                    <div className="step-content__block">
                        <div className="step-content__block">
                            <Translate>ExternalCourse:ProviderSelectionHint</Translate>
                            <AutoComplete
                                data={this.state.filteredProviderList}
                                onChange={(e: AutoCompleteChangeEvent) => this.onChange(e)}
                                label={Session.instance.storage.translation.GetString('ExternalCourse:SelectCourseProvider')}
                                suggest={false}
                                textField="name"
                                className="flexForIcon"
                                onClose={(e) => this.onClose(e)}
                                value={this.state.selectedProvider ? this.state.selectedProvider.name : undefined}
                                disabled={!this.props.isNew}
                            />
                        </div>

                        {this.state.errorMessage.length > 0 &&
                            <div className="step-content__block">
                                <span className={'input-message error--dark'}>
                                    <Translate>{this.state.errorMessage}</Translate>
                                </span>
                            </div>
                        }

                        {(providerUrl.length > 0) &&
                            <div className="step-content__block inlineFlex">
                                <InlineSVG src={LinkIcon} />
                                <a href={providerUrl} target="_blank" className="button-link button-link--colorized-dark">
                                    <Translate>ExternalCourse:ProviderHomepage</Translate>
                                </a>
                            </div>
                        }

                        {this.props.isNew &&
                            <React.Fragment>
                                {globalConfig.externalCourse.showCreateProviderLink &&
                                    <React.Fragment>
                                        <div className="step-content__block">
                                            <button className="button-link button-link--colorized-dark create-link"
                                                onClick={() => this.createNewProvider()}
                                                id="btnCreateCourseProvider">
                                                <Translate>ExternalCourse:CreateNewProvider</Translate>
                                            </button>
                                        </div>
                                        <ModalPopup
                                            isOpen={this.state.shouldShowModal}
                                            onRequestClose={() => this.closeModal()}
                                            title={Session.instance.storage.translation.GetString('ExternalCourse:CreateNewCourseProvider')}>
                                            <div className="modal__spread buttons">
                                                <CreateExternalCourseProvider
                                                    parentHeadingLevel={this.props.parentHeadingLevel}
                                                    onSaveExternalCourseProvider={(confirmed, createdProvider) => this.onSaveExternalCourseProvider(confirmed, createdProvider)}
                                                />
                                            </div>
                                        </ModalPopup>
                                    </React.Fragment>
                                }
                                <div className="step-info__block">
                                    <div className="inlineFlex">
                                        <InlineSVG src={InfoIcon} />
                                        <span>
                                            <Translate>ExternalCourse:HintDataSavedOnStepRequest</Translate>
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="step-navigation">
                        <div />
                        <span><Translate>ExternalCourse:CourseProvider</Translate></span>
                        <button className="button-link button-link--colorized-dark"
                                onClick={() => this.goToCourse()}
                                aria-label={ariaLabelNextStep}
                                disabled={!this.state.selectedProvider}
                                id="btnGoToCourse">
                                {
                                    this.state.selectedProvider ?
                                        <div className="inlineFlex">
                                            <span className="notMobile"><Translate>ExternalCourse:Course</Translate></span>
                                            <InlineSVG src={ArrowRight} />
                                        </div>
                                    :
                                    <div className="inlineFlex external-courses__link__disabled">
                                        <span className="notMobile"><Translate>ExternalCourse:Course</Translate></span>
                                    </div>
                                }
                        </button>
                    </div>
                </div>
            )
        }
    }

    private async getCourseProviders() {
        const response = await Session.instance.storage.externalCourseProviders.getExternalCourseProviderList();
        if (isSuccess<ExternalCourseProvider[]>(response)) {
            this.setState({ providerList: response, filteredProviderList: response, isDataLoaded: true });
        } else {
            this.setState({ errorMessage: 'ErrorMessage:GetExternalCourseProvidersFailed', isDataLoaded: true })
        }

    }

    private onChange(event: AutoCompleteChangeEvent) {
        const value = event.target.value;
        const filteredProviderList = this.filterData(value);
        let errorMessage = this.state.errorMessage
        if (value === '') {
            errorMessage = '';
        }
        this.setState({
            errorMessage,
            filteredProviderList,
            selectedProvider: undefined
        })
    }

    private filterData(value: string): ExternalCourseProvider[] {
        const data = this.state.providerList
        const filter = {
            field: 'name',
            ignoreCase: true,
            operator: 'contains',
            value,

        };
        return value && value !== '*' ? filterBy(data, filter) : data;

    }

    private onClose(event: AutoCompleteCloseEvent) {
        const value = event.target.value;
        const selectedProvider = this.state.providerList.filter(p => p.name === value)[0];
        const errorMessage = selectedProvider || value === '' ? '' : 'ErrorMessage:SelectAValidProvider';
        this.setState({
            errorMessage,
            selectedProvider
        })

        if (this.props.onCourseProviderChanged && selectedProvider) {
            this.props.onCourseProviderChanged(selectedProvider)
        }
    }

    private createNewProvider() {
        this.openModal();
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private onSaveExternalCourseProvider(confirmed: boolean, createdProvider: ExternalCourseProvider | undefined) {
        if (confirmed && createdProvider) {
            this.setState({ selectedProvider: createdProvider, errorMessage: '' });
            if (this.props.onCourseProviderChanged && createdProvider) {
                this.props.onCourseProviderChanged(createdProvider)
            }
        }
        this.closeModal();
    }

    private goToCourse() {
        if (this.props.onStepClicked) {
            this.props.onStepClicked(this.courseStepNumber);
        }
    }
}