import React from 'react';
import InlineSVG from 'react-inlinesvg';

import { Translate } from '$components/shared/Translate';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import IconskillExpired from '$resources/svgs/skills/skill-expired.svg';
import IconskillMet from '$resources/svgs/skills/skill-met.svg';
import IconskillNotMet from '$resources/svgs/skills/skill-not-met.svg';
import { TrainingPlan } from '$storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanOutputSkill } from '$storage/models/TrainingPlan/TrainingPlanOutputSkill';
import { Tooltip } from '$components/shared/Tooltip';

interface IProps {
    trainingPlan: TrainingPlan | null;
}

interface IState {
    trainingPlan: TrainingPlan | null;
    errorMessage: string;
}

export class TrainingPlanOutputSkills extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            errorMessage: '',
            trainingPlan: this.props.trainingPlan,
        }
    }

    public render() {
        return (
            <React.Fragment>
                <table className="tp-table">
                    <thead>
                        <tr>
                            <th><Translate>TrainingPlan:Skills</Translate></th>
                            <th colSpan={2} className="tp-table__column--double"><Translate>TrainingPlan:SkillLevel</Translate></th>
                            <th ><Translate>TrainingPlan:OSkillOwned</Translate></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderOutputSkills()}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    protected renderOutputSkills(): JSX.Element[] | JSX.Element {
        if (this.state.trainingPlan != null && this.state.trainingPlan.outputSkills != null && this.state.trainingPlan.outputSkills.length > 0) {
            const tr = Session.instance.storage.translation;
            const elements: JSX.Element[] = [];
            this.state.trainingPlan.outputSkills.map(
                (oskl, index) => elements.push(
                    <tr key={index}>
                        <td>{oskl.outputSkill != null ? oskl.outputSkill.skillTitle : '??'}</td>
                        <td colSpan={2}>{oskl.outputSkill != null ? oskl.outputSkill.skillLevelTitle : ''}</td>
                        <td 
                            aria-label={tr.GetString('TrainingPlan:OSkillOwned') + ': ' + this.renderOutputSkillTitles(oskl)}
                            data-tip={tr.GetString('TrainingPlan:OSkillOwned') + ': ' + this.renderOutputSkillTitles(oskl)}
                            data-for={`${index}_oSkillTooltip`}>
                            <div className="inlineFlexAboveSm">{this.renderOutputSkillIcons(oskl)}
                                <span className="marginLeft5AboveSm">{this.renderOutputSkillTitles(oskl)}</span>
                            </div>
                            <Tooltip id={`${index}_oSkillTooltip`} />
                        </td>
                    </tr>));
            return elements;
        }
        else {
            return (
                <tr>
                    <td colSpan={3}>
                        <NoDataFound message={Session.instance.storage.translation.GetString('TrainingPlan:NoOutputSkills')} />
                    </td>
                </tr>
            );
        }
    }

    private renderOutputSkillIcons(oskl: TrainingPlanOutputSkill): JSX.Element {
        let iconName = '';
        if (oskl.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            iconName = IconskillMet;
        }
        else if (oskl.isExpired) {
            iconName = IconskillExpired;
        }
        else {
            iconName = IconskillNotMet;
        }

        return <InlineSVG src={iconName} />
    }

    private renderOutputSkillTitles(oskl: TrainingPlanOutputSkill): string {
        const tr = Session.instance.storage.translation;
        let title = '';
        if (oskl.fulfilled) { // auch bei dbo.IOSkill.ioMayBeExpired = 1
            title = tr.GetString('TrainingPlan:FulfilledYes');
        }
        else if (oskl.isExpired) {
            title = tr.GetString('TrainingPlan:SkillExpired');
        }
        else {
            title = tr.GetString('TrainingPlan:FulfilledNo');
        }

        return title;
    }
}