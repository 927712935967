import { JsonObject  } from 'json2typescript';

@JsonObject
export default  class  JsonHelper {
    public  static toString = (obj: any): string | undefined =>  {
        if(obj == null) {
            return undefined
        }
        return JSON.stringify(obj, null, 2)
    }

    public  static toObject=(value: string | undefined): any => {
        if(value == null || value === '') {
            return undefined
        }
        return JSON.parse(value)
    }
} 
