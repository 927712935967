import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import ItemRatingService from '$src/core/Services/ItemRatingService';
import React, { useEffect, useState } from 'react';
import { Translate } from '$src/components/shared/Translate';
import { ItemRating } from '$src/storage/models/ItemDetails/ItemRating';
import SingleRating from '$components/itemRating/SingleRating';
import { Heading } from '$components/shared/Heading';

interface IProps {
    parentHeadingLevel: number;
    itemId: number;
    parentTrainingPlanId: number;
}

/**
 * Component that renders all ratings for one item
 */
export default function ItemRatings(props: IProps) {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [ratings, setRatings] = useState<ItemRating[]>([]);

    // Hooks
    useEffect(() => {
        if (!isLoaded) {
            ItemRatingService.instance.getItemRatings(props.itemId, props.parentTrainingPlanId).then((ratings) => {
                setRatings(ratings as Array<ItemRating>);
                setIsLoaded(true);
            });
        }
    }, []);

    // Functions
    const itemDeleted = (itemRatingId: number) => {
        setRatings([...ratings.filter(r => r.id !== itemRatingId)])
    }

    // Render
    if (!isLoaded) {
        return <ProgressSpinner />
    }

    const myRating = ratings.filter(r => r.isOwnRating === true);
    const otherRatings = ratings.filter(r => r.isOwnRating === false);
    if(globalConfig.ratingProperties.maxOtherRatings > 0) {
        otherRatings.length = Math.min(otherRatings.length, globalConfig.ratingProperties.maxOtherRatings);
    }

    return (
        <>
            {myRating.length === 1 && <>
                <Heading
                    headingLevel={props.parentHeadingLevel + 1}
                    cssClass={'heading__Level3'}>
                    <Translate>{'Rating:MyRating'}</Translate>
                </Heading>
                {myRating.map((rating) => <SingleRating key={rating.itemId + rating.userId}
                    itemRating={rating}
                    onDeleted={(itemRatingId) => itemDeleted(itemRatingId)} />)}
            </>}
            {!globalConfig.ratingProperties.hideOtherRatings && otherRatings.length > 0 && <>
                <Heading
                    headingLevel={props.parentHeadingLevel + 1}
                    cssClass={'heading__Level3'}>
                    <Translate>{'Rating:OtherRatings'}</Translate>
                </Heading>
                {
                    otherRatings.map((rating) => <SingleRating key={rating.itemId + rating.userId}
                        itemRating={rating}
                        onDeleted={(itemRatingId) => itemDeleted(itemRatingId)} />)
                }</>
            }
        </>
    )
}