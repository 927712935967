import Logger from '$src/core/Logger';
import GamificationService from '$src/core/Services/GamificationService';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import GTHorizontalTableHeaderRow, { GTHeaderContent } from '$components/shared/Atoms/GTHorizontalTableHeaderRow';
import Session from '$src/core/Session';
import GTHorizontalTableRow, { GTRowContent } from '$components/shared/Atoms/GTHorizontalTableRow';
import GTHorizontalTable from '$components/shared/Molecules/GTHorizontalTable';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { GamificationGroupHighscore } from '$src/storage/models/Gamification/GamificationGroupHighscore';


export const GamificationGroupHighscoreRankingList: React.FC = () => {
    const [groupHighscoreRankingList, setGroupHighscoreRankingList] = useState<GamificationGroupHighscore[]>();
    const [groupHighscoreDataLoading, setGroupHighscoreDataLoading] = useState<boolean>(false);
    const [groupHighscoreDataLoaded, setGroupHighscoreDataLoaded] = useState<boolean>(false);
    const [errorLoadingData, setErrorLoadingData] = useState<boolean>(false);

    const loggerLocality = 'GamificationgroupHighscoreRankingList';

    useEffect(() => {
        async function fetchData() {
            setGroupHighscoreDataLoading(true);
            await GamificationService.instance.getGamificationGroupHighscore()
                .then((result) => {
                    if (isSuccess<GamificationGroupHighscore[]>(result)) {
                        setGroupHighscoreRankingList(result);
                        setGroupHighscoreDataLoading(false);
                        setErrorLoadingData(false);
                    } else {
                        setErrorLoadingData(true);
                        Logger.log(loggerLocality, 'failed to get data');
                        console.error(`${loggerLocality} failed to get data`);
                    }
                    setGroupHighscoreDataLoaded(true);
                })
        }
        fetchData();
    }, []);

    const headerElements: GTHeaderContent[] = [];
    headerElements.push({
        cellId: 'rank',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Rank')
    });

    headerElements.push({
        cellId: 'name',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Name')
    });

    headerElements.push({
        cellId: 'info',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Info')
    });

    headerElements.push({
        cellId: 'points',
        cellElement: Session.instance.storage.translation.GetString('Gamification:Points')
    });

    const headerRow = <GTHorizontalTableHeaderRow
        headerElements={headerElements}
        tableRowClassName="screen-reader-only" />

    const rows = () => {
        const tableRows: JSX.Element[] = [];
        groupHighscoreRankingList!.map((grpRank, index) => {
            const rowContent: GTRowContent[] = [];
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Rank'),
                cell: <>{grpRank.rank}</>,
                cellId: `rank_${index}`,
                cellClassName: `gamification-highscore__rank-cell`
            })
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Name'),
                cell: <>{grpRank.groupname}</>,
                cellId: `name_${index}`,
                cellClassName: `gamification-highscore__name-cell`
            })
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Info'),
                cell: <>{grpRank.groupInfo == '' ? '-' : grpRank.groupInfo}</>,
                cellId: `info_${index}`,
                cellClassName: `gamification-highscore__infoLevel-cell`
            })
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Gamification:Points'),
                cell: <>{grpRank.points.toLocaleString(Session.instance.getUserLanguageCodeOrFallBack)}</>,
                cellId: `points_${index}`,
                cellClassName: `gamification-highscore__points-cell`
            })
    
    
            tableRows.push(<GTHorizontalTableRow
                key={`groupRank_${index}`}
                rowContent={rowContent}
                tableRowClassName={`div-table__horizontal-table-row zebra ${grpRank.isMyGroup ? `gamification-highscore__my-rank` : ''}` } />);
        });
        return tableRows;
    }


    return (
        <div className='gamification-highscore__ranking-list'>
            {groupHighscoreDataLoaded && <GTHorizontalTable
                headerElement={headerRow}
                rows={rows()} />}
            {groupHighscoreDataLoading && !groupHighscoreDataLoaded && <ProgressSpinner />}
            {errorLoadingData && <ErrorMessage appearance='box' errorMessage='Common:ErrorLoadingData' />}
        </div>
    )
}