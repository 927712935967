import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import React from 'react';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import Session from '$src/core/Session';
import { SkillTableItemContainer } from '$src/components/Skills/SkillItem';
import { SkillTable } from '$src/components/Skills/SkillTable';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

interface IProps {
    bossRelationCode?: string;
    context: EItemDetailCallerContextType;
    userId: number;
    userSkills: UserSkill[];
}

/**
 * This component renders a List of user acquired skills as a table
 */
export class UserSkills extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const skillsContainer = this.concatTargetAndUserSkills();
        if (skillsContainer.skillTableItem.length > 0) {
            return (
                <SkillTable bossRelationCode={this.props.bossRelationCode} context={this.props.context} skillsContainer={skillsContainer} isTargetSkill={false} userId={this.props.userId} isProfile={false}/>);
        } else {
            return <NoDataFound message={Session.instance.storage.translation.GetString('Skill:NoData')} />
        }
    }

    private concatTargetAndUserSkills(): SkillTableItemContainer {
        const targetAndUserSkillsContainer: SkillTableItemContainer = new SkillTableItemContainer();
        this.props.userSkills.map(userSkill => {
            targetAndUserSkillsContainer.skillTableItem.push({
                skillId: userSkill.skillId,
                skillTitle: userSkill.skillTitle,
                skillDescription: userSkill.skillDescription,
                skillRequiredLevel: null,
                skillRequiredLevelId: null,
                skillLevel: userSkill.skillLevelTitle,
                skillLevelSetId: null,
                skillAquired: userSkill.dateAcquired,
                skillExpired: userSkill.expirationDate,
                skillStatus: userSkill.skillStatus,
                skillNumberOfRecommendaedLessons: userSkill.numberOfRecommendedLessons,
                skillReasonForAssignment: userSkill.reasonForAssignment,
                certificates: userSkill.userSkillCertificates,
                attributes: userSkill.attributes,
                sortOrder: 0
            });
        });
        return targetAndUserSkillsContainer;
    }
}