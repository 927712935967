import { MenuBreadCrumb } from '$components/breadCrumb/MenuBreadCrumb';
import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import Session from '$core/Session';
import Logger from '$src/core/Logger';
import TranslationStorage from '$src/storage/TranslationStorage';
import * as React from 'react';

import OfflineAdminService from '$src/core/Services/OfflineAdminService';
import { ErrorLogEntry } from '$src/storage/models/offlineAdmin/ErrorLogEntry';
import { ErrorLogEntryResponse } from '$src/storage/models/offlineAdmin/ErrorLogEntryResponse';
import { FileDataRequest } from '$src/storage/models/RequestObjects/FileDataRequest';
import { isSuccess } from '$src/util/Result';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import InputFile from '../shared/InputFile';
import { ExcelExport } from '@progress/kendo-react-excel-export';

interface IState {
    errorMessage: string;
    isResultGridVisible: boolean;
    result: ErrorLogEntry[] | undefined;
}

/**
 * Main page to import lesson status via XML
 */
export class LessonStatusImportOffline extends React.Component<{}, IState> {

    private className = 'LessonStatusImportOfflineXML';
    private TRANSLATION_PREFIX = 'LessonStatusImportOffline';
    private loggerLocality = 'Components.LessonStatusImportOfflineXML';
    private tr: TranslationStorage;
    private excelExport: ExcelExport | null = null;

    constructor(props: {}) {
        super(props);

        this.state = ({
            errorMessage: '',
            isResultGridVisible: false,
            result: undefined
        });

        this.tr = Session.instance.storage.translation;
    }

    public componentDidMount() {
        Logger.log(this.loggerLocality, `${this.className}componentDidMount`);
        document.title = globalConfig.appProperties.title;
    }

    public componentDidUpdate() {
        Logger.log(this.loggerLocality, `${this.className}componentDidUpdate`);
    }

    public render() {
        Logger.log(this.loggerLocality, `${this.className}render`);
        return (
            <div className="l-container">
                <MenuBreadCrumb {...this.props} />
                <Heading headingLevel={1} cssClass="l-box-container heading__Title">
                    <Translate>LessonStatusImportOffline:Title</Translate>
                </Heading>
                <div>
                    <span className={'input-message error'}>
                        <Translate>{this.state.errorMessage}</Translate>
                    </span>
                </div>
                <div>
                    <label id="lblXmlFileUpload">{this.tr.GetString(`${this.TRANSLATION_PREFIX}:ImportDescription`)}</label>
                    <InputFile
                        acceptFilter=".xml"
                        isMultiple={false}
                        labeledBy="lblXmlFileUpload"
                        onUploadChange={(files) => this.onUploadChange(files)}
                        maxFileSizeInByte={globalConfig.externalCourse.requestFileUpload.maxFileSize}
                        linkCssClass="button-link button-link--colorized-dark"
                    />
                </div>
                {
                    this.state.isResultGridVisible ?
                        <div className="lsioResultGrid">
                            <LocalizationProvider language={Session.instance.getUserLanguageCodeOrFallBack}>
                                <IntlProvider locale={Session.instance.getUserLanguageCodeOrFallBack} >
                                    <ExcelExport
                                        ref={(exporter) => { this.excelExport = exporter; }}
                                        data={this.state.result}
                                        fileName="results.xlsx">
                                        <Grid data={this.state.result}>
                                            <GridNoRecords>
                                                <Translate>NoDataFound:DefaultMessage</Translate>
                                            </GridNoRecords>
                                            <GridColumn
                                                title={this.tr.GetString(`${this.TRANSLATION_PREFIX}:ConcernedItem`)}
                                                field="concernedItem"
                                                filterable={false}
                                                sortable={false} />
                                            <GridColumn
                                                title={this.tr.GetString(`${this.TRANSLATION_PREFIX}:Lastname`)}
                                                field="lastname"
                                                filterable={false}
                                                sortable={false}
                                            />
                                            <GridColumn
                                                title={this.tr.GetString(`${this.TRANSLATION_PREFIX}:Firstname`)}
                                                field="firstname"
                                                filterable={false}
                                                sortable={false}
                                            />
                                            <GridColumn
                                                title={this.tr.GetString(`${this.TRANSLATION_PREFIX}:Lesson`)}
                                                field="lection"
                                                filterable={false}
                                                sortable={false}
                                            />
                                            <GridColumn
                                                title={this.tr.GetString(`${this.TRANSLATION_PREFIX}:DetailedMessage`)}
                                                field="message"
                                                filterable={false}
                                                sortable={false} />
                                        </Grid>
                                    </ExcelExport>
                                </IntlProvider>
                            </LocalizationProvider>
                            <a onClick={() => this.exportToExcel()}>{this.tr.GetString("Reporting:ExportToExcel")}</a>
                        </div>
                        : ''}

            </div>
        );
    }

    private async onUploadChange(files: File[]) {
        Logger.log(this.loggerLocality, `${this.className}:onUploadChange: ${files.length > 0 ? files[0].name : ''}`);
        if (files.length === 0) {
            return;
        }

        const f = files[0];
        const fileDataRequest = new FileDataRequest();
        fileDataRequest.file = f;
        fileDataRequest.contentType = f.type;
        fileDataRequest.fileName = f.name;

        const response = await OfflineAdminService.instance
            .saveLessonStatusFromOfflineXmlImport(fileDataRequest);
        if (isSuccess<ErrorLogEntryResponse>(response)) {
            this.setState({ result: response.errorLogEntries, isResultGridVisible: true });
        }
        else {
            this.setState({ errorMessage: 'An error occured.' });
        }
    }

    private exportToExcel(): void {
        if (this.excelExport) {
            this.excelExport.save();
        }
    }
}
export default LessonStatusImportOffline;