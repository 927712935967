import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { ECheckoutRegistrationStatus, EItemTypeAttributeValue, ECheckoutPaymentStatus} from '$src/storage/models/enums';

/** Response of payment booking journal request */
@JsonObject
export class PaymentBookingJournalResponse {
    /** pcoi id */
    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('bookingDate', DateTimeConverter, false)
    public bookingDate: Date = new Date();
    
    @JsonProperty('paymentStatus', Number, false)
    public paymentStatus: ECheckoutPaymentStatus = ECheckoutPaymentStatus.NotProcessed;
    
    @JsonProperty('bookingReference', String, false)
    public bookingReference: string = '';
    
    @JsonProperty('paymentReference', String, false)
    public paymentReference: string = '';
    
	/** See paymentTypeString */
    @JsonProperty('paymentType', Number, false)
    public paymentType: number = 0;

    @JsonProperty('paymentProviderId', String, false)
    public paymentProviderId: string = '';
    
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    @JsonProperty('itemType', Number, false)
    public itemType: EItemTypeAttributeValue = EItemTypeAttributeValue.Undefined;

    @JsonProperty('lesson', String, false)
    public lesson: string = '';
    
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    @JsonProperty('classTitle', String, false)
    public classTitle: string = '';

    @JsonProperty('classCode', String, false)
    public classCode: string = '';

    @JsonProperty('classDate', DateTimeConverter, false)
    public classDate: Date = new Date();

    @JsonProperty('classEnd', DateTimeConverter, false)
    public classEnd: Date = new Date();

    @JsonProperty('participantId', Number, false)
    public participantId: number = 0;

    @JsonProperty('participant', String, false)
    public participant: string = '';
    
    @JsonProperty('registrationStatus', Number, false)
    public registrationStatus: ECheckoutRegistrationStatus = ECheckoutRegistrationStatus.NotProcessed;
       
    @JsonProperty('voucherCampaignBasket', String, false)
    public voucherCampaignBasket: string = '';

    @JsonProperty('voucherCodeBasket', String, false)
    public voucherCodeBasket: string = '';

    @JsonProperty('voucherCampaignUser', String, false)
    public voucherCampaignUser: string = '';

    @JsonProperty('voucherCodeUser', String, false)
    public voucherCodeUser: string = '';

    @JsonProperty('voucherDiscountUser', Number, false)
    public voucherDiscountUser: number = 0;
    
    @JsonProperty('groupDiscountUser', Number, false)
    public groupDiscountUser: number = 0;

    @JsonProperty('itemSum', Number, false)
    public itemSum: number = 0;
    
    @JsonProperty('basketPrice', Number, false)
    public basketPrice: number = 0;

    @JsonProperty('basketVoucherSum', Number, false)
    public basketVoucherSum: number = 0;

    @JsonProperty('basketGroupDiscountSum', Number, false)
    public basketGroupDiscountSum: number = 0;
    
    @JsonProperty('basketSum', Number, false)
    public basketSum: number = 0;

    @JsonProperty('currency', String, false)
    public currency: string = '';
    
    @JsonProperty('purchaser', String, false)
    public purchaser: string = '';
    
    @JsonProperty('comments', String, false)
    public comments: string = '';

    @JsonProperty('hasAnyClassChanged', Boolean, false)
    public HasAnyClassChanged: boolean = false;

    @JsonProperty('hasAnyUserChanged', Boolean, false)
    public HasAnyUserChanged: boolean = false;

    @JsonProperty('hasAnyCancelled', Boolean, false)
    public HasAnyCancelled: boolean = false;

    /** String value of enum */
    @JsonProperty('paymentStatusString', String, false)
    public paymentStatusString: string = '';

	/** String value of enum */
    @JsonProperty('paymentTypeString', String, false)
    public paymentTypeString: string = '';
    
	/** String value of enum */
    @JsonProperty('registrationStatusString', String, false)
    public registrationStatusString: string = '';


    /** For kendo grid needed - when true, details row of this entry will be shown */
    public expanded: boolean = false;
}