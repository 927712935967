import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import AuthService from '$src/core/Services/AuthService';
import MediaService from '$src/core/Services/MediaService';
import NewsService from '$src/core/Services/NewsService';
import Session from '$src/core/Session';
import { News } from '$src/storage/models/News';
import { StringResponse } from '$src/storage/models/StringResponse';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import ArrowLeft from '$resources/svgs/navi/arrow-left.svg';
import ArrowRight from '$resources/svgs/navi/arrow-right.svg';
import InlineSVG from 'react-inlinesvg';
import GTIconButton from '$src/components/shared/Atoms/GTIconButton';

interface IProps {
    parentHeadingLevel: number;
    news?: News[] | undefined;
}

export default function NewsPanel(props: IProps) {

    const [news, setNews] = useState<News[] | undefined>();
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [screenSizeType, setScreenSizeType] = useState<'small' | 'middle' | 'large'>("large");
    const newsComponent: JSX.Element[] = [];
    const newsComponentForCarousel: Array<{ size: number; element: JSX.Element }> = [];
    let _isMounted = false;

    function sortNews(a: any, b: any) {
        if (a.priortiy < b.priortiy) {
            return -1;
        }
        if (a.priortiy > b.priortiy) {
            return 1;
        }
        return 0;
    }

    function getScreenWidth(): number {
        const w = window;
        const d = document;
        const e = d.documentElement;
        const g = d.getElementsByTagName('body')[0];

        if (e != null) {
            return w.innerWidth || e.clientWidth || g.clientWidth;
        }
        else {
            return w.innerWidth || g.clientWidth;
        }
    }

    function resize() {
        let screenSize: 'small' | 'middle' | 'large' = screenSizeType;
        if (getScreenWidth() < 680) {
            screenSize = 'small';
        } else if (getScreenWidth() < 1440 && getScreenWidth() > 679) {
            screenSize = 'middle';
        } else {
            screenSize = 'large';
        }

        if (screenSize !== screenSizeType) {
            setScreenSizeType(screenSize);
            if (news != null) {
                const tmpNews = [...news];
                Promise.all(tmpNews.map((newsItem) => {
                    const imageID = newsItem.getImageNumber(screenSize);
                    if (imageID > 0) {
                        const url = MediaService.instance.getMediaUrl(parseInt(imageID.toString(10), 10));
                        newsItem.divStyle = { height: globalConfig.newsProperties.newsHeightInPx + 'px', backgroundImage: 'url(' + url + ')' };
                    }
                    else {
                        newsItem.divStyle = { height: globalConfig.newsProperties.newsHeightInPx + 'px' };
                    }
                }));
                if (_isMounted) {
                    setNews(tmpNews);
                }
            }
        }

    }

    useEffect(() => {
        window.addEventListener('resize', () => { resize });

        _isMounted = true;
        async function getNews() {
            const tmpNews = props.news != null ? props.news : await NewsService.instance.getNewsByUser(Session.instance.getUserLanguageCodeOrFallBack);
            if (isSuccess<News[]>(tmpNews)) {
                Promise.all(tmpNews.map((newsItem) => {
                    const imageID = newsItem.getImageNumber(screenSizeType);
                    if (imageID > 0) {
                        const url = MediaService.instance.getMediaUrl(parseInt(imageID.toString(10), 10));
                        newsItem.divStyle = { height: globalConfig.newsProperties.newsHeightInPx + 'px', backgroundImage: 'url(' + url + ')' }
                    }
                    else {
                        newsItem.divStyle = { height: globalConfig.newsProperties.newsHeightInPx + 'px' }
                    }
                    newsItem.html = newsItem.html.replace('%divHeight%', globalConfig.newsProperties.newsHeightInPx + 'px');
                }));
                tmpNews.sort(sortNews);
                if (_isMounted) {
                    setNews(tmpNews);
                }
            } else {
                if (tmpNews.detailedObject !== undefined) {
                    if (_isMounted) {
                        setErrorMsg(CustomErrorMessage.getErrorCodeMessageString(tmpNews.detailedObject.subStatusCode));
                    }
                } else {
                    if (_isMounted) {
                        setErrorMsg('ErrorMessage:NewsLoadingFailed');
                    }
                }
            }
        }
        getNews();
        return () => {
            window.removeEventListener('resize', () => { resize() });
            _isMounted = false;
        }

    })

    function HandleNewLinkClick(event: any) {
        if (event.target.href !== undefined) {
            let link = event.target.href;
            const target = event.target.target;
            event.preventDefault();
            event.stopPropagation();
            if (link.includes('$token')) {
                AuthService.instance.GetExternalAccessToken().then((resp: StringResponse) => {
                    link = link.replace('$token', resp.value);
                });
            }
            if (target != null && (target as string).toLocaleLowerCase().includes("_blank")) {
                const win = window.open();
                if (win != null) {
                    win.location.href = link;
                }
            }
            else {
                window.location.href = link;
            }
        }
    }

    function renderArrowButtons() {
        return (
            <>
                <GTIconButton
                    additionalClassNames='carousel__container__slide-prev--news'
                    id='SlidePrev'
                    ariaLabel={Session.instance.storage.translation.GetString('Carousel:PrevSlide')}
                    tooltipText={Session.instance.storage.translation.GetString('Carousel:PrevSlide')}
                    onClick={() => null}
                >
                    <InlineSVG src={ArrowLeft} />
                </GTIconButton>

                <GTIconButton
                    additionalClassNames='carousel__container__slide-next--news'
                    id='SlideNext'
                    ariaLabel={Session.instance.storage.translation.GetString('Carousel:NextSlide')}
                    tooltipText={Session.instance.storage.translation.GetString('Carousel:NextSlide')}
                    onClick={() => null}
                >
                    <InlineSVG src={ArrowRight} />
                </GTIconButton>
            </>
        );
    }

    if (news !== undefined) {
        news.map((news) => {
            if (news.size === 1) {
                newsComponent.push(
                    <div key={news.pkNews} onClick={(e) => HandleNewLinkClick(e)}
                        className="news__container news__container--small"
                        style={news.divStyle}
                        dangerouslySetInnerHTML={{ __html: news.html }}
                    />

                );
            } else if (news.size === 2) {
                newsComponent.push(
                    <div key={news.pkNews} onClick={(e) => HandleNewLinkClick(e)}
                        className="news__container news__container--large"
                        style={news.divStyle}
                        dangerouslySetInnerHTML={{ __html: news.html }}
                    />
                );
            }
        });
    } else if (errorMsg === '') {
        return <ProgressSpinner />;
    }
    if (globalConfig.newsProperties.showAsSlider) {
        if (news !== undefined) {
            news.map((news) => {
                if (news.size === 1) {
                    newsComponentForCarousel.push(
                        {
                            size: news.size, element: <div key={news.pkNews} onClick={(e) => HandleNewLinkClick(e)}
                                className="news__container news__container--small"
                                style={news.divStyle}
                                dangerouslySetInnerHTML={{ __html: news.html }}
                            />
                        }

                    );
                } else if (news.size === 2) {
                    newsComponentForCarousel.push(
                        {
                            size: news.size, element: <div key={news.pkNews} onClick={(e) => HandleNewLinkClick(e)}
                                className="news__container news__container--large"
                                style={news.divStyle}
                                dangerouslySetInnerHTML={{ __html: news.html }}
                            />
                        }
                    );
                }
            });

        }
    }

    return (
        <>
            {newsComponent.length > 0 || newsComponentForCarousel.length > 0 ?

                <div className="news__panel-background">
                    <div className="l-box-container news__panel-container" onClick={HandleNewLinkClick} >
                        <div>
                            {globalConfig.newsProperties.showAsSlider ?
                                <div className="news__inner-container-carousel carousel__container">
                                    <Swiper
                                       autoplay={globalConfig.newsProperties.autoPlay ? {delay: globalConfig.newsProperties.autoPlayInterval} : false}
                                        navigation={{
                                            nextEl: '.carousel__container__slide-next--news',
                                            prevEl: '.carousel__container__slide-prev--news',
                                        }}
                                        slidesPerView={"auto"}
                                        spaceBetween={0}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        loop={true}
                                        modules={[Navigation, Pagination, Autoplay]}
                                        slideNextClass={".carousel__container__slide-next--news"}
                                        slidePrevClass={".carousel__container__slide-prev--news"}
                                        className="mySwiper"
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1,
                                                spaceBetween: 0
                                            },
                                            698: {
                                                slidesPerView: "auto",
                                                spaceBetween: 0
                                            },
                                            1042: {
                                                slidesPerView: "auto",
                                                spaceBetween: 0
                                            }
                                        }}
                                    >

                                        {newsComponentForCarousel.map((news, index) => {
                                            return (
                                                <SwiperSlide key={index} className={news.size === 1 ? 'swiper__element--small' : 'swiper__element--large'}>
                                                    {news.element}
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                    {newsComponentForCarousel.length > 1 ? renderArrowButtons() : null}
                                </div>
                                : <div className="news__inner-container">{newsComponent}</div>}
                            <div>
                                <span className={'input-message error'}>
                                    <Translate>{errorMsg}</Translate>
                                </span>
                            </div>
                        </div>
                    </div>
                </div> : <></>}
        </>
    )

}