import { observer } from 'mobx-react';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Session from '$core/Session';
import { KeyPressHelper } from '$util/KeyPressHelper';

interface INavBurgerProps extends RouteComponentProps {
    onBurgerClicked?: () => void;
}
// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
@observer
class NavBurger extends React.Component<INavBurgerProps, {}> {
    protected navBurgerRef: React.RefObject<HTMLButtonElement> | undefined

    constructor(props: INavBurgerProps) {
        super(props);
        this.onBurgerClickedInternal = this.onBurgerClickedInternal.bind(this);
        this.navBurgerRef = React.createRef();
    }

    public render() {
        const burgerPopoutCssClassName = Session.instance.isSmallScreenMenuVisible ? ' popout' : '';
        if (!globalConfig.navigationProperties.backMenuEnabled || 
            globalConfig.navigationProperties.navigationItems.filter(i => i.link.endsWith(this.props.location.pathname)).length > 0) {
            return <button ref={this.navBurgerRef}
                className={'menu__hamburger button-link' + burgerPopoutCssClassName}
                onClick={this.onBurgerClickedInternal}
                onKeyUp={(e) => { KeyPressHelper.executeWhenSpaceOrEnter(e, () => this.onBurgerClickedInternal()) }}>
                <span className="menu__hamburger__line" />
                <span className="menu__hamburger__line" />
                <span className="menu__hamburger__line" />
            </button>
        }
        else { return null }
    }

    protected onBurgerClickedInternal() {
        if (this.props.onBurgerClicked) {
            this.props.onBurgerClicked();
            if (this.navBurgerRef != null && this.navBurgerRef.current != null) {
                this.navBurgerRef.current.blur();
            }
        }
    }
}

export default withRouter(NavBurger);