import { JsonObject, JsonProperty } from 'json2typescript';

import { File } from '$storage/models/File';
import { FileWithContent } from '$storage/models/FileWithContent';

// tslint:disable-next-line:max-classes-per-file
@JsonObject
export class GlobalDescription {

    @JsonProperty('name', String, false)
    public name: string = '';
    @JsonProperty('globalDescriptionFile', FileWithContent, false)
    public globalDescriptionFile: FileWithContent | null = null;
    @JsonProperty('files', [File], false)
    public files: File[] | null = null;
}
