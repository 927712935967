/* eslint-disable @typescript-eslint/camelcase */

/** Defines all types of lessons */
export enum EItemType {
    Undefined = 0, Folder, Document, WBT, Questionnaire, F2FCourse, Recording, Agenda, FileSharing, TrainingPlan, Assessment, CBT, Block
}

/** Defines all sub types of lessons */
export enum EItemSubType {
    Undefined = 0, Traditional = 1, FaceToFace = 2, Virtual = 3, External = 4, Standard = 5, Random = 6, Survey = 8
}

/** Defines all possible lesson status */
export enum ELessonStatus {
    Undefined = 0, NotAttempted, Incomplete, Completed, Failed, Passed,
}

/** Defines all possible registration status */
export enum ERegistrationStatus {
    Undefined = 0, NotRegistered, Requested, Accepted, InWaitingList, Rejected, Cancelled, ScheduleCancelled,
}

/** Status of a registration in the checkout process */
export enum ECheckoutRegistrationStatus {
    NotProcessed = 0, Succeeded = 1, Failed = 2, Canceled = 3
}

/** Status of a payment in the checkout process */
export enum ECheckoutPaymentStatus {
        /**  Payment processing has not yet started */
        NotProcessed = 0,
        /**  No payment needed for this checkout (voucher only) */
        NoPayment = 1,
        /**  Payment was just initiated, payment link created */
        Initiated = 2,
        /**  Payment returned and is in validation */
        Validation = 3,
        /**  Payment was canceled by user */
        CancelledByUser = 4,
        //** // Payment process timed out */
        TimedOut = 5,
        /**  Payment failed - with reason by payment provider */
        Failed = 6,
        /**  Payment completed */
        Completed = 98,
        /**  Payment failed via exception */
        Exception = 99
}

/** Defines all possible class status for F2F and training plan classes */
export enum EClassStatus {
    Undefined = 0, Open, Reserved, TakesPlace, Closed, Cancelled,
}

/** Defines all possible registration status */
export enum ERecoverLoginMode {
    GenerateRandomPassword = 1, SetNewPassword = 2, SendPin = 3, StartWorkflow = 4, UNKNOWN = 0,
}

/** Defines the possible enumeration type for attribute lists */
export enum EListingType {
    NoListingType = 0, PointList = 1, NumberedList = 2
}
/** Defines the possible value type of custom attributes */
export enum EValueType {
    Undefined = 0, String = 1, Integer = 2, Double = 3, Boolean = 4, DateTime = 5, Enum = 6, Image = 7, Date = 8, ExternalVideoLink = 9
}

/** Defines the possible f2f document types */
export enum EDocumentType {
    WithoutRestriction = 0,
    RegistrationRequestedOrAccepted = 1,
    RegistrationAccepted = 2,
    ClassTakesPlace = 3,
    ClassAfterRunningPhase = 4,
    GeneralTermsAndConditions = 5,
    OnlyForAdministrators = 6,
    Custom = 100
}

// SubError Codes for HttpErrorResponses -->Add the new StatusCode also to the converter SubStatusCodeConverter
export enum ESubStatusCodes {
    Unknown = 0,
    MethodNotAllowed_NewPinNotAllowed = 4051,
    MethodNotAllowed_PWResetNotAllowed = 4052,
    MethodNotAllowed_UpdateRegistration = 4053,
    MethodNotAllowed_RequestAuthToken = 4054,
    MethodNotAllowed_ExternalCourseRegistration = 4055,
    MethodNotAllowed_UserIsNotATrainer = 4056,
    MethodNotAllowed_PWRecoveryCooldown = 4057,
    MethodNotAllowed_EmptyDocumentUpload = 4058,
    MethodNotAllowed_MFA_Cooldown = 4059,
    NotFound_SetNewPassword_UserNotFound = 4041,
    NotFound_MFA_UserNotFound = 4042,
    NotFound_MFA_NotUnique = 4043,
    InternalServerError_SetNewPassword_LinkInvalid = 5001,
    InternalServerError_SetNewPassword_PINExpired = 5002,
    InternalServerError_SetNewPassword_PINInvalid = 5003,
    InternalServerError_SetNewPassword_LinkExpired = 5004,
    InternalServerError_SetNewPassword_MobileNrNotFound = 5005,
    InternalServerError_SetNewPassword_EmailAddressNotUnique = 5006,
    InternalServerError_SetNewPassword_CannotChangePublicPassword = 5007,
    InternalServerError_SetNewPassword_InvalidPassword = 5008,
    InternalServerError_SetNewPassword_PasswordIsToShort = 5009,
    InternalServerError_SetNewPassword_PasswordHasWrongSyntax = 50010,
    InternalServerError_SetNewPassword_PasswordIsInvalid = 50011,
    InternalServerError_SetNewPassword_PasswordIsInHistory = 50012,
    InternalServerError_SetNewPassword_PasswordIsToNew = 50013,
    InternalServerError_UserCertificates_GeneralError = 50014,
    InternalServerError_DocumentFiles_GeneralError = 50015,
    InternalServerError_GlobalDescription_GeneralError = 50016,
    InternalServerError_LessonFiles_GeneralError = 50017,
    InternalServerError_RequestAuthTokens_UserIsInvalidOrLocked = 50018,
    InternalServerError_ExternalCourseProviderSidAlreadyExists = 50019,
    InternalServerError_ExternalCourseSidAlreadyExists = 50020,
    InternalServerError_ContactSystemAdmin = 50021,
    InternalServerError_Login_UserIsLocked = 50022,
    InternalServerError_Login_UserIsInvalid = 50023,
    InternalServerError_Login_UserIsInactive = 50024,
    InternalServerError_Login_LoginNotPermitted = 50025,
    InternalServerError_Login_UserNotFound = 50026,
    InternalServerError_Login_InvalidPassword = 50027,
    InternalServerError_SelfReg_MaxActiveUsersExceeded = 50028,
    InternalServerError_SelfReg_ValidationError = 50029,
    InternalServerError_LicenseRegRunOutError = 50030,
    InternalServerError_LicenseRegUsernameError = 50031,
    InternalServerError_LicenseRegEmailError = 50032,
    InternalServerError_LicenseRegWrongContractNumberError = 50033,
    NotSupported_UpdateRegistration = 5011,

    /// <summary>Captcha verification failed..</summary>
    RegisterNewUser_CaptchaVerificationFailed = 1000101,
    /// <summary>Username isn't valid.</summary>
    RegisterNewUser_UsernameNotValid = 1000102,
    /// <summary>Email isn't valid.</summary>
    RegisterNewUser_EmailNotValid = 1000103,
    /// <summary>Max user count for domain exceeded.</summary>
    RegisterNewUser_MaxUserCountForDomainExceeded = 1000104,
    /// <summary>Token for 2nd step of self-registration with 2 steps is expired.</summary>
    RegisterNewUser_TokenForSecondStepExpired = 1000105,
    /// <summary>Invalid token for 2nd step of self-registration with 2 steps.</summary>
    RegisterNewUser_InvalidTokenForSecondStep = 1000106,
    /// <summary>Second step of self-registration with 2 steps seems to be already completed.</summary>
    RegisterNewUser_SecondStepSeemsToBeCompleted = 1000107,
    /// <summary>Username is not unique, but is configured to be unique.</summary>
    RegisterNewUser_UsernameNotUnique = 1000108,
    /// <summary>Email is not unique, but is configured to be unique.</summary>
    RegisterNewUser_EmailNotUnique = 1000109,
    /// <summary>Password doesn't fulfill complexity rules.</summary>
    RegisterNewUser_PasswordComplexityFailed = 1000110,
    /// <summary>Password was alread used in the past.</summary>
    RegisterNewUser_PasswordIsInHistory = 1000111,
    /// <summary>Password is too new (?).</summary>
    RegisterNewUser_PasswordIsTooNew = 1000112,
    /// <summary>Password is to short.</summary>
    RegisterNewUser_PasswordIsTooShort = 1000113,
    /// <summary>Password is invalid (unknown password error).</summary>
    RegisterNewUser_InvalidPassword = 1000114,
    /// <summary>Unnown error.</summary>
    RegisterNewUser_UnknownError = 1000115,
}

export enum ECertificateType {
    Undefined = 0,
    Skill = 1,
    Competence = 2,
    Lesson = 3,
    TPlan = 4,
    TplanBlock = 5
}

export enum ECancellationPermission {
    Allowed = 0,
    WithWarning = 1,
    NotAllowed = 2
}
export enum ESortDirection {
    None,
    Up,
    Down,
}
export enum EWorkflowActivityStatus {
    /** The workflow activity was not yet started. */
    Locked = 0,
    /** The workflow activity is scheduled to be started. */
    Due = 1,
    /** The workflow activity was successfully completed. */
    Completed = 2,

}

export enum ECancellationType {
    /** why the class was cancelled. */
    Class = 1,
    /** why the registration was cancelled or rejected. */
    Enrolment = 2,
    /** why the student didn't attend. */
    Attendance = 3,
}

export enum EFavoriteType {
    Item = 1,
    CatalogFolder = 2,
    TPlanElement = 3
}

export enum ESkillStatus {
    Undefined = 0,
    // Status only for frontend (do not come from backend)
    NotAcquired = 1,
    LevelMissing = 2,
    // Original Status from backend
    Expired = 3,
    Valid = 4,
    ExpiredButValid = 5,
    Requested = 6,
    Declined = 7
}

//This enum used for the Performance Check
export enum ETargetSkillStatus {
    Undefined = 0,
    //When the Empyloee requested a new target skill
    Requested = 1,
    //When the Boss accepted the requested target skill from a emplyoee
    Accepted = 2,
    //When the Boss declined the requested target skill from a empyloee
    Declined = 3
}

export enum EPerformanceCheckStatus {
    Undefined = 0,
    // When the Empyloee requested a new skill or target skill
    Requested = 1,
    //When the Boss accepted the requested skill or target skill from a emplyoee
    Accepted = 2,
    //When the Boss declined the requested skill or target skill from a empyloee
    Declined = 3
}

/** Defines all possible item detail callers */
export enum EItemDetailCallerContextType {
    Undefined = 0,
    Catalog = 1,
    Assignment = 2,
    RecommendedItems = 3,
    MyLesson = 4,
    MyFavorites = 5,
    MyWatchlist = 6,
    RegisteredItem = 7,
    Search = 8,
    Recommendations = 9,
    MyCourses = 10,
    CourseRequests = 11,
    ExternalCourseCostReport = 12,
    MyFileSharing = 13,
    MyEmployees = 14,
    PerformanceReview = 15,
    TutoringReport = 16,
    PerformanceSupport = 17,
    TrainerBookingView = 18,
    MySkills = 19,
    MyPerformanceSupport = 20,
    PowerBiReports = 21,
    Privacy = 22,
    MyRecommendationsByInterests = 23,
    BookingOverview = 24,
    MySubjectSubscriptionsEditView = 25,
    VoucherValidation = 26,
    PaymentCampaignOverview = 27,
    PurchasedItemsList = 28,
    PerformanceCheck = 29,
    MyGlossary = 30,
    ExternalCourse = 31, // used only to convert to EItemSecurityContext.ExternalCourse
    TrainingPlan = 32,
    ItemDetail = 33,
    RequirementsOverview = 34
}

/** Context in which a item is requested, is used to speed up the access right check for lesson security */
export enum EItemSecurityContext {
    Undefined = 0,
    CatalogAccess = 1,
    Assignment = 2,
    MyLesson = 3,
    RegisteredItem = 6,
    MyFileSharing = 8,
    PerformanceReports = 9,
    PerformanceReportsBoss = 10,
    TutoringReport = 11,
    TrainerBookingView = 12,
    PurchasedItems = 13,
    ExternalCourse = 14,
    BossReporting = 15,
}

export enum EItemDetailCallerType {
    Item = 0,
    TPlan = 1,
    ExternalCourse = 2,
    PerformanceCheck = 3
}

export enum EAttendanceStatus {
    Undefined = 0,
    NotAttended = 1,
    Attended = 2
}

/** Uploaded file types, used for external course conclusion step */
export enum EFileType {
    Undefined = 0,
    Certificate = 1,
    Confirmation = 2,
    Miscellaneous = 3
}

export enum EGlossaryAssociationType {
    UserPinned = 1,
    Config = 2,
    Groups = 3,
    Item = 4
}

// Defines all possible lesson stages
export enum EAvailablePlaces {
    undefined = 0, NoParticpantsLimit, NoPlaces, WaitingList, FreePlaces, OneFreePlace
}

// Defines validation status for user properties in self registration
export enum EValidationStatus {
    Success,
    Warning,
    Error
}

export enum ELessonFilterContext {
    undefined = 0, Generic, MyLessons, MyCourses, MyTrainingPlans
}

export enum EEmailCheckMode {
    None = 0,
    WarnIfNotUnique = 1,
    ErrorIfNotUnique = 2
}


// Defines Day Modes: 
export enum ERelativeTimeSettingsDayMode {
    DaysLater = 2, WorkingDaysLater = 4
}
/**
 * Defines Time Setting Modes
 */
export enum ERelativeTimeSettingMode {
    Absolute = 1, Relative
}

export enum ESkillType {
    ActualSkill = 0,
    TargetSkill = 1,
    PerformanceReviewSkill = 2,
}

/**
 * Well known types of AttributeValues.
 * See also Twi.Gt.PersistentObjects.KnownAttributeType (serve-side).
 */
export enum EAttributeType {
    NativeLanguage = 10,
    Country = 11,
    ItemType = 12,
    Subjects = 16,
    UserAttribute1 = 24,
    UserAttribute2 = 25,
    UserAttribute3 = 26,
    UserAttribute4 = 27,
    UserAttribute5 = 28,
    GroupType = 45,
    LOM_Classification = 46,
    LOM_LifeCycle_Status = 47,
    LOM_Educational_InteractivityType = 48,
    LOM_Educational_LearningResourceType = 49,
    LOM_Educational_IntendedEndUserRole = 50,
    LOM_Educational_Context = 51,
    LOM_Rights_Cost = 52,
    LOM_Rights_CopyrightAndOtherRestrictions = 53,
    BossGroupType = 59,
    UILanguage = 60,
    F2FInventoryType = 62,
    F2FRoomType = 63,
    F2FDocumentType = 64,
    SkillType = 65,
    SkillProfileType = 66
}

/** Defines the user skill comment type used for performance review */
export enum EUserSkillCommentType {
    Undefined = 0,
    MySkill_Boss = 1,
    MySkill_User = 2,
    MyPotential_Boss = 3,
    MyPotential_User = 4,
    MyTargets = 5
}

/**
 * This enum is used either as parameter for the overview site, or as LinkType in the response model.
 */
export enum EVirtualMeetingsViewType {
    All = 0,
    Trainer = 1,
    Student = 2
}

/**
 * Status of users terms and conditions aggreement
 */
export enum ETCApprovalStatus {
    Any = 0,
    None = 1,
    Accepted = 2,
    Refused = 3,
    DisabledByGroup = 4,
}

/**
 * Method how user approved the terms and conditions
 */
export enum ETCApprovalMethod {
    Any = 0,
    None = 1,
    Digital = 2,
    Paper = 3,
    Email = 4,
    Phone = 5,
    DisabledByGroup = 6,
}

/**
 * Method how user approved the terms and conditions
 */
export enum ERecommendedItemsByUserInterestsOrdering {
    Any = 0,
    Subject = 1,
    CreatedDate = 2,
    Rating = 3,
    LessonsStatusCount = 4
}

/** Defines all types of lessons as Attribute Values */
export enum EItemTypeAttributeValue {
    Undefined = 0,
    Folder = 1164,
    Document = 1051,
    WBT = 1252,
    Questionnaire = 1222,
    F2FCourse = 1050,
    Recording = 1278,
    FileSharing = 1492,
    TrainingPlan = 1185,
    Assessment = 1310,
    CBT = 1048,
    Block = 10000003,
}

/** Defines all default colors of NSUI */
export enum EColor {
    Undefined = 0,
    BaseColor = 1,
    Highlight = 2,
    HighlightDark = 3,
    Error = 4,
}

/** Defines the possible reasons for voucher invalidity */
export enum EVoucherInvalidityReason {
    Undefined = 0,
    InvalidCode = 1,
    AlreadyRedeemed = 2,
    ValidityPeriodExpired = 3,
    Revoked = 4,
    ValidationFailed = 5, // used for unexpected validation errors
}

/** Immediate result state of a checkout operation */
export enum ECheckoutStatus {
    Pending = 0,
    VoucherFailed = 1,
    PaymentFailed = 2,
    RegistrationFailed = 3,
    Succeeded = 4,
}

/** Type of payment checkout item history to see what kind of action was made */
export enum EPaymentCheckoutItemHistoryType {
    Unknown = 0,
    UserChanged = 1,
    ClassChanged = 2,
    Cancelled = 3
}

/** Severity of a checkout event message */
export enum ECheckoutEventSeverity {
    Information = 0,
    Warning = 1,
    Error = 2,
}

/** Category/source of a checkout event message */
export enum ECheckoutEventCategory {
    Voucher = 0,
    Payment = 1,
    Registration = 2
}

/** 
 * Defines how the payment link should be used in the SUI 
 * Note: Enum must match the according enum in GTServices and PaymentGateway */
export enum EPayLinkUsage {
    SameTab = 1,
    NewTab = 2,
    IFrame = 3,
    Webhook = 4
}

/** Defines the type of the registration request, i.e. how to create/register a new GT user (C# type: GTServices.Models.UserRegistration.RegisterNewUserRequest.ERegistrationRequestType). */
export enum ERegistrationRequestType {
    /** Should never be used. */
    Undefined = 0,
    /** Single-Step self-Registration. */
    SingleStepSelfRegistration = 1,
    /** 1st step of a 2-step self-registration. */
    FirstStepOfTwoStepSelfRegistration = 2,
    /** 2nd step of a 2-step self-registration. */
    SecondStepOfTwoStepSelfRegistration = 3,
    /** Boss is registering a new user. */
    RegistrationByBoss = 4,
}
/** Defines all breakpoints */
export enum EBreakPoint {
    Undefined = 0,
    Tiny = 1,
    Small = 2,
    Medium = 3,
    Large = 4,
    ExtraLarge = 5,
}

/** Response status of a request */
export enum EResponseStatus {
    Accepted = 0,
    Declined = 1,
}

/** Config value for displaying the lead time of an vcg start time */
export enum EDisplayVcgLeadTime {
    None = 0,
    Integrated = 1,
    Column = 2
}

/** The sorting modes for file sharing documents */
export enum EFileSharingSortEnum {
    Manually = 1,
    Ascending = 2,
    Descending = 3
}
/** The sorting modes for file sharing documents */
export enum EGamificationPointOrigin {
    Unknown = 0,
    SkillAquired = 1,
    RatingSubmitted = 2,
    LessonStatusChanged = 3
}

export enum ELifeCycle {
    Development = 1,
    Production = 2,
    Obsolete = 3
}