
import { Heading } from '$components/shared/Heading';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import SkillService from '$src/core/Services/SkillService';
import Session from '$src/core/Session';
import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import { UserSkillProfile } from '$src/storage/models/SkillProfile/UserSkillProfile';
import { UserSkillProfileSkill } from '$src/storage/models/SkillProfile/UserSkillProfileSkill';
import { UserSkillProfileSkillContainer } from '$src/storage/models/SkillProfile/UserSkillProfileSkillContainer';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { UnmountClosed } from 'react-collapse';
import { Translate } from '$components/shared/Translate';
import { TargetSkills } from '$components/Skills/TargetSkills';
import { AssignSkillProfileModal } from '$components/myEmployees/actionModals/AssignSkillProfileModal';
import { User } from '$src/storage/models/User';
import { AssignSkillsModal } from '$components/myEmployees/ActionModals/AssignSkillsModal';
import { AssignSkillProfileResponse } from '$src/storage/models/SkillProfile/AssignSkillProfileResponse';
import { ESkillType, EItemDetailCallerContextType, ESkillStatus } from '$src/storage/models/enums';
import { AssignUserSkillResponse } from '$src/storage/models/AssignUserSkillResponse';
import InlineSVG from 'react-inlinesvg';
import IconComplete from '$resources/svgs/status/profile_complete.svg';
import IconIncomplete from '$resources/svgs/status/profile_incomplete.svg';
import { Tooltip } from '$components/shared/Tooltip';
import { IPanelState } from "$storage/models/UserPreferences/IPanelState";
import { RemoveTargetProfilesModal } from '$src/components/myEmployees/ActionModals/RemoveTargetProfilesModal';
import { RemoveSkillsModal } from '$src/components/myEmployees/ActionModals/RemoveSkillsModal';
import Logger from '$src/core/Logger';

interface IProps {
    context: EItemDetailCallerContextType;
    user: User;
    isBossView?: boolean;
    userSkillProfiles?: UserSkillProfile[];
    userTargetSkillContainer?: UserSkillProfileSkillContainer;
    achievedUserSkills?: UserSkill[];
    parentHeadingLevel: number;
    bossRelationCode?: string;
}

interface IState {
    isDataLoaded: boolean;
    userSkillProfiles: UserSkillProfile[];
    userTargetSkillConainer: UserSkillProfileSkillContainer;
    achievedUserSkills: UserSkill[];
    errorMessage: string;
    preselectedSkillId?: number;
    preselectedLevelId?: number;
    preselectedLevelSetId?: number;
    profilePanel: IPanelState[];
    showAssignSkillsModal: boolean;
    showSkillProfileModal: boolean;
    showDeleteTargetSkillsModal: boolean;
    showDeleteSkillProfilesModal: boolean;
    skillProfileTypes: { typeId: number; typeTranslation: string }[];
    assignSkillTypeValue: ESkillType;
    user: User;
}

export class PerformanceSupport extends React.Component<IProps, IState> {
    protected className = 'PerformanceSupport';
    protected loggerLocality = 'Components.PerformanceSupport';

    constructor(props: IProps) {
        super(props);

        this.state = {
            achievedUserSkills: this.props.achievedUserSkills ? this.props.achievedUserSkills : [],
            errorMessage: '',
            isDataLoaded: false,
            preselectedSkillId: undefined,
            preselectedLevelId: undefined,
            preselectedLevelSetId: undefined,
            profilePanel: [],
            showAssignSkillsModal: false,
            showSkillProfileModal: false,
            showDeleteSkillProfilesModal: false,
            showDeleteTargetSkillsModal: false,
            skillProfileTypes: [],
            assignSkillTypeValue: ESkillType.TargetSkill,
            userSkillProfiles: this.props.userSkillProfiles ? this.props.userSkillProfiles : [],
            userTargetSkillConainer: this.props.userTargetSkillContainer ? this.props.userTargetSkillContainer : new UserSkillProfileSkillContainer,
            user: this.props.user ? this.props.user : Session.instance.loginUser!
        }
    }

    public async componentDidMount() {
        if (!this.props.isBossView) {
            document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('PerformanceSupport:Title');
        }
        const allPromises = new Array<Promise<unknown>>();
        if (this.props.achievedUserSkills == null) {
            allPromises.push(this.getUserSkills());
        }
        if (this.props.userSkillProfiles == null) {
            allPromises.push(this.getUserSkillProfile());
        } else {
            this.setProfilePanel(this.props.userSkillProfiles);
            const skillProfileTypes = this.extractSkillProfileTypes(this.props.userSkillProfiles);
            this.setState({ skillProfileTypes });
        }
        if (this.props.userTargetSkillContainer == null) {
            allPromises.push(this.getUserTargetSkills());
        }
        await Promise.all(allPromises);

        this.setState({ isDataLoaded: true });
    }

    /**
     * To check if the achieved user skill props did change and assign it to state for a correct update of the skill tables
     * @param nextProps Props for the next render
     */
    public shouldComponentUpdate(nextProps: IProps) {
        if (nextProps.achievedUserSkills !== this.props.achievedUserSkills) {
            this.setState({ achievedUserSkills: nextProps.achievedUserSkills ? nextProps.achievedUserSkills : [] })
        }
        return true;
    }

    public render() {
        if (!this.state.isDataLoaded) {
            return (
                <ProgressSpinner />
            )
        }
        else if (this.props.isBossView) {
            return (
                <React.Fragment>
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level3">
                        <Translate>PerformanceSupport:TargetSkillsTitle</Translate>
                    </Heading>
                    {this.renderTargetSkills()}

                    <div className="employee-detail__actions">
                        <div className="employee-detail__action">
                            <button
                                className="btn--md btn--primary"
                                onClick={() => this.setState({ showAssignSkillsModal: true, assignSkillTypeValue: ESkillType.TargetSkill, preselectedSkillId: undefined, preselectedLevelSetId: undefined })}>
                                {Session.instance.storage.translation.GetString('EmployeeDetail:AssignTargetSkillButton')}
                            </button>
                        </div>
                        {this.isTargetSkillRemovalOptionEnabled() &&
                            <div className="employee-detail__action">
                                <button
                                    className="btn--md btn--primary"
                                    onClick={() => this.setState({ showDeleteTargetSkillsModal: true, assignSkillTypeValue: ESkillType.TargetSkill, preselectedSkillId: undefined, preselectedLevelSetId: undefined })}>
                                    {Session.instance.storage.translation.GetString('EmployeeDetail:DeleteTargetSkillButton')}
                                </button>
                            </div>
                        }
                    </div>

                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level3">
                        <Translate>PerformanceSupport:TargetProfilesTitle</Translate>
                    </Heading>
                    {this.state.userSkillProfiles.length !== 0 ?
                        this.renderSkillProfileTypes() :
                        <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceSupport:NoTargetProfilesFound')} />
                    }


                    <div className="employee-detail__actions">
                        <div className="employee-detail__action">
                            <button
                                className="btn--md btn--primary"
                                onClick={() => this.setState({ showSkillProfileModal: true })}>
                                {Session.instance.storage.translation.GetString('EmployeeDetail:AssignProfileButton')}
                            </button>
                        </div>
                        {this.isTargetProfileRemovalOptionEnabled() &&
                            <div className="employee-detail__action">
                                <button
                                    className="btn--md btn--primary"
                                    onClick={() => this.setState({ showDeleteSkillProfilesModal: true })}>
                                    {Session.instance.storage.translation.GetString('EmployeeDetail:DeleteTargetProfileButton')}
                                </button>
                            </div>
                        }
                    </div>

                    {this.state.showDeleteTargetSkillsModal &&
                        <RemoveSkillsModal
                            isOpen={this.state.showDeleteTargetSkillsModal}
                            onClose={() => this.setState({ showDeleteTargetSkillsModal: false })}
                            headingLevel={2}
                            translationPrefix="RemoveTargetSkills"
                            isForTargetSkills={true}
                            headingClassName="heading__Level2"
                            userTargetSkills={this.state.userTargetSkillConainer.userSkillProfileSkills}
                            employees={[this.state.user]}
                            onTargetSkillRemovalSuccess={() => this.onTargetSkillRemovalSuccess()}
                            bossRelationCode={this.props.bossRelationCode ?? ''} />
                    }
                    {this.state.showDeleteSkillProfilesModal &&
                        <RemoveTargetProfilesModal
                            headingLevel={2}
                            userTargetProfiles={this.state.userSkillProfiles}
                            headingClassName="heading__Level2"
                            employees={[this.state.user]}
                            isOpen={this.state.showDeleteSkillProfilesModal}
                            onProfileRemovalSuccess={() => this.onProfileRemovalSuccess()}
                            onClose={() => this.setState({ showDeleteSkillProfilesModal: false })} 
                            bossRelationCode={this.props.bossRelationCode ?? ''}/>

                    }
                    {this.state.showAssignSkillsModal &&
                        <AssignSkillsModal
                            headingLevel={2}
                            headingClassName="heading__Level2"
                            isOpen={this.state.showAssignSkillsModal}
                            onAssignSuccess={(assignments, type) => this.onTargetSkillAssignmentSuccess(assignments, type)}
                            onClose={() => this.setState({ showAssignSkillsModal: false })}
                            preselectedSkillId={this.state.preselectedSkillId}
                            preselectedSkillLevelId={this.state.preselectedLevelId}
                            preselectedSkillLevelSetId={this.state.preselectedLevelSetId}
                            selectedEmployees={[this.state.user]}
                            skillTypeValue={this.state.assignSkillTypeValue} 
                            bossRelationCode={this.props.bossRelationCode ?? ''} />}

                    {this.state.showSkillProfileModal &&
                        <AssignSkillProfileModal
                            isOpen={this.state.showSkillProfileModal}
                            onAssignSuccess={(assignments) => this.onProfileAssignmentSuccess(assignments)}
                            onClose={() => this.setState({ showSkillProfileModal: false })}
                            employees={[this.state.user]}
                            headingLevel={2}
                            headingClassName="heading__Level2" 
                            bossRelationCode={this.props.bossRelationCode ?? ''}/>}
                </React.Fragment>
            )
        } else {
            return (
                <div className="l-container">
                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level2">
                        <Translate>PerformanceSupport:TargetSkillsTitle</Translate>
                    </Heading>
                    {this.renderTargetSkills()}

                    <Heading headingLevel={this.props.parentHeadingLevel} cssClass="heading__Level2">
                        <Translate>PerformanceSupport:TargetProfilesTitle</Translate>
                    </Heading>
                    {this.state.userSkillProfiles.length !== 0 ?
                        this.renderSkillProfileTypes() :
                        <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceSupport:NoTargetProfilesFound')} />
                    }
                </div>)
        }
    }

    private renderTargetSkills(): JSX.Element {
        return (
            <div className="performanceSupport__target-box">
                <TargetSkills
                    bossRelationCode={this.props.bossRelationCode}
                    context={this.props.context}
                    onAssignPreselectedSkill={(preselectedSkillId, preselectedLevelSetId, requiredSkillLevelId) => this.onAssignPreselectedSkill(preselectedSkillId, preselectedLevelSetId, requiredSkillLevelId)}
                    targetSkillContainer={this.state.userTargetSkillConainer}
                    userSkills={this.state.achievedUserSkills}
                    userId={this.props.user.id}
                    isProfile={false} />
            </div>
        );
    }

    private renderSkillProfileTypes(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        this.state.skillProfileTypes.map(profileType => {
            elements.push(
                <div className="performanceSupport__target-box" key={profileType.typeId}>
                    <Heading headingLevel={this.props.parentHeadingLevel + 1} cssClass="heading__Level4">
                        {profileType.typeTranslation}
                    </Heading>
                    {this.renderSkillProfiles(this.state.userSkillProfiles.filter(usp => usp.skillProfileTypeId === profileType.typeId))}
                </div>
            )
        });

        return elements;
    }

    private renderSkillProfiles(profiles: UserSkillProfile[]): JSX.Element[] {
        const elements: JSX.Element[] = [];
        profiles.map(profile => {
            elements.push(
                <div className="performanceSupport__targetProfile-box" key={profile.userSkillProfileId}>
                    <HeadingCollapsible
                        headingLevel={this.props.parentHeadingLevel + 2}
                        containerCssClass="performanceSupport__skillHeaderContainer "
                        headingCssClass="heading__Level5 performanceSupport__HeadingCollapsible-width"
                        onToggleOpenedState={() => this.toggleProfile(profile.userSkillProfileId)}
                        isOpened={Session.instance.storage.ipanelState.getPanelState("SkillProfile" + profile.userSkillProfileId)}>
                        <div className="performanceSupport__skillHeaderGrid">
                            <div className="performanceSupport__skillProfileTitle">{profile.skillProfileTitle}</div>
                            {this.percentageProgress(profile.skillProfileId, profile.skillProfileTitle, profile.skillContainer?.userSkillProfileSkills)}
                        </div>
                    </HeadingCollapsible>
                    <UnmountClosed isOpened={Session.instance.storage.ipanelState.getPanelState("SkillProfile" + profile.userSkillProfileId)}>
                        <TargetSkills
                            bossRelationCode={this.props.bossRelationCode}
                            context={this.props.context}
                            onAssignPreselectedSkill={(preselectedSkillId, preselectedLevelSetId, requiredSkillLevelId) => this.onAssignPreselectedSkill(preselectedSkillId, preselectedLevelSetId, requiredSkillLevelId)}
                            targetSkillContainer={profile.skillContainer}
                            userSkills={this.state.achievedUserSkills}
                            userId={this.props.user.id}
                            skillProfileId={profile.skillProfileId}
                            isProfile={true} />
                    </UnmountClosed>
                </div>
            )
        });

        return elements;
    }

    private async getUserSkillProfile(forceReload = false): Promise<void> {
        const response = await Session.instance.storage.userSkillProfile.getUserSkillProfiles(this.state.user.id, forceReload, this.props.bossRelationCode);
        const errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadProfiles');
        if (isSuccess<UserSkillProfile[] | null>(response)) {
            if (response != null) {
                this.setProfilePanel(response);
                const skillProfileTypes = this.extractSkillProfileTypes(response);
                this.setState({ skillProfileTypes, userSkillProfiles: response });
            } else {
                Session.instance.setLastErrorMessage(errorMessage);
                this.setState({ errorMessage })
            }

        } else {
            Session.instance.setLastErrorMessage(errorMessage);
            this.setState({ errorMessage })
        }
    }

    private async getUserTargetSkills(forceReload = false): Promise<void> {
        const response = await Session.instance.storage.userTargetSkill.getUserTargetSkills(this.state.user.id, forceReload, this.props.bossRelationCode);
        const errorMessage = Session.instance.storage.translation.GetString('PerformanceReview:ErrorToLoadTargetSkills');
        if (isSuccess<UserSkillProfileSkillContainer | null>(response)) {
            if (response != null) {
                this.setState({ userTargetSkillConainer: response });
            } else {
                Session.instance.setLastErrorMessage(errorMessage);
                this.setState({ errorMessage })
            }

        } else {
            Session.instance.setLastErrorMessage(errorMessage);
            this.setState({ errorMessage })
        }
    }

    private async getUserSkills(): Promise<void> {
        const lang = Session.instance.getUserLanguageCodeOrFallBack;
        const userId = this.state.user ? this.state.user.id : Session.instance.loginUser!.id;
        const response = await SkillService.instance.getUserSkills(lang, userId);
        if (isSuccess<UserSkill[]>(response)) {
            this.setState({
                achievedUserSkills: response
            })
        }
        else {
            Session.instance.setLastErrorMessage(response.message);
            this.setState({ errorMessage: response.message });
        }
    }

    private toggleProfile(profileId: number) {
        const profilePanel: IPanelState[] = this.state.profilePanel;
        const panel = profilePanel.find(pnl => Number.parseInt(pnl.panelId) === profileId);
        panel!.isOpened = Session.instance.storage.ipanelState.isExpanded("SkillProfile" + profileId);
        this.setState({ profilePanel });
    }

    private extractSkillProfileTypes(userSkillProfiles: UserSkillProfile[]): { typeId: number; typeTranslation: string }[] {
        const skillProfileTypes: { typeId: number; typeTranslation: string }[] = [];
        userSkillProfiles.map(
            usp => {
                if (usp.skillProfileTypeId !== 0 && skillProfileTypes.findIndex(spt => spt.typeId === usp.skillProfileTypeId) === -1) {
                    skillProfileTypes.push({ typeId: usp.skillProfileTypeId, typeTranslation: usp.skillProfileType });
                }
            }
        );
        skillProfileTypes.sort((a, b) => (a.typeTranslation > b.typeTranslation) ? 1 : ((b.typeTranslation > a.typeTranslation) ? -1 : 0));
        if (userSkillProfiles.findIndex(usp => usp.skillProfileTypeId === 0) !== -1) {
            skillProfileTypes.push({ typeId: 0, typeTranslation: Session.instance.storage.translation.GetString('PerformanceSupport:MoreTargetProfilesTitle') });
        }

        return skillProfileTypes;

    }

    private setProfilePanel(userSkillProfiles: UserSkillProfile[]): void {
        const profilePanel: IPanelState[] = this.state.profilePanel;
        userSkillProfiles.map(profile => {
            profilePanel.push({ panelId: String(profile.userSkillProfileId), isOpened: false });
        });
    }

    private onProfileAssignmentSuccess(assignments: AssignSkillProfileResponse[]) {
        assignments.map(async assignment => {
            if (assignment.userId === this.state.user.id && assignment.assigned) {
                await this.getUserSkillProfile(true);
                return;
            }
        });
    }

    private onProfileRemovalSuccess() {
        this.getUserSkillProfile(true);
        return;
    }

    private onTargetSkillRemovalSuccess() {
        const methodName = `${this.className}:onTargetSkillRemovalSuccess()`;
        Logger.log(this.loggerLocality, `${methodName} called`);
        this.getUserTargetSkills(true);
        return;
    }

    private onTargetSkillAssignmentSuccess(assignments: AssignUserSkillResponse, type: ESkillType) {
        assignments.employeeIdsWithSuccess.map(async employee => {
            if (employee === this.state.user.id) {
                if (type === ESkillType.ActualSkill) {
                    await this.getUserSkills();
                } else {
                    await this.getUserTargetSkills(true);
                }
                return;
            }
        });

    }

    private onAssignPreselectedSkill(preselectedSkillId: number, preselectedLevelSetId: number | null, requiredSkillLevelId: number | null) {
        this.setState({
            preselectedSkillId,
            assignSkillTypeValue: ESkillType.ActualSkill,
            preselectedLevelId: requiredSkillLevelId != null ? requiredSkillLevelId : undefined,
            preselectedLevelSetId: preselectedLevelSetId != null ? preselectedLevelSetId : undefined,
            showAssignSkillsModal: true
        });
    }

    private percentageProgress(profileId: number, profileTitle: string, profileTargetSkills?: UserSkillProfileSkill[]): JSX.Element {
        let achievedCounter = 0;
        //profiletargetSkills only contains skills where user has show or assign right
        const totalCount = profileTargetSkills?.length; // Filter out skills to which the boss has no access
        let result = 0;
        profileTargetSkills?.map(profileTargetSkill => {
            const userSkill = this.state.achievedUserSkills.find(us => us.skillId === profileTargetSkill.skillId);
            if (userSkill != null && userSkill.skillLevelValue >= profileTargetSkill.skillProfileSkillLevelValue) {
                if ((userSkill.skillStatus === ESkillStatus.Expired === profileTargetSkill.mayBeExpired) || (userSkill.skillStatus === ESkillStatus.Valid)) {
                    achievedCounter++;
                }
            }
        });

        if (totalCount === 0) {
            result = 100;
        } else {
            if (totalCount !== undefined) {
                result = Math.round(achievedCounter / totalCount * 100);
            }
        }

        return (
            <div className="performanceSupport__percentageProgress-box">
                {result === 100 ? <div className="performanceSupport__percentageProgress-icon-complete"
                    aria-label={Session.instance.storage.translation.GetString("PerformanceSupport:percentageComplete").Format(profileTitle)}
                    data-tip={Session.instance.storage.translation.GetString("PerformanceSupport:percentageComplete").Format(profileTitle)}
                    data-for={`${profileId}_percentProgress`}><InlineSVG src={IconComplete} /><Tooltip id={`${profileId}_percentProgress`} /></div> :
                    <div className="performanceSupport__percentageProgress-icon-incomplete"
                        aria-label={Session.instance.storage.translation.GetString("PerformanceSupport:percentageIncomplete").Format(profileTitle)}
                        data-tip={Session.instance.storage.translation.GetString("PerformanceSupport:percentageIncomplete").Format(profileTitle)}
                        data-for={`${profileId}_percentProgress`}><InlineSVG src={IconIncomplete} /><Tooltip id={`${profileId}_percentProgress`} /></div>}
                {result === 100 ? <div className="performanceSupport__percentageProgress-box-font-complete">{result} %</div> :
                    <div className="performanceSupport__percentageProgress-box-font-incomplete">{result} %</div>}
            </div>
        )
    }

    private isTargetSkillRemovalOptionEnabled(): boolean {
        const relationConfiguration = globalConfig.myTeamProperties.relationConfiguration.find(relation => relation.id === this.props?.bossRelationCode);
        return relationConfiguration ? relationConfiguration.showTargetSkillRemovalOption : false;
    }

    private isTargetProfileRemovalOptionEnabled(): boolean {
        const relationConfiguration = globalConfig.myTeamProperties.relationConfiguration.find(relation => relation.id === this.props?.bossRelationCode);
        return relationConfiguration ? relationConfiguration.showTargetProfileRemovalOption : false;
    }
}