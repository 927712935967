import { JsonObject, JsonProperty } from 'json2typescript';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import { EItemSubType, EItemType } from '$storage/models/enums';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { SearchResultCatalogPath } from '$src/storage/models/search/elk/SearchResultCatalogPath';
import { ItemSubTypeConverter } from '$src/storage/models/converters/ItemSubTypeConverter';

/** Model Class for the search function result list.
 */
@JsonObject('SearchResult')
export class SearchResult {

    @JsonProperty('itemid', Number, false)
    public itemId: number = 0;

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('summary', String, false)
    public summary: string = '';

    @JsonProperty('subjects', [String], false)
    public subjects: string[] = [];

    /** Type of the item. */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType = EItemType.Undefined;

     /** Sub type of the item. */
     @JsonProperty('itemSubType', ItemSubTypeConverter, false)
     public itemSubType: EItemSubType = EItemSubType.Undefined;

    @JsonProperty('from', DateTimeConverter, false)
    public from: Date | undefined = undefined;


    @JsonProperty('to', DateTimeConverter, false)
    public to: Date | undefined = undefined;

    @JsonProperty('catalogParents', [SearchResultCatalogPath], false)
    public catalogParents: SearchResultCatalogPath[] = [];

    @JsonProperty('itmTPId', Number, false)
    public itmTPId: number = 0;

    @JsonProperty('itmTPTitle', String, false)
    public itmTPTitle: string = '';

    

}