
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class FileSharingTag {

    @JsonProperty('id', Number)
    public id: number = 0;

    @JsonProperty('tagName', String)
    public tagName: string = '';
}