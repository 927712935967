
import { ItemsReportResponse } from '$storage/models/Reports/ItemsReportResponse';
import ServiceClient from '$core/ServiceClient';
import GtError from '$util/GtError';
import { EItemType } from '$storage/models/enums';
import { ReportRequest } from '$storage/models/RequestObjects/ReportRequest';

/**
 * Provides all Service Methods needed for Reports
 */
export default class TutoringReportService extends ServiceClient {

    protected static _instance: TutoringReportService | null = null;
    protected className = 'TutoringReportService';
    protected loggerLocality = 'TutoringReportService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): TutoringReportService {
        return this._instance || (this._instance = new this());
    }


    public async getItems(language: string, aclRight: number, pageSize: number, pageNumber: number, itemType: EItemType, subjectId: number, searchValue: string): Promise<ItemsReportResponse[] | GtError> {
        const requestObject: ReportRequest = {
            aclId: aclRight,
            language,
            pageSize,
            pageNumber,
            itemType,
            subjectId,
            searchValue
        }
        const response = await this.post<ItemsReportResponse[]>(
            'tutoringReport/getItems',
            requestObject,
            ItemsReportResponse);
        return response;
    }
}