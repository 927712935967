import { DocumentTypeConverter } from '$src/storage/models/converters/DocumentTypeConverter';
import { EDocumentType } from '$src/storage/models/enums';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class F2FDocument {

    /** Id of the corresponding document */
    @JsonProperty('courseDocumentId', Number, false)
    public courseDocumentId: number = 0;

    /** Course Id of the corresponding document, can be 0 if it's a class document */
    @JsonProperty('courseId', Number, false)
    public courseId: number = 0;

    /** Sid from course of the corresponding document */
    @JsonProperty('sid', String, false)
    public sid: string = '';

    /** Class Id of the corresponding document, can be 0 if it's a course document */
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    /** Name of the corresponding document */
    @JsonProperty('name', String, false)
    public name: string = '';

    /** Filename of the corresponding document */
    @JsonProperty('filename', String, false)
    public filename: string = '';

    /** Link of the corresponding document */
    @JsonProperty('link', String, false)
    public link: string = '';

    /** Document Type Id of the corresponding document */
    @JsonProperty('documentTypeId', Number, false)
    public documentTypeId: number = 0;

    /** DocumentType Converter of the corresponding document */
    @JsonProperty('documentType', DocumentTypeConverter, false)
    public documentType: EDocumentType = EDocumentType.WithoutRestriction;

    /** Ignore List of the corresponding document */
    @JsonProperty('ignoreList', [String], false)
    public ignoreList: string[] = [];

    /** Url of the corresponding document */
    @JsonProperty('url', String, false)
    public url: string = '';

    /** Modified User Id of the corresponding document */
    @JsonProperty('modifiedBy', Number, false)
    public modifiedBy: number = 0;
}