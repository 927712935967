import React from 'react';

import Iconassessment from '$resources/svgs/lessonTypes/assessment.svg';
import Iconfolder from '$resources/svgs/lessonTypes/catalog-folder.svg';
import Iconcbt from '$resources/svgs/lessonTypes/cbt-lesson.svg';
import Icondocument from '$resources/svgs/lessonTypes/document.svg';
import Iconexternal from '$resources/svgs/lessonTypes/external-lesson.svg';
import Iconf2f from '$resources/svgs/lessonTypes/internal-lesson.svg';
import Iconwbt from '$resources/svgs/lessonTypes/online-lesson.svg';
import Iconquestionnaire from '$resources/svgs/lessonTypes/questionnaire.svg';
import Iconfilesharing from '$resources/svgs/lessonTypes/share.svg';
import Iconsurvey from '$resources/svgs/lessonTypes/survey.svg';
import Icontrainingplan from '$resources/svgs/lessonTypes/training-plan.svg';
import Session from '$src/core/Session';
import { Item } from '$src/storage/models/Item';
import { ItemHelper } from '$src/util/ItemHelper';
import { EItemSubType, EItemType } from '$storage/models/enums';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';


interface IProps {
    item?: Item;
    itemSubType?: EItemSubType;
    itemType?: EItemType;
    textVisible: boolean;
    className?: string;
}

export default function ToolbarItemType(props: IProps) {

    const tr = Session.instance.storage.translation;
    let isUserDefinedIcon = false;
    let icon = '';
    let text = '';
    let hoverText = tr.GetString('ItemDetail:ItemType');
    let tooltipId = 'TooltipFor_';
    const type = props.itemType != null ? (props.itemType) : (props.item != null ? props.item.itemType : EItemType.Undefined);
    const subType = props.itemSubType != null ? (props.itemSubType) : (props.item != null ? props.item.itemSubType : EItemSubType.Undefined);
    switch (type) {
        case EItemType.Document:
            icon = Icondocument;
            text = tr.GetString('ItemType:Document');
            break;
        case EItemType.F2FCourse:
            if (subType === EItemSubType.External) {
                icon = Iconexternal;
                text = tr.GetString('ItemSubType:External');
            } else if (subType === EItemSubType.FaceToFace) {
                icon = Iconf2f;
                text = tr.GetString('ItemSubType:FaceToFace');
            } else {
                icon = Iconf2f;
                text = tr.GetString('ItemType:F2FCourse');
            }
            break;
        case EItemType.WBT:
            icon = Iconwbt;
            text = tr.GetString('ItemType:WBT');
            break;
        case EItemType.CBT:
            icon = Iconcbt;
            text = tr.GetString('ItemType:CBT');
            break;
        case EItemType.Questionnaire:
            icon = Iconquestionnaire;
            if (subType === EItemSubType.Random) {
                text = tr.GetString('ItemSubType:Random');
            } else if (subType === EItemSubType.Survey) {
                icon = Iconsurvey;
                text = tr.GetString('ItemSubType:Survey');
            } else if (subType === EItemSubType.Standard) {
                text = tr.GetString('ItemSubType:Standard');
            } else {
                text = tr.GetString('ItemType:Questionnaire');
            }
            break;
        case EItemType.TrainingPlan:
            icon = Icontrainingplan;
            text = tr.GetString('ItemType:TrainingPlan');
            break;
        case EItemType.Assessment:
            icon = Iconassessment;
            text = tr.GetString('ItemType:Assessment');
            break;
        case EItemType.FileSharing:
            icon = Iconfilesharing;
            text = tr.GetString('ItemType:FileSharing');
            break;
        case EItemType.Folder:
            icon = Iconfolder;
            text = tr.GetString('Favorite:TypeCatalogFolder');
            break;

    }
    if (props.item != null) {
        if (globalConfig.itemProperties.isCustomLessonTypeTextSupported) {
            const customLessonTypeTextAttributeName = globalConfig.itemProperties.customLessonTypeTextAttributeName
            const customLessonType = props.item.attributes != null ?
                props.item.attributes.find(x => x.name.toUpperCase() === customLessonTypeTextAttributeName.toUpperCase()) : null;
            if (customLessonType != null) {
                text = customLessonType.value
            }
        }

        const isDefaultIcon = ItemHelper.isDefaultIcon(props.item.icon, props.item.itemType)
        if (props.item.icon != null && props.item.icon.length > 0 && !isDefaultIcon) {
            icon = globalConfig.itemProperties.iconsUrl + '/' + props.item.icon
            isUserDefinedIcon = true;
        }
        tooltipId += props.item.itemId
    }
    hoverText = hoverText + ': ' + text;
    const iconElement = isUserDefinedIcon ? <img alt={hoverText} src={icon} /> :
        <InlineSVG src={icon} />
    const classname = props.className != null ? props.className : 'l-box__toolbar__icon lesson-tools lesson-tools--type'
    return (
        <div className={classname} aria-label={hoverText} data-tip={hoverText} data-for={tooltipId}>
            {iconElement}
            {props.textVisible ? (text) : ''}
            <Tooltip id={tooltipId} />
        </div>
    )
}
