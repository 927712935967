import { JsonObject, JsonProperty } from 'json2typescript';
import { DateTimeConverter } from '../converters/DateTimeConverter';

@JsonObject
export class QuestionnaireUrls {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('title', String, false)
    public title: string = '';

    @JsonProperty('type', Number, false)
    public type: number = 0;

    @JsonProperty('url', String, false)
    public url: string = '';
}

@JsonObject
export class TrainerBookings {
    @JsonProperty('classId', Number, false)
    public classId: number = 0;

    @JsonProperty('courseItemId', Number, false)
    public courseItemId: number = 0;

    @JsonProperty('courseSid', String, false)
    public courseSid: string = '';

    @JsonProperty('courseTitle', String, false)
    public courseTitle: string = '';

    @JsonProperty('classTitle', String, false)
    public classTitle: string = '';

    @JsonProperty('classCode', String, false)
    public classCode: string = '';

    @JsonProperty('sessionStartDate', DateTimeConverter, false)
    public sessionStartDate?: Date = undefined;

    @JsonProperty('sessionEndDate',  DateTimeConverter, false)
    public sessionEndDate?: Date = undefined;

    @JsonProperty('reportUrl', String, false)
    public reportUrl: string = '';

    @JsonProperty('questionnaireUrls', [QuestionnaireUrls], false)
    public questionnaireUrls: QuestionnaireUrls[] = [];
}


// tslint:disable-next-line: max-classes-per-file
@JsonObject
export class TrainerBookingsResponse {

    @JsonProperty('trainerId', Number, false)
    public trainerId: number = 0;

    @JsonProperty('trainerLastname', String, false)
    public TrainerLastname: string = '';

    @JsonProperty('trainerBookings', [TrainerBookings], false)
    public trainerBookings: TrainerBookings[] = [];

    @JsonProperty('filterMonths', [Number], false)
    public filterMonths: number[] = [];

    @JsonProperty('filterYears', [Number], false)
    public filterYears: number[] = [];
}