import { JsonObject, JsonProperty } from 'json2typescript';

import { Assignment } from '$src/storage/models/Assignment';
import { Attribute } from '$src/storage/models/Attribute';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';
import { RegistrationStatusConverter } from '$src/storage/models/converters/RegistrationStatusConverter';
import { ItemSubTypeConverter } from '$storage/models/converters/ItemSubTypeConverter';
import { ItemTypeConverter } from '$storage/models/converters/ItemTypeConverter';
import { LessonStatusConverter } from '$storage/models/converters/LessonStatusConverter';
import { ECancellationPermission, EItemSubType, EItemType, ELessonStatus, ERegistrationStatus } from '$storage/models/enums';
import { Registration } from '$storage/models/Registration';
import { Mentor } from '$storage/models/Mentor';
import { IOSkill } from '$storage/models/IOSkill';
import DateHelper from '$src/util/DateHelper';

/**
 * Base class for all derived classes containing information about items (lessons, training plans or catalog folders).
 */
@JsonObject('Item')
export class Item {

    /** Id of the corresponding item. */
    @JsonProperty('itemId', Number, false)
    public itemId: number = 0;

    /** String Id of the corresponding item. */
    @JsonProperty('sId', String, false)
    public sId: string = '';

    /** Type of the item. */
    @JsonProperty('itemType', ItemTypeConverter, false)
    public itemType: EItemType = EItemType.Undefined;

    /** Sub type of the item. */
    @JsonProperty('itemSubType', ItemSubTypeConverter, false)
    public itemSubType: EItemSubType = EItemSubType.Undefined;

    /** Title (in selected language). */
    @JsonProperty('title', String, false)
    public title: string = '';

    /** Summary (in selected language). */
    @JsonProperty('summary', String, false)
    public summary: string = '';

    /** Filename of an optional / alternative icon for item (should be related to the lesson type). */
    @JsonProperty('icon', String, false)
    public icon: string = '';

    /** true indicates users may rate this item. */
    @JsonProperty('isRatingEnabled', Boolean, false)
    public isRatingEnabled: boolean = false;

    /** Average user rating (0...10). */
    @JsonProperty('ratingAverage', Number, false)
    public ratingAverage: number = 0;

    /** Current user's rating (0...10). 0 means user hasn't stored a rating yet. */
    @JsonProperty('ratingMine', Number, false)
    public ratingMine: number = 0;

    /** Number of user ratings. */
    @JsonProperty('ratingCount', Number, false)
    public ratingCount: number = 0;

    /** Number of skills required by user before starting this lesson is allowed. */
    @JsonProperty('inputSkillCount', Number, false)
    public inputSkillCount: number = 0;

    /** true indicates child catalog elements have to be ordered by title, otherwise by rank. */
    @JsonProperty('orderByTitle', Boolean, false)
    public orderByTitle: boolean = false;

    /** Current user's lesson status. */
    @JsonProperty('lessonStatus', LessonStatusConverter, false)
    public lessonStatus: ELessonStatus = ELessonStatus.NotAttempted;

    /** true indicates lesson is locked due to missing required skill. */
    @JsonProperty('isLockedDueToRequiredSkills', Boolean, false)
    public isLockedDueToRequiredSkills: boolean = false;

    /** Valid Date indicates the lesson is locked due to failed attempts */
    @JsonProperty('lessonLockedByAttemptsUntil', DateTimeConverter, false)
    public lessonLockedByAttemptsUntil: Date | undefined = undefined;

    /** true indicates lesson is locked due to the settings in the training plan. Only appies if lesson was requested in context of a training plan */
    @JsonProperty('isLockedDueToTrainingPlan', Boolean, false)
    public isLockedDueToTrainingPlan: boolean = false;
    

    // 2018-09-25 PS: We don't use the value determined on server side, we want to calculate it here, see 
    //                implementation of 'get isLockedDueToAssignments()' below.
    // /** true indicates lesson is locked due to an assignment out of learning phase. */
    // @JsonProperty('isLockedDueToAssignments', Boolean, false)
    // public isLockedDueToAssignments: boolean = false;

    /** Current user's score (0...100%). */
    @JsonProperty('score', Number, false)
    public score: number = 0;

    /** Date & time of last usage of lesson (last change of lesson status). */
    @JsonProperty('lastUsed', DateTimeConverter, false)
    public lastUsed: Date | undefined = undefined;

    /** true indicates user may register for more than one class of the same F2F course. */
    @JsonProperty('isMultipleRegistrationAllowed', Boolean, false)
    public isMultipleRegistrationAllowed: boolean = false;

    /** Registration status, taken in this priority order from 1: CurrentRegistration 2: NextRegistration 3: last one from AllRegistrations. */
    @JsonProperty('registrationStatus', RegistrationStatusConverter, false)
    public registrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;

    @JsonProperty('registrationClassId', Number, false)
    public registeredClassId: number = 0;

    @JsonProperty('registrationId', Number, false)
    public registrationId: number = 0;

    /** cancellationDate1 of the corresponding class */
    @JsonProperty('cancellationDate1', DateTimeConverter, false)
    public cancellationDate1: Date | undefined = undefined;

    /** cancellationDate2 of the corresponding class */
    @JsonProperty('cancellationDate2', DateTimeConverter, false)
    public cancellationDate2: Date | undefined = undefined;

    /** registrationPeriodStart of the corresponding class */
    @JsonProperty('registrationPeriodStart', DateTimeConverter, false)
    public registrationPeriodStart: Date | undefined = undefined;

    /** registrationPeriodEnd of the corresponding class */
    @JsonProperty('registrationPeriodEnd', DateTimeConverter, false)
    public registrationPeriodEnd: Date | undefined = undefined;

    /** Current registration, if there is one where current time is between LearningPeriodBegin and LearningPeriodEnd. */
    @JsonProperty('currentRegistration', Registration, false)
    public currentRegistration: Registration | null = null;

    /** Next registration in the future, if there is one with LearningPeriodBegin in the future. */
    @JsonProperty('nextRegistration', Registration, false)
    public nextRegistration: Registration | null = null;

    /** All registrations for current user in the past, present and future. */
    @JsonProperty('allRegistrations', [Registration], false)
    public allRegistrations: Registration[] | null = null;

    /** Earliest learning phase begin, if item is referenced by at least one assignment. */
    @JsonProperty('assignmentsEarliestLearningPeriodBegin', DateTimeConverter, false)
    public assignmentsEarliestLearningPeriodBegin?: Date = undefined;

    /** Latest learning phase end, if item is referenced by at least one assignment. */
    @JsonProperty('assignmentsLatestLearningPeriodTarget', DateTimeConverter, false)
    public assignmentsLatestLearningPeriodTarget?: Date = undefined;

    /** Latest learning phase end, if item is referenced by at least one assignment. */
    @JsonProperty('assignmentsLatestLearningPeriodEnd', DateTimeConverter, false)
    public assignmentsLatestLearningPeriodEnd?: Date = undefined;

    /** All assignments referencing this item and current user. */
    @JsonProperty('allAssignments', [Assignment], false)
    public allAssignments: Assignment[] | null = null;

    /** List of all (custom) attributes in selected language or in default language. */
    @JsonProperty('attributes', [Attribute], false)
    public attributes: Attribute[] = [];

    /** Learning duration Id, i.e. Id of the attribute value (AttributeValue) whose text provides information about the learning duration. */
    @JsonProperty('learningDurationId', Number, false)
    public learningDurationId: number = 0;

    /** Learning duration, i.e. text providing information about the learning duration, in selected language. */
    @JsonProperty('learningDuration', String, false)
    public learningDuration: string = '';

    /** true registration required is enabled. */
    @JsonProperty('registrationRequiredEnabled', Boolean, false)
    public registrationRequiredEnabled: boolean = false;

    /** true auto registration is enabled. */
    @JsonProperty('isAutoRegister', Boolean, false)
    public isAutoRegister: boolean = false;

    /**Full width description */
    @JsonProperty('isFullWidthDescription', Boolean, false)
    public isFullWidthDescription: boolean = false;

    /**Flag if the user has the publish right on the item */
    @JsonProperty('hasPublishRight', Boolean, true)
    public hasPublishRight: boolean = false;

    /** Global Description File Name */
    @JsonProperty('globalTemplate', String, false)
    public globalTemplate: string = '';

    @JsonProperty('mentors', [Mentor], true)
    public mentors: Mentor[] = [];

    @JsonProperty('outputSkill', [IOSkill], true)
    public itemOutputSkills: IOSkill[] = [];
    /** true => locked icon is not displayed if the start condition is not fulfilled */
    @JsonProperty('isIgnoreStartCondition', Boolean, false)
    public isIgnoreStartCondition: boolean = false;

    @JsonProperty('price', Number, false)
    public price: number | null = null;

    @JsonProperty('currency', String, false)
    public currency: string = '';

    @JsonProperty('priceInfo', String, false)
    public priceInfo: string = '';

    @JsonProperty('costCenter', String, false)
    public costCenter: string = '';

    /** true:  The current user has to pay for that item (i.e. the item is attributed as IsPurchasable and the user is not in a PaymentExclude group)
     *  false: The current user does not have to pay for that item.  */
    @JsonProperty('isPurchasableForCurrentUser', Boolean, false)
    public isPurchasableForCurrentUser: boolean = false;

    public get isRegistered(): boolean {
        let retVal = false;
        if (this.registrationStatus === ERegistrationStatus.Accepted
            || this.registrationStatus === ERegistrationStatus.Requested
            || this.registrationStatus === ERegistrationStatus.InWaitingList) {
            retVal = true;
        }
        return retVal;
    }

    // /* ------------------------------------------------------------------------------------------ */

    /** 
     * Determine if item is locked due to assignments: true = locked 
     * The item is locked if current time is before the earliest learningTimeBegin or after the latest  
     * learningPeriodEnd of all assignments referring this lesson. The second condition (learningPeriodEnd)
     * may be disabled by globalConfig.assignmentProperties.allowToStartAssignmentAfterRunningPeriod.
     */
    public get isLockedDueToAssignments(): boolean {
        let isLocked = true;
        if (this.allAssignments != null) {
            for (const assignment of this.allAssignments) {
                const isAfterStart = assignment.learningPeriodBegin == null || new Date() >= new Date(assignment.learningPeriodBegin);
                const isBeforeEnd = assignment.learningPeriodEnd == null || new Date() <= new Date(assignment.learningPeriodEnd);
                if (isAfterStart && (isBeforeEnd || globalConfig.assignmentProperties.allowToStartAssignmentAfterRunningPeriod)) {
                    isLocked = false;
                    break;
                }
            }
        } else {
            isLocked = false;
        }
        return isLocked;
    }

    /**
     * Returns true if lesson is locked due to missing skills or due to assignment restrictions (learning period).
     */
    public get isLocked(): boolean {
        return this.isLockedDueToRequiredSkills || this.isLockedDueToAssignments || (this.lessonLockedByAttemptsUntil !== undefined) || this.isLockedDueToTrainingPlan;
    }

    /**
     * Calculate for an lesson or training plan how many hours are left until a F2F course ends or the learning 
     * phase of an assignment ends.
     */
    public get remainingHoursToEnd(): number | undefined {
        // All times and timediffs are milliseconds
        // tslint:disable-next-line:no-unnecessary-initializer
        let remainingMilliSeconds: number | undefined = undefined;
        const now = new Date().getTime();

        // For F2F courses and training plans use the learning phase of any registered class as reference for
        // calculation of the remaining time.
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            if (this.currentRegistration != null &&
                this.currentRegistration.learningPeriodEnd != null &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.Cancelled &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.Rejected &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.ScheduleCancelled) {
                // We have currently a running learning phase -> get time until end of running learning phase.
                remainingMilliSeconds = new Date(this.currentRegistration.learningPeriodEnd).getTime() - now;
            } else if (this.nextRegistration != null &&
                this.nextRegistration.learningPeriodEnd != null &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.Cancelled &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.Rejected &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.ScheduleCancelled) {
                // We have no currently a running learning phase, but one in the future -> get time until the
                // end of the next learning phase.
                remainingMilliSeconds = new Date(this.nextRegistration.learningPeriodEnd).getTime() - now;
            }
        }
        // Use learning phase of any assignment as reference for calculation of the remaining time.
        if (remainingMilliSeconds === undefined) {
            const periodEnd = this.assignmentsLatestLearningPeriodTarget == null ?
                this.assignmentsLatestLearningPeriodEnd : this.assignmentsLatestLearningPeriodTarget;

            if (periodEnd != null) {
                remainingMilliSeconds = new Date(periodEnd).getTime() - now;
            }
        }
        // Get hours from milliseconds
        return (remainingMilliSeconds !== undefined) ? Math.round(remainingMilliSeconds / 1000 / 3600) : undefined;
    }

    /**
     * Calculate for an lesson or training plan how many hours are left until a F2F course starts or the learning 
     * phase of an assignment ends.
     */
    public get remainingHoursToStart(): number | undefined {
        // All times and timediffs are milliseconds
        // tslint:disable-next-line:no-unnecessary-initializer
        let remainingMilliSeconds: number | undefined = undefined;
        const now = new Date().getTime();

        // For F2F courses and training plans use the learning phase of any registered class as reference for
        // calculation of the remaining time.
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            if (this.currentRegistration != null &&
                this.currentRegistration.learningPeriodBegin != null &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.Cancelled &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.Rejected &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.ScheduleCancelled) {
                // We have currently a running learning phase -> get time until end of running learning phase.
                remainingMilliSeconds = new Date(this.currentRegistration.learningPeriodBegin).getTime() - now;
            } else if (this.nextRegistration != null &&
                this.nextRegistration.learningPeriodBegin != null &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.Cancelled &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.Rejected &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.ScheduleCancelled) {
                // We have no currently a running learning phase, but one in the future -> get time until the
                // end of the next learning phase.
                remainingMilliSeconds = new Date(this.nextRegistration.learningPeriodBegin).getTime() - now;
            }
        }
        // Use learning phase of any assignment as reference for calculation of the remaining time.
        if (remainingMilliSeconds === undefined) {
            const periodStart = this.assignmentsEarliestLearningPeriodBegin;
            if (periodStart != null) {
                remainingMilliSeconds = new Date(periodStart).getTime() - now;
            }
        }
        // Get hours from milliseconds
        return (remainingMilliSeconds !== undefined) ? Math.round(remainingMilliSeconds / 1000 / 3600) : undefined;
    }

    public get learningPeriodEndDate(): Date | undefined {
        let learningPeriodEnd: Date | undefined;
        // If F2F or TP take the registration
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            if (this.currentRegistration != null &&
                this.currentRegistration.learningPeriodEnd != null) {
                learningPeriodEnd = this.currentRegistration.learningPeriodEnd
            } else if (this.nextRegistration != null &&
                this.nextRegistration.learningPeriodEnd != null) {
                learningPeriodEnd = this.nextRegistration.learningPeriodEnd;
            }
            else if (this.allRegistrations != null && this.allRegistrations.length > 0) {
                learningPeriodEnd = this.allRegistrations[this.allRegistrations.length - 1].learningPeriodEnd;
            }
        }
        return learningPeriodEnd;
    }

    public get learningPeriodStartDate(): Date | undefined {
        let learningPeriodStart: Date | undefined;
        // If F2F or TP take the registration
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            if (this.currentRegistration != null &&
                this.currentRegistration.learningPeriodBegin != null) {
                learningPeriodStart = this.currentRegistration.learningPeriodBegin
            } else if (this.nextRegistration != null &&
                this.nextRegistration.learningPeriodBegin != null) {
                learningPeriodStart = this.nextRegistration.learningPeriodBegin;
            }
            else if (this.allRegistrations != null && this.allRegistrations.length > 0) {
                learningPeriodStart = this.allRegistrations[this.allRegistrations.length - 1].learningPeriodBegin;
            }
        }
        return learningPeriodStart;
    }

    public learningPeriodEndDateByClassID(relatedClassId: number): Date | undefined {
        let learningPeriodEnd: Date | undefined;
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            const relatedClassRegistration = this.allRegistrations?.filter(r => r.classId === relatedClassId);
            return (relatedClassRegistration != null && relatedClassRegistration.length === 1) ? relatedClassRegistration[0].learningPeriodEnd : learningPeriodEnd;
        }
        return learningPeriodEnd;
    }

    public learningPeriodStartDateByClassID(relatedClassId: number): Date | undefined {
        let learningPeriodStart: Date | undefined;
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            const relatedClassRegistration = this.allRegistrations?.filter(r => r.classId === relatedClassId);
            return (relatedClassRegistration != null && relatedClassRegistration.length === 1) ? relatedClassRegistration[0].learningPeriodBegin : learningPeriodStart;
        }
        return learningPeriodStart;
    }

    /**
     * Returns true if today is before cancellationPeriod1 Date
     */
    public get isInCancellationPeriod1(): boolean {
        const today = new Date()
        return this.cancellationDate1 !== undefined && this.cancellationDate1 >= today;
    }

    /**
     * Returns true if today is before the cancellation Period 2 Date
     */
    public get isInCancellationPeriod2(): boolean {
        const today = new Date()
        if (this.cancellationDate2 === undefined) {
            return false;
        } else if (today > this.cancellationDate2) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Returns true if the item has no cancellation period specified
     */
    public get hasNoCancellationPeriod(): boolean {
        return (this.cancellationDate1 == null && this.cancellationDate2 == null)
    }

    /**
     * Returns true if the current date is in the RegistrationPeriod of the current Registration
     */
    public get isInRegistrationPeriod(): boolean {
        return ((this.registrationPeriodEnd != null && new Date() < this.registrationPeriodEnd) &&
            (this.registrationPeriodStart != null && new Date() >= this.registrationPeriodStart))
    }

    /**
     * Returns true if the current date is in the learning period
     */
    public get isInLerningPeriod(): boolean {
        const today = new Date()
        return ((this.learningPeriodEndDate != null && today < this.learningPeriodEndDate) &&
            (this.learningPeriodStartDate != null && today >= this.learningPeriodStartDate))
    }

    /**
     * Returns true if today is after learningPeriodStartDate 
     */
    public get hasLearningPeriodStarted(): boolean {
        const today = new Date()
        return this.learningPeriodStartDate != null && today > this.learningPeriodStartDate;
    }

    /**
     * Returns an ENUM ECancellationType depending on if the cancellation is still allowed and if it should display a warning
     *
     * @readonly
     * @type {ECancellationType}
     * @memberof Item
     */
    public get isCancellationAllowed(): ECancellationPermission {
        if (this.isInCancellationPeriod1) {
            return ECancellationPermission.Allowed;
        } else if (this.isInCancellationPeriod2) {
            return ECancellationPermission.WithWarning;
        } else if (this.hasNoCancellationPeriod) {
            return ECancellationPermission.Allowed;
        }
        return ECancellationPermission.NotAllowed;
    }

    /**
     * Checks if the item expires in the next configured days 
     * Config: globalConfig.assignmentProperties.daysToLookAheadForExpirations
     *
     * @readonly
     * @type {boolean}
     * @memberof Item
     */
    public get expiresInTheNextFewDays(): boolean {
        let expiresInTheNextFewDays = false;
        const numberOfDays = globalConfig.assignmentProperties.daysToLookAheadForExpirations;
        const compareDateFrom = new Date();
        const compareDateTo = new Date();
        compareDateTo.setDate(compareDateFrom.getDate() + numberOfDays); 
        compareDateTo.setHours(23, 59, 59);

        // For F2F courses and training plans use the learning phase of any registered class as reference for
        // calculation of the remaining time.
        if (this.itemType === EItemType.F2FCourse || this.itemType === EItemType.TrainingPlan) {
            if (this.currentRegistration != null &&
                this.currentRegistration.learningPeriodEnd != null &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.Cancelled &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.Rejected &&
                this.currentRegistration.registrationStatus !== ERegistrationStatus.ScheduleCancelled) {
                // We have currently a running learning phase -> get time until end of running learning phase.
                if(this.currentRegistration.learningPeriodEnd != null) {
                    expiresInTheNextFewDays = DateHelper.datesWithinBoundaries(compareDateFrom, compareDateTo, this.currentRegistration.learningPeriodEnd, null, false);
                }
            } else if (this.nextRegistration != null &&
                this.nextRegistration.learningPeriodEnd != null &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.Cancelled &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.Rejected &&
                this.nextRegistration.registrationStatus !== ERegistrationStatus.ScheduleCancelled) {
                // We have no currently a running learning phase, but one in the future -> get time until the
                // end of the next learning phase.
                if(this.nextRegistration.learningPeriodEnd != null) {
                    expiresInTheNextFewDays = DateHelper.datesWithinBoundaries(compareDateFrom, compareDateTo, this.nextRegistration.learningPeriodEnd, null, false);
                }
            }
        }
        // Use learning phase of any assignment as reference for calculation of the remaining time.
        if (!expiresInTheNextFewDays) {
            const periodEnd = this.assignmentsLatestLearningPeriodTarget == null ?
                this.assignmentsLatestLearningPeriodEnd : this.assignmentsLatestLearningPeriodTarget;

            if (periodEnd != null) {
                expiresInTheNextFewDays = DateHelper.datesWithinBoundaries(compareDateFrom, compareDateTo, periodEnd, null, false);
            }
        }
        
        return expiresInTheNextFewDays;
    }
}

