import React from 'react';
import { Heading } from '../Heading';

interface IProps {
    tabId: string;
    title: string;
    linkClassName: string;
    isActive: boolean;
    tabIndex: number;
    onTabSelected: (tabIndex: number) => void;
    parentHeadingLevel: number;
}

// tslint:disable:object-literal-sort-keys
export class TabLink extends React.Component<IProps, {}> {
    protected tabButton: HTMLButtonElement | null;
    constructor(props: IProps) {
        super(props);
        this.onTabSelected = this.onTabSelected.bind(this);
    }

    public render() {
        return (
            <li className={`${this.props.linkClassName} ${this.props.isActive ? this.props.linkClassName + '--active' : ''}`}>
                <button className="button"
                    onClick={this.onTabSelected}
                    ref={(ref) => this.tabButton = ref}
                    id={this.props.tabId}
                    aria-selected={this.props.isActive}
                    role="tab">
                    <Heading cssClass="heading--secondary heading__Level3" headingLevel={this.props.parentHeadingLevel + 1}>
                        {this.props.title}
                        {/* <span className="screen-reader-only"><Translate>Navigation:ActiveItem</Translate></span> */}
                    </Heading>
                </button>
            </li>
        );
    }

    private onTabSelected = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (this.tabButton !== null) {
            this.tabButton.blur();
        }
        this.props.onTabSelected(this.props.tabIndex);
    }

}