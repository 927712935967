import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Translate } from '$src/components/shared/Translate';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { User } from '$src/storage/models/User';


import {
    GridCellProps,
} from '@progress/kendo-react-grid';
interface IProps extends GridCellProps, RouteComponentProps<{}> {
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
export default class MyTeamListActionCell extends React.Component<IProps> {
    public render() {
        let userString: string | null = null;
        // tslint:disable-next-line: no-string-literal
        if(this.props.dataItem != null && this.props.dataItem['employee'] instanceof User) {
            // tslint:disable-next-line: no-string-literal
            userString = this.props.dataItem['employee'].firstName + ' ' + this.props.dataItem['employee'].lastName + ' (' + this.props.dataItem['employee'].email + ')';
        }
        return (
            <td> {
                (userString == null) ? 'did not get user' : 
                <a onClick={() => this.onLinkCklick(userString || '')} ><Translate>MyEmployees:CourseRequestsLink</Translate></a>}
            </td>
        );
    }

    private onLinkCklick(userString: string) {
        this.props.history.push(this.getLink(userString));
    }

    private getLink(userString: string): string {
        return `/courseRequests?user=${userString}&context=${EItemDetailCallerContextType.MyEmployees}`;
    }
}