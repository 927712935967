import { JsonObject, JsonProperty } from 'json2typescript';
import { EVoucherInvalidityReason } from '$storage/models/enums';
import { DateTimeConverter } from '$src/storage/models/converters/DateTimeConverter';

/** Infos about a voucher's validity */
@JsonObject
export class VoucherValidity {
    /** Voucher code */
    @JsonProperty('voucherCode', String, false)
    public voucherCode: string = "";

    /** true if voucherCode is valid */
    @JsonProperty('isValid', Boolean, false)
    public isValid: boolean = false;

    /** Reason if isValid==false */
    @JsonProperty('invalidityReason', Number, false)  
    public invalidityReason: EVoucherInvalidityReason = EVoucherInvalidityReason.InvalidCode;

    /** Expiration date */
    @JsonProperty('expirationDate', DateTimeConverter, false)
    public expirationDate: Date | null = null;

    /** Discount in % */
    @JsonProperty('percentDiscount', Number, false)
    public percentDiscount: number = 0;

    /** Absolute discount in currency */
    @JsonProperty('absDiscount', Number, false)
    public absDiscount: number = 0;

    /** Currency of the discount */
    @JsonProperty('absDiscountCurrency', String, false)
    public absDiscountCurrency: string = "";

    /** IDs of the items (WBT, F2F courses, TPlan) to which the voucher is restricted */
    @JsonProperty('itemIds', [Number], false)
    public itemIds: number[] = [];
}
