import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import UserService from '$core/Services/UserService';
import Session from '$core/Session';
import Iconmail from '$resources/svgs/objects/mail.svg';
import { Boss } from '$storage/models/Boss';
import { User } from '$storage/models/User';
import { isSuccess } from '$util/Result';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { GenericFilter, IGenericFilterItem } from '$src/components/shared/GenericFilter';
import { BossSelection } from '$src/storage/models/BossSelection';
import { NumberResponse } from '$src/storage/models/NumberRepsonse';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Heading } from '../shared/Heading';

interface IState {
    bosses: Boss[] | null;
    bossSelection: BossSelection[] | null;
    dataLoaded: boolean;
    editButton: boolean;
    genericFilterBosses: IGenericFilterItem[] | undefined;
    bossUserId: number | undefined;
    selectedBossId?: number;
    isChangeBossAllowed: boolean;
}


export class BossesTable extends React.Component<{}, IState> {

    protected className = 'BossTable';
    protected loggerLocality = 'Components.BossTable';

    constructor(props: {}) {
        super(props);
        this.state = {
            bosses: null,
            bossSelection: null,
            dataLoaded: false,
            editButton: false,
            genericFilterBosses: undefined,
            bossUserId: undefined,
            selectedBossId: undefined,
            isChangeBossAllowed: false,
        };
    }

    public async componentDidMount() {

        const isChangeBossAllowed = await UserService.instance.IsChangeBossAllowed();
        if (isSuccess<BooleanResponse>(isChangeBossAllowed)) {
            this.setState({ isChangeBossAllowed: isChangeBossAllowed.status });
        }
        const bossSelection = await UserService.instance.getBossSelection();
        if (isSuccess<BossSelection[]>(bossSelection)) {
            this.setState({ bossSelection: bossSelection });

            const bossesFilter: Array<IGenericFilterItem> = [];
            bossSelection.map((i) => {
                bossesFilter.push({ id: i.id, text: i.fullName, helperItem: undefined } as IGenericFilterItem);
            });
            this.setState({ genericFilterBosses: bossesFilter });
        }
        const bossesOfLoginUser = await UserService.instance.getBosses(Session.instance.getUserLanguageCodeOrFallBack, false);
        if (isSuccess<User[]>(bossesOfLoginUser)) {
            this.setState({ bosses: bossesOfLoginUser });
        }
        this.setState({ dataLoaded: true });
    }

    public render() {
        if (!this.state.dataLoaded) {
            return <ProgressSpinner />
        }
        else {
            return <>
                {this.renderBossTable()}
                {this.state.editButton && this.addBoss()}
                {this.state.isChangeBossAllowed && this.renderButtons()}
            </>
        }
    }

    protected renderBossTable(): JSX.Element[] | JSX.Element {
        const elements: JSX.Element[] = [];
        elements.push(
            <div key={'tbl_bosses'} role="table" className="div-table__horizontal-table user-profile__bosses">
                <div role="rowgroup">
                    <div role="row" className="div-table__horizontal-table-row zebra">
                        <div role="columnheader" className="notMobile myProfile__firstColumn">
                            <Translate>UserProfile:BossType</Translate>
                        </div>
                        <div role="columnheader" className="notMobile">
                            <Translate>UserProfile:Firstname</Translate>
                        </div>
                        <div role="columnheader" className="notMobile">
                            <Translate>UserProfile:Lastname</Translate>
                        </div>
                        <div role="columnheader" className={this.state.editButton ? "notMobile" : "notMobile myProfile__lastColumn"}>
                            <Translate>UserProfile:Email</Translate>
                        </div>
                    </div>
                </div>
                <div role="rowgroup">
                    {this.renderBossRows()}
                </div>
            </div >

        );

        return elements;
    }

    protected renderBossRows(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        if (this.state.bosses != null) {
            this.state.bosses.map((boss, index) => {
                elements.push(
                    <div role="row" key={index} className="div-table__horizontal-table-row zebra">
                        <div role="columnheader" className="mobileOnly">
                            <Translate>UserProfile:BossType</Translate>
                        </div>
                        <div role="cell" className="myProfile__firstColumn">
                            {boss.bossType}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>UserProfile:Username</Translate>
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>UserProfile:Firstname</Translate>
                        </div>
                        <div role="cell">
                            {boss.firstName}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>UserProfile:Lastname</Translate>
                        </div>
                        <div role="cell">
                            {boss.lastName}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>UserProfile:Email</Translate>
                        </div>
                        <div role="cell" className="myProfile__lastColumn" >
                            <a aria-label={Session.instance.storage.translation.GetString("UserProfile:EmailProgramOpen")} href={`mailto:${boss.email}`} role="button">
                                <InlineSVG src={Iconmail} />
                            </a>
                        </div>
                    </div>
                )
            });
        }
        return elements;
    }

    protected renderButtons(): JSX.Element {
        if (this.state.editButton) {
            return this.renderSaveCancelButtons()
        }
        else {
            return <button className="button button-small button--secondary" id="btnEdit" onClick={() => this.toggleEditButton()}>
                <Translate>{'Button:Edit'}</Translate>
            </button>
        }
    }

    protected toggleEditButton() {
        this.setState({ editButton: this.state.editButton ? false : true });
    }

    protected renderSaveCancelButtons(): JSX.Element {
        return <div className='user-detail__button-container'>
            <button className="button button-small button--secondary marginRight-5" id="btnCancel" onClick={() => this.toggleEditButton()}>
                <Translate>Button:Cancel</Translate>
            </button>
            <button
                type="button"
                className="button button-small button--secondary marginRight-5"
                 id="btnSave"
                 disabled={!(this.state.selectedBossId != null && this.state.selectedBossId > 0)}
                onClick={() => { this.selectedBossChanged(); this.setState({ dataLoaded: false }) }}>
                <Translate>Button:Save</Translate>
            </button>
        </div>
    }

    public async selectedBossChanged() {
        if (this.state.selectedBossId) {
            const userId = new NumberResponse
            userId.value = this.state.selectedBossId;
            const loaded = await UserService.instance.saveBoss(userId);
            if (loaded) {
                const bossesOfLoginUser = await UserService.instance.getBosses(Session.instance.getUserLanguageCodeOrFallBack, false);
                if (isSuccess<User[]>(bossesOfLoginUser)) {
                    this.setState({ bosses: bossesOfLoginUser, dataLoaded: true });
                    this.toggleEditButton();
                }
            }
        }
    }

    protected addBoss(): JSX.Element {
        return <>
            <Heading cssClass="heading__Level4" headingLevel={2}>
                <Translate>UserProfile:AddBoss</Translate>
            </Heading>
            {this.state.genericFilterBosses != null &&
                <GenericFilter
                    initValue={undefined}
                    fetchedItemList={this.state.genericFilterBosses}
                    filterable={true}
                    suggest={true}
                    onSelectedItemChange={(i) => (this.setState({ selectedBossId: i?.id }))}
                    className="my-team__item-filter"
                    required={true}
                />
            }
        </>
    }

}

export default BossesTable;