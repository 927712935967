import { JsonConverter, JsonCustomConvert} from 'json2typescript';

// https://www.npmjs.com/package/json2typescript

@JsonConverter
export class DateOrNullConverter implements JsonCustomConvert<Date | null> {
    public serialize(date: Date| null ): any {
        return date === null ? '' : date.getFullYear() + '-' + (date.getMonth() + 1) + '-' +  date.getDate();
    }
    public deserialize(date: any): Date | null {

        return date === null ? null : new Date(date);
    }
}