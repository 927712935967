import TranslationObject from '$storage/models/TranslationObject';
import { JsonObject, JsonProperty } from 'json2typescript';


@JsonObject
 export class TranslationList  {  
  
    @JsonProperty('translationObjects', [TranslationObject], false)
    public translationObjects: TranslationObject[] | undefined = undefined;

      
    @JsonProperty('languageCode', String, false)
    public languageCode: string ='';

}






