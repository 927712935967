import React from "react";
import { Tooltip } from "$components/shared/Tooltip";
import { Rating } from '@progress/kendo-react-inputs';
import Session from "$src/core/Session";

/**
 * The GT Star Rating Props
 */
export interface IStarRatingProps {

    /**
     * Component unique id
     */
    id: string;

    /**
     * Number of selected stars to display, represents the average rating of stars
     */
    averageRating: number;

    /**
     * Number of entered ratings
     */
    ratingCount: number;

    /**
     * Number of stars the user rated
     */
    myRating: number;

    /**
     * Aria Label for rating
     */
    ariaLabel: string;

    /**
     * **Optional** Additional css class
     */
    cssClass?: string;

    /**
     * **Optional** Text to display in the tool tip. If empty no tool tip will be displayed
     */
    tooltipText?: string;

}


/**
 * Global Teach Star Rating Component
 */
export default function StarRating(props: IStarRatingProps) {
    let cssClass = 'star-rating highlight-color';

    if (props.cssClass != null) {
        cssClass += ' ' + props.cssClass;
    }

    return (
        <>
            <label key={`StarRating_${props.id}`}
                className={cssClass}
                aria-label={props.ariaLabel}
                data-tip={props.tooltipText}
                data-for={`TT_StarRating_${props.id}`}
                data-event={'focus mouseover mouseenter'}
                data-event-off={'blur mouseleave mouseout'}>
                <Rating
                    className="z-icon"
                    half={true}
                    value={props.averageRating}
                    disabled={true} />
                {props.tooltipText &&
                    <Tooltip id={`TT_StarRating_${props.id}`} delayShow={500} />}
                <div className="star-rating__text">
                    ({props.ratingCount})
                </div>
            </label>
        </>
    )
}

export function getRatingToolTipText(myRating: number, count: number, averageRating: number): string {
    const tr = Session.instance.storage.translation;
    if (myRating === 0) {
        return tr.GetString('Rating:NotRated')
            .replace('{0}', count.toString())
            .replace('{1}', averageRating.toString())
    } else {
        return tr.GetString('Rating:AlreadySelected')
            .replace('{0}', count.toString())
            .replace('{1}', averageRating.toString())
            .replace('{2}', myRating.toString());
    }
}