import { JsonObject, JsonProperty } from "json2typescript";
import { UserSkillProfileSkill } from "./UserSkillProfileSkill";

@JsonObject('UserSkillProfileSkillContainer')
export class UserSkillProfileSkillContainer {
    @JsonProperty('userSkillProfileSkills', [UserSkillProfileSkill], false)
    public userSkillProfileSkills: UserSkillProfileSkill[] = [];
    
    @JsonProperty('hasAllShowRights', Boolean, false)
    public hasAllShowRights: boolean = true;
}