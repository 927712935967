import { ValidationResult } from '$storage/models/ValidationResult';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class UserPropertiesValidation {
    @JsonProperty('hasWarnings', Boolean, false)
    public hasWarnings: boolean = false;

    @JsonProperty('hasErrors', Boolean, false)
    public hasErrors: boolean = false;

    @JsonProperty('results', [ValidationResult], false) 
    public results: ValidationResult[] = [];
}