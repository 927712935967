import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ItemSkillLevelTranslatedDisplay {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('translatedText', String, false)
    public translatedText: string = '';

    @JsonProperty('skillLevelValue', Number, false)
    public skillLevelValue: number = 0;

}