import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { MenuBreadCrumb } from "$src/components/breadCrumb/MenuBreadCrumb";
import PerformanceCheckTable from "$src/components/Skills/PerformanceCheckTable";
import Session from "$src/core/Session";
import GTButton from "$src/components/shared/Atoms/GTButton";
import { Translate } from "$src/components/shared/Translate";
import { isSuccess } from "$src/util/Result";
import { Heading } from '$src/components/shared/Heading';
import { DatePicker } from '$components/shared/DatePicker';
import { Tooltip } from '$components/shared/Tooltip';
import { HeadingCollapsible } from '$components/shared/HeadingCollapsible';
import { UnmountClosed } from 'react-collapse';
import ContainerButton from '$components/shared/ContainerButton';
import { AutoComplete, AutoCompleteChangeEvent, DropDownList } from "@progress/kendo-react-dropdowns";
import { EPerformanceCheckStatus } from "$src/storage/models/enums";
import { PerformanceCheckTableItem } from "$src/components/Skills/PerformanceCheckItem";
import { ProgressSpinner } from "../shared/ProgressSpinner";
import { StringHelper } from "$src/util/StringHelper";

/**
 * Component that renders MyPerformanceCheck 
 *  Author: Bryan Gerber 
 */
export default function MyPerformanceCheck() {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [forceReload, setForceReload] = useState<boolean>(false);
    const [filterDateTo, setFilterDateTo] = useState<Date | null>(null);
    const [filterDateFrom, setFilterDateFrom] = useState<Date | null>(null);
    const [filterPanelCollapsed, setFilterPanelCollapsed] = useState<boolean>(true);
    const [skillsPerformanceCheck, setSkillsPerformanceCheck] = useState<PerformanceCheckTableItem[]>([]);
    const [skillsForPerformanceFilterd, setSkillsForPerformanceFilterd] = useState<PerformanceCheckTableItem[]>([]);
    const [filterTitle, setFilterTitle] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<EPerformanceCheckStatus>();

    useEffect(() => {
        document.title = globalConfig.appProperties.title + ' - ' + Session.instance.storage.translation.GetString("PerformanceCheck:Title")
    });

    useEffect(() => {
        if (!isLoaded || forceReload) {
            (async function loadSkills() {
                const skillsForTable = await Session.instance.storage.skill.getSkillsForPerformanceCheck(Session.instance.loginUser!.id, Session.instance.getUserLanguageCodeOrFallBack);
                if (isSuccess<PerformanceCheckTableItem[]>(skillsForTable)) {
                    setSkillsPerformanceCheck(skillsForTable);
                    setSkillsForPerformanceFilterd(skillsForTable);
                    setIsLoaded(true);
                    setForceReload(false);
                }
            })();
        }
    });

    const history = useHistory();

    function onClick() {
        history.push('/skillRequest');
    }

    function filterDateFromChanged(value: Date | null) {
        setFilterDateFrom(value);
    }

    function filterDateToChanged(value: Date | null) {
        setFilterDateTo(value);
    }

    function onClickFilter() {
        let tempList = skillsPerformanceCheck;
        if (filterDateFrom) {
            tempList = tempList.filter((e) => StringHelper.dateString(e.requestDate) >= StringHelper.dateString(filterDateFrom));
        }

        if (filterDateTo) {
            tempList = tempList.filter((e) => StringHelper.dateString(e.requestDate) <= StringHelper.dateString(filterDateTo));
        }

        if (filterTitle.length > 0 && filterTitle != "*") {
            tempList = tempList.filter((e) => e.skillTitle.toLowerCase().includes(filterTitle.toLowerCase()));
        }

        if (statusFilter) {
            tempList = tempList.filter((e) => e.status == statusFilter);
        }
        setSkillsForPerformanceFilterd([...tempList]);
    }

    function filterTitleTextChanged(event: AutoCompleteChangeEvent) {
        const value = event.target.value;
        setFilterTitle(value);
    }

    function statusChanged(status: string) {
        switch (status) {
            case "Requested":
                setStatusFilter(EPerformanceCheckStatus.Requested);
                break;
            case "Declined":
                setStatusFilter(EPerformanceCheckStatus.Declined);
                break;
            case "Accepted":
                setStatusFilter(EPerformanceCheckStatus.Accepted);
                break;
            case "All":
                setStatusFilter(undefined);
                break;
        }

    }

    function renderFilterPanel() {
        const tr = Session.instance.storage.translation;
        return (
            <div role="table" className="my-performanceCheck__filter-table">
                <div role="row" className={'my-performanceCheck__filter-table-row'}>
                    <div role="columnheader" className="my-performanceCheck__columnheader-autocomplete">
                        <Translate>PerformanceCheck:LastUseBetween</Translate>
                    </div>
                    <div role="cell" className="datePicker" >
                        <DatePicker
                            key={'txt_Filter_DateFrom'}
                            onChange={(v) => filterDateFromChanged(v)}
                            value={filterDateFrom}
                        />
                    </div>
                    <div role="columnheader" className="connectorText my-performanceCheck__columnheader-autocomplete">
                        <Translate>PerformanceCheck:LastUseBetweenAnd</Translate>
                    </div>
                    <div role="cell" className="datePicker" >
                        <DatePicker
                            key={'txt_Filter_DateTo'}
                            onChange={(v) => filterDateToChanged(v)}
                            value={filterDateTo} />
                    </div>
                </div>
                <div role="row" className="my-performanceCheck__filter-table-row">
                    <div role="columnheader" className="my-performanceChecks__columnheader-autocomplete"><Translate>PerformanceCheck:SearchByTitle</Translate>
                    </div>
                    <div role="cell">
                        <AutoComplete
                            aria-label={tr.GetString('PerformanceCheck:SearchByTitle')}
                            data={skillsForPerformanceFilterd}
                            placeholder={tr.GetString('PerformanceCheck:SearchByTitle')}
                            value={filterTitle}
                            className="input-field flexForIcon"
                            textField="skillTitle"
                            suggest={false}
                            onChange={(value: AutoCompleteChangeEvent) => filterTitleTextChanged(value)}
                        />
                    </div>
                </div>
                <div role="row" className="my-performanceCheck__filter-table-row">
                    <div role="columnheader">
                        <Translate>PerformanceCheck:SkillStatus</Translate>
                    </div>
                    <div role="cell">
                        <DropDownList
                            id="ddlSkillStatusFilter"
                            data-tip={tr.GetString('PerformanceCheck:SkillStatus')}
                            data-for="ddlSkillStatusFilter_Tooltip"
                            aria-label={tr.GetString('PerformanceCheck:SkillStatus')}
                            className="input-field"
                            dataItemKey="id"
                            textField="text"
                            onChange={(e) => statusChanged(e.target.value.id)}
                            defaultItem={{ id: "All", text: Session.instance.storage.translation.GetString('Skill:All') }}
                            data={
                                [
                                    { id: "Requested", text: Session.instance.storage.translation.GetString('Skill:Requested') },
                                    { id: "Accepted", text: Session.instance.storage.translation.GetString('Skill:Accepted') },
                                    { id: "Declined", text: Session.instance.storage.translation.GetString('Skill:Declined') }
                                ]
                            }

                        />
                        <Tooltip id="ddlSkillStatusFilter_Tooltip" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="l-container">
                <MenuBreadCrumb />
                <div className="l-box--wide my-performanceCheck__header">
                    <Heading headingLevel={1} cssClass="l-box-container heading__Title"><Translate>PerformanceCheck:Title</Translate></Heading>
                    <Translate>PerformanceCheck:SubTitle</Translate>
                </div>
            </div>
            <div className="my-performanceCheck__container l-element--striped-reverse my-performanceCheck__container--padding">
                <div className="l-container">
                    <HeadingCollapsible
                        headingLevel={2}
                        containerCssClass="my-performanceCheck__container--no-margin "
                        headingCssClass="heading__Level2"
                        isOpened={!filterPanelCollapsed}
                        onToggleOpenedState={() => setFilterPanelCollapsed(!filterPanelCollapsed)}>
                        <Translate>MyLessons:Filter</Translate>
                    </HeadingCollapsible>
                    <UnmountClosed
                        isOpened={!filterPanelCollapsed}>
                        {renderFilterPanel()}
                    </UnmountClosed>
                    <ContainerButton
                        ariaLabel={Session.instance.storage.translation.GetString('PerformanceCheck:LoadButton')}
                        buttonText={Session.instance.storage.translation.GetString('PerformanceCheck:LoadButton')}
                        id="idLoadSearch"
                        onButtonClicked={() => onClickFilter()}
                    />
                </div>
            </div>
            <div className="l-container">
                <GTButton
                    onClick={() => onClick()}
                ><Translate>PerformanceCheck:RequestSkill</Translate></GTButton>
                {!isLoaded ? <ProgressSpinner /> : <PerformanceCheckTable
                    isBoss={false}
                    skills={skillsForPerformanceFilterd}
                    parentHeadingLevel={1}
                />}
            </div>
        </div>

    )
}