
import { JsonObject, JsonProperty } from 'json2typescript';
import { SearchResult } from '$src/storage/models/search/elk/SearchResult';

/** This is the Model Class for the ServerResponse for all SearchResults. New ELK search */
@JsonObject
export class SearchResponse {

    @JsonProperty('searchResults', [SearchResult], false)
    public searchResults: SearchResult[] = [];
}




