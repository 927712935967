import { JsonObject, JsonProperty } from 'json2typescript';

/** Model Class for the search function result list.
 */
@JsonObject('SearchResultCatalogPath')
export class SearchResultCatalogPath {


    @JsonProperty('parentId', Number, false)
    public parentId: number = 0;

    @JsonProperty('catalogPath', String, false)
    public catalogPath: string = '';




}