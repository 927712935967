import Logger from '$core/Logger';
import Session from '$core/Session';
import WatchlistService from '$src/core/Services/WatchlistService';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import SessionCache from '$storage/cache/SessionCache';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { WatchedItemModel } from '$storage/models/WatchedItemModel';
import GtError from '$util/GtError';

const CACHING_DURATION = 3600;

// Der Key eines WatchedItems im Storage ist immer die ItemId

export default class WatchlistStorage extends SessionCache<number, WatchedItemModel> {
    protected className = 'WatchlistStorage';
    protected loggerLocality = 'Storage.WatchlistStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, false, true);
    }

    public async loadWatchlistFromServer() {
        const methodName = `${this.className}:loadWatchlistFromServer()`;
        this.clear();
        Logger.log(this.loggerLocality, `${methodName} loading Watchlist into cache`);
        const response  = await WatchlistService.instance.GetWatchedItems(Session.instance.getUserLanguageCodeOrFallBack)
        if(isSuccess<WatchedItemModel[]>(response)) {
            response.map( x => this.saveObjectToCache(x.itemId, x))
            Logger.log(this.loggerLocality, `${methodName} succeeded.`);        
        }
        else {
            if (response.detailedObject !== undefined) {
                Logger.log(this.loggerLocality, CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) )
            } else {
                Logger.log(this.loggerLocality, 'ErrorMessage:GetWatchlistsFailed' );
            }

        } 

    }

    public GetSubscriptionStatus(itemId: number): WatchedItemModel | null {
        const methodName = `${this.className}:GetSubscriptionStatus()`;

        const cachedWatchlist = this.getObjectsFromCache().find(x => x.itemId === itemId )
        if (cachedWatchlist === undefined ) {
            Logger.log(this.loggerLocality, `${methodName} getting Watchlist from cache, not found, Id=${itemId}.`);

            return null; 
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting Watchlist from cache, found, Id=${itemId}.`);

            return cachedWatchlist; 
        }
    }

    public async RemoveSubscriber(itemId: number): Promise<BooleanResponse | GtError> {
        const methodName = `${this.className}:removeWatchlist()`;
        const response = await WatchlistService.instance.RemoveSubscriber(itemId)
        if(isSuccess<BooleanResponse>(response))
        {
            if(response.status === false ) {
                Logger.log(this.loggerLocality, `${methodName} RemoveSubscriber item already deleted:  Id=${itemId}`);
            }
            const cachedWatchlist = this.getObjectsFromCache().find(x => x.itemId === itemId )
            if(cachedWatchlist !== undefined) {
                this.removeObjectFromCache(cachedWatchlist.itemId)
            }
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} failed:  Id=${itemId}, ${response.message}`);

        }
        return response;

    }

    public async AddSubscriber(itemId: number): Promise<WatchedItemModel | GtError> {
        const methodName = `${this.className}:addWatchlist()`;
        const cachedWatchlistItem = this.getObjectsFromCache().find(x => x.itemId === itemId )
        if(cachedWatchlistItem !== undefined)
        {
            // item is already watched
            return cachedWatchlistItem; 
        }
        const response = await WatchlistService.instance.AddSubscriber(itemId)
        if(isSuccess<WatchedItemModel>(response))
        {
            this.saveObjectToCache(itemId, response);
        }
        else {
            Logger.log(this.loggerLocality, `${methodName} failed:  Id=${itemId}`);

        }
        return response;

    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }

}
