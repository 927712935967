// The following 'user defined type guards' are used to check if a function returned the 
// expected object of type T or an Error object.
// These functions are especially usefull for checking responses of web API calls.
// The function must define a return type like the followings (both are equivalent):
//     function(): Type<ExpectedType>        e.g. getUser(userId): Type<User>
//     function(...): ExpectedType | Error   e.g. getUser(userId): User | Error
// Remark: The error object must be an instance of the class Error or subclass of Error.
// Copied from here https://spin.atomicobject.com/2018/02/23/error-either-typescript/
// For the explanation of the 'Either Pattern'
// see https://www.schoolofhaskell.com/school/starting-with-haskell/basics-of-haskell/10_Error_Handling#either-may-be-better-than-maybe

// Defines general return type for functions: T | Error
type Result<T> = T | Error;
export type Type<T> = Result<T>;

/**
 * Check a function's return value for error, i.e. check if result is of type Error
 * @param result object/response to check for error
 */
export function isError<T>(result: Result<T>): result is Error {
    return result instanceof Error;
}

/**
 * Check a function's return value for success, i.e. check if result is an instance of type T
 * @param result object/response to check for success
 */
export function isSuccess<T>(result: Result<T | T[]>): result is T {
    return !isError(result);
}
