
import BackButton from '$src/components/layout/menu/BackButton';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import SystemRoles from '$src/core/SystemRoles';
import { observer } from 'mobx-react';
import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

interface INavigationItem {
    id: string;
    text: string;
    link: string;
    allowGuest: boolean;
    visibleOnBackMenu: boolean;
    allowedRoles: string[];
    allowedGroups: string[];
    parent: string;
    openNewWindow?: boolean;
}

interface INavigationProps extends RouteComponentProps {
    onNavigate?: () => void;
}

interface IState {
    openSubMenuParent: string;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
@observer
class Navigation extends React.Component<INavigationProps, IState> {

    protected timeOutId: number;

    constructor(props: INavigationProps) {
        super(props);

        this.state = { openSubMenuParent: '' };
    }

    public render() {
        if (!globalConfig.navigationProperties.backMenuEnabled ||
            globalConfig.navigationProperties.navigationItems.filter(i => i.link.endsWith(this.props.location.pathname)).length > 0) {
            return this.renderNavigation();
        }
        else {
            return this.renderBackButton();
        }
    }

    protected onNavigateInternal = (event: any) => { // React.MouseEvent<HTMLOListElement> 
        event.persist();
        if (!this.hasPageChildren(event.target.id)) {
            if (event.target.id !== '') {
                document.title = globalConfig.appProperties.title + ': ' + event.target.text;
                Session.instance.setActivePage(event.target.id);
                this.setState({ openSubMenuParent: '' });
            }
            if (this.props.onNavigate) {
                this.props.onNavigate();
            }
        }
        else {
            this.setState({ openSubMenuParent: event.target.id });
        }
    }

    protected renderBackButton() {
        return <nav className={'menu__nav'}>
            <ol className={'nav__item-container'}>
                <li>
                    <BackButton />
                </li>
                {this.renderNavigationItems(true)}
            </ol>
        </nav>
    }

    protected renderNavigation() {
        let menuPopOutCSS = Session.instance.isSmallScreenMenuVisible ? ' popout' : '';
        menuPopOutCSS += Session.instance.isSmallScreen ? ' small-screen' : '';
        const navigation: JSX.Element[] = [];
        navigation.push(<div key={'nav_Container'}>
            <nav key={'nav_Main'} className={'menu__nav' + menuPopOutCSS}>
                <ol key={'nav_main_ol'} className={'nav__item-container' + menuPopOutCSS} onClick={(e) => this.onNavigateInternal(e)}>
                    {this.renderNavigationItems(false)}
                </ol>
            </nav></div>
        )
        return navigation;
    }
    protected renderNavigationItems(isBackMenu: boolean) {
        const navigationItems: JSX.Element[] = [];
        let currAriaPage: 'page' | undefined;
        let pageIsActive = false;
        this.getNavigationItemsToDisplay(isBackMenu).map((i, index) => (
            pageIsActive = (Session.instance.getActivePage.toLocaleLowerCase() === i.id.toLocaleLowerCase()),
            currAriaPage = pageIsActive ? 'page' : undefined,
            navigationItems.push(
                <li
                    key={'nav_' + index}
                    onFocus={() => this.onFocusHandler()}
                    onBlur={() => this.onMenuBlur()}
                    hidden={!i.allowGuest && Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)}
                    className={(!i.allowGuest && Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)) ? 'hidden' : ''}>
                    <NavLink
                        key={'nav_link_' + index}
                        to={i.link.toLocaleLowerCase().includes('externalpage') ? i.link + '?d=' + (new Date().getTime() / 1000) : i.link}
                        id={i.id}
                        exact={true}
                        activeClassName={i.link === '#' ? "" : "nav__item--active"}
                        aria-current={currAriaPage}
                        target={i.openNewWindow ? '_blank' : ''}
                    >
                        <Translate>{i.text}</Translate>
                        {pageIsActive ?
                            (<span className="screen-reader-only"><Translate>Navigation:ActiveItem</Translate></span>) : ''}
                    </NavLink>

                    {
                        this.state.openSubMenuParent === i.id ? (
                            <ol key={'nav_link_sub_nav' + index}>{this.renderChildItemsForParent(false, i.id)}</ol>
                        ) : <React.Fragment />}
                </li>
            )));
        return navigationItems;
    }

    protected onMenuBlur() {
        this.timeOutId = setTimeout(() => {
            this.setState({ openSubMenuParent: '' });
        });
    }

    protected onFocusHandler() {
        clearTimeout(this.timeOutId);
    }

    protected renderChildItemsForParent(isBackMenu: boolean, parent: string) {
        const navigationItems: JSX.Element[] = [];
        let currAriaPage: 'page' | undefined;
        const isUserBossOfDefaultRelation = Session.instance.isLoggedInUserBossOfDefaultRelation;
        globalConfig.navigationProperties.navigationItems.filter(i => 
            (
                (isBackMenu && i.visibleOnBackMenu) 
                ||
                !isBackMenu && (i.parent === parent)
                && 
                (i.allowOnlyTrainers === undefined || 
                    i.allowOnlyTrainers === false ||                     
                    (Session.instance.isCurrentUserTrainer()))
                &&
                (!(i.mustBeBossOfDefaultRelation && !isUserBossOfDefaultRelation))
                && (
                    (i.allowedBossRelationCodes == null || i.allowedBossRelationCodes.length === 0)
                    ||
                    (i.allowedBossRelationCodes != null && 
                        i.allowedBossRelationCodes.filter(c => Session.instance.isLoggedInUserBossOfRelation(c)).length > 0)
                )
            )).map((i, index) => (
                currAriaPage = Session.instance.getActivePage.toLocaleLowerCase() === i.id.toLocaleLowerCase() ? 'page' : undefined,
                navigationItems.push(
                    <li
                        key={'navSub_' + parent + index}
                        hidden={(!i.allowGuest && Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest))}
                        className={(!i.allowGuest && Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)) ? 'hidden' : ''}>
                        <NavLink
                            key={'navSub-link_' + parent + index}
                            to={i.link}
                            id={i.id}
                            exact={true}
                            activeClassName={i.link === '#' ? "" : "nav__item--active"}
                            className={Session.instance.isSmallScreen ? ' secondary' : ''}
                            aria-current={currAriaPage}
                            target={i.openNewWindow ? '_blank' : ''}
                        >
                            <React.Fragment>
                                <Translate>{i.text}</Translate>
                                <span className="screen-reader-only"><Translate>Navigation:ActiveItem</Translate></span>
                            </React.Fragment>
                        </NavLink>
                    </li>
                )));
        return navigationItems;
    }

    protected hasPageChildren(page: string) {
        let retVal: boolean = false;
        globalConfig.navigationProperties.navigationItems.forEach(i => {
            if (i.parent === page) {
                retVal = true;
            }
        });
        return retVal;
    }

    protected getNavigationItemsToDisplay(isBackMenu: boolean): INavigationItem[] {
        const navItems = globalConfig.navigationProperties.navigationItems;
        const navItemsAllowed: INavigationItem[] = [];
        const isUserBossOfDefaultRelation = Session.instance.isLoggedInUserBossOfDefaultRelation;
        for (const i of navItems) {
            if ((isBackMenu && i.visibleOnBackMenu || !isBackMenu)
                && (i.allowedGroups === undefined || Session.instance.isCurrentUserMemberOfAtLeastOneOfGroups(i.allowedGroups))
                && (i.allowedRoles === undefined || Session.instance.hasCurrentUserAtLeastOneOfRoles(i.allowedRoles))
                && (i.allowOnlyTrainers === undefined || 
                    i.allowOnlyTrainers === false ||                     
                    (Session.instance.isCurrentUserTrainer()))
                && (i.parent == null || i.parent === '')
                && !(i.mustBeBossOfDefaultRelation && !isUserBossOfDefaultRelation)
                && (
                    (i.allowedBossRelationCodes == null || i.allowedBossRelationCodes.length === 0)
                    ||
                    (i.allowedBossRelationCodes != null &&
                        i.allowedBossRelationCodes.filter(c => Session.instance.isLoggedInUserBossOfRelation(c)).length > 0)
                )) {
                navItemsAllowed.push(i);
            }
        }
        return navItemsAllowed;
    }

}

export default withRouter(Navigation);