
import React from 'react';
import InlineSVG from 'react-inlinesvg';

import IconOpenInNewTab from '$resources/svgs/misc/open-in-new-tab.svg';
import IconDelete from '$resources/svgs/misc/delete.svg';

import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Logger from '$core/Logger';
import Session from '$core/Session';
import { EDocumentType } from '$src/storage/models/enums';
import { F2FDocument } from '$src/storage/models/F2F/F2FDocument';
import { F2FDocumentList } from '$storage/models/F2F/F2FDocumentList';
import { isSuccess } from '$util/Result';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import { Tooltip } from '$src/components/shared/Tooltip';
import F2FService from '$src/core/Services/F2FService';
import AuthService from '$src/core/Services/AuthService';


interface IProps {
    f2fDocuments: F2FDocumentList | null;
    allowedDocumentTypes?: EDocumentType[];
    uploadAllowed?: boolean;
    deleteAllowed?: boolean;
    onReloadDocuments?: () => void;
    hideIcon?: boolean;
}

interface IState {
    errorMessage?: string;
}

export class F2FDocuments extends React.Component<IProps, IState> {
    protected className = 'F2FDocuments';
    protected loggerLocality = 'Components.F2FDocuments';

    constructor(props: IProps) {
        super(props);

        this.state = { errorMessage: '' };
    }

    public render() {
        const docElements = this.getDocumentsList();
        if (docElements.length > 0) {
            return (<React.Fragment>
                <ol className="f2f-documents f2f-document__list">{docElements}</ol>
                {this.state.errorMessage &&
                    <Alert
                        message={this.state.errorMessage}
                        alertAppereance="single-line"
                        alertType="error" />}
            </React.Fragment>)
        }
        else {
            if (this.props.allowedDocumentTypes != null &&
                this.props.allowedDocumentTypes.length === 1 &&
                this.props.allowedDocumentTypes[0] === EDocumentType.GeneralTermsAndConditions) {
                return (<React.Fragment/>)
            } else {
                return (<NoDataFound message={Session.instance.storage.translation.GetString('F2FDocuments:NoDocuments')} />)
            }
        }
    }

    protected getDocumentsList(): JSX.Element[] {
        const methodName = `${this.className}:renderDocument()`;
        const elements: JSX.Element[] = [];
        if (this.props.f2fDocuments != null &&
            this.props.f2fDocuments.allowedDocuments != null &&
            this.props.f2fDocuments.allowedDocuments.length > 0) {
            if (this.props.allowedDocumentTypes != null) {
                // tslint:disable-next-line:max-line-length
                this.props.f2fDocuments.allowedDocuments.filter(doc => (this.props.allowedDocumentTypes === undefined || this.props.allowedDocumentTypes.includes(doc.documentType))).map((doc, index) => {
                    elements.push(this.renderSingleDocument(doc, index));
                });
            } else {
                this.props.f2fDocuments.allowedDocuments.map((doc, index) => {
                    elements.push(this.renderSingleDocument(doc, index));
                });
            }
        }
        Logger.log(`${methodName} - Found ${elements.length + 1} documents.`)
        return elements;
    }

    protected renderSingleDocument(doc: F2FDocument, index: number): JSX.Element {
        const deleteAllowed = this.props.deleteAllowed && Session.instance.loginUser!.id === doc.modifiedBy;
        const showIcon = !this.props.hideIcon;
        return (
            <li key={index}>
                {showIcon &&
                    <div>
                        <InlineSVG src={IconOpenInNewTab} />
                    </div>
                }
                <div className="inlineFlex">
                    <a href={doc.url} target="_blank" onClick={async () => {await AuthService.instance.GetPing(); return true}}>{doc.name} ({doc.filename})</a>
                    {deleteAllowed &&
                        <React.Fragment>
                            <button className="button-link inlineFlex"
                                onClick={() => this.onDeleteDocument(doc.courseDocumentId)}
                                aria-label={Session.instance.storage.translation.GetString('Course:IconDeleteFileName').Format(doc.name)}
                                data-for={doc.name}
                                data-tip={Session.instance.storage.translation.GetString('Course:IconDeleteFileName').Format(doc.name)}>
                                <InlineSVG src={IconDelete} />
                            </button>
                            <Tooltip id={doc.name} />
                        </React.Fragment>}
                </div>
            </li>
        );
    }

    private async onDeleteDocument(documentId: number) {
        const response = await F2FService.instance.deleteF2FDocument(documentId);
        if (isSuccess<BooleanResponse>(response) && response.status) {
            if (this.props.onReloadDocuments) {
                this.props.onReloadDocuments();
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:FailedToDeleteDocument' });
        }
    }
}