import { Attribute } from '$src/storage/models/Attribute';
import { JsonObject, JsonProperty } from 'json2typescript';

/** This class holds information needed for starting a WBT lesson. */
@JsonObject
export class WbtStartParameters {

    /** 
     * URL to be opened by SUI to start the WBT lesson. 
     * Normally the lesson should be opened in a new browser window using the WindowParams. 
     * IMPORTANT: The StartUrl is valid only for one usage and only for a short period, e.g. 30 seconds!
     */
    @JsonProperty('startUrl', String, false)
    public startUrl: string = '';

    /** 
     * Window parameters to be used when opening a new browser window for the lesson (to be used with window.open()). 
     */
    @JsonProperty('windowParameters', String, false)
    public windowParameters: string = '';

    /** 
     * Lesson info (ExternalId) (used for additional start parameters like [HTML5]). 
     */
    @JsonProperty('info', String, false)
    public info: string = '';

    /** 
     * Indicates if the window containing the WBT content will be closed automatically when lesson is finished.
     * Property just for information. 
     */
    @JsonProperty('autoCloseWindow', Boolean, false)
    public autoCloseWindow: boolean = false;

     /** 
      * Indicates if the ContentHost asks if user have completed when finishing a lesson. Valid only for non-compatible lessons.
      * Property just for information. 
      */
    @JsonProperty('askForCompletion', Boolean, false)
    public askForCompletion: boolean = false;

    /** 
     * Indicates if the ContentHost starts the lesson content in its own top level window, i.e. an additional window is opened 
     * in addition the the one opened by the SUI (the window opened by the SUI is hided in this case).
     * Property just for information. 
     */
    @JsonProperty('startInNewToplevelWindow', Boolean, false)
    public startInNewToplevelWindow: boolean = false;

    /** Id of the WBT lesson. */
    @JsonProperty('lessonId', Number, false)
    public lessonId = 0;

    /** String Id of the WBT lesson. */
    @JsonProperty('lessonSId', String, false)
    public lessonSId = '';

    /** Title of the WBT lesson. */
    @JsonProperty('lessonTitle', String, false)
    public lessonTitle = '';
    
    /** All assignments referencing this item and current user. */
    @JsonProperty('lessonAttributes', [Attribute], false)
    public lessonAttributes: Attribute[] | null = null;

   /** Score required to pass the lesson. */
    @JsonProperty('masteryScore', Number, false)
    public masteryScore = 0;
}