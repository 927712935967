import Session from '$core/Session';
import { Translate } from '$src/components/shared/Translate';
import { observer } from 'mobx-react';
import React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Tooltip } from '$components/shared/Tooltip';
import { LanguageSwitcher } from '$src/components/layout/LanguageSwitcher';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import { Contact } from '$components/contact/Contact';
import Logger from '$core/Logger';
import headerSVG from '$src/resources/defaultImages/footer_header_space.custom.svg'
import InlineSVG from 'react-inlinesvg';
import QuickSearch from '../search/elk/QuickSearch';


interface IHeaderProps extends RouteComponentProps<{}> {
    onLanguageSwitched?: () => void;
}

interface IState {
    shouldShowModal: boolean;
    modalTitle: string;
    searchBarActive: boolean;
}

// TODO: CHANGE TO REAC.FC TO BE ABLE TO REMOVE RouteComponentProps
@observer
export class Header extends React.Component<IHeaderProps, IState> {
    protected className = 'Header';
    protected loggerLocality = 'Components.Header';
    constructor(props: IHeaderProps) {
        super(props);
        this.state = {
            modalTitle: '',
            shouldShowModal: false,
            searchBarActive: false,
        }

        this.onLanguageSwitched = this.onLanguageSwitched.bind(this);
        this.toggleMenuHandler = this.toggleMenuHandler.bind(this);
        this.renderHeaderImage = this.renderHeaderImage.bind(this);
    }

    public renderSearchBar(): JSX.Element {
        if (!globalConfig.searchProperties.useLegacySearch &&
            globalConfig.searchProperties.searchPosition === 'header' &&
            Session.instance.isUserLoggedIn &&
            !this.props.location.pathname.startsWith('/search') &&
            !(Session.instance.isPasswordExpired)) {
            return <QuickSearch />;
        } else {
            return <div></div>
        }
    }

    public render() {
        const isUserLoggedIn = Session.instance.isUserLoggedIn;
        return (
            <>
                {this.renderHeaderImage()}
                <header className="header">
                    <div className="l-container l-container--flex header__image--margin">
                        <NavLink to={'/'} aria-label={Session.instance.storage.translation.GetString('Header:Home')}>
                            <div
                                data-tip={Session.instance.storage.translation.GetString('Header:Home')}
                                data-for="header_homeIconTooltip"
                                className="header__logo"
                                onClick={() => this.onLogoClick()}>
                                <img className="header__logo" src={globalConfig.accessibilityProperties.highContrastLogoUrl} />
                            </div>
                            <Tooltip id="header_homeIconTooltip" place="bottom" />
                        </NavLink>
                        <div>
                            <div className="header__information">
                                {this.renderSortHeaderOrder(isUserLoggedIn)}</div>
                            <div className="header__slogan" >
                                <Translate>Header:Slogan</Translate>
                            </div>
                            {this.renderSearchBar()}
                        </div>
                    </div>
                </header>
            </>
        );
    }



    /**
     * Render the buttons in the header when it's configured
     * The order can be defined in the globalconfig.js with the header properties
     * @param isUserLoggedIn 
     */
    private renderSortHeaderOrder(isUserLoggedIn: boolean) {
        const headerElementsConfig = globalConfig.headerProperties;
        headerElementsConfig.sort((a, b) => a.position - b.position);
        return headerElementsConfig.map((element, index) => {
            if (element.headerType === 'logout' && isUserLoggedIn) {
                return (
                    <div className="header__navigation" key={`header_${index}`}>
                        <NavLink
                            to={'/logout'}
                            className="header__font"
                            aria-label={Session.instance.storage.translation.GetString('Header:Logout')}
                            data-tip={Session.instance.storage.translation.GetString('Header:Logout')}
                            onClick={this.onClickBarToggle}>
                            <Translate>{element.text}</Translate>
                        </NavLink>
                        {this.isNextIconAvailable(element.position) ? <div className="header__space">|</div> : null}
                    </div>
                );
            }
            else if (element.headerType === 'contact' && isUserLoggedIn) {
                return (
                    <div className="header__navigation" key={`header_${index}`}>
                        <a
                            className="header__font"
                            aria-haspopup={"dialog"}
                            onClick={() => this.openModal()}>
                            <Translate>{element.text}</Translate>
                        </a>
                        {this.isNextIconAvailable(element.position) ? <div className="header__space">|</div> : null}
                        <ModalPopup
                            isOpen={this.state.shouldShowModal}
                            onRequestClose={() => this.closeModal()}>
                            <Contact />
                        </ModalPopup>
                    </div>
                );
            }
            else if (element.headerType === 'language') {
                if (isUserLoggedIn || globalConfig.layoutProperties.displayLanguageSwitcherOnLoginHeader && !isUserLoggedIn) {
                    return (
                        <div className="header__language-container select header__navigation" key={`header_${index}`} >
                            <LanguageSwitcher
                                onLanguageSwitched={this.onLanguageSwitched}
                                isLanguageSwitcherinHeader={true} />
                            {this.isNextIconAvailable(element.position) ? <div className="header__space">|</div> : null}
                        </div>
                    );
                } else {
                    return;
                }
            } else {
                return;
            }
        })
    }

    /**
     * if the header image enabled then render this method the image footer_header_space.custom.svg 
     */
    private renderHeaderImage() {
        if (!globalConfig.headerConfiguration.imageEnabled) {
            return null;
        }

        return (
            <div className="header__background-image">
                <InlineSVG src={headerSVG} />
            </div>
        )
    }

    /**
     * Check if the next Button is configured true 
     * for display the | after the Buttons
     * @param elementPosition 
     */
    private isNextIconAvailable(elementPosition: number): boolean {
        const headerElementsConfig = globalConfig.headerProperties;
        const isUserLoggedIn = Session.instance.isUserLoggedIn;
        headerElementsConfig.sort((a, b) => a.position - b.position);
        if (elementPosition < headerElementsConfig.length && isUserLoggedIn) {
            return true;
        }
        return false;
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false, modalTitle: Session.instance.storage.translation.GetString('Contact:PopUpTitle') }); // Replace with actual Titel translation
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true, modalTitle: Session.instance.storage.translation.GetString('Contact:PopUpTitle') }); // Replace with actual Titel translation
    }

    private toggleMenuHandler() {
        const methodName = `${this.className}:toggleMenuHandler()`;
        Logger.log(this.loggerLocality, `${methodName}`);
        Session.instance.toggleSmallScreenMenu();
    }

    protected onLogoClick() {
        Session.instance.setSmallScreenMenuVisibility(false);
    }

    private onLanguageSwitched() {
        const methodName = `${this.className}:onLanguageSwitched()`;
        Logger.log(this.loggerLocality, `${methodName} event executed on component Menu`);
        if (this.props.onLanguageSwitched !== undefined) {
            this.props.onLanguageSwitched();
        }
    }

    private onClickBarToggle(): void {
        Session.instance.setSmallScreenMenuVisibility(false);
    }
}