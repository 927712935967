import { InputAttributes } from '$src/components/shared/InputAttributes';
import { Translate } from '$src/components/shared/Translate';
import { FieldValidators } from '$src/util/Fieldvalidators';
import React from 'react';
import { KeyboardEvent } from 'react';

interface IProps {
    attributes: InputAttributes;
    isRequired?: boolean;
    onChange?: (id: string, value: string, errMsg: string) => void;
    onCrEntered?: (id: string) => void;
}

interface IState {
    value: string;
    isRequired?: boolean;
    isValid: boolean;
    errorMsg: string;
}
// tslint:disable:object-literal-sort-keys
export class InputTextArea extends React.Component<IProps, IState> {
    protected controlTextArea: HTMLTextAreaElement | null;
    protected className = 'InputTextArea';
    protected loggerLocality = 'Components\shared.InputTextArea';

    constructor(props: IProps) {
        super(props);

        const sourceValue: string = this.props.attributes.value;
        const initialValidation: boolean = (this.props.attributes.initialValidation === undefined) ? false : this.props.attributes.initialValidation;

        // Validate input value.
        const errorMessage = this.validateField(initialValidation, sourceValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        // Initialize state based on validation check.
        this.state = {
            value: sourceValue,
            isRequired: this.props.isRequired,
            isValid: controlValid,
            errorMsg: errorMessage,
        }

        // If the control is invalid, the event will be triggered to inform parent component.
        if (!controlValid) {
            if (this.props.onChange) {
                this.props.onChange(this.props.attributes.id, sourceValue, errorMessage);
            }
        }
    }

    public componentDidMount(): void {
        if (this.props.attributes.editMode && this.props.attributes.hasFocus) {
            this.setFocus();
        }
    }

    public componentDidUpdate() {
        if (this.props.isRequired !== this.state.isRequired) {
            this.setState({ isRequired: this.props.isRequired },
                () => {
                    // Validate input value.
                    const errorMessage = this.validateField((this.props.attributes.initialValidation === undefined)
                        ? false : this.props.attributes.initialValidation,
                        this.props.attributes.value);
                    const controlValid = errorMessage.length === 0 ? true : false;

                    // If the control is invalid, the event will be triggered to inform parent component.
                    if (!controlValid) {
                        if (this.props.onChange) {
                            this.props.onChange(this.props.attributes.id, this.props.attributes.value, errorMessage);
                        }
                    }
                    this.setState({ errorMsg: errorMessage });
                });
        }
    }

    /**
     * Render methode.
     */
    public render(): JSX.Element {
        const lblClass: string = this.props.attributes.labelClassName ? this.props.attributes.labelClassName : 'input-label';
        let txtClass: string = 'input-field';
        txtClass = txtClass + this.errorClass(this.state.errorMsg)

        let boxClass: string = (this.props.attributes.class === undefined || this.props.attributes.class === null) ? 'l-box--short' : this.props.attributes.class
        boxClass = boxClass + ' l-box__input l-box__input--edit'
        const placeholder: string = (this.props.attributes.placeholder === undefined || this.props.attributes.placeholder === null) ? '' : this.props.attributes.placeholder;
        const rows: number = (this.props.attributes.rows === undefined || this.props === null) ? 3 : this.props.attributes.rows;

        // Build final component element        
        const inputElement: JSX.Element[] = [];

        inputElement.push(
            <label key={'InputTextArea_Label_' + this.props.attributes.id} htmlFor={this.props.attributes.id} className={lblClass}>
                <Translate>{this.props.attributes.label}</Translate>
                {((this.state.isRequired !== undefined ? this.state.isRequired : this.props.attributes.isRequired) && this.props.attributes.editMode) ? ' *' : ''}
            </label>
        )

        inputElement.push(
            <textarea
                key={'InputTextArea_Input_' + this.props.attributes.id}
                id={this.props.attributes.id}
                name={this.props.attributes.id}
                className={txtClass}
                readOnly={this.props.attributes.isReadOnly}
                required={this.state.isRequired !== undefined ? this.state.isRequired : this.props.attributes.isRequired}
                value={this.state.value}
                placeholder={placeholder}
                maxLength={this.props.attributes.maxLength}
                onChange={(e) => this.onChange(e)} onKeyUp={this.onKeyUp} ref={(e) => this.controlTextArea = e} rows={rows} />
        )
        {
            !this.props.attributes.disableErrorMessage ? inputElement.push(
                <span key={'InputTextArea_ErrorMsg_' + this.props.attributes.id} className={'input-message error'}>
                    <Translate>{this.state.errorMsg}</Translate>
                </span>
            ) : null
        }


        return (
            <div key={'InputTextArea_Div_' + this.props.attributes.id} className={boxClass}>
                {inputElement}
            </div >
        )
    }

    /**
     * Retrieve error class.
     */
    protected errorClass(errorMsg: string) {
        return (errorMsg.length === 0 ? '' : ' has-error');
    }

    /**
     * Validate input value.
     */
    protected validateField(validateValue: boolean, value: string): string {
        let message: string = '';

        if (validateValue) {
            if (message.length === 0) {
                message = FieldValidators.Required(value, this.props.isRequired !== undefined ? this.props.isRequired : this.props.attributes.isRequired);
            }

            if (message.length === 0) {
                message = FieldValidators.RegExpression(value, this.props.attributes.regExpression)
            }
        }
        return message;
    }

    /**
     * Set input focus to input field.
     */
    protected setFocus() {
        if (this.controlTextArea !== null) {
            const htmlInput = this.controlTextArea;
            htmlInput.focus();
        }
    }

    /**
     * Callback for text input: text has changed.
     */
    protected onChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement;
        const targetValue: string = target.value;

        // Validate input value
        const errorMessage = this.validateField(true, targetValue);
        const controlValid = errorMessage.length === 0 ? true : false;

        this.setState({
            value: targetValue,
            isValid: controlValid,
            errorMsg: errorMessage,
        })

        // Callback for control: Text has changed.
        if (this.props.onChange) {
            this.props.onChange(this.props.attributes.id, targetValue, errorMessage);
        }
    }

    /**
     * Callback called each time a key is released (key up event).
     */
    protected onKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {  // ******* event type?
        if (event.keyCode === 13) {
            if (this.props.onCrEntered) {
                this.props.onCrEntered(this.props.attributes.id);
                event.preventDefault();
            }
        }
    }
}