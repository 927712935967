import ServiceClient from "$src/core/ServiceClient";
import { UserTCApprovalStatus } from "$src/storage/models/Privacy/UserTCApprovalStatus";
import GtError from "$src/util/GtError";
import { TCDocument } from "$src/storage/models/Privacy/TCDocument";
import { BooleanResponse } from "$src/storage/models/BooleanResponse";

/**
 * Service for privacy statement
 */
export default class PrivacyService extends ServiceClient {
    protected static _instance: PrivacyService | null = null;

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): PrivacyService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Returns if gdpr is enabled for authorized users
     */
    public async isGdprEnabled(): Promise<BooleanResponse | GtError> {
        const response: BooleanResponse | GtError = await this.get<BooleanResponse>('gdpr/isactive', BooleanResponse, undefined, undefined);
        return response;
    }

    /**
     * Returns if gdpr is enabled for unauthorized users (selfreg)
     */
    public async isGdprEnabledNoAuthorization(isAnonymousUser: boolean): Promise<BooleanResponse | GtError> {
        const response: BooleanResponse | GtError = await this.get<BooleanResponse>('gdpr/isactive', BooleanResponse, undefined, undefined, String(isAnonymousUser));
        return response;
    }

    /**
     * Returns the current terms and condition document
     */
    public async getCurrentTermsAndConditionsDocument(): Promise<TCDocument | GtError> {
        const response: TCDocument | GtError = await this.get<TCDocument>('gdpr/getCurrentTermsAndConditionsDocument', String, undefined, undefined);
        return response;
    }

    /**
     * Returns the last entry of the users terms and conditions history and the current terms and condition document, if not present in users list
     */
    public async getUserTermsAndConditionsApprovalStatuses(): Promise<UserTCApprovalStatus[] | GtError> {
        const response: UserTCApprovalStatus[] | GtError = await this.get<UserTCApprovalStatus[]>('gdpr/getUserTermsAndConditionsApprovalStatuses', String, undefined, undefined);
        return response;
    }

    /**
     *  Gets the DSGVO Document from the registration / anonymous Domain
     */
    public async getSelfregistrationTermsAndConditionsApprovalStatus(langCode: string, isAnonymousUser: boolean): Promise<UserTCApprovalStatus | GtError> {
        const response: UserTCApprovalStatus | GtError = 
            await this.get<UserTCApprovalStatus>(
                'gdpr/getSelfregistrationTermsAndConditionsApprovalStatus', 
                String, 
                undefined, 
                [{ name: 'language', value: langCode }], 
                String(isAnonymousUser));
        return response;
    }

    /**
     * Decline/Revoke the current terms and conditions for the user
     */
    public async declineTermsAndConditions(tcDocumentId: number): Promise<boolean | GtError> {
        const response: boolean | GtError = await this.post<boolean>(`gdpr/declineTermsAndConditions/${String(tcDocumentId)}`, {}, Boolean, undefined, undefined);
        return response;
    }

    /**
     * Approve the current terms and conditions for the user
     */
    public async approveTermsAndConditions(tcDocumentId: number): Promise<boolean | GtError> {
        const response: boolean | GtError = await this.post<boolean>(`gdpr/approveTermsAndConditions/${String(tcDocumentId)}`, {}, Boolean, undefined, undefined);
        return response;
    }


    /**
     * Download the given pdf document
     * @param tcDocument terms and conditions document
     */
    public downloadTermsAndConditions(tcDocument: TCDocument) {
        // convert Byte64 to byte[]                   
        const fileContent = window.atob(tcDocument.document.toString());
        const bytes = new Uint8Array(fileContent.length);
        for (let i = 0; i < fileContent.length; i++) {
            const ascii = fileContent.charCodeAt(i);
            bytes[i] = ascii;
        }        

        // create blob
        const blob = new Blob([bytes], { type: 'application/pdf' });
        
        // create download anchor and click it
        // or use ms function for IE
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, tcDocument.filename);
        } else {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = tcDocument.filename;            
            a.click();
        }
    }
} 