import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ExternalCourseRevertStatusRequest {
    @JsonProperty('registrationId', Number, false)
    public registrationId: number;

    @JsonProperty('userId', Number, false)
    public userId: number;

    @JsonProperty('revertLessonStatusBossComment', String, false)
    public revertLessonStatusBossComment: string;
}