import { Heading } from '$components/shared/Heading';
import { InputAttributes, ISetFocus } from '$components/shared/InputAttributes';
import { InputText } from '$components/shared/InputText';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import LicenseRegistrationService from '$src/core/Services/LicenseRegistrationService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import CustomErrorMessage from '$src/util/CustomErrorMessage';
import { isSuccess } from '$src/util/Result';
import React from 'react';
import { Redirect, } from 'react-router';

interface IURLParamState {
    contractNumber: string;
}

interface IState extends IURLParamState {
    username: string;
    email: string;
    focusTo: '' | 'contractNumber' | 'username' | 'email';  // Id of the TextInput getting the input focus on next render.
    errorMessage: string;
    canRegisterForLicense: boolean;
    checkForLicense: boolean;
    statusInputControls: IStatusInputControl[];
    isFormValid: boolean;
}

interface IStatusInputControl {
    id: string;
    isValid: boolean;
    errorMsg: string;
}

export class LicenseSelfRegCheck extends React.Component<{}, IState> {
    protected ref: ISetFocus;

    constructor(props: {}) {
        super(props);

        // Get URL Parameters
        const partialStateUrlParamState = this.getURLParameters();
        const formfields: IStatusInputControl[] = [
            {
                errorMsg: '',
                id: 'username',
                isValid: true,
            },
            {
                errorMsg: '',
                id: 'contractNumber',
                isValid: true,
            },
            {
                errorMsg: '',
                id: 'email',
                isValid: true,
            },
        ];
        this.state = {
            ...partialStateUrlParamState,
            canRegisterForLicense: false,
            checkForLicense: false,
            email: '',
            errorMessage: '',
            focusTo: partialStateUrlParamState.contractNumber !== '' ? 'username' : 'contractNumber',
            isFormValid: false,
            statusInputControls: formfields,
            username: ''
        }

        this.areInputsFieldsNotEmpty = this.areInputsFieldsNotEmpty.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString('LicenseReg:ActivateLicense');
    }

    public render() {
        if (this.state.canRegisterForLicense) {
            return <Redirect push={true} to={`/licenseSelfReg?username=${this.state.username}&email=${this.state.email}&contractNumber=${this.state.contractNumber}`} />
        } else {
            return (
                <div className="login__background">
                    <div className="l-container">
                        {this.renderLicenseSelfRegForm()}
                    </div>
                </div>
            )
        }
    }

    private getURLParameters(): IURLParamState {
        const parameters = new URLSearchParams(window.location.search);
        const urlContractNumber = parameters.get('contractNumber');

        return {
            contractNumber: urlContractNumber != null ? urlContractNumber : ''
        }
    }

    private renderLicenseSelfRegForm(): JSX.Element {
        const contractNumberAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'contractNumber',
            label: 'LicenseReg:ContractNumber',
            value: this.state.contractNumber,
            class: 'box__login',
            isReadOnly: false,
            isRequired: true,
            regExpression: undefined,
            hasFocus: this.state.focusTo === 'contractNumber',
            placeholder: '',
            editMode: true,
            autoComplete: 'contractNumber'
        };

        const userAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'username',
            label: 'LicenseReg:Username',
            value: this.state.username,
            class: 'box__login',
            isReadOnly: false,
            isRequired: true,
            regExpression: /^[a-zA-Z0-9\-_.()@]*$/,
            hasFocus: this.state.focusTo === 'username',
            placeholder: '',
            editMode: true,
            autoComplete: 'username'
        };

        const emailAttributes: InputAttributes =
        {
            // tslint:disable:object-literal-sort-keys
            id: 'email',
            label: 'LicenseReg:Email',
            value: this.state.email,
            class: 'box__login',
            isReadOnly: false,
            isRequired: true,
            // tslint:disable-next-line:max-line-length
            regExpression: /^(([^<>()[\]\\.,;:\s@\""]+(\.[^<>()[\]\\.,;:\s@\""]+)*)|(\"".+\""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            hasFocus: this.state.focusTo === 'email',
            placeholder: '',
            editMode: true,
            setFocus: this.ref,
            autoComplete: 'current-email'
        };
        if (this.state.checkForLicense) {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">
                        <div className="login__spinner">
                            <ProgressSpinner />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        } else {
            return <React.Fragment>
                <div className="login__whitepanel">
                    <div className="login__form l-form-container">
                        <div className="box__login login__AcitvateLicense">
                            <Heading headingLevel={1} cssClass="heading__Level4">
                                <Translate>LicenseReg:SelfRegTitle</Translate>
                            </Heading>
                            <div className="text">
                                <Translate>LicenseReg:SelfRegHint</Translate>
                            </div>
                        </div>
                        <br />
                        <form className="box__login">
                            <InputText
                                attributes={contractNumberAttributes}
                                onChange={(id, text, errMsg) => this.onTextChange(id, text, errMsg)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                            <InputText
                                attributes={userAttributes}
                                onChange={(id, text, errMsg) => this.onTextChange(id, text, errMsg)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                            <InputText
                                attributes={emailAttributes}
                                onChange={(id, text, errMsg) => this.onTextChange(id, text, errMsg)}
                                onCrEntered={(id) => this.onCrEntered(id)}
                            />
                            <span className={'input-message error'}>
                                <Translate>{this.state.errorMessage}</Translate>
                            </span>
                        </form>
                        <div className="box__login login__LoginPanel">
                            <button
                                id="btnSave"
                                type="button"
                                className="btn--md"
                                onClick={() => this.checkLicense()}
                                disabled={!this.areInputsFieldsNotEmpty()} >
                                <Translate>LicenseReg:Save</Translate>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
    }

    /**
     * Callback for TextInput component: text has changed.
     * @param id Id of the TextInput triggering this callback.
     * @param text Current text from TextInput.
     */
    private onTextChange(id: string, text: string, errorMsg: string) {
        switch (id) {
            case 'contractNumber':
                this.setState({ contractNumber: text });
                break;
            case 'username':
                this.setState({ username: text });
                break;
            case 'email':
                this.setState({ email: text });
                break;
        }

        // Get state of status panel.
        const statusInputs = this.state.statusInputControls;

        // Get affected control reference (current input control [user attribute])
        const inputIndex = statusInputs.findIndex(i => i.id === id);
        const statusInput: IStatusInputControl = statusInputs[inputIndex];

        // Update affected control values
        statusInput.isValid = errorMsg.length === 0 ? true : false;
        statusInput.errorMsg = errorMsg;

        // Update state of status panel                
        statusInputs[inputIndex] = statusInput;
        // Check, whether any input controlis invalid
        const isFormValid = statusInputs.some(i => i.isValid === false);

        this.setState({ focusTo: '', statusInputControls: statusInputs, isFormValid });
    }

    private handleClick = () => {
        if (this.ref !== undefined) {
            if (this.ref.innerRef !== undefined && this.ref.innerRef.current !== null) {
                this.ref.innerRef.current.focus()
            }
        }
    };

    /**
     * Callback for TextInput component: user has entered a Carriage Return ('Enter' key).
     * @param id Id of the TextInput triggering this callback.
     */
    private onCrEntered(id: string) {
        if (id === 'username') {
            this.setState({ focusTo: 'email' })
        } else if (id === 'email') {
            this.checkLicense();
        }
    }

    /**
     * Check if fields (contractNumber, username and email) are not empty
     */
    private areInputsFieldsNotEmpty(): boolean {
        let isValid: boolean = true;
        if (this.state.contractNumber === '' || this.state.username === '' || this.state.email === '' || this.state.isFormValid) {
            isValid = false;
        }
        return isValid;
    }

    private async checkLicense() {
        this.setState({ checkForLicense: true });

        const canRegisterForLicense = await LicenseRegistrationService.instance.canRegisterForLicense(
            Session.instance.getUserLanguageCodeOrFallBack,
            this.state.username,
            this.state.email,
            this.state.contractNumber
        );
        if (isSuccess<BooleanResponse>(canRegisterForLicense)) {
            if (canRegisterForLicense.status) {
                this.setState({ canRegisterForLicense: true, focusTo: '' });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:CheckCanRegisterForLicenseFailed' })
                this.handleClick();
            }
        } else {
            if (canRegisterForLicense != null && canRegisterForLicense.detailedObject != null) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(canRegisterForLicense.detailedObject.subStatusCode) });
            } else {
                this.setState({ errorMessage: 'ErrorMessage:CheckCanRegisterForLicenseFailed' });
            }
            this.handleClick();
        }

        this.setState({ checkForLicense: false });
    }
}
