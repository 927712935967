import React from "react";
import { Translate } from "$components/shared/Translate";

interface IGuestRegistrationActionConfirmationProps {
    onConfirmClick: () => void;
    onCancelClick: () => void;
}


export default function GuestRegistrationActionConfirmation(props: IGuestRegistrationActionConfirmationProps) {
    return (
        <>
            <Translate>Class:GuestRegistrationInfo</Translate>
            <div className="modal__spread-buttons">
                <button type="button"
                    className="btn btn--sm marginRight-5"
                    onClick={() => props.onConfirmClick()}>
                    <Translate>Class:ConfirmGuestRegistration</Translate>
                </button>
                <button type="button"
                    className="btn btn--sm btn--secondary"
                    onClick={() => props.onCancelClick()}
                    disabled={false}>
                    <Translate>Class:CancelGuestRegistration</Translate>
                </button>
            </div>
        </>
    )
}