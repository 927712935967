import React from 'react';

import { NoDataFound } from '$components/shared/WarningsAndErrors/NoDataFound';
import Session from '$core/Session';
import { ItemSummary } from '$src/components/item/itemSummaries/ItemSummary';
import { ProgressSpinner } from '$src/components/shared/ProgressSpinner';
import { Translate } from '$src/components/shared/Translate';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';
import { RegisteredItem } from '$src/storage/models/RegisteredItem';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

interface IProps {
    maxItems: number;
    allItemsLink?: string;
    allItemsLinkText?: string;
    parentHeadingLevel: number;
}
@observer
export class RegisteredItems extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public async componentDidMount() {
        const registeredItemsLoading = Session.instance.storage.registeredItem.isLoading;
        const registeredItemsInitialized = Session.instance.storage.registeredItem.isInitialized;
        const registeredItemStorage = Session.instance.storage.registeredItem;

        if (!registeredItemsInitialized && !registeredItemsLoading)
        {
            await registeredItemStorage.getObjects();
        }
    }
    
    public render() {
        const registeredItemStorage = Session.instance.storage.registeredItem;
        if (!registeredItemStorage.isInitialized) {
            return <ProgressSpinner />;
        }
        const registeredItems = registeredItemStorage.getObjectsFromCache();
        if(registeredItems != null) {
            registeredItemStorage.sort(registeredItems);
        }
        return (
            <div className="l-box-container l-box-container--column-flex">
                {this.renderRegisteredItems(registeredItems)}
                {this.renderAllItemsLink(registeredItems != null && registeredItems.length > 0)}
            </div>
        );
    }

    protected renderRegisteredItems(elements: RegisteredItem[] | null): JSX.Element | JSX.Element[] {
        if (elements == null || elements.length === 0) {
            return <NoDataFound message={ Session.instance.storage.translation.GetString('RegisteredItems:NoData') } />;
        }
        else {
            return (
                <ul className="item-box-tile__parent">
                    {this.renderRegisteredItems2(elements)}
                </ul>
            )
        }
    }
    protected renderRegisteredItems2(elements: RegisteredItem[]): JSX.Element[] | null {
        const itemsToDisplay = this.props.maxItems > 0 && elements.length > this.props.maxItems ? this.props.maxItems : elements.length;
        elements = elements.filter(item => 
            !globalConfig.registeredItemsProperties.ignoreItemsWithRegistrationStatus.includes(item.registrationStatus) &&
            !globalConfig.registeredItemsProperties.ignoreItemsWithLessonStatus.includes(item.lessonStatus) &&
            globalConfig.registeredItemsProperties.classStatusToShow.includes(item.classStatus)
          );

        return elements.slice(0, itemsToDisplay).map(assignment =>      
            <ItemSummary
                key={assignment.sId}
                item={assignment}
                itemCallerContext={EItemDetailCallerContextType.RegisteredItem}
                parentAssignmentId={0}
                parentCatalogFolderId={0}
                parentTrainingPlanId={0}
                {...this.props}  />
        );
    }

    protected renderAllItemsLink(hasElements: boolean): JSX.Element | null {
        if (hasElements && this.props.allItemsLink != null && this.props.allItemsLinkText != null) {
            return <div className="registered-items__all-items-link">
                <NavLink to={ this.props.allItemsLink } className="btn btn--md btn--primary">
                    <Translate>{ this.props.allItemsLinkText }</Translate>
                </NavLink>
            </div>
        }
        else { return null }
    }
}