import React from 'react';

import { NavigationPointContainer } from '$components/layout/menu/NavigationPointContainer';
import { MenuBreadCrumb } from '$src/components/breadCrumb/MenuBreadCrumb';
import { NavigationPoint } from '$src/components/layout/menu/NavigationPoint';
import { Translate } from '$src/components/shared/Translate';
import Logger from '$src/core/Logger';
import UserService from '$src/core/Services/UserService';
import Session from '$src/core/Session';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import GtError from '$src/util/GtError';
import { isSuccess } from '$src/util/Result';
import { HeadingCollapsible } from '$src/components/shared/HeadingCollapsible';
import { UnmountClosed } from 'react-collapse';
import UserPreferencesService from '$src/core/Services/UserPreferencesService';
import { UserPreferences } from '$src/storage/models/UserPreferences/UserPreferences';
import { MorePanel } from '$storage/models/MorePanel';


interface IState {
    hasAccessToLessonStatusFromOfflineXmlImport: boolean;
    userPreferences: UserPreferences | undefined;
}

export class ExtendedMenu extends React.Component<{}, IState> {
    private pageTitle = 'Navigation:More';
    private loggerLocality = 'Components.ExtendedMenu';

    constructor(props: {}) {
        super(props);

        this.state = {
            hasAccessToLessonStatusFromOfflineXmlImport: false,
            userPreferences: Session.instance.loginUserPreferences,
        };
        this.togglePanel = this.togglePanel.bind(this);

    }

    public async UNSAFE_componentWillMount() {
        const methodName = 'componentWillMount';
        const response = await UserService.instance.hasAccessToLessonStatusFromOfflineXmlImport();
        if (isSuccess<BooleanResponse>(response)) {
            this.setState({ hasAccessToLessonStatusFromOfflineXmlImport: true });
        }
        else if (response == null || response instanceof GtError) {
            Logger.log(this.loggerLocality, `${methodName}: Failed to check hasAccessToLessonStatusFromOfflineXmlImport`);
            return;
        }
    }

    public componentDidMount() {
        document.title = globalConfig.appProperties.title + ': ' + Session.instance.storage.translation.GetString(this.pageTitle);
    }

    public render() {
        return (
            <div className="l-container extended-menu__container">
                <MenuBreadCrumb {...this.props} />
                <div>
                    {this.renderMenuPoints()}
                </div>
            </div>
        );
    }

    public renderMenuPoints(): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const menuPoints = globalConfig.navigationProperties.extendedNavigationItems;
        const groups = [...new Set(menuPoints.map(item => item.groupId))];

        groups.map(groupId => {
            if (this.renderMenupointsForGroup(groupId).length !== 0) {
                const isOpen = this.state.userPreferences?.preferences.morePanels?.find(mp => mp.id === groupId);
                elements.push(
                    <React.Fragment>
                        <HeadingCollapsible
                            headingLevel={1}
                            headingCssClass='heading__Title'
                            containerCssClass=""
                            isOpened={isOpen === null ? true : isOpen?.expanded}
                            onToggleOpenedState={() => this.togglePanel(groupId)}>
                            <Translate>{'More:' + groupId}</Translate>
                        </HeadingCollapsible>
                        <UnmountClosed isOpened={isOpen == null ? true : isOpen?.expanded}>
                            <NavigationPointContainer>
                                {this.renderMenupointsForGroup(groupId)}
                            </NavigationPointContainer>
                        </UnmountClosed>
                    </React.Fragment >)
            }
        });
        return elements;
    }
    protected togglePanel(groupId: string) {
        const preferences = Session.instance.loginUserPreferences;
        if (preferences != null) {
            if (preferences.preferences.morePanels != null) {
                const mp = preferences.preferences.morePanels.find(o => o.id === groupId);
                if (mp != null) {
                    mp.expanded = !mp.expanded;
                }
                else {
                    const mp = new MorePanel(groupId, false);
                    preferences.preferences.morePanels.push(mp);
                }
            }
            else {
                preferences.preferences.morePanels = [];
                const mp = new MorePanel(groupId, false);
                preferences.preferences.morePanels.push(mp);
            }
            this.setState({ userPreferences: Session.instance.loginUserPreferences });
            UserPreferencesService.instance.saveUserPreferences(preferences);
        }
    }

    private renderMenupointsForGroup(groupId: string): JSX.Element[] {
        const elements: JSX.Element[] = [];
        const menuPoints = globalConfig.navigationProperties.extendedNavigationItems;
        const userIsBossOfDefaultRelation = Session.instance.isLoggedInUserBossOfDefaultRelation;
        menuPoints.filter(mp => mp.groupId === groupId
            && !(mp.mustBeBossOfDefaultRelation && !userIsBossOfDefaultRelation)
            && (
                (mp.allowedBossRelationCodes == null || mp.allowedBossRelationCodes.length === 0)
                ||
                (mp.allowedBossRelationCodes != null &&
                    mp.allowedBossRelationCodes.filter(c => Session.instance.isLoggedInUserBossOfRelation(c)).length > 0)
            )
            && (mp.allowedRoles === undefined || Session.instance.hasCurrentUserAtLeastOneOfRoles(mp.allowedRoles))
            && (mp.allowedGroups === undefined || Session.instance.isCurrentUserMemberOfAtLeastOneOfGroups(mp.allowedGroups))
            && (mp.allowOnlyTrainers === undefined ||
                mp.allowOnlyTrainers === false ||
                (Session.instance.isCurrentUserTrainer()))
            && (mp.parent == null || mp.parent === '')).map((mp, index) => {
                if (mp.id === 'LessonStatusImportOffline' && this.state.hasAccessToLessonStatusFromOfflineXmlImport) {
                    elements.push(
                        <NavigationPoint
                            key={index}
                            link={mp.link}
                            isExternalLink={mp.isExternalLink}
                            text={mp.text}
                            id={mp.id}
                            backgroundColor={mp.backgroundColor}
                            iconColor={mp.iconColor}
                            openNewWindow={mp.openNewWindow} />
                    );
                    return;
                }
                elements.push(
                    <NavigationPoint
                        key={index}
                        link={mp.link}
                        isExternalLink={mp.isExternalLink}
                        text={mp.text}
                        id={mp.id}
                        backgroundColor={mp.backgroundColor}
                        iconColor={mp.iconColor}
                        openNewWindow={mp.openNewWindow} />
                );
            });
        return elements;
    }
}
export default ExtendedMenu;