import { AutoComplete, DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useEffect } from 'react';
import InlineSVG from 'react-inlinesvg';

import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import FileSharingService from '$core/Services/FileSharingService'
import { FileSharingUserPanel } from '$src/components/fileSharing/FileSharingUserPanel';
import { Heading } from '$src/components/shared/Heading';
import { Translate } from '$src/components/shared/Translate';
import { ErrorMessage } from '$components/shared/WarningsAndErrors/ErrorMessage';
import Session from '$src/core/Session';
import { FileSharingDocument } from '$src/storage/models/FileSharing/FileSharingDocument';
import { FileSharingLesson } from '$src/storage/models/FileSharing/FileSharingLesson';
import { FileSharingUser } from '$src/storage/models/FileSharing/FileSharingUser';
import { ScheduleCode } from '$src/storage/models/FileSharing/ScheduleCode';
import Icondelete from '$resources/svgs/misc/delete.svg';
import { isSuccess } from '$src/util/Result';
import { Item } from '$storage/models/Item';

import IconDownloadAll from '$resources/svgs/filesharing/download-all.svg';
import { StringResponse } from '$src/storage/models/StringResponse';
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import GtError from '$src/util/GtError';
import { ModalPopup } from '$src/components/shared/ModalPopup';
import GTButton from '$src/components/shared/Atoms/GTButton';
import FileSharingTagManagement from '$components/fileSharing/FileSharingTagManagement';
import { EFileSharingSortEnum, ESortDirection } from '$src/storage/models/enums';
import SortingAlgorithm from '$src/util/SortingAlgorithm';

interface IProps {
    lesson: Item;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number;
    parentScheduleId?: number;
    headingLevel?: number;
}

/**
 * Item for the kendo dropdown list
 * @interface IDdlEntry
 */
interface IDdlEntry {
    id: number;
    text: string;
}

export default function FileSharing(props: IProps) {
    const [fileSharingSortingModeList, setFileSharingModelList] = React.useState<IDdlEntry[]>([
        { id: EFileSharingSortEnum.Manually, text: Session.instance.storage.translation.GetString('FileSharing:SortManually') },
        { id: EFileSharingSortEnum.Ascending, text: Session.instance.storage.translation.GetString('FileSharing:SortAscending') },
        { id: EFileSharingSortEnum.Descending, text: Session.instance.storage.translation.GetString('FileSharing:SortDescending') }])

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [fileSharingLesson, setFileSharingLesson] = React.useState(new FileSharingLesson());
    const [filteredFileSharingLesson, setFilteredFileSharingLesson] = React.useState(new FileSharingLesson());
    const [schedules, setSchedules] = React.useState<IDdlEntry[]>([]);
    const [selectedSchedule, setSelectedSchedule] = React.useState<IDdlEntry | undefined>(undefined);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [tempDownloadUrl, setTempDownloadUrl] = React.useState<string | null>(null);
    const [currentUserIsTutor, setCurrentUserIsTutor] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteButton, setShowDeleteButton] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const [selectedSortingMode, setSelectedSortingMode] = React.useState<IDdlEntry>(fileSharingSortingModeList.filter(s => s.id === EFileSharingSortEnum.Manually)[0]);

    /**
     * Loads all schedules the user has tutorize rights on into the dropdown for schedule selection
     * @param currentSchedule Optional parameter which entry of the dropdown has to be selected
     */
    function loadTPlanSchedules(currentSchedule?: number) {
        FileSharingService.instance.getMyTutorizeSchedules(props.parentTrainingPlanId).then((schedules: ScheduleCode[]) => {
            if (isSuccess(schedules)) {
                const ddl: IDdlEntry[] = [];
                schedules.forEach((schedule: ScheduleCode) => {
                    ddl.push({ id: schedule.scheduleId.valueOf(), text: schedule.code.valueOf() });
                });
                setSchedules(ddl);
                setSelectedSchedule(ddl.find(p => p.id === currentSchedule));
            } else {
                setSchedules([]);
                setErrorMessage('FileSharing:Error_LoadingSchedules');
            }
        });
    }

    /**
     * Loads the file sharing lesson including the users and users documents
     * @param scheduleId Optional parameter to overwrite the props.parentScheduleId - uses for schedule selection
     */
    async function loadFileSharing(scheduleId?: number) {
        const schId: number | undefined = scheduleId || props.parentScheduleId;

        setIsLoaded(false);
        setFileSharingLesson(new FileSharingLesson());

        await FileSharingService.instance.getFileSharingLesson(
            props.lesson.itemId,
            props.parentAssignmentId,
            props.parentTrainingPlanId,
            schId).then((res: FileSharingLesson) => {
                if (isSuccess(res)) {
                    const userId = Session.instance.loginUser !== null ? Session.instance.loginUser.id : 0;
                    setFileSharingLesson(res);
                    setFilteredFileSharingLesson(res);
                    setIsLoaded(true);
                    setCurrentUserIsTutor(res.users.some(p => p.userId === userId && p.isTutor.value.valueOf()));
                    if (res.users.some(p => p.userId === userId && p.isTutor.value.valueOf()) &&
                        props.parentTrainingPlanId > 0 &&
                        res.documementsAtTPlanlevel.valueOf() === false) {
                        loadTPlanSchedules(schId);
                    }
                } else {
                    setIsLoaded(true);
                    setErrorMessage('FileSharing:Error_LoadingFileSharing');
                }
            });
    }

    useEffect(() => {
        const link: HTMLElement = document.getElementById('fs-download') as HTMLAnchorElement;
        if (link) {
            link.click();
            setTempDownloadUrl(null);
        }
    }, [tempDownloadUrl])

    useEffect(() => {
        loadFileSharing();
    }, [])


    /**
     * DELETE FUNCTIONS
     */


    function getShowDeleteButton() {
        return filteredFileSharingLesson.users.some(user => user.documents.some(document => document.isCbxClicked === true));
    }

    /**
     * Removes an document from the list
     * This is used the rerender the sub components for calculation purposes
     * @param document FileSharingDocument entry
     */
    function removeDocument(document: FileSharingDocument) {
        if (filteredFileSharingLesson === null) {
            return;
        }

        const stateFileSharingUsers: FileSharingUser[] = [...filteredFileSharingLesson.users] || [];
        stateFileSharingUsers.forEach((fileSharingUser) => {
            if (fileSharingUser.userId === document.userId) {
                fileSharingUser.documents = fileSharingUser.documents.filter((filter) => {
                    return filter.id !== document.id;
                });
            }
        });
        const stateLesson = { ...fileSharingLesson };
        stateLesson.users = stateFileSharingUsers;

        setFileSharingLesson(stateLesson);
        setFilteredFileSharingLesson(stateLesson);
    }

    function deleteAllMarkedDocuments() {
        setShowModal(false)
        filteredFileSharingLesson.users.map(fileSharingUsers => {
            fileSharingUsers.documents.map(async document => {
                if (document.isCbxClicked) {
                    const response: BooleanResponse | GtError = await FileSharingService.instance.deleteDocument(
                        document.id.valueOf(),
                        document.itemId.valueOf(),
                        document.assignmentId,
                        document.tPlanId,
                        document.scheduleId);

                    if (!isSuccess<BooleanResponse>(response)) {
                        setErrorMessage('FileSharing:Error_DeleteFile');
                        return;
                    }
                    removeDocument(document);
                    setShowDeleteButton(getShowDeleteButton());
                }
            })
        });
    }

    function documentClickedDelete(document: FileSharingDocument) {
        if (document === null) {
            return;
        }

        filteredFileSharingLesson.users.map(fileSharingUser => {
            if (fileSharingUser.userId == document.userId) {
                fileSharingUser.documents.map(documentTest => {
                    if (documentTest.id == document.id) {
                        documentTest.isCbxClicked = document.isCbxClicked;
                    }
                })
            }
        });

        setShowDeleteButton(getShowDeleteButton());
    }

    /**
     * DOWNLOAD FUNTIONS
     */
    
    /**
     * Invisible html anchor that will be populated with the download token for a file download after clicking the download link
     * Since the download token will be generated for each download request, we need this workaround
     */
    function renderTempDownloadAnchor() {
        if (!tempDownloadUrl) {
            return null;
        }

        return (
            <a id="fs-download" href={tempDownloadUrl} style={{ 'display': 'none' }} target="_blank" rel="noopener noreferrer">Download</a>
        );
    }
    
    /**
     * Returns the ids of all documents the user is allowed to download
     * @returns Array of FileSharingDocument. Array is empty if the user can't download any files
     */
    function getDownloadAllFiles(): number[] {
        const res: number[] = [];
        filteredFileSharingLesson.users.forEach(user => {
            user.documents.forEach(document => {
                if (document.allowDownload) {
                    res.push(document.id.valueOf());
                }
            });
        });
        return res;
    }

    async function downloadAll() {
        const token = await FileSharingService.instance.getDownloadToken(
            getDownloadAllFiles(),
            props.lesson.itemId,
            props.parentAssignmentId,
            props.parentTrainingPlanId,
            props.parentScheduleId || null);

        if (isSuccess<StringResponse>(token)) {
            const url = `${FileSharingService.instance.getUrl()}filesharing/download/${token.value}`;
            setTempDownloadUrl(url);
            /* this.setState({ tempDownloadUrl: url }, () => {
                const link: HTMLElement = document.getElementById('fs-download') as HTMLAnchorElement;
                if (link) {
                    link.click();
                    this.setState({ tempDownloadUrl: null });
                }
            }); */
        } else {
            setErrorMessage('FileSharing:Error_DownloadAll');
        }
    }

    function onSearchChange(searchText: string) {
        if (searchText.length > 0) { // in this caes don't allow to change the order per drag & drop
            setFileSharingModelList(fileSharingSortingModeList.filter(s => s.id !== EFileSharingSortEnum.Manually));
            setSearchText(searchText);
            setSelectedSortingMode(fileSharingSortingModeList.filter(s => s.id === EFileSharingSortEnum.Ascending)[0]);
        } else {
            if (fileSharingSortingModeList.filter(s => s.id === EFileSharingSortEnum.Manually).length === 0) {
                fileSharingSortingModeList.push({ id: EFileSharingSortEnum.Manually, text: Session.instance.storage.translation.GetString('FileSharing:SortManually') });
            }
            setSearchText(searchText);
            setSelectedSortingMode(fileSharingSortingModeList.filter(s => s.id === EFileSharingSortEnum.Manually)[0]);
        }
    }

    function renderScheduleSelect() {
        if (!schedules || schedules.length <= 0) {
            return null;
        }

        const label: string = Session.instance.storage.translation.GetString('FileSharing:SelectSchedulePlaceholder');

        return (
            <div className="item-detail__file-sharing__user-panel">
                <Heading headingLevel={4} cssClass="heading__level4">
                    <Translate>FileSharing:SelectSchedule</Translate>
                </Heading>
                <DropDownList
                    data={schedules}
                    label={label}
                    textField="text"
                    dataItemKey="id"
                    value={selectedSchedule}
                    onChange={(e) => loadFileSharing(e.target.value.id)} />
            </div>
        );
    }

    /**
     * RENDER FUNCTIONS
     */

    function renderDownloadAll() {
        if (getDownloadAllFiles().length <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                <div>
                    <button className="link-button" onClick={() => downloadAll()}>
                        <InlineSVG src={IconDownloadAll} />&nbsp;<Translate>FileSharing:DownloadAllOfEverybody</Translate>
                    </button>
                </div>
            </React.Fragment>
        );
    }

    function renderDeleteConfirmationModal() {
        if (!showModal) {
            return null;
        }
        return (
            <ModalPopup isOpen={true} onRequestClose={() => setShowModal(false)}>
                <div className="modal-delete-conformation">
                    <p>
                        <Translate>FileSharing:DeleteConfirmation</Translate>
                    </p>
                    <div className="modal__spread-buttons">
                        <GTButton onClick={() => deleteAllMarkedDocuments()}
                            ariaLabel={Session.instance.storage.translation.GetString('FileSharing:DeleteOk')}>
                            <Translate>FileSharing:DeleteOk</Translate>
                        </GTButton>
                        <GTButton onClick={() => setShowModal(false)}
                            ariaLabel={Session.instance.storage.translation.GetString('FileSharing:CancelDelete')}
                            isPrimary={false}>
                            <Translate>FileSharing:CancelDelete</Translate>
                        </GTButton>
                    </div>
                    <ErrorMessage errorMessage={errorMessage} />
                </div>
            </ModalPopup>
        );
    }

    function renderDeleteAll() {
        if (!showDeleteButton) {
            return null;
        }
        return (
            <React.Fragment>
                <div>
                    <button className="link-button" onClick={() => setShowModal(true)}>
                        <InlineSVG src={Icondelete} />&nbsp;<Translate>FileSharing:DeleteAllDocuments</Translate>
                    </button>
                </div>
            </React.Fragment>
        );
    }

    function renderFileSharingUser(fileSharingUser: FileSharingUser) {
        return (
            <React.Fragment key={fileSharingUser.userId.valueOf()}>
                <FileSharingUserPanel
                    lesson={props?.lesson}
                    fileSharing={filteredFileSharingLesson}
                    fileSharingUser={fileSharingUser}
                    availableFileSharingTags={fileSharingLesson.availableFileSharingTags}
                    onCbxDeleteIsClicked={documentClickedDelete}
                    onReload={() => loadFileSharing()}
                    searchText={searchText}
                    sortingMode={selectedSortingMode.id} />
            </React.Fragment>
        );
    }

    if (!isLoaded || fileSharingLesson === null) {
        return <ProgressSpinner />
    }

    return (
        <div className="l-element--striped item-detail__file-sharing">
            {currentUserIsTutor &&
                <div className="l-container">
                    <Heading headingLevel={props.headingLevel || 2} cssClass={'heading__Level' + (props.headingLevel || 2)}>
                        <Translate>FileSharing:TagHandlingTitle</Translate>
                    </Heading>
                    <FileSharingTagManagement
                        fileSharingLesson={fileSharingLesson}
                        itemId={props.lesson.itemId}
                        assignmentId={props.parentAssignmentId}
                        tPlanId={props.parentTrainingPlanId}
                        scheduleId={selectedSchedule ? selectedSchedule.id : 0}
                        onCreateTag={() => loadFileSharing()}
                    />
                </div>
            }
            <div className="l-container">
                <Heading headingLevel={props.headingLevel || 2} cssClass={'heading__Level' + (props.headingLevel || 2)}>
                    <Translate>FileSharing:Title</Translate>
                </Heading>
                <ErrorMessage errorMessage={errorMessage} />
                <div className="item-detail__file-sharing__user-panel item-detail__file-sharing__filter-panel">
                    {fileSharingLesson.availableFileSharingTags.length > 0 &&
                        <div className="item-detail__file-sharing__filter-panel_tag-search">
                            <AutoComplete
                                data={fileSharingLesson.availableFileSharingTags}
                                label={Session.instance.storage.translation.GetString('FileSharing:FilterPlaceholder')}
                                suggest={false}
                                textField="tagName"
                                onClose={(e) => onSearchChange(e.target.value)}
                            />
                        </div>
                    }

                    <div className="item-detail__file-sharing__filter-panel_sorting">
                        <DropDownList
                            data={fileSharingSortingModeList.sort((a, b): number => {
                                return SortingAlgorithm.sortByColumnAndDirection(a, b, 'id', ESortDirection.Up);
                            })}
                            label={Session.instance.storage.translation.GetString('FileSharing:Sorting')}
                            textField="text"
                            dataItemKey="id"
                            value={selectedSortingMode}
                            onChange={(e) => setSelectedSortingMode(e.target.value)}
                            className="item-detail__file-sharing__filter-panel_sorting" />
                    </div>
                </div>
                {renderScheduleSelect()}
                {
                    filteredFileSharingLesson.users.map(fileSharingUser => {
                        return renderFileSharingUser(fileSharingUser);
                    })
                }
                {renderDownloadAll()}
                {renderDeleteAll()}
                {renderDeleteConfirmationModal()}
                {renderTempDownloadAnchor()}
            </div>
        </div>
    );
}