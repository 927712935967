
import { BooleanResponse } from '$src/storage/models/BooleanResponse';
import { EFavoriteType } from '$src/storage/models/enums';
import { Favorite } from '$src/storage/models/Favorite';
import { AddFavoriteRequest } from '$src/storage/models/RequestObjects/AddFavoriteRequest';
import { RemoveFavoriteRequest } from '$src/storage/models/RequestObjects/RemoveFavoriteRequest';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';


/**
 * SearchService provides all Service Methods for the Search Functionality
 */
export default class FavoriteService extends ServiceClient {
    protected static _instance: FavoriteService | null = null;

    protected className = 'FavoriteService';
    protected loggerLocality = 'FavoriteService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): FavoriteService {
        return this._instance || (this._instance = new this());
    }


    /**
     * loads the favorites of the current user in current language from database
     * This method is allowed to be used in FavoriteStorage.ts only
     * @param language 
     */
    public async getFavorites(language: string): Promise<Favorite[] | GtError> {
        let response: Favorite[] | GtError;
        response = await this.get<Favorite[]>('favorites/getFavoritesByUser', Favorite, undefined, [{ name: 'language', value: language }]);
        return response;
    }

    /**
     * adds the selected item to the favorites
     * This method is allowed to be used in FavoriteStorage.ts only
     * @param objectId   
     * @param type 
     * @param language 
     */
    public async addFavorite(objectId: number, type: EFavoriteType, language: string): Promise<Favorite | GtError> {
        let response: Favorite | GtError;
        const obj = new AddFavoriteRequest();
        obj.objectId = objectId;
        obj.type = type;
        obj.language = language;
        response = await this.post<Favorite>('favorites/addFavorite', obj, Favorite);
        return response;
    }

    /**
     * Removes the selected item from the favorites
     * This method is allowed to be used in FavoriteStorage.ts only
     * @param id 
     */
    public async removeFavorite(id: number): Promise<BooleanResponse | GtError> {

        let response: BooleanResponse | GtError;
        const obj = new RemoveFavoriteRequest();
        obj.id = id;

        response = await this.post<BooleanResponse>('favorites/removeFavorite', obj, BooleanResponse);
        return response;
    }

    /**
     * Gets the favorite status of the selected item
     * This method is allowed to be used in FavoriteStorage.ts only
     * @param language 
     * @param objectId 
     * @param type 
     */
    public async getFavoriteStatus(language: string, objectId: number, type: EFavoriteType): Promise<Favorite | null | GtError> {
        let response: Favorite | GtError | null;
        response = await this.get<Favorite>('favorites/getFavoriteStatus',
            Favorite,
            undefined,
            [{ name: 'language', value: language }],
            objectId.toString(),
            type.toString());
        return response;
    }
} 