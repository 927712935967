import React from 'react';

import { Heading } from '$components/shared/Heading';
import { Attribute } from '$storage/models/Attribute';
import { CatalogElement } from '$storage/models/Catalog';
import { NavLink } from 'react-router-dom';

interface IState {
    backgroundImage: string;
}

interface IProps {
    catalogFolder: CatalogElement;
    parentHeadingLevel: number;
}

// tslint:disable:object-literal-sort-keys
export class CatalogFolderListItem extends React.Component<IProps, IState> {
    protected className = 'CatalogFolderListItem';
    protected loggerLocality = 'Components.CatalogFolderListItem';

    constructor(props: IProps) {
        super(props);
        this.state = { backgroundImage: '' }
    }

    public componentDidMount() {

        const itemImageAttr: Attribute | undefined = this.props.catalogFolder.attributes.find(f => f.name === 'ItemImage');

        if (itemImageAttr !== undefined) {
            this.setState({ backgroundImage: 'url(' + itemImageAttr.getMediaStreamUrl() + ')'});
        }
    }

    public render() {
        return (
            <NavLink
                to={`/catalog/${this.props.catalogFolder.itemId}`}
                style={{ backgroundImage: this.state.backgroundImage }}>
                <div className="title title--with-background">
                    <Heading headingLevel={this.props.parentHeadingLevel+1} cssClass="heading__Level2 heading--secondary">
                        {this.props.catalogFolder.title}
                    </Heading>                    
                </div>
            </NavLink>
        );
    }
}