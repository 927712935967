import React from 'react';
import { useHistory } from 'react-router';

import GTButton, { EButtonSize } from '$components/shared/Atoms/GTButton';
import { ModalPopup } from '$components/shared/ModalPopup';
import { Translate } from '$components/shared/Translate';
import { useState } from 'react';
import { SsoUserCreation } from '../login/BackgroundSsoUserCreation';
import { SsoSuiConfigurationIdentityProvider } from '$src/storage/models/SsoSuiConfigurationIdentityProvider';

/**
 * @export
 * @interface ISsoSelfregistrationModalProps
 */
export interface ISsoSelfregistrationModalProps {
    onCloseClicked(): void;
    onLoginClicked(): void;
    provider: SsoSuiConfigurationIdentityProvider;
}

/**
 * Modal popup shown when user authenticated via sso but no corresponding GT user could be found
 * @param _props children, onCloseClicked, onLoginClicked
 */
export const SsoSelfregistrationModal: React.FC<ISsoSelfregistrationModalProps> = (_props: ISsoSelfregistrationModalProps) => {
    const history = useHistory();
    const [enableBackgroundCreateUser, setEnableBackgroundCreateUser] = useState(false);
    const [hideButtons, setHideButtons] = useState(false);

    function onCloseClicked() {
        _props.onCloseClicked();
    }

    return (
        <>
            <ModalPopup containerCssClass="ssoSelfregistrationModal" isOpen={true} onRequestClose={onCloseClicked}>
                <div>
                    <Translate>Login:SsoUserModal</Translate>
                </div>

                {
                    enableBackgroundCreateUser &&
                    <SsoUserCreation provider={_props.provider} onLoading={() => setHideButtons(true)} onFailed={() => setHideButtons(true)} />
                }
                {
                    !hideButtons && <div className="modal__spread-buttons ssoSelfregistrationModal__buttons">
                        {!globalConfig.loginProperties.disableDefaultLoginSso &&
                            <GTButton onClick={() => _props.onLoginClicked()} buttonSize={EButtonSize.small} isPrimary={false}>
                                <Translate>Login:SsoUserModal_Login</Translate>
                            </GTButton>
                        }
                        {
                            globalConfig.loginProperties.isSelfRegistrationEnabledSso &&
                            <GTButton onClick={() => history.push('/selfRegistration')} buttonSize={EButtonSize.small} isPrimary={false}>
                                <Translate>Login:SsoUserModal_Register</Translate>
                            </GTButton>
                        }
                        {
                            _props.provider.isBackgroundUserCreationEnabled &&
                            <GTButton onClick={() => setEnableBackgroundCreateUser(true)} buttonSize={EButtonSize.small} isPrimary={false}>
                                <Translate>Login:SsoUserModal_BackgroundCreateUser</Translate>
                            </GTButton>
                        }
                    </div>
                }
            </ModalPopup>
        </>
    )
}