import { ItemInputSkill } from '$src/storage/models/ItemDetails/ItemInputSkill';
import { ItemOutputSkill } from '$src/storage/models/ItemDetails/ItemOutputSkill';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class ItemSkills {
    @JsonProperty('inputSkills', [ItemInputSkill], false)
    public userInputSkills: ItemInputSkill[] | undefined = undefined;

    @JsonProperty('outputSkills', [ItemOutputSkill], false)
    public userOutputSkills: ItemOutputSkill[] | undefined = undefined;

}