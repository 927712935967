import IcontodoActive from '$resources/svgs/todo/todo-active.svg'
import IcontodoDone from '$resources/svgs/todo/todo-done.svg'

import { Heading } from '$src/components/shared/Heading';
import Session from '$src/core/Session';
import { EWorkflowActivityStatus } from '$src/storage/models/enums';
import { WorkflowActivity } from '$src/storage/models/Workflow/WorkflowActivity';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { Tooltip } from '$components/shared/Tooltip';
import { StringHelper } from '$src/util/StringHelper';

interface IProps {
    workflowActivity: WorkflowActivity;
    collapseIsOpen: boolean;
    headingLevel: number;
}


export class TodoItemHeader extends React.Component<IProps> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const item = this.props.workflowActivity;
        const icon = item.status === EWorkflowActivityStatus.Completed ?
            IcontodoDone : IcontodoActive;
        const title = icon === IcontodoActive ?
            Session.instance.storage.translation.GetString('Todo:StateActive') :
            Session.instance.storage.translation.GetString('Todo:StateDone');

        return (
            <div className="todo-stretch-right l-container--flex" >
                <Heading cssClass="heading__Level4 todo-header-title" headingLevel={this.props.headingLevel}>
                    {item.userName}, {item.itemDescription}
                </Heading>
                {this.props.workflowActivity.completedDate != '' ?
                    <div className='todo-completed-due-date'>
                        {Session.instance.storage.translation.GetString('Todo:CompletedDate').Format(StringHelper.dateTimeIsoString(this.props.workflowActivity.completedDate))}
                    </div>
                    : this.props.workflowActivity.dueDate != '' &&
                    <div className='todo-completed-due-date'>
                        {Session.instance.storage.translation.GetString('Todo:DueDate').Format(StringHelper.dateTimeIsoString(this.props.workflowActivity.dueDate))}
                    </div>
                }
                <div className="todo-flexend">
                    <div aria-label={title}
                        data-tip={title}
                        data-for={`${item.id}_todoTooltip`}>
                        <InlineSVG src={icon} />
                        <Tooltip id={`${item.id}_todoTooltip`} place="bottom" />
                    </div>
                </div>
            </div>
        )
    }
}
