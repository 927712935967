import Logger from '$src/core/Logger';
import F2FService from '$src/core/Services/F2FService';
import Session from '$src/core/Session';
import SessionCache from '$src/storage/cache/SessionCache';
import { F2FClassDetail } from '$src/storage/models/F2F/F2FClassDetail';
import { isSuccess } from '$src/util/Result';
import { EItemDetailCallerContextType } from '$src/storage/models/enums';

const CACHING_DURATION = 300;

export default class F2FClassDetailStorage extends SessionCache<number, F2FClassDetail> {
    protected className = 'F2FClassDetailStorage';
    protected loggerLocality = 'Storage.F2FClassDetailStorage';

    constructor(cachingDuration?: number) {
        super(cachingDuration !== undefined ? cachingDuration : CACHING_DURATION, true, true);
    }

    public async getF2FClassDetails(classId: number, itemContext: EItemDetailCallerContextType, language?: string, parentTrainingPlanId = 0): Promise<F2FClassDetail | null> {
        const methodName = `${this.className}:getF2FClassDetails()`;

        if (language === null || language === undefined) {
            language = Session.instance.getUserLanguageCodeOrFallBack;
        }

        if(this.isObjectInCache(classId)) {
            Logger.log(this.loggerLocality, `${methodName} getting ClassDetails from cache, classId=${classId}.`);
        } else {
            Logger.log(this.loggerLocality, `${methodName} getting ClassDetails from server, itemId=${classId}.`);
            const response = await F2FService.instance.getF2FClassDetails(classId, language, itemContext, parentTrainingPlanId);
            if(isSuccess<F2FClassDetail>(response))
            {
                this.saveObjectToCache(classId, response);
            } else {
                Logger.log(this.loggerLocality, `${methodName} failed to get ClassDetails from server, itemId=${classId}`);
            }
        }
        return this.getObjectFromCache(classId);
    }

    public clear(): void {
        super.clear();
    }

    public isCacheExpired(): boolean {
        return false;
    }
}