import { DatePicker } from '$components/shared/DatePicker';
import { Translate } from '$src/components/shared/Translate';
import Session from '$src/core/Session';
import { ERelativeTimeSettingMode, ERelativeTimeSettingsDayMode } from '$src/storage/models/enums';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import Moment from 'moment';
import React from 'react';

interface IProps{
    id: string;
    title: string;
    isDisabled: boolean;
    setNewDateSettings: 
        (   id: string, 
            selectedTimeSettingMode: ERelativeTimeSettingMode, 
            selectedDayMode: ERelativeTimeSettingsDayMode, 
            ticks: number | null, 
            days: number
        ) => void;
    showOnlyAbsoluteDate: boolean;
    selectedTimeSettingMode: ERelativeTimeSettingMode;
    assignmentDate: string;
    initialDate: Date;
}

interface IState {
    selectedDayMode: ERelativeTimeSettingsDayMode;
    ticks: number;
    days: number;
    minDate: Date;
}

export class TimeSettingFormElement extends React.Component<IProps, IState>{
    private TRANSLATION_PREFIX = 'AssignLessonsModal';
    private timeSettingsDayModes: string[] = [
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:DaysLater`),
        Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:WorkingDaysLater`)];


    constructor(props: IProps) {
        super(props);

        this.state = {
            days: 0,
            minDate: new Date(),
            selectedDayMode: ERelativeTimeSettingsDayMode.DaysLater,
            ticks: Date.now(),
        }
    }

    public render(){
        return (
            this.props.showOnlyAbsoluteDate ?  this.renderOnlyAbsoluteDate() : this.renderAll() 
        );
    }

    public componentDidMount(){
        if(this.props.showOnlyAbsoluteDate){
            const ticks = new Date().setHours(0);

            this.setState({ ticks }, 
                () => this.props.setNewDateSettings(this.props.id, ERelativeTimeSettingMode.Absolute, ERelativeTimeSettingsDayMode.DaysLater, ticks, this.state.days));
        }
    }

    private renderOnlyAbsoluteDate = (): JSX.Element => {        
        if(this.props.selectedTimeSettingMode === ERelativeTimeSettingMode.Absolute){
            return  <div className="k-form-field">
                        <legend><Translate>{this.props.title}</Translate></legend>
                        <div className="spacing">
                            <DatePicker
                                value={this.props.initialDate}
                                onChange={(e) => this.datePickerChangeEvent(e)}
                                minDate={this.state.minDate}
                            />
                        </div>
                    </div>
        }else{
            return  <React.Fragment>
                        <legend><Translate>{this.props.title}</Translate>: { this.props.assignmentDate }</legend>
                    </React.Fragment>
        }
        
    }

    private renderAll = (): JSX.Element => {
        const absolute = ERelativeTimeSettingMode.Absolute;
        const relative = ERelativeTimeSettingMode.Relative;
        return  <div className="k-form-field">
                    <legend><Translate>{this.props.title}</Translate></legend>
                    { 
                        this.props.selectedTimeSettingMode === absolute ? 
                        <DatePicker
                            value={this.props.initialDate}
                            onChange={(e) => this.datePickerChangeEvent(e)}
                            minDate={this.state.minDate}
                        /> 
                        : null 
                    }
                    { 
                        this.props.selectedTimeSettingMode === relative ? 
                        this.renderFormForRelativeDateSetting() : null 
                    }
                </div>
    }

    private renderFormForRelativeDateSetting = (): JSX.Element => {
        const normalDayMode = this.timeSettingsDayModes[0];
        return(
            <div className="k-form-field">
                <div className="spacing">
                    <NumericTextBox format="n" defaultValue={0} min={0} 
                        disabled={this.props.isDisabled} 
                        onChange={(e) => this.amountOfDaysChangeEvent(e)}
                        label={this.getTranslation('Days')} />
                    <DropDownList data={this.timeSettingsDayModes} 
                        onChange={(e) => this.dayModeChangeEvent(e)} 
                        defaultValue={normalDayMode} disabled={this.props.isDisabled}
                        label={this.getTranslation('TimeSettingsDayModes')} />
                </div>
            </div>
        );
    }

    private datePickerChangeEvent(targetValue: Date | null){
        if(targetValue && Moment(targetValue).isValid()){ 
            let ticks = 0;
            if(this.props.id === 'start'){
                ticks = new Date(targetValue.getTime()).setHours(0,0,0,0);
                this.setState(
                    { ticks },
                    () => this.props.setNewDateSettings(this.props.id, this.props.selectedTimeSettingMode, this.state.selectedDayMode, ticks, this.state.days));
            }else{ 
                // if End or Target Date, the Time must be set to 23:59Uhr
                ticks = new Date(targetValue.getTime()).setHours(23,59,0,0);
                this.setState(
                    { ticks },
                    () => this.props.setNewDateSettings(this.props.id, this.props.selectedTimeSettingMode, this.state.selectedDayMode, ticks, this.state.days));
            }
        }
        else if (targetValue == null) {
            this.setState(
                { ticks: 0 },
                () => this.props.setNewDateSettings(this.props.id, this.props.selectedTimeSettingMode, this.state.selectedDayMode, 0, this.state.days));
        }
    }

    private amountOfDaysChangeEvent = (event: NumericTextBoxChangeEvent) =>{
        const { selectedDayMode, ticks} = this.state;
        const days = event.target.value && event.target.value > -1 ? event.target.value : 0;
        this.setState(
            { days }, 
            () => this.props.setNewDateSettings(this.props.id, this.props.selectedTimeSettingMode, selectedDayMode, ticks, days));
    }

    private dayModeChangeEvent(event: DropDownListChangeEvent){
        const { ticks, days} = this.state;
        const stringModeIndex = this.timeSettingsDayModes.indexOf(event.target.value);
        let selectedDayMode = ERelativeTimeSettingsDayMode.DaysLater;
        if(stringModeIndex === 0){  
            selectedDayMode = ERelativeTimeSettingsDayMode.DaysLater;
        }
        if(stringModeIndex === 1){  
            selectedDayMode = ERelativeTimeSettingsDayMode.WorkingDaysLater;
        }

        this.setState(
            { selectedDayMode },
            () => this.props.setNewDateSettings(this.props.id, this.props.selectedTimeSettingMode, selectedDayMode, ticks, days));
    }

    private getTranslation = (str: string): string => {
        return Session.instance.storage.translation.GetString(`${this.TRANSLATION_PREFIX}:${str}`);
    }

}


export default TimeSettingFormElement;