import GTButton, { EButtonSize, IGTButtonProps } from '$src/components/shared/Atoms/GTButton';
import SearchService from '$src/core/Services/SearchService';
import Session from '$src/core/Session';
import { SearchFilter } from '$src/storage/models/search/elk/SearchFilter';
import { SearchResponse } from '$src/storage/models/search/elk/SearchResponse';
import { SearchResult } from '$src/storage/models/search/elk/SearchResult';
import { isSuccess } from '$src/util/Result';
import { ComboBox, ComboBoxChangeEvent, ComboBoxCloseEvent, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import InlineSVG from 'react-inlinesvg';
import searchIcon from '$resources/svgs/navi/search.svg';
import GTIconButton, { GTIconButtonProps } from '$src/components/shared/Atoms/GTIconButton';
import IconClose from '$resources/svgs/misc/close.svg';
interface IProps {
    onSearch?: (
        searchText: string,
    ) => void;
    onClose?: (e: React.FormEvent<HTMLButtonElement>) => void;
    classPrefix: "search-panel" | "quicksearch" | "quicksearch-homepanel";
    className?: string;
    searchValue: string;
}

export default function SearchPanel(props: IProps) {
    const [searchValue, setSearchValue] = useState<string>(props.searchValue);
    const [filterValue, setFilterValue] = useState<string>("");
    const [filterResults, setFilterResults] = useState<string[]>();
    const [filterLoading, setFilterLoading] = useState<boolean>(false);

    let timeout: NodeJS.Timeout;

    async function getFilterResults(filterInputValue: string) {
        let response = new Array<SearchResult>();

        setFilterLoading(true);
        await SearchService.instance.getSearchItemsv2(Session.instance.getUserLanguageCodeOrFallBack, filterInputValue, new SearchFilter(), "").then((result) => {
            if (isSuccess<SearchResponse>(result)) {
                response = result.searchResults;
                setFilterLoading(false);
            } else {
                response = [];
                setFilterLoading(false);
            }
        });

        setFilterLoading(false);
        return response;
    }

    /** This effect filters the results in the combobox */
    useEffect(() => {
        const searchResults = new Array<string>(filterValue);
        setFilterResults(searchResults);
    }, [setFilterValue]);

    /** SetFilterValue with SearchValue only if the SearchValue has changed (i.e. if props.SearchValue has changed), 
     * because the filterValue changes also in the onChange event of the combobox if the user selects another value, 
     * in this case filterValue must not be overwritten with the SearchValue
     */
    useEffect(() => {
        if(searchValue != filterValue) {
            setFilterValue(searchValue);
        }
    }, [searchValue])

    /** Save the props.searchValue in searchValue. 
     * The props.SearchValue changes when the user clicks a keyword from SearchByKeyword. 
     * This effect must be executed every time but checks if searchValue does not match the props anymore. 
     * It is not stored in filterValue because otherwise the onChange event in the combobox does not work correctly anymore.
    */
    useEffect(() => {
        if(props.searchValue && props.searchValue != searchValue) {
            setSearchValue(props.searchValue);
        } 
    });

    function onFilterChange(event: ComboBoxFilterChangeEvent) {
        const filterInputValue = event.filter.value;
        global.clearTimeout(timeout);
        if (filterInputValue && (filterInputValue.length > 2 || filterInputValue == "*")) {
            timeout = global.setTimeout(() => {
                getFilterResults(filterInputValue.trim()).then(r => {
                    if (r != null) {
                        const titles = r.map(r => r.title);
                        if (titles != null) {
                            const uniqueTitles = Array.from(new Set(titles));
                            setFilterResults(uniqueTitles);
                        }
                    }
                }
                )
            }, 300);
        }
    }

    function onChange(event: ComboBoxChangeEvent) {
        const value = event.target.value;
        if (value) {
            setFilterValue(value);
        }
        else {
            setFilterValue("");
        }
    }

    /** The onClose event is used instead of the onKeyDown event on the div */
    function onClose(event: ComboBoxCloseEvent) {
        const value = event.target.value;
        if (value) {
            if (props.onSearch) {
                props.onSearch(value);
            }
        }
    }

    const searchTitle = Session.instance.storage.translation.GetString('Search:Title');
    const searchClose = Session.instance.storage.translation.GetString('Search:Title');

    const iconButtonAttributes: GTIconButtonProps = {
        id: "quickSearchButton",
        ariaLabel: searchTitle,
        tooltipText: searchTitle,
        onClick: () => props.onSearch ? props.onSearch(filterValue) : '',
        children: <InlineSVG src={searchIcon} />,
        additionalClassNames: `${props.classPrefix}__button`
    }

    const iconButtonCloseAttributes: GTIconButtonProps = {
        id: "quickSearchCloseButton",
        ariaLabel: searchClose,
        tooltipText: searchClose,
        onClick: (e: React.FormEvent<HTMLButtonElement>) => { props.onClose && props.onClose(e) },
        children: <InlineSVG src={IconClose} />,
        additionalClassNames: `${props.classPrefix}__button`
    }

    const searchButtonAttributes: IGTButtonProps = {
        buttonSize: EButtonSize.small,
        onClick: () => props.onSearch ? props.onSearch(filterValue) : '',
        children: Session.instance.storage.translation.GetString('Search:Search'),
        additionalClassNames: `${props.classPrefix}__button`
    };

    return (
        <div className={`${props.classPrefix}__container ${props.className != null ? ' ' + props.className : ''}`}>
            <ComboBox
                id={"quickSearchInput"}
                aria-label={Session.instance.storage.translation.GetString('Search:SearchFieldLabel')}
                placeholder={Session.instance.storage.translation.GetString('Search:TextboxPlaceholder')}
                className={`${props.classPrefix}__input`}
                label={Session.instance.storage.translation.GetString('Search:SearchFieldLabel')}
                data={filterResults}
                value={filterValue}
                loading={filterLoading}
                filterable={true}
                allowCustom={true}
                suggest={false}
                onFilterChange={(e) => onFilterChange(e)}
                onChange={(e) => onChange(e)}
                onClose={(e) => onClose(e)}
            />
            {
                props.classPrefix.toString().startsWith("quicksearch") ?
                    <>
                        <GTIconButton {...iconButtonAttributes} />
                        {props.onClose && <div onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                e.stopPropagation();
                            }
                        }}><GTIconButton {...iconButtonCloseAttributes} /></div>}
                    </> :
                    <GTButton {...searchButtonAttributes} />}
        </div>
    );
}