import React, { useState, useCallback, useEffect } from 'react';
import { ListItemProps, ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import InlineSVG from 'react-inlinesvg';

import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';

import { PaymentUserSearchFilter } from '$src/storage/models/Payment/PaymentUserSearchFilter';
import PaymentAdminService from '$src/core/Services/PaymentAdminService';
import { PaymentUserSearchResponse } from '$src/storage/models/Payment/PaymentUserSearchResponse';
import { isSuccess } from '$src/util/Result';
import Iconuploaded from '$resources/svgs/filesharing/success.svg';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';

interface IUserSearchProps {
    /** For updating user selection outside, f.e. after validation */
    selectedUser: PaymentUserSearchResponse[];
    /** Selected user changed, returns complete selected list */
    onUserSelected?: (selectedUser: PaymentUserSearchResponse[]) => void;
    /** For the MyTeam Boss View, restricts the user selection to the employees with the given relation of the current boss */
    bossRelationCode?: string;
}

const MININPUTLENGH = 3;

/**
 * Component for payment admins to search users to book
 * @param props IUserSearchProps
 * @returns Hook - complete component
 */
export default function UserSearch(props: IUserSearchProps) {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState<PaymentUserSearchFilter>({ userSearchText: '' });
    const [searchResults, setSearchResults] = useState<PaymentUserSearchResponse[]>([]);
    const [selectedResult, setSelectedResult] = useState<PaymentUserSearchResponse[]>(props.selectedUser);
    const [restrictCompany, setRestrictCompany] = useState<string | undefined>(undefined);

    /** Update restrictCompany after changes and on first load */
    useEffect(() => {
        if (ShoppingBasketStorage.instance.shoppingBasketContent.shoppingBasketItems.length > 0 && ShoppingBasketStorage.instance.shoppingBasketContent.shoppingBasketItems[0].bookUsers && ShoppingBasketStorage.instance.shoppingBasketContent.shoppingBasketItems[0].bookUsers.length > 0) {
            setRestrictCompany(ShoppingBasketStorage.instance.shoppingBasketContent.shoppingBasketItems[0].bookUsers[0].company);
        } else if (selectedResult && selectedResult.length > 0) {
            setRestrictCompany(selectedResult[0].company);
        } else {
            setRestrictCompany(undefined);
        }
    }, [selectedResult]);

    /** Update selected user from outside */
    useEffect(() => {
        setSelectedResult(props.selectedUser);
    }, [props.selectedUser]);

    /** When search text is entered */
    const onFilterChange = useCallback(async (event: ComboBoxFilterChangeEvent) => {
        const newFilter = { ...filter, userSearchText: event.filter.value };
        setFilter(newFilter);

        if (event.filter.value && event.filter.value.length >= MININPUTLENGH) {
            setLoading(true);
            const res = await PaymentAdminService.instance.getUserSearch(newFilter, props.bossRelationCode);
            if (isSuccess(res)) {
                setSearchResults(res as PaymentUserSearchResponse[]);
                setLoading(false);
            }
        } else if (!event.filter.value || event.filter.value.length < MININPUTLENGH) {
            setSearchResults([]);
        }
    }, []);

    /** first name last name and optional (company) */
    function renderName(entry: PaymentUserSearchResponse) {
        const company = entry.company && <>({entry.company})</>;
        return (
            <span>{entry.firstName} {entry.lastName} {company}</span>
        );
    }

    /** When one item of the search results is rendered to kendo multi select box */
    const userSearchComboBoxItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const entry = itemProps.dataItem as PaymentUserSearchResponse;
        const isSelected = (selectedResult || []).some(p => p.userId === entry.userId);

        let text: JSX.Element;
        if (isSelected) {
            text = (
                <span className='payment-booking__user-search__combobox-li selected'>
                    <InlineSVG src={Iconuploaded} style={{ outerWidth: 16 }} /> {renderName(entry)}
                </span>
            );
        } else {
            text = (
                <span className='payment-booking__user-search__combobox-li'>
                    {renderName(entry)}
                </span>
            );
        }

        return React.cloneElement(li, li.props, text);
    };

    /** When one item in search results is selected */
    const userSearchComboBoxChange = (e: ComboBoxChangeEvent) => {
        const changed = e.value as PaymentUserSearchResponse;
        if (!changed) {
            return; // empty selection fallback
        }

        const unselect = selectedResult && selectedResult.some(p => p.userId === changed.userId);

        let selected: PaymentUserSearchResponse[] = [];
        if (unselect && selectedResult) {
            selected = selectedResult.filter(p => p.userId !== changed.userId);
        } else {
            selected = [...selectedResult || [], changed];
        }

        setSelectedResult(selected);
        if (props.onUserSelected) {
            props.onUserSelected(selected);
        }
    }

    /** No data or minimum input length text */
    const renderNoData = (element: React.ReactElement<HTMLDivElement>) => {
        const length = filter.userSearchText?.length || 0;

        const noData = (
            <span>
                {length < MININPUTLENGH && <Translate>PaymentBooking:MinimumInputLengthValidator</Translate>}
                {length >= MININPUTLENGH && <Translate>PaymentBooking:NoData</Translate>}
            </span>
        );

        return React.cloneElement(element, { ...element.props }, noData);
    };

    /** only return search results with the same company that is already selected */
    function companyfilterSearchResults() {
        return searchResults.filter(p => restrictCompany === undefined || p.company === restrictCompany);
    }

    return (
        <>
            <div className="">
                <Heading headingLevel={2} cssClass="heading__Title">
                    <Translate>PaymentBooking:UserSearchTitle</Translate>
                </Heading>

                <div>
                    <Translate>PaymentBooking:UserSearchSubTitle</Translate>
                </div>

                <div role="table" className="div-table__vertical-table">
                    <div role="rowgroup">
                        {
                            restrictCompany &&
                            <div role="row" className="div-table__vertical-table-row">
                                <div role="columnheader">
                                    <Translate>PaymentBooking:UserSearchRestrictToCompany</Translate>
                                </div>
                                <div role="cell">
                                    <span className='user-search__selected-company'>{restrictCompany}</span>
                                </div>
                            </div>
                        }
                        <div role="row" className="div-table__vertical-table-row">
                            <div role="columnheader">
                                <Translate>PaymentBooking:UserSearchUser</Translate>
                            </div>
                            <div role="cell">
                                <ComboBox
                                    loading={loading}
                                    style={{ width: '100%' }}
                                    filterable={true}
                                    onFilterChange={onFilterChange}
                                    itemRender={userSearchComboBoxItemRender}
                                    opened={open}
                                    onFocus={() => setOpen(true)}
                                    onBlur={() => setOpen(false)}
                                    listNoDataRender={renderNoData}
                                    onChange={userSearchComboBoxChange}
                                    data={companyfilterSearchResults()}
                                    value={filter.userSearchText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}