import React, { useState } from 'react';

import { Heading } from '$components/shared/Heading';
import { Translate } from '$components/shared/Translate';
import GTButton from '../shared/Atoms/GTButton';
import TranslationService from '$src/core/Services/TranslationService';
import Session from '$src/core/Session';
import { ProgressSpinner } from '../shared/ProgressSpinner';

export const ConfigurationPage: React.FC = () => {
    const [inProgress, setInProgress] = useState<boolean>(false);

    const reloadStrings = async () => {
        setInProgress(true);
        await TranslationService.instance.cleanTranslationCache();
        await Session.instance.storage.translation.InitializeTranslationStore(Session.instance.getUserLanguageCodeOrFallBack);
        setInProgress(false);
    }

    return (
        <div className="l-container">
            <Heading headingLevel={1} cssClass="heading__Title">
                <Translate>Configuration:Title</Translate>
            </Heading>
            <Translate>Configuration:Description</Translate>
            <Heading headingLevel={2} cssClass="heading__Level2">
                <Translate>Configuration:ReloadStringsTitle</Translate>
            </Heading>
            <p><span id="ConfigurationReloadStringsDescription"><Translate>Configuration:ReloadStringsDescription</Translate></span></p>
            <GTButton
                onClick={async () => await reloadStrings()}
                
                defaultButtonProps={{ disabled: inProgress, "aria-labelledby": "ConfigurationReloadStringsDescription" }}
                >
                <>
                    <Translate>Configuration:ReloadStrings</Translate>
                    {inProgress && <ProgressSpinner isSmall={true} /> }
                </>
            </GTButton>
        </div>
    );
}
export default ConfigurationPage;