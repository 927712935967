
import { ItemRating } from '$src/storage/models/ItemDetails/ItemRating';
import { ItemRatingRequest } from '$src/storage/models/RequestObjects/ItemRatingRequest';
import GtError from '$src/util/GtError';
import ServiceClient from '../ServiceClient';
import { BooleanResponse } from './../../storage/models/BooleanResponse';
/**
 * Item Rating Service provides all Service Methods for the Rating Feature 
 */
export default class ItemRatingService extends ServiceClient {
    protected static _instance: ItemRatingService | null = null;

    protected className = 'RatingService';
    protected loggerLocality = 'RatingService';

    /**
     * Implement Singleton pattern.
     */
    public static get instance(): ItemRatingService {
        return this._instance || (this._instance = new this());
    }

    /**
     * Save new Item Rating
     * @param language 
     */
    public async saveItemRating(itemRatingRequest: ItemRatingRequest) {
        await this.post<void>('itemRatingInsert', itemRatingRequest);
    }

    /**
     * Delete Item Rating
     * @param language 
     */
    public async deleteItemRating(itemRatingId: number): Promise<BooleanResponse | GtError> {
        return await this.post<BooleanResponse>(`itemRatingDelete/${itemRatingId}`, {}, BooleanResponse);
    }

    /**
     * Save new Item Rating
     * @param language 
     */
    public async getItemRatings(itemId: number, parentTrainingPlanId: number): Promise<ItemRating[] | GtError> {
        const response = await this.get<ItemRating[]>(
            'itemRatingGet/allItemRatingsOfItem', 
        ItemRating, 
        undefined, 
        [
            { name: 'trainingplanId', value: parentTrainingPlanId.toString() }
        ], 
        itemId.toString());
        return response;
    }
}