import React from 'react';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import Session from '$src/core/Session';
import { SkillItem, SkillTableItem, SkillTableItemContainer } from '$src/components/Skills/SkillItem';
import { SkillModel } from '$src/components/Skills/SkillModel';
import { SortableHeaderElement } from '$src/components/shared/SortableHeaderElement';
import { ESortDirection, EItemDetailCallerContextType } from '$src/storage/models/enums';
import { Translate } from '$src/components/shared/Translate';
import SortingAlgorithm from '$src/util/SortingAlgorithm';
import { Alert } from '$src/components/shared/WarningsAndErrors/Alert';
import GTHorizontalTableRow, { GTRowContent } from '$src/components/shared/Atoms/GTHorizontalTableRow';
import GTHorizontalTable from '$src/components/shared/Molecules/GTHorizontalTable';
import GTHorizontalTableHeaderRow, { GTHeaderContent } from '$src/components/shared/Atoms/GTHorizontalTableHeaderRow';

interface IProps {
    bossRelationCode?: string;
    context: EItemDetailCallerContextType;
    skillsContainer: SkillTableItemContainer;
    isTargetSkill: boolean;
    userId: number;
    isProfile: boolean;
    onAssignPreselectedSkill?: (preselectedSkillId: number, preselectedLevelSetId: number | null, requiredSkillLevelId: number | null) => void;
}

interface ISort {
    id: string;
    direction: ESortDirection;
}

interface IState {
    activeHeaderId: string;
    sort: ISort;
}

/**
 * This component renders a List of skills as a table. The row required level can optional be enabled for target skills.
 */
export class SkillTable extends React.Component<IProps, IState> {
    protected skillModel: SkillModel = new SkillModel()
    protected openedItem: SkillItem | null;
    protected sortAlphabetical = globalConfig.targetProfiles.sortAlphabetical;

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeHeaderId: this.sortAlphabetical ? 'skillTitle' : 'sortOrder',
            sort: this.sortAlphabetical ? { id: 'skillTitle', direction: ESortDirection.Up } : { id: 'sortOrder', direction: ESortDirection.Up },
        }
    }

    public render() {
        this.sortSkills(this.state.sort.id, this.state.sort.direction);
        if (this.props.skillsContainer.skillTableItem.length > 0) {
            return (
                <GTHorizontalTable tableClassName="skill__table"
                    headerElement={this.renderTableHeader()}
                    rows={this.renderSkillsList(this.props.skillsContainer)}
                />);
        } else {
            return <NoDataFound message={Session.instance.storage.translation.GetString('Skill:NoData')} />
        }
    }

    private renderTableHeader() {
        const headerElements: GTHeaderContent[] = [];
        headerElements.push({
            cellId: 'skillTitle',
            cellElement: this.sortAlphabetical || !this.props.isProfile ? <SortableHeaderElement
                id="skillTitle"
                isActive={this.isActiveHeader('skillTitle')}
                header="Skill:SkillName"
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => this.onSortDirectionChanged(id, direction)}
            /> : <Translate>Skill:SkillName</Translate>,
            cellClassName: !this.sortAlphabetical && this.props.isProfile ? 'not-sortable-header' : ''
        });
        if (this.props.isTargetSkill) {
            headerElements.push({
                cellId: 'skillRequiredLevel',
                cellElement: this.sortAlphabetical || !this.props.isProfile ? <SortableHeaderElement
                    id={'skillRequiredLevel'}
                    isActive={this.isActiveHeader('skillRequiredLevel')}
                    header="Skill:SkillRequiredLevel"
                    initialSortDirection={ESortDirection.Down}
                    OnSortDirectionChanged={(id, direction) => this.onSortDirectionChanged(id, direction)}
                /> : <Translate>Skill:SkillRequiredLevel</Translate>,
                cellClassName: !this.sortAlphabetical && this.props.isProfile ? 'not-sortable-header' : ''
            });
        }
        headerElements.push({
            cellId: 'skillLevel',
            cellElement: this.sortAlphabetical || !this.props.isProfile ? <SortableHeaderElement
                id={'skillLevel'}
                isActive={this.isActiveHeader('skillLevel')}
                header="Skill:SkillLevel"
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => this.onSortDirectionChanged(id, direction)}
            /> : <Translate>Skill:SkillLevel</Translate>,
            cellClassName: !this.sortAlphabetical && this.props.isProfile ? 'not-sortable-header' : ''
        });
        headerElements.push({
            cellId: 'skillAquired',
            cellElement: this.sortAlphabetical || !this.props.isProfile ? <SortableHeaderElement
                id={'skillAquired'}
                isActive={this.isActiveHeader('skillAquired')}
                header="Skill:SkillAquired"
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => this.onSortDirectionChanged(id, direction)}
            /> : <Translate>Skill:SkillAquired</Translate>,
            cellClassName: !this.sortAlphabetical && this.props.isProfile ? 'not-sortable-header' : ''
        });
        headerElements.push({
            cellId: 'skillExpired',
            cellElement: this.sortAlphabetical || !this.props.isProfile ? <SortableHeaderElement
                id={'skillExpired'}
                isActive={this.isActiveHeader('skillExpired')}
                header="Skill:SkillExpired"
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => this.onSortDirectionChanged(id, direction)}
            /> : <Translate>Skill:SkillExpired</Translate>,
            cellClassName: !this.sortAlphabetical && this.props.isProfile ? 'not-sortable-header' : ''
        });
        headerElements.push({
            cellId: 'skillStatus',
            cellElement: this.sortAlphabetical || !this.props.isProfile ? <SortableHeaderElement
                id={'skillStatus'}
                isActive={this.isActiveHeader('skillStatus')}
                header="Skill:SkillValid"
                initialSortDirection={ESortDirection.Down}
                OnSortDirectionChanged={(id, direction) => this.onSortDirectionChanged(id, direction)}
            /> : <Translate>Skill:SkillValid</Translate>,
            cellClassName: this.sortAlphabetical && !this.props.isProfile ? 'small-cell' : 'small-cell not-sortable-header'
        });
        headerElements.push({
            cellId: 'Recommendations',
            cellElement: <Translate>Skill:Recommendations</Translate>,
            cellClassName: 'small-cell not-sortable-header'
        });
        return <GTHorizontalTableHeaderRow
            headerElements={headerElements}
            tableRowClassName="skill__table__row notMobile row__header" />;
    }


    private renderSkillsList(skillsContainer: SkillTableItemContainer): JSX.Element[] {
        const elements: JSX.Element[] = [];
        skillsContainer.skillTableItem.map((skill, index) => {
            elements.push(<SkillItem
                context={this.props.context}
                onAssignPreselectedSkill={this.props.onAssignPreselectedSkill}
                onCollapseStateChanged={(item) => this.onCollapseStateChanged(item)}
                key={index}
                bossRealtionCode={this.props.bossRelationCode}
                skill={skill}
                isTargetSkill={this.props.isTargetSkill}
                userId={this.props.userId}
                isActiveHeader={this.isActiveHeader(this.skillModel.columnDictionary[index])}
                onActiveHeaderChanged={(id, direction) => this.onSortDirectionChanged(id, direction)} />)
        }
        )
        if (skillsContainer.hasAllShowRights === false) {
            const rowContent: GTRowContent[] = [];
            rowContent.push({
                mobileColumnHeader: Session.instance.storage.translation.GetString('Skill:Info'),
                cell: <Alert message={Session.instance.storage.translation.GetString('Skill:NoAccessInfo')} alertType='info' alertAppereance='single-line' />,
                cellId: 'noAccessCellContent',
                cellClassName: 'fullsize'
            });
            elements.push(
                <GTHorizontalTableRow
                    key="noAccessInfoRow"
                    rowContent={rowContent}
                    tableRowClassName="skill__table__row row__content" />
            )
        }
        return elements;
    }

    private onCollapseStateChanged(item: SkillItem) {
        if (this.openedItem != null) {
            this.openedItem.collapse();
        }
        this.openedItem = item;
    }

    // IsActiveHeader return "true" if the column "id" determines the sort direction
    // Otherwise "false" is returned.
    private isActiveHeader(id: string) {
        return id === this.state.activeHeaderId;
    }

    private onSortDirectionChanged(id: string, direction: ESortDirection) {
        this.setState({ activeHeaderId: id, sort: { id, direction } });
    }

    private sortSkills(id: string, direction: ESortDirection): SkillTableItem[] {

        return this.props.skillsContainer.skillTableItem.sort((a: SkillTableItem, b: SkillTableItem): number => {
            return SortingAlgorithm.sortByColumnAndDirection(a, b, id, direction);
        })

    }
}