import Session from '$src/core/Session';
import { StringHelper } from '$src/util/StringHelper';
import Moment from 'moment';

export default class DateHelper {


    // returns a date object without time part (dd.mm.yyyy 00:00:00)
    public static GetDatePart(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate())

    }

    /**
     * Returns 0 if the dates are equal, 1 the first is greater than second, -1  if the first is less than second
     */
    public static CompareDate(date1: Date | null | undefined, date2: Date | null | undefined): number {
        // With Date object we can compare dates them using the >, <, <= or >=.
        // The ==, !=, ===, and !== operators require to use date.getTime(),
        // so we need to create a new instance of Date with 'new Date()'

        if (date1 === null || date1 === undefined) {
            return -1;
        }

        if (date2 === null || date2 === undefined) {
            return 1;
        }

        const d1 = new Date(date1);
        const d2 = new Date(date2);

        // Check if the dates are equal
        const same = d1.getTime() === d2.getTime();
        if (same) { return 0; }

        // Check if the first is greater than second
        if (d1 > d2) { return 1; }

        // Check if the first is less than second
        if (d1 < d2) { return -1; }

        return 0;
    }

    public static getDatePeriod(periodStart: Date | undefined, periodEnd: Date | undefined): string {
        const start = periodStart;
        const end = periodEnd;
        let period = '';
        if (start !== undefined && end !== undefined) {
            period = `${StringHelper.dateString(start)} 
            - ${StringHelper.dateString(end)}`
        }
        return period;
    }

    public static toDateString(d: Date | null): string {
        const temp = d != null ?
            StringHelper.dateString(d) : ('')
        return temp
    }

    /** Return day and month without year */
    public static toDayMonthString(d: Date | null): string {
        const temp = d != null ?
            StringHelper.dayMonthString(d) : ('')
        return temp
    }

    public static toDateTimeString(d: Date | null): string {
        const temp = d != null ?
            StringHelper.dateTimeString(d) : ('')
        return temp
    }

    public static toSqlDateString(d: Date | null): string {
        const temp = d != null ?
            Moment(d).format('YYYY-MM-DD') + 'T00:00:00' : ('');
        return temp
    }

    // The input format is expected in Iso standard 
    public static toDateFromIso(s: string | undefined | null): Date | null {
        if (s != null && s !== '') {
            return new Date(s)
        }
        return null
    }

    public static toDate(s: string | undefined | null): Date | null {
        if (s != null && s !== '') {
            const l = Session.instance.getUserLanguageCodeOrFallBack;
            Moment.locale(l)
            const m = Moment.parseZone(s, 'L', l)
            if (!m.isValid()) {
                return new Date(s);
            }
            return m.toDate();
        }
        return null
    }

    public static addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    /**
     * Checks if the dates are within the given filter boundaries
     * @param filterDateForm lower filter boundary date
     * @param filterDateTo upper filter boundary date
     * @param dateFrom lower date to ckeck if its is within the filter boundaries
     * @param dateTo upper date to ckeck if its is within the filter boundaries
     * @param hardBoundaries if set to true, the method will only return true if both dates are within the filter boundaries
     */
    public static datesWithinBoundaries(filterDateFrom: Date | null | undefined, filterDateTo: Date | null | undefined, dateFrom: Date | null | undefined,
        dateTo: Date | null | undefined, hardBoundaries: boolean): boolean {
        if (!dateFrom && !dateTo) {
            return false;
        }
        if (!dateFrom) {
            dateFrom = dateTo;
        }
        if (!dateTo) {
            dateTo = dateFrom;
        }
        if (hardBoundaries) {
            return (filterDateFrom == null || this.CompareDate(filterDateFrom, dateFrom) === -1) && (filterDateTo == null || this.CompareDate(filterDateTo, dateTo) === 1);
        } else {
            return (filterDateFrom == null || this.CompareDate(filterDateFrom, dateTo) === -1) && (filterDateTo == null || this.CompareDate(filterDateTo, dateFrom) === 1);
        }
    }

    /**
     * Checks if the dates are in the specified year
     * @param filterYear year to compare the dates with
     * @param dateFrom lower date to compare with the year
     * @param dateTo upper date to compate with the year
     * @param hardBoundaries if set to true, the method will only return true if both dates are in the specified year. Otherwise one date in that year suffice
     */
    public static datesWithinBoundariesByYear(filterYear: number, dateFrom: Date | null | undefined, dateTo: Date | null | undefined, hardBoundaries: boolean) {
        if (!dateFrom && !dateTo) {
            return false;
        }
        if (!dateFrom) {
            dateFrom = dateTo;
        }
        if (!dateTo) {
            dateTo = dateFrom;
        }
        if (hardBoundaries) {
            return dateFrom && filterYear === dateFrom.getFullYear() && dateTo && filterYear === dateTo.getFullYear();
        } else {
            return (dateFrom && filterYear === dateFrom.getFullYear()) || (dateTo && filterYear === dateTo.getFullYear()) ||
                (dateFrom && dateFrom.getFullYear() < filterYear && dateTo && dateTo.getFullYear() > filterYear);
        }
    }


    public static toUtcDate(localDate: Date): Date {
        const utcDate = Date.UTC(
            localDate.getUTCFullYear(),
            localDate.getUTCMonth(),
            localDate.getUTCDate(),
            localDate.getUTCHours(),
            localDate.getUTCMinutes(),
            localDate.getUTCSeconds());

        return new Date(utcDate);
    }

}
