import React from 'react';
import { RouteComponentProps } from 'react-router';

import { ItemBreadCrumb } from '$components/breadCrumb/ItemBreadCrumb';
import { F2FDocuments } from '$components/item/F2F/F2FDocuments';
import { ItemRegistrationActionConfirmation } from '$components/item/ItemRegistrationActionConfirmation';
import { ToolbarRegistrationStatus } from '$components/item/Toolbar//ToolbarRegistrationStatus';
import AttributeList from '$components/shared/Attributes/AttributeList';
import { ModalPopup } from '$components/shared/ModalPopup';
import { ProgressSpinner } from '$components/shared/ProgressSpinner';
import { Translate } from '$components/shared/Translate';
import Logger from '$core/Logger';
import RegistrationService from '$core/Services/RegistrationService';
import Session from '$core/Session';
import SystemRoles from '$core/SystemRoles';
import ToolbarItemType from '$src/components/item/Toolbar/ToolbarItemType';
import { CheckBox } from '$src/components/shared/CheckBox';
import { ParticipantList } from '$src/components/shared/ParticipantList';
import { RoomDetails } from '$src/components/shared/RoomDetails';
import { Attribute } from '$src/storage/models/Attribute';
import DateHelper from '$src/util/DateHelper';
import {
    ECancellationPermission,
    ECancellationType,
    EClassStatus,
    EDisplayVcgLeadTime,
    EDocumentType,
    EItemDetailCallerContextType,
    EItemDetailCallerType,
    EItemSubType,
    EItemType,
    ELessonStatus,
    ERegistrationStatus
} from '$storage/models/enums';
import { F2FClassDetail } from '$storage/models/F2F/F2FClassDetail';
import { F2FDocumentList } from '$storage/models/F2F/F2FDocumentList';
import { Room } from '$storage/models/F2F/Room';
import { Trainer } from '$storage/models/F2F/Trainer';
import { Item } from '$storage/models/Item';
import { Registration } from '$storage/models/Registration';
import { CancellationReasonModel } from '$storage/models/Workflow/CancellationReasonModel';
import CustomErrorMessage from '$util/CustomErrorMessage';
import GtError from '$util/GtError';
import { isSuccess } from '$util/Result';
import { StringHelper } from '$util/StringHelper';
import { BooleanResponse } from '$storage/models/BooleanResponse';
import { Tooltip } from '$components/shared/Tooltip';
import { ItemLockedReasons } from '$components/item/ItemLockedReasons';
import { F2FDocumentsUpload } from '$components/item/F2F/F2FDocumentsUpload';
import { BossSearchResult } from '$src/storage/models/BossSearchResult';
import GuestRegistrationActionConfirmation from '$components/item/GuestRegistrationActionConfirmation';
import { ItemHelper } from '$src/util/ItemHelper';
import ShoppingBasketStorage from '$src/storage/ShoppingBasketStorage';
import ShoppingBasketItem from '$src/storage/models/ShoppingBasket/ShoppingBasketItem';
import { NumberHelper } from '$src/util/NumberHelper';
import GTButton from '$src/components/shared/Atoms/GTButton';
import F2FService from '$src/core/Services/F2FService';
import { VirtualMeetingSingleLink } from './F2FVirtualMeetingSingleLink';
import { VoucherValidity } from '$src/storage/models/Purchase/VoucherValidity';
import PurchaseService from '$src/core/Services/PurchaseService';
import ShoppingBasket from '$src/storage/models/ShoppingBasket/ShoppingBasket';
import { CheckoutResponse } from '$src/storage/models/Purchase/CheckoutResponse';
import { CheckoutRequest } from '$src/storage/models/Purchase/CheckoutRequest';
import { CollaborationProviderClassPanel } from '$components/collaborationProvider/item/CollaborationProviderClassPanel';
import ShoppingBasketPopUp from '$src/components/shoppingBasket/ShoppingBasketPopUp';

interface IMatchParams {
    clsId: string;
    itmId: string;
}

interface IURLParamState {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    parentTrainingPlanId: number;
    placeReleasedFromWaitingList?: boolean; // Released from Waiting List
    voucherCode: string | null;
}

type IProps = RouteComponentProps<IMatchParams>

interface IState extends IURLParamState {
    cancellationReasons: CancellationReasonModel[];
    cancellationReasonText: string;
    classDetails: F2FClassDetail | null;
    errorMessage: string;
    f2fDocuments: F2FDocumentList | null;
    isClassInShoppingBasket: boolean;
    item: Item | null;
    selectedBoss?: BossSearchResult;
    selectedCancellationReasonId?: number;
    shouldShowModal: boolean;
    shouldShowAGBAcceptancePanel: boolean;
    agbAccepted: boolean;
    showParticipitions?: boolean;
    displaySelfConfirmationModal: boolean;
    currentClsId: number | undefined;
    voucherValidity: VoucherValidity | undefined;
    showShoppingModal: boolean;
}

export class F2FRegistration extends React.Component<IProps, IState> {

    protected className = 'F2FRegistration';
    protected loggerLocality = 'Components.F2FRegistration';
    protected leadTimeConfig = EDisplayVcgLeadTime[globalConfig.virtualMeetingsProperties.showLeadTimeAs] ? globalConfig.virtualMeetingsProperties.showLeadTimeAs : EDisplayVcgLeadTime.Integrated;

    constructor(props: IProps) {
        super(props);
        this.state = {
            agbAccepted: false,
            cancellationReasonText: '',
            cancellationReasons: [],
            classDetails: null,
            errorMessage: '',
            f2fDocuments: null,
            isClassInShoppingBasket: false,
            item: null,
            itemDetailCallerContextType: EItemDetailCallerContextType.Undefined,
            parentAssignmentId: 0,
            parentCatalogFolderId: 0,
            parentTrainingPlanId: 0,
            placeReleasedFromWaitingList: false,
            selectedBoss: undefined,
            selectedCancellationReasonId: undefined,
            shouldShowAGBAcceptancePanel: false,
            shouldShowModal: false,
            showParticipitions: undefined,
            displaySelfConfirmationModal: false,
            currentClsId: undefined,
            voucherCode: null,
            voucherValidity: undefined,
            showShoppingModal: false,
        }
    }

    public async componentDidMount() {
        const { clsId } = this.props.match.params;
        const clsIdChecked = clsId != null && !isNaN(Number(clsId)) ? Number(clsId) : 0;
        await this.loadData(clsIdChecked);
    }

    public render() {
        let registerAriaText = Session.instance.storage.translation.GetString('Course:Register');
        let cancelRegistrationAriaText = Session.instance.storage.translation.GetString('Course:CancelRegistration');
        let hasRightToSeeF2FParticipantListTutor = false;
        if (this.state.classDetails != null) {
            registerAriaText += ' ' + this.state.classDetails.classTitle
            cancelRegistrationAriaText += ' ' + this.state.classDetails.classTitle
            hasRightToSeeF2FParticipantListTutor = this.state.classDetails.isTutor;
        }

        const currentRegistration = this.getActiveClassRegistration();
        let showF2FParticipantList = false;
        if (Session.instance.isCurrentUserMemberOfAtLeastOneOfGroups(globalConfig.f2fProperties.alwaysShowF2FParticipantListForGroups)) {
            showF2FParticipantList = true;
        } else if
            (currentRegistration != null &&
            globalConfig.f2fProperties.showF2FParticipantList &&
            currentRegistration.registrationStatus === ERegistrationStatus.Accepted) {
            showF2FParticipantList = true;
        }

        // prepare the display of the shopping button if this course must be purchased
        let showShoppingBtn: boolean = false;
        let shoppingBtnText = '';
        let shouldShoppingButtonBeDisabled = true;
        if (this.state.item != null && this.state.classDetails != null && this.state.item.isPurchasableForCurrentUser) {
            showShoppingBtn = true;
            if (this.state.classDetails.price === null || this.state.classDetails.currency.length === 0) {
                // misconfiguration: price or currency is missing
                shoppingBtnText = Session.instance.storage.translation.GetString('Course:PurchaseImpossible');
                shouldShoppingButtonBeDisabled = true;
            } else {
                // price is known, item can be purchased
                if (this.state.isClassInShoppingBasket) {
                    shoppingBtnText = Session.instance.storage.translation.GetString('Course:ItemAlreadyInShoppingBasket');
                    // if the class is already in the shopping basket then it can be removed with another click on the button...                
                    shouldShoppingButtonBeDisabled = false; // ...and no AGB acceptance is required for that
                } else {
                    shoppingBtnText = Session.instance.storage.translation.GetString('Course:AddToShoppingBasket');
                    // adding a class to the shopping basket requires AGB acceptance
                    shouldShoppingButtonBeDisabled = this.state.shouldShowAGBAcceptancePanel && !this.state.agbAccepted;
                }
            }
        }

        //prepare the display for 100% discount redeem voucher
        const showRedeemVoucherBtn: boolean = showShoppingBtn && this.state.voucherValidity !== undefined && this.state.voucherValidity.isValid;
        const redeemBtnText = Session.instance.storage.translation.GetString("Course:RedeemVoucher");
        const requestVoucherBtnText = Session.instance.storage.translation.GetString("Course:RequestVoucher");

        return (
            <React.Fragment>
                <div className="l-container">
                    <ItemBreadCrumb
                        itemDetailCallerContextType={this.state.itemDetailCallerContextType}
                        parentAssignmentId={this.state.parentAssignmentId}
                        parentCatalogFolderId={this.state.parentCatalogFolderId}
                        parentTrainingPlanId={this.state.parentTrainingPlanId}
                        itemDetailType={EItemDetailCallerType.Item}
                        itemTitle={this.state.item != null ? this.state.item.title : ''}
                        itemId={this.state.item != null ? this.state.item.itemId : 0}
                        classId={this.state.classDetails != null ? this.state.classDetails.classId : 0}
                        classTitle={this.state.classDetails != null ?
                            this.state.classDetails.classTitle !== '' ?
                                this.state.classDetails.classTitle :
                                this.state.classDetails.classCode :
                            ''}
                        voucherCode={this.state.voucherCode}
                        {...this.props} />
                    <div>
                        <h1 className="heading__Title">
                            <Translate>Course:ClassDetails</Translate>
                        </h1>
                    </div>
                    <div>
                        <span className={'input-message error'}>
                            <Translate>{this.state.errorMessage}</Translate>
                        </span>
                    </div>
                    <div>
                        {this.state.classDetails != null ? (this.renderCustomAttributes(this.state.classDetails.attributes)) : null}
                    </div>

                    {globalConfig.f2fClassDetailsProperites.displayScheduleDatesBeforeDetails && this.renderAppointmentsWithTitle()}

                    <div>
                        <h2 className="heading__Level2">
                            <Translate>Course:ClassDetailsOverview</Translate>
                        </h2>
                        {this.state.classDetails != null ? (this.renderMainClassDetails()) : (<ProgressSpinner />)}
                    </div>

                    <div className="f2f__registration-button-container">
                        {showF2FParticipantList &&
                            <GTButton
                                onClick={() => this.onParticipationListClicked()}
                                aria-label={Session.instance.storage.translation.GetString('Course:OpenParticipantList')}>
                                <Translate>Course:OpenParticipantList</Translate>
                            </GTButton>
                        }

                        {globalConfig.f2fProperties.showF2FParticipantListTutor && hasRightToSeeF2FParticipantListTutor &&
                            <GTButton
                                additionalClassNames=""
                                onClick={() => this.onParticipationListTutorClicked()}>
                                <Translate>ParticipantListTutor:PrintButton</Translate>
                            </GTButton>
                        }
                    </div>

                    {!globalConfig.f2fClassDetailsProperites.displayScheduleDatesBeforeDetails && this.renderAppointmentsWithTitle()}
                    {(this.state.classDetails != null && this.state.classDetails.overlappingRegistrations.length > 0) &&
                        <React.Fragment>
                            <h2 className="heading__Level2 inlineFlex"
                                data-tip={Session.instance.storage.translation.GetString('Course:Conflicts')}
                                data-for={`${this.state.classDetails.classId}_conflictTooltip`}
                                aria-label={Session.instance.storage.translation.GetString('Course:Conflicts')}>
                                <Translate>Course:OverlappingSessions</Translate>
                                <Tooltip id={`${this.state.classDetails.classId}_conflictTooltip`} />
                            </h2>
                            <div>
                                {this.renderOverlappingSessions()}
                            </div>
                        </React.Fragment>
                    }

                    {this.renderDocuments()}

                    {this.state.item != null &&
                        currentRegistration != null &&
                        currentRegistration.registrationStatus == ERegistrationStatus.Accepted &&
                        this.state.currentClsId != null &&
                        <CollaborationProviderClassPanel
                            itemId={this.state.item.itemId}
                            classId={currentRegistration.classId}
                            panelTitle={'Course:CollaborationProviderLink'} />
                    }

                    {this.allowRegistration() || this.allowCancellation() &&
                        <h2 className="heading__Level2">
                            <Translate>Course:ClassRegistration</Translate>
                        </h2>
                    }
                    {this.renderAGBAcceptance()}
                    <div className="f2f__registration-button-container">
                        {this.allowRegistration() && this.state.placeReleasedFromWaitingList != true &&
                            <>
                                {
                                    showRedeemVoucherBtn ? <button type="button"
                                        aria-label={redeemBtnText}
                                        onClick={() => this.onRedeemVoucherBtnClicked()}
                                        disabled={this.state.shouldShowAGBAcceptancePanel && !this.state.agbAccepted}
                                        className="btn--md btn--primary">
                                        {redeemBtnText}
                                    </button>
                                        :
                                        showShoppingBtn ?
                                            // for purchasable courses show the request voucher (secondary) and shopping button (primary, to add the schedule to the shopping basket) 
                                            <>
                                                {globalConfig.shoppingProperties.isRequestVoucherBtnActive ? <button type="button"
                                                    disabled={this.state.shouldShowAGBAcceptancePanel && !this.state.agbAccepted}
                                                    aria-label={requestVoucherBtnText}
                                                    onClick={() => this.onRequestVoucherBtnClicked()}
                                                    className="btn--md btn--secondary">
                                                    {requestVoucherBtnText}
                                                </button> : <></>}
                                                <button type="button"
                                                    aria-label={shoppingBtnText}
                                                    disabled={shouldShoppingButtonBeDisabled}
                                                    onClick={() => this.onShoppingBtnClicked()}
                                                    className="btn--md btn--primary">
                                                    {shoppingBtnText}
                                                </button>
                                            </>
                                            :
                                            // otherwise show the normal register button
                                            <button type="button"
                                                aria-label={registerAriaText}
                                                disabled={this.state.shouldShowAGBAcceptancePanel && !this.state.agbAccepted}
                                                onClick={() => this.openModal()}
                                                className="btn--md btn--primary"
                                                id="btnRegister">
                                                <Translate>Course:Register</Translate>
                                            </button>
                                }
                            </>
                        }
                        {this.allowRegistration() && this.state.placeReleasedFromWaitingList === true && // Register after waiting list place released
                            <button type="button"
                                aria-label={registerAriaText}
                                onClick={() => this.openModal()}
                                className="btn--md btn--primary">
                                <Translate>Course:Register</Translate>
                            </button>
                        }
                        {this.allowCancellation() && this.state.placeReleasedFromWaitingList !== true &&
                            <button
                                type="button"
                                aria-label={cancelRegistrationAriaText}
                                onClick={() => this.onCancellationClicked()}
                                className="btn--md btn--primary">
                                <Translate>Course:CancelRegistration</Translate>
                            </button>
                        }
                    </div>

                    {this.allowSelfConfirmationOfAttendance() &&
                        currentRegistration != null &&
                        <>
                            <h2 className="heading__Level2">
                                <Translate>Course:SelfConfirmationOfAttendanceTitle</Translate>
                            </h2>
                            <p><Translate>Course:SelfConfirmationOfAttendanceDescription</Translate></p>
                            <div>
                                <GTButton
                                    onClick={() => this.onConfirmAttendance(currentRegistration.registrationId, this.state.itemDetailCallerContextType,
                                        this.state.parentTrainingPlanId)}>
                                    <Translate>Course:SelfConfirmationOfAttendance</Translate>
                                </GTButton>
                            </div>
                        </>
                    }

                </div>
                <ModalPopup
                    isOpen={this.state.shouldShowModal}
                    onRequestClose={() => this.closeModal()}
                    contentCssClass={!this.state.showParticipitions ? '' : 'modal__participantList'}>
                    {!this.state.showParticipitions ?
                        this.renderActionConfirmation() :
                        <ParticipantList
                            parentHeadingLevel={1}
                            scheduleId={this.state.classDetails != null ? this.state.classDetails.classId : 0}
                            itemType={this.state.item!.itemType}
                            itemSubType={this.state.item!.itemSubType} />
                    }
                </ModalPopup>
                <ModalPopup
                    isOpen={this.state.displaySelfConfirmationModal}
                    onRequestClose={() => this.closeSelfConfirmationModal()}
                    contentCssClass={!this.state.showParticipitions ? '' : 'modal__self-confirmation-of-attendance'}>
                    <p><Translate>Course:SelfConfirmationOfAttendanceSuccess</Translate></p>
                    <GTButton
                        onClick={() => this.closeSelfConfirmationModal()}>
                        <Translate>Course:SelfConfirmationOfAttendanceSuccessOK</Translate>
                    </GTButton>
                </ModalPopup>
                <ShoppingBasketPopUp closeModal={() => this.closeShoppingModal()} showModal={this.state.showShoppingModal} />
            </React.Fragment>
        );
    }

    public closeShoppingModal() {
        this.setState({ showShoppingModal: false });
    }

    public async componentDidUpdate() {
        const newTitle = document.getElementsByTagName('h1')[0];
        document.title = newTitle == null ? globalConfig.appProperties.title : globalConfig.appProperties.title + ': ' + newTitle.innerText;

        const { clsId } = this.props.match.params;
        const clsIdChecked = clsId != null && !isNaN(Number(clsId)) ? Number(clsId) : 0;

        if (this.state.currentClsId != clsIdChecked) {
            await this.loadData(clsIdChecked);
        }
    }

    private async loadData(clsIdChecked: number) {
        const { itmId } = this.props.match.params;
        const itmIdChecked = itmId != null && !isNaN(Number(itmId)) ? Number(itmId) : 0;

        let f2fDocuments: F2FDocumentList | null = null;
        let shouldShowAGBAcceptancePanel = false;
        let voucherValidity: VoucherValidity | undefined = undefined;
        const partialStateUrlParamState = this.getURLParameters();

        if (partialStateUrlParamState.voucherCode !== null) {
            Logger.log(this.loggerLocality, `${this.loggerLocality} loadData() -> checkVoucher`);
            const response = await PurchaseService.instance.checkVoucher(partialStateUrlParamState.voucherCode);
            if (isSuccess<VoucherValidity>(response)) {
                if (response.isValid && response.percentDiscount === 100 && response.itemIds.findIndex(i => i == itmIdChecked) !== -1) {
                    voucherValidity = response;
                }
            } else {
                console.error(`checkVoucher failed`);
            }
        }

        const item = await Session.instance.storage.item.getItemDetail(itmIdChecked,
            partialStateUrlParamState.itemDetailCallerContextType, partialStateUrlParamState.parentTrainingPlanId);
        const classDetails = await Session.instance.storage.classDetails.getF2FClassDetails(clsIdChecked,
            partialStateUrlParamState.itemDetailCallerContextType, undefined, partialStateUrlParamState.parentTrainingPlanId);
        if (classDetails != null) {
            f2fDocuments = await this.getDocuments(classDetails.classId, classDetails.courseId, partialStateUrlParamState.itemDetailCallerContextType,
                partialStateUrlParamState.parentTrainingPlanId, false);
            if (f2fDocuments != null &&
                f2fDocuments.allowedDocuments != null &&
                f2fDocuments.allowedDocuments.filter(doc => doc.documentType === EDocumentType.GeneralTermsAndConditions).length > 0) {
                shouldShowAGBAcceptancePanel = true;
            }
        }

        // check whether this class is already in shopping basket and set state accordingly
        const isClassInShoppingBasket = ShoppingBasketStorage.instance.isItemInBasket(itmIdChecked, clsIdChecked);

        this.setState({ f2fDocuments, shouldShowAGBAcceptancePanel, item, classDetails, isClassInShoppingBasket, voucherValidity, ...partialStateUrlParamState, currentClsId: clsIdChecked });
    }

    private areAnyAllowedDocumentsPresent(considerABGDocuments: boolean): boolean {
        if (this.state.f2fDocuments != null && this.state.f2fDocuments.allowedDocuments != null &&
            considerABGDocuments && this.state.f2fDocuments.allowedDocuments.length > 0) {
            return true;
        }
        else if (this.state.f2fDocuments != null && this.state.f2fDocuments.allowedDocuments != null &&
            this.state.f2fDocuments.allowedDocuments.filter(doc => doc.documentType != EDocumentType.GeneralTermsAndConditions).length > 0) {
            return true;
        }
        else {
            return false;
        }

    }

    private renderActionConfirmation(): JSX.Element | null {
        let element: JSX.Element | null = null;

        if (Session.instance.hasCurrentUserRole(SystemRoles.instance.Guest)) {
            element = <GuestRegistrationActionConfirmation
                onCancelClick={() => this.closeModal()}
                onConfirmClick={() => this.onGuestRegistrationConfirmation()} />
        } else {
            element = <ItemRegistrationActionConfirmation
                itemID={this.state.item != null ? this.state.item.itemId : 0}
                classTitle={this.state.classDetails != null ? this.state.classDetails.classTitle : this.state.item != null ? this.state.item.title : ''}
                parentHeadingLevel={2}
                register={(this.allowRegistration() && !this.allowCancellation()) || (this.allowRegistration() && this.state.placeReleasedFromWaitingList === true)}
                onExitClicked={() => this.closeModal()}
                onRegistrationConfirmedClick={async (boss) => this.onRegistrationConfirmed(boss)}
                onCancelRegistrationConfirmedClick={async (cancellationId, cancellationReason) => this.onCancelRegistrationConfirmed(cancellationId, cancellationReason)}
                cancellationType={this.state.classDetails != null ? this.state.classDetails.isCancellationAllowed : ECancellationPermission.NotAllowed}
                cancellationReasonType={ECancellationType.Enrolment}
                bossSelectionReadonly={globalConfig.bossSelection.bossSelectionIsReadOnly_F2F}
                bossSelectionRequired={globalConfig.bossSelection.bossSelectionIsRequired_F2F}
                bossSelectionVisible={globalConfig.bossSelection.selectionIsVisible_F2F &&
                    this.state.classDetails != null &&
                    !this.state.classDetails.isAutoregister} />
        }

        return element;
    }

    private renderDocuments() {
        const hasTutorRight = this.state.classDetails != null && this.state.classDetails.isTutor;
        const hasTutorRole = Session.instance.loginUser!.roles.find(role => role.includes(SystemRoles.instance.Tutor)) != undefined;
        const registrationStatus = this.getCurrentRegistrationStatus();
        // Show AGB documents always if the user has a registration status
        const showABGDocuments = registrationStatus != null && registrationStatus !== ERegistrationStatus.Undefined && registrationStatus !== ERegistrationStatus.NotRegistered;
        const allowedDocumentTypes = [EDocumentType.ClassAfterRunningPhase,
        EDocumentType.ClassTakesPlace,
        EDocumentType.Custom,
        EDocumentType.OnlyForAdministrators,
        EDocumentType.RegistrationAccepted,
        EDocumentType.RegistrationRequestedOrAccepted,
        EDocumentType.WithoutRestriction];
        if (showABGDocuments) {
            allowedDocumentTypes.push(EDocumentType.GeneralTermsAndConditions)
        }
        if (this.areAnyAllowedDocumentsPresent(showABGDocuments)
            || (hasTutorRole && hasTutorRight)) {
            return (<React.Fragment>
                <h2 className="heading__Level2">
                    <Translate>Course:F2FDocuments</Translate>
                </h2>
                <div>
                    {this.state.classDetails != null &&
                        <React.Fragment>
                            {hasTutorRole && hasTutorRight &&
                                <F2FDocumentsUpload
                                    classId={this.state.classDetails.classId}
                                    onDocumentsUploaded={() => this.reloadDocuments()} />}
                            <F2FDocuments
                                f2fDocuments={this.state.f2fDocuments}
                                allowedDocumentTypes={allowedDocumentTypes}
                                deleteAllowed={hasTutorRole && hasTutorRight}
                                uploadAllowed={hasTutorRole && hasTutorRight}
                                onReloadDocuments={() => this.reloadDocuments()} />
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>);
        }
        else {
            return;
        }
    }

    private renderAGBAcceptance(): JSX.Element {
        if (this.state.classDetails != null) {
            const hasTutorRight = this.state.classDetails != null && this.state.classDetails.isTutor;
            const hasTutorRole = Session.instance.loginUser!.roles.find(role => role.includes(SystemRoles.instance.Tutor)) != undefined;
            return (
                <React.Fragment>
                    {this.state.shouldShowAGBAcceptancePanel && this.allowRegistration() ?
                        (<>
                            <div className="f2f__registration-agb-container">
                                <CheckBox id="cbAcceptAGB" onClick={() => this.onToggleAcceptAGB()} />
                                <label id="lblAcceptAGB" htmlFor="cbAcceptAGB"><Translate>Course:AcceptAGB</Translate></label>
                            </div>
                            <div className="f2f__registration-agb-documents">
                                <F2FDocuments
                                    f2fDocuments={this.state.f2fDocuments}
                                    allowedDocumentTypes={[EDocumentType.GeneralTermsAndConditions]}
                                    deleteAllowed={hasTutorRole && hasTutorRight}
                                    onReloadDocuments={() => this.reloadDocuments()}
                                    hideIcon={true} />
                            </div>
                        </>) : ('')}
                </React.Fragment>
            );
        } else {
            return <div />
        }
    }

    private renderCustomAttributes(classAttributes: Attribute[]): JSX.Element | null {
        const customAttributeConfig = globalConfig.f2fProperties.customAttributesClassOnClassDetail;
        if (customAttributeConfig != null && customAttributeConfig.length > 0) {
            return <AttributeList
                customAttributeConfig={customAttributeConfig}
                attributes={classAttributes}
                headingCssClass="heading__Level2"
                attributeCssClassName="item-detail"
                parentHeadingLevel={1}
            />
        }
        return null;
    }

    private onToggleAcceptAGB() {
        this.setState({ agbAccepted: !this.state.agbAccepted });
    }

    private renderClassDetailField = (fieldName: string) => {
        return globalConfig.f2fClassDetailsProperites.visibleFields == null ||
            globalConfig.f2fClassDetailsProperites.visibleFields.length == 0 ||
            globalConfig.f2fClassDetailsProperites.visibleFields.filter(f => f.toLocaleLowerCase() === fieldName.toLocaleLowerCase()).length >= 1;
    }

    private renderMainClassDetails() {
        if (this.state.classDetails != null && this.state.item != null) {
            return (
                <div role="table" className="f2f__registration-details-table">
                    <div role="rowgroup">
                        {this.renderClassDetailField('CourseTitle') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:CourseTitle</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.state.item.title}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('ClassCode') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:ClassCode</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.state.classDetails.classCode}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('ClassTitle') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:ClassTitle</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.state.classDetails.classTitle}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('Location') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:Location</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.state.classDetails.venueCity}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('Price') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:Price</Translate>
                                    </div>
                                    <div role="cell">
                                        {NumberHelper.getFormattedPrice(this.state.classDetails.price, this.state.classDetails.currency)}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('AvailablePlaces') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:AvailablePlaces</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.renderAvailability(this.state.classDetails.maxParticipants, this.state.classDetails.freePlaces, this.state.classDetails.hasWaitingList)}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('ClassLanguage') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:ClassLanguage</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.state.classDetails.language}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('ClassDescription') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:ClassDescription</Translate>
                                    </div>
                                    <div role="cell">
                                        {this.state.classDetails.classDescription}
                                    </div>
                                </div>
                            </>}
                        {this.renderClassDetailField('RegistrationPeriod') &&
                            <>
                                <div role="row" className="f2f__registration-details-table-row">
                                    <div role="columnheader">
                                        <Translate>Course:RegistrationPeriod</Translate>
                                    </div>
                                    <div role="cell">
                                        {DateHelper.getDatePeriod(this.state.classDetails.registrationStart, this.state.classDetails.registrationEnd)}
                                    </div>
                                </div>
                            </>}
                        <div role="row" className="f2f__registration-details-table-row">
                            <div role="columnheader">
                                <Translate>Course:ClassRegistrationStatus</Translate>
                            </div>
                            <div role="cell">
                                <ToolbarRegistrationStatus
                                    item={this.state.item}
                                    classId={this.state.classDetails.classId}
                                    textVisible={true}
                                    classTitle={this.state.classDetails.classCode}
                                    hasRegisterRight={this.state.classDetails.hasRegistrationRight}
                                    canSelfRegister={Session.instance.loginUser != null &&
                                        Session.instance.loginUser.canSelfRegisterToCourse}
                                    isInRegistrationPeriod={this.state.classDetails.isInRegistrationPeriod}
                                    checkingDateOfStartCondition={this.state.classDetails.checkingDateOfStartCondition} />
                            </div>
                        </div>
                        {this.state.item.isLocked && this.state.classDetails.cheatStartcondition && this.state.classDetails.checkingDateOfStartCondition &&
                            <div role="row" className="f2f__registration-details-table-row">
                                <div role="columnheader">
                                    <Translate>Course:CheckingStartConditionDate</Translate>
                                </div>
                                <div role="cell">
                                    {StringHelper.dateString(this.state.classDetails.checkingDateOfStartCondition)}
                                </div>
                            </div>
                        }
                        <div role="row" className="f2f__registration-details-table-row">
                            <ItemLockedReasons
                                item={this.state.item}
                                hasRegisterRight={this.state.classDetails.hasRegistrationRight}
                                canSelfRegister={Session.instance.loginUser != null &&
                                    Session.instance.loginUser.canSelfRegisterToCourse}
                                isInRegistrationPeriod={this.state.classDetails.isInRegistrationPeriod} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    }


    private renderAppointmentsWithTitle = () => {
        return <><h2 className="heading__Level2">
            <Translate>Course:Schedules</Translate>
        </h2>
            <div>
                {this.renderAppointments()}
            </div></>
    }

    private renderAppointments() {
        let hasVcRooms = false;
        let minutesOpenedBeforeStartTime = 0;
        if (this.state.classDetails != null && this.state.classDetails.schedulesList != null) {
            for (let s = 0; s < this.state.classDetails.schedulesList.length; s++) {
                const schedule = this.state.classDetails.schedulesList[s];
                if (schedule.rooms == null) continue;
                for (let r = 0; r < schedule.rooms.length; r++) {
                    const room = schedule.rooms[r];
                    hasVcRooms = room.externalMeetingId != null && room.externalMeetingId.length > 0;
                    if (hasVcRooms && room.virtualMeetingLink != null) {
                        minutesOpenedBeforeStartTime = room.virtualMeetingLink.minutesOpenedBeforeStartTime;
                        break;
                    }

                }
                if (hasVcRooms && minutesOpenedBeforeStartTime > 0) break;
            }
        }
        return (
            <>
                {this.state.classDetails != null && this.state.classDetails.schedulesList != null && hasVcRooms && globalConfig.virtualMeetingsProperties.showLeadTimeHint &&
                    <div className={globalConfig.virtualMeetingsProperties.leadTimeHintCss != null ?
                        ('virtualMeetings__lead-time-hint ' + globalConfig.virtualMeetingsProperties.leadTimeHintCss) : ''}>
                        {Session.instance.storage.translation.GetString("F2FVirtualMeetingLinks:LeadTimeHint").Format(minutesOpenedBeforeStartTime.toString())}
                    </div>
                }
                <div role="table" className="f2f__registration-appointments-table">
                    <div role="rowgroup">
                        <div role="row" className="f2f__registration-appointments-table-row">
                            <div role="columnheader" className="notMobile">
                                <Translate>Course:Date</Translate>
                            </div>
                            {hasVcRooms && this.leadTimeConfig === EDisplayVcgLeadTime.Column &&
                                <div role="columnheader" >
                                    <Translate>F2FVirtualMeetingLinks:LeadTime</Translate>
                                </div>
                            }
                            {this.renderClassDetailField('SessionDescription') &&
                                <div role="columnheader" className="notMobile">
                                    <Translate>Course:SessionDescription</Translate>
                                </div>
                            }
                            <div role="columnheader" className="notMobile">
                                <Translate>Course:Location</Translate>
                            </div>
                            {globalConfig.f2fClassDetailsProperites.displayTrainer && <div role="columnheader" className="notMobile">
                                <Translate>Course:Trainer</Translate>
                            </div>}
                            {
                                hasVcRooms && this.state.item != null &&
                                    this.state.item.registrationStatus === ERegistrationStatus.Accepted &&
                                    this.state.currentClsId === this.state.item.registeredClassId ?
                                    <div role="columnheader" className="notMobile">
                                        <Translate>F2FVirtualMeetingLinks:URL</Translate>
                                    </div> : ''
                            }
                        </div>
                    </div>
                    <div role="rowgroup">
                        {this.renderAppointmentList(hasVcRooms)}
                    </div>
                </div>
            </>);
    }

    private renderAppointmentList(hasVcRooms: boolean) {
        const elements: JSX.Element[] = [];
        if (this.state.classDetails != null && this.state.classDetails.schedulesList != null) {
            this.state.classDetails.schedulesList.map((sce, index) => {
                let startDateTime = sce.startDateTimeUTC;
                let endDateTime = sce.endDateTimeUTC;
                let minutesOpenedBeforeStartTime = 0;
                if (hasVcRooms) {
                    const virtualRoom = sce.rooms.find(room => { return room.externalMeetingId != null && room.externalMeetingId.length > 0 });
                    if (virtualRoom != null && virtualRoom.virtualMeetingLink != null) {
                        const vm = virtualRoom.virtualMeetingLink;
                        startDateTime = vm.startDateTime;
                        endDateTime = vm.endDateTime;
                        minutesOpenedBeforeStartTime = vm.minutesOpenedBeforeStartTime;
                        if (this.leadTimeConfig !== EDisplayVcgLeadTime.Integrated && vm.minutesOpenedBeforeStartTime !== 0 && vm.startDateTime != null) {
                            const newStartDateTime = new Date(vm.startDateTime.getTime() + vm.minutesOpenedBeforeStartTime * 60000);
                            startDateTime = newStartDateTime;
                        }
                    }
                }

                const date = startDateTime !== undefined ? StringHelper.dateString(startDateTime) : '';
                let time = '';
                if (startDateTime !== undefined && endDateTime !== undefined) {
                    time = `${StringHelper.timeString(startDateTime)} - 
                    ${StringHelper.timeString(endDateTime)}`;
                }
                const dateTime = `${date} ${time}`;


                elements.push(
                    <div key={index} role="row" className="f2f__registration-appointments-table-row">
                        <div role="columnheader" className="mobileOnly">
                            <Translate>Course:SingleDate</Translate>
                        </div>
                        <div role="cell">
                            {dateTime}
                        </div>
                        {hasVcRooms && this.leadTimeConfig === EDisplayVcgLeadTime.Column &&
                            <React.Fragment>
                                <div role="columnheader" className="mobileOnly">
                                    <Translate>F2FVirtualMeetingLinks:LeadTime</Translate>
                                </div>
                                <div role="cell">
                                    {minutesOpenedBeforeStartTime}
                                </div>
                            </React.Fragment>
                        }

                        {this.renderClassDetailField('SessionDescription') &&
                            <>
                                <div role="columnheader" className="mobileOnly">
                                    <Translate>Course:SessionDescription</Translate>
                                </div>
                                <div role="cell">
                                    {sce.sessionDescription}
                                </div>
                            </>
                        }
                        <div role="columnheader" className="mobileOnly">
                            <Translate>Course:Room</Translate>
                        </div>
                        <div role="cell">
                            <ul>
                                {this.renderLocations(sce.rooms)}
                            </ul>
                        </div>
                        {globalConfig.f2fClassDetailsProperites.displayTrainer && <><div role="columnheader" className="mobileOnly">
                            <Translate>Course:Trainer</Translate>
                        </div>
                            <div role="cell">
                                <ul>
                                    {this.renderTrainers(sce.trainers)}
                                </ul>
                            </div></>}
                        {
                            hasVcRooms && this.state.item != null &&
                                this.state.item.registrationStatus === ERegistrationStatus.Accepted &&
                                this.state.currentClsId === this.state.item.registeredClassId ?
                                <div>
                                    <ul>{this.renderVCLinks(sce.rooms,
                                        this.state.item.registrationId,
                                        this.state.classDetails != null ? this.state.classDetails.participationAutoConfirmationForVCG : false)}</ul>
                                </div>
                                : ''
                        }
                    </div>);
            })
        }
        return elements;

    }

    private renderOverlappingSessions() {
        return (
            <div role="table" className="f2f__registration-overlapping-sessions-table">
                <div role="rowgroup">
                    <div role="row" className="f2f__registration-overlapping-sessions-table-row">
                        <div role="columnheader" className="notMobile">
                            <Translate>Course:CourseTitle</Translate>
                        </div>
                        <div role="columnheader" className="notMobile">
                            <Translate>Course:SingleDate</Translate>
                        </div>
                        <div role="columnheader" className="notMobile">
                            <Translate>Course:ClassRegistrationStatus</Translate>
                        </div>
                        <div role="columnheader" className="notMobile">
                            <Translate>ItemDetail:ItemType</Translate>
                        </div>
                    </div>
                </div>
                <div role="rowgroup">
                    {this.renderOverlappingSessionList()}
                </div>
            </div>);
    }

    private renderOverlappingSessionList() {
        const elements: JSX.Element[] = [];
        if (this.state.classDetails != null) {
            this.state.classDetails.overlappingRegistrations.map((reg, index) => {

                const learningPeriodBegin = reg.learningPeriodBegin !== undefined ? StringHelper.dateString(reg.learningPeriodBegin) : '';
                const learningPeriodEnd = reg.learningPeriodEnd !== undefined ? StringHelper.dateString(reg.learningPeriodEnd) : '';

                const date = `${learningPeriodBegin} - ${learningPeriodEnd}`;


                elements.push(
                    <div key={index} role="row" className="f2f__registration-overlapping-sessions-table-row">
                        <div role="columnheader" className="mobileOnly">
                            <Translate>Course:CourseTitle</Translate>
                        </div>
                        <div role="cell">
                            {reg.title}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>Course:SingleDate</Translate>
                        </div>
                        <div role="cell">
                            {date}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>Course:ClassRegistrationStatus</Translate>
                        </div>
                        <div role="cell">
                            {reg.registrationStatusAsString}
                        </div>
                        <div role="columnheader" className="mobileOnly">
                            <Translate>ItemDetail:ItemType</Translate>
                        </div>
                        <div role="cell">
                            <ToolbarItemType item={reg} textVisible={true} />
                        </div>
                    </div>);
            })
        }
        return elements;

    }

    private renderLocations(rooms: Room[]) {
        const elements: JSX.Element[] = [];
        rooms.map((room, index) => {
            elements.push(
                <li key={index}>
                    <RoomDetails
                        uniqueId={`${index}_${room.roomId}`}
                        courseItemId={this.state.item?.itemId || 0}
                        itemContext={this.state.itemDetailCallerContextType}
                        parentTraningPlanId={this.state.parentTrainingPlanId}
                        room={room}
                        showRoomTitleAsLink={true}
                        setErrorMessage={(message) => this.setErrorMessage(message)}
                        parentHeadingLevel={1} />
                </li>
            );
        })
        return elements;
    }

    private renderTrainers(trainers: Trainer[]) {
        const elements: JSX.Element[] = [];
        trainers.map((trainer, index) => {
            elements.push(
                <li key={index}>
                    {trainer.trainerFirstName + ' ' + trainer.trainerLastName}
                </li>
            );
        })
        return elements;
    }

    private renderVCLinks(rooms: Room[], registrationId: number, participationAutoConfirmationForVCG: boolean): React.ReactNode {
        const elements: JSX.Element[] = [];
        rooms.map((room, index) => {
            if (room.externalMeetingId != null && room.externalMeetingId.length > 0) {
                if (room.virtualMeetingLink?.url != null && room.virtualMeetingLink.url.length > 0) {
                    const title = `${Session.instance.storage.translation.GetString("VideoConference:LinkTitle")} ${room.virtualMeetingLink?.title}`;
                    elements.push(<li key={index}>
                        <VirtualMeetingSingleLink
                            virtualMeetingLink={room.virtualMeetingLink?.url}
                            participationAutoConfirmationForVCG={participationAutoConfirmationForVCG}
                            registrationId={registrationId}
                            linkText="F2FVirtualMeetingLinks:JoinVirtualMeeting"
                            linkClass=""
                            linkTitle={title}
                            itemContext={this.state.itemDetailCallerContextType}
                            parentTrainingPlanId={this.state.parentTrainingPlanId} />

                    </li>);
                } else {
                    elements.push(<li key={index}><Translate>F2FVirtualMeetingLinks:MeetingNotActive</Translate></li>);
                }
            }
        });
        return elements;
    }

    private allowRegistration() {
        const courseCompleted = (this.state.item !== null && (this.state.item.lessonStatus === ELessonStatus.Completed || this.state.item.lessonStatus === ELessonStatus.Passed || this.state.item.lessonStatus === ELessonStatus.Failed) ? true : false);
        // alert(courseCompleted.toString());
        if (this.state.item != null &&
            this.state.item.itemSubType === EItemSubType.External &&
            this.state.item.isMultipleRegistrationAllowed &&
            !courseCompleted) {
            return false
        }
        if (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfRegisterToCourse) {
            return false;
        }
        if (this.state.item == null ||
            this.state.classDetails == null ||
            (this.state.item.isLocked === true && !this.state.classDetails.cheatStartcondition) ||
            !this.state.classDetails.isRegistrationAllowed ||
            !this.state.classDetails.hasRegistrationRight) {
            return false;
        }
        // Waiting list
        else if (this.state.item != null &&
            this.state.item.isRegistered &&
            this.state.placeReleasedFromWaitingList === true &&
            this.state.item.registrationStatus === ERegistrationStatus.InWaitingList) {
            return true;
        }
        else if (this.state.item != null &&
            ((this.state.item.isMultipleRegistrationAllowed && this.getActiveClassRegistration() === null) || !this.state.item.isRegistered)) {
            return true
        }
        else {
            return false
        }
    }

    private allowCancellation() {
        if (Session.instance.loginUser != null && !Session.instance.loginUser.canSelfUnregisterOfCourse) {
            return false;
        }
        const currentClass = this.state.classDetails;
        const currentRegistration = this.getActiveClassRegistration();
        if (currentClass != null && currentRegistration != null) {
            // if the cancellation is not allowed, check if no cancellationdates are set and the class is closed            

            return currentClass.isInCancellationPeriod1 ||
                currentClass.isInCancellationPeriod2 ||
                (currentClass.hasNoCancellationPeriod && currentClass.classStatus !== EClassStatus.Closed)
        }
        return false
    }

    private allowSelfConfirmationOfAttendance() {
        const currentClass = this.state.classDetails;
        const currentRegistration = this.getActiveClassRegistration();
        if (currentClass != null &&
            currentRegistration != null &&
            this.state.item !== null &&
            currentRegistration.registrationStatus === ERegistrationStatus.Accepted &&
            (!currentRegistration.hasAttended ||
                (this.state.item.lessonStatus !== ELessonStatus.Completed &&
                    this.state.item.lessonStatus !== ELessonStatus.Passed)
            ) &&
            this.state.item.lessonStatus !== ELessonStatus.Failed) {
            return currentClass.isInUserSelfConfirmationOfApprovalDates;
        }
        return false
    }

    private getActiveClassRegistration(): Registration | null {
        let currentRegistration: Registration[];
        const currentClass = this.state.classDetails;
        if (this.state.item != null &&
            this.state.item.allRegistrations != null &&
            this.state.item.allRegistrations.length > 0 &&
            currentClass != null) {
            currentRegistration = this.state.item.allRegistrations.filter(r => r.classId === currentClass.classId);
            // Has registration for this class
            if (currentRegistration.length > 0 &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.Cancelled &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.Rejected &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.ScheduleCancelled &&
                currentRegistration[0].registrationStatus !== ERegistrationStatus.NotRegistered) {
                return currentRegistration[0];
            }
        }
        return null;
    }

    private getCurrentRegistrationStatus(): ERegistrationStatus {
        let currentRegistrationStatus: ERegistrationStatus = ERegistrationStatus.Undefined;
        const currentClass = this.state.classDetails;
        if (this.state.item != null &&
            this.state.item.allRegistrations != null &&
            this.state.item.allRegistrations.length > 0 &&
            currentClass != null) {
            const currentRegistration = this.state.item.allRegistrations.filter(r => r.classId === currentClass.classId);
            if (currentRegistration != null && currentRegistration.length > 0 && currentRegistration[0] != null) {
                currentRegistrationStatus = currentRegistration[0].registrationStatus;
            }
        }
        return currentRegistrationStatus;
    }

    private async onRegistrationConfirmed(boss: BossSearchResult | undefined) {
        if (Session.instance.loginUser != null &&
            Session.instance.loginUser.canSelfRegisterToCourse) {
            const response = await RegistrationService.instance.saveF2FRegistration(
                (this.state.classDetails != null ? this.state.classDetails.courseId : 0),
                (this.state.classDetails != null ? this.state.classDetails.classId : 0),
                (boss != null ? boss.id : 0),
                false);
            if (isSuccess<BooleanResponse>(response)) {
                if (this.state.item != null && this.state.classDetails != null) {
                    window.location.reload();
                }
            } else {
                if (response.detailedObject !== undefined) {
                    this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
                } else {
                    this.setState({ errorMessage: 'ErrorMessage:RegistrationFailed' });
                }
            }
        } else {
            this.setState({ errorMessage: 'ErrorMessage:CanNotSelfRegisterOfCourse' });
        }
    }

    private async onRequestVoucherBtnClicked() {
        const response = await RegistrationService.instance.saveF2FRegistration(
            (this.state.classDetails != null ? this.state.classDetails.courseId : 0),
            (this.state.classDetails != null ? this.state.classDetails.classId : 0),
            0, true);

        if (isSuccess<BooleanResponse>(response)) {
            if (this.state.item != null && this.state.classDetails != null) {
                window.location.reload();
            }
        } else {
            if (response.detailedObject !== undefined) {
                this.setState({ errorMessage: CustomErrorMessage.getErrorCodeMessageString(response.detailedObject.subStatusCode) })
            } else {
                this.setState({ errorMessage: 'ErrorMessage:RegistrationFailed' });
            }
        }
    }

    private renderAvailability(maxParticipants: number, freePlaces: number, hasWaitingList: boolean): JSX.Element {
        let availability: JSX.Element;
        if (maxParticipants === 0) {
            availability = <Translate>Course:NoParticpantsLimit</Translate>
        } else if (freePlaces <= 0 && !hasWaitingList) {
            availability = <Translate>Course:NoPlaces</Translate>
        } else if (freePlaces <= 0 && hasWaitingList) {
            availability = <Translate>Course:WaitingList</Translate>
        } else {
            const tr = Session.instance.storage.translation;
            const text = tr.GetString('Course:FreePlaces').Format(freePlaces.toString());
            availability = <p>{text}</p>
        }
        return availability;
    }

    private async getDocuments(classId: number, courseId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0, forceReload: boolean): Promise<F2FDocumentList | null> {
        const methodName = `${this.className}:getDocuments()`;
        let f2fDocuments: F2FDocumentList | null = null;
        const f2fDocumentsResponse = await Session.instance.storage.f2fDocuments.getF2FDocuments(courseId, 0, classId, itemContext, parentTrainingPlanId, forceReload);
        if (isSuccess<F2FDocumentList | null>(f2fDocumentsResponse)) {
            f2fDocuments = f2fDocumentsResponse;
        } else {
            const errorMessage = `${methodName} failed to load f2fdocuments for course id ${courseId}, ` +
                `class id ${classId}, exception: ${f2fDocumentsResponse.message}`
            Logger.log(this.loggerLocality, errorMessage);
            this.setState({ errorMessage: 'ErrorMessage:GetF2FDocumentsFailed' });
            console.error(errorMessage);
        }
        return f2fDocuments;
    }

    private async reloadDocuments() {
        const classDetails = this.state.classDetails;
        let f2fDocuments: F2FDocumentList | null = null;
        let shouldShowAGBAcceptancePanel = false;
        if (classDetails != null) {
            f2fDocuments = await this.getDocuments(classDetails.classId, classDetails.courseId, this.state.itemDetailCallerContextType,
                this.state.parentTrainingPlanId, true);
            if (f2fDocuments != null &&
                f2fDocuments.allowedDocuments != null &&
                f2fDocuments.allowedDocuments.filter(doc => doc.documentType === EDocumentType.GeneralTermsAndConditions).length > 0) {
                shouldShowAGBAcceptancePanel = true;
            }
        }
        this.setState({ f2fDocuments, shouldShowAGBAcceptancePanel });
    }

    private async onCancelRegistrationConfirmed(cancellationReasonId: number | undefined, cancellationReasonText: string) {
        const methodName = `${this.className}:onCancelRegistration()`;
        let currentRegistration: Registration[];
        const currentClass = this.state.classDetails;
        let onCancelRegistration: BooleanResponse | GtError = new BooleanResponse();

        if (this.state.item != null &&
            this.state.item.allRegistrations != null &&
            this.state.item.allRegistrations.length > 0 &&
            currentClass != null) {
            currentRegistration = this.state.item.allRegistrations.filter(r => r.classId === currentClass.classId)
            if (currentRegistration != null) {
                if (this.state.item.itemType === EItemType.F2FCourse &&
                    this.state.item.itemSubType === EItemSubType.FaceToFace &&
                    Session.instance.loginUser != null &&
                    !Session.instance.loginUser.canSelfUnregisterOfCourse) {
                    this.setState({ errorMessage: 'ErrorMessage:CanNotSelfUnregisterOfCourse' });
                } else {
                    onCancelRegistration = await RegistrationService.instance.cancelRegistration(currentRegistration[0].registrationId,
                        cancellationReasonId != null ?
                            cancellationReasonId : 0,
                        cancellationReasonText);
                }
            }
        }

        if (isSuccess<BooleanResponse>(onCancelRegistration)) {
            window.location.reload();
        } else {
            const errorMessage = `${methodName} - Error occured: ${onCancelRegistration.message}`
            Logger.log(this.loggerLocality, errorMessage);
            console.error(errorMessage);

            this.setState({ errorMessage: 'ErrorMessage:CancelRegistrationFailed' })
            this.closeModal();
        }
    }

    private onRedeemVoucherBtnClicked(): void {
        if (this.state.isClassInShoppingBasket) {
            // remove class from the shopping basket
            ShoppingBasketStorage.instance.removeItemFromBasket(this.state.item!.itemId, this.state.classDetails!.classId);
            this.setState({ isClassInShoppingBasket: false });
        } else {
            // add class to the shopping basket
            // format learning period
            let classDates = '';
            if (this.state.classDetails!.learningPeriodStart != undefined && this.state.classDetails!.learningPeriodEnd != undefined) {
                classDates = StringHelper.dateString(this.state.classDetails!.learningPeriodStart) + ' - ' + StringHelper.dateString(this.state.classDetails!.learningPeriodEnd);
            }
            // format class location(s)
            let classLocations = '';
            const rooms = this.state.classDetails!.rooms;
            if (rooms != null) {
                classLocations = rooms.filter((room, index, self) => self.findIndex(r => r.venueCity === room.venueCity) === index) // remove duplicate locations
                    .map(r => r.venueCity).join(', '); // return remaining cities as string 
            }

            const shoppingBasket = new ShoppingBasket();
            shoppingBasket.saveTimeStamp = new Date();
            shoppingBasket.voucherCode = this.state.voucherValidity!.voucherCode;
            shoppingBasket.shoppingBasketItems.push(new ShoppingBasketItem(
                this.state.item!.itemId,
                this.state.classDetails!.classId,
                this.state.item!.itemType,
                this.state.item!.sId,
                this.state.classDetails!.classCode,
                this.state.item!.title,
                classDates + (classLocations.length > 0 ? ', ' + classLocations : ''),
                this.state.classDetails!.price,
                this.state.classDetails!.currency,
                [],
                []
            ));

            PurchaseService.instance.checkout(new CheckoutRequest(shoppingBasket)).then(response => {
                if (isSuccess<CheckoutResponse>(response)) {
                    // No paylink: either because 100% paid by voucher or because an error occurred.
                    // The awaitCheckoutCompletion page handles both cases.
                    this.props.history.push('/awaitCheckoutCompletion/' + response.referenceCode);
                }
                else {
                    console.error(`PurchaseService: checkout failed with itemid ${this.state.item!.itemId}.`);
                }
            });
        }
    }

    private onShoppingBtnClicked(): void {
        if (this.state.isClassInShoppingBasket) {
            // remove class from the shopping basket
            ShoppingBasketStorage.instance.removeItemFromBasket(this.state.item!.itemId, this.state.classDetails!.classId);
            this.setState({ isClassInShoppingBasket: false });
        } else {
            // add class to the shopping basket
            // format learning period
            let classDates = '';
            if (this.state.classDetails!.learningPeriodStart != undefined && this.state.classDetails!.learningPeriodEnd != undefined) {
                classDates = StringHelper.dateString(this.state.classDetails!.learningPeriodStart) + ' - ' + StringHelper.dateString(this.state.classDetails!.learningPeriodEnd);
            }
            // format class location(s)
            let classLocations = '';
            const rooms = this.state.classDetails!.rooms;
            if (rooms != null) {
                classLocations = rooms.filter((room, index, self) => self.findIndex(r => r.venueCity === room.venueCity) === index) // remove duplicate locations
                    .map(r => r.venueCity).join(', '); // return remaining cities as string 
            }
            // add to basket
            if (ShoppingBasketStorage.instance.addItemToBasket(new ShoppingBasketItem(
                this.state.item!.itemId,
                this.state.classDetails!.classId,
                this.state.item!.itemType,
                this.state.item!.sId,
                this.state.classDetails!.classCode,
                this.state.item!.title,
                classDates + (classLocations.length > 0 ? ', ' + classLocations : ''),
                this.state.classDetails!.price,
                this.state.classDetails!.currency,
                [],
                []
            ))) {
                this.setState({ isClassInShoppingBasket: true, showShoppingModal: true });
            }
        }
    }

    private onCancellationClicked() {
        this.setState({ showParticipitions: false });
        this.openModal();
    }

    private closeModal(): void {
        this.setState({ shouldShowModal: false });
    }

    private openModal(): void {
        this.setState({ shouldShowModal: true });
    }

    private onParticipationListClicked(): void {
        this.setState({ showParticipitions: true });
        this.openModal();
    }

    private onParticipationListTutorClicked(): void {
        if (this.state.classDetails?.classId) {
            window.open(globalConfig.appProperties.appPathOverride + 'participantlisttutor/' + this.state.classDetails.classId + '?callercontext=nsui');
        }
    }

    private closeSelfConfirmationModal(): void {
        this.setState({ displaySelfConfirmationModal: false });
        window.location.reload();
    }

    private getURLParameters(): IURLParamState {
        const methodName = `${this.className}:getURLParameters()`;
        const parameters = new URLSearchParams(window.location.search);

        const urlParamContext = parameters.get('context');
        const urlParamAssingmentId = parameters.get('asId');
        const urlParamCatalogFolerId = parameters.get('catId');
        const urlParamTrainingPlanId = parameters.get('tpId');
        const urlParamReleased = parameters.get('released');
        const voucherCode = parameters.get('vouchercode');

        let paramContext: EItemDetailCallerContextType = EItemDetailCallerContextType.Undefined;

        paramContext = urlParamContext != null && !isNaN(Number(urlParamContext)) ?
            Number(urlParamContext) : EItemDetailCallerContextType.Undefined;
        const paramAssingmentId = urlParamAssingmentId != null && !isNaN(Number(urlParamAssingmentId)) ?
            Number(urlParamAssingmentId) : 0;
        const paramCatalogFolerId = urlParamCatalogFolerId != null && !isNaN(Number(urlParamCatalogFolerId)) ?
            Number(urlParamCatalogFolerId) : 0;
        const paramTrainingPlanId = urlParamTrainingPlanId != null && !isNaN(Number(urlParamTrainingPlanId)) ?
            Number(urlParamTrainingPlanId) : 0;
        const paramReleased = urlParamReleased != null && urlParamReleased.toLocaleLowerCase() === "true" ? true : false;

        Logger.log(this.loggerLocality, `${methodName} got context: ${paramContext}, assignmentid: ${paramAssingmentId} ` +
            `catalogFolderId: ${paramCatalogFolerId}, trainingPlanId: ${paramTrainingPlanId}`);

        return {
            itemDetailCallerContextType: paramContext,
            parentAssignmentId: paramAssingmentId,
            parentCatalogFolderId: paramCatalogFolerId,
            parentTrainingPlanId: paramTrainingPlanId,
            placeReleasedFromWaitingList: paramReleased,
            voucherCode: voucherCode
        };
    }

    private setErrorMessage(errorMessage: string) {
        this.setState({ errorMessage });
    }

    // Guest confirm to be redirected to login page
    private onGuestRegistrationConfirmation(): void {
        if (this.state.item != null && this.state.classDetails != null) {
            const itemDetailLink = ItemHelper.getF2fRegistrationLink(this.state.item.itemId,
                this.state.classDetails.classId,
                this.state.itemDetailCallerContextType,
                this.state.parentCatalogFolderId,
                this.state.parentAssignmentId,
                this.state.parentTrainingPlanId)
            // Save the itemDetailLink in the session storage to enable redirect after selfReg
            Session.instance.setReturnUrlAfterLogin(itemDetailLink);
        }
        Session.instance.logout();
    }

    private async onConfirmAttendance(registrationId: number, itemContext: EItemDetailCallerContextType, parentTrainingPlanId = 0) {
        const response = await F2FService.instance.setLessonStatusCompletedAndHasAttended(registrationId, itemContext, parentTrainingPlanId);
        if (isSuccess<BooleanResponse>(response) && response.status) {
            this.setState({ displaySelfConfirmationModal: true });
        } else {
            this.setState({ errorMessage: 'ErrorMessage:SelfConfirmationOfAttendanceFailed' });
        }
    }
}
export default F2FRegistration;