import React from "react";
import { Alert } from "$components/shared/WarningsAndErrors/Alert";
import Session from "$src/core/Session";

/**
 * Forbidden Page which only shows an error message in the error alert box
 */
export default function ForbiddenPage() {
    return (
        <div className="l-container">
            <Alert 
                message={Session.instance.storage.translation.GetString('ErrorMessage:Forbidden')}
                alertType="error"
                alertAppereance="box"/>
        </div>
    )
}