
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SelfRegistrationResponse')
export class SelfRegistrationResponse {

    @JsonProperty('dynamicGroupRefreshActivated', Boolean, false)
    public dynamicGroupRefreshActivated: boolean = false;

    @JsonProperty('successful', Boolean, false)
    public successful: boolean | undefined = undefined;

    @JsonProperty('validationSuccess', Boolean, false)
    public validationSuccess: boolean = false;
 }


