import { News } from '$src/storage/models/News';
import { StringHelper } from '$src/util/StringHelper';
import React from 'react';

interface IProps {
    news: News;
}


export class PopupNewsSummary extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }



    public render() {
        return (
            <div className="popupNews__summary-container">
                <div className="popupNews__summary-container__item" dangerouslySetInnerHTML={{__html:StringHelper.htmlFormat(this.props.news.popupText)}}/>               
            </div>);
    }



}