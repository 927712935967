import React from 'react';

import IconarrowRight from '$resources/svgs/navi/arrow-right.svg';
import Session from '$src/core/Session';
import { BreadCrumbElement } from '$storage/models/BreadCrumbElement';
import InlineSVG from 'react-inlinesvg';
import { NavLink } from 'react-router-dom';


interface IProps {
    rootElement?: BreadCrumbElement;
    splitterElement?: JSX.Element;
    breadCrumbElements: BreadCrumbElement[];
}

export class BreadCrumb extends React.Component<IProps, {}> {
    protected className = 'BreadCrumb';
    protected loggerLocality = 'Components.BreadCrumb';

    private splitterDefault = <InlineSVG src={IconarrowRight} />;

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const elements: BreadCrumbElement[] = [];
        if(this.props.rootElement != null) { elements.push(this.props.rootElement) }
        if(this.props.breadCrumbElements != null) { this.props.breadCrumbElements.map(value => elements.push(value)) }
        return <nav aria-label="Breadcrumb" className="bread-crumb">
                    <ol>
                        {elements.map((value, index) => {
                                    return <li key={`breadcrumbelement_${index}`}>
                                                {this.renderBreadCrumbElement(value, index, elements.length)}
                                           </li>;
                                })
                        }
                    </ol>
                </nav>
    }

    protected renderBreadCrumbElement(value: BreadCrumbElement, index: number, totalElements: number): JSX.Element | null {
        const splitterElement = this.props.splitterElement != null ? this.props.splitterElement : this.splitterDefault;
        const title = value.translateTitle ? Session.instance.storage.translation.GetString(value.title) : value.title;
        return <React.Fragment key={`fragment_${index}`}>
            <NavLink
                key={`link_${index}`}
                to={value.navigationPath}
                aria-label={title}
                exact={true}
                dangerouslySetInnerHTML={{ __html: title }}
                style={value.style} />
            {totalElements !== index + 1 ? splitterElement : null}
        </React.Fragment>
    }
}
