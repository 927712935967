import { JsonObject, JsonProperty } from 'json2typescript';

/** Summary data of a "Glossar Karte" */
@JsonObject
export class GlossaryTermSummary {

    @JsonProperty('id', Number, false)
    public id: number = 0;

    @JsonProperty('sid', String, false)
    public sid: string = '';

    @JsonProperty('title', String, false)
    public title: string = '';
 
    @JsonProperty('synonyms', String, false)
    public synonyms: string = '';

    @JsonProperty('abbreviation', String, false)
    public abbreviation: string = '';

    @JsonProperty('text', String, false)
    public text: string = '';

    @JsonProperty('isViewed', Boolean, false)
    public isViewed: boolean = false;    

    @JsonProperty('isBookmarked', Boolean, false)
    public isBookmarked: boolean = false;        
}