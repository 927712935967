import ItemSummaryTrainingPlanItemV2 from '$src/components/item/itemSummaries/ItemSummaryTrainingPlanItemV2';
import ItemSummaryTrainingPlanModuleV2 from '$src/components/item/itemSummaries/ItemSummaryTrainingPlanModuleV2';
import { Collapsible } from '$src/components/shared/Collapsible';
import { EItemDetailCallerContextType, EItemType } from '$src/storage/models/enums';
import { TrainingPlan } from '$src/storage/models/TrainingPlan/TrainingPlan';
import { TrainingPlanElement } from '$src/storage/models/TrainingPlan/TrainingPlanElement';
import TranslationStorage from '$src/storage/TranslationStorage';
import { ItemHelper } from '$src/util/ItemHelper';
import React, { useMemo, useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import TrainingPlanBlockCompletitionRequirementsIcon from '../TrainingPlanBlockCompletitionRequirementsIcon';

interface IContext {
    itemDetailCallerContextType: EItemDetailCallerContextType;
    parentAssignmentId: number;
    parentCatalogFolderId: number;
    trainingPlan: TrainingPlan;
    parentHeadingLevel: number;
    translationStorage: TranslationStorage;
}

interface ITrainingPlanElementsContentsV2Props extends IContext {
    trainingPlanElements: TrainingPlanElement[];
}

interface ITrainingPlanElementLevelContainerProps extends IContext {
    trainingPlanElements: TrainingPlanElement[];
    parentItemId: string;
}

interface ITrainingPlanElementLevelProps extends ITrainingPlanElementLevelContainerProps {
    trainingPlanElement: TrainingPlanElement;
}

const ROOT_PARENT = 'root';

const TrainingPlanElementLevel = (props: ITrainingPlanElementLevelProps) => {
    const allowSelection = useMemo(() => props.trainingPlanElement.allowSelection(props.trainingPlan), [props.trainingPlan, props.trainingPlanElement]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const isBlock = props.trainingPlanElement.itemType === EItemType.Block;
    const trainingPlanElementLink = () => {
        return ItemHelper.getItemDetailLink(
            props.trainingPlanElement.itemId,
            props.itemDetailCallerContextType,
            props.parentCatalogFolderId,
            props.parentAssignmentId,
            props.trainingPlan.itemId,
            props.trainingPlanElement.tpeId)
    }

    return <li className={isBlock ? "item-summary__modulV2" : "item-summary__lessonV2"}>
        {!isBlock && <ItemSummaryTrainingPlanItemV2
            trainingPlanElement={props.trainingPlanElement}
            trainingPlan={props.trainingPlan}
            parentHeadingLevel={props.parentHeadingLevel + 1}
            navLink={trainingPlanElementLink()}
            id={props.trainingPlanElement.sId}
            translationStorage={props.translationStorage} />}
        {isBlock &&
            <>
                <div className='tp__contentsV2__heading-container'>
                    <div className='tp__contentsV2__completition-requirements'>
                        <TrainingPlanBlockCompletitionRequirementsIcon
                            activeTPElement={props.trainingPlanElement}
                            alwaysVisible={true} />
                    </div>
                    <Collapsible
                        containerCssClass="tp__contentsV2__module"
                        isOpened={isOpen && allowSelection}
                        onToggleOpenedState={() => allowSelection && setIsOpen(!isOpen)}
                        disallowTogglingOpenState={!allowSelection}>
                        <ItemSummaryTrainingPlanModuleV2
                            trainingPlanElement={props.trainingPlanElement}
                            trainingPlan={props.trainingPlan}
                            parentHeadingLevel={props.parentHeadingLevel + 1}
                            id={props.trainingPlanElement.sId}
                            translationStorage={props.translationStorage} />
                    </Collapsible>
                </div>
                {allowSelection && <UnmountClosed isOpened={isOpen}>
                    <TrainingPlanElementLevelContainer
                        {...props}
                        parentHeadingLevel={props.parentHeadingLevel + 1}
                        parentItemId={props.trainingPlanElement.sId}
                    />
                </UnmountClosed>}
            </>}
    </li>
}

const TrainingPlanElementLevelContainer = (props: ITrainingPlanElementLevelContainerProps) => {
    return <ol key={'tplevelContainer_' + props.parentItemId} className="tp__contentsV2">
        {props.trainingPlanElements.filter(tpe => tpe.parentItemId === props.parentItemId).map(tpe => <TrainingPlanElementLevel
            {...props}
            key={"tplevelContainer_" + tpe.sId}
            parentItemId={ROOT_PARENT}
            trainingPlanElement={tpe}
            parentHeadingLevel={props.parentHeadingLevel}
        />)}
    </ol>
}


export const TrainingPlanElementsContentsV2 = (props: ITrainingPlanElementsContentsV2Props) => {
    return <>
        {props.trainingPlanElements != null &&
            <TrainingPlanElementLevelContainer
                {...props}
                parentItemId={ROOT_PARENT}
                parentHeadingLevel={props.parentHeadingLevel}
            />}
    </>
}