import { UserSkill } from '$src/storage/models/Skill/UserSkill';
import React from 'react';
import { NoDataFound } from '$src/components/shared/WarningsAndErrors/NoDataFound';
import Session from '$src/core/Session';
import { SkillTableItemContainer } from '$src/components/Skills/SkillItem';
import { ESkillStatus, EItemDetailCallerContextType } from '$src/storage/models/enums';
import { UserSkillProfileSkillContainer } from '$src/storage/models/SkillProfile/UserSkillProfileSkillContainer';
import { SkillTable } from '$src/components/Skills/SkillTable';

interface IProps {
    context: EItemDetailCallerContextType;
    bossRelationCode?: string;
    userId: number;
    userSkills: UserSkill[];
    targetSkillContainer?: UserSkillProfileSkillContainer;
    skillProfileId?: number;
    onAssignPreselectedSkill?: (preselectedSkillId: number, preselectedLevelSetId: number | null, requiredSkillLevelId: number | null) => void;
    isProfile: boolean;
}


/**
 * This component renders a List of target skills as a table. The acquired user skills and the target skills get combined to show what skill is missing.
 */
export class TargetSkills extends React.Component<IProps, {}> {

    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const skillsContainer = this.concatTargetAndUserSkills(this.props.skillProfileId);
        if (skillsContainer.skillTableItem.length > 0) {
            return (
                <SkillTable
                    bossRelationCode={this.props.bossRelationCode}
                    context={this.props.context}
                    onAssignPreselectedSkill={this.props.onAssignPreselectedSkill}
                    skillsContainer={skillsContainer}
                    isTargetSkill={true}
                    userId={this.props.userId}
                    isProfile={this.props.isProfile} />);
        } else {
            if (skillsContainer.hasAllShowRights === false) {
                return <NoDataFound message={Session.instance.storage.translation.GetString('Skill:NoAccessInfo')} />
            }
            else {
                return <NoDataFound message={Session.instance.storage.translation.GetString('PerformanceSupport:NoTargetSkillsFound')} />
            }
        }
    }

    private concatTargetAndUserSkills(skillProfileId?: number): SkillTableItemContainer {
        const targetAndUserSkillsContainer: SkillTableItemContainer = new SkillTableItemContainer();
        targetAndUserSkillsContainer.hasAllShowRights = this.props.targetSkillContainer && this.props.targetSkillContainer.hasAllShowRights || false;

        this.props.targetSkillContainer?.userSkillProfileSkills?.map(targetSkill => {
            const userSkill = this.props.userSkills.find(us => us.skillId === targetSkill.skillId);
            if (userSkill != null) {
                targetAndUserSkillsContainer.skillTableItem.push({
                    skillId: targetSkill.skillId,
                    skillTitle: targetSkill.skillTitle,
                    skillDescription: targetSkill.skillDescription,
                    skillRequiredLevel: targetSkill.skillProfileSkillLevelTitle,
                    skillRequiredLevelId: targetSkill.skillProfileSkillLevelId,
                    skillLevel: userSkill.skillLevelTitle,
                    skillLevelSetId: targetSkill.skillLevelSetId,
                    skillAquired: userSkill.dateAcquired,
                    skillExpired: userSkill.expirationDate,
                    skillStatus: targetSkill.skillProfileSkillLevelValue > userSkill.skillLevelValue ? ESkillStatus.LevelMissing :
                        (userSkill.skillStatus === ESkillStatus.Expired && targetSkill.mayBeExpired) ? ESkillStatus.ExpiredButValid : userSkill.skillStatus,
                    skillNumberOfRecommendaedLessons: userSkill.numberOfRecommendedLessons,
                    skillReasonForAssignment: userSkill.reasonForAssignment,
                    skillProfileId,
                    certificates: userSkill.userSkillCertificates, //performanceSupport
                    attributes: userSkill.attributes, //performanceSupport
                    sortOrder: targetSkill.sortOrder,
                });
            } else {
                targetAndUserSkillsContainer.skillTableItem.push({
                    skillId: targetSkill.skillId,
                    skillTitle: targetSkill.skillTitle,
                    skillDescription: targetSkill.skillDescription,
                    skillRequiredLevel: targetSkill.skillProfileSkillLevelTitle,
                    skillRequiredLevelId: targetSkill.skillProfileSkillLevelId,
                    skillLevel: '',
                    skillLevelSetId: targetSkill.skillLevelSetId,
                    skillAquired: null,
                    skillExpired: null,
                    skillStatus: ESkillStatus.NotAcquired,
                    skillNumberOfRecommendaedLessons: targetSkill.numberOfRecommendedLessons,
                    skillReasonForAssignment: null,
                    skillProfileId,
                    hasAccessRight: targetSkill.hasAssignRight,
                    attributes: [],
                    sortOrder: targetSkill.sortOrder,
                });
            }
        });
        return targetAndUserSkillsContainer;
    }
}