import Iconcancelled from '$resources/svgs/status/cancelled.svg';
import Iconaccepted from '$resources/svgs/status/confirmed.svg';
import Iconrejected from '$resources/svgs/status/rejected.svg';
import Iconrequested from '$resources/svgs/status/requested.svg';
import IconwaitingList from '$resources/svgs/status/waiting-list.svg';
import { Translate } from '$src/components/shared/Translate';
import { ERegistrationStatus } from '$src/storage/models/enums';
import { ExternalCourseSchedule } from '$src/storage/models/ExternalCourse/ExternalCourseSchedule';
import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { StringHelper } from '$src/util/StringHelper';

interface IProps {
    externalCourseSchedule: ExternalCourseSchedule;
    showRequestStatus: boolean;
    externalCourseRequestStatus: ERegistrationStatus;
}

export class ExternalCourseScheduleTable extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
    }

    public render() {
        const regStatus = ERegistrationStatus[this.props.externalCourseRequestStatus];
        let icon = '';
        switch (this.props.externalCourseRequestStatus) {
            case ERegistrationStatus.Requested:
                icon = Iconrequested;
                break;
            case ERegistrationStatus.Accepted:
                icon = Iconaccepted;
                break;
            case ERegistrationStatus.Rejected:
                icon = Iconrejected;
                break;
            case ERegistrationStatus.Cancelled:
            case ERegistrationStatus.ScheduleCancelled:
                icon = Iconcancelled;
                break;
            case ERegistrationStatus.InWaitingList:
                icon = IconwaitingList;
                break;
        }
        return (
                <div role="table" className="div-table__horizontal-table">
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Schedule</Translate>
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Duration</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Schedule</Translate>
                            </div>
                            <div role="cell">
                                {`${this.props.externalCourseSchedule.formatedFirstAppointment} - ${this.props.externalCourseSchedule.formatedLastAppointment}`}
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Duration</Translate>
                            </div>
                            <div role="cell">
                                {this.props.externalCourseSchedule.duration}
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Location</Translate>
                            </div>
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Code</Translate>
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Location</Translate>
                            </div>
                            <div role="cell">
                                {this.props.externalCourseSchedule.location}
                            </div>
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Code</Translate>
                            </div>
                            <div role="cell">
                                {this.props.externalCourseSchedule.code}
                            </div>
                        </div>
                    </div>
                    <div role="rowgroup" className="notMobile">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5">
                                <Translate>ExternalCourse:Price</Translate>
                            </div>
                            {this.props.showRequestStatus &&
                                <div role="columnheader" className="heading__Level5">
                                    <Translate>ExternalCourse:RequestStatus</Translate>
                                </div>
                            }
                        </div>
                    </div>
                    <div role="rowgroup">
                        <div role="row" className="div-table__horizontal-table-row">
                            <div role="columnheader" className="heading__Level5 mobileOnly">
                                <Translate>ExternalCourse:Price</Translate>
                            </div>
                            <div role="cell" className="inlineFlex">
                            {`${this.props.externalCourseSchedule.currency} ${StringHelper.numberString(this.props.externalCourseSchedule.price)}`}
                            </div>
                            {this.props.showRequestStatus &&
                                <React.Fragment>
                                    <div role="columnheader" className="heading__Level5 mobileOnly">
                                        <Translate>ExternalCourse:RequestStatus</Translate>
                                    </div>
                                    <div role="cell" className={`inlineFlex registrationStatus registrationStatus__${regStatus}`}>
                                        <InlineSVG src={icon} />
                                        <Translate>{`RegistrationStatus:${regStatus}`}</Translate>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
        )
    }
}